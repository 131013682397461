import React, { Component } from 'react'
import overview_button from '../../../../assets/drishti_images/overview_button.svg';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import './NotificationStyles.css';
import cfm from '../../../../assets/drishti_images/notification/cfm.svg';
import todo from '../../../../assets/drishti_images/notification/todo.svg';
import transfer from '../../../../assets/drishti_images/notification/transfer.svg';
import calender_Notify from '../../../../assets/drishti_images/notification/calender-Notify.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { dateFromNow } from '../../../../utils/Utils'
import memo from '../../../../assets/drishti_images/notification/memo.svg'
import moments_icon from "../../../../assets/drishti_images/momentsFeed/MTMHeader.png";

class NotificationDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notificationData: [],
            notificationFilterType: [],
            approval_type: [],
            feedback_type: [],
            other_type: [],
            read_type: [],
        }
    }

    componentDidMount() {
        this.getNotifications(this.props.loginData.userData.Perno)
        this.getNotificationsFilterType()
    }

    getNotifications(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            'emp_id': employeeId
        }
        console.warn("getNotifications body:", body)
        fetchApiCall(Config.getNotifications, body, headers, false, '')
            .then(res => {
                console.warn("getNotifications res:", res)
                sessionOut(this.props, res);
                if (res.data.statusCode === '200') {
                    this.setState({
                        notificationData: res.data.data ? res.data.data : [],
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        notificationData: [],
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    getNotificationsFilterType() {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.filter_type_for_Notification, '', headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        notificationFilterType: res.data.data,
                        approval_type: res.data.data.approval_type ? res.data.data.approval_type : [],
                        feedback_type: res.data.data.feedback_type ? res.data.data.feedback_type : [],
                        other_type: res.data.data.other_type ? res.data.data.other_type : [],
                        read_type: res.data.data.read_type ? res.data.data.read_type : [],
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        notificationFilterType: [],
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    changeScreen = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }

    render() {
        return (
            <div>
                {this.state.loading === true &&
                    <LoaderIndicator loading={this.state.loading} />
                }
                <div>
                    <img alt='' src={overview_button} onClick={this.changeScreen} style={{ cursor: 'pointer' }} />
                </div>
                <div className='row col-md-12'>
                    {/* <div className='common-card col-md-10 mr-3 mt-3 px-12px ' style={{ height: '92vh' }}> */}
                    <div className='common-card col-md-12 mr-3 mt-3 px-12px notificationCardHeight'>
                        <div className='row m-1 p-10'>
                            All Notification
                        </div>
                        <hr />
                        {this.state.notificationData.length === 0 ?
                            <DataNotFound />
                            :
                            <div className='notification-scroller'>
                                {
                                    this.state.notificationData.map((listValue, id) =>
                                        <div key={id}>
                                            <div className='cardnotification col-md mt-3 '>
                                                <div className='flex-direction-row justify-content-between align-item-center m-3'>
                                                    <div className='flex-direction-row justify-content-start'>
                                                        {listValue.notification_type.includes('Task') ?
                                                            <div className="notification-circle-shape bg-dark-green">
                                                                <img src={todo} alt='' className='notification-emp_circle_text notification-Icons'/>
                                                            </div>
                                                            : listValue.notification_type.includes('Leave') ?
                                                                <div className="notification-circle-shape bg-purple">
                                                                    <img src={calender_Notify} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                    {/* <label className='notification-emp_circle_text'>{this.short_name(this.props.loginData.userData.CompName)}</label> */}
                                                                </div>
                                                                : listValue.notification_type.includes('feedback') ?
                                                                    <div className="notification-circle-shape bg-orange">
                                                                        <img src={cfm} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                    </div>
                                                                    : listValue.notification_type.includes('Birthday') ?
                                                                        <div className="notification-circle-shape bg-light-lightBlue">
                                                                            <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                        </div>
                                                                        : listValue.notification_type.includes('Long') ?
                                                                            <div className="notification-circle-shape bg-light-lightBlue">
                                                                                <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                            </div>
                                                                            : listValue.notification_type.includes('Work') ?
                                                                                <div className="notification-circle-shape bg-light-lightBlue">
                                                                                    <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                                </div>
                                                                                :
                                                                                <div className="notification-circle-shape bg-puple">
                                                                                    <img src={transfer} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                                </div>
                                                        }

                                                        <div className='flex-direction-column'>
                                                            <div className='text19 common-text-color'>
                                                                {listValue.notification_type}
                                                            </div>
                                                            <div className='text20 black-tag'>
                                                                {listValue.notification_description}
                                                            </div>
                                                            {/* <div className='align-item-center row'>
                                                            <div><img src={task_calender} /></div>
                                                            <div className='text18 common-text-color '> {moment(listValue.created_datetime).format('DD-MM-YYYY')}</div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className='text18 common-text-color'>
                                                        {dateFromNow(listValue.created_datetime)}
                                                        {/* {moment(listValue.created_datetime).format('DD/MM/YYYY')} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        }


                    </div>
                    {/* <div className='common-card col-md mt-3 px-12px ml-2'>
                        <div className='row m-1'>
                            Filter
                        </div>
                        <hr className='mb-3' />
                        <div className='flex-direction-column'>
                            <div className='row'>
                                <label className='filterTypetxt mb-2'>Read Type</label>
                            </div>
                            {this.state.read_type.map((list, i) => {
                                return (
                                    <div className='row p-1' key={i}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                // onChange={() => this.filterDueDate(list.value, list.isSelected, i)} 
                                                />
                                            <span className="markk"></span>
                                        </label>
                                    </div>

                                )
                            })}
                             <hr className='mb-3' />
                             <div className='filterTypetxt mb-2'>Approval</div>
                            {this.state.approval_type.map((list, index) => {
                                return (
                                    <div className='row p-1' key={index}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                // onChange={() => this.filterPriority(list.value, list.isSelected)}
                                            />
                                            <span className="markk"></span>
                                        </label>
                                    </div>
                                )
                            })}
                             <hr className='mb-3' />
                             <div className='filterTypetxt mb-2'>Feedback Request</div>
                            {this.state.feedback_type.map((list, index) => {
                                return (
                                    <div className='row p-1' key={index}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                // onChange={() => this.filterPriority(list.value, list.isSelected)}
                                            />
                                            <span className="markk"></span>
                                        </label>
                                    </div>
                                )
                            })}
                             <hr className='mb-3' />
                             <div className='filterTypetxt mb-2'>Others</div>
                            {this.state.other_type.map((list, index) => {
                                return(
                                    <div className='row p-1' key={index}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                // onChange={() => this.filterPriority(list.value, list.isSelected)}
                                            />
                                            <span className="markk"></span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>
                    </div> */}

                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}
export default connect(mapStatesToProps)(NotificationDetails);