import React, { Component } from 'react'
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';

class SliderImageNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageName: '',
      imageTitle: '',
      image: '',
      imageHyperlink: '',
    }
  }

  insertDynamicImage = () => {
    this.setState({ loading: true })
    const headers = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
    let body = {
      image_name: this.state.imageName,
      image_title: this.state.imageTitle,
      image_link: this.state.image_link,
      image_hyperlink: this.state.imageHyperlink,
    }
    fetchApiCall(Config.insertDynamicSliderImageBychro, body, headers, false, 'admin')
      .then(res => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code === '200') {
          toast.success(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          },
            this.props.allDismiss()
          )
          this.props.addDynamicImageDetailsActive()
          // this.props.getSliderImagesDataCall()
        } else {
          toast.warn(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
          this.setState({ loading: false })
        }
      }).catch(err => {
        this.setState({ loading: false })
      })
  }

  // fileSelectHandler = (event) => {
  //   this.setState({ imageName: event.target.files[0] ? event.target.files[0].name : '' })
  //   if (event.target.files && event.target.files[0]) {
  //     let maxAllowedSize = 2097152;           // 2MB size
  //     if (event.target.files[0].size <= maxAllowedSize) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]);
  //       reader.onload = (e) => { this.setState({ image_link: e.target.result }) }
  //     }
  //     else {
  //       toast.warn('Please upload image less than 2MB', {
  //         position: "bottom-center",
  //         hideProgressBar: true,
  //         className: 'toastWarn'
  //       })
  //       this.setState({ image_link: '' })
  //     }
  //   }
  //   else { this.setState({ image_link: '' }) }
  // }

  fileSelectHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ imageName: event.target.files[0] ? event.target.files[0] : '' })

    if (event.target.files && event.target.files[0]) {
      let maxAllowedSize = 2097152;           // 2MB size
      if (event.target.files[0].size <= maxAllowedSize) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.setState({
              heightAndWidth: [img.width, img.height]
            })
            this.uploadImg(this.state.imageName, file)
            console.log('Image width:', img.width);
            console.log('Image height:', img.height);
          }
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({ imageName: '' })
      }
    }
    else { this.setState({ imageName: '' }) }
  }

  uploadImg = (event, data) => {
    console.log("uploadImg event:", event);
    this.setState({
      imageName: event ? event.name : '',
    })
    console.log('event--------->', event)
    if (event) {
      console.log('event.size--------->', event.size)
      let maxAllowedSize = 2097152;  // 2MB size
      if (event.size <= maxAllowedSize) {
        // console.log("inside :", event.size, maxAllowedSize, data.file);
        // ******************************for Resize the image*****************************************
        try {
          Resizer.imageFileResizer(
            data,
            1280,
            720,
            'PNG',
            100,
            0,
            (uri) => {
              console.log('Resized image URI:', uri);
              this.setState({ image_link: uri })
              // Image.getSize(uri, (width, height) => {
              //   console.log('Resized image size:', width, height);
              // });
            },
            'uri',
            1280,
            720
          );
        } catch (err) {
          console.log("Resizer err:", err);
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          image_link: '',
          imageName: ''
        })
      }
    }
    else {
      this.setState({
        image_link: '',
        imageName: ''
      })
    }
  }

  validate = () => {
    if (this.state.imageTitle === '') {
      toast.warning('Please enter Image title', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.imageName === '' || this.state.image_link === '') {
      toast.warning('Please select image', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.imageHyperlink === '') {
      toast.warning('Please enter Hyperlink', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else {
      this.insertDynamicImage()
    }
  }

  render() {
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        <div>
          <form className='todoModalBody popup_scroller w-100'>
            <div>
              <div>
                <div className='p-sub-heading ml-3 mt-2'>Title</div>
                <div className='p-placeholder-text mb-2'>
                  <input
                    className="form-control p-placeholder px-3"
                    type="text"
                    placeholder={"Enter the title"}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ imageTitle: e.target.value })}
                    value={this.state.imageTitle}
                  />
                </div>
                <div>
                  <div className='p-sub-heading ml-3'>File</div>
                  {!this.props.show &&
                    <div className='p-placeholder-text mb-2'>
                      <input
                        className="form-control p-placeholder px-3"
                        type="file"
                        accept="image/*"
                        onChange={this.fileSelectHandler}
                      />
                      <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                    </div>
                  }
                </div>
                <div className='p-sub-heading ml-3'>Description</div>
                <div className='p-placeholder-text'>
                  <textarea
                    className="form-control p-placeholder px-3"
                    type="text"
                    placeholder={"Enter the description"}
                    maxLength={1000}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ imageHyperlink: e.target.value })}
                    value={this.state.imageHyperlink}
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </form>
          <div className='modal-footer btnBox justify-content-between'>
            <div className='justify-content-start'>
              <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
            </div>
            <div className='justify-content-around'>
              <div className='justify-content-start'>
                <input type='button'
                  value='CANCEL'
                  className='popup-back-btn popup-back-text'
                  onClick={this.props.back} />
              </div>
              <div className='justify-content-start'>
                <input type='button'
                  value='ADD'
                  className='popup-confirm-btn'
                  onClick={this.validate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    keycloakData: state.keycloakState,
  }
}

export default connect(mapStatesToProps)(SliderImageNotification);