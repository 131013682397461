import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import ClearanceRequests from './ClearanceRequests'
import ClearanceInitiation from './ClearanceInitiation'
import { RoleContext } from './Contexts'
import { constantVals } from './constants'
import ClearanceForTrainee from './ClearanceForTrainee'
import MasterMaintenance from './MasterMaintenance'
import { getClearanceRequestMaster } from '../../../../constants/api.service'
import { toaster } from '../../../../utils/Utils'
import ClearanceReport from './ClearanceReport'
import { configStore } from '../../../../redux/ConfigStore'

function ClearanceAdminHome(props) {
    const [loading, setLoading] = useState(false)
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [tabList, setTabList] = useState([])
    const [filterMaster, setFilterMaster] = useState({
        dateRange: { minDay: 0, maxDays: 0 },
        releaseStatusList: [],
        cleRequestViewFilter: [],
        adminTabList: []
    })

    const tabs = [
        { name: "Clearance Request", component: <ClearanceRequests />, isCommonComponent: true },
        { name: "Report", component: <ClearanceReport screenType={constantVals.ClearanceReports} />, isCommonComponent: false },
        { name: "Clearance Initiation", component: <ClearanceInitiation screenType={constantVals.ClearanceInitiate} />, isCommonComponent: false },
        { name: "Clearance for Trainee", component: <ClearanceForTrainee screenType={constantVals.ClearanceTrainee} />, isCommonComponent: true },
        { name: "Master Maintenance", component: <MasterMaintenance screenType={constantVals.ClearanceTrainee} />, isCommonComponent: true },
    ]
    const toggleLocalTabs = (index) => {
        setActiveTabIndex(index)
    }

    const fetchClearanceMaster = () => {
        setLoading(true)
        let params = {
            comp_code: configStore.getState()?.adminLoginState?.company?.company_code
        }
        getClearanceRequestMaster(params, props).then(
            (res) => {
                let tempArr = filterMaster.cleRequestViewFilter
                let tempArrStatus = []
                let tempDateRange = {}
                if (res.status === 200 || res.status === 201) {
                    const { cle_emp_agn, cle_status, cle_config_date, admin_tab_list } = res?.data?.data
                    admin_tab_list && setTabList(admin_tab_list)
                    cle_emp_agn && cle_emp_agn.map((config, index) => {
                        tempArr.push({ id: index + 1, title: config.agency_name, value: config.agency_config_id[0].join(), ...config })
                    })
                    cle_status && cle_status.map((config, index) => {
                        tempArrStatus.push({ id: index + 1, title: config.text, value: config.code })
                    })
                    if (cle_config_date) {
                        tempDateRange['minDay'] = cle_config_date[0].min_days ?? 0
                        tempDateRange['maxDays'] =  cle_config_date[0].max_days ?? 0
                    }
                    setFilterMaster({
                        cleRequestViewFilter: tempArr,
                        releaseStatusList: tempArrStatus,
                        dateRange: tempDateRange,
                        adminTabList: admin_tab_list
                    })
                }
                else { toaster("warning", res.data.message ? res.data.message : "") }
            }
        ).catch((error) => {
            console.log("error in clearance master api", error)
            toaster("warning", error.message ? error.message : "")
        }).finally(() => { setLoading(false) })
    }

    useEffect(() => { fetchClearanceMaster() }, [])

    return (
        <div className="row pb-0 pt-12px px-12px">
            <LoaderIndicator loading={loading} />
            <div className='col-md-12 justify-content-center align-item-center px-0'>
                <div className="col-md-12 card-new m-rem h-83vh">
                    <div className='row mt-3 ss'>
                        {tabs.filter((tab) => { return Object.keys(tabList).includes(tab.name) }).map((tab, index) => {
                            return (
                                <div className={index == activeTabIndex ? "tab-active" : "tab-inactive"} onClick={() => toggleLocalTabs(index)}>
                                    {tab.name}
                                </div>
                            )
                        })}
                    </div>
                    <div className='mt-3px'><hr /></div>
                    <div className='w-100'>
                        <RoleContext.Provider value={filterMaster}>
                            {tabs.filter((tab) => { return Object.keys(tabList).includes(tab.name) })?.[activeTabIndex]?.component}
                        </RoleContext.Provider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClearanceAdminHome