import React, { Component } from 'react';
import back from '../../../../assets/drishti_images/back.svg';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import calender_Notify from '../../../../assets/drishti_images/notification/calender-Notify.svg';
import todo from '../../../../assets/drishti_images/notification/todo.svg';
import cfm from '../../../../assets/drishti_images/notification/cfm.svg';
import transfer from '../../../../assets/drishti_images/notification/transfer.svg';
import noNotification from '../../../../assets/drishti_images/nodatafound/no-notification-new.png';
import { dateFromNow } from '../../../../utils/Utils'
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfmData: [],
            loading: false,
            notificationData: [],
            page_number: 1,
            selectitem: 'All',
            isDataPresent: false,
        }
    }

    componentDidMount() {
        this.getNotifications(this.props.adminLoginData.emp_id)
    }

    getNotifications(employeeId) {
        this.setState({
            loading: true,
            announcement_type: this.state.selectitem,
            page_number: this.state.page_number,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            announcement_type: this.state.selectitem,
            employee_id: employeeId,
            page_number: this.state.page_number
        }
        console.log("get_announcement body", body);
        fetchApiCall(Config.get_announcement, body, headers, false, 'admin')
            .then(res => {
                console.log("get_announcement res", res);
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        notificationData: this.state.notificationData.concat(res.data.data),
                        isDataPresent: true,
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        notificationData: [],
                        isDataPresent: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    goNotification = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/announcementNotification');
    }
    render() {
        return (
            <div className="card-new h-100" >
                <LoaderIndicator loading={this.state.loading} />
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Notifications</label>
                        </div>
                        {/* <div
                            type='button'
                            onClick={() => this.goNotification()}>
                            <img alt="gotoAnnouncement" src={back} className="righticon"></img>
                        </div> */}
                    </div>
                    {false ?
                    <div className={this.state.notificationData.length > 0 ? 'admin-landing-notification' : 'admin-landing-notification-withoutData'}>
                        {this.state.notificationData.length !== 0 ?
                            <>
                                {this.state.notificationData.slice(0, 6).map((listValue, id) =>
                                    <div key={id}>
                                        <div className='admin-notification-data flex-direction-row justify-content-start'>
                                            <div>
                                                {listValue.announcement_title.includes('Task') ?
                                                    <div className="admin-notification-circle-shape bg-dark-green">
                                                        <img alt='' src={todo} className='admin-notification-emp_circle_text admin-notification-Icons' />
                                                    </div>
                                                    : listValue.announcement_title.includes('Leave') ?
                                                        <div className="admin-notification-circle-shape bg-purple">
                                                            <img alt='' src={calender_Notify} className='notification-emp_circle_text notification-Icons' />
                                                            {/* <label className='notification-emp_circle_text'>{this.short_name(this.props.loginData.userData.CompName)}</label> */}
                                                        </div>
                                                        : listValue.announcement_title.includes('feedback') ?
                                                            <div className="admin-notification-circle-shape bg-orange">
                                                                <img alt='' src={cfm} className='notification-emp_circle_text notification-Icons' />
                                                            </div>
                                                            :
                                                            <div className="admin-notification-circle-shape bg-blue">
                                                                <img alt='' src={transfer} className='notification-emp_circle_text notification-Icons' />
                                                            </div>
                                                }
                                            </div>
                                            <div className='flex-direction-column'>
                                                <div className='text17_bold common-text-color'>
                                                    {listValue.announcement_title}
                                                </div>
                                                <div className='text17_light black-tag'>
                                                    {listValue.announcement_description}
                                                </div>
                                                <div className='text18 common-text-color'>
                                                    {dateFromNow(listValue.created_datetime)}
                                                    {/* {moment(listValue.created_datetime).format('DD-MM-YYYY')} */}
                                                </div>

                                            </div>
                                        </div>
                                        <div><hr /></div>


                                    </div>
                                )}
                            </>
                            :
                            <div className='col-md-12 d-flex align-items-center flex-column mb-10px'>
                                <div>
                                    <img src={noNotification} alt="Nodata" style={{ "padding": "10px", "height": "6.25rem" }} />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No notification found</label>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div><label className='card-content-title grey-color mt-8rem'>Coming Soon</label></div>}
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    }
}

export default connect(mapStatesToProps)(Notification)