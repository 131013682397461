import React, { Component } from 'react'
import { detailExpense, readOnlyLabel } from './constant'
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import download from '../../../../assets/drishti_images/documents/download.svg';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import NewNotdata from '../../../shared/notFound/NewNoData';
import common_no_data from '../../../../assets/drishti_images/common_no_data.svg'
import moment from 'moment';

const imageType = ['png', 'jpg', 'jpeg']
class DetailExpensePopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliceData: [],
            selectedUploadBill: {},
            uploadBillModal: false
        }
    }
    componentDidMount() {
        this.dataMap()
    }

    dataMap = () => {
        let tempData = []
        let checkVarType = this.props.type == "detailExpense" ? detailExpense : []
        for (let i = 0; i < Math.ceil(checkVarType.length / 3); i++) {
            let arr = checkVarType.slice(i * 3, (i + 1) * 3)
            tempData.push(arr)
        }
        this.setState({
            sliceData: tempData
        })
    }

    selectedUploadBill = (data) => {
        this.setState({ uploadBillModal: true, selectedUploadBill: data })
    }

    downloadBill = (value) => {
        commonDownloadFile(value.attachment_url)
    }

    render() {
        return (
            <div className="modal popup-box" >
                <div className={"popupCard-box p-0 w-50"}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{this.props.header}</h4>
                        <button type="button" className="close" data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;</button>
                    </div>
                    <div className='modal-body m-15px scroll-auto'>
                        <span className='flex-direction-column'>
                            <span className={this.state.uploadBillModal === true ? 'd-flex flex-direction-column' : 'container-project-details px-10px pb-10px'}>
                                {
                                    this.state.uploadBillModal === true ?
                                        <>
                                            <span className=''>
                                                <span onClick={() => this.setState({ uploadBillModal: false, selectedUploadBill: {} })}>
                                                    <img alt="Back" className='mr-10 pointer' src={backArrow} />
                                                </span>
                                                <span class="card-heading">{this.state.selectedUploadBill ? this.state.selectedUploadBill.attachment_name : ''}</span>
                                            </span>
                                            {
                                                imageType.includes(this.state.selectedUploadBill.attachment_type) &&
                                                < img src={this.state?.selectedUploadBill?.attachment_url ? this.state.selectedUploadBill.attachment_url : ''} alt="" className="upload_bills_modal_body" />
                                            }

                                            {this.state.selectedUploadBill.attachment_type === 'pdf' &&
                                                <iframe className='upload_bills_modal_body' src={this.state?.selectedUploadBill?.attachment_url && this.state?.selectedUploadBill?.attachment_url}></iframe>
                                            }
                                        </>
                                        :
                                        this.state.sliceData.map((row) => {
                                            return (
                                                row.map((data) => {
                                                    return (
                                                        this.props.type == "detailExpense" && this.props.isDetailsExpense.data.hasOwnProperty(data.key) &&
                                                        <>
                                                            {data.isOptional && this.props.isDetailsExpense.data[data.key] == null && data.key != 'bill_path' ?
                                                                <></>
                                                                :
                                                                <span className={'line-horizontal'}>
                                                                    <>
                                                                        <span className='appcenter-app-name'>{data.title}</span>
                                                                        <span className='feedback-sm-text ml-5px'>{data.key == 'from_expense_date' || data.key == 'to_expense_date' && this.props.isDetailsExpense.data[data.key] != null ? moment(this.props.isDetailsExpense.data[data.key]).format('DD-MM-YYYY') :
                                                                            (this.props.isDetailsExpense.data['expense_type'] == 'Accommodation allowance' || this.props.isDetailsExpense.data['expense_type'] == 'Daily allowance' || this.props.isDetailsExpense.data['sub_expense_type1'] == 'Own vehicle') && data.key == 'permissible_expense_amount' ? this.props.isDetailsExpense.data['permissible_expense_amount'] != null ? this.props.isDetailsExpense.data['permissible_expense_amount'] : 'NA'
                                                                                : this.props.isDetailsExpense.data[data.key] && data.key != 'permissible_expense_amount' ? this.props.isDetailsExpense.data[data.key] : 'NA'}</span>
                                                                    </>
                                                                </span>
                                                            }
                                                        </>
                                                    )
                                                }
                                                )
                                            )
                                        }
                                        )
                                }
                            </span >
                            < div className='line-horizontal' ></div>
                            {
                                this.props.isDetailsExpense.data.hasOwnProperty('bill_path') != null && this.state.uploadBillModal === false && this.props.attachmeantData.length > 0 ?

                                    <div className="main-contain" >
                                        <div className="row align-item-center justify-content-start ">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"><div className='thead'>{'Attachment'}</div></th>
                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Download'}</div></th>
                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Preview'}</div></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.props.attachmeantData.map((listValue, index) => {
                                                        return (
                                                            <tr key={index} >
                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.attachment_name}</div></td>
                                                                <td>
                                                                    <div className="td-choice-pay d-flex amount-justify-content-end" onClick={e => this.downloadBill(listValue, index)}>
                                                                        <img alt="view" src={download} style={{ width: '35px', cursor: 'pointer' }} className='mx-2' />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="td-choice-pay d-flex amount-justify-content-end" onClick={e => this.selectedUploadBill(listValue, index)}>
                                                                        <img alt="view" src={view_action} style={{ width: '30px', cursor: 'pointer' }} className='mx-2' />
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    this.props.isDetailsExpense.data.hasOwnProperty('bill_path') != null && this.state.uploadBillModal === false &&
                                    <div className='d-flex justify-content-center'>
                                        <NewNotdata />
                                    </div>
                            }
                        </span >
                    </div>
                </div>
            </div >
        )
    }
}

export default DetailExpensePopUp