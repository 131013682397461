import React, { Component } from 'react';
import moment from 'moment';
import '../transfer/Transfer.css';

class LeaveDetailPopup extends Component {
    render() {
        const { data } = this.props
        if (!this.props.data) return null;
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0 w-40">
                    <div className="modal-header justify-content-center mt-2 ml-2">
                        <h4 className="modal_title black">{'Leave Request Details'}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body p-3">
                        <div className="col-md">
                            <div className="row mr-0 p-3 justify-content-between">
                                {this.props.type === 'leave_request' ?
                                    <div className="font-weight-bold text-dark text-left w-50">
                                        <div className='text14_bold'>Approver Name </div>
                                        <div className="text14_light mt-1">{this.props.approver}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div> :
                                    <div className="font-weight-bold text-dark text-left w-50">
                                        <div className='text14_bold'>Employee Name</div>
                                        <div className="text14_light mt-1">{data.requestor_name ? data.requestor_name : ''}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                }
                                <div className="font-weight-bold text-dark text-left w-40">
                                    <div className='text14_bold'>Employee ID</div>
                                    <div className="text14_light mt-1">{data.requestor_emp_id ? data.requestor_emp_id : ''}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="row mr-0 p-3 justify-content-between">
                                <div className="font-weight-bold text-dark text-left w-50">
                                    <div className='text14_bold'>Leave Category</div>
                                    <div className="text14_light mt-1">{data.leave_category}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-40">
                                    <div className='text14_bold'>Duration</div>
                                    <div className="text14_light mt-1">{data.leave_type}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="row mr-0 p-3 justify-content-between">
                                <div className="font-weight-bold text-dark text-left w-50">
                                    <div className='text14_bold'>Start Date</div>
                                    <div className="text14_light mt-1">{moment(data.from_date).format("DD-MM-YYYY")}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-40">
                                    <div className='text14_bold'>To Date</div>
                                    <div className="text14_light mt-1">{moment(data.to_date).format("DD-MM-YYYY")}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="row mr-0 p-3 justify-content-between">
                                <div className="font-weight-bold text-dark text-left w-50">
                                    <div className='text14_bold'>Total days</div>
                                    <div className="text14_light mt-1">{parseFloat(data.total_days).toFixed(1)} {data.total_days <= 1 ? 'day' : 'days'}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>

                                <div className="font-weight-bold text-dark text-left w-40">
                                    <div className='text14_bold'>Reason</div>
                                    <div className="text14_light mt-1">{this.props.reason}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="row mr-0 p-3 justify-content-between">
                                <div className="font-weight-bold text-dark text-left w-50">
                                    <div className='text14_bold'>Status</div>
                                    <div className="text14_light mt-1">{data.leave_status}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.type == 'leave_request' ? null :
                        <div className='modal-footer btnBox'>
                            {data.leave_status_code === '2' || data.leave_status_code === '3' ? null :
                                <div className='d-flex flex-direction-end' style={{ paddingTop: '5%' }}>
                                    <input type='button' value='REJECT' className='cancelBtn' />
                                    <input type='button' value='APPROVE' className='ml-2 validateBtn' />
                                </div>}
                        </div>
                    }
                </div>

            </div>
        );
    }
}

export default LeaveDetailPopup;