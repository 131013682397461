import React, { Component } from 'react';
import { connect } from 'react-redux';
import './COIStyles.css';
import SpecificEmployeeReport from './SpecificEmployeeReport';
import SpecificPeriodReport from './SpecificPeriodReport';
import CompletionStatusReport from './CompletionStatusReport';


class COIDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_Specific_Employee_Report_Active: false,
            is_Specific_Period_Report_Active: false,
            is_Completion_Status_Report_Active: true,
        }
    }

    toggleSpecificEmployeeReport = () => {
        this.setState({ is_Specific_Employee_Report_Active: true, is_Specific_Period_Report_Active: false, is_Completion_Status_Report_Active: false })
    }
    toggleSpecificPeriodReport = () => {
        this.setState({ is_Specific_Employee_Report_Active: false, is_Specific_Period_Report_Active: true, is_Completion_Status_Report_Active: false })
    }
    toggleCompletionStatusReport = () => {
        this.setState({ is_Specific_Employee_Report_Active: false, is_Specific_Period_Report_Active: false, is_Completion_Status_Report_Active: true })
    }


    componentDidMount() {

    }

    render() {
        return (
            <div className="row p-12px">
                <div className="col-md-12 px-0 pt-0">
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.is_Completion_Status_Report_Active ? "tab-active" : "tab-inactive"} onClick={this.toggleCompletionStatusReport}>
                                    COI dashboard
                                </div>
                                <div className={this.state.is_Specific_Employee_Report_Active ? "tab-active" : "tab-inactive"} onClick={this.toggleSpecificEmployeeReport}>
                                    Specific Employee Report
                                </div>
                                <div className={this.state.is_Specific_Period_Report_Active ? "tab-active" : "tab-inactive"} onClick={this.toggleSpecificPeriodReport}>
                                    Specific Period Report
                                </div>
                            </div>

                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='profile-inner-card'>
                                {this.state.is_Completion_Status_Report_Active ?
                                    <div>
                                        <CompletionStatusReport />
                                    </div> : null
                                }
                                {this.state.is_Specific_Employee_Report_Active ?
                                    <div>
                                        <SpecificEmployeeReport />

                                    </div> : null
                                }
                                {this.state.is_Specific_Period_Report_Active ?
                                    <div>
                                        <SpecificPeriodReport />
                                    </div> : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(COIDashboard);