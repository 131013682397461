import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { notification_type1 } from '../../../../utils/Utils';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as AdminLoginAction from '../../adminScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
class PushNotificationPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            notificationType: notification_type1(),
            selectTypeView: 'Select',
            selectTypeCode: '',
            descriptionText: '',
            imageSize: '',
            PushNotificationImageName: '',
            PushNotificationImage: '',
        }
    }

    insertPushNotification = () => {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
        let body = {
            title: this.state.title,
            body: this.state.descriptionText,
            image_name: this.state.PushNotificationImageName,
            image_link: this.state.PushNotificationImage,
            emp_mail: this.props.adminLoginData.adminData.user_email,
            // notification_type: this.state.selectTypeView
            notification_type: 'organization'
        }
        console.log('body', body);
        fetchApiCall(Config.InserPushNotification, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                console.log("insertPushNotification res:", res)
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false, openNextPopup: false },
                        () => this.props.AllClose(),
                    )
                    setTimeout(() => { window.location.reload() })
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    changeType = (data) => {
        this.setState({
            selectTypeView: data.value,
        })
    }

    fileSelectHandler = (event) => {
        this.setState({ PushNotificationImageName: event.target.files[0] ? event.target.files[0].name : '' })
        if (event.target.files && event.target.files[0]) {
            // let maxAllowedSize = 1048576;  // 1MB size
            let maxAllowedSize = 2097152;  // 2MB size
            this.setState({
                imageSize: event.target.files[0].size
            })
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => { this.setState({ PushNotificationImage: e.target.result }) }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ PushNotificationImage: '' })
            }
        }
        else { this.setState({ PushNotificationImage: '' }) }
    }

    validate = () => {
        if (this.state.title === '') {
            toast.warning('Please Enter Image Title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.selectTypeView === '') {
            toast.warning('Please Select Notification Type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        // else if (this.state.PushNotificationImageName === '' && this.state.imageSize <= 2097152) {
        //     toast.warning('Please Select Image', {
        //         position: "bottom-center",
        //         hideProgressBar: true,
        //         className: 'toastWarn'
        //     })
        // }
        else if (this.state.descriptionText === '') {
            toast.warning('Please Write Description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertPushNotification()
        }
    }

    render() {
        const { NotificatioType, close, back, loading } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{this.props.NotificatioType}</h4>
                            {/* <button type="button" className="close" onClick={close}>&times;</button> */}
                        </div>
                        <div className="">
                            <form className='todoModalBody popup_scroller w-100'>
                                {/* <label className='form-label ml-2 mt-2'>Notification Type :
                                    <span className='form-label-notification-bold mx-1'>{NotificatioType}</span>
                                </label> */}
                                <div>
                                    <div>
                                        <div className='p-sub-heading ml-3 mt-1'>Title</div>
                                        <div className='p-placeholder-text mb-1'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the title"}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ title: e.target.value })}
                                                value={this.state.title}
                                            />
                                        </div>
                                        {/* <div>
                                            <div className='p-sub-heading ml-3'>Type</div>
                                            <div>
                                                <Dropdown
                                                    data={this.state.notificationType}
                                                    selectedValue={(data) => this.changeType(data)}
                                                    value={this.state.selectTypeView}
                                                    render={'value'}
                                                />
                                            </div>
                                        </div> */}
                                        <div>
                                            <div className='p-sub-heading ml-3 mt-1'>File</div>
                                            {!this.props.show &&
                                                <div className='p-placeholder-text mb-1'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.fileSelectHandler}
                                                    />
                                                    <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='p-sub-heading ml-3'>Description</div>
                                        <div className='p-placeholder-text'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the description"}
                                                maxLength={1000}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ descriptionText: e.target.value })}
                                                value={this.state.descriptionText}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                                </div>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={back} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='ADD'
                                            className='popup-confirm-btn'
                                            onClick={this.validate}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(PushNotificationPopup));