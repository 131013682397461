import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import DeclarationPopup from '../profile/DeclarationPopup';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import delete_icon from '../../../../assets/images/cfm/delete_icon.svg';
import 'react-toastify/dist/ReactToastify.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import LargeDropdown from '../../../shared/components/LargeDropdown';
// import Select from "react-select";

const chooseselect = 'Select'
class EducationCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            loading: false,
            isEdit: false,
            openDetail: false,
            delete: false,
            countryData: [],
            examinationPassedData: [],
            educationEstimateData: [],
            instituteData: [],
            weightageData: [],
            branchData: [],
            courseModeData: [],
            finalGradeData: [],
            examination: '',
            examination_code: '',
            country: chooseselect,
            country_code: '',
            education_estimate: chooseselect,
            education_estimate_code: '',
            date_of_start: '',
            date_of_completion: '',
            institute: chooseselect,
            institute_code: '',
            weightage: chooseselect,
            weightage_code: '',
            course_mode: chooseselect,
            course_mode_code: '',
            branch_code: '',
            branch: chooseselect,
            final_grade: chooseselect,
            final_grade_code: '',
            complete_course_name: '',
        }
    }

    componentDidMount() {
        if (!this.props.isEdit)
            return;

        // value added in Array of data for dropdown render value  
        const examinationPassedArray = this.props.examinationPassedData.map((item, idx) => ({
            ...item,
            id: idx,
            label: item.examination_text

        }))

        const countryArray = this.props.countryData.map((item, idx) => ({
            ...item,
            label: item.country_text
        }))

        const BranchArray = this.props.branchData.map((item, idx) => ({
            ...item,
            label: item.branch_text
        }))

        const educationEstimateArray = this.props.educationEstimateData.length != 0 ?
            this.props.educationEstimateData.map((item, idx) => ({
                ...item,
                label: item.value
            })) : []

        const instituteArray = this.props.instituteData ?
            this.props.instituteData.map((item, idx) => ({
                ...item,
                label: item.value
            })) : []

        const weightageArray = this.props.weightageData ?
            this.props.weightageData.map((item, idx) => ({
                ...item,
                label: item.value
            })) : []

        const courseArray = this.props.courseModeData ?
            this.props.courseModeData.map((item, idx) => ({
                ...item,
                label: item.value
            })) : []

        const gradeArray = this.props.finalGradeData ?
            this.props.finalGradeData.map((item, idx) => ({
                ...item,
                label: item.value
            })) : []


        this.setState(
            {
                examinationPassedData: examinationPassedArray,
                countryData: countryArray,
                branchData: BranchArray,
                educationEstimateData: educationEstimateArray,
                instituteData: instituteArray,
                weightageData: weightageArray,
                courseModeData: courseArray,
                finalGradeData: gradeArray,
            })
    }
    toggleOpenDetail = (type) => {
        if (type === 'education') {
            this.setState({
                openDetail: !this.state.openDetail,
            })
        }
        else return null
    }

    toggleClose = () => {
        this.setState({
            openDetail: !this.state.openDetail,
            isEdit: !this.state.isEdit,
        })
    }


    getInstituteDropdownData = (education_estimate_code) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { education_estimate_code: education_estimate_code }
        fetchApiCall(Config.getInstituteDropdown, body, headers, false, '')
            .then(result => {

                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        instituteData: result.data.data ? result.data.data : [],
                    });
                    const instituteArray = this.state.instituteData.map((item, idx) => ({
                        ...item,
                        label: item.institute_text
                    }))
                    this.setState({
                        instituteData: instituteArray ? instituteArray : []
                    });
                }

            }).catch(err => {
                console.warn('Institue err', err)
            })
    }
    validateEducationData = () => {
        let current_Date = new Date();
        let end_date = new Date(this.state.date_of_completion);
        let start_date = new Date(this.state.start_date);

        if (this.state.examination === '' || this.state.examination === chooseselect) {
            toast.error('Please select examination', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.date_of_start === '') {
            toast.error('Please select start date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (start_date > current_Date) {
            toast.error('Start date cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.date_of_completion === '') {
            toast.error('Please select completion date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (end_date > current_Date) {
            toast.error('Date of completion cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (start_date >= end_date) {
            toast.error('Start date cannot exceed completed date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.country == '' || this.state.country === chooseselect) {
            toast.error('Please select country', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.education_estimate == '' || this.state.education_estimate == chooseselect) {
            toast.error('Please select education estimate', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.institute == '' || this.state.institute === chooseselect) {
            toast.error('Please select institute', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.complete_course_name == '') {
            toast.error('Please enter completed course name', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.branch == '' || this.state.branch === chooseselect) {
            toast.error('Please select branch of study', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.course_mode == '' || this.state.course_mode == chooseselect) {
            toast.error('Please select course mode', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.final_grade == '' || this.state.final_grade == chooseselect) {
            toast.error('Please select final grade', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else {
            this.setState({ popupVisibility: true });
        }
    }
    hideModal = () => this.setState({ popupVisibility: false });

    deleteDataPopup = () => this.setState({ popupVisibility: true, delete: true });

    changeEducationEstablishment = (data) => {
        this.setState({
            education_estimate: data.value,
            education_estimate_code: data.code,
            institute: chooseselect
        }, () => this.getInstituteDropdownData(this.state.education_estimate_code))
    }

    changeCourseMode = (data) => {
        this.setState({
            course_mode: data.value,
            course_mode_code: data.code
        })
    }
    changeFinalGrade = (data) => {
        this.setState({
            final_grade: data.value,
            final_grade_code: data.code
        })
    }
    handleChangeCompletedCourse = (value) => {
        this.setState({
            complete_course_name: value
        })
    }

    handleOnSelectExamination = (item) => {
        this.setState({
            examination: item.examination_text,
            examination_code: item.examination_code
        })
    }
    handleOnSelectCountry = (item) => {
        this.setState({
            country: item.country_text,
            country_code: item.country_code
        })
    }
    handleOnSelectBranch = (item) => {
        this.setState({
            branch: item.branch_text,
            branch_code: item.branch_code
        })
    }
    handleOnSelectinstitute = (item) => {
        this.setState({
            institute: item.institute_text ? item.institute_text : '',
            institute_code: item.institute_code ? item.institute_code : ''
        })
    }
    setStartDate = (fulldate) => {
        this.setState({
            date_of_start: moment(fulldate).format("YYYY-MM-DD")
        });
    }
    setEndDate = (fulldate) => {
        this.setState({
            date_of_completion: moment(fulldate).format("YYYY-MM-DD")
        });
    }
    saveEducationDetails = () => {
        this.setState({
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            examination: this.state.examination ? this.state.examination : '',
            examination_code: this.state.examination_code ? this.state.examination_code : '',
            country: this.state.country ? this.state.country : '',
            country_code: this.state.country_code ? this.state.country_code : '',
            education_estimate: this.state.education_estimate ? this.state.education_estimate : '',
            education_estimate_code: this.state.education_estimate_code ? this.state.education_estimate_code : '',
            date_of_start: this.state.date_of_start ? this.state.date_of_start : '',
            date_of_completion: this.state.date_of_completion ? this.state.date_of_completion : '',
            institute: this.state.institute ? this.state.institute : '',
            institute_code: this.state.institute_code ? this.state.institute_code : '',
            weightage: 'Others',
            weightage_code: '004',
            course_mode: this.state.course_mode ? this.state.course_mode : '',
            course_mode_code: this.state.course_mode_code ? this.state.course_mode_code : '',
            branch_code: this.state.branch_code ? this.state.branch_code : '',
            branch: this.state.branch ? this.state.branch : '',
            final_grade: this.state.final_grade ? this.state.final_grade : '',
            final_grade_code: this.state.final_grade_code ? this.state.final_grade_code : '',
            complete_course_name: this.state.complete_course_name ? this.state.complete_course_name : ''
        }
        this.props.saveEducationData(body);
    }
    deleteData = () => {
        this.setState({ popupVisibility: false })
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            examination: this.props.item.examination ? this.props.item.examination : '',
            examination_code: this.props.item.examination_code ? this.props.item.examination_code : '',
            country: this.props.item.country ? this.props.item.country : '',
            country_code: this.props.item.country_code ? this.props.item.country_code : '',
            education_estimate: this.props.item.education_estimate ? this.props.item.education_estimate : '',
            education_estimate_code: this.props.item.education_estimate_code ? this.props.item.education_estimate_code : '',
            date_of_start: this.props.item.date_of_start ? this.props.item.date_of_start : '',
            date_of_completion: this.props.item.date_of_completion ? this.props.item.date_of_completion : '',
            institute: this.props.item.institute ? this.props.item.institute : '',
            institute_code: this.props.item.institute_code ? this.props.item.institute_code : '',
            weightage: this.props.item.weightage ? this.props.item.weightage : '',
            weightage_code: this.props.item.weightage_code ? this.props.item.weightage_code : '',
            course_mode: this.props.item.course_mode ? this.props.item.course_mode : '',
            course_mode_code: this.props.item.course_mode_code ? this.props.item.course_mode_code : '',
            branch_code: this.props.item.branch_code ? this.props.item.branch_code : '',
            branch: this.props.item.branch ? this.props.item.branch : '',
            final_grade: this.props.item.final_grade ? this.props.item.final_grade : '',
            final_grade_code: this.props.item.final_grade_code ? this.props.item.final_grade_code : '',
            complete_course_name: this.props.item.complete_course_name ? this.props.item.complete_course_name : ''
        }
        this.props.deleteEducationData(body)
    }
    render() {
        const { isEdit } = this.props
        return (
            <div className='row'>
                {isEdit ?
                    <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                        <div className='d-flex justify-content-between align-item-center'>
                            <div className='document-common-heading common-text-color'>Add New Educational Details
                            </div>
                            {this.state.openDetail ?
                                <div
                                    className='justify-content-end'
                                    onClick={() => this.toggleOpenDetail('education')}>
                                    <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                </div>
                                :
                                <div className='justify-content-end mt-3'
                                    onClick={() => this.toggleOpenDetail('education')}>
                                    <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                </div>
                            }
                        </div>
                        {!this.state.openDetail &&
                            <div>
                                <div className='hz-lines mt-2 mb-2'></div>
                                <div className='row justify-content-between'>
                                    <div className='p-heading mx-4 mt-2 mb-2'>Educational Details</div>
                                </div>
                                <div>
                                    <div className='row'>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Examination Passed</div>
                                            <LargeDropdown
                                                data={this.state.examinationPassedData}
                                                value={this.state.examination}
                                                selectedValue={(data) => this.handleOnSelectExamination(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Start Date</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="date"
                                                    onChange={(e) => this.setStartDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course End Date</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="date"
                                                    onChange={(e) => this.setEndDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Country</div>
                                            <LargeDropdown
                                                data={this.state.countryData}
                                                value={this.state.country}
                                                selectedValue={(data) => this.handleOnSelectCountry(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Establishment</div>
                                            <LargeDropdown
                                                data={this.state.educationEstimateData}
                                                value={this.state.education_estimate}
                                                selectedValue={(data) => this.changeEducationEstablishment(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                        {this.state.education_estimate != chooseselect &&
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Name of Institute</div>
                                                <LargeDropdown
                                                    data={this.state.instituteData}
                                                    value={this.state.institute}
                                                    selectedValue={(data) => this.handleOnSelectinstitute(data)}
                                                    disabled={!isEdit}
                                                />
                                            </div>
                                        }
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Completed Course Name</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type='text'
                                                    maxLength={40}
                                                    onChange={(e) => this.handleChangeCompletedCourse(e.target.value)}
                                                    value={this.state.complete_course_name}
                                                />
                                                <div className='p-sub-note'>{'You can add upto 40 characters and symbol "&" is not allowed'}</div>
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Branch of Study</div>
                                            <LargeDropdown
                                                data={this.state.branchData}
                                                value={this.state.branch}
                                                selectedValue={(data) => this.handleOnSelectBranch(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Mode</div>
                                            <LargeDropdown
                                                data={this.state.courseModeData}
                                                value={this.state.course_mode}
                                                selectedValue={(data) => this.changeCourseMode(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Final Grade</div>
                                            <LargeDropdown
                                                data={this.state.finalGradeData}
                                                value={this.state.final_grade}
                                                selectedValue={(data) => this.changeFinalGrade(data)}
                                                disabled={!isEdit}
                                            />
                                        </div>
                                    </div>
                                    <div className='row justify-content-end'>
                                        <div className='justify-content-between'>
                                            <div
                                                type='button'
                                                className='cancl-btn cancel-txt'
                                                onClick={() => this.props.cancel()}
                                            >Cancel</div>
                                            <div
                                                type='button'
                                                className='save-btn save-txt'
                                                onClick={() => this.validateEducationData()}
                                            >Save</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                        <div className='d-flex justify-content-between align-item-center'>
                            <div className='toggle-card-heading ml-2'>
                                {this.props.item.examination}
                            </div>
                            {this.state.openDetail ?
                                <div
                                    className='justify-content-end'
                                    onClick={() => this.toggleOpenDetail('education')}>
                                    <img alt="hide" src={up_arrow} className="up_arrow"></img>
                                </div>
                                :
                                <div className='justify-content-end mt-3'
                                    onClick={() => this.toggleOpenDetail('education')}>
                                    <img alt="hide" src={down_arrow} className="hide_icon"></img>
                                </div>
                            }
                        </div>
                        {this.state.openDetail &&
                            <div>
                                <div className='hz-lines mt-2 mb-2'></div>
                                <div className='row justify-content-between'>
                                    <div className='p-heading mx-2 mt-2 mb-2'>Educational Details</div>
                                    <div
                                        className='mr-2'
                                        onClick={this.deleteDataPopup}
                                    >
                                        <img
                                            alt="delete_icon"
                                            src={delete_icon}
                                            className="up_arrow"
                                        ></img>
                                    </div>
                                </div>
                                <div>
                                    <div className='row'>
                                    <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Examination Passed</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={this.props.item.examination}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Completed Course Name</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={this.props.item.complete_course_name}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Start Date</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={moment(this.props.item.date_of_start).format("DD-MM-YYYY")}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course End Date</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={moment(this.props.item.date_of_completion).format("DD-MM-YYYY")}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Country</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={this.props.item.country}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Establishment</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={this.props.item.education_estimate}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Name of Institute</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={this.props.item.institute}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Branch of Study</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={this.props.item.branch}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Course Model</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={this.props.item.course_mode}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Final Grade</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={this.props.item.final_grade}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={this.hideModal}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveEducationDetails}
                        deleteDetails={this.deleteData}
                        delete={this.state.delete} />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(EducationCards);