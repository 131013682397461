import React, { Component } from 'react';
import '../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from './NotificationDetailsPopup';
import moment from 'moment';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import NotificationConfirmPopup from './NotificationConfirmPopup';
import { currentDate } from '../../../../utils/Utils';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class AddDynamicImageDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admin: true,
            loading: false,
            listType: '',
            transferRequestData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            actionValue: '',
            data: [],
            isAddressEdit: false,
            isCurrentAddress: false,
            isToggle: false,
            isAddressSame: false,
            imageId: '',
            tempData: [],
            sliderImageData: [],
            isAdmin: true,
            requestData: [
                { id: 1, title: 'Active' },
                { id: 2, title: 'Inactive' }
            ]
        }
    }

    componentDidMount() {
        this.getSliderImagesData()
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    activeShowData = (data) => {
        this.setState({ selectedData: data, showDetail: true })
    }

    onPresentTypeChange = (key, type) => {
        this.setState({
            checked: key,
            listType: type
        },
            () => this.getAppliedTransferRequest(type)
        )
    }

    inActiveImage = () => {
        this.deleteSliderImageDetails()
    }

    getSliderImagesData() {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'application/json', }
        let request = { 'current_date': currentDate() }
        fetchApiCall(Config.getSliderImages, request, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.isAdmin);
                if (res.data.status_code === '200') {
                    if (res.data.data.length !== 0) {
                        this.setState({ sliderImageData: res.data.data, loading: false })
                    }
                }
                else { this.setState({ loading: false, sliderImageData: [] }) }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    deleteSliderImageDetails = () => {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'application/json' }
        let body = {
            image_title: this.state.tempData.image_title
        }
        fetchApiCall(Config.deleteSliderImage, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false })
                    setTimeout(() => { window.location.reload() }, 1000)

                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    warningPopUp = (data) => {
        this.setState({
            showConfirm: true,
            tempData: data
        })
    }

    showDetails = (data) => {
        this.setState({ showDetail: true, tempData: data })
    }

    render() {
        const { showConfirm, showDetail } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className="pushNotification-container pt-2" >
                    <div className='sm-scroll-table p-2'>
                        {this.state.sliderImageData.length != 0 ?
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                        <th scope="col"><div className='thead'>File</div></th>
                                        <th scope="col"><div className='thead'>Description</div></th>
                                        <th scope="col"><div className='thead'>Created Date</div></th>
                                        <th scope="col"><div className='thead'>Modified Date</div></th>
                                        {/* <th scope="col"><div className='thead ml-2'>Status</div></th> */}
                                        <th scope="col"><div className='thead ml-3'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.sliderImageData.map((listValue, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                <td><div className="td" style={{ width: "68%" }}>{listValue.image_title}</div></td>
                                                <td><div className="td">{listValue.image_hyperlink}</div></td>
                                                <td><div className="td" style={{ width: "135px" }}>{moment(listValue.created_datetime).format("DD-MM-YYYY")}</div></td>
                                                <td><div className="td" style={{ width: "135px" }}>{moment(listValue.modified_datetime).format("DD-MM-YYYY")}</div></td>
                                                {/* <td>
                                                    {this.state.isToggle ?
                                                        <img
                                                            className='td' src={toggleon}  alt=''
                                                        />
                                                        :
                                                        <img
                                                         alt=''
                                                            src={toggleoff} className='td pointer'  onClick={() => this.warningPopUp(listValue)}
                                                        />
                                                    }
                                                </td> */}
                                                <td className='d-flex'>
                                                    <div
                                                        onClick={() => this.showDetails(listValue)}>
                                                        <img
                                                            src={view_action}
                                                            alt='view'
                                                            style={{ width: '25px' }}
                                                            className='mx-2'
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => this.warningPopUp(listValue)}
                                                    >
                                                        <img alt="delete"
                                                            src={delete_action}
                                                            style={{ width: '25px' }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                    <div>
                                        <img src={noDataTransfer} alt="noDataTransfer" />
                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>No any image data!</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    showConfirm &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'inactive'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete slider images?'}
                        inActiveImage={this.inActiveImage}
                        onDismiss={() => this.setState({ showConfirm: false })}
                    />
                }
                {
                    showDetail &&
                    <NotificationDetailsPopup
                        type={"log-In-Image"}
                        title={"LogIn Image Details"}
                        data={this.state.tempData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(AddDynamicImageDetails);