import React, { Component } from 'react';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Stage1ConfirmPopup from '../conflictOfInterest/Stage1ConfirmPopup';
import Stage2ConfirmPopup from '../conflictOfInterest/Stage2ConfirmPopup';
import Declaration from '../conflictOfInterest/Declaration';
import DashboardScreen from '../conflictOfInterest/DashboardScreen';
import RequestFormPopup from '../conflictOfInterest/coiRequestForms/RequestFormPopup';
import '../conflictOfInterest/COIStyle.css';
import { connect } from 'react-redux';
import DeclarationPopup from '../conflictOfInterest/DeclarationPopup';
import { configStore } from "../../../../redux/ConfigStore";
import * as COIAction from '../conflictOfInterest/COIAction';

// const policy_hyperlink = 'https://www.tata.com/content/dam/tata/pdf/Tata%20Code%20Of%20Conduct.pdf'

class HomeScreenCOI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isStage1ConfirmPopup: false,
            isStage2ConfirmPopup: false,
            isDeclaration: false,
            openDeclaration: false,
            relationshipData: [],
            editForm: false,
            is_coi: false
        }
    }

    componentDidMount() {
        this.isCOIDataPresent()
        this.getRelationshipData()
    }

    isCOIDataPresent() {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.isDataPresent, {
            emp_id: this.props.loginData.userData.Perno
        }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isDeclaration: !res.data.is_visited_first_time,
                        is_coi: res.data.is_coi
                    });
                }
                else {
                    this.setState({ loading: false, isDeclaration: false })
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }

    getRelationshipData = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getRelationshipType, {}, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        relationshipData: res.data.relationship_options ? res.data.relationship_options : [],
                        loading: false,
                    });
                    const relationshipArray = this.state.relationshipData.map((item, idx) => ({
                        ...item,
                        value: item.relationship
                    }))
                    this.setState({
                        relationshipData: relationshipArray,
                    });
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }

    openOptionForm = () => {
        this.setState({
            editForm: false,
            isStage2ConfirmPopup: true

        })
        configStore.dispatch(COIAction.clearCOITypeData())
    }

    proceed = () => {
        this.setState({ isStage1ConfirmPopup: true })
    }

    openRequestForm = () => {
        this.setState({ editForm: true })
    }

    isDeclarationProceed = () => {
        this.setState({ isDeclaration: true })
        this.onDismiss()
    }

    onDismiss = () => {
        this.setState({
            editForm: false,
            openDeclaration: false
        })
        configStore.dispatch(COIAction.clearCOITypeData())
    }

    popupAction = (action) => {
        if (action === 'next') {
            this.setState({
                isStage2ConfirmPopup: true,
                isStage1ConfirmPopup: false
            })
        }
        else {
            this.setState({
                isStage2ConfirmPopup: false,
                isStage1ConfirmPopup: true
            })
        }
    }

    DashboardOpenDirectForm = (formType) => {
        configStore.dispatch(COIAction.clearCOITypeData())
        if (formType === 'A1') {
            configStore.dispatch(COIAction.getCOIType('A1'))
            this.openRequestForm()
        }
        else if (formType === 'A2') {
            configStore.dispatch(COIAction.getCOIType('A2'))
            this.openRequestForm()
        }
        else if (formType === 'A3') {
            configStore.dispatch(COIAction.getCOIType('A3'))
            this.openRequestForm()
        }
    }

    goToDashboard = () => {
        this.setState({
            isDeclaration: true
        })
    }

    render() {
        const { is_coi } = this.state
        return (
            <div className="row pb-0 pt-12px px-12px">
                <LoaderIndicator loading={this.state.loading} />
                {this.state.isStage1ConfirmPopup &&
                    <Stage1ConfirmPopup
                        next={() => this.popupAction('next')}
                        onDismiss={() => this.setState({ isStage1ConfirmPopup: false })}
                        proceedToHome={this.goToDashboard}
                        loginData={this.props.loginData}
                    />
                }
                {this.state.isStage2ConfirmPopup &&
                    <Stage2ConfirmPopup
                        back={() => this.popupAction('back')}
                        onDismiss={() => this.setState({ isStage2ConfirmPopup: false })}
                        openRequestForm={this.openRequestForm}
                    />
                }
                <div className='w-100'>
                    {!this.state.isDeclaration ?
                        <Declaration show={!this.state.isDeclaration}
                            proceed={this.proceed} />
                        : <DashboardScreen loginData={this.props.loginData}
                            openRequestForm={() => this.popupAction('next')}
                            restartCOI={() => this.setState({ isDeclaration: false })}
                            openDeclarationForm={() => this.setState({ openDeclaration: true })}
                            reset={this.isCOIDataPresent}
                            addNewForm={(form) => this.DashboardOpenDirectForm(form)}
                        />
                    }
                </div>
                {this.state.editForm &&
                    <RequestFormPopup
                        onDismiss={this.onDismiss}
                        proceedToHome={this.isDeclarationProceed}
                        backFromRequest={() => this.openOptionForm()}
                        relationshipData={this.state.relationshipData}
                        loginData={this.props.loginData}
                    />
                }
                {this.state.openDeclaration && <DeclarationPopup onDismiss={this.onDismiss} />}
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(HomeScreenCOI);