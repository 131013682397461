import React, { Component } from 'react';
import "../../../employeeScreens/continuousFeedbackManagement/CFMHome.css";
import plus from "../../../../../assets/images/cfm/plus.svg";
import done from "../../../../../assets/images/cfm/done.svg";
import cancel from "../../../../../assets/images/cfm/cancel.svg";
import basline from "../../../../../assets/images/cfm/basline.svg";
import { connect } from "react-redux";
import * as Config from "../../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../../constants/Service";
import CheckGreenIcon from '../../../../../assets/drishti_images/cfm/CheckGreenIcon.svg';
import nodata from "../../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg";
import { toast } from "react-toastify";
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import Tooltip from "react-bootstrap/Tooltip";
import { dateFromNow } from '../../../../../utils/Utils';
import ConfirmPopup from './ConfirmPopup';
import doubleTick from '../../../../../assets/drishti_images/manager/teams/doubleTick.svg';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import toDoDelete from '../../../../../assets/drishti_images/todo/toDoDelete.svg';
import toDoEdit from '../../../../../assets/drishti_images/todo/toDoEdit.svg';

toast.configure();
const requestFailed = "Unable to contact server\n\tPlease retry after sometime";
class GoalScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            isSubmit: false,
            isDelete: false,
            isUpdate: false,
            loading: false,
            addComment: false,
            isToggleOn: '',

            role: this.props.role,
            comment: '',
            itemId: '',
            sfGoalCommentsId: '',
            itemID: '',
            itemName: '',
            user_id: '',
            formDataID: '',
            formTemplateId: '',
            goalData: [],
            goalCommentsData: [],

            actionCode: '',
            actionValue: '',
            getComment_itemID: '',
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.length === 0) {
            this.setState({
                loading: false,
                goalData:[]
            })
        }
        else {
            this.setState({
                goalData: nextProps.data,
                loading: false
            });
        }
    }

    addCommentDetails = (item_id, name, user_id) => {
        this.setState({
            loading: true,
            isSubmit: false,
            isDelete: false,
            isUpdate: false
        });
        const req = {
            emp_id: this.props.empIdOfReportee,
            item_id: item_id,
            comment: this.state.comment,
            commented_by: this.props.loginData.userData.Perno,
            commented_by_name: this.props.loginData.userData.CompName,
            goal_name: name,
            role: this.state.role,
            form_data_id: this.state.formDataID,
            form_template_id: this.state.formTemplateId,
            prev_sf_goal_comments_id: '',
            fem_email: this.props.matrixManagerEmailID,
            dem_email: this.props.managerEmailID,
            emp_email: this.props.directReporteeEmailID
        };
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.addGoalComments, req, headers, true, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.props.getGoalsDetail()
                    this.cancelComment()
                } else {
                    this.setState({ loading: false });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: "toastError",
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    };

    updateManagerGoalComment = () => {
        this.setState({
            loading: true,
            isSubmit: false,
            isDelete: false,
            isUpdate: false
        });
        const req = {
            item_id: this.state.itemID,
            comment: this.state.updateComment,
            sf_goal_comments_id: this.state.sfGoalCommentsId
        };
        console.log('updateManagerGoalComment req:', req);
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.updateManagerGoalComment, req, headers, true, "")
            .then((res) => {
                console.log('updateManagerGoalComment res---->', res);
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                    },
                        () => this.getCommentDetails(this.state.getComment_itemID, this.state.userId),
                        () => this.cancelUpdateComment(),
                    );
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.cancelUpdateComment()
                } else {
                    this.setState({ loading: false });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: "toastError",
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    };

    popupAction = (action) => {
        if (action === "next") {
            this.setState({
                isGoalPopup: true,
                isLeadershipPopup: false,
            });
        } else {
            this.setState({
                isGoalPopup: false,
                isLeadershipPopup: true,
            });
        }
    };

    handleClick = (id, action) => {
        const tempGoal = this.state.goalData.map((item, idx) => ({
            ...item,
            isCommentShow: false
        }))
        this.setState({ goalData: tempGoal, addComment: false })
        if (action === 'view') {
            this.setState({
                itemId: id,
                getComment_itemID: id,
                updateComment: [],
                showUpdateComment: false
                // sfGoalCommentsId: sf_goal_comments_id,
            }, () => this.getCommentDetails(id));
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === id
                        ? { ...date, isCommentShow: true }
                        : date
                ),
            }));
        }
        else {
            this.setState({
                itemId: '',
                // sfGoalCommentsId: sf_goal_comments_id
            })
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === id
                        ? { ...date, isCommentShow: false }
                        : date
                ),
            }));
        }
    }

    handleChangeComment = (value, listValue) => {
        this.setState((prevState) => ({
            goalData: prevState.goalData.map((date) =>
                date.item_id === listValue.item_id
                    ? { ...date, commentDescription: value }
                    : date
            ),
        }));
        this.setState({
            comment: value,
            itemId: listValue.item_id,
            sfGoalCommentsId: listValue.sf_goal_comments_id,
            formDataID: listValue.form_data_id,
            formTemplateId: listValue.form_template_id,
        });
    };

    validateUpdateGoalsComment = (item_id, name, user_id) => {
        if (this.state.updateComment === "") {
            toast.error("Please enter comment", {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastError",
            });
        } else {
            this.setState({ itemName: name, user_id: user_id, isUpdate: true })
        }
    };

    cancelComment = (listValue) => {
        if (listValue) {
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === listValue.item_id
                        ? { ...date, commentDescription: '' }
                        : date
                ),
            }));
        }
        this.setState({
            comment: '',
            itemId: '',
            sfGoalCommentsId: '',
            formDataID: '',
            formTemplateId: '',
            addComment: false
        });
    }

    onClickPlusButton = (id) => {
        this.setState({
            itemId: id,
            addComment: true,
            showUpdateComment: false,
            // updateComment:[]
        });
        const tempGoal = this.state.goalData.map((item, idx) => ({
            ...item,
            isCommentShow: false
        }))
        this.setState({ goalData: tempGoal })
    };

    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }

    updateGoalsComment = (data, listValue) => {
        this.setState({
            showUpdateComment: true,
            // itemID: allData.item_id,
            itemID: data.item_id_pk_id,
            sfGoalCommentsId: data.sf_goal_comments_id,
            updateComment: data.comment,
            userId: listValue.user_id,
            // addComment:false
        })
    }

    cancelUpdateComment = () => {
        this.setState({
            updateComment: '',
            // itemId: '',
            showUpdateComment: false,
            formDataID: '',
            formTemplateId: '',
            updateComments: false
        });
    }

    validateGoalsData = (data) => {
        // console.log('data', data);
        if (this.state.comment == "") {
            toast.error("Please enter comment", {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastError",
            });
        } else {
            this.setState({ isSubmit: true, isDelete: false, isUpdate: false, itemName: data.name, itemId: data.item_id, user_id: data.user_id })
        }
    };

    deleteComment = (data) => {
        console.log('goallistValue', data);
        this.setState({
            isSubmit: false,
            isDelete: true,
            isUpdate: false,
            sfGoalCommentsId: data.sf_goal_comments_id,
            itemID: data.item_id_pk_id
        })
    }

    getCommentDetails = (id) => {
        this.setState({
            loading: true,
        });
        let body = {
            item_id: id,
            is_employee: false
        };
        console.log('item_id', body);
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.getGoalComments, body, headers, true, "")
            .then((res) => {
                // console.log('sf_goal_comments_id', res);
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                        goalCommentsData: res.data.goal_comments ? res.data.goal_comments : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        goalCommentsData: [],
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: "toastError",
                });
            });
    };

    deleteGoalComments = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            item_id: this.state.itemID,
            sf_goal_comments_id: this.state.sfGoalCommentsId
        }
        // console.log('delete body---------', body);
        fetchApiCall(Config.deleteManagerGoalComment, body, headers, true, '')
            .then(res => {
                console.log('deleteManagerGoalComment', res);
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isDelete: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getCommentDetails(this.state.itemID)
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    showModal = () => {
        this.setState({ popupVisibility: true });
    };

    hideModal = () => this.setState({ popupVisibility: false });

    lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }
    renderMTRTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{'Sent to MTR'}</div>
        </Tooltip>
    );
    renderSFTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{'Sent to Final Appraisal'}</div>
        </Tooltip>
    );

    handleUpdateChangeComment = (value, listValue) => {
        this.setState({
            updateComment: value
        })
    };

    render() {
        const { isSubmit, isDelete, isUpdate } = this.state
        const { applicationMode } = this.props
        return (
            <div>
                <div className="">
                    <LoaderIndicator loading={this.state.loading} />
                    {this.state.goalData.length != 0 ?
                        <div className="feedback-inside-scrollbar mr-0">
                            {this.state.goalData.map((listValue, id) => {
                                return (
                                    <div className="col-12 row justify-content-between" key={id}>
                                        <p className="col-11 feedback-text justify-content-start px-0">
                                            {listValue.name}
                                        </p>
                                        {applicationMode === 'write' &&
                                            <div
                                                type="button"
                                                className="justify-content-end mr-0"
                                                onClick={() => this.onClickPlusButton(listValue.item_id)}
                                            >
                                                <img alt="plus" src={plus} className="plus-img"></img>
                                            </div>
                                        }
                                        <div className="row col-12 justify-content-start align-item-center px-0 mb-3">
                                            <div className="tag-back">
                                                <label className="tag-text justify-content-center">
                                                    {listValue.category}
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            {this.state.itemId === listValue.item_id && this.state.addComment ?
                                                <div className="">
                                                    <div className="flex-direction-column">
                                                        <textarea
                                                            className=" sub-textarea cfm-subcard feedback-comment"
                                                            style={{ 'borderBottom': 'none' }}
                                                            placeholder="Enter your feedback..."
                                                            rows="3"
                                                            maxLength={300}
                                                            value={listValue.commentDescription}
                                                            onChange={(e) => {
                                                                this.handleChangeComment(
                                                                    e.target.value,
                                                                    listValue
                                                                );
                                                            }}
                                                        ></textarea>
                                                        <div className="row hint-btn-view suggetion" >
                                                            {/* <div className='row justify-between-around'> */}
                                                            <div className="hint-btn common-text-color" onClick={() =>
                                                                this.handleChangeComment(
                                                                    'Consider',
                                                                    listValue
                                                                )}>
                                                                <label className='hint-text-new'>Consider</label></div>
                                                            <div className="hint-btn common-text-color" onClick={() =>
                                                                this.handleChangeComment(
                                                                    'Continue',
                                                                    listValue
                                                                )}>
                                                                <label className='hint-text-new'>Continue</label></div>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="justify-content-between row">
                                                        <div className="justify-content-start time-text ml-3 mt-2">
                                                            {this.lengthCalculate(listValue.commentDescription)}/300
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <div
                                                                type="button"
                                                                className="btn done-text grey-color mt-1"
                                                                onClick={() => this.cancelComment(listValue)}
                                                            >
                                                                <img
                                                                    alt="cancel"
                                                                    src={cancel}
                                                                    className="done-icon mr-1 mb-0"
                                                                ></img>
                                                                Cancel
                                                            </div>
                                                            <div
                                                                type="button"
                                                                className="btn done-text purple-color mt-1"
                                                                onClick={() => this.validateGoalsData(listValue)}
                                                            >
                                                                <img
                                                                    alt="done"
                                                                    src={done}
                                                                    className="done-icon mr-1 mb-0"
                                                                ></img>
                                                                Submit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {this.state.itemId === listValue.item_id && this.state.showUpdateComment ?
                                                <div className="">
                                                    <div className="flex-direction-column">
                                                        <textarea
                                                            className="sub-textarea cfm-subcard feedback-comment"
                                                            placeholder="Enter your feedback..."
                                                            rows="3"
                                                            maxLength={300}
                                                            value={this.state.updateComment}
                                                            onChange={(e) => {
                                                                this.handleUpdateChangeComment(
                                                                    e.target.value,
                                                                    listValue
                                                                );
                                                            }}
                                                        ></textarea>
                                                        <div className="row hint-btn-view suggetion">
                                                            <div className="hint-btn common-text-color"
                                                                onClick={() =>
                                                                    this.handleUpdateChangeComment(
                                                                        'Consider',
                                                                        listValue
                                                                    )}><label className='hint-text-new'>Consider</label>
                                                            </div>
                                                            <div className="hint-btn common-text-color"
                                                                onClick={() =>
                                                                    this.handleUpdateChangeComment(
                                                                        'Continue',
                                                                        listValue
                                                                    )}><label className='hint-text-new'>Continue</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="justify-content-between row">
                                                        <div className="justify-content-start time-text ml-3 mt-2">
                                                            {this.lengthCalculate(listValue.commentDescription)}/300
                                                        </div>
                                                        <div className="d-flex justify-content-end">
                                                            <div
                                                                type="button"
                                                                className="btn done-text grey-color mt-1"
                                                                onClick={() => this.cancelUpdateComment()}
                                                            >
                                                                <img
                                                                    alt="cancel"
                                                                    src={cancel}
                                                                    className="done-icon mr-1 mb-0"
                                                                ></img>
                                                                Cancel
                                                            </div>
                                                            <div
                                                                type="button"
                                                                className="btn done-text purple-color mt-1"
                                                                onClick={() => this.validateUpdateGoalsComment(listValue.item_id, listValue.name, listValue.user_id)}
                                                            >
                                                                <img
                                                                    alt="done"
                                                                    src={done}
                                                                    className="done-icon mr-1 mb-0"
                                                                ></img>
                                                                Update
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                            {listValue.comments.length != 0 &&
                                                <div>
                                                    {!listValue.isCommentShow &&
                                                        <div className="mb-3">
                                                            <div className="d-flex flex-wrap align-item-center">
                                                                {listValue.comments.map(
                                                                    (commentValue, id) => {
                                                                        return (
                                                                            <div key={id}
                                                                                className={commentValue.role === 'FEM' ? 'fem-bg-circle cfm-hide-goals-view' :
                                                                                    commentValue.role === 'DEM' ? 'dem-bg-circle cfm-hide-goals-view' :
                                                                                        "employee-bg-circle cfm-hide-goals-view"}
                                                                            >
                                                                                {this.short_name(
                                                                                    commentValue.commented_by_name
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                                <label
                                                                    type="button"
                                                                    className="view-feedback-btn mr-3"
                                                                    onClick={() => this.handleClick(listValue.item_id, 'view')}
                                                                >
                                                                    View feedbacks
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.goalCommentsData.length != 0 && listValue.isCommentShow &&
                                                        <div>
                                                            <div className="feedback-subcard">
                                                                {this.state.goalCommentsData.map((goallistValue, id) => {
                                                                    return (
                                                                        <div className="d-flex align-item-center" key={id}>
                                                                            <div className={id < (this.state.goalCommentsData.length - 1) ? "timeline ml-3" : 'timeline1 ml-3'}>
                                                                                <div
                                                                                    className={goallistValue.role === 'FEM' ? 'fem-bg-circle manager-circle-text' :
                                                                                        goallistValue.role === 'DEM' ? 'dem-bg-circle manager-circle-text' :
                                                                                            "employee-bg-circle manager-circle-text"}
                                                                                >
                                                                                    {this.short_name(goallistValue.commented_by_name)}
                                                                                </div>
                                                                                <div className="ml-5">
                                                                                    <div className="row justify-content-between mt-2">
                                                                                        <div className="row">
                                                                                            <div className="sub-card-text-bold">
                                                                                                {goallistValue.commented_by_name}
                                                                                            </div>
                                                                                            <div className='time-text mx-3'>{dateFromNow(goallistValue.created_date_time)}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="mr-4">
                                                                                            {goallistValue.status === '4' ?
                                                                                                <OverlayTrigger placement="left" overlay={this.renderMTRTooltip}>
                                                                                                    <img
                                                                                                        alt="basline"
                                                                                                        src={CheckGreenIcon}
                                                                                                        className="inside-basedline"
                                                                                                    >
                                                                                                    </img>
                                                                                                </OverlayTrigger>
                                                                                                :
                                                                                                goallistValue.status === '5' ?
                                                                                                    <OverlayTrigger placement="left" overlay={this.renderSFTooltip}>
                                                                                                        <img
                                                                                                            alt="basline"
                                                                                                            src={doubleTick}
                                                                                                            className="inside-basedline"
                                                                                                        ></img>
                                                                                                    </OverlayTrigger>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                        {goallistValue.status === '0' && this.props.loginData.userData.Perno === goallistValue.commented_by ?
                                                                                            <>
                                                                                                {applicationMode !== 'view' &&
                                                                                                    <div className='row'>
                                                                                                        <img src={toDoDelete}
                                                                                                            alt=''
                                                                                                            className='mr-2'
                                                                                                            onClick={() => this.deleteComment(goallistValue)}
                                                                                                        />
                                                                                                        <img src={toDoEdit}
                                                                                                            alt=''
                                                                                                            className='mr-2'
                                                                                                            onClick={() => this.updateGoalsComment(goallistValue, listValue)}
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                            </> : null
                                                                                        }
                                                                                    </div>
                                                                                    <div className="row feedback-comment-text mt-1">
                                                                                        {goallistValue.comment}
                                                                                    </div>
                                                                                    <div className="row mt-2 mb-2">
                                                                                        <img
                                                                                            alt="basline"
                                                                                            src={basline}
                                                                                            className="inside-basedline row"
                                                                                        ></img>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div className="justify-content-start align-item-start mt-2">
                                                                <label
                                                                    type="button"
                                                                    className="view-feedback-btn mx-3"
                                                                    onClick={() => this.handleClick(listValue.item_id, 'hide')}
                                                                >
                                                                    Hide feedbacks
                                                                </label>
                                                            </div>
                                                        </div>}
                                                </div>
                                            }
                                            <div className="mt-2">
                                                <img
                                                    alt="basline"
                                                    src={basline}
                                                    className="basedline"
                                                ></img>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        :
                        <div className=''>
                            <div className='d-flex h-60vh align-item-center flex-column w-100 justify-content-center'>
                                <img className='align-self-center' src={nodata} alt="Nodata" />
                                <div className='row'>
                                    <label className='card-content-title grey-color'>
                                        {this.props.noGoalSet != '' ? this.props.noGoalSet : 'Your goal forms is not yet completed !'}
                                    </label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {isDelete &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={'delete'}
                        content={'Kindly confirm if you want to delete?'}
                        deleteData={() => this.deleteGoalComments()}
                        onDismiss={() => this.setState({ isDelete: false })}
                    />
                }
                {isUpdate &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={'update'}
                        content={'Please note, once feedback is submitted it cannot be modified or deleted. Kindly confirm if you want to proceed?'}
                        updateData={() => this.updateManagerGoalComment()}
                        onDismiss={() => this.setState({ isUpdate: false })}
                    />
                }
                {isSubmit &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={'submit'}
                        content={'Please note, once feedback is submitted it cannot be modified or deleted. Kindly confirm if you want to proceed?'}
                        submitData={() => this.addCommentDetails(this.state.itemId, this.state.itemName, this.state.user_id)}
                        onDismiss={() => this.setState({ isSubmit: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    };
};

export default connect(mapStatesToProps)(GoalScreen);
