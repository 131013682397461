import React, { Component } from 'react';
import './announcementScreen.css';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import moment from 'moment';
import nodataicon from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
class TataBuzzNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tatabuzznews: []
        }
    }

    componentDidMount() {
        this.getBuzzNews()
    }

    getBuzzNews = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            action: "GET"
        }
        fetchApiCall(Config.getBuzzNews, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                console.log("res.data", res.data)
                if (res.data.status_code == '200') {

                    this.setState({
                        tatabuzznews: res.data.data.buzz_news,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        tatabuzznews: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        return (
            <div className='row tata-buzz-news mt-3'>
                <div className="col-md-12 card-new" >
                    <div className='row'>
                        <label className='edit-cardtitle common-text-color mt-2 '>
                            Tata Buzz News
                        </label>
                    </div>
                    {this.state.tatabuzznews.length === 0 ?
                        <div className='nodata-incenter'>
                            <img src={nodataicon} alt="Nodata" />
                            <label className='card-content-title grey-color'>News not found !</label>
                        </div>
                        :
                        < div className='row news-scroll padding-left-10 padding-top-5'>
                            {this.state.tatabuzznews.map((newslist, id) =>
                                <div key={id}>
                                    <div className='row'>
                                        <label className='announcement-inner-content common-text-color'>{newslist.news_title}</label>
                                    </div>
                                    <div className='row'>
                                        <label className='announcement-pin-content light-grey '>{newslist.news_description}</label>
                                    </div>
                                    <div className='row'>
                                        <label className='sm-text light-grey'>{moment(newslist.created_datetime).format("DD-MM-YYYY")}</label>
                                    </div>
                                    <hr className='hr-mt-mb-7' />
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div >
        )
    }

}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(TataBuzzNews);