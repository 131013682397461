import React from 'react'
import { useState } from 'react'
import LocalConveyanceSummary from './LocalConveyanceSummary'
import AdminApproval from './AdminApproval'
import BDCGeneration from './BDCGeneration'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import BDCHistory from './BDCHistory'

function LocalConveyanceHomeScreen() {
  const [loading, setLoading] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const tabs = [
    { name: "Admin Approval", component: <AdminApproval /> },
    { name: "BDC Generation", component: <BDCGeneration /> },
    { name: "Claim Summary", component: <LocalConveyanceSummary /> },
    { name: "BDC History", component: <BDCHistory /> },
  ]
  const toggleLocalTabs = (index) => {
    setActiveTabIndex(index)
  }

  return (
    <>
      <div className="row pb-0 pt-12px px-12px">
        <LoaderIndicator loading={loading} />
        <div className='col-md-12 justify-content-center align-item-center px-0'>
          <div className="col-md-12 card-new m-rem h-83vh">
            <div className='row mt-3 px-3 ss col-md-12'>
              {tabs.map((tab, index) => {
                return (
                  <div className={index == activeTabIndex ? "tab-active" : "tab-inactive"} onClick={() => toggleLocalTabs(index)}>
                    {tab.name}
                  </div>
                )
              })}
            </div>
            <div className='mt-3px'><hr /></div>
            <div className='w-100'>
              {tabs[activeTabIndex].component}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LocalConveyanceHomeScreen