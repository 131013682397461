import React, { Component } from 'react'
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import moment from 'moment';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import { delete_tata_motors_tv_video_by_admin, headers } from '../../../../../constants/Config'
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import { toast } from 'react-toastify';
import { requestFailed } from '../../../../../constants/Messages'
import { connect } from 'react-redux';
class TatamotorsTVDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tempTVData: [],
            tempTVID: '',
            viewPopup: false,
            showDelete: false,
        }
    }

    deleteTV = () => {
        this.setState({
            loading: true,
            showDelete: false
        });

        let body = {
            is_active: false,
            tata_motors_video_id: this.state.tempTVID
        }
        console.log("deleteTV body:", body);
        fetchApiCall(delete_tata_motors_tv_video_by_admin, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.props.callTV()
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    onAction = (listValue, type) => {
        console.log("TatamotorsTVDashboard listValue:",listValue)
        if (type === "view") {
            this.setState({
                viewPopup: true,
                tempTVData: listValue
            })
        }
        else if (type === "delete") {
            // console.log("TatamotorsTVDashboard listValue for delete:",listValue)
            this.setState({
                showDelete: true,
                tempTVID: listValue.tata_motors_video_id
            })
        }
    }

    render() {
        // console.log("this.props.tatamotorsTvData:", this.props.tatamotorsTvData)
        // console.log("this.props dash:",this.props);
        return (
            <div className=" pt-2 tvNotification-container" >
                <LoaderIndicator loading={this.props.loading} />
                {
                    this.props.tatamotorsTvData.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" className='w-7'><div className='thead '>Sr. No</div></th>
                                    <th scope="col" className='w-25'><div className='thead'>Title</div></th>
                                    {/* <th scope="col" ><div className='thead'>Description</div></th> */}
                                    <th scope="col" className='w-30'><div className='thead'>Attachment</div></th>
                                    <th scope="col" ><div className='thead'>Created Date</div></th>
                                    <th scope="col" ><div className='thead text-align-center'>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.tatamotorsTvData.data.map((listValue, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td><div className="td " >{index + 1}</div></td>
                                                <td className='w-25'><div className="td auto-line-break" >{listValue.tata_motors_video_title ? listValue.tata_motors_video_title : ''}</div></td>
                                                {/* <td className='w-40'><div className="td auto-line-break" >{listValue.tata_motors_video_description ? listValue.tata_motors_video_description : ''}</div></td> */}
                                                <td className='w-30'><div className="td auto-line-break" >{listValue.tata_motors_video_name ? listValue.tata_motors_video_name : ''}</div></td>
                                                <td className='w-20'><div className="td auto-line-break" >{listValue.created_datetime ? moment(listValue.created_datetime).format("DD-MM-YYYY") : ''}</div></td>
                                                <td className='d-flex align-item-center-noflex justify-content-center-noflex'>
                                                    <div
                                                        onClick={() => this.onAction(listValue, 'view')}
                                                    >
                                                        <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                    </div>
                                                    <div
                                                        onClick={() => this.onAction(listValue, 'delete')}
                                                    >
                                                        <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            <tr className='text-align-center'>
                                <th colSpan='7' scope="col" className='w-5 text-align-center '>
                                    <div className='row text-align-center justify-content-center'>
                                        {
                                            this.props.currentPage != 1 ?
                                                <div className='thead pr-0 pre-next pointer'
                                                    onClick={() => this.props.parentCallback("decrease")}
                                                >
                                                    {" < "}</div>
                                                :
                                                <div className='thead pr-0 pre-next pointer' >{" < "}</div>
                                        }
                                        <div className='thead pr-0 pre-next purple'>{this.props.currentPage}</div>
                                        {
                                            this.props.total_page > this.props.currentPage ?
                                                <div className='thead pr-0 pre-next pointer'
                                                    onClick={() => this.props.parentCallback("increase")}
                                                >
                                                    {" > "}</div>
                                                :
                                                <div className='thead pr-0 pre-next pointer'>{" > "}</div>
                                        }
                                    </div>
                                </th>
                            </tr>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No TV details have been added yet!</label>
                                </div>
                            </div>
                        </div>
                }
                 {this.state.viewPopup &&
                    <NotificationDetailsPopup
                        type={"tataTVDetails"}
                        title={"Tata Motors TV Details"}
                        data={this.state.tempTVData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                 {this.state.showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteTataTV'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Tata motors TV details ?'}
                        deleteTV={() => this.deleteTV()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
      loginData: state.loginState,
    }
  }
export default connect(mapStatesToProps)(TatamotorsTVDashboard)