import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import delete_icon from '../../../../assets/images/cfm/delete_icon.svg';
import ConfirmFormPopup from '../conflictOfInterest/ConfirmFormPopup';
import { coi_type } from '../../../../utils/Utils';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import infoicon from '../../../../assets/images/cfm/infoicon.svg';
import * as commonMessage from '../../../../constants/Messages';
import { connect } from 'react-redux';

const optionB = '“I do not have any actual or potential conflict of interest situation to declare”'
const tata_Code_Of_Conduct_Policy = 'https://www.tata.com/content/dam/tata/pdf/Tata%20Code%20Of%20Conduct.pdf'
const coiPolicy = 'https://tatamotors.sharepoint.com/:w:/r/sites/c-app/s-MTMDev/_layouts/15/Doc.aspx?sourcedoc=%7B731F497A-EAC6-4BE2-82F2-1CF48EEA41D2%7D&file=Conflict%20of%20interest%20policy%20_V1.docx&action=default&mobileredirect=true&cid=8f3ef21e-b159-44c8-86ee-800f2cfdcdde'
class DashboardScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showConfirm: false,
            openDetail: false,
            listType: '',
            selectedData: {},
            a1_details: [],
            a2_details: [],
            a3_details: [],
            count_data: [],
            typeData: coi_type(),
            type_desciption: '',
            selected_data: {},
            is_coi: false,
            is_last_record: false,
            date: ''
        }
    }

    componentDidMount() {
        this.setState({
            listType: 'A1',
            type_desciption: this.state.typeData[0].value + this.props.loginData.company.company_sht_txt + '.'
        })
        this.isCOIDataPresent()
    }

    isCOIDataPresent = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.isDataPresent, {
            emp_id: this.props.loginData.userData.Perno
        }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        a1_details: res.data.a1_details ? res.data.a1_details : [],
                        a2_details: res.data.a2_details ? res.data.a2_details : [],
                        a3_details: res.data.a3_details ? res.data.a3_details : [],
                        count_data: res.data.count_data ? res.data.count_data : [],
                        is_coi: res.data.is_coi,
                        date: res.data.date ? res.data.date : '',
                        is_last_record: res.data.is_last_record ? res.data.is_last_record : false
                    });
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }

    deleteCOIRequest = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.deleteCOIRequest, {
            form_option: this.state.listType,
            emp_id: this.props.loginData.userData.Perno,
            unique_id: this.state.selected_data.unique_id,
            emp_pa: this.props.loginData.userData.PersArea,
            emp_psa: this.props.loginData.userData.PSubarea,
            emp_region: this.props.loginData.userData.RegCod,
            company_code: this.props.loginData.userData.CompCode,
            organization_unit: this.props.loginData.userData.Orgtx,
            emp_esubgroup: this.props.loginData.userData.Esubgroup
        }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                        showConfirm: false,
                    },
                        () => this.isCOIDataPresent()
                    );
                    if (this.state.is_last_record) {
                        window.location.reload()
                    }
                }
                if (res.data.status_code == 500) {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
                else {
                    this.setState({ loading: false, showConfirm: false })
                }
            }).catch(err => {
                toast.error(err, { hideProgressBar: true })
                this.setState({ loading: false })
                toast.error(commonMessage.requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    handleClick = (type) => {
        this.setState({ listType: type })
        if (type === 'A1') {
            this.setState({ type_desciption: this.state.typeData[0].value + this.props.loginData.company.company_sht_txt + '.' })
        }
        else if (type === 'A2') {
            this.setState({ type_desciption: this.state.typeData[1].value })
        }
        else if (type === 'A3') {
            this.setState({ type_desciption: this.state.typeData[2].value })
        }
    }

    COIData = (data, action) => {
        this.setState({ selected_data: data })
        if (action === 'delete') {
            this.setState({ showConfirm: true })
        }
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    toggleOpenDetail = (type) => {
        if (type === 'education') {
            this.setState({
                openDetail: !this.state.openDetail,
            })
        }
        else return null
    }

    toggleClose = () => {
        this.setState({
            openDetail: !this.state.openDetail,
            isEdit: !this.state.isEdit,
        })
    }

    render() {
        const { listType, showConfirm, is_coi } = this.state
        // console.log("loginData == == ", this.props.loginData.company.company_sht_txt)
        return (
            <div className='col-md-12 justify-content-center align-item-center px-0'>
                <LoaderIndicator loading={this.state.loading} />
                {is_coi ?
                    <div className='coi-card bg-white'>
                        <div className='justify-content-between mt-3'>
                            <div className='justify-content-around'>
                                <label className='card-heading mx-4 mt-1'>Conflict of Interest</label>
                                <div>
                                    <img
                                        alt="infoicon"
                                        src={infoicon}
                                        className="info_icon"
                                        onClick={() => this.props.openDeclarationForm()}
                                    ></img>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='row'>
                                    <a href={coiPolicy} target="_blank">
                                        <label
                                            className='tata-policy-link mr-4'
                                            type='button'
                                        >COI Policy</label>
                                    </a>
                                </div>
                                <div className='horizontal-lines mr-4'></div>
                                <div className='row'>
                                    <a href={tata_Code_Of_Conduct_Policy} target="_blank">
                                        <label
                                            className='tata-policy-link mr-4'
                                            type='button'
                                        >Tata Code Of Conduct Policy</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            {this.state.count_data.map((list, i) => {
                                return (
                                    <div
                                        key={i}
                                        className='mt-3 px-3'
                                        onClick={() => this.handleClick(list.title)}
                                    >
                                        <div className='row'>
                                            <div className={list.title === this.state.listType ? "tab-active" : "tab-inactive"}>
                                                <div
                                                    className="align-item-center mt-1 mb-1">
                                                    Option {list.title}
                                                    <div className='row justify-content-center align-item-center'>{' (' + list.value + ')'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <div className="mx-4">
                                <div className="row d-flex dashboard-options-back align-item-center align-item-center">
                                    <div className="font-weight-bold text-left text_heading ml-2">
                                        {this.state.listType} -
                                    </div>
                                    <div className='smallest_text ml-1'>
                                        {this.state.type_desciption}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 mx-4">
                                <div>
                                    <div className='ml-5 mt-3 mb-3'>
                                        <div
                                            type='button'
                                            className='flex_2 row add-btn-text d-flex justify-content-end'
                                            onClick={() => this.props.addNewForm(this.state.listType)}
                                        > + Add New Form
                                        </div>
                                    </div>
                                    {this.state.a1_details.length != 0 && listType === 'A1' ?
                                        <div className='coiDashboardHeight'>
                                            {this.state.a1_details.map((listValue, index) => {
                                                return (
                                                    <div key={index} className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                        <div className='d-flex justify-content-between align-item-center'>
                                                            <div className='toggle-card-heading ml-2'>
                                                                {listValue.relative_full_name}
                                                            </div>
                                                            {this.state.openDetail ?
                                                                <div
                                                                    className='justify-content-end'
                                                                    onClick={() => this.toggleOpenDetail('education')}>
                                                                    <img alt="hide" src={up_arrow} className="up_arrow"></img>
                                                                </div>
                                                                :
                                                                <div className='justify-content-end'
                                                                    onClick={() => this.toggleOpenDetail('education')}>
                                                                    <img alt="hide" src={down_arrow} className="hide_icon"></img>
                                                                </div>
                                                            }
                                                        </div>
                                                        {this.state.openDetail &&
                                                            <div>
                                                                <div className='hz-lines mt-2 mb-2'></div>
                                                                <div className='row justify-content-between'>
                                                                    <div className='p-heading mx-2 mt-2 mb-2'>Conflict of Interest Details - Option {this.state.listType}</div>
                                                                    <div
                                                                        className='mr-2'
                                                                        onClick={() => this.COIData(listValue, 'delete')}
                                                                    >
                                                                        <img
                                                                            alt="delete_icon"
                                                                            src={delete_icon}
                                                                            className="up_arrow"
                                                                        ></img>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className='row'>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Personnel No.</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.relative_perno}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Full Name</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.relative_full_name}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Business Unit</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.relative_business_unit}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Relationship</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.relationship}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Does Relationship Exist</div>
                                                                            <div className='dropdown'>
                                                                                <input
                                                                                    className='form-control p-placeholder px-3'
                                                                                    type="text"
                                                                                    value={listValue.is_reporting_relationship ? 'Yes' : 'No'}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Relative Working Since</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.relative_joining_date ? listValue.relative_joining_date : '-'}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Disclosed in TML</div>
                                                                            <div className='p-placeholder-text'>
                                                                                <input
                                                                                    className="form-control p-placeholder px-3"
                                                                                    type="text"
                                                                                    value={listValue.is_disclosed ? 'Yes' : 'No'}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-4 mb-3'>
                                                                            <div className='p-sub-heading ml-3'>Who and When</div>
                                                                            <div className='dropdown'>
                                                                                <input
                                                                                    className='form-control p-placeholder px-3'
                                                                                    type="text"
                                                                                    value={listValue.disclosure_description ? listValue.disclosure_description : '-'}
                                                                                    readOnly={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        :
                                        this.state.a2_details.length != 0 && listType === 'A2' ?
                                            <div className='coiDashboardHeight'>
                                                {this.state.a2_details.map((listValue, index) => {
                                                    return (
                                                        <div key={index} className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                            <div className='d-flex justify-content-between align-item-center'>
                                                                <div className='toggle-card-heading ml-2'>
                                                                    {listValue.relative_full_name}
                                                                </div>
                                                                {this.state.openDetail ?
                                                                    <div
                                                                        className='justify-content-end'
                                                                        onClick={() => this.toggleOpenDetail('education')}>
                                                                        <img alt="hide" src={up_arrow} className="up_arrow"></img>
                                                                    </div>
                                                                    :
                                                                    <div className='justify-content-end'
                                                                        onClick={() => this.toggleOpenDetail('education')}>
                                                                        <img alt="hide" src={down_arrow} className="hide_icon"></img>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {this.state.openDetail &&
                                                                <div>
                                                                    <div className='hz-lines mt-2 mb-2'></div>
                                                                    <div className='row justify-content-between'>
                                                                        <div className='p-heading mx-2 mt-2 mb-2'>Conflict of Interest Details - Option {this.state.listType}</div>
                                                                        <div
                                                                            className='mr-2'
                                                                            onClick={() => this.COIData(listValue, 'delete')}
                                                                        >
                                                                            <img
                                                                                alt="delete_icon"
                                                                                src={delete_icon}
                                                                                className="up_arrow"
                                                                            ></img>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='row'>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Full name of related person</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.relative_full_name}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Relative's company name</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.relative_comp_name}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Relative's company address</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.relative_comp_add}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Nature of interest</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.nature_of_interest}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Relationship</div>
                                                                                <div className='dropdown'>
                                                                                    <input
                                                                                        className='form-control p-placeholder px-3'
                                                                                        type="text"
                                                                                        value={listValue.relationship}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Does working relationship exist</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.is_working_relationship_exist ? 'Yes' : 'No'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Relative working since?</div>
                                                                                <div className='p-placeholder-text'>
                                                                                    <input
                                                                                        className="form-control p-placeholder px-3"
                                                                                        type="text"
                                                                                        value={listValue.relative_joining_date ? listValue.relative_joining_date : '-'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>Was this disclosed?</div>
                                                                                <div className='dropdown'>
                                                                                    <input
                                                                                        className='form-control p-placeholder px-3'
                                                                                        type="text"
                                                                                        value={listValue.is_disclosed ? 'Yes' : 'No'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-4 mb-3'>
                                                                                <div className='p-sub-heading ml-3'>whom and when?</div>
                                                                                <div className='dropdown'>
                                                                                    <input
                                                                                        className='form-control p-placeholder px-3'
                                                                                        type="text"
                                                                                        value={listValue.disclosure_description ? listValue.disclosure_description : '-'}
                                                                                        readOnly={true}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            :
                                            this.state.a3_details.length != 0 && listType === 'A3' ?
                                                <div className='coiDashboardHeight'>
                                                    {this.state.a3_details.map((listValue, index) => {
                                                        return (
                                                            <div key={index} className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                                <div className='d-flex justify-content-between align-item-center'>
                                                                    <div className='toggle-card-heading ml-2'>
                                                                        {listValue.relative_full_name}
                                                                    </div>
                                                                    {this.state.openDetail ?
                                                                        <div
                                                                            className='justify-content-end'
                                                                            onClick={() => this.toggleOpenDetail('education')}>
                                                                            <img alt="hide" src={up_arrow} className="up_arrow"></img>
                                                                        </div>
                                                                        :
                                                                        <div className='justify-content-end'
                                                                            onClick={() => this.toggleOpenDetail('education')}>
                                                                            <img alt="hide" src={down_arrow} className="hide_icon"></img>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {this.state.openDetail &&
                                                                    <div>
                                                                        <div className='hz-lines mt-2 mb-2'></div>
                                                                        <div className='row justify-content-between'>
                                                                            <div className='p-heading mx-2 mt-2 mb-2'>Conflict of Interest Details - Option {this.state.listType}</div>
                                                                            <div
                                                                                className='mr-2'
                                                                                onClick={() => this.COIData(listValue, 'delete')}
                                                                            >
                                                                                <img
                                                                                    alt="delete_icon"
                                                                                    src={delete_icon}
                                                                                    className="up_arrow"
                                                                                ></img>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className='row'>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Complete name of the value-chain partner/customer/competitor</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.name}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Location of the value-chain partner/customer/competitor</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.location}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Type of association/interest</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.association_type}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Associated since</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.associated_since ? listValue.associated_since : '-'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Have you been interacting with the value-chain partner/customer/competitor?</div>
                                                                                    <div className='dropdown'>
                                                                                        <input
                                                                                            className='form-control p-placeholder px-3'
                                                                                            type="text"
                                                                                            value={listValue.is_interacting ? 'Yes' : 'No'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Have you received any financial or non-financial benefit due to this relationship?</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.is_disclosed ? 'Yes' : 'No'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div> */}
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Was this disclosed?</div>
                                                                                    <div className='p-placeholder-text'>
                                                                                        <input
                                                                                            className="form-control p-placeholder px-3"
                                                                                            type="text"
                                                                                            value={listValue.is_disclosed ? 'Yes' : 'No'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>whom and when?</div>
                                                                                    <div className='dropdown'>
                                                                                        <input
                                                                                            className='form-control p-placeholder px-3'
                                                                                            type="text"
                                                                                            value={listValue.disclosure_description ? listValue.disclosure_description : '-'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Full name of related person</div>
                                                                                    <div className='dropdown'>
                                                                                        <input
                                                                                            className='form-control p-placeholder px-3'
                                                                                            type="text"
                                                                                            value={listValue.relative_full_name ? listValue.relative_full_name : '-'}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-4 mb-3'>
                                                                                    <div className='p-sub-heading ml-3'>Relationship</div>
                                                                                    <div className='dropdown'>
                                                                                        <input
                                                                                            className='form-control p-placeholder px-3'
                                                                                            type="text"
                                                                                            value={listValue.relationship}
                                                                                            readOnly={true}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='coi-card bg-white px-0'>
                        <div className='justify-content-between mt-3'>
                            <label className='card-heading mx-4 mt-1'>Conflict of Interest</label>
                            <div className='row'>
                                <div className='row'>
                                    <a href={coiPolicy} target="_blank">
                                        <label
                                            className='tata-policy-link mr-4'
                                            type='button'
                                        >COI Policy</label>
                                    </a>
                                </div>
                                <div className='horizontal-lines mr-4'></div>
                                <div className='row'>
                                    <a href={tata_Code_Of_Conduct_Policy} target="_blank">
                                        <label
                                            className='tata-policy-link mr-4'
                                            type='button'
                                        >Tata Code Of Conduct Policy</label>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='horizontal-lines mt-3'></div>
                        <div className='d-flex row'>
                            <div className='dashboard-options-back mx-3 mt-4'>
                                You have declared at {moment(this.state.date).format("DD-MM-YYYY")} that {optionB}
                            </div>
                            <div className='ml-5 mt-3 mb-3'>
                                <div
                                    type='button'
                                    className='flex_2 row add-btn-text'
                                    onClick={() => this.props.restartCOI()}
                                > + Add New Form
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {showConfirm &&
                    <ConfirmFormPopup
                        action={'delete'}
                        is_last_record={this.state.is_last_record}
                        deleteData={this.deleteCOIRequest}
                        onDismiss={this.onDismiss}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(DashboardScreen);