import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import '../../../../assets/styles/basic.css';
import '../../../../assets/styles/popupStyles.css';
import { bankDetails, dependentNote, bankDetailsNote } from '../../../../constants/Messages';
class NotePopup extends Component {
    onClickApplyButton = () => {
        this.props.onDismiss();
    }
    render() {
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    {
                        this.props.type === 'dependentTypeNote' ?
                            <div>
                                <div className="modal-header">
                                    Note
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={this.props.onDismiss}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='p-10'>
                                        <div className='performance-timeline-text-lighter'>{dependentNote}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className="modal-header">
                                    Note
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        onClick={this.props.onDismiss}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='p-10 mb-10px'>
                                        <div className='performance-timeline-text-lighter'>{bankDetails}</div>
                                        <div className='performance-timeline-text-lighter'>{bankDetailsNote}</div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>

        );
    }
}

export default NotePopup;