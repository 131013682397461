import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { requestFailed } from '../../../../constants/Messages';

class UpcomingOccasions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: '',
            description: '',
            fromDate: '',
        }
    }

    validate = () => {
        if (this.state.title === '') {
            toast.warning('Please enter title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.title.length >= 100) {
            toast.warning('Please enter title less than 100 characters', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.fromDate === '') {
            toast.warning('Please select date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertUpcomingEvents()
        }
    }

    insertUpcomingEvents = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_title: this.state.title,
            event_date: this.state.fromDate,
            emp_mail: this.props.adminLoginData.adminData.user_email
        }
        fetchApiCall(Config.insertUpcomingEvents, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                        this.props.allDismiss(),
                    )
                    this.props.upcomingOccasionActive()
                    this.setState({ loading: false })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    },
                        this.props.allDismiss(),
                    )
                    this.props.upcomingOccasionActive()
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div>
                    <form className='todoModalBody popup_scroller w-100'>
                        <div className='p-1 w-100'>
                            <div>
                                <div>
                                    <div className='p-sub-heading ml-3'>Title</div>
                                    <div className='p-placeholder-text mb-2'>
                                        <input
                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                            type="text"
                                            readOnly={this.state.show}
                                            onChange={(e) => this.setState({ title: e.target.value })}
                                            value={this.state.title}
                                            placeholder={"Enter the title"}
                                            maxLength={256}
                                        />
                                    </div>
                                    <div className='mb-3 mt-2'>
                                        <div className='p-sub-heading ml-3'>Start Date</div>
                                        <div className='p-placeholder-text'>
                                            <input
                                                className="form-control p-placeholder p-placeholder-admin px-2"
                                                type="date"
                                                value={this.state.fromDate}
                                                onChange={(e) => this.setState({ fromDate: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='modal-footer btnBox justify-content-between'>
                        <div className='justify-content-start'>
                            <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                        </div>
                        <div className='justify-content-around'>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='CANCEL'
                                    className='popup-back-btn popup-back-text'
                                    onClick={this.props.back}
                                />
                            </div>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='ADD'
                                    className='popup-confirm-btn'
                                    onClick={this.validate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(UpcomingOccasions);