import React, { Component } from 'react'
import './HomeScreen.css'
import { connect } from "react-redux";
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { getTestimony } from './Gems.api.service';
import { toaster } from '../../../../utils/Utils';
import NewNotdata from '../../../shared/notFound/NewNoData';
import whitecross from '../../../../assets/drishti_images/white-cross.svg';
import default_profile from '../../../../assets/drishti_images/profile/default_profile.svg';

const testimonialWordCount = 559
class GEMSTestimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            usersData: [],
            clickImage: null
        }
    }

    componentDidMount() {
        this.getTestimonyDetails()
    }

    getTestimonyDetails = () => {
        this.setState({ loading: true })
        const param = {}
        getTestimony(param, this.props)
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        usersData: res?.data?.data
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        usersData: []
                    })
                    toaster("", res.message ? res.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    usersData: []
                })
                toaster("", "Something went wrong")
                console.log("getTestimonyDetails", err)
            })
    }

    viewImage = (img) => {
        this.setState({
            clickImage: img
        })
    }

    render() {
        const { usersData, loading } = this.state;
        return (
            <div className='landing-profile mb-2'>
                <LoaderIndicator loading={loading} />
                <div>
                    {usersData?.length !== 0 ?
                        <div className='scroll-y h-64vh'>
                            {usersData?.map((listValue, index) =>
                                <div key={index} className='card-inner p-5px m-5px mb-10px'>
                                    <div className='container-landing-details mt-2'>
                                        <span className='justify-content-center align-item-center-noflex'>
                                            <img
                                                alt="avatar"
                                                src={listValue.profile_image ? listValue.profile_image : default_profile}
                                                onClick={() => this.viewImage(listValue?.profile_image)}
                                                className="profile-header"
                                                data-toggle="modal"
                                                data-target="#exampleModalCenter"
                                            ></img>
                                        </span>
                                        <span className='flex-direction-column'>
                                            <span className='coi-text'>
                                                {listValue.emp_name}
                                            </span>
                                            <span className='highlight-sm-Text'>
                                                Function- {listValue.function}
                                            </span>
                                            <span className='highlight-sm-Text'>
                                                Org Unit -{listValue.org_unit}
                                            </span>
                                        </span>
                                    </div>
                                    <div className='mt-2 mb-1'><hr /></div>
                                    <div className={listValue.testimony.length > testimonialWordCount ? 'noteTextMsg ml-4 scroll-auto h-28vh' : 'noteTextMsg ml-4'}>
                                        {listValue.testimony}
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <span className='d-flex justify-content-center align-item-center mt-50p'>
                            <NewNotdata />
                        </span>
                    }
                </div>
                {/* // view full size image */}
                <div className="modal fade" id="exampleModalCenter" tabIndex={"-1"} role="" >
                    <div className="modal-dialog modal-dialog-centered" role="">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='close-btn-styles'>
                                    <img src={whitecross} alt="" className="" data-dismiss="modal" aria-label="Close" />
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <img src={this.state.clickImage} alt="" className="model-img-gems" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    };
};

export default connect(mapStatesToProps, "")(GEMSTestimonial);