import React, { Component } from 'react';
import edit from '../../../../../assets/images/cfm/edit.svg';
import { validateName, validateNumber } from '../../../../../utils/Utils';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { aadharHolder, aadharNumber, aadharUpload, aadharHolderPlaceHolder, aadharCardNumberPlaceHolder, aadharUploadPlaceHolder } from '../../../../../constants/Messages'
import * as profileActions from '../../profile/ProfileAction';
import DeclarationPopup from '../../profile/DeclarationPopup'
class Adhar extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            isEditAdhar: false,
            popupVisibility: false,
            aadharName: '',
            aadharNumber: '',
            aadharFileName: '',
            aadharFileContent: ''
        }
    }
    componentDidMount() {
        this.getAadharDetails(this.props.loginData.userData.Perno)
    }
    getAadharDetails(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getAadharDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    this.props.aadharActions.getAadharDetails(res.data.aadhar_details);

                    this.setState({
                        aadharName: res.data.aadhar_details.name_aadhar ? res.data.aadhar_details.name_aadhar : '',
                        aadharNumber: res.data.aadhar_details.aadhar_number ? res.data.aadhar_details.aadhar_number : '',
                        aadharFileName: res.data.aadhar_details.aadhar_file_name ? res.data.aadhar_details.aadhar_file_name : '',
                        aadharFileContent: res.data.aadhar_details.aadhar_attachment ? res.data.aadhar_details.aadhar_attachment : '',
                        loading: false
                    });

                } else {
                    this.setState({
                        loading: false
                    });
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error('Unable to contact server\nPlease try again later', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    saveAadharDetails = () => {
        this.setState({
            loading: true,
            popupVisibility: false
        });
        let req = {
            emp_id: this.props.loginData.userData.Perno,
            aadhar_number: this.state.aadharNumber ? this.state.aadharNumber : '',
            name_aadhar: this.state.aadharName ? this.state.aadharName : '',
            aadhar_file_name: this.state.aadharFileName ? this.state.aadharFileName : '',
            aadhar_attachment: {
                base64: this.state.aadharFileContent ? this.state.aadharFileContent : ''
            }
        };
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updateAadharDetails, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        isEditAdhar: false,
                        popupVisibility: false,
                        loading: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getAadharDetails(this.props.loginData.userData.Perno)

                } else {
                    this.setState({
                        isEditAdhar: false,
                        popupVisibility: false,
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error('Unable to contact server\nPlease try again later', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    uploadAdhar = (event) => {
        const fileType = event.target.files[0].type
        this.setState({ aadharFileName: event.target.files[0] ? event.target.files[0].name : '' })
        if (fileType == 'application/pdf' || fileType == 'image/jpeg' || fileType == 'image/png') {
            if (event.target.files && event.target.files[0]) {
                let maxAllowedSize = 1048576 * 2;           // 2MB size
                if (event.target.files[0].size <= maxAllowedSize) {
                    let reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => { this.setState({ aadharFileContent: e.target.result }) }
                }
                else {
                    toast.error('Please upload pdf less than 2MB', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ aadharFileContent: '' })
                }
            }
            else { this.setState({ aadharFileContent: '' }) }
        }
        else {
            toast.error('Please upload pdf/jpeg/png files only ', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
            this.setState({ aadharFileContent: '' })
        }

    }
    validateAadharData = () => {
        if (this.state.aadharNumber == '' || this.state.aadharNumber.length < 12) {
            toast.error('Please enter valid aadhar number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.aadharName == '') {
            toast.error('Please enter aadhar name', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if ((this.state.aadharNumber === this.props.aadharData.aadharData.aadhar_number) && (this.state.aadharName === this.props.aadharData.aadharData.name_aadhar)) {
            toast.error('Cannot update same data', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.showModal();
        }
    }
    showModal = () => {
        this.setState({ popupVisibility: true });
    }
    render() {
        const { isEditAdhar } = this.state
        return (
            <div className="">
                <LoaderIndicator loading={this.state.loading} />
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>Aadhaar Card Details </div>
                    {!this.state.isEditAdhar &&
                        <div className='mt-2 mr-3 pointer'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setState({ isEditAdhar: true })}
                            ></img>
                        </div>
                    }
                </div>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>{aadharHolder}</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={this.state.aadharName}
                                    maxLength="58"
                                    readOnly={!isEditAdhar}
                                    placeholder={aadharHolderPlaceHolder}
                                    onChange={(e) => this.setState({
                                        aadharName: e.target.value ? validateName(e.target.value) : ''
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>{aadharNumber}</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    maxLength="12"
                                    value={this.state.aadharNumber}
                                    placeholder={aadharCardNumberPlaceHolder}
                                    readOnly={!isEditAdhar}
                                    onChange={(e) => {
                                        this.setState({
                                            aadharNumber: e.target.value ? validateNumber(e.target.value) : ''
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>{aadharUpload}</div>
                            <div className='p-placeholder-text'>
                                {isEditAdhar ?
                                    <input
                                        className="form-control p-placeholder px-3 paddingTop-11"
                                        type="file"
                                        name='business_no'
                                        readOnly={!isEditAdhar}
                                        placeholder={aadharUploadPlaceHolder}
                                        onChange={this.uploadAdhar}
                                        accept=".png,.pdf,.jpeg"
                                    />
                                    :
                                    <input
                                        type="text"
                                        readOnly={!isEditAdhar}
                                        className="form-control p-placeholder px-3"
                                        value={this.state.aadharFileName}
                                    />
                                }
                            </div>
                            {isEditAdhar ? <div className='p-sub-heading ml-3'>(Single file of 2MB to be uploaded.)</div> : null}
                        </div>
                    </div>
                    {isEditAdhar &&
                        <div className='row justify-content-end'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.setState({ isEditAdhar: false })}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={this.validateAadharData}
                                >Save</div>
                            </div>
                        </div>}
                    <div className='hz-lines mt-4 mb-2 mr-2 ml-2'></div>
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.saveAadharDetails}
                    />
                }

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        aadharData: state.profileState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        aadharActions: bindActionCreators(profileActions, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(Adhar));