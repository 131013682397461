import React, { Component } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import right_button_arrow from '../../../../assets/drishti_images/right-button-arrow.svg'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import ConfirmPopup from '../../managerScreens/ConfirmPopup';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import NewNotdata from '../../../shared/notFound/NewNoData';
import { checkapprovalstatus, getAttachmentList, getExpenseList, patchClaimApproval } from '../../../../constants/api.service'
import { readOnlyLabel, employeeDetailsForm, constantValues, employeeDetailsFormObj, expepnseTypeLabel, employeeDetails, checkdropDownLabel } from './constant'
import './LocalConveyanceApporval.css'
import '../../employeeScreens/profile/ProfileScreen.css'
import { connect } from 'react-redux';
import DetailExpensePopUp from './DetailExpensePopUp';
import moment from 'moment';
import { toaster } from '../../../../utils/Utils';

const rejectNote = 'Are you sure you want to reject the claim request ?'
const approveNote = 'Are you sure you want to approve the claim request ?'
const listOfProjectDetails = ["CompName", "travel_type", "from_date", "to_date", "claim_status"]
const listOfProjectDtlKeys = { CompName: "Applicant Name :", travel_type: 'Travel Type :', from_date: "From Date :", to_date: "To Date :", claim_status: "Claim Detail status :" }
let tempemployeeDetailData = []
let tempDetailsData = []
class LocalConveyance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            statusValue: 'Pending',
            showConveyanceList: true,
            showSelectedConveyance: '',
            showDetailsPage: false,
            isConformationPopUpOpen: false,
            isRejectReason: false,
            isMislinious: '',
            EmployeeData: {},
            ManagerData: [],
            managerDropDownData: {},
            selectedUploadBill: {},
            attachmeantData: {},
            openStandardCard: false,
            managerSelected: false,
            isDetailsExpense: { isOpen: false, data: {} },
            totalPages: 0,
            pageNumber: 1,
            paginatedData: [],
            isShowApprovalStatus: this.props.isShowApprovalStatus,
            expenseDetails: [],
            billToggles: [],
            setReject: "",
            setApproveNote: ''
        }
    }

    componentDidMount = () => {
        this.onResetLabel()
    }

    onLabelCalling = () => {
        let checkVarType = employeeDetailsFormObj
        for (let i = 0; i < Math.ceil(checkVarType.length / 4); i++) {
            let arr = checkVarType.slice(i * 4, (i + 1) * 4)
            tempemployeeDetailData.push(arr)
        }

        let claimDetailsType = employeeDetails
        for (let i = 0; i < Math.ceil(claimDetailsType.length / 4); i++) {
            let arr = claimDetailsType.slice(i * 4, (i + 1) * 4)
            tempDetailsData.push(arr)
        }
    }

    onResetLabel = () => {
        tempemployeeDetailData = []
        tempDetailsData = []
    }

    goToDetailScreen = (dataValue, idx) => {
        this.setState({
            showConveyanceList: false,
            showSelectedConveyance: dataValue,
            showDetailsPage: true,
            EmployeeData: dataValue.emp,
            ManagerData: this.props?.managerData.length == 1 ? this.props.managerData : [],
        },
            this.onLabelCalling(),
            this.props.onShowApprovalStatus(false),
            this.getExpenseList(dataValue.claim_id, dataValue.emp.Perno)
        )
    }

    getExpenseList(claim_id, emp_id) {
        const param = {
            claim_id: claim_id,
            emp_id: emp_id,
        }
        this.setState({
            loading: true,
        })
        getExpenseList(param, this.props)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    let toggles = Array(res?.data?.data.length).fill(false)
                    this.setState({
                        loading: false,
                        expenseDetails: res?.data?.data,
                        billToggles: toggles
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        expenseDetails: [],
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    expenseDetails: [],
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    conformationPopUpOpen = (data, type) => {
        const param = {
            employee_id: data.emp.Perno,
            screen_type: 'DEM',
            from_date: data.from_date,
            to_date: data.to_date,
            travel_type: data.travel_type,
        }
        this.setState({
            loading: true,
        })
        checkapprovalstatus(param, this.props)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        isConformationPopUpOpen: true,
                        projectData: data,
                        setApproveNote: res?.data?.message
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        isConformationPopUpOpen: true,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                })
            });
    }

    conformationPopUpClose = () => {
        this.setState({
            isConformationPopUpOpen: false,
            setApproveNote: ''
        })
    }

    isRejectReasonOpen = () => {
        this.setState({
            isRejectReason: true,
        })
    }

    isRejectReasonClose = () => {
        this.setState({
            setReject: '',
            isRejectReason: false,
            showDetailsPage: true
        })
    }

    clearManagerData = () => {
        this.props.managerData.length > 1 && this.setState({
            ManagerData: [],
            managerSelected: false
        })
    }

    LocalConveyanceConfirmApporval = () => {
        this.setState({
            isConformationPopUpOpen: false,
            showDetailsPage: false,
            showConveyanceList: true,
            setApproveNote: ''
        }, () =>
            this.updateClaimApi('Approved', '')
        )
    }

    updateClaimApi = (action, remark) => {
        this.props.onShowApprovalStatus(true)
        this.setState({ loading: true })

        let body = {
            claim_status: this.state.showSelectedConveyance.claim_status,
            claim_action: action == "Approved" ? "Approve" : "Reject",
            rejection_remark: this.state.showSelectedConveyance.claim_status == checkdropDownLabel.pendingByDem || checkdropDownLabel.pendingByL3 && remark && remark != "" ? remark : null,
        }
        let claim_id = this.state?.showSelectedConveyance?.claim_id ? this.state?.showSelectedConveyance?.claim_id : null

        patchClaimApproval(body, this.props, claim_id)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    if (action == "Approved") {
                        this.clearManagerData()
                        this.onResetLabel()
                        this.props.onChangedApprovalStatus({ id: "1", key: 'PENDING_BY_My', title: 'Pending for my approvals' })
                    }
                    if (action == "Reject") {
                        this.clearManagerData()
                        this.onResetLabel()
                        this.props.onChangedApprovalStatus({ id: "1", key: 'PENDING_BY_My', title: 'Pending for my approvals' })
                    }
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        loading: false,
                        setReject: ''
                    })
                }
                else {
                    if (action == "Approved") {
                        this.onResetLabel()
                        this.clearManagerData()
                    }
                    if (action == "Reject") {
                        this.onResetLabel()
                        this.clearManagerData()
                    }
                    this.setState({
                        loading: false,
                        setReject: ''
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    setReject: ''
                })
                toaster("warning", error?.message ? error.message : "")
            });
    }

    LocalConveyanceRejectApporval = () => {
        this.setState({
            isRejectReason: false,
            showDetailsPage: false,
            showConveyanceList: true
        }, () =>
            this.updateClaimApi('Reject', this.state.setReject)
        )
    }

    changeNotificationType = (data) => {
        this.setState({
            ManagerData: [data],
            managerSelected: true
        })
    }

    selectedUploadBill = (data, index) => {
        this.setState({ selectedUploadBill: data })
    }

    onSetRejcetValue = (value) => {
        value.persist();
        this.setState({
            setReject: value.target.value
        })
    }

    getAttachmentList(emp_id, claim_id, chechExpenseType) {
        const param = {
            emp_id: emp_id,
            component_id: claim_id
        }
        this.setState({
            loading: true,
        })
        getAttachmentList(param, this.props)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        attachmeantData: res?.data?.data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        attachmeantData: [],
                    })
                    if (chechExpenseType.expense_type == 'Consolidated allowance' || chechExpenseType.expense_type == 'Incidental allowance') {
                    }
                    else {
                        toaster("warning", res.data.message ? res.data.message : "")
                    }
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    attachmeantData: [],
                })
                toaster("warning", error.message ? error.message : "")
            });
    }


    onDetailExpense = (data) => {
        this.getAttachmentList(this.state.EmployeeData.Perno, data.id, data)
        this.setState({
            isDetailsExpense: { isOpen: true, data: data }
        })
    }

    validateRejectBtn = () => {
        if (this.state.showSelectedConveyance?.is_miscellaneous == true) {
            console.log("CHECK Validation is ")
            if (this.state.showSelectedConveyance.claim_status == checkdropDownLabel.pendingByDem && this.state?.showSelectedConveyance?.is_dem == true) {
                if (this.state.ManagerData.length == 1 && this.state.showSelectedConveyance?.is_dem == true) {
                    return false
                }
                else if ((this.state?.ManagerData?.length === 0 && this.props.managerData.length === 0 && this.state.showSelectedConveyance?.is_dem == true)) {
                    return false
                }
                else if (this.state?.ManagerData?.length == 0 && this.props?.managerData?.length > 0 && this.state.showSelectedConveyance?.is_dem == true) {
                    return true
                }
            }
            else if (this.state.showSelectedConveyance.claim_status == checkdropDownLabel.pendingByL3 && this.state?.showSelectedConveyance?.is_l3 == true) {
                if (this.state.showSelectedConveyance != null) {
                    return false
                }
                else {
                    return true
                }
            }
        }
        else {
            return false
        }
    }

    render() {
        let { showSelectedConveyance } = this.state
        const statusList = ["Pending for Admin approval", 'Approved by Admin', 'Claim Approved & sent to Accountant for settlement', 'Claim Settled']

        return (
            <>
                <LoaderIndicator loading={this.state.loading} />
                <div className='h-60vh scroll-auto'>
                    {this.state.showConveyanceList && this.props?.paginatedData?.length !== 0 && this.props?.paginatedData != undefined ?
                        this.props?.paginatedData.map((dataValue, idx) =>
                            <div key={idx} className='document_card p-10px m-10px pointer flex-direction-row justify-content-between align-item-center-noflex'
                                onClick={() => this.
                                    goToDetailScreen(dataValue, idx)}
                            >
                                <span>
                                    <span className='text16_bold project-list-style mx-10px '>
                                        {dataValue?.claim_id}
                                    </span>
                                    <div className='flex-direction-row'>
                                        {listOfProjectDetails.map((key, idx) =>
                                            < span key={idx} className='mx-10px' >
                                                <span className='appcenter-app-name'>{listOfProjectDtlKeys[key]}&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{key == 'from_date' || key == 'to_date' ? moment(dataValue[key]).format('DD-MM-YYYY') : key == 'CompName' ? dataValue?.emp[key] : dataValue?.[key] ? dataValue[key] : "NA"}</span>
                                            </span>
                                        )
                                        }
                                    </div>
                                </span>
                                <span className='mr-10'>
                                    <img alt="go to details" className='' src={right_button_arrow} />
                                </span>
                            </div >
                        )
                        :
                        this.state.showConveyanceList && <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                            <NewNotdata />
                        </span>
                    }
                    {this.state.showConveyanceList && this.props.pageNumber < this.props.totalPages && this.props.totalPages > 1 &&
                        <div className='m-10px'>
                            <div
                                className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                onClick={() => this.props.morePage()}
                                style={{ cursor: 'pointer' }}
                            >
                                <label className='card-content-title white-color p-5px' >
                                    {"MORE"}
                                </label>
                            </div>
                        </div>
                    }
                    {this.state.showDetailsPage &&
                        <>
                            <div className='justify-content-between flex-direction-row ml-10px mt-10px mb-15px mr-20px'>
                                <span className='align-item-center'>
                                    <span onClick={() => this.setState({ showDetailsPage: false, showConveyanceList: true }, this.isRejectReasonClose(), this.clearManagerData(), this.onResetLabel(), this.props.onShowApprovalStatus(true))}>
                                        <img alt="Back" className='mr-10 pointer' src={backArrow} />
                                    </span>
                                    <span class="text1_25rem-bold">{this.state?.showSelectedConveyance ? this.state.showSelectedConveyance.claim_id : ''}</span>
                                </span>
                                {/* {
                                    this.props.managerData.length > 1 && this.state.showSelectedConveyance.is_dem == true && this.state?.showSelectedConveyance?.claim_status == checkdropDownLabel.pendingByDem &&
                                    < div className='align-item-center'>
                                        <label className='form-label mr-2'>{constantValues.SelectManager}</label>
                                        <Dropdown
                                            data={this.props.managerData}
                                            selectedValue={(data) => this.changeNotificationType(data)}
                                            value={this.state.ManagerData?.[0]?.CompName ? this.state.ManagerData[0].CompName : 'Select '}
                                            render={'managerName'}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                        />
                                    </div>
                                } */}
                            </div>
                            <div className='toggleStyle mt-1rem max-h-42vh scroll-auto'>
                                <>
                                    <div className='flex-direction-column mb-1 w-97'>
                                        <div class="m-2 card-heading w-97">{employeeDetailsForm.EmployeeDetails}</div>
                                        <>
                                            {tempemployeeDetailData.map((row) => {
                                                return (
                                                    < span className='pg-project-grid px-20px mt-1' >
                                                        {row.map((data) => {
                                                            return (
                                                                <span>
                                                                    <span className='appcenter-app-name1'>{data.title}</span>
                                                                    <span className='card-content-title1 ml-5px'>{this.state?.EmployeeData[data.key] ? this.state.EmployeeData[data.key] : "-"}</span>
                                                                </span>
                                                            )
                                                        })}
                                                    </span>
                                                )
                                            })
                                            }
                                        </>
                                    </div>
                                    <hr class="line-horizontal mt-1 mb-1"></hr>
                                    {/* 
                                    {this.props.managerData.length > 1 && this.state.managerSelected && this.state.showSelectedConveyance.is_dem == true &&
                                        <>
                                            <div className='flex-direction-column mb-1 w-97'>
                                                <div class="m-2 card-heading w-97">{employeeDetailsForm.ManagerDetails}</div>
                                                {tempemployeeDetailData.map((row) => {
                                                    return (
                                                        < span className='pg-project-grid px-20px mt-1' >
                                                            {row.map((data) => {
                                                                return (
                                                                    <span>
                                                                        <span className='appcenter-app-name1'>{data.title}</span>
                                                                        <span className='card-content-title1 ml-5px'>{this.state?.ManagerData?.[0]?.[data.key] ? this.state.ManagerData[0][data.key] : "-"}</span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                })
                                                }
                                            </div>
                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                        </>
                                    }
                                    {
                                        this.props.managerData.length == 1 && this.state.showSelectedConveyance?.is_dem == true &&
                                        <>
                                            <div className='flex-direction-column mb-1 w-97'>
                                                <div class="m-2 card-heading w-97">{employeeDetailsForm.ManagerDetails}</div>
                                                {tempemployeeDetailData.map((row) => {
                                                    return (
                                                        < span className='pg-project-grid px-20px mt-1' >
                                                            {row.map((data) => {
                                                                return (
                                                                    <span>
                                                                        <span className='appcenter-app-name1'>{data.title}</span>
                                                                        <span className='card-content-title1 ml-5px'>{this.props?.managerData[0][data.key] ? this.props.managerData[0][data.key] : "-"}</span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                })
                                                }
                                            </div>
                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                        </>
                                    }
                                */}
                                    {this.state.showSelectedConveyance != null && this.state.showSelectedConveyance?.is_miscellaneous == true && this.state.showSelectedConveyance?.is_l3 == true &&
                                        <>
                                            <div className='flex-direction-column mb-1 w-97'>
                                                <div class="m-2 card-heading w-97">{employeeDetailsForm.ManagerDetails}</div>
                                                {tempemployeeDetailData.map((row) => {
                                                    return (
                                                        < span className='pg-project-grid px-20px mt-1' >
                                                            {row.map((data) => {
                                                                return (
                                                                    <span>
                                                                        <span className='appcenter-app-name1'>{data.title}</span>
                                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.dem[data.key] ? this.state.showSelectedConveyance.dem[data.key] : "-"}</span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                })
                                                }
                                            </div>
                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                        </>
                                    }
                                    {this.state.showSelectedConveyance != null && this.state.showSelectedConveyance?.is_dem == true && this.state.showSelectedConveyance?.is_miscellaneous == true && this.state?.showSelectedConveyance?.misc_approver &&
                                        <>
                                            <div className='flex-direction-column mb-1 w-97'>
                                                <div class="m-2 card-heading w-97">{employeeDetailsForm.ManagerDetails}</div>
                                                {tempemployeeDetailData.map((row) => {
                                                    return (
                                                        < span className='pg-project-grid px-20px mt-1' >
                                                            {row.map((data) => {
                                                                return (
                                                                    <span>
                                                                        <span className='appcenter-app-name1'>{data.title}</span>
                                                                        <span className='card-content-title1 ml-5px'>{this.state?.showSelectedConveyance?.misc_approver?.[data.key] ? this.state.showSelectedConveyance.misc_approver[data.key] : "-"}</span>
                                                                    </span>
                                                                )
                                                            })}
                                                        </span>
                                                    )
                                                })
                                                }
                                            </div>
                                            <hr class="line-horizontal mt-1 mb-1"></hr>
                                        </>
                                    }
                                </>
                                <div className='flex-direction-column mb-1 w-97'>
                                    <span className='m-2 card-heading w-100'>{employeeDetailsForm.claimDetails}</span>
                                    {tempDetailsData.map((row) => {
                                        return (
                                            <span className='pg-project-grid px-20px'>
                                                {row.map((data) => {
                                                    let { key, title } = data
                                                    const tempValue = showSelectedConveyance[key]
                                                    if ((key === "dem_rejection_remark" || key === "misc_rejection_remark" || key === "admin_rejection_remark") && tempValue === null) {
                                                        return null
                                                    }
                                                    if (key === 'total_approved_claim_amount') {
                                                        const claimStatus = showSelectedConveyance['claim_status'];
                                                        if (!(claimStatus === "Pending for Admin approval" || claimStatus === "Approved by Admin" || claimStatus === "Claim Approved & sent to Accountant for settlement" || claimStatus === "Claim Settled")) {
                                                            return null;
                                                        }
                                                    }
                                                    return (
                                                        <span>
                                                            <span className='appcenter-app-name1'>{title}</span>
                                                            <span className='card-content-title1 ml-5px'>{
                                                                key == 'from_date' || key == 'to_date' && showSelectedConveyance[key] ? moment(showSelectedConveyance[key]).format('DD-MM-YYYY') : showSelectedConveyance[key] !== null ?
                                                                    showSelectedConveyance[key] : "NA"}</span>
                                                        </span>
                                                    )
                                                })
                                                }
                                            </span >
                                        )
                                    })}
                                    <hr class="line-horizontal mt-1 mb-1"></hr>
                                    {this.state?.expenseDetails.length > 0 &&
                                        <>
                                            <span className='m-2 card-heading w-100'>{readOnlyLabel.expenseType}</span>
                                            <div className='main-contain'>
                                                <div className="row scroll-y h-27vh">
                                                    <table className="table gemsAdmin">
                                                        <thead className='w-100'>
                                                            <tr className='w-100'>
                                                                <th scope="col"><div className='thead pr-0 w-35px'>{expepnseTypeLabel.srNo}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.expenseType}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.category}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.subCategory}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.totalAmount}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.maxAmount}</div></th>
                                                                <th scope="col"><div className='thead'>{expepnseTypeLabel.approvableAmt}</div></th>
                                                                {statusList.includes(showSelectedConveyance['claim_status']) &&
                                                                    <th scope="col"><div className='thead'>{expepnseTypeLabel.approvedAmt}</div></th>}
                                                                <th scope="col" style={{ 'max-width': '150px' }}><div className='thead'>{expepnseTypeLabel.remark}</div></th>
                                                                <th scope="col"><div className='thead ml-06rem'>{expepnseTypeLabel.viewDetails}</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='adminScreentbody w-100'>
                                                            {this.state.expenseDetails.map((listValue, index) => {
                                                                return (
                                                                    <tr key={index} >
                                                                        <td><div className="td w-20px">{index + 1}</div></td>
                                                                        <td> <div className="td d-flex">{listValue?.expense_type ? listValue.expense_type : "NA"}</div> </td>
                                                                        <td> <div className="td d-flex">{listValue?.sub_expense_type1 ? listValue.sub_expense_type1 : "NA"}</div> </td>
                                                                        <td> <div className="td d-flex">{listValue?.sub_expense_type2 ? listValue.sub_expense_type2 : "NA"}</div> </td>
                                                                        <td> <div className="td d-flex">{listValue?.total_expense_amount ? listValue.total_expense_amount : "NA"}</div> </td>
                                                                        <td> <div className="td d-flex">{(listValue?.expense_type == 'Accommodation allowance' || listValue?.expense_type == 'Daily allowance' || listValue?.sub_expense_type1 == 'Own vehicle') ? listValue?.permissible_expense_amount != null ? listValue.permissible_expense_amount : "NA" : "NA"}</div> </td>
                                                                        <td> <div className="td d-flex">{listValue?.approvable_expense_amount !== null ? listValue.approvable_expense_amount : "NA"}</div> </td>
                                                                        {statusList.includes(showSelectedConveyance['claim_status']) &&
                                                                            <td> <div className="td d-flex">{listValue?.approved_expense_amount !== null ? listValue.approved_expense_amount : "NA"}</div> </td>}
                                                                        <td style={{ 'max-width': '150px' }}> <div className="td d-flex">{listValue.remark ? listValue.remark : "NA"}</div> </td>
                                                                        <td className='d-flex'>
                                                                            <div onClick={() => this.onDetailExpense(listValue)}>
                                                                                <img alt="view" src={view_action} className='pointer mx-2' />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div >
                            </div >
                            {(this.state?.showSelectedConveyance?.claim_status == checkdropDownLabel.pendingByDem && this.state?.showSelectedConveyance?.is_dem == true) || (this?.state?.showSelectedConveyance?.claim_status == checkdropDownLabel.pendingByL3 && this.state?.showSelectedConveyance?.is_l3 == true) ?
                                <div className='d-flex justify-content-end align-item-center mt-4 p-10px '>
                                    <button
                                        type="submit"
                                        className={this.validateRejectBtn() ? 'disable_cancel_btn mx-10px' : 'enable_cancel_btn mx-10px'}
                                        value="REJECT"
                                        disabled={this.validateRejectBtn()}
                                        onClick={() => this.isRejectReasonOpen()}>
                                        {constantValues.REJECT}
                                    </button>

                                    <button
                                        type="submit"
                                        className={this.validateRejectBtn() ? 'disableBtncheck mx-10px' : 'enableBtncheck mx-10px'}
                                        value="APPROVE"
                                        disabled={this.validateRejectBtn()}
                                        onClick={() => this.conformationPopUpOpen(this.state.showSelectedConveyance, 'APPROVE')}>
                                        {constantValues.APPROVE}
                                    </button>
                                </div>
                                :
                                <></>
                            }
                        </>
                    }
                </div >
                {this.state.isRejectReason &&
                    <>
                        <div className="modal popup-box">
                            <div className="declaration-box p-0" style={{ width: '30%' }}>
                                <div className="modal-header">
                                    <h4 className="modal_title black">Reject Confirmation</h4>
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => this.isRejectReasonClose()}>&times;</button>
                                </div>
                                <div className="modal-body">
                                    <div className='mt-2rem'>
                                        <div className='p-sub-heading ml-3 pb-10px '>Reason : </div>
                                        <div className='p-placeholder-text px-10px'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type='text'
                                                placeholder='Please enter reason'
                                                maxLength={50}
                                                onChange={(data) => { this.onSetRejcetValue(data) }}
                                                rows="3"
                                            />
                                        </div>
                                    </div>
                                    <div className="popup_text text-center p-3 m-3">
                                        <span className='purple-color performance-timeline-text-circle'>Note: </span>
                                        {rejectNote}
                                    </div>
                                </div>
                                <div className='modal-footer btnBox'>
                                    <input type='button' onClick={() => this.isRejectReasonClose()} value='CANCEL' className='cancelBtn' />
                                    <input type='button' value='CONFIRM'
                                        className={this.state.setReject.length > 0 ? 'validateBtn' : 'disableBtncheck'}
                                        disabled={this.state.setReject.length > 0 ? false : true}
                                        onClick={() => this.LocalConveyanceRejectApporval()} />
                                </div>
                            </div>
                        </div >
                    </>
                }
                {this.state.isConformationPopUpOpen &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        content={approveNote}
                        approveNoteContent={this.state.setApproveNote && this.state.setApproveNote}
                        action={'confirm'}
                        LocalConveyanceConfirmApporval={() => this.LocalConveyanceConfirmApporval()}
                        onDismiss={() => this.conformationPopUpClose()}
                    />
                }
                {this.state.isDetailsExpense?.isOpen &&
                    <DetailExpensePopUp
                        header={'Expense'}
                        type={'detailExpense'}
                        isDetailsExpense={this.state.isDetailsExpense}
                        attachmeantData={this.state.attachmeantData}
                        selectedUploadBill={this.selectedUploadBill}
                        onDismiss={e => {
                            this.setState({
                                isDetailsExpense: {
                                    isOpen: false,
                                    data: {}
                                },
                                attachmeantData: []
                            })
                        }}
                    />
                }
            </>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
export default connect(mapStatesToProps)(LocalConveyance)