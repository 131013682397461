import { fetchApiCallWithService } from '../../../../constants/Service'
import { commonHeadersAcceptJson, commonHeadersAcceptPDF } from '../../../../utils/Utils'
import { configStore } from "../../../../../src/redux/ConfigStore"
import { update_selected_status, get_project_status, headers, project, org_unit, area, duration, mtr_fr, mtr_fr_objective, objectives, landing_images, testimony, pg_instructions, pg_supervisors, applicant_instructions, future_status, delete_objectives, rejReason, getCompensationStructurePdf, gems_admin, testimonyEmploye, appCenterConfig } from '../../../../constants/Config'
import Axios from 'axios'

export const getProjectStatus = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(get_project_status, {}, commonHeadersAcceptJson, false, "accessToken", "GET", '', propsValue, params)
}

export const updateProjectStatus = (id, body, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${update_selected_status}${id}`, body, commonHeadersAcceptJson, false, role, "PATCH", '', '', {})
}

export const mtrFrObjective = (id, body, propsValue) => {
    return fetchApiCallWithService(`${mtr_fr_objective}${id}${objectives}`, body, commonHeadersAcceptJson, false, "accessToken", 'GET', '', propsValue, {})
}

// get single project details only
export const getProject = (id, propsValue) => {
    return fetchApiCallWithService(`${project}/${id}`, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "", propsValue)
}

// get list of projects
export const getProjects = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(`${project}/`, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "", propsValue, params)
}

// apply a project by PG
export const raiseProject = (body) => {
    body = { ...body, emp_id: configStore.getState().loginState.userData.Perno }
    return fetchApiCallWithService(project, body, commonHeadersAcceptJson, false, "accessToken", "POST", "")
}

export const deleteProject = (id) => {
    return fetchApiCallWithService(`${project}/${id}`, {}, commonHeadersAcceptJson, false, "accessToken", "DELETE", "")
}

export const getPGInstructions = () => {
    return fetchApiCallWithService(pg_instructions, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "")
}

export const applicantInstructions = () => {
    return fetchApiCallWithService(applicant_instructions, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "")
}

export const getSupervisors = () => {
    return fetchApiCallWithService(pg_supervisors + (configStore.getState().loginState?.userData?.Perno), {}, commonHeadersAcceptJson, false, "accessToken", "GET", "")
}

export const getArea = (type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(area, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const getOrgUnit = (type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(org_unit, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const getDuration = (params, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(duration, {}, commonHeadersAcceptJson, false, role, "GET", "", "", params)
}

export const getMTRFR = (id, propsValue, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${mtr_fr}${id}/objectives`, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const updateMTRFR = (id, body, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${mtr_fr}${id}/objectives`, body, commonHeadersAcceptJson, false, role, "PUT", "")
}

export const startProject = (prjId, body) => {
    return fetchApiCallWithService(`${project}/${prjId}`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const getApplicants = (prjId, params, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${project}/${prjId}/application`, {}, commonHeadersAcceptJson, false, role, "GET", "", "", params)
}

//---Apllicant project upload and submit function
export const applicantApplyProject = (prjId, body) => {
    return fetchApiCallWithService(`${project}/${prjId}/application`, body, commonHeadersAcceptJson, false, "accessToken", "POST", "")
}

export const downloadApplicantCV = (prjID, applicantId, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${project}/${prjID}/application/${applicantId}/cv`, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const startMTR = (prjID, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/start_mtr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const closeMTR = (prjID, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/close_mtr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const startFR = (prjID, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/start_fr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const fetchMTRSummary = (prjID, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${project}/${prjID}/fetch_mtr_summary`, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const fetchFRSummary = (prjID, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${project}/${prjID}/fetch_fr_summary`, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const downloadReport = (application, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    const key = application === "application" ? "application/report" : "report"
    return fetchApiCallWithService(`${project}/${key}`, {}, commonHeadersAcceptJson, false, role, "GET", "")
}

export const closeFR = (prjID, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/close_fr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const selectIndMTR = (prjID, applicantId, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/application/${applicantId}/mtr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const selectIndFR = (prjID, applicantId, body) => {
    return fetchApiCallWithService(`${project}/${prjID}/application/${applicantId}/fr`, body, commonHeadersAcceptJson, false, "accessToken", "PATCH", "")
}

export const updateProject = () => {
    return fetchApiCallWithService(project,)
}

// updateStatus is used for shortlist applicant by PG
export const updateStatus = (prjId, applicantID, body, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${project}/${prjId}/application/${applicantID}`, body, commonHeadersAcceptJson, false, role, "PATCH", "")
}


//--------Gems landing-------------
// export const getPGInstructions = (param, propsValue) => {
//     const params = param
//     return fetchApiCallWithService(pg_instructions, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "", propsValue, params)
// }

export const getTestimony = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(testimony, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "", propsValue, params)
}

export const getCarouselImages = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(landing_images, {}, commonHeadersAcceptJson, false, "accessToken", "GET", "", propsValue, params)
}

export const approveApplicantByPG = () => { }

export const deleteAppRequest = (prjID, appId) => {
    return fetchApiCallWithService(`${mtr_fr}${prjID}/application/${appId}`, {}, commonHeadersAcceptJson, false, "accessToken", "DELETE", "")
}

export const deleteObjectives = (prjID, appId, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${delete_objectives}${prjID}/objectives/${appId}`, {}, commonHeadersAcceptJson, false, role, "DELETE", "")
}

//---------Admin Function-----------//
export const getAdminProject = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(`${project}/`, {}, commonHeadersAcceptJson, false, "admin", "GET", "", propsValue, params)
}

export const publishAdminProject = (prjId, body) => {
    return fetchApiCallWithService(`${project}/${prjId}`, body, commonHeadersAcceptJson, false, "admin", "PATCH", "")
}

export const submitUpdatedData = (prjId, body) => {
    return fetchApiCallWithService(`${project}/${prjId}`, body, commonHeadersAcceptJson, false, "admin", "PUT", "")
}

export const getUpdateStatusData = (prjId, params) => {
    return fetchApiCallWithService(`${future_status}${prjId}/future_status`, {}, commonHeadersAcceptJson, false, "admin", "GET", "", "", params)
}

export const searchApplication = (empId) => {
    return fetchApiCallWithService(`${project}/application/${empId}`, {}, commonHeadersAcceptJson, false, "admin", "GET", "", "", "")
}

export const fetchRejectList = () => {
    return fetchApiCallWithService(`${rejReason}`, {}, commonHeadersAcceptJson, false, "admin", "GET", "", "", "")
}

export const fetchProjectCount = () => {
    return fetchApiCallWithService(`${project}/summary`, {}, commonHeadersAcceptJson, false, "admin", "GET", "", "", "")
}

//-----------------COMP download functionality---------------------
export const downloadPDF = (param, propsValue) => {
    const params = param
    return fetchApiCallWithService(getCompensationStructurePdf, null, commonHeadersAcceptPDF, false, "accessToken", "GET", '', propsValue, params, 'blob')
}

// ---------------Gems MAintenance------------------------------

export const fetchGemsMaintenanceList = (param) => {
    return fetchApiCallWithService(`${gems_admin}/${param}`, {}, commonHeadersAcceptJson, true, "admin", 'GET')
}

export const gettestimonyEmployeDetails = (param) => {
    return fetchApiCallWithService(`${testimonyEmploye}/${param}`, {}, commonHeadersAcceptJson, true, "admin", 'GET')
}

export const addGemsMaintenance = (param, body) => {
    return fetchApiCallWithService(`${gems_admin}/${param}`, body, commonHeadersAcceptJson, true, "admin", 'POST')
}

export const updateGemsMaintenance = (param, id, body) => {
    return fetchApiCallWithService(`${gems_admin}/${param}/${id}`, body, commonHeadersAcceptJson, true, "admin", 'PUT')
}

export const deleteGemsMaintenance = (param, id, body) => {
    return fetchApiCallWithService(`${gems_admin}/${param}/${id}`, {}, commonHeadersAcceptJson, true, "admin", 'DELETE')
}
//--------------App Center Configuration-------------------------------
export const addAppCenterDetails = (type, body) => {
    console.log('body in addAppCenterDetails:', body); // Debug log
    const role = type === 'admin' ? 'admin' : "accessToken";
    return fetchApiCallWithService(`${appCenterConfig}`, body, commonHeadersAcceptJson, false, role, "POST", 'admin', '', {}, 'json');
}

export const getAppCenterDetails = (id, params, type) => {
    const role = type === "admin" ? "admin" : "accessToken"
    return fetchApiCallWithService(`${appCenterConfig}`, {}, commonHeadersAcceptJson, false, role, "GET", 'admin', '', params, 'json')
}

export const inactiveAppCenterDetails = (type, id) => {
    const role = type === 'admin' ? 'admin' : "accessToken";
    return fetchApiCallWithService(`${appCenterConfig}/${id}`, {}, commonHeadersAcceptJson, false, role, 'DELETE', 'admin', '', {}, 'json')
}