import React, { Component } from 'react';
import { ReactionEventScreenEmoji, Newemoji } from '../../../../utils/Utils';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import '../Announcement/announcementScreen.css';

const NoReaction = `No Reaction`;
const MORE = `MORE`;
const PeopleReacted = `People who reacted`;

class MOMReactionview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reactionScreenEmoji: ReactionEventScreenEmoji(),
            responsedata: [],
            reactionAnnouncementid: '',
            page_number: 1,
            emojiData: Newemoji(),
            selectEmoji: 'All',
            totalPage: ''
        }
    }

    callingAnnouncementEmployeeRecordAPI = (moment_type_id) => {
        console.log("theNewFunction calling1:", moment_type_id);
        const headers = {
            'Content-Type': 'application/json',
        }
        console.log("theNewFunction calling2 :", moment_type_id);
        let body = {
            moments_id: moment_type_id,
            employee_response: this.state.selectEmoji,
            page_number: this.state.page_number
        }
        console.log("getMomentEmployeeRecord body:", body);
        fetchApiCall(Config.getMomentEmployeeReactionResponse, body, headers, true, '')
            .then(res => {
                console.log("getMomentEmployeeRecord res:", res)
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        responsedata: this.state.responsedata.concat(res.data.data.data ? res.data.data.data : []),
                        totalPage: res.data.data.total_page,
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        responsedata: [],
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });

            })
    }

    getMomentEmployeeRecord(moment_type_id, response, clear, oldNew) {
        console.log("getMomentEmployeeRecord calling1:", moment_type_id, response, clear, oldNew);
        if (oldNew === 'new') {
            this.setState({
                page_number: 1
            })
        }
        else {
            this.setState({
                page_number: this.state.page_number
            })
        }
        if (clear === 'clear') {
            this.setState({
                responsedata: [],
                selectEmoji: response === "" ? this.state.selectEmoji : response,
                loading: true,
            },
                () => this.callingAnnouncementEmployeeRecordAPI(moment_type_id))
        }
        else {
            this.setState({
                loading: true,
                selectEmoji: response === "" ? this.state.selectEmoji : response
            },
                () => this.callingAnnouncementEmployeeRecordAPI(moment_type_id))
        }
        console.log("getMomentEmployeeRecord calling2 :", response);
    }

    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }
    pageIncrease = (id) => {
        this.setState({
            page_number: this.state.totalPage > this.state.page_number ? this.state.page_number + 1 : this.state.page_number,
        },
            () => this.getMomentEmployeeRecord(id, "", "", "old")
        )
    }
    clearData = () => {
        this.setState({
            responsedata: [],
        })
    }
    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal fade" id="openEmployeeReaction" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className=" modal-dialog modal-dialog-centered modal-dialog-size" role="document">
                        <div className="modal-content mdl-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{PeopleReacted} {this.state.reactionAnnouncementid}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.clearData}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body mdl-body">
                                <div className='row d-flex justify-content-between'>
                                    {this.state.reactionScreenEmoji.map((reactemoji, id) =>
                                        <div key={id} className=''>
                                            <div className='col-md-12 '>
                                                <img
                                                    key={id}
                                                    alt=''
                                                    src={reactemoji.src}
                                                    className="emoji-custom"
                                                    onClick={() => this.getMomentEmployeeRecord(this.props.passMomentId, reactemoji.description, 'clear', 'new')}   //pass click response(all, like,smile, etc) and announcement id 
                                                />
                                                {this.state.selectEmoji === reactemoji.description ?
                                                    <div><hr className='purple-color borderthickness-selected' /></div>
                                                    :
                                                    <div><hr className='borderthickness-non-selected' /></div>
                                                }
                                            </div>
                                        </div>
                                    )}
                                    <hr className='hr-outerline' />
                                </div>
                                <div className='row mt-3 justify-content-center reaction-page-popup'>
                                    {this.state.responsedata.length > 0 ?
                                        <div className='col-md-12'>
                                            {this.state.responsedata.map((datalist, id) =>

                                                <div key={id} className='d-flex justify-content-between'>
                                                    <div className='d-flex justify-content-start align-item-center mb-3'>
                                                        <div className='reaction-cicle-icon'>
                                                            <div className="announcement-inner-circle announcement-circle-text" style={{ backgroundColor: '#3A83F8', paddingTop: '5px' }} >{this.short_name(datalist.employee_name)}</div>
                                                        </div>
                                                        <div className='reaction-cicle-icon'>
                                                            <div className='card-content-title common-text-color'>{datalist.employee_name}</div>
                                                        </div>
                                                    </div>
                                                    {this.state.emojiData.map((emojidata, id) =>
                                                        emojidata.description === datalist.employee_response &&
                                                        <div key={id}>
                                                            <img
                                                                key={id}
                                                                alt=''
                                                                src={emojidata.src}
                                                                className="emoji-emp-reaction"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div className='p'>
                                            <label className='card-content-title grey-color'>{NoReaction}</label>
                                        </div>
                                    }
                                </div>
                                {
                                    this.state.totalPage > '1' && this.state.totalPage > this.state.page_number &&
                                    <div onClick={() => this.pageIncrease(this.props.passMomentId)} style={{ margin: '10px' }}>
                                        <div style={{ cursor: 'pointer' }}
                                            className='row col-12 d-flex justify-content-center card-for-click mx-10'
                                        >
                                            <label style={{ cursor: 'pointer', margin: '5px', paddingBottom: '5px', color: 'white' }} className='card-content-title' >
                                                {MORE}
                                            </label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MOMReactionview;