import React, { Component } from 'react';
import moment from "moment";
import in_time_img from '../../../../assets/drishti_images/attendance/in_time.svg';
import out_time_img from '../../../../assets/drishti_images/attendance/out_time.svg';
import ApplyLeavePopup from '../leave/ApplyLeavePopup';
import { connect } from 'react-redux';
class AttendanceDetailsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLeaveCard: false,
            is_apply_leave_service_Unavailable: false,
            leave_Service_reason: '',
        }
    }

    componentDidMount() {
        this.checkService();
    }

    closepopup = () => {
        this.setState({ showLeaveCard: false })
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "leave_request" && item.is_active == false) {
                this.setState({
                    is_apply_leave_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
        })
    }

    render() {
        const { data } = this.props
        const { is_apply_leave_service_Unavailable } = this.state
        const day = new Date(data.LDATE).toLocaleString('en-us', { weekday: 'long' })
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0" style={{ width: '500px' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">Attendance Details</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className='justify-content-between flex-direction-column m-3 p-3 mt-0 mb-3'>
                            <div className="text16_bold">{day + moment(data.LDATE).format('  DD MMMM, YYYY')}</div>
                            <div className='justify-content-between mb-3'>
                                <div className='flex-direction-column pt-12px mt-3'>
                                    <div className='ml-3 mb-2 announcement-pin-content'> In Time</div>
                                    <div className='circle-attendance-input row align-item-center pl-12px'>
                                        <div>
                                            <img alt="list" className='pr-12px pl-12px' src={in_time_img}></img>
                                        </div>
                                        <div className='flex-direction-column'>
                                            <div>{data.SWIPE_IN}</div>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='performance-timeline-text-bold'>Leave Type:</div>
                                        <div className='ml-2 announcement-pin-content'>{data.FH}</div>
                                    </div>
                                </div>
                                <div className='flex-direction-column pl-12px pt-12px mt-3'>
                                    <div className='ml-3 mb-2 announcement-pin-content'> Out Time</div>
                                    <div className='circle-attendance-input row align-item-center pl-12px'>
                                        <div>
                                            <img alt="list" className='pr-12px pl-12px' src={out_time_img}></img>
                                        </div>
                                        <div className='flex-direction-column'>
                                            <div>{data.SWIPE_OUT}</div>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <div className='performance-timeline-text-bold'>Leave Type:</div>
                                        <div className='ml-2 announcement-pin-content'>{data.SH}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='performance-timeline-text-bold'>Status:</div>
                                <div className='ml-2 announcement-pin-content'>{data.title_status}</div>
                            </div>
                            {!is_apply_leave_service_Unavailable &&
                                <div className='mt-3'>
                                    <input type='button' onClick={() => this.setState({ showLeaveCard: true })} value='APPLY LEAVE' className='validateBtn w-100 mt-3' />
                                </div>
                            }
                        </div>
                    </div>
                </div>

                {this.state.showLeaveCard &&
                    <ApplyLeavePopup
                        show={true}
                        selected_date={data.LDATE}
                        closeapplyLeave={this.closepopup}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(AttendanceDetailsPopup);