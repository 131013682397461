import React, { Component } from 'react';
import newTask from '../../../../assets/drishti_images/todo/newtask.svg';
import OrganizationData from './OrganizationData';
import PersonalData from './PersonalData';
import './ToDo.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';

import AddNewTask from './AddNewTask'
class ToDo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            show: false,
            isOrganisationActive: true,
            isPersonalActive: false,
            todoTitle: 'Organisation',

            todoData: [],
            selectedData: {},
            actionType: '',

            //filterArray
            statusKey: [],
            priorityKey: [],
            dueDateFilter: [],
            filterType: [],

            //masters
            statusData: [],
            priorityData: [],
            dueDateData: []
        }
    }
    componentDidMount() {
        this.getMSTodoMasterData()
    }
    getMSTodoMasterData = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }

        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getMSTodoMasterData, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        statusData: res.data.data.status ? res.data.data.status : [],
                        priorityData: res.data.data.importance ? res.data.data.importance : [],
                        dueDateData: res.data.data.task_due_date ? res.data.data.task_due_date : [],
                    });
                    const tempStatuslList = this.state.statusData.map(tempValue => ({ ...tempValue, isSelected: false }))
                    const tempPriorityList = this.state.priorityData.map(tempValue => ({ ...tempValue, isSelected: false }))
                    const tempDateList = this.state.dueDateData.map(tempValue => ({ ...tempValue, isSelected: false }))

                    this.setState({
                        statusData: tempStatuslList,
                        priorityData: tempPriorityList,
                        dueDateData: tempDateList,
                        loading: false,
                    })
                    // for default select status notstarted
                    const defaultStatus = 'notStarted'
                    const defaultFilter = 'status'
                    this.setState(prevState => ({
                        statusData: prevState.statusData.map(
                            satatus => satatus.value === defaultStatus ? { ...satatus, isSelected: true } : satatus
                        )
                    }))
                    this.state.statusKey.push(defaultStatus)
                    this.state.filterType.push(defaultFilter)
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            }).then(() =>
                this.getTODOData()
            )
    }

    getTODOData = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoTitle,    //'Tasks
            filter_type: this.state.filterType,         //["status"], 
            status_key: this.state.statusKey,           //:["notStarted"] 
            priority_key: this.state.priorityKey,       //[],
            due_date_filter: this.state.dueDateFilter   //[]
        }
        console.log("getTODOData body:", body)
        fetchApiCall(Config.getAllTODOTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                console.log("res.data.personal_tasks:", res.data.personal_tasks)
                if (res.data.status_code == 200) {
                    this.setState({
                        todoData: res.data.personal_tasks ? res.data.personal_tasks : [],
                        loading: false,
                    });
                    // const tempTodoList = this.state.todoData.map(tempValue => ({ ...tempValue, isCompleted: tempValue.status === 'completed' ? true : false }))

                    let filteredData = this.state.todoData.filter((task, index) =>
                        (this.state.statusKey.length !== 0 ?
                            this.state.statusKey.includes(task.status) : true) &&
                        (this.state.priorityKey.length !== 0 ?
                            this.state.priorityKey.includes(task.importance) : true)
                    )
                    console.log("filteredData:", filteredData)

                    if (this.state.statusKey.length !== 0)
                        this.setState({
                            todoData: filteredData
                        })
                    const tempTodoList = this.state.todoData.map(tempValue => ({ ...tempValue, isCompleted: tempValue.status === 'completed' ? true : false }))
                    this.setState({ todoData: tempTodoList })
                    console.log("final todoData:", this.state.todoData)
                } else {
                    this.setState({
                        loading: false,
                        todoData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    todoData: []
                });
            })
    }
    deleteTask = (data) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoTitle,
            task_id: data.id,
            comp_code: this.props.loginData.userData.CompCode
        }
        fetchApiCall(Config.deleteMSTodoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        todoData: res.data.personal_tasks ? res.data.personal_tasks : [],
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getTODOData()
                } else {
                    this.setState({
                        loading: false,
                        todoData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    todoData: []
                });
            })
    }
    updateTaskComplete = (data) => {
        if (data.status === 'completed') return null;
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoTitle,
            title: data.title,
            task_due_date: data.dueDateTime ? data.dueDateTime.dateTime : '',
            priority: data.importance,
            status: 'completed',
            task_id: data.id,
            comp_code: this.props.loginData.userData.CompCode
        }
        fetchApiCall(Config.updateMSTodoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getTODOData()
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })

    }

    addAction = (type, data) => {
        this.setState({ show: true, actionType: type, selectedData: data })
    }
    filterStatus = (value, selected) => {
        const filter_key = 'status'
        const { statusKey, filterType } = this.state
        const selectedDateType = !selected
        this.setState(prevState => ({
            statusData: prevState.statusData.map(
                date => date.value === value ? { ...date, isSelected: selectedDateType } : date
            )
        }))
        if (selectedDateType) {
            statusKey.push(value)
            if (statusKey.length === 1) {
                filterType.push(filter_key)
            }
            this.getTODOData()
        }
        else {
            const selectItem = (element) => element === value;
            statusKey.splice(statusKey.findIndex(selectItem), 1);
            // statusKey.pop(value)
            if (statusKey.length === 0) {
                const select = (element) => element === "status";
                filterType.splice(filterType.findIndex(select), 1);
                // filterType.pop(filter_key)
            }
            this.getTODOData()
        }
    }
    filterPriority = (value, selected) => {
        const filter_key = 'priority' //'importance'
        const { priorityKey, filterType } = this.state
        const selectedDateType = !selected
        this.setState(prevState => ({
            priorityData: prevState.priorityData.map(
                date => date.value === value ? { ...date, isSelected: selectedDateType } : date
            )
        }))
        if (selectedDateType) {
            priorityKey.push(value)
            if (priorityKey.length == 1) {
                filterType.push(filter_key)
            }
            this.getTODOData()
        }
        else {
            const selectItem = (element) => element === value;
            priorityKey.splice(priorityKey.findIndex(selectItem), 1);
            // priorityKey.pop(value)
            if (priorityKey.length == 0) {
                const select = (element) => element === "priority"; //"importance";
                filterType.splice(filterType.findIndex(select), 1);
                // filterType.pop(filter_key)
            }
            this.getTODOData()
        }

    }
    filterDueDate = (value, selected) => {
        const filter_key = 'due_date_filter'// 'task_due_date'
        const { dueDateFilter, filterType } = this.state

        const selectedDateType = !selected
        this.setState(prevState => ({
            dueDateData: prevState.dueDateData.map(
                date => date.value === value ? { ...date, isSelected: selectedDateType } : date
            )
        }))
        if (selectedDateType) {
            dueDateFilter.push(value)
            if (dueDateFilter.length == 1) {
                filterType.push(filter_key)
            }
            this.getTODOData()
        }
        else {
            const selectItem = (element) => element === value;
            dueDateFilter.splice(dueDateFilter.findIndex(selectItem), 1);
            // dueDateFilter.pop(value)
            if (dueDateFilter.length == 0) {
                const select = (element) => element === "task_due_date";
                filterType.splice(filterType.findIndex(select), 1);
                // filterType.pop(filter_key)
            }
            this.getTODOData()
        }
    }
    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }

    togglePersonal = () => {
        this.setState({ isOrganisationActive: false, isPersonalActive: true, todoTitle: 'Tasks' },
            () => this.getTODOData())
    }
    toggleOrganization = () => {
        this.setState({ isOrganisationActive: true, isPersonalActive: false, todoTitle: 'Organisation' },
            () => this.getTODOData())
    }

    handleChange = e => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        this.setState(prevState => ({
            checkedItems: prevState.checkedItems.set(item, isChecked)
        }));
    };

    render() {
        return (
            <div className='pt-12px'>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row justify-content-between'>
                    <div className='row ml-1' onClick={this.gotoLanding}>
                        <img src={overviewButton} alt='' />
                    </div>
                    <div className='newTask pr-3'>
                        <img
                            src={newTask}
                            alt=''
                            onClick={() => this.addAction('add', '')}
                        />
                    </div>
                </div>
                <div className='row col-md-12'>
                    <div className='common-card col-md-10 mr-3 mt-3 px-12px heightTTodoDashboard'>
                        <div className='row mt-2'>
                            <div className={this.state.isOrganisationActive ? "activeTab" : "inactiveTab"} onClick={this.toggleOrganization}>
                                Organisation
                            </div>
                            <div className={this.state.isPersonalActive ? "activeTab" : "inactiveTab"} onClick={this.togglePersonal}>
                                Personal
                            </div>
                        </div>
                        <div className='hz-lines mr-4 ml-4'></div>
                        {this.state.isOrganisationActive ?
                            <div>
                                <OrganizationData
                                    data={this.state.todoData}
                                    deleteTask={(data) => this.deleteTask(data)}
                                    updateComplete={(data) => this.updateTaskComplete(data)}
                                />
                            </div> : null
                        }
                        {this.state.isPersonalActive ?
                            <div>
                                <PersonalData
                                    data={this.state.todoData}
                                    deleteTask={(data) => this.deleteTask(data)}
                                    updateTask={(data) => this.addAction('update', data)}
                                    updateComplete={(data) => this.updateTaskComplete(data)}
                                    status_key={this.state.statusKey}
                                />
                            </div> : null
                        }
                    </div>
                    <div className='common-card col-md mt-3 px-12px ml-2 heightTTodoDashboard'>
                        <div className='filtertxt document-innercard-content-title mt-3 ml-3 mb-3'>
                            Filter
                        </div>
                        <hr />
                        <div className='filterBox scrollFilter'>
                            <div className='filterTypetxt mb-2'>Due Date</div>
                            {this.state.dueDateData.map((list, i) => {
                                return (
                                    <div className='row p-1' key={i}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                onChange={() => this.filterDueDate(list.value, list.isSelected, i)} />
                                            <span className="markk"></span>
                                        </label>
                                    </div>

                                )
                            })}
                            <hr className='mb-3' />
                            <div className='filterTypetxt mb-2'>Priority</div>
                            {this.state.priorityData.map((list, index) => {
                                return (
                                    <div className='row p-1' key={index}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                onChange={() => this.filterPriority(list.value, list.isSelected)}
                                            />
                                            <span className="markk"></span>
                                        </label>
                                    </div>
                                )
                            })}
                            <hr className='mb-3' />
                            <div className='filterTypetxt mb-2'>Status</div>
                            {this.state.statusData.map((list, index) => {
                                return (
                                    <div className='row p-1' key={index}>
                                        <label className="checkBoxData">{list.key}
                                            <input type="checkbox" className='hidecheckBox'
                                                checked={list.isSelected}
                                                onChange={() => this.filterStatus(list.value, list.isSelected)}
                                            />
                                            <span className="markk"></span>
                                        </label>
                                    </div>
                                )
                            })}
                            <hr />

                        </div>
                    </div>
                </div>
                {this.state.show ?
                    <AddNewTask
                        loginData={this.props.loginData}
                        type={this.state.todoTitle}
                        action={this.state.actionType}
                        data={this.state.selectedData}
                        onDismiss={() => this.setState({ show: false }, () => this.togglePersonal())}
                    /> : null}
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps, {})(ToDo);