import React, { Component } from 'react';
import './COIStyle.css';

const headerTitle = 'Declaration : Conflict Of Interest Policy'
const what_is_conflict_of_interest = `A conflict of interest, actual or potential,could be any known activity, transaction, relationship or service engaged in by an employee, his/her Immediate Family Member or Relative or person with Close Personal Relationship, which may cause concern
    that the employee could not or might not be able to fairly,independently or objectively perform his/her duties to our Company.`

const A = `A) engages in a business, activity or relationship with anyone who is party to a transaction with our Company.`
const B = `B) is in a position to derive an improper benefit, personally or for any immediate family member or relative or for any person in a close personal relationship, by making or influencing decisions relating to any transaction.`
const C = `C) conducts business on behalf of our Company or is in a position to influence a decision with regard to our Company's business with a Value-Chain partner or customer or competitor where an immediate family member or relative of, or a person in close personal relationship with, an employee or executive director is a principal officer or representative, resulting in a personal benefit or a benefit to the relative.`
const D = `D) Is in a position to influence decisions with regard to award of benefits such as increase in salary or other remuneration, posting, promotion or recruitment of an immediate family member or relative or a person in close personal relationship employed in our Company.`
const E = `E) undertakes an activity by which the interest of our Company can be compromised or defeated; or`
const F = `F) does anything by which an independent judgement of our Company's best interest cannot be exercised.`
class DeclarationPopup extends Component {
    render() {
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box align-items-center apply_leave_modal">
                    <div className="modal-header">
                        <div className='row'>
                            <h4 className="popup-title">{headerTitle}</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body scroller-coi">
                        <h1 className='coi-text mx-4 mt-4'>What is Conflict Of Interest</h1>
                        <h2 className='coi-sub-text mx-4 mr-4'>{what_is_conflict_of_interest}</h2>
                        <h1 className='coi-text mx-4'>Examples of Potential Conflicts of Interest :</h1>
                        <h2 className='coi-sub-text mx-4'>Actual or potential, arises where,directly or indirectly, an employee or executive director :</h2>
                        <div className='coi-option-text display-linebreak'>
                            <h2 className='coi-option-text mr-4'>{A}</h2>
                            <h2 className='coi-option-text mr-4'>{B}</h2>
                            <h2 className='coi-option-text mr-4'>{C}</h2>
                            <h2 className='coi-option-text mr-4'>{D}</h2>
                            <h2 className='coi-option-text mr-4'>{E}</h2>
                            <h2 className='coi-option-text mr-4'>{F}</h2>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeclarationPopup;