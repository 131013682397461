export function handleClickForReactions(id, reaction, mainData, loginData, type) {
    if (type === "announcement_id") {
        const reactionIdData = mainData.find(rectId => rectId.announcement_id === id)
        const feedbackArrayWithSameReaction = reactionIdData.feedback.find(empReaction => empReaction.employee_response === reaction)
        const feedbackArrayWithSameUserEmp = reactionIdData.feedback.find(empId => empId.employee_id === loginData.userData.Perno)
        if (reactionIdData.feedback.length === 0) {
            return mainData.map((data, key) =>
                data.announcement_id === id ?
                    {
                        ...data,
                        feedback: [{ employee_response: reaction, employee_id: loginData.userData.Perno }],
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)
        }
        else if (feedbackArrayWithSameReaction !== undefined) {

            return mainData.map((data, key) =>
                data.announcement_id === id ?
                    {
                        ...data,
                        feedback: data.feedback.map(el => (
                            el.employee_response === reaction ?
                                { ...el, employee_response: reaction } :
                                { ...el } &&
                                    el.employee_id === loginData.userData.Perno ?
                                    { ...el, employee_response: reaction } :
                                    { ...el }
                        ),
                        ),
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)

        }
        else if (feedbackArrayWithSameUserEmp !== undefined) {

            return mainData.map((data, key) =>
                data.announcement_id === id ?
                    {
                        ...data,
                        feedback: data.feedback.map(el => (
                            el.employee_id === loginData.userData.Perno ?
                                { ...el, employee_response: reaction }
                                :
                                { ...el }
                        )
                        ),
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)

        }
        else {

            return mainData.map((data, key) =>
                data.announcement_id === id ?
                    {
                        ...data,
                        feedback: [...data.feedback, { employee_response: reaction, employee_id: loginData.userData.Perno }],
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data
            )
        }
    }
    else if (type === "moments_id") {
        // console.log("dfghjkl = ",reactionIdData)
        const reactionIdData = mainData.find(rectId => rectId.moments_id === id)
        console.log("reactionIdData", reactionIdData)

        var feedbackArrayWithSameReaction = ""
        var feedbackArrayWithSameUserEmp = ""
        if (reactionIdData.feedback_data.length > 0) {
            feedbackArrayWithSameReaction = reactionIdData.feedback_data?.find(empReaction => empReaction.employee_response === reaction)
            feedbackArrayWithSameUserEmp = reactionIdData.feedback_data?.find(empId => empId.employee_id === loginData.userData.Perno)
        }
        if (reactionIdData.feedback_data.length === 0) {
            return mainData.map((data, key) =>
                data.moments_id === id ?
                    {
                        ...data,
                        feedback_data: [{ employee_response: reaction, employee_id: loginData.userData.Perno }],
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)
        }
        else if (feedbackArrayWithSameReaction !== undefined) {

            return mainData.map((data, key) =>
                data.moments_id === id ?
                    {
                        ...data,
                        feedback_data: data.feedback_data.map(el => (
                            el.employee_response === reaction ?
                                { ...el, employee_response: reaction } :
                                { ...el } &&
                                    el.employee_id === loginData.userData.Perno ?
                                    { ...el, employee_response: reaction } :
                                    { ...el }
                        ),
                        ),
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)

        }
        else if (feedbackArrayWithSameUserEmp !== undefined) {

            return mainData.map((data, key) =>
                data.moments_id === id ?
                    {
                        ...data,
                        feedback_data: data.feedback_data.map(el => (
                            el.employee_id === loginData.userData.Perno ?
                                { ...el, employee_response: reaction }
                                :
                                { ...el }
                        )
                        ),
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data)

        }
        else {

            return mainData.map((data, key) =>
                data.moments_id === id ?
                    {
                        ...data,
                        feedback_data: [...data.feedback_data, { employee_response: reaction, employee_id: loginData.userData.Perno }],
                        employee_response: reaction,
                        total_response: data.employee_response === "" ? data.total_response + 1 : data.total_response
                    }
                    :
                    data
            )

        }
    }
}
