import React, { Component } from 'react';
import moment from 'moment';
import '../../employeeScreens/transfer/Transfer.css';
class LeaveDetailsPopup extends Component {
    render() {
        const EmpData = this.props.EmpData.length > 0 ? this.props.EmpData[0] : ''
        const ManagerData = this.props.ManagerData.length > 0 ? this.props.ManagerData[0] : ''
        const LeaveData = this.props.leaveData
        const { action } = this.props
        return (
            <div>
                {action === 'view' &&
                    <div className="modal popup-box">
                        <div className="declaration-box p-0 " style={{ width: '50%' }}>
                            <div className="modal-header justify-content-center mt-2 ml-2">
                                <h4 className="modal_title black">Employee Leave Details</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="text14_light text-left ml-4">
                                    <div className="text14_bold">Employee</div>
                                </div>
                                <div className='row d-flex justify-content-between p-3'>
                                    <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                        <div className="text14_bold">Employee Name</div>
                                        <div className="text14_light mt-1">{EmpData.CompName ? EmpData.CompName : ''} </div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                        <div className="text14_bold">Employee ID</div>
                                        <div className="text14_light mt-1">{EmpData.Perno ? EmpData.Perno : ''} </div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                        <div className="text14_bold">Employee Email</div>
                                        <div className="text14_light mt-1">{EmpData.Imailid ? EmpData.Imailid : ''} </div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                        <div className="text14_bold">Employee Contact</div>
                                        <div className="text14_light mt-1">{EmpData.PersMobile ? EmpData.PersMobile : ''} </div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                {LeaveData.leave_category &&
                                    <div>
                                        <div className="text14_light text-left ml-4">
                                            <div className="text14_bold">Approver</div>
                                        </div>
                                        <div className='row p-3'>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Approver Employee ID</div>
                                                <div className="text14_light mt-1">{LeaveData.approver_emp_id ? LeaveData.approver_emp_id : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Approver Email</div>
                                                <div className="text14_light mt-1">{LeaveData.approver_email ? LeaveData.approver_email : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                        </div>
                                        <div className="text14_light text-left ml-4">
                                            <div className="text14_bold">Leave Details</div>
                                        </div>
                                        <div className='row d-flex justify-content-between p-3'>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Leave Category</div>
                                                <div className="text14_light mt-1">{LeaveData.leave_category ? LeaveData.leave_category : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Leave Type</div>
                                                <div className="text14_light mt-1">{LeaveData.leave_type ? LeaveData.leave_type : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Start Date</div>
                                                <div className="text14_light mt-1">{LeaveData.from_date ? moment(LeaveData.from_date).format("DD-MM-YYYY") : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">To Date</div>
                                                <div className="text14_light mt-1">{LeaveData.to_date ? moment(LeaveData.to_date).format("DD-MM-YYYY") : ''}</div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                        </div>
                                        <div className='row d-flex justify-content-between p-3'>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Total Days</div>
                                                <div className="text14_light mt-1">{LeaveData.total_days ? LeaveData.total_days : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Reason</div>
                                                <div className="text14_light mt-1">{LeaveData.reason ? LeaveData.reason : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Leave Status</div>
                                                <div className="text14_light mt-1">{LeaveData.leave_status ? LeaveData.leave_status : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Leave Posted Date</div>
                                                <div className="text14_light mt-1">{LeaveData.created_date_time ? moment(LeaveData.created_date_time).format("DD-MM-YYYY") : ''}</div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                        </div>
                                        <div className='row p-3'>
                                            <div className="popup_text text-left font-weight-bold text-dark mx-2">
                                                <div className="text14_bold">Leave Applied Date</div>
                                                <div className="text14_light mt-1">{LeaveData.applied_date ? moment(LeaveData.applied_date).format("DD-MM-YYYY") : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                            <div className="popup_text text-left font-weight-bold text-dark ml-4">
                                                <div className="text14_bold">Last Modified Date</div>
                                                <div className="text14_light mt-1">{LeaveData.modified_date_time ? moment(LeaveData.modified_date_time).format("DD-MM-YYYY") : ''} </div>
                                                <div className='mt-2'><hr /></div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                }
            </div>
        );
    }
}

export default LeaveDetailsPopup;