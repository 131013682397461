import React, { Component } from 'react';
import { GuidelineHeader, GuidelineTitle, Point8, Point7, Point6, Point5, Point4, Point3, SubPoint3, Point2, Point1 } from './EseparationContants';

const NoticePeriodPolicy = 'https://tatamotors.sharepoint.com/:w:/r/sites/c-app/s-MTMDev/_layouts/15/WopiFrame.aspx?sourcedoc={5e48ff72-a975-4e75-85b6-6093a775c6ec}&action=default'
class InitiateFormPopups extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <div className="modal popup-box">
        <div className={'popupCard-box p-0 w-60'}>
          <div className="modal-header">
            <h4 className="modal_title black">{GuidelineTitle}</h4>
            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
          </div>

          <div className="modal-body">
            <div className='scroll-y h-64vh mt-2 mb-2'>
              <h2 className='coi-sub-text mx-4'>{GuidelineHeader}</h2>
              <h2 className='coi-option-text mr-4'>{'Here are some quick guidelines for your reference –'}</h2>
              <h2 className='coi-option-text mr-4'>{Point1}</h2>
              <h2 className='coi-option-text mr-4'>{Point2}</h2>
              <div className='coi-option-text mr-4'><span className='coi-option-text'>{'To: '}</span><span ><u className='coi-text'>{'DEM MANAGER,  FEM MANAGER'}</u></span></div>
              <div className='coi-option-text mr-4'><span className='coi-option-text'>{'Cc: '}</span><span ><u className='coi-text'>{'BHR'}</u></span></div>
              <div>
                <span className='coi-option-text'>{Point3}</span>
              </div>
              <div className='ml-4'>
                <span className='coi-text'>{' (Kindly note, only intimation of separation is required on mail, acknowledgement/approval is optional).'}</span>
              </div>
              <div className='ml-2'><h2 className='coi-option-text italic-Font mr-4'>{SubPoint3}</h2></div>
              <h2 className='coi-option-text mr-4'>{Point4}</h2>
              <h2 className='mt-15'><span className='coi-option-text mr-1'>{'Refer table below for quick reference and'}</span><span><a href={NoticePeriodPolicy} target="_blank"><label className='tata-policy-link mr-4' type='button'>{'Click here to read Notice Period policy'}</label></a></span></h2>
              <div className='d-flex justify-content-center align-item-center mt-3'>
                <div className='w-50rem'>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"><div className='thead w-10rem'>{'Level'}</div></th>
                        <th scope="col"><div className='thead'>{'Employee Category'}</div></th>
                        <th scope="col"><div className='thead'>{'Notice Period Days'}</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><div className="td d-flex">{'Level C'}</div></td>
                        <td> <div className="td d-flex">{'Permanent'}</div> </td>
                        <td> <div className="td d-flex">{'180'}</div> </td>
                      </tr>
                      <tr>
                        <td><div className="td d-flex">{'Level 1,2,3'}</div></td>
                        <td> <div className="td d-flex">{'Permanent'}</div> </td>
                        <td> <div className="td d-flex">{'90'}</div> </td>
                      </tr>
                      <tr>
                        <td><div className="td d-flex">{'Level 4,5,6'}</div></td>
                        <td> <div className="td d-flex">{'Permanent'}</div> </td>
                        <td> <div className="td d-flex">{'60'}</div> </td>
                      </tr>
                      <tr>
                        <td><div className="td d-flex">{'Level 5, 6'}</div></td>
                        <td> <div className="td d-flex">{'Probationers'}</div> </td>
                        <td> <div className="td d-flex">{'30'}</div> </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="td flex-direction-column">
                            <span className="td d-flex">{'Graduate Apprentice Trainee (GAT),'}</span>
                            <span className="td d-flex">{'Professional Fixed Term (PFT),'}</span>
                            <span className="td d-flex"> {'Technical Fixed Term (TFT),'}</span>
                            <span className="td d-flex"> {'SCIP Associate,'}</span>
                            <span className="td d-flex">{'FSE, All Other Fixed Term'}</span>
                          </div>
                        </td>
                        <td> <div className="td d-flex">{'Fixed term Contract'}</div> </td>
                        <td> <div className="td d-flex">{'30'}</div> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h2 className='coi-option-text mr-4 mt-2'>{Point5}</h2>
              <h2 className='coi-option-text mr-4'>{Point6}</h2>
              <h2 className='coi-option-text mr-4'>{Point7}</h2>
              <h2 className='coi-option-text mr-4'>{Point8}</h2>
            </div>
          </div>

        </div>
      </div >
    );
  }
}

export default InitiateFormPopups;