import * as XLSX from 'xlsx'
export const summaryColumns = [
    { title: "Sr. No.", width: '5%' },
    { title: "Claim ID", width: '10%' },
    { title: "Emp ID", width: '10%' },
    { title: "Emp name", width: '10%' },
    { title: "Applied Date", width: '10%' },
    { title: "Total Amount", width: '10%' },
    { title: "Claim Status", width: '10%' },
    { title: "View", width: '5%' },
]
export const bdcColumns = [
    { title: "Sr. No.", width: '5%' },
    { title: "Claim ID", width: '10%' },
    { title: "Employee Name", width: '10%' },
    { title: "Travel Type", width: '10%' },
    { title: "From Date", width: '10%' },
    { title: "To Date", width: '10%' },
    { title: "From Place", width: '10%' },
    { title: "To Place", width: '10%' },
    { title: "View", width: '5%' },
]

export const bdcHistoryColumns = [
    { title: "Sr. No.", width: '10%' },
    { title: "Admin Name", width: '15%' },
    { title: "Document Name", width: '25%' },
    { title: "Created Date", width: '13%' },
    { title: "Total Claims", width: '13%' },
    { title: "Total Claim Amount", width: '13%' },
    { title: 'Generate BDC', width: '10%' }
]

export const employeeDetailsFields = [
    { label: "Emp ID", key: 'Perno' },
    { label: "Name", key: 'CompName' },
    { label: "Level", key: 'Esgtxt' },
    { label: "Grade", key: 'Esubgroup' },
    { label: "Cost Center", key: 'Costcenter' },
    { label: "Personal Area", key: 'PersArea' },
    { label: "Personal Sub Area", key: 'Payarea' },
    { label: "Company", key: 'Ccodetxt' },
]
export const adminDetailsFields = [
    { label: "Name", key: 'spoc_fullname' }
]
export const expenseTableCols = [
    { title: 'Sr No.', width: '10%' },
    { title: 'Expense Type', width: '10%' },
    { title: 'Category', width: '10%' },
    { title: 'Sub Category', width: '10%' },
    { title: 'Claim Amount', width: '10%' },
    { title: 'Permissible Amount', width: '10%' },
    { title: 'Approvable Amount', width: '10%' },
    { title: 'Approved Amount', width: '10%' },
    { title: 'Claim Expense', width: '10%' },
    { title: 'Attachments', width: '20%' },
]

export const expenseTableColsSummary = [
    { title: 'Sr No.', width: '10%' },
    { title: 'Expense Type', width: '10%' },
    { title: 'Category', width: '10%' },
    { title: 'Sub Category', width: '10%' },
    { title: 'Claim Amount', width: '10%' },
    { title: 'Permissible Amount', width: '10%' },
    { title: 'Approvable Amount', width: '10%' },
    { title: 'Approved Amount', width: '10%' },
    { title: 'Remark', width: '10%' },
    { title: 'Attachments', width: '20%' },
]

export const expenseDetailsFields = [
    { key: "expense_type", label: 'Expense Type', type: 'text' },
    { key: "sub_expense_type1", label: 'Category', type: 'text' },
    { key: "sub_expense_type2", label: 'Sub Category', type: 'text' },
    { key: "total_expense_amount", label: 'Claim Amount', type: 'text' },
    { key: "permissible_expense_amount", label: 'Permissible Amount', type: 'text' },
    { key: "approved_expense_amount", label: 'Approvable Amount', type: 'text' },
    { key: "from_expense_date", label: 'From Date', type: 'date' },
    { key: "to_expense_date", label: 'To Date', type: 'date' },
    { key: "remark", label: 'Remark', type: 'text' },
]
export const billDetailsCol = [
    { title: "Sr No.", width: '5%' },
    { title: "Attachment", width: '5%' },
    { title: "Download", width: '5%' },
    { title: "View", width: '5%' },
]

export const exportTOExcel = (fileName, ExcelExportData, fileExtension) => {
    var ws = XLSX.utils.json_to_sheet(ExcelExportData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Bulk Registration");
    XLSX.writeFile(wb, fileName + fileExtension);
}

export const applicantStatus = [
    { id: 'PENDING_BY_ADMIN', title: "Pending for Admin approval" },
    { id: 'APPROVED_BY_ADMIN', title: "Approved by Admin" },
    { id: 'REJECTED_BY_ADMIN', title: "Claim rejected by Admin" },
    { id: 'ADMIN_BDC_DOCUMENT_GENERATED', title: "Claim Approved & sent to Accountant for settlement" },
    { id: 'CLAIM_SETTLED', title: "Claim Settled" }
]

export const claimSummaryStatus = [
    { id: 'ALL', title: "All" },
    { id: 'PENDING_BY_ADMIN', title: "Pending for DEM approval" },
    { id: 'PENDING_FOR_ADMIN', title: "Pending for Admin approval" },
    { id: 'APPROVED_BY_ADMIN', title: "Approved by Admin" },
    { id: 'REJECTED_BY_ADMIN', title: "Claim rejected by Admin" },
    { id: 'ADMIN_BDC_DOCUMENT_GENERATED', title: "Claim Approved & sent to Accountant for settlement" },
    { id: 'CLAIM_SETTLED', title: "Claim Settled" }
]

export const claimPrimaryKeys = [
    { key: "emp", value: "Applicant Name", type: "text" },
    { key: "travel_type", value: 'Travel Type', type: "text" },
    { key: "from_date", value: 'From Date', type: "date" },
    { key: "to_date", value: 'To Date', type: "date" },
    { key: "claim_status", value: 'Claim Detail Status', type: "text" }
]

export const applicantDetailsForm = [
    { key: "Perno", label: 'Emp ID' },
    { key: "CompName", label: "Name" },
    { key: "Esgtxt", label: "Grade" },
    { key: "Payarea", label: "Payroll Area" },
    { key: "Costcenter", label: "Cost Center" },
    { key: "PersArea", label: "Personnel Area" },
    { key: "PSubarea", label: "Personnel Sub Area" },
    { key: "Ccodetxt", label: "Company" },
]
export const claimDetailsForm = [
    { key: "travel_type", label: "Travel Type", type: "text" },
    { key: "purpose", label: "Travel Purpose", type: "text" },
    { key: "from_place", label: "From Place", type: "text" },
    { key: "to_place", label: "To Place", type: "text" },
    { key: "from_date", label: "From Date", type: "date" },
    { key: "to_date", label: "To Date", type: "date" },
    { key: "from_time", label: "From Time", type: "text" },
    { key: "to_time", label: "To Time", type: "text" },
    { key: "total_emp_claim_amount", label: "Total Claim Amount", type: "text" },
    { key: "total_permissible_claim_amount", label: "Total Permissible Amount", type: "text" },
    { key: "total_approvable_claim_amount", label: "Total Approvable Amount", type: "text" },
    { key: "total_approved_claim_amount", label: "Total Approved Amount", type: "text" },
    { key: "claim_status", label: "Claim Status", type: "text" },
    { key: "dem_rejection_remark", label: "DEM Rejection Remark", type: "text" },
    { key: "misc_rejection_remark", label: "L3 and Above Rejection Remark", type: "text" },
    { key: "admin_rejection_remark", label: "Admin Rejection Remark", type: "text" },
]
export const claimDetailsFormClaim = [
    { key: "travel_type", label: "Travel Type", type: "text" },
    { key: "purpose", label: "Travel Purpose", type: "text" },
    { key: "from_place", label: "From Place", type: "text" },
    { key: "to_place", label: "To Place", type: "text" },
    { key: "from_date", label: "From Date", type: "date" },
    { key: "to_date", label: "To Date", type: "date" },
    { key: "from_time", label: "From Time", type: "text" },
    { key: "to_time", label: "To Time", type: "text" },
    { key: "total_emp_claim_amount", label: "Total Claim Amount", type: "text" },
    { key: "total_permissible_claim_amount", label: "Total Permissible Amount", type: "text" },
    { key: "total_approvable_claim_amount", label: "Total Approvable Amount", type: "text" },
    { key: "total_approved_claim_amount", label: "Total Approved Amount", type: "text" },
    { key: "claim_status", label: "Claim Status", type: "text" },
    { key: "dem_rejection_remark", label: "DEM Rejection Remark", type: "text" },
    { key: "misc_rejection_remark", label: "L3 and Above Rejection Remark", type: "text" },
    { key: "admin_rejection_remark", label: "Admin Rejection Remark", type: "text" },
]
export const tdStyle = 'td-choice-pay d-flex amount-justify-content-start'
export const approveNote = 'Are you sure you want to approve the claim request?'
export const rejectNote = 'Are you sure you want to reject the claim request?'