import React, { Component } from 'react';
import '../../employeeScreens/continuousFeedbackManagement/CFMHome.css';
import { connect } from 'react-redux';
import TMA from '../../../../assets/drishti_images/learningArchitecture/TMA.png'
// import Loader from '../../../../shared/loader/LoaderIndicator'
// import * as Config from "../../../../../constants/Config";
// import { fetchApiCall, sessionOut } from "../../../../../constants/Service";
// import { toast } from 'react-toastify';
import LACopyRight from './LACopyRight';
const nodataMessage = "We invite you to explore applicable functional/professional/leadership learning programs and e-learning offerings on our Learning Management System (LMS)"
class LARightScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
        }
    }
    componentDidMount() {
        this.setState({
            dataView: this.props.data
        })
    }
    openCopyrightPopup = () => {
        this.setState({
            openCopyrightPopup: !this.state.openCopyrightPopup
        })
    }
    render() {
        return (
            <>
                <div className='transparent-card-subcard p-10'>
                    {this.props.data.length === 0 && this.props.customSearch === true ?
                        <div className='noDataAtCenter'>
                            <label className='white-card-subcard-outer common-text-color learningBox p-10 document-innercard-content-title'>
                                {nodataMessage}{'. '}
                                <a target="_blank" href="https://tataindia.plateau.com/learning/user/personal/landOnPortalHome.do?OWASP_CSRFTOKEN=RKY9-3WWQ-ISAZ-9WTQ-2YKF-2R0B-Q8XR-5YSX&fromSF=Y&fromDeepLink=true&pageID=">
                                    Click here to access LMS
                                </a>
                            </label>
                        </div>
                        :
                        <div className='inner-Card'>
                            <div className='row justify-content-between margin-top-25px align-item-center'>
                                {this.props.data.length !== 0 &&
                                    <>
                                        <div className='basic-card-heading common-text-color'>
                                            {this.props.title}
                                        </div>
                                        <div className='align-item-center'>
                                            <label className='card-content-title marginLeftlA'>Powered by </label>
                                            <img alt="info"
                                                src={TMA}
                                                className="tataAcademy"
                                                data-toggle="modal"
                                            // onClick = {()=> this.openCopyrightPopup()}
                                            >
                                            </img>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='dataListhight'>
                                {this.props.data.length !== 0 &&
                                    <>
                                        {
                                            this.props.data.map((dataValue, id) =>
                                                <div key={id} className={id === 0 ? "learningBox" : "learningBox mt-10px "}>
                                                    <div className='d-flex justify-content-between'>
                                                        <div className='document-innercard-content-title w-25rem'>
                                                            {this.props.data[id].title}
                                                        </div>
                                                        {this.props.data[id].link == null || this.props.data[id].link == "empty" ? null :
                                                            <label className='card-content-title1 purple-color'>
                                                                <u><a className='card-content-title1' target='_Blank' href={this.props.data[id].link}>View Course</a></u>
                                                            </label>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                    </>
                                }
                            </div>
                        </div>
                    }
                </div>
                {this.state.openCopyrightPopup &&
                    <LACopyRight
                        // title={this.state.tempTitle}
                        // loginData={this.props.loginData}
                        // empID={this.props.loginData.userData.Perno}
                        onDismiss={() => this.setState({ openCopyrightPopup: false })}
                    />
                }
            </>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfm: state.cfmState
    }
}

export default (connect(mapStatesToProps)(LARightScreen));