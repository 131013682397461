import React, { Component } from 'react'
import '../continuousFeedbackManagement/CFMHome.css';
import '../../../../assets/styles/flex.css';
import '../../../../assets/styles/styles.css';
import sendicon from '../../../../assets/images/cfm/sendicon.svg';
import GoalScreen from '../continuousFeedbackManagement/GoalScreen';
import LeadershipScreen from '../continuousFeedbackManagement/LeadershipScreen';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import FeedbackRequestPopupNew from './FeedbackRequestPopUpNew';
import nodata from '../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg';

toast.configure();
class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todoSelect: 'Goal',
            loading: false,
            show: false,
            isGoalPopup: true,
            isLeadershipPopup: false,
            isSubmitPopup: false,
            goalsData: [],
            leadersheepGoalsData: [],
            elsecondition: false,
            emp_id: this.props.loginData.userData.Perno,
            directReporteeEmailID: '',
            managerEmailID: '',
            matrixManagerEmailID: '',
            messageGoal: '',
            applicationModes: '',
            goalApplicationMode:'',
            GoalMessageStatus:'',
            leadershipApplicationMode:'',
            leadershipMessageStatus:''
        }
    }

    componentDidMount() {
        this.getGoalsDetails()
        this.getUserDetail()
    }

    getUserDetail = (emp_id) => {
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.emp_id,
        }
        fetchApiCall(Config.getSuccessFactorUserDetails, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        directReporteeEmailID: res.data.user_details.user_name ? res.data.user_details.user_name : '',
                        managerEmailID: res.data.user_details.manager.username ? res.data.user_details.manager.username : '',
                        matrixManagerEmailID: res.data.user_details.matrix_manager.username ? res.data.user_details.matrix_manager.username : '',
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        directReporteeEmailID: '',
                        managerEmailID: '',
                        matrixManagerEmailID: '',
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    directReporteeEmailID: '',
                    managerEmailID: '',
                    matrixManagerEmailID: '',
                });
                console.log('err-->', err)
            })
    }

    getGoalsDetails = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            user_id: this.props.loginData.cfmUserDetails.length != 0 ? this.props.loginData.cfmUserDetails.user_details.user_id : '',
            is_employee: true
        }
        fetchApiCall(Config.getEmployeeGoals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        goalsData: res.data.sf_goal_details ? res.data.sf_goal_details : [],
                        applicationModes : res.data.application_modes,
                        messageGoal: res.data.message,
                        goalApplicationMode:res.data.application_modes,
                        GoalMessageStatus:res.data.message
                    });
                    const tempGoalData = this.state.goalsData.map(tempValue => ({ ...tempValue, commentDescription: '', isCommentShow: false, isSelected: false }))
                    this.setState({ goalsData: tempGoalData })
                } else {
                    this.setState({
                        elsecondition: true,
                        loading: false,
                        goalsData: []
                    })
                }
            }).catch(err => {
                console.log("getGoalsDetails err : ", err);
                this.setState({
                    loading: false,
                    goalsData: []
                });
            })
    }

    getLeadersheepGoals = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            user_id: this.props.loginData.cfmUserDetails.length != 0 ? this.props.loginData.cfmUserDetails.user_details.user_id : '',
            is_employee: true
        }

        fetchApiCall(Config.getEmployeeCompetencies, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: res.data.sf_competency_details ? res.data.sf_competency_details : [],
                        applicationModes : res.data.application_modes,
                        messageGoal: res.data.message,
                        leadershipApplicationMode: res.data.application_modes,
                        leadershipMessageStatus: res.data.message
                    },
                    );
                    const tempGoalData = this.state.leadersheepGoalsData.map(tempValue => ({ ...tempValue, commentDescription: '', isCommentShow: false, isSelected: false }))
                    this.setState({ leadersheepGoalsData: tempGoalData })
                } else {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    leadersheepGoalsData: []
                });
            })
    }
    checkMode = (applicationMode) => {
        this.setState({
            show: !this.state.show,
            popupMode: applicationMode
        })
    }

    render() {
        const { directReporteeEmailID, managerEmailID, matrixManagerEmailID, emp_id, applicationModes,messageGoal, goalApplicationMode, GoalMessageStatus, leadershipMessageStatus, leadershipApplicationMode } = this.state
        return (
            <div className="row justify-content-between align-item-center">
                <LoaderIndicator loading={this.state.loading} />
                <div className="cfm-card p-3 ml-1 mr-0">
                    <div className='d-flex justify-content-between mb-2'>
                        <div className='cfm_label text-start'>Self Feedback</div>
                        {/* {this.state.goalsData.length !=0 && this.state.leadersheepGoalsData.length !=0 && */}
                        {(goalApplicationMode === 'write' || leadershipApplicationMode === 'write') &&
                            <div
                                type='button'
                                className='d-flex justify-content-end p-0'
                            >
                                <img
                                    alt="sendicon"
                                    src={sendicon}
                                    className="send-img mt-2"
                                ></img>
                                <div
                                    type="button"
                                    className='send-text'
                                    onClick={() => this.checkMode(applicationModes, messageGoal)}
                                >
                                    Nudge for feedback request
                                </div>
                            </div>
                        }
                        {/* } */}
                    </div>
                    <div className='row d-flex justify-content-center mt-3 mb-4'>
                        {this.state.todoSelect === 'Competency' ?
                            <>
                                <div className="row deactivate-button-left d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Goal' }, () => this.getGoalsDetails())}>Goals</div>
                                <div className="row activate-button-right d-flex justify-content-center" >Leadership Behaviours</div>
                            </>
                            :
                            <>
                                <div className="row activate-button-left d-flex justify-content-center">Goals</div>
                                <div className="row deactivate-button-right d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Competency' }, () => this.getLeadersheepGoals())}>Leadership Behaviours</div>
                            </>
                        }
                    </div>
                    <div className='row'>
                        {this.state.todoSelect === 'Goal' ?
                            <div className='w-100'>
                                {applicationModes === 'close' ?
                                    <div className='d-flex h-60vh align-item-center flex-column w-100 justify-content-center'>
                                        <img className='align-self-center' src={nodata} alt="Nodata" />
                                        <div className='row'>
                                            <label className='card-content-title grey-color'>{messageGoal}</label>
                                        </div>
                                    </div>
                                    :
                                    <GoalScreen
                                        getGoadlsDetail={this.getGoalsDetails}
                                        data={this.state.goalsData}
                                        getPerformanceData={() => this.props.getPerformanceData('All')}
                                        applicationMode={applicationModes}
                                        // disabledMessage={disabledMessage}
                                        empId={emp_id}
                                        directReporteeEmailID={directReporteeEmailID}
                                        managerEmailID={managerEmailID}
                                        matrixManagerEmailID={matrixManagerEmailID}
                                        elsecondition={this.state.elsecondition}
                                    />
                                }
                            </div> : null
                        }
                        {this.state.todoSelect === 'Competency' &&
                            <div className='w-100'>
                                {applicationModes === 'close' ?
                                    <div className='d-flex h-60vh align-item-center flex-column w-100 justify-content-center'>
                                        <img className='align-self-center' src={nodata} alt="Nodata" />
                                        <div className='row'>
                                            <label className='card-content-title grey-color'>{messageGoal}</label>
                                        </div>
                                    </div>
                                    :
                                    <LeadershipScreen
                                        getLeadersheepGoals={this.getLeadersheepGoals}
                                        data={this.state.leadersheepGoalsData}
                                        getPerformanceData={() => this.props.getPerformanceData('All')}
                                        applicationMode={applicationModes}
                                        // disabledMessage={disabledMessage}
                                        empId={emp_id}
                                        directReporteeEmailID={directReporteeEmailID}
                                        managerEmailID={managerEmailID}
                                        matrixManagerEmailID={matrixManagerEmailID}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>

                {this.state.show &&
                    <FeedbackRequestPopupNew
                        goalsdata={this.state.goalsData}
                        leadersheepGoalsdata={this.state.leadersheepGoalsData}
                        type={'goalsFeedback'}
                        callFunctionGetLeadersheepGoals={this.getLeadersheepGoals}
                        loading={this.state.loading}
                        onDismiss={() => this.setState({ show: false })}
                        loginData={this.props.loginData}
                        applicationModes={applicationModes}
                        messageGoal={messageGoal}
                        leadershipMessageStatus={leadershipMessageStatus}
                        GoalMessageStatus={GoalMessageStatus}
                        leadershipApplicationMode={leadershipApplicationMode}
                        goalApplicationMode={goalApplicationMode}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default (connect(mapStatesToProps)(Feedback));