import React, { Component } from 'react'
import './ToDo.css'
import Dropdown from '../../../shared/components/Dropdown'
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { toast } from 'react-toastify';
const chooseSelect = 'Select'

class AddNewTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            priorityData: [],
            statusData: [],
            priorityValue: '',
            priorityKey: chooseSelect,
            statusValue: '',
            statusKey: chooseSelect,
            taskDueDate: '',
            todoDisplayName: 'Tasks',
            url: '',
            loading: false
        }
    }
    componentDidMount() {
        this.getMSTodoMasterData()

    }
    updateActionTake = () => {
        const { data, action } = this.props
        if (action === 'update') {
            this.setState({
                title: data.title,
                taskDueDate: data.dueDateTime ? data.dueDateTime.dateTime : '',
            })
            let statusList = this.state.statusData.filter((list) => list.value === data.status)
            let priorityList = this.state.priorityData.filter((list) => list.value === data.importance)
            this.setState({
                priorityValue: priorityList[0].value ? priorityList[0].value : '',
                priorityKey: priorityList[0].key ? priorityList[0].key : '',
                statusValue: statusList[0].value ? statusList[0].value : '',
                statusKey: statusList[0].key ? statusList[0].key : ''
            })

        }
    }
    getMSTodoMasterData = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }

        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getMSTodoMasterData, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        statusData: res.data.data.status ? res.data.data.status : [],
                        priorityData: res.data.data.importance ? res.data.data.importance : []
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            }).then(() =>
                this.updateActionTake()
            )
    }
    createTask = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }

        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.state.todoDisplayName,
            title: this.state.title,
            task_due_date: this.state.taskDueDate + "T23:59:00",
            priority: this.state.priorityValue,
            status: this.state.statusValue,
            comp_code: this.props.loginData.userData.CompCode
        }
        fetchApiCall(Config.createToDoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.onDismiss()
                } else {
                    this.setState({
                        loading: false,
                        todoData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    validateTasks = () => {
        if (this.state.title === '') {
            toast.error('Please enter description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.taskDueDate === '') {
            toast.error('Please enter due date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.priorityKey === chooseSelect) {
            toast.error('Please select priority', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.statusKey === chooseSelect) {
            toast.error('Please select status', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            if (this.props.action === 'add') {
                this.createTask()
            }
            else {
                this.updateTask(this.props.data)
            }
        }
    }
    updateTask = (data) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            todo_display_name: this.props.type,
            title: this.state.title,
            task_due_date: this.state.taskDueDate,
            priority: this.state.priorityValue,
            status: this.state.statusValue,
            task_id: data.id,
            comp_code: this.props.loginData.userData.CompCode
        }
        fetchApiCall(Config.updateMSTodoTasks, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);

                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.onDismiss()
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }


    render() {
        const { action, data } = this.props
        console.log('dataupdate', data);
        console.log("this.state.taskDueDate:", this.state.taskDueDate + "T23:59:00")
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{action === 'add' ? 'New Task Details' : 'Update Task Details'}</h4>
                            <button type="button" className="close" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className='p-sub-note ml-3 mb-2'>{'Note: You can add only personal tasks here.'}</div>
                        <div className="modal-body">
                            <form className='todoModalBody'>
                                <label className='form-label ml-2'>Task Description</label>
                                <input
                                    className='inpt'
                                    type='text'
                                    placeholder='Enter Task Description'
                                    value={this.state.title}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                />
                                <label className='form-label ml-2 mt-2'>Due Date</label>
                                <input
                                    className='inpt'
                                    type='date'
                                    placeholder='Enter Title'
                                    value={this.state.taskDueDate}
                                    onChange={(e) => this.setState({ taskDueDate: e.target.value })}
                                />
                                <label className='form-label ml-2 mt-2'>Set Priority</label>
                                <Dropdown
                                    data={this.state.priorityData}
                                    selectedValue={(data) => this.setState({ priorityValue: data.value, priorityKey: data.key })}
                                    value={this.state.priorityKey}
                                    render={'key'}
                                />
                                <label className='form-label ml-2 mt-2'>Status</label>
                                <Dropdown
                                    data={this.state.statusData}
                                    selectedValue={(data) => this.setState({ statusValue: data.value, statusKey: data.key })}
                                    value={this.state.statusKey}
                                    render={'key'}
                                />
                            </form>
                        </div>
                        <div className='modal-footer btnBox'>
                            <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />

                            <input type='button' value='SAVE' className='ml-2 validateBtn' onClick={() => this.validateTasks()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNewTask;