import React, { useState, useEffect } from 'react'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import ServiceUnavailable from '../../../../shared/ServiceUnavailable'
import Table from '../../../../shared/table/Table'
import { toast } from 'react-toastify';
import { fetchApiCallWithService, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { commonHeadersAcceptJson, commonHeadersAcceptPDF, toaster } from '../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import moment from 'moment';
import OTServiceUnavailable from '../Popup/OTServiceUnavailable';


function OTDashboard() {
    const adminLoginState = useSelector(state => state.adminLoginState);
    const loginState = useSelector(state => state.loginState);
    const [loading, setLoading] = useState(false)
    const [isUser, setIsUser] = useState()
    const [activeTab, setActiveTab] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [costCenterSup, setCostCenterSup] = useState([])
    const [costCenterMag, setCostCenterMag] = useState([])
    const [orgUnitSup, setOrgUnitSup] = useState([])
    const [orgUnitmag, setOrgUnitmag] = useState([])
    const [isOTBookingUnavailable, isSetOTBookingUnavailable] = useState(false)
    const [isOTBookingReason, isSetOTBookingReason] = useState('')
    const [isOTRegularizeUnavailable, isSetOTRegularizeUnavailable] = useState(false)
    const [isOTRegularizeReason, isSetOTRegularizeReason] = useState('')
    const [isOTHolidayUnavailable, isSetOTHolidayUnavailable] = useState(false)
    const [isOTHolidayReason, isSetOTHolidayReason] = useState('')
    const [isRegularizeHolidayBookingUnavailable, isSetRegularizeHolidayBookingUnavailable] = useState(false)
    const [isRegularizeHolidayBookingReason, isSetRegularizeHolidayBookingReason] = useState('')
    const [isOTApprovalUnavailable, isSetOTApprovalUnavailable] = useState(false)
    const [isOTApprovalReason, isSetOTApprovalReason] = useState('')
    const [isOTRegularizeApprovalUnavailable, isSetOTRegularizeApprovalUnavailable] = useState(false)
    const [isOTRegularizeApprovalReason, isSetOTRegularizeApprovalReason] = useState('')
    const [isOTHolidayApprovalUnavailable, isSetOTHolidayApprovalUnavailable] = useState(false)
    const [isOTHolidayApprovalReason, isSetOTHolidayApprovalReason] = useState('')
    const [isRegularizeHolidayApprovalUnavailable, isSetRegularizeHolidayApprovalUnavailable] = useState(false)
    const [isRegularizeHolidayApprovalReason, isSetRegularizeHolidayApprovalReason] = useState('')
    const [apiBody, setApiBody] = useState(activeTab === "OTBooking" || activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'HolidayBooking' || activeTab === 'RegularizeHolidayBooking' ? {
        status: "Pending",
        supervisor_emp_id: loginState.userData.Perno,
        // supervisor_emp_id: "00600015",
        from_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
        to_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
        ot_type: activeTab === 'OTBooking' || activeTab === 'OTApproveReject' ? 'Normal' : activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'RegularizeOTApprove' ? "Regularize" : activeTab === 'HolidayBooking' || activeTab === 'HolidayApprove' ? "Booking" : activeTab === 'RegularizeHolidayBooking' || activeTab === 'RegularizeHolidayApprove' ? "Regularize_Booking": "Normal",
        emp_id: null,
        page_number: 1
    } : {});
    const [apiManagerBody, setApiManagerBody] = useState(
        activeTab === "OTApproveReject" || activeTab === 'RegularizeOTApprove' || activeTab === 'HolidayApprove' || activeTab === 'RegularizeHolidayApprove' ?
            {
                status: "Booked & Pending for Approval",
                manager_emp_id: loginState.userData.Perno,
                from_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
                to_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
                ot_type: activeTab === 'OTBooking' || activeTab === 'OTApproveReject' ? 'Normal' : activeTab === 'RegularizeOT' || activeTab === 'RegularizeOTBooking' || activeTab === 'RegularizeOTApprove' ? "Regularize" : activeTab === 'HolidayBooking' || activeTab === 'HolidayApprove' ? "Booking" : activeTab === 'RegularizeHolidayBooking' || activeTab === 'RegularizeHolidayApprove' ? "Regularize_Booking" :"Normal",
                emp_id: null,
                page_number: 1
            } : {}
    );
    const [bookOTBody, SetBookOTBody] = useState({});
    const [tableData, setTableData] = useState([]);
    const [isEmployee, setIsEmployee] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [supTableData, setsupTableData] = useState([
    ]);
    const [headers, setHeaders] = useState([
        {
            label: 'Checkbox',
            width: '1%',
        },
        {
            label: 'Sr.No',
            width: '0%',
            valueExtractor: (item, index) => index + 1,
        },
        {
            label: 'Employee Name',
            width: '10%',
            valueExtractor: (item) => item.emp_name || '-',
        },
        {
            label: 'Emp Id',
            width: '6%',
            valueExtractor: (item) => item.emp_id || '-',
        },
        {
            label: 'Date',
            width: '6% !important',
            valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
        },
        // {
        //     label: 'Cost Center',
        //     width: '10%',
        //     valueExtractor: (item) => item.cost_center || '-',
        // },

        // {
        //     label: 'To Date',
        //     width: '20%',
        //     valueExtractor: (item) => item.ot_date || '-',
        // },
        // {
        //     label: 'From Time',
        //     width: '12%',
        //     valueExtractor: (item) => item.ot_in || '-',
        // },
        // {
        //     label: 'To Time',
        //     width: '12%',
        //     valueExtractor: (item) => item.ot_out || '-',
        // },
        {
            label: 'Day Type',
            width: '5%',
            valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
        },
        // {
        //     label: 'Shift Type',
        //     width: '5%',
        //     valueExtractor: (item) => item.shift_type || '-',
        // },
        // {
        //     label: 'Shift Time',
        //     width: '5%',
        //     valueExtractor: (item) => item.shift_time || '-',
        // },
        {
            label: 'Shift In',
            width: '5%',
            valueExtractor: (item) => item.sw_in || '-',
        },
        {
            label: 'Shift Out',
            width: '5%',
            valueExtractor: (item) => item.sw_out || '-',
        },
        {
            label: 'OT In',
            width: '5%',
            valueExtractor: (item) => item.ot_in || '-',
        },
        {
            label: 'OT Out',
            width: '5%',
            valueExtractor: (item) => item.ot_out || '-',
        },
        {
            label: 'OT Hrs',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs || '-',
        },
        // {
        //     label: 'OT Min',
        //     width: '5%',
        //     valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
        // },
        {
            label: 'OT Max',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
        },

        {
            label: 'BC',
            width: '5%',
            valueExtractor: (item) => item.cost_center,
        },
        {
            label: 'Org Unit',
            width: '5%',
            valueExtractor: (item) => item.org_unit,
        },
        {
            label: 'Booked Hrs (HH:MM)',
            width: '2%',
            valueExtractor: (item) => item.supervisor_ot_hrs,
        },
        {
            label: 'Assigned BC',
            width: '8%',
            valueExtractor: (item) => item.booked_cost_center ?? '-',
        },
        {
            label: 'Action',
            width: '8%',
            valueExtractor: () => 'Action',
        },
    ]);

    const [managerHeaders, setManaerHeaders] = useState([
        {
            label: 'Checkbox',
            width: '1%',
        },
        {
            label: 'Sr.No',
            width: '0%',
            valueExtractor: (item, index) => index + 1,
        },
        {
            label: 'Employee Name',
            width: '10%',
            valueExtractor: (item) => item.emp_name || '-',
        },
        {
            label: 'Employee Id',
            width: '10%',
            valueExtractor: (item) => item.emp_id || '-',
        },
        {
            label: 'Booked By',
            width: '10%',
            valueExtractor: (item) => item.booked_by || '-',
        },
        {
            label: 'Date',
            width: '6% !important',
            valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
        },

        {
            label: 'Day Type',
            width: '20%',
            valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
        },
        // {
        //     label: 'Shift Type',
        //     width: '12%',
        //     valueExtractor: (item) => item.shift_type || '-',
        // },
        // {
        //     label: 'Shift Time',
        //     width: '12%',
        //     valueExtractor: (item) => item.shift_time || '-',
        // },
        {
            label: 'Shift In',
            width: '5%',
            valueExtractor: (item) => item.sw_in || '-',
        },
        {
            label: 'Shift Out',
            width: '5%',
            valueExtractor: (item) => item.sw_out || '-',
        },
        {
            label: 'OT In',
            width: '5%',
            valueExtractor: (item) => item.ot_in || '-',
        },
        {
            label: 'OT Out',
            width: '5%',
            valueExtractor: (item) => item.ot_out || '-',
        },
        {
            label: 'OT Hrs',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs || '-',
        },
        {
            label: 'OT Min',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
        },
        {
            label: 'OT Max',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
        },
        {
            label: 'BC',
            width: '10%',
            valueExtractor: (item) => item.cost_center || '-',
        },
        {
            label: 'Org Unit',
            width: '5%',
            valueExtractor: (item) => item.org_unit,
        },
        {
            label: 'Assigned BC',
            width: '8%',
            valueExtractor: (item) => item.booked_cost_center ?? '-',
        },
        {
            label: 'Status',
            width: '15%',
            valueExtractor: (item) => item.status || '-',
        },
        {
            label: 'Supervisor Hrs',
            width: '2%',
            valueExtractor: (item) => item.supervisor_ot_hrs,
        },
        // ...(activeStatus === 'Booked & Pending for Approval' ? [{
        //     label: 'Approve OT Hrs',
        //     width: '2%',
        //     valueExtractor: (item) => item.manager_ot_hrs,
        // }] : [
        //     {
        //         label: 'Approve OT Hrs',
        //         width: '2%',
        //         valueExtractor: (item) => item.manager_ot_hrs || '-',
        //     }
        // ]),
        {
            label: 'Approve OT Hrs',
            width: '2%',
            valueExtractor: (item) => item.manager_ot_hrs,
        },

        {
            label: 'Action',
            width: '8%',
            valueExtractor: () => 'Action',
        },
    ]);

    const props = {
        adminLoginState: adminLoginState
    }

    useEffect(() => {
        getUserRole();
        checkService()
        if (isUser === 'manager') {
            setActiveTab('OTApproveReject')
        }
        if (isUser === 'supervisor' || isUser === 'manager supervisor') {
            setActiveTab('OTBooking')
        }


    }, [isUser])


    useEffect(() => {
        if (Object.keys(apiBody).length > 0) {
            getOTDetails();
        }
    }, [apiBody]);



    useEffect(() => {
        const showArray = ['Approved & Pending for Update', 'Approved & Updated', 'Error in Posting', 'Approved & Paid']
        const showactiveColumnForHolidayWeeklyOff = ['OTBooking', "RegularizeOT", 'RegularizeOTBooking', 'OTApproveReject', 'RegularizeOTApprove']
        setHeaders([
            {
                label: 'Checkbox',
                width: '1%',
            },
            {
                label: 'Sr.No',
                width: '0%',
                valueExtractor: (item, index) => index + 1,
            },
            {
                label: 'Employee Name',
                width: '10%',
                valueExtractor: (item) => item.emp_name || '-',
            },
            {
                label: 'Emp Id',
                width: '6%',
                valueExtractor: (item) => item.emp_id || '-',
            },
            ...(activeStatus === 'Booked & Pending for Approval' || showArray.includes(activeStatus) ? [{
                label: 'Booked By',
                width: '2%',
                valueExtractor: (item) => item.booked_by || '-'
            }] : []),
            {
                label: 'Date',
                width: '6% !important',
                valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
            },
            {
                label: 'Day Type',
                width: '5%',
                valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
            },
            // {
            //     label: 'Shift Type',
            //     width: '5%',
            //     valueExtractor: (item) => item.shift_type || '-',
            // },
            // {
            //     label: 'Shift Time',
            //     width: '5%',
            //     valueExtractor: (item) => item.shift_time || '-',
            // },
            {
                label: 'Shift In',
                width: '5%',
                valueExtractor: (item) => item.sw_in || '-',
            },
            {
                label: 'Shift Out',
                width: '5%',
                valueExtractor: (item) => item.sw_out || '-',
            },
            // {
            //     label: 'OT In',
            //     width: '5%',
            //     valueExtractor: (item) => item.ot_in || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT In',
                width: '5%',
                valueExtractor: (item) => item.ot_in || '-',
            }] : []),
            // {
            // label: 'OT Out',
            // width: '5%',
            // valueExtractor: (item) => item.ot_out || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Out',
                width: '5%',
                valueExtractor: (item) => item.ot_out || '-',
            }] : []),
            // {
            // label: 'OT Hrs',
            // width: '5%',
            // valueExtractor: (item) => item.proposed_ot_hrs || '-',
            // },
            ...(activeTab == "RegularizeOT" || activeTab == 'RegularizeOTBooking' || activeTab == 'HolidayBooking' || activeTab == 'RegularizeHolidayBooking' ? [] : [{
                label: 'OT Hrs',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs || '-',
            }]),
            ...(activeTab == "RegularizeOT" || activeTab == 'RegularizeOTBooking' || activeTab == "OTBooking" ? [{
                label: 'OT Min',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
            }] : []),
            // {
            //     label: 'OT Max',
            //     width: '5%',
            //     valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Max',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
            }] : []),

            {
                label: 'BC',
                width: '5%',
                valueExtractor: (item) => item.cost_center || '-',
            },
            {
                label: 'Org Unit',
                width: '5%',
                valueExtractor: (item) => item.org_unit,
            },
            ...(['HolidayApprove', 'HolidayBooking','RegularizeHolidayApprove', 'RegularizeHolidayBooking'].includes(activeTab) && apiBody.day_type == "O" ? [] : [{
                label: 'Booked Hrs (HH:MM)',
                width: '2%',
                valueExtractor: (item) => item.supervisor_ot_hrs,
            }]),
            ...(showArray.includes(activeStatus) ? [{
                label: 'Approve OT Hrs',
                width: '2%',
                valueExtractor: (item) => item.manager_ot_hrs,
            }] : []),
            ...(activeStatus === 'Approved & Paid' ? [{
                label: 'Authorized HRS',
                width: '2%',
                valueExtractor: (item) => item.authorized_ot_hrs || '-',
            }] : []),
            {
                label: 'Assigned BC',
                width: '8%',
                valueExtractor: (item) => item.booked_cost_center ?? '-',
            },
            {
                label: 'Action',
                width: '8%',
                valueExtractor: () => 'Action',
            },
        ])

        setManaerHeaders([
            {
                label: 'Checkbox',
                width: '1%',
            },
            {
                label: 'Sr.No',
                width: '0%',
                valueExtractor: (item, index) => index + 1,
            },
            {
                label: 'Employee Name',
                width: '10%',
                valueExtractor: (item) => item.emp_name || '-',
            },
            {
                label: 'Employee Id',
                width: '10%',
                valueExtractor: (item) => item.emp_id || '-',
            },
            {
                label: 'Booked By',
                width: '10%',
                valueExtractor: (item) => item.booked_by || '-',
            },
            {
                label: 'Date',
                width: '6% !important',
                valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
            },

            {
                label: 'Day Type',
                width: '20%',
                valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
            },
            // {
            //     label: 'Shift Type',
            //     width: '12%',
            //     valueExtractor: (item) => item.shift_type || '-',
            // },
            // {
            //     label: 'Shift Time',
            //     width: '12%',
            //     valueExtractor: (item) => item.shift_time || '-',
            // },
            {
                label: 'Shift In',
                width: '5%',
                valueExtractor: (item) => item.sw_in || '-',
            },
            {
                label: 'Shift Out',
                width: '5%',
                valueExtractor: (item) => item.sw_out || '-',
            },
            // {
            //     label: 'OT In',
            //     width: '5%',
            //     valueExtractor: (item) => item.ot_in || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT In',
                width: '5%',
                valueExtractor: (item) => item.ot_in || '-',
            }] : []),
            // {
            //     label: 'OT Out',
            //     width: '5%',
            //     valueExtractor: (item) => item.ot_out || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Out',
                width: '5%',
                valueExtractor: (item) => item.ot_out || '-',
            }] : []),
            // {
            //     label: 'OT Hrs',
            //     width: '5%',
            //     valueExtractor: (item) => item.proposed_ot_hrs || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Hrs',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs || '-',
            }] : []),
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Min',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
            }] : []),
            // {
            //     label: 'OT Max',
            //     width: '5%',
            //     valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
            // },
            ...(showactiveColumnForHolidayWeeklyOff.includes(activeTab) ? [{
                label: 'OT Max',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
            }] : []),
            {
                label: 'BC',
                width: '10%',
                valueExtractor: (item) => item.cost_center || '-',
            },
            {
                label: 'Org Unit',
                width: '5%',
                valueExtractor: (item) => item.org_unit,
            },
            {
                label: 'Assigned BC',
                width: '8%',
                valueExtractor: (item) => item.booked_cost_center ?? '-',
            },
            {
                label: 'Status',
                width: '15%',
                valueExtractor: (item) => item.status || '-',
            },
            ...(activeTab == 'HolidayApprove' || activeTab == 'RegularizeHolidayApprove' ? [] : [{
                label: 'Supervisor Hrs',
                width: '2%',
                valueExtractor: (item) => item.supervisor_ot_hrs,
            }]),
            // ...(activeStatus === 'Booked & Pending for Approval' ? [{
            //     label: 'Approve OT Hrs',
            //     width: '2%',
            //     valueExtractor: (item) => item.manager_ot_hrs,
            // }] : [
            //     {
            //         label: 'Approve OT Hrs',
            //         width: '2%',
            //         valueExtractor: (item) => item.manager_ot_hrs || '-',
            //     }
            // ]),
            // {
            //     label: 'Approve OT Hrs',
            //     width: '2%',
            //     valueExtractor: (item) => item.manager_ot_hrs,
            // },
            ...(['HolidayApprove', 'HolidayBooking','RegularizeHolidayApprove','RegularizeHolidayBooking'].includes(activeTab) && apiManagerBody.day_type == "O" ? [] : [{
                label: 'Approve OT Hrs',
                width: '2%',
                valueExtractor: (item) => item.manager_ot_hrs,
            }]),

            {
                label: 'Action',
                width: '8%',
                valueExtractor: () => 'Action',
            },
        ]);


    }, [activeStatus, activeTab, apiBody, apiManagerBody]);


    useEffect(() => {
        if (Object.keys(apiManagerBody).length > 0) {
            getOtRequestManager();
        }
    }, [apiManagerBody]);

    const checkService = () => {
        loginState.serviceEnableDisableData.forEach((item, i) => {
            if (item.service_type == "ot_booking" && item.is_active == false) {
                isSetOTBookingUnavailable(true)
                isSetOTBookingReason(item.reason)
            }
            if (item.service_type == "ot_regularize" && item.is_active == false) {
                isSetOTRegularizeUnavailable(true)
                isSetOTRegularizeReason(item.reason)
            }
            if (item.service_type == "ot_holiday_weekly_off_booking" && item.is_active == false) {
                isSetOTHolidayUnavailable(true)
                isSetOTHolidayReason(item.reason)
            }
            if (item.service_type == "ot_holiday_weekly_off_regularize_booking" && item.is_active == false) {
                isSetRegularizeHolidayBookingUnavailable(true)
                isSetRegularizeHolidayBookingReason(item.reason)
            }
            if (item.service_type == "ot_booking_approval" && item.is_active == false) {
                isSetOTApprovalUnavailable(true)
                isSetOTApprovalReason(item.reason)
            }
            if (item.service_type == "ot_regularize_approval" && item.is_active == false) {
                isSetOTRegularizeApprovalUnavailable(true)
                isSetOTRegularizeApprovalReason(item.reason)
            }
            if (item.service_type == "ot_holiday_weekly_off_booking_approval" && item.is_active == false) {
                isSetOTHolidayApprovalUnavailable(true)
                isSetOTHolidayApprovalReason(item.reason)
            }
            if (item.service_type == "ot_holiday_weekly_off_regularize_booking_approval" && item.is_active == false) {
                isSetRegularizeHolidayApprovalUnavailable(true)
                isSetRegularizeHolidayApprovalReason(item.reason)
            }
        })
    }

    const getOTDetails = () => {
        setLoading(true)

        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCallWithService(Config.getOtDetails, apiBody, headers, false, "accessToken", "POST", "login", "")
            .then((res) => {
                setLoading(false)
                sessionOut(props, res);
                if (apiBody.export == true && res.status == "200") {
                    toaster("success", res.data.message)
                }
                if (res.data.data.ot_data) {
                    setsupTableData(res.data.data.ot_data)
                    setCurrentPage(res?.data?.data?.page_number || 1)
                    setTotalPages(res?.data?.data?.total_pages)
                    if (res.data.data.is_employee_tagged === false && res.data.data.ot_data.length !== 0) {
                        setIsEmployee(false)

                    }

                }
                if (apiBody.export && res.status != "200") {
                    toaster("warning", res.data.message)

                }
            })
            .catch((err) => {
            });

    }

    const getUserRole = () => {
        // const reqBody = {
        //     emp_id: loginState.userData.Perno,
        // }

        // const headers = {
        //     "Content-Type": "application/json",
        // };
        // fetchApiCallWithService(Config.getOTRole, reqBody, headers, false, "accessToken", "POST", "login", "")
        //     .then((res) => {
        //         setLoading(false)
        //         sessionOut(props, res);
        if (loginState.OTData) {
            if (loginState.OTData.is_supervisor == true && loginState.OTData.is_manager == true) {
                setIsUser("manager supervisor")
            }
            else if (loginState.OTData.is_manager == true) {
                setIsUser("manager")
            }
            else if (loginState.OTData.is_supervisor == true) {
                setIsUser("supervisor")
            }
            let ccSup = loginState.OTData.supervisor_cost_center.map((cc, idx) => ({
                ...cc, value: cc.cost_center, id: idx,
                label: cc.cost_center
            }))
            let ouSup = loginState.OTData.supervisor_org_unit.map((ou, idx) => ({
                ...ou, value: ou.org_unit, id: idx,
                label: ou.org_unit
            }))
            let ccMag = loginState.OTData.manager_cost_center.map((cc, idx) => ({
                ...cc, value: cc.cost_center, id: idx,
                label: cc.cost_center
            }))
            let ouMag = loginState.OTData.manager_org_unit.map((ou, idx) => ({
                ...ou, value: ou.org_unit, id: idx,
                label: ou.org_unit
            }))
            setCostCenterSup(ccSup)
            setCostCenterMag(ccMag)
            setOrgUnitSup(ouSup)
            setOrgUnitmag(ouMag)

        }

        // }
        // )
        //             .catch ((err) => {
        //         setLoading(false)
        //     });

    }

    const getOtRequestManager = () => {
        setLoading(true)

        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCallWithService(Config.getOtRequestManager, apiManagerBody, headers, false, "accessToken", "POST", "login", "")
            .then((res) => {
                setLoading(false)
                sessionOut(props, res);
                if (apiManagerBody.export == true && res.status == "200") {
                    toaster("success", res.data.message)
                }
                if (res.data.data.ot_data) {
                    setTableData(res.data.data.ot_data)
                    setCurrentPage(res?.data?.data?.page_number || 1)
                    setTotalPages(res?.data?.data?.total_pages)
                }
                if (apiManagerBody.export && res.status != "200") {
                    toaster("warning", res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false)
            });

    }

    const handleUpdateBC = (newValue, rowIndex) => {
        const numericValue = parseFloat(newValue || 0);
        const updateRow = (data, index) => {
            return data.map((o, i) => {
                if (i === index) {
                    return {
                        ...o,
                        booked_cost_center: newValue
                    }
                }
                return o;
            });
        };

        if (isUser === 'supervisor' || isUser === 'manager supervisor') {
            if (!isNaN(numericValue)) {
                setsupTableData(s => updateRow(s, rowIndex));
            }
        }
    }
    const handleUpdateData = (newHour, newMinute, rowIndex) => {

        const newValue = `${newHour}:${newMinute}`;

        const updateRow = (data, index, newValue) => {
            return data.map((o, i) => {
                if (i === index) {
                    return {
                        ...o,
                        supervisor_ot_hrs: newValue,
                    };
                }
                return o;
            });
        };

        const updateRowManager = (data, index, newValue) => {
            return data.map((o, i) => {
                if (i === index) {
                    return {
                        ...o,
                        manager_ot_hrs: newValue,
                    };
                }
                return o;
            });
        };

        if (isUser === 'supervisor' || (isUser === 'manager supervisor' && (activeTab === 'OTBooking' || activeTab === 'RegularizeOTBooking' || activeTab === 'HolidayBooking' || activeTab === 'RegularizeHolidayBooking'))) {
            if (Array.isArray(rowIndex)) {
                const updatedData = supTableData.map((row, index) => {
                    if (rowIndex.includes(index)) {
                        return {
                            ...row,
                            supervisor_ot_hrs: newValue,
                        };
                    }
                    return row;
                });
                setsupTableData(updatedData);
            } else if (rowIndex || rowIndex === 0) {
                setsupTableData((s) => updateRow(s, rowIndex, newValue));
            }
        } else {

            if (Array.isArray(rowIndex)) {
                const updatedData = tableData.map((row, index) => {
                    if (rowIndex.includes(index)) {
                        return {
                            ...row,
                            manager_ot_hrs: newValue,
                        };
                    }
                    return row;
                });
                setTableData(updatedData);
            } else if (rowIndex || rowIndex === 0) {
                setTableData((s) => updateRowManager(s, rowIndex, newValue));
            }
        }
    };

    // const handleUpdateData = (newValue, rowIndex) => {

    //     const numericValue = parseFloat(newValue || 0);

    //     const updateRow = (data, index) => {
    //         return data.map((o, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...o,
    //                     supervisor_ot_hrs: newValue
    //                 };
    //             }
    //             return o;
    //         });
    //     };
    //     const updateRowManager = (data, index) => {
    //         return data.map((o, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...o,
    //                     manager_ot_hrs: newValue
    //                 };
    //             }
    //             return o;
    //         });
    //     };
    //     if (!isNaN(numericValue)) {
    //         if (isUser === 'supervisor' || (isUser === 'manager supervisor' && (activeTab === 'OTBooking' || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking"))) {
    //             if (Array.isArray(rowIndex)) {
    //                 if (!isNaN(numericValue)) {
    //                     const updatedData = supTableData.map((row, index) => {
    //                         if (rowIndex.includes(index)) {
    //                             return {
    //                                 ...row,
    //                                 supervisor_ot_hrs: newValue
    //                             };
    //                         }
    //                         return row;
    //                     });


    //                     setsupTableData(updatedData);
    //                 } else {
    //                     toast.error("Supervisor Hours must be less than or equal to the  OT hours.", {
    //                         position: "bottom-center",
    //                         hideProgressBar: true,
    //                         className: 'toastError'
    //                     })
    //                 }
    //             } else {
    //                 if (rowIndex || rowIndex === 0) {
    //                     if (!isNaN(numericValue)) {
    //                         setsupTableData(s => updateRow(s, rowIndex));
    //                     } else {
    //                         toast.error("Supervisor Hours must be less than or equal to the  OT hours.", {
    //                             position: "bottom-center",
    //                             hideProgressBar: true,
    //                             className: 'toastError'
    //                         })
    //                     }
    //                 }
    //             }
    //         } else {
    //             if (Array.isArray(rowIndex)) {
    //                 if (!isNaN(numericValue)) {
    //                     const updatedData = tableData.map((row, index) => {
    //                         if (rowIndex.includes(index)) {
    //                             return {
    //                                 ...row,
    //                                 manager_ot_hrs: newValue
    //                             };
    //                         }
    //                         return row;
    //                     });
    //                     setTableData(updatedData);
    //                 } else {
    //                     toast.error("Manager Hours must be less than or equal to the  OT hours.", {
    //                         position: "bottom-center",
    //                         hideProgressBar: true,
    //                         className: 'toastError'
    //                     })
    //                 }
    //             } else {
    //                 if (rowIndex || rowIndex === 0) {
    //                     if (!isNaN(numericValue)) {
    //                         setTableData(s => updateRowManager(s, rowIndex));
    //                     } else {
    //                         toast.error("Manager OT Hours must be less than or equal to the  OT hours.", {
    //                             position: "bottom-center",
    //                             hideProgressBar: true,
    //                             className: 'toastError'
    //                         })
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         toast.error("Invalid input. Please enter a numeric value.", {
    //             position: "bottom-center",
    //             hideProgressBar: true,
    //             className: 'toastError'
    //         });
    //     }
    // };


    // const handleUpdateData = (newValue, rowIndex) => {

    //     const numericValue = parseFloat(newValue || 0);
    //     console.log("numericValue", numericValue);

    //     const updateRow = (data, index) => {
    //         return data.map((o, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...o,
    //                     supervisor_ot_hrs: newValue
    //                 };
    //             }
    //             return o;
    //         });
    //     };
    //     const updateRowManager = (data, index) => {
    //         return data.map((o, i) => {
    //             if (i === index) {
    //                 return {
    //                     ...o,
    //                     manager_ot_hrs: newValue
    //                 };
    //             }
    //             return o;
    //         });
    //     };
    //     if (!isNaN(numericValue)) {
    //         if (isUser === 'supervisor' || (isUser === 'manager supervisor' && (activeTab === 'OTBooking' || activeTab === "RegularizeOTBooking" || activeTab === "HolidayBooking"))) {
    //             if (Array.isArray(rowIndex)) {
    //                 if (!isNaN(numericValue)) {
    //                     console.log("inside updateRows");
    //                     const updatedData = supTableData.map((row, index) => {
    //                         if (rowIndex.includes(index)) {
    //                             return {
    //                                 ...row,
    //                                 supervisor_ot_hrs: newValue
    //                             };
    //                         }
    //                         return row;
    //                     });


    //                     setsupTableData(updatedData);
    //                 } else {
    //                     toast.error("Supervisor Hours must be less than or equal to the  OT hours.", {
    //                         position: "bottom-center",
    //                         hideProgressBar: true,
    //                         className: 'toastError'
    //                     })
    //                 }
    //             } else {
    //                 console.log("inside updateRow");
    //                 if (rowIndex || rowIndex === 0) {
    //                     if (!isNaN(numericValue)) {
    //                         setsupTableData(s => updateRow(s, rowIndex));
    //                     } else {
    //                         toast.error("Supervisor Hours must be less than or equal to the  OT hours.", {
    //                             position: "bottom-center",
    //                             hideProgressBar: true,
    //                             className: 'toastError'
    //                         })
    //                     }
    //                 }
    //             }
    //         } else {
    //             if (Array.isArray(rowIndex)) {
    //                 if (!isNaN(numericValue)) {
    //                     const updatedData = tableData.map((row, index) => {
    //                         if (rowIndex.includes(index)) {
    //                             return {
    //                                 ...row,
    //                                 manager_ot_hrs: newValue
    //                             };
    //                         }
    //                         return row;
    //                     });
    //                     setTableData(updatedData);
    //                 } else {
    //                     toast.error("Manager Hours must be less than or equal to the  OT hours.", {
    //                         position: "bottom-center",
    //                         hideProgressBar: true,
    //                         className: 'toastError'
    //                     })
    //                 }
    //             } else {
    //                 if (rowIndex || rowIndex === 0) {
    //                     if (!isNaN(numericValue)) {
    //                         setTableData(s => updateRowManager(s, rowIndex));
    //                     } else {
    //                         toast.error("Manager OT Hours must be less than or equal to the  OT hours.", {
    //                             position: "bottom-center",
    //                             hideProgressBar: true,
    //                             className: 'toastError'
    //                         })
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         toast.error("Invalid input. Please enter a numeric value.", {
    //             position: "bottom-center",
    //             hideProgressBar: true,
    //             className: 'toastError'
    //         });
    //     }
    // };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


    return (
        <div className="row px-0 px-12px">
            <LoaderIndicator loading={loading} />
            <div className="col-md-12 ">
                {isUser === "manager supervisor" ? (
                    <div className='row pt-12px'>
                        <div className='card-new col-md-12'>
                            <div className='d-flex flex-direction-row  mt-10px mb-0px row'>
                                {/* Render tabs here */}
                                <div className={activeTab === 'OTBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('OTBooking')}>{'OT Booking'}</div>
                                <div className={activeTab === 'RegularizeOTBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeOTBooking')}>{'Regularize OT Booking'}</div>
                                <div className={activeTab === 'HolidayBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('HolidayBooking')}>{'Holiday/Weekly off Booking'}</div>
                                <div className={activeTab === 'RegularizeHolidayBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeHolidayBooking')}>{'Regularize Holiday/Weekly off Booking'}</div>
                                <div className={activeTab === 'OTApproveReject' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('OTApproveReject')}>{'OT Approve/Reject'}</div>
                                <div className={activeTab === 'RegularizeOTApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeOTApprove')}>{'Regularize OT Approve'}</div>
                                <div className={activeTab === 'HolidayApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('HolidayApprove')}>{'Holiday/Weekly off Approve'}</div>
                                <div className={activeTab === 'RegularizeHolidayApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeHolidayApprove')}>{'Regularize Holiday/Weekly off Approve'}</div>
                            </div>
                            <div className='hz-lines d-flex mx-10px mt-0px mb-0px'></div>
                            <div>
                                {activeTab === 'OTApproveReject' ? !isOTApprovalUnavailable ?
                                    < div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTApprovalReason} /> : <></>
                                }
                                {activeTab === 'RegularizeOTApprove' ? !isOTRegularizeApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTRegularizeApprovalReason} /> : <></>
                                }
                                {activeTab === 'HolidayApprove' ? !isOTHolidayApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTHolidayApprovalReason} /> : <></>
                                }
                                {activeTab === 'OTBooking' ? !isOTBookingUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTBookingReason} /> : <></>
                                }
                                {activeTab === 'RegularizeOTBooking' ? !isOTRegularizeUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTRegularizeReason} /> : <></>
                                }
                                {activeTab === 'HolidayBooking' ? !isOTHolidayUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTHolidayReason} /> : <></>
                                }
                                {activeTab === 'RegularizeHolidayBooking' ? !isRegularizeHolidayBookingUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isRegularizeHolidayBookingReason} /> : <></>
                                }
                                {activeTab === 'RegularizeHolidayApprove' ? !isRegularizeHolidayApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isRegularizeHolidayApprovalReason} /> : <></>
                                }
                            </div>
                        </div>
                    </div>
                ) : isUser === "manager" ? (

                    <div className='row pt-12px'>
                        <div className='card-new col-md-12'>
                            <div className='d-flex flex-direction-row  mt-10px mb-0px row'>
                                {/* Render tabs here */}
                                <div className={activeTab === 'OTApproveReject' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('OTApproveReject')}>{'OT Approve/Reject'}</div>
                                <div className={activeTab === 'RegularizeOTApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeOTApprove')}>{'Regularize OT Approve'}</div>
                                <div className={activeTab === 'HolidayApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('HolidayApprove')}>{'Holiday/Weekly off Approve'}</div>
                                <div className={activeTab === 'RegularizeHolidayApprove' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeHolidayApprove')}>{'Regularize Holiday/Weekly off Approve'}</div>
                            </div>
                            <div className='hz-lines d-flex mx-10px mt-0px mb-0px'></div>
                            <div>
                                {activeTab === 'OTApproveReject' ? !isOTApprovalUnavailable ?
                                    < div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTApprovalReason} /> : <></>
                                }
                                {activeTab === 'RegularizeOTApprove' ? !isOTRegularizeApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTRegularizeApprovalReason} /> : <></>
                                }
                                {activeTab === 'HolidayApprove' ? !isOTHolidayApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTHolidayApprovalReason} /> : <></>
                                }
                                {activeTab === 'RegularizeHolidayApprove' ? !isRegularizeHolidayApprovalUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={managerHeaders} data={tableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isRegularizeHolidayApprovalReason} /> : <></>
                                }
                            </div>
                        </div>
                    </div>
                ) : (

                    <div className='row pt-12px'>
                        <div className='card-new col-md-12'>
                            <div className='d-flex flex-direction-row  mt-10px mb-0px row'>
                                {/* Render tabs here */}
                                <div className={activeTab === 'OTBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('OTBooking')}>{'OT Booking'}</div>
                                <div className={activeTab === 'RegularizeOT' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeOT')}>{'Regularize OT Booking'}</div>
                                <div className={activeTab === 'HolidayBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('HolidayBooking')}>{'Holiday/Weekly off Booking'}</div>
                                <div className={activeTab === 'RegularizeHolidayBooking' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('RegularizeHolidayBooking')}>{'Regularize Holiday/Weekly off Booking'}</div>
                            </div>
                            <div className='hz-lines d-flex mx-10px mt-0px mb-0px'></div>
                            <div>
                                {activeTab === 'OTBooking' ? !isOTBookingUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTBookingReason} /> : <></>
                                }
                                {activeTab === 'RegularizeOT' ? !isOTRegularizeUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTRegularizeReason} /> : <></>
                                }
                                {activeTab === 'HolidayBooking' ? !isOTHolidayUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} setApiManagerBody={setApiManagerBody} SetbookOTBody={SetBookOTBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} />
                                    </div> :
                                    <OTServiceUnavailable reason={isOTHolidayReason} /> : <></>
                                }
                                {activeTab === 'RegularizeHolidayBooking' ? !isRegularizeHolidayBookingUnavailable ?
                                    <div className="mt-10px">
                                        <Table headers={headers} data={supTableData} user={isUser} onUpdateData={handleUpdateData} onupdateBC={handleUpdateBC} setApiBody={setApiBody} SetbookOTBody={SetBookOTBody} setApiManagerBody={setApiManagerBody} activeTab={activeTab} setActiveStatus={setActiveStatus} getOTDetails={getOTDetails} getOtRequestManager={getOtRequestManager} isEmployee={isEmployee} setIsEmployee={setIsEmployee} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} costCenterDropDownSup={costCenterSup} orgUnitDropDownSup={orgUnitSup} costCenterDropDownMag={costCenterMag} orgUnitDropDownMag={orgUnitmag} />
                                    </div> :
                                    <OTServiceUnavailable reason={isRegularizeHolidayBookingReason} /> : <></>
                                }
                            </div>
                        </div>
                    </div>
                )
                }
            </div>
        </div >
    )
}

export default OTDashboard