import axios from 'axios';
import fileDownload from 'js-file-download';
import { toaster } from '../../utils/Utils'

export const commonDownloadFile = async (url, toastTitle, fileName, OperativeDoc) => {
    try {
        if (url instanceof Blob || url instanceof File) {
            fileDownload(url, fileName);
            toaster("success", (toastTitle == true || toastTitle != undefined) ? toastTitle : "Your document has been downloaded.")
        }
        else {
            let commonCondition = (OperativeDoc === undefined || OperativeDoc == false)
            let fileTypenew = url.substring(url.lastIndexOf('/')).split('.')[1]
            let filename = commonCondition ? url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')) : fileName.split('.')[0];
            let fileType = commonCondition ? url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?')) : fileTypenew;
            await axios.get(url, {
                responseType: 'blob',
            }).then((res) => {
                fileDownload(res.data, filename + ('.' + fileType))
                toaster("success", (toastTitle == true || toastTitle != undefined) ? toastTitle : "Your document has been downloaded.")
            }).catch((err) => {
                console.log("commonDownloadFile catch error:", err)
                toaster("error", 'Unable to contact server\nPlease try again later')
            })
        }
    }
    catch (err) {
        toaster("error", 'Unable to contact server\nPlease try again later')
        console.log("commonDownloadFile ERROR ", err)
    }
}


// import axios from 'axios';
// import fileDownload from 'js-file-download';
// import { toaster } from '../../utils/Utils'

// export const commonDownloadFile = (url, toastTitle,fileName, OperativeDoc) => {
//     let commonCondition = (OperativeDoc === undefined || OperativeDoc == false )
//     let fileTypenew = url.substring(url.lastIndexOf('/')).split('.')[1]
//     let filename = commonCondition ?  url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.')) : fileName.split('.')[0] ;
//     let fileType = commonCondition ? url.substring(url.lastIndexOf('.') + 1, url.lastIndexOf('?')) : fileTypenew;
//     axios.get(url, {
//         responseType: 'blob',
//     }).then((res) => {
//         fileDownload(res.data, filename + ('.' + fileType))
//         toaster("success", (toastTitle == true || toastTitle != undefined) ? toastTitle : "Your document has been downloaded.")
//     }).catch((err) => {
//         console.log("commonDownloadFile catch error:", err)
//         toaster("error", 'Unable to contact server\nPlease try again later')
//     })
// }