import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../conflictOfInterest/COIStyle.css';
import '../../../../../assets/styles/popupStyles.css';
import '../PaySpaceStyle.css';
import down_arrow from '../../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../../assets/drishti_images/documents/up_arrow.svg';
import rightarrowbg from '../../../../../assets/drishti_images/right-arrow-bg.svg';
import backArrow from '../../../../../assets/drishti_images/backArrow.svg';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import '../../../employeeScreens/leave/LeaveScreen.css';
import moment from 'moment';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
class ChoicePayHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openStandardCard: false,
            openFixedCard: false,
            openFlexiCard: false,
            openTotalCard: false,
            openStandardOthersCard: false,
            isSubmissionList: true,

            is_service_Unavailable: false,  // service unavailble from backend
            choicePayHistoryReason: '',
            historyData: null
        }
    }

    componentDidMount() {
        this.checkService()
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "choice_pay_selection" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    choicePayHistoryReason: item.reason
                })
            }
        })
    }

    toggleStandardCard = () => {
        this.setState({ openStandardCard: !this.state.openStandardCard })
    }

    toggleFixedCard = () => {
        this.setState({ openFixedCard: !this.state.openFixedCard })
    }

    toggleFlexiCard = () => {
        this.setState({ openFlexiCard: !this.state.openFlexiCard })
    }

    openSubmissionDetails = (listValue) => {
        this.setState({ isSubmissionList: false, historyData: listValue })
    }

    toggleTotalCard = () => {
        this.setState({ openTotalCard: !this.state.openTotalCard })
    }

    toggleStandardOthersCard = () => {
        this.setState({ openStandardOthersCard: !this.state.openStandardOthersCard })
    }

    render() {
        var { loading, isSubmissionList, choicePayHistoryReason, is_service_Unavailable, historyData } = this.state;
        const { choicePayHistory } = this.props;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                {is_service_Unavailable == true ?
                    <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                        <ServiceUnavailable reason={choicePayHistoryReason} width={true} />
                    </div>
                    :
                    <>
                        {Object.keys(choicePayHistory).length !== 0 ?
                            <>
                                {isSubmissionList ?
                                    <>
                                        {choicePayHistory !== null ?
                                            <>
                                                {choicePayHistory.map((listValue, index) => {
                                                    return (
                                                        <div key={index} className='document_card mt-3 p-3 mb-24px col-md-12 pointer'>
                                                            <div
                                                                className='d-flex justify-content-between align-item-center'
                                                                onClick={() => this.openSubmissionDetails(listValue)}
                                                            >
                                                                <div className='toggle-card-heading ml-2'>
                                                                    {'Submission Received On'} {listValue.date_of_selection ? moment(listValue.date_of_selection).format("DD-MM-YYYY") : ''}
                                                                </div>
                                                                <div
                                                                    className='justify-content-end pointer'
                                                                >
                                                                    <img
                                                                        alt="hide"
                                                                        src={rightarrowbg}
                                                                        className="up_arrow"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                }
                                            </>
                                            :
                                            <>
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            </>

                                        }
                                    </>
                                    :
                                    <>
                                        <div>
                                            <div type='button' className='d-flex justify-content-start p-0'>
                                                <img
                                                    alt="sendicon"
                                                    src={backArrow}
                                                    className="send-img mt-1rem"
                                                    onClick={() => this.setState({ isSubmissionList: true })}
                                                />
                                                <div onClick={() => this.setState({ isSubmissionList: true })}
                                                    className='flex-direction-column flex-start mt-2'>
                                                    <div className='send-text'>
                                                        {'Submission Received On'} {historyData.date_of_selection ? moment(historyData.date_of_selection).format("DD-MM-YYYY") : ''}
                                                    </div>
                                                    <div className='send-text'>
                                                        {"With Effective From: ("}{historyData.start_date ? moment(historyData.start_date).format("DD-MM-YYYY") + ")" : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                <div className='d-flex justify-content-between align-item-center '
                                                    onClick={() => this.toggleStandardCard()}>
                                                    <div className='toggle-card-heading ml-2'>
                                                        {'Standard Components'}
                                                    </div>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={this.state.openStandardCard ? up_arrow : down_arrow}
                                                            className="up_arrow"
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.openStandardCard &&
                                                    <>
                                                        <div className='max-h-37vh scroll-y'>
                                                            {historyData.standard_list != 0 ?
                                                                <div className="main-contain" >
                                                                    <div className="row align-item-center justify-content-start" >
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {historyData.standard_list.map((listValue, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_description}</div></td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount.toFixed(2)}</div> </td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount.toFixed(2)}</div> </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='mt-5'>
                                                                    <DataNotFound />
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                <div className='d-flex justify-content-between align-item-center '
                                                    onClick={() => this.toggleFixedCard()}>
                                                    <div className='toggle-card-heading ml-2'>
                                                        {'Retirals'}
                                                    </div>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={this.state.openFixedCard ? up_arrow : down_arrow}
                                                            className="up_arrow"
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.openFixedCard &&
                                                    <>
                                                        <div className='max-h-37vh scroll-y'>
                                                            {historyData.retirals_list != 0 ?
                                                                <div className="main-contain" >
                                                                    <div className="row align-item-center justify-content-start" >
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {historyData.retirals_list.map((listValue, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_description}</div></td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount.toFixed(2)}</div> </td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount.toFixed(2)}</div> </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='mt-5'>
                                                                    <DataNotFound />
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                            <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                <div className='d-flex justify-content-between align-item-center '
                                                    onClick={() => this.toggleFlexiCard()}>
                                                    <div className='toggle-card-heading ml-2'>
                                                        {'Choice Pay'}
                                                    </div>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={this.state.openFlexiCard ? up_arrow : down_arrow}
                                                            className="up_arrow"
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.openFlexiCard &&
                                                    <>
                                                        <div className=''>
                                                            {historyData.choice_pay_list != 0 ?
                                                                <div className="main-contain" >
                                                                    <div className="row align-item-center justify-content-start" >
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"><div className='thead'>{'Choice Pay Component'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Current Selection (₹)'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Current Selection Annual Amt (₹)'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Current Selection Monthly Amt (₹)'}</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {historyData.choice_pay_list.filter(listValue => listValue.wage_type !== "89FL").map((listValue, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_description ? listValue.wage_description : '-'}</div></td>
                                                                                            <td>
                                                                                                {listValue.selected_value ?
                                                                                                    <div className="td-choice-pay d-flex amount-justify-content-end">
                                                                                                        <div className='dropdown filter-btn-choice-pay' >
                                                                                                            {listValue.criteria === "P" ?
                                                                                                                <>
                                                                                                                    <input
                                                                                                                        className='inpt'
                                                                                                                        type='text'
                                                                                                                        value={listValue.selected_value !== null ? listValue.selected_value + ' %' : '-'}
                                                                                                                        readOnly={true}
                                                                                                                    />
                                                                                                                </>
                                                                                                                :
                                                                                                                <>
                                                                                                                    <input
                                                                                                                        className='inpt'
                                                                                                                        type='text'
                                                                                                                        value={listValue.selected_value !== null ? listValue.selected_value : '-'}
                                                                                                                        readOnly={true}
                                                                                                                    />
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div> </td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div> </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>

                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='mt-5'>
                                                                    <DataNotFound />
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                <div className='d-flex justify-content-between align-item-center '
                                                    onClick={() => this.toggleTotalCard()}>
                                                    <div className='toggle-card-heading ml-2'>
                                                        {'Total Pay'}
                                                    </div>
                                                    <div className='justify-content-end'>
                                                        <img
                                                            alt="hide"
                                                            src={this.state.openTotalCard ? up_arrow : down_arrow}
                                                            className="up_arrow"
                                                        />
                                                    </div>
                                                </div>
                                                {this.state.openTotalCard &&
                                                    <>
                                                        <div className='max-h-37vh scroll-y'>
                                                            {historyData.total_pay_list != 0 ?
                                                                <div className="main-contain" >
                                                                    <div className="row align-item-center justify-content-start" >
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                                    <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {historyData.total_pay_list.map((listValue, index) => {
                                                                                    return (
                                                                                        <tr key={index} >
                                                                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_description ? listValue.wage_description : '-'}</div></td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div></td>
                                                                                            <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div></td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='mt-5'>
                                                                    <DataNotFound />
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            {historyData.standard_others_list == [] &&
                                                <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                                    <div className='d-flex justify-content-between align-item-center '
                                                        onClick={() => this.toggleStandardOthersCard()}>
                                                        <div className='toggle-card-heading ml-2'>
                                                            {'Standard Others'}
                                                        </div>
                                                        <div className='justify-content-end'>
                                                            <img
                                                                alt="hide"
                                                                src={this.state.openStandardOthersCard ? up_arrow : down_arrow}
                                                                className="up_arrow"
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.openStandardOthersCard &&
                                                        <>
                                                            <div className='max-h-37vh scroll-y'>
                                                                {historyData.standard_others_list != [] ?
                                                                    <div className="main-contain" >
                                                                        <div className="row align-item-center justify-content-start" >
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col"><div className='thead'>{'Pay Component'}</div></th>
                                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Annual Amount (₹)'}</div></th>
                                                                                        <th scope="col"><div className='thead parameter-align-item-right'>{'Monthly Amount (₹)'}</div></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {historyData.standard_others_list.map((listValue, index) => {
                                                                                        return (
                                                                                            <tr key={index} >
                                                                                                <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.wage_description ? listValue.wage_description : '-'}</div></td>
                                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.annual_amount !== null ? listValue.annual_amount.toFixed(2) : '-'}</div></td>
                                                                                                <td> <div className="td-choice-pay d-flex amount-justify-content-end">{listValue.monthly_amount !== null ? listValue.monthly_amount.toFixed(2) : '-'}</div></td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className='mt-5'>
                                                                        <DataNotFound />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>}
                                        </div>
                                    </>
                                }
                            </>
                            :
                            <>
                                <div className='mt-8rem'>
                                    <DataNotFound />
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        serviceData: state.activePopupState,
    }
}

export default connect(mapStatesToProps)(ChoicePayHistory);