import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import plus from "../../../../../assets/images/cfm/plus.svg"
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg'
import downloadLatestImg from '../../../../../assets/drishti_images/downloadLatestImg.svg'

import FileSaver from 'file-saver'
import { DDMMMYY, DDMMYYYY, toaster } from '../../../../../utils/Utils'
import { getPresignedDocumentUrl } from '../../../../../constants/api.service'
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction'

export const docType = ['vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.openxmlformats-officedocument.wordprocessingml.document','text/csv','docx','xlsx','eml','msg','csv','xls','application/vnd.ms-excel','doc','application/msword']
export const imageType = ['png', 'jpg', 'jpeg']

export const SuggestionAttachmentInput = (props) => {
    const { onChange, attachment, disabled, error, field, employeeId,download=true, isMandatory = true, length, maxSize, uploadNote, onDelete, from } = props

    let allowedFileTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "msg", "message/rfc822","application/msword","application/vnd.ms-excel","text/csv"]
    let StaticIdForImage = useRef(1)
    const hiddenFileInput = useRef(null);

    const [selectedUploadBill, setSelectedUploadBill] = useState({})
    const [uploadBillModal, setUploadBillModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [presignedUrl, setPresignedUrl] = useState(null)

    const submitAttachment = (attachmentBase64, attachmentName, attachmentType) => {
        let typeAttachment = attachmentType
        if (attachmentType == "vnd.openxmlformats-officedocument.wordprocessingml.document") {
            typeAttachment = "docx"
        }
        else if (attachmentType == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            typeAttachment = "xlsx"
        }
        else if (attachmentType == 'rfc822') {
            typeAttachment = 'eml'
        }
        else if (attachmentType == 'msword'){
            typeAttachment = 'doc'
        }
        else if (attachmentType == 'vnd.ms-excel'){
            typeAttachment = 'xls'
        }
        onChange({
            id: StaticIdForImage.current,
            attachment_presigned_url: attachmentBase64,
            attachment_name: attachmentName,
            attachment_extension: typeAttachment,
        })
    }

    const onImageChange = (event) => {
        let ImagePath = event?.target?.files[0] && event.target.files[0].name
        let typeOfImage = event?.target?.files[0].type ? event?.target?.files[0].type : 'msg'
        let ImageType = typeOfImage?.split('/')
        if (event.target?.files && event.target?.files[0]) {
            let maxAllowedSize = maxSize * 1024;           //MB size
            if (Math.round((event.target.files[0].size / 1024)) <= maxAllowedSize && allowedFileTypes.includes(typeOfImage)) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                    submitAttachment(e.target.result, ImagePath, ImageType[ImageType.length - 1])
                }
                StaticIdForImage.current = StaticIdForImage.current + 1
            }
            else {
                if (!allowedFileTypes.includes(typeOfImage)) {
                    toast.warn('Please upload PDF,excel,Image,Docx, .eml & .msg format only', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
                else {
                    toast.warn(`Please upload file with size less than ${maxSize} MB`, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }
        }
        hiddenFileInput.current.value = null;
    } 
    const previewAttachment = (data) => {
        setUploadBillModal(true)
        setSelectedUploadBill(data)
        previewAttachmentCall(data)
    }
    const downloadFile = async(presignedUrl, data) => {
        const toasterMsg = "Your document has been downloaded.";
        const response = await fetch(presignedUrl);
        const blobData = await  response.blob();
        commonDownloadFile(blobData, toasterMsg, data?.name);
    }
    const deleteAttachment = (data,index) => {
        onDelete(data,index)
    }

    const previewAttachmentCall = (selectedPre) => {
        setLoading(true)
        let params = {
            document_id: selectedPre?.id,
            document_path: selectedPre?.path
        }
        getPresignedDocumentUrl(params)
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    const url = URL.createObjectURL(data);
                    setPresignedUrl(url ? url : null)
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }

    console.log("selectedUploadBill DATA TYPES--->", selectedUploadBill)
    return (
        <div className='p-10px mb-1 mt-1 readOnlyInputBlock'>
            <LoaderIndicator loading={loading} />
            <div className="d-flex justify-content-between align-item-center">
                <span className={`pointer card-content-title mx-10px ${isMandatory ? "required": ""}`}>
                    {"Upload Document"}
                </span>
            </div>
            <div className='p-placeholder-text w-30'>
                <label className="form-control p-placeholder p-placeholder-admin px-2 align-item-center justify-content-between"
                    style={attachment?.length < length && !disabled ? {} : {
                        backgroundColor: '#E5E5E5',
                        color: '#1E293B'
                    }}
                    disabled={attachment?.length < length && !disabled ? false : true}
                    onChange={(event) => onImageChange(event)}
                >
                    <input
                        id="Attachment"
                        style={{ display: "none" }}
                        type="file"
                        ref={hiddenFileInput}
                        title="No Files Added"
                        disabled={attachment?.length < length && !disabled ? false : true}
                        accept='.png,.jpg,.jpeg,.pdf'
                    />
                    {attachment.length < 1 ? "Add Attachments" : `${attachment.length} ${attachment.length == 1 ? "Attachment" : "Attachments"} added`}
                    {
                        <img alt="plus" src={plus} className="plus-img"></img>
                    }
                </label>
            </div>
            <div className='d-flex justify-content-between align-item-center mb-10px'>
                <label className='noteTextMsg ml-10px'>
                    <span className='noteTextBold'>{'Note:'}</span> {uploadNote}
                </label>
            </div>
            {attachment && attachment.length > 0 ?
                <div className='scroll-y h-8rem w-100'>
                    <table className="table">
                        <thead>
                            <tr>
                                {from === 'divCordinator' && <th scope="col"><div className='thead'>{'Sr No.'}</div></th>}
                                <th scope="col"><div className='thead'>{'Name'}</div></th>
                                <th scope="col"><div className='thead'>{'Entered by'}</div></th>
                                <th scope="col"><div className='thead'>{'Entered Date'}</div></th>
                                <th scope="col"><div className='thead'>{'Preview'}</div></th>
                                {from !=='divCordinator' && <th scope="col"><div className='thead'>{'Delete'}</div></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {attachment.map((listValue, index) => {
                                console.log("171-->",listValue.created_datetime)
                                return (
                                    <tr key={index}>
                                        {from === 'divCordinator' && <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{index+1}</div></td>}
                                        <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.attachment_name ? listValue.attachment_name : listValue.name}</div></td>
                                        <td> <div className="td-choice-pay">{listValue?.role ? listValue?.role : '---'}</div></td>
                                        <td> <div className="td-choice-pay">{listValue?.created_datetime ? DDMMYYYY(listValue?.created_datetime) : '---'}</div></td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => {previewAttachment(listValue) }} />
                                            </div>
                                        </td>
                                        {from !=='divCordinator' && <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { deleteAttachment(listValue, index) }} />
                                            </div>
                                        </td>}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <span className='d-flex justify-content-center align-item-center'>
                    <label className='card-content-title grey-color' >No data found</label>
                </span>
            }

            {uploadBillModal === true &&
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{selectedUploadBill.name ? selectedUploadBill.name : ''}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={e => { setUploadBillModal(false); setSelectedUploadBill({}) }}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                        { download &&( <div className='row gap-10 mb-3 justify-content-end pointer' onClick={(e) => { downloadFile(presignedUrl, selectedUploadBill) }}>
                                        <div className='add-btn-text'>Download</div>
                                        <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                    </div>)
                                }
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    <>
                                        {imageType.includes(selectedUploadBill?.extension) &&
                                            <img src={presignedUrl ? presignedUrl : ""} alt="" className="upload_bills_modal_body" />
                                        }
                                        {(selectedUploadBill.extension === 'pdf') &&
                                            <iframe className='upload_bills_modal_body' src={presignedUrl && presignedUrl}></iframe>
                                        }
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
            {/* {<span className='p-sub-heading ml-3 errorBlock'>{error}</span>} */}
        </div>
    )
}