import React, { Component } from 'react';
import '../ProfileScreen.css';
import { connect } from 'react-redux';
import * as profileActions from '../ProfileAction';
import { bindActionCreators } from 'redux';
import MaritalStatus from './MaritalStatus';
import AddressDetails from './Address';

class BasicDetails extends Component {

    // handleChange = (type, value) => {
    //     let typeState = type.toString()
    //     this.setState({ [typeState]: value })
    // }
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    {/* <ContactDetail /> */}
                    <MaritalStatus />
                    <AddressDetails />
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        condata: state.profileState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        contactActions: bindActionCreators(profileActions, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(BasicDetails);