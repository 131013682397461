import React, { Component } from 'react';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import newTask from '../../../../assets/drishti_images/todo/newtask.svg';
import '../../employeeScreens/profile/ProfileScreen.css';
import AddNewTask from './AddNewTask';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Month } from '../../../../utils/Utils';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import todo_default_no_data from '../../../../assets/drishti_images/todo/todo_default_no_data.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import calander from '../../../../assets/drishti_images/todo/calander.svg';
import tag from '../../../../assets/drishti_images/todo/tag.svg';
import orangeTag from '../../../../assets/drishti_images/todo/orangeTag.svg';
import Greentag from '../../../../assets/drishti_images/todo/Greentag.svg';
import moment from 'moment';

const currentMonth = new Date()
class TodoScreen extends Component {
    constructor() {
        super();
        this.state = {
            admin: true,
            loading: false,

            dueDateKey: 'Due Date',
            dueDateData: [],
            dueDateValue: '',

            priorityKey: 'Priority',
            priorityData: [],
            priorityValue: '',
            statusData: [
                { key: "Not Started", value: "notStarted" },
                { key: "In Process", value: "inProgress" },
                { key: "Completed", value: "completed" },
                { key: "Waiting On Others", value: "waitingOnOthers" },
                { key: "Deferred", value: "deferred" }],

            show: false,
            todoTitle: 'Organisation',
            monthData: Month(),
            monthCode: currentMonth.getMonth() + 1,
            monthValue: '',
            admin_task_details: [],
            todoData: [],
            selectedData: {},
            actionType: '',
        }
    }

    componentDidMount() {
        this.getMSTodoMasterData()
        //for current month
        let currentMonthvalue = this.state.monthData.filter((list) => list.code == this.state.monthCode)
        this.setState({
            monthValue: currentMonthvalue[0].value,
        },
            () => this.getAdminTask()
        )
    }

    LargeTextStatus = (value) => {
        let statusList = this.state.statusData.filter((list) => list.value === value)
        return statusList[0].key
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    togglePersonal = () => {
        this.setState({ todoTitle: 'Tasks' })
    }

    addAction = (type, data) => {
        this.setState({ show: true, actionType: type, selectedData: data })
    }

    changeMonth = (data) => {
        this.setState({
            monthCode: data.code,
            monthValue: data.value,
        },
            () => this.getAdminTask()
        )
    }

    updateActionTake = () => {
        const { data, action } = this.props
        if (action === 'update') {
            this.setState({
                title: data.title,
                taskDueDate: data.dueDateTime ? data.dueDateTime.dateTime : '',
            })
            let statusList = this.state.statusData.filter((list) => list.value === data.status)
            let priorityList = this.state.priorityData.filter((list) => list.value === data.importance)
            this.setState({
                priorityValue: priorityList[0].value ? priorityList[0].value : '',
                priorityKey: priorityList[0].key ? priorityList[0].key : '',
                statusValue: statusList[0].value ? statusList[0].value : '',
                statusKey: statusList[0].key ? statusList[0].key : ''
            })
        }
    }

    getMSTodoMasterData = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getMSTodoMasterData, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        statusData: res.data.data.status ? res.data.data.status : [],
                        priorityData: res.data.data.importance ? res.data.data.importance : []
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            }).then(() =>
                this.updateActionTake()
            )
    }

    getAdminTask = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            filter_month: this.state.monthCode
        }
        fetchApiCall(Config.getAdminTask, body, headers, true, 'admin')
            .then(res => {
                console.log('filter_month:', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        admin_task_details: res.data.admin_task_details ? res.data.admin_task_details : [0]
                    });
                } else {
                    this.setState({
                        loading: false,
                        AdminLandingData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    render() {
        return (
            <div className="row p-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0 pt-0">
                    <div>
                        <div className='justify-content-between align-item-center'>
                            <div className='row px-0 pt-0' >
                                <img src={overviewButton} alt="" onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                            </div>
                            <div className='row justify-content-end'>
                                <div className='newTask pr-3'>
                                    <img
                                        src={newTask}
                                        alt=""
                                        onClick={() => this.addAction('add', '')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row mt-1'>
                            <div className='card-new col-md-12 mt-2'>
                                <div className="row justify-content-between align-item-center pt-3 px-3">
                                    <div>
                                        <label className="edit-cardtitle">Task List</label>
                                    </div>
                                    <div className=' justify-content-end'>
                                        <div>
                                            <Dropdown
                                                data={this.state.monthData}
                                                selectedValue={(data) => this.changeMonth(data)}
                                                value={this.state.monthValue}
                                                render={'value'}
                                                arrow={'true'}
                                            />
                                        </div>
                                        {/* <div className='ml-3'>
                                            <Dropdown
                                                data={this.state.priorityData}
                                                selectedValue={(data) => this.setState({ priorityValue: data.value, priorityKey: data.key })}
                                                value={this.state.priorityKey}
                                                render={'key'}
                                                arrow={'true'}
                                            />
                                        </div> */}
                                    </div>
                                </div>
                                <div className='hrLine'><hr /></div>
                                <div className='todo-inner-card'>
                                    {this.state.admin_task_details != 0 ?
                                        <div>
                                            {this.state.admin_task_details.map((item, index) =>
                                                <div className='toDoBox mt-2' key={index}>
                                                    <div className='row justify-content-between align-item-center p-1'>
                                                        <div className='row' style={{ width: '90%' }}>
                                                            <div className='ml-0'>
                                                                <div className='ml-0'>
                                                                    <div className={item.isCompleted ? 'ml-1 document-innercard-content-title line-through' : 'ml-1 document-innercard-content-title'}>{item.title}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className='row'>
                                                    <img src={toDoDelete} className='mr-3' onClick={() => this.action('delete', item)} />
                                                    <img src={toDoEdit} onClick={() => this.action('update', item)} />
                                                </div> */}
                                                    </div>
                                                    <div className='ml-1 align-item-center'>
                                                        <div className='row p-1 mt-2 align-item-center'>
                                                            <div className='row align-item-center pr-3'>
                                                                <img src={calander} alt="" style={{ width: 20, height: 20 }} />
                                                                <div className='ml-2 dueDate sm-text'>Created date:
                                                                    {item.created_date_time ? moment(item.created_date_time).format('  DD MMMM, YYYY') : '  Not Setted  '}
                                                                </div>
                                                            </div>
                                                            <div className='row align-item-center pr-3'>
                                                                <img src={calander} alt="" style={{ width: 20, height: 20 }} />
                                                                <div className='ml-2 dueDate sm-text'>Due date:
                                                                    {item.due_date ? moment(item.due_date).format('  DD MMMM, YYYY') : '  Not Setted  '}
                                                                </div>
                                                            </div>
                                                            <div className='row px-3'>
                                                                <img src={item.priority === 'high' ? tag : item.priority === 'normal' ? orangeTag : Greentag} style={{ width: 20, height: 20 }} alt="" className='ml-4' />
                                                                <div className={item.priority === 'high' ? 'ml-2 red-tag sm-text text-capitalize align-item-center' :
                                                                    item.priority === 'normal' ? 'ml-2 amber-tag sm-text text-capitalize align-item-center'
                                                                        : 'ml-2 green-tag sm-text text-capitalize align-item-center'}>{item.priority}</div>
                                                            </div>
                                                            {/* <div className='row px-3'>
                                                        <img src={radio} style={{ width: 20, height: 20 }} className='ml-3' />
                                                        <div className={item.status === 'completed' ? 'ml-2 sm-text green-tag align-item-center'
                                                            :
                                                            item.status === 'notStarted' ? 'ml-2 sm-text gray-tag align-item-center'
                                                                :
                                                                item.status === 'inProgress' ? 'ml-2 sm-text amber-tag align-item-center'
                                                                    :
                                                                    item.status === 'deferred' ? 'ml-2 sm-text red-tag align-item-center'
                                                                        :
                                                                        'ml-2 sm-text purple-tag align-item-center'}>{this.LargeTextStatus(item.status)}</div>
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        :
                                        <div className='col-md-12 d-flex align-items-center flex-column mt-5rem'>
                                            <div>
                                                <img src={todo_default_no_data} alt="Nodata" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No data found!</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.show ?
                    <AddNewTask
                        loginData={this.props.loginData}
                        type={this.state.todoTitle}
                        action={this.state.actionType}
                        data={this.state.selectedData}
                        getAdminTask={this.getAdminTask}
                        onDismiss={() => this.setState({ show: false }, () => this.togglePersonal())}
                    /> : null}
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
        loginData: state.loginState,
    }
}

export default withRouter(connect(mapStatesToProps, {})(TodoScreen));