import React, { Component } from 'react';

class ResetPassword extends Component {

    render() {
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-2" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">Reset Password</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body mt-2">
                        <div className='p-3'>
                            <div className="popup_text">Note: It will take around 30 minutes to reset your password</div>
                            <div className="popup_text pt-2 mb-2">
                                Click<a className='mx-1' href={'https://passwordreset.microsoftonline.com/'} target="_blank">here</a>to reset password
                            </div>
                        </div>
                    </div>
                    {/* <div className='p-3'>
                        <input type='button' value='CANCEL' className='ml-2 validateBtn' onClick={() => this.props.onDismiss()} />
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ResetPassword;