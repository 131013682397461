import React, { Component } from 'react'

class CancelButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { value, type, onClick } = this.props
        return (
            <input
                type={type}
                value={value}
                className='cancelBtn'
                onClick={onClick}
            />
        )
    }
}
export default CancelButton
