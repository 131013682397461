import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DeclarationPopup from '../../profile/DeclarationPopup'
import edit from '../../../../../assets/images/cfm/edit.svg';

const requestFailed = 'Unable to contact server\nPlease try again later'

class UAN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            popupVisibility: false,
            isEdit: false,
            emp_id: '',
            begining_date: '',
            end_date: '',
            account_number: '',
        };
    }
    componentDidMount() {
        this.getUANDetails(this.props.loginData.userData.Perno);
    }
    getUANDetails = (empID) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: empID
        }
        fetchApiCall(Config.getUANDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        begining_date: res.data.uan_details.begin_date ? res.data.uan_details.begin_date : '',
                        end_date: res.data.uan_details.end_date ? res.data.uan_details.end_date : '',
                        account_number: res.data.uan_details.account_number ? res.data.uan_details.account_number : '',
                        emp_id: res.data.uan_details.emp_id ? res.data.uan_details.emp_id : ''
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    updateUANDetails = () => {
        this.setState({ loading: true, popupVisibility: false })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            begin_date: this.state.begining_date ? this.state.begining_date : '',
            end_date: this.state.end_date ? this.state.end_date : '',
            account_number: this.state.account_number
        }
        fetchApiCall(Config.updateUANDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getUANDetails(this.props.loginData.userData.Perno);
                    this.setState({
                        loading: false,
                        isEdit: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    validateData = () => {
        if (this.state.account_number === '' || this.state.account_number.length < 12) {
            toast.error('Please Enter valid 12 digit Universal Account Number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else { this.setState({ popupVisibility: true }) }
    }
    render() {
        const { isEdit } = this.state

        return (
            <div>
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>PF UAN</div>
                    {!this.state.isEdit ?
                        <div className='mt-2 mr-3 pointer'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setState({ isEdit: true })}
                            ></img>
                        </div> : null
                    }
                </div>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Universal Account Number</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="number"
                                    name='account_number'
                                    value={this.state.account_number}
                                    maxLength={'12'}
                                    readOnly={!isEdit}
                                    onChange={(e) => this.setState({ account_number: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    {isEdit &&
                        <div className='row justify-content-end mb-3'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.setState({ isEdit: false })}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={this.validateData}
                                >Save</div>
                            </div>
                        </div>}
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.updateUANDetails}
                    />
                }
            </div>
        );
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
export default connect(mapStatesToProps)(UAN);
