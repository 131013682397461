import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import './DevToolStyles.css';
import { validateNumber } from '../../../../utils/Utils';

class SyncLeaves extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            leave_status: '',
        }
    }
 
    startSyncLeaves = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        var body = {leave_status: this.state.leave_status}
        fetchApiCall(Config.syncLeaves, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log("syncLeaves error : ", err)
                toast.error("Something went Wrong", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    handleChange = (e) => {
        this.setState({
            leave_status: e.target.value ? validateNumber(e.target.value) : ''
        })
    }

    loadData = () => {
        if (this.state.leave_status === '') {
            toast.warn('Please type the correct leave status', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.startSyncLeaves()
        }
    }

    render() {
        const {loading, leave_status} = this.state
        return (
            <div className='h-60vh'>
                <div className="row">
                    <LoaderIndicator loading={loading} />
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'Sync Leaves'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'Leave Status'}</div>
                                <div className='p-placeholder-text widthMonth'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        placeholder="Enter Leave Status"
                                        type="text"
                                        maxLength={1}
                                        value={leave_status}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-placeholder-text widthMonth'>
                                    <div
                                        type='button'
                                        className='save-btn save-txt'
                                        onClick={() => this.loadData()}
                                    >Sync Leaves
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default SyncLeaves;