import React, { Component } from 'react';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';
import '../../employeeScreens/transfer/Transfer.css';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';

const approve = `Are you sure want to approve this transfer request?`
const reject = `Are you sure want to reject this transfer request?`
const delete1 = `Are you sure want to delete this transfer request?`
class ConfirmPopup extends Component {
    render() {
        const { title, action } = this.props
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0">
                    <div className="modal-headers mt-2 mx-3">
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className='hr'>
                        <hr />
                    </div>
                    <div className="modal-body">
                        {action === 'rejected' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={delete_popup}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {reject}
                                </div>
                            </div>
                        }
                        {action === 'delete' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={delete_popup}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {delete1}
                                </div>
                            </div>
                        }
                        {action === 'approved' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={Success_icon}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {approve}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        {action === 'delete' ?
                            <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.deleteData()} /> :
                            action === 'rejected' ?
                                <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.props.rejectData()} /> :
                                action === 'approved' ?
                                    <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => this.props.approveData()} /> :

                                    <input type='button' value='SAVE' className='ml-2 validateBtn' onClick={() => this.props.saveData()} />
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default ConfirmPopup;