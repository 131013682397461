import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import ReactExport from "react-data-export";
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class CompletionStatusReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            summaryData: [],
            coiData: []
        }
    }


    componentDidMount() {
        this.getCOICompletionSummary()
        this.getCOIData()
    }

    getCOIData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            // page_number: 1,
        }
        console.log('getCOICompletionReport body', body);
        fetchApiCall(Config.getCOICompletionReport, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                console.log('getCOICompletionReport response', res);
                console.log('getCOICompletionReport response', res.data.paginated_data);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        // coiData: res.data.paginated_data,
                        coiData: res.data.result,
                    })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    getCOICompletionSummary = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
        }
        console.log('getCOICompletionSummary body', body);
        fetchApiCall(Config.getCOICompletionSummary, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                console.log('getCOICompletionSummary response', res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        summaryData: res.data
                    })
                    console.log("summaryData", this.state.summaryData)
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    render() {
        return (
            <div >
                <LoaderIndicator loading={this.state.loading} />
                <div className="row justify-content-between align-item-center pt-3 px-3">
                    <div>
                        <label className="edit-cardtitle ml-2">Summary Chart:</label>
                    </div>
                </div>

                <div className="justify-content-between mb-3">
                    <div className="col-md-12 px-0" >
                        <div className='row'>
                            <div className='col-3 mb-3 mt-3 row align-item-center justify-content-center-noflex'>
                                <div className="coi-summary-chart-text" >
                                    {this.state.summaryData.total_employee_count}
                                </div>
                                <div>
                                    <label className='thead'>Total Employees</label>
                                </div>
                            </div>
                            <div className='hz-lines'></div>
                            <div className='col-4 mb-3 mt-3 row align-item-center justify-content-center-noflex'>
                                <div className="coi-summary-chart-text" >
                                    {this.state.summaryData.coi_completion_count}
                                </div>
                                <div>
                                    <label className='thead'>Completed COI</label>
                                </div>
                            </div>
                            <div className='hz-lines'></div>
                            <div className='col-3 mb-3 mt-3 row align-item-center justify-content-center-noflex'>
                                <div className="coi-summary-chart-text" >
                                    {this.state.summaryData.coi_completion_percentage}%
                                </div>
                                <div>
                                    <label className='thead'>Completion Rate</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <>
                    <div className='hz-lines mr-4 ml-4'></div>
                    <div className="row justify-content-between align-item-center px-3">
                        <div>
                            <label className="edit-cardtitle ml-2">Employee COI Monthly Status Report</label>
                        </div>
                        <div className=' justify-content-end'>
                            <div>
                                {this.state.coiData.length > 0 ?
                                    <ExcelFile filename="AllEmployeeCOIStatusReport"
                                        element={
                                            <div
                                                type='button'
                                                className="save-btn save-txt mt-38px"
                                            >Export
                                            </div>
                                        }>
                                        <ExcelSheet
                                            data={this.state.coiData}
                                            name="Employees"
                                        >
                                            <ExcelColumn label="Employee ID" value="emp_id" />
                                            <ExcelColumn label="PA" value="emp_pa" />
                                            <ExcelColumn label="PSA" value="emp_psa" />
                                            <ExcelColumn label="Region" value="emp_region" />
                                            <ExcelColumn label="Company Code" value="company_code" />
                                            <ExcelColumn label="Organization Unit" value="organization_unit" />
                                            <ExcelColumn label="A1" value="is_A1" />
                                            <ExcelColumn label="A2" value="is_A2" />
                                            <ExcelColumn label="A3" value="is_A3" />
                                            <ExcelColumn label="B" value="is_B" />
                                            <ExcelColumn label="BHR ID" value="BhrPerno" />
                                            <ExcelColumn label="Manager ID" value="Reporting" />
                                            <ExcelColumn label="COI" value="is_coi" />
                                            <ExcelColumn label="Created Date Time" value="created_date_time" />
                                            <ExcelColumn label="Modified Date Time" value="modified_date_time" />
                                        </ExcelSheet>
                                    </ExcelFile> : null
                                }
                            </div>
                        </div>
                    </div>

                    {this.state.coiData.length != 0 ?
                        <>
                            <div className='col-md-12 flex-direction-column completionDashboard'>
                                <div className='mb-2 col-md-12 mt-3'>
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Employee ID</div></th>
                                                <th scope="col"><div className='thead'>PA</div></th>
                                                <th scope="col"><div className='thead'>PSA</div></th>
                                                <th scope="col"><div className='thead'>Region</div></th>
                                                <th scope="col"><div className='thead'>A1</div></th>
                                                <th scope="col"><div className='thead'>A2</div></th>
                                                <th scope="col"><div className='thead'>A3</div></th>
                                                <th scope="col"><div className='thead'>B</div></th>
                                                <th scope="col"><div className='thead'>COI</div></th>
                                                <th scope="col"><div className='thead'>BHR ID</div></th>
                                                <th scope="col"><div className='thead'>Manager ID</div></th>
                                                <th scope="col"><div className='thead'>Created Date</div></th>
                                                <th scope="col"><div className='thead'>Modified Date</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.coiData.map((listValue, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td> <div className="td d-flex">{listValue.emp_id ? listValue.emp_id : '-'}</div> </td>
                                                        <td> <div className="td">{listValue.emp_pa ? listValue.emp_pa : '-'}</div></td>
                                                        <td> <div className="td d-flex">{listValue.emp_psa ? listValue.emp_psa : '-'}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.emp_region ? listValue.emp_region : '-'}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.is_A1 === true ? "YES" : "NO"}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.is_A2 === true ? "YES" : "NO"}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.is_A3 === true ? "YES" : "NO"}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.is_B === true ? "YES" : "NO"}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.is_coi === true ? "YES" : "NO"}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.BhrPerno ? listValue.BhrPerno : '-'}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.Reporting ? listValue.Reporting : '-'}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.created_date_time ? moment(listValue.created_date_time).format("DD-MM-YYYY") : '-'}</div> </td>
                                                        <td> <div className="td d-flex">{listValue.modified_date_time ? moment(listValue.modified_date_time).format("DD-MM-YYYY") : '-'}</div> </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                        :
                        <div className='col-md-12 justify-content-center align-item-center h-60vh'>
                            <DataNotFound />
                        </div>
                    }
                </>


                {/* {this.state.coiData.length > 0 &&
                    <div className="col-md-12 w-100 p-3 d-flex justify-content-center ">
                        <Pagination>
                            <Pagination.Prev
                                onClick={() => this.handlePageCount("decrement")}
                            />
                            {this.state.count.length > 1 &&
                                <Pagination.Item
                                    onClick={() => this.selectedPage(1)}>{1}
                                </Pagination.Item>}
                            <Pagination.Ellipsis />
                            <Pagination.Item active>
                                {this.state.page}
                            </Pagination.Item>
                            <Pagination.Ellipsis />
                            {this.state.count.length > 1 &&
                                <Pagination.Item
                                    onClick={() => this.selectedPage(this.state.count[this.state.count.length - 1])} >
                                    {this.state.count[this.state.count.length - 1]}</Pagination.Item>}
                            <Pagination.Next
                                onClick={() => this.handlePageCount("increment")} />
                        </Pagination>
                    </div>
                } */}

            </div>
        );
    }
}

// export default ExitScreen;
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(CompletionStatusReport);