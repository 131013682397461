import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Dropdown from '../../../shared/components/Dropdown';
import { fetchApiCall } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';

class EsepRevokeConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            revocationReason: '',
            reasonList: [],
            companyCode: '',
            reasonValue: 'Select',
            reasonReascription: ''
        }
    }

    componentDidMount() {
        this.getRevoactionReason()
    }

    getRevoactionReason = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getRevocationReason, {}, headers, true, 'admin')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        reasonList: res.data.data ? res.data.data : [],
                        loading: false
                    })
                    const tempCompanyArray = this.state.reasonList.map((item, idx) => ({
                        ...item,
                        value: item.reason
                    }))
                    this.setState({
                        reasonList: tempCompanyArray
                    })
                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                console.log();
                this.setState({ loading: false })
                console.log(err);
            })
    }

    changeCompanyType = (data) => {
        this.setState({
            reasonValue: data.reason,
            companyCode: data.company_code,
        })
    }

    validateRevoke = () => {
        if (this.state.reasonValue == 'Select' || this.state.reasonValue == '') {
            toast.warn('Please select revocation reason', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.reasonValue == "Other (If any)" && this.state.reasonReascription == '') {
            toast.warn('Please enter revocation reason', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            if (this.state.reasonValue == "Other (If any)") {
                this.props.submitRevocation(this.state.reasonReascription)
            }
            else {
                this.props.submitRevocation(this.state.reasonValue)
            }
        }

    }

    render() {
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content bg-white border_radius_12px">
                        <div className="modal-header mx-2">
                            <div className='row'>
                                <h4 className="popup-title mt-2">Confirmation :</h4>
                            </div>
                            <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            <div className='solid-border'></div>
                        </div>
                        <div className="">
                            <div className="px-2 h-23vh d-flex justify-content-center">
                                <div className='column pl-4 pr-4'>
                                    <div className='p-sub-heading mt-2 ml-2 mb-1'>{'Please enter revocation reason'}</div>
                                    <div className='p-placeholder-text widthMonth'>
                                        <Dropdown
                                            data={this.state.reasonList}
                                            selectedValue={(data) => this.changeCompanyType(data)}
                                            value={this.state.reasonValue}
                                            render={'value'}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                        />
                                        {this.state.reasonValue == "Other (If any)" &&
                                            <>
                                                <label className='login-form-label card-content-title1 mt-3 ml-2'>Revocation reason</label>
                                                <div className='p-placeholder-text'>
                                                    <textarea
                                                        className="form-control p-placeholder px-3"
                                                        type="text"
                                                        maxLength={100}
                                                        onChange={(e) => this.setState({ reasonReascription: e.target.value })}
                                                        value={this.state.reasonReascription}
                                                        placeholder={"Enter the revocation reason"}
                                                    >
                                                    </textarea>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='popup-text mx-4 mt-4 mb-4'></div>
                            <div className="popup-button-background justify-content-end align-item-center px-0 mr-3">
                                <input type='button' value='BACK' className='cancelBtn' onClick={this.props.onDismiss} />
                                <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.validateRevoke()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EsepRevokeConfirmPopup