import React, { Component } from 'react';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg'
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg'
import download from '../../../../assets/drishti_images/documents/download.svg'
import pdf from '../../../../assets/drishti_images/documents/pdf.svg'
import { toast } from 'react-toastify';
import DataNotFound from '../../../shared/notFound/DataNotFound'

const errMessage = 'Unable to contact server\nPlease try again later'
class Retiral extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            html: '',
            type: ''
        }
    }

    getAnnualRetiralData(type) {
        this.setState({
            loading: true,
            type: type
        });
        const req = {
            emp_id: this.props.loginData.userData.Perno,
            fin_year: this.props.fy_year.toString(),
            annual_type: type,
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getAnnualStatement, req, headers, false, ''
        ).then(res => {
            sessionOut(this.props, res);
            if (res.data.status_code === '200') {
                this.setState({
                    html: res.data.passbook_details,
                    loading: false,
                    type: type
                })
                this.generatePDF(type)
                setTimeout(() => {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                }, 100)
            } else {
                this.setState({
                    loading: false
                });
                toast.error(res.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            }

        }).catch(err => {
            this.setState({
                loading: false
            });
            setTimeout(() => {
                toast.error(errMessage, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            }, 100)

        })
    }
    generatePDF = async (type) => {
        if (process.browser || window !== undefined) {
            const html2pdf = (await import("html2pdf.js")).default;
            var pdfFileName = '';
            var html2canvas = { dpi: 192, letterRendering: true, height: window.innerHeight * 2 }

            if (type === 'TELCOEPS') {
                pdfFileName = this.props.loginData.userData.Perno + '_Pension_' + this.props.fy_year
            }
            else if (type === 'TELCOSAF') {
                pdfFileName = this.props.loginData.userData.Perno + '_SAF_' + this.props.fy_year
                html2canvas = { dpi: 192, letterRendering: true, height: window.innerHeight * 4 }
            }
            else if (type === 'TELCOPF') {
                pdfFileName = this.props.loginData.userData.Perno + '_Provident_Fund_' + this.props.fy_year
            }
            var element = this.state.html;

            var option = {
                margin: [0.5, 1, 1, 1],
                filename: pdfFileName,
                html2canvas: html2canvas,
                jsPDF: {
                    orientation: 'portrait',
                    unit: 'cm',
                    format: [40, 25]
                }
            };
            html2pdf().from(element).set(option).save();
        }
    }
    render() {
        const { fy_year, showPF, showPension, showSAF } = this.props
        const PFTitle = 'PF Statement'
        const SAFTitle = 'Superannuatation Fund Statement'
        const PensionTitle = 'Pension Statement'
        return (
            <div>
                <div>
                    <div className='row'>
                        {showSAF &&
                            <div className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3" onClick={() => this.getAnnualRetiralData("TELCOSAF")}>
                                <div className='flex flex-direction-row align-item-center'>
                                    <div className='mx-3'>
                                        <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                    </div>
                                    <div className='flex-direction-column'>
                                        <div className='document-innercard-content-title common-text-color'>{SAFTitle}</div>
                                        <div className='document-innercard-content-subtitle light-common-label-color'>Your {SAFTitle} for the financial Year {fy_year} has been released </div>
                                    </div>
                                </div>
                                <div className='align-item-center'>
                                    <img alt="download" src={download} className="w-46px h-46px"></img>
                                </div>
                            </div>
                        }
                        {showPF &&
                            <div className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3" onClick={() => this.getAnnualRetiralData("TELCOPF")}>
                                <div className='flex flex-direction-row align-item-center'>
                                    <div className='mx-3'>
                                        <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                    </div>
                                    <div className='flex-direction-column'>
                                        <div className='document-innercard-content-title common-text-color'>{PFTitle}</div>
                                        <div className='document-innercard-content-subtitle light-common-label-color'>Your {PFTitle} for the financial Year {fy_year} has been released  </div>
                                    </div>
                                </div>
                                <div className='align-item-center'>
                                    <img alt="download" src={download} className="w-46px h-46px"></img>
                                </div>
                            </div>
                        }
                        {showPension &&
                            <div className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3" onClick={() => this.getAnnualRetiralData("TELCOEPS")}>
                                <div className='flex flex-direction-row align-item-center'>
                                    <div className='mx-3'>
                                        <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                    </div>
                                    <div className='flex-direction-column'>
                                        <div className='document-innercard-content-title common-text-color'>{PensionTitle}</div>
                                        <div className='document-innercard-content-subtitle light-common-label-color'>Your {PensionTitle} for the financial Year {fy_year} has been released </div>
                                    </div>
                                </div>
                                <div className='align-item-center'>
                                    <img alt="download" src={download} className="w-46px h-46px"></img>
                                </div>
                            </div>
                        }

                    </div>
                    {!showPF && !showPension && !showSAF &&
                        <div className='justify-content-center d-flex'>
                            <DataNotFound/>
                        </div>
                    }

                    {/* <div className='justify-content-end mt-3' onClick={() => this.props.toggleCard('retirals')}>
                        <img alt="hide" src={up_arrow} className="hide_icon"></img>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Retiral;