import * as type from '../../../../redux/action/ActionType';

const initialState = {
    COITypeData: [],
    COITypeA1Data:[],
    COITypeA2Data:[],
    COITypeA3Data:[],
}

export const coiRequestData = (state= initialState, action) => {
    switch(action.type) {
        case type.GET_COI_TYPE:
            let tempCOIArray = state.COITypeData;
            // console.log('tempCOIArray reducer------>',tempCOIArray);
            const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
            tempCOIArray.push(action.payload)
            tempCOIArray.sort(sortAlphaNum)
            return {
                ...state,
                COITypeData: tempCOIArray,
            }
        case type.GET_COI_TYPE_A1_DATA:
            return {
                ...state,
                COITypeA1Data: action.payload,
            }
        case type.GET_COI_TYPE_A2_DATA:
            return {
                ...state,
                COITypeA2Data: action.payload,
            }
        case type.GET_COI_TYPE_A3_DATA:
            return {
                ...state,
                COITypeA3Data: action.payload,
            }
                
        case type.CLEAR_COI_TYPE_DATA:
            return {
                ...state,
                COITypeData: [],
                COITypeA1Data: [],
                COITypeA2Data: [],
                COITypeA3Data: []
            }    
        default:
            return state;
    }
}