import React, { Component } from 'react'
import { toaster } from '../../../../utils/Utils';
import { claimHistoryCommonApiFun, claimSubmit, doesClaimExistsApi, getManagerList, updateAttachment } from '../../../../constants/api.service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { constantValues, employeeDetailsForm, employeeDetailsFormObj, errorCodes, initialClaimForm, initialExpenseError, initialFields, } from './constant';
import Dropdown from '../../../shared/components/Dropdown';
import LargeDropdown from '../../../shared/components/LargeDropdown';
import CharacterCounter from '../../../shared/CharacterCounter';
import ExpenseForms from './ExpenseForms';
import { calcTotalDays, calcTotalTime, handleValidation } from './commonUtils';
import ConfirmPopup from '../../employeeScreens/todo/ConfirmPopup';
import './LocalConveyanceApporval.css'
import '../../employeeScreens/profile/ProfileScreen.css'
import LocalConveyanceSubmitPopUp from './LocalConveyanceSubmitPopUp';
import LocalConveyanceConfirmPopUp from './LocalConveyanceConfirmPopUp';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import moment from 'moment';
import { OverlayTrigger } from 'react-bootstrap';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';

// keep these hardcoded values
const travelTypeList = [
    {
        id: 0,
        title: 'Intercity',
        value: 'Intercity'
    },
    {
        id: 1,
        title: 'Short Distance',
        value: 'Short Distance'
    },
    {
        id: 2,
        title: 'Intracity',
        value: 'Intracity'
    },
]

export class NewClaimForm extends Component {
    constructor(props) {
        super(props);
        this.state = initialClaimForm
    }
    getEmployeeDetails = (emp_id) => {
        this.setState({
            loading: true,
        })
        const param = {
            claim_type: "Others",
            emp_id: emp_id,
        }
        claimHistoryCommonApiFun(param, this.props, 'get_employee_details_as_per_claim_type/')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        employeeDetailData: res?.data?.data?.employee_details[0],
                        managerDetailData: res?.data?.data?.manager_details.length > 1 ? res?.data?.data?.manager_details : [],
                        singleManagerDetailData: res?.data?.data?.manager_details.length == 1 ? this.onManagerValidate(res?.data?.data?.manager_details[0].Perno) : [{}]
                    })
                    const tempArray = this.state.managerDetailData.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.CompName
                    }))

                    res?.data?.data?.manager_details.length > 1 && this.setState({
                        managerDetailData: tempArray
                    })
                    this.getCityDetails()
                }
                else {
                    this.setState({
                        loading: false,
                        employeeDetailData: {},
                        managerDetailData: [],
                        singleManagerDetailData: [{}]
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    employeeDetailData: {},
                    managerDetailData: [],
                    singleManagerDetailData: [{}]
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    getCityDetails = () => {
        this.setState({
            loading: true,
        })
        claimHistoryCommonApiFun('', '', 'city')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    const cityDataArray = res?.data?.data ? res.data.data.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.city
                    })) : []

                    this.setState({
                        loading: false,
                        cityData: cityDataArray
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        cityData: []
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    cityData: []
                })
                toaster("warning", error.message ? error.message : "")
            });
    }

    deleteExpenseDetails = (index) => {
        this.setState({ loading: true })
        const param = {
            travel_type: this.state.selectedTravelType ? this.state.selectedTravelType : '',
            city_type: this.state.toPlace.city_type ? this.state.toPlace.city_type : this.state.selectedTravelType == "Intracity" ? "B" : '',
            selected_expense_types: index != this.state.expenseForm.length ?
                this.state?.selectedExpenseTypes?.map((expenseObj) => expenseObj.expense_type).join()
                : this.state.selectedExpenseTypes.slice(0, index - 1).map((expenseObj) => expenseObj.expense_type).join()
        }
        claimHistoryCommonApiFun(param, this.props, 'expense_types')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                    })
                    this.setState({
                        expenseForm: [...this.state.expenseForm.slice(0, this.state.expenseForm.length - 1),
                        { ...this.state.expenseForm[index == this.state.expenseForm.length ? index - 1 : index], expenseType: res.data.data.expense_data },
                        ]
                    }, this.setState({ expenseTypeFields: true }))
                }
                else {
                    this.setState({
                        loading: false,
                        expenseTypeDropDownListing: [],
                        expenseTypeFields: false
                    })
                    this.setState({ expenseTypeFields: false })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    expenseTypeDropDownListing: []
                })
                toaster("warning", error.message ? error.message : "")
            });

    }

    getExpenseTypeDropDownList = async () => {
        this.setState({ loading: true })
        const param = {
            travel_type: this.state.selectedTravelType ? this.state.selectedTravelType : '',
            city_type: this.state.toPlace.city_type ? this.state.toPlace.city_type : this.state.selectedTravelType == "Intracity" ? "B" : '',
            // selected_expense_types: this.state?.selectedExpenseTypes?.map((expenseObj) => expenseObj.expense_type).filter((expenseType) => toExcludeExpenseTypes.includes(expenseType)).join()
            selected_expense_types: this.state?.selectedExpenseTypes?.map((expenseObj) => expenseObj.expense_type).join()
        }
        await claimHistoryCommonApiFun(param, this.props, 'expense_types')
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        maxFormValue: res?.data?.data?.expense_limit ?? 1,
                        expenseForm: [...this.state.expenseForm,
                        {
                            "expenseType": res.data.data.expense_data,
                            "subExpenseType1": [], "SubExpenseType2": []
                        }]
                    }, this.setState({ expenseTypeFields: true }))
                    return new Promise((resolve) => resolve("expenseType received"))
                }
                else {
                    this.setState({
                        loading: false,
                        expenseTypeDropDownListing: [],
                        expenseTypeFields: false
                    })
                    this.setState({
                        expenseForm: [...this.state.expenseForm,
                        { "expenseType": [], "subExpenseType1": [], "SubExpenseType2": [] }]
                    }, this.setState({ expenseTypeFields: false }))
                    toaster("warning", res.data.message ? res.data.message : "")
                    return new Promise((_, reject) => reject("expenseType Error"))
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    expenseTypeDropDownListing: []
                })
                toaster("warning", error.message ? error.message : "")
                return new Promise((_, reject) => reject("expenseType Error"))
            });

    }

    getExpenseTypeData = (travel_type, city_type, expense_type, sub_expense_type1, selected_expense_types, selected_subexpense_types, type, index) => {
        this.setState({
            loading: true,
        })
        const param = {
            travel_type: travel_type ? travel_type : "",
            city_type: city_type ? city_type : this.state.selectedTravelType == "Intracity" ? "B" : "",
            expense_type: expense_type ? expense_type : "",
            sub_expense_type1: sub_expense_type1 ? sub_expense_type1 : "",
            selected_expense_types: selected_expense_types ? selected_expense_types : '',
            selected_subexpense_types: selected_subexpense_types ? selected_subexpense_types : ''
        }

        claimHistoryCommonApiFun(param, this.props, 'expense_types')
            .then((res) => {
                if (res?.status === 200 || res?.status === 201) {
                    if (type == "subExpenseType1") {
                        this.setState({
                            loading: false,
                            expenseForm: [...this.state.expenseForm.slice(0, index),
                            {
                                expenseType: [...this.state.expenseForm?.[index]?.expenseType],
                                subExpenseType1: [...res?.data?.data?.expense_data.filter((item) => item.sub_expense_type1 != null)] ?? [],
                            },
                            ...this.state.expenseForm.slice(index + 1)
                            ],
                        })
                    }
                    else if (type == "subExpenseType2") {
                        this.setState({
                            loading: false,
                            expenseForm: [...this.state.expenseForm.slice(0, index),
                            {
                                expenseType: [...this.state.expenseForm?.[index]?.expenseType],
                                subExpenseType1: [...this.state.expenseForm?.[index]?.subExpenseType1],
                                subExpenseType2: [...res?.data?.data?.expense_data.filter((item) => { return (item.sub_expense_type2 != null && item.sub_expense_type2 != "") })] ?? [],
                            },
                            ...this.state.expenseForm.slice(index + 1)
                            ],
                        })
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        expenseType1: []
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    expenseType1: []
                })
                console.log("Expense Types Api Error -->", error)
                toaster("warning", error.message ? error.message : "")
            });

    }

    employeeDetailsGet = (data) => {
        data.persist();
        if (data.target.value.length >= 6) {
            this.getEmployeeDetails(data.target.value)
        }
        else {
            this.setState({
                employeeDetailData: {},
                managerDetailData: [],
                singleManagerDetailData: [{}],
                selectedTravelType: '',
                selectedTravelPurpose: '',
                fromPlace: '',
                toPlace: '',
                fromDate: '',
                toDate: '',
                fromTime: '',
                toTime: '',
                cityData: []
            })
        }
        this.setState({
            employeeId: data.target.value
        })
    }

    setExpenseError = (key, code, index) => {
        const { errors } = this.state
        this.setState({
            errors: {
                ...errors, expenseDetails: [
                    ...errors.expenseDetails.slice(0, index),
                    { ...errors.expenseDetails[index], [key]: code },
                    ...errors.expenseDetails.slice(index + 1)
                ]
            }
        })
    }
    handleAddExpense = async (index) => {
        const { expenseDetails, selectedTravelType, selectedExpenseTypes, errors } = this.state
        let isValid = expenseDetails.every((expenseDetail, ind1) => handleValidation(expenseDetail, selectedTravelType, this.setExpenseError, ind1))
        if (isValid == true || index == 0) {
            await this.getExpenseTypeDropDownList(index).then(() => {
                this.setState({ expenseDetails: [...expenseDetails, initialFields] })
                errors.expenseDetails.push(initialExpenseError)
                return new Promise((resolve) => resolve("expenseType received"))
            }).catch((errVal) => {
                console.log("errVal", errVal);
                return new Promise((_, reject) => reject(errVal))
            }
            )
        }
        else {
            toaster("warning", "Expense forms may have invalid fields or missing mandatory fields")
            return new Promise((_, reject) => reject("Expense details are not valid"))
        }
    }
    handleDeleteExpenseForm = (index) => {
        const { expenseDetails, selectedExpenseTypes, expenseForm, errors } = this.state;
        this.setState({ errors: { ...errors, expenseDetails: [...errors.expenseDetails.slice(0, index), ...errors.expenseDetails.slice(index + 1)] } },
            () => this.setState({ expenseDetails: [...expenseDetails.slice(0, index), ...expenseDetails.slice(index + 1)] },
                () => this.setState({ selectedExpenseTypes: [...selectedExpenseTypes.slice(0, index), ...selectedExpenseTypes.slice(index + 1)] },
                    () => {
                        this.setState({ expenseForm: [...expenseForm.slice(0, index), ...expenseForm.slice(index + 1)] }, () => {
                            this.deleteExpenseDetails(index)
                            this.getTotal()
                        })
                    })))
    }
    onDeleteExpenseForm = (index) => {
        const { expenseDetails, employeeId } = this.state
        this.setState({ loading: true })
        let attachmentPathList = expenseDetails[index].bill_path.map((detail) => { return detail.attachment_path })
        let deleteAttachmentReqBody = {
            emp_id: employeeId,
            bill_path: attachmentPathList
        }
        if (attachmentPathList.length > 0) {
            updateAttachment(deleteAttachmentReqBody, "POST", this.props, "delete_claim_attachment/").then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        toaster("success", res?.data?.message ?? "Bill deleted successfully")
                        this.handleDeleteExpenseForm(index)
                    }
                    else {
                        toaster("error", res?.data?.message ? res?.data?.message : "Some error occurred")
                    }
                    this.setState({ loading: false })
                }
            ).catch(
                (err) => {
                    console.log("Attachment delete api error -->", err)
                    this.setState({ loading: false })
                    toaster("error", err?.data?.message ? err?.data?.message : "Some error occurred")
                }
            )
        }
        else {
            this.setState({ loading: false })
            this.handleDeleteExpenseForm(index)
        }
    }

    validatePrimaryData = () => {
        const { employeeId, employeeDetailData, singleManagerDetailData, selectedTravelType, selectedTravelPurpose, fromDate, toDate, fromTime, toTime, fromPlace, toPlace, errors } = this.state
        if (employeeId == "" || Object.keys(employeeDetailData).length <= 0 || Object.keys(singleManagerDetailData[0]).length <= 0 || selectedTravelType == "" || selectedTravelPurpose == "" || fromPlace == "" || toPlace == "" || fromDate == "" || toDate == "") {
            if (selectedTravelType == "") {
                this.setState({ errors: { ...errors, selectedTravelType: 1 } })
            }
            else if (selectedTravelPurpose == "") {
                this.setState({ errors: { ...errors, selectedTravelPurpose: 1 } })
            }
            else if (fromDate == "") {
                this.setState({ errors: { ...errors, fromDate: 1 } })
            }
            else if (toDate == "") {
                this.setState({ errors: { ...errors, toDate: 1 } })
            }
            else if (fromPlace == "") {
                this.setState({ errors: { ...errors, fromPlace: 1 } })
            }
            else if (toPlace == "") {
                this.setState({ errors: { ...errors, toPlace: 1 } })
            }
            else {
                toaster("warning", "Employee details or Manager details not found")
            }
        }
        else if (fromDate > toDate) {
            this.setState({ errors: { ...errors, ...{ fromDate: 2 } } })
        }
        else if (selectedTravelType == "Intracity" && fromTime == "") {
            this.setState({ errors: { ...errors, ...{ fromTime: 1 } } })
        }
        else if (selectedTravelType == "Intracity" && toTime == "") {
            this.setState({ errors: { ...errors, ...{ toTime: 1 } } })
        }
        else if (selectedTravelType == "Intracity" && fromTime > toTime) {
            this.setState({ errors: { ...errors, ...{ fromTime: 2 } } })
        }
        else if (selectedTravelType == "Intracity" && (moment(fromTime, 'hh:mm')).isSame(moment(toTime, 'hh:mm'))) {
            this.setState({ errors: { ...errors, ...{ fromTime: 3 } } })
        }
        else {
            this.handleAddExpense(0).then(() => {
                toaster("success", "Primary details are valid")
                this.setState({ errors: initialClaimForm.errors })
                this.setState({ isPrimaryDataValidated: true })
            }).catch((errVal) => console.log("errVal", errVal))
        }
    }
    selectOneManager = (seletedManager) => {
        this.onManagerValidate(seletedManager.Perno)
    }

    onSelectTravelType = (data) => {
        const { errors, employeeDetailData } = this.state
        this.setState({
            selectedTravelType: data.value,
            selectedTravelPurpose: '',
            fromPlace: data.value == "Intracity" ? { city: employeeDetailData.CityTown } : '',
            toPlace: data.value == "Intracity" ? { city: employeeDetailData.CityTown } : '',
            fromDate: '',
            toDate: '',
            fromTime: '',
            toTime: '',
            errors: { ...errors, selectedTravelType: 0 }
        })
    }

    onSelectTravelPurpose = (data) => {
        let { errors } = this.state
        this.setState({
            selectedTravelPurpose: data.target.value,
            errors: { ...errors, selectedTravelPurpose: 0 }
        })
    }

    onChangeFromPlace = (data) => {
        const { errors } = this.state
        this.state.selectedTravelType == "Intracity" ? this.setState({
            toPlace: data
        }) :
            this.setState({
                fromPlace: data,
                errors: { ...errors, fromPlace: 0 }
            })
    }

    onChangeToPlace = (data) => {
        const { errors } = this.state
        this.state.selectedTravelType != "Intracity" &&
            this.setState({
                toPlace: data,
                errors: { ...errors, toPlace: 0 }
            })
    }

    onFromDate = (data) => {
        let { toDate, selectedTravelType, errors } = this.state
        this.setState({
            fromDate: data.target.value,
            errors: { ...errors, fromDate: 0 }
        })
        selectedTravelType == "Intracity" ? this.setState({
            toDate: data.target.value
        }) :
            toDate && toDate < data.target.value && this.setState({
                errors: { ...errors, fromDate: 2 }
            })
    }

    onToDate = (data) => {
        let { fromDate, errors } = this.state
        this.setState({
            toDate: data.target.value,
            errors: { ...errors, toDate: 0 }
        })
        fromDate && fromDate > data.target.value && this.setState({
            errors: { ...errors, fromDate: 2 }
        })
    }

    onFromTime = (data) => {
        const { toTime, errors } = this.state
        this.setState({
            fromTime: data.target.value,
            toTimeFlag: false,
            errors: { ...errors, fromTime: 0 }
        })
        toTime && (moment(toTime, 'hh:mm')).isSame(moment(data.target.value, 'hh:mm')) && this.setState({
            errors: { ...errors, fromTime: 3 }
        })
        toTime && toTime < data.target.value && this.setState({
            errors: { ...errors, fromTime: 2 }
        })
    }

    onToTime = (data) => {
        const { fromTime, errors } = this.state
        this.setState({
            toTime: data.target.value,
            toTimeFlag: false,
            errors: { ...errors, toTime: 0 }
        })
        fromTime && (moment(fromTime, 'hh:mm')).isSame(moment(data.target.value, 'hh:mm')) && this.setState({
            errors: { ...errors, fromTime: 3 }
        })
        fromTime && fromTime < data.target.value && this.setState({
            errors: { ...errors, fromTime: 0 }
        })
    }


    onSelectExpenseType = (data, index, key) => {
        this.setState({
            selecetdExpenseType: data,
            selectedExpenseTypes: [...this.state.selectedExpenseTypes.slice(0, index), data, ...this.state.selectedExpenseTypes.slice(index + 1)],
            selectedSubExpenseTypes1: [...this.state.selectedSubExpenseTypes1.slice(0, index), [], ...this.state.selectedSubExpenseTypes1.slice(index + 1)],
            selectedSubExpenseTypes2: [...this.state.selectedSubExpenseTypes2.slice(0, index), [], ...this.state.selectedSubExpenseTypes2.slice(index + 1)]
        })
        // this.getExpenseTypeDropDownList()
        this.setState({
            expenseForm: [...this.state.expenseForm.slice(0, index),
            {
                expenseType: [...this.state.expenseForm?.[index]?.expenseType],
                subExpenseType1: [],
            },
            ...this.state.expenseForm.slice(index + 1)
            ],
        })
        this.getExpenseTypeData(this.state.selectedTravelType, this.state.toPlace.city_type, data.expense_type, "", "", "", "subExpenseType1", index)
    }

    onSelectExpenseType1 = (data, index) => {
        this.setState({
            selectedSubExpenseType1: data,
            selectedSubExpenseTypes1: [...this.state.selectedSubExpenseTypes1.slice(0, index), data, ...this.state.selectedSubExpenseTypes1.slice(index + 1)],
            selectedSubExpenseTypes2: [...this.state.selectedSubExpenseTypes2.slice(0, index), [], ...this.state.selectedSubExpenseTypes2.slice(index + 1)]
        })
        this.setState({
            expenseForm: [...this.state.expenseForm.slice(0, index),
            {
                expenseType: [...this.state.expenseForm?.[index]?.expenseType],
                subExpenseType1: [...this.state.expenseForm?.[index]?.subExpenseType1],
                subExpenseType2: [],
            },
            ...this.state.expenseForm.slice(index + 1)
            ],
        })
        data?.sub_expense_type1 == "Own vehicle" && this.getExpenseTypeData(this.state.selectedTravelType, this.state.toPlace.city_type, this.state.selectedExpenseTypes?.[index]?.expense_type, data.sub_expense_type1, "", "", "subExpenseType2", index)
    }

    onSelectExpenseType2 = (data, index) => {
        this.setState({
            selectedSubExpenseType2: data,
            selectedSubExpenseTypes2: [...this.state.selectedSubExpenseTypes2.slice(0, index), data, ...this.state.selectedSubExpenseTypes2.slice(index + 1)]
        })
        // this.getExpenseTypeData(this.state.selectedTravelType, this.state.toPlace.city_type, this.state.selectedExpenseTypes[index].expense_type, data.sub_expense_type1, "", "", "subExpenseType2", index)
    }

    onChangeForm = (key, value, index) => {
        const { expenseDetails, fromDate } = this.state
        let expenseDetailWithIntracity = [
            ...expenseDetails.slice(0, index), {
                ...expenseDetails[index],
                ...{ [key]: value, "from_expense_date": fromDate, "to_expense_date": fromDate, "total_expense_days": 1 }
            },
            ...expenseDetails.slice(index + 1)
        ]
        let expenseDetailsWithoutIntracity = [
            ...expenseDetails.slice(0, index),
            { ...expenseDetails[index], [key]: value },
            ...expenseDetails.slice(index + 1)
        ]
        this.setState({
            expenseDetails: this.state.selectedTravelType == "Intracity" ? expenseDetailWithIntracity : expenseDetailsWithoutIntracity
        })
        this.setExpenseError(key, 0, index)
    }

    onClearForm = () => {
        const { errors } = this.state
        this.setState({ ...initialClaimForm, expenseDetails: [], errors: { ...errors, expenseDetails: [] } })
        this.state.isBackAction == false && this.getCityDetails()
    }

    onClearAction = () => {
        const { expenseDetails, employeeId, isBackAction } = this.state
        let attachmentPathList = expenseDetails.flatMap((detail) => { return detail.bill_path.map((attachment) => { return attachment.attachment_path }) })
        let deleteAttachmentReqBody = {
            emp_id: employeeId,
            bill_path: attachmentPathList
        }
        if (attachmentPathList.length > 0) {
            updateAttachment(deleteAttachmentReqBody, "POST", this.props, "delete_claim_attachment/").then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        toaster("success", res?.data?.message ?? "Bill deleted successfully")
                        this.onClearForm()
                    }
                    else {
                        toaster("error", res?.data?.message ? res?.data?.message : "Some error occurred")
                        this.setState({ loading: false })
                    }
                    isBackAction && this.props.onDismiss()
                }
            ).catch(
                (err) => {
                    console.log("Attachment delete api error -->", err)
                    this.setState({ loading: false })
                    toaster("error", err?.data?.message ? err?.data?.message : "Some error occurred")
                }
            )
        }
        else {
            isBackAction && this.props.onDismiss()
            // this.setState({ loading: false })
            this.onClearForm()
        }
    }
    onSubmit = () => {
        this.setState({ isPopUpLoading: true })
        let req_body = {
            "claim_type": "Others",
            "employee_id": "00" + this.state.employeeId,
            "employee_type": "Blue Collar",
            "travel_type": this.state.selectedTravelType,
            "purpose": this.state.selectedTravelPurpose,
            "from_date": this.state.fromDate,
            "to_date": this.state.toDate,
            "total_claim_days": calcTotalDays(this.state.toDate, this.state.fromDate) + 1 ?? null,
            "from_place": this.state.fromPlace.city,
            "to_place": this.state.toPlace.city,
            "city_type": this.state?.toPlace?.city_type ?? "B",
            "from_time": this.state.fromTime != "" ? this.state.fromTime : null,
            "to_time": this.state.toTime != "" ? this.state.toTime : null,
            "total_claim_time": calcTotalTime(this.state.toTime, this.state.fromTime)[0] ?? null,
            "dem_id": (this.state.singleManagerDetailData?.[0].Perno) ?? null,
            "total_emp_claim_amount": this.state.totalExpenseAmount,
            "total_permissible_claim_amount": this.state.totalPermissibleAmount,
            "total_approved_claim_amount": this.state.totalApprovedAmount,
            "total_approvable_claim_amount": this.state.totalApprovedAmount,
            "expense_types": [...this.state.expenseDetails.filter((expenseDetails) => expenseDetails != initialFields)
                .map((expenseDetails) => {
                    return {
                        ...expenseDetails, ...{
                            claim_expense_amount: expenseDetails.claim_expense_amount ? Math.round(expenseDetails.claim_expense_amount) : null,
                            max_expense_amount: expenseDetails.max_expense_amount ? Math.round(expenseDetails.max_expense_amount) : null,
                            extra_expense_amount: expenseDetails.extra_expense_amount ? Math.round(expenseDetails.extra_expense_amount) : null,
                            permissible_expense_amount: expenseDetails.permissible_expense_amount ? Math.round(expenseDetails.permissible_expense_amount) : null,
                            total_expense_amount: expenseDetails.total_expense_amount ? Math.round(expenseDetails.total_expense_amount) : null,
                            start_reading: expenseDetails.start_reading ? Math.round(expenseDetails.start_reading) : null,
                            end_reading: expenseDetails.end_reading ? Math.round(expenseDetails.end_reading) : null,
                            approved_expense_amount: expenseDetails.approved_expense_amount ? Math.round(expenseDetails.approved_expense_amount) : null,
                            approvable_expense_amount: expenseDetails.approved_expense_amount ? Math.round(expenseDetails.approved_expense_amount) : null
                        }
                    }
                })
            ]
        }
        claimSubmit(req_body, "claim_submit", this.props).then((res) => {
            if (res.status == 200 || res.status == 201) {
                this.setState({ isSubmitPopUpOpen: false })
                this.setState({ isPopUpLoading: false })
                this.onClearForm()
                this.props.onDismiss()
                toaster("success", res?.data?.message)
            }
            else {
                this.setState({ isPopUpLoading: false })
                this.setState({ isSubmitPopUpOpen: false })
                toaster("warning", res?.data?.message)
            }
        }).catch(err => {
            console.log("Claim Submit Api Error---->", err)
            this.setState({ isPopUpLoading: false })
            this.setState({ isSubmitPopUpOpen: false })
            toaster("error", err?.data?.message) ?? toaster("error", "Some error occurred. Failed to submit the claim")
        })
    }
    doesClaimExist = () => {
        let params = {
            employee_id: "00" + this.state.employeeId,
            screen_type: "Employee",
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            travel_type: this.state.selectedTravelType
        }
        doesClaimExistsApi(params, this.props).then((res) => {
            if (res.status == 200) {
                this.setState({ isSubmitPopUpOpen: false })
                this.setState({ isClaimExistsMsg: res?.data?.message ?? "Claim already exists" })
            }
            else {
                this.setState({ isSubmitPopUpOpen: true, isClaimExistsMsg: "" })
            }
        }).catch((err) => {
            console.log("Error in claim exist api--->", err);
        })
    }
    handleSubmitButton = () => {
        const { expenseDetails, selectedTravelType, selectedExpenseTypes } = this.state
        let isValid = expenseDetails.every((expenseDetail, ind1) => handleValidation(expenseDetail, selectedTravelType, this.setExpenseError, ind1))
        if (isValid == true) {
            if (expenseDetails?.length == 1 && expenseDetails[0] == initialFields) {
                this.setState({ isSubmitPopUpOpen: false })
                this.setState({ isPopUpLoading: false })
                toaster("warning", "You must add at least 1 expense")
            }
            else { this.doesClaimExist() }
        }
        else {
            this.setState({ isSubmitPopUpOpen: false })
            this.setState({ isPopUpLoading: false })
            toaster("warning", "Expense forms may have invalid fields or missing mandatory fields")
        }
    }
    getTotal = () => {
        const initialValue = 0;
        let totalAmt = this.state.expenseDetails.reduce((accumulator, currentValue) => {
            if (currentValue?.total_expense_amount && currentValue.total_expense_amount != null) {
                return accumulator + Math.round(currentValue?.total_expense_amount)
            }
            else return accumulator + 0
        }
            , initialValue)
        let totalPermAmt = this.state.expenseDetails.reduce((accumulator, currentValue) => {
            if (currentValue?.permissible_expense_amount && currentValue.permissible_expense_amount != null) {
                return accumulator + Math.round(currentValue?.permissible_expense_amount)
            }
            else return accumulator + 0
        }
            , initialValue)
        let totalApprAmt = this.state.expenseDetails.reduce((accumulator, currentValue) => {
            if (currentValue?.approved_expense_amount && currentValue.approved_expense_amount != null) {
                return accumulator + Math.round(currentValue?.approved_expense_amount)
            }
            else return accumulator + 0
        }
            , initialValue)
        this.setState({ totalExpenseAmount: totalAmt, totalPermissibleAmount: totalPermAmt, totalApprovedAmount: totalApprAmt })
    }

    onManagerValidate(id) {
        this.setState({
            loading: true,
        })
        let param = {
            emp_id: id
        }

        getManagerList(param)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        loading: false,
                        singleManagerDetailData: res?.data?.data?.reporting_details,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        singleManagerDetailData: [{}],
                    })
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    singleManagerDetailData: [{}],
                })
                toaster("warning", error?.data?.message ? error.data.message : "")
            });
    }

    renderSFTooltip = () => (
        <div className='flex-direction-column tool-tip-font-choicePay'>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Intercity Travel: \n
This refers to an employee journeying from one city to another. For instance, an employee traveling from Jamshedpur to Sanand (>300 Km).`}</div>
            <div className='mt-3px'><hr /></div>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Short Distance Travel: \n
This category encompasses employees traveling from one city to another where the distance is relatively short. For instance, an employee traveling from Pune to Mumbai or from Delhi to Jaipur (<=300 Km).`}</div>
            <div className='mt-3px'><hr /></div>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Intracity Travel: \n
This type involves an employee journeying within a single city. For example, an employee traveling within Lucknow.`}</div>

        </div>
    );

    render() {
        let tempemployeeDetailData = []
        let checkVarType = employeeDetailsFormObj
        for (let i = 0; i < Math.ceil(checkVarType.length / 4); i++) {
            let arr = checkVarType.slice(i * 4, (i + 1) * 4)
            tempemployeeDetailData.push(arr)
        }


        return (
            < div className='h-66vh overflow-y-hidden claim-form-div' >
                <>
                    <LoaderIndicator loading={this.state.loading} />

                    <div className='mb-1 mt-1 ml-15px d-flex align-items-flex-end gap-10'>
                        <div className='w-16'>
                            <div className='appcenter-app-name1 mr-10'>{constantValues.employeeId2} * </div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type='text'
                                    maxLength={6}
                                    onChange={(data) => this.employeeDetailsGet(data)}
                                    placeholder='Enter Employee Id'
                                    // onChange={(data) => this.checkInt(data,) && this.employeeDetailsGet(data)}
                                    value={this.state.employeeId}
                                    disabled={this.state.isPrimaryDataValidated == true}
                                />
                            </div>
                        </div>
                        {
                            Object.keys(this.state.employeeDetailData).length > 0 && <>
                                <div className='w-16'>
                                    <div className='appcenter-app-name1 mr-10'>{constantValues.TotalExpenseAmt} </div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='number'
                                            readOnly={true}
                                            placeholder='NA'
                                            value={this.state.totalExpenseAmount ?? ""}
                                        />
                                    </div>
                                </div>
                                <div className='w-16'>
                                    <div className='appcenter-app-name1 mr-10'>{constantValues.TotalPermissibleAmt} </div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='number'
                                            readOnly={true}
                                            placeholder='NA'
                                            value={this.state.totalPermissibleAmount ?? ""}
                                        />
                                    </div>
                                </div>
                                <div className='w-16'>
                                    <div className='appcenter-app-name1 mr-10'>{constantValues.TotalApprovedAmt} </div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type='number'
                                            readOnly={true}
                                            placeholder='NA'
                                            value={this.state.totalApprovedAmount ?? ""}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className='toggleStyle mt-1rem max-h-46vh scroll-auto '>
                        <>
                            <hr class="line-horizontal mt-1 mb-1"></hr>
                            <div className='flex-direction-column mb-1 w-97'>
                                <div class="m-2 card-heading w-97">{employeeDetailsForm.EmployeeDetails} </div>
                                <>
                                    {tempemployeeDetailData.map((row) => {
                                        return (
                                            < span className='pg-project-grid px-20px mt-1' >
                                                {row.map((data) => {
                                                    return (
                                                        <span>
                                                            <span className='appcenter-app-name1'>{data.title}</span>
                                                            <span className='card-content-title1 ml-5px'>{this.state?.employeeDetailData?.[data.key] ? this.state.employeeDetailData[data.key] : "-"}</span>
                                                        </span>
                                                    )
                                                })}
                                            </span>
                                        )
                                    })
                                    }
                                </>
                            </div>


                            <hr class="line-horizontal mt-1 mb-1"></hr>

                            <div className='flex-direction-column mb-1 w-97'>
                                <span className='d-flex align-item-center justify-content-between'>
                                    <div class="m-2 card-heading w-97">{employeeDetailsForm.ManagerDetails}</div>
                                    {
                                        this.state.managerDetailData.length > 1 &&
                                        <div className='align-item-center w-32rem mt-5px mb-5px '>
                                            <label className='form-label mr-2 w-40'>{constantValues.SelectManager}</label>
                                            <div className='w-100'>
                                                <LargeDropdown
                                                    data={this.state.managerDetailData}
                                                    value={this.state.singleManagerDetailData?.[0]?.CompName}
                                                    selectedValue={(data) => this.selectOneManager(data)}
                                                    disabled={this.state.isPrimaryDataValidated == true}
                                                    noOptionFound="Name not found"
                                                />
                                            </div>
                                        </div>
                                    }
                                </span>
                                <>
                                    {tempemployeeDetailData.map((row) => {
                                        return (
                                            < span className='pg-project-grid px-20px mt-1' >
                                                {row.map((data) => {
                                                    return (
                                                        <span>
                                                            <span className='appcenter-app-name1'>{data.title}</span>
                                                            <span className='card-content-title1 ml-5px'>{this.state?.singleManagerDetailData?.[0]?.[data.key] ? this.state.singleManagerDetailData?.[0]?.[data.key] : "-"}</span>
                                                        </span>
                                                    )
                                                })}
                                            </span>
                                        )
                                    })
                                    }
                                </>
                                <hr class="line-horizontal mt-1 mb-1"></hr>
                                {
                                    Object.keys(this.state.employeeDetailData).length > 0 &&
                                    <>
                                        <div class="m-2 card-heading w-97">{employeeDetailsForm.claimDetails}</div>
                                        <div className='d-flex justify-content-between align-items-baseline flex-wrap'>

                                            <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                <div className='d-flex'>
                                                    <div className='p-sub-heading ml-3 required'>Select Travel Type</div>
                                                    <div className="d-flex">
                                                        <OverlayTrigger placement="right" overlay={this.renderSFTooltip()}>
                                                            <img
                                                                alt="infoicon"
                                                                src={infoicon}
                                                                className="info_icon_flexi_pay"
                                                            ></img>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                                <Dropdown
                                                    data={travelTypeList}
                                                    selectedValue={(data) => this.onSelectTravelType(data)}
                                                    value={this.state.selectedTravelType ? this.state.selectedTravelType : 'Select Travel Type'}
                                                    render={'ApporvalStatus'}
                                                    arrow={'true'}
                                                    height={'NotificationHeight'}
                                                    disabled={this.state.isPrimaryDataValidated == true}
                                                />
                                                {this.state.errors.selectedTravelType != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["selectedTravelType"][this.state.errors.selectedTravelType]}</span>}
                                            </div>
                                            {
                                                this.state.selectedTravelType != '' &&
                                                <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                    <div className='p-sub-heading ml-3'>Enter Travel Purpose *</div>
                                                    <div className='p-placeholder-text'>
                                                        <input
                                                            className="form-control p-placeholder px-3"
                                                            type='text'
                                                            maxLength={50}
                                                            placeholder='Enter Travel Purpose'
                                                            onChange={(e) => this.onSelectTravelPurpose(e)}
                                                            value={this.state.selectedTravelPurpose}
                                                            disabled={this.state.isPrimaryDataValidated == true}
                                                        />
                                                        <CharacterCounter
                                                            totalCount="50"
                                                            currentCount={this.state.selectedTravelPurpose.length}
                                                        />
                                                    </div>
                                                    {this.state.errors.selectedTravelPurpose != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["selectedTravelPurpose"][this.state.errors.selectedTravelPurpose]}</span>}
                                                </div>
                                            }
                                            {
                                                this.state.selectedTravelType != '' &&
                                                <>
                                                    <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                        <div className='p-sub-heading ml-3'>Select From Date *</div>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control p-placeholder px-3"
                                                                type="date"
                                                                placeholder='Enter date'
                                                                value={this.state.fromDate}
                                                                onChange={(e) => this.onFromDate(e)}
                                                                max={this.state.currentDate}
                                                                disabled={this.state.isPrimaryDataValidated == true}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                        {this.state.errors.fromDate != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["fromDate"][this.state.errors.fromDate]}</span>}
                                                    </div>
                                                    <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                        <div className='p-sub-heading ml-3'>Select To Date {this.state.selectedTravelType != "Intracity" ? "*" : ""}</div>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control p-placeholder px-3"
                                                                type="date"
                                                                placeholder='Enter date'
                                                                value={this.state.selectedTravelType == "Intracity" ? this.state.fromDate : this.state.toDate}
                                                                onChange={(e) => this.onToDate(e)}
                                                                min={this.state.fromDate}
                                                                max={this.state.currentDate}
                                                                disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                                                                onKeyDown={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                        {this.state.errors.toDate != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["toDate"][this.state.errors.toDate]}</span>}
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.selectedTravelType != '' &&
                                                <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                    <div className='p-sub-heading ml-3'>Start City *</div>
                                                    <div className='p-placeholder-text'>
                                                        <LargeDropdown
                                                            data={this.state.cityData}
                                                            value={this.state.fromPlace.city ?? "Select From Place"}
                                                            selectedValue={(data) => this.onChangeFromPlace(data)}
                                                            disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                                                            noOptionFound={"No Place Found"}
                                                        />
                                                    </div>
                                                    {this.state.errors.fromPlace != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["fromPlace"][this.state.errors.fromPlace]}</span>}
                                                </div>
                                            }
                                            {
                                                this.state.selectedTravelType != '' &&
                                                <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                    <div className='p-sub-heading ml-3'>Destination *</div>
                                                    <div className='p-placeholder-text'>
                                                        <LargeDropdown
                                                            data={this.state.cityData.filter((city) => city.city != this.state.fromPlace.city)}
                                                            value={this.state.toPlace.city ?? "Select To Place"}
                                                            selectedValue={(data) => this.onChangeToPlace(data)}
                                                            disabled={this.state.isPrimaryDataValidated == true || this.state.selectedTravelType == "Intracity"}
                                                            noOptionFound={"No Place Found"}
                                                        />
                                                    </div>
                                                    {this.state.errors.toPlace != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["toPlace"][this.state.errors.toPlace]}</span>}
                                                </div>
                                            }

                                            {
                                                this.state.selectedTravelType != '' && this.state.selectedTravelType == "Intracity" &&
                                                <>
                                                    <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                        <div className='p-sub-heading ml-3'>Select From Time *</div>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control p-placeholder px-3"
                                                                type="time"
                                                                placeholder='Enter From Time'
                                                                value={this.state.fromTime}
                                                                onChange={(e) => this.onFromTime(e)}
                                                                // onClick={(e) => { this.setState({ toTime: '' }) }}
                                                                // max={this.state.toTime && toString(this.state.toTime)}
                                                                disabled={this.state.isPrimaryDataValidated == true}
                                                            />
                                                        </div>
                                                        {this.state.errors.fromTime != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["fromTime"][this.state.errors.fromTime]}</span>}
                                                    </div>
                                                    <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                                        <div className='p-sub-heading ml-3'>Select To Time *</div>
                                                        <div className='p-placeholder-text'>
                                                            <input
                                                                className="form-control p-placeholder px-3"
                                                                type="time"
                                                                placeholder='Enter To Time'
                                                                value={this.state.toTime}
                                                                onChange={(e) => this.onToTime(e)}
                                                                min={toString(this.state.fromTime)}
                                                                disabled={this.state.isPrimaryDataValidated == true}
                                                            />
                                                        </div>
                                                        {this.state.errors.toTime != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes["toTime"][this.state.errors.toTime]}</span>}
                                                    </div>
                                                </>

                                            }
                                        </div>
                                        <hr class="line-horizontal mt-1 mb-1"></hr>
                                    </>
                                }
                                <>
                                    {
                                        this.state.isPrimaryDataValidated == true &&
                                        <>
                                            <div class="m-2 card-heading w-97">Expense Details</div>
                                            {
                                                this.state.expenseTypeFields && this.state.expenseForm.length > 0 &&
                                                this.state.expenseForm.map((form, index) =>
                                                    <ExpenseForms
                                                        length={this.state.expenseForm.length}
                                                        expenseDetails={this.state.expenseDetails[index]}
                                                        index={index}
                                                        selectedTravelType={this.state.selectedTravelType}
                                                        selectedExpenseTypes={this.state.selectedExpenseTypes[index]}
                                                        form={form}
                                                        expenseTypes={form.expenseType}
                                                        selectedSubExpenseType1={this.state.selectedSubExpenseTypes1[index]}
                                                        selectedSubExpenseType2={this.state.selectedSubExpenseTypes2[index]}
                                                        onSelectExpenseType={(data, key, index) => { this.onSelectExpenseType(data, index, key) }}
                                                        onSelectExpenseType1={(data, key, index) => { this.onSelectExpenseType1(data, index) }}
                                                        onSelectExpenseType2={(data, key, index) => { this.onSelectExpenseType2(data, index) }}
                                                        onChangeForm={(key, value, index) => { this.onChangeForm(key, value, index) }}
                                                        fromDate={this.state.fromDate}
                                                        toDate={this.state.toDate}
                                                        fromTime={this.state.fromTime}
                                                        toTime={this.state.toTime}
                                                        onAddNewForm={(index) => { this.handleAddExpense(index) }}
                                                        maxFormLength={this.state.maxFormValue}
                                                        onDeleteForm={(index) => { this.onDeleteExpenseForm(index) }}
                                                        totalExpenseAmount={this.state.totalExpenseAmount}
                                                        onClearForm={() => this.setState({ isOpenFrom: true })}
                                                        getTotal={() => this.getTotal()}
                                                        errors={this.state.errors.expenseDetails[index]}
                                                        setError={(key, code, index) => { this.setExpenseError(key, code, index) }}
                                                        employeeId={this.state.employeeId}
                                                    />
                                                )
                                            }
                                        </>
                                    }
                                </>
                            </div>

                            {
                                this.state.isPopUpOpen &&
                                <LocalConveyanceConfirmPopUp
                                    heading={"Clear Form"}
                                    onDismiss={() => this.setState({ isPopUpOpen: false, isBackAction: false })}
                                    onConfirmAction={() => {
                                        this.setState({ isPopUpOpen: false, loading: true, isBackAction: false })
                                        this.onClearAction()
                                    }}
                                    message={
                                        <>
                                            <div className='d-flex flex-direction-column'>
                                                <div className='align-self-center'>
                                                    <img
                                                        alt="Success_icon"
                                                        src={Success_icon}
                                                        className="mb-1"
                                                    ></img>
                                                </div>
                                                <div className="popup_text text-center p-3">
                                                    {this.state.isBackAction ? "All your progress will be deleted. Are you sure you want to go back?" : "Are you sure you want to clear form?"}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    confirmBtnLbl="YES"
                                    cancelBtnLbl="NO"
                                />
                            }
                            {
                                this.state.isSubmitPopUpOpen &&
                                <LocalConveyanceSubmitPopUp
                                    closeAction={() => this.setState({ isSubmitPopUpOpen: false })}
                                    onSubmit={() => this.onSubmit()}
                                    loading={this.state.isPopUpLoading}
                                    selectedClaim={this.state}
                                />
                            }
                            {
                                this.state?.isClaimExistsMsg.length > 0 &&
                                <LocalConveyanceConfirmPopUp
                                    heading={"Claim Already Exists"}
                                    onDismiss={() => this.setState({ isClaimExistsMsg: "" })}
                                    onConfirmAction={() => { this.setState({ isClaimExistsMsg: "", isSubmitPopUpOpen: true }) }}
                                    message={
                                        <div className='d-flex flex-direction-column'>
                                            <div className='align-self-center'>
                                                <img
                                                    alt="Success_icon"
                                                    src={Success_icon}
                                                    className="mb-1"
                                                ></img>
                                            </div>
                                            <div className="popup_text text-center p-3">
                                                {this.state.isClaimExistsMsg}
                                            </div>
                                        </div>
                                    }
                                />
                            }
                        </>
                    </div>
                    <span className='d-flex align-item-center justify-content-end gap-10 bottom-tab'>
                        <input
                            type="button"
                            className='ml-2 cancelBtn'
                            value="Back"
                            onClick={() => {
                                this.state == initialClaimForm ? this.props.onDismiss() : this.setState({ isPopUpOpen: true, isBackAction: true })
                            }}
                        ></input>
                        {
                            Object.keys(this.state.employeeDetailData).length > 0 &&
                            <input
                                type="button"
                                className='ml-2 cancelBtn'
                                value="Clear Form"
                                onClick={() => this.setState({ isPopUpOpen: true })}
                            ></input>
                        }
                        {
                            this.state.isPrimaryDataValidated == true ?
                                <input
                                    type="button"
                                    className='ml-2 validateBtn'
                                    value="Submit Claim"
                                    onClick={() => this.handleSubmitButton()}
                                ></input> :
                                <>
                                    {
                                        Object.keys(this.state.employeeDetailData).length > 0 &&
                                        <button
                                            type="submit"
                                            className='btn btn-primary login-btn py-2 align-self-end'
                                            disabled={false}
                                            onClick={(e) => { this.validatePrimaryData() }}
                                        >
                                            {"Validate & Continue"}
                                        </button>
                                    }
                                </>
                        }
                    </span>
                </>
            </div >
        )
    }
}

export default NewClaimForm