import React, { useEffect, useRef } from 'react'
import Dropdown from '../../../shared/components/Dropdown'
import { errorCodes, initialFields } from './constant'
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg'
import { BillField, DateField, InputField, RemarkField } from './commonComponents'
import { addDays, calcTotalDays, calcTotalTime, subtractDays } from './commonUtils'

const ExpenseForms = (props) => {
    const { form,
        onSelectExpenseType,
        selectedExpenseTypes,
        index,
        length,
        expenseTypes,
        selectedSubExpenseType1,
        selectedSubExpenseType2,
        onSelectExpenseType1,
        onSelectExpenseType2,
        onChangeForm,
        fromDate,
        toDate,
        onAddNewForm,
        maxFormLength,
        onDeleteForm,
        expenseDetails,
        totalExpenseAmount,
        selectedTravelType,
        fromTime,
        toTime,
        onClearForm,
        getTotal,
        errors,
        employeeId,
        setError
    } = props
    const initialExpenseRef = useRef(true)

    const handleSubmit = async () => {
        await onAddNewForm(index + 1)
    }
    useEffect(() => {
        let tempFunc = async () => {
            if (initialExpenseRef.current == true) { initialExpenseRef.current = false }
            else {
                if (selectedExpenseTypes?.expense_type == "Incidental allowance") {
                    if (selectedTravelType == "Intracity") {
                        let timeDiff = calcTotalTime(toTime, fromTime)
                        // await onChange("total_expense_days", 1)
                        await onChange("total_expense_time", timeDiff[0])
                        await calcStandardAmt(selectedExpenseTypes)
                    }
                    //  else {
                    //     let dayDiff = calcTotalDays(toDate, fromDate) + 1
                    //     await onChange("total_expense_days", dayDiff)
                    // }
                }
            }
        }
        tempFunc()
    }, [selectedExpenseTypes])

    const onChange = async (key, value, data) => {
        if (key == "claim_expense_amount") {
            await onChangeForm("total_expense_amount", value, index)
            if (selectedExpenseTypes?.expense_type != "Accommodation allowance" && selectedExpenseTypes?.expense_type != "Daily allowance") {
                await onChangeForm("permissible_expense_amount", value, index)
                await onChangeForm("approved_expense_amount", value, index)
            }
            else {
                value && value != null && value <= expenseDetails.permissible_expense_amount ? await onChangeForm("approved_expense_amount", value, index) : await onChangeForm("approved_expense_amount", expenseDetails.permissible_expense_amount, index)
            }
            await onChangeForm(key, value, index)
            getTotal()
        }
        else if (key == "from_expense_date") {
            await onChangeForm(key, value, index)
            await onChange("total_expense_days", null)
            await onChangeForm("to_expense_date", null, index)
        }
        else if (key == "to_expense_date") {
            await onChangeForm(key, value, index)
            let total_days = calcTotalDays(value, expenseDetails.from_expense_date) + 1
            await onChange("total_expense_days", total_days)
        }
        else if (key == "expense_type" && value != expenseDetails?.expense_type) {
            await onChangeForm(key, value, index)
            let { expense_type, ...rest } = initialFields
            Object.keys(rest).forEach((key) => onChangeForm(key, rest[key], index))
            await onSelectExpenseType(data, "expense_type", index);
        }
        else if (key == "sub_expense_type1" && value != expenseDetails.sub_expense_type1) {
            await onSelectExpenseType1(data, "sub_expense_type1", index);
            await onChangeForm(key, value, index)
            let { expense_type, sub_expense_type1, ...rest } = initialFields
            Object.keys(rest).forEach((key) => onChangeForm(key, rest[key], index))
        }
        else if (key == "sub_expense_type2" && value != expenseDetails.sub_expense_type2) {
            await onSelectExpenseType2(data, "sub_expense_type2", index);
            await onChangeForm(key, value, index)
            let { expense_type, sub_expense_type1, sub_expense_type2, ...rest } = initialFields
            Object.keys(rest).forEach((key) => onChangeForm(key, rest[key], index))
        }
        else {
            await onChangeForm(key, value, index)
        }
    }
    const calcStandardAmt = async (expenseType,key,value) => {
        if (expenseType && expenseType.max_expense_amount) {
            let max_amount = expenseType["max_expense_amount"]
            if (expenseType?.expense_type == "Incidental allowance") {
                if (selectedTravelType == "Intracity") {
                    let total_time = calcTotalTime(toTime, fromTime)
                    if (total_time[1] < 4) {
                        await onChange("claim_expense_amount", (Math.round(max_amount / 2)))
                    }
                    else if (total_time[1] >= 4) {
                        await onChange("claim_expense_amount", (Math.round(max_amount)))
                    }
                }
                else {
                    if(key=="to_expense_date"){
                        let total_days = calcTotalDays(value,expenseDetails.from_expense_date) + 1
                        // await onChange("from_expense_date", expenseDetails.from_expense_date)
                        await onChange("to_expense_date", value)
                        await onChange("total_expense_days", total_days)
                        await onChange("claim_expense_amount", (Math.round(max_amount)) * total_days)
                    }
                    else{
                        await onChange("from_expense_date",value)
                        await onChange("to_expense_date","")
                        await onChange("total_expense_days",0)
                        await onChange("claim_expense_amount", 0)
                    }
                }
            }
            else if (expenseType?.expense_type == "Consolidated allowance") {
                let max_amount = expenseType["max_expense_amount"]
                if(key=="to_expense_date"){
                    let total_days = calcTotalDays(value,expenseDetails.from_expense_date) + 1
                    // await onChange("from_expense_date", expenseDetails.from_expense_date)
                    await onChange("to_expense_date", value)
                    await onChange("total_expense_days", total_days)
                    await onChange("claim_expense_amount", (Math.round(max_amount)) * total_days)
                }
                else{
                    await onChange("from_expense_date",value)
                    await onChange("to_expense_date","")
                    await onChange("total_expense_days",0)
                    await onChange("claim_expense_amount", 0)
                }
            }
        }
        getTotal()
    }
    const calcOwnVehicleStandard = async (key, value) => {
        let total_distance = 0
        let max_amt = selectedSubExpenseType2?.max_expense_amount
        if (key == "start_reading") {
            value != "" && await onChange("start_reading", value)
            await onChange("end_reading", null)
            await onChange("claim_expense_amount", null)
            await onChange("extra_expense_amount", null)
        }
        else if (key == "end_reading") {
            await onChange("extra_expense_amount", null)
            value != "" && await onChange("end_reading", value)
            if (expenseDetails.start_reading && value && Math.round(expenseDetails.start_reading) < Math.round(value)) {
                total_distance = Math.round(value) - Math.round(expenseDetails.start_reading)
                total_distance > 0 && await onChange("distance_calculated", total_distance)
                max_amt && total_distance > 0 && await onChange("claim_expense_amount", Math.round(max_amt * total_distance))
            }
            else {
                await onChange("claim_expense_amount", null)
                await onChange("total_expense_amount", null)
                await onChange("permissible_expense_amount", null)
            }
        }
        await onChange(key, value, index)
        getTotal()
    }
    const calcOwnVehicleTotal = async (key, value) => {
        let total_amt = (expenseDetails?.claim_expense_amount ?? 0) + (value ? Math.round(value) : 0)
        await onChangeForm(key, value ? Math.round(value) : null, index)
        if (total_amt != undefined || total_amt != null) {
            await onChange("total_expense_amount", total_amt)
            await onChange("permissible_expense_amount", total_amt)
            await onChange("approved_expense_amount", total_amt)
        }
        getTotal()
    }
    const calcTotalDaysAccommodation = async (key, value) => {
        let total_days = 0;
        if (selectedTravelType == "Intracity") {
            await onChange("total_expense_days", 1)
        }
        else if (key == "from_expense_date") {
            await onChange("to_expense_date", "")
            await onChange("permissible_expense_amount", "")
            await onChange("approved_expense_amount", "")
        }
        else if (key == "to_expense_date") {
            total_days = calcTotalDays(value, expenseDetails.from_expense_date) + 1
        }
        if (total_days && total_days > 0) {
            if (selectedExpenseTypes?.max_expense_amount) {
                let PermissibleAmt = (selectedExpenseTypes.max_expense_amount * total_days)
                await onChange("permissible_expense_amount", PermissibleAmt)
                await onChange("approved_expense_amount", PermissibleAmt)
            } else {
                await onChange("permissible_expense_amount", "")
            }
            await onChangeForm("claim_expense_amount", null, index)
        }
        await onChange(key, value)
        getTotal()
    }

    return (
        form &&
        <>
            <div className='expense-form'>
                {
                    length == 1 && index == 0 ? <></> : <img alt="view" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2 align-self-end' onClick={(e) => { onDeleteForm(index) }} />
                }
                <div className='d-flex align-item-center expense-form-fields'>
                    <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                        <div className='p-sub-heading ml-3'>Select Expense Type *</div>
                        <Dropdown
                            data={expenseTypes}
                            selectedValue={(data) => { onChange("expense_type", data?.expense_type, data) }}
                            value={expenseDetails?.expense_type ? expenseDetails.expense_type : 'Select Expense Type'}
                            render={'expenseType'}
                            arrow={'true'}
                            height={'NotificationHeight'}
                            disabled={(index + 1) != length}
                        />
                        {errors?.expense_type != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes?.["expense_type"]?.[errors?.expense_type]}</span>}
                    </div>
                    {
                        (form?.subExpenseType1 && form?.subExpenseType1?.length > 0) ?
                            <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                <div className='p-sub-heading ml-3'>Select Category *</div>
                                <Dropdown
                                    data={form.subExpenseType1}
                                    selectedValue={(data) => { onChange("sub_expense_type1", data.sub_expense_type1, data) }}
                                    value={expenseDetails?.sub_expense_type1 ? expenseDetails.sub_expense_type1 : 'Select Category'}
                                    render={'subExpenseType1'}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                    disabled={(index + 1) != length}
                                />
                                {errors?.sub_expense_type1 != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes?.["sub_expense_type1"]?.[errors?.sub_expense_type1]}</span>}
                            </div> : <></>
                    }
                    {
                        (form?.subExpenseType2 && form?.subExpenseType2?.length > 0) ?
                            <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
                                <div className='p-sub-heading ml-3'>Select Sub-Category *</div>
                                <Dropdown
                                    data={form?.subExpenseType2}
                                    selectedValue={(data) => { onChange("sub_expense_type2", data.sub_expense_type2, data) }}
                                    value={expenseDetails?.sub_expense_type2 ? expenseDetails?.sub_expense_type2 : 'Select Sub-Category'}
                                    render={'subExpenseType2'}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                    disabled={(index + 1) != length}
                                />
                                {errors?.sub_expense_type2 != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes?.["sub_expense_type2"]?.[errors?.sub_expense_type2]}</span>}
                            </div> : <></>
                    }
                    {
                        selectedExpenseTypes?.expense_type?.length > 0 &&
                        <>
                            {
                                expenseDetails?.expense_type == "Accommodation allowance" && expenseDetails?.sub_expense_type1 &&
                                <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select Check In Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => calcTotalDaysAccommodation("from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={false} />
                                    <DateField error={errors} field="to_expense_date" title="Select Check Out Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => calcTotalDaysAccommodation("to_expense_date", e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="total_expense_days" value={expenseDetails.total_expense_days} onChange={(e) => { }} title={"Total expense days "} type={"text"} placeholder={"Total expense days"} readOnly />
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => onChange("claim_expense_amount", e.target.value)} title={"Claim Expense Amt *"} type={"number"} placeholder={"Claim Expense Amt."} readOnly={false} />
                                    <InputField error={errors} field="permissible_expense_amount" value={expenseDetails.permissible_expense_amount} onChange={(e) => { }} title={"Max Permissible Amt "} type={"number"} placeholder={"Max Permissible Amt."} readOnly />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    <BillField employeeId={employeeId} error={errors} field="bill_path" billPath={expenseDetails.bill_path} onChange={(bill) => { onChange("bill_path", bill) }} disabled={false} />
                                </div>
                            }
                            {
                                (expenseDetails?.expense_type == "Consolidated allowance" &&
                                    <div className='d-flex align-items-baseline flex-wrap'>
                                        <DateField error={errors} field="from_expense_date" title="Select Check In Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => calcStandardAmt(selectedExpenseTypes,"from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                        <DateField error={errors} field="to_expense_date" title="Select Check Out Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => calcStandardAmt(selectedExpenseTypes,"to_expense_date",e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                        <InputField error={errors} field="total_expense_days" value={expenseDetails.total_expense_days} onChange={(e) => { }} title={"Total expense days"} type={"text"} placeholder={"Total expense days"} readOnly />
                                        <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => { }} title={"Claim Expense Amt "} type={"number"} placeholder={"Claim Expense Amt."} readOnly />
                                        <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    </div>)
                            }
                            {
                                ((expenseDetails?.expense_type == "Transportation" && expenseDetails.sub_expense_type1 && expenseDetails.sub_expense_type1 != "Own vehicle")) && <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select From Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => onChange("from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                    <DateField error={errors} field="to_expense_date" title="Select To Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => onChange("to_expense_date", e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => onChange("claim_expense_amount", e.target.value)} title={"Claim Expense Amt *"} type={"number"} placeholder={"Claim Expense Amt."} readOnly={false} />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    <BillField employeeId={employeeId} error={errors} field="bill_path" billPath={expenseDetails.bill_path} onChange={(bill) => { onChange("bill_path", bill) }} disabled={false} />
                                </div>
                            }
                            {
                                (expenseDetails?.expense_type == "Transportation" && expenseDetails.sub_expense_type1 == "Own vehicle" && expenseDetails.sub_expense_type2) && <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select From Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => onChange("from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                    <DateField error={errors} field="to_expense_date" title="Select To Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => onChange("to_expense_date", e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="start_reading" value={expenseDetails.start_reading} onChange={(e) => calcOwnVehicleStandard("start_reading", e.target.value)} title={"Start Reading *"} type={"number"} placeholder={"Start Reading"} readOnly={false} min={expenseDetails.end_reading} />
                                    <InputField error={errors} field="end_reading" value={expenseDetails.end_reading} onChange={(e) => calcOwnVehicleStandard("end_reading", e.target.value)} title={"End Reading *"} type={"number"} placeholder={"End Reading"} readOnly={false} max={expenseDetails.start_reading} />
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => { }} title={"Claimed Fuel Expenses"} type={"number"} placeholder={"Claimed Fuel Expenses "} readOnly />
                                    <InputField error={errors} field="extra_expense_amount" value={expenseDetails.extra_expense_amount} onChange={(e) => calcOwnVehicleTotal("extra_expense_amount", e.target.value)} title={"Toll/ Parking Expenses (if any)"} type={"number"} placeholder={"Toll/ Parking Expenses (if any)"} readOnly={false} />
                                    <InputField error={errors} field="permissible_expense_amount" value={expenseDetails.permissible_expense_amount} onChange={(e) => onChange("total_expense_amount", e.target.value)} title={"Total claim amt(fuel + extra)"} type={"number"} placeholder={"Total claim amt"} readOnly />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    <BillField employeeId={employeeId} error={errors} field="bill_path" billPath={expenseDetails.bill_path} onChange={(bill) => { onChange("bill_path", bill) }} disabled={false} isMandatory={expenseDetails.extra_expense_amount != null && expenseDetails.extra_expense_amount != ""} />
                                </div>
                            }
                             {
                                ((expenseDetails?.expense_type == "Unbilled Expenses" )) && 
                                <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select From Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => onChange("from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                    <DateField error={errors} field="to_expense_date" title="Select To Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => onChange("to_expense_date", e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => onChange("claim_expense_amount", e.target.value)} title={"Claim Expense Amt *"} type={"number"} placeholder={"Claim Expense Amt."} readOnly={false} />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    <BillField employeeId={employeeId} error={errors} field="bill_path" billPath={expenseDetails.bill_path} onChange={(bill) => { onChange("bill_path", bill) }} disabled={false} isMandatory={false} isAttachement={true}/>
                                </div>
                            }
                            {
                                expenseDetails?.expense_type == "Daily allowance" &&
                                <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select From Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => calcTotalDaysAccommodation("from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                    <DateField error={errors} field="to_expense_date" title="Select To Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => calcTotalDaysAccommodation("to_expense_date", e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="total_expense_days" value={expenseDetails.total_expense_days} onChange={(e) => { }} title={"Total expense days"} type={"text"} placeholder={"Total expense days"} readOnly />
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => onChange("claim_expense_amount", e.target.value)} title={"Claim Expense Amt *"} type={"number"} placeholder={"Claim Expense Amt."} readOnly={false} />
                                    <InputField error={errors} field="permissible_expense_amount" value={expenseDetails.permissible_expense_amount} onChange={(e) => { }} title={"Max Permissible Amt."} type={"number"} placeholder={"Max Permissible Amt "} readOnly />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark *"} placeholder={"Add Remarks"} readOnly={false} />
                                    <BillField employeeId={employeeId} error={errors} field="bill_path" billPath={expenseDetails.bill_path} onChange={(bill) => { onChange("bill_path", bill) }} disabled={false} />
                                </div>
                            }
                            {
                                expenseDetails?.expense_type == "Incidental allowance" &&
                                <div className='d-flex align-items-baseline flex-wrap'>
                                    <DateField error={errors} field="from_expense_date" title="Select Check In Date *" date={expenseDetails.from_expense_date} onChangeDate={(e) => calcStandardAmt(selectedExpenseTypes,"from_expense_date", e.target.value)} maxDate={toDate} minDate={fromDate} disabled={selectedTravelType == "Intracity"} />
                                    <DateField error={errors} field="to_expense_date" title="Select Check Out Date *" date={expenseDetails.to_expense_date} onChangeDate={(e) => calcStandardAmt(selectedExpenseTypes,"to_expense_date",e.target.value)} maxDate={toDate} minDate={expenseDetails.from_expense_date} disabled={selectedTravelType == "Intracity"} />
                                    <InputField error={errors} field="total_expense_days" value={expenseDetails.total_expense_days} onChange={(e) => { }} title={"Total expense days "} type={"text"} placeholder={"Total expense days"} readOnly />
                                    {selectedTravelType == "Intracity" && <InputField error={errors} field="total_expense_time" value={expenseDetails.total_expense_time} onChange={(e) => { }} title={"Total travel time "} type={"text"} placeholder={"Total travel time"} readOnly />}
                                    <InputField error={errors} field="claim_expense_amount" value={expenseDetails.claim_expense_amount} onChange={(e) => { }} title={"Claim Expense Amt "} type={"number"} placeholder={"Claim Expense Amt"} readOnly />
                                    <RemarkField error={errors} field="remark" value={expenseDetails.remark} onChange={(e) => onChange("remark", e.target.value)} title={"Remark"} placeholder={"Add Remarks"} readOnly={false} />
                                </div>
                            }
                        </>
                    }
                </div>

            </div>

            <div className='d-flex justify-content-end align-item-center mt-4 mb-4'>
                {
                    index != maxFormLength - 1 && index == length - 1 &&
                    <button
                        type="submit"
                        className='btn btn-primary login-btn py-2 align-self-end'
                        onClick={(e) => { handleSubmit() }}
                    >
                        +&nbsp;{"Add New Expense"}
                    </button>
                }
            </div>
        </>
    )
}

export default ExpenseForms