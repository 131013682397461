import React, { useEffect, useState } from 'react'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { agencyDetailsInitiatePrimaryCol, agencyDetailsPrimaryCol, constantVals } from './constants'
import ClearanceTable from './ClearanceTable'
import ClearanceDetailsPopup from './ClearanceDetailsPopup'
import ContactDetailsPopUp from './ContactDetailsPopUp'
import { toaster } from '../../../../utils/Utils'
import { getAgencyWiseClearance } from '../../../../constants/api.service'
import { someThingWentWrong } from '../../../../constants/Messages'

function AgencyWiseTrack(props) {
    const { selectedClearance, screenType,response } = props
    
    
    const [selectedAgencyDetails, setSelectedAgencyDetails] = useState(null)

   
    const handleAction = (index) => {
        setSelectedAgencyDetails(response["request"][index])
    }
    const onDismiss = () => {
        setSelectedAgencyDetails(null)
    }
   
    return (
        <div className='h-27vh mt-10px'>
            {
                selectedAgencyDetails ?
                    screenType == constantVals.ClearanceInitiate ?
                        <ContactDetailsPopUp
                            title={"Agency Listing"}
                            onDismiss={onDismiss}
                            details={selectedAgencyDetails["contacts"]}
                        /> :
                        <ClearanceDetailsPopup
                            title={selectedAgencyDetails?.agency_name ?? "Clearance Details"}
                            isRemarkPopUp={false}
                            agencyData={selectedAgencyDetails}
                            onDismiss={onDismiss}
                            commentOnly={true}
                            clearanceId={selectedClearance["clearance_id"]}
                            agencyConfigId={selectedAgencyDetails["agency_config"]}
                            showAgencyDetails={true}
                            viewOnly={true}
                            selectedEmployee={selectedClearance}
                        />
                    : <></>}
            {response ?
                <ClearanceTable
                    data={response["request"]}
                    columns={screenType == constantVals.ClearanceInitiate ? agencyDetailsInitiatePrimaryCol : agencyDetailsPrimaryCol}
                    hasPrimaryAction={false}
                    hasSecondaryAction={true}
                    handleSecondaryAction={(index) => handleAction(index)}
                    isFilterOpen={true}
                    scrollEnabled={false}
                />
                :
                <div className='mt-5 h-90-percent'>
                    <DataNotFound />
                </div>
            }
        </div>
    )
}

export default AgencyWiseTrack