import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as cetActions from '../login/LoginAction';
import { currentDateYMD } from '../../../../utils/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { bindActionCreators } from 'redux';
import moment from 'moment';

class ImportantDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    // componentDidMount() {
    //     this.refreshCETDetails()
    // }

    // refreshCETDetails() {
    //     this.setState({
    //         loading: true
    //     });
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     let body = {
    //         emp_id: this.props.loginData.userData.Perno,
    //         modified_date: currentDateYMD()
    //     }
    //     fetchApiCall(Config.getLatestuserDetailsFromEdpCet, body, headers, true, '')
    //         .then(res => {
    //             sessionOut(this.props, res);
    //             if (res.data.status_code == 200) {
    //                 if (res.data.user_details[0]) {
    //                     // this.props.actions.authUser(res.data.user_details[0]);
    //                 }
    //                 if (res.data.manager_details[0]) {
    //                     this.props.actions.authManager(res.data.manager_details[0]);
    //                 }
    //                 this.setState({
    //                     loading: false
    //                 });
    //                 setTimeout(() => { toast.success(res.data.message, { hideProgressBar: true }) }, 100)
    //             } else {
    //                 this.setState({
    //                     loading: false
    //                 })
    //                 setTimeout(() => { toast.warn(res.data.message, { hideProgressBar: true }) }, 100)
    //             }

    //         }).catch(err => {
    //             this.setState({
    //                 loading: false
    //             });
    //             setTimeout(() => { toast.error(err, { hideProgressBar: true }) }, 100)

    //         })
    // }

    render() {
        let userData = this.props.loginData.userData;
        return (
            <>
                <div className='p-heading mx-4 mt-3 mb-2'>Important Dates Details</div>
                <div className='row'>
                    <div className='col-4 mb-3'>
                        <div className='p-sub-heading ml-3'>Date of Joining</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="text"
                                value={userData.Doj ? moment(userData.Doj).format("DD-MM-YYYY") : ''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='col-4 mb-3'>
                        <div className='p-sub-heading ml-3'>Last Promotion Date</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="text"
                                value={userData.Dlprom ? moment(userData.Dlprom).format("DD-MM-YYYY") : ''}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='col-4 mb-3'>
                        <div className='p-sub-heading ml-3'>Tata Group Joining Date</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="text"
                                value={userData.tata_group_joining_date ? moment(userData.tata_group_joining_date).format("DD-MM-YYYY") : ''}
                                readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='col-4 mb-3'>
                        <div className='p-sub-heading ml-3'>TML Group Joining Date</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="text"
                                value={userData.tml_group_joining_date ? moment(userData.tml_group_joining_date).format("DD-MM-YYYY") : ''}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(cetActions, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ImportantDate);