import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import moment from 'moment';
class ConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { loading, separationExitData, onDismiss, title } = this.props
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className='popupCard-box p-0 w-79'>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body occasion-bg-color scroll-auto h-68vh">
                        <div className="col-md-12 px-0">
                            <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                <div className={'scroll-y h-65vh'}>
                                    <div className='p-heading mx-2 mt-2 mb-2'>Employee Details</div>
                                    <div className='row'>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Employee Ticket No.</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.employee_ticket_no ? separationExitData.employee_ticket_no : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Employee Name</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.employee_name ? separationExitData.employee_name : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Grade/Level</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.grade ? separationExitData.grade : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Function</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.function ? separationExitData.function : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Sub-Function1</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={separationExitData.sub_function_1 ? separationExitData.sub_function_1 : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Present Designation</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.present_designation ? separationExitData.present_designation : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Reporting Manager (Ticket No.)</div>
                                            <div className='p-placeholder-text'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    type="text"
                                                    value={separationExitData.reporting_ticket ? separationExitData.reporting_ticket : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Date of Joining</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={moment(separationExitData.date_of_joining ? separationExitData.date_of_joining : '-').format("DD-MM-YYYY")}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Resignation Date</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={moment(separationExitData.date_of_resignation ? separationExitData.date_of_resignation : '-').format("DD-MM-YYYY")}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Present Address</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={separationExitData.present_address ? separationExitData.present_address : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Permanent Address</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={separationExitData.permanent_address ? separationExitData.permanent_address : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-4 mb-3'>
                                            <div className='p-sub-heading ml-3'>Present Mobile No.</div>
                                            <div className='dropdown'>
                                                <input
                                                    className='form-control p-placeholder px-3'
                                                    type="text"
                                                    value={separationExitData.present_mobile_no ? separationExitData.present_mobile_no : '-'}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='row justify-content-between'>
                                            <div className='p-heading mx-4 mt-2 mb-2'>For Future References</div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Future Contact No</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type='number'
                                                        maxLength={13}
                                                        value={separationExitData.future_contact_number ? separationExitData.future_contact_number : '-'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Email ID (Personal)</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type='text'
                                                        maxLength={50}
                                                        value={separationExitData.email_id_personal ? separationExitData.email_id_personal : '-'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className='p-sub-heading ml-3'>Email ID (Alternate)</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type='text'
                                                        value={separationExitData.email_id_alternative ? separationExitData.email_id_alternative : '-'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <div className='row justify-content-between'>
                                            <div className='p-heading mx-4 mt-2 mb-2'>Employee response on exit interview questionnaire</div>
                                        </div>
                                        <div className='column'>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3'>Reason for Leaving</div>
                                                <div className='row dropdown mt-1'>
                                                    <input
                                                        className='form-control p-placeholder px-3'
                                                        type="text"
                                                        value={separationExitData.reason_for_leaving ? separationExitData.reason_for_leaving : '-'}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>Please elaborate on above reason</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        value={separationExitData.reason_for_leaving_elaborate ? separationExitData.reason_for_leaving_elaborate : '-'}
                                                        maxLength={1500}
                                                        readOnly={true} >
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>Would you like to specify which other Organization you are joining?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.other_organization_info ? separationExitData.other_organization_info : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3'>Why did you join this organization ?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.reason_of_joining ? separationExitData.reason_of_joining : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>What were some your key achievements at company?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.main_role ? separationExitData.main_role : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>What aspect of organization do you hold in high regards ?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.aspect_of_organization ? separationExitData.aspect_of_organization : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>How was your relationship with your superior ? Did you receive challenging work and support?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.relationship_with_superior ? separationExitData.relationship_with_superior : '-'}
                                                        readOnly={true} >
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>Please list top 3 reasons which caused you dissatisfaction during your tenure.</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.dissatisfaction_feature ? separationExitData.dissatisfaction_feature : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>Was there anything that the organization could have done to retain your services here?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.action_of_organization_to_retain_sevice ? separationExitData.action_of_organization_to_retain_sevice : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className='mb-3 mx-3'>
                                                <div className='p-sub-heading ml-3 required'>Would you like to work for this organization at a later date and in a different capacity?</div>
                                                <div className='row p-placeholder-text mt-1'>
                                                    <textarea
                                                        className="form-control sub-textarea cfm-subcard"
                                                        rows="3"
                                                        id="comment"
                                                        maxLength="1500"
                                                        value={separationExitData.like_to_work_later ? separationExitData.like_to_work_later : '-'}
                                                        readOnly={true}>
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmPopup;