import React, { Component } from 'react'
import './ConfirmationPopup.css'
import delete_popup from '../../../assets/images/cfm/delete_popup.svg';

class ConformationPopUpLatest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteLeaveData: this.props.deleteLeaveData
        }
    }
    render() {
        const { action, text, title } = this.props
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className='flex-direction-column justify-content-center p-3'>
                            <div className='align-self-center'>
                                <img
                                    alt="delete_popup"
                                    src={delete_popup}
                                    className="mb-2"
                                ></img>
                            </div>
                            <div className="popup_text text-center p-3 m-3">
                                {text}
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer btnBox'>
                    <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                    {action === 'delete' &&
                        <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.deleteData()} />
                    }
                </div>
                </div>
            </div>
        )
    }
}

export default ConformationPopUpLatest