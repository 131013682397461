//PopUp Second Screen
import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { dynamic_screen_type } from '../../../../utils/Utils';
import { admin_notification_type } from '../../../../utils/Utils';
import AddNewTask from './AddNotification';
import PushNotificationPopup from './PushNotificationPopup';
import ScreenPopUpNotifications from './ScreenPopUpNotification';
import AnnouncementPopUp from './AnnouncementPopUp';
import LoginImagePopup from './LoginImagePopup';
import LeadershipBehavioursPopup from './LeadershipBehavioursPopup';
import TataBuzzNews from './TataBuzzNews';
import UpcomingOccasions from './UpcomingOccasions';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class ConfirmPopups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: true,
            loading: false,
            imageName: '',
            typeData: admin_notification_type(),
            image: '',
            imageHyperlink: '',
            imageTitle: '',
            modifiedDatetime: '',
            createdDatetime: '',
            show: false,

            noteTitle: '',
            screenTypeValue: 'Select Notification Type',
            screenTypeCode: '',
            hyperlink: '',
            noteType: 'Select note type',
            isActive: true,
            noteTypeData: [{ value: 'Image' }, { value: 'Text' }],
            notificationID: '',
            update: false,
            dropdownValue: '',
            notificationType: [{ value: 'Organization', type: 'Organization' }, { value: 'Plateform', type: 'Plateform' }],
            selectTypeView: '',
            descriptionText: '',
            typeScreenData: dynamic_screen_type(),
            dynamicPopupImageName: '',
            dynamicPopupImage: '',
            dynamicPopuphyperlink: '',
            fromDate: '',
            toDate: '',
            noteContent: '',
            openFirstPopUp: true,
        }
    }

    componentDidMount() {
        if (this.props.action === 'update') {
            this.setState({
                dynamicPopupImageName: this.props.updateData.image_name,
                screenTypeCode: this.props.updateData.screen_type,
                noteTitle: this.props.updateData.note_title,
                noteContent: this.props.updateData.note_content,
                noteType: this.props.updateData.note_type,
                dynamicPopupImage: this.props.updateData.image_url,
                dynamicPopuphyperlink: this.props.updateData.image_hyperlink,
                fromDate: this.props.updateData.from_date,
                toDate: this.props.updateData.to_date,
                isActive: this.props.updateData.is_active,
                notificationID: this.props.updateData.notification_id,
                action: this.props.action
            })
        }
    }

    activePopupUpdate = () => {
        this.setState({ update: true })
    }

    add = () => {
        this.submit('add')
    }

    update = () => {
        this.deactivePopup()
        this.submit('update')
    }

    deactivePopup = () => {
        this.setState({ update: false })
    }

    validate = (value) => {
        let end_date = new Date(this.state.toDate);
        let start_date = new Date(this.state.fromDate);
        if (value === 'PushNotification') {
            if (this.state.title === '') {
                toast.warning('Please enter image title', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else if (this.state.selectTypeView === '') {
                toast.warning('Please select notification type', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else if (this.state.PushNotificationImageName === '') {
                toast.warning('Please select image', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else if (this.state.descriptionText === '') {
                toast.warning('Please write description', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.sumbit(value)
            }
        }
        else if (value === 'Screen-Pop-Up') {
            if (this.state.screenTypeCode === '') {
                toast.warning('Please select screen type', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.noteTitle === '') {
                toast.warning('Please select Note title', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.noteType === 'Select note type' || this.state.noteType == '') {
                toast.warning('Please select Note type', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.fromDate === '') {
                toast.warning('Please Enter start date', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.toDate === '') {
                toast.warning('Please Enter end date', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (start_date > end_date) {
                toast.warning('Start date cannot exceed to date', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else {
                this.sumbit(value)
            }
        }
    }

    sumbit = (value) => {
        switch (value) {
            case 'PushNotification':
                console.log("value:", value)
                this.insertPushNotification();
                break;
            case 'Screen-Pop-Up':
                break;
            case 'Announcement':
                console.log("value:", value)
                break;
            case 'Log-In-Page':
                console.log("value:", value)
                break;
            default: toast.warn("Error", { hideProgressBar: true });
        }
    }

    insertPopupNotification = (action) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            image_name: this.state.dynamicPopupImageName,
            screen_type: this.state.screenTypeCode,
            note_title: this.state.noteTitle,
            note_content: this.state.noteContent,
            note_type: this.state.noteType,
            image_url: this.state.dynamicPopupImage,
            image_hyperlink: this.state.dynamicPopuphyperlink,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            is_active: this.state.isActive,
            action: action,
            notification_id: this.state.notificationID
        }
        fetchApiCall(Config.insertPopUpNotificationBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false })
                    // this.props.updateData()
                    setTimeout(() => { window.location.reload() }, 1000)
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    insertDynamicImage = () => {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
        let body = {
            image_name: this.state.imageName,
            image_title: this.state.imageTitle,
            image_link: this.state.image,
            image_hyperlink: this.state.imageHyperlink,
        }
        fetchApiCall(Config.insertDynamicSliderImageBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false },
                        () => this.props.onDismiss(),
                        () => this.props.getnewImageData()
                    )
                    // setTimeout(() =>{window.location.reload()})
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    changeType = (data) => {
        this.setState({
            selectTypeCode: data.code,
            selectTypeView: data.value,
        })
    }

    changeNotificationType = (data) => {
        this.setState({
            screenTypeCode: data.code,
            screenTypeValue: data.value,
        })
    }

    setDropdown = (data) => {
        this.setState({
            screenTypeCode: data.code,
            screenTypeValue: data.value
        })
    }

    changeNoteType = (data) => {
        this.setState({ noteType: data.value })
    }

    fileSelectHandler = (event) => {
        this.setState({ dynamicPopupImageName: event.target.files[0] ? event.target.files[0].name : '' })
        if (event.target.files && event.target.files[0]) {
            let maxAllowedSize = 1048576;           // 1MB size
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => { this.setState({ dynamicPopupImage: e.target.result }) }
            }
            else {
                toast.warn('Please upload image less than 1MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ dynamicPopupImage: '' })
            }
        }
        else { this.setState({ dynamicPopupImage: '' }) }
    }

    openNextPopUp = () => {
        this.setState({
            openNextPopup: true,
            openFirstPopUp: false
        })
    }

    closePopUp = () => {
        this.setState({
            openFirstPopUp: false
        })
    }

    back = () => {
        this.setState({
            openNextPopup: false,
            openFirstPopUp: true
        })
    }

    close = () => {
        this.setState({
            openNextPopup: !this.state.openNextPopup
        })
    }

    allClose = () => {
        this.props.closeFirstPopup()
        this.setState({
            openFirstPopUp: false,
            openNextPopup: false,
        })
    }

    render() {
        const { data, closeFirstPopup } = this.props
        return (
            <div>
                {
                    this.state.openNextPopup &&
                    <>
                        {this.state.screenTypeValue === 'Push Notification' &&
                            <PushNotificationPopup
                                close={this.close}
                                back={this.back}
                                NotificatioType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Screen Pop-Up' &&
                            <ScreenPopUpNotifications
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Announcement' &&
                            <AnnouncementPopUp
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Log-In Page' &&
                            <LoginImagePopup
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Leadership Announcement' &&
                            <LeadershipBehavioursPopup
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Upcoming Occasions' &&
                            <UpcomingOccasions
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'Tata Buzz News' &&
                            <TataBuzzNews
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }

                        {this.state.screenTypeValue === 'tataMotorsSparsh' &&
                            <AnnouncementPopUp
                                close={this.close}
                                back={this.back}
                                Action={"add"}
                                NotificationType={this.state.screenTypeValue}
                                AllClose={this.allClose}
                                loading={this.state.loading}
                            />
                        }
                    </>
                }

                {
                    this.state.openFirstPopUp &&
                    <AddNewTask
                        loading={this.state.loading}
                        closePopUp={this.closePopUp}
                        openNextPopUp={this.openNextPopUp}
                        closeFirstPopup={closeFirstPopup}
                        dropdownValue={(data) => this.setDropdown(data)}
                    />
                }
            </div>
        )
    }
}

export default ConfirmPopups;