import React, { Component } from "react";
import "./MOMStyles.css";

import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import * as Config from "../../../../constants/Config";
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';

const SendWishes = `Send Wishes`
const Preview = `Preview`
const CherishTheMoments = `Cherish the moments with `
const Images = `Images`
const WishTo = `PALAK`
const CharacterLength = `Message (300 Characters)`
const MailHeading = `Drishti 2.0`
const Regards = `Regards,`
class SendWishPopups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LongAnniversarySelected: false,
      AnniversarySelected: false,
      BirthdaySelected: false,
      textValue: '',
      ShowPreview: false,
      SelectedImgUrl: '',
      sendWishPopData: [],
      loading: false
    }
  }

  imageChosen = (type, e) => {
    if (type === "SelectFirstImg") {
      this.setState({
        BirthdaySelected: true,
        LongAnniversarySelected: false,
        AnniversarySelected: false,
        SelectedImgUrl: this.props.sendWishPopData.FisrtImg
      })
    }
    else if (type === "SelectThirdImg") {
      this.setState({
        BirthdaySelected: false,
        LongAnniversarySelected: true,
        AnniversarySelected: false,
        SelectedImgUrl: this.props.sendWishPopData.ThirdImg
      })
    }
    else {
      this.setState({
        BirthdaySelected: false,
        LongAnniversarySelected: false,
        AnniversarySelected: true,
        SelectedImgUrl: this.props.sendWishPopData.SecondImg
      })
    }
    console.log("image selected", e)
  };

  onChangeText = () => {
    this.setState({
      textValue: "Type your Message"
    })
  }

  sendWishEmailNotification = () => {
    this.setState({
      loading: true
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      sender_email: this.props.sendWishPopData.sender_email,
      receiver_email: this.props.sendWishPopData.receiver_email,
      sender_name: this.props.sendWishPopData.sender_name,
      receiver_name: this.props.sendWishPopData.receiver_name,
      event_type: this.props.notification_type,
      greeting_text: this.state.textValue,
      selected_image_link: this.state.SelectedImgUrl
    };
    console.log("body", body)
    fetchApiCall( Config.sendWishEmailNotification, body, headers, true, "")
      .then((res) => {
        if (res.data.status_code === "200") {
          this.setState({
            loading: false,
          });
          this.props.onDismiss()
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  }

 validateTextInput = (event) => {
        event.preventDefault()
        if (this.state.textValue === '') {
          toast.warn('Please enter greeting message', {
              position: "bottom-center",
              hideProgressBar: true,
              className: 'toastWarn'
          })
      }
        else {
            this.preview(event)
        }
    }

  preview = () => {
    this.setState({
      ShowPreview: !this.state.ShowPreview
    })
    console.log("View:")
  }

  render() {
    console.log("AnniversarySelected:", this.state.AnniversarySelected);
    console.log("LongAnniversarySelected:", this.state.LongAnniversarySelected);
    console.log("BirthdaySelected:", this.state.BirthdaySelected);
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        {this.state.ShowPreview ?
          <div>
            <div className="modal popup-box popup-box-bg-color">
              <div className="sender-popup-card p-0">
                <div className="modal-header">
                  <h4 className="modal_title"> {Preview}</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.props.onDismiss}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card">
                    <div className="container-head">
                      <div className="mail-head"><p className="title-text">{MailHeading}</p></div>
                      <img className="mail-head-img" src="https://di0zifa10d7yc.cloudfront.net/EDP/TML/leaveMailPicture/tata_motor_logo.png" alt="tata image" />
                    </div>
                    <div className="container-mid">
                      <img className="selected-img" src={this.state.SelectedImgUrl} />
                      <p className="mail-text-msg-h">Hi {this.props.sendWishPopData.receiver_name},</p>
                      <p className="mail-text-msg">{this.state.textValue}</p>
                      <p className="mail-text-msg-r">{Regards}</p>
                      <p className="mail-text-msg-h">{this.props.sendWishPopData.sender_name}</p>
                    </div>
                    <div className="container-bottom">
                      <img className="mail-bottom-img" src="https://di0zifa10d7yc.cloudfront.net/EDP/TML/leaveMailPicture/header_logo.png" alt="More When One" />
                    </div>
                  </div>
                </div>
                <div className="modal-footer btnBox">
                  <input
                    type="button"
                    onClick={this.preview}
                    value="GO BACK"
                    className="cancelBtn"
                  />
                  <input
                    type="button"
                    value="CONFIRM"
                    className="ml-2 validateBtn"
                    onClick={this.sendWishEmailNotification}
                  />
                </div>
              </div>
            </div>
          </div>
          : <div>
            <div className="modal popup-box popup-box-bg-color">
              <div className="sender-popup-card p-0">
                <div className="modal-header">
                  <h4 className="modal_title"> {SendWishes}</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.props.onDismiss}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body p-11px">
                  <div>
                    <h4 className="modal_title cherish-title-wish-popup">
                      {CherishTheMoments}
                      <strong style={{ color: "#1E293B solid" }}>{this.props.sendWishPopData.receiver_name}!</strong>
                    </h4>
                  </div>
                  <div>
                    <label className="form-label ml-3 mt-2">
                      {CharacterLength}
                    </label>
                    <textarea
                      className="form-control textareaMom"
                      maxLength={300}
                      style={{ fontWeight: "bold" }}
                      onChange={(e) => this.setState({ textValue: e.target.value })}
                      readOnly={false}
                      value={this.state.textValue}
                      placeholder={"Type your Message"}
                    ></textarea>
                  </div>
                  <h5 className="send-popup-image-text">{Images}</h5>
                  <div className="send-popup-small-img">
                    <img
                      src={this.props.sendWishPopData.FisrtImg}
                      alt="Image"
                      className={this.state.BirthdaySelected ? "isSelect" : ""}
                      onClick={(e) => this.imageChosen("SelectFirstImg", e.target.src)}
                    ></img>
                    <img
                      src={this.props.sendWishPopData.SecondImg}
                      alt="Image"
                      className={this.state.AnniversarySelected ? "isSelect" : ""}
                      onClick={(e) => this.imageChosen("SelectSecondImg", e.target.src)}
                    ></img>
                    <img
                      src={this.props.sendWishPopData.ThirdImg}
                      alt="Image"
                      className={this.state.LongAnniversarySelected ? "isSelect" : ""}
                      onClick={(e) => this.imageChosen("SelectThirdImg", e.target.src)}
                    ></img>
                  </div>
                </div>
                <div className="modal-footer btnBox">
                  <input
                    type="button"
                    onClick={this.props.onDismiss}
                    value="CANCEL"
                    className="cancelBtn"
                  />
                  <input
                    type="button"
                    value="PREVIEW"
                    className="ml-2 validateBtn"
                    onClick={this.validateTextInput}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default SendWishPopups;



