// Alert PopUp

import React, { Component } from 'react';
import '../../employeeScreens/transfer/Transfer.css';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';
import bellIcon from '../../../../assets/drishti_images/bellIcon.svg';
import bellCrossIcon from '../../../../assets/drishti_images/bellCrossIcon.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
class NotificationConfirmPopup extends Component {
    render() {
        const { title, content, action, onDismiss, loader } = this.props
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loader} />
                <div className="body-box p-0">
                    <div className="modal-headers mt-2" >
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                    </div>
                    <div className='hr'>
                        <hr />
                    </div>
                    <div className="modal-body">
                        {action === 'delete' || action === 'reject' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={delete_popup}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {content}
                                </div>
                            </div>
                        }

                        {action === "ApprovePushNotification" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellIcon"
                                        src={bellIcon}
                                        style={{ "width": '48px' }}
                                    ></img>
                                </div>
                                <div className="text-center p-2 m-2 flex-direction-column">
                                    <label className='popup_sm-text text-align-left'>
                                        Note:
                                    </label>
                                    <label className='popup_sm-text text-align-left'>
                                        1. After approving this push notification will send to all devices.
                                    </label>
                                    <label className='popup_sm-text text-align-left'>
                                        2. It will take around 5 minutes to send push notification to all devices.
                                    </label>
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            action === 'RejectPushNotification' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellCrossIcon"
                                        src={bellCrossIcon}
                                        className=""
                                    ></img>
                                </div>
                                <div className=" text-center p-2 m-2 flex-direction-column">
                                    {/* <label className='popup_sm-text text-align-left'>
                                        Note:
                                    </label>
                                    <label className='popup_sm-text text-align-left'>
                                        After reject push notification will not send to any device
                                    </label> */}
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            action === 'enabledisable' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellCrossIcon"
                                        src={bellCrossIcon}
                                        className=""
                                    ></img>
                                </div>
                                <div className=" text-center p-2 m-2 flex-direction-column">
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            action === 'deleteAnnouncement' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellCrossIcon"
                                        src={delete_popup}
                                        className=""
                                    ></img>
                                </div>
                                <div className=" text-center p-2 m-2 flex-direction-column">
                                    <label className='popup_sm-text text-align-left'>
                                        Note:
                                    </label>
                                    <label className='popup_sm-text text-align-left'>
                                        After reject push notification will not send to any device
                                    </label>
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            action === 'deleteGemsAdminData' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellCrossIcon"
                                        src={delete_popup}
                                        className=""
                                    ></img>
                                </div>
                                <div className=" text-center p-2 m-2 flex-direction-column">
                                    {/* <label className='popup_sm-text text-align-left'>
                                        Note:
                                    </label>
                                    <label className='popup_sm-text text-align-left'>
                                        After reject push notification will not send to any device
                                    </label> */}
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }

                        {
                            action === 'inactive' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="bellCrossIcon"
                                        src={delete_popup}
                                        className=""
                                    ></img>
                                </div>
                                <div className=" text-center p-2 m-2 flex-direction-column">
                                    <label className='announcement-pin-content-bold text-align-left mt-12px'>
                                        {content}
                                    </label>
                                </div>
                            </div>
                        }
                    </div>

                    <div className='modal-footer btnBox'>
                        {action === "ApprovePushNotification" &&
                            <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => this.props.approve()} />
                        }
                        {action === 'RejectPushNotification' &&
                            <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.props.reject()} />
                        }
                        {action === 'delete' &&
                            <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                        }
                        {action === 'deleteAnnouncement' &&
                            <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                        }
                        {action === 'deleteAnnouncement' &&
                            <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.deleteAnnouncement()} />
                        }
                        {action === 'enabledisable' &&
                            <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        }
                        {action === 'enabledisable' &&
                            <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.props.deactivateData()} />
                        }
                        {action === 'inactive' &&
                            <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                        }
                        {action === 'inactive' &&
                            <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.inActiveImage()} />
                        }
                        {action === 'delete' ?
                            <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.deleteData()} /> :
                            action === 'reject' ?
                                <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.props.rejectData()} /> :
                                action === 'approve' ?
                                    <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => this.props.approveData()} /> : null
                        }

                        {action === 'deleteGemsAdminData' &&
                            <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                        }
                        {action === 'deleteGemsAdminData' &&
                            <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.deleteGemsAdminData()} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationConfirmPopup;