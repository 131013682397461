import * as type from '../../../../redux/action/ActionType';

const initialState = {
    AnnouncementCollectData: [],
}

export const AnnouncementRequestData = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_ANNOUNCEMENT_DATA:
            return {
                ...state,
                AnnouncementCollectData: action.payload,
            }
        default:
            return state;
    }
}