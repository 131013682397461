import * as type from '../../../../redux/action/ActionType';

export const isAdmin = (res) => {
    console.log('LoginActionisAdmin:', res)
    return {
        type: type.TOGGLE_ADMIN,
        payload: res
    }
}

export const adminData = (res) => {
    console.log('adminData', res);
    return {
        type: type.ADMIN_DATA,
        payload: res
    }
}

//--------for emp id admin------
export const adminEmpID = (res) => {
    return {
        type: type.ADMIN_EMP_ID,
        payload: res
    }
}

export const authUser = (res) => {
    return {
        type: type.ADMIN_USER_LOGIN_SUCCESS,
        userData: res,
        payload: res
    }
}

export const getProfileImage = (res) => {
    return {
        type: type.ADMIN_GET_EMPLOYEE_PROFILE_DETAILS,
        payload: res
    }
}

export const adminRole = (res) => {
    return {
        type: type.ADMIN_ROLE,
        payload: res
    }
}

export const logout = () => {
    return {
        type: type.ADMIN_LOGOUT
    }
}
export const setCompany = (res) => {
    return {
        type: type.SET_ADMIN_COMPANY,
        payload: res
    }
}

export const setScheduleJobs = (res) => {
    return {
        type: type.SET_SCHEDULE_JOBS,
        payload: res
    }
}

//keyclok token
export const getToken = (res) => {
    return {
        type: type.GET_ADMIN_TOKEN,
        payload: res
    }
}

export const adminOperativeRole = (res) => {
    // console.log("adminOperativeRole res",res)
    // console.log("adminOperativeRole res roles",res.roles)
    return {
        type: type.ADMIN_OPERATIVE_ROLE,
        payload: res,
        // operativeRole: res
    }
}