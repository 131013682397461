//  This is for edit details from Registered table
import React, { Component } from 'react'

class EditDetailsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editData: []
        }
    }
    componentDidMount() {
        this.setState({
            editData: this.props.editdetails
        },
            console.log("editData: ", this.state.editData))
    }
    render() {
        console.log("this.props.editDetails:", this.props.editdetails)
        const { onDismiss, editDetails } = this.props
        return (
            <>
                <div className="modal popup-box">
                    <div className="registration-body p-0">
                        <div className="modal-headers mt-2" >
                            <h4 className="modal_title black">{"Edit"}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                        </div>
                        <div className='hr'>
                            <hr />
                        </div>
                        <div className="modal-body">
                            <div className='firstContainer'>
                                <div className='card-new'>
                                    <div className='secondContainer'>
                                        Edit Details Screen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default EditDetailsForm;