import React, { useEffect, useState } from 'react'
import { docType, imageType } from '../../../adminScreens/clearance/constants'
import FileSaver from 'file-saver'
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg'
import downloadLatestImg from '../../../../../assets/drishti_images/downloadLatestImg.svg'
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction'
import { getS3Document } from '../../../../../constants/api.service'
import { toaster } from '../../../../../utils/Utils'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'

function SuggesterPreviewCompoment(props) {
    const { data, isUrl = false, handleDeleteDocument, viewDelete, download = false, addDocumentKey, handleAddDocumentDelete } = props
    const [selectedUploadBill, setSelectedUploadBill] = useState({})
    const [uploadBillModal, setUploadBillModal] = useState(false)
    const [loading, setLoading] = useState(false)


    const previewAttachment = async (data) => {
        if (!data.attachment) { 
            const newAttachment = await getPresizeUrl(data);
            setSelectedUploadBill({ ...data, attachment: newAttachment?.attachment })
         }else{setSelectedUploadBill({ ...data,...data[0]})}
        setUploadBillModal(true)
    }

    const downloadFile = async(data) => {
        let toasterMsg = "Your document has been downloaded.";
        const response = await fetch(data?.attachment);
        const blobData = await  response.blob();
        commonDownloadFile(blobData,toasterMsg,data?.name);
    }

    const getPresizeUrl = async (data) => {
        setLoading(true)
        let params = {
            document_path: data.path,
            document_id: data.id
        }
        try {
            const res = await getS3Document(params)
            if (res.status === 200 || res.status === 201) {
                const url = URL.createObjectURL(res?.data);
                return { type: data.type, extension: data.extension, name: data.name, attachment: url }
            }
            else {
                return {}
            }

        }
        catch (error) {
            console.log("error in get Presize Url request API", error)
        } finally { setLoading(false) }
    }

    return (
        <>
            <LoaderIndicator loading={loading} />
            <div style={{ overflow: 'auto', maxHeight: '150px' }}>
                <div>
                    <div className='w-94' >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col"><div className='thead'>{'Sr No'}</div></th>
                                    <th scope="col"><div className='thead'>{'Document Name'}</div></th>
                                    <th scope="col"><div className='thead'>{'Document Type'}</div></th>
                                    <th scope="col"><div className='thead'>{'Action'}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((listValue, index) => {
                                    return (
                                        <tr key={index}>
                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{index + 1}</div></td>
                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{data[index]?.name || data[index]?.name}</div></td>
                                            <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{data[index]?.type}</div></td>
                                            <td>
                                                <div className="td-choice-pay" >
                                                    <img alt="view" src={docType.includes(listValue.extension) ? downloadLatestImg : view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => {
                                                        docType.includes(listValue.name) ?
                                                            downloadFile(listValue)
                                                            :
                                                            previewAttachment(listValue)
                                                    }} />
                                                    {
                                                        viewDelete && (
                                                            <img
                                                                src={delete_action}
                                                                alt='Delete'
                                                                style={{ width: '30px' }}
                                                                className='pointer mx-1'
                                                                onClick={() => handleDeleteDocument(index, 'prev')}
                                                            />)
                                                    }
                                                    {addDocumentKey && (
                                                        <img
                                                            src={delete_action}
                                                            alt='Delete'
                                                            style={{ width: '30px' }}
                                                            className='pointer mx-1'
                                                            onClick={() => handleAddDocumentDelete(index)}
                                                        />
                                                    )}

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {uploadBillModal === true &&
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{"Attachment"}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={e => { setUploadBillModal(false); setSelectedUploadBill({}) }}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                            {
                                download &&
                                <div className='row gap-10 mb-3 justify-content-end pointer' onClick={(e) => { downloadFile(selectedUploadBill) }}>
                                    <div className='add-btn-text'>Download</div>
                                    <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                </div>
                            }
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    <>
                                        {(imageType.includes(selectedUploadBill.extension)) &&
                                            <>
                                                <img src={selectedUploadBill?.attachment ? selectedUploadBill.attachment : ""} alt="" className="upload_bills_modal_body" />
                                            </>
                                        }
                                        {(selectedUploadBill.extension === 'pdf') &&
                                        <>
                                            <iframe className='upload_bills_modal_body' src={selectedUploadBill?.attachment ?? ''}></iframe>
                                        </>
                                        }
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SuggesterPreviewCompoment