//first popUp
import React, { Component } from 'react';
import LoaderIndicator from '../../../../../shared/loader/LoaderIndicator';
import Dropdown from '../../../../../shared/components/Dropdown';
import '../../../../employeeScreens/todo/ToDo.css'
import { gemsMaintenanceModule } from '../../../../../../utils/Utils';
import { toast } from 'react-toastify';
import { AddPopUp } from '../gemsMaintenanceConstant';
import '../gemsMaintainance.css'
class AddPopups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTypeValue: AddPopUp.SelectNotificationType,
            typeData: gemsMaintenanceModule(),
        }
    }

    render() {
        const { loading, closeFirstPopup, dropdownValue, validate, addFormDropDownValue } = this.props
        return (
            <>
                <LoaderIndicator loading={loading} />
                <div className="modal popup-box" id="addTodoModal">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content toDoModal bg-light">
                            <div className="modal-header edit-cardtitle">
                                <h4 className="modal_title">{'Add Form'}</h4>
                                <button type="button" className="close" onClick={closeFirstPopup}>&times;</button>
                            </div>
                            <div className="gemsMaitenanceFormBody">
                                <form className='todoModalBody'>
                                    <label className='form-label ml-2'>{AddPopUp.FormType}</label>
                                    <Dropdown
                                        data={this.state.typeData}
                                        selectedValue={(data) => dropdownValue(data)}
                                        value={addFormDropDownValue.key ? addFormDropDownValue.value : this.state.screenTypeValue}
                                        render={'value'}
                                        height={'NotificationHeight'}
                                    />
                                </form>
                            </div>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>{AddPopUp.Step}<span className='popup-next-screen-txt-bold'>1</span>/2</div>
                                </div>
                                <div className='justify-content-start'>
                                    <input type='button'
                                        value='NEXT'
                                        className='ml-2 validateBtn'
                                        onClick={() => validate()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default AddPopups;