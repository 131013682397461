import React, { Component } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri';
import './DocumentStyles.css';
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg';
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg';
import download from '../../../../assets/drishti_images/documents/download.svg';

import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import pdf from '../../../../assets/drishti_images/documents/pdf.svg';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import Retiral from './Retiral';
import Dropdown from '../../../shared/components/Dropdown';
import GenerateBonafide from './GenerateBonafide';
import { toast } from 'react-toastify';
import { configStore } from "../../../../redux/ConfigStore";
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import { checkActivePoup, checkPopupCount } from '../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../shared/dynamicCustomPopum/DynamicCustomPopup';
import * as homeActions from '../landingScreen/ActivePopupAction';
import { bindActionCreators } from 'redux';
import NotePopup from './NotePopup';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';

const Form_A_text = 'This document gives you Form16 - Part A related information for the AY year-'
const Form_B_text = 'This document gives you Form16 - Part B related information for the the AY year-'
const Annexure_text = 'This document gives you the details of the Form 16 - Annexure for the the AY year- '
const _80_G_text = 'This document is the 80G Certificate for the the AY year-'
const Annual_text = 'This document gives you the details of the Annual compensation for the the AY year-'

const ITSheet_text = 'This document gives you the details of the Income tax worksheet for the -'
const passbook_text = 'This document gives you the details of PF passbook'

const Form_A_title = 'Form 16 - Type A'
const Form_B_title = 'Form 16 - Type B'
const Annexure_title = 'Form 16 - Annexure'
const _80_G_title = 'Form 16 - 80G'
const Annual_title = 'Annual Compensation'

const payslip_description = 'This document gives you information of your income, deductions and total paid days of -'
const err_message = 'Unable to contact server\nPlease try again later'
const doc_success_download = 'Your document has been downloaded. Use your PAN number to open the file.'
const additiona_doc_success_download = 'Your document has been downloaded.'

const today = new Date();
const previousMonthDate = new Date(today);
previousMonthDate.setMonth(today.getMonth() - 1);
const prevMonthNumber = today.getMonth()
const currentYear = today.getFullYear()
const previousMonthName = previousMonthDate.toLocaleString('default', { month: 'long' })

class DocumentScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showNote: false,
            html: '',
            show: false,
            showIncome: false,
            showTax: false,
            showRetirals: false,
            showAddition: false,
            isPFActive: false,
            isSafActive: false,
            isPensionActive: false,
            taxData: [],
            ayYearData: [],
            fyYearData: [],
            fy_year_value: '',
            fy_year_code: '',
            ay_year_value: '',
            payslip_year_value: currentYear,
            payslip_month_value: previousMonthName,
            payslip_month_count: prevMonthNumber,
            payslip_year_data: [],
            payslip_month_data: [],
            openCard: '',
            noteType: '',
            monthlyPayslipData: {},
            monthlyITSheetData: {},
            latestITSheetData: [],
            additionalDocumentsList: [],
            showBonafideMessage: false,
            // itSheetData: [],

            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',

            is_service_Unavailable: false,  // service unavailble from backend
            document_Service_reason: '',

            is_MonthlyPayslip_service_Unavailable: false,
            monthlyPayslip_Service_reason: '',
            is_form16_service_Unavailable: false,
            form16_Service_reason: '',
            is_trustFundDocuments_service_Unavailable: false,
            trustFundDocuments_Service_reason: '',
            is_additionalDocuments_service_Unavailable: false,
            additionalDocuments_Service_reason: '',
        }
    }

    componentDidMount() {
        this.getCurrentYearDropdown()
        this.checkService();
        this.checkActivePoupDetails();
    }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('document');
        this.setState({
            isActive: activeDetails && checkPopupCount('document') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('document')
            }
        })
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "document_center" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    document_Service_reason: item.reason
                })
            }
            if (item.service_type == "document_monthly_payslip" && item.is_active == false) {
                this.setState({
                    is_MonthlyPayslip_service_Unavailable: true,
                    monthlyPayslip_Service_reason: item.reason
                })
            }
            if (item.service_type == "document_form16" && item.is_active == false) {
                this.setState({
                    is_form16_service_Unavailable: true,
                    form16_Service_reason: item.reason
                })
            }
            if (item.service_type == "document_trust_fund_documents" && item.is_active == false) {
                this.setState({
                    is_trustFundDocuments_service_Unavailable: true,
                    trustFundDocuments_Service_reason: item.reason
                })
            }
            if (item.service_type == "document_additional_documents" && item.is_active == false) {
                this.setState({
                    is_additionalDocuments_service_Unavailable: true,
                    additionalDocuments_Service_reason: item.reason
                })
            }
        })
    }

    setFyYear = (listValue) => {
        this.setState({
            fy_year_value: listValue.fy_year,
            fy_year_code: listValue.value
        }, () => this.getEmployeeApplicableAnnualStatement())
    }

    setAyYear = (listValue) => {
        this.setState({
            ay_year_value: listValue,
        }, () => this.getAnnualDocuments())
    }

    setMonthYear = (listValue, type) => {
        if (type == "month") {
            this.setState({
                payslip_month_value: listValue.month,
                payslip_month_count: listValue.month_count
            },
                () => this.getMonthDocuments()
            )
        }
        if (type == 'year') {
            this.setState({
                payslip_year_value: listValue,
            },
                () => this.getMonthDocuments()
            )
        }
    }

    toggleCard = (type) => {
        if (type === 'payslip') {
            this.setState({ showIncome: !this.state.showIncome, showTax: false, showRetirals: false, showAddition: false }, () => {
                this.state.showIncome && this.getMonthDocuments()
            })
        }
        else if (type === 'tax') {
            this.setState({ showTax: !this.state.showTax, showIncome: false, showRetirals: false, showAddition: false }, () => {
                this.state.showTax && this.getAnnualDocuments()
            })
        }
        else if (type === 'retirals') {
            this.setState({ showRetirals: !this.state.showRetirals, showIncome: false, showTax: false, showAddition: false }, () => {
                this.state.showRetirals && this.getEmployeeApplicableAnnualStatement()
            })
        }
        else if (type === 'addition') {
            this.setState({ showAddition: !this.state.showAddition, showIncome: false, showTax: false, showRetirals: false }, () => {
                this.state.showAddition && this.getEmpAdditionalDocuments()
            })
        }
        else return null
    }

    getCurrentYearDropdown() {
        this.setState({
            loading: true
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            doj: this.props.loginData.userData.Doj ? this.props.loginData.userData.Doj : ''
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCurrentMasterDocumentCenter, body, headers, false, '').then(res => {
            sessionOut(this.props, res);
            if (res.data.status_code == 200) {
                this.setState({
                    loading: false,
                    ayYearData: res.data.data.assesment_years ? res.data.data.assesment_years : [],
                    fyYearData: res.data.data.financial_years ? res.data.data.financial_years : [],
                    payslip_month_data: res.data.data.payslip_months ? res.data.data.payslip_months : [],
                    payslip_year_data: res.data.data.payslip_years ? res.data.data.payslip_years : [],

                    // fy_year_value: res.data.data.financial_years ? res.data.data.financial_years[0].fy_year : '',
                    // fy_year_code: res.data.data.financial_years ? res.data.data.financial_years[0].value : '',
                    // ay_year_value: res.data.data.assesment_years ? res.data.data.assesment_years[0] : '',
                    fy_year_value: res.data.data.financial_years.length !== 0 ? res.data.data.financial_years[0]?.fy_year : '',
                    fy_year_code: res.data.data.financial_years.length !== 0 ? res.data.data.financial_years[0]?.value : '',
                    ay_year_value: res.data.data.assesment_years.length !== 0 ? res.data.data.assesment_years[0] : '',
                })
            } else {
                this.setState({
                    loading: false,
                    ayYearData: [],
                    fyYearData: [],
                    payslip_month_data: [],
                    payslip_year_data: [],
                    fy_year_value: '',
                    fy_year_code: '',
                    ay_year_value: '',
                });
            }
        }).catch(err => {
            console.log("getCurrentMasterDocumentCenter error : ", err)
            this.setState({
                loading: false,
                ayYearData: [],
                fyYearData: [],
                payslip_month_data: [],
                payslip_year_data: [],
                fy_year_value: '',
                fy_year_code: '',
                ay_year_value: ''
            });
        })
    }

    getMonthDocuments() {
        this.setState({
            loading: true
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            document_type: 'payslip',
            month_number: this.state.payslip_month_count,
            year: this.state.payslip_year_value
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getPayslipsAndItworksheet, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        monthlyPayslipData: res.data.data.payslip_month_list ? res.data.data.payslip_month_list : {},
                        monthlyITSheetData: res.data.data.it_sheet_month_list ? res.data.data.it_sheet_month_list : {},
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        monthlyPayslipData: {},
                        monthlyITSheetData: {},
                    });
                }
            }).catch(err => {
                console.log("getMonthDocuments error : ", err)
                this.setState({
                    loading: false,
                    monthlyPayslipData: {},
                    monthlyITSheetData: {},
                });
            })
    }

    getAnnualDocuments() {
        this.setState({
            loading: true
        });
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            assesment_year: this.state.ay_year_value
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getForm16Documents, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        taxData: res.data.data.form16_list ? res.data.data.form16_list : [],
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        taxData: []
                    });
                }
            }).catch(err => {
                console.log("getAnnualDocuments error : ", err)
                this.setState({
                    loading: false,
                    taxData: []
                });
            })
    }

    getEmployeeApplicableAnnualStatement = () => {
        this.setState({
            loading: true,
        });
        const req = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            finanical_year: this.state.fy_year_code ? this.state.fy_year_code : ''
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getEmployeeApplicableAnnualStatement, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        loading: false,
                        isPFActive: res.data.applicable_for_provident_fund,
                        isSafActive: res.data.applicable_for_superannuation_scheme,
                        isPensionActive: res.data.applicable_for_pension_scheme
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log("getEmployeeApplicableAnnualStatement error : ", err)
                this.setState({
                    loading: false
                });
            })
    }


    getEmpAdditionalDocuments = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : ''
        }
        fetchApiCall(Config.getEmployeeAdditionalDocuments, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        additionalDocumentsList: res.data.data.additional_documents_list ? res.data.data.additional_documents_list : [],
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log("getEmpAdditionalDocuments error : ", err)
                this.setState({
                    loading: false
                })
            })
    }

    getPassbookData = () => {
        this.setState({ loading: true })
        let req = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : ''
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getPassBookDetails, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        html: res.data.passbook_details,
                        loading: false,
                    }, () => this.generatePDF(this.state.html))
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.log("getPassbookData error : ", err)
                this.setState({
                    loading: false
                });
            })
    }

    generatePDF = async (html) => {
        if (process.browser || window !== undefined) {
            const html2pdf = (await import("html2pdf.js")).default;
            var pdfFileName = 'PF Passbook';
            var element = html;
            var option = {
                margin: [0.5, 1, 1, 1],
                filename: pdfFileName,
                html2canvas: { dpi: 192, letterRendering: true, height: window.innerHeight * 2 },
                jsPDF: {
                    orientation: 'portrait',
                    unit: 'cm',
                    format: [40, 25]
                }
            };
            html2pdf().from(element).set(option).save();
        }
    }

    additionalDocumentDownload(documentType, fileName) {
        this.setState({ loading: true })
        let req = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            file_name: fileName,
            document_type: documentType,
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        let EndurlAnnual = Config.downloadAdditionalDocuments
        fetchApiCall(EndurlAnnual, req, headers, false, ''
        ).then(res => {
            sessionOut(this.props, res);
            if (res.data.status_code == 200) {
                this.setState({
                    url: res.data.data.url,
                    loading: false,
                }, () => this.actualAdditionalDownload(this.state.url))
            } else {
                this.setState({
                    loading: false,
                });
            }
        }).catch(err => {
            console.log("additionalDocumentDownload error : ", err)
            this.setState({
                loading: false
            });
        })
    }

    documentDownload(path) {
        this.setState({ loading: true })
        let req = {
            path: path
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getMonthlyPayslipData, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        url: res.data.data.url,
                        loading: false,
                    },
                        () => this.actualDownload(this.state.url)
                    )
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.log("documentDownload error : ", err)
                this.setState({
                    loading: false
                });
            })
    }

    actualDownload(url) {
        commonDownloadFile(url, doc_success_download)
    }

    actualAdditionalDownload(url) {
        commonDownloadFile(url, additiona_doc_success_download)
    }
    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }

    render() {
        const companyName = configStore.getState().loginState.company.company_sht_txt
        const { showNote, showIncome, showAddition, latestITSheetData, monthlyPayslipData, monthlyITSheetData, payslip_year_data, showTax, taxData, fyYearData, showRetirals, ayYearData, isPFActive, isPensionActive, isSafActive, additionalDocumentsList, payslip_month_data } = this.state
        return (
            <div>
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-100vh justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.document_Service_reason} width={true} />
                    </div>
                    :
                    <div>
                        <LoaderIndicator loading={this.state.loading} />
                        <div className='row p-12px'>
                            <div className="col-md-12 px-0">
                                <div className="row justify-content-between align-item-center">
                                    <div className='row' onClick={this.gotoLanding}>
                                        <img src={overviewButton} alt='' />
                                    </div>
                                    <div className='row'>
                                        <div>
                                            <input
                                                type="button"
                                                className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-199px'
                                                value="GENERATE BONAFIDE"
                                                onClick={() => this.setState({ show: !this.state.show })}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                    <div className='d-flex justify-content-between align-item-center'>
                                        <div className='row align-item-center'>
                                            <div className='announcement-inner-title common-text-color'>{'Monthly Payslip & Income Tax Worksheet'}</div>
                                            <img alt="info" src={infoicon} onClick={() => this.setState({ showNote: !this.state.showNote, noteType: 'payslip' })}
                                                data-toggle='modal' data-target='#ShowNoteModal' className="info-icon ml-2"></img>
                                        </div>
                                        {showIncome ?
                                            <div className='row align-item-center'>
                                                {!this.state.is_MonthlyPayslip_service_Unavailable && payslip_year_data.length != 0 &&
                                                    <>
                                                        <div className='px-5px'>
                                                            <Dropdown
                                                                data={payslip_month_data}
                                                                selectedValue={(listValue) => this.setMonthYear(listValue, "month")}
                                                                value={this.state.payslip_month_value}
                                                                render={'month'}
                                                            />
                                                        </div>
                                                        <div className='px-5px'>
                                                            <Dropdown
                                                                data={payslip_year_data}
                                                                selectedValue={(listValue) => this.setMonthYear(listValue, 'year')}
                                                                value={this.state.payslip_year_value}
                                                                render={''}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            :
                                            <div className='justify-content-end' onClick={() => this.toggleCard('payslip')}>
                                                <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                            </div>
                                        }
                                    </div>
                                    {showIncome &&
                                        <>

                                            <div>
                                                <>
                                                    {this.state.is_MonthlyPayslip_service_Unavailable ?
                                                        <div className='justify-content-center d-flex'>
                                                            <ServiceUnavailable reason={this.state.monthlyPayslip_Service_reason} />
                                                        </div>
                                                        :
                                                        <>
                                                            {Object.keys(monthlyPayslipData).length == 0 && Object.keys(monthlyITSheetData).length == 0 ?
                                                                <div className='justify-content-center d-flex'>
                                                                    <DataNotFound />
                                                                </div>
                                                                :
                                                                <div className='row'>
                                                                    {monthlyPayslipData.map((item, i) =>
                                                                        <div key={i} className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3">
                                                                            <div className='flex flex-direction-row align-item-center'>
                                                                                <div className='mx-3'>
                                                                                    <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                                                </div>
                                                                                <div className='flex-direction-column'>
                                                                                    <div className='document-innercard-content-title common-text-color'>{'Monthly Payslip'} {item.company}</div>
                                                                                    <div className='document-innercard-content-subtitle light-common-label-color'>{payslip_description}{' '}{item.month_name + ' - ' + item.year}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='align-item-center' onClick={() => this.documentDownload(item.path)}>
                                                                                <img alt="download" src={download} className="w-46px h-46px"></img>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {monthlyITSheetData.map((item, i) =>
                                                                        <div key={i} className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3">
                                                                            <div className='flex flex-direction-row align-item-center'>
                                                                                <div className='mx-3'>
                                                                                    <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                                                </div>
                                                                                <div className='flex-direction-column'>
                                                                                    <div className='document-innercard-content-title common-text-color'>{'Income Tax Worksheet'} {item.company}</div>
                                                                                    <div className='document-innercard-content-subtitle light-common-label-color'>{ITSheet_text}{' '}{item.month_name}{' - '} {item.year}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='align-item-center' onClick={() => this.documentDownload(item.path)}>
                                                                                <img alt="download" src={download} className="w-46px h-46px"></img>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                                <div className='justify-content-end mt-3' onClick={() => this.toggleCard('payslip')}>
                                                    <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                    <div className='d-flex justify-content-between align-item-center'>
                                        <div className='announcement-inner-title common-text-color'>Form 16</div>
                                        {showTax ?
                                            <>
                                                {!this.state.is_form16_service_Unavailable && this.state.ay_year_value.length != 0 &&
                                                    <Dropdown
                                                        data={ayYearData}
                                                        selectedValue={(listValue) => this.setAyYear(listValue)}
                                                        value={this.state.ay_year_value}
                                                        render={''}
                                                    />
                                                }
                                            </>
                                            :
                                            <div className='justify-content-end' onClick={() => this.toggleCard('tax')}>
                                                <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                            </div>
                                        }
                                    </div>
                                    {showTax &&
                                        <>
                                            <div>
                                                {this.state.is_form16_service_Unavailable ?
                                                    <div className='justify-content-center d-flex'>
                                                        <ServiceUnavailable reason={this.state.form16_Service_reason} />
                                                    </div>
                                                    :
                                                    <>
                                                        {taxData.length != 0 ?
                                                            <div className='row'>
                                                                {taxData.map((list, i) =>
                                                                    <div className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3" key={i} onClick={() => this.documentDownload(list.path)}>
                                                                        <div className='flex flex-direction-row align-item-center'>
                                                                            <div className='mx-3'>
                                                                                <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                                            </div>
                                                                            <div className='flex-direction-column'>
                                                                                <div className='document-innercard-content-title common-text-color'>
                                                                                    {list.type === 'Part_A' ? Form_A_title :
                                                                                        list.type === 'Part_B' ? Form_B_title :
                                                                                            list.type === '2f10k' ? Annexure_title :
                                                                                                list.type === '80G' ? _80_G_title :
                                                                                                    list.type === 'CompStructure' ? Annual_title
                                                                                                        : ''
                                                                                    } {list.company}
                                                                                </div>
                                                                                <div className='document-innercard-content-subtitle mt-2 light-common-label-color'>
                                                                                    {list.type === 'Part_A' ? Form_A_text + list.financial_year :
                                                                                        list.type === 'Part_B' ? Form_B_text + list.financial_year :
                                                                                            list.type === '2f10k' ? Annexure_text + list.financial_year :
                                                                                                list.type === '80G' ? _80_G_text + list.financial_year :
                                                                                                    list.type === 'CompStructure' ? Annual_text + list.financial_year
                                                                                                        : ''
                                                                                    } </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='align-item-center'>
                                                                            <img alt="download" src={download} className="w-46px h-46px"></img>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                }
                                                            </div>
                                                            :
                                                            <div className='justify-content-center d-flex'>
                                                                <DataNotFound />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                <div className='justify-content-end mt-3' onClick={() => this.toggleCard('tax')}>
                                                    <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                {companyName === 'TML' &&
                                    <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                        <div className='d-flex justify-content-between align-item-center'>
                                            <div className='row align-item-center'>
                                                <div className='announcement-inner-title common-text-color'>{'Trust fund Documents'}</div>
                                                <img alt="info" src={infoicon} onClick={() => this.setState({ showNote: !this.state.showNote, noteType: 'retiral' })}
                                                    data-toggle='modal' data-target='#ShowNoteModal' className="info-icon ml-2"></img>
                                            </div>

                                            {showRetirals ?
                                                <>
                                                    {!this.state.is_trustFundDocuments_service_Unavailable &&
                                                        <div className='dropdown mx-3'>
                                                            <button
                                                                className='btn common-dropdown-text d-flex justify-content-between'
                                                                type='button' id="dropdownMenuButton"
                                                                data-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                {"FY -" + this.state.fy_year_value}
                                                                <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                                                            </button>
                                                            <div className='dropdown-menu' aria-labelledby="dropdownMenuButton">
                                                                {fyYearData.map((listValue, i) => {
                                                                    return (
                                                                        <label key={i} className='dropdown-item' onClick={(e) => this.setFyYear(listValue)}>{"FY -" + listValue.fy_year}</label>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                <div className='justify-content-end' onClick={() => this.toggleCard('retirals')}>
                                                    <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                                </div>
                                            }
                                        </div>
                                        {showRetirals &&
                                            <>
                                                {this.state.is_trustFundDocuments_service_Unavailable ?
                                                    <div className='justify-content-center d-flex'>
                                                        <ServiceUnavailable reason={this.state.trustFundDocuments_Service_reason} />
                                                    </div>
                                                    :
                                                    <Retiral
                                                        fy_year={this.state.fy_year_code}
                                                        showPF={isPFActive}
                                                        showPension={isPensionActive}
                                                        showSAF={isSafActive}
                                                        loginData={this.props.loginData}
                                                        toggleCard={(type) => this.toggleCard(type)}
                                                    />
                                                }
                                                <div className='justify-content-end mt-3' onClick={() => this.toggleCard('retirals')}>
                                                    <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                                </div>
                                            </>
                                        }
                                    </div>
                                }
                                <div className='document_card mt-3 p-3 mb-24px col-md-12'>
                                    <div className='d-flex justify-content-between align-item-center'>
                                        <div className='row align-item-center'>
                                            <div className='announcement-inner-title common-text-color'>Additional Documents</div>
                                            <img alt="info" src={infoicon} onClick={() => this.setState({ showNote: !this.state.showNote, noteType: 'addition' })}
                                                data-toggle='modal' data-target='#ShowNoteModal' className="info-icon ml-2"></img>
                                        </div>
                                        {!showAddition &&
                                            <div className='justify-content-end' onClick={() => this.toggleCard('addition')}>
                                                <img alt="hide" src={down_arrow} className="up_arrow"></img>
                                            </div>
                                        }
                                    </div>
                                    {showAddition &&
                                        <div>
                                            {this.state.is_additionalDocuments_service_Unavailable ?
                                                <div className='justify-content-center d-flex'>
                                                    <ServiceUnavailable reason={this.state.additionalDocuments_Service_reason} />
                                                </div>
                                                :
                                                <div className=''>
                                                    <>
                                                        {(additionalDocumentsList != '' || companyName === 'TML') ?
                                                            <>
                                                                <div className='row'>
                                                                    {companyName === 'TML' &&
                                                                        <div className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3" >
                                                                            <div className='flex flex-direction-row align-item-center'>
                                                                                <div className='mx-3'>
                                                                                    <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                                                </div>
                                                                                <div className='flex-direction-column'>
                                                                                    <div className='document-innercard-content-title common-text-color'>{'PF Passbook'}</div>
                                                                                    <div className='document-innercard-content-subtitle light-common-label-color'>{passbook_text} </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='align-item-center' onClick={() => this.getPassbookData()}>
                                                                                <img alt="download" src={download} className="w-46px h-46px"></img>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {additionalDocumentsList != '' &&
                                                                        <>
                                                                            {additionalDocumentsList.map((item, i) =>
                                                                                <div key={i} className="d-flex justify-content-between align-item-center document_data_card MW-30-100 mx-3">
                                                                                    <div className='flex flex-direction-row align-item-center'>
                                                                                        <div className='mx-3'>
                                                                                            <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                                                        </div>
                                                                                        <div className='flex-direction-column'>
                                                                                            <div className='document-innercard-content-title common-text-color'>{item.title}</div>
                                                                                            <div className='document-innercard-content-subtitle light-common-label-color'>{item.description}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='align-item-center'
                                                                                        onClick={() => this.additionalDocumentDownload(item.document_type, item.file_name)}
                                                                                    >
                                                                                        <img alt="download" src={download} className="w-46px h-46px"></img>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            }
                                                                        </>
                                                                    }
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className='justify-content-center d-flex'>
                                                                    <DataNotFound />
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                            }
                                            <div className='justify-content-end mt-3' onClick={() => this.toggleCard('addition')}>
                                                <img alt="hide" src={up_arrow} className="hide_icon"></img>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.show &&
                            <GenerateBonafide
                                show={this.state.show}
                                onDismiss={() => this.setState({ show: false })}
                                loginData={this.props.loginData}
                            />
                        }
                        {showNote &&
                            <NotePopup
                                type={this.state.noteType}
                                onDismiss={() => this.setState({ showNote: false })} />}
                    </div>
                }
                {
                    this.state.isActive &&
                    <DynamicCustomPopupModal
                        loading={this.state.isActive}
                        note_title={this.state.note_title}
                        note_content={this.state.note_content}
                        note_type={this.state.note_type}
                        image_url={this.state.image_url}
                        image_hyperlink={this.state.image_hyperlink}
                        message={this.state.message}
                        onDismiss={this.hideModal} />
                }
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DocumentScreen);
