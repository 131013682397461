import * as type from '../../../../redux/action/ActionType'

export const getPANDetails = (res) => {
    return{
        type : type.GET_PAN_DETAILS,
        payload: res
    }
}

export const getAadharDetails = (res) => {
    return{
        type : type.GET_AADHAR_DETAILS,
        payload: res
    }
}

export const getPermanentAddressDetails = (res) => {
    return{
        type : type.GET_PERMANENT_ADDRESS_DETAILS,
        payload: res
    }
}

export const getCurrentAddressDetails = (res) => {
    return{
        type : type.GET_CURRENT_ADDRESS_DETAILS,
        payload: res
    }
}

export const getContactDetails = (res) => {
    console.log("res getContactDetails action-------------: ", res)
    return{
        type : type.GET_CONTACT_DETAILS,
        payload: res
    }
}

export const getBankDetails = (res) => {
    return{
        type : type.GET_BANK_DETAILS,
        payload: res
    }
}

export const appLogout = () => {
    return{
        type : type.APP_LOGOUT
    }
}