import React, { useState } from 'react'
import { docType, imageType } from '../../../adminScreens/clearance/constants'
import FileSaver from 'file-saver'
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import downloadLatestImg from '../../../../../assets/drishti_images/downloadLatestImg.svg'
import { getPresignedDocumentUrl } from '../../../../../constants/api.service'
import { toaster } from '../../../../../utils/Utils'
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction'

function ClearancePreviewComponent(props) {
    const { data, download = false } = props
    const [selectedUploadBill, setSelectedUploadBill] = useState({})
    const [uploadBillModal, setUploadBillModal] = useState(false)
    const [presignedUrl, setPresignedUrl] = useState(null)

    const previewAttachment = (data) => {
        setUploadBillModal(true)
        setSelectedUploadBill(data)
        previewAttachmentCall(data)
    }
    const downloadFile = (url,data) => {
        let toasterMsg = "Your document has been downloaded.";
        commonDownloadFile(url, toasterMsg, data.name);
    }

    const previewAttachmentCall = (selectedPre) => {
        // setLoading(true)
        let params = {
            document_id: selectedPre.id,
            document_path: selectedPre.path
        }
        getPresignedDocumentUrl(params)
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setPresignedUrl(data?.data ? data?.data?.document_url : null)
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                // setLoading(false)
            })
    }

    return (
        <>
            {/* <div className='toggle-card-heading ml-3 mt-10px mb-10'>Attachments</div> */} 
            <div className='justify-content-center'>

                <div className='w-94' >
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><div className='thead'>{'Sr No'}</div></th>
                                <th scope="col"><div className='thead'>{'Name'}</div></th>
                                <th scope="col"><div className='thead'>{'Preview'}</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((listValue, index) => {
                                return (
                                    <tr key={index}>
                                        <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{index + 1}</div></td>
                                        <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.name}</div></td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { previewAttachment(listValue)}} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {uploadBillModal === true &&
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{selectedUploadBill.name ? selectedUploadBill.name : ''}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={e => { setUploadBillModal(false); setSelectedUploadBill({}) }}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                            {
                                download &&
                                <div className='row gap-10 mb-3 justify-content-end pointer' onClick={(e) => { downloadFile(presignedUrl, selectedUploadBill) }}>
                                    <div className='add-btn-text'>Download</div>
                                    <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                </div>
                            }
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    <>
                                        {imageType.includes(selectedUploadBill.extension) &&
                                            <img src={presignedUrl ? presignedUrl : ""} alt="" className="upload_bills_modal_body" />
                                        }
                                        {(selectedUploadBill.extension === 'pdf') &&
                                            <iframe className='upload_bills_modal_body' src={presignedUrl && presignedUrl}></iframe>
                                        }
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ClearancePreviewComponent