import React, { Component } from 'react';
import calander from '../../../../assets/images/applyLeave/calander.png'
import radioOn from '../../../../assets/images/applyLeave/radioOn.png'
import close from '../../../../assets/images/applyLeave/close.png'
import radioOff from '../../../../assets/images/applyLeave/radioOff.png'
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as leaveActions from './LeaveAction';
import moment from 'moment';
import { toast } from 'react-toastify';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css'
import './LeaveScreen.css'
import Dropdown from '../../../shared/components/Dropdown'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import down_arrow from '../../../../assets/drishti_images/documents/down_arrow.svg'
import up_arrow from '../../../../assets/drishti_images/documents/up_arrow.svg'
import OutsideClickHandler from 'react-outside-click-handler';

class ApplyLeavecard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			leavetype: ['LATE COMING', 'EARLY GOING', 'COD', 'WFH'],
			showStartCalendar: false,
			showEndCalendar: false,
			hideCard: false,
			startDate: props.show ? props.selected_date : new Date(),
			endDate: props.show ? props.selected_date : new Date(),
			leaveRequests: [],
			leaveRequestStatus: [],
			index: 0,
			loading: false,
			reason: '',
			approver: '',
			leaveCatCode: '',
			leaveCatValue: 'Select Leave Type',
			absenseLevaeData: ['Full Day', 'First Half', 'Second Half'],
			checked: 0,
			totalDays: '',
			leaveRequestTypes: [],
			leaveTypeActive: '',
			attendanceEnquiryDetails: [],
			weekOff: [],
			publicHolidays: [],
			leaveRequestsWithStatus: [],
			approved: [],
			error_in_deletion: [],
			error_in_posting: [],
			pending_for_approval: [],
			total_days: '',
			leaveTypeCode: '',
			rejected: [],
			approved_and_post_pending: [],
			delete_approval_pending: [],
			deleted_and_update_in_progress: [],
			enddateArray: [],
			lwop: [],
			leaveMonthValue: moment(new Date()).format("MMMM"),
			activeYear: '',
			calendarEndDate: '',
			calendarStartDate: ''
		}
	}

	componentDidMount() {
		this.getActiveYear()
		this.getLeaveRequestMasterData();
		this.getAttendanceEnquiryDetails(new Date().getMonth() + 1, new Date().getFullYear())
		this.onsubmitChange('FD', 'leave_type_code');
		this.onsubmitChange(this.props.loginData.userData.Reporting, 'approver');
		this.onsubmitChange(this.props.loginData.managerData.Imailid, 'approver_email');
		this.onsubmitChange(this.props.loginData.userData.Imailid, 'requestor_email');
		this.onsubmitChange(moment(this.state.startDate).format("YYYY-MM-DD"), 'from_date');
		this.onsubmitChange(moment(this.state.endDate).format("YYYY-MM-DD"), 'to_date');
		this.onsubmitChange('', 'total_days')
	}
	closeCalendars = () => {
		this.setState({
			showStartCalendar: false, showEndCalendar: false
		});
	}
	getLeaveRequestDetails(month) {
		this.setState({
			loading: true
		});
		const headers = {
			'Content-Type': 'application/json',
		}
		let tempmonth = (month < 10) ? '0' + (month) : (month);

		let body = {
			emp_id: this.props.loginData.userData.Perno,
			leave_category_code: 'All',
			leave_status_code: 'All',
			filter_type: '',
			month: tempmonth.toString(),
			pa: this.props.loginData.userData.PersArea,
			psa: this.props.loginData.userData.PSubarea,
			requestor_emp_id: this.props.loginData.userData.Perno
		}
		fetchApiCall(Config.getPreviousYearLeaveRequest, body, headers, false, '')
			.then(res => {
				sessionOut(this.props, res);
				if (res.data.status_code == 200) {
					this.setState({
						leaveRequestsWithStatus: res.data.data.leave_requests ? res.data.data.leave_requests : [],
						loading: false,
					});
				} else {
					this.setState({
						loading: false,
						leaveRequests: [],
					});
				}
			}).catch(err => {
				this.setState({
					loading: false
				});
			})
			.then(() =>
				this.getLeaveWithStatus())
	}

	getAllDays(strDate, endDate, leave_status_code, leave_category_code) {
		const start = new Date(strDate);
		const end = new Date(endDate);
		var day = 1000 * 60 * 60 * 24;

		const daysBetween = (end.getTime() - start.getTime()) / day;
		const arr = [];
		for (let i = 0; i <= daysBetween; i++) {
			let xx = start.getTime() + day * i;
			let yy = new Date(xx);
			arr.push(moment(yy).format("YYYY-MM-DD"));
		}
		let obj = {}
		let temm = []
		arr.forEach((item) => {
			obj = {
				date: item,
				status_code: leave_status_code,
				cat_code: leave_category_code
			}
			temm.push(obj)
		})
		return temm;
	}

	getLeaveWithStatus() {
		let tempArray = []
		let tempAllDays;
		this.state.leaveRequestsWithStatus.forEach((leaveDate) => {
			tempAllDays = this.getAllDays(leaveDate.from_date, leaveDate.to_date, leaveDate.leave_status_code, leaveDate.leave_category_code)
			tempArray.push(tempAllDays)
			tempAllDays.map((item) => {
				switch (item.status_code) {
					case '0':
						this.state.pending_for_approval.push(item.date)
						break;
					case '1':
						this.state.approved_and_post_pending.push(item.date)
						break;
					case '2':
						break;
					case '3':
						this.state.rejected.push(item.date)
						break;
					case '4':
						if (leaveDate.leave_category_code === 'Z0' || leaveDate.leave_category_code === 'Z0P' || leaveDate.leave_category_code === 'X0' || leaveDate.leave_category_code === 'X0P') {
							this.state.lwop.push(item.date)
						}
						else {
							this.state.approved.push(item.date)
						}
						break;
					case '5':
						this.state.delete_approval_pending.push(item.date)
						break;
					case '6':
						this.state.deleted_and_update_in_progress.push(item.date)
						break;
					case '7':
						break;
					case '8':
						this.state.error_in_posting.push(item.date)
						break;
					case '9':
						this.state.error_in_deletion.push(item.date)
						break;
					case 'Z0':
						this.state.lwop.push(item.date)
						break;
					case 'Z0P':
						this.state.lwop.push(item.date)
						break;
					default:
						return null;
				}
				return null
			})
		})
		this.state.approved = this.state.approved.filter((data, index) => {
			return this.state.approved.indexOf(data) === index;
		})

	}
	getAttendanceEnquiryDetails(month, year) {
		this.setState({
			loading: true
		});
		const headers = {
			'Content-Type': 'application/json',
		}
		let tempmonth = (month < 10) ? '0' + (month) : (month);

		const body = {
			emp_id: this.props.loginData.userData.Perno,
			month: tempmonth.toString(),
			year: year.toString(),
			date_of_joining: moment(this.props.loginData.userData.Doj).format("YYYY-MM-DD")
		}

		fetchApiCall(Config.getAttendanceEnquiry, body, headers, false, '')
			.then(res => {
				sessionOut(this.props, res);
				if (res.data.status_code === '200') {
					this.setState({
						attendanceEnquiryDetails: res.data.attendance_enquiry_details,
						weekOff: res.data.week_offs,
						publicHolidays: res.data.public_holidays,
						loading: false
					});
					this.getLeaveRequestDetails(month);
				} else {
					this.setState({
						loading: false
					});
				}

			}).catch(err => {
				this.setState({
					loading: false
				});
				console.log(err)
			})
	}
	getActiveYear() {
		this.setState({
			loading: true
		});
		const headers = {
			'Content-Type': 'application/json',
		}
		const body = {}
		fetchApiCall(Config.getActiveYear, body, headers, false, '')
			.then(res => {
				sessionOut(this.props, res);
				if (res.data.status_code === '200') {
					this.setState({
						loading: false,
						activeYear: res.data.data.active_year
					});
				} else {
					this.setState({
						loading: false
					});
				}
			}).catch(err => {
				this.setState({
					loading: false
				});
				console.log("err : ", err)
			})
	}

	getLeaveRequestMasterData() {
		let month = (new Date().getMonth() + 1);
		let year = new Date().getFullYear();
		this.setState({ loading: true })
		const headers = {
			'Content-Type': 'application/json',
		}
		fetchApiCall(Config.getPreviousYearLeaveMaster, {
			emp_id: this.props.loginData.userData.Perno,
			pa: this.props.loginData.userData.PersArea,
			psa: this.props.loginData.userData.PSubarea
		}, headers, false, '')
			.then(res => {
				sessionOut(this.props, res);
				if (res.data.status_code == 200) {
					this.setState({
						leaveRequestTypes: res.data.data.leave_category,
						leaveRequestStatus: res.data.data.leave_status,
						calendarStartDate: res.data.data.start_date,
						calendarEndDate: res.data.data.end_date,
						loading: false
					});
					let tempLeaveTypeData = [];
					if (this.props.loginData.userData.Gender == 'M') {
						tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D0");
					} else if (this.props.loginData.userData.Gender == 'F') {
						tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D3");
					}
					this.setState({
						leaveRequestTypes: tempLeaveTypeData,
						loading: false
					}, () => this.getAttendanceEnquiryDetails(month, year));
				}
				else {
					this.setState({
						loading: false
					});
					toast.error(res.data.message, {
						position: "bottom-center",
						hideProgressBar: true,
						className: 'toastError'
					})
				}
			}).catch(err => {
				this.setState({ loading: false })
				console.log(err)
			})
	}

	onPresentTypeChange = (key) => {
		if (key === 0) {
			this.setState({
				checked: key,
			},
				() => this.onsubmitChange('FD', 'leave_type_code'))
		} else if (key === 1) {
			this.setState({
				checked: key,
			},
				() => this.onsubmitChange('FH', 'leave_type_code'))
		} else {
			this.setState({
				checked: key,
			},
				() => this.onsubmitChange('SH', 'leave_type_code'))
		}
	}

	onsubmitChange(text, type) {
		let _type = type.toString()
		let newArray = this.props.leaveRequest;
		for (var i = 0; i <= this.props.length; i++) {
			if (i === this.props.index) {
				newArray[this.props.index] = {
					...newArray[this.props.index],
					[_type]: text
				}
			}
		}
		if (type === 'remove_data') {
			newArray.splice(-1)
		}
		this.props.saveLeaveRequest(newArray)

	}

	changeCatDropdown(listValue) {
		this.setState({
			leaveCatCode: listValue.code,
			leaveCatValue: listValue.value
		},
			() => {
				this.onsubmitChange(this.state.leaveCatCode, 'leave_category_code')
				this.onsubmitChange(this.state.leaveCatValue, 'leave_category_text')
			}
		)
	}
	changeCatBox(listCode) {
		if (listCode == 'LATE COMING') {
			this.setState({
				leaveTypeActive: 'LC',
				leaveCatValue: 'Late Coming'
			},
				() => {
					this.onsubmitChange('V0', 'leave_category_code')
					this.onsubmitChange(this.state.leaveCatValue, 'leave_category_text')
				}
			)
		}
		else if (listCode == 'EARLY GOING') {
			this.setState({
				leaveTypeActive: 'EG',
				leaveCatValue: 'Early Going'
			},
				() => {
					this.onsubmitChange('E0', 'leave_category_code')
					this.onsubmitChange(this.state.leaveCatValue, 'leave_category_text')
				}
			)
		}
		else if (listCode == 'COD') {
			this.setState({
				leaveTypeActive: 'COD',
				leaveCatValue: 'Cert. Attd (Away on Duty)'
			},
				() => {
					this.onsubmitChange('W0', 'leave_category_code')
					this.onsubmitChange(this.state.leaveCatValue, 'leave_category_text')
				}
			)
		}
		else {
			this.setState({
				leaveTypeActive: 'WFH',
				leaveCatValue: 'Work From Home'
			},
				() => {
					this.onsubmitChange('WFH', 'leave_category_code')
					this.onsubmitChange(this.state.leaveCatValue, 'leave_category_text')
				}
			)
		}
	}

	handleChangeEvents(event) {
		this.setState({
			reason: event
		}, () => this.onsubmitChange(this.state.reason, 'reason'))
	}

	removeLeave = () => {
		this.onsubmitChange('', 'remove_data')
		this.props.removeLeave();
	}
	onStartCalender = () => {
		this.setState({ showStartCalendar: !this.state.showStartCalendar, showEndCalendar: false })
	}
	onEndCalender = () => {
		this.setState({ showEndCalendar: !this.state.showEndCalendar, showStartCalendar: false })
	}
	handleHideCard = () => {
		this.setState({ hideCard: !this.state.hideCard })
	}


	onChangeMonth = (e) => {
		let month_change = e.activeStartDate.getMonth() + 1
		let year = e.activeStartDate.getFullYear()
		let x = moment(e.activeStartDate).format("MMMM")
		this.setState({ leaveMonthValue: x })
		this.getAttendanceEnquiryDetails(month_change, year);
		this.getLeaveRequestDetails(month_change)
	}
	onSelectMonth = (e) => {
		let month_change = e.getMonth() + 1
		let year = e.getFullYear()
		let x = moment(e).format("MMMM")
		this.setState({ leaveMonthValue: x })
		this.getLeaveRequestDetails(month_change);
		this.getAttendanceEnquiryDetails(month_change, year);
	}
	handleCalender = (date, type) => {
		let start_date
		let end_date
		if (type === 'start') {
			this.state.enddateArray = []
			start_date = new Date(date[0])
			end_date = new Date(date[1])
		}
		else {
			this.state.enddateArray = []
			start_date = new Date(this.state.startDate)
			end_date = new Date(date)
		}

		this.setState({
			showStartCalendar: false, showEndCalendar: false,
			startDate: start_date,
			endDate: end_date,
		})
		this.state.enddateArray.push(moment(end_date).format("YYYY-MM-DD"))
		this.onsubmitChange(moment(start_date).format("YYYY-MM-DD"), 'from_date')
		this.onsubmitChange(moment(end_date).format("YYYY-MM-DD"), 'to_date')
	}

	insertTotalDays(index) {
		let tempTotalDays = this.props.leaveRequestData.totalLeavedays;
		if (tempTotalDays)
			return parseFloat(tempTotalDays[index]).toFixed(1)
		return '';
	}
	onDismiss = () => {
		this.setState({ showEndCalendar: false, showStartCalendar: false, })
	}
	removeLeave = () => {
		this.onsubmitChange('', 'remove_data')
		this.props.removeLeave();
	}
	// handleAddAnotherLeave = () => {
	// 	this.props.addAnotherleave();
	// }

	render() {
		const { length, index, isVisible, isSubmit, showFormEdit1 } = this.props;
		const { hideCard } = this.state
		//----Start Date----------
		const startDateParts = this.state.calendarStartDate.split('-');
		const startYear = parseInt(startDateParts[0], 10);
		const startMonth = parseInt(startDateParts[1], 10) - 1; // Month is 0-indexed
		const startDay = parseInt(startDateParts[2], 10);
		//----End Date-----------
		const endDateParts = this.state.calendarEndDate.split('-');
		const endYear = parseInt(endDateParts[0], 10);
		const endMonth = parseInt(endDateParts[1], 10) - 1; // Month is 0-indexed
		const endDay = parseInt(endDateParts[2], 10);
		return (
			<div>
				<LoaderIndicator loading={this.state.loading} />
				{index !== length - 1 || isSubmit ?
					<div className='card-new m-0 p-3' onClick={() => this.handleHideCard()}>
						{!hideCard &&
							<div className='row justify-content-between align-item-center'>
								<div className='row align-item-center'>
									<div className='date'>&nbsp; {this.state.endDate === '' ? 'DD/MM/YYYY' : moment(this.state.startDate).format("DD/MM/YYYY") + ' - '} </div>
									<div className='date'>&nbsp; {this.state.endDate === '' ? 'DD/MM/YYYY' : moment(this.state.endDate).format("DD/MM/YYYY") + ' : '} </div>
									<div className='date'>&nbsp;&nbsp;
										{this.insertTotalDays(this.props.index)} {this.insertTotalDays(this.props.index) <= 1 ? ' day' : ' days'}
									</div>
								</div>

								<div className='justify-content-end'>
									<img alt="hide" src={down_arrow} className="up_arrow"></img>
								</div>
							</div>
						}
						{hideCard ?
							<div>
								<div className='ml-2 row'>
									<label className='totalDaycardtxtLabel'>From:&nbsp;</label><label className='totaldaysdate'>
										{moment(this.state.startDate).format("DD-MM-YYYY")}
									</label>
									<label className='totalDaycardtxtLabel magin'>To:&nbsp;</label><label className='totaldaysdate'>
										{moment(this.state.endDate).format("DD-MM-YYYY")}
									</label>
								</div>
								<hr />
								<div className='row'>
									<label className='totalDaycardtxtLabel'>Total Days:&nbsp;</label><label className='totaldaysdate'>
										{this.insertTotalDays(this.props.index)} {this.insertTotalDays(this.props.index) <= 1 ? 'day' : 'days'}
									</label>
								</div>
								<hr />
								<div className='row'>
									<div className='totalDaycardtxtLabel'>Type : &nbsp;</div>
									<div className='totaldaysdate'>{this.state.leaveCatValue}</div>
								</div>
								<hr />
								<div className='row'>
									<div className='totalDaycardtxtLabel'>Approver : &nbsp;</div>
									<div className='totaldaysdate'>{this.props.loginData.userData.Nameofreporting}</div>
								</div>
								<hr />
								<div className='row'>
									<label className='notetxt'>
										Note: Number of days can change in case of weekly off/holiday calendar changes
									</label>
								</div>


								{hideCard ?
									<div className='justify-content-end p-2' onClick={() => this.handleHideCard()}>
										<img alt="hide" src={up_arrow} className="up_arrow"></img>
									</div>
									:
									null
								}
							</div>

							:
							null
						}
					</div>
					:
					null
				}

				<div>
					{index === length - 1 && showFormEdit1 == true ?
						<form className='form-group'>
							<div className='row d-flex justify-content-end'>
								{isVisible ?
									<div>
										{index === length - 1 ?
											<div className="ml-3">
												<img alt="close" className="closeIcon mb-2 ml-5" src={close} onClick={() => this.removeLeave()}></img>
											</div>
											: null}
									</div>
									: null}
							</div>
							<div className='d-flex firstRow'>
								{this.state.leavetype.map((item, key) =>
									<div key={key}
										onClick={() => { this.changeCatBox(item, key) }}
									>
										<label
											className='preveLeaveType'
											style={{
												backgroundColor: this.state.leaveTypeActive === item ? '#3D1D76' : '#E2E8F0',
												color: this.state.leaveTypeActive === item ? '#FFFFFF' : '#1E293B'
											}}
										>
											{item}
										</label>
									</div>
								)}
							</div>
							<div className='box applyLeavecardBox'>
								<div className='mt-1 p-2'>
									<label className='form-label ml-2'>Leave Type</label>
									<Dropdown
										data={this.state.leaveRequestTypes}
										selectedValue={(data) => this.changeCatDropdown(data)}
										value={this.state.leaveCatValue}
										render={'value'}
										height={'height'}
									/>
								</div>
								<div className='box applyLeavecardBox'>
									<div className='d-flex  justify-content-between'>
										<div className='ml-2 w-35'>
											<label className='form-label ml-2'>From</label><br />
											<div className="inpt dateInput">
												<div className="row justify-content-betweeen">
													<div className='date'>{this.state.startDate === '' ? <div className="datePlaceHolder">DD/MM/YYYY</div> : moment(this.state.startDate).format("DD/MM/YYYY")}</div>
													<img src={calander} alt='' className='dateImg mt-1' onClick={() => this.onStartCalender()} />
												</div>
											</div>
										</div>
										{
											this.state.showStartCalendar &&
											<center>
												<div className="popup-box calanderBoxStart" style={{ zIndex: 2 }} id={this.props.id}>
													<OutsideClickHandler
														onOutsideClick={() => this.closeCalendars()}>
														<div className="calanderCard">
															<Calendar
																className="calendar"
																onChange={(date) => this.handleCalender(date, 'start')}
																selectRange={true}
																value={[this.state.startDate != '' ? new Date(this.state.startDate) : new Date(), this.state.endDate != '' ? new Date(this.state.endDate) : new Date()]}
																onActiveStartDateChange={this.onChangeMonth}
																onClickMonth={this.onSelectMonth}
																prev2Label={null}
																minDate={new Date(startYear, startMonth, startDay)}
																maxDate={new Date(endYear, endMonth, endDay)}
																next2Label={null}
																tileClassName={({ date, view }) => {
																	if (view === 'month' && this.state.enddateArray.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'end-date-highlight highlight'
																	}
																	if (view === 'month' && this.state.pending_for_approval.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'pending-for-approval-highlight highlight'
																	}
																	if (view === 'month' && this.state.approved_and_post_pending.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'approved-and-post-pending-highlight highlight'
																	}
																	if (view === 'month' && this.state.rejected.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'rejected-highlight highlight'
																	}
																	if (view === 'month' && this.state.approved.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'approved-highlight highlight'
																	}
																	if (view === 'month' && this.state.delete_approval_pending.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'delete-approval-pending-highlight highlight'
																	}
																	if (view === 'month' && this.state.deleted_and_update_in_progress.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'deleted-and-update-in-progress-highlight highlight'
																	}
																	if (view === 'month' && this.state.error_in_posting.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'error-in-posting-highlight highlight'
																	}
																	if (view === 'month' && this.state.error_in_deletion.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'error-in-deletion-highlight highlight'
																	}
																	if (view === 'month' && this.state.lwop.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'lwop-highlight highlight'
																	}
																	if (view === 'month' && this.state.weekOff.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'week-highlight highlight'
																	}
																	if (view === 'month' && this.state.publicHolidays.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																		return 'public-highlight highlight'
																	}
																}}
															/>
														</div>
													</OutsideClickHandler>
												</div>
											</center>
										}

										<div className='mr-5 w-35'>
											<label className='form-label ml-2'>To</label><br />
											<div className="inpt dateInput" onClick={() => this.onEndCalender()} >
												<div className="row justify-content-betweeen">
													<div className='date'> {this.state.endDate === '' ? 'DD/MM/YYYY' : moment(this.state.endDate).format("DD/MM/YYYY")} </div>
													<img src={calander} alt='' className='dateImg mt-1' />
												</div>
											</div>
											{
												this.state.showEndCalendar &&
												<center>
													<div className="popup-box calanderBoxEnd" style={{ zIndex: 2 }} id={this.props.id}>
														<OutsideClickHandler
															onOutsideClick={() => this.closeCalendars()}>
															<div className="calanderCard">
																<Calendar
																	className='calendar'
																	onChange={(date) => this.handleCalender(date, 'end')}
																	value={new Date(this.state.endDate)}
																	onActiveStartDateChange={this.onChangeMonth}
																	onClickMonth={this.onSelectMonth}
																	minDate={new Date(startYear, startMonth, startDay)}
																	maxDate={new Date(endYear, endMonth, endDay)}
																	prev2Label={null}
																	next2Label={null}
																	tileClassName={({ date, view }) => {
																		if (view === 'month' && this.state.enddateArray.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'end-date-highlight highlight'
																		}
																		if (view === 'month' && this.state.pending_for_approval.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'pending-for-approval-highlight highlight'
																		}
																		if (view === 'month' && this.state.approved_and_post_pending.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'approved-and-post-pending-highlight highlight'
																		}
																		if (view === 'month' && this.state.rejected.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'rejected-highlight highlight'
																		}
																		if (view === 'month' && this.state.approved.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'approved-highlight highlight'
																		}
																		if (view === 'month' && this.state.delete_approval_pending.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'delete-approval-pending-highlight highlight'
																		}
																		if (view === 'month' && this.state.deleted_and_update_in_progress.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'deleted-and-update-in-progress-highlight highlight'
																		}
																		if (view === 'month' && this.state.error_in_posting.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'error-in-posting-highlight highlight'
																		}
																		if (view === 'month' && this.state.error_in_deletion.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'error-in-deletion-highlight highlight'
																		}
																		if (view === 'month' && this.state.lwop.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'lwop-highlight highlight'
																		}
																		else if (view === 'month' && this.state.weekOff.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'week-highlight highlight'
																		}
																		else if (view === 'month' && this.state.publicHolidays.find(x => x === moment(date).format("YYYY-MM-DD"))) {
																			return 'public-highlight highlight'
																		}
																	}}
																/>
															</div>
														</OutsideClickHandler>
													</div>
												</center>
											}
										</div>
									</div>
								</div>

								<div className='box mt-3'>
									<div className=''>
										<label className='form-label ml-3'>Duration</label>
										<div className='d-flex justify-content-around'>
											{this.state.absenseLevaeData && this.state.absenseLevaeData.map((data, key) => {
												return (
													<div key={key}>
														{this.state.checked === key ?
															<div onClick={() => this.onPresentTypeChange(key)}>
																<img alt="radioOn" className='radio' src={radioOn} />
																<label className="radioTxt" >{data}</label>
															</div>
															:
															<div onClick={() => this.onPresentTypeChange(key)}>
																<img alt="radioOff" className='radio' src={radioOff} />
																<label className="radioTxt">{data}</label>
															</div>
														}
													</div>
												)
											})
											}
										</div>
									</div>
								</div>
								<div>
									<label className='form-label ml-3 mt-2'>Reason in detail</label>
									<textarea
										className="form-control textarea"
										placeholder="Enter reason.."
										maxLength={255}
										onChange={(e) => { this.handleChangeEvents(e.target.value) }}
									>
									</textarea>
								</div>
								<div className='row mt-4 justify-content-between'>
									<div className='row'>
										<div className='approverTxt ml-2'>Approver:&nbsp;</div>
										<div className='approverName'>{this.props.loginData.userData.Nameofreporting}</div>
									</div>
								</div>
							</div>

						</form>
						:
						<div className='row mt-4 justify-content-between'>

						</div>
					}
				</div>
			</div>

		)
	}
}

const mapStatesToProps = (state) => {
	return {
		loginData: state.loginState,
		keycloakData: state.keycloakState,
		leaveRequestData: state.leaveState
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		leaveActions: bindActionCreators(leaveActions, dispatch)
	};
}

export default connect(mapStatesToProps, mapDispatchToProps)(ApplyLeavecard);