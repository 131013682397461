import React, { Component } from 'react';
import delete_popup from '../../../../../assets/images/cfm/delete_popup.svg';
import Success_icon from '../../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
class ConfirmPopup extends Component {
    render() {
        const { title, content, action, loading } = this.props
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        {action === 'delete' || action === 'reject' || action === 'deleteEsepRequest' ?
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={delete_popup}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {content}
                                </div>
                            </div> :
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="delete_popup"
                                        src={Success_icon}
                                        className=""
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {content}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        {action === 'delete' ?
                            <input type='button' value='DELETE' className='ml-2 validateBtn' onClick={() => this.props.deleteData()} /> :
                            action === 'deleteEsepRequest' ?
                                <input type='button' value='DELETE' className='ml-2 validateBtn' onClick={() => this.props.deleteEsepData()} /> :
                                action === 'reject' ?
                                    <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.props.rejectData()} /> :
                                    action === 'update' ?
                                        <input type='button' value='UPDATE' className='ml-2 validateBtn' onClick={() => this.props.updateData()} /> :
                                        action === 'submit' ?
                                            <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.props.submitData()} /> :
                                            <input type='button' value='SAVE' className='ml-2 validateBtn' onClick={() => this.props.saveData()} />
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default ConfirmPopup;