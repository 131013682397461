import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { requestFailed } from '../../../../constants/Messages';

class TataBuzzNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: '',
            description: '',
        }
    }

    validate = () => {
        if (this.state.title === '') {
            toast.warning('Please enter title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.title.length > 75) {
            toast.warning('Please enter title less than 75 character', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.description === '') {
            toast.warning('Please enter description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.description.length > 140) {
            toast.warning('Please enter description less than 140 character', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertTataBuzzNews()
        }
    }

    insertTataBuzzNews = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            // announcement_description: this.state.description,
            // image_name: this.state.imageName,
            // emp_mail: this.props.adminLoginData.adminData.user_email,
            // image_link: this.state.image_link

            action: "POST",
            news_title: this.state.title,
            news_description: this.state.description,
            emp_mail: this.props.adminLoginData.adminData.user_email
        }
        fetchApiCall(Config.insertBuzzNews, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '201') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                        this.props.allDismiss(),
                    )
                    this.props.addTataBuzzNewsActive()
                    this.setState({ loading: false })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    },
                        this.props.allDismiss(),
                    )
                    this.props.upcomingOccasionActive()
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div>
                    <form className='todoModalBody popup_scroller w-100'>
                        <div className='p-1 w-100'>
                            <div>
                                <div>
                                    <div className='p-sub-heading ml-3 mt-2'>Title</div>
                                    <div className='p-placeholder-text mb-2'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type="text"
                                            placeholder={"Enter the title"}
                                            onChange={(e) => this.setState({ title: e.target.value })}
                                            value={this.state.title}
                                        />
                                    </div>
                                    <div className='p-sub-heading ml-3'>Description</div>
                                    <div className='p-placeholder-text'>
                                        <textarea
                                            className="form-control p-placeholder px-3"
                                            type="text"
                                            placeholder={"Enter the description"}
                                            maxLength={1000}
                                            readOnly={this.state.show}
                                            onChange={(e) => this.setState({ description: e.target.value })}
                                            value={this.state.description}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='modal-footer btnBox justify-content-between'>
                        <div className='justify-content-start'>
                            <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                        </div>
                        <div className='justify-content-around'>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='CANCEL'
                                    className='popup-back-btn popup-back-text'
                                    onClick={this.props.back}
                                />
                            </div>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='ADD'
                                    className='popup-confirm-btn'
                                    onClick={this.validate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(TataBuzzNews);