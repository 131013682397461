import React, { Component } from 'react';
import Dropdown from '../../../shared/components/Dropdown';
import webPurple from '../../../../assets/drishti_images/admin/webPurple.svg';
import androidPurple from '../../../../assets/drishti_images/admin/androidPurple.svg';
import iosPurple from '../../../../assets/drishti_images/admin/iosPurple.svg';
import chatbotPurple from '../../../../assets/drishti_images/admin/chatbotPurple.svg';
import teamsPurple from '../../../../assets/drishti_images/admin/teamsPurple.svg';
import { cfmData } from '../../../../utils/Utils';
import { RadialBarChart, RadialBar } from 'recharts';
import { Tooltip } from 'recharts';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";

class Engagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            engagementData: [],
            cfmData: cfmData(),
            cfmValue: 'Weekly',
            cfmCode: 'weekly',
            data: [],
            loading:false
        }
    }

    componentDidMount() {
        this.getEmployeeEngagementActivity()
    }

    getEmployeeEngagementActivity = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            filter: this.state.cfmCode
        }
        console.log('getEmployeeEngagement body------>', body);
        fetchApiCall(Config.getEmployeeEngagementActivity, body, headers, true, 'admin')
            .then(res => {
                console.log('getEmployeeEngagementActivity------>', res);
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        engagementData: res.data.platforms_usage_insights,
                        loading: false,
                        data: [
                            {
                                "name": " ",
                                "x": 100,
                                "fill": "#eeeeee"
                            },
                            {
                                "name": "web-portal",
                                "x": res.data.platforms_usage_insights.web_portal,
                                "fill": "#3d1d76"
                            },
                            {
                                "name": "ios",
                                "x": res.data.platforms_usage_insights.ios,
                                "fill": "#6D3DC5"
                            },
                            {
                                "name": "Android",
                                "x": res.data.platforms_usage_insights.android,
                                "fill": "#8155CF"
                            },
                            {
                                "name": "Teams",
                                "x": res.data.platforms_usage_insights.tml_dashboard,
                                "fill": "#7B69EB"
                            },
                            {
                                "name": "chatbot",
                                "x": res.data.platforms_usage_insights.tml_chatbot,
                                "fill": "#8195FC"
                            }

                        ]
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    changeMonth = (data) => {
        console.log("data", data)
        this.setState({
            cfmCode: data.code,
            cfmValue: data.value,
        },
            () => this.getEmployeeEngagementActivity())
    }


    render() {
        console.log("engagementData", this.state.engagementData)
        return (
            <div className="card-new h-100" >
            <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Engagement</label>
                        </div>
                        <div className='mt-2 justify-content-end'>
                            <div>
                                <Dropdown
                                    data={this.state.cfmData}
                                    selectedValue={(data) => this.changeMonth(data)}
                                    value={this.state.cfmValue}
                                    render={'value'}
                                    arrow={'true'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='row'>
                        <RadialBarChart
                            width={150}
                            height={150}
                            innerRadius="10%"
                            outerRadius="60%"
                            data={this.state.data}
                            startAngle={360}
                            endAngle={0}
                            className="engagementCircle"
                            name={"name"}
                        >
                            <RadialBar minAngle={15} background clockWise={true} dataKey='x' />
                            {/* <Tooltip /> */}
                        </RadialBarChart>
                    </div>
                    <div className='column mx-2 mt-2'>
                        <div className='row align-item-center'>
                            <div><img src={webPurple}></img></div>
                            <div className='performance-timeline-text-circle black-text-color mt-1 mx-2'>Web Portal</div>
                            <div className='sm-text purple-color mt-1'>{this.state.engagementData.web_portal}%</div>
                        </div>
                        <div className='row align-item-center'>
                            <div><img src={iosPurple}></img></div>
                            <div className='performance-timeline-text-circle black-text-color mt-1 mx-2'>iOS</div>
                            <div className='sm-text purple-L1 mt-1'>{this.state.engagementData.ios}%</div>
                        </div>
                        <div className='row align-item-center'>
                            <div><img src={androidPurple}></img></div>
                            <div className='performance-timeline-text-circle black-text-color mt-1 mx-2'>Android</div>
                            <div className='sm-text purple-L2 mt-1'>{this.state.engagementData.android}%</div>
                        </div>
                    </div>
                    <div className='vericalDevider mt-2'></div>
                    <div className='column mx-2 mt-2'>
                        <div className='row align-item-center'>
                            <div><img src={teamsPurple}></img></div>
                            <div className='performance-timeline-text-circle black-text-color mt-1 mx-2'>Teams</div>
                            <div className='sm-text purple-L3 mt-1'>{this.state.engagementData.tml_dashboard}%</div>
                        </div>
                        <div className='row align-item-center'>
                            <div><img src={chatbotPurple}></img></div>
                            <div className='performance-timeline-text-circle black-text-color mt-1 mx-2'>Chatbot</div>
                            <div className='sm-text purple-L4 mt-1'>{this.state.engagementData.tml_chatbot}%</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Engagement;