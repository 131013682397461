import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import Dropdown from '../../../shared/components/Dropdown';
import Papa from "papaparse";

class CETSync extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            typeData: [
                { code: '01', value: "By Employee ID " },
                { code: '02', value: "By File " }
            ],
            typeValue: 'Select',
            typeCode: '',
            empID: '',
            specificEmp: '',
            CEtResponseDetails: '',
            companyList: [],
            companyCode: '',
            companyValue: 'Select'
        }
        this.updateData = this.updateData.bind(this);
    }
    componentDidMount() {
        this.getCompanyNames()
    }
    getCompanyNames = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
            .then(res => {
                console.log("res.data", res.data)
                if (res.data.status_code === '200') {
                    this.setState({
                        companyList: res.data.company_list ? res.data.company_list : [],
                        loading: false
                    })
                    const tempCompanyArray = this.state.companyList.map((item, idx) => ({
                        ...item,
                        value: item.company_sht_txt
                    }))
                    this.setState({
                        companyList: tempCompanyArray
                    })

                }
                else {
                    this.setState({ loading: false })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }

    updateData(result, file) {
        if (file.type === "text/csv") {
            const updateData = result.data; // Here this is available and we can call this.setState (since it's binded in the constructor)
            console.log('specificEmp------------', updateData)
            // console.log('result.dataresult.dataresult.dataresult.data', result.data)
            this.setState({ specificEmp: updateData.slice(0, -1) }); // or shorter ES syntax: this.setState({ data });
            // console.log('specificEmp', updateData)
            // var myJsonString = JSON.stringify(updateData.slice(0, -1));
            console.log("specificEmp", this.state.specificEmp)
            // if (this.state.specificEmp[0] === '') {
            //     console.log("Empty data")
            // }

            const tempArray = [];
            this.state.specificEmp.forEach(e => {
                console.log("b0 ", e[0])
                console.log("b1 ", e[1])
                // tempArray.push(e[0], e[1])
                tempArray.push({ "emp_id": e[0], "comp_code": e[1] })
            });
            console.log("tempArray", tempArray)
            this.setState({
                specificEmp: tempArray
            })

            // if (tempArray[0] !== '') {
            //     const tempArray2 = [];
            //     tempArray.forEach(e => {
            //         console.log("e : ", e)
            //         tempArray2.push({ "emp_id": e, "comp_code": '0100' })
            //     });
            //     console.log("temspArray2", tempArray2)

            //     this.setState({
            //         specificEmp: tempArray2
            //     })
            //     console.log("this.state.specificEmp", this.state.specificEmp)
            // }
            // else {
            //     this.setState({
            //         specificEmp: "null"
            //     })
            // }
        }
        else {
            toast.warn("Please upload CSV file only", {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })

            this.setState({
                specificEmp: ''
            })
        }

    }

    startECTSyncJob = () => {
        console.log("job started")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        var body = []
        if (this.state.typeCode === '01') {
            body = [{
                emp_id: this.state.empID,
                comp_code: this.state.companyCode
            }]
        }
        else {
            body = this.state.specificEmp;
        }
        fetchApiCall(Config.syncEmployeeCetData, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                console.log("res.data", res.data)
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        CEtResponseDetails: res.data.data,
                        typeCode: '',
                        typeValue: 'Select',
                        companyValue: 'Select',
                        companyCode: ''

                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    changeType = (data) => {
        this.setState({
            typeValue: data.value,
            typeCode: data.code,
            CEtResponseDetails: ''
        })
    }

    changeCompanyType = (data) => {
        this.setState({
            companyValue: data.company_sht_txt,
            companyCode: data.company_code,
        })
    }

    handleChange = (e) => {
        this.setState({
            empID: e.target.value
        })
    }

    loadData = () => {
        if (this.state.typeCode === '01') {
            if (this.state.empID === '') {
                toast.warn('Please Enter employee ID ', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.startECTSyncJob()
            }
        }
        else {
            if (this.state.specificEmp === 'null') {
                toast.warn('file is empty/check file format', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else if (this.state.specificEmp === '') {
                toast.warn('Please upload CSV file', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.startECTSyncJob()
            }
        }
    }

    render() {
        return (
            <div className='h-60vh'>
                <div className="row">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'CET Sync'}</div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'Select Type'}</div>
                                <div className='p-placeholder-text widthMonth'>
                                    <Dropdown
                                        data={this.state.typeData}
                                        selectedValue={(data) => this.changeType(data)}
                                        value={this.state.typeValue}
                                        render={'value'}
                                        arrow={'true'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.typeCode === '01' &&
                        <>
                            <div className="col-md-12 px-0" >
                                <div className='row align-item-center'>
                                    <div className='mt-3 ml-4'>
                                        <div className='p-sub-heading ml-3 mb-1'>{'Select Company'}</div>
                                        <div className='p-placeholder-text widthMonth'>
                                            <Dropdown
                                                data={this.state.companyList}
                                                selectedValue={(data) => this.changeCompanyType(data)}
                                                value={this.state.companyValue}
                                                render={'value'}
                                                arrow={'true'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 px-0" >
                                <div className='row align-item-center'>
                                    <div className='mt-3 ml-4'>
                                        <div className='p-sub-heading ml-3 mb-1'>{'Employee ID'}</div>
                                        <div className='p-placeholder-text widthMonth'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                placeholder="Enter Employee ID"
                                                type="number"
                                                maxLength={8}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {this.state.typeCode === '02' &&
                        <div className="col-md-12 px-0" >
                            <div className='row align-item-center'>
                                <div className='mt-3 ml-4'>
                                    <div className='p-sub-heading ml-3 mb-1'>{'Attach CSV File'}</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className='inpt'
                                            type="file"
                                            accept=".csv"
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                console.log("files : ", files);
                                                if (files) {
                                                    console.log("files[0] : ", files[0]);
                                                    Papa.parse(files[0], {
                                                        complete: this.updateData
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.typeCode !== '' &&
                        <>
                            <div className="col-md-12 px-0" >
                                <div className='row align-item-center'>
                                    <div className='mt-3 ml-4'>
                                        <div className='p-sub-heading ml-3 mb-1'>{'Employee ID'}</div>
                                        <div className='p-placeholder-text widthMonth'>
                                            <div
                                                type='button'
                                                className='save-btn save-txt'
                                                onClick={() => this.loadData()}
                                            >UPLOAD
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <br />
                    <br />
                    <br />
                    <br />
                    {this.state.CEtResponseDetails !== '' &&
                        <div className='row col-md-12'>
                            <div className="col-md-12" >
                                <div className='row align-item-center'>
                                    <div className='mt-3'>
                                        <div className='p-sub-heading ml-3 mb-1'>{'CET Sync result'}</div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <br />
                            <div className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                <div className='enabledisablebox justify-content-between align-item-center'>
                                    <div className='text16_medium row justify-content-between align-item-center'>
                                        <div className='text16_medium'>{"Employee Sync Count"}</div>
                                    </div>
                                    <div className="infoLabel ml-2" >
                                        {this.state.CEtResponseDetails.emp_sync_count}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                <div className='enabledisablebox justify-content-between align-item-center'>
                                    <div className='text16_medium row justify-content-between align-item-center'>
                                        <div className='text16_medium'>{"Employee Sync Error Count"}</div>
                                    </div>
                                    <div className="infoLabel ml-2" >
                                        {this.state.CEtResponseDetails.sync_error_employee_id.length}
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                <div className='enabledisablebox justify-content-between align-item-center'>
                                    <div className='text16_medium row justify-content-between align-item-center'>
                                        <div className='text16_medium'>{"Employee Unsync Count"}</div>
                                    </div>
                                    <div className="infoLabel ml-2" >
                                        {this.state.CEtResponseDetails.unsync_employee_id.length}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default CETSync;