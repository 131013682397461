import { configStore } from "../redux/ConfigStore";

export function checkActivePoup(str) {
    let activePopupData = [];
    activePopupData = configStore.getState().activePopupState?configStore.getState().activePopupState.activePopup:[];
    if(activePopupData) {
        let tempData = activePopupData.find(data => data.screen_type === str)

        if(tempData) {
            return tempData;
        } else {
            return false
        }
    }
    return false
}

export function checkPopupCount(str) {
    let tempCount = configStore.getState().activePopupState.activePopupCount[str];
    if(tempCount >= 1){
        return false;
    } else {
        return true
    }
}

export function dynamic_popup_type(){
    let type=[]
    type=[
        {code:"home",value:"Home"},
        {code:"leaveApproval",value:"Leave Approval"},
        {code:"attendanceEnquiry",value:"Attendance Enquiry"},
        {code:"leaveListing",value:"Leave Listing"},
        {code:"attendance",value:"Attendance"},
        {code:"myDetails",value:"My Details"},
        {code:"document",value:"Document"},
        {code:"deleteLeaveApproval",value:"Delete Leave Approval"},
        {code:"leaveRequest",value:"Leave Request"},
        {code:"notification",value:"Notification"},
        {code:"education",value:"Education"},
        {code:"employment",value:"Employment"},
        {code:"address",value:"Address"},
        {code:"aadhar",value:"Aadhar"},
        {code:"contact",value:"Contact"},
        {code:"dependent",value:"Dependent"},
        {code:"bank",value:"Bank"},
        {code:"myProfile",value:"My Profile"},
        {code:"pan",value:"Pan"},
    ]
    return type
}
