import React, { Component, Fragment } from "react";
import "../../employeeScreens/Announcement/announcementScreen.css";
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import { connect } from "react-redux";
import "./MOMStyles.css";
import { Portalemoji, Newemoji, ReactionScreenEmoji, mom_notification_type } from "../../../../utils/Utils";
import likegrey from "../../../../assets/drishti_images/announcement/portalEmoji/like-grey.svg";
import clapgrey from "../../../../assets/drishti_images/announcement/portalEmoji/clap-grey.svg";
import lovegrey from "../../../../assets/drishti_images/announcement/portalEmoji/love-grey.svg";
import moment from 'moment';
import likecolor from "../../../../assets/drishti_images/announcement/portalEmoji/like-color.svg";
import clapcolor from "../../../../assets/drishti_images/announcement/portalEmoji/clap-color.svg";
import lovecolor from "../../../../assets/drishti_images/announcement/portalEmoji/love-color.svg";
import { handleClickForReactions } from "../../../shared/reactionHandle";
import NoMomentData from "../../../../assets/drishti_images/nodatafound/announcement-nodata.svg";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import MOMReactionview from "./MOMReactionview";
import SendWishPopups from "./SendWishPopups";

const MomentFeedTitle = `Moments Feed`;
const NoDataMsg = `Stay Tuned!`
const rectionFormOther = " others"
const reactionFromYouAndOther = " others"
const MORE = `MORE`
const MyMoments = `My Moments`
const Birthday = `Birthdays`
const WorkAnniversary = `Work Anniversaries`
const LongServiceAwards = `Service Milestones`

// const notification_type = window.location.search.split('key=')[1]
// const atobNotification_type = atob(notification_type)
class MOMFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMyMTM: false,
      isBirthday: true,
      isWorkAnniversary: false,
      isLongServiceAwards: false,
      mom_notification_type: ["My Moments", "Birthday", "Work Anniversary", "Long Service Award"],
      momentsType: "Birthday",
      loading: false,
      portalEmojiData: Portalemoji(),
      emojiData: Newemoji(),
      reactionScreenEmoji: ReactionScreenEmoji(),
      page_number: 1,
      momentData: [],
      clickImage: "",
      collectdata: [],
      datapresent: false,
      message: "",
      responsedata: [],
      momentTypeId: "",
      moments_id: "",
      total_page: 0,
      whichReaction: "",
      username: "",
      likeactive: false,
      applaudactive: false,
      loveactive: false,
      innovative: false,
      thoughtfulactive: false,
      showSendWishPopup: false,
      notification_type: "",
      greeting_message: "",
      created_date_time: "",
      sendWishPopData: [],
      is_event_owner: false
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    if (window.location.search.split('key=')[1] != undefined) {
      const notification_type = window.location.search.split('key=')[1]
      const atobNotification_type = atob(notification_type)

      if (atobNotification_type == "Birthday") {
        this.toggleBirthdayFeeds()
      }
      else if (atobNotification_type == "Work Anniversary") {
        this.toggleWorkAnniversaryFeeds()
      }
      else {
        this.toggleLongAnniversaryFeeds()
      }
      this.setState({
        momentsType: atobNotification_type,
        username: this.props.loginData.userData.Firstname,
      },
        this.getMomentsFeed()
      );
    }
    else {
      this.setState({
        momentsType: this.state.mom_notification_type[1],
        username: this.props.loginData.userData.Firstname,
      },
        this.getMomentsFeed()
      );
    }
  }

  getMomentsFeed = () => {
    this.setState({
      loading: true,
      page_number: this.state.page_number,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      filter: this.state.momentsType,
      emp_id: this.props.loginData.userData.Perno,
      page_number: this.state.page_number,
    };
    fetchApiCall(Config.getMomentFeedData, body, headers, true, "")
      .then((res) => {
        if (res.data.status_code === "200") {
          this.setState(
            {
              momentData: this.state.momentData.concat(res.data.data.moment_feed_details_json_list ? res.data.data.moment_feed_details_json_list : ""),
              is_event_owner: res.data.data.is_event_owner,
              total_page: res.data.data.total_page,
              loading: false,
              datapresent: true,
              message: "",
            });
        } else {
          this.setState({
            loading: false,
            momentData: [],
            show: true,
            datapresent: false,
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  };

  updateReactionMoment(moment_type_id, response) {
    this.setState({
      loading: true
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      moments_id: moment_type_id,
      employee_id: this.props.loginData.userData.Perno,
      employee_name: this.props.loginData.userData.CompName,
      employee_response: response,
    };
    fetchApiCall(Config.updateMomentUserReactionsResponse, body, headers, true, "")
      .then((res) => {
        sessionOut(this.props, res);
        if (res.data.status_code === "200") {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  }

  onChanged = (value) => {
    this.setState({ momentsType: value, momentData: [] }, () =>
      this.getMomentsFeed()
    );
  };

  toggleMyFeeds = () => {
    this.setState({ isMyMTM: true, isBirthday: false, isWorkAnniversary: false, isLongServiceAwards: false, momentsType: 'My Moments', momentData: [], page_number: 1 },
      () => this.getMomentsFeed())
  }

  toggleBirthdayFeeds = () => {
    this.setState({ isMyMTM: false, isBirthday: true, isWorkAnniversary: false, isLongServiceAwards: false, momentsType: 'Birthday', momentData: [], page_number: 1 },
      () => this.getMomentsFeed())
  }

  toggleWorkAnniversaryFeeds = () => {
    this.setState({ isMyMTM: false, isBirthday: false, isWorkAnniversary: true, isLongServiceAwards: false, momentsType: 'Work Anniversary', momentData: [], page_number: 1 },
      () => this.getMomentsFeed())
  }

  toggleLongAnniversaryFeeds = () => {
    this.setState({ isMyMTM: false, isBirthday: false, isWorkAnniversary: false, isLongServiceAwards: true, momentsType: 'Long Service Award', momentData: [], page_number: 1 },
      () => this.getMomentsFeed())
  }

  openSendWishPopup(greeting_list) {
    this.setState({
      showSendWishPopup: true,
      loading: true,
      notification_type: greeting_list.notification_type

    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      login_emp_id: this.props.loginData.userData.Perno,
      greeting_for: greeting_list.greeting_for,
      notification_type: greeting_list.notification_type
    };
    fetchApiCall(Config.send_wish, body, headers, true, "")
      .then((res) => {

        if (res.data.status_code === "200") {
          this.setState({
            sendWishPopData: res.data.data,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  }

  callChildFunctionFromParent = (moment_type_id) => {
    this.setState({
      momentTypeId: moment_type_id,
    }, () => this.child.getMomentEmployeeRecord(this.state.momentTypeId, "All"));
  };

  morePage = () => {
    this.setState({
      page_number: this.state.page_number + 1
    },
      () => this.getMomentsFeed()
    )
  }

  handleClick = (id, reaction) => {
    console.log("id, reaction:", id, reaction);
    this.setState(
      (prevReactions) => ({
        momentData: handleClickForReactions(
          id,
          reaction,
          prevReactions.momentData,
          this.props.loginData,
          "moments_id"
        ),
      }),
      () => this.updateReactionMoment(id, reaction)
    );
  }

  render() {
    return (
      <div className="row">
        <LoaderIndicator loading={this.state.loading} />
        <div className="col-md-12 card-new m-rem">
          <div className="row justify-content-between align-item-center">
            <div className="edit-cardtitle common-text-color mt-12">
              {MomentFeedTitle}
            </div>
          </div>
          <div className='row mt-3 px-3 ss col-md-12'>
            <div className={this.state.isBirthday ? "tab-active" : "tab-inactive"} onClick={this.toggleBirthdayFeeds}>
              {Birthday}
            </div>
            <div className={this.state.isWorkAnniversary ? "tab-active" : "tab-inactive"} onClick={this.toggleWorkAnniversaryFeeds}>
              {WorkAnniversary}
            </div>
            <div className={this.state.isLongServiceAwards ? "tab-active" : "tab-inactive"} onClick={this.toggleLongAnniversaryFeeds}>
              {LongServiceAwards}
            </div>
            <div className={this.state.isMyMTM ? "tab-active" : "tab-inactive"} onClick={this.toggleMyFeeds}>
              {MyMoments}
            </div>
          </div>
          <hr />
          <div className="mom-feed-data">
            {this.state.momentData.length !== 0 ?
              <div>
                <>
                  {this.state.momentData.map((list, id) => (
                    <Fragment key={id}>
                      <div className="row">
                        <div className="col-md-12 pl-0">
                          <div className="row pb-2 pt-2">
                            <div className="col-md-4 img-container">
                              <img
                                src={list.image_url}
                                alt=""
                                className="moments-img"
                                data-toggle="modal"
                                data-target="#exampleModalCenter"
                              />
                            </div>
                            <div className="col-md pl-0">
                              <div
                                className="d-flex flex-column min-height-200px "
                              >
                                {/* <div className="pl-1 pr-1 pt-2 pb-0">
                                  <div className="row d-flex justify-content-end">
                                    <div className="announcement-inner-title">
                                      <p className="corner-date">
                                        {moment(list.moment_date).format(('DD MMM'))}
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                                <div className="mb-auto pt-0 pl-1 pr-1">
                                  <div className="row announcement-inner-content flex-direction-column">
                                    <div className="row justify-content-between">
                                      <div>
                                        <h3 className="mom-feeds-rows-heading">
                                          {list.greeting_title}
                                        </h3>
                                        {list.is_event_owner === false &&
                                          <h3 className="card-content-subtitle">
                                            {list.employee_name}
                                          </h3>
                                        }
                                      </div>
                                      <div className="announcement-inner-title justify-content-center">
                                        <p className="corner-date">
                                          {this.state.momentsType === 'My Moments' ? (moment(list.moment_date).format(('DD MMM YYYY'))) : (moment(list.moment_date).format(('DD MMM')))}
                                        </p>
                                      </div>
                                    </div>
                                    <p className="announcement-inner-content">
                                      {list.greeting_message}
                                    </p>
                                  </div>
                                </div>
                                <div className="pt-0 pl-1 pr-1 pb-0">
                                  <div className="row"></div>
                                </div>
                                {/* ***************************** reaction *********************** */}
                                <div className="pt-0 pl-1 pr-1 pb-0">
                                  <div className='row '>
                                    <div className="row outer-emoji align-item-center ">
                                      {list.feedback_data.length !== 0 &&
                                        <>
                                          <div
                                            className='d-flex justify-content-start align-item-center'
                                            data-toggle="modal"
                                            data-target="#openEmployeeReaction"
                                            onClick={() => this.callChildFunctionFromParent(list.moments_id)}
                                          >
                                            {[...new Map(list.feedback_data.map(item => [item["employee_response"], item])).values()].map((feedB, id) =>
                                              <div key={id} style={{ marginRight: '-5px' }}>
                                                {this.state.emojiData.map((emo, id) =>
                                                  <div key={id}>
                                                    {emo.description === feedB.employee_response &&
                                                      <>
                                                        <img src={emo.src} alt="" />
                                                      </>
                                                    }
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                            <div className=' reaction-text ml-2'>
                                              {
                                                list.feedback_data.length === 1 && list.employee_response === '' && this.state.moments_id === list.moments_id ?
                                                  (this.state.username + " and " + list.feedback_data.length + reactionFromYouAndOther)
                                                  :
                                                  list.feedback_data.length === 1 && list.employee_response === '' ?
                                                    (list.feedback_data.length + rectionFormOther)
                                                    :
                                                    list.feedback_data.length === 1 && list.employee_response != '' &&
                                                    (this.state.username)
                                              }
                                              {
                                                list.feedback_data.length > 1 && list.employee_response === '' && this.state.moments_id === list.moments_id ?
                                                  (this.state.username + " and " + list.feedback_data.length + reactionFromYouAndOther)
                                                  :
                                                  list.feedback_data.length > 1 && list.employee_response === '' ?
                                                    (list.feedback_data.length + rectionFormOther)
                                                    :
                                                    list.feedback_data.length > 1 && list.employee_response != '' &&
                                                    (this.state.username + " and " + (list.feedback_data.length - 1) + reactionFromYouAndOther)
                                              }
                                              {
                                                list.feedback_data.length === 0 && list.employee_response === '' && this.state.moments_id === list.moments_id &&
                                                (this.state.username)
                                              }
                                            </div>
                                          </div>
                                        </>
                                      }
                                    </div>
                                  </div>
                                </div>
                                {/* ***************************** reaction large emoji*********************** */}
                                <div className="pl-1 pr-0 pb-0 pt-0">
                                  <div className="row d-flex justify-content-between">
                                    <div
                                      onClick={() =>
                                        this.handleClick(
                                          list.moments_id,
                                          "like"
                                        )
                                      }
                                    >
                                      {list.employee_response === "like" ? (
                                        <img
                                          src={likecolor}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      ) : (
                                        <img
                                          src={likegrey}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      )}
                                    </div>
                                    <div
                                      onClick={() =>
                                        this.handleClick(
                                          list.moments_id,
                                          "applaud"
                                        )
                                      }
                                    >
                                      {list.employee_response === "applaud" ? (
                                        <img
                                          src={clapcolor}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      ) : (
                                        <img
                                          src={clapgrey}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      )}
                                    </div>
                                    <div
                                      onClick={() =>
                                        this.handleClick(
                                          list.moments_id,
                                          "love"
                                        )
                                      }
                                    >
                                      {list.employee_response === "love" ? (
                                        <img
                                          src={lovecolor}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      ) : (
                                        <img
                                          src={lovegrey}
                                          alt=""
                                          className="portal-emoji"
                                        />
                                      )}
                                    </div>
                                    {!list.is_event_owner ? (
                                      <input
                                        type="button"
                                        value="SEND WISHES"
                                        className="ml-2 sendWishBtn"
                                        onClick={() => this.openSendWishPopup(list)}
                                      />
                                    )
                                      :
                                      (''
                                      )
                                    }
                                    {this.state.showSendWishPopup == true ? (
                                      <SendWishPopups
                                        notification_type={this.state.notification_type}
                                        sendWishPopData={this.state.sendWishPopData}
                                        popup={this.state.showSendWishPopup}
                                        onDismiss={() =>
                                          this.setState({
                                            showSendWishPopup: false,
                                          })
                                        }
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    </Fragment>
                  ))}
                </>
                {this.state.page_number < this.state.total_page && this.state.total_page > 1 &&
                  <div style={{ margin: '10px' }}>
                    <div
                      className='row col-12 d-flex justify-content-center card-for-click mx-10'
                      onClick={() => this.morePage()}
                      style={{ cursor: 'pointer' }}
                    >
                      <label style={{ cursor: 'pointer', margin: '5px', paddingBottom: '5px' }} className='card-content-title white-color' >
                        {MORE}
                      </label>
                    </div>
                  </div>
                }
              </div>
              :
              <div className=''>
                <div className='d-flex justify-content-center flex-column' style={{ height: '27.688rem', marginTop: '20px' }}>
                  <div className='align-self-center'>
                    <img src={NoMomentData} alt="Nodata" />
                  </div>
                  <div className='align-self-center'>
                    <label className='card-content-title grey-color'>{NoDataMsg}</label>
                  </div>
                </div>
              </div>
            }
          </div>
          {/* ********************model for reaction people************************************** */}
          <MOMReactionview
            passMomentId={this.state.momentTypeId}
            ref={instance => { this.child = instance; }}
          />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState
  };
};

export default connect(mapStatesToProps, null)(MOMFeeds);


