import React, { Component, Fragment } from 'react'
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import NewNotdata from '../../../shared/notFound/NewNoData';
import moment from 'moment';

const closedProjectTitle = {
    srNo: 'Sr. No',
    pg_perno: "PG Name",
    title: "Project Title",
    status: "Status",
    planned_start_date: "Start Date",
    planned_end_date: "End Date",
    no_of_openings: "No. of Opening",
}
const tableTitles = ["pg_perno", "title", "status", "planned_start_date", "planned_end_date", "no_of_openings"]
const detailsViewTitle = ["pg_perno", "title", "status", "projectBHR", "location", "projectArea", "planned_start_date", "planned_end_date", "projectOU", "no_of_openings", "pgLevel", "planned_mtr_date"] //,"frDate"

class ClosedProject extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    callToOpenDetails = (data) => {
        this.props.selectedProject(data)
        this.props.view(true)
        this.props.detailsViewTitle(detailsViewTitle)
    }
    render() {
        return (
            <div className='overflow-x-auto h-60vh'>
                {this.props.data.length !== 0 ?
                    <table>
                        <thead>
                            <tr>
                                {
                                    Object.values(closedProjectTitle).map((value, idx) =>
                                        <th scope="col" className={`p-10px ${idx === 0 ? "w-5" : "w-7"} ${idx === 6 ? "text-align-center-noflex " : ""}`}
                                            key={idx}
                                        >
                                            <div className='thead pr-0'>{value}</div>
                                        </th>
                                    )
                                }
                                <th className='p-10px text-align-center-noflex w-7'>
                                    <div className='thead pr-0'>Action</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props?.data && this.props?.data?.length && this.props.data.map((projectData, id) =>
                                <tr className='line-horizontal' key={id}>
                                    <td className="td w-5 pt-10px pl-20px">
                                        {id + 1}
                                    </td>
                                    {tableTitles.map((value, idx) =>
                                        <Fragment key={idx}>
                                            {
                                                idx === 0 ?
                                                    <td key={idx} className={`td pt-10px w-7 padding-left-10`}>
                                                        {projectData.project_guide.CompName}
                                                    </td>
                                                    :
                                                    <td key={idx} className={`td pt-10px w-7  ${idx === 5 ? "text-align-center-noflex " : ""} ${idx === 0 || idx === 1 || idx === 2 || idx === 3 || idx === 4 ? "padding-left-10" : ""}`}>
                                                        {idx == 3 || idx == 4 ? moment(projectData[value]).format('DD-MM-YYYY') : projectData[value]}
                                                    </td>
                                            }
                                        </Fragment>
                                    )}
                                    <td className='text-align-center-noflex pt-10px w-7'>
                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                            onClick={() => this.callToOpenDetails(projectData)}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    :
                    <>
                        <span className='d-flex justify-content-center align-item-center mt-10p'>
                            <NewNotdata />
                        </span>
                    </>
                }
            </div>
        )
    }
}
export default ClosedProject
