import React, { Component } from 'react';
import moment from 'moment';
import '../../employeeScreens/transfer/Transfer.css';
import ReactPlayer from 'react-player'
import pdf from '../../../../assets/drishti_images/documents/pdf.svg';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
class NotificationDetailsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomed: false
        }
    }

    renderMTRTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{props}</div>
        </Tooltip>
    );

    render() {
        const { data, type, title, submit } = this.props
        const ToolTipTexToDown = "Click to download"
        if (!this.props.data) return null;
        return (
            <div className="modal popup-box">
                {type === 'log-In-Image' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Image Title :</div>
                                        <div className="text14_light mt-1">{data.image_title}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Hyperlink :</div>
                                        <div className="text14_light mt-1">{data.image_hyperlink}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold viewImage">Image :</div>
                                        <div className="text14_light mt-1"><img src={data.image_link} style={{ height: '9.188rem', width: '12.75rem' }} /></div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'announcementDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.announcement_title}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold">Attachment :</div>
                                        <div className="text14_light mt-1">{data.announcement_image}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.announcement_description}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Type :</div>
                                        <div className="text14_light mt-1">{data.announcement_type}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Hyperlink :</div>
                                        <div className="text14_light mt-1">{data.announcement_url}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold">Total response :</div>
                                        <div className="text14_light mt-1">{data.total_response}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created date :</div>
                                        <div className="text14_light mt-1">{moment(data.created_datetime).format("DD-MM-YYYY")}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'leadershipAnnouncementDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by}</div>
                                    </div>
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark mr-1">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.announcement_description}</div>
                                    </div>
                                </div>
                                <div className='mt-2'><hr /></div>
                                <div className="d-flex justify-content-center align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold viewImage">Image :</div>
                                        <div className="text14_light mt-1"><img src={data.image_link} style={{ height: '10rem', width: '10rem' }} /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'tataBuzzDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.news_title}</div>
                                        <div className='mt-2'><hr /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.news_description}</div>
                                        <div className='mt-2'><hr /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'upcomingOccasionDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.event_title}</div>
                                        <div className='mt-2'><hr /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Event Date :</div>
                                        <div className="text14_light mt-1">{data.event_date}</div>
                                        <div className='mt-2'><hr /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'warning' &&
                    <div className="declaration-box p-0 " style={{ width: '40%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="text20">
                                        {/* <div className="text14_bold">Title :</div> */}
                                        The image width should be <span className='text14_bold'>&nbsp;1280px&nbsp;</span>Please ensure that the image meets the required dimensions to prevent distortion or cropping.
                                        if you want to add click on <span className='text14_bold'>&nbsp;Ok&nbsp;&nbsp;</span>otherwise click on <span className='text14_bold'>&nbsp;&nbsp;Cancel&nbsp;&nbsp;</span>
                                        <div className="text14_light mt-1"></div>
                                        <div className='mt-2'><hr /></div>
                                        <div className='mt-2'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer btnBox justify-content-end'>
                            <div className='justify-content-around'>
                                <div className='justify-content-start'>
                                    <input type='button'
                                        value='CANCEL'
                                        className='popup-back-btn popup-back-text'
                                        onClick={this.props.onDismiss}
                                    />
                                </div>
                                <div className='justify-content-start'>
                                    <input
                                        type='button'
                                        // type='submit'
                                        value='Ok'
                                        className='popup-confirm-btn'
                                        onClick={this.props.submit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'sparshDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%', height: '80vh' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <table className="flex-direction-column justify-content-center-noflex ">
                            <tbody className="flex-direction-row  p-10px">
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead'>Title:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 pr-12px'>
                                                <div className="td auto-line-break" >
                                                    {data.announcement_title}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Total response:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {data.total_response}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <div className='mt-2'><hr /></div>
                            <tbody className="flex-direction-row p-10px">
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Created by:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 pr-12px'>
                                                <div className="td auto-line-break" >
                                                    {data.created_by}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Created date:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {moment(data.created_datetime).format("DD-MM-YYYY")}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <div className='mt-2'><hr /></div>
                            <tbody className="flex-direction-row p-10px">
                                <div className='flex-direction-column w-100 text-align-center-noflex'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead'>Attachment:</div></th>
                                        </tr>
                                    </div>
                                    <tbody className='justify-content-center'>
                                        <tr>
                                            <td className='w-20'>
                                                {
                                                    data.file_extention === "PNG" ?
                                                        <div className="td auto-line-break" >
                                                            <img src={data.image_link} style={{ height: '10rem', width: 'auto' }} />
                                                        </div>
                                                        :
                                                        data.file_extention === "mp4" &&
                                                        <div className="mt-1">
                                                            <ReactPlayer url={data.image_link}
                                                                width={350} height={200}
                                                                controls={true}
                                                                playing={true}
                                                                light={data.thumbnail_link}
                                                                config={{
                                                                    file: {
                                                                        attributes: {
                                                                            controlsList: "fullscreen"
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <div className='mt-2'><hr /></div>
                            <tbody className="flex-direction-row p-10px">
                                <div className='flex-direction-column w-100'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead'>Description:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {data.announcement_description}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <div className='mt-2'><hr /></div>
                        </table>
                    </div>
                }

                {type === 'tataTVDetails' &&
                    <div className="declaration-box p-0 " style={{ width: '40%', height: '80%' }}>
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <table className="flex-direction-column justify-content-center-noflex ">
                            <div className="flex-direction-row  p-10px">
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead'>Title:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {data.tata_motors_video_title}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Video Type:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {data.tata_motors_video_type}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                            <div className='mt-2'><hr /></div>
                            <div className="flex-direction-row p-10px">
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Created by:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {data.created_by}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead '>Created date:</div></th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20'>
                                                <div className="td auto-line-break" >
                                                    {moment(data.created_datetime).format("DD-MM-YYYY")}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                            <div className='mt-2'><hr /></div>
                            <div className="flex-direction-row p-10px">
                                <div className='flex-direction-column w-100 text-align-center-noflex'>
                                    <div>
                                        <tr>
                                            <th scope="col" ><div className='thead'>Attachment:</div></th>
                                        </tr>
                                    </div>
                                    <tbody className='justify-content-center '>
                                        <tr>
                                            <td className='w-20 relative-icon'>
                                                <div className="mt-1" style={{ width: '', height: '' }}>
                                                    <ReactPlayer url={data.presigned_link} width={350} height={200} controls={true}
                                                        playing={true}
                                                        light={data.presigned_thumbnail_link}
                                                        config={{
                                                            file: {
                                                                attributes: {
                                                                    controlsList: "fullscreen"
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </div>
                            <div className='mt-2'><hr /></div>
                        </table>
                    </div>
                }
                {type === 'plantupdate' &&
                    <div className="declaration-box p-0 w-40 h-80vh">
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <table className="flex-direction-column justify-content-center-noflex ">
                            <tbody className="flex-direction-row  p-10px">
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" className='no-border p-5px'>
                                                <div className='thead' >Title:</div>
                                            </th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 p-lrb-5px no-border'>
                                                <div className="td auto-line-break" >
                                                    {data.title}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" className='no-border p-5px'>
                                                <div className='thead '>Document:</div>
                                            </th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 pr-12px no-border p-lrb-5px'>
                                                <OverlayTrigger placement="left" overlay={this.renderMTRTooltip(ToolTipTexToDown)}>
                                                    <a href={data.pdf_url} download={data.pdf_name}
                                                        className="td auto-line-break align-item-center flex-direction-row pointer"
                                                    // onClick={() => this.props.documentDownload(data.pdf_url, data.pdf_name)}
                                                    >
                                                        <img alt="pdf" src={pdf} className="h-15 w-15 mx-10px"></img>
                                                        <span className='td row auto-line-break'>{data.pdf_name}</span>
                                                    </a>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <div className='mt-2'><hr /></div>
                            <tbody className="p-10px">
                                <tr>
                                    <th scope="col" className='no-border p-5px'>
                                        <div className='thead '>Image:</div>
                                    </th>
                                </tr>
                                <tr>
                                    <td className='w-20 no-border p-lrb-5px'>
                                        <OverlayTrigger placement="bottom" overlay={this.renderMTRTooltip(ToolTipTexToDown)}>
                                            <div className="td auto-line-break flex-direction-column align-item-center-noflex justify-content-center-noflex ">
                                                <a href={data.file_url} download={data.file_name}>
                                                    <img src={data.file_url}
                                                        className='pointer'
                                                        style={{
                                                            transition: 'all 0.3s ease',
                                                            transform: this.state.zoomed ? 'scale(2)' : 'scale(1)',
                                                            height: this.state.zoomed ? '10rem' : '5rem',
                                                            width: 'auto'
                                                        }}
                                                    // onClick={() => this.props.documentDownload(data.file_url, data.file_name)}
                                                    />
                                                </a>
                                                <span className='td row thead auto-line-break'>
                                                    {data.file_name}
                                                </span>
                                            </div>
                                        </OverlayTrigger>
                                    </td>
                                </tr>
                            </tbody>
                            <td className='mt-2'><hr /></td>
                            <tbody className="flex-direction-row  p-10px">
                                <div className='flex-direction-column'>
                                    <div>
                                        <tr>
                                            <th scope="col" className='no-border p-5px'>
                                                <div className='thead '>Description:</div>
                                            </th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 no-border p-lrb-5px'>
                                                <div className="td auto-line-break" >
                                                    {data.description}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            <tbody className="flex-direction-row  p-10px">
                                <div className='flex-direction-column w-50'>
                                        <tr>
                                            <th scope="col" className='no-border p-5px'>
                                                <div className='thead'>Update date:</div>
                                            </th>
                                        </tr>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 p-lrb-5px no-border'>
                                                <div className="td auto-line-break" >
                                                    {moment(data.update_date).format("DD-MM-YYYY")}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                                <div className='flex-direction-column w-50'>
                                    <div>
                                        <tr>
                                            <th scope="col" className='no-border p-5px'>
                                                <div className='thead '>Type:</div>
                                            </th>
                                        </tr>
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className='w-20 no-border p-lrb-5px'>
                                                <div className="td auto-line-break" >
                                                    {data.update_type.charAt(0).toUpperCase() + data.update_type.slice(1)}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </div>
                            </tbody>
                            {/* <div className='mt-2'><hr /></div> */}
                        </table >
                    </div >
                }
            </div >
        );
    }
}

export default NotificationDetailsPopup;