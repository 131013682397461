import React, { Component } from 'react'
import '../../../shared/confirmationPopup/ConfirmationPopup.css'
import '../../../../assets/styles/text.css'
import './DocumentStyles.css'
const currentYear = new Date().getFullYear();
const prevYear = currentYear - 1;
class NotePopup extends Component {
    render() {
        const { type } = this.props
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <div className="modal-header">
                        <h4 className="modal-title">
                            {"Note"}
                        </h4>
                        <button type="button" className="close" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body w-100">
                        <div className='px-30px pt-24px'>
                            <div className="">
                                {type === 'retiral' &&
                                <ol className='flex-direction-column pb-3'>
                                        <li className="note-text-description mb-2"> Statements as applicable (in terms of financial year and trust fund policy)  will be available for download below.</li>
                                        <li className="note-text-description mb-2"> Statements on the system are available from 2005 or after date of joining whichever is later.</li>
                                        <li className="note-text-description mb-2"> In case of any queries w.r.t annual statements or passbook entry, please raise to "Trust Fund Operations" team, via gdcsupport.tatamotors.com.</li>
                                    </ol>
                                }
                                {type === 'addition' &&
                                <ol className='flex-direction-column pb-3'>
                                        <li className="note-text-description mb-2"> PF passbook covers PF details from Apr {currentYear} -  Apr {prevYear} .</li>
                                        <li className="note-text-description mb-2"> IT Worksheet latest available from payroll can be downloaded from here.</li>
                                        <li className="note-text-description mb-2"> Pension details will be coming soon.</li>
                                    </ol>
                                }
                                {type === 'payslip' &&
                                <ol className='flex-direction-column pb-3'>
                                        <li className="note-text-description mb-1"> Please make sure to enter your PAN number in capital letters or your date of birth in 'ddmmyyyy' format while opening your payslip.</li>
                                        {/* <div className='note-text-description mb-1'>OR</div>
                                        <li className="note-text-description mb-2"> Birth date using format 'ddmmyyyy'.</li> */}
                                        {/* <li className="note-text-description mb-2"> In case of any queries w.r.t annual statements or passbook entry, please raise to "Trust Fund Operations" team, via gdcsupport.tatamotors.com.</li> */}
                                    </ol>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotePopup;