import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { toaster, toggelDiffStatus } from '../../../../../utils/Utils';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import { updateSuggestionOfficeAction } from '../../../../../constants/api.service';

class SuggestionOfficeCommonPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            division: '',
            agency: '',
            remarks: ''
        }
    }
    handleBtnAction(){
        let payload ={
            action: this.props.title,
            sug_ids: this.props.data.map((data)=>{return data.id}),
            to_agency_name:this.props.title === 'Reject'? null :this.props.title=='Send_back'? this.props.data[0]?.division_name:this.props.selectedOffice,
            from_agency_name:  this.props.selectedOffice,
            comment: this.state.remarks,
            from_role: "Suggestion_office",
            from_status:this.props.title=='Send_back'||this.props.title=='Reject'?2:2,
            to_status: this.props.title === 'Send_back' ? 1 : this.props.title === 'Reject' ? 9: 3,
            to_role:this.props.title==='Approved' ? 'Super_Admin': null,
        }
        // console.log("payload-->",payload)
        updateSuggestionOfficeAction(payload).then((res)=>{
            if (res){
                if (res.status === 200){
                    toaster("success", res.data.message)
                    this.props.onDismiss()
                }else{
                    toaster("error", res.data.message)
                }
            }
        }).catch((err)=>{
            console.log("error ----- ", err)
        })
    }
   
    render() {
        const { title, loading,data ,suggestionOfficeCardData,selectedOffice} = this.props
        const { division, agency, remarks } = this.state

        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title == "EVALUATOR" ? "Suggestion to other Evaluator" : title == "SEND BACK" ? "Clarification Remarks" : title === "Approved" ? 'Confirmation' : title === "Reject" ? "Rejection Remark" : "Re-evaluation Remarks"}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        {/* ------ EVALUATOR Body-------*/}
                        {title === "EVALUATOR" &&
                            <div className='flex
                            -direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Division"}</div>
                                    <DynamicDropdown
                                        data={toggelDiffStatus()}
                                        render="value"
                                        value={division ? division : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) => this.setState({
                                            division: data.value
                                        })}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                        width={"w-16rem"}
                                    />
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Agency"}</div>
                                    <DynamicDropdown
                                        data={toggelDiffStatus()}
                                        render="value"
                                        value={agency ? agency : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) => this.setState({
                                            agency: data.value
                                        })}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                        width={"w-16rem"}
                                    />
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Enter Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) =>  this.setState({ remarks : e.target.value}) }
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "Send_back" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Note: Enter Remarks for Clarification for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "Reject" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Re-evaluation Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "Approved" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Approved Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks: e.target.value })}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        {title === "Approved" && <input type='button' value='APPROVE' className={
                                remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                            } disabled={
                                remarks === '' ? true : false
                            } onClick={() =>{this.handleBtnAction();this.props.submitApprove(remarks)}} /> }
                        {title === 'EVALUATOR' ?
                            <input type='button' value='CONFIRM' className={
                                remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                            } disabled={
                                remarks === '' ? true : false
                            } onClick={() => this.props.submitEvaluator(division, agency, remarks)} /> :
                            title === 'Send_back' ?
                                <input type='button' value='SEND BACK' className={
                                    remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                                } disabled={
                                    remarks === '' ? true : false
                                } onClick={() => {this.handleBtnAction();this.props.submitSendBack(remarks)}} /> :
                                title === 'Reject' ? 
                                    <input type='button' value='REJECT' 
                                        className={remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'}
                                        onClick={() => {this.handleBtnAction();this.props.submitRejection(remarks)}} /> :
                                    // <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.props.onDismiss()} />
                                    null
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default SuggestionOfficeCommonPopup;