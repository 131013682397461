import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import '../../managerScreens/teams/TeamsStyle.css';
import { getCarouselImages } from './Gems.api.service';
import { toaster } from '../../../../utils/Utils';

class GemsCarousel extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            carouselData: []
        }
    }

    componentDidMount() {
        this.getCarousel()
    }

    getCarousel = () => {
        this.setState({ loading: true })
        const param = {}
        getCarouselImages(param, this.props)
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        carouselData: res?.data?.data ? res?.data?.data : []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        carouselData: []
                    })
                    toaster("", res?.message)
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    carouselData: []
                })
                toaster("", "Something went wrong")
                console.log("getCarousel", err)
            })
    }

    render() {
        const { carouselData } = this.state;
        return (
            <div>
                {carouselData.length !== 0 ?
                    <AliceCarousel autoPlay autoPlayInterval="2000" infinite={true}>
                        {carouselData.map((listValue, index) => {
                            return (
                                <div key={index}>
                                    <div className='d-flex flex-direction-row justify-content-center align-item-center'>
                                        <img src={listValue?.image_path} role="presentation" className='image-styles' />
                                    </div>
                                </div>
                            )
                        })
                        }
                    </AliceCarousel>
                    :
                    <div className='col-md-12 justify-content-center align-item-center'>
                        <div className='mt-5'>
                            <DataNotFound />
                        </div>
                    </div>
                }
            </div >
        );
    }
}

export default GemsCarousel;