import React, { useState, useMemo, useCallback, useEffect } from 'react';
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import { suggestionDetails, clarification } from '../../suggestionScheme/constants'
import download from '../../../../../assets/drishti_images/download.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import edit from '../../../../../assets/drishti_images/profile/edit.svg'
import SuggesterDeatilPopup from './SuggesterDeatilPopup'
import EditSuggestion from './EditSuggestion'
import { DDMMYYYY, toaster } from '../../../../../utils/Utils'
import ClarifcationDetailPopup from './ClarifcationDetailPopup'
import searchIcon from '../../../../../assets/drishti_images/admin/searchIcon.svg';
import { deleteS3Documents, getMasterLists, getMasterStatus, getSuggestionList } from '../../../../../constants/api.service';
import { toast } from 'react-toastify';
import { Pagination } from 'react-bootstrap'
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import Select, { components } from 'react-select';
import './suggestion.css';
import { useSelector } from 'react-redux';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';

const Table = ({ data, showCheckbox, showAction, selectedOption, handleEdit, loading, isSuggestorAddForm }) => {
    const [viewDetail, setViewDetail] = useState(false);
    const [viewClarificationDetails, setViewClarificationDetails] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const SuggestionDetails = useMemo(() => {
        return selectedOption === 'clarification' ? clarification : suggestionDetails;
    }, [selectedOption]);

    const onDismiss = useCallback(() => {
        setViewDetail(false);
        setViewClarificationDetails(false);
        setSelectedItem(null);
    }, []);

    const viewDetails = useCallback((item) => {
        setSelectedItem(item);
        setViewDetail(true);
    }, []);

    const viewClarificationDetail = useCallback((item) => {
        setSelectedItem(item);
        setViewClarificationDetails(true);
    }, []);
    const keys = ['division_name','agency_name','award_amount','status']
    return (
        <>
            {loading ? (
                <LoaderIndicator loading={loading} />
            ) : (
                <>
                <div className='scroll-x'>
                    <div className="table-responsive" style={{
                        position: 'relative', 
                        minHeight: '340px', 
                        maxHeight: '340px' ,
                        overflowX: 'auto',  
                        whiteSpace: 'nowrap'
                        }}>
                        <table className="table table-no-border">
                            <thead className='thred-Fixed-suggestion'>
                                <tr>
                                    {SuggestionDetails.map((detail, index) => (
                                        <th className='thead' key={index} style={{ width: detail.width }}>
                                            {detail.title}
                                        </th>
                                    ))}
                                    {showAction && (
                                        <th className='thead' style={{ width: "10%", paddingLeft: '20px' }}>
                                            Action
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 ? (
                                    data.map((item, index) => (
                                        <tr key={index}>
                                            {showCheckbox && (
                                                <td>
                                                    <input type="checkbox" />
                                                </td>
                                            )}
                                            {SuggestionDetails.map((detail, subIndex) => (
                                                <td
                                                    className={`unineue-font size_875rem ${keys.includes(detail.key) ? "Sugg-horizontal-scroll" : 'custom-pre-suggestor' }`} style={{
                                                        padding: '8px',
                                                    }} 
                                                key={subIndex}>
                                                    {detail.subKey ?
                                                        (item[detail.key][detail.subKey] ?? '-----')
                                                        : (detail.key === 'created_datetime' || detail.key === 'modified_datetime' || detail.key === 'auto_close_date' ?
                                                            (item[detail.key] ? DDMMYYYY(item[detail.key]) : '-----')
                                                            : (item[detail.key] ?? '-----')
                                                        )
                                                    }
                                                </td>
                                            ))}
                                            {showAction && (
                                                <td>
                                                    <div>
                                                        {selectedOption === 'clarification' ? (
                                                            <>
                                                                {!isSuggestorAddForm && (

                                                                    <img
                                                                        src={edit}
                                                                        alt='Edit'
                                                                        style={{ width: '30px' }}
                                                                        className='pointer mx-2'
                                                                        onClick={() => handleEdit(item)}
                                                                    />)
                                                                }
                                                                <img
                                                                    src={view_action}
                                                                    alt='view'
                                                                    style={{ width: '40px' }}
                                                                    className='pointer mx-2'
                                                                    onClick={() => viewClarificationDetail(item)}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img
                                                                    src={view_action}
                                                                    alt='view'
                                                                    style={{ width: '40px' }}
                                                                    className='pointer mx-2'
                                                                    onClick={() => viewDetails(item)}
                                                                />
                                                                {item.status === 'Approved' && (
                                                                    <img
                                                                        src={download}
                                                                        alt='download'
                                                                        style={{ width: '40px' }}
                                                                        className='pointer mx-2'
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </td>
                                            )}
                                        </tr>

                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={SuggestionDetails.length + (showCheckbox ? 1 : 0) + (showAction ? 1 : 0)}>
                                            <DataNotFound />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                </>
                )
            }
            {viewDetail && <SuggesterDeatilPopup selectedItem={selectedItem} selectedOption={selectedOption} onDismiss={onDismiss} />}
            {viewClarificationDetails && <ClarifcationDetailPopup selectedItem={selectedItem} onDismiss={onDismiss} />}
        </>
    );
};

const SuggestionDashboard = ({ status = 0, triggerUpdate = false, setselectedCardStatus, isSuggestorAddForm }) => {
    const today = new Date().toISOString().split('T')[0];
    const [suggestionList, setSuggestionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [clarificationList, setClarificationList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState('suggestion_list');
    const [viewFilter, setViewFilter] = useState(status.length == 0 || undefined ? { label: "All", value: '' } : status ? status : { label: "All", value: '' });
    const [suggestionEditPage, setSuggestionEditPage] = useState(false);
    const [searchItem, setSearchItem] = useState('');
    const [localSearchItem, setLocalSearchItem] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [editData, setEditData] = useState(null);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [originalSuggestionList, setOriginalSuggestionList] = useState([]);
    const [checked, setChecked] = useState(0);
    const [currentStatusCodes, setCurrentStatusCodes] = useState('')
    const [highlightStatus, setHighlightedStatus] = useState({})
    const [schemeTypeList, setschemeTypeList] = useState([])
    const [selectedSchemeType, setSelectedSchemeType] = useState([])

    const options = statusList.map(status => ({ value: status.id, label: status.title }));
    const loginData = useSelector(state => state.loginState);

    const CustomValueContainer = ({ children, ...props }) => {
        const count = props.getValue().length;
        const filteredList = useMemo(() => props.getValue(), [props.getValue]);
        return (
            <components.ValueContainer {...props}>
                {count > 0 ? `${count} Status Selected` : children}
            </components.ValueContainer>
        );
    };
    const suggestionListStaus = [
        { key: 0, value: 'Suggestion List' },
        { key: 1, value: 'Reconsideration' },
    ]

    const handleViewFilter = useCallback((selectedOptions) => {
        const newPage = 1
        setCurrentPage(newPage);
        setViewFilter(selectedOptions);
        const selectedValues = selectedOptions.map(option => option.value).join(',');
        setCurrentStatusCodes(selectedValues)
        getSuggestionListData(selectedValues, newPage, localSearchItem, selectedSchemeType?.scheme_type_code);
    }, [currentPage, fromDate, toDate, localSearchItem]);

    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '300px',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#653DAF',
            borderRadius: '5px',
            padding: '2px 5px',
            color: 'white'
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            cursor: 'pointer',
            color: 'white',
            ':hover': {
                backgroundColor: 'transparent',
                color: 'white',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            maxHeight: '200px',
            overflowY: 'auto',
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
            color: state.isSelected ? 'white' : provided.color,
            padding: '10px',
            margin: '5px 0',
            cursor: 'pointer',
            ':active': {
                backgroundColor: state.isSelected ? '#653DAF' : state.isFocused ? '#f0f0f0' : 'white',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            maxHeight: '38px',
            overflowY: 'auto',
        }),
    };

    useEffect(() => {
        selectedOption === 'suggestion_list' && getStatusMasterData();
        if (selectedOption === 'clarification') {
            setCurrentStatusCodes('')
            getSuggestionListData('', currentPage, localSearchItem, selectedSchemeType?.scheme_type_code)
        }
        if (triggerUpdate) {
            getSuggestionListData(viewFilter.value, currentPage);
        }
        if (status) {
            const newPage = 1
            const selectedValues = status.map(option => option.value).join(',');
            setCurrentStatusCodes(selectedValues)
            getSuggestionListData(selectedValues, newPage, localSearchItem, selectedSchemeType?.scheme_type_code);
        } else if (selectedOption === 'suggestion_list') {
            getSuggestionListData('', currentPage, localSearchItem, selectedSchemeType?.scheme_type_code)
        }

    }, [selectedOption, triggerUpdate, status]);

    useEffect(() => {
        getSchemeTypeList()
    }, [])

    const handlePageCount = (action) => {
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            getSuggestionListData(currentStatusCodes, currentPage - 1, localSearchItem, selectedSchemeType?.scheme_type_code)
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            getSuggestionListData(currentStatusCodes, currentPage + 1, localSearchItem, selectedSchemeType?.scheme_type_code)
        }
    };

    const getSchemeTypeList = () => {
        setLoading(true)
        getMasterLists("scheme_type")
            .then((res) => {
                // console.log("RES SCHEME-->", res)
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setschemeTypeList(data?.data ? data?.data?.scheme_type_list : [])
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }
    const getSuggestionListData = async (selectedCodes, page, searchText, suugSchemeCode) => {
        setLoading(true);
        let param = {
            req_status: selectedOption,
            sug_status_codes: selectedCodes,
            from_date: fromDate,
            to_date: toDate,
            search: searchText,
            page_number: page,
            scheme_type_code: suugSchemeCode
        };
        getSuggestionList(param)
            .then(res => {
                const { status, data } = res;
                if (status === 200) {
                    setSuggestionList(data.data.paginated_data);
                    setOriginalSuggestionList(data.data.paginated_data);
                    setTotalPages(data.data.total_pages);
                } else {
                    setSuggestionList([]);
                    setTotalPages(1);
                }
            })
            .catch(error => {
                toast.warn(error.message, {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    className: 'toastWarn',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getStatusMasterData = () => {
        setLoading(true);
        getMasterStatus()
            .then(res => {
                if (res.status === 200) {
                    const formattedStatusList = res.data.data.status_list.map(status => ({
                        title: status.status_name,
                        id: status.status_code,
                    }));
                    const allStatus = { title: 'All', id: '' };
                    setStatusList([allStatus, ...formattedStatusList]);
                } else {
                    toast.warn(res.message, {
                        position: 'bottom-center',
                        hideProgressBar: true,
                        className: 'toastWarn',
                    });
                }
            })
            .catch(error => {
                toast.warn(error.message, {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    className: 'toastWarn',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const deleteS3Document = async (pathdoc) => {
        setLoading(true)
        let params = {
            emp_id: loginData.userData.Perno,
            path: pathdoc
        }
        await deleteS3Documents(params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    console.log("document Deleted Successfully")
                }
                else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
            })
            .catch((error) => {
                console.log("error in S3 delete documents-->", error)
                error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "")
            })
            .finally(() => { setLoading(false) })
    }

    const handleOptionChange = useCallback((key, list) => {
        const selectedOption = key === 0 ? 'suggestion_list' : key === 1 ? 'clarification' : '';
        setSelectedOption(selectedOption);
        setCurrentPage(1)
        setselectedCardStatus(0)
        setLocalSearchItem('');
        setFromDate('');
        setCurrentStatusCodes('')
        setToDate('');
        setViewFilter({ label: "All", value: '' })
        setChecked(key);
        setFilteredSuggestions(selectedOption === 'clarification' ? clarificationList : suggestionList);
        setEditData(null);

    }, [clarificationList, suggestionList]);

    const handleEdit = useCallback((item) => {
        setEditData(item);
        setSuggestionEditPage(true);
    }, []);

    const handleSearchChange = () => {
        const newPage = 1
        setCurrentPage(newPage);
        getSuggestionListData(currentStatusCodes, newPage, localSearchItem, selectedSchemeType?.scheme_type_code)
    };

    const handleSearch = () => {
        const newPage = 1
        setCurrentPage(newPage);
        getSuggestionListData(currentStatusCodes, newPage, localSearchItem, selectedSchemeType?.scheme_type_code);
    };

    const commonSerachFilters = (data) => {
        setSelectedSchemeType(data)
        getSuggestionListData(currentStatusCodes, currentPage, localSearchItem, data?.scheme_type_code)

    }

    const handleBack = (documents) => {
        if (documents?.length > 0) {
            documents.map((document) => {
                if (document.id === null || document.id === undefined) {
                    deleteS3Document(document.path)
                }
            })
        }
        setSuggestionEditPage(false);
    };

    if (suggestionEditPage) {
        return <EditSuggestion setselectedCardStatus={setselectedCardStatus} handleBack={handleBack} editData={editData} />;
    }

    return (
        <>
            <div className="flex-direction-row d-flex flex-wrap background-prop-new justify-content-between">
                <LoaderIndicator loading={loading} />
                <div className="row align-item-center ml-2 pt-10px pb-10px">
                    {suggestionListStaus.map((list, key) => (
                        <span key={key} className="align-item-center-noflex px-5px">
                            {checked === key ? (
                                <span className="" onClick={() => handleOptionChange(key, list)}>
                                    <img alt="radioOn" className="radio" src={radioOn} />
                                    <label className="radioTxt ml-2">{list.value}</label>
                                </span>
                            ) : (
                                <span onClick={() => handleOptionChange(key, list)}>
                                    <img alt="radioOff" className="radio" src={radioOff} />
                                    <label className="radioTxt ml-2">{list.value}</label>
                                </span>
                            )}
                        </span>
                    ))}
                </div>
                <div className="row d-flex flex-wrap flex-container">
                    <div className="ml-3 d-flex flex-wrap flex-container gap-10px">
                    {selectedOption === 'suggestion_list' &&
                            <div className='flex-direction-column gap-10px'>
                                <div className='p-sub-heading-new'>{"Suggestion Type"}</div>
                                {/* <div className='d-flex align-item-center'></div> */}
                                <DynamicDropdown
                                    data={schemeTypeList}
                                    render="scheme_type"
                                    value={selectedSchemeType?.scheme_type}
                                    selectedValue={(data) => commonSerachFilters(data)}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                    width={"w-16rem"}
                                    boxWidth="8rem"
                                />
                            </div>}
                        <div>
                            <div className='flex-direction-column'>
                                <div className="td-choice-pay p-sub-heading">Search</div>
                                <div className='row ml-10'>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-2 p-placeholder-admin"
                                            type="text"
                                            placeholder='Search'
                                            onChange={(e) => setLocalSearchItem(e.target.value)}
                                            value={localSearchItem}
                                        />
                                    </div>
                                    <div type='button' className='serchIconSize-gems' onClick={handleSearchChange} >
                                        <img alt="radioOn" className='filterIcon' src={searchIcon} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedOption === 'suggestion_list' &&
                            <div className="mr-1">
                                <div className="td-choice-pay p-sub-heading">Status</div>
                                <div className="p-placeholder-text">
                                    <Select
                                        isMulti
                                        options={options}
                                        value={viewFilter}
                                        onChange={handleViewFilter}
                                        className="basic-multi-select"
                                        placeholder="Select status"
                                        styles={customStyles}
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                    />
                                </div>
                            </div>
                        }
                        <div className="mr-1">
                            <div className="td-choice-pay p-sub-heading">From</div>
                            <div className="p-placeholder-text">
                                <input
                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                    type="date"
                                    name="fromDate"
                                    value={fromDate}
                                    max={today}
                                    onChange={(e) => setFromDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mr-1">
                            <div className="td-choice-pay p-sub-heading">To</div>
                            <div className="p-placeholder-text">
                                <input
                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                    type="date"
                                    name="toDate"
                                    value={toDate}
                                    min={fromDate}
                                    max={today}
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="h-100" style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <input
                                type="button"
                                value="SEARCH"
                                className=" align-item-center validateBtn mt-4"
                                onClick={handleSearch}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {suggestionList.length > 0 ?
                    <>
                        <div className="mt-2    ">
                            <Table
                                data={suggestionList}
                                data1={statusList}
                                handleEdit={handleEdit}
                                selectedOption={selectedOption}
                                showCheckbox={false}
                                showAction={true}
                                loader={loading}
                                isSuggestorAddForm={isSuggestorAddForm}
                            />
                        </div>
                    </> : <>
                        <div className='mt-5'>
                            <DataNotFound />
                        </div>
                    </>
                }
                {suggestionList.length > 0 ?
                    <div className="col-md-12 w-100 justify-content-center">
                        <Pagination>
                            {currentPage != 1 &&
                                <>
                                    <Pagination.Prev
                                        onClick={() => { handlePageCount("decrement") }} />
                                    <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                                    <Pagination.Ellipsis disabled />
                                </>
                            }
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            {currentPage != totalPages &&
                                <>
                                    <Pagination.Ellipsis disabled />
                                    <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                                    <Pagination.Next
                                        onClick={() => { handlePageCount("increment") }} />
                                </>
                            }
                        </Pagination>
                    </div> : null}
            </div>
        </>
    );
};

export default SuggestionDashboard;