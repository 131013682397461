import React from 'react'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { useEffect, useState } from 'react';
import SummaryPopUp from './SummaryPopUp';
import './LocalConveyanceAdmin.css'
import { getClaimSummaryAdmin, getClaimSummaryAttachment } from '../../../../constants/api.service';
import { summaryColumns as columns, tdStyle } from './constants'
import { exportTOExcel } from './constants';
import moment from 'moment';

const SummaryTable = (props) => {
    const { empId, fromDate, toDate, claimId, selectedPage, setTotalPage, exportSummary, setExportSummary, approvedChecked } = props;
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [isPopUpOpen, setIsPopUpOpen] = useState(false)
    const [selectedClaim, setSelectedClaim] = useState("")
    useEffect(() => {
        fetchSummary(empId, fromDate, toDate, claimId, selectedPage, approvedChecked)
    }, [empId, fromDate, toDate, claimId, selectedPage, approvedChecked])

    useEffect(() => {
        exportSummary == true && fetchClaimSummaryAttachment(empId, fromDate, toDate, claimId, selectedPage, approvedChecked)
    }, [exportSummary])

    const handleViewAction = (index) => {
        setIsPopUpOpen(true)
        setSelectedClaim(tableData[index])
    }

    const fetchClaimSummaryAttachment = (empID, fromdate, todate, claimID, selectedPage, approvedChecked) => {
        setLoading(true)
        let params = {
            claim_id: claimID,
            applicant_id: empID,
            from_date: fromdate,
            to_date: todate,
            claim_status: approvedChecked?.title,
        }

        getClaimSummaryAttachment(params, props).then(
            (res) => {
                if (res.status == 200) {
                    if (exportSummary == true) {
                        let exportBDCData = res?.data?.data
                        exportTOExcel('Export_Summary', exportBDCData, '.csv');
                        setLoading(false);
                    }
                }
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
        })
        setExportSummary(false)
    }


    const fetchSummary = (empID, fromdate, todate, claimID, selectedPage, approvedChecked) => {
        setLoading(true)
        let params = {
            claim_id: claimID,
            applicant_id: empID,
            from_date: fromdate,
            to_date: todate,
            claim_status: approvedChecked?.title,
            page_number: selectedPage
        }
        getClaimSummaryAdmin(params, props).then(
            (res) => {
                if (res.status == 200) {
                    setTotalPage(res?.data?.data?.total_pages)
                    setTableData(res?.data?.data?.paginated_data)
                }
                else {
                    setTotalPage(1)
                    setTableData([])
                }
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
        })
    }
    return (
        <>
            <LoaderIndicator loading={loading} />
            {isPopUpOpen == true && <SummaryPopUp closeAction={() => setIsPopUpOpen(false)} selectedClaim={selectedClaim} />}
            {
                tableData?.length > 0 ?
                    <div className="main-contain">
                        <div className="row align-item-center justify-content-start ">
                            <table className="table gemsAdmin">
                                <thead className='w-100'>
                                    <tr className='w-100'>
                                        {columns.map((col) => {
                                            return (
                                                <th width={col.width}><div className='thead'>{col.title}</div></th>
                                            )
                                        })}
                                    </tr>
                                </thead>
                                <tbody className='w-100'>
                                    {
                                        tableData.map((row, index) => {
                                            return (
                                                <tr>
                                                    <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                    <td width='10%'><div className={tdStyle}>{row.claim_id ? row.claim_id : 'NA'}</div></td>
                                                    <td width='10%'><div className={tdStyle}>{row.emp.Perno ? row.emp.Perno : 'NA'}</div></td>
                                                    <td width='10%'><div className={tdStyle}>{row.emp.Perno ? row.emp.CompName : 'NA'}</div></td>
                                                    {/* <td width='10%'><div className={tdStyle}>{row.from_date ? row.from_date :'NA'}</div></td>
                                                        <td width='10%'><div className={tdStyle}>{row.to_date ? row.to_date :'NA'}</div></td> */}
                                                    <td width='10%'><div className={tdStyle}>{row.applied_date ? moment(row.applied_date).format("DD-MM-YYYY") : 'NA'}</div></td>
                                                    <td width='10%'><div className={tdStyle}>{row.total_emp_claim_amount ? row.total_emp_claim_amount : 'NA'}</div></td>
                                                    <td width='10%'><div className={tdStyle}>{row.claim_status ? row.claim_status : 'NA'}</div></td>
                                                    <td width='5%'>
                                                        <span className='d-flex'>
                                                            <div
                                                                onClick={() => handleViewAction(index)}
                                                            >
                                                                <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                            </div>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className='mt-5 h-90-percent'>
                        <DataNotFound />
                    </div>
            }
        </>
    )
}

export default SummaryTable