import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut, operativeSessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { insert_sparsh_data_v2, headersForm } from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Dropdown from '../../../shared/components/Dropdown';
import Resizer from 'react-image-file-resizer';
import NotificationDetailsPopup from '../adminNotifications/NotificationDetailsPopup';
import { SparshandTvMsg, requestFailed, fileSize, } from '../../../../constants/Messages.js'
import { resizes, byteToMB } from '../../../../utils/Utils'
import CharacterCounter from '../../../shared/CharacterCounter';
class SparshDetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      title: '',
      announcementImage: '',
      sparshDescription: '',
      sparshHyperlink: '',
      sparshImgName: '',
      sparshthumbnailImgName: '',
      sparshData: '',
      sparshImgUrl: '',
      sparshthumbnailUrl: '',
      spashVideoUrl: '',
      heightAndWidth: [],
      warningPopup: false,
      eventData: [],
      file: null,
      sparshTypeValue: '',
      videoError: false,
      thumbnailerror: false,
      errorMessage: '',
      uploadedtypeincorrect: false,
      uploadedtypethumbnailincorrect: false
    }
  }

  validate = (event) => {
    event.preventDefault();
    if (this.state.title === '') {
      toast.warning(SparshandTvMsg.tittleWarning, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.fileType === "video" && this.state.thumbnailerror == true) {
      toast.warning(SparshandTvMsg.finaluploadError, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.fileType === "video" && this.state.sparshthumbnailImgName === "") {
      toast.warning(SparshandTvMsg.thumbnailWarning, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.uploadedtypeincorrect == true) {
      toast.warning(SparshandTvMsg.finaluploadError, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.fileType !== "video" && this.state.sparshImgName === '') {
      toast.warning(SparshandTvMsg.selectfile, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.sparshDescription === '') {
      toast.warning(SparshandTvMsg.descriptionWarning, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else {
      this.submitSparshDetailsByAdmin()
      this.setState({
        loading: true
      })
    }
  }

  submitSparshDetailsByAdmin = async (event) => {
    const formData = new FormData();
    if (this.state.fileType === "video") {
      formData.append("file_upload", this.state.spashVideoUrl);
      formData.append("emp_mail", this.props.adminLoginData.adminData.user_email);
      formData.append("announcement_title", this.state.title);
      formData.append("announcement_description", this.state.sparshDescription);
      formData.append("image_name", this.state.sparshthumbnailImgName);
      formData.append("announcement_type", this.state.sparshTypeValue);
      formData.append("announcement_url", this.state.sparshHyperlink);
      formData.append("emp_id", this.props.adminLoginData.emp_id);
      formData.append("thumbnail", this.state.sparshImgUrl);
    } else {
      formData.append("file_upload", this.state.sparshImgUrl);
      formData.append("emp_mail", this.props.adminLoginData.adminData.user_email);
      formData.append("announcement_title", this.state.title);
      formData.append("announcement_description", this.state.sparshDescription);
      formData.append("image_name", this.state.sparshImgName);
      formData.append("announcement_type", this.state.sparshTypeValue);
      formData.append("announcement_url", this.state.sparshHyperlink);
      formData.append("emp_id", this.props.adminLoginData.emp_id);
    }

    try {
      fetchApiCall(insert_sparsh_data_v2, formData, headersForm, false, 'admin')
        .then(res => {
          operativeSessionOut(this.props, res, "admin");
          if (res.data.status_code === '200') {
            this.setState({
              loading: false
            })
            toast.success(res.data.message, {
              position: "bottom-center",
              hideProgressBar: true,
              className: 'toastSuccess'
            },
              this.props.allDismiss(),
              this.props.callSparsh(),
            )
            this.setState({ loading: false },
              () => this.props.tataSparshActive()
            )
          }
          else {
            this.setState({
              loading: false
            })
            toast.warning(res.data.message, {
              position: "bottom-center",
              hideProgressBar: true,
              className: 'toastWarn'
            })
            this.setState({ loading: false })
          }
        })
        .catch(err => {
          this.setState({ loading: false })
          toast.error(err.response.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastError'
          })
          this.setState({
            loading: false
          })
          console.log("submitTVDetailsByAdmin err:", err.response)
        })
    }
    catch (error) { console.log(error) }
  }

  uploadImg = (event, data, typeInput, accept) => {
    if (typeInput === "imageFile") {
      this.setState({
        sparshImgName: event ? event.name : '',
        warningPopup: false,
        fileType: this.state.fileType === "video" ? "video" : typeInput
      })
    }
    else if (typeInput === "thumbnail") {
      this.setState({
        sparshthumbnailImgName: event ? event.name : '',
        warningPopup: false,
        fileType: this.state.fileType === "video" ? "video" : typeInput
      })
    }
    else if (typeInput === "video") {
      this.setState({
        sparshthumbnailImgName: event ? event.name : '',
        warningPopup: false,
        fileType: "video"
      })
    }
    else {
      this.setState({
        sparshthumbnailImgName: '',
        sparshImgName: '',
        warningPopup: false,
        fileType: this.state.fileType === "video" ? "video" : ""
      })
    }
    if (event) {
      resizes(data, 1280, 720, 'PNG', 100, 0, 'file', 128, 72)
        .then((uri) => {
          this.setState({ sparshImgUrl: uri, thumbnailerror: false })
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  }

  fileSelectHandler = (typeInput, event, accept) => {
    if (this.state.fileType === "video" && accept === "firstinputfileld") {
      this.setState({
        fileType: typeInput
      })
    }
    console.log("Type Check:", event.target.files[0])
    console.log("event.target.files[0].size:", ((event.target.files[0].size / 1024) / 1024).toFixed(2))
    let maxAllowedSize = fileSize.imageSize;  // 2MB size
    if (event.target.files[0] != undefined) {   // if undefined means select and then cancle the the folder.
      if (event.target.files[0].type.split('/')[1] === "png" || event.target.files[0].type.split('/')[1] === "jpeg") {
        if (event.target.files[0].size <= maxAllowedSize) {
          const file = event.target.files[0];
          this.setState({
            eventData: file,
            uploadedtypeincorrect: false,
            uploadedtypethumbnailincorrect: false
          })
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = () => {
              this.uploadImg(this.state.eventData, file, typeInput, accept)
            }
          }
        }
        else {   // file size should be less than 2 mb
          toast.warn(SparshandTvMsg.imgSizeNote + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + " MB.", {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
          this.setState({
            sparshImgUrl: '',
            sparshImgName: '',
            uploadedtypeincorrect: this.state.fileType !== "video" ? true : false,
            thumbnailerror: this.state.fileType === "video" ? true : false,
            uploadedtypethumbnailincorrect: this.state.fileType === "video" ? true : false
          })
        }
      }
      else if (event.target.files[0].type.split('/')[1] === "mp4") {
        if (event.target.files[0].size > fileSize.videoSize) {  // greater than 100mb  (not allowed)  104857600
          this.setState({
            videoError: true,
            sparshImgUrl: '',
            spashVideoUrl: '',
            sparshImgName: '',
            fileType: '',
            uploadedtypeincorrect: true,
            errorMessage: SparshandTvMsg.videoSizeWarning,
          })
          toast.warning(SparshandTvMsg.videoSizeWarning + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + "MB.", {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
        }
        else {
          this.setState({
            spashVideoUrl: event.target.files[0] ? event.target.files[0] : '',
            sparshImgName: event.target.files[0] ? event.target.files[0].name : '',
            fileType: "video",
            uploadedtypeincorrect: false
          })
        }
      }
      else {
        // other than mp4
        toast.warn(SparshandTvMsg.unsupportedMediaforthumbnail, {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          sparshImgName: '',
          uploadedtypeincorrect: this.state.fileType !== "video" ? true : false,
          thumbnailerror: this.state.fileType === "video" ? true : false,
          uploadedtypethumbnailincorrect: this.state.fileType === "video" ? true : false
        })
      }
    }
    else if (event.target.files[0] == undefined) {  // file selected but cancel
      this.setState({
        sparshImgName: '',
        uploadedtypeincorrect: false,
        sparshthumbnailImgName: "",
      })
    }
    else {
      // not allow other file type
      toast.warn(SparshandTvMsg.unsupportedMediaforthumbnail, {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
      this.setState({
        sparshImgName: '',
        uploadedtypeincorrect: true
      })
    }
  }

  render() {
    return (
      <>
        <form className='todoModalBody w-100'
        // onSubmit={this.validate}
        >
          {
            this.state.loading &&
            <LoaderIndicator loading={this.state.loading} />
          }
          <div className='p-1 w-100 popup_scroller'>
            <div>
              <div>
                <div className='p-sub-heading ml-3'>{SparshandTvMsg.title}</div>
                <div className='p-placeholder-text mb-2'>
                  <input
                    className="form-control p-placeholder p-placeholder-admin px-2"
                    type="text"
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    value={this.state.title}
                    placeholder={"Enter the title"}
                    maxLength={120}
                  />
                </div>
                <div>
                  <div className='p-sub-heading ml-3'>{SparshandTvMsg.file}</div>
                  {!this.props.show &&
                    <div className='p-placeholder-text inputHeight'>
                      <input
                        className={this.state.uploadedtypeincorrect ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                        type="file"
                        accept="video/*,image/*"
                        onChange={(event) => this.fileSelectHandler("imageFile", event, "firstinputfileld")}
                      />
                    </div>
                  }
                  <div className='flex-direction-row'>
                    <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                    <div>
                      <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction1}</div>
                      <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction2}</div>
                      <div className='p-sub-heading ml-3 mt-2px mb-2'>{SparshandTvMsg.instruction3}</div>
                    </div>
                  </div>
                </div>
                {!this.props.show && (this.state.fileType === 'video') &&
                  <>
                    <div>
                      <div className='p-sub-heading ml-3'>{SparshandTvMsg.thumbnail}</div>
                      <div className='secondContainer align-item-center-noflex'>
                        <input
                          className={this.state.uploadedtypethumbnailincorrect ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                          type="file"
                          name='thumbnailUpload'
                          id="files"
                          accept="image/*"
                          title={this.state.sparshImgName}
                          placeholder={"Upload Video"}
                          readOnly={false}
                          onChange={(event) => this.fileSelectHandler("thumbnail", event, "thumbnailinputfileld")}
                        />
                      </div>
                      <div className='flex-direction-row'>
                        <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                        <div>
                          <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction1}</div>
                          <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction2}</div>
                          <div className='p-sub-heading ml-3 mt-2px mb-2'>{SparshandTvMsg.thumbnailInstruction3}</div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                <div className='p-sub-heading ml-3'>{SparshandTvMsg.description}</div>
                <div className='p-placeholder-text mb-2'>
                  <textarea
                    className="form-control p-placeholder px-3"
                    type="text"
                    maxLength={1000}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ sparshDescription: e.target.value })}
                    value={this.state.sparshDescription}
                    placeholder={"Enter the description"}
                  >
                  </textarea>
                  <CharacterCounter
                    currentCount={this.state.sparshDescription.length}
                    totalCount={1000}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='modal-footer btnBox justify-content-between'>
          <div className='justify-content-start'>
            <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
          </div>
          <div className='justify-content-around'>
            <div className='justify-content-start'>
              <input type='button'
                value='CANCEL'
                className='popup-back-btn popup-back-text'
                onClick={this.props.back}
              />
            </div>
            <div className='justify-content-start'>
              <input
                // type='button'
                type='submit'
                value='ADD'
                className='popup-confirm-btn'
                onClick={this.validate}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    adminLoginData: state.adminLoginState,
    keycloakData: state.keycloakState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminAction: bindActionCreators(AdminLoginAction, dispatch),
  };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(SparshDetailsPopup));