import React, { Component } from 'react';

class PageNotFound extends Component { 
    render() {
        return (
            <div style={{display:'flex', flexDirection: 'column', alignItems:'center',paddingTop: '20%'}}>
                <h1>404</h1>
                <h4>Page Not Found</h4>
            </div>
        )
    }
}

export default PageNotFound
