import React, { Component } from 'react'
import ApplyLeavecard from './ApplyLeavecard'
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import SuccessCard from '../../../../assets/images/applyLeave/Successcard.svg'
import WarningCard from '../../../../assets/images/applyLeave/Warning.svg'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as leaveActions from './LeaveAction';
import './LeaveScreen.css'
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import addAnotherLeaveImage from '../../../../assets/images/applyLeave/addAnotherLeave.svg'

const success = `Leave/ Regularization request validated succesfully.`
const success1 = ` Kindly click on “submit” to complete submission.`

class ApplyLeavePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      leaveRequests: [],
      newLeaveRequest: [],
      requestLeaveBody: [],
      showSubmit: false,
      isEdit: true,
      success: false,
      warning: false,
      index: 0,
      loading: false,
      totalDays: '',
      totalDay: [],
      isLWOPArray: [],
      uniqueQuotaIds: [],
      warningMessage: '',
      hideEnable: false,
      showFormEdit: true,

      is_service_Unavailable: false,  // service unavailble from backend
      document_Service_reason: '',
    }
  }

  componentDidMount() {
    this.addAnotherleave(false);
    this.clearStore();
    this.checkService();
  }

  clearStore() {
    this.props.leaveAction.clearData();
  }

  scrollToElement = () => {
    if (this.state.success) {
      document.getElementById("SuccessWarnMsg").scrollIntoView();
    }
  }

  addAnotherleave(isHide) {
    this.setState(prevState => ({
      success: false,
      warning: false,
      leaveRequests: [{}, ...prevState.leaveRequests],
      isVisible: true,
      showSubmit: false,
      hideEnable: isHide,
      showFormEdit: true,
    }));
    if (this.state.leaveRequests.length === 0) {
      this.setState({
        isVisible: false
      });
    }
  }

  removeLeave() {
    let actualData = this.state.leaveRequests.splice(1)
    this.setState({
      leaveRequests: actualData,
      showSubmit: true,
      showFormEdit: false,
      // this.state.success
    });

    if (this.state.leaveRequests.length === 0) {
      this.setState({
        showFormEdit: true
      });
    }

    if (this.state.leaveRequests.length === 1) {
      this.setState({
        success: true
      });
    }


    if (this.state.leaveRequests.length === 1 || this.state.leaveRequests.length === 0) {
      this.setState({
        isVisible: false
      });
    }
  }

  validateLeave() {
    let index = this.state.requestLeaveBody.length - 1

    if (!this.state.requestLeaveBody[index].leave_category_code) {
      this.setState({ warningMessage: 'Please select leave type', warning: true });
    } else if (this.state.requestLeaveBody[index].leave_category_code === '') {
      this.setState({ warningMessage: 'Please select leave type', warning: true });
    } else if (!this.state.requestLeaveBody[index].from_date) {
      this.setState({ warningMessage: 'Please select start date', warning: true });
    } else if (this.state.requestLeaveBody[index].from_date === '') {
      this.setState({ warningMessage: 'Please select start date', warning: true });
    } else if (!this.state.requestLeaveBody[index].to_date) {
      this.setState({ warningMessage: 'Please select end date', warning: true });
    } else if (this.state.requestLeaveBody[index].to_date === '') {
      this.setState({ warningMessage: 'Please select end date', warning: true });
    } else if (this.state.requestLeaveBody[index].from_date > this.state.requestLeaveBody[index].to_date) {
      this.setState({ warningMessage: 'Start date cannot exceed end date', warning: true });
    } else if (this.state.requestLeaveBody[index].leave_type_code !== 'FD' && (this.state.requestLeaveBody[index].from_date !== this.state.requestLeaveBody[index].to_date)) {
      this.setState({ warningMessage: 'You cannot apply leave for this present type on multiple days', warning: true });
    } else if (!this.state.requestLeaveBody[index].reason) {
      this.setState({ warningMessage: 'Please enter reason', warning: true });
    } else if (this.state.requestLeaveBody[index].reason === '') {
      this.setState({ warningMessage: 'Please enter reason', warning: true });
    } else if (!this.validateDate()) {
      this.setState({ warningMessage: 'You have already applied leave on selected date', warning: true });
    } else if (!this.validateFromDate(this.state.requestLeaveBody[index].from_date, this.state.requestLeaveBody[index].leave_category_code)) {
      this.setState({ warningMessage: 'You cannot apply sick leave on future date', warning: true });
    } else if (!this.validateToDate(this.state.requestLeaveBody[index].to_date, this.state.requestLeaveBody[index].leave_category_code)) {
      this.setState({ warningMessage: 'You cannot apply sick leave on future date', warning: true });
    } else {
      this.validateLeaveRequest();
    }
  }

  validateFromDate(tempFromDate, leaveCode) {
    let isValidate = false;
    let tempFrom = new Date(tempFromDate)
    var current = new Date();

    if (leaveCode === 'M0' && (tempFrom > current)) {
      console.log('future');
      return false;
    } else {
      console.log('else');
      isValidate = true;
    }

    if (isValidate) {
      return true;
    }

  }

  validateToDate(tempToDate, leaveCode) {
    let isValidate = false;
    let tempTo = new Date(tempToDate)
    var current = new Date();

    if (leaveCode === 'M0' && (tempTo > current)) {
      return false;
    } else {
      isValidate = true;
    }

    if (isValidate) {
      return true;
    }

  }
  validateDate() {

    if (this.state.requestLeaveBody.length === 1) {
      return true;
    }

    let length = this.state.requestLeaveBody.length;
    var countfd = 0;
    var counthd = 0;
    if (length > 1) {
      for (var i = length - 2; i >= 0; i--) {
        var last_from_date = this.state.requestLeaveBody[length - 1].from_date
        var last_to_date = this.state.requestLeaveBody[length - 1].to_date
        var lastleave_type_code = this.state.requestLeaveBody[length - 1].leave_type_code
        if (lastleave_type_code === 'FD') {
          if (((last_from_date >= this.state.requestLeaveBody[i].from_date && last_from_date <= this.state.requestLeaveBody[i].to_date) || (last_to_date >= this.state.requestLeaveBody[i].from_date && last_to_date <= this.state.requestLeaveBody[i].to_date)) && (this.state.requestLeaveBody[i].leave_type_code === 'FH' || this.state.requestLeaveBody[i].leave_type_code === 'SH')) {
            countfd = countfd + 1
          }
          else if (last_from_date >= this.state.requestLeaveBody[i].from_date && last_from_date <= this.state.requestLeaveBody[i].to_date && lastleave_type_code === this.state.requestLeaveBody[i].leave_type_code) {
            countfd = countfd + 1
          }
          else if (last_from_date <= this.state.requestLeaveBody[i].from_date && last_to_date >= this.state.requestLeaveBody[i].from_date && lastleave_type_code === this.state.requestLeaveBody[i].leave_type_code) {
            countfd = countfd + 1
          }
        }
        else if (last_from_date >= this.state.requestLeaveBody[i].from_date && last_to_date <= this.state.requestLeaveBody[i].to_date && (this.state.requestLeaveBody[i].leave_type_code === 'FD' || lastleave_type_code === this.state.requestLeaveBody[i].leave_type_code)) {
          counthd = counthd + 1
        }
      }
      if (countfd > 0 || counthd > 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }
  validateLeaveRequest = () => {
    this.setState({
      loading: true
    });
    const req = {
      emp_id: this.props.loginData.userData.Perno,
      data: this.state.requestLeaveBody,
      emp_company_code: this.props.loginData.userData.CompCode,
      manager_company_code: this.props.loginData.managerData.CompCode
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    fetchApiCall(Config.validateLeaveRequest, req, headers, false, '')
      .then(res => {
        sessionOut(this.props, res);
        if (res.data.status_code === '200') {
          this.setState({
            success: true,
            success: res.data.message,
            warning: false,
            loading: false,
            showSubmit: true,
            totalDays: res.data.total_days,
            showFormEdit: false,
          });
          // this.scrollToElement();
          this.props.leaveAction.getLeaveRequestTotalDays(res.data.total_days)
          this.state.totalDay.push(res.data.total_days);
          this.state.uniqueQuotaIds.push(res.data.unique_quota_id);
          this.state.isLWOPArray.push(res.data.is_lwop);

        } else {
          console.log(res.data.message)

          this.setState({
            loading: false,
            warning: true,
            warningMessage: res.data.message
          });
        }

      }).catch(err => {
        this.setState({
          loading: false
        });
        console.log(err)
      })
  }

  applyLeave = () => {
    this.setState({
      loading: true
    });
    this.state.totalDay.forEach((totalDay, index) => {
      this.state.requestLeaveBody[index]['total_days'] = totalDay;
    });
    this.state.uniqueQuotaIds.forEach((uniqueId, index) => {
      this.state.requestLeaveBody[index]['unique_quota_id'] = uniqueId;
    });
    this.state.isLWOPArray.forEach((islwop, index) => {
      this.state.requestLeaveBody[index]['is_lwop'] = islwop;
    });
    const req = {
      emp_id: this.props.loginData.userData.Perno,
      emp_name: this.props.loginData.userData.CompName,
      data: this.state.requestLeaveBody,
      pa: this.props.loginData.userData.PersArea,
      psa: this.props.loginData.userData.PSubarea,
      emp_company_code: this.props.loginData.userData.CompCode,
      manager_company_code: this.props.loginData.managerData.CompCode
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    fetchApiCall(Config.applyLeaveRequest, req, headers, false, '')
      .then(res => {
        sessionOut(this.props, res);
        console.log("applyLeave res:", res)
        if (res.data.status_code === '200' || res.data.status_code == 200) {
          this.setState({
            loading: false,
          }, () => this.props.closeapplyLeave());
          toast.success('Leave Applied Successfully', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          })
          setTimeout(() => { window.location.reload() }, 1000)
        } else {
          this.setState({
            loading: false
          }, () => this.props.closeapplyLeave());
          toast.warn('Leave Applied Failed', {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastError'
          })
        }
      }).catch(err => {
        this.setState({
          loading: false
        });
        console.log(err)
        toast.warn('Something went wrong', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastError'
        })
      })
  }

  checkService = () => {
    this.props.serviceData.availableServiceData.forEach((item, i) => {
      if (item.service_type == "add_another_leave" && item.is_active === false) {
        this.setState({
          is_service_Unavailable: true
        })
      }
    })
  }

  render() {
    return (
      <div className="modal popup-box  d-flex align-item-center justify-content-center" id="leaveRequestModal">
        <LoaderIndicator loading={this.state.loading} />
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable d-flex align-item-center justify-content-center">
          <div className="modal-content apply_leave_modal">
            <div className="modal-header">
              <h4 className="modal_title">Apply Leaves</h4>
            </div>
            <div className="modal-body leave-req-body">
              <div className="applyLeaveCard">
                {this.state.leaveRequests.map((listValue, index) => {
                  return (
                    <ApplyLeavecard
                      key={index}
                      hideCard={listValue.hideCard}
                      handleHideCard={(id, isHide) => this.handleHideCard(id, isHide)}
                      leaveRequestTypes={ApplyLeavePopup}
                      length={this.state.leaveRequests.length}
                      index={index}
                      isVisible={this.state.isVisible}
                      isEdit={this.state.isEdit}
                      totalDays={this.state.totalDays}
                      isSubmit={this.state.showSubmit}
                      leaveRequest={this.state.newLeaveRequest}
                      selected_date={this.props.selected_date ? this.props.selected_date : ''}
                      show={this.props.show}
                      isSaveAndAdd={() => this.addAnotherleave()}
                      removeLeave={() => this.removeLeave()}
                      saveLeaveRequest={(data) => this.setState({ requestLeaveBody: data })}
                      addAnotherleave={() => this.addAnotherleave(true)}
                      hideEnable={this.state.hideEnable}
                      showFormEdit1={this.state.showFormEdit}
                      successProp={this.state.success}
                    />
                  )
                })}
                {this.state.success &&
                  <>
                    {!this.state.is_service_Unavailable &&
                      <div className='row mt-4 justify-content-between'>
                        <div className='row'>
                        </div>
                        {/* <div className='row mb-2'>
                          <img
                            style={{ cursor: 'pointer' }}
                            src={addAnotherLeaveImage}
                            onClick={() => this.addAnotherleave(true)}
                          />
                        </div> */}
                      </div>
                    }
                  </>
                }
                {this.state.success ?
                  <div className='success-msg mb-5'>
                    <div className='row d-flex align-item-center'>
                      <div className='success-vertical-bar mr-1'></div>
                      <img src={SuccessCard} alt='' className='success-icon mt-2 mb-2' />
                      <div className='msg mt-2 mb-2 mx-1'>{success}<br /> {success1}</div>
                    </div>
                  </div>
                  : null
                }
                {this.state.warning ?
                  <div className='warning-msg mb-5'>
                    <div className='row d-flex align-item-center'>
                      <div className='row d-flex align-item-center'>
                        <div className='warning-vertical-bar mr-1'></div>
                        <img src={WarningCard} alt='' className='warning-icon mt-2 mb-2' />
                      </div>
                      <div className='col-md'>
                        <div className='warningMsg mt-2 mb-2 mx-1 row' style={{ zIndex: -1 }}>
                          {this.state.warningMessage}
                        </div>
                      </div>
                    </div>
                  </div>
                  : null
                }
              </div>
            </div>
            <div className='modal-footer btnBox'>
              <input type='button' onClick={() => this.props.closeapplyLeave()} value='CANCEL' className='cancelBtn' />
              {this.state.showSubmit ?
                <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.applyLeave()} />
                :
                <input type='button' value='VALIDATE' className='ml-2 validateBtn' onClick={() => this.validateLeave()} />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    keycloakData: state.keycloakState,
    serviceData: state.activePopupState
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    leaveAction: bindActionCreators(leaveActions, dispatch)
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ApplyLeavePopup);