import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import GEMSSpocScreen from './GEMSSpocScreen';
import { connect } from 'react-redux';
import { configStore } from "../../../../redux/ConfigStore";
import GEMSMaintenance from './maintainance/GEMSMaintenance';

const SPOCGEMs = `GEMS Project`
const GemsMaintainanceTab = `GEMS Maintenance`
class GEMSAdminHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isGemsTab: true,
            isGemsMaintenanceTab: false,
        }
    }

    toggleGems = () => {
        this.setState({ isGemsTab: true, isGemsMaintenanceTab: false })
    }

    toggleGemsMaintenance = () => {
        this.setState({ isGemsTab: false, isGemsMaintenanceTab: true })
    }

    render() {
        const companyName = configStore.getState().loginState.company.company_sht_txt
        return (
            <>
                <div className="row pb-0 pt-12px px-12px">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className='col-md-12 justify-content-center align-item-center px-0'>
                        <div className="col-md-12 card-new m-rem h-83vh">
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.isGemsTab ? "tab-active" : "tab-inactive"} onClick={this.toggleGems}>
                                    {SPOCGEMs}
                                </div>
                                <div className={this.state.isGemsMaintenanceTab ? "tab-active" : "tab-inactive"} onClick={this.toggleGemsMaintenance}>
                                    {GemsMaintainanceTab}
                                </div>
                            </div>
                            <div className='mt-3px'><hr /></div>
                            <div className='w-100'>
                                {this.state.isGemsTab &&
                                    <div>
                                        <GEMSSpocScreen />
                                    </div>}
                                {this.state.isGemsMaintenanceTab &&
                                    <div>
                                        <GEMSMaintenance />
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(GEMSAdminHomeScreen);