import React, { Component } from 'react'
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { headers, delete_sparsh_data } from '../../../../../constants/Config'
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import moment from 'moment';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import { toast } from 'react-toastify';
import { requestFailed } from '../../../../../constants/Messages'
class TatamotorsSparshDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sparshData: [],
            viewPopup: false,
            tempSparshData: [],
            showDelete: false,
            tempSparshID: ''
        }
    }

    deleteSparsh = () => {
        this.setState({
            loading: true,
            showDelete: false
        });

        let body = {
            is_active: false,
            announcement_id: this.state.tempSparshID
        }
        fetchApiCall(delete_sparsh_data, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.props.callSparsh()
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    onAction = (listValue, type) => {
        if (type === "view") {
            this.setState({
                viewPopup: true,
                tempSparshData: listValue
            })
        }
        else if (type === "delete") {
            this.setState({
                showDelete: true,
                tempSparshID: listValue.announcement_id
            })
        }
    }
    render() {
        console.log("this.props.sparshData:", this.props.sparshData);
        return (
            <div className=" pt-2 tvNotification-container" >
                <LoaderIndicator loading={this.state.loading} />
                {

                    this.props.sparshData.length != 0 ?
                        <>
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col" ><div className='thead w40px'>Sr. No</div></th>
                                        <th scope="col" ><div className='thead'>Title</div></th>
                                        <th scope="col" ><div className='thead'>Description</div></th>
                                        <th scope="col" ><div className='thead'>Attachment</div></th>
                                        <th scope="col" ><div className='thead'>Created Date</div></th>
                                        <th scope="col" className='text-align-center'><div className='thead'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.sparshData.map((listValue, index) => {
                                            return (
                                                <tr key={index} className=''>
                                                    <td><div className="td " >{index + 1}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.announcement_title ? listValue.announcement_title : ''}</div></td>
                                                    <td className='w-40'><div className="td auto-line-break" >{listValue.announcement_description ? listValue.announcement_description : ''}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.announcement_image ? listValue.announcement_image : ''}</div></td>
                                                    <td className='w-10'><div className="td auto-line-break" >{moment(listValue.created_datetime).format("DD-MM-YYYY")}</div></td>
                                                    <td className='d-flex'>
                                                        <div
                                                            onClick={() => this.onAction(listValue, 'view')}
                                                        >
                                                            <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                        </div>
                                                        <div
                                                            onClick={() => this.onAction(listValue, 'delete')}
                                                        >
                                                            <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                                <tbody>
                                <tr className='text-align-center'>
                                    <th colSpan='7' scope="col" className='w-5 text-align-center '>
                                        <div className='row text-align-center justify-content-center'>
                                            {
                                                this.props.currentPage != 1 ?
                                                    <div className='thead pr-0 pre-next pointer'
                                                        onClick={() => this.props.parentCallback("decrease")}
                                                    >
                                                        {" < "}</div>
                                                    :
                                                    <div className='thead pr-0 pre-next pointer' >{" < "}</div>
                                            }
                                            <div className='thead pr-0 pre-next purple'>{this.props.currentPage}</div>
                                            {
                                                this.props.total_page > this.props.currentPage ?
                                                    <div className='thead pr-0 pre-next pointer'
                                                        onClick={() => this.props.parentCallback("increase")}
                                                    >
                                                        {" > "}</div>
                                                    :
                                                    <div className='thead pr-0 pre-next pointer'>{" > "}</div>
                                            }
                                        </div>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                            {/* <div>
                                <label>Bootom</label>
                            </div> */}
                        </>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No sparsh have been added yet!</label>
                                </div>
                            </div>
                        </div>
                }
                {this.state.viewPopup &&
                    <NotificationDetailsPopup
                        type={"sparshDetails"}
                        title={"Tata Motors Sparsh Details"}
                        data={this.state.tempSparshData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {this.state.showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteSparsh'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Tata motors Sparsh ?'}
                        deleteSparsh={() => this.deleteSparsh()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        )
    }
}

export default TatamotorsSparshDashboard