export const BASE_WEB_URL_DEV = 'https://drishti-dev.tatamotors.com/';
// export const BASE_WEB_URL_DEV = 'http://localhost:3000/';
// 
//----Back end service URL---------------------------------------------------
// export const COMMON_BASE_URL_PROD = 'https://empappqa.tatamotors.com:5003/'  //BLueCollar backend API
export const COMMON_BASE_URL_PROD = 'https://empapidev.tatamotors.com'  //kong API
// export const COMMON_BASE_URL_PROD ='https://empappdev.tatamotors.com:6002'  //Back end API after route from kong

export const version = '5.12' //For last release update perspective only

//-----Central Service--------------------------------------------------
export const getServiceAccountToken = '/authentication/get_keycloak_service_account_token/'  //to get service account token only valid in company dropdown & slider image 
export const logout = '/authentication/logout_keycloak/'; //Logout urls
export const getEmployeeToken = '/authentication/get_employee_token/';  //login API
// export const getUserAndManagerDetail = '/authentication/get_user_and_manager_details/'
export const getUserAndManagerDetail = '/authentication/get_user_and_manager_details_employee/'
export const getCompanyNames = "/authentication/get_company_list/";
export const getCurrentYearHolidayList = '/authentication/get_current_year_holiday_list/';
export const syncEmployeeCetData = '/authentication/sync_employee_cet_data/';
export const syncAllEmployeeCetData = '/authentication/sync_all_employee_cet_data/';
export const updateGroupJoiningDetails = '/authentication/update_group_joining_details/';
export const getEmpModules = '/authorization/user_modules'
export const logEmpSearchActivity = '/authorization/search_activity'

//---Developer Tools ---------------------------------------------------
export const setToDeletedStatus = '/leave/leave_requests/set_to_deleted_status/';

//---CFM MODULES---------------------------------------------------
export const getSuccessFactorUserDetails = '/success_factor/get_success_factor_user_details/';
export const getEmployeeGoals = '/success_factor/get_employee_goals/';
export const addGoalComments = '/success_factor/add_goal_comments/';
export const getGoalComments = '/success_factor/get_goal_comments/';

export const getEmployeeCompetencies = '/success_factor/get_employee_competencies/'
export const addCompetenciesComment = '/success_factor/add_competencies_comments/'
export const getCompetenciesComments = '/success_factor/get_competency_comments/'

export const getPerformaceTimeline = '/success_factor/get_performance_timeline/'
export const getleadershipbehaviourdescription = '/success_factor/get_leadership_behaviour_description/'

export const success_factor_push_feedack = '/success_factor/send_feedback_request/'
export const nudgeForFeedbackRequest = '/success_factor/nudge_for_feedback_request/'

export const deleteManagerGoalComment = '/success_factor/delete_manager_goal_comment/'
export const updateManagerGoalComment = '/success_factor/update_manager_goal_comment/'

export const deleteManagerCompetencyComment = '/success_factor/delete_manager_competency_comment/'
export const updateManagerCompetencyComment = '/success_factor/update_manager_competency_comment/'

//CET refresh url
export const getLatestuserDetailsFromEdpCet = '/authentication/get_edp_employee_details/';
export const getEdpEmploymentDetails = '/cet_syncing/get_edp_employee_details/';
export const uploadEmployeeMonthlyPayslip = '/documents/cet_syncing/upload_employee_monthly_payslip/';
export const uploadEmployeeItWorksheet = '/documents/cet_syncing/upload_employee_it_worksheet/';

//CET admin url 
// export const getAdminLatestuserDetailsFromEdpCet = '/admin/authentication/get_edp_employee_details/';

//----Document module---------------------------------------------------
export const getSliderImages = '/documents/cet_syncing/get_dynamic_image/';
export const getServiceAvailabilty = '/documents/cet_syncing/get_service_availability/';
export const updateServiceStatus = '/documents/cet_syncing/update_service_status/';

export const getPayslipsAndItworksheet = '/documents/document/get_payslips_and_itworksheet_v1/'
export const getAdditionalDocumentsMonthly = '/documents/document/get_additional_documents_monthly/'
export const getOldDocumentsMonthly = '/documents/document/get_all_old_documents_monthly/'
// export const getAnnualCompDocument = '/documents/document/get_available_documents_annual/';
export const getForm16Documents = '/documents/document/get_form16_documents_v1/'
export const getOldDocumentsAnnual = '/documents/document/get_all_old_documents_annual/'

export const employeeRoleBasedAccess = '/documents/digital_signing/employee_role_based_acccess/'
export const employeeApplicableForExitForm = '/documents/document/employee_applicable_for_exit_form/'  //exit form applicable check
export const getAvailableLatestDocument = '/documents/document/get_available_latest_documents_v1/' //landing page
export const getMonthlyPayslipData = '/documents/document/get_document_v1/';
export const getOldMonthlyPayslipData = '/documents/document/get_old_document/';
export const getForm16Document = '/documents/document/get_available_payslips/';
export const getBonafideDetails = '/documents/document/get_bonafide_details/';
export const getBonafideReasons = '/documents/document/get_bonafide_reasons/';
export const getCurrentMasterDocumentCenter = '/documents/document/get_master_dropdown_for_document_center_v1/'
export const masterDataForOldDocuments = '/documents/document/master_data_for_old_documents_monthly/'
export const getActivePopup = '/documents/cet_syncing/get_pop_up_notifications/';
export const getEmployeeAdditionalDocuments = '/documents/document/get_employee_additional_documents/';
export const downloadAdditionalDocuments = '/documents/document/download_additional_documents/';

//----cet_syncing/get_pop_up_notifications---------------------------------------------------
export const getOLDDocMasterMonthly = '/documents/document/master_data_for_old_documents_monthly/'


//----Exit Interview Form---------------------------------------------------
// export const employeeApplicableForExitForm='/cet_syncing/get_user_and_manager_details_temp/'
export const generateEmployeeExitForm = '/documents/document/generate_employee_exit_form/'
export const separationForm = '/documents/document/employee_seperation_form/'

//TrustFund Details module urls---------------------------------------------------
export const getAnnualStatement = '/documents/document/get_annual_statement/';
export const getEmployeeApplicableAnnualStatement = '/documents/document/employee_applicable_annual_statement/'
//PassBook urls---------------------------------------------------
export const getPassBookDetails = '/documents/document/get_passbook_details/';

//Attendance marking module urls---------------------------------------------------
export const attendanceMarking = '/profile/attendance_marking/mark_attendance/';
export const checkAttendance = '/profile/attendance_marking/check_attendance/';

export const getEmployeeDetails = '/profile/my_profile/get_employee_details/';

export const getAadharDetails = '/profile/my_profile/get_aadhar_details/';
export const updateAadharDetails = '/profile/my_profile/update_aadhar_details/';

export const getPANDetails = '/profile/my_profile/get_pan_details/';
export const updatePANDetails = '/profile/my_profile/update_pan_details/';

export const getAddressDetails = '/profile/my_profile/get_address_details/';
export const updateAddressDetails = '/profile/my_profile/update_address_details/';

export const updatePassportDetails = '/profile/my_profile/update_passport_details/'
export const getPassportDetails = '/profile/my_profile/get_passport_details/'
export const getCountrydata = '/profile/my_profile/get_countries/'

export const getUANDetails = '/profile/my_profile/get_employee_uan/'
export const updateUANDetails = '/profile/my_profile/update_employee_uan/'

export const getPersonalDetails = '/profile/my_profile/get_personal_details/';
export const updatePersonalDetails = '/profile/my_profile/update_personal_details/';

export const getContactDetails = '/profile/my_profile/get_contact_details/';
export const updateContactDetails = '/profile/my_profile/update_contact_details/';

export const getBankDetails = '/profile/my_profile/get_bank_details/';
export const updateBankDetails = '/profile/my_profile/update_bank_details/';

export const getEducationalDetails = '/profile/my_profile/get_education_details/';
export const updateEducationalDetails = '/profile/my_profile/update_education_details/';
export const deleteEmployeeEducationalDetails = '/profile/my_profile/delete_employee_education/';

export const getEmploymentDetails = '/profile/my_profile/get_employment_details/';
export const updateEmploymentDetails = '/profile/my_profile/update_employment_details/';
export const deleteEmploymentDetails = '/profile/my_profile/previous_employement_delete/';

export const getDependentDetails = '/profile/my_profile/get_dependent_details/';
export const updateDependentDetails = '/profile/my_profile/update_dependent_details/';

export const getNominationDetails = '/profile/my_profile/get_nomination_details/';
export const updateNominationDetails = '/profile/my_profile/update_nomination_details/';

//---All dropdowns urls---------------------------------------------------
export const getCountryDropdown = '/profile/my_profile/get_countries/';
export const getStateDropdown = '/profile/my_profile/get_states/';
export const getDistrictDropdown = '/profile/my_profile/get_districts/';
export const getCityDropdown = '/profile/my_profile/get_cities/';
export const getBankNameDropdown = '/profile/my_profile/get_banks/';
export const getPaymentmethodDropdown = '/profile/my_profile/get_payment_methods/';
export const getCurrencyDropdown = '/profile/my_profile/get_currency/';
export const getMaritalStatus = '/profile/my_profile/get_marital_status/';
export const getSectorsDropdown = '/profile/my_profile/get_sectors_update/';
export const getDivisionDropdown = '/profile/my_profile/get_divisions_update/';
export const getCTCDropdown = '/profile/my_profile/get_ctc_update/';
export const getCompanyTypeDropdown = '/profile/my_profile/get_company_types/';
export const getCategoryCodeDropdown = '/profile/my_profile/get_employment_category/';
export const getReasonForLeaveDropdown = '/profile/my_profile/get_rfl/';
export const getFamilyMemberDropdown = '/profile/my_profile/get_family_members_update/';
export const getGenderDropdown = '/profile/my_profile/get_genders/';

export const getInstituteDropdown = '/profile/my_profile/get_institutes_update/';
export const getWeightageDropdown = '/profile/my_profile/get_weightage_update/';
export const getBranchDropdown = '/profile/my_profile/get_branches_update/';
export const getCourseModeDropdown = '/profile/my_profile/get_course_modes_update/';
export const getFinalGradeDropdown = '/profile/my_profile/get_final_grades/';
export const getExaminationPassedDropdown = '/profile/my_profile/get_examinations_update/';
export const getEducationEstimateDropdown = '/profile/my_profile/get_education_estimates_update/';

export const getDependentMasterDropdown = '/profile/my_profile/get_dependent_master_data/';
export const getEducationMasterDropdown = '/profile/my_profile/get_education_master_data/';
export const getEmploymentMasterDropdown = '/profile/my_profile/get_employment_master_data/';
export const getBankMasterDropdown = '/profile/my_profile/get_bank_master_data/';

//----Notifications urls---------------------------------------------------
export const getNotifications = '/profile/attendance_marking/get_notifications/';
export const getNotificationsById = '/profile/attendance_marking/get_notification_details/';
export const updateNotificationStatus = '/profile/attendance_marking/update_notification_status/'

//---Conflict of Interest module---------------------------------------------------
export const getCOIEmployeeDetails = '/profile/conflict_of_interest/get_employee_details/'
export const isDataPresent = '/profile/conflict_of_interest/is_data_present/'
export const submitCOIDetails = '/profile/conflict_of_interest/submit_coi_details/'
export const deleteCOIRequest = '/profile/conflict_of_interest/delete_coi_details/'

export const fetchEmployeeType = '/authentication/fetch_employee_type/'

//---dropdown---------------------------------------------------
export const getRelationshipType = '/profile/conflict_of_interest/get_relationship_options/'
export const getNatureOfInterest = '/profile/conflict_of_interest/get_natureofinterest_options/'

//---conflict of interest module for admin---------------------------------------------------
export const getRegion = '/profile/conflict_of_interest/get_region_master/'
export const getCOIEmpReport = '/profile/conflict_of_interest/get_coi_emp_report/'
export const getCOIPeriodReport = '/profile/conflict_of_interest/get_coi_period_report/'
export const getCOIPeriodReportByPagination = '/profile/conflict_of_interest/get_coi_period_report_by_pagination/'
export const getCOICompletionSummary = '/profile/conflict_of_interest/get_coi_completion_summary/'
export const getCOICompletionReport = '/profile/conflict_of_interest/get_coi_completion_report/'

//----Leave module urls---------------------------------------------------
export const getAvailedLeavesData = '/leave/leave_requests/get_availed_leaves_data_v1/'
export const getLeaveQuota = '/leave/leave_requests/get_leave_quota_v1/';
export const getLeaveRequests = '/leave/leave_requests/get_leave_requests/';
export const getLeaveRequestMasterData = '/leave/leave_requests/get_leave_requests_master/';
export const getPreviousYearLeaveMaster = '/leave/leave_requests/get_previous_year_Leave_master/';

export const getLeaveDetails = '/leave/leave_requests/get_leave_requests_details/';
export const getLeaveReason = '/leave/leave_requests/get_leave_details/';            //it is now not used because of reason mismatch
export const getEmployeeLeaveSummary = '/leave/leave_requests/get_employee_leave_summary/';
export const getReporteeAvailedLeavesData = '/leave/leave_requests/get_reportee_availed_leaves_data/'

//---Leave approval module urls---------------------------------------------------
export const getLeaveApproval = '/leave/leave_requests/get_leave_requests_approval/';
export const getPreviousYearLeaveRequest = '/leave/leave_requests/get_previous_year_leave_request/';
export const approveLeaveRequest = '/leave/leave_requests/leave_request_approval/';
export const getAttendanceEnquiry = '/leave/leave_requests/get_attendance_enquiry/'; //old
export const getAttendanceLeaveDetail = '/leave/leave_requests/get_attendance_leave_details/';
export const getApprovedLeaveDetail = '/leave/leave_requests/get_approved_leave_details/'; //added to get exact leave reason
export const generateAttendanceReports = '/leave/leave_requests/generate_attendance_reports/'; //added to generate attendance report

//---Leave request urls---------------------------------------------------
export const validateLeaveRequest = '/leave/leave_requests/validate_leave_request/';
export const applyLeaveRequest = '/leave/leave_requests/create_leave_request_edp/';
//------Previous leave urls-------------------------------------------------
export const createPreviousYearLeaveRequestEdp = '/leave/leave_requests/create_previous_year_leave_request_edp/';
export const validatePreviousLeaveRequest = '/leave/leave_requests/validate_previous_leave_request/';

//---Leave Active Year urls---------------------------------------------------
export const getActiveYear = '/leave/leave_requests/get_active_year/';

//---Previous year leave urls---------------------------------------------------
export const applyPrevYearLeave = '/leave/leave_requests/create_previous_year_leave_request_edp/';
// export const getPreviousYearLeaveRequest = '/leave/leave_requests/get_previous_year_leave_requests/';
export const getPrevYearLeaverequestmasterdata = '/leave/leave_requests/get_previous_year_leave_requests_master/';

// export const getPreviousYearLeaveRequestApproval = '/leave/leave_requests/get_previous_year_leave_request_approval/';
export const prevYearLeaveApprovalRequest = '/leave/leave_requests/previous_year_leave_request_approval/';

//---Delete leave urls---------------------------------------------------
export const deleteLeave = '/leave/leave_requests/delete_leave_request/';
export const deletePreviousYearLeaveRequest = '/leave/leave_requests/delete_previous_year_leave_request/';
export const deleteLeaveApproval = '/leave/leave_requests/delete_leave_request_approval/';

export const NudgeForLeave = "/leave/leave_requests/nudge_for_leave/"
export const filter_type_for_Notification = "/leave/leave_requests/get_inapp_notification_filter/"

//---e-Seaparation urls---------------------------------------------------
export const getExitFormDetails = '/e_separation/get_exit_form_details/'
export const addSeparationRequest = '/e_separation/add_separation_request/'
export const getEsepRequest = '/e_separation/get_esep_request/'
export const deleteEsepRequest = '/e_separation/delete_esep_request/'
export const demApproval = '/e_separation/dem_approval/'
export const bhrApproval = '/e_separation/bhr_approval/'
export const buhrApproval = '/e_separation/buhr_approval/'
export const getDemEsepApprovals = '/e_separation/get_dem_esep_approvals/'
export const getBhrEsepApprovals = '/e_separation/get_bhr_esep_approvals/'
export const getBuhrEsepApprovals = '/e_separation/get_buhr_esep_approvals/'
export const getNoticePayRecoveryOption = '/e_separation/get_notice_pay_recovery_option/'
//---e-Seaparation dropdown urls---------------------------------------------------
export const getResignationReasons = '/documents/document/get_resignation_reasons/'

export const addRejectionReason = '/e_separation/add_rejection_reason/'
export const updateRejectionReason = '/e_separation/update_rejection_reason/'
export const deleteRejectionReason = '/e_separation/delete_rejection_reason/'
export const getRejectionReason = '/e_separation/get_rejection_reason/'

//---e-separation admin urls---------------------------------------------------
export const getSeparationRequestByAdmin = '/e_separation/get_separation_request_by_admin/'
export const getGroup = '/e_separation/get_group/'
export const getLevel = '/e_separation/get_level/'
export const getNoticePayWaiverRequests = '/e_separation/get_notice_pay_waiver_requests/'
export const getErrorInPostingRequests = '/e_separation/get_error_in_posting_requests/'
export const getEmployeeExitFormByAdmin = '/documents/document/get_employee_exit_form_by_admin/'
export const getEsepRequestById = '/e_separation/get_esep_request_by_id/'
export const getEmployeeToDelegate = '/e_separation/get_employee_to_delegate/'
export const delegateEsepRequest = '/e_separation/delegate_esep_request/'
export const resolveEsepErrorInPosting = '/e_separation/resolve_esep_error_in_posting/'
export const revokeSeparationRequest = '/e_separation/revoke_separation_request/'
export const getRevocationReason = '/e_separation/get_revocation_reason/'

//---Tax Regime---------------------------------------------------
export const isApplicableForTaxRegime = '/tax_regime/is_applicable_for_tax_regime/';
export const fetchTaxRegimeData = '/tax_regime/fetch_tax_regime_data/';
export const applyForTaxRegime = '/tax_regime/apply_for_tax_regime/';
export const getTaxRegimeInstructions = '/tax_regime/get_tax_regime_instructions/';

//---Choice Pay Selection---------------------------------------------------
export const getChoicePayInstructions = '/payspace/choice_pay/get_choice_pay_instructions/';
export const getChoicePayHistory = '/payspace/choice_pay/get_choice_pay_history/';
export const employeeAccessForChoicePay = '/payspace/choice_pay/is_choice_pay_applicable/';
export const getChoicePayComponents = '/payspace/choice_pay/get_choice_pay_components/';
export const validateChoicePay = '/payspace/choice_pay/validate_choice_pay/';
export const submitChoicePayDetails = '/payspace/choice_pay/submit_choice_pay_details/';
export const getPayspaceVisibility = '/payspace/choice_pay/get_payspace_visibility/';

//---HRA and Conveyance Details---------------------------------------------------
export const getHraAndConveyanceDetails = '/payspace/choice_pay/get_hra_and_conveyance_details/';

//---Compensation Structure---------------------------------------------------
export const choicePayNaEmployee = '/authentication/choice_pay_na_employee/';
export const getCompensationStructureInstructions = '/payspace/choice_pay/get_compensation_structure_instructions/';
export const getCompensationStructure = '/payspace/choice_pay/get_compensation_structure/';
export const getCompensationStructurePdf = '/payspace/choice_pay/get_compensation_structure_pdf/';

// --------------------- local coneyance-----------------------------------
export const getApprovedConveyanceList = '/payspace/local_conveyance/'
export const getClaimSummary = '/payspace/local_conveyance/claim_summary'
export const getClaimComponents = '/payspace/local_conveyance/claim_components'
export const getClaimSummaryAttachmentFiles = '/payspace/local_conveyance/get_claim_summary_document'
export const getClaimAttachments = '/payspace/local_conveyance/claim_component_attachments'

// --------------------- clearance -------------------------------------------
export const getClearanceMaster = '/clearance/masters'
export const getClearanceRequest = '/clearance/agency/requests'
export const getClearanceAgencyDetails = '/clearance/clearance'
export const addClearanceRemark = 'clearance/add_clearance_remark'
export const clearanceAttachment = 'clearance/clearance-attachment'
export const updateClearance = 'clearance/clearance-update'
export const getClearanceStartRequests = 'clearance/pending-requests'
export const postStartClearanceAction = 'clearance'
export const employeeClearanceStatus = 'clearance/employee/requests'
export const nudgeForClearance = 'clearance/nudge_for_clearance/'
export const agencyWiseClearanceReport = 'clearance/report/agency'
export const downloadAgencyWise = 'clearance/'
export const fAndFSettlementReport = 'clearance/report/full-and-final-report'
export const isClearanceApplicable = 'clearance/is_applicable_for_clearance/'
export const Agentmaster = 'clearance/agent_master/'
export const AgentConfig = 'clearance/agent_config/'
export const DuplicateAgentConfig = 'clearance/duplicate_agent_config/'
export const AgencyMaster = 'clearance/agency_master/'
export const AgencyMasterV1 = 'clearance/agency_config_v1'
export const AgencyConfig = 'clearance/agency_config/'
export const EsgGrpMapping = 'clearance/esg_grp_mapping/'
export const getClearancePAList = 'clearance/get_pa_list/'
export const getClearancePSAList = 'clearance/get_psa_list/'
export const getGradeGrpList = 'clearance/get_grade_grp_list/'
export const getAgencyList = 'clearance/get_agency_list/'
export const getAgencySPOC = 'clearance/agency_spoc_config_list/'
export const checkAgencyAgentConfig = 'clearance/check_agent_agency_config/'
export const checkAgencyConfig = 'clearance/check_agency_config/'
export const clearanceRevoke = 'clearance/revoke'
export const employeeExithandbook = 'clearance/get_exit_form_handbook/'
export const getEmployeeAssetsDetails = 'clearance/get_asset_detail'

//----Transfer module---------------------------------------------------
export const is_employee_bhr = '/transfer/employee_transfer/is_employee_bhr/'
export const getCetDataTransfer = '/transfer/employee_transfer/get_cet_data_transfer/'
export const getPositionDataTransfer = '/transfer/employee_transfer/get_position_data_transfer/'
export const getPersonalSubAreaMasterOfTransfer = '/transfer/employee_transfer/get_personal_sub_area_master/'
export const getWsrSwippingLocationPaPsaMapping = '/transfer/employee_transfer/get_wsr_swiping_location_pa_psa_mapping/'
export const submitTransferRequest = '/transfer/employee_transfer/submit_transfer_request/'
export const getSubFunction1 = '/transfer/employee_transfer/get_sub_function_1/'
export const getSubFunction2 = '/transfer/employee_transfer/get_sub_function_2/'
export const getTransferDashboardData = '/transfer/employee_transfer/get_transfer_dashboard_data/'
export const getreceivedTransferRequest = '/transfer/employee_transfer/get_received_transfer_requests/'
export const getAppliedTransferRequest = '/transfer/employee_transfer/get_applied_transfer_requests/'
export const approveRejectTransferRequest = '/transfer/employee_transfer/approve_reject_transfer_requests/'
export const deleteTransferrequest = '/transfer/employee_transfer/delete_transfer_request/'
export const getReasonForTransfer = '/transfer/employee_transfer/get_reason_for_transfer/'

//----Admin Leave module---------------------------------------------------
export const getAdminLeaveRequest = '/admin/leave_support_service/get_admin_leave_request/'
export const leaveRequestByTimeOffice = '/admin/leave_support_service/get_leave_request_by_time_office/'
export const downloadLeaveRequestByTimeOffice = '/admin/leave_support_service/download_leave_request_by_time_office/'
export const leaveRequestDeleteByItsdAndTimeOffice = '/admin/leave_support_service/leave_delete_by_itsd_and_timeOffice/'
export const deleteLeaveRequestAdmin = '/leave/leave_requests/delete_leave_request_by_admin/'
export const exportToExcelErrorInPosting = '/leave/leave_requests/export_to_excel_error_in_posting/'
export const errorInDeletion = '/leave/leave_requests/error_in_deletion_force_run_v1/'
export const syncLeaves = '/leave/leave_requests/sync_leave_requests_sap/'

//---To get pa psa all data---------------------------------------------------
export const getPaPsaMaster = '/leave/leave_requests/get_pa_psa_master/'
export const getPersonalAreaMaster = '/leave/leave_requests/get_personal_area_master/'
export const getPersonalSubAreaMaster = '/leave/leave_requests/get_personal_sub_area_master/'

//---MSToDo---------------------------------------------------
export const getProfilePhotoDetails = '/ms_todo/get_profile_photo/';
export const getAllTODOTasks = '/ms_todo/get_all_tasks/'
export const createToDoTasks = '/ms_todo/create_task/'
export const getMSTodoMasterData = '/ms_todo/get_ms_todo_master_data/'
export const deleteMSTodoTasks = '/ms_todo/delete_task/'
export const updateMSTodoTasks = '/ms_todo/update_task/'
export const createAdminTask = '/ms_todo/create_admin_task/'

//----Get employee profile image---------------------------------------------------
export const get_bulk_profile_image = '/ms_todo/get_bulk_profile_image/'

//---For moments screen---------------------------------------------------
export const momentThatMatterPresent = '/moments_that_matter/is_moment_that_matter_present/';
export const getMomentFeedData = '/moments_that_matter/get_moment_feed_data/';
export const getMomentEmployeeReactionResponse = '/moments_that_matter/get_moment_employee_reaction_response/';
export const updateMomentUserReactionsResponse = '/moments_that_matter/update_moment_user_reactions_response/';
export const sendWishEmailNotification = '/moments_that_matter/send_wish_email_notification/';
export const send_wish = '/moments_that_matter/send_wish/';
export const getMyMilesStone = `/moments_that_matter/get_milestone/`;
export const getUpcomingMilesStone = `/moments_that_matter/get_upcoming_milestone/`

//---------------------------------------------------Admin---------------------------------------------------
//for announcement screen
export const get_announcement = '/admin/announcements/get_announcement_notification/';
export const update_announcement_user_responce = '/admin/announcements/update_announcement_user_response/';
export const get_announcement_employee_record = '/admin/announcements/get_announcement_employee_record/';
export const insert_announcement_by_admin = '/admin/announcements/insert_announcement_by_admin/';
export const deleteannouncementbyadmin = '/admin/announcements/delete_announcement_by_admin/';
export const getUpcomingEvents = '/admin/announcements/get_upcoming_events/';
export const insertUpcomingEvents = '/admin/announcements/insert_upcoming_events/';
export const deleteUpcomingEvents = '/admin/announcements/delete_upcoming_events/';
export const getLeadershipAnnouncement = '/admin/announcements/get_leadership_announcement/';
export const deleteLeadershipAnnouncement = '/admin/announcements/delete_leadership_announcement/';
export const insertLeadershipAnnouncement = '/admin/announcements/insert_leadership_announcement/';
export const getBuzzNews = '/admin/announcements/get_buzz_news/';
export const insertBuzzNews = '/admin/announcements/insert_buzz_news/';
export const deleteBuzzNews = '/admin/announcements/delete_buzz_news/';

export const getEmployeeIdByMail = '/authentication/get_employee_id_by_mail/'

//Admin portal urls
export const adminLogin = '/admin/document_support_service/admin_login/'
export const insertPopUpNotificationBychro = '/admin/document_support_service/pop_up_notification_image_upload_into_s3/'
export const deletePopupNotificationBychro = '/admin/document_support_service/delete_pop_notification/'
export const getDeactivatedPopupNotifications = '/admin/document_support_service/get_deactivated_pop_notification/'
export const insertDynamicSliderImageBychro = '/admin/document_support_service/slider_image_upload_into_s3/'
export const deleteSliderImage = '/admin/document_support_service/delete_slider_image/'

//Jigyasa Bot url
export const getJiygyasaBotUrl = '/authentication/get_jigyasa_bot_url/'

//Engagement admin dashboard
export const getEmployeeEngagementActivity = '/authentication/get_employee_engagement_activity/'

//push notification to all
export const bulkUploadPushNotification = '/admin/document_support_service/approved_push_notification_by_admin/'
export const bulkInsertPushNotification = '/admin/document_support_service/bulk_insert_push_notification/'
export const GetPushNotification = '/admin/document_support_service/get_push_notification/'
//employee exit form Admin module
export const getEmployeeExitForm = '/admin/document_support_service/get_employee_exit_form/'
export const downloadEmployeeExitForm = '/admin/document_support_service/download_employee_exit_form/'

export const getTransferRequestCount = '/admin/document_support_service/get_tranfer_request_count/'
export const getEmployeeTransferRequest = '/admin/document_support_service/get_employee_transfer_request/'
export const approveEmployeeTransferRequest = '/admin/document_support_service/approve_employee_transfer_request/'

//Service/Reliving letter module at Admin
export const getServiceCertDetail = '/admin/document_support_service/get_service_cert_details/'
export const getRelivingLetterDetail = '/admin/document_support_service/get_relieving_letter_details/'

//Service/Reliving letter module at Admin
export const getDirectReporteeData = '/leave/leave_requests/get_reportees_planned_leaves_details/'

export const InserPushNotification = "/admin/document_support_service/insert_push_notification_by_admin/"
//MS-ToDo modules for Admin
export const storeAdminTask = '/ms_todo/store_admin_task/'
export const getAdminTask = '/ms_todo/get_admin_task/'

// Learning Architecture
export const getcurrent_role = '/learning_arch/current_role/'
export const ujr_search = '/learning_arch/ujr_search/'
export const guided_search = '/learning_arch/guided_search/'
export const get_learning_arch_master_data = '/learning_arch/get_learning_arch_master_data/'
export const get_company = '/learning_arch/get_company/'
export const get_function = '/learning_arch/get_function/'
export const get_sub_job_family_1 = '/learning_arch/get_sub_job_family_1/'
export const get_sub_job_family_2 = '/learning_arch/get_sub_job_family_2/'
export const get_level = '/learning_arch/get_level/'
export const get_level_ujr = '/learning_arch/get_level_ujr/'
export const get_ujr_search_text = '/learning_arch/get_ujr_search_text/'
export const get_ujr_text = '/learning_arch/get_ujr_text/'

//Admin Bluecollar employee registration
export const single_employee_registration = '/support/employee_registration/single_employee_registration/'
export const bulk_employee_registration = '/support/employee_registration/bulk_employee_registration/'
export const get_registered_employee_details = '/support/employee_registration/get_registered_employee_details/'
export const update_registered_employee_details = '/support/employee_registration/update_registered_employee_details/'
export const get_bulk_upload_details = '/support/employee_registration/get_bulk_ulpoad_details/'


// Admin BlueCollar Sparsh for admin screen 
export const get_sparsh_data = '/support/announcements/get_announcement_notification/'
export const insert_sparsh_data = '/support/announcements/insert_announcement_by_admin/'
export const insert_sparsh_data_v2 = '/support/announcements/insert_announcement_by_admin_v2/'
export const get_tata_motors_television_master_dropdown = '/support/tatamotors_tv/get_tata_motors_television_master_dropdown/'
export const delete_sparsh_data = '/support/announcements/delete_announcement_by_admin/'

// Admin bluecollar TV for admin screen
export const insert_tata_motors_tv_video_by_admin = '/support/tatamotors_tv/insert_tata_motors_tv_video_by_admin/'
export const get_tata_motors_tv_notification = '/support/tatamotors_tv/get_tata_motors_tv_notification/'
export const delete_tata_motors_tv_video_by_admin = '/support/tatamotors_tv/delete_tata_motors_tv_video_by_admin/'
export const getMasterTvDropdown = "/support/tatamotors_tv/get_tata_motors_television_master_dropdown/"

//-----Gems modules-------------------------------------------------------------
export const testimony = "/gems/testimony"
export const testimonyEmploye = "/gems/testimony/employee/"
export const landing_images = "/gems/landing_images"
export const get_project_status = "/gems/project/"
export const delete_objectives = "/gems/project/"
export const update_selected_status = "/gems/project/"
export const project = "/gems/project"
export const mtr_fr = "/gems/project/"
export const mtr_fr_objective = '/gems/project/'
export const objectives = '/objectives'
export const rejReason = '/gems/rej_reason'
//---Gems master--------
export const area = "/gems/area"
export const org_unit = "/gems/org_unit"
export const duration = "/gems/duration"
export const future_status = "/gems/project/"

export const applicant_instructions = "/gems/applicant_instructions"
export const pg_instructions = "/gems/pg_instructions"
export const pg_supervisors = "/gems/supervisors/"


// SuggetionSCheme Suggesrtor
export const suggestionCount = "/suggestion/suggestion_scheme/suggestion_count"
export const memberDetails = "/suggestion/suggestion_scheme/edp_employee_details/"
export const SuggestionHistory = "/suggestion/suggestion_scheme/suggestion_by_id/"
export const masterStatus = "/suggestion/suggestion_scheme/master/status"
export const SuggestionList = "/suggestion/suggestion_scheme/suggestion_list_details"
export const addSuggestion = "/suggestion/suggestion_scheme/suggestion_request"
export const schemeType = "/suggestion/suggestion_scheme/master/scheme_type"
export const get_categorydetails = "/suggestion/suggestion_scheme/master/category"
export const get_divisiondetails = "/suggestion/suggestion_scheme/master/division"
export const get_agency_detail = "/suggestion/suggestion_scheme/master/agency"
export const get_schemeList = "/suggestion/suggestion_scheme/master/scheme"
export const get_document = "/suggestion/suggestion_scheme/document_details"
export const upload_document = "/suggestion/suggestion_scheme/suggestion_document"
export const get_UploadDocument = "/suggestion/suggestion_scheme/document_url"
export const getDocument = "/suggestion/suggestion_scheme/document_url"
export const editSuggestion = "/suggestion/suggestion_scheme/suggestion-update/"
export const deleteDocument = "/suggestion/suggestion_scheme/suggestion_document"
export const evaluatorUpdate = "/suggestion/suggestion_scheme/evaluator_update"



// --------------------- Suggestions ----------------------------------------
export const suggestionSchemeBaseUrl = '/suggestion/suggestion_scheme/'

// plant update API 
export const plantUpdates = '/support/plant_updates/'
export const plantUpdateTypes = '/support/plant_updates/types'
export const adminPlants = '/support/plant_updates/admin/plants'

// gems admin API
export const gems_admin = '/gems/admin'

//----App Center config----------------
export const appCenterConfig = '/admin/document_support_service/app_center_link'

//---------------Common header--------------------------------------------------
export const headers = {
    'Content-Type': 'application/json',
}

export const headersForm = {
    "Content-Type": "multipart/form-data"
}
//---OT----------------------------------------------------------------
export const getOtDetails = '/overtime/overtime/get_ot_details_supervisor/'
export const getOtRequestManager = '/overtime/overtime/get_ot_request_manager/'
export const bookOt = '/overtime/overtime/submit_ot_booking/'
export const validateBookOt = '/overtime/overtime/validate_ot_booking/'
export const validateApproveOt = '/overtime/overtime/validate_ot_approve_reject/'
export const checkIsEmployeeValid = '/overtime/overtime/get_basic_edp_details/'
// export const getOTApproveReject = '/overtime/overtime/ot_approve_reject/'
export const getOTApproveReject = '/overtime/overtime/submit_ot_approve_reject/'
export const getOTRole = "/overtime/overtime/check_is_supervisor_manager/"
export const validateEmpIdAndOtDate = '/overtime/overtime/validate_regularize_ot/'
export const insertRegularizeOt = '/overtime/overtime/insert_regularize_ot/'
export const getOTDetailsAdmin = '/overtime/overtime/get_ot_details_admin/'
export const getOTRequestAdmin = "/overtime/overtime/get_ot_request_for_admin/"

//-------------------------------------------------------------------------------
//esep form key from email
//for staging
export const ESEP_VERIFY_KEY = "e6T78ipN8udWrn4AaHyG582MwTtYyQGK"

//for production
// export const ESEP_VERIFY_KEY = "51VzpldjD3NpPUmtBht5aHEDh4SHvuBO"
//-------------------------------------------------------------------------------

// Company
export const TMCV = "0100"
export const TMPVL = "0550"
export const TPEML = "0650"
export const TMLBSL = "0300"

export const TMCV_PATH = "/cv"
export const TMPVL_PATH = "/pv"
export const TPEML_PATH = "/ev"
export const TMLBSL_PATH = "/tmlbsl"

// ---------------------------------------- Trainee Leave --------------------------
export const trl_getLeaveRequestMasterData = '/operativesLeave/trainee_leaves/get_leave_requests_master/';
export const trl_getLeaveApproval = '/operativesLeave/trainee_leaves/get_leave_requests_approval/';
export const trl_approveLeaveRequest = '/operativesLeave/trainee_leaves/leave_request_approval/';
export const trl_deleteLeaveApproval = '/operativesLeave/trainee_leaves/delete_leave_request_approval/';
export const trl_exportLeaveRequestsApproval = '/operativesLeave/trainee_leaves/export_leave_requests_approval/';