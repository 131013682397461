import React, { Component } from 'react';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import plus from '../../../../assets/drishti_images/manager/landing/plus.svg';
import '../../employeeScreens/profile/ProfileScreen.css';
import { BASE_WEB_URL_DEV, get_sparsh_data, headers, get_tata_motors_tv_notification } from '../../../../constants/Config';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { fetchApiCall, sessionOut, operativeSessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import moment from 'moment';
import { admin_blueCollar_notification_type, admin_notification_type, toaster } from '../../../../utils/Utils';
import { currentDate } from '../../../../utils/Utils';
import AnnouncementDashboard from '../adminNotifications/dashboard/AnnouncementDashboard';
import PushNotificationDashboard from '../adminNotifications/dashboard/PushNotificationDashboard';
import ScreenPopupDashboard from '../adminNotifications/dashboard/ScreenPopupDashboard';
import SliderImageDashboard from '../adminNotifications/dashboard/SliderImageDashboard';
import TataBuzzDashboard from '../adminNotifications/dashboard/TataBuzzDashboard';
import LeadershipAnnouncementDashboard from '../adminNotifications/dashboard/LeadershipAnnouncementDashboard';
import UpcomingOccasionsDashboard from '../adminNotifications/dashboard/UpcomingOccasionsDashboard';
import Dropdown from '../../../shared/components/Dropdown';
import AnnouncementNotification from './AnnouncementNotification';
import PushNotification from './PushNotification';
import ScreenPopupNotification from './ScreenPopupNotification';
import SliderImageNotification from './SliderImageNotification';
import UpcomingOccasions from './UpcomingOccasions';
import LeadershipBehavioursPopup from './LeadershipBehavioursPopup';
import TataBuzzNews from './TataBuzzNews';
import SparshDetailsPopup from '../adminNotificationHome/SparshDetailsPopup';
import AddTatamotorsTVPopUp from './AddTatamotorsTVPopUp'
import TatamotorsTVDashboard from './dashboard/TatamotorsTVDashboard'
import TatamotorsSparshDashboard from './dashboard/TatamotorsSparshDashboard'
import TatamotorsPlantUpdateDashboard from './dashboard/TatamotorsPlantUpdatedashboard'
import { configStore } from "../../../../redux/ConfigStore";
import AddPlantUpdatePopUp from './AddPlantUpdatePopUp';
import { getAppCenterDetails } from '../../employeeScreens/gems/Gems.api.service';
import AppCenterConfiguration from './AppCenterConfiguration';
import AppCenterDashboard from './dashboard/AppCenterDashboard';

toast.configure();
const current_date = new Date();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

const blueCollarRoles = [
    "hr-notification-plant-spoc",
    "hr-reg-spoc",
    "opr-admin",
    "hr-notification-corp-admin"
];

const isWhiteCollar = (roles) => {
    return roles && roles.some(role => !blueCollarRoles.includes(role));
};

const isBlueCollar = (roles) => {
    return roles && roles.some(role => blueCollarRoles.includes(role));
};

class NotificationsHome extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            isAdmin: true,
            show: false,
            show1: false,
            isAnnouncementsActive: true,
            isPushNotificationsActive: false,
            isScreenPopupNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isUpcomingOccasionsActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false,
            isAppCenterConfig: false,
            screenTypeValue: 'Select notification type',
            screenTypeCode: '',
            typeData: [],
            page_number: 1,
            announcementData: [],
            upcomingEventsData: [],
            appCenterData: [],
            LeadershipAnnouncementData: [],
            tataBuzzNewsData: [],
            selectMonth: '',
            selectMonthCode: '',
            pushNotificationAdminData: [],
            screenPopUpDashboard: [],
            screenPopUpInactiveDashboard: [],
            sliderImageData: [],
            sparshData: [],
            tatamotorsTvData: [],
            total_page: 1,
            currentPage: 1,
        }
    }

    componentDidMount() {

        console.log('BlueCollar-------->', configStore.getState().adminLoginState.operativeRole);
        console.log('AdminStore-------->', configStore.getState().adminLoginState);

        const userRoles = configStore.getState().adminLoginState.adminRoles || [];
        const roles = configStore.getState().adminLoginState.operativeRole?.roles || [];

        const hasWhiteCollarRole = isWhiteCollar(userRoles);
        const hasBlueCollarRole = isBlueCollar(roles);

        let typeData = [];

        if (hasBlueCollarRole && hasWhiteCollarRole) {
            typeData = [...admin_blueCollar_notification_type(), ...admin_notification_type()];
        } else if (hasWhiteCollarRole) {
            typeData = admin_notification_type();
        } else if (hasBlueCollarRole) {
            typeData = admin_blueCollar_notification_type();
        }
        this.setState({ typeData });

        this.getAnnouncement()
        this.getPushNotification()
        this.getSliderImagesData1()
        this.getScreenPopupDashBoard()
        this.getDeactivetedPopupDashBoard()
        this.getUpcomingEvents(new Date().getMonth() + 1)
        this.getLeadershipAnnouncement()
        this.getBuzzNews()
        // this.getAppCenterConfig()
    }

    toggleannouncement = () => {
        this.setState({ isAnnouncementsActive: true, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    togglepushnotification = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: true, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    togglescreenpopupnotification = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: true, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    toggleadddyanamicimages = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: true, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false },
        )
    }
    toggleLeadershipAnnouncement = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: true, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    toggleTataBuzz = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: true, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    toggleUpcomingOccasions = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: true, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false })
    }
    toggleTataSparsh = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: true, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig: false },
            () => this.getSparshData(1)
        )
    }
    toggleTataMotorsTV = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: true, isTataMotorsPlantUpdate: false, isAppCenterConfig: false },
            () => this.getTataMotorsTvNotification(1)
        )
    }
    toggleTataMotorsPlantUpdate = () => {
        this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: true, isAppCenterConfig: false })
    }
    // toggleAppCenterConfig = () => {
    //     this.setState({ isAnnouncementsActive: false, isPushNotificationsActive: false, isScreenPopupNotificationsActive: false, isAddDynamicImageDetailsActive: false, isLeadershipAnnouncementActive: false, isTataBuzzActive: false, isUpcomingOccasionsActive: false, isTataSparshActive: false, isTataMotorsTVActive: false, isTataMotorsPlantUpdate: false, isAppCenterConfig : true })
    // }

    changeNotificationType = (data) => {
        this.setState({
            screenTypeCode: data.code,
            screenTypeValue: data.value,
        })
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    addNotification = () => {
        this.setState({
            show: !this.state.show
        })
    }

    onDismiss = () => {
        this.setState({
            show: false,
        })
    }

    announcementsActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: true,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
    }

    pushNotificationsActive = () => {
        this.setState({
            isPushNotificationsActive: true,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
    }

    screenPopupNotificationsActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: true,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
    }

    addDynamicImageDetailsActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: true,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
        this.getSliderImagesData1()
    }

    addleadershipAnnouncementActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: true,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
        this.getLeadershipAnnouncement()
    }

    addTataBuzzNewsActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: true,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
        this.getBuzzNews()
    }

    upcomingOccasionActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: true,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        })
        this.getUpcomingEvents(new Date().getMonth() + 1)
    }

    appCenterConfig = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: true
        })
        // this.getAppCenterConfig()
    }

    tataSparshActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: true,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        },
            () => this.getSparshData(1)
        )
    }

    tataTVActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: true,
            isTataMotorsPlantUpdate: false, isAppCenterConfig: false
        },
            () => this.get_tata_motors_tv_notification()
        )
    }
    plantUpdateActive = () => {
        this.setState({
            isPushNotificationsActive: false,
            isAddDynamicImageDetailsActive: false,
            isScreenPopupNotificationsActive: false,
            isAnnouncementsActive: false,
            isUpcomingOccasionsActive: false,
            isLeadershipAnnouncementActive: false,
            isTataBuzzActive: false,
            isTataSparshActive: false,
            isTataMotorsTVActive: false,
            isTataMotorsPlantUpdate: true, isAppCenterConfig: false
        })
    }

    // updating state couple of times to rerender the component
    refreshPlantUpdate = () => {
        if (this.state.isTataMotorsPlantUpdate) {
            this.setState({ isTataMotorsPlantUpdate: false }, () => this.setState({ isTataMotorsPlantUpdate: true }))
        }
    }

    allDismiss = () => {
        this.setState({
            show: false,
            show1: false,
            screenTypeValue: 'Select notification type',
            screenTypeCode: ''
        })
    }

    back = () => {
        this.setState({
            show: true,
            show1: false,
            screenTypeValue: 'Select notification type',
            screenTypeCode: ''
        })
    }

    nextPopup = () => {
        this.setState({
            show: false,
            show1: true
        })
    }

    validate = () => {
        if (this.state.screenTypeCode === '') {
            toast.warning('Please select notification type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.nextPopup()
        }
    }

    //----------- Get Announcement API call-------------
    getAnnouncement = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            employee_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            announcement_type: 'All',
            page_number: this.state.page_number
        }
        fetchApiCall(Config.get_announcement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        announcementData: res.data.data,
                        loading: false,
                        datapresent: true,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        announcementData: [],
                        show: true,
                        datapresent: false,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    //----------- Get Upcoming Events API call-------------
    getUpcomingEvents = (monthCode) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_month: monthCode,
            event_year: new Date().getUTCFullYear(),
        }
        fetchApiCall(Config.getUpcomingEvents, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        upcomingEventsData: res.data.data.upcoming_occasions,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        upcomingEventsData: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    //----------- Get Upcoming Events API call-------------
    getLeadershipAnnouncement = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getLeadershipAnnouncement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        LeadershipAnnouncementData: res.data.data.announcment_records,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        LeadershipAnnouncementData: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    //----------- Get Get Tata Buzz News API call-------------
    getBuzzNews = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            action: "GET"
        }
        fetchApiCall(Config.getBuzzNews, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        tataBuzzNewsData: res.data.data.buzz_news,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        tataBuzzNewsData: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    //----------- Get Push Notification API call-------------
    getPushNotification = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            month: this.state.selectMonthCode === '' ? 'All' : this.state.selectMonthCode
        }
        fetchApiCall(Config.GetPushNotification, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        pushNotificationAdminData: res.data ? res.data.data : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        pushNotificationAdminData: [],
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    //----------- Get Screen-Popup Notification API call -------------
    getScreenPopupDashBoard = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            current_date: moment(current_date).format('YYYY-MM-DD'),
            is_admin: true
        }
        fetchApiCall(Config.getActivePopup, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        screenPopUpDashboard: res.data.data ? res.data.data : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        screenPopUpDashboard: [],
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getDeactivetedPopupDashBoard = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            is_active: false
        }
        fetchApiCall(Config.getDeactivatedPopupNotifications, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        screenPopUpInactiveDashboard: res.data.inactive_notification ? res.data.inactive_notification : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        screenPopUpInactiveDashboard: [],
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    //----------- Get Slider-log-in Image-------------
    getSliderImagesData1() {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'application/json', }
        let request = { 'current_date': currentDate() }
        fetchApiCall(Config.getSliderImages, request, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.isAdmin);
                if (res.data.status_code === '200') {
                    if (res.data.data.length !== 0) {
                        this.setState({ sliderImageData: res.data.data, loading: false })
                    }
                }
                else {
                    this.setState({ loading: false, sliderImageData: [] })
                }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    //----------- Get Sparsh Details --------------------------
    getSparshData = (pageNo) => {
        this.setState({
            loading: true,
        });

        let body = {
            announcement_type: 'All',
            employee_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            page_number: pageNo == undefined ? 1 : pageNo
        }
        fetchApiCall(get_sparsh_data, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                operativeSessionOut(this.props, res, 'admin');
                if (res.data.status_code === '200') {
                    this.setState({
                        sparshData: res.data.data,
                        loading: false,
                        currentPage: res.data.page_number,
                        total_page: res.data.total_page
                    })
                }
                else {
                    this.setState({
                        sparshData: [],
                        loading: false,
                        currentPage: 1,
                        total_page: 1
                    })
                }
            }).catch(err => {
                console.warn('getSparshData catch:', err)
                this.setState({
                    loading: false,
                    sparshData: [],
                    currentPage: 1,
                    total_page: 1
                });
            })
    }

    getTataMotorsTvNotification = (pageNo) => {
        this.setState({
            loading: true,
        });

        let body = {
            tm_video_type: 'All',
            employee_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            page_number: pageNo == undefined ? 1 : pageNo
        }
        fetchApiCall(get_tata_motors_tv_notification, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                operativeSessionOut(this.props, res, "admin");
                if (res.data.status_code === '200') {
                    this.setState({
                        tatamotorsTvData: res.data.data,
                        loading: false,
                        currentPage: res.data.data.page_number,
                        total_page: res.data.data.total_page
                    })
                }
                else {
                    this.setState({
                        tatamotorsTvData: [],
                        loading: false,
                        currentPage: 1,
                        total_page: 1
                    })
                }
            }).catch(err => {
                console.warn('getSparshData catch:', err)
                this.setState({
                    loading: false,
                    tatamotorsTvData: [],
                    currentPage: 1,
                    total_page: 1
                });
            })
    }

    //----------get App center Details API----------------------
    getAppCenterConfig = (id, pageNo) => {
        this.setState({ loading: true })
        const params = {
            page_number: 1
        }
        getAppCenterDetails(id, params, "admin")
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        appCenterData: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    toaster("", res.message ? res.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    appCenterData: []
                })
                toaster("warning", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    handlePagination = (childData) => {
        if (childData === 'decrease') {
            let pageNo = this.state.currentPage - 1
            this.getSparshData(pageNo)
        }
        else if (childData === 'increase') {
            let pageNo = this.state.currentPage + 1
            this.getSparshData(pageNo)
        }
    }

    tvhandlePagination = (childData) => {
        if (childData === 'decrease') {
            let pageNo = this.state.currentPage - 1
            this.getTataMotorsTvNotification(pageNo)
        }
        else if (childData === 'increase') {
            let pageNo = this.state.currentPage + 1
            this.getTataMotorsTvNotification(pageNo)
        }
    }

    render() {
        const roles = configStore.getState().adminLoginState.operativeRole?.roles || [];
        console.log('roles---->', roles);
        const userRoles = configStore.getState().adminLoginState?.adminRoles || [];
        console.log('userRoles---->', userRoles);

        const hasBlueCollarRole = isBlueCollar(roles);
        const hasWhiteCollarRole = isWhiteCollar(userRoles);
        console.log('hasBlueCollarRole---->', hasBlueCollarRole);
        console.log('hasWhiteCollarRole---->', hasWhiteCollarRole);
        return (
            <div className="row p-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0 pt-0">
                    <div className='justify-content-between align-item-center'>
                        <div className='row px-0 pt-0' >
                            <img src={overviewButton} onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className='row justify-content-end'>
                            <a
                                type="button"
                                className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-13rem'
                                onClick={() => this.addNotification()}
                            >
                                <img alt="plus" src={plus} className=" mx-1"></img>
                                ADD NOTIFICATION
                            </a>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='card-new col-md-12 mt-2'>
                            <div className='flex-direction-row scrollmenu'>
                                {hasWhiteCollarRole &&
                                    <>
                                        <div className={this.state.isAnnouncementsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleannouncement}>
                                            Announcements
                                        </div>
                                        <div className={this.state.isPushNotificationsActive ? "tab-active" : "tab-inactive"} onClick={this.togglepushnotification}>
                                            Push Notifications
                                        </div>
                                        <div className={this.state.isScreenPopupNotificationsActive ? "tab-active" : "tab-inactive"} onClick={this.togglescreenpopupnotification}>
                                            Screen Pop-up Notifications
                                        </div>
                                        <div className={this.state.isAddDynamicImageDetailsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleadddyanamicimages}>
                                            Log-In Images
                                        </div>
                                        <div className={this.state.isLeadershipAnnouncementActive ? "tab-active" : "tab-inactive"} onClick={this.toggleLeadershipAnnouncement}>
                                            Leadership Announcement
                                        </div>
                                        <div className={this.state.isTataBuzzActive ? "tab-active" : "tab-inactive"} onClick={this.toggleTataBuzz}>
                                            Tata Buzz News
                                        </div>
                                        <div className={this.state.isUpcomingOccasionsActive ? "tab-active" : "tab-inactive"} onClick={this.toggleUpcomingOccasions}>
                                            Upcoming Occasions
                                        </div>
                                        {/* <div className={this.state.isAppCenterConfig ? "tab-active" : "tab-inactive"} onClick={this.toggleAppCenterConfig}>
                                            App Center
                                        </div> */}
                                    </>
                                }
                                {hasBlueCollarRole &&
                                    <>
                                        <div className={this.state.isTataSparshActive ? "tab-active" : "tab-inactive"} onClick={this.toggleTataSparsh}>
                                            Tata Motors Sparsh
                                        </div>
                                        <div className={this.state.isTataMotorsTVActive ? "tab-active" : "tab-inactive"} onClick={this.toggleTataMotorsTV}>
                                            Tata Motors TV
                                        </div>
                                        <div className={this.state.isTataMotorsPlantUpdate ? "tab-active" : "tab-inactive"} onClick={this.toggleTataMotorsPlantUpdate}>
                                            Plant Update
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>

                            <div className='profile-inner-card'>
                                {this.state.isAnnouncementsActive ?
                                    <div>
                                        <AnnouncementDashboard
                                            loading={this.state.loading}
                                            announcementData1={this.state.announcementData}
                                            getAnnouncementCall={this.getAnnouncement}
                                        />
                                    </div> : null
                                }
                                {this.state.isPushNotificationsActive ?
                                    <div>
                                        <PushNotificationDashboard
                                            loading={this.state.loading}
                                            pushNotificationAdminData1={this.state.pushNotificationAdminData}
                                            getPushNotificationCall={this.getPushNotification}
                                        />
                                    </div> : null
                                }
                                {this.state.isScreenPopupNotificationsActive ?
                                    <div>
                                        <ScreenPopupDashboard
                                            loading={this.state.loading}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            screenPopUpDashboard1={this.state.screenPopUpDashboard}
                                            screenPopUpInactiveDashboard1={this.state.screenPopUpInactiveDashboard}
                                            getScreenPopupDashBoardCall={this.getScreenPopupDashBoard}
                                            getDeactivetedPopupDashBoardCall={this.getDeactivetedPopupDashBoard}
                                        />
                                    </div> : null
                                }
                                {this.state.isAddDynamicImageDetailsActive ?
                                    <div>
                                        <SliderImageDashboard
                                            loading={this.state.loading}
                                            addDynamicImageDetailsActive={this.addDynamicImageDetailsActive}
                                            sliderImageData1={this.state.sliderImageData}
                                        />
                                    </div> : null
                                }
                                {this.state.isLeadershipAnnouncementActive ?
                                    <div>
                                        <LeadershipAnnouncementDashboard
                                            loading={this.state.loading}
                                            announcementData1={this.state.LeadershipAnnouncementData}
                                            getLeadershipAnnouncementCall={this.getLeadershipAnnouncement}
                                        />
                                    </div> : null
                                }
                                {this.state.isTataBuzzActive ?
                                    <div>
                                        <TataBuzzDashboard
                                            loading={this.state.loading}
                                            announcementData1={this.state.tataBuzzNewsData}
                                            getTataBuzzNewsCall={this.getBuzzNews}
                                        />
                                    </div> : null
                                }
                                {this.state.isUpcomingOccasionsActive ?
                                    <div>
                                        <UpcomingOccasionsDashboard
                                            loading={this.state.loading}
                                            upcomingEventsData={this.state.upcomingEventsData}
                                            getUpcomingEventsCall={(monthCode) => this.getUpcomingEvents(monthCode)}
                                        />
                                    </div> : null
                                }
                                {this.state.isAppCenterConfig ?
                                    <div>
                                        <AppCenterDashboard
                                            loading={this.state.loading}
                                            appCenterData={this.state.appCenterData}
                                            getAppCenterConfigCall={() => this.getAppCenterConfig()}
                                        />
                                    </div> : null
                                }
                                {this.state.isTataSparshActive ?
                                    <div>
                                        <TatamotorsSparshDashboard
                                            loading={this.state.loading}
                                            sparshData={this.state.sparshData}
                                            callSparsh={this.getSparshData}
                                            currentPage={this.state.currentPage}
                                            total_page={this.state.total_page}
                                            parentCallback={this.handlePagination}

                                        />
                                    </div> : null
                                }
                                {
                                    this.state.isTataMotorsTVActive &&
                                    <div>
                                        <TatamotorsTVDashboard
                                            loading={this.state.loading}
                                            tatamotorsTvData={this.state.tatamotorsTvData}
                                            callTV={this.getTataMotorsTvNotification}
                                            currentPage={this.state.currentPage}
                                            total_page={this.state.total_page}
                                            parentCallback={this.tvhandlePagination}
                                        />
                                    </div>
                                }
                                {
                                    this.state.isTataMotorsPlantUpdate &&
                                    <div>
                                        <TatamotorsPlantUpdateDashboard
                                            loading={this.state.loading}
                                            currentPage={this.state.currentPage}
                                            total_page={this.state.total_page}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.show1 ?
                        <div className="modal popup-box" id="addTodoModal">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                {this.state.screenTypeCode === 'announcement' &&
                                    //-----------------Announcement Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Announcement'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <AnnouncementNotification
                                            announcementsActive={this.announcementsActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            getAnnouncementCall={this.getAnnouncement}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'pushNotification' &&
                                    //-----------------Push Notification Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Push Notification'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <PushNotification
                                            pushNotificationsActive={this.pushNotificationsActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            getPushNotificationCall={this.getPushNotification}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'screenPopUp' &&
                                    //-----------------Screen Notification Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Screen Pop-Up'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <ScreenPopupNotification
                                            screenPopupNotificationsActive={this.screenPopupNotificationsActive}
                                            back={this.back}
                                            Action={"add"}
                                            allDismiss={this.allDismiss}
                                            getScreenPopupDashBoardCall={this.getScreenPopupDashBoard}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'logInPage' &&
                                    //-----------------Slider Image Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Log-In Page'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <SliderImageNotification
                                            addDynamicImageDetailsActive={this.addDynamicImageDetailsActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'leadershipAnnouncement' &&
                                    //-----------------Slider Image Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Leadership Announcement'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <LeadershipBehavioursPopup
                                            leadershipAnnouncementActive={this.addleadershipAnnouncementActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'tataBuzzNews' &&
                                    //-----------------Slider Image Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Tata Buzz News'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <TataBuzzNews
                                            addTataBuzzNewsActive={this.addTataBuzzNewsActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'upcomingOccasions' &&
                                    //-----------------Slider Image Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Upcoming Occasions'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <UpcomingOccasions
                                            upcomingOccasionActive={this.upcomingOccasionActive}
                                            allDismiss={this.allDismiss}
                                            back={this.back}
                                            getUpcomingEventsCall={(monthCode) => this.getUpcomingEvents(monthCode)}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'appCenter' &&
                                    //-----------------App Center Config Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'App Center'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <AppCenterConfiguration
                                            appCenterConfig={this.appCenterConfig}
                                            allDismiss={this.allDismiss}
                                            back={this.back}
                                            getAppCenterConfigCall={() => this.getAppCenterConfig()}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'tataMotorsSparsh' &&
                                    //-----------------tatamotors sparsh Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Tata Motors Sparsh'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <SparshDetailsPopup
                                            tataSparshActive={this.tataSparshActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            callSparsh={this.getSparshData}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'tataMotorsTV' &&
                                    //-----------------Tatamotors TV Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Tata Motors TV'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <AddTatamotorsTVPopUp
                                            tataTVActive={this.tataTVActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            callTV={this.getTataMotorsTvNotification}
                                        />
                                    </div>
                                }
                                {this.state.screenTypeCode === 'plantUpdate' &&
                                    //-----------------Tatamotors Plant Update Popup--------------------------
                                    <div className="modal-content toDoModal bg-light">
                                        <div className="modal-header edit-cardtitle">
                                            <h4 className="modal_title">{'Plant Update'}</h4>
                                            <button type="button" className="close" onClick={this.allDismiss}>&times;</button>
                                        </div>
                                        <AddPlantUpdatePopUp
                                            plantUpdateActive={this.plantUpdateActive}
                                            back={this.back}
                                            allDismiss={this.allDismiss}
                                            callPlantUpdate={this.refreshPlantUpdate}
                                        />
                                    </div>
                                }
                            </div>
                        </div> : null
                }

                {
                    this.state.show ?
                        <div className="modal popup-box" id="addTodoModal">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content toDoModal bg-light">
                                    <div className="modal-header edit-cardtitle">
                                        <h4 className="modal_title">{'Add Notification'}</h4>
                                        <button type="button" className="close" onClick={this.onDismiss}>&times;</button>
                                    </div>
                                    <div className="mb-5">
                                        <form className='todoModalBody'>
                                            <label className='form-label ml-2'>Notification Type</label>
                                            <Dropdown
                                                data={this.state.typeData}
                                                selectedValue={(data) => this.changeNotificationType(data)}
                                                value={this.state.screenTypeValue}
                                                render={'value'}
                                                height={'NotificationHeight'}
                                            />
                                        </form>
                                    </div>
                                    <div className='modal-footer btnBox justify-content-between'>
                                        <div className='justify-content-start'>
                                            <div className='popup-next-screen-txt-light'>Step<span className='popup-next-screen-txt-bold'>1</span>/2</div>
                                        </div>
                                        <div className='justify-content-start'>
                                            <input type='button'
                                                value='NEXT'
                                                className='ml-2 validateBtn'
                                                onClick={this.validate} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                }
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}
export default connect(mapStatesToProps)(NotificationsHome);