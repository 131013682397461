import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import DependentCards from '../profile/DependentCards';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import NotePopup from './NotePopUp';

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime'

class DependentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            empId: '',
            dependentData: [],
            countryData: [],
            familyMemberData: [],
            genderData: [],
            popupVisibility: false,
            isVisible: false,
            showData: false,
            loading: false,
            newDependent: [],
            newDependentData: [],
            multipleDependentData: [],
            show: false,
            isSaveAndAdd: false,
            tempDependentDetails: [],
            isActive: false,
            message: '',
            notePopup: false,
        }
    }

    componentDidMount() {
        this.getDependentMasterData();
        this.getDependentDetails(this.props.loginData.userData.Perno);
    }

    getDependentMasterData = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getDependentMasterDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        countryData: result.data.data.country ? result.data.data.country : [],
                        genderData: result.data.data.gender ? result.data.data.gender : [],
                        familyMemberData: result.data.data.family_member ? result.data.data.family_member : []
                    });
                }
            }).catch(err => {
                console.warn('Dependent masterdata err', err)
            })
    }

    getDependentDetails(employeeId) {
        this.setState({
            loading: true,
            isEdit: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getDependentDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    this.setState({
                        dependentData: res.data.dependent_details,
                        newDependentData: res.data.dependent_details,
                        loading: false,
                        showData: true
                    });
                } else {
                    this.setState({
                        loading: false,
                        newDependentData: []
                    });
                }
            }).catch(err => {
                console.log('Dependent err', err)
                this.setState({
                    loading: false
                });
            })
    }

    saveDependentDetails = (savingData) => {
        this.setState({
            loading: true
        });
        const req = {
            data: [{
                emp_id: this.props.loginData.userData.Perno,
                family_member: savingData.family_member ? savingData.family_member : '',
                family_member_code: savingData.family_member_code ? savingData.family_member_code : '',
                first_name: savingData.first_name ? savingData.first_name : '',
                last_name: savingData.last_name ? savingData.last_name : '',
                birth_name: savingData.birth_name ? savingData.birth_name : '',
                gender: savingData.gender ? savingData.gender : '',
                gender_code: savingData.gender_code ? savingData.gender_code : '',
                date_of_birth: savingData.date_of_birth ? savingData.date_of_birth : '',
                birth_place: savingData.birth_place ? savingData.birth_place : '',
                city_text: savingData.city ? savingData.city : '',
                city: savingData.city ? savingData.city : '',
                nationality: savingData.nationality ? savingData.nationality : '',
                nationality_code: savingData.nationality_code ? savingData.nationality_code : '',
            }]
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updateDependentDetails, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.statusCode === '200') {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getDependentDetails(this.props.loginData.userData.Perno);
                } else {
                    this.setState({
                        loading: false,
                        newDependent: {},
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    setEdit = () => {
        this.setState({
            isEdit: true
        })
    }

    unSetEdit = () => {
        this.setState({ isEdit: false }, () => this.getDependentDetails(this.props.loginData.userData.Perno)
        );
    }

    toggleOpenDetail = () => {
        this.setState({
            isEdit: true,
        })
    }
    close = () => {
        this.setState({
            notePopup: false
        })
    }
    render() {
        return (
            <div className="row">
                <LoaderIndicator loading={this.state.loading} />
                {!this.state.isEdit &&
                    <div className=' row col-md-12 d-flex justify-content-end' style={{ 'alignItems': 'end' }}>
                        <div
                            type='button'
                            className='bank-addoption-text mx-4 mt-3 mb-0'
                            onClick={() => this.toggleOpenDetail()}
                        > + Add New Dependent Detail
                        </div>
                        {/* <img
                            alt="infoicon"
                            src={infoicon}
                            className="info_icon_dependent"
                            onClick={() => this.setState({
                                notePopup: true
                            })}
                        ></img> */}
                    </div>
                }
                <div className="col-md-12">
                    {this.state.showData && !this.state.isEdit ?
                        <div>
                            {!this.state.newDependentData.length == 0 ?
                                <div>
                                    {this.state.newDependentData.map((item, i) => {
                                        return (
                                            <div key={i}>
                                                <DependentCards
                                                    item={item}
                                                    isEdit={false}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                :
                                <div className='mt-2'>
                                    <DataNotFound />
                                </div>
                            }
                        </div> : null
                    }
                    {this.state.isEdit ?
                        <DependentCards
                            isEdit={this.state.isEdit}
                            countryData={this.state.countryData}
                            familyMemberData={this.state.familyMemberData}
                            genderData={this.state.genderData}
                            show={this.state.show}
                            saveEmployeeData={(data) => this.saveDependentDetails(data)}
                            cancel={() => this.setState({ isEdit: false })}
                        />
                        : null
                    }
                    {this.state.notePopup &&
                        <NotePopup
                            onDismiss={this.close}
                            type={'dependentTypeNote'}
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}
export default connect(mapStatesToProps)(DependentDetails);