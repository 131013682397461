import React from 'react'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { contactField } from './constants'

function ContactDetailsPopUp(props) {
    const { title, onDismiss, details } = props
    return (
        <div className="modal popup-box">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem">
                <div className="modal-content bg-white border_radius_12px">
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">{title}</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>
                    {
                        details ?
                            <div className='column mt-3 h-25vh scroll-y'>
                                <div>
                                    <div className='d-flex row'>
                                        {
                                            details.map((contact, index) => {
                                                return (
                                                    contactField.map((field) => {
                                                        return (
                                                            <div key={index} className="col-12 col-md-4">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="text14_bold">{field.label.slice(0, 8) + ` ${index + 1} ` + field.label.slice(8)} :</div>
                                                                        <div className="text14_light mt-1"> {contact?.[field.key] ?? "--"}</div>
                                                                        <div className='mt-1 mb-3'><hr /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> :
                            <div className='mt-5 h-90-percent'>
                                <DataNotFound />
                            </div>
                    }
                    <div className="popup-button-background justify-content-end align-item-center pr-3">
                        <input type='button' value={'CLOSE'} className='cancelBtn' onClick={onDismiss} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactDetailsPopUp