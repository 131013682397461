import React, { Component } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import './DropdownStyles.css'
import './../../../../src/assets/styles/basic.css'
import down from '../../../assets/images/applyLeave/downArrow.png'

class DynamicDropdown extends Component {
    setValue = (list, item) => { this.props.selectedValue(list, item) }
    dropDownBtnCSS = (bg) => {
        switch (bg) {
            case "whiteBG":
                return " btn d-flex justify-content-between common-white-dropdown-text"
            case "invalid":
                return "invalidDropdown btn d-flex justify-content-between"
            default:
                return "btn common-dropdown-text d-flex justify-content-between"
        }
    }
    dropDownCSS = (height, width) => {
        let heightClass = ""
        let widthClass = ""
        switch (height) {
            case "height":
                heightClass = "dropdown-menu scroller menu-height w-100"
                break;
            case "NotificationHeight":
                heightClass = "Notification-menu-height admin-scroller dropdown-menu"
                break;
            case "NotificationHeight-L":
                heightClass = "Notification-menu-height-Large admin-scroller dropdown-menu"
                break;
            case "dependOnContent":
                heightClass = "max-dropdown-height contentWise-scroll dropdown-menu "
                break;
            case "LAContent":
                heightClass = "dropdown-menu dropdown-hnew"
                break;
            case "widthwith":
                heightClass = "dropdown-menu dropdown-menu-for-reasoncode"
                break;
            case "innerCard":
                heightClass = "max-dropdown-height contentWise-scroll-min dropdown-menu"
                break;
            default:
                heightClass = "dropdown-menu w-25"
        }
        switch (width) {
            case "width":
                widthClass = "w-100"
                break;
            case "w-8rem":
                widthClass = "w-8rem"
                break;    
            case "w-35rem":
                widthClass = "w-25rem"
                break;
            case "w-14rem":
                widthClass = "w-14rem"
                break;
            case "w-16rem":
                widthClass = "w-16rem"
                break;
            case "w-12rem":
                widthClass = "w-12rem"
                break;
            case "w-15rem":
                widthClass = "w-15rem "
                break;      
                   
            case "w-20rem":
                    widthClass = "w-20rem"
                    break;
            default:
                widthClass = ""
        }
        return heightClass + " " + widthClass
    }
    render() {
        const { data, value, render, arrow, height, disabled, width, textBoxWidth, boxWidth, editable, bg, placeholder = "Select Value", refs = null } = this.props
        let bgClass = this.dropDownBtnCSS(bg)
        let dropdownClass = this.dropDownCSS(height, width)

        return (
            <div className={[`dropdown ${textBoxWidth}`]}>
                <button
                    className={`${bgClass}`}
                    type='button' id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    disabled={disabled}
                    style={{ width: boxWidth }}
                    ref={refs}
                >
                    {boxWidth ? <div className='text_ellipsis toggleStyle justify-content-start pointer ml-1' data-tooltip={value ?? placeholder} >{value ?? placeholder}</div> : value ?? placeholder }
                    {arrow === 'true' ?
                        <img src={down} alt='' className='mt-2 ml-2 dropdownIcon' />
                        :
                        <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                    }
                </button>
                <div className={dropdownClass} aria-labelledby="dropdownMenuButton">
                    {data?.length > 0 ? data.map((listValue, i) => {
                        return (
                            <label key={i} className='dropdown-item' onClick={(e) => { this.setValue(listValue, i) }}>
                                {render ? listValue?.[render] : listValue}
                            </label>
                        );
                    }) :
                        <div className='dropdown-item'>{"No data found"}</div>
                    }
                </div>
            </div>
        );
    }
}

export default DynamicDropdown;