import React, { Component } from 'react';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
const confirmDeleteLastRecord = `Are you sure you want to proceed with `
const newTaxNote = `Are you sure you want to proceed with New Tax Regime option.`
const oldTaxNote = `Are you sure you want to proceed with Old Tax Regime option.`
const warnnNewTaxNote = `Note : You are about to submit New Tax Regime option. If you wish, you can opt for Old Tax Regime later in the current FY, following which further changing of Tax Regime to New Tax Regime will not be possible.`
const warnOldTaxNote = `Note : You are about to submit Old Tax Regime option. Please note that once the employee submits Old Tax Regime option, he/she will not be able to change the tax regime option later in the current financial year.`

class TaxSelectionPopup extends Component {
    render() {
        const { taskTitle, type } = this.props
        console.log('TaxRegimeType-------------------------->', this.props.TaxRegimeType)
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0 popupWidth">
                    <div className="modal-header">
                        <h4 className="modal_title black">
                            Confirmation
                        </h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className='flex-direction-column justify-content-center p-3'>
                            <>
                                <div className='align-self-center'>
                                    <img
                                        alt="Success_icon"
                                        src={Success_icon}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3">
                                    {this.props.TaxNote}
                                </div>
                                <div className="popup_text text-center p-3">
                                    {this.props.warnTaxNote}
                                </div>
                            </>
                           
                        </div>
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                        <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.DeclarationConfirm()} />
                    </div>
                </div>
            </div>

        );
    }
}
export default TaxSelectionPopup;