import React, { useState } from 'react';
import './Regularize.css';
import { DDMMYYYY } from '../../../../../utils/Utils';


const RegularizeDetail = ({ onCancel, validatedData, insertRegularizeOt, supId, mangId, regularizeTab }) => {

    const [otHrs, setOtHrs] = useState('')
    const [error, setError] = useState(false);

    const isValidInput = (value) => {
        // Allow empty input without triggering an error
        if (value.trim() === "") return true;

        // Split the input into parts based on colon
        const parts = value.split(':');
        if (parts.length !== 2) {
            // Must be exactly three parts for HH:MM:SS
            return false;
        }

        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        // const seconds = parseInt(parts[2], 10);

        // Validate hours as non-negative integers
        if (isNaN(hours) || hours < 0) {
            return false;
        }

        // Validate minutes and seconds between 00 and 59
        if (isNaN(minutes) || minutes < 0 || minutes > 59 || parts[1].length !== 2) {
            return false;
        }
        // if (isNaN(seconds) || seconds < 0 || seconds > 59 || parts[2].length !== 2) {
        //     return false;
        // }
        // Consider only hours greater than 0:30 (30 minutes)
        if (hours === 0 && minutes < 30) {
            return false;
        }

        return true;
    };



    const handleUpdate = () => {
        const body = {

            "emp_id": validatedData.emp_id,
            "emp_name": validatedData.emp_name,
            "supervisor_emp_id": supId,
            "manager_emp_id": mangId,
            "ot_date": validatedData.ot_date,
            "pa": validatedData.pa,
            "psa": validatedData.psa,
            "eg": validatedData.eg,
            "esg": validatedData.esg,
            "cost_center": validatedData.cost_center,
            "assigned_cost_center": validatedData.booked_cost_center,
            "day_type": validatedData.day_type,
            "quota_type": validatedData.quota_type,
            "ot_hrs": validatedData.ot_hrs,
            "location_code": validatedData.location_code,
            "org_unit": validatedData.org_unit,
            "comp_code": validatedData.comp_code

        }
        insertRegularizeOt(body)
    };


    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0 edit-popup" style={{ width: '48%' }}>
                <div className="modal-header">
                    <h4 className="modal_title black">Details</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className='pt-10px pb-10px ot-grid-container pl-4 pr-4'>
                        <div className='record'>
                            <span className='sub-atribute'>Employee Id:</span>
                            <span className='sub-atribute-value'>{validatedData.emp_id ? validatedData.emp_id : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>Employee Name:</span>
                            <span className='sub-atribute-value'>{validatedData.emp_name ? validatedData.emp_name : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>Assign Cost Center:</span>
                            <span className='sub-atribute-value'>{validatedData.booked_cost_center ? validatedData.booked_cost_center : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>PA:</span>
                            <span className='sub-atribute-value'>{validatedData.pa_text ? validatedData.pa_text : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>PSA:</span>
                            <span className='sub-atribute-value'>{validatedData.psa_text ? validatedData.psa_text : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>Cost Center:</span>
                            <span className='sub-atribute-value'>{validatedData.cost_center ? validatedData.cost_center : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>E Group:</span>
                            <span className='sub-atribute-value'>{validatedData.eg_text ? validatedData.eg_text : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>E Sub-Group:</span>
                            <span className='sub-atribute-value'>{validatedData.esg_text ? validatedData.esg_text : '-'}</span>
                        </div>
                        {/* {regularizeTab.activeTab == "HolidayBooking" &&
                            <div className='record'>
                                <span className='sub-atribute'>Quota Type:</span>
                                <span className='sub-atribute-value'>{validatedData.quota_type ? validatedData.quota_type : '-'}</span>
                            </div>
                        } */}

                        <div className='record'>
                            <span className='sub-atribute'>Day Type:</span>
                            <span className='sub-atribute-value'>{validatedData.day_type ? validatedData.day_type == "O" ? "Weekly Off" : validatedData.day_type == "H" ? "Holiday" : validatedData.day_type == "W" ? "Working" : '-' : "-"}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>Location Code:</span>
                            <span className='sub-atribute-value'>{validatedData.location_code ? validatedData.location_code : '-'}</span>
                        </div>
                        <div className='record'>
                            <span className='sub-atribute'>{regularizeTab.activeTab == "RegularizeHolidayBooking" ? "Date " : "OT Date "}:</span>
                            <span className='sub-atribute' style={{ color: '#096dd9' }}>{validatedData.ot_date ? DDMMYYYY(validatedData.ot_date) : '-'}</span>
                        </div>
                        {regularizeTab.activeTab == "RegularizeHolidayBooking" && validatedData.day_type == "H" ?
                            < div className='record'>
                                <span className='sub-atribute'>Hrs:</span>
                                <span className='sub-atribute' style={{ color: '#096dd9' }}>{validatedData.ot_hrs ? validatedData.ot_hrs : '-'}</span>
                            </div>
                            :
                            <></>
                        }
                        {regularizeTab.activeTab != "RegularizeHolidayBooking" &&
                            <div className='record'>
                                <span className='sub-atribute'>OT Hours:</span>
                                <span className='sub-atribute' style={{ color: '#096dd9' }}>{validatedData.ot_hrs ? validatedData.ot_hrs : '-'}</span>
                            </div>
                        }
                    </div>
                </div>
                <div className='modal-footer'>
                    <input type='button' value='Cancel' className='cancelBtn' onClick={onCancel} />
                    <input type='button' value='Book' className={'ml-2 validateBtn'}
                        onClick={handleUpdate} />
                </div>
            </div>
        </div >
    );
};

export default RegularizeDetail;
