import React, { Component } from 'react'
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import { projectFormConstant, constTypes } from './Constant'
import CharacterCounter from '../../../shared/CharacterCounter';
import moment from 'moment';
import { toaster } from '../../../../utils/Utils';
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { getMTRFR, downloadApplicantCV } from '../../../ScreenType/employeeScreens/gems/Gems.api.service'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';

const startProjectValues = {
    planned_start_date: "Project Start Date : ",
    actual_start_date: "Actual Project Start Date: ",
    planned_mtr_date: "Mid Term Date : ",
    actual_mtr_date: "New Mid Term Date : ",
    planned_fr_date: "Final Review Date : ",
    newFRDate: "New Final Review Date : ",
    durationDays: "Duration (Days) : "
}
const projectStaus = [
    { id: 0, toggleStatus: 'On Track' },
    { id: 1, toggleStatus: 'Delayed' }
]
const projectEvalutionStatus = [
    { id: 0, toggleStatus: 'Completed' },
    { id: 1, toggleStatus: 'Incomplete' }
]
const projectCompletionStaus = [
    { value: true, toggleStatus: 'Yes' },
    { value: false, toggleStatus: 'No' }
]
const mtrEvaluationStatus = [
    { id: 0, toggleStatus: "On Track" },
    { id: 1, toggleStatus: "Delayed" },
    { id: 2, toggleStatus: "Dropped" }
]
const frEvaluationStatus = [
    { id: 0, toggleStatus: "Completed" },
    { id: 1, toggleStatus: "Incomplete" },
    { id: 2, toggleStatus: "Dropped" }
]
const startProjectField = ["planned_start_date", "actual_start_date", "planned_mtr_date", "actual_mtr_date", "planned_fr_date", "newFRDate"]
class StartProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            startProjectKey: false,
            checked: 0,
            commentReview: "",
            selectedStartDate: this.props.data[0]?.planned_start_date,
            newMidTermDate: "",
            newFinalTermDate: "",
            StartProjectData: [],
            closeMTRDetails: { "mtr_status": "On Track" },
            closeFRDetails: { "fr_status": "Completed", "is_project_completed": true },
            attachment: "",
            projectStatus: 0,
        }
    }

    updateStartDate = (selectedDate) => {
        this.props.declaration(this.state.check)
        this.props.enableStartBtn(true)
        const duration = this.props.data[0].duration
        let finalProjectDate = new Date(new Date(selectedDate).getTime() + duration * 24 * 60 * 60 * 1000)
        const firstDate = new Date(selectedDate);
        const lastDate = finalProjectDate;
        const timeDiffInMillis = lastDate - firstDate;
        const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
        const mtrDate = new Date(midpointTime);
        this.setState({
            selectedStartDate: selectedDate,
            newMidTermDate: mtrDate,
            newFinalTermDate: finalProjectDate,
            StartProjectData: {
                "actual_start_date": moment(selectedDate).format('YYYY-MM-DD'),
                "actual_end_date": moment(finalProjectDate).format('YYYY-MM-DD'),
                "actual_mtr_date": moment(mtrDate).format('YYYY-MM-DD'),
                "actual_fr_date": moment(finalProjectDate).format('YYYY-MM-DD'),
                "duration": this.props.data[0].duration,
                "category": this.props.data[0].category,
                "status": "Ongoing",
            }
        },
            () => this.props.passDetails(this.state.StartProjectData)
            // ()=> this.props.declaration(this.state.check)
        )
    }

    onChangedProjectStatus = (toggleStatus, key) => {
        this.setState({
            checked: key,
            closeMTRDetails: {
                ...this.state.closeMTRDetails,
                "mtr_status": toggleStatus
            }
        },
            () => this.props.passDetails(this.state.closeMTRDetails)
        )
    }

    collectOverAllComment = (comment) => {
        this.setState({
            closeMTRDetails: {
                ...this.state.closeMTRDetails,
                "mtr_comments": comment
            }
        },
            () => this.props.passDetails(this.state.closeMTRDetails)
        )
    }

    onIndChangedProjectStatus = (toggleStatus, key) => {
        this.setState({
            checked: key,
            closeMTRDetails: {
                ...this.state.closeMTRDetails,
                "mtr_status": toggleStatus
            }
        },
            () => this.props.passDetailsInd(this.state.closeMTRDetails)
        )
    }

    commentIndMTR = (comment) => {
        this.setState({
            closeMTRDetails: {
                ...this.state.closeMTRDetails,
                "mtr_comments": comment
            }
        },
            () => this.props.passDetailsInd(this.state.closeMTRDetails)
        )
    }

    onIndFRChangedProjectStatus = (toggleStatus, key) => {
        this.setState({
            checked: key,
            closeFRDetails: {
                ...this.state.closeFRDetails,
                "fr_status": toggleStatus
            }
        },
            () => this.props.passDetailsInd(this.state.closeFRDetails)
        )
    }

    commentIndFR = (comment) => {
        this.setState({
            closeFRDetails: {
                ...this.state.closeFRDetails,
                "fr_comments": comment
            }
        },
            () => this.props.passDetailsInd(this.state.closeFRDetails)
        )
    }

    onChangedFRStatus = (toggleStatus, key) => {
        this.setState({
            checked: key,
            closeFRDetails: {
                ...this.state.closeFRDetails,
                "fr_status": toggleStatus
            }
        },
            () => this.props.passDetails(this.state.closeFRDetails)
        )
    }

    commentFRFunction = (comment) => {
        this.setState({
            closeFRDetails: {
                ...this.state.closeFRDetails,
                "fr_comments": comment
            }
        },
            () => this.props.passDetails(this.state.closeFRDetails)
        )
    }

    onChangedProjectompletion = (toggleStatus, value, key) => {
        this.setState({
            projectStatus: key,
            closeFRDetails: {
                ...this.state.closeFRDetails,
                "is_project_completed": value
            }
        },
            () => this.props.passDetails(this.state.closeFRDetails)
        )
    }

    uploadProjectSummery = (event) => {
        const fileType = event.target.files[0].type
        const fileName = event.target.files[0] ? event.target.files[0].name : ''
        if (fileType == 'application/pdf') {
            if (event.target.files && event.target.files[0]) {
                let maxAllowedSize = 1048576 * 2;           // 2MB size
                if (event.target.files[0].size <= maxAllowedSize) {
                    let reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    // reader.onload = (e) => {attachment = e.target.result }
                    reader.onload = (e) => {

                        if (this.props.type === constTypes.closeFR) {
                            this.setState({
                                closeFRDetails: {
                                    ...this.state.closeFRDetails,
                                    "filename": fileName,
                                    "attachment": e.target.result
                                }
                            }, () => this.props.passDetails(this.state.closeFRDetails))
                        } else if (this.props.type === constTypes.closeMTR) {
                            this.setState({
                                closeMTRDetails: {
                                    ...this.state.closeMTRDetails,
                                    "filename": fileName,
                                    "attachment": e.target.result
                                }
                            }, () => this.props.passDetails(this.state.closeMTRDetails))
                        }
                    }
                }
                else {
                    toaster("", "Please upload pdf less than 2MB")
                }
            }
        }
    }

    downloadCV = (projectID, applicantID) => {
        console.log("downloadCVdownloadCVdownloadCV")
        this.setState({
            loading: true
        })
        downloadApplicantCV(projectID, applicantID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("downloadApplicantCV catch err>>", err)
            })
    }

    render() {
        return (
            <div className='px-20px'>
                {/* ----- Star project body------ */}
                {this.props.type === constTypes.startPrj &&
                    <>
                        {
                            this.props.data.map((dataValue, idx) =>
                                <div className='approval-popup' key={idx}>
                                    {
                                        startProjectField.map((key, idx) =>
                                            <div className='line-horizontal pb-10px mt-1' key={idx}>
                                                {
                                                    idx === 1 ?
                                                        <div className='d-flex flex-direction-row mt-1'>
                                                            <div className='performance-timeline-text-date required'>{startProjectValues[key]}</div>
                                                            <div className='ml-2'>
                                                                <input
                                                                    className="form-control popup-placeholder px-3"
                                                                    type="date"
                                                                    onKeyDown={(e) => e.preventDefault()}
                                                                    name='actual start date'
                                                                    placeholder={'Enter'}
                                                                    onChange={(planned_start_date) => this.updateStartDate(planned_start_date.target.value)}
                                                                    value={(this.state.selectedStartDate)}
                                                                    min={moment(dataValue?.planned_start_date).format('YYYY-MM-DD')}
                                                                />
                                                            </div>
                                                        </div>
                                                        :
                                                        idx === 3 ?
                                                            <>
                                                                <span className='performance-timeline-text-date'>{startProjectValues[key]}</span>
                                                                <span className='feedback-sm-text'>{this.state.newMidTermDate !== "" ? moment(this.state.newMidTermDate).format('DD-MM-YYYY') : "  -"}</span>
                                                            </>
                                                            :
                                                            idx === 5 ?
                                                                <>
                                                                    <span className='performance-timeline-text-date'>{startProjectValues[key]}</span>
                                                                    <span className='feedback-sm-text'>{this.state.newFinalTermDate !== "" ? moment(this.state.newFinalTermDate).format('DD-MM-YYYY') : "  -"}</span>
                                                                </>
                                                                :
                                                                <>
                                                                    <span className='performance-timeline-text-date'>{startProjectValues[key]}</span>
                                                                    <span className='feedback-sm-text'>{moment(dataValue[key]).format('DD-MM-YYYY')}</span>
                                                                </>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        <span className='pb-10px'>
                            <span className='performance-timeline-text-date'>{startProjectValues.durationDays}</span>
                            <span className='feedback-sm-text'>{this.props.data[0].duration}</span>
                        </span>
                        <hr />
                        <div className='d-flex flex-direction-row align-items-center mt-3 ml-2'>
                            <label className="checkBoxData pl-3">
                                <input type="checkbox" className='hidecheckBox'
                                    checked={this.state.check}
                                    onChange={e => this.setState({ check: !this.state.check }, () => this.props.declaration(this.state.check))}
                                />
                                <span className="markk"></span>
                            </label>
                            <div className='mt-3'>
                                <span className='performance-timeline-text-date'>{'Declaration:'}</span>
                                <span className='feedback-sm-text'>{constTypes.declarationInfo}</span>
                            </div>
                        </div>
                    </>
                }
                {/*  ------ Close MTR body ------ */}
                {this.props.type === constTypes.closeMTR &&
                    <div className='pt-10px'>
                        <div className=''>
                            <span className='performance-timeline-text-circle pt-10px required'>{projectFormConstant.prjMtrEvaluation}</span>
                            <div className='flex-direction-row align-item-center-noflex'>
                                <span className='performance-timeline-text-circle'>Status:</span>
                                {projectStaus.map((list, key) =>
                                    <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                        {this.state.checked === key ?
                                            <span className=''
                                                onClick={() => this.onChangedProjectStatus(list.toggleStatus, key)}
                                            >
                                                <img alt="radioOn" className='radio' src={radioOn} />
                                                <label className="radioTxt ml-15px" >{list.toggleStatus}</label>
                                            </span>
                                            :
                                            <span className=''
                                                onClick={() => this.onChangedProjectStatus(list.toggleStatus, key)}
                                            >
                                                <img alt="radioOff" className='radio' src={radioOff} />
                                                <label className="radioTxt ml-15px">{list.toggleStatus}</label>
                                            </span>
                                        }
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className='pt-10px'>
                            <span className='performance-timeline-text-circle'>{constTypes.atchPrjReport} </span>
                            <input
                                className={`form-control px-3 paddingTop-11" form-control px-3 paddingTop-11 ${false ? 'p-placeholder-error' : 'p-placeholder'}`}
                                type="file"
                                accept="pdf/*"
                                onChange={this.uploadProjectSummery}
                            />
                            <span className='performance-timeline-text-circle pt-10px '>
                                {constTypes.pdNote}
                            </span>
                        </div>
                        <div className='pt-10px mb-1'>
                            <span className='performance-timeline-text-circle required'>{constTypes.overallComment}</span>
                            <textarea
                                className="form-control popup-placeholder px-3"
                                type="text"
                                maxLength="150"
                                onChange={(data) => this.setState({ commentReview: data.target.value }, this.collectOverAllComment(data.target.value))}
                                value={this.state.commentReview}
                                placeholder={'Enter Comments'}
                            />
                            <CharacterCounter
                                totalCount="150"
                                currentCount={this.state.commentReview.length}
                            />
                        </div>

                    </div>
                }
                {/*  ------ Close FR body ------ */}
                {this.props.type === constTypes.closeFR &&
                    <div className='pt-10px'>
                        <div>
                            {/* <span className='performance-timeline-text-circle'>{'MTR Selection'}</span> */}
                            <div className='justify-content-between mt-2'>
                                <div className='webkit-width'>
                                    <span className='performance-timeline-text-circle'>{'MTR Evaluation: '}</span>
                                    <span className='radioTxt mt-3px'>{this.props.mtrViewData[0]?.mtr_status}</span>
                                </div>
                                <div>
                                    <span className='performance-timeline-text-circle'>{'MTR Comment: '}</span>
                                    <span className='radioTxt mt-3px'>{this.props.mtrViewData[0]?.mtr_comments}</span>
                                </div>
                            </div>
                            <div><hr /></div>
                        </div>

                        <div>
                            <div>
                                <span className='performance-timeline-text-circle pt-10px required'>{projectFormConstant.prjFREvaluation}</span>
                                <div className='flex-direction-row align-item-center-noflex'>
                                    <span className='performance-timeline-text-circle'>Status:</span>
                                    {projectEvalutionStatus.map((list, key) =>
                                        <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                            {this.state.checked === key ?
                                                <span className=''
                                                    onClick={() => this.onChangedFRStatus(list.toggleStatus, key)}
                                                >
                                                    <img alt="radioOn" className='radio' src={radioOn} />
                                                    <label className="radioTxt ml-15px" >{list.toggleStatus}</label>
                                                </span>
                                                :
                                                <span className=''
                                                    onClick={() => this.onChangedFRStatus(list.toggleStatus, key)}
                                                >
                                                    <img alt="radioOff" className='radio' src={radioOff} />
                                                    <label className="radioTxt ml-15px">{list.toggleStatus}</label>
                                                </span>
                                            }
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className='pt-10px'>
                                <span className='performance-timeline-text-circle required ml-3'>{constTypes.atchPrjReport} </span>
                                <input
                                    className={`form-control px-3 paddingTop-11" form-control px-3 paddingTop-11 ${false ? 'p-placeholder-error' : 'p-placeholder'}`}
                                    type="file"
                                    accept="pdf/*"
                                    onChange={this.uploadProjectSummery}
                                />
                                <span className='performance-timeline-text-circle pt-10px '>
                                    {constTypes.pdNote}
                                </span>
                            </div>
                            <div className='pt-10px'>
                                <span className='performance-timeline-text-circle required ml-3'>{constTypes.overallComment}</span>
                                <textarea
                                    className="form-control popup-placeholder px-3"
                                    type="text"
                                    maxLength="150"
                                    onChange={(data) => this.setState({ commentReview: data.target.value }, this.commentFRFunction(data.target.value))}
                                    value={this.state.commentReview}
                                    placeholder={'Enter Comments'}
                                />
                                <CharacterCounter
                                    totalCount="150"
                                    currentCount={this.state.commentReview.length}
                                />
                            </div>

                            <div><hr /></div>
                            <div className='mb-2'>
                                <span className='performance-timeline-text-circle pt-10px required'>{projectFormConstant.prjCompletion}</span>
                                <div className='flex-direction-row align-item-center-noflex'>
                                    <span className='performance-timeline-text-circle'>{'Move Project To Completion: '}</span>
                                    {projectCompletionStaus.map((list, key) =>
                                        <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                            {this.state.projectStatus === key ?
                                                <span className=''
                                                    onClick={() => this.onChangedProjectompletion(list.toggleStatus, list.value, key)}
                                                >
                                                    <img alt="radioOn" className='radio' src={radioOn} />
                                                    <label className="radioTxt ml-15px" >{list.toggleStatus}</label>
                                                </span>
                                                :
                                                <span className=''
                                                    onClick={() => this.onChangedProjectompletion(list.toggleStatus, list.value, key)}
                                                >
                                                    <img alt="radioOff" className='radio' src={radioOff} />
                                                    <label className="radioTxt ml-15px">{list.toggleStatus}</label>
                                                </span>
                                            }
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.type === constTypes.indMTR &&
                    <div>
                        {this.props.data.map((dataValue, idx) =>
                            <div key={idx}>
                                <div className='approval-popup '>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Application Id"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.id}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Id"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Perno}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Name"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.CompName}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Level"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Esgtxt}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Location"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.LocDesc}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant OU"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Orgtx}</span>
                                    </div>
                                </div>
                                <div className='line-horizontal py-5px'>
                                    <span className='performance-timeline-text-circle'>{"Applicant CV"}:&nbsp;&nbsp;</span>
                                    <span className='feedback-sm-text'>{dataValue.attachment}</span>
                                    <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadCV(this.props.currentPorjectId, dataValue.id)} />
                                </div>
                                <div className='py-5px'>
                                    <div className='performance-timeline-text-date required'>{projectFormConstant.prjMtrEvaluation}</div>
                                    <div className='flex-direction-row align-item-center-noflex'>
                                        <span className='performance-timeline-text-circle'>Status:</span>
                                        {mtrEvaluationStatus.map((list, key) =>
                                            <span key={key} className='align-item-center-noflex pl-15 ml-10 px-5px  '>
                                                {this.state.checked === key ?
                                                    <span className=''
                                                        onClick={() => this.onIndChangedProjectStatus(list.toggleStatus, key)}
                                                    >
                                                        <img alt="radioOn" className='radio' src={radioOn} />
                                                        <label className="radioTxt ml-15px" >{list.toggleStatus}</label>
                                                    </span>
                                                    :
                                                    <span className=''
                                                        onClick={() => this.onIndChangedProjectStatus(list.toggleStatus, key)}
                                                    >
                                                        <img alt="radioOff" className='radio' src={radioOff} />
                                                        <label className="radioTxt ml-15px">{list.toggleStatus}</label>
                                                    </span>
                                                }
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <span className='performance-timeline-text-circle required'>Comments</span>
                                    <textarea
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        maxLength="150"
                                        onChange={(comment) => this.setState({ commentReview: comment.target.value }, this.commentIndMTR(comment.target.value))}
                                        value={this.state.commentReview}
                                        placeholder={"Enter Comments"}
                                    />
                                    <CharacterCounter
                                        totalCount="150"
                                        currentCount={this.state.commentReview.length}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
                {
                    this.props.type === constTypes.indFR &&
                    <div>
                        {this.props.data.map((dataValue, idx) =>
                            <div key={idx}>
                                <div className='approval-popup '>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Application Id"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.id}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Id"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Perno}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Name"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.CompName}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Level"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Esgtxt}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant Location"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.LocDesc}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"Applicant OU"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.applicant.Orgtx}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"MTR Status"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.mtr_status}</span>
                                    </div>
                                    <div className='line-horizontal py-5px'>
                                        <span className='performance-timeline-text-circle'>{"MTR Comments"}:&nbsp;&nbsp;</span>
                                        <span className='feedback-sm-text'>{dataValue.mtr_comments}</span>
                                    </div>
                                </div>
                                <div className='line-horizontal py-5px'>
                                    <span className='performance-timeline-text-circle'>{"Applicant CV"}:&nbsp;&nbsp;</span>
                                    <span className='feedback-sm-text'>{dataValue.attachment}</span>
                                    <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadCV(this.props.currentPorjectId, dataValue.id)} />
                                </div>
                                <div className='py-5px'>
                                    <div className='performance-timeline-text-date required'>{projectFormConstant.prjFREvaluation}</div>
                                    <div className='flex-direction-row align-item-center-noflex'>
                                        <span className='performance-timeline-text-circle'>Status:</span>
                                        {frEvaluationStatus.map((list, key) =>
                                            <span key={key} className='align-item-center-noflex pl-15 ml-10 px-5px  '>
                                                {this.state.checked === key ?
                                                    <span className=''
                                                        onClick={() => this.onIndFRChangedProjectStatus(list.toggleStatus, key)}
                                                    >
                                                        <img alt="radioOn" className='radio' src={radioOn} />
                                                        <label className="radioTxt ml-15px" >{list.toggleStatus}</label>
                                                    </span>
                                                    :
                                                    <span className=''
                                                        onClick={() => this.onIndFRChangedProjectStatus(list.toggleStatus, key)}
                                                    >
                                                        <img alt="radioOff" className='radio' src={radioOff} />
                                                        <label className="radioTxt ml-15px">{list.toggleStatus}</label>
                                                    </span>
                                                }
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <span className='performance-timeline-text-circle required'>Comments</span>
                                    <textarea
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        maxLength="150"
                                        onChange={(comment) => this.setState({ commentReview: comment.target.value }, this.commentIndFR(comment.target.value))}
                                        value={this.state.commentReview}
                                        placeholder={"Enter Comments"}
                                    />
                                    <CharacterCounter
                                        totalCount="150"
                                        currentCount={this.state.commentReview.length}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
                {this.props.type === "pgMTRFRViewDetails" &&
                    <>
                        {this.props.data.map((dataValue, idx) =>
                            <div key={idx}>
                                <div className='approval-popup '>
                                    <div className='pg-MTRFR-grid'>
                                        <div className='pg-MTR-first'>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Application Id"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.id ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Applicant Name"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.applicant.CompName ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Applicant Location"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.applicant.LocDesc ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"MTR Status"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.mtr_status ?? '-'}</span>
                                            </div>
                                        </div>
                                        <div className='pg-MTR-second'>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Applicant Id"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.applicant.Perno ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Applicant Level"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.applicant.Esgtxt ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"Applicant OU"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.applicant.Orgtx ?? '-'}</span>
                                            </div>
                                            <div className='line-horizontal py-5px'>
                                                <span className='performance-timeline-text-circle'>{"FR Status"}:&nbsp;&nbsp;</span>
                                                <span className='feedback-sm-text'>{dataValue?.fr_status ?? '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='line-horizontal py-5px'>
                                    <span className='performance-timeline-text-circle'>{"MTR Comments"}:&nbsp;&nbsp;</span>
                                    <span className='feedback-sm-text'>{dataValue?.mtr_comments ?? '-'}</span>
                                </div>
                                <div className='line-horizontal py-5px'>
                                    <span className='performance-timeline-text-circle'>{"FR Comments"}:&nbsp;&nbsp;</span>
                                    <span className='feedback-sm-text'>{dataValue?.fr_comments ?? '-'}</span>
                                </div>
                                <div className='line-horizontal py-5px'>
                                    <span className='performance-timeline-text-circle'>{"Applicant CV"}:&nbsp;&nbsp;</span>
                                    <span className='feedback-sm-text'>{dataValue?.attachment}</span>
                                    <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadCV(this.props.currentPorjectId, dataValue.id)} />
                                </div>
                            </div>
                        )}
                    </>
                }
            </div>
        )
    }
}

export default StartProject