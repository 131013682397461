import React from 'react'

function LocalConveyanceConfirmPopUp(props) {
    const { heading, onDismiss, message, confirmBtnLbl = "CONFIRM", cancelBtnLbl = "CANCEL", onConfirmAction } = props
    return (
        <div className="modal popup-box">
            <div className={'popupCard-box p-0 w-30'}>
                {/* ------------------------------------------------------------- Header -------------------------------------------------------------- */}
                <div className="modal-header">
                    <h4 className="modal_title black popup-head">{heading}</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className='flex-direction-column justify-content-center p-3'>
                        {message}
                    </div>
                </div>
                <div className='modal-footer btnBox1'>
                    <input type='button' onClick={() => { onConfirmAction() }} value={confirmBtnLbl} className='validateBtn' />
                    <input type='button' onClick={() => { onDismiss() }} value={cancelBtnLbl} className='cancelBtn' />
                </div>
            </div>
        </div>
    )
}

export default LocalConveyanceConfirmPopUp