import React, { Component } from 'react';
import '../ProfileScreen.css';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BankCards from '../BankDetails/BankCards';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import infoicon from '../../../../../assets/drishti_images/infoicon.svg';
import NotePopup from '../NotePopUp';

toast.configure();

class BankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            countryData: [],
            cityData: [],
            bankNameData: [],
            paymentMethodData: [],
            currencyData: [],
            newBankData: [],
            bank: [{
                account_holder_name: '',
                bank_country_code: '',
                bank_country: '',
                bank_city_code: '',
                bank_city: '',
                bank_postal_code: '',
                bank: '',
                ifsc_code: '',
                account_number: '',
                payment_method_code: '',
                payment_method: '',
                payment_currency: ''
            }],
            newBank: [],
            isAddButon: false,
            showData: false,
            show: false,
            isActive: false,
            loading: false,
            message: '',
            notePopup: false,
        }
    }

    componentDidMount() {
        this.getBankMasterData();
        this.getBankDetails(this.props.loginData.userData.Perno);
    }

    getBankMasterData = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getBankMasterDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        countryData: result.data.data.country ? result.data.data.country : [],
                        cityData: result.data.data.city ? result.data.data.city : [],
                        bankNameData: result.data.data.bank ? result.data.data.bank : [],
                        currencyData: result.data.data.currency ? result.data.data.currency : [],
                        paymentMethodData: result.data.data.payment_method ? result.data.data.payment_method : [],
                    });
                }
            }).catch(err => {
                console.warn('Bank Master err', err)
            })
    }

    getBankDetails(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getBankDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    if (res.data.status_code == 300) {
                        // toast.warn(res.data.message, { hideProgressBar: true });
                    }
                    this.setState({
                        loading: false,
                        bank: res.data.bank_details ? res.data.bank_details : [],
                        showData: true,
                        newBankData: res.data.bank_details ? res.data.bank_details : []
                    })

                } else {
                    this.setState({
                        loading: false,
                        newBankData: []
                    });
                    // toast.warn(res.data.message, {hideProgressBar: true});
                }

            }).catch(err => {
                console.log('getBankDetails err', err)
                this.setState({
                    loading: false
                });
                // toast.error("requestFailed" , {hideProgressBar: true})
            })
    }

    saveBankDetails = (data) => {
        this.setState({
            loading: true
        })
        const req = {
            data: data
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        fetchApiCall(Config.updateBankDetails, req, headers, false, '')
            .then(res => {
                if (res.data.statusCode == 200) {
                    this.setState({
                        isEdit: false,
                        loading: false,
                        newBank: {},
                        show: true
                    },
                    ()=>this.getBankDetails(this.props.loginData.userData.Perno),
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    }));
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                console.log('updateBankDetails err', err)
                this.setState({
                    loading: false
                });
                toast.error('Unable to contact server\nPlease try again later', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }


    // saveBankDetails = (savingData) => {
    //     this.setState({
    //         loading: true
    //     });
    //     const req = {
    //         data: [{
    //             emp_id: this.props.loginData.userData.Perno,
    //             account_holder_name: savingData.account_holder_name ? savingData.account_holder_name : '',
    //             bank_country_code: savingData.country_code ? savingData.country_code : '',
    //             bank_country: savingData.bank_country ? savingData.bank_country : '',
    //             bank_city: savingData.bank_city ? savingData.bank_city : '',
    //             bank_postal_code: savingData.bank_postal_code ? savingData.bank_postal_code : '',
    //             bank_code: savingData.bank_code ? savingData.item.bank_code : '',
    //             bank: savingData.bank ? savingData.bank : '',
    //             ifsc_code: savingData.ifsc_code ? savingData.ifsc_code : '',
    //             account_number: savingData.account_number ? savingData.account_number : '',
    //             payment_method_code: savingData.payment_method_code ? savingData.payment_method_code : '',
    //             payment_method: savingData.payment_method ? savingData.payment_method : '',
    //             payment_currency: savingData.payment_currency ? savingData.payment_currency : '',
    //             payment_currency_code: savingData.payment_currency_code ? savingData.payment_currency_code : '',
    //         }]
    //     }
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     fetchApiCall( Config.updateBankDetails, req,  headers,false,'')
    //         .then(res => {
    //             sessionOut(this.props, res);
    //             if (res.data.statusCode == 200) {
    //                 this.setState({
    //                     isEdit: false,
    //                     loading: false,
    //                     newBank: {},
    //                     show: true
    //                 });
    //                 toast.success(res.data.message, { hideProgressBar: true });
    //                 this.state.newBankData.push(req.data[0]);

    //             } else {
    //                 this.setState({
    //                     loading: false
    //                 });
    //                 // toast.warn(res.data.message, { hideProgressBar: true });
    //             }

    //         }).catch(err => {
    //             console.log('err', err)
    //             this.setState({
    //                 loading: false
    //             });
    //             // toast.error("requestFailed" , {hideProgressBar: true})
    //         })
    // }

    setEdit = () => {
        this.setState({
            isEdit: true
        })
    }

    unSetEdit = () => {
        this.setState({ isEdit: false }, () => this.getBankDetails(this.props.loginData.userData.Perno)
        );
    }

    toggleOpenDetail = () => {
        this.setState({
            openDetail: !this.state.openDetail,
            isEdit: true,
        })
    }

    close = () => {
        this.setState({
            notePopup: false
        })
    }
    render() {
        return (
            <div className="row">
                <LoaderIndicator loading={this.state.loading} />
                <div className=' row col-md-12 d-flex justify-content-end ' style={{ 'alignItems': 'end' }}>
                    <div
                        type='button'
                        className='bank-addoption-text mx-4 mt-3'
                        onClick={() => this.toggleOpenDetail('education')}
                    > + Add New Bank Detail
                    </div>
                    <div>
                        <img
                            alt="infoicon"
                            src={infoicon}
                            className="info_icon"
                            onClick={() => this.setState({
                                notePopup: true
                            })}
                        ></img>
                    </div>
                </div>
                <div className="col-md-12">
                    {this.state.showData && !this.state.isEdit ?
                        <div>
                            <BankCards
                                item={this.state.bank}
                                isEdit={false}
                                deleteEmploymentData={(data) => this.deleteEmploymentDetails(data)}
                            />
                        </div> : null
                    }
                    {this.state.isEdit ?
                        <BankCards
                            isEdit={this.state.isEdit}
                            countryData={this.state.countryData}
                            cityData={this.state.cityData}
                            bankNameData={this.state.bankNameData}
                            paymentMethodData={this.state.paymentMethodData}
                            currencyData={this.state.currencyData}
                            show={this.state.show}
                            saveEmployeeData={(data) => this.saveBankDetails(data)}
                            cancel={this.unSetEdit}
                        />
                        : null
                    }
                </div>
                {this.state.notePopup &&
                    <NotePopup
                        onDismiss={this.close}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}
export default connect(mapStatesToProps)(BankDetails);