import React, { Component } from 'react';
import moment from 'moment';
import LoaderIndicator from '../../../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import Dropdown from '../../../../../shared/components/Dropdown';
import { ConfirmedFormPopupConstant, MaintenanceDashboardConstant } from '../gemsMaintenanceConstant';
import { addGemsMaintenance, gettestimonyEmployeDetails, updateGemsMaintenance } from '../../../../employeeScreens/gems/Gems.api.service';
import { toaster } from '../../../../../../utils/Utils';
import CharacterCounter from '../../../../../shared/CharacterCounter';

class UpdateFormPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteTypeData: [{ value: 'Stretch' }, { value: 'Deputation' }],
            noteType: '',
            isDisabled: true,
            areaTitle: '',
            areaColor: '',
            entityTitle: '',
            empName: '',
            testimonialsEmp: '',
            perNo: '',
            OrgUnit: '',
            function: '',
            testimonialsEmpId: '',
            testimonials: '',
            imagePath: '',
            attachment: '',
            projectCategory: '',
            projectDuration: '',
            mtrDuration: '',
            frDuration: '',
            pipId: '',
            pipStartDate: '',
            pipEndDate: '',
            debarApplicantId: '',
            debarStartDate: '',
            debarEndDate: '',
            otherProjectApplicantId: '',
            otherProjectStartDate: '',
            otherProjectEndDate: '',
            spocId: '',
            spocUpin: '',
            spocEmail: '',
            reason: ''
        }
    }

    componentDidMount() {
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Area) {
            this.setState({
                areaTitle: this.props.updateFormData.area,
                areaColor: this.props.updateFormData.color_code
            })
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Testimonials) {
            this.setState({
                testimonialsEmpId: this.props.updateFormData.testimonial_id,
                testimonials: this.props.updateFormData.testimony,
                empName: this.props.updateFormData.emp_name,
                OrgUnit: this.props.updateFormData.org_unit,
                function: this.props.updateFormData.function
            })
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Duration) {
            this.setState({
                projectCategory: this.props.updateFormData.project_category,
                projectDuration: this.props.updateFormData.project_duration,
                mtrDuration: this.props.updateFormData.mtr_duration,
                frDuration: this.props.updateFormData.fr_duration
            })
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.DebarApplicant) {
            this.setState({
                debarApplicantId: this.props.updateFormData.applicant_perno,
                debarStartDate: this.props.updateFormData.start_date,
                debarEndDate: this.props.updateFormData.end_date
            })
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.OtherProjectApplicant) {
            this.setState({
                otherProjectApplicantId: this.props.updateFormData.applicant_perno,
                otherProjectStartDate: this.props.updateFormData.project_start_date,
                otherProjectEndDate: this.props.updateFormData.project_end_date
            })
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.isApplicationRejectScreen) {
            this.setState({
                reason: this.props.updateFormData.reason
            })
        }
    }

    updateFormDataApi = (body) => {
        updateGemsMaintenance(this.props.filteredValue.endPoint, this.props.updateFormData.id, body)
            .then(res => {
                if (res.status == '200' || res.status == '201') {
                    this.setState({
                        loading: false,
                        message: '',
                    })
                    this.props.closeUpdatePopUp()
                    toaster("success", res.data.message ? res.data.message : "Updated successfully")
                } else {
                    this.setState({
                        loading: false,
                        message: res.data.message
                    });
                    toaster("warning", res.data.message ? res.data.message : "Something went wrong")
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toaster("", err.data.message ? err.data.message : "Something went wrong")
            })
    }
    validateForm = () => {
        let body = this.stateUpdate()
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Area) {
            if (this.state.areaTitle === '') {
                toaster("warning", "Please enter area")
            }
            else if (this.state.areaColor === '') {
                toaster("warning", "Please select color")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Testimonials) {
            if (this.state.testimonialsEmpId.length < 6) {
                toaster("warning", "Please enter valid employee id")
            } else if (this.state.empName === '') {
                toaster("warning", "Please enter employee name")
            } else if (this.state.OrgUnit === '') {
                toaster("warning", "Please enter organization Unit")
            } else if (this.state.function === '') {
                toaster("warning", "Please enter function")
            } else if (this.state.testimonials === '') {
                toaster("warning", "Please enter testimonials")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.Duration) {
            if (this.state.projectCategory === '') {
                toaster("warning", "Please enter project category")
            } else if (this.state.projectDuration === '') {
                toaster("warning", "Please enter project duration")
            }
            else if (this.state.projectDuration % 2 != 0) {
                toaster("warning", "Please enter even number")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.DebarApplicant) {
            if (this.state.debarApplicantId.length < 6) {
                toaster("warning", "Please enter applicant employee id")
            } else if (this.state.debarStartDate === '') {
                toaster("warning", "Please select start date")
            }
            else if (this.state.debarEndDate === '') {
                toaster("warning", "Please select end date")
            }
            else if (new Date(this.state.debarStartDate).getTime() > new Date(this.state.debarEndDate).getTime()) {
                toaster("warning", "Please select valid date")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.OtherProjectApplicant) {
            if (this.state.otherProjectApplicantId.length < 6) {
                toaster("warning", "Please enter applicant employee id")
            } else if (this.state.otherProjectStartDate === '') {
                toaster("warning", "Please select start date")
            }
            else if (this.state.otherProjectEndDate === '') {
                toaster("warning", "Please select end date")
            }
            else if (new Date(this.state.otherProjectStartDate).getTime() > new Date(this.state.otherProjectEndDate).getTime()) {
                toaster("warning", "Please select valid date")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
        if (this.props.filteredValue.key == MaintenanceDashboardConstant.isApplicationRejectScreen) {
            if (this.state.reason === '') {
                toaster("warning", "Please enter reason")
            }
            else {
                this.updateFormDataApi(body)
            }
        }
    }

    stateUpdate = () => {
        switch (this.props.filteredValue.key) {
            case MaintenanceDashboardConstant.Area:
                return (
                    {
                        area: this.state.areaTitle,
                        color_code: this.state.areaColor
                    }
                )
            case MaintenanceDashboardConstant.Testimonials:
                console.log("this.state.perNo", this.state.perNo)
                return (
                    {
                        testimonial_id: this.state.testimonialsEmpId,
                        emp_name: this.state.empName,
                        org_unit: this.state.OrgUnit,
                        function: this.state.function,
                        testimony: this.state.testimonials

                    }
                )
            case MaintenanceDashboardConstant.Duration:
                return (
                    {
                        project_duration: this.state.projectDuration,
                        mtr_duration: this.state.mtrDuration,
                        fr_duration: this.state.frDuration,
                        project_category: this.state.projectCategory
                    }
                )
            case MaintenanceDashboardConstant.DebarApplicant:
                return (
                    {
                        applicant_perno: this.state.debarApplicantId,
                        start_date: this.state.debarStartDate,
                        end_date: this.state.debarEndDate
                    }
                )
            case MaintenanceDashboardConstant.OtherProjectApplicant:
                return (
                    {
                        applicant_perno: this.state.otherProjectApplicantId,
                        project_end_date: this.state.otherProjectEndDate,
                        project_start_date: this.state.otherProjectStartDate
                    }
                )
            case MaintenanceDashboardConstant.isApplicationRejectScreen:
                return (
                    {
                        reason: this.state.reason,
                    }
                )
            default:
                return null
        }
    }

    checkValidDuration = (e) => {
        this.setState({
            projectDuration: e.target.value,
            mtrDuration: e.target.value ? e.target.value / 2 : "",
            frDuration: e.target.value
        })

    }

    checkUser = (data) => {
        data.persist()
        var term = data.target.value;
        var re = new RegExp('^[0-9]{0,8}$');
        if (re.test(term)) {
            this.setState({ testimonialsEmpId: data.target.value })
        }
        else {
            data.preventDefault();
        }
    }

    getEmpDetail = (e) => {
        gettestimonyEmployeDetails(this.state.testimonialsEmpId)
            .then(res => {
                if (res.status == '200' || res.status == '201') {
                    this.setState({
                        loading: false,
                        message: '',
                        perNo: res.data.data.Perno ? res.data.data.Perno : '',
                        OrgUnit: res.data.data.Orgtx,
                        function: res.data.data.FunctionText ? res.data.data.FunctionText : '',
                        empName: res.data.data.CompName ? res.data.data.CompName : "",
                    })
                } else {
                    this.setState({
                        loading: false,
                        message: res.data.message,
                        // perNo: '',
                        // OrgUnit: "",
                        // function: '',
                        // empName: "",
                    });
                    toaster("warning", res.data.message ? res.data.message : "Something went wrong")
                }
            }).catch(err => {
                console.log("err", err)
                this.setState({
                    loading: false
                });
                toaster("warning", err?.data?.message ? err.data.message : "Something went wrong")
            })
    }

    intValCheck = () => {
        if (this.state.testimonialsEmpId.length >= 6) {
            this.getEmpDetail()
        }
        else {
            // this.setState({
            //     perNo: '',
            //     OrgUnit: "",
            //     function: '',
            //     empName: "",
            // })
        }
    }

    checkValidDebarEmpid = (data) => {
        data.persist()
        var term = data.target.value;
        var re = new RegExp('^[0-9]{0,8}$');
        if (re.test(term)) {
            this.setState({ debarApplicantId: data.target.value })
        }
        else {
            data.preventDefault();
        }
    }

    checkValidOtherProjectEmpid = (data) => {
        data.persist()
        var term = data.target.value;
        var re = new RegExp('^[0-9]{0,8}$');
        if (re.test(term)) {
            this.setState({ otherProjectApplicantId: data.target.value })
        }
        else {
            data.preventDefault();
        }
    }

    render() {
        const { loading, separationExitData, onDismiss, openFirstPopUp, filteredValue, updateFormData, dismisUpdateForm } = this.props
        console.log("updateFormData", updateFormData, "filteredValue", filteredValue, "this.state.entityTitle.length")
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{filteredValue.value}</h4>
                            <button type="button" className="close" onClick={dismisUpdateForm}>&times;</button>

                        </div>
                        <div className="">
                            <form className='todoModalBody gemsMaintenance_popup_scroller w-100'>
                                <div className='p-1 w-100'>
                                    <div>
                                        <div>
                                            {filteredValue.key == MaintenanceDashboardConstant.Area &&
                                                <>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.Area}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            onChange={(e) => this.setState({ areaTitle: e.target.value })}
                                                            value={this.state.areaTitle}
                                                            placeholder={"Enter area"}
                                                            maxLength={50}
                                                        />
                                                    </div>
                                                    <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.ColorCode}</div>
                                                    <label className='form-control p-placeholder p-placeholder-admin px-2 d-flex colorCodeInput' for='colorCode'>
                                                        <span className='w-70'>{this.state.areaColor}</span>
                                                        <input
                                                            id='colorCode'
                                                            type="color"
                                                            onChange={(e) => this.setState({ areaColor: e.target.value })}
                                                            value={this.state.areaColor}
                                                            maxLength={10}
                                                            placeholder='select color'
                                                        />
                                                    </label>
                                                </>
                                            }
                                            {filteredValue.key == MaintenanceDashboardConstant.Testimonials &&
                                                <>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.EmpId}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            onChange={(e) => this.checkUser(e)}
                                                            onKeyUp={(e) => { this.intValCheck() }}
                                                            value={this.state.testimonialsEmpId}
                                                            placeholder={"Enter employee id"}
                                                            maxLength={8}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.EmpName}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            // readOnly='true'
                                                            onChange={(e) => this.setState({ empName: e.target.value })}
                                                            value={this.state.empName}
                                                            placeholder={"Enter employee name"}
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.OrgUnit}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            // readOnly='true'
                                                            onChange={(e) => this.setState({ OrgUnit: e.target.value })}
                                                            value={this.state.OrgUnit}
                                                            placeholder={"Enter organization unit"}
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.Function}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            // readOnly='true'
                                                            onChange={(e) => this.setState({ function: e.target.value })}
                                                            value={this.state.function}
                                                            placeholder={"Enter function"}
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.Testimonials}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <textarea
                                                            className="form-control p-placeholder px-2"
                                                            type="text"
                                                            // readOnly={updateFormData.show}
                                                            onChange={(e) => this.setState({ testimonials: e.target.value })}
                                                            value={this.state.testimonials}
                                                            placeholder={"Enter testimonials"}
                                                            maxLength={1024}
                                                            rows={3}
                                                        />
                                                    </div>
                                                    <CharacterCounter
                                                        totalCount="1024"
                                                        currentCount={this.state.testimonials.length}
                                                    />
                                                </>
                                            }
                                            {filteredValue.key == MaintenanceDashboardConstant.Duration &&
                                                <>

                                                    <div className='p-sub-heading ml-3 required mb-1'>{MaintenanceDashboardConstant.ProjectCategory}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <Dropdown
                                                            data={this.state.noteTypeData}
                                                            selectedValue={(data) => this.setState({ projectCategory: data.value })}
                                                            value={this.state.projectCategory}
                                                            render={'value'}
                                                            height={'dependOnContent'}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.ProjectDuration}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            onChange={(e) => this.checkValidDuration(e)}
                                                            value={this.state.projectDuration}
                                                            placeholder={"Enter project duration"}
                                                            maxLength={256}
                                                            onWheel={(e) => e.target.blur()}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.mtrDuration}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            readOnly='true'
                                                            // onChange={(e) => this.setState({ mtrDuration: e.target.value })}
                                                            value={this.state.mtrDuration}
                                                            placeholder={"Enter mtr duration"}
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.frDuration}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            readOnly='true'
                                                            // onChange={(e) => this.setState({ frDuration: e.target.value })}
                                                            value={this.state.frDuration}
                                                            placeholder={"Enter fr duration"}
                                                            maxLength={256}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            {filteredValue.key == MaintenanceDashboardConstant.DebarApplicant &&
                                                <>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.ApplicationNo}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            // readOnly={updateFormData.show}
                                                            onChange={(e) => this.checkValidDebarEmpid(e)}
                                                            value={this.state.debarApplicantId}
                                                            placeholder={"Enter the applicant employee id"}
                                                            maxLength={8}
                                                        />
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='mb-3 mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.StartDate}</div>
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                                                    type="date"
                                                                    value={this.state.debarStartDate}
                                                                    onChange={(e) => this.setState({ debarStartDate: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mb-3 mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.EndDate}</div>
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                                                    type="date"
                                                                    value={this.state.debarEndDate}
                                                                    onChange={(e) => this.setState({ debarEndDate: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {filteredValue.key == MaintenanceDashboardConstant.OtherProjectApplicant &&
                                                <>
                                                    < div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.ApplicationNo}</div>
                                                    <div className='p-placeholder-text mb-2'>
                                                        <input
                                                            className="form-control p-placeholder p-placeholder-admin px-2"
                                                            type="text"
                                                            // readOnly={updateFormData.show}
                                                            onChange={(e) => this.checkValidOtherProjectEmpid(e)}
                                                            value={this.state.otherProjectApplicantId}
                                                            placeholder={"Enter the applicant employee id"}
                                                            maxLength={8}
                                                        />
                                                    </div>
                                                    <div className='row justify-content-between'>
                                                        <div className='mb-3 mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.StartDate}</div>
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                                                    type="date"
                                                                    value={this.state.otherProjectStartDate}
                                                                    onChange={(e) => this.setState({ otherProjectStartDate: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='mb-3 mt-2'>
                                                            <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.EndDate}</div>
                                                            <div className='p-placeholder-text'>
                                                                <input
                                                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                                                    type="date"
                                                                    value={this.state.otherProjectEndDate}
                                                                    onChange={(e) => this.setState({ otherProjectEndDate: e.target.value })}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {filteredValue.key == MaintenanceDashboardConstant.isApplicationRejectScreen &&
                                                <>
                                                    <div className='p-sub-heading ml-3 required'>{MaintenanceDashboardConstant.Reason}</div>
                                                    <div className='p-placeholder-text'>
                                                        <textarea
                                                            className="form-control p-placeholder px-3"
                                                            type="text"
                                                            maxLength={100}
                                                            // readOnly={updateFormData.show}
                                                            onChange={(e) => this.setState({ reason: e.target.value })}
                                                            value={this.state.reason}
                                                            placeholder={"Enter the Reason"}
                                                            rows={3}
                                                        >
                                                        </textarea>
                                                    </div>
                                                    <CharacterCounter
                                                        totalCount="100"
                                                        currentCount={this.state.reason.length}
                                                    />
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-end'>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={(e) => dismisUpdateForm()} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='UPDATE'
                                            className='popup-confirm-btn'
                                            onClick={(e) => this.validateForm()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

export default UpdateFormPopUp;