import React, { Component } from 'react';
import '../conflictOfInterest/COIStyle.css';
import '../../../../assets/styles/popupStyles.css';

const what_is_conflict_of_interest = `A conflict of interest, actual or potential,could be any known activity, transaction, relationship or service engaged in by an employee, his/her Immediate Family Member or Relative or person with Close Personal Relationship, which may cause concern
    that the employee could not or might not be able to fairly,independently or objectively perform his/her duties to our Company.`
const A = `A) engages in a business, activity or relationship with anyone who is party to a transaction with our Company.`
const B = `B) is in a position to derive an improper benefit, personally or for any immediate family member or relative or for any person in a close personal relationship, by making or influencing decisions relating to any transaction.`
const C = `C) conducts business on behalf of our Company or is in a position to influence a decision with regard to our Company's business with a Value-Chain partner or customer or competitor where an immediate family member or relative of, or a person in close personal relationship with, an employee or executive director is a principal officer or representative, resulting in a personal benefit or a benefit to the relative.`
const D = `D) Is in a position to influence decisions with regard to award of benefits such as increase in salary or other remuneration, posting, promotion or recruitment of an immediate family member or relative or a person in close personal relationship employed in our Company.`
const E = `E) undertakes an activity by which the interest of our Company can be compromised or defeated; or`
const F = `F) does anything by which an independent judgement of our Company's best interest cannot be exercised.`
const tata_Code_Of_Conduct_Policy = 'https://www.tata.com/content/dam/tata/pdf/Tata%20Code%20Of%20Conduct.pdf'
const coiPolicy = 'https://tatamotors.sharepoint.com/:w:/r/sites/c-app/s-MTMDev/_layouts/15/Doc.aspx?sourcedoc=%7B731F497A-EAC6-4BE2-82F2-1CF48EEA41D2%7D&file=Conflict%20of%20interest%20policy%20_V1.docx&action=default&mobileredirect=true&cid=8f3ef21e-b159-44c8-86ee-800f2cfdcdde'
class Declaration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    render() {
        return (
            <div className='col-md-12 justify-content-center align-item-center px-0'>
                <div className='coi-card bg-white px-0'>
                    <div className='justify-content-between mt-3'>
                        <label className='card-heading mx-4 mt-1'>Conflict of Interest Declaration : Conflict of Interest Policy</label>
                        <div className='row'>
                            <div className='row'>
                                <a href={coiPolicy} target="_blank">
                                    <label
                                        className='tata-policy-link mr-4'
                                        type='button'
                                    >COI Policy</label>
                                </a>
                            </div>
                            <div className='horizontal-lines mr-4'></div>
                            <div className='row'>
                                <a href={tata_Code_Of_Conduct_Policy} target="_blank">
                                    <label
                                        className='tata-policy-link mr-4'
                                        type='button'
                                    >Tata Code Of Conduct Policy</label>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <h1 className='coi-text mx-4 mt-4'>What is Conflict Of Interest</h1>
                        <h2 className='coi-sub-text mx-4 mr-4'>{what_is_conflict_of_interest}</h2>
                    </div>
                    <div className=''>
                        <h1 className='coi-text mx-4'>Examples of Potential Conflicts of Interest :</h1>
                        <h2 className='coi-sub-text mx-4'>Actual or potential, arises where,directly or indirectly, an employee or executive director :</h2>
                        <h2 className='coi-option-text mr-4'>{A}</h2>
                        <h2 className='coi-option-text mr-4'>{B}</h2>
                        <h2 className='coi-option-text mr-4'>{C}</h2>
                        <h2 className='coi-option-text mr-4'>{D}</h2>
                        <h2 className='coi-option-text mr-4'>{E}</h2>
                        <h2 className='coi-option-text mr-4'>{F}</h2>
                    </div>
                    <div className='d-flex justify-content-center align-item-center mt-4 mb-4'>
                        <input
                            type="button"
                            className='btn btn-primary common-btn declaration_button_text px-3'
                            value="Proceed to Declaration"
                            onClick={() => this.props.proceed()}
                        ></input>
                    </div>
                </div>
                {/* {this.state.show &&
                    <div className="modal popup-box" id="leaveRequestModal">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <Stage1ConfirmPopup data={this.state.show} />
                        </div>
                    </div>
                } */}
            </div>
        );
    }
}

export default Declaration;