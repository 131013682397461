import React, { Component } from 'react'

class CharacterCounter extends Component {

    render() {
        const {currentCount, totalCount} = this.props
        return (
            <div className='align-item-center-noflex flex-direction-row m-5px'>
                <span className='time-text'>
                    {currentCount} /{totalCount}
                </span>
            </div>
        )
    }
}
export default CharacterCounter
