import React, { Component } from 'react';
import moment from 'moment';
import '../../employeeScreens/transfer/Transfer.css';
class NotificationDetailsPopup extends Component {
    render() {
        const { data, type, title } = this.props
        if (!this.props.data) return null;
        return (
            <div className="modal popup-box">
                {type === 'log-In-Image' &&
                    <div className="declaration-box p-0 w-40">
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Image Title :</div>
                                        <div className="text14_light mt-1">{data.image_title ? data.image_title : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Hyperlink :</div>
                                        <div className="text14_light mt-1">{data.image_hyperlink ? data.image_hyperlink : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold viewImage">Image :</div>
                                        <div className="text14_light mt-1"><img src={data.image_link ? data.image_link : '-'} alt='' className='notification-details-image' /></div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'announcementDetails' &&
                    <div className="declaration-box p-0 w-40">
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.announcement_title ? data.announcement_title : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold">Attachment :</div>
                                        <div className="text14_light mt-1">{data.announcement_image ? data.announcement_image : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.announcement_description ? data.announcement_description : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Type :</div>
                                        <div className="text14_light mt-1">{data.announcement_type ? data.announcement_type : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by ? data.created_by : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Hyperlink :</div>
                                        <div className="text14_light mt-1">{data.image_link ? data.image_link : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold">Total response :</div>
                                        <div className="text14_light mt-1">{data.total_response ? data.total_response : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created date & time :</div>
                                        <div className="text14_light mt-1">{data.created_datetime ? moment(data.created_datetime).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'tataBuzzDetails' &&
                    <div className="declaration-box p-0 w-40" >
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.news_title ? data.news_title : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by ? data.created_by : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.news_description ? data.news_description : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created date & time :</div>
                                        <div className="text14_light mt-1">{data.created_datetime ? moment(data.created_datetime).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Modified date & time :</div>
                                        <div className="text14_light mt-1">{data.modified_datetime ? moment(data.modified_datetime).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    type === 'upcomingOccasionsDetails' &&
                    <div className="declaration-box p-0 w-40">
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Title :</div>
                                        <div className="text14_light mt-1">{data.event_title ? data.event_title : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by ? data.created_by : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>

                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created date & time :</div>
                                        <div className="text14_light mt-1">{data.created_datetime ? moment(data.created_datetime).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Modified date & time :</div>
                                        <div className="text14_light mt-1">{data.modified_datetime ? moment(data.modified_datetime).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    type === 'LeadershipAnnouncement' &&
                    <div className="declaration-box p-0 w-40">
                        <div className="modal-header justify-content-center mt-2 ml-2">
                            <h4 className="modal_title black">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>

                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className=" popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Description :</div>
                                        <div className="text14_light mt-1">{data.announcement_description ? data.announcement_description : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">

                                    <div className="font-weight-bold text-dark text-left w-50 popup_text">
                                        <div className="text14_bold">Attachment :</div>
                                        <div className="text14_light mt-1">{data.announcement_image ? data.announcement_image : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created by :</div>
                                        <div className="text14_light mt-1">{data.created_by ? data.created_by : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex align-item-center flex-direction-row mb-3">
                                    <div className="popup_text text-left font-weight-bold text-dark">
                                        <div className="text14_bold">Hyperlink :</div>
                                        <div className="text14_light mt-1">{data.image_link ? data.image_link : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                                <div className="d-flex  align-item-center w-100 mb-3">
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Created date & time :</div>
                                        <div className="text14_light mt-1">{data.created_at ? moment(data.created_at).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" >
                                        <div className="text14_bold">Updated date & time :</div>
                                        <div className="text14_light mt-1">{data.updated_at ? moment(data.updated_at).format("DD-MM-YYYY") : '-'}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
        );
    }
}

export default NotificationDetailsPopup;