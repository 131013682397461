import React from 'react'
import './CommonCard.css'

function CommonCard(props) {
    const { children, onClick } = props
    return (
        <div className="card-inner pointer card-style instruction h-100 w-100" onClick={onClick}>
            <div className='flex-direction-row h-100 w-100'>
                <span className='align-item-center'>
                    <span className='vertical-bar' ></span>
                </span>
                {children}
            </div>
        </div>
    )
}

export default CommonCard