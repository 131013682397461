import moment from "moment"

export const readOnlyLabel = {
    SelectTravelType: 'Travel Type :',
    EnterTravelPurpose: 'Travel Purpose :',
    FromPlace: 'From Place :',
    ToPlace: 'To Place :',
    SelectFromDate: 'From Date :',
    SelectToDate: 'To Date :',
    SelectExpenseType: 'Expense Type :',
    SelectSubExpenseType1: 'Category',
    SelectSubExpenseType2: 'Sub Category',
    EnterAmountValue: 'Standard Amount :',
    maxAmount: 'Max Amount :',
    startReading: 'Start Reading :',
    endReading: 'End Reading :',
    distanceCalc: 'Distance Calculated :',
    checkIn: 'Check In :',
    checkOut: 'Check Out :',
    totalDays: 'Total Days :',
    UploadBill: 'Bills :',
    Remark: 'Remark :',
    expenseType: 'Expense Type',
    fromTime: 'Start Time :',
    toTime: ' End Time :',
    totalApprovalAmount: 'Total Approval Amount :',
    totalAmount: 'Total Amount :'
}

export const detailExpense = [
    { key: "expense_type", title: 'Expense Type :', isOptional: false },
    { key: "sub_expense_type1", title: 'Category :', isOptional: false },
    { key: "sub_expense_type2", title: 'Sub Category:', isOptional: false },
    { key: "total_expense_amount", title: 'Claim Amount : ', isOptional: false },
    { key: "permissible_expense_amount", title: 'Permissible Amount :', isOptional: false },
    { key: "approved_expense_amount", title: 'Approvable Amount : ', isOptional: false },
    { key: "start_reading", title: 'Start Reading : ', isOptional: true },
    { key: "end_reading", title: 'End Reading : ', isOptional: true },
    { key: "distance_calculated", title: 'Distance Calculated : ', isOptional: true },
    { key: "from_expense_date", title: 'From Date : ', isOptional: true },
    { key: "to_expense_date", title: 'To Date : ', isOptional: true },
    { key: "total_days", title: 'Total Days : ', isOptional: true },
    { key: "remark", title: 'Remark :', isOptional: false },
]

export const employeeDetails = [
    { key: 'travel_type', title: 'Travel Type :' },
    { key: 'purpose', title: 'Travel Purpose :' },
    { key: 'from_place', title: 'From Place :' },
    { key: 'to_place', title: 'To Place :' },
    { key: 'from_date', title: 'From Date :' },
    { key: 'to_date', title: 'To Date :' },
    { key: 'from_time', title: 'From Time :' },
    { key: 'to_time', title: 'To Time :' },
    { key: 'total_emp_claim_amount', title: 'Total Claim Amount :' },
    { key: 'total_permissible_claim_amount', title: 'Total Permissible Amount :' },
    { key: 'total_approvable_claim_amount', title: 'Total Approvable Amount :' },
    { key: "total_approved_claim_amount", title: "Total Approved Amount :"},
    { key: "claim_status", title: "Claim Status :"},
    { key: 'dem_rejection_remark', title: 'DEM Rejection Remark :' },
    { key: 'misc_rejection_remark', title: 'L3 and Above Rejection Remark :' },
    { key: 'admin_rejection_remark', title: 'Admin Rejection Remark :' },
]

export const employeeDetailsForm = {
    EmployeeDetails: 'Employee Details',
    ManagerDetails: 'Manager Details',
    AddNewExpense: '+ Add New Expense',
    selectExpenses: 'Select Expenses',
    claimDetails: 'Claim Details',
    Name: 'Name :',
    Grade: 'Grade :',
    PayrollArea: 'Payroll Area :',
    CostCenter: 'Cost Center :',
    BillableCostCenter: 'Billable Cost Center :',
    PersonnelArea: 'Personnel Area :',
    PersonnelSubArea: 'Personnel Sub Area :',
    Company: 'Company :'
}

export const employeeDetailsFormObj = [
    { key: "Perno", title: 'Emp ID :' },
    { key: "CompName", title: 'Name :' },
    { key: "Esgtxt", title: 'Grade :' },
    { key: "Payarea", title: 'Payroll Area :' },
    { key: "Costcenter", title: 'Cost Center :' },
    { key: "PersArea", title: 'Personnel Area :' },
    { key: "PSubarea", title: 'Personnel Sub Area :' },
    { key: "Ccodetxt", title: 'Company :' }
]

export const constantValues = {
    SelectManager: 'Select Manager :',
    employeeId: 'Employee ID',
    employeeId2: 'Employee ID ',
    Status: 'Status : ',
    REJECT: 'REJECT',
    APPROVE: 'APPROVE',
    TotalExpenseAmt: "Total Claimed Expense Amount",
    TotalPermissibleAmt: "Total Permissible Amount",
    TotalApprovedAmt: "Total Approval Amount",
    employeeName: 'Employee Name :',
    employeeId: 'Employee ID :',
    ApproverName: 'Approver Name :',
    ApproverId: 'Approver ID :',
    L3Id: 'L3 and Above ID :',
    L3Name: 'L3 and Above Name :',
    claimStatus: "Claim Status :",
    Attachments: "Attachments",
    expenseDetails: "Expense Details",
    claimDetails: "Claim Details",
    demDetails: "DEM Details",
    employeeDetails: "Employee Details"
}

export const expepnseTypeLabel = {
    srNo: 'Sr No.',
    expenseType: 'Expense Type',
    category: 'Category',
    subCategory: 'Sub Category',
    totalAmount: 'Claim Amount',
    maxAmount: 'Permissible Amount',
    approvableAmt: 'Apprvovable Amount',
    approvedAmt: 'Approved Amount',
    remark: 'Remark',
    viewDetails: 'View Details'
}

export const checkdropDownLabel = {
    pendingByDem: 'Pending for DEM approval',
    pendingByL3: 'Pending for L3 & Above approval'
}

export const AccommodationSchema = {
    allowance: ["checkin", "checkout", "totalDays", "stdAmt", "totalAmt", "bill", "remark"],
    consolidated: ["totalDays", "stdAmt", "totalAmt", "remark"]
}
export const initialFields = {
    "expense_type": null,
    "sub_expense_type1": null,
    "sub_expense_type2": null,
    "max_expense_amount": null,
    "start_reading": null,
    "end_reading": null,
    "distance_calculated": null,
    "from_expense_date": null,
    "to_expense_date": null,
    "total_expense_days": null,
    "total_expense_time": null,
    "claim_expense_amount": null,
    "extra_expense_amount": null,
    "total_expense_amount": null,
    "permissible_expense_amount": null,
    "approved_expense_amount": null,
    "remark": null,
    "bill_path": []
}
const fetchCurrentDate = new Date();
export const initialClaimForm = {
    loading: false,
    employeeId: '',
    selectedTravelType: '',
    selectedTravelPurpose: '',
    fromPlace: '',
    toPlace: '',
    fromDate: '',
    toDate: '',
    fromTime: '',
    toTime: '',
    toTimeFlag: true,
    expenseTypeFields: false,
    selecetdExpenseType: '',
    selectedSubExpenseType1: '',
    selectedSubExpenseType2: '',
    cityData: [],
    employeeDetailData: {},
    managerDetailData: [],
    singleManagerDetailData: {},
    expenseTypeDropDownListing: [],
    subExpenseTypeDropDownListing: [],
    expenseType1: [],
    expenseType2: [],
    expenseForm: [],
    selectedExpenseTypes: [],
    selectedSubExpenseTypes1: [],
    selectedSubExpenseTypes2: [],
    expenseDetails: [],
    totalExpenseAmount: "",
    totalPermissibleAmount: "",
    totalApprovedAmount: "",
    tempForm: {},
    isPrimaryDataValidated: false,
    maxFormValue: 1,
    currentDate: moment(fetchCurrentDate).format('YYYY-MM-DD'),
    isPopUpOpen: false,
    isSubmitPopUpOpen: false,
    isBackAction: false,
    isPopUpLoading: false,
    isClaimExistsMsg: "",
    errors: {
        fromPlace: 0,
        toPlace: 0,
        selectedTravelType: 0,
        selectedTravelPurpose: 0,
        fromDate: 0,
        toDate: 0,
        fromTime: 0,
        toTime: 0,
        expenseDetails: []
    }
}
export const toExcludeExpenseTypes = ["Consolidated allowance", "Incidental allowance", "Daily allowance", "Miscellaneous allowance"]

export const employeeDetailsFieldsPreview = [
    { label: "Emp ID", key: 'Perno' },
    { label: "Name", key: 'CompName' },
    { label: "Level", key: 'Esgtxt' },
    { label: "Grade", key: 'Esubgroup' },
    { label: "Cost Center", key: 'Costcenter' },
    { label: "Personal Area", key: 'PersArea' },
    { label: "Personal Sub Area", key: 'Payarea' },
    { label: "Company", key: 'Ccodetxt' },
]
export const expenseTableColsPreview = [
    { title: 'Sr No.', width: '10%' },
    { title: 'Expense Type', width: '10%' },
    { title: 'Category', width: '10%' },
    { title: 'Sub Category', width: '10%' },
    { title: 'Claim Amount', width: '10%' },
    { title: 'Permissible Amount', width: '10%' },
    { title: 'Approval Amount', width: '10%' },
    { title: 'Attachments', width: '20%' },
]
export const expenseDetailsFieldsPreview = [
    { label: "Expense Type", key: 'expense_type', type: "text" },
    { label: "Category", key: 'sub_expense_type1', type: "text" },
    { label: "Sub Category", key: 'sub_expense_type2', type: "text" },
    { label: "Start Reading", key: 'start_reading', type: "text" },
    { label: "End Reading", key: 'end_reading', type: "text" },
    { label: "Total Distance", key: 'distance_calculated', type: "text" },
    { label: "From Date", key: 'from_expense_date', type: "date" },
    { label: "To Date", key: 'to_expense_date', type: "date" },
    { label: "Total Expense Days", key: 'total_expense_days', type: "text" },
    { label: "Total Expense Time", key: 'total_expense_time', type: "text" },
    { label: "Claimed Amount", key: 'claim_expense_amount', type: "text" },
    { label: "Extra Amount", key: 'extra_expense_amount', type: "text" },
    { label: "Total Amount", key: 'total_expense_amount', type: "text" },
    { label: "Permissible Amount", key: 'permissible_expense_amount', type: "text" },
    { label: "Approval Amount", key: 'approved_expense_amount', type: "text" },
]
export const billDetailsColPreview = [
    { title: "Sr No.", width: '5%' },
    { title: "Attachment", width: '5%' },
    { title: "View", width: '5%' },
]

export const claimDetailsFormPreview = [
    { key: "selectedTravelType", label: "Travel Type", type: "text" },
    { key: "selectedTravelPurpose", label: "Travel Purpose", type: "text" },
    { key: "fromPlace", label: "From Place", type: "text" },
    { key: "toPlace", label: "To Place", type: "text" },
    { key: "fromDate", label: "From Date", type: "date" },
    { key: "toDate", label: "To Date", type: "date" },
    { key: "fromTime", label: "From Time", type: "text" },
    { key: "toTime", label: "To Time", type: "text" },
    { key: "totalExpenseAmount", label: "Total Claim Amount", type: "text" },
    { key: "totalPermissibleAmount", label: "Total Permissible Amount", type: "text" },
    { key: "totalApprovedAmount", label: "Total Approval Amount", type: "text" },
]
export const errorCodes = {
    fromPlace: { 1: "Please select the start city" },
    toPlace: { 1: "Please select the Destination city" },
    selectedTravelType: { 1: "Please select the travel type" },
    selectedTravelPurpose: { 1: "Please enter travel purpose" },
    fromDate: { 1: "Select an appropriate date", 2: "Select date less than or equal to To Date" },
    toDate: { 1: "Select an appropriate date" },
    fromTime: { 1: "Select an appropriate time", 2: "Select time less than To time", 3: "From time and to time should not be same" },
    toTime: { 1: "Select an appropriate time" },
    expense_type: { 1: "Please select the Expense Type" },
    sub_expense_type1: { 1: "Please select the category" },
    sub_expense_type2: { 1: "Please select the sub-category" },
    start_reading: { 1: "Please enter start reading" },
    end_reading: { 1: "Please enter end reading", 2: "End reading should be greater than start reading" },
    claim_expense_amount: { 1: "Please enter the amount" },
    permissible_expense_amount: { 1: "Error occurred in calculation" },
    remark: { 1: "Remarks are mandatory" },
    bill_path: { 1: "Bills are mandatory" },
    to_expense_date: { 1: "Select an appropriate date" },
    from_expense_date: { 1: "Select an appropriate date" },
    total_expense_days: { 1: "Error in calculation" },
    total_expense_time: { 1: "Error in calculation" },

}
export const initialExpenseError = {
    expense_type: 0,
    sub_expense_type1: 0,
    sub_expense_type2: 0,
    max_expense_amount: 0,
    start_reading: 0,
    end_reading: 0,
    distance_calculated: 0,
    from_expense_date: 0,
    to_expense_date: 0,
    total_expense_days: 0,
    total_expense_time: 0,
    claim_expense_amount: 0,
    extra_expense_amount: 0,
    total_expense_amount: 0,
    permissible_expense_amount: 0,
    approved_expense_amount: 0,
    remark: 0,
    bill_path: 0
}