import React, { Component } from 'react'
import "react-circular-progressbar/dist/styles.css";
import './LeaveScreen.css';
import withdraw from '../../../../assets/images/applyLeave/withdraw.png';
import 'antd/dist/antd.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as KeycloakActions from '../login/KeyCloakAction';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ConformationPopUpLatest from '../../../shared/confirmationPopup/ConformationPopUpLatest';
import Dropdown from '../../../shared/components/Dropdown';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import LeaveDetailPopup from './LeaveDetailPopup';
import { LeaveHistoryCardSkeleton_New } from '../../../shared/skeleton/Skeletons';
import { toast } from 'react-toastify';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import PreviousLeaveHistory from '../previousYearLeave/PreviousLeaveHistory';

const previousLeave = 'previousLeave'
const currentLeave = 'currentLeave'
class LeaveHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyLeave: false,
            loading: false,
            reason: '',

            approver_name: '',
            deleteLeaveData: '',

            approver: '',
            leaveRequestStatus: [],
            leaveRequestTypes: [],
            leaveRequestDetails: [],
            leaveRequestsMonth: [],
            leaveCatCode: 'All',
            leaveStatusCode: 'All',
            leaveMonthCode: (new Date().getMonth() + 1),
            showConfirm: false,
            leaveCatValue: 'All Leave type',
            leaveStatusValue: 'All Status',
            leaveMonthValue: 'February',
            holidayList: [],
            isShow: false,
            selectedData: {},

            is_service_Unavailable: false,  // service unavailble from backend
            leave_history_Service_reason: '',

            is_previous_year_leave: false,
            previous_year_leaves_reason: '',
        }
    }

    componentDidMount() {
        this.checkService();
        this.getLeaveRequestMasterData();
    }

    getLeaveRequestMasterData() {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea,
            emp_id: this.props.loginData.userData.Perno
        }
        fetchApiCall(Config.getLeaveRequestMasterData, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        leaveRequestStatus: res.data.data.leave_status,
                        leaveRequestTypes: res.data.data.leave_category,
                        leaveRequestsMonth: res.data.data.month_list,
                        leaveMonthValue: moment(new Date()).format("MMMM"),
                        loading: false
                    });
                    let tempLeaveTypeData = [];
                    if (this.props.loginData.userData.Gender == 'M') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D0");
                    } else if (this.props.loginData.userData.Gender == 'F') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D3");
                    }
                    this.setState({
                        leaveRequestTypes: tempLeaveTypeData
                    });

                    let temp = {
                        value: 'All',
                        code: 'All'
                    }
                    this.state.leaveRequestStatus.unshift(temp);
                    this.state.leaveRequestTypes.unshift(temp);
                    this.state.leaveRequestsMonth.unshift(temp)
                    this.getLeaveListingData(this.state.leaveMonthCode, this.state.leaveCatCode, this.state.leaveStatusCode, 'month');
                }
                else {
                    this.setState({ loading: false })
                }

            }).catch(err => {
                this.setState({ loading: false })
                console.log(err)
            })
    }

    getLeaveListingData = (monthData, leaveData, statusData, type) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            month: monthData,
            leave_category_code: leaveData,
            leave_status_code: statusData,
            filter_type: type,
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea
        }
        fetchApiCall(Config.getLeaveRequests, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        leaveRequestDetails: res.data.data.leave_requests ? res.data.data.leave_requests : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        leaveRequestDetails: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                console.log(err)
            })
    }

    filterLeaveByMonth = (listValue) => {
        this.setState({
            leaveMonthCode: listValue.code,
            leaveMonthValue: listValue.value
        }, () =>
            this.getLeaveListingData(this.state.leaveMonthCode, this.state.leaveCatCode, this.state.leaveStatusCode, 'month'))
    }

    filterLeaveByType(listValue) {
        this.setState({
            leaveCatCode: listValue.code,
            leaveCatValue: listValue.value
        }, () =>
            this.getLeaveListingData(this.state.leaveMonthCode, this.state.leaveCatCode, this.state.leaveStatusCode, 'leave_type'))
    }

    filterLeaveByStatus(listValue) {
        this.setState({
            leaveStatusCode: listValue.code,
            leaveStatusValue: listValue.value
        }, () =>
            this.getLeaveListingData(this.state.leaveMonthCode, this.state.leaveCatCode, this.state.leaveStatusCode, 'leave_status'))
    }

    showData = (data) => {
        this.getLeaveReason(data.leave_request_id)
        this.setState({
            selectedData: data,
            isShow: true
        })
    }

    getLeaveReason(LeaveRequestID) {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getApprovedLeaveDetail,
            {
                leave_request_id: LeaveRequestID,
            }, headers)
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        reason: res.data.reason,
                        approver_name: res.data.approver_name,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        reason: '',
                        approver_name: '',
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    leaveDeleteConfirmation = (leaveData) => {
        this.setState({
            deleteLeaveData: leaveData,
            showConfirm: true,
        });
    }

    deleteLeave = () => {
        this.setState({
            loading: true,
            showConfirm: false,
        });
        console.log("deleteLeave: ", this.state.deleteLeaveData)
        var leaveList = this.state.deleteLeaveData
        const req = {
            emp_id: this.props.loginData.userData.Perno,
            leave_request_id: leaveList.leave_request_id,
            from_date: leaveList.from_date,
            to_date: leaveList.to_date,
            leave_status: leaveList.leave_status_code,
            approver_email: this.props.loginData.managerData.Imailid,
            emp_name: this.props.loginData.userData.CompName,
            leave_type_code: leaveList.leave_type_code,
            leave_category_code: leaveList.leave_category_code,
            approver: this.props.loginData.userData.Reporting,
            reason: leaveList.reason ? leaveList.reason : '',
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea,
            emp_company_code: this.props.loginData.userData.CompCode,
            manager_company_code: this.props.loginData.managerData.CompCode
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.deleteLeave, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({ loading: false, showConfirm: false });
                    this.getLeaveListingData(this.state.leaveMonthCode, this.state.leaveCatCode, this.state.leaveStatusCode, 'month');
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    console.log(res.data.message)
                    this.setState({
                        loading: false,
                        showConfirm: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                console.log(err)
            })
    }

    hideDeleteButton = (statusCode) => {
        let statusCodeArray = [3, 5, 6, 9];

        let temp = statusCodeArray.find(status => status == statusCode);
        if (temp) {
            return true;
        } else {
            return false;
        }
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "leave_history" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    leave_history_Service_reason: item.reason
                })
            }
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason
                })
            }
        })
    }

    render() {
        const { loading } = this.state
        return (
            <div className=''>
                <LoaderIndicator loading={this.state.loading} />
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-40vh justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.leave_history_Service_reason} width={true} />
                    </div>
                    :
                    <>
                        <div className='row justify-content-between align-item-center p-2'>
                            <div className='leaveHistoryText'>{'Leave History'}</div>
                            <div className='d-flex row'>
                                <div>
                                    <Dropdown
                                        data={this.state.leaveRequestsMonth}
                                        selectedValue={(data) => this.filterLeaveByMonth(data)}
                                        value={this.state.leaveMonthValue}
                                        render={'value'}
                                        arrow={'true'}
                                        height={'height'}
                                    />
                                </div>
                                <div className='ml-2'></div>
                                <Dropdown
                                    data={this.state.leaveRequestTypes}
                                    selectedValue={(data) => this.filterLeaveByType(data)}
                                    value={this.state.leaveCatValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'height'}
                                />
                                <div className='ml-2'></div>
                                <Dropdown
                                    data={this.state.leaveRequestStatus}
                                    selectedValue={(data) => this.filterLeaveByStatus(data)}
                                    value={this.state.leaveStatusValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'height'}
                                />
                            </div>

                        </div>
                        <div>
                            {loading ?
                                <div>
                                    <LeaveHistoryCardSkeleton_New />
                                </div>
                                :
                                <div className={!this.state.is_previous_year_leave ? 'scroll-y h-23vh p-2 m-5px pt-0' : 'scroll-y h-30vh p-2 m-5px pt-0'}>
                                    {this.state.leaveRequestDetails.length != 0 ?
                                        <table className="table leaveTble">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='thead'>Sr.No.</th>
                                                    <th scope="col" className='thead'>Leave Type</th>
                                                    <th scope="col" className='thead'>From</th>
                                                    <th scope="col" className='thead'>To</th>
                                                    <th scope="col" className='thead'>Status</th>
                                                    <th scope="col" className='thead'>View</th>
                                                    <th scope="col" className='thead'>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.leaveRequestDetails.map((listValue, index) => {
                                                    return (
                                                        <tr key={index} data-toggle="modal" data-target="#approvalDetailsModal">
                                                            <td><div className="tdLeaveHistory">{index + 1}</div></td>
                                                            <td>
                                                                <div className="td d-flex">
                                                                    <>
                                                                        {this.state.leaveRequestTypes.map((item, i) =>
                                                                            <div key={i}>
                                                                                {item.value === listValue.leave_category ?
                                                                                    <div className='colorCategory mr-2'
                                                                                        style={{
                                                                                            backgroundImage: `linear-gradient${item.color}`
                                                                                        }}
                                                                                    >
                                                                                    </div> : null
                                                                                }
                                                                            </div>
                                                                        )}</>
                                                                    <div className="tdLeaveHistory">
                                                                        {listValue.leave_category}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><div className="tdLeaveHistory">{moment(listValue.from_date).format("DD-MM-YYYY")}</div></td>
                                                            <td><div className="tdLeaveHistory">{moment(listValue.to_date).format("DD-MM-YYYY")}</div></td>
                                                            <td><div className="tdLeaveHistory" style={{ color: listValue.leave_status_color }}>{listValue.leave_status}</div></td>
                                                            <td>
                                                                <div
                                                                    className='withdrawImg'
                                                                    data-toggle="modal"
                                                                    data-target="#approvalDetailsModal"
                                                                    onClick={() => this.showData(listValue)}
                                                                >
                                                                    <img
                                                                        src={view_action}
                                                                        alt="delete"
                                                                        title='withdraw'
                                                                        data-toggle='modal' data-target='#DeleteConfirmationModal' />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {!this.hideDeleteButton(listValue.leave_status_code) ?
                                                                    <div
                                                                        className='withdrawImg'
                                                                        data-toggle="modal"
                                                                        data-target="#approvalDetailsModal"
                                                                        onClick={() => this.leaveDeleteConfirmation(listValue)}
                                                                    >
                                                                        <img
                                                                            src={withdraw}
                                                                            alt="delete"
                                                                            title='withdraw'
                                                                            data-toggle='modal' data-target='#DeleteConfirmationModal' />
                                                                    </div> : <div className="table-data ml-1">-</div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        :
                                        <div className='mt-5'>
                                            <DataNotFound />
                                        </div>
                                    }
                                </div>
                            }
                            {this.state.isShow &&
                                <LeaveDetailPopup
                                    onDismiss={() => this.setState({ isShow: false })}
                                    type={'leave_request'}
                                    approver={this.state.approver_name}
                                    reason={this.state.reason}
                                    data={this.state.selectedData} />
                            }
                            {this.state.showConfirm &&
                                <ConformationPopUpLatest
                                    title={"Delete Confirmation"}
                                    text={'Are you sure you want to delete?'}
                                    onDismiss={() => this.setState({ showConfirm: false })}
                                    deleteData={() => this.deleteLeave()}
                                    action={'delete'}
                                />
                            }
                        </div>
                    </>
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tokenActions: bindActionCreators(KeycloakActions, dispatch)
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(LeaveHistory)