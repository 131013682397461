import * as type from '../../../../redux/action/ActionType';

const initialState = {
    availableServiceData: [],
    activePopup: [],
    activePopupCount: {}
}
export const activePopupData = (state= initialState, action) => {
    switch(action.type) {
        case type.GET_ACTIVE_POPUP:
            return {
                ...state,
                activePopup: action.payload,
            }

        case type.GET_ACTIVE_POPUP_COUNT:
            let tempCountState = state.activePopupCount;
            let temp = action.payload;

            if(!tempCountState.hasOwnProperty(temp)) {
                tempCountState[temp] = 1
            } else {
                tempCountState[temp] = tempCountState[temp] + 1;
            }
            return {
                ...state,
                activePopupCount: tempCountState,
            }  

        case type.GET_AVAILABLE_SERVICE_DATA:
            return {
                ...state,
                availableServiceData: action.payload,
            }
        case type.AVAILABLE_SERVICE_DATA_LOGOUT:
            return {
                availableServiceData: [],
                activePopup: [],
                activePopupCount: {}
            }
        default:
            return state;
    }

}