//first popUp
import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { admin_notification_type } from '../../../../utils/Utils';

class AddNewTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screenTypeValue: 'Select notification type',
            screenTypeCode: '',
            typeData: admin_notification_type(),
            dropdownValue: '',
            showPopup: false
        }
    }

    changeNotificationType = (data) => {
        this.setState({
            screenTypeCode: data.code,
            screenTypeValue: data.value,

        },
        this.props.dropdownValue(data)
        )
    }

    validateTasks = () => {
        this.setState({
            dropdownValue: this.state.screenTypeValue,
            showPopup: true,
        },
        )
    }

    // closeFirstPopup
    render() {
        const { action, loading, openNextPopUp, closeFirstPopup } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">
                                {action === 'add' ? 'New Task Details' : 'Add Notification'}
                                </h4>
                            <button type="button" className="close" onClick={closeFirstPopup}>&times;</button>
                        </div>
                        {/* <div className='p-sub-note ml-3 mt-1 mb-1'>{'Select the type of notification you would like to send?'}</div> */}
                        <div className="mb-5">
                            <form className='todoModalBody'>
                                <label className='form-label ml-2'>Notification Type</label>
                                <Dropdown
                                    data={this.state.typeData}
                                    selectedValue={(data) => this.changeNotificationType(data)}
                                    value={this.state.screenTypeValue}
                                    render={'value'}
                                    height={'NotificationHeight'}
                                />
                            </form>
                        </div>
                        <div className='modal-footer btnBox justify-content-between'>
                            <div className='justify-content-start'>
                                <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>1</span>/2</div>
                            </div>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='NEXT'
                                    className='ml-2 validateBtn'
                                    onClick={this.state.screenTypeValue !== 'Select notification type' && openNextPopUp} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddNewTask;