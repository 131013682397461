import React, { Component } from 'react';
import edit from '../../../../../assets/images/cfm/edit.svg';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import * as profileActions from '../ProfileAction';
import DeclarationPopup from '../../profile/DeclarationPopup'
import { validatePANnumber } from '../../../../../utils/Utils';
import '../ProfileScreen.css'
import { panNumber, panUpload, panNumberPlaceHolder, panUploadPlaceHolder } from '../../../../../constants/Messages'
const requestFailed = 'Unable to contact server\nPlease try again later'
var regpan = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;

class Pan extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit: false,
            panNumber: '',
            panFileName: '',
            panFileContent: '',
            popupVisibility: false,
            loading: false,
            isActive: false,
            message: ''
        };
    }
    componentDidMount() {
        this.getPANDetails(this.props.loginData.userData.Perno);
    }
    getPANDetails(employeeId) {
        this.setState({
            loading: true
        });

        const headers = {
            'Content-Type': 'application/json',
        }

        fetchApiCall(Config.getPANDetails, { emp_id: employeeId }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        panNumber: res.data.pan_details.pan_number ? res.data.pan_details.pan_number : '',
                        panFileName: res.data.pan_details.pan_file_name ? res.data.pan_details.pan_file_name : '',
                        panFileContent: res.data.pan_details.pan_attachment ? res.data.pan_details.pan_attachment.base64 : '',
                    }, () => {
                        this.setState({
                            loading: false
                        })
                    });
                    this.props.panActions.getPANDetails(res.data.pan_details);
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                console.log('err111', err);
                this.setState({
                    loading: false,
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    uploadPAN = (event) => {
        const fileType = event.target.files[0].type
        this.setState({ panFileName: event.target.files[0] ? event.target.files[0].name : '' })
        if (fileType == 'application/pdf' || fileType == 'image/jpeg' || fileType == 'image/png') {
            if (event.target.files && event.target.files[0]) {
                let maxAllowedSize = 1048576 * 2;           // 2MB size
                if (event.target.files[0].size <= maxAllowedSize) {
                    let reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => { this.setState({ panFileContent: e.target.result }) }
                }
                else {
                    toast.error('Please upload pdf less than 2MB', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ panFileContent: '' })
                }
            }
            else { this.setState({ panFileContent: '' }) }
        }
        else {
            toast.error('Please upload pdf/jpeg/png files only ', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
            this.setState({ panFileContent: '' })
        }
    }
    savePANDetails = () => {
        this.setState({
            loading: true,
            popupVisibility: false
        });
        const body = {
            emp_id: this.props.loginData.userData ? this.props.loginData.userData.Perno : '',
            pan_number: this.state.panNumber ? this.state.panNumber : '',
            pan_file_name: this.state.panFileName ? this.state.panFileName : '',
            pan_attachment: {
                base64: this.state.panFileContent ? this.state.panFileContent : ''
            }
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updatePANDetails, body, headers, false, '')
            .then(res => {
                console.log("RES:", res.data.status_code)
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    console.log("success")
                    this.setState({
                        isEdit: false,
                        popupVisibility: false,
                        loading: false
                    },
                        () => this.getPANDetails(this.props.loginData.userData.Perno),
                        toast.success(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastSuccess'
                        }));
                } else {
                    this.setState({
                        loading: false,
                        popupVisibility: false,
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                console.log('err0', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    validateDetails = () => {
        if (this.state.panNumber === '' || this.state.panNumber.length < 10) {
            toast.error('Please enter valid PAN number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (!regpan.test(this.state.panNumber)) {
            toast.error('Invalid PAN number', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.panNumber === this.props.panData.panData.pan_number) {
            toast.error('Cannot update same data', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.panFileName === "" || this.state.panFileContent === "") {
            toast.error('Please upload Pan document', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.setState({ popupVisibility: true })
        }
    }
    render() {
        const { isEdit } = this.state
        return (
            <div>
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>PAN Card Details </div>
                    {!this.state.isEdit ?
                        <div className='mt-2 mr-3 pointer'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setState({ isEdit: true })}
                            ></img>
                        </div> : null
                    }
                </div>
                <div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>{panNumber}</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='care_of'
                                    value={this.state.panNumber}
                                    placeholder={panNumberPlaceHolder}
                                    readOnly={!isEdit}
                                    maxLength={'10'}
                                    onChange={(e) => this.setState({
                                        panNumber: e.target.value ? validatePANnumber(e.target.value) : ''
                                    })}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>{panUpload}</div>
                            <div className='p-placeholder-text'>
                                {this.state.isEdit ?
                                    <input
                                        className="form-control p-placeholder px-3 paddingTop-11"
                                        type="file"
                                        name='pan'
                                        id="files"
                                        accept=".png,.pdf,.jpeg"
                                        title={this.state.panFileName}
                                        placeholder={panUploadPlaceHolder}
                                        readOnly={!isEdit}
                                        onChange={(e) => this.uploadPAN(e)}
                                    />
                                    :
                                    <input
                                        type="text"
                                        readOnly={!isEdit}
                                        className="form-control p-placeholder px-3"
                                        value={this.state.panFileName}
                                    />
                                }
                            </div>
                            {this.state.isEdit ? <div className='p-sub-heading ml-3'>(Single file of 2MB to be uploaded.)</div> : null}
                        </div>
                    </div>
                    {this.state.isEdit &&
                        <div className='row justify-content-end'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.setState({ isEdit: false })}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={this.validateDetails}
                                >Save</div>
                            </div>
                        </div>}
                    <div className='hz-lines mt-4 mb-4 mr-2 ml-2'></div>
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.savePANDetails}
                    />
                }
            </div>
        );
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        panData: state.profileState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        panActions: bindActionCreators(profileActions, dispatch),
    }
}

export default connect(mapStatesToProps, mapDispatchToProps)(Pan);