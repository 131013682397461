import React, { Component } from 'react';
import './PerformanceTimeLine.css';
import back from '../../../../../assets/drishti_images/back.svg';
import nodata from '../../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg';
import { dateFromNow } from '../../../../../utils/Utils';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import '../../../employeeScreens/continuousFeedbackManagement/CFMHome.css';
import { BulletList } from 'react-content-loader'

const sharedFeedback = "shared a feedback on "
const onThe = 'the '
const leadership = 'Leadership behavior'
const competency = 'Competency'
const title = 'Achievement Updates'

class PerformanceTimeLine extends Component {
    constructor(props) {
        super(props)
    }

    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }

    goToPerformance = () => {
        window.location = (BASE_WEB_URL_DEV + 'performance');
    }

    render() {
        const { data, loading, applicationMode, disabledMessage } = this.props
        return (
            <div className="card-new edit-card-performance">
                {loading ?
                    <div className='performancetimeline-container m-3'>
                        <BulletList />
                    </div> :
                    <div>
                        <div className="row">
                            <div className="d-flex justify-content-between card-title-position">
                                <div>
                                    <label className="edit-cardtitle">{title}</label>
                                </div>
                                <div
                                    type='button'
                                    onClick={this.goToPerformance}>
                                    <img alt="Back" src={back} className="righticon"></img>
                                </div>
                            </div>
                        </div>
                        {applicationMode === 'write' ?
                            <div>
                                {data.length != 0 ?
                                    <div className='performancetimeline-container ml-1'>
                                        {data.map((listValue, id) => {
                                            return (
                                                <div className='timeline-performance' key={id}>
                                                    <div className='container-performance'>
                                                        <div className='container2-performance'>
                                                            <div
                                                                className={listValue.role === 'FEM' ? 'fem-bg-circle circle-text-performance' :
                                                                    listValue.role === 'DEM' ? 'dem-bg-circle circle-text-performance' :
                                                                        "employee-bg-circle circle-text-performance"}
                                                            >
                                                                {this.short_name(listValue.commented_by_name)}
                                                            </div>
                                                        </div>
                                                        <div className='h-liness'></div>
                                                        <div className='content right p-12px'>
                                                            <div className='justify-content-between'>
                                                                <div className='row'>
                                                                    <div className='sub-card-text-bold pr-1'>
                                                                        {listValue.commented_by_name}
                                                                    </div>
                                                                    <div className='sub-card-text-light pr-1'>
                                                                        {sharedFeedback}
                                                                    </div>
                                                                    <div className='sub-card-text-bold'>
                                                                        {onThe}{listValue.type === competency ? leadership : listValue.type}
                                                                    </div>
                                                                </div>
                                                                <div className='justify-content-end align-item-center d-flex'>
                                                                    <div className='time-text mb-3'>
                                                                        {dateFromNow(listValue.created_date_time)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='mt20per col-md-12 d-flex align-items-center flex-column'>
                                            <div className='row'>
                                                <img src={nodata} alt="Nodata" />
                                            </div>
                                            <div className='row'>
                                                <label className='card-content-title grey-color'>No feedbacks shared yet!</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div> :
                            <div className='justify-content-center h-100'>
                                <div className='row mt-2'>
                                    <div className='col-md-12 d-flex align-items-center flex-column'>
                                        <div className='row'>
                                            <img src={nodata} alt="Nodata" />
                                        </div>
                                        <div className='row'>
                                            <label className='card-content-title grey-color'>{disabledMessage}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default PerformanceTimeLine;


