import React, { useState } from 'react';
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { downloadApplicantCV } from './Gems.api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import { warnings } from './Constant';


export function PGApproveForm(props) {
    const [loading, setLoading] = useState(false);
    const downloadCV = (projectID, applicantID) => {
        setLoading(true)
        downloadApplicantCV(projectID, applicantID)
            .then((res) => {
                setLoading(false)
                if (res) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                console.log("downloadApplicantCV catch err>>", err)
            })
    }

    return (
        <div className='flex-direction-column p'>
            <LoaderIndicator loading={loading} />
            {props.data.map((dataValue, idx) =>
                <div key={idx}>
                    <span className='approval-popup'>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Application ID"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.id ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Applicant ID"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.applicant.Perno ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Applicant Name"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.applicant.CompName ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Applicant Level"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.applicant.Esgtxt ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Applicant Location"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.applicant.LocDesc ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"Applicant OU"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.applicant.Orgtx ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"MTR status"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.mtr_status ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"MTR Comments"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.mtr_comments ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"FR status"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.fr_status ?? "-"}</span>
                        </span>
                        <span className='line-horizontal pb-10px'>
                            <span className='performance-timeline-text-date'>{"FR Comments"}:&nbsp;&nbsp;</span>
                            <span className='feedback-sm-text'>{dataValue.fr_comments ?? "-"}</span>
                        </span>
                    </span>
                    <span className='flex-direction-row align-item-center-noflex pb-10px pt-10px'>
                        <span className='performance-timeline-text-date'>{"Applicant CV"}:&nbsp;&nbsp;</span>
                        <span className='feedback-sm-text'>{dataValue.attachment ?? "-"}</span>&nbsp;&nbsp;
                        <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer' onClick={() => downloadCV(props.projectId[0].id, props.data[0].id)} />
                    </span>
                </div>
            )}
        </div>
    )
}