import * as type from '../../../../../redux/action/ActionType';

const initialState = {
    directReporteeDetails: [],
}

export const cfm = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_DIRECT_REPORTEE_DETAILS:
            return {
                ...state,
                directReporteeDetails: action.payload
            }
        default:
            return state;
    }
}