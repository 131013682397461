import React, { Component } from 'react'
import moment from 'moment';
import FormPopup from '../../../shared/components/gemsFormPopUp/FormPopup';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import { updateProjectStatus, getProjectStatus } from '../../../ScreenType/employeeScreens/gems/Gems.api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { configStore } from "../../../../../src/redux/ConfigStore"
import { toast } from 'react-toastify';
import { toggelDiffStatus } from '../../../../../src/utils/Utils'
import { validateChoicePay } from '../../../../constants/Config';
import ConfirmPopup from '../../employeeScreens/continuousFeedbackManagement/ConfirmPopup';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';


class GEMSApproval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checked: 0,
            loading: false,
            filteredPgData: [],
            pgData: [],
            isFormClosed: true,
            toggel: toggelDiffStatus(),
            finalAction: false,
            titleText: '',
            statusCode: 'Initiated'
        }
    }

    componentDidMount() {
        this.getProject('Initiated')
    }

    showProjects = (data) => {
        console.log("showProjects>>>>", data)
    }

    actionTaken = (chosenAction) => {
        console.log("chosenAction:", chosenAction)
        if (chosenAction === 'approve') {
            this.setState({
                finalAction: true,
            })
        }
        else {
            this.setState({
                finalAction: false,
                checked: chosenAction === "approve" ? 1 : chosenAction === "reject" ? 2 : 0,
            }, () => this.updateData(chosenAction, this.state.pendingData)
            )
        }
    }

    final = (action) => {
        this.setState({
            isFormClosed: true,
            checked: action === "approve" ? 1 : action === "reject" ? 2 : 0,
        }, () => this.updateData(action, this.state.pendingData))
    }

    getProject(status) {
        const param = {
            status: status,
            // page_number: 1,
            manager_perno: configStore.getState().loginState?.userData?.Perno,
            title: this.state.titleText
        }
        this.setState({ loading: true })
        getProjectStatus(param, this.props)
            .then((response) => {
                this.setState({
                    loading: false,
                    pgData: response.data.data.paginated_data ? response.data.data.paginated_data : [],
                    // filteredPgData: response.data.data.paginated_data.filter((item) => item.status === status)
                })
            }).catch((error) => {
                toast.error("Unable to contact server\n\tPlease retry after sometime", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false, pgData: [] })
                console.log("getProject catch error:", error)
            });
    }

    updateData = (actionTaken, pendingData) => {
        let body = {
            "status": actionTaken === "reject" ? 'Rejected' : actionTaken === "approve" ? 'Approved' : '',
            'emp_id': configStore.getState().loginState?.userData?.Perno
        }
        this.setState({ loading: true })
        updateProjectStatus(pendingData.id, body, this.props)
            .then((response) => {
                toast.success(response.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastSuccess'
                })
                const refreshAction = actionTaken === "reject" ? 'Rejected' : actionTaken === "approve" ? 'Approved' : ''
                this.setState({
                    loading: false,
                    checked: actionTaken === "approve" ? 1 : actionTaken === "reject" ? 2 : 0,
                    finalAction: false,
                }, () => this.getProject(refreshAction))
            }).catch((error) => {
                toast.error("Unable to contact server\n\tPlease retry after sometime", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false })
                console.log("updateProjectStatus catch error:", error)
            });

    }

    onChangedStatus = (key, value) => {
        // this.setState({
        //     filteredPgData: this.state.pgData.filter((item) => item.status === value.value)
        // })
        this.setState({
            checked: key,
            titleText: '',
            statusCode: value.value
        },
            () => this.getProject(value.value)
        )
    }

    searchProjectFilter = () => {
        this.setState({
            titleText: this.state.titleText,
            pageNumber: 1,
        }, () => this.getProject(this.state.statusCode))
    }

    render() {
        const { toggel, pgData, checked, isFormClosed } = this.state
        let i = 0;
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='flex-direction-row align-item-center-noflex justify-content-between m-15px'>
                    <div className='row align-item-center'>
                    <span className='flex-direction-row align-item-center-noflex'>Status :</span>
                    <span className='row align-item-center'>
                        {toggel.length != 0 && toggel.map((list, key) => {
                            return (
                                <span key={key} className='flex-direction-row align-item-center-noflex pl-20px'>
                                    {checked === key ?
                                        <span onClick={() => this.onChangedStatus(key, list)}>
                                            <img alt="radioOn" className='radio' src={radioOn} />
                                            <label className="radioTxt" >{list.value == "Initiated" ? list.key : list.value}</label>
                                        </span>
                                        :
                                        <span onClick={() => this.onChangedStatus(key, list)}>
                                            <img alt="radioOff" className='radio' src={radioOff} />
                                            <label className="radioTxt">{list.value == "Initiated" ? list.key : list.value}</label>
                                        </span>
                                    }
                                </span>
                            )
                        })
                        }
                    </span>
                    </div>
                    <div className='flex-direction-row f-gap-1rem'>
                            <div className='row ml-10'>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-2 p-placeholder-admin"
                                        type="text"
                                        placeholder='Search Project'
                                        onChange={(e) => this.setState({ titleText: e.target.value })}
                                        value={this.state.titleText}
                                    />
                                </div>
                                <div type='button' className='serchIconSize-gems' onClick={() => this.searchProjectFilter()}>
                                    <img alt="radioOn" className='filterIcon' src={searchIcon} />
                                </div>
                            </div>
                        </div>
                </div>
                <div className='pt-2 pushNotification-container margin-left-20 mr-20px'>
                    {pgData.length != 0 ?
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" className='thead'>Sr.No.</th>
                                    <th scope="col" className='thead'>PG Name</th>
                                    <th scope="col" className='thead'>Project Title</th>
                                    <th scope="col" className='thead'>Status</th>
                                    <th scope="col" className='thead'>Start Date</th>
                                    <th scope="col" className='thead'>End Date</th>
                                    <th scope="col" className='thead'>No. of Opening</th>
                                    <th scope="col"><div className='thead'>Action</div></th>
                                </tr>
                            </thead>
                            <tbody className='scroll-auto'>
                                {this.state.pgData.map((listValue, index) => {
                                    return (
                                        <>
                                            <tr key={index}>
                                                <>
                                                    <td><span className="td" style={{ width: 20 }}>{i = i + 1}</span></td>
                                                    <td><span className="td" >{listValue?.project_guide?.CompName ?? ''}</span></td>
                                                    <td><span className="td">{listValue?.title ?? ''}</span></td>
                                                    <td><span className="td" style={{ color: listValue.status === "Initiated" ? '#E3B203' : listValue.status == "Rejected" ? '#E11111' : '#047857' }}>{listValue.status ? listValue.status : ''}</span></td>
                                                    <td><span className="td" >{moment(listValue?.actual_start_date ?? listValue?.planned_start_date ?? '').format("DD/MM/YYYY")}</span></td>
                                                    <td><span className="td">{moment(listValue?.actual_end_date ?? listValue?.planned_end_date ?? '').format("DD/MM/YYYY")}</span></td>
                                                    <td><span className="td ml-5px">{listValue?.no_of_openings ?? ''}</span></td>
                                                    <td className='d-flex'>
                                                        <>
                                                            <span
                                                                onClick={() => this.setState({ isFormClosed: false, pendingData: listValue })}
                                                            >
                                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                            </span>
                                                        </>
                                                    </td>
                                                </>
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No Project!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {isFormClosed == false &&
                    <FormPopup
                        type={'GEMSApproval'}
                        onDismiss={() => this.setState({ isFormClosed: true })}
                        submitAddProject={(data) => this.showProjects(data)}
                        showPendingData={this.state.pendingData ? this.state.pendingData : ''}
                        approveData={this.actionTaken}
                    />
                }
                {this.state.finalAction === true &&
                    <>
                        <ConfirmPopup
                            title={'Confirmation :'}
                            action={'finalAction'}
                            content={'Note:  Are you sure you want to approve the project.'}
                            approveData={this.final}
                            onDismiss={() => this.setState({ finalAction: false })}
                        />
                    </>
                }
            </div>
        )
    }
}
export default GEMSApproval