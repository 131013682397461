import React, { Component } from 'react'
import common_no_data from '../../../assets/drishti_images/common_no_data.svg'

class NewNotdata extends Component {
    render() {
        return (
            <div className='flex-col align-items-center'>
                <div>
                    <img src={common_no_data} alt="Nodata" />
                </div>
                <div>
                    <label className='card-content-title grey-color' >No data found</label>
                </div>
            </div>
        )
    }
}

export default NewNotdata
