import React from 'react';
import delete_popup from '../../../../../assets/images/cfm/delete_popup.svg';
import Success_icon from '../../../../../assets/drishti_images/manager/landing/Success_icon.svg';
// import Success_icon from '../../../assets/drishti_images/manager/landing/Success_icon.svg';

const OtPopup = ({ title, content, action, approveNoteContent, onDismiss, updateData }) => {
    const handleUpdate = () => {
        updateData();
        onDismiss();
    };
    
    const handleUpdateEmp = () => {
        updateData();
    };
    const handleUpdateBook= () => {
        updateData();
    };
    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0" style={{ width: '30%' }}>
                <div className="modal-header">
                    <h4 className="modal_title black">{title}</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                </div>
                <div className="modal-body">
                    {action === 'delete' || action === 'reject' ?
                        <div className='flex-direction-column justify-content-center p-3'>
                            <div className='align-self-center'>
                                <img alt="Action Icon" src={delete_popup} className="mb-2"></img>
                            </div>
                            <div className="popup_text text-center p-3 m-3">
                                {content}
                            </div>
                        </div> :
                        <div className='flex-direction-column justify-content-center p-3'>
                            <div className='align-self-center'>
                                <img alt="Action Icon" src={Success_icon}></img>
                            </div>
                            {approveNoteContent &&
                                <span className="popup_text text-center mt-10px p-2">
                                    {approveNoteContent}
                                </span>
                            }
                            <div className="popup_text text-center p-2 m-1">
                                {content}
                            </div>
                        </div>
                    }
                </div>
                <div className='modal-footer btnBox'>
                    <input type='button' onClick={onDismiss} value='CANCEL' className='cancelBtn' />
                    {/* Buttons for actions could be dynamically generated here */}
                    {action === 'delete' ?
                        <input type='button' value='DELETE' className='ml-2 validateBtn' onClick={() => updateData()} /> :
                        action === 'reject' ?
                            <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => handleUpdate()} /> :
                            action === 'approve' ?
                                <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => handleUpdate()} /> :
                                action === 'confirm' ?
                                    <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => handleUpdate()} /> :
                                    action === 'confirm Employee' ?
                                        <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => handleUpdateEmp()} /> :
                                        action === 'confirm Booking' ?
                                        <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => handleUpdateBook()} /> :
                                        <input type='button' value='SAVE' className='ml-2 validateBtn' onClick={() => updateData()} />
                    }
                </div>
            </div>
        </div>
    );
};

export default OtPopup;
