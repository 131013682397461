import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import './NotificationStyles.css';
import eye from '../../../../assets/drishti_images/notification/eye.svg';
import cfm from '../../../../assets/drishti_images/notification/cfm.svg';
import todo from '../../../../assets/drishti_images/notification/todo.svg';
import transfer from '../../../../assets/drishti_images/notification/transfer.svg';
import calender_Notify from '../../../../assets/drishti_images/notification/calender-Notify.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { dateFromNow } from '../../../../utils/Utils'
import CircleSkeleton from '../../../shared/skeleton/Skeletons';
import noNotification from '../../../../assets/drishti_images/nodatafound/no-notification.svg';
import moments_icon from "../../../../assets/drishti_images/momentsFeed/MTMHeader.png";

class NotificationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notificationData: [],
        }
    }
    componentDidMount() {
        this.getNotifications(this.props.loginData.userData.Perno)
    }

    getNotifications(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            'emp_id': employeeId
        }
        fetchApiCall(Config.getNotifications, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.statusCode === '200') {
                    this.setState({
                        notificationData: res.data.data ? res.data.data : [],
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        notificationData: [],
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    // short_name = (str) => {
    //     if (str.split(' ').length < 3) {
    //         let regular_ex = /\b(\w)/g;
    //         let match_name = str.match(regular_ex);
    //         let shortName = match_name.join('');
    //         return shortName;
    //     } else {
    //         let string = str.substring(str.lastIndexOf(" ") + 1);
    //         let full_string = str.split(' ')[0] + " " + string
    //         let regular_ex = /\b(\w)/g;
    //         let match_name = full_string.match(regular_ex);
    //         let shortName = match_name.join('');
    //         return shortName;
    //     }
    // }
    changeScreen = () => {
        window.location = (BASE_WEB_URL_DEV + 'notification');
    }
    render() {
        console.log("notificationData:", this.state.notificationData)
        const { loading } = this.state
        return (
            <OutsideClickHandler
                onOutsideClick={() => this.props.onDismiss()}>
                <div className="notification-container" >
                    {this.state.loading === true &&
                        <LoaderIndicator loading={this.state.loading} />
                    }
                    <div className='flex-direction-row'>
                        <label className='announcement-inner-content common-text-color notification-title'>Notifications</label>
                    </div>
                    <div><hr /></div>

                    {loading ?
                        <>
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                        </>
                        :
                        <>
                            <div className={this.state.notificationData.length > 0 ? 'landing-notification' : 'landing-notification-withoutData'}>
                                {this.state.notificationData.length !== 0 ?
                                    <>
                                        {this.state.notificationData.slice(0, 5).map((listValue, id) =>
                                            <div key={id}>
                                                <div className='notification-data flex-direction-row justify-content-start'>
                                                    <div>
                                                        {listValue.notification_type.includes('Task') ?
                                                            <div className="notification-circle-shape bg-dark-green">
                                                                <img alt='' src={todo} className='notification-emp_circle_text notification-Icons' />
                                                            </div>
                                                            : listValue.notification_type.includes('Leave') ?
                                                                <div className="notification-circle-shape bg-purple">
                                                                    <img alt='' src={calender_Notify} className='notification-emp_circle_text notification-Icons' />
                                                                    {/* <label className='notification-emp_circle_text'>{this.short_name(this.props.loginData.userData.CompName)}</label> */}
                                                                </div>
                                                                : listValue.notification_type.includes('feedback') ?
                                                                    <div className="notification-circle-shape bg-orange">
                                                                        <img alt='' src={cfm} className='notification-emp_circle_text notification-Icons' />
                                                                    </div>
                                                                    : listValue.notification_type.includes('Birthday') ?
                                                                        <div className="notification-circle-shape bg-light-lightBlue">
                                                                            <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                        </div>
                                                                        : listValue.notification_type.includes('Long') ?
                                                                            <div className="notification-circle-shape bg-light-lightBlue">
                                                                                <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                            </div>
                                                                            : listValue.notification_type.includes('Work') ?
                                                                                <div className="notification-circle-shape bg-light-lightBlue">
                                                                                    <img alt='' src={moments_icon} className='notification-emp_circle_text notification-iconsMTM' />
                                                                                </div>
                                                                                :
                                                                                <div className="notification-circle-shape bg-blue">
                                                                                    <img alt='' src={transfer} className='notification-emp_circle_text notification-Icons' />
                                                                                </div>
                                                        }

                                                    </div>
                                                    <div className='flex-direction-column'>
                                                        <div className='text17_bold common-text-color'>
                                                            {listValue.notification_type}
                                                        </div>
                                                        <div className='text17_light black-tag'>
                                                            {listValue.notification_description}
                                                        </div>
                                                        <div className='text18 common-text-color'>
                                                            {dateFromNow(listValue.created_datetime)}
                                                            {/* {moment(listValue.created_datetime).format('DD-MM-YYYY')} */}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div><hr /></div>
                                            </div>
                                        )}
                                    </>
                                    :
                                    <div className='col-md-12 d-flex align-items-center flex-column mb-10px'>
                                        <div>
                                            <img src={noNotification} alt="Nodata" style={{ "padding": "10px", "height": "6.25rem" }} />
                                        </div>
                                        <div>
                                            <label className='card-content-title grey-color'>No notification found</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {this.state.notificationData.length > 0 &&
                        <div className='flex-direction-row justify-content-center' onClick={() => this.changeScreen()}>
                            <img alt='' src={eye} />
                            <label className='text14_bold purple-color ml-1' style={{ cursor: 'pointer', padding: '0.625rem' }}>View all</label>
                        </div>
                    }
                </div>
            </OutsideClickHandler >
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}
export default connect(mapStatesToProps)(NotificationCard)