import React, { Component } from 'react';
import './announcementScreen.css';
import { connect } from 'react-redux';
import left from '../../../../assets/drishti_images/upcomingOccasion/grey-left-cone.svg';
import right from '../../../../assets/drishti_images/upcomingOccasion/grey-right-cone.svg';
import occasionlogo from '../../../../assets/drishti_images/upcomingOccasion/occasion-logo.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import nodataicon from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const d = new Date();
class UpcommingOccasion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            upcommingOccasion: [],
            monthName: month[d.getMonth()],
            monthCode: d.getMonth() + 1,
            eventYear: new Date().getUTCFullYear(),
            currentMonthCode: d.getMonth() + 1,
            currentEventYear: new Date().getUTCFullYear(),
        }
    }

    componentDidMount() {
        this.getUpcomingEvents(new Date().getMonth() + 1)
    }

    getUpcomingEvents = (monthCode) => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_month: monthCode,
            event_year: this.state.eventYear,
        }
        fetchApiCall(Config.getUpcomingEvents, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        upcommingOccasion: res.data.data.upcoming_occasions,
                        loading: false,
                        message: '',
                        monthName: month[monthCode - 1],
                        monthCode: monthCode
                    })
                } else {
                    this.setState({
                        loading: false,
                        upcommingOccasion: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    changeMonth = (tempMonthCode) => {
        console.log("tempMonthCode", tempMonthCode)
        if (tempMonthCode < this.state.currentMonthCode && this.state.eventYear == this.state.currentEventYear) {
            console.log("tempMonthCode 1", tempMonthCode)
            // this.setState({
            //     monthCode: tempMonthCode
            // },
            //     () => this.getUpcomingEvents(this.state.monthCode)
            // )
        }
        else if (tempMonthCode > 0 && tempMonthCode < 13) {
            console.log("tempMonthCode 2", tempMonthCode)
            this.setState({
                monthCode: tempMonthCode
            },
                () => this.getUpcomingEvents(this.state.monthCode)
            )
        }
        else if (tempMonthCode <= 0) {
            console.log("tempMonthCode 3", tempMonthCode)
            this.setState({
                eventYear: this.state.eventYear - 1,
                monthCode: 12
            },
                () => this.getUpcomingEvents(this.state.monthCode)
            )
        }
        else {
            console.log("tempMonthCode 4", tempMonthCode)
            this.setState({
                eventYear: this.state.eventYear + 1,
                monthCode: 1
            },
                () => this.getUpcomingEvents(this.state.monthCode)
            )
        }
    }

    render() {
        return (
            <div className='row upcoming-occasion'>
                <div className="col-md-12 card-new px-0">
                    <div className='row'>
                        <div className='col-md-12 edit-cardtitle' >
                            Upcoming Occasions
                        </div>
                    </div>
                    <div className='row d-flex justify-content-around mt-2 mb-10'>
                        <img src={left} alt='' style={{ marginLeft: '25px' }} onClick={() => this.changeMonth(this.state.monthCode - 1)} />
                        <label className='document-innercard-content-title'>{this.state.monthName} {this.state.eventYear}</label>
                        <img src={right} alt='' style={{ marginRight: '25px' }} onClick={() => this.changeMonth(this.state.monthCode + 1)} />
                    </div>
                    {this.state.upcommingOccasion.length === 0 ?
                        <div className='nodata-upcommingOccasion'>
                            <img src={nodataicon} alt="Nodata" />
                            <label className='card-content-title grey-color'>Event not found !</label>
                        </div>
                        :
                        <div className='row occasionscroll mt-2 p-2'>
                            <div className='col-md-12 px-0'>
                                <div className='occasion-bg-color bottom-radius'>
                                    {this.state.upcommingOccasion.map((upolist, id) =>
                                        <div key={id} className='hover-tab-color-change'>
                                            <div className='row pl-3 pr-1 align-item-center vertical-padding-5px'>
                                                <div>
                                                    <div className='mr-3'>
                                                        <img src={occasionlogo} alt='' className='occasionlogo' />
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <div className='card-content-title common-text-color'>
                                                        {upolist.event_title}
                                                    </div>
                                                    <div className='feedback-sm-text light-grey'>
                                                        {upolist.event_date}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(UpcommingOccasion);