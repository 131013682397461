import React, { Component } from 'react'
import moment from 'moment';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import { noDataMsg } from '../../../../../constants/Messages'
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import GoToPage from '../../blueCollarRegistration/goToPage';
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction';
import { getPlantUpdateTypes, getPlantUpdate, deletePlantUpdate, getAdminPlants } from '../../../../../constants/api.service';
import Dropdown from '../../../../shared/components/Dropdown';
import { toast } from 'react-toastify';
import { configStore } from "../../../../../redux/ConfigStore";
import { toaster } from '../../../../../utils/Utils'

class TatamotorsPlantUpdateDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPopup: false,
            showDelete: false,
            tempPlantUpdateData: [],
            plantUpdateType: [],
            plantUpdates: [],
            selectedType: null,
            plants: [],
            selectedPlant: null,
            total_page: 1,
            currentPage: 1,
        }
    }

    componentDidMount() {
        this.fetchAdminPlants();
    }

    fetchAdminPlants() {
        this.setState({ loading: true })
        getAdminPlants(
            {
                "emp_id": parseInt(configStore.getState()?.adminLoginState?.userData?.Perno),
                "comp": parseInt(configStore.getState()?.adminLoginState?.company?.company_code)
            },
            this.props)
            .then((response) => {
                response.data.plants = response.data.plants.map((plant) => ({ viewValue: plant, value: plant }))
                this.setState({ plants: response.data.plants, selectedPlant: response.data.plants[0], loading: false }, () => this.fetchPlantUpdateTypes());
            }).catch((error) => {
                this.setState({ loading: false })
                console.log("getAdminPlants catch error:", error)
            });
    }

    fetchPlantUpdateTypes() {
        this.setState({ loading: true })
        getPlantUpdateTypes(
            {},
            this.props
        ).then((response) => {
            this.setState({ plantUpdateType: response.data.data, selectedType: response.data.data[0], loading: false })
            this.getPlantUpdates();
        }).catch((error) => {
            this.setState({ loading: false })
            console.log("getPlantUpdateTypes catch error:", error)
        });
    }

    getPlantUpdates = () => {
        this.setState({ loading: true });

        getPlantUpdate(
            {
                update_type: this.state.selectedType.viewValue,
                page_size: 5,
                page_no: this.state.currentPage || 1,
                month: '',
                year: '',
                plant: this.state.selectedPlant?.value
            },
            this.props
        )
            .then((response) => {
                this.setState({ plantUpdates: response?.data?.data, loading: false, total_page: response.data.total_page, currentPage: response.data.page_number })
            })
            .catch((error) => {
                this.setState({ loading: false, plantUpdates: [] })
                console.log("getPlantUpdates catch error:", error.response)
            });
    }

    deletePlantUpdate = (event) => {
        const param = {
            u_id: event
        }
        this.setState({ loading: true })
        deletePlantUpdate(param, this.props)
            .then((response) => {
                this.setState({
                    showDelete: false,
                    loading: false
                })
                toast.success("Deleted Sucessfully", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastSuccess'
                })
                this.getPlantUpdates()
            })
            .catch((error) => {
                console.log("deletePlantUpdate catch error:", error)
                this.setState({
                    showDelete: false,
                    loading: false
                })
            })
    }

    onAction = (listValue, type) => {
        if (type === "view") {
            this.setState({
                viewPopup: true,
                tempPlantUpdateData: listValue
            })
        }
        else if (type === "delete") {
            this.setState({
                showDelete: true,
                tempPlantUpdateData: listValue.u_id
            })
        }
    }

    // commented for future use. using alternative as of now
    // documentDownload = (url, file_name) => {
    //     window.open(url);
    //     if(url !== ''){
    //         toaster("success", "Your document has been downloaded.")
    //     }else{
    //         toaster("error", 'Unable to contact server\nPlease try again later')

    //     }
    //     // commonDownloadFile(url, undefined, file_name, true)
    // }

    switchPage = (value) => {
        this.setState({ currentPage: Number(value) },
            (() => this.getPlantUpdates())
        );
    }

    theme = createTheme({
        palette: {
            custom: {
                main: '#623AAA',
                darker: '#623AAA',
                contrastText: '#FFFFFF'
            }
        },
    });

    getRowNo = (id) => {
        return (id + 1) + (this.state.currentPage - 1) * 5
    }

    changeNotificationType(selectedTypeObj) {
        this.setState(
            { selectedType: selectedTypeObj },
            (() => this.getPlantUpdates())
        )
    }

    changePlant(selectedPlant) {
        this.setState(
            { selectedPlant: selectedPlant },
            (() => this.getPlantUpdates())
        )
    }

    render() {
        return (
            <div className=" pt-2 tvNotification-container" >
                {
                    this.state.filteredPlantUpdates?.length == 0 &&
                    <LoaderIndicator loading={this.state.loading} />
                }
                <div className='flex-direction-row mb-1 justify-content-between' style={{ 'alignItems': 'center' }}>
                    <div className='flex-direction-row mb-1 justify-content-start ml-10'>
                        <label className='edit-cardtitle'>{this.state.selectedPlant && this.state.selectedPlant.value}</label>
                    </div>
                    <div className='flex-direction-row mb-1 justify-content-end'>
                        {this.state.plants && this.state.plants.length > 1 &&
                            <span className='ml-2 dropdown-Text justify-self-end'>
                                <Dropdown
                                    data={this.state.plants}
                                    selectedValue={(data) => this.changePlant(data)}
                                    value={this.state.selectedPlant && this.state.selectedPlant.value}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                />
                            </span>
                        }
                        <span className='ml-2 plant-update-type dropdown-Text justify-self-end'>
                            {
                                this.state.plantUpdateType &&  this.state.selectedType &&  
                                <Dropdown
                                    data={this.state.plantUpdateType}
                                    selectedValue={(data) => this.changeNotificationType(data)}
                                    value={this.state.selectedType && this.state.selectedType.value}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                />
                            }
                        </span>
                    </div>
                </div>
                {
                    this.state.plantUpdates.length !== 0 ?
                        <>
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col" ><div className='thead w40px'>Sr. No</div></th>
                                        <th scope="col" ><div className='thead'>Title</div></th>
                                        <th scope="col" ><div className='thead'>Description</div></th>
                                        <th scope="col" ><div className='thead'>Update Date</div></th>
                                        <th scope="col" ><div className='thead'>Update Type</div></th>
                                        <th scope="col" ><div className='thead'>Attachmented File</div></th>
                                        <th scope="col" className='text-align-center'><div className='thead'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.plantUpdates.map((listValue, index) => {
                                            return (
                                                <tr key={index} className=''>
                                                    <td><div className="td " >{this.getRowNo(index)}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.title ? listValue.title : ''}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.description ? listValue.description : ''}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.update_date ? moment(listValue.update_date).format("DD-MM-YYYY") : ''}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.update_type ? listValue.update_type.charAt(0).toUpperCase() + listValue.update_type.slice(1) : ''}</div></td>
                                                    <td className='w-20'><div className="td auto-line-break" >{listValue.pdf_name ? listValue.pdf_name : ''}</div></td>
                                                    <td className='d-flex'>
                                                        <div
                                                            onClick={() => this.onAction(listValue, 'view')}
                                                        >
                                                            <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                        </div>
                                                        <div
                                                            onClick={() => this.onAction(listValue, 'delete')}
                                                        >
                                                            <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                    <tr className='text-align-center'>
                                        <th colSpan='7' scope="col" className='w-5 text-align-center btm-pagination'>
                                            {
                                                this.state.total_page && <div className='flex-style-for-view-button'>
                                                    <ThemeProvider theme={this.theme}>
                                                        <Pagination count={this.state.total_page} color="custom" page={parseInt(this.state.currentPage)} onChange={(event, value) => this.switchPage(value)} showFirstButton showLastButton />
                                                    </ThemeProvider>
                                                    <GoToPage totalPage={parseInt(this.state.total_page)} currentPage={this.state.currentPage}
                                                        onChange={this.switchPage}
                                                    />
                                                </div>
                                            }
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                        :
                        <>
                            {
                                this.state.loading ?
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <label className='card-content-title grey-color'>{noDataMsg.dataLoad}</label>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column h-38vh' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>{noDataMsg.noData}</label>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </>
                }
                {
                    this.state.viewPopup &&
                    <NotificationDetailsPopup
                        type={"plantupdate"}
                        title={"Plant Update Details"}
                        data={this.state.tempPlantUpdateData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                        documentDownload={(url, file_name) => this.documentDownload(url, file_name)}
                    />
                }
                {
                    this.state.showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deletePlantUpdate'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Plant update ?'}
                        deletePlantUpdate={() => this.deletePlantUpdate(this.state.tempPlantUpdateData)}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div >
        )
    }
}
export default TatamotorsPlantUpdateDashboard