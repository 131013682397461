import React, { Component } from 'react';
import feedback_icon from '../../../../../assets/drishti_images/manager/teams/feedback_icon.svg';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import * as userActions from '../directReportees/CfmAction';
// import * as userActions from '../../../employeeScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import '../TeamsStyle.css';
import { connect } from 'react-redux';
import '../TeamsStyle.css';
import CircleSkeleton from '../../../../shared/skeleton/Skeletons';
import default_profile from '../../../../../assets/drishti_images/profile/default_profile.svg';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';

class DirectReportees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            loading: false,
            directReporteesData: [],
            is_service_Unavailable: false,  // service unavailble from backend
            team_teamMember_Service_reason: '',
        }
    }
    componentDidMount() {
        this.checkService();
        this.setState({ directReporteesData: this.props.loginData.cfmUserDetails.length != 0 ? this.props.loginData.cfmUserDetails.user_details.all_reportees : [] })
        // console.log('directReporteesData', this.props.loginData.cfmUserDetails.user_details.all_reportees);
    }
    goToReportee = (list) => {
        window.location = (BASE_WEB_URL_DEV + 'directReportee');
        this.props.actions.cfmDirectReportee(list)
        console.log('cfm data', this.props.actions.cfmDirectReportee(list))
    }
    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }


    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "team_member" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    team_teamMember_Service_reason: item.reason
                })
            }
        })
    }
    render() {
        const { directReporteesData } = this.state
        return (
            <div className="card-new h-100" >
                {this.state.loading ?
                    <>
                        <div className='mt-4'>
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                            <CircleSkeleton />
                        </div>
                    </>
                    :
                    <>
                        {this.state.is_service_Unavailable ?
                            <div className="col-ms-12 mt-50p justify-content-center align-item-center">
                                <ServiceUnavailable reason={this.state.team_teamMember_Service_reason} />
                            </div>
                            :
                            <>
                                <div className="row">
                                    <div className="d-flex justify-content-between card-title-position">
                                        <div>
                                            <label className="edit-cardtitle">Team Members</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-contain main-contain-child m-3" >
                                    {directReporteesData.length != 0 ?
                                        <div>{directReporteesData.map((list, i) =>
                                            <div key={i} type='button'
                                                className='child-team-card row justify-content-start align-item-center px-2'
                                                onClick={() => this.goToReportee(list)} style={{ width: '99%' }}>
                                                <div className="row align-item-center justify-content-start my-3" style={{ width: '99%' }}>
                                                    {list.employee_profile_picture === '' ?
                                                        <div>
                                                            <img alt="avatar" src={default_profile} className="profile-circle-shape emp_circle_text"></img>
                                                        </div> :
                                                        <img alt="avatar" src={list.employee_profile_picture} className="profile-circle-shape emp_circle_text"></img>
                                                    }

                                                    <div className="flex-direction-column ml-1" style={{ 'width': '64%' }}>
                                                        <div className='card-content-title common-text-color'>
                                                            {list.defaultFullName}
                                                        </div>
                                                        <div className='profile-list-item-text pointer'>
                                                            {list.title}
                                                        </div>
                                                    </div>
                                                    <div className='mb-1'>
                                                        <div className='row'>
                                                            <img alt="Back" src={feedback_icon}></img>
                                                            {/* <div className='blue-sm-circle bg-puple ml-2'></div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='row ml-51px mb-1'>
                                            <div className='row'>
                                                <img alt="Back" src={feedback_icon}></img>
                                                <div className='blue-sm-circle bg-puple ml-2'></div>
                                            </div>
                                        </div> */}
                                            </div>
                                        )}
                                        </div>
                                        :
                                        <div style={{ marginTop: '50%' }}>
                                            <DataNotFound />
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfm: state.cfmState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DirectReportees);