import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './Eseparations.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import resolveErrorIcon from '../../../../assets/drishti_images/resolveErrorIcon.svg';
import delegateIcon from '../../../../assets/drishti_images/admin/delegateIcon.png';
import esep_revoke from '../../../../assets/drishti_images/admin/esep_revoke.png';
import EsepTrackerDashboard from '../../employeeScreens/eSeparation/manager/EsepTrackerDashboard';
import Pagination from 'react-bootstrap/Pagination';
import ReactExport from "react-data-export";
import filterIconActive from '../../../../assets/drishti_images/admin/filterIconActive.svg';
import filterIconInactive from '../../../../assets/drishti_images/admin/filterIconInactive.svg';
import exportExcelcon from '../../../../assets/drishti_images/admin/exportExcelcon.svg';
import exportExcelconActive from '../../../../assets/drishti_images/admin/exportExcelconActive.svg';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import { esep_status, esep_noticepaywavier_status, exitFormReports_status } from '../../../../utils/Utils';
import Dropdown from '../../../shared/components/Dropdown';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import ConfirmPopup from './ConfirmPopup';
import EsepRevokeConfirmPopup from './EsepRevokeConfirmPopup';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const select = 'Select'
export class EseparationAdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            employeeID: '',
            isAdminView: false,
            isFilter: false,
            isExport: false,
            radioFilters: [{ id: 1, title: 'Pending', }, { id: 2, title: 'Approved', }, { id: 3, title: 'Rejected', }, { id: 4, title: 'Deleted', }],

            isEmployeeRequestActive: true,
            isNoticeWavierActive: false,
            isExitFormActive: false,
            isErrorPostingActive: false,

            listType: '',
            empID: '',
            checked: 0,
            page: 1,
            count: [],

            groupType: '',
            groupData: [],
            groupCode: '',
            groupValue: select,
            newGroupData: [],

            levelType: '',
            levelData: [],
            levelCode: '',
            levelValue: select,
            newLevelData: [],

            statusType: select,
            statusData: esep_status(),
            statusCode: '',
            statusValue: '',
            noticePayStatusData: esep_noticepaywavier_status(),
            exitFormStatusData: exitFormReports_status(),
            eSeparationId: '',

            masterRequestData: [],
            noticePayWavierData: [],
            exitFormReportData: [],
            errorInPostingData: [],
            masterData: [], //Common Data Array State
            employeeRequestData: [], //Esep Request All Data State
            companyList: [],
            companyCode: '',
            companyValue: select,

            esepRequestExportData: [],
            noticePayWavierExportData: [],
            exitFormExportData: [],
            errorInPostingExportData: [],
            separationExitData: [],
            isExitView: false,

            separationRequestId: '',
            resignationRequestDate: '',
            lwdRequestedByEmployee: '',
            employeeSeparationRemark: '',
            employeeName: '',
            actualResignationDate: '',
            employeeSeparationReason: '',
            createdBy: '',
            lwdAsPerPolicy: '',
            noticePeriodDays: '',
            lwdJustificationEmployee: '',
            separationStatus: '',
            noticePayRecoveryAmount: '',
            isNoticePayWaveOffDem: false,
            isNoticePayWaveOffBhr: false,

            isDelegatePopup: false,
            isDelegate: false,
            isRecordShow: false,
            isDEMDelegateSubmit: false,
            isBHRDelegateSubmit: false,
            isBUHRDelegateSubmit: false,
            isAgree: false,
            empRoleType: '',
            delegateEmpName: '',
            delegateEmpID: '',
            delegateEmpCompany: '',
            delegateEmpCompCode: '',

            lwdJustificationDem: '',
            lwdJustificationBhr: '',
            waveOffJustificationBhr: '',
            waveOffJustificationDem: '',
            lwdRecommendedByBhr: '',
            lwdRecommendedByDem: '',
            demApprovalRemark: '',
            bhrApprovalRemark: '',
            buhrApprovalRemark: '',

            attachmentUrl: '',
            buhrName: '',
            bhrName: '',
            demName: '',
            showRevokePopup: false,
            revokeEmpId: '',
            revokeEmployeeCompCode: ''
        }
    }

    componentDidMount() {
        this.getSeparationRequestByAdmin();
        this.getGroup();
    }
    //----------------All Tabs Active Inactive Functions---------------------
    toggleEmployeeRequestDetails = () => {
        this.setState({ isEmployeeRequestActive: true, isErrorPostingActive: false, isNoticeWavierActive: false, isExitFormActive: false, isFilter: false, empID: '' }, () => this.getSeparationRequestByAdmin())
    }

    toggleNoticeWavierDetails = () => {
        this.setState({ isEmployeeRequestActive: false, isErrorPostingActive: false, isNoticeWavierActive: true, isExitFormActive: false, isFilter: false, empID: '' }, () => this.getNoticePayWaiverRequests())
    }

    toggleExitDetails = () => {
        this.setState({ isEmployeeRequestActive: false, isErrorPostingActive: false, isNoticeWavierActive: false, isExitFormActive: true, isFilter: false, empID: '' }, () => this.getEmployeeExitFormByAdmin())
    }

    toggleErrorPostingDetails = () => {
        this.setState({ isEmployeeRequestActive: false, isErrorPostingActive: true, isNoticeWavierActive: false, isExitFormActive: false, isFilter: false, empID: '' }, () => this.getErrorInPostingRequests())
    }

    //----------------Get All Eseparation Requests API---------------------
    getSeparationRequestByAdmin = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            page_number: this.state.page ? this.state.page : 1,
            search_text: this.state.empID ? this.state.empID : '',
            group: this.state.groupValue === select ? '' : this.state.groupValue,
            level: this.state.levelValue === select ? '' : this.state.levelValue,
            from_date: this.state.actualDate ? this.state.actualDate : '',
            to_date: this.state.resignationDate ? this.state.resignationDate : '',
            status: this.state.statusType === select ? '' : this.state.statusType
        }
        fetchApiCall(Config.getSeparationRequestByAdmin, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        masterRequestData: res.data.data.paginated_data,
                        count: demData
                    }, () => this.commonAPIFunctionCall());
                    // this.onDismiss();
                }
                else {
                    this.setState({
                        loading: false,
                        masterRequestData: []
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //----------------Get Notice Pay Wavier Requests API---------------------
    getNoticePayWaiverRequests = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            page_number: this.state.page ? this.state.page : 1,
            search_text: this.state.empID ? this.state.empID : '',
            group: this.state.groupValue === select ? '' : this.state.groupValue,
            level: this.state.levelValue === select ? '' : this.state.levelValue,
            from_date: this.state.actualDate ? this.state.actualDate : '',
            to_date: this.state.resignationDate ? this.state.resignationDate : '',
            status: this.state.statusType === select ? '' : this.state.statusType
        }
        fetchApiCall(Config.getNoticePayWaiverRequests, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        noticePayWavierData: res.data.data.paginated_data,
                        count: demData
                    }, () => this.commonAPIFunctionCall());
                    // this.onDismiss();
                }
                else {
                    this.setState({
                        loading: false,
                        noticePayWavierData: []
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //----------------Get Exit Forms Reports API---------------------
    getEmployeeExitFormByAdmin = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            page_number: this.state.page ? this.state.page : 1,
            status: this.state.statusType === select ? '' : this.state.statusType,
            search_text: this.state.empID ? this.state.empID : '',
        }
        fetchApiCall(Config.getEmployeeExitFormByAdmin, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        exitFormReportData: res.data.data.paginated_data,
                        count: demData
                    }, () => this.commonAPIFunctionCall());
                    // this.onDismiss();
                    const tempExitFormData = this.state.exitFormReportData.map(separation_request_id => ({ value: separation_request_id.separation_request_id, value1: separation_request_id.employee_id }))
                    console.log('separation_request_id------>', tempExitFormData);
                    this.setState({
                        eSeparationId: tempExitFormData[0].value,
                        employeeID: tempExitFormData[0].value1,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        exitFormReportData: []
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //----------------Get Error in Posting Cases API---------------------
    getErrorInPostingRequests = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            page_number: this.state.page ? this.state.page : 1,
            search_text: this.state.empID ? this.state.empID : '',
        }
        fetchApiCall(Config.getErrorInPostingRequests, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        errorInPostingData: res.data.data.paginated_data,
                        count: demData
                    }, () => this.commonAPIFunctionCall());

                    const tempErrorInPostingData = this.state.errorInPostingData.map(separation_request_id => ({ value: separation_request_id.separation_request_id }))
                    this.setState({
                        eSeparationId: tempErrorInPostingData[0].value,
                        loading: false
                    }, () => this.getEsepRequestById())
                }
                else {
                    this.setState({
                        loading: false,
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //----------------Get Esep Request View All Data API---------------------
    getEsepRequestById = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : '',
        }
        fetchApiCall(Config.getEsepRequestById, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        employeeRequestData: res.data.data.request
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        employeeRequestData: []
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //----------------Get Company Data----------------------
    getCompanyNames = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        companyList: res.data.company_list ? res.data.company_list : [],
                        loading: false
                    })
                    const tempCompanyArray = this.state.companyList.map((item, idx) => ({
                        ...item,
                        value: item.company_sht_txt
                    }))
                    this.setState({
                        companyList: tempCompanyArray
                    })
                }
                else {
                    this.setState({ loading: false })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }
    //-----------Search Delegate Employee Data API-------------------
    getEmployeeToDelegate = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.state.companyCode ? this.state.companyCode : '',
            emp_id: this.state.empID ? this.state.empID : '',
        }
        fetchApiCall(Config.getEmployeeToDelegate, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        delegateEmpCompany: res.data.data.employee_details.CompCode === '0100' ? 'TML' : res.data.data.employee_details.CompCode === '0550' ? 'TMPVL' : res.data.data.employee_details.CompCode === '0650' ? 'TPEML' : res.data.data.employee_details.CompCode === '0300' ? 'TMLBSL' : '-',
                        delegateEmpCompCode: res.data.data.employee_details.CompCode ? res.data.data.employee_details.CompCode : '-',
                        delegateEmpID: res.data.data.employee_details.Perno ? res.data.data.employee_details.Perno : '-',
                        delegateEmpName: res.data.data.employee_details.CompName ? res.data.data.employee_details.CompName : '-',
                        isRecordShow: true,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        delegateEmployeeData: []
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-----------Update Delegate Details API-------------------------
    delegateEsepRequest = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.state.delegateEmpCompCode ? this.state.delegateEmpCompCode : '',
            emp_id: this.state.delegateEmpID ? this.state.delegateEmpID : '',
            delegate: this.state.empRoleType ? this.state.empRoleType : '',
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : ''
        }
        fetchApiCall(Config.delegateEsepRequest, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    if (this.state.empRoleType === 'DEM') {
                        this.setState({
                            loading: false,
                            isDEMDelegateSubmit: true,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        }, () => this.getEsepRequestById());
                    } else if (this.state.empRoleType === 'BHR') {
                        this.setState({
                            loading: false,
                            isBHRDelegateSubmit: true,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        }, () => this.getEsepRequestById());
                    } else if (this.state.empRoleType === 'BUHR') {
                        this.setState({
                            loading: false,
                            isBUHRDelegateSubmit: true,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        }, () => this.getEsepRequestById());
                    }
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    if (this.state.empRoleType === 'DEM') {
                        this.setState({
                            loading: false,
                            isDEMDelegateSubmit: false,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        });
                    } else if (this.state.empRoleType === 'BHR') {
                        this.setState({
                            loading: false,
                            isBHRDelegateSubmit: false,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        });
                    } else if (this.state.empRoleType === 'BUHR') {
                        this.setState({
                            loading: false,
                            isBUHRDelegateSubmit: false,
                            isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false
                        });
                    }
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-----------Get Employee Groups Details API-------------------------
    getGroup = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getGroup, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        groupData: res.data.data.groups
                    })

                    const tempGroupsData = this.state.groupData.map(tempValue => ({ value: tempValue.group }))
                    this.setState({
                        newGroupData: tempGroupsData,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        // groupData: [],
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
                this.downloadSeparationRequestByAdmin();
                this.downloadNoticePayWaiverRequests();
                this.downloadErrorInPostingRequests();
                this.downloadEmployeeExitFormByAdmin();
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-----------Get Emplyee Levels Details API-------------------------
    getLevel = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            group: this.state.groupValue
        }
        console.log('getLevel body------>', body)
        fetchApiCall(Config.getLevel, body, headers, true, 'admin')
            .then(res => {
                console.log('getLevel res------>', res)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        levelData: res.data.data.levels
                    })
                    const tempLevelData = this.state.levelData.map(tempValue => ({ value: tempValue.level }))
                    this.setState({
                        newLevelData: tempLevelData,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        // levelData: [],
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-----------Resolve Error In Posting Cases API---------------------
    resolveEsepErrorInPosting = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : '',
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
        }
        fetchApiCall(Config.resolveEsepErrorInPosting, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    })
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getErrorInPostingRequests();
                }
                else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-----------Revoke e-sepration---------------------
    revokeEsep = (data) => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.revokeEmpId ? this.state.revokeEmpId : '',
            comp_code: this.state.revokeEmployeeCompCode ? this.state.revokeEmployeeCompCode : '',
            revocation_reason: data
        }
        fetchApiCall(Config.revokeSeparationRequest, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        showRevokePopup: false
                    })
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getSeparationRequestByAdmin();
                    this.downloadSeparationRequestByAdmin();
                }
                else {
                    this.setState({
                        loading: false
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("revokeSeparationRequest error -> ", err)
                this.setState({
                    loading: false,
                });
            })
    }

    //------------Download Employee request all data----------------
    downloadSeparationRequestByAdmin = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            search_text: this.state.empID ? this.state.empID : '',
            group: this.state.groupValue === select ? '' : this.state.groupValue,
            level: this.state.levelValue === select ? '' : this.state.levelValue,
            from_date: this.state.actualDate ? this.state.actualDate : '',
            to_date: this.state.resignationDate ? this.state.resignationDate : '',
            status: this.state.statusType === select ? '' : this.state.statusType
        }
        fetchApiCall(Config.getSeparationRequestByAdmin, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        esepRequestExportData: res.data.data.paginated_data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        esepRequestExportData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-------------Download Notice Pay Waiver all data---------------------
    downloadNoticePayWaiverRequests = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            search_text: this.state.empID ? this.state.empID : '',
            group: this.state.groupValue === select ? '' : this.state.groupValue,
            level: this.state.levelValue === select ? '' : this.state.levelValue,
            from_date: this.state.actualDate ? this.state.actualDate : '',
            to_date: this.state.resignationDate ? this.state.resignationDate : '',
            status: this.state.statusType === select ? '' : this.state.statusType
        }
        fetchApiCall(Config.getNoticePayWaiverRequests, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        noticePayWavierExportData: res.data.data.paginated_data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        noticePayWavierExportData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //--------------Download Exit form reports all data---------------------
    downloadEmployeeExitFormByAdmin = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
            status: this.state.statusType === select ? '' : this.state.statusType
        }
        fetchApiCall(Config.getEmployeeExitFormByAdmin, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        exitFormExportData: res.data.data.paginated_data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        exitFormExportData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-------------Download Employee request all data--------------------
    downloadErrorInPostingRequests = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            search_text: this.state.empID ? this.state.empID : '',
            comp_code: this.props.adminLoginData.company.company_code ? this.props.adminLoginData.company.company_code : '',
        }
        fetchApiCall(Config.getErrorInPostingRequests, body, headers, true, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        errorInPostingExportData: res.data.data.paginated_data,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        errorInPostingExportData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }
    //-------------Fetching All Exit Form Data Records----------------------
    getSeparationDetail = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            employee_seperation_id: this.state.eSeparationId ? this.state.eSeparationId : '',
            emp_id: this.state.employeeID ? this.state.employeeID : '',
        }
        fetchApiCall(Config.separationForm, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        separationExitData: res.data.user_details ? res.data.user_details : [],
                        isAdminView: false,
                        isExitView: true,
                    })
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ loading: false, isExitView: false, isAdminView: false, separationExitData: [] })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error('Unable to Contact Server Error', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    changeCompanyType = (data) => {
        this.setState({
            companyValue: data.company_sht_txt,
            companyCode: data.company_code,
        })
    }

    onActionView = (listValue) => {
        if (this.state.isEmployeeRequestActive === true || this.state.isNoticeWavierActive === true) {
            this.setState({
                isAdminView: true,
                isExitView: false,
                separationRequestId: listValue.separation_request_id,
                lwdAsPerPolicy: listValue.lwd_as_per_policy,
                actualLWDDate: listValue.actual_resignation_date,
                resignationRequestDate: listValue.resignation_request_date,
                lwdRequestedByEmployee: listValue.lwd_requested_by_employee,
                employeeSeparationRemark: listValue.employee_separation_remark,
                employeeName: listValue.employee_name,
                actualResignationDate: listValue.actual_resignation_date,
                employeeSeparationReason: listValue.employee_separation_reason,
                createdBy: listValue.created_by,
                noticePeriodDays: listValue.notice_period_days,
                lwdJustificationEmployee: listValue.lwd_justification_employee,
                separationStatus: listValue.separation_status,
                isNoticePayWaveOffBhr: listValue.is_notice_pay_wave_off_bhr,
                isNoticePayWaveOffDem: listValue.is_notice_pay_wave_off_dem,
                attachmentUrl: listValue.attachment_url,
                noticeSeriodShortfallDays: listValue.notice_period_shortfall_days,
                noticePayRecoveryAmount: listValue.notice_pay_recovery_amount,
                lwdJustificationDem: listValue.lwd_justification_dem,
                lwdJustificationBhr: listValue.lwd_justification_bhr,
                waveOffJustificationBhr: listValue.wave_off_justification_bhr,
                waveOffJustificationDem: listValue.wave_off_justification_dem,
                lwdRecommendedByBhr: listValue.lwd_recommended_by_bhr,
                lwdRecommendedByDem: listValue.lwd_recommended_by_dem,
                demApprovalRemark: listValue.dem_approval_remark,
                bhrApprovalRemark: listValue.bhr_approval_remark,
                buhrApprovalRemark: listValue.buhr_approval_remark,
                demName: listValue.dem_name,
                bhrName: listValue.bhr_name,
                buhrName: listValue.buhr_name,
            })
        } else if (this.state.isErrorPostingActive === true) {
            this.setState({
                isAdminView: true,
                isExitView: false,
                employeeRequestData: this.state.employeeRequestData
            })
        } else if (this.state.isExitFormActive === true) {
            this.setState({
                employeeID: listValue.employee_id,
                eSeparationId: listValue.separation_request_id
            }, () => this.getSeparationDetail())
        }
    }

    onClickDelegate = (listValue) => {
        this.setState({ isDelegatePopup: !this.state.isDelegatePopup, eSeparationId: listValue.separation_request_id, createdBy: listValue.created_by, separationStatus: listValue.separation_status, }, () => this.getEsepRequestById())
    }

    onClickFilter = () => {
        this.setState({
            isFilter: !this.state.isFilter
        })
    }

    onClickExport = () => {
        console.log('call');
        this.setState({
            isExport: !this.state.isExport
        })
    }

    handleChange = (e) => { this.setState({ empID: e }) }

    onDismiss = () => {
        this.setState({ resignationDate: '', actualDate: '', levelValue: 'Select', groupValue: 'Select', statusType: 'Select' })
    }

    clearAllFilter = () => {
        if (this.state.isEmployeeRequestActive === true) {
            this.setState({ resignationDate: '', actualDate: '', levelValue: 'Select', groupValue: 'Select', statusType: 'Select' }, () => this.getSeparationRequestByAdmin())
        } else if (this.state.isNoticeWavierActive === true) {
            this.setState({ resignationDate: '', actualDate: '', levelValue: 'Select', groupValue: 'Select', statusType: 'Select' }, () => this.getNoticePayWaiverRequests())
        } else if (this.state.isExitFormActive === true) {
            this.setState({ statusType: 'Select' }, () => this.getEmployeeExitFormByAdmin())
        }
    }

    searchFilterFunction = () => {
        if (this.state.isEmployeeRequestActive === true) {
            this.getSeparationRequestByAdmin();
        } else if (this.state.isNoticeWavierActive === true) {
            this.getNoticePayWaiverRequests();
        } else if (this.state.isErrorPostingActive === true) {
            this.getErrorInPostingRequests();
        } else if (this.state.isExitFormActive === true) {
            this.getEmployeeExitFormByAdmin();
        }
    }

    //--------------Pagination Function Handled------------------------------
    handlePageCount = (type) => {
        let pageCountInc = this.state.page + 1
        let pageCountDec = this.state.page - 1
        if (this.state.isEmployeeRequestActive === true || this.state.isNoticeWavierActive === true || this.state.isExitFormActive === true || this.state.isErrorPostingActive === true) {
            if (type === "increment") {
                if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                    this.setState({ page: pageCountInc },
                        () => this.searchFilterFunction()
                    )
                }
            }
            else if (type === "decrement") {
                if (this.state.page > 1) {
                    this.setState({ page: pageCountDec },
                        () => this.searchFilterFunction()
                    )
                }
            }
        }
    }

    selectedPage = (pageCount) => {
        if (this.state.isEmployeeRequestActive === true || this.state.isNoticeWavierActive === true || this.state.isExitFormActive === true || this.state.isErrorPostingActive === true) {
            this.setState({ page: pageCount }, () => this.searchFilterFunction())
        }
    }

    changeGroupsType = (data) => {
        this.setState({
            groupValue: data.value,
        }, () => this.getLevel())
    }

    changeLevelType = (data) => {
        this.setState({
            levelValue: data.value,
        })
    }

    changeStatusType = (data) => {
        this.setState({
            statusCode: data.code,
            statusType: data.value,
        })
    }

    commonAPIFunctionCall = () => {
        if (this.state.isEmployeeRequestActive === true) {
            this.setState({
                masterData: this.state.masterRequestData
            })
        } else if (this.state.isNoticeWavierActive === true) {
            this.setState({
                masterData: this.state.noticePayWavierData
            })
        } else if (this.state.isErrorPostingActive === true) {
            this.setState({
                masterData: this.state.errorInPostingData
            })
        } else if (this.state.isExitFormActive === true) {
            this.setState({
                masterData: this.state.exitFormReportData
            })
        }
    }

    proceedToDelegate = (data) => {
        this.setState({ isDelegate: true, empRoleType: data }, () => this.getCompanyNames())
    }

    back = () => {
        this.setState({ isDelegate: false, empID: '', isAgree: false, companyValue: 'Select', isRecordShow: false })
    }

    searchDelegateData = () => {
        if (this.state.empRoleType === 'DEM' || this.state.empRoleType === 'BHR' || this.state.empRoleType === 'BUHR') {
            if (this.state.companyValue === 'Select' || this.state.companyValue === '') {
                toast.error('Please select company', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.empID === '') {
                toast.error('Please enter employee id', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else {
                this.getEmployeeToDelegate();
            }
        }
    }

    submitDelegate = () => {
        if (this.state.empRoleType === 'DEM' || this.state.empRoleType === 'BHR' || this.state.empRoleType === 'BUHR') {
            this.delegateEsepRequest();
        }
    }

    onClose = () => {
        this.setState({ isDelegatePopup: false, isBUHRDelegateSubmit: false, isDEMDelegateSubmit: false, isBHRDelegateSubmit: false, companyValue: 'Select', empID: '' })
    }

    render() {
        const { isExitView, esepRequestExportData, noticePayWavierExportData, exitFormExportData, errorInPostingExportData, isErrorPostingActive, isExitFormActive, isNoticeWavierActive, isEmployeeRequestActive, isDelegatePopup, isDEMDelegateSubmit, isBHRDelegateSubmit, isBUHRDelegateSubmit, isDelegate, isAgree, empRoleType, isRecordShow, isAdminView, employeeRequestData, isFilter, isExport, noticePayWavierData, masterRequestData, masterData, radioFilters, isDelete, buhrName, bhrName, demName, lwdRecommendedByDem, lwdRecommendedByBhr, buhrApprovalRemark, bhrApprovalRemark, demApprovalRemark, waveOffJustificationDem, waveOffJustificationBhr, lwdJustificationBhr, lwdJustificationDem, noticePayRecoveryAmount, noticeSeriodShortfallDays, isManagerApprovedPopup, lwdJustificationEmployee, separationStatus, noticePeriodDays, lwdAsPerPolicy, createdBy, employeeSeparationReason, actualResignationDate, employeeName, employeeSeparationRemark, lwdRequestedByEmployee, resignationRequestDate, separationRequestId, isNoticePayWaveOffBhr, isNoticePayWaveOffDem, showRevokePopup } = this.state;
        return (
            <div className="row p-12px">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0 pt-0">
                    <div className='row mt-1'></div>
                    <div className='card-new col-md-12'>
                        <div>
                            <>
                                <div className='d-flex justify-content-between'>
                                    <div className='row mt-3'>
                                        <div className={isEmployeeRequestActive ? "tab-active" : "tab-inactive"} onClick={this.toggleEmployeeRequestDetails}>{'Employee Requests'}</div>
                                        <div className={isNoticeWavierActive ? "tab-active" : "tab-inactive"} onClick={this.toggleNoticeWavierDetails}>{'Notice Pay Wavier Requests'}</div>
                                        <div className={isExitFormActive ? "tab-active" : "tab-inactive"} onClick={this.toggleExitDetails}>{'Exit Form Reports'}</div>
                                        <div className={isErrorPostingActive ? "tab-active" : "tab-inactive"} onClick={this.toggleErrorPostingDetails}>{'Error in Posting Requests'}</div>
                                    </div>
                                    <div className='row mt-10px'>
                                        <div className='row mt-2'>
                                            <div className='p-placeholder-text mb-1 mr-3'>
                                                <input
                                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                                    type="text"
                                                    placeholder='Enter emp id'
                                                    onChange={(e) => this.setState({ empID: e.target.value })}
                                                    value={this.state.empID}
                                                />
                                            </div>
                                            <div type='button' className='serchIconSize'
                                                onClick={() => this.searchFilterFunction()}
                                            ><div className='mr-4'><img alt="radioOn" className='filterIcon' src={searchIcon} ></img></div>
                                            </div>
                                        </div>

                                        {!isErrorPostingActive &&
                                            <div data-tooltip2={'Filter'} className="filterIconLabel infoIconStyle ml-1" >
                                                <div className='row mt-1' onClick={() => this.onClickFilter()}>
                                                    <div className='mr-4'><img alt="radioOn" className='filterIcon' src={isFilter ? filterIconActive : filterIconInactive} ></img></div>
                                                </div>
                                            </div>
                                        }
                                        {/*------Epmloyee Requests && Notice Pay Requests Excel Export Data----------- */}
                                        {isEmployeeRequestActive || isNoticeWavierActive ?
                                            <ExcelFile filename={isEmployeeRequestActive ? 'e-Separation Employee Requests Report' : isNoticeWavierActive ? 'Notice Pay Wavier Requests Report' : 'e-Separation Report'}
                                                element={
                                                    <div data-tooltip2={'Export'} className="filterIconLabel infoIconStyle ml-1" >
                                                        <div className='row mt-1'
                                                            onClick={() => this.onClickExport()}
                                                            onMouseOver={() => this.setState({ isExport: true })}
                                                            onMouseLeave={() => this.setState({ isExport: false })}>
                                                            <div className='mr-4'><img alt="excel" className='filterIcon' src={isExport ? exportExcelconActive : exportExcelcon} ></img></div>
                                                        </div>
                                                    </div>
                                                }>
                                                <ExcelSheet
                                                    data={isEmployeeRequestActive ? esepRequestExportData : isNoticeWavierActive ? noticePayWavierExportData : []}
                                                    name="Employees"
                                                >
                                                    <ExcelColumn label="EMPLOYEE POSITION CODE" value="position_code" />
                                                    <ExcelColumn label="EMPLOYEE ID" value="employee_id" />
                                                    <ExcelColumn label="EMPLOYEE NAME" value="employee_name" />
                                                    <ExcelColumn label="EMPLOYEE LEVEL" value="employee_level" />
                                                    <ExcelColumn label="EMPLOYEE GROUP" value="employee_group" />
                                                    <ExcelColumn label="EMPLOYEE CONTACT NO" value="employee_contact_no" />
                                                    <ExcelColumn label="EMPLOYEE EAMIL ID" value="employee_personal_email" />
                                                    <ExcelColumn label="PA" value="employee_pa" />
                                                    <ExcelColumn label="PSA" value="employee_psa" />
                                                    <ExcelColumn label="RESIGNATION REQUEST DATE" value="resignation_request_date" />
                                                    <ExcelColumn label="ACTUAL RESIGNATION DATE" value="actual_resignation_date" />
                                                    <ExcelColumn label="LWD AS PER POLICY DATE" value="lwd_as_per_policy" />

                                                    <ExcelColumn label="SEPARATION REQUEST ID" value="separation_request_id" />
                                                    <ExcelColumn label="SEPARATION STATUS" value="separation_status" />
                                                    <ExcelColumn label="NOTICE PERIOD DAYS" value="notice_period_days" />
                                                    <ExcelColumn label="NOTICE PERIOD SHORTFALL DAYS" value="notice_period_shortfall_days" />
                                                    <ExcelColumn label="NOTICE PAY RECOVERY AMOUNT" value="notice_pay_recovery_amount" />

                                                    <ExcelColumn label="DEM POSITION CODE" value="dem_position_code" />
                                                    <ExcelColumn label="DEM EMPLOYEE ID" value="dem_emp_id" />
                                                    <ExcelColumn label="DEM NAME" value="dem_name" />
                                                    <ExcelColumn label="LWD RECOMMENDED BY DEM" value="lwd_recommended_by_dem" />

                                                    <ExcelColumn label="BHR POSITION CODE" value="bhr_position_code" />
                                                    <ExcelColumn label="BHR EMPLOYEE ID" value="bhr_emp_id" />
                                                    <ExcelColumn label="BHR NAME" value="bhr_name" />
                                                    <ExcelColumn label="LWD RECOMMENDED BY BHR" value="lwd_recommended_by_bhr" />

                                                    <ExcelColumn label="BUHR POSITION CODE" value="buhr_position_code" />
                                                    <ExcelColumn label="BUHR EMPLOYEE ID" value="buhr_emp_id" />
                                                    <ExcelColumn label="BUHR NAME" value="buhr_name" />
                                                    <ExcelColumn label="REVOCATION REASON" value="revocation_reason" />

                                                    <ExcelColumn
                                                        label="REVOKED BY"
                                                        value={rowData => rowData.separation_status === 'Revoked by Admin' ? rowData.modified_by : 'NA'}
                                                    />
                                                    <ExcelColumn
                                                        label="REVOKED ON"
                                                        value={rowData => rowData.separation_status === 'Revoked by Admin' ? moment(rowData.modified_datetime).format('DD-MM-YYYY') : 'NA'}
                                                    />

                                                </ExcelSheet>
                                            </ExcelFile> : null
                                        }
                                        {/* ----------Exit Form Excel Export Data------------------------------ */}
                                        {isExitFormActive &&
                                            <ExcelFile filename={'Employee Exit Form Report'}
                                                element={
                                                    <div data-tooltip2={'Export'} className="filterIconLabel infoIconStyle ml-1" >
                                                        <div className='row mt-1'
                                                            onClick={() => this.onClickExport()}
                                                            onMouseOver={() => this.setState({ isExport: true })}
                                                            onMouseLeave={() => this.setState({ isExport: false })}>
                                                            <div className='mr-4'><img alt="excel" className='filterIcon' src={isExport ? exportExcelconActive : exportExcelcon} ></img></div>
                                                        </div>
                                                    </div>
                                                }>
                                                <ExcelSheet
                                                    data={exitFormExportData}
                                                    name="Employees Exit Form Data"
                                                >
                                                    <ExcelColumn label="EMPLOYEE ID" value="employee_id" />
                                                    <ExcelColumn label="EMPLOYEE NAME" value="employee_name" />
                                                    <ExcelColumn label="EMPLOYEE COMPANY CODE" value="employee_comp_code" />
                                                    <ExcelColumn label="SEPARATION REQUEST ID" value="separation_request_id" />
                                                    <ExcelColumn label="SEPARATION STATUS" value="separation_status" />
                                                    <ExcelColumn label="STATUS" value="status" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }
                                        {/* ----------Error in Posting Cases Excel Export Data------------- */}
                                        {isErrorPostingActive &&
                                            <ExcelFile filename={'Employee Error In Posting Report'}
                                                element={
                                                    <div data-tooltip2={'Export'} className="filterIconLabel infoIconStyle ml-1" >
                                                        <div className='row mt-1'
                                                            onClick={() => this.onClickExport()}
                                                            onMouseOver={() => this.setState({ isExport: true })}
                                                            onMouseLeave={() => this.setState({ isExport: false })}>
                                                            <div className='mr-4'><img alt="excel" className='filterIcon' src={isExport ? exportExcelconActive : exportExcelcon} ></img></div>
                                                        </div>
                                                    </div>
                                                }>
                                                <ExcelSheet
                                                    data={errorInPostingExportData}
                                                    name="Error in Posting Data"
                                                >
                                                    <ExcelColumn label="EMPLOYEE ID" value="employee_id" />
                                                    <ExcelColumn label="EMPLOYEE NAME" value="employee_name" />
                                                    <ExcelColumn label="EMPLOYEE COMPANY CODE" value="employee_comp_code" />
                                                    <ExcelColumn label="SEPARATION REQUEST ID" value="separation_request_id" />
                                                    <ExcelColumn label="POSTING STATUS" value="posting_status" />
                                                    <ExcelColumn label="SAP RESPONSE" value="sap_error_response" />
                                                    <ExcelColumn label="LAST WORKING DATE" value="last_working_day" />
                                                    <ExcelColumn label="EDP RESPONSE" value="edp_error_response" />
                                                    <ExcelColumn label="BHR APPROVAL DATE" value="bhr_approval_date" />
                                                </ExcelSheet>
                                            </ExcelFile>
                                        }

                                    </div>
                                </div>
                                <div className='mt-3px'><hr /></div>
                                {/* ---------------------------Filter Option Code------------------------ */}
                                {!isErrorPostingActive &&
                                    <div>
                                        {isFilter &&
                                            <div>
                                                <div className='row mt-3'>
                                                    <div className='col-md-4 col-sm-6 col-12'>
                                                        <div className='p-sub-heading ml-3 mb-1'>{'Status'}</div>
                                                        <div className='p-placeholder-text mb-1'>
                                                            <Dropdown
                                                                data={isExitFormActive ? this.state.exitFormStatusData : isNoticeWavierActive ? this.state.noticePayStatusData : this.state.statusData}
                                                                selectedValue={(data) => this.changeStatusType(data)}
                                                                value={this.state.statusType}
                                                                render={'value'}
                                                                height={'NotificationHeight'}
                                                            />
                                                        </div>
                                                    </div>
                                                    {!isExitFormActive &&
                                                        <>
                                                            <div className='col-md-4 col-sm-6 col-12'>
                                                                <div className='p-sub-heading ml-3 mb-1'>{'Groups'}</div>
                                                                <div className='p-placeholder-text mb-1'>
                                                                    <Dropdown
                                                                        data={this.state.newGroupData}
                                                                        selectedValue={(data) => this.changeGroupsType(data)}
                                                                        value={this.state.groupValue}
                                                                        render={'value'}
                                                                        height={'NotificationHeight'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12'>
                                                                <div className='p-sub-heading ml-3 mb-1'>{'Level'}</div>
                                                                <div className='p-placeholder-text mb-1'>
                                                                    <Dropdown
                                                                        data={this.state.newLevelData}
                                                                        selectedValue={(data) => this.changeLevelType(data)}
                                                                        value={this.state.levelValue}
                                                                        render={'value'}
                                                                        height={'NotificationHeight'}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12  mb-2'>
                                                                <div className='p-sub-heading ml-3 mb-1'>{'Resignation Request From Date'}</div>
                                                                <div className='p-placeholder-text mb-1'>
                                                                    <input
                                                                        className="form-control p-placeholder px-2 p-placeholder-admin"
                                                                        type="date"
                                                                        placeholder='Select the date'
                                                                        onChange={(e) => this.setState({ actualDate: e.target.value })}
                                                                        value={this.state.actualDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='col-md-4 col-sm-6 col-12 mb-2'>
                                                                <div className='p-sub-heading ml-3 mb-1'>{'Resignation Request To Date'}</div>
                                                                <div className='p-placeholder-text mb-1'>
                                                                    <input
                                                                        className="form-control p-placeholder px-2 p-placeholder-admin"
                                                                        type="date"
                                                                        placeholder='Select the date'
                                                                        onChange={(e) => this.setState({ resignationDate: e.target.value })}
                                                                        value={this.state.resignationDate}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className='d-flex justify-content-end mr-4 mb-2'>
                                                    <input type='button' onClick={isFilter ? () => this.clearAllFilter() : () => this.onDismiss()} value='CLEAR ALL' className='cancelBtn' />
                                                    <input type='button' value='SEARCH' className='ml-2 validateBtn' onClick={() => this.searchFilterFunction()} />
                                                </div>
                                            </div>
                                        }
                                        <div className='mt-3px'><hr /></div>
                                    </div>
                                }
                            </>
                            {/* --------------------------------Common Table------------------------------ */}
                            {isEmployeeRequestActive || isNoticeWavierActive || isExitFormActive || isErrorPostingActive ?
                                <>
                                    {masterData.length != 0 ?
                                        <>
                                            <div className='col-md-12 flex-direction-column mt-1'>
                                                <div className={isFilter ? 'scroll-y h-27vh' : 'scroll-y h-60vh'}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"><div className='thead pr-0 w-35px'>{'Sr No.'}</div></th>
                                                                <th scope="col"><div className='thead'>{'Employee ID'}</div></th>
                                                                <th scope="col"><div className='thead'>{isErrorPostingActive ? 'Separation Request ID' : 'Employee Name'}</div></th>
                                                                <th scope="col"><div className='thead'>{isErrorPostingActive || isExitFormActive ? 'Company' : 'Reason'}</div></th>
                                                                <th scope="col"><div className='thead'>{isErrorPostingActive ? 'Posting Status' : isExitFormActive ? 'Separation Request ID' : 'Status'}</div></th>
                                                                <th scope="col"><div className='thead'>{isErrorPostingActive ? 'EDP Error Response' : isExitFormActive ? 'Status' : 'Pending With'}</div></th>
                                                                <th scope="col"><div className='thead'>{isErrorPostingActive ? 'Last Working Date' : isExitFormActive ? 'Separation Status' : 'Resignation Request Date'}</div></th>
                                                                {!isExitFormActive && <th scope="col"><div className='thead'>{isErrorPostingActive ? 'BHR Approval Date' : 'Actual Date of Resignation'}</div></th>}
                                                                {!isExitFormActive && <th scope="col"><div className='thead'>{isErrorPostingActive ? 'SAP Error Response' : 'Notice Period Days'}</div></th>}
                                                                <th scope="col"><div className='thead ml-06rem'>{'Action'}</div></th>
                                                                <th scope="col"><div className='thead ml-06rem'>{'Revoke'}</div></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {masterData.map((listValue, index) => {
                                                                return (
                                                                    <tr key={index} >
                                                                        <td><div className="td w-20px">{index + 1}</div></td>
                                                                        <td> <div className="td d-flex">{listValue.employee_id ? listValue.employee_id : '-'}</div> </td>
                                                                        <td> <div className="td d-flex">{isErrorPostingActive ? listValue.separation_request_id ? listValue.separation_request_id : '-' : listValue.employee_name ? listValue.employee_name : '-'}</div> </td>

                                                                        <td>
                                                                            <div className="td d-flex">
                                                                                {isErrorPostingActive || isExitFormActive ?
                                                                                    listValue.employee_comp_code === '0100' ? 'TML' :
                                                                                        listValue.employee_comp_code === '0550' ? 'TMPVL' :
                                                                                            listValue.employee_comp_code === '0650' ? 'TPEML' :
                                                                                                listValue.employee_comp_code === '0300' ? 'TMLBSL' : '-'
                                                                                    :
                                                                                    listValue.employee_separation_reason ? listValue.employee_separation_reason : '-'}
                                                                            </div>
                                                                        </td>

                                                                        <td><div className="td">{isErrorPostingActive ? listValue.posting_status ? listValue.posting_status : '-' : isExitFormActive ? listValue.separation_request_id ? listValue.separation_request_id : '-' : listValue.separation_status ? listValue.separation_status : '-'}</div></td>

                                                                        {!isErrorPostingActive &&
                                                                            <td>
                                                                                <div className="td">
                                                                                    {isExitFormActive ? listValue.status ? listValue.status : '-' :
                                                                                        listValue.separation_status === 'Pending' ? listValue.dem_name + ' (Manager)' :
                                                                                            listValue.separation_status === 'Approved by DEM' ? listValue.bhr_name + ' (BHR)' : 'NA'
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                        <td> <div className="td d-flex">{isErrorPostingActive ? listValue.edp_error_response ? listValue.edp_error_response : '-' : isExitFormActive ? listValue.separation_status ? listValue.separation_status : '-' : moment(listValue.resignation_request_date).format("DD-MM-YYYY") ? moment(listValue.resignation_request_date).format("DD-MM-YYYY") : '-'}</div> </td>
                                                                        {!isExitFormActive && <td> <div className="td d-flex">{isErrorPostingActive ? moment(listValue.last_working_day).format("DD-MM-YYYY") ? moment(listValue.last_working_day).format("DD-MM-YYYY") : '-' : moment(listValue.actual_resignation_date).format("DD-MM-YYYY") ? moment(listValue.actual_resignation_date).format("DD-MM-YYYY") : '-'}</div> </td>}
                                                                        {!isExitFormActive && <td><div className="td">{isErrorPostingActive ? moment(listValue.bhr_approval_date).format("DD-MM-YYYY") ? moment(listValue.bhr_approval_date).format("DD-MM-YYYY") : '-' : listValue.notice_period_days ? listValue.notice_period_days : '-'}</div></td>}

                                                                        {isErrorPostingActive && <td><div className="td">{listValue.sap_error_response ? listValue.sap_error_response : '-'}</div></td>}

                                                                        <td className='d-flex'>
                                                                            <div onClick={() => this.onActionView(listValue)}>
                                                                                <img alt="view" src={view_action} className='pointer mx-2' />
                                                                            </div>

                                                                            {isErrorPostingActive &&
                                                                                <div onClick={() => this.resolveEsepErrorInPosting()}>
                                                                                    <img alt="view" src={resolveErrorIcon} className='pointer mx-2' />
                                                                                </div>}

                                                                            {isEmployeeRequestActive &&
                                                                                <div onClick={() => this.onClickDelegate(listValue)}>
                                                                                    <img alt="view" src={delegateIcon} className='pointer mx-2 h-31px' />
                                                                                </div>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {listValue.separation_status === 'Approved by BHR and Updated' ?
                                                                                <div
                                                                                    onClick={() => this.setState({
                                                                                        showRevokePopup: true,
                                                                                        revokeEmpId: listValue.employee_id,
                                                                                        revokeEmployeeCompCode: listValue.employee_comp_code
                                                                                    })}>
                                                                                    <img alt="view" src={esep_revoke} className='pointer mx-2 hw-25px' />
                                                                                </div>
                                                                                :
                                                                                <div>{'-'}</div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className={isFilter ? 'd-flex justify-content-center align-item-center' : 'd-flex justify-content-center align-item-center min-h-40vh'}>
                                            <DataNotFound />
                                        </div>
                                    }

                                    {/* ----------------------------Pagination model-------------------------------- */}
                                    <>
                                        {masterData.length != 0 ?
                                            <div className="col-md-12 w-100 d-flex justify-content-center">
                                                <Pagination>
                                                    <Pagination.Prev
                                                        onClick={() => this.handlePageCount("decrement")} />
                                                    {this.state.count.length > 1 &&
                                                        <Pagination.Item onClick={() => this.selectedPage(1)}>{1}</Pagination.Item>}<Pagination.Ellipsis />
                                                    <Pagination.Item active>{this.state.page}</Pagination.Item><Pagination.Ellipsis />
                                                    {this.state.count.length > 1 &&
                                                        <Pagination.Item onClick={() => this.selectedPage(this.state.count[this.state.count.length - 1])} >{this.state.count[this.state.count.length - 1]}</Pagination.Item>}
                                                    <Pagination.Next
                                                        onClick={() => this.handlePageCount("increment")} />
                                                </Pagination>
                                            </div> : null}
                                    </>
                                </> : null
                            }

                        </div>
                    </div>
                </div>

                {/* ----------------------------------Delegate popup----------------------------------- */}
                {isDelegatePopup &&
                    <div className="modal popup-box">
                        <LoaderIndicator loading={this.state.loading} />
                        <div className={'popupCard-box p-0 w-40'}>
                            <div className="modal-header">
                                <h4 className="modal_title black">{'Delegate Confirmations'}</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={() => this.onClose()}>&times;</button>
                            </div>
                            <div className={isDelegate ? "modal-body h-40vh scroll-y" : "modal-body min-h-48vh scroll-y"}>
                                {isDelegate ?
                                    <div className='row d-flex'>
                                        <div className='row mt-3'>
                                            <div className='row'>
                                                <div className='column ml-3'>
                                                    <div className='p-sub-heading ml-3 mb-1'>{'Select Company'}</div>
                                                    <div className='esep-placeholder-editable w-11rem'>
                                                        <Dropdown
                                                            data={this.state.companyList}
                                                            selectedValue={(data) => this.changeCompanyType(data)}
                                                            value={this.state.companyValue}
                                                            render={'value'}
                                                            arrow={'true'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='column ml-3'>
                                                    <div className='p-sub-heading ml-3 mb-1'>{'Employee ID'}</div>
                                                    <div className='esep-placeholder-editable mb-1'>
                                                        <input
                                                            className="form-control p-placeholder px-2 p-placeholder-admin"
                                                            type="text"
                                                            placeholder='Enter emp id'
                                                            onChange={(e) => this.setState({ empID: e.target.value })}
                                                            value={this.state.empID}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ml-4 mr-2 m-26px'>
                                                <input type='button' value='SEARCH' className='ml-2 validateBtn' onClick={() => this.searchDelegateData()} />
                                            </div>
                                        </div>
                                        <hr />
                                        {isRecordShow &&
                                            <div className='column ml-1'>
                                                <div className='card-content-title mx-2 mt-2 ml-3'>{empRoleType === 'DEM' ? 'DEM Delegation Details' : empRoleType === 'BHR' ? 'BHR Delegation Details' : empRoleType === 'BUHR' ? 'BUHR Delegation Details' : 'Delegation Details'}</div>
                                                <div className='row justify-content-between mt-3'>
                                                    <div className='d-flex'>
                                                        <div className='column ml-3'>
                                                            <div className='card-content-title ml-2'>{'Company Name:'}</div><hr />
                                                            <div className='p-sub-heading ml-2'>{this.state.delegateEmpCompany ? this.state.delegateEmpCompany : '-'}</div>
                                                        </div>

                                                        <div className='column ml-3'>
                                                            <div className='card-content-title ml-2'>{'Employee ID:'}</div><hr />
                                                            <div className='p-sub-heading ml-2'>{this.state.delegateEmpID ? this.state.delegateEmpID : '-'}</div>
                                                        </div>

                                                        <div className='column ml-3'>
                                                            <div className='card-content-title ml-2'>{'Employee Name:'}</div><hr />
                                                            <div className='p-sub-heading ml-2'>{this.state.delegateEmpName ? this.state.delegateEmpName : '-'}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex align-item-center mt-36px'>
                                                    <div className='d-flex ml-3'>
                                                        <label className="checkBoxData pl-3">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                checked={isAgree}
                                                                onChange={e => this.setState({ isAgree: !isAgree })}
                                                            />
                                                            <span className="markk"></span>
                                                        </label>
                                                        <div className='rememberMe align-item-center'>{'Are you sure want to confirm'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className='mt-3'>
                                        <div>
                                            <div className='card-content-title mx-2 mt-2 ml-3'>{'DEM Delegation Details'}</div>
                                            <div className='row d-flex justify-content-between mt-2'>
                                                <div className='d-flex'>
                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee ID:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.dem_emp_id ? employeeRequestData.dem_emp_id : '-'}</div>
                                                    </div>

                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee Name:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.dem_name ? employeeRequestData.dem_name : '-'}</div>
                                                    </div>
                                                </div>

                                                {isDEMDelegateSubmit ?
                                                    <div className='card-content-title green-tag mt-12px mr-5rem'>{'DEM Delegated'}</div>
                                                    :
                                                    <>
                                                        {separationStatus === 'Pending' ?
                                                            <div className='mr-3'>
                                                                <input type='button' value='DEM DELEGATE' className='ml-2 validateBtn' onClick={() => this.proceedToDelegate('DEM')} />
                                                            </div>
                                                            :
                                                            <div className='mr-3'>
                                                                <input type='button' value='DEM DELEGATE' className='ml-2 disableBtn' />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className='mt-2 mb-2'><hr /></div>
                                        </div>

                                        <div>
                                            <div className='card-content-title mx-2 mt-2 ml-3'>{'BHR Delegation Details'}</div>
                                            <div className='row d-flex justify-content-between mt-2'>
                                                <div className='d-flex'>
                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee ID:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.bhr_emp_id ? employeeRequestData.bhr_emp_id : '-'}</div>
                                                    </div>

                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee Name:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.bhr_name ? employeeRequestData.bhr_name : '-'}</div>
                                                    </div>
                                                </div>

                                                {isBHRDelegateSubmit ?
                                                    <div className='card-content-title green-tag mt-12px mr-5rem'>{'BHR Delegated'}</div>
                                                    :
                                                    <>
                                                        {separationStatus === 'Pending' || separationStatus === 'Approved by DEM' ?
                                                            <div className='mr-3'>
                                                                <input type='button' value='BHR DELEGATE' className='ml-2 validateBtn' onClick={() => this.proceedToDelegate('BHR')} />
                                                            </div>
                                                            :
                                                            <div className='mr-3'>
                                                                <input type='button' value='BHR DELEGATE' className='ml-2 disableBtn' />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className='mt-2 mb-2'><hr /></div>
                                        </div>

                                        <div>
                                            <div className='card-content-title mx-2 mt-2 ml-3'>{'BUHR Delegation Details'}</div>
                                            <div className='row d-flex justify-content-between mt-2'>
                                                <div className='d-flex'>
                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee ID:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.buhr_emp_id ? employeeRequestData.buhr_emp_id : '-'}</div>
                                                    </div>

                                                    <div className='column ml-3'>
                                                        <div className='p-sub-heading ml-2'>{'Employee Name:'}</div>
                                                        <hr />
                                                        <div className='p-sub-heading ml-2'>{employeeRequestData.buhr_name ? employeeRequestData.buhr_name : '-'}</div>
                                                    </div>
                                                </div>

                                                {isBUHRDelegateSubmit ?
                                                    <div className='card-content-title green-tag mt-12px mr-4_5rem'>{'BUHR Delegated'}</div>
                                                    :
                                                    <>
                                                        {separationStatus === 'Pending' || separationStatus === 'Approved by DEM' || separationStatus === 'Approved by BHR and Updated' ?
                                                            <div className='mr-3'>
                                                                <input type='button' value='BUHR DELEGATE' className='ml-2 validateBtn' onClick={() => this.proceedToDelegate('BUHR')} />
                                                            </div>
                                                            :
                                                            <div className='mr-3'>
                                                                <input type='button' value='BUHR DELEGATE' className='ml-2 disableBtn' />
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className='mt-2 mb-2'><hr /></div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {isDelegate &&
                                <div className='modal-footer btnBox1'>
                                    <input type='button' onClick={() => this.back()} value='BACK' className='cancelBtn' />
                                    {isAgree ?
                                        <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.submitDelegate()} /> :
                                        <input type='button' value='CONFIRM' className='ml-2 disableBtn' />}
                                </div>}
                        </div>
                    </div >
                }
                {/* ------------Exit Request Tracking Status and View Data Popup-------------- */}
                {isAdminView &&
                    <EsepTrackerDashboard
                        title={'e-Separation Requests Dashboard'}
                        action={'viewEsepRequest'}
                        onDismiss={() => this.setState({ isAdminView: false })}
                        adminMasterRequestData={masterRequestData}
                        separationRequestId={separationRequestId}
                        resignationRequestDate={resignationRequestDate}
                        lwdRequestedByEmployee={lwdRequestedByEmployee}
                        employeeSeparationRemark={employeeSeparationRemark}
                        employeeName={employeeName}
                        actualResignationDate={actualResignationDate}
                        employeeSeparationReason={employeeSeparationReason}
                        createdBy={createdBy}
                        lwdAsPerPolicy={lwdAsPerPolicy}
                        noticePeriodDays={noticePeriodDays}
                        lwdJustificationEmployee={lwdJustificationEmployee}
                        separationStatus={separationStatus}
                        isNoticePayWaveOffBhr={isNoticePayWaveOffBhr}
                        isNoticePayWaveOffDem={isNoticePayWaveOffDem}
                        lwdRecommendedByDem={lwdRecommendedByDem}
                        lwdRecommendedByBhr={lwdRecommendedByBhr}
                        waveOffJustificationDem={waveOffJustificationDem}
                        waveOffJustificationBhr={waveOffJustificationBhr}
                        lwdJustificationBhr={lwdJustificationBhr}
                        lwdJustificationDem={lwdJustificationDem}
                        noticeSeriodShortfallDays={noticeSeriodShortfallDays}
                        noticePayRecoveryAmount={noticePayRecoveryAmount}
                        demName={demName}
                        bhrName={bhrName}
                        buhrName={buhrName}
                        demApprovalRemark={demApprovalRemark}
                        buhrApprovalRemark={buhrApprovalRemark}
                        bhrApprovalRemark={bhrApprovalRemark}
                        employeeRequestData={employeeRequestData}
                        isErrorPostingActive={isErrorPostingActive}
                        isExitFormActive={isExitFormActive}
                    />
                }
                {/* ------------Exit Form View Mode Data Popup-------------- */}
                {isExitView &&
                    <ConfirmPopup
                        title={'e-Separation Exit Form'}
                        onDismiss={() => this.setState({ isExitView: false })}
                        separationExitData={this.state.separationExitData}
                    />
                }
                {/* ------------Revoke e-sepration Popup-------------- */}
                {showRevokePopup &&
                    <EsepRevokeConfirmPopup
                        title={'Revoke e-Separation'}
                        onDismiss={() => this.setState({ showRevokePopup: false, revokeEmpId: '', revokeEmployeeCompCode: '' })}
                        submitDeclaration={this.state.separationExitData}
                        submitRevocation={(data) => this.revokeEsep(data)}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    }
}

export default connect(mapStatesToProps)(EseparationAdminHome);