import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';

class LeadershipBehavioursPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageName: '',
            imageTitle: '',
            imageLink: '',
            announcmentDescription: '',
        }
    }

    fileSelectHandler = (event) => {
        const file = event.target.files[0];
        this.setState({ imageName: event.target.files[0] ? event.target.files[0] : '' })

        if (event.target.files && event.target.files[0]) {
            let maxAllowedSize = 2097152;           // 2MB size
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        this.uploadImg(this.state.imageName, file)
                    }
                }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ imageName: '' })
            }
        }
        else { this.setState({ imageName: '' }) }
    }

    uploadImg = (event, data) => {
        this.setState({
            imageName: event ? event.name : '',
        })
        if (event) {
            let maxAllowedSize = 2097152;  // 2MB size
            if (event.size <= maxAllowedSize) {
                // ******************************for Resize the image*****************************************
                try {
                    Resizer.imageFileResizer(
                        data,
                        1280,
                        720,
                        'PNG',
                        100,
                        0,
                        (uri) => {
                            this.setState({ imageLink: uri })
                        },
                        'uri',
                        1280,
                        720
                    );
                } catch (err) {
                    console.log("Resizer err:", err);
                }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({
                    imageLink: '',
                    imageName: ''
                })
            }
        }
        else {
            this.setState({
                imageLink: '',
                imageName: ''
            })
        }
    }

    validate = () => {
        if (this.state.description === '') {
            toast.warning('Please enter description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.imageName === '' || this.state.imageLink === '') {
            toast.warning('Please select image', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertLeadershipAnnouncement()
        }
    }

    insertLeadershipAnnouncement = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            announcement_description: this.state.announcmentDescription,
            image_name: this.state.imageName,
            emp_mail: this.props.adminLoginData.adminData.user_email,
            image_link: this.state.imageLink
        }
        fetchApiCall(Config.insertLeadershipAnnouncement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '201' || res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                        this.props.AllClose(),
                    )
                    this.props.leadershipAnnouncementActive()
                    this.setState({ loading: false })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    },
                        this.props.AllClose(),
                    )
                    this.props.upcomingOccasionActive()
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    render() {
        const { back, loading } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{this.props.NotificationType}</h4>
                        </div>
                        <div className="">
                            <form className='todoModalBody popup_scroller w-100'>
                                <div>
                                    <div>
                                        <div className='p-sub-heading ml-3 mt-2'>Title</div>
                                        <div className='p-placeholder-text mb-2'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the title"}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ imageTitle: e.target.value })}
                                                value={this.state.imageTitle}
                                            />
                                        </div>
                                        <div>
                                            <div className='p-sub-heading ml-3'>File</div>
                                            {!this.props.show &&
                                                <div className='p-placeholder-text mb-2'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.fileSelectHandler}
                                                    />
                                                    <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='p-sub-heading ml-3'>Description</div>
                                        <div className='p-placeholder-text'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the description"}
                                                maxLength={1000}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ announcmentDescription: e.target.value })}
                                                value={this.state.announcmentDescription}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                                </div>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={back} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='ADD'
                                            className='popup-confirm-btn'
                                            onClick={this.validate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(LeadershipBehavioursPopup);