import React, { Component, Fragment } from 'react'
import moment from 'moment';
import { toast } from 'react-toastify';
import { warnings } from './Constant'
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg';
import GemsConfirmPopup from '../../../shared/components/gemsFormPopUp/GemsConfirmPopup';
import WarningPopup from '../../../shared/components/gemsFormPopUp/WarningPopup';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { deleteAppRequest } from '../../../ScreenType/employeeScreens/gems/Gems.api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';

const tableContent = ["pgName", "projectTitle", "status", "startDate", "endDate", "action"]
const detailsViewTitle = ["pgName", "projectObj", "projectLocation", "noOfOpening", "applicationsReceived", "applicationShortlistBySPOC", "applicationShortlistByPG", "startDate", "endDate", "pgLevel", "projectOU", "projectArea"]
class MyProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isConfirmOpen: "",
            type: "",
            deleteItem: [],
            passData: []
        }
    }

    componentDidMount() {
        this.props.updateData();
    }

    deleteApplication = (deleteParam) => {
        this.setState({
            deleteItem: deleteParam,
            isConfirmOpen: "delete"
        })
    }

    deleteRequest() {
        this.setState({
            loading: true,
        })
        deleteAppRequest(this.state.deleteItem?.project.id, this.state.deleteItem?.id)
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                    })
                    this.props.updateData()
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                }
                else {
                    this.setState({
                        loading: false,
                    })
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            })
            .catch((error) => {
                console.log("deleteRequest catch error:", error)
                toast.error('Unable to contact server error', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            });
    }

    respocType = (value) => {
        this.setState({
            isConfirmOpen: "warning",
            type: value
        })
    }

    isApproved = (value) => {
        this.setState({
            isConfirmOpen: value === "back" ? "view" : ""
        })
    }

    deleteApplicationAPI = (action) => {
        console.log("action", action)
        this.setState({
            isConfirmOpen: ""
        }, () => this.deleteRequest()
        )
    }

    render() {
        const {loading} = this.props;
        let i = 0;
        return (
            <>
                <div className='pt-2 pushNotification-container margin-left-20 mr-20px'>
                    <LoaderIndicator loading={loading} />
                    <>
                        {this.props.appliedProjects?.length != 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='thead'>Sr.No.</th>
                                        <th scope="col" className='thead'>PG Name</th>
                                        <th scope="col" className='thead'>Project Title</th>
                                        <th scope="col" className='thead'>Status</th>
                                        <th scope="col" className='thead'>Start Date</th>
                                        <th scope="col" className='thead'>End Date</th>
                                        <th scope="col"><div className='thead'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody className='scroll-auto'>
                                    {this.props.appliedProjects?.map((listValue, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <>
                                                        <td><span className="td" style={{ width: 20 }}>{i = i + 1}</span></td>
                                                        <td><span className="td" >{listValue?.project?.project_guide?.CompName ?? ''}</span></td>
                                                        <td><span className="td">{listValue?.project?.title ?? ''}</span></td>
                                                        <td><span className="td"
                                                            style={{
                                                                color:
                                                                    listValue.status === "Rejected" || listValue.status === "Rejected by PG" || listValue.status === "Rejected by SPOC"
                                                                        ? '#E11111'
                                                                        : listValue.status === "Approved" || listValue.status === "Shortlisted by SPOC" || listValue.status === "Shortlisted by PG"
                                                                            ? '#047857'
                                                                            : '#E3B203'
                                                            }}
                                                        >{listValue.status ? listValue.status : ''}</span></td>
                                                        <td><span className="td" >{moment(listValue?.project?.actual_start_date ?? listValue?.project?.planned_start_date ?? '').format("DD/MM/YYYY")}</span></td>
                                                        <td><span className="td">{moment(listValue?.project?.actual_end_date ?? listValue?.project?.planned_end_date ?? '').format("DD/MM/YYYY")}</span></td>
                                                        <td className='d-flex'>
                                                            <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                onClick={() => this.setState({ isConfirmOpen: "view", passData: listValue })}
                                                            />
                                                            {
                                                                listValue.status === "Pending" &&
                                                                <img alt="delete"
                                                                    src={delete_action} className='m-5px table-action-button '
                                                                    onClick={() => this.deleteApplication(listValue)}
                                                                />
                                                            }
                                                        </td>
                                                    </>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                    <div>
                                        <img src={noDataTransfer} alt="noDataTransfer" />
                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>{warnings.noProject}</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                </div>
                {this.state.isConfirmOpen === "delete" &&
                    <GemsConfirmPopup
                        header="Delete"
                        message={warnings.deleteProject}
                        onDismiss={() => this.setState({ isConfirmOpen: "" })}
                        width="small"
                        type="delete"
                        appliedProjects={this.state.deleteItem}
                        deleteAppication={this.deleteApplicationAPI}
                    />
                }
                {this.state.isConfirmOpen === "view" &&
                    <GemsConfirmPopup
                        header="Project Form Details"
                        message={warnings.deleteProject}
                        onDismiss={() => this.setState({ isConfirmOpen: "" })}
                        width="large"
                        type="detail_view"
                        appliedProjectsDetail={this.state.passData ? this.state.passData : []}
                        detailsViewTitle={detailsViewTitle}
                        respocType={(item) => this.respocType(item)}
                    />
                }
                {this.state.isConfirmOpen === "warning" &&
                    <WarningPopup
                        onDismiss={() => this.setState({ isConfirmOpen: "" })}
                        type={this.state.type}
                        isApprove={(item) => this.isApproved(item)}
                    // message={this.state.type === "ACCEPT" ? warnings.acceptProject : warnings.rejectProject}
                    />
                }
            </>
        )
    }
}
export default MyProject
