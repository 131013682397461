import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { toaster, toggelDiffStatus } from '../../../../../utils/Utils';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import { patchSuperAdminUpdate } from '../../../../../constants/api.service'

class SuperAdminCommonPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            division: '',
            agency: '',
            remarks: ''
        }
    }

    async handleBtnAction() {

        const payload = {
          "action": this.props.title === "SEND BACK" ? "Send_back" : "Approved",
          "sug_ids": this.props.data?.map( sugg => sugg.id ),
          "comment": this.state.remarks,
          "agency": null,  // null
          "from_agency_name": this.props.agency_name,
          "from_status": 3,
          "from_role": "Super_Admin",
          "to_agency_name": this.props.title === 'SEND BACK' ? this.props.agency_name : null, 
          "to_status": this.props.title === 'SEND BACK' ? 2 : 4,
          "to_role": this.props.title === 'SEND BACK' ? "Suggestion_office" : null
        }
        
        await patchSuperAdminUpdate(payload)
        .then( (res) => {
            if (res){
                if (res.status === 200){
                    toaster("success", res.data.message)
                    this.props.onDismiss();
                }else{
                    toaster("error", res.data.message)
                }
            }

        } )
        .catch( (error) => { console.log(error); toaster("warning", error?.data?.message ?? "Something Went Wrong")  } )
        .finally( () => {
    
        })
    }

    render() {
        const { title, loading } = this.props
        const { division, agency, remarks } = this.state
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title == "EVALUATOR" ? "Suggestion to other Evaluator" : title == "SEND BACK" ? "Clarification Remarks" : title === "APPROVE" ? 'Confirmation' :"Re-evaluation Remarks"}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        {/* ------ EVALUATOR Body-------*/}
                        {title === "EVALUATOR" &&
                            <div className='flex
                            -direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Division"}</div>
                                    <DynamicDropdown
                                        data={toggelDiffStatus()}
                                        render="value"
                                        value={division ? division : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) => this.setState({
                                            division: data.value
                                        })}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                        width={"w-16rem"}
                                    />
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Agency"}</div>
                                    <DynamicDropdown
                                        data={toggelDiffStatus()}
                                        render="value"
                                        value={agency ? agency : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) => this.setState({
                                            agency: data.value
                                        })}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                        width={"w-16rem"}
                                    />
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Enter Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) =>  this.setState({ remarks : e.target.value}) }
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "SEND BACK" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Note: Enter Remarks for Clarification for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "REJECT" &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Re-evaluation Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        {title === "APPROVE" &&
                            <div className='flex-direction-column justify-content-center p-3 align-items-center'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Approval remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        {title === "APPROVE" && <input type='button' value='CONFIRM' className={
                                remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                            } disabled={
                                remarks === '' ? true : false
                            } onClick={() => { this.handleBtnAction(); this.props.submitApproved()  }} /> }
                        {title === 'EVALUATOR' ?
                            <input type='button' value='CONFIRM' className={
                                remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                            } disabled={
                                remarks === '' ? true : false
                            }
                            onClick={() => this.props.submitEvaluator(division, agency, remarks)} /> :
                            title === 'SEND BACK' ?
                                <input type='button' value='CONFIRM' className={
                                    remarks === '' ? 'ml-2 validateBtnDisabled' : 'ml-2 validateBtn'
                                } disabled={
                                    remarks === '' ? true : false
                                }
                                onClick={() => { this.handleBtnAction(); this.props.submitSendBack(remarks)  }} /> :
                                title === 'REJECT' ? <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => { this.handleBtnAction(); this.props.submitRejection(remarks)}} /> :
                                    // <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.props.onDismiss()} />
                                    null
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default SuperAdminCommonPopup;