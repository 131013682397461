import React from 'react'
import ServiceUnavailable from '../../../../shared/ServiceUnavailable'

function OTServiceUnavailable({ reason }) {
    return (
        <div className='row'>
            <div className='h-70vh  col-md-12 d-flex justify-content-center align-items-center flex-column'>
                <ServiceUnavailable reason={reason} />
            </div>
        </div>
    )
}

export default OTServiceUnavailable