import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { getMasterTvDropdown, headers, headersForm, insert_tata_motors_tv_video_by_admin } from '../../../../constants/Config';
import { SparshandTvMsg, fileSize } from '../../../../constants/Messages';
import { fetchApiCall, operativeSessionOut } from '../../../../constants/Service';
import { byteToMB, resizes, toaster } from '../../../../utils/Utils';
import Dropdown from '../../../shared/components/Dropdown';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
class AddTatamotorsTVPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tvTitle: '',
      tvVideName: '',
      tvVideoDescription: '',
      tvTypeValue: 'Select',
      tvVideoHyperlink: '',
      tvVideoContent: '',
      videoError: false,
      thumbnailImgName: '',
      thumbnailImgUrl: '',
      eventData: [],
      heightAndWidth: [],
      warningPopup: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    this.getMasterTvDropdown()
  }

  getMasterTvDropdown() {
    this.setState({
      loading: true,
    })
    const body = {
      emp_id: this.props.adminLoginData.userData.Perno ? this.props.adminLoginData.userData.Perno : '',
    }
    fetchApiCall(getMasterTvDropdown, body, headers, false, 'admin')
      .then(res => {
        this.setState({ loading: false })
        operativeSessionOut(this.props, res, 'admin');
        if (res.data.status_code == '200') {
          this.setState({
            tvUpdateDropdown: res.data.data ? res.data.data : []
          },
            // () => this.getVideosType("All", this.state.page_number)
          )
          const tata_tv_value_data = this.state.tvUpdateDropdown.map((item, idx) => ({
            ...item,
            value: item.tata_motors_video_type ? item.tata_motors_video_type : '',
          }))
          this.setState({
            tvUpdateDropdown: tata_tv_value_data.filter(item => item.tata_motors_video_type !== 'All'),
          })
        }
        else {
            this.setState({
              loading: false,
              tvUpdateDropdown: []
            })
        }
      })
      .catch(err => {
        console.log("getMasterTvDropdown err:", err)
        this.setState({
          loading: false,
          tvUpdateDropdown: []
        })
      })
  }

  VideofileSelectHandler = (event) => {
    if (event.target.files[0] && event.target.files[0].type.split('/')[1] === "mp4") {
      if (event.target.files[0] && event.target.files[0].size > fileSize.videoSize) {  // greater than 100mb
        console.log("tvVideoContent:", this.state.tvVideoContent)
        this.setState({
          videoError: true,
          tvVideoContent: '',
          tvVideName: '',
        })
        toast.warn(SparshandTvMsg.videoSizeWarning + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + " MB.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
      }
      else {
        this.setState({
          videoError: false,
          tvVideName: event.target.files[0] ? event.target.files[0].name : '',
          tvVideoContent: event.target.files[0],
        })
      }
    }
    else if (event.target.files[0] == undefined) {
      this.setState({
        videoError: false,
        tvVideName: '',
      })
    }
    else {
      this.setState({
        videoError: true,
        errorMessage: SparshandTvMsg.videoMediaerror,
      })
      toaster("warning", SparshandTvMsg.videoMediaerror)
    }
  }
  thumbnailfileSelectHandler = (event) => {
    if (event.target.files[0] && (event.target.files[0].type.split('/')[1] === "png" || event.target.files[0].type.split('/')[1] === "jpeg")) {
      if (event.target.files[0].size <= fileSize.imageSize) {
        this.setState({
          thumbnailImgName: event.target.files[0].name ? event.target.files[0].name : "",
        })
        const file = event.target.files[0];
        this.setState({ eventData: file })
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.setState({
              heightAndWidth: [img.width, img.height],
              thumbnailError: false
            })
            this.uploadImg(this.state.eventData, file)
          }
        }
      } else {
        toast.warn(SparshandTvMsg.imgSizeNote + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + " MB.", {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          thumbnailImgUrl: '',
          thumbnailImgName: '',
          errorMessage: SparshandTvMsg.imgSizeNote,
          thumbnailError: true
        })
      }
    } else if (event.target.files[0] == undefined) {   // file selected but cancel
      this.setState({
        thumbnailError: false,
        thumbnailImgName: ''
      })
    }
    else {
      this.setState({
        thumbnailError: true,
        errorMessage: SparshandTvMsg.unsupportedMediaforthumbnail,
      })
      toaster("warning", SparshandTvMsg.unsupportedMediaforthumbnail)
    }
  }

  uploadImg = (event, data) => {
    this.setState({
      thumbnailImgName: event ? event.name : '',
      warningPopup: false
    })
    if (event) {
      // **************************************************************************
      resizes(data, 1280, 720, 'PNG', 100, 0, 'file', 128, 72)
        .then((uri) => {
          this.setState({ thumbnailImgUrl: uri, thumbnailError: false })
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  }
  submitTVDetailsByAdmin = async (event) => {
    this.setState({
      loading: true
    })
    const formData = new FormData();
    formData.append("file_upload", this.state.tvVideoContent);
    formData.append("emp_mail", this.props.adminLoginData.adminData.user_email);
    formData.append("tm_video_title", this.state.tvTitle);
    formData.append("tm_video_description", this.state.tvVideoDescription);
    formData.append("tm_video_name", this.state.tvVideName);
    formData.append("tm_video_type", this.state.tvTypeValue);
    formData.append("tm_video_url", this.state.tvVideoHyperlink);
    formData.append("emp_id", this.props.adminLoginData.emp_id);
    formData.append("thumbnail", this.state.thumbnailImgUrl);
    try {
      // do not remove below code
      // for (var pair of formData.entries()) {
      //   console.log("submitTVDetailsByAdmin: ", pair[0] + ', ' + pair[1]);
      // }
      fetchApiCall(insert_tata_motors_tv_video_by_admin, formData, headersForm, false, 'admin')
        .then(res => {
          operativeSessionOut(this.props, res, this.state.admin);
          if (res.data.status_code === '200') {
            this.setState({
              loading: false
            })
            toaster("success", res.data.message)
            this.props.allDismiss();
            this.props.callTV();
            this.setState({ loading: false },
              () => this.props.tataTVActive()
            )
          } else {
            this.setState({
              loading: false
            })
            toaster("warning", res.data.message)
            this.setState({ loading: false })
          }
        })
        .catch(err => {
          this.setState({ loading: false })
          toaster("error", err.response.data.message)
          this.setState({
            loading: false
          })
          console.log("submitTVDetailsByAdmin err:", err)
        })
    }
    catch (error) { console.log("submitTVDetailsByAdmin", error) }
  }

  changeTVType = (data) => {
    this.setState({
      tvTypeValue: data.value,
    })
  }

  uploadValidation = (event) => {
    event.preventDefault();
    if (this.state.tvTypeValue === 'Select') {
      toaster("warning", SparshandTvMsg.videoTypeWarning)
    }
    else if (this.state.tvTitle === "") {
      toaster("warning", SparshandTvMsg.tittleWarning)
    }
    else if (this.state.thumbnailError == true) {
      toaster("warning", SparshandTvMsg.finaluploadError)
    }
    else if (this.state.thumbnailImgName === "") {
      toaster("warning", SparshandTvMsg.thumbnailWarning)
    }
    else if (this.state.videoError == true) {
      toaster("warning", SparshandTvMsg.finaluploadError)
    }
    else if (this.state.tvVideName === "") {
      toaster("warning", SparshandTvMsg.videoWarning)
    }
    else {
      this.submitTVDetailsByAdmin()
    }
  }

  render() {
    return (
      <div>
        {
          this.state.loading &&
          <LoaderIndicator loading={this.state.loading} />
        }
        <div>
          <form className='todoModalBody w-100'
          // onSubmit={this.uploadValidation}
          >
            {this.state.tvUpdateDropdown != undefined ?
              <div className='popup_TVscroller'>
                <form className='p-placeholder-text mb-2 px-5px'>
                  <label className='form-label ml-2'>{SparshandTvMsg.videoType}</label>
                  <Dropdown
                    data={this.state.tvUpdateDropdown}
                    selectedValue={(data) => this.changeTVType(data)}
                    value={this.state.tvTypeValue}
                    render={'value'}
                    height={'NotificationHeight'}
                    bg={"whiteBG"}
                  />
                </form>
                <div className='p-sub-heading ml-3'>{SparshandTvMsg.title}</div>
                <div className='p-placeholder-text mb-2 px-5px'>
                  <input
                    className="form-control p-placeholder p-placeholder-admin px-2 "
                    type="text"
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ tvTitle: e.target.value })}
                    value={this.state.tvTitle}
                    placeholder={"Enter the title"}
                    maxLength={120}
                  />
                </div>
                <div>
                  <div className='p-sub-heading ml-3'>{SparshandTvMsg.thumbnail}</div>
                  {!this.props.show &&
                    <div className='secondContainer align-item-center-noflex px-5px'>
                      <input
                        className={this.state.thumbnailError ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                        type="file"
                        name='thumbnailUpload'
                        id="files"
                        accept="image/*"
                        title={this.state.thumbnailImgName}
                        placeholder={"Upload Video"}
                        readOnly={false}
                        onChange={this.thumbnailfileSelectHandler}
                      />
                    </div>
                  }
                  <div className='flex-direction-row'>
                    <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                    <div>
                      <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction1}</div>
                      <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction2}</div>
                      <div className='p-sub-heading ml-3 mt-2px mb-2'>{SparshandTvMsg.thumbnailInstruction3}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='p-sub-heading ml-3'>{SparshandTvMsg.uploadVideo}</div>
                  {!this.props.show &&
                    <div className='secondContainer align-item-center-noflex px-5px'>
                      <input
                        className={this.state.videoError ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                        type="file"
                        name='videoUpload'
                        id="files"
                        accept="video/mp4"
                        // title={this.state.tvVideName}
                        placeholder={"Upload Video"}
                        readOnly={false}
                        onChange={this.VideofileSelectHandler}
                      />
                    </div>
                  }
                  <div className='flex-direction-row'>
                    <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                    <div>
                      <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.videoInstruction}</div>
                      <div className='p-sub-heading ml-3 mt-2px mb-2'>2.{SparshandTvMsg.videoSizeWarning}</div>
                    </div>
                  </div>
                </div>
                {/* <div className='p-sub-heading ml-3'>Description</div>
                    <div className='p-placeholder-text mb-2 '>
                      <textarea
                        className="form-control p-placeholder px-3"
                        type="text"
                        maxLength={1000}
                        readOnly={this.state.show}
                        onChange={(e) => this.setState({ tvVideoDescription: e.target.value })}
                        value={this.state.tvVideoDescription}
                        placeholder={"Enter the description"}
                      >
                      </textarea>
                    </div> */}
              </div>
              :
              <div className='min-height-45vh'></div>
            }
          </form>
          <div className='modal-footer btnBox justify-content-between'>
            <div className='justify-content-start'>
              <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
            </div>
            <div className='justify-content-around'>
              <div className='justify-content-start'>
                <input type='button'
                  value='CANCEL'
                  className='popup-back-btn popup-back-text'
                  onClick={this.props.back}
                />
              </div>
              <div className='justify-content-start'>
                <input
                  // type='button'
                  type='submit'
                  value='ADD'
                  className='popup-confirm-btn'
                  onClick={this.uploadValidation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStatesToProps = (state) => {
  return {
    adminLoginData: state.adminLoginState,
  }
}
export default connect(mapStatesToProps)(AddTatamotorsTVPopUp)
