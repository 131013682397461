import React, { Component } from 'react';
import ApplyLeavePopup from '../../../employeeScreens/leave/ApplyLeavePopup';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import plus from '../../../../../assets/drishti_images/plus_one.svg'
import noDataLeave from '../../../../../assets/drishti_images/manager/landing/noDataLeave.svg';
import { LeaveAvailedSkeleton } from '../../../../shared/skeleton/Skeletons';
import { configStore } from '../../../../../redux/ConfigStore';
import { toast } from 'react-toastify';
import { Progress } from 'antd';
import { connect } from 'react-redux';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
class ManagerLeave extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leaveQuotaData: [],
            total_leaves: '',
            availed_leaves: '',
            show: false,
            loading: false,
            is_service_Unavailable: false,
            leave_Service_reason: ''
        }
    }

    componentDidMount() {
        this.getAvailedLeaveData();
        this.checkService();
    }

    async checkService() {
        const result = await this.props.serviceData.availableServiceData;
        this.props.loginData.serviceEnableDisableData.forEach((item, i) => {
            if (item.service_type == "landing_leave" && item.is_active == false) {
                console.log("checkService leave inside :")
                this.setState({
                    is_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
        })
    }

    applyleave = () => {
        if (this.props.serviceUnavailable) {
            toast.error(this.props.unavailableReason, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.setState({ show: !this.state.show })
        }
    }

    getAvailedLeaveData = () => {
        this.setState({
            loading: true
        });
        const body = { 
            emp_id: this.props.loginData.userData.Perno,
            psa: this.props.loginData.userData.PSubarea,
            pa: this.props.loginData.userData.PersArea
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(
            Config.getAvailedLeavesData, body, headers, false, ''
        ).then(res => {
            sessionOut(this.props, res);
            if (res.data.status_code == 200) {
                this.setState({
                    loading: false,
                    leaveQuotaData: res.data.leave_quota ? res.data.leave_quota : [],
                    total_leaves: res.data.total_outcome,
                    availed_leaves: res.data.available_outcome
                })
            } else {
                this.setState({
                    loading: false
                });
            }
        }).catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    closepopup = () => {
        this.setState({ show: false })
    }

    render() {
        const { loading } = this.state
        return (
            <div className="card-new h-100" >
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Leaves</label>
                        </div>
                        {!this.state.is_service_Unavailable &&
                            <div>
                                <img alt="addLeave"
                                    src={plus}
                                    className="righticon"
                                    onClick={() => this.applyleave()}
                                ></img>
                            </div>
                        }
                    </div>
                </div>
                {this.state.is_service_Unavailable ?
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                            <ServiceUnavailable reason={this.state.leave_Service_reason} />
                        </div>
                    </div>
                    :
                    <>
                        {loading ?
                            <>
                                <div className='justify-content-center'>
                                    <LeaveAvailedSkeleton />
                                </div>
                            </>
                            :
                            <div className='px-2'>
                                {this.state.leaveQuotaData.length != 0 ?
                                    <div className={window.innerWidth < 600 ? 'row mb-10px justify-content-around' : ''}>

                                        <div className="row d-flex justify-content-center mb-1 align-item-center">
                                            <div className={window.innerWidth < 600 ? 'root row justify-content-center d-flex' : 'root row d-flex'}>
                                                <div className='circleBg'>
                                                    <Progress
                                                        type="circle"
                                                        strokeColor={{
                                                            '0%': '#4B83D9',
                                                            '50%': '#6881D7',
                                                            '100%': '#8C7ED4',
                                                        }}
                                                        trailColor={'#F1F5F9'}
                                                        strokeWidth={4}
                                                        width={60}
                                                        status={'normal'}
                                                        percent={((this.state.availed_leaves / this.state.total_leaves) * 100).toFixed(1)}
                                                    />
                                                </div>
                                                <div className='flex-direction-column ml-2 mt-7px'>
                                                    <div className='text17_light'>Leaves</div>
                                                    <div className='text16_bold'>Remaining</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row inner-leave-type employeeLeaveContainer">
                                            {this.state.leaveQuotaData.map((list, i) => (
                                                <div className="col-md-12 mt-2 ml-1" key={i}>
                                                    <div className='row'>
                                                        <div className='col-md-11 d-flex justify-content-between px-2 align-items-center'
                                                            style={{
                                                                backgroundImage: `linear-gradient${list.leave_category_color}`,
                                                                backgroundSize: 'cover',
                                                                borderRadius: 8,
                                                                padding: 1
                                                            }}>
                                                            <div className="privilege-text">{list.leave_category_text}</div>
                                                            <div className='totalLeave'>{list.available_leaves}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                        <div>
                                            <img src={noDataLeave} alt="noDataLeave" />
                                        </div>
                                        <div>
                                            <label className='card-content-title grey-color'>No data found</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
                {this.state.show &&
                    <ApplyLeavePopup
                        applyLeave={this.state.show}
                        show={false}
                        closeapplyLeave={this.closepopup}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(ManagerLeave);