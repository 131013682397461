import React, { Component } from 'react';
import PayslipJobs from './PayslipJobs';
import ITWorksheetJobs from './ITWorksheetJobs';
import ErrorInPostingJobs from './ErrorInPostingJobs';
import SetToDeleteStatus from './SetToDeleteStatus';
import ErrorInDeletion from './ErrorInDeletion';
import SyncLeaves from './SyncLeaves';
import CETSync from './CETSync';
import GroupJoiningDate from './GroupJoiningDate';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
// import DeleteLeaveApproval from './DeleteLeaveApproval';
// import ServiceUnavailable from '../../../shared/ServiceUnavailable';

class DevAdminMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: FontFaceSetLoadEvent,
            isPayslipJobsActive: true,
            isITWorksheetJobsActive: false,
            isErrorInPostingActive: false,
            isSetToDeleteStatusActive: false,
            isErrorinDeletionActive:false,
            isSyncLeavesActive: false,
            isCETSyncActive: false,
            isGroupJoiningDateActive: false,
        }
    }


    toggleDevTools = (type) => {
        console.log('typ', type);
        if (type === 'payslipJobs') {
            this.setState({
                isPayslipJobsActive: true,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: false,
                isCETSyncActive: false,
                isGroupJoiningDateActive: false,
            })
        }
        else if (type === 'ITWorksheetJobs') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: true,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: false,
                isCETSyncActive: false,
                isGroupJoiningDateActive: false,
            })

        }
        else if (type === 'ErrorInPostingJobs') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: true,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: false,
                isCETSyncActive: false,
                isGroupJoiningDateActive: false,
            })
        }
        else if (type === 'SetToDeleteStatus') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: true,
                isErrorinDeletionActive:false,
                isCETSyncActive: false,
                isSyncLeavesActive: false,
                isGroupJoiningDateActive: false,
            })
        }
        else if (type === 'ErrorInDeletion') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:true,
                isCETSyncActive: false,
                isSyncLeavesActive: false,
                isGroupJoiningDateActive: false,
            })
        }
        else if (type === 'SyncLeaves') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: true,
                isCETSyncActive: false,
                isGroupJoiningDateActive: false,
            })
        }
        else if (type === 'CETSync') {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: false,
                isCETSyncActive: true,
                isGroupJoiningDateActive: false,
            })
        }
        else {
            this.setState({
                isPayslipJobsActive: false,
                isITWorksheetJobsActive: false,
                isErrorInPostingActive: false,
                isSetToDeleteStatusActive: false,
                isErrorinDeletionActive:false,
                isSyncLeavesActive: false,
                isCETSyncActive: false,
                isGroupJoiningDateActive: true,
            })
        }

    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    render() {
        const {isPayslipJobsActive, isITWorksheetJobsActive, isErrorInPostingActive, isSetToDeleteStatusActive, isErrorinDeletionActive, isSyncLeavesActive, isCETSyncActive, isGroupJoiningDateActive} = this.state
        return (
            <div className="row px-0 px-12px">
                <div className="col-md-12 ">
                    <div className='row px-0 pt-0' >
                        <img src={overviewButton} alt='' onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 col-md-12'>
                                <div className={isPayslipJobsActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('payslipJobs')}>
                                    {'Payslip jobs'}
                                </div>
                                <div className={isITWorksheetJobsActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ITWorksheetJobs')}>
                                    {'IT worksheet jobs'}
                                </div>
                                <div className={isErrorInPostingActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ErrorInPostingJobs')}>
                                    {'Error in posting jobs'}
                                </div>
                                <div className={isSetToDeleteStatusActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('SetToDeleteStatus')}>
                                    {'Set to delete status'}
                                </div>
                                <div className={isErrorinDeletionActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ErrorInDeletion')}>
                                    {'Error in Deletion'}
                                </div>
                                <div className={isSyncLeavesActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('SyncLeaves')}>
                                    {'Sync Leaves'}
                                </div>
                                <div className={isCETSyncActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('CETSync')}>
                                    {'CET Sync'}
                                </div>
                                <div className={isGroupJoiningDateActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('GroupJoiningDate')}>
                                    {'Group Joining date'}
                                </div>

                                {/* <div className={this.state.isBasicDetailActive ? "tab-active" : "tab-inactive"} onClick={()=>this.toggleApprove('software')}>
                                             Software Installation
                                        </div>
                                        <div className={this.state.isContactDetailActive ? "tab-active" : "tab-inactive"} onClick={()=>this.toggleApprove('digital')}>
                                            Digital Sign In Listing
                                        </div> */}
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className=''>
                                {isPayslipJobsActive ?
                                    <div>
                                        <PayslipJobs />
                                    </div> : null
                                }
                                {isITWorksheetJobsActive ?
                                    <div>
                                        <ITWorksheetJobs />
                                    </div> : null
                                }
                                {isErrorInPostingActive ?
                                    <div>
                                        <ErrorInPostingJobs />
                                    </div> : null
                                }
                                {isSetToDeleteStatusActive ?
                                    <div>
                                        <SetToDeleteStatus />
                                    </div> : null
                                }
                                {isErrorinDeletionActive ?
                                    <div>
                                        <ErrorInDeletion />
                                    </div> : null
                                }
                                {isSyncLeavesActive ?
                                    <div>
                                        <SyncLeaves />
                                    </div> : null
                                }
                                {isCETSyncActive ?
                                    <div>
                                        <CETSync />
                                    </div> : null
                                }
                                {isGroupJoiningDateActive ?
                                    <div>
                                        <GroupJoiningDate />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default DevAdminMain;
