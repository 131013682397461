export const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
export const bankDetails = 'Any bank details changes will be reflected from next payroll'
export const bankDetailsNote = 'Adding new bank details it will override the current details'
export const dependentNote = 'dependent Note'
export const aadharHolder = 'Name As Per Aadhaar'
export const aadharNumber = 'Aadhaar Card Number'
export const aadharUpload = 'Upload File'
export const aadharHolderPlaceHolder = "Enter Name As Per Aadhaar"
export const aadharCardNumberPlaceHolder = "Enter Aadhaar Number"
export const aadharUploadPlaceHolder = "Upload File"
export const panNumber = "PAN Card Number"
export const panUpload = "Upload PAN Document"
export const panNumberPlaceHolder = "Enter PAN Card Number"
export const panUploadPlaceHolder = "Upload File"
export const planeLeaveNote = "Kindly note only approved, PL, CL and SL of direct reportees is visible in this list"
export const consentMessage = "I agree that i have taken the consent from the employee to use the mobile number provides for company purposes and have instructed him on the best practices to be followed while using this facility."
export const templateDownloadTitle = "Click here to Download Template";
export const ExcelExportData = [{ "emp_id": "", "mobile_number": "" }];
export const maximumRecordMessage = "Maximum record upload limit is 400";
export const note1 = "1.Download template for reference"
export const note2 = "2.The employee id should be 6 digit and employee phone number should be 10 digit."
export const noRegisterdata = "Employee details are not available"
export const noBulkDetails = "Bulk  details are not available"
export const someThingWentWrong = "Something went wrong"
export const default_text = 'Engage and connect with new UI & Omnichannel experience'
export const imgSizeNote = "Please upload image less than 2MB"
export const videoSizeWarning = "Video file size should be less than 100mb"
export const imgSizeWarningNote = "Please upload image less than 2MB"

export const imgResolutionNote = "Image resolution should be 1280 X 720"
export const checkfileFormat = "Please check file format"
export const tittleWarning = "Please enter title"
export const exportRegEmp = {
     fileName: "Employee Details",
     ext: ".csv",
     colNames: ["Sr. No", "Employee ID", "Phone No", "Created by", "Modified by", "Status"]
}
//Attendance details download
export const attendanceDetails = {
     ext: ".csv",
     colNames: ["SR NO", "DATE", "DAY", "SWIPE IN", "SWIPE OUT", "FIRST HALF", "SECOND HALF", "REASON", "STATUS"]
}

//Gems details download
export const gemsDetails = {
     ext: ".csv",
     projectColumnNames: [
          "Project No.",
          "Project Title",
          "Project Status",
          "Project Description",
          "No. of Opening",
          "Project Entity",
          "Project Category",
          "Project Area",
          "Project Location",
          "Project Duration",
          "Project Initiation Date",
          "Project Approved Date",
          "Project Published Date",
          "Project Guide Name",
          "Project Guide OU1",
          "Project Guide OU2",
          "Project Guide OU3",
          "Project Guide Email ID",
          "Project Guide Level",
          "Project Guide Perno",
          "Approver Name",
          "Approver Email",
          "Approver Level",
          "Project BHR",
          "No. of Applications Received",
          "No. of Applications Shortlisted by SPOC",
          "No. of Applications Selected by PG",
          "Planned Project Start Date",
          "Actual Project Start Date",
          "Planned Mid Term Review Date",
          "Revised Mid Term Review Date",
          "Actual Mid Term Review Date",
          "Planned Final Review Date",
          "Revised Final Review Date",
          "Actual Final Review Date",
          "Planned Project End Date",
          "Actual Project End Date",
          "GEMs Project TAT",
          "Rejected By",
          "Rejected On",
     ],

     applicantColumnNames:
          [
               "Project No.",
               "Project Title",
               "Project Status",
               "Project Entity",
               "Project Category",
               "Project Area",
               "Project Location",
               "Project Duration",
               "Project Guide Name",
               "Project Guide OU1",
               "Project Guide OU2",
               "Project Guide OU3",
               "Project Guide Email ID",
               "Project Guide Level",
               "Project Guide Perno",
               "Project BHR",
               "Planned Project Start Date",
               "Actual Project Start Date",
               "Planned Mid Term Review Date",
               "Revised Mid Term Review Date",
               "Actual Mid Term Review Date",
               "Planned Final Review Date",
               "Revised Final Review Date",
               "Actual Final Review Date",
               "Planned Project End Date",
               "Actual Project End Date",
               "Application Status",
               "Applicant Perno",
               "Applicant Name",
               "Applicant Gender",
               "Applicant Level",
               "Applicant Location",
               "Applicant OU1",
               "Applicant OU2",
               "Applicant OU3",
               "Applicant BU",
               "Applied Date",
               "Shortlisted Date",
               "Selected Date",
               "Certificate Issued Date",
               "Debarred Date"
          ]
}

export const SparshandTvMsg = {
     uploadWarning: "Please check and upload file",
     thumbnailWarning: "Please select thumbnail",
     tittleWarning: "Please enter title",
     descriptionWarning: "Please enter description",
     unsupportedMediaforthumbnail: "Please select supported media file",
     title: "Title",
     file: "File",
     instruction: "Note:",
     thumbnail: "Upload thumbnail",
     description: "Description",
     instruction1: "1.Image and video size should be 2MB and less than 100MB respectively",
     instruction2: "2.Image resolution should be 1280px X 720px",
     instruction3: "3.File format should be PNG,JPEG, MP4 only",
     thumbnailInstruction1: "1. File format should be PNG, JPEG only",
     thumbnailInstruction2: "2. Image resolution should be 1280px X 720px",
     thumbnailInstruction3: "3. Image size should be less then 2MB",
     videoType: "Video Type",
     uploadVideo: "Upload Video",
     videoInstruction: "1.File format must be MP4 only",
     videoSizeWarning: "Video file size should be less than 100MB",
     videoTypeWarning: "Please select the type",
     videoWarning: "Please upload video",
     imgSizeNote: "Please upload image less than 2MB",
     pdfSizeNote: "Please upload pdf less than 10MB",
     finaluploadError: "Please check and re-upload file",
     videoMediaerror: 'Please upload video only',
     selectfile: "Please select file",
     ourSize: `Your uploaded file size is`
     // imgSizeWarningNote: "Please upload image less than 2MB"
};

export const fileSize = {
     imageSize: 2097152,  //2MB  given size in bytes
     videoSize: 104857600, // 100MB  given size in bytes
     characterCount: 1000,
     pdfSize: 10485760,  //10MB  given size in bytes
}

export const notificationHeight = 'NotificationHeight'
export const preProfile = "data:image/png;base64,";
export const plantUpdateMsg = {
     selectPlantUpdate: "Please select plant update type",
     uploadDocument: "Please upload the document",
     uploadImage: "Please upload the image",
     documentInstructions: [
          "File format should be PDF only",
          "PDF size should be less then 10MB"
     ],
     unselectedPlant: "Please select plant name",
     calendarInstructions: [
          "Plant update notification will be triggered instantly upon clicking the 'Add' button."
     ]
}

export const noDataMsg = {
     noData: "No data have been added yet!",
     dataLoad: "Data getting loaded please wait...."
}

// Clearance Trainee Bulk Upload Template 

export const clearanceBulkTemplates = {
     fileName: "Trainee_Clearance_Template",
     ext: '.csv',
     colNames: ["SR NO", "Employee ID", "Recovery Amount (upto 2 decimals)", "Payment Amount (upto 2 decimals)", "Remark"]
}