import * as type from '../../../../redux/action/ActionType'

const initialState = {
    tabType: ""
}

export const adminRegistration = (state = initialState, action) => {
    switch (action.type) {
        case type.GET_TAB:
            return {
                ...state,
                tabType: action.payload
            }
        default:
            return state;
    }
}