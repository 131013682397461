import React, { Component } from 'react'

class SuccessButton extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { value, type, onClick, readOnly, isDisable } = this.props
        return (
            <>
                {isDisable === 'disable' ?
                    <input
                        type={type}
                        value={value}
                        className='ml-2 validateBtnDisabled'
                        onClick={onClick}
                        readOnly={readOnly}
                    />
                    :
                    <input
                        type={type}
                        value={value}
                        className='ml-2 validateBtn'
                        onClick={onClick}
                        readOnly={readOnly}
                    />
                }
            </>
        )
    }
}
export default SuccessButton
