import React from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import LargeDropdown from '../../../shared/components/LargeDropdown'

function GradeGroupMapping(props) {
    const {
        setGroupName,
        groupName,
        editMode,
        setGrade,
        compCodeList,
        selectedCompany,
        handleCompanyFilter,
        paList,
        selectedPA,
        handlePAFilter,
        setIsConfirmPopupOpen,
        grade,
        eGroup,
        setEgroup,
        gradeDesc,
        setGradeDesc
    } = props
    return (
        <div>
            <div className='column mt-3 scroll-y h-42vh'>
                <div className='toggle-card-heading ml-10 mb-10'>{`Basic Details`}</div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Group name`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setGroupName(e.target.value) }}
                                value={groupName}
                                placeholder={"Enter the group name"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <>
                    <div><hr /></div>
                    <div className='toggle-card-heading ml-10 mb-10'>{`Other Details`}</div>
                </>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Egroup`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setEgroup(e.target.value) }}
                                value={eGroup}
                                placeholder={"Enter the Egroup"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Esubgroup`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setGrade(e.target.value) }}
                                value={grade}
                                placeholder={"Enter the Esubgroup"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3'>{`Group Description`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setGradeDesc(e.target.value) }}
                                value={gradeDesc}
                                placeholder={"Enter the Group Description"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='p-sub-heading ml-3 required'>{`Select Company`}</div>
                        <div className='p-placeholder-text'>
                            <DynamicDropdown
                                data={compCodeList}
                                render={"company_name"}
                                value={selectedCompany.company_name}
                                selectedValue={(data) => handleCompanyFilter(data)}
                                arrow={'true'}
                                height={'NotificationHeight'}
                                placeholder={"Select Company"}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='p-sub-heading ml-3 required'>{`Select PA`}</div>
                        <div className='p-placeholder-text'>
                            <LargeDropdown
                                data={paList}
                                value={selectedPA.pa ?? "Select PA"}
                                selectedValue={(data) => handlePAFilter(data)}
                                disabled={editMode}
                                noOptionFound={"No Data Found"}
                            />
                        </div>
                        <label className='noteTextMsg ml-10px d-flex' >
                            <span className='noteTextBold'>{'Note:'}</span>
                            <span className='noteTextMsg ml-5px'>{'Select Company to fetch Pers.Area list'}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='modal-footer btnBox justify-content-end'>
                <div className='justify-content-start'>
                    <input type='button'
                        value='SUBMIT'
                        className='ml-2 validateBtn'
                        onClick={() => { setIsConfirmPopupOpen(true) }} />
                </div>
            </div>
        </div>
    )
}

export default GradeGroupMapping