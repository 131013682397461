import React, { Component } from 'react'
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { notification_type1 } from '../../../../utils/Utils';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import Dropdown from '../../../shared/components/Dropdown';
import Resizer from 'react-image-file-resizer';

class PushNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      notificationType: notification_type1(),
      selectTypeView: 'Select',
      selectTypeCode: '',
      descriptionText: '',
      imageSize: '',
      PushNotificationImageName: '',
      PushNotificationImage: '',
    }
  }

  insertPushNotification = () => {
    this.setState({ loading: true })
    const headers = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
    let body = {
      title: this.state.title,
      body: this.state.descriptionText,
      image_name: this.state.PushNotificationImageName,
      image_link: this.state.PushNotificationImage,
      emp_mail: this.props.adminLoginData.adminData.user_email,
      // notification_type: this.state.selectTypeView
      notification_type: 'organization'
    }
    fetchApiCall(Config.InserPushNotification, body, headers, false, 'admin')
      .then(res => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code === '200') {
          toast.success(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          },
            this.props.allDismiss(),
            this.props.getPushNotificationCall(),
            this.props.pushNotificationsActive()
          )
          this.setState({ loading: false })

        } else {
          toast.warn(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
          this.setState({ loading: false })
        }
      }).catch(err => {
        this.setState({ loading: false })
      })
  }

  changeType = (data) => {
    this.setState({
      selectTypeView: data.value,
    })
  }


  // fileSelectHandler = (event) => {
  //   this.setState({ PushNotificationImageName: event.target.files[0] ? event.target.files[0].name : '' })
  //   if (event.target.files && event.target.files[0]) {
  //     // let maxAllowedSize = 1048576;  // 1MB size
  //     let maxAllowedSize = 2097152;  // 2MB size
  //     this.setState({
  //       imageSize: event.target.files[0].size
  //     })
  //     if (event.target.files[0].size <= maxAllowedSize) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]);
  //       reader.onload = (e) => { this.setState({ PushNotificationImage: e.target.result }) }
  //     }
  //     else {
  //       toast.warn('Please upload image less than 2MB', {
  //         position: "bottom-center",
  //         hideProgressBar: true,
  //         className: 'toastWarn'
  //       })
  //       this.setState({ PushNotificationImage: '' })
  //     }
  //   }
  //   else { this.setState({ PushNotificationImage: '' }) }
  // }



  fileSelectHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ PushNotificationImageName: event.target.files[0] ? event.target.files[0] : '' })
    // let re = /(?:\.([^.]+))?$/;
    // var ext = re.exec("file.name.with.dots.txt")[1];
    // console.log("ext:", re.exec(event.target.files[0])[1])
    if (event.target.files && event.target.files[0]) {
      // let maxAllowedSize = 1048576;  // 1MB size
      let maxAllowedSize = 2097152;  // 2MB size
      if (event.target.files[0].size <= maxAllowedSize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.setState({
              heightAndWidth: [img.width, img.height]
            })
            this.uploadImg(this.state.PushNotificationImageName, file)
            console.log('Image width:', img.width);
            console.log('Image height:', img.height);
          }
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({ PushNotificationImageName: '' })
      }
    }
    else { this.setState({ PushNotificationImageName: '' }) }
  }

  uploadImg = (event, data) => {
    console.log("uploadImg event:", event);
    this.setState({
      PushNotificationImageName: event ? event.name : '',
    })
    console.log('event--------->', event)
    if (event) {
      console.log('event.size--------->', event.size)
      let maxAllowedSize = 2097152;  // 2MB size
      if (event.size <= maxAllowedSize) {
        console.log("inside :", event.size, maxAllowedSize, data.file);
        // ******************************for Resize the image*****************************************
        try {
          Resizer.imageFileResizer(
            data,
            1280,
            720,
            'PNG',
            100,
            0,
            (uri) => {
              console.log('Resized image URI:', uri);
              this.setState({ PushNotificationImage: uri })
              // Image.getSize(uri, (width, height) => {
              //   console.log('Resized image size:', width, height);
              // });
            },
            'uri',
            1280,
            720
          );
        } catch (err) {
          console.log("Resizer err:", err);
        }
        // **************************************************************************

        // let reader = new FileReader();
        // reader.readAsDataURL(event);
        // reader.onload = (e) => {
        //   this.setState({
        //     sparshImgUrl: e.target.result
        //   },
        //     console.log("e.target.result:", e.target.result)
        //   )
        // }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          PushNotificationImage: '',
          PushNotificationImageName: ''
        })
      }
    }
    else {
      this.setState({
        PushNotificationImage: '',
        PushNotificationImageName: ''
      })
    }
  }

  validate = () => {
    if (this.state.title === '') {
      toast.warning('Please Enter Image Title', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else if (this.state.selectTypeView === '') {
      toast.warning('Please Select Notification Type', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    // else if (this.state.PushNotificationImageName === '' && this.state.imageSize <= 2097152) {
    //     toast.warning('Please Select Image', {
    //         position: "bottom-center",
    //         hideProgressBar: true,
    //         className: 'toastWarn'
    //     })
    // }
    else if (this.state.descriptionText === '') {
      toast.warning('Please Write Description', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else {
      this.insertPushNotification()
    }
  }

  render() {
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        <div>
          <form className='todoModalBody popup_scroller w-100'>
            <div>
              <div>
                <div className='p-sub-heading ml-3 mt-1'>Title</div>
                <div className='p-placeholder-text mb-1'>
                  <input
                    className="form-control p-placeholder px-3"
                    type="text"
                    placeholder={"Enter the title"}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ title: e.target.value })}
                    value={this.state.title}
                  />
                </div>
                {/* <div>
                <div className='p-sub-heading ml-3'>Type</div>
                <div>
                  <Dropdown
                    data={this.state.notificationType}
                    selectedValue={(data) => this.changeType(data)}
                    value={this.state.selectTypeView}
                    render={'value'}
                  />
                </div>
              </div> */}
                <div>
                  <div className='p-sub-heading ml-3 mt-1'>File</div>
                  {!this.props.show &&
                    <div className='p-placeholder-text mb-1'>
                      <input
                        className="form-control p-placeholder px-3"
                        type="file"
                        accept="image/*"
                        onChange={this.fileSelectHandler}
                      />
                      <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                    </div>
                  }
                </div>
                <div className='p-sub-heading ml-3'>Description</div>
                <div className='p-placeholder-text'>
                  <textarea
                    className="form-control p-placeholder px-3"
                    type="text"
                    placeholder={"Enter the description"}
                    maxLength={1000}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ descriptionText: e.target.value })}
                    value={this.state.descriptionText}
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </form>
          <div className='modal-footer btnBox justify-content-between'>
            <div className='justify-content-start'>
              <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
            </div>
            <div className='justify-content-around'>
              <div className='justify-content-start'>
                <input type='button'
                  value='CANCEL'
                  className='popup-back-btn popup-back-text'
                  onClick={this.props.back} />
              </div>
              <div className='justify-content-start'>
                <input type='button'
                  value='ADD'
                  className='popup-confirm-btn'
                  onClick={this.validate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    adminLoginData: state.adminLoginState,
    keycloakData: state.keycloakState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminAction: bindActionCreators(AdminLoginAction, dispatch),
  };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(PushNotification));