import React, { Component } from 'react';
import back from '../../../../assets/drishti_images/back.svg';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import task_calender from '../../../../assets/drishti_images/todo/task_calender.svg';
import tag from '../../../../assets/drishti_images/todo/tag.svg';
import orangeTag from '../../../../assets/drishti_images/todo/orangeTag.svg';
import Greentag from '../../../../assets/drishti_images/todo/Greentag.svg';
import moment from 'moment';
import nodataicon from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
class ToDoList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adminTaskDetails: [],
        }
    }

    componentDidMount() {
        this.getAdminTask()
    }

    getAdminTask = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            filter_month: 'All'
        }
        fetchApiCall(Config.getAdminTask, body, headers, true, 'admin')
            .then(res => {
                console.log('filter_month:', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        adminTaskDetails: res.data.admin_task_details ? res.data.admin_task_details : [0]
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    goTodo = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/todo')
    }

    render() {
        return (
            <div className="card-new h-100" >
                <div className="row justify-content-center">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">To-do List</label>
                        </div>
                        <div
                            type='button'
                            onClick={this.goTodo}>
                            <img alt="gotoAnnouncement" src={back} className="righticon"></img>
                        </div>
                    </div>
                    {this.state.adminTaskDetails.length != 0 ?
                        <div className="row container-fluid mt-2">
                            <div className='col-md-12 todo-container h-30vh mt-1 p-lr'>
                                {this.state.adminTaskDetails.map((todolist, id) =>
                                    <div key={id}>
                                        <div className='d-flex row p-2'>
                                            {/* <div className='justify-content-between' style={{ flex: 1 / 5 }}>
                                                <label className="labell">
                                                    <input type="checkbox" name="radio"
                                                        checked={todolist.isCompleted}
                                                        disabled={todolist.isCompleted}
                                                        onChange={() => this.updateTaskComplete(todolist)} />
                                                    <span className="checkk"></span>
                                                </label>
                                            </div> */}
                                            <div style={{ flex: 1 }}>
                                                <div className="to-do-list" >
                                                    <div className='justify-content-between' style={{ flex: 3 }}>
                                                        <div className='row todotitle'>
                                                            {todolist.title}
                                                        </div>
                                                        <div className='ml-1'>
                                                            <img alt='' src={todolist.priority === 'high' ? tag : todolist.priority === 'normal' ? orangeTag : Greentag} style={{ height: 17 }} />
                                                        </div>
                                                    </div>
                                                    <div className='justify-content-between'>
                                                        <div className='row align-item-center mt-2'>
                                                            <img alt='check' src={task_calender} className='check-icon mr-2' />
                                                            <div className='tododate'>Due date:
                                                                {todolist.due_date ? moment(todolist.due_date.dateTime).format('  DD MMMM, YYYY') : '  Not Setted  '}
                                                            </div>
                                                        </div>
                                                        {/* <div>
                                                                        <img alt='delete' src={dustbeen} data-toggle='modal' style={{}}
                                                                            data-target='#DeleteConfirmationModal' onClick={() => this.updateAction('delete', todolist)} />
                                                                    </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-hr-lr'>
                                            <hr />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <div className='nodata-incenter'>
                            <img src={nodataicon} alt="Nodata" />
                            <label className='card-content-title grey-color'>No tasks yet!</label>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ToDoList;