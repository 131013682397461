import React, { Component } from 'react';
import FeedbackCard from './FeedbackCard';
import SummaryCard from './SummaryCard';
import { connect } from 'react-redux';
import Loader from '../../../../shared/loader/LoaderIndicator'
import overviewButton from '../../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
class DirectReporteeDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            emp_id: ''
        }
    }
    componentDidMount() {
        this.setState({ empID: this.props.cfmData.directReporteeDetail ? this.props.cfmData.directReporteeDetail.empID : '' })
    }
    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'teams');
    }


    render() {
        return (
            <div className="justify-content-between">
                <Loader loading={this.state.loading} />
                <div className="col-md-12" >
                    {/* <div type='button' className='row' onClick={this.gotoLanding}>
                        <img src={overviewButton} />
                    </div> */}
                    <div className='row' >
                        <img src={overviewButton} alt='' onClick={this.gotoLanding} style={{ 'cursor': 'pointer' }} />
                    </div>
                    <div className="center-body-color">
                        <div className="row pt-12px">
                            <div className="col-md-6 px-0">
                                <FeedbackCard
                                    loginData={this.props.loginData}
                                    cfmData={this.props.cfmData}
                                />
                            </div>
                            <div className="col-md-6 pr-0">
                                <SummaryCard
                                    loginData={this.props.loginData}
                                    cfmData={this.props.cfmData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfmData: state.cfmState
    }
}

export default (connect(mapStatesToProps, {})(DirectReporteeDetail));