import React, { Component } from 'react';
import DataNotFound from '../../../shared/notFound/DataNotFound'
import moment from 'moment';
import approve from '../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../assets/drishti_images/manager/landing/reject.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import ConfirmPopup from '../ConfirmPopup'
import Dropdown from '../../../shared/components/Dropdown'
import reject_button from '../../../../assets/drishti_images/manager/approval/reject_button.svg'
import approve_button from '../../../../assets/drishti_images/manager/approval/approve_button.svg'

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class LeaveApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            isLeaveApprovalActive: true,
            loading: false,
            leaveApprovalList: [],
            actionCode: '',
            isApprove: false,
            isReject: false,
            selectedData: [],
            selectAllCheckBox: false,
            showApproveReject: false,
            leaveRequestStatus: [],
            leaveRequestTypes: [],
            leaveRequestEmpId: [],
            tempHideShowArray: [],
            leaveCatCode: 'All',
            leaveStatusCode: '',
            leaveNameCode: 'All',
            leaveCatValue: 'All Leave Type',
            leaveStatusValue: '',
            leaveNameValue: 'All Employees',
        }
    }
    componentDidMount() {
        this.getLeaveRequestMasterData()
    }
    getCurrentStatusName(statusCode) {
        var tempStatusName = this.state.leaveRequestStatus.find(status => status.code == statusCode);
        if (tempStatusName) {
            this.setState({
                leaveStatusCode: tempStatusName.code,
                leaveStatusValue: tempStatusName.value
            }, () =>
                this.getApprovalLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.leaveNameCode, 'emp_id')
            )
        } else {
            return null
        }
    }
    getLeaveRequestMasterData() {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getLeaveRequestMasterData, {
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea,
            emp_id: this.props.loginData.userData.Perno
        }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveRequestTypes: res.data.data.leave_category,
                        leaveRequestStatus: res.data.data.leave_status,
                        leaveRequestEmpId: res.data.data.emp_id ? res.data.data.emp_id : [],
                        loading: false
                    });
                    let tempLeaveTypeData = [];
                    if (this.props.loginData.userData.Gender == 'M') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D0");
                    } else if (this.props.loginData.userData.Gender == 'F') {
                        tempLeaveTypeData = this.state.leaveRequestTypes.filter(item => item.code !== "D3");
                    }
                    this.setState({
                        leaveRequestTypes: tempLeaveTypeData
                    });
                    let temp = {
                        value: 'All',
                        code: 'All'
                    }
                    let tempEmpID = {
                        value: 'All Employees',
                        code: 'All'
                    }
                    this.state.leaveRequestTypes.unshift(temp);
                    this.state.leaveRequestStatus.unshift(temp);
                    this.getCurrentStatusName(0)

                    if (this.state.leaveRequestEmpId.length != 0) {
                        this.state.leaveRequestEmpId.unshift(tempEmpID)
                    } else {
                        this.state.leaveRequestEmpId.unshift({ value: 'No data found', code: '' })
                    }
                }
                else { this.setState({ loading: false }) }

            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    getApprovalLeaveListingData(employeeId, leaveData, statusData, empIdData, type) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
            approval_type: 'Leave Request',
            leave_category_code: leaveData,
            leave_status_code: statusData,
            emp_id_value: empIdData,
            filter_type: type,
            approver_id: this.props.loginData.managerData.Perno,
            pa: this.props.loginData.userData.PersArea,
            psa: this.props.loginData.userData.PSubarea
        }

        fetchApiCall(Config.getLeaveApproval, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApprovalList: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false,
                    });
                    const tempApprovalList = this.state.leaveApprovalList.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))

                    this.setState({
                        leaveApprovalList: tempApprovalList,
                        tempHideShowArray: []
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log('err', err);
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    
    approveRequest = (action) => {
        this.setState({
            loading: true
        });
        let temp = this.state.tempHideShowArray;
        const ids = [];
        let result = temp.filter(item => ids.includes(item.leave_request_id) ? false : ids.push(item.leave_request_id));
        const req = {
            emp_id: this.props.loginData.userData.Perno,
            data: result,
            action: action,
            approval_type: 'Leave Request'
        }
        const headers = {
            'Content-Type': 'application/json',
        }

        let apiType = Config.approveLeaveRequest;

        fetchApiCall(apiType, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                        tempHideShowArray: [],
                        selectAllCheckBox: false,
                        selectAllCheckBoxDelete: false,
                        isApprove: false,
                        isReject: false,

                    });
                    this.getCurrentStatusName(0)

                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                    });
                }
            }).catch(err => {

                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({
                    loading: false
                });
            })
    }
    changeCatDropdown = (listValue) => {
        this.setState({
            leaveCatCode: listValue.code,
            leaveCatValue: listValue.value
        }, () =>
            this.getApprovalLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.leaveNameCode, 'leave_type')
        )
    }
    changeStatusDropdown = (listValue) => {
        this.setState({
            leaveStatusCode: listValue.code,
            leaveStatusValue: listValue.value
        }, () =>
            this.getApprovalLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.leaveNameCode, 'leave_status')
        )
    }

    changeEmpDropdown = (listValue) => {
        this.setState({
            leaveNameCode: listValue.code,
            leaveNameValue: listValue.value
        }, () =>
            this.getApprovalLeaveListingData(this.props.loginData.userData.Perno, this.state.leaveCatCode, this.state.leaveStatusCode, this.state.leaveNameCode, 'emp_id')
        )
    }
    toggleAction = (type, data) => {
        if (data) {
            this.setState({ selectedData: data, actionValue: type })
            this.selectItem(data)
            if (type === 'approve') {
                this.setState({ actionCode: '0', isApprove: true })
            }
            else {
                this.setState({ actionCode: '1', isReject: true })
            }
            let temp = [{
                leave_request_id: data.leave_request_id,
                requestor_emp_id: data.requestor_emp_id
            }]
            this.setState({ tempHideShowArray: temp })
        }
        else {
            if (type === 'approve') {
                this.setState({ actionCode: '0', isApprove: true, actionValue: type })
            }
            else {
                this.setState({ actionCode: '1', isReject: true, actionValue: type })
            }
        }
    }
    selectItem = data => {
        data.isSelected = !data.isSelected;
        const index = this.state.leaveApprovalList.findIndex(
            item => item.leave_request_id === data.leave_request_id
        );
        this.state.leaveApprovalList[index] = data;
        if (data.isSelected) {
            let temp = {
                leave_request_id: data.leave_request_id,
                requestor_emp_id: data.requestor_emp_id
            }
            this.setState({
                showApproveReject: true
            })
            this.state.tempHideShowArray.push(temp)
        } else {
            this.state.tempHideShowArray.splice(this.state.tempHideShowArray.findIndex(item => item.leave_request_id === data.leave_request_id), 1)
            if (this.state.tempHideShowArray.length === 0) {
                this.setState({
                    tempHideShowArray: [],
                    selectAllCheckBox: false,
                    showApproveReject: false
                })
            }
        }
        var tempList = [];
        tempList = this.state.leaveApprovalList.filter((leave) => leave.leave_status_code === 0)

        if (tempList.length === this.state.tempHideShowArray.length) {
            this.setState({
                selectAllCheckBox: true,
            })
        } else {
            this.setState({
                selectAllCheckBox: false,
            })
        }
        this.setState({
            leaveApprovalList: this.state.leaveApprovalList,
        });
    }

    selectAllCheckBox = () => {
        this.setState({
            tempHideShowArray: [],
            selectAllCheckBox: !this.state.selectAllCheckBox,
        });
        if (!this.state.selectAllCheckBox) {
            this.state.leaveApprovalList.forEach((data) => {
                if (data.leave_status_code == 0) {
                    data.isSelected = true;
                    this.setState({
                        showApproveReject: true,
                    })

                    let temp = {
                        leave_request_id: data.leave_request_id,
                        requestor_emp_id: data.requestor_emp_id
                    }
                    this.state.tempHideShowArray.push(temp)
                    let tempselect = this.state.tempHideShowArray;
                    const ids = [];
                    let result = tempselect.filter(item => ids.includes(item.leave_request_id) ? false : ids.push(item.leave_request_id));
                    this.setState({ tempHideShowArray: result })
                }
            })
        } else {
            this.state.leaveApprovalList.forEach((data) => {
                data.isSelected = false;
                this.setState({
                    showApproveReject: false
                })
            })
            while (this.state.tempHideShowArray.length) {
                this.state.tempHideShowArray.pop();
            }
        }
    }
    render() {
        const { isApprove, isReject, leaveApprovalList, leaveStatusCode } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row mt-12px '>
                    <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                        <div className='text16_medium'>
                            Approval Table
                        </div>
                        <div className='d-flex row'>
                            <div>
                                <Dropdown
                                    data={this.state.leaveRequestEmpId}
                                    selectedValue={(data) => this.changeEmpDropdown(data)}
                                    value={this.state.leaveNameValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'height'}
                                />
                            </div>
                            <div className='ml-2'></div>
                            <Dropdown
                                data={this.state.leaveRequestTypes}
                                selectedValue={(data) => this.changeCatDropdown(data)}
                                value={this.state.leaveCatValue}
                                render={'value'}
                                arrow={'true'}
                                height={'height'}
                            />
                            <div className='ml-2'></div>
                            <Dropdown
                                data={this.state.leaveRequestStatus}
                                selectedValue={(data) => this.changeStatusDropdown(data)}
                                value={this.state.leaveStatusValue}
                                render={'value'}
                                arrow={'true'}
                                height={'height'}
                            />
                        </div>
                    </div>
                    {leaveApprovalList.length != 0 ?
                        <>
                            <div className='col-md-12 flex-direction-column scroller-managerApproval'>
                                <div className='mb-2 col-md-12 mt-3'>
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th><div>
                                                    {leaveStatusCode == 0 ?
                                                        <label className="checkBoxData">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                onChange={() => this.selectAllCheckBox()}
                                                                checked={this.state.selectAllCheckBox}
                                                                disabled={this.state.leaveApprovalList == [] || this.state.leaveApprovalList == '' ? true : false}
                                                            />
                                                            <span className="markk"></span>
                                                        </label> :
                                                        <label className="checkBoxData">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                disabled={true}
                                                                checked={false}
                                                            />
                                                            <span className="markk"></span>
                                                        </label>}
                                                </div>
                                                </th>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Employee Name</div></th>
                                                <th scope="col"><div className='thead'>Leave Category</div></th>
                                                <th scope="col"><div className='thead'>Leave Type</div></th>
                                                <th scope="col"><div className='thead'>From</div></th>
                                                <th scope="col"><div className='thead'>To</div></th>
                                                <th scope="col"><div className='thead'>Status</div></th>
                                                <th scope="col"><div className='thead'>Reason</div></th>
                                                <th scope="col"><div className='thead'>Action</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leaveApprovalList.map((listValue, index) => {
                                                return (
                                                    <tr key={index} >
                                                        <td><div>
                                                            {listValue.leave_status_code === '1' || listValue.leave_status_code === '2' || listValue.leave_status_code === '3'
                                                                ?
                                                                <label className="checkBoxData">
                                                                    <input type="checkbox" className='hidecheckBox'
                                                                        disabled={true}
                                                                    />
                                                                    <span className="markk"></span>
                                                                </label> :
                                                                <label className="checkBoxData">
                                                                    <input type="checkbox" className='hidecheckBox'
                                                                        onChange={() => this.selectItem(listValue)}
                                                                        checked={listValue.isSelected} />
                                                                    <span class="markk"></span>
                                                                </label>
                                                            }
                                                        </div>
                                                        </td>
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td><div className="td">{listValue.requestor_name ? listValue.requestor_name : ''}</div></td>
                                                        <td><div className="td">{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                        <td> <div className="td d-flex">{listValue.leave_type ? listValue.leave_type : ''}</div> </td>
                                                        <td><div className="td" >{moment(listValue.from_date).format("DD-MM-YYYY")}</div></td>
                                                        <td><div className="td" >{moment(listValue.to_date).format("DD-MM-YYYY")}</div></td>
                                                        <td><div className="td" style={{ color: listValue.leave_status_color }}>{listValue.leave_status ? listValue.leave_status : ''}</div></td>
                                                        <td><div className="td">{listValue.reason}</div></td>
                                                        {(this.state.tempHideShowArray.length == 1 || this.state.tempHideShowArray.length == 0) && leaveStatusCode == 0 ?
                                                            <td className='d-flex'>
                                                                <div onClick={() => this.toggleAction('approve', listValue)}>
                                                                    <img alt='approve' src={approve} style={{ width: '25px' }} className='mx-2' />
                                                                </div>
                                                                <div onClick={() => this.toggleAction('reject', listValue)}>
                                                                    <img alt="reject" src={reject} style={{ width: '25px' }} />
                                                                </div>
                                                            </td> :
                                                            <td className='d-flex'>
                                                                <div>{'-'}</div>
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            }
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className='col-md-12 flex-direction-column approveBottom occasion-bg-color  mb-3'>
                                {this.state.showApproveReject && this.state.tempHideShowArray.length > 1 ?
                                    <div className='row justify-content-between p-2'>
                                        <div>
                                            <span>
                                                {this.state.tempHideShowArray.length}
                                                {' '} of {''}
                                                {this.state.leaveApprovalList.length}
                                                {''} Selected
                                            </span>
                                        </div>
                                        <div className='row'>
                                            <div className='mx-2 pointer' onClick={() => this.toggleAction('reject')}>
                                                <img alt="reject" src={reject_button} />
                                            </div>
                                            <div className='pointer' onClick={() => this.toggleAction('approve')}>
                                                <img alt="approve" src={approve_button} />
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </>
                        :
                        <div className='col-md-12 justify-content-center align-item-center h-60vh'>
                            <DataNotFound />
                        </div>
                    }
                </div>
                {isReject &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Are you sure to reject this leave?'}
                        rejectData={() => this.approveRequest(this.state.actionCode)}
                        onDismiss={() => this.setState({ isReject: false })}
                    />
                }
                {isApprove &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Are you sure to approve this leave?'}
                        approveData={() => this.approveRequest(this.state.actionCode)}
                        onDismiss={() => this.setState({ isApprove: false })}
                    />
                }

            </div>
        );
    }
}

export default LeaveApproval;