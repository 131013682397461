import React, { Component } from 'react';
import { connect } from 'react-redux';
import PlannedLeave from '../../managerScreens/teams/PlannedLeave';
import DepartmentReportees from '../../managerScreens/teams/DepartmentReportees';
import graphActive from '../../../../assets/drishti_images/manager/teams/graphActive.svg'
import graphInActive from '../../../../assets/drishti_images/manager/teams/graphInActive.svg'
import calenderActive from '../../../../assets/drishti_images/manager/teams/calenderActive.svg'
import calenderInActive from '../../../../assets/drishti_images/manager/teams/calenderInActive.svg'
class ReporteeAndPlannedLeaveMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            toggleSelect: 'reportee',
        }
    }

    toggleTask = (type) => {
        if (type === 'reportee') {
            this.setState({ toggleSelect: type })
        }
        else {
            this.setState({ toggleSelect: type })
        }
    }

    render() {
        return (
            <div className="card-new h-100" >
                <div className='reporteeScroller'>
                    {
                        this.state.toggleSelect === 'plannedLeave' ?
                            <div>
                                <PlannedLeave loginData={this.props.loginData} className="height-210" />
                            </div>
                            :
                            <div>
                                <DepartmentReportees loginData={this.props.loginData} className="height-210" />
                            </div>
                    }
                </div>
                <div>
                    {this.state.toggleSelect === 'reportee' ?
                        < div className="toggleStyle">
                            <img src={graphActive} alt='' className='mx-1' />
                            <img src={calenderInActive} alt='' onClick={() => this.toggleTask('plannedLeave')} />
                        </div>
                        :
                        <div className="toggleStyle" >
                            <img src={graphInActive} alt='' className='mx-1' onClick={() => this.toggleTask('reportee')} />
                            <img src={calenderActive} alt='' className='mx-1' />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(ReporteeAndPlannedLeaveMain);