import React, { Component } from 'react';
import '../Transfer.css';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import EmployeeDetails from './ApplyPhase1';
import NewPositionDetails from './ApplyPhase2';
import TransferDetails from './ApplyPhase3';
import smallcircle from '../../../../../assets/drishti_images/transfer/smallcircle.svg';
import greencircle from '../../../../../assets/drishti_images/transfer/greencircle.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import overviewButton from '../../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import '../../../employeeScreens/profile/ProfileScreen.css';
class TransferAdmin extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            checked: false,
            isEmployeeDetailsActive: true,
            isNewPositionDetailsActive: false,
            isTransferDetailsActive: false,
            screenwidth: 0,
            screenheight: 0,
            showEmployeeInformation: false,
            newPositionData: [],
            locationCodeData: [],
            subFunction1Data: [],
            subFunction2Data: [],
            showPositionInformation: false,
            functionData: [],
            paData: [],
            psaData: [],
            isStep1Complete: false,
            isStep2Complete: false,
            isStep3Complete: false,
            emp_id: '',
            current_bhr: '',
            newPositionDataToTransfer: []
        }
    }

    toggleEmployeeDetails = () => {
        this.setState({ isEmployeeDetailsActive: true, isNewPositionDetailsActive: false, isTransferDetailsActive: false })
    }

    toggleNewPositionDetails = () => {
        this.setState({ isEmployeeDetailsActive: false, isNewPositionDetailsActive: true, isTransferDetailsActive: false })
    }

    toggleTransferDetails = () => {
        this.setState({ isEmployeeDetailsActive: false, isNewPositionDetailsActive: false, isTransferDetailsActive: true })
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'transfer')
    }

    getPositionDataTransfer = (data, empID, empName, currentBHR) => {
        this.setState({
            loading: true,
            newPositionDataToTransfer: data,
            emp_id: empID,
            transfer_emp_name: empName,
            current_bhr: currentBHR
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = data
        fetchApiCall(Config.getPositionDataTransfer, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isPositionedEmployee: true,
                        showPositionInformation: true,
                        showEmployeeInformation: false,
                        fillPosition: false,
                        paData: res.data.pa_code ? res.data.pa_code : [],
                        functionData: res.data.function_id ? res.data.function_id : [],
                        newPositionData: res.data.position_data ? res.data.position_data : '',
                        locationCodeData: res.data.location_code ? res.data.location_code : [],
                        isStep1Complete: true
                    },
                        () => this.toggleNewPositionDetails(),
                        () => this.updatePositionData(this.state.newPositionData)
                    );
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    const paArray = this.state.paData.map((item, idx) => ({
                        ...item,
                        id: idx
                    }))
                    const functionArray = this.state.functionData.map((item, idx) => ({
                        ...item,
                        id: idx
                    }))
                    const locationCodeArray = this.state.locationCodeData.map((item, idx) => ({
                        ...item,
                        id: idx
                    }))
                    this.setState({
                        paData: paArray,
                        functionData: functionArray,
                        locationCodeData: locationCodeArray
                    })
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                        paData: []
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
    }

    confirmPositionData = () => {
        this.setState({ checked: this.state.checked },
            () => this.toggleTransferDetails(),
        )
    }

    updatePositionData = (ListValue, positionCode, transferDate) => {
        let data = ListValue
        this.setState({
            position_code_for_new_position: positionCode,
            transfer_date: transferDate,
            position_bhr: data.bhr_pernr ? data.bhr_pernr : '',
            position_bhr_name: data.bhr_name ? data.bhr_name : '',
            reporting_manager: data.dem_pernr ? data.dem_pernr : '',
            reporting_manager_name: data.dem_name ? data.dem_name : '',
            ujr: data.jobtext ? data.jobtext : '',
            level_for_new_position: data.jobtext_level ? data.jobtext_level : '',
            job_code: data.job_code ? data.job_code : '',
            job_post: data.postxt ? data.postxt : '',
            sales_incentive: data.sales_incentive ? data.sales_incentive : '',
            vacant: data.vacant ? data.vacant : '',
            fem: data.fem ? data.fem : '',
            fem_name: data.fem_name ? data.fem_name : '',
            ou_for_new_position: data.immediate_ou ? data.immediate_ou : '',
            ou1_for_new_position: data.immediate_ou_level_1 ? data.immediate_ou_level_1 : '',
            ou2_for_new_position: data.immediate_ou_level_2 ? data.immediate_ou_level_2 : '',
            ou3_for_new_position: data.immediate_ou_level_3 ? data.immediate_ou_level_3 : ''
        })
    }

    GotoSecondPhase = () => {
        this.setState({ isStep2Complete: true }, () => this.toggleTransferDetails())
    }

    render() {
        return (
            <div className="row px-0 pt-0">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-12px pt-12px">
                    <div className='row px-0 pt-0' >
                        <img src={overviewButton} alt='' onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.isEmployeeDetailsActive ? "tab-active1" : "tab-inactive1"}>
                                    {this.state.isStep1Complete ?
                                        <img alt="smallcircle" src={greencircle} className='mr-3' /> :
                                        <img alt="greencircle" src={smallcircle} className='mr-3' />
                                    }
                                    <div className='mb-1'
                                        onClick={this.toggleEmployeeDetails}>
                                        <label type='button' className='mlm-30px mt-1'>
                                            Employee Details</label><br />
                                        <div className='tab-small-labels'>
                                            Enter and verify the details
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.isNewPositionDetailsActive ? "tab-active1" : "tab-inactive1"}>
                                    {this.state.isStep2Complete ?
                                        <img alt="smallcircle" src={greencircle} className='mr-3' /> :
                                        <img alt="greencircle" src={smallcircle} className='mr-3' />
                                    }                                    <div className='mb-1'
                                        onClick={this.toggleNewPositionDetails}>
                                        <label type='button' className='mlm-30px mt-1'>
                                            New Position Details
                                        </label><br />
                                        <div className='tab-small-labels'>
                                            Verify the new position details
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.isTransferDetailsActive ? "tab-active1" : "tab-inactive1"}>
                                    {this.state.isStep3Complete ?
                                        <img alt="smallcircle" src={greencircle} className='mr-3' /> :
                                        <img alt="greencircle" src={smallcircle} className='mr-3' />
                                    }
                                    <div className='mb-1'
                                        onClick={this.toggleTransferDetails}>
                                        <label type='button' className='mt-1'>
                                            Transfer Details</label><br />
                                        <div className='tab-small-labels mlm-25px'>
                                            Fill the details
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='main-contain-transfer'>
                                {this.state.isEmployeeDetailsActive ?
                                    <div>
                                        <EmployeeDetails
                                            loading={this.state.loading}
                                            getPositionDataTransfer={(data, empID, empName, currentBHR) => this.getPositionDataTransfer(data, empID, empName, currentBHR)}
                                        />
                                    </div> : null
                                }
                                {this.state.isNewPositionDetailsActive ?
                                    <div>
                                        <NewPositionDetails
                                            newPositionData={this.state.newPositionData}
                                            confirmPositionData={() => this.GotoSecondPhase()}
                                            loginData={this.props.loginData}
                                            loading={this.state.loading}
                                        />
                                    </div> : null
                                }
                                {this.state.isTransferDetailsActive ?
                                    <div>
                                        <TransferDetails
                                            loading={this.state.loading}
                                            paData={this.state.paData}
                                            newPositionData={this.state.newPositionData}
                                            functionData={this.state.functionData}
                                            locationCodeData={this.state.locationCodeData}
                                            subFunction1Data={this.state.subFunction1Data}
                                            subFunction2Data={this.state.subFunction2Data}
                                            psaData={this.state.psaData}
                                            loginData={this.props.loginData}
                                            emp_id={this.state.emp_id}
                                            transfer_emp_name={this.state.transfer_emp_name}
                                            current_bhr={this.state.current_bhr}
                                            setPhase3Complete={() => this.setState({ isStep3Complete: true })}
                                            newPositionDataToTransfer={this.state.newPositionDataToTransfer}
                                        />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(TransferAdmin);
