import React, { useContext, useEffect, useState } from "react";
import ClearanceTable from "./ClearanceTable";
import { reportTable1Col, FAndFSettlementReportCol, agencyWiseReportFullAccessAgencies, } from "./constants";
import DataNotFound from "../../../shared/notFound/DataNotFound";
import DateFilter from "./DateFilter";
import { Pagination } from "react-bootstrap";
import searchIcon from "../../../../assets/drishti_images/admin/searchIcon.svg";
import { addDays, subtractDays, } from "../../managerScreens/approval/commonUtils";
import { DDMMYYYY, toaster , DDMMMYY } from "../../../../utils/Utils";
import DynamicDropdown from "../../../shared/components/DynamicDropdown";
import {
  getAgencyWiseClearanceReport,
  downloadAgencyWiseReport,
  getFAndFSettlementClearanceReport,
  getAgencyMasterList,getAgencyMasterListV1
} from "../../../../constants/api.service";
import { commonDownloadFile } from "../../../shared/CommonDownloadFunction";
import exportReport from "../../../../assets/drishti_images/clearance/exportReport.svg";
import moment from "moment/moment";
import { exportTOExcel } from "../localConveyance/constants";
import downloadReport from "../../../../assets/drishti_images/clearance/downloadReport.svg";
import { RoleContext } from "./Contexts";
import useDidMountEffect from "../../../../customHooks/ComponentDidUpdateFunction";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import { configStore } from "../../../../redux/ConfigStore";

export default function ClearanceReport(props) {
  const { screenType } = props;
  const filterMaster = useContext(RoleContext)
  const [viewFilter, setViewFilter] = useState(filterMaster?.cleRequestViewFilter?.[0]);
  const [filterVal, setFilterVal] = useState(filterMaster?.releaseStatusList?.[0]);
  const [tableData, setTableData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [empId, setEmpId] = useState("");
  const [selectedOption, setSelectedOption] = useState("agency_consolidated");
  const [selectedAgency, setSelectedAgency] = useState("")
  const [agencyList, setAgencyList] = useState([])

  const fetchClearanceReport = (page) => {
    let agency_cid = selectedAgency.agency_config_id.join(',')
    setLoading(true);
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
      page_number: page,
      status: filterVal?.value,
      emp_id: empId,
      agency_config_id: agency_cid,
      // comp_code: configStore.getState()?.adminLoginState?.company?.company_code
    };
    getAgencyWiseClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const { paginated_data, total_pages } = res.data.data;
          setTableData(paginated_data);
          setTotalPages(total_pages);
        } else {
          setTableData([]);
          setTotalPages(0);
          res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        setTableData([]);
        setTotalPages(0);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  useDidMountEffect(() => {
    setCurrentPage(1);
    setTotalPages(1);
    if (selectedOption == "released_clearance_application") {
      getFAndFReportData(1);
    } else {
      fetchClearanceReport(1);
    }
  }, [fromDate, toDate, filterVal, selectedOption, selectedAgency]);

  useDidMountEffect(() => {
    setFromDate("");
    setToDate("");
    setEmpId("")
  }, [selectedOption]);

  useEffect(() => {
    fetchAgencyList()
  }, [filterMaster])

  const fetchAgencyList = () => {
    setLoading(true)
    let params = {
      comp_code: configStore.getState()?.adminLoginState?.company?.company_code
  }
    if (agencyWiseReportFullAccessAgencies.some(agency => filterMaster?.cleRequestViewFilter?.map((agency) => { return agency.title }).includes(agency)))
      getAgencyMasterListV1(params ,props)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              let agencyList = res?.data?.data?.map((agency) => { return { ...agency, agency_id: agency.id, title: agency.name } })
              setAgencyList(agencyList)
              setSelectedAgency(agencyList[0])
            }
          }
        ).catch((err) => { setAgencyList([]) }).finally(() => setLoading(false))
    else {
      setAgencyList(filterMaster.cleRequestViewFilter)
      setSelectedAgency(filterMaster.cleRequestViewFilter[0])
    }
  }
  const searchFilterFunction = () => {
    setCurrentPage(1);
    setTotalPages(1);
    if (selectedOption === "settlement_report") {
      getFAndFReportData(currentPage);
    } else {
      fetchClearanceReport(currentPage);
    }
  };

  const handleAction = (index) => {
    setLoading(true);
    downloadAgencyWiseReport(props, tableData[index].clearance_id)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          let data = res.data;
          let fileNametest =
            res.headers["content-disposition"].split("filename=")[1];
          let fileName = (fileNametest ? fileNametest : "my_report") + ".pdf";
          let toasterMsg = "Your document has been downloaded.";
          commonDownloadFile(data, toasterMsg, fileName);
        } else {
          setTableData([])
          setTotalPages(0)
          res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "")
        }
      })
      .catch((error) => {
        console.log("downloadCompPDF catch error:", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const getFAndFReportData = (page) => {
    setLoading(true);
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
      page_number: page,
      emp_id: empId
    };
    getFAndFSettlementClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          const { paginated_data, total_pages } = res.data.data;
          setTableData(paginated_data);
          setTotalPages(total_pages);
        } else {
          setTableData([]);
          setTotalPages(0);
          res?.status != 404 &&
            toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        setTableData([]);
        setTotalPages(0);
        error?.status != 404 &&
          toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const exportCsvReport = () => {
    let agency_cid = selectedAgency.agency_config_id.join(',')
    setLoading(true);
    let currentDate = moment().format("DD-MM-YYYY");
    let currentTime = moment().format("HH:mm");
    let fileName = "Clearance_" + currentDate + "_" + currentTime;
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
      status: filterVal?.value,
      emp_id: empId,
      // agency_id: selectedAgency.agency_id,
      agency_config_id: agency_cid,
      // comp_code: configStore.getState()?.adminLoginState?.company?.company_code
    };
    getAgencyWiseClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data?.paginated_data?.length == 0) {
            let exportClearanceData = [
              {
                "Employee ID": "-",
                "Employee Name": "-",
                "Company": "-",
                "Organization Unit 1": "-",
                "Employee Group": "-",
                "Level": "-",
                "Person Area": "-",
                "Person SubArea": "-",
                "Location": "-",
                "Actual Resignation Date": "-",
                "Last Working Date": "-",
                "Clearance Initiation Date": "-",
                "Clearance Completion Date": "-",
                "Clearance Status": "-",
                "Revoked Date": "-",
              },
            ];
            exportTOExcel(fileName, exportClearanceData, ".csv");
          } else {
            let exportClearanceData = res?.data?.data?.paginated_data?.map(
              (data, idx) => {
                return {
                  "Employee ID": data?.["emp_id"] ?? "-",
                  "Employee Name": data?.["emp_name"] ?? "-",
                  "Company": data?.["emp_comp_name"] ?? "-",
                  "Organization Unit 1": data?.["emp_department"] ?? "-",
                  "Employee Group": data?.["Egroup"] ?? "-",
                  "Level": data?.["Esubgroup"] ?? "-",
                  "Person Area": data?.["PersArea"] ?? "-",
                  "Person SubArea": data?.["PSubarea"] ?? "-",
                  "Location": data?.["emp_location"] ?? "-",
                  "Actual Resignation Date": DDMMMYY(data?.["resignation_date"]) ?? "-",
                  "Last Working Date": DDMMMYY(data?.["separation_date"]) ?? "-",
                  "Clearance Initiation Date": DDMMMYY(data?.["initiated_on"]) ?? "-",
                  "Clearance Completion Date": DDMMMYY(data?.["released_on"]) ?? "-",
                  "Clearance Status": data?.["clearance_status"] ?? "-",
                  "Revoked Date": DDMMMYY(data?.["revoked_date"]) ?? "-",
                  // "Location": data?.["emp_location"] ?? "-",
                };
              }
            );
            exportTOExcel(fileName, exportClearanceData, ".csv");
          }

          setLoading(false);
        } else {
          res?.status != 404 &&
            toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const exportFAndFSettlementReport = () => {
    setLoading(true);
    let currentDate = moment().format("DD-MM-YYYY");
    let currentTime = moment().format("HH:mm");
    let fileName =
      "F&F_Settlement_Clearance_" + currentDate + "_" + currentTime;
    let params = {
      sep_start_date: fromDate,
      sep_end_date: toDate,
    };
    getFAndFSettlementClearanceReport(params, props)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          if (res?.data?.data?.paginated_data?.length == 0) {
            const exportClearanceData = [
              {
                "Employee ID": "-",
                "Employee Name": "-",
                "Company": "-",
                "Organization Unit 1": "-",
                "Employee Group": "-",
                "Level": "-",
                "Person Area": "-",
                "Person SubArea": "-",
                "Actual Resignation Date": "-",
                "Last Working Date": "-",
                "Clearance Initiation Date": "-",
                "Clearance Completion Date": "-",
                "Clearance Status": "-",
                "Separation Reason": " ",
                "HR Clearance Date": " ",
                "Finance Clearance Date": "",
                "Revoked Date": "-",
              },
            ];
            exportTOExcel(fileName, exportClearanceData, ".csv");
          } else {
            const exportClearanceData = res?.data?.data?.paginated_data?.map(
              (data, idx) => {
                return {
                  "Employee ID": data?.["emp_id"] ?? "-",
                  "Employee Name": data?.["emp_name"] ?? "-",
                  "Employee Group": data?.["Egroup"] ?? "-",
                  "Level": data?.["Esubgroup"] ?? "-",
                  "Person Area": data?.["PersArea"] ?? "-",
                  "Person SubArea": data?.["PSubarea"] ?? "-",
                  "Organization Unit 1": data?.["emp_department"] ?? "-",
                  "Location": data?.["emp_location"] ?? "-",
                  "Actual Resignation Date": DDMMMYY(data?.["resignation_date"]) ?? "-",
                  "Last Working Date": DDMMMYY(data?.["separation_date"]) ?? "-",
                  "Clearance Initiation Date": DDMMMYY(data?.["initiated_on"]) ?? "-",
                  "Clearance Completion Date": DDMMMYY(data?.["released_on"]) ?? "-",
                  "Clearance Status": data?.["clearance_status"] ?? "-",
                  "Separation Reason": data?.["sep_reason"] ?? "-",
                  "HR Clearance Date": DDMMMYY(data?.["hr_clearance_date"]) ?? "-",
                  "Finance Clearance Date": DDMMMYY(data?.["finance_clearance_date"]) ?? "-",
                  "Revoked Date": DDMMMYY(data?.["revoked_date"]) ?? "-",
                };
              }
            );
            exportTOExcel(fileName, exportClearanceData, ".csv");
          }

          setLoading(false);
        } else {
          res?.status != 404 &&
            toaster("warning", res.data.message ? res.data.message : "");
        }
      })
      .catch((error) => {
        console.log("error in get clearance request API", error);
        error?.status != 404 && toaster("warning", error?.data?.message ? error.data.message : "");
      })
      .finally(() => setLoading(false));
  };

  const handleViewFilter = (data) => {
    setViewFilter(data);
  };

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      if (selectedOption === "settlement_report") {
        getFAndFReportData(currentPage - 1);
      } else {
        fetchClearanceReport(currentPage - 1);
      }
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      if (selectedOption === "settlement_report") {
        getFAndFReportData(currentPage + 1);
      } else {
        fetchClearanceReport(currentPage + 1);
      }
    }
  };

  const handleOptionChange = (event) => { setSelectedOption(event.target.value); };

  return (
    <>
      <div className="flex-direction-column background-prop">
        <LoaderIndicator loading={loading} />
        <div className="row align-item-center ml-2 pt-10px pb-10px">
          <span className="bold-font mr-10px">Report Type: </span>

          <input
            type="radio"
            id="agency_consolidated"
            value="agency_consolidated"
            checked={selectedOption === "agency_consolidated"}
            onChange={handleOptionChange}
            className="purple-radio mr-10px"
          />
          <label className="td-choice-pay mr-15px" htmlFor="agency_consolidated">
            Agency Wise Report
          </label>
          {
            filterMaster.adminTabList["Report"].includes("Released Clearance Application") &&
            <>
              <input
                type="radio"
                id="released_clearance_application"
                value="released_clearance_application"
                checked={selectedOption === "released_clearance_application"}
                onChange={handleOptionChange}
                className="purple-radio mr-10px"
              />
              <label className="td-choice-pay mr-10px" htmlFor="released_clearance_application">
                Completed Clearance
              </label>
            </>
          }

        </div>

        <div className="row align-item-center justify-content-between ml-2">
          <div className="row">
            <div className="row mt-2 ml-4">
              <div className="p-placeholder-text mb-1 mr-3">
                <input
                  className="form-control p-placeholder px-2 p-placeholder-admin"
                  type="text"
                  placeholder="Enter Employee ID"
                  onChange={(e) => setEmpId(e.target.value)}
                  value={empId}
                />
              </div>
              <div
                type="button"
                className="serchIconSize"
                onClick={() => searchFilterFunction()}
              >
                <div className="mr-4">
                  <img
                    alt="radioOn"
                    className="filterIcon"
                    src={searchIcon}
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <DateFilter
              title="From Date"
              action={(val) => setFromDate(val)}
              value={fromDate}
              minDate={subtractDays(new Date(), 60)}
              maxDate={addDays(new Date(), 60)}
            />
            <DateFilter
              title="To Date"
              action={(val) => setToDate(val)}
              value={toDate}
              minDate={subtractDays(new Date(), 60)}
              maxDate={addDays(new Date(), 60)}
            />

            <div className="ml-3 flex-direction-row gap-10px align-item-center mt-10_2px">
              {selectedOption === "agency_consolidated" && filterMaster?.releaseStatusList &&
                <>
                  <div className="p-sub-heading mb-1 ml-2">{"Clearance Status"}</div>
                  <DynamicDropdown
                    data={filterMaster.releaseStatusList}
                    render="title"
                    value={filterVal?.title}
                    selectedValue={(data) => setFilterVal(data)}
                    arrow={"true"}
                    height={"NotificationHeight"}
                  />
                </>}
              {
                selectedOption === "agency_consolidated" && agencyList &&
                <>
                  <div className="p-sub-heading mb-1 ml-2">{"Select Agency"}</div>
                  <DynamicDropdown
                    data={agencyList}
                    render="title"
                    value={selectedAgency?.title}
                    selectedValue={(data) => setSelectedAgency(data)}
                    arrow={"true"}
                    height={"NotificationHeight"}
                  />
                </>
              }
              {
                tableData.length > 0 &&
                <img
                  className="button-hover-effect"
                  src={exportReport}
                  onClick={
                    selectedOption === "agency_consolidated"
                      ? exportCsvReport
                      : exportFAndFSettlementReport
                  }
                ></img>
              }
            </div>
          </div>
        </div>
      </div>

      {selectedOption === "agency_consolidated" && (
        <div className="flex-direction-column scroll-auto h-48vh mt-10px">
          {tableData && tableData.length > 0 ? (
            <ClearanceTable
              data={tableData}
              columns={reportTable1Col}
              hasAction={false}
              screenType={screenType}
              currentPage={currentPage}
              pageSize={10}
            />
          ) : (
            <div className="mt-5 h-90-percent">
              <DataNotFound />
            </div>
          )}
        </div>
      )}
      {tableData.length > 0 && (
        <div className="col-md-12 w-100 d-flex justify-content-center paginator">
          <Pagination>
            {currentPage != 1 && (
              <>
                <Pagination.Prev
                  onClick={() => {
                    handlePageCount("decrement");
                  }}
                />
                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>
                  {1}
                </Pagination.Item>
                <Pagination.Ellipsis disabled />
              </>
            )}
            <Pagination.Item active>{currentPage}</Pagination.Item>
            {currentPage != totalPages && (
              <>
                <Pagination.Ellipsis disabled />
                <Pagination.Item
                  disabled
                  onClick={() => setCurrentPage(totalPages)}
                >
                  {totalPages}
                </Pagination.Item>
                <Pagination.Next
                  onClick={() => {
                    handlePageCount("increment");
                  }}
                />
              </>
            )}
          </Pagination>
        </div>
      )}
      {/* table 2 */}

      {selectedOption === "released_clearance_application" && (
        <div className="flex-direction-column scroll-auto h-48vh mt-10px">
          {tableData && tableData.length > 0 ? (
            <ClearanceTable
              data={tableData}
              columns={FAndFSettlementReportCol}
              hasAction={true}
              screenType={screenType}
              handleAction={(index) => handleAction(index)}
              primaryActionImg={downloadReport}
              currentPage={currentPage}
              pageSize={10}
            />
          ) : (
            <div className="mt-5 h-90-percent">
              <DataNotFound />
            </div>
          )}
        </div>
      )}
    </>
  );
}
