import React, { Component } from 'react';
import '../appCenter/Appcenter.css';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const exploreTabData = [
    { text: 'Pay Space', path: '/paySpace', imgSrc: require('../../../../../assets/drishti_images/explore/paySpaces.png') },
    { text: 'CFM', path: '/performance', imgSrc: require('../../../../../assets/drishti_images/explore/timeline.png') },
    { text: 'Documents', path: '/document', imgSrc: require('../../../../../assets/drishti_images/explore/document-center.png') },
    { text: 'Chat', path: 'https://teams.microsoft.com/l/chat/0/0?users=28:6d8568d5-5dc8-4a44-a4f7-314ff2727962', imgSrc: require('../../../../../assets/drishti_images/explore/chat.png') },
    { text: 'COI', path: '/coi', imgSrc: require('../../../../../assets/drishti_images/explore/coi.png') },
    { text: 'Holidays', path: '/leave', imgSrc: require('../../../../../assets/drishti_images/explore/holidays.png') },]

export class Explore extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    render() {
        return (
            <div className="card-new h-100">
                <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position pb-0">
                        <div>
                            <label className="edit-cardtitle">{'Explore'}</label>
                        </div>
                    </div>
                </div>
                <div className="ml-3 mb-1 mr-3 scroll-x">
                    <div className='d-flex align-item-center w-0px'>
                        {exploreTabData.map((listValue, index) => {
                            return (
                                <div key={index}>
                                    {listValue.text === 'Chat' ?
                                        <>
                                            <div className="col-sm appcentercolumn">
                                                <div className="d-flex justify-content-center">
                                                    <a href={listValue.path} target="_blank">
                                                        <div className="inner-app mt-3">
                                                            <img alt="Logo" src={listValue.imgSrc} className="appcenterlogo"></img>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="row appcenter-app-name mb-3">
                                                    {listValue.text}
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {(listValue.text !== 'Pay Space' || (listValue.text === "Pay Space" && this.props.loginData.isApplicableForChoicePay.is_employee_applicable)) &&
                                                <div className="col-sm appcentercolumn">
                                                    <div className="d-flex justify-content-center">
                                                        <Link to={listValue.path}>
                                                            <div className="inner-app mt-3">
                                                                <img alt="Logo" src={listValue.imgSrc} className="appcenterlogo"></img>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="row appcenter-app-name mb-3">
                                                        {listValue.text}
                                                    </div>
                                                </div>
                                            }
                                        </>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(Explore);