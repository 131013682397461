import React, { Component } from 'react'
import './ToDo.css';
import radio from '../../../../assets/drishti_images/todo/radio.svg'
import calander from '../../../../assets/drishti_images/todo/calander.svg'
import tag from '../../../../assets/drishti_images/todo/tag.svg'
import Greentag from '../../../../assets/drishti_images/todo/Greentag.svg'
import todo_default_no_data from '../../../../assets/drishti_images/todo/todo_default_no_data.svg'
import moment from 'moment';
import orangeTag from '../../../../assets/drishti_images/todo/orangeTag.svg';
import ConfirmPopup from './ConfirmPopup';
class OrganizationData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      selectedData: {},
      isconform: false,
      statusData: [
        { key: "Not Started", value: "notStarted" },
        { key: "In Process", value: "inProgress" },
        { key: "Completed", value: "completed" },
        { key: "Waiting On Others", value: "waitingOnOthers" },
        { key: "Deferred", value: "deferred" }]
    }
  }
  LargeTextStatus = (value) => {
    let statusList = this.state.statusData.filter((list) => list.value === value)
    return statusList[0].key
  }

  onChecked = () => {
    this.props.updateComplete(this.state.tempData)
  }

  onClickCheck = (data) => {
    this.setState({
      isconform: !this.state.isconform,
      tempData: data
    },
      console.log("data:", data)
    )
  }
  render() {
    const { data } = this.props
    return (
      <div>
        {data.length != 0 ?
          <div className='toDoListBox todo-scroll heightTodo'>
            <div className=''>
              <div className='row'>
                <div className='dateIndicator announcement-pin-content mt-2 ml-2'>
                  {/* {this.state.dueDateTitle} +{'|'} +{this.state.date} */}
                </div>
              </div>
              {data.map((item, index) =>
                <div className='toDoBox mt-2' key={index}>
                  <div className='row justify-content-between align-item-center p-1'>
                    <div className='row' style={{ width: '85%' }}>
                      <label className="labell">
                        <input type="checkbox" name="radio"
                          checked={item.isCompleted}
                          disabled={item.isCompleted}
                          onChange={() => this.onClickCheck(item)} />
                        <span className="checkk"></span>
                      </label>
                      <div className='ml-4'>
                        <div className={item.isCompleted ? 'ml-1 document-innercard-content-title line-through' : 'ml-1 document-innercard-content-title'}>{item.title}</div>
                      </div>
                    </div>
                  </div>
                  <div className='ml-5 align-item-center'>
                    <div className='row p-1 mt-2 align-item-center'>
                      <div className='row align-item-center pr-3'>
                        <img alt='' src={calander} style={{ width: 20, height: 20 }} />
                        <div className='ml-2 dueDate sm-text'>Due date:
                          {item.dueDateTime ? moment(item.dueDateTime.dateTime).format('  DD MMMM, YYYY') : '  Not Setted  '}
                        </div>
                      </div>
                      <div className='row px-3'>
                        <img alt='' src={item.importance === 'high' ? tag : item.importance === 'normal' ? orangeTag : Greentag} style={{ width: 20, height: 20 }} className='ml-4' />
                        <div className={item.importance === 'high' ? 'ml-2 red-tag sm-text text-capitalize align-item-center'
                          :
                          item.importance === 'normal' ? 'ml-2 amber-tag sm-text text-capitalize align-item-center'
                            : 'ml-2 green-tag sm-text text-capitalize align-item-center'}>
                          {item.importance}
                        </div>
                      </div>
                      <div className='row px-3'>
                        <img alt='' src={radio} style={{ width: 20, height: 20 }} className='ml-3' />
                        <div className={item.status === 'completed' ? 'ml-2 sm-text green-tag align-item-center'
                          :
                          item.status === 'notStarted' ? 'ml-2 sm-text gray-tag align-item-center'
                            :
                            item.status === 'inProgress' ? 'ml-2 sm-text amber-tag align-item-center'
                              :
                              item.status === 'deferred' ? 'ml-2 sm-text red-tag align-item-center'
                                :
                                'ml-2 sm-text purple-tag align-item-center'}
                        >
                          {this.LargeTextStatus(item.status)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          :
          <div className='col-md-12 d-flex align-items-center flex-column mt-5rem'>
            <div>
              <img src={todo_default_no_data} alt="Nodata" />
            </div>
            <div>
              <label className='card-content-title grey-color'>No data found</label>
            </div>
          </div>
        }
        {
          this.state.isconform &&
          <ConfirmPopup
            type={"Approve"}
            onDismiss={() => this.setState({ isconform: false, selectedData: {} })}
            approve={(data) => this.setState({ isconform: false }, () => this.onChecked())}
            taskTitle={this.state.selectedData.title}
            tempData={this.state.tempData}
          />
        }
      </div>
    )
  }
}

export default OrganizationData