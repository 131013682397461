import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import moment from 'moment';
import { toCamelCase } from '../../../../utils/Utils';
class Certificate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // isSearch: true,
            emp_id: '',
            rl_emp_id: '',
            service_cert_emp_id: '',
            resignation_date: '',
            rl_lwd: '',
            service_cert_lwd: '',
            loading: false,
            admin: true,
            listType: '',
            checked: 0,

            requestData: [
                {
                    id: 1,
                    title: 'Service Certificate'
                },
                {
                    id: 2,
                    title: 'Relieving Letter'
                }
            ]
        }
    }
    componentDidMount() {

    }

    // loadData = (emp_id) => {
    //     if (emp_id === '') {
    //         toast.warn('Please enter employee ID', {
    //             position: "bottom-center",
    //             hideProgressBar: true,
    //             className: 'toastWarn'
    //         });
    //     }
    //     else {
    //         // this.getEmployeeTransferData(empID)
    //     }
    // }

    // loadStartDate = (startDate) => {
    //     if (startDate === '') {
    //         toast.warn('Please Start Date', {
    //             position: "bottom-center",
    //             hideProgressBar: true,
    //             className: 'toastWarn'
    //         });
    //     }
    //     else {
    //         // this.getEmployeeTransferData(empID)
    //     }
    // }

    onPresentTypeChange = (key, type) => {
        this.setState({
            checked: key,
            listType: type,

            emp_id: '',
            rl_emp_id: '',
            service_cert_emp_id: '',
            resignation_date: '',
            rl_lwd: '',
            service_cert_lwd: '',
        })
    }

    validateServiceCertificate = () => {
        if (this.state.service_cert_emp_id === '') {
            toast.error('Please enter employee Id', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.service_cert_emp_id.length > 8) {
            toast.error('Please enter valid employee Id', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.service_cert_lwd === '' || !this.state.service_cert_lwd) {
            toast.error('Please select last date of working', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.getForm_service_cert()
        }
    }
    getForm_service_cert = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.service_cert_emp_id,
            lwd: moment(this.state.service_cert_lwd).format("DD-MM-YYYY")
        }
        fetchApiCall(Config.getServiceCertDetail, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        html: res.data.service_cert_details,
                        emp_name: res.data.employee_data
                    });
                    this.generatePDF_service_cert(this.state.service_cert_emp_id)
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    generatePDF_service_cert = async (emp_id) => {
        const html2pdf = (await import("html2pdf.js")).default;
        var type = 'Service_Certificate_'
        var emp_name = toCamelCase(this.state.emp_name)
        var pdfFileName = type + emp_name + '_' + emp_id;
        var html2canvas = { dpi: 192, letterRendering: true, height: 1024 }
        var element = this.state.html;
        var option = {
            margin: [0.5, 1, 1, 1],
            filename: pdfFileName,
            html2canvas: html2canvas,
            jsPDF: {
                orientation: 'portrait',
                unit: 'cm',
                format: [29.7, 21]
            }
        };

        html2pdf().set(option).from(element).save();
    }

    validateRelievingLetter = () => {
        if (this.state.rl_emp_id === '') {
            toast.error('Please enter employee Id', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.rl_emp_id.length > 8) {
            toast.error('Please enter valid employee Id', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.rl_lwd === '' || !this.state.rl_lwd) {
            toast.error('Please select last date of working', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.resignation_date === '' || !this.state.resignation_date) {
            toast.error('Please select date of resignation', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.getForm_rl()
        }
    }

    getForm_rl = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.rl_emp_id,
            lwd: moment(this.state.rl_lwd).format("DD-MM-YYYY"),
            resignation_date: moment(this.state.resignation_date).format("DD-MM-YYYY")

        }
        console.log("rl_body", body)
        fetchApiCall(Config.getRelivingLetterDetail, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    console.log('data')
                    this.setState({
                        loading: false,
                        html: res.data.relieving_letter_details,
                        emp_name: res.data.employee_data
                    });
                    this.generatePDF_rl(this.state.rl_emp_id)
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    generatePDF_rl = async (emp_id) => {
        const html2pdf = (await import("html2pdf.js")).default;
        var type = 'Relieving Letter_'
        var emp_name = toCamelCase(this.state.emp_name)
        var pdfFileName = type + emp_name + '-' + emp_id;
        var html2canvas = { dpi: 192, letterRendering: true, height: 1024 }
        var element = this.state.html;
        var option = {
            margin: [0.5, 1, 1, 1],
            filename: pdfFileName,
            html2canvas: html2canvas,
            jsPDF: {
                orientation: 'portrait',
                unit: 'cm',
                format: [29.7, 21]
            }
        };

        html2pdf().set(option).from(element).save();
    }
    render() {
        return (
            <div >
                <LoaderIndicator loading={this.state.loading} />
                <div className='col-12 mb-3 mt-3'>
                    <div className='p-heading'>Select the Type of Certificate :</div>
                    <div className='row' >
                        {this.state.requestData.length != 0 &&
                            this.state.requestData.map((list, key) => {
                                return (
                                    <div key={key} className='col-12 col-sm-4 col-lg-3 col-sm-4 mt-3 mb-2'>
                                        {this.state.checked === key ?
                                            <div className='row align-item-center' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                                <img alt="radioOn" className='radio' src={radioOn} />
                                                <label className="radioTxt" >{list.title}</label>
                                            </div>
                                            :
                                            <div className='row align-item-center' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                                <img alt="radioOff" className='radio' src={radioOff} />
                                                <label className="radioTxt">{list.title}</label>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='hz-lines mr-4 ml-4'></div>
                {this.state.checked === 0 ?
                    <>
                        <div className="col-md-12 px-0" >
                            <div className='p-heading ml-3'>Enter service Certificate of employee :</div>
                        </div>
                        <div className="col-md-12 px-0" >
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                    <div className='p-sub-heading ml-3'>Enter the employee Details</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            placeholder="Employee ID"
                                            type="number"
                                            onChange={(e) => this.setState({ service_cert_emp_id: e.target.value })}
                                            value={this.state.service_cert_emp_id ? this.state.service_cert_emp_id : ''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 px-0" >
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                    <div className='p-sub-heading ml-3'>Last Date of Working </div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type="date"
                                            placeholder='Enter date'
                                            onChange={(e) => this.setState({ service_cert_lwd: e.target.value })}
                                            value={this.state.service_cert_lwd ? this.state.service_cert_lwd : ''}
                                        />
                                    </div>
                                </div>
                                <div
                                    type='button'
                                    className='save-btn save-txt mt-38px'
                                    onClick={() => this.validateServiceCertificate(this.state.service_cert_emp_id)}
                                >GENERATE
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="col-md-12 px-0" >
                            <div className='p-heading ml-3'>Enter service Certificate of employee :</div>
                        </div>
                        <div className="col-md-12 px-0" >
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                    <div className='p-sub-heading ml-3'>Enter the employee Details</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            placeholder="Employee ID"
                                            type="number"
                                            value={this.state.rl_emp_id ? this.state.rl_emp_id : ''}
                                            onChange={(e) => this.setState({ rl_emp_id: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 px-0" >
                            <div className='row'>
                                <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                    <div className='p-sub-heading ml-3'>Last Date of Working </div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type="date"
                                            placeholder='Enter date'
                                            onChange={(e) => this.setState({ rl_lwd: e.target.value })}
                                            value={this.state.rl_lwd ? this.state.rl_lwd : ''}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 col-md-4 mb-3 mt-3'>
                                    <div className='p-sub-heading ml-3'>Date of Resignation</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            type="date"
                                            placeholder='Enter date'
                                            onChange={(e) => this.setState({ resignation_date: e.target.value })}
                                            value={this.state.resignation_date ? this.state.resignation_date : ''}
                                        />
                                    </div>
                                </div>
                                <div
                                    type='button'
                                    className='save-btn save-txt mt-38px'
                                    onClick={() => this.validateRelievingLetter(this.state.rl_emp_id)}
                                >GENERATE
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default Certificate;