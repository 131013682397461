import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import EmploymentCards from '../profile/EmploymentCards';
import { toast } from 'react-toastify';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime'

class EmploymentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            countryData: [],
            cityData: [],
            sectorData: [],
            CTCData: [],
            currencyData: [],
            departmentData: [],
            companyData: [],
            categoryCodeData: [],
            reasonForLeavingData: [],
            loading: false,
            showData: true,
            newEmplymentData: [],
        }
    }
    componentDidMount() {
        this.getEmploymentMasterData();
        this.getEmploymentDetails(this.props.loginData.userData.Perno);
    }
    getEmploymentMasterData = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getEmploymentMasterDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code === '200') {
                    this.setState({
                        countryData: result.data.data.country ? result.data.data.country : [],
                        cityData: result.data.data.city ? result.data.data.city : [],
                        sectorData: result.data.data.sector ? result.data.data.sector : [],
                        CTCData: result.data.data.ctc ? result.data.data.ctc : [],
                        currencyData: result.data.data.currency ? result.data.data.currency : [],
                        departmentData: result.data.data.division ? result.data.data.division : [],
                        companyData: result.data.data.company_type ? result.data.data.company_type : [],
                        categoryCodeData: result.data.data.employment_category ? result.data.data.employment_category : [],
                        reasonForLeavingData: result.data.data.rfl ? result.data.data.rfl : [],
                        loading: false
                    });
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    getEmploymentDetails(employeeId) {
        this.setState({
            loading: true,
            isEdit: false,
            showData: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getEmploymentDetails, { 'emp_id': employeeId }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200' || res.data.status_code === '300') {
                    this.setState({
                        newEmplymentData: res.data.employment_details,
                        loading: false,
                        showData: true
                    });
                } else {
                    this.setState({
                        loading: false,
                        newEmplymentData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })

    }
    saveEmploymentDetails = (savingData) => {
        this.setState({
            loading: true
        });
        const req = {
            data: [{
                emp_id: this.props.loginData.userData.Perno,
                previous_employer_name: savingData.previous_employer_name ? savingData.previous_employer_name : '',
                employment_start_date: savingData.employment_start_date ? savingData.employment_start_date : '',
                employment_end_date: savingData.employment_end_date ? savingData.employment_end_date : '',
                sector: savingData.sector ? savingData.sector : '',
                sector_code: savingData.sector_code ? savingData.sector_code : '',
                division: savingData.division ? savingData.division : '',
                division_code: savingData.division_code ? savingData.division_code : '',
                city: savingData.city ? savingData.city : '',
                country: savingData.country ? savingData.country : '',
                country_code: savingData.country_code ? savingData.country_code : '',
                ctc: savingData.ctc ? savingData.ctc : '',
                ctc_code: savingData.ctc_code ? savingData.ctc_code : '',
                currency: savingData.currency ? savingData.currency : '',
                currency_code: savingData.currency_code ? savingData.currency_code : '',
                designation: savingData.designation ? savingData.designation : '',
                company_type: savingData.company_type ? savingData.company_type : '',
                company_type_code: savingData.company_type_code ? savingData.company_type_code : '',
                employment_category: savingData.employment_category ? savingData.employment_category : '',
                employment_category_code: savingData.employment_category_code ? savingData.employment_category_code : '',
                reason_for_leave: savingData.reason_for_leave ? savingData.reason_for_leave : '',
                reason_for_leave_code: savingData.reason_for_leave_code ? savingData.reason_for_leave_code : ''
            }]
        }

        const headers = {
            'Content-Type': 'application/json',
        }

        fetchApiCall(Config.updateEmploymentDetails, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.statusCode === '200') {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getEmploymentDetails(this.props.loginData.userData.Perno)
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    deleteEmploymentDetails = (deleteData) => {
        this.setState({
            loading: true
        });
        const req = {
            data: [deleteData]
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.deleteEmploymentDetails, req, headers, false, '')
            .then(res => {
                if (res.data.statusCode == 200) {
                    this.setState({
                        loading: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getEmploymentDetails(this.props.loginData.userData.Perno)
                    if (this.state.newEmplymentData.length == 1) {
                        this.setState({
                            loading: false,
                            isEdit: true,
                            showData: true
                        });
                    }
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warn(res.data.message, { hideProgressBar: true });
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    unSetEdit = () => {
        this.setState({ isEdit: false }, () => this.getEmploymentDetails(this.props.loginData.userData.Perno)
        );
    }

    toggleOpenDetail = () => {
        this.setState({
            isEdit: true,
        })
    }

    render() {
        return (
            <div className="row">
                <LoaderIndicator loading={this.state.loading} />
                <div className=' row col-md-12 d-flex justify-content-end'>
                    {!this.state.isEdit && <label
                        type='button'
                        className='bank-addoption-text mx-4 mt-3 mb-0'
                        onClick={() => this.toggleOpenDetail()}
                    > + Add New Employement Detail
                    </label>}
                </div>
                <div className="col-md-12">
                    {this.state.showData && !this.state.isEdit ?
                        <div>
                            {!this.state.newEmplymentData.length == 0 ?
                                <div>
                                    {this.state.newEmplymentData.map((item, i) => {
                                        return (
                                            <div
                                                key={i}
                                            >
                                                <EmploymentCards
                                                    item={item}
                                                    isEdit={false}
                                                    deleteEmploymentData={(data) => this.deleteEmploymentDetails(data)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                :
                                <div className='mt-2'>
                                    <DataNotFound />
                                </div>
                            }
                        </div> : null
                    }
                    {this.state.isEdit ?
                        <EmploymentCards
                            isEdit={this.state.isEdit}
                            countryData={this.state.countryData}
                            cityData={this.state.cityData}
                            sectorData={this.state.sectorData}
                            CTCData={this.state.CTCData}
                            currencyData={this.state.currencyData}
                            departmentData={this.state.departmentData}
                            companyData={this.state.companyData}
                            categoryCodeData={this.state.categoryCodeData}
                            reasonForLeavingData={this.state.reasonForLeavingData}
                            show={this.state.show}
                            saveEmployeeData={(data) => this.saveEmploymentDetails(data)}
                            cancel={() => this.unSetEdit()}
                        />
                        : null
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}
export default connect(mapStatesToProps)(EmploymentDetails);