import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../button/ButtonStyles.css';

class CommonButton extends Component {

    render() {
        const { label, onClick, className, style, isGradientBtn, isDisableBtn, isWhiteBtn } = this.props;
        return (
            <div>
                <button className={
                    isGradientBtn ?
                        `btn buttonStyle ${className}` : //----Gradient button styles
                        isDisableBtn ?
                            `btn disableButtonStyle ${className}` : //----Disable button styles
                            isWhiteBtn ?
                                `btn whiteButtonStyle ${className}` //----White button styles
                                : null
                }
                    style={style}
                    onClick={onClick}>
                    {label}
                    {isGradientBtn}
                </button>
            </div>
        );
    }
}

CommonButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default CommonButton;