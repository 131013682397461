import React, { Component } from 'react';
import moment from 'moment';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import download from '../../../../assets/drishti_images/documents/download.svg';
import pdf from '../../../../assets/drishti_images/documents/pdf.svg';
import Dropdown from '../../../shared/components/Dropdown';
import radioOff from '../../../../assets/drishti_images/radioOff.png';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import radioOn from '../../../../assets/drishti_images/radioOn.png';
import { ManagerViewTitle, ApprovalConfirmation, RejectionConfirmation, successDownload, ManagerWaveOffApproval, WaveOffApproval, BUHRRejectRequest, RejectRequest, CharLimit300, CharLimit150 } from './EseparationContants';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';

const NoticePayWaveOffPolicy = 'https://tatamotors.sharepoint.com/:p:/r/sites/C-CorpHR/hrdocument/_layouts/15/WopiFrame.aspx?sourcedoc=%7BE255F5EA-51A4-41B2-B0D3-438A9306DE9A%7D&file=Notice%20pay%20wavier%20workflow.pptx&action=edit&mobileredirect=true&cid=95367797-2d9e-4486-8ff7-471a66fdc870'
export class ApprovalPopups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isReject: false,
            isApproveEarly: false,
            waveOff: false,
            reasonValue: 'Select',
            reasonCode: '',
            managerRejectReason: [],
            checked: 0,
            listType: '',
            radioFilters: [
                {
                    id: 2,
                    title: false,
                    type: 'No'
                },
                {
                    id: 1,
                    title: true,
                    type: 'Yes'
                }
            ],
            type: '',
            eSeparationId: '',
            noticePeriod: false,
            approvalsData: [],
            managerRecommedLWD: '',
            remarks: '',
            managerJustification1: '',
            waveOffJustification: '',
            rejectRemarks: '',
            is_notice_pay_wave_off_dem: null,
            isManagerApprovedPopup: false,
            actualLWDDate: ''
        }
    }

    componentDidMount() {
        this.getRejectionReason();
        this.setState({
            actualLWDDate: this.props.actualLWDDate ? this.props.actualLWDDate : '',
            eSeparationId: this.props.separationRequestId ? this.props.separationRequestId : '',
            isManagerApprovedPopup: this.props.isManagerApprovedPopup ? this.props.isManagerApprovedPopup : false,
            is_notice_pay_wave_off_dem: this.props.isNoticePayWaveOffDem ? this.props.isNoticePayWaveOffDem : null
        })
    }

    onPresentType1 = (key, list) => {
        this.setState({
            checked: key,
            listType: list.title
        })
    }

    //-------------------DEM Manager Approval API---------------------
    demApproval = () => {
        this.setState({
            loading: true,
            isDelete: false,
            isReject: false,
            isApproveEarly: false,
            waveOff: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : '',
            lwd_recommended_by_dem: this.state.managerRecommedLWD ? this.state.managerRecommedLWD : '',
            approval_status: this.state.type ? this.state.type : '',
            is_notice_pay_wave_off_dem: this.state.listType ? this.state.listType : false,
            dem_approval_remark: this.state.remarks ? this.state.remarks : '',
            dem_rejection_reason: this.state.reasonValue ? this.state.reasonValue : '',
            dem_rejection_remark: this.state.rejectRemarks ? this.state.rejectRemarks : '',
            lwd_justification_dem: this.state.managerJustification1 ? this.state.managerJustification1 : '',
            wave_off_justification_dem: this.state.waveOffJustification ? this.state.waveOffJustification : ''
        }
        fetchApiCall(Config.demApproval, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.onDismiss()
                    this.backClick()
                    this.props.toggleManagerApprovalsDetails()
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.backClick()
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                });
            })
    }

    //-------------------BHR Manager Approval API---------------------
    bhrApproval = () => {
        this.setState({
            loading: true,
            isDelete: false,
            isReject: false,
            isApproveEarly: false,
            waveOff: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : '',
            lwd_recommended_by_bhr: this.state.managerRecommedLWD ? this.state.managerRecommedLWD : '',
            approval_status: this.state.type ? this.state.type : '',
            is_notice_pay_wave_off_bhr: this.state.listType ? this.state.listType : false,
            bhr_approval_remark: this.state.remarks ? this.state.remarks : '',
            bhr_rejection_reason: this.state.reasonCode ? this.state.reasonCode : '',
            bhr_rejection_remark: this.state.rejectRemarks ? this.state.rejectRemarks : '',
            lwd_justification_bhr: this.state.managerJustification1 ? this.state.managerJustification1 : '',
            wave_off_justification_bhr: this.state.waveOffJustification ? this.state.waveOffJustification : ''
        }
        fetchApiCall(Config.bhrApproval, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.onDismiss()
                    this.backClick()
                    this.props.toggleBHRApprovalsDetails()
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.backClick()
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                });
            })
    }

    //-------------------BUHR Manager Approval API---------------------
    buhrApproval = () => {
        this.setState({
            loading: true,
            isDelete: false,
            isReject: false,
            isApproveEarly: false,
            waveOff: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            separation_request_id: this.state.eSeparationId ? this.state.eSeparationId : '',
            approval_status: this.state.type ? this.state.type : '',
            buhr_approval_remark: this.state.remarks ? this.state.remarks : '',
            buhr_rejection_reason: this.state.reasonCode ? this.state.reasonCode : '',
            buhr_rejection_remark: this.state.rejectRemarks ? this.state.rejectRemarks : '',
        }
        fetchApiCall(Config.buhrApproval, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.onDismiss()
                    this.backClick()
                    this.props.toggleBUHRApprovalsDetails()
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.backClick()
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                });
            })
    }

    //-------------------Manager Rejection Reason API------------------------
    getRejectionReason = () => {
        this.setState({
            loading: true,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            applicable_for: (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive) ? 'DEM' : this.props.isBHRApprovalActive === true ? 'BHR' : this.props.isBUHRApprovalActive === true ? 'BUHR' : '',
        }
        fetchApiCall(Config.getRejectionReason, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        reason: res.data.data
                    });
                    const tempRejectReasonList = this.state.reason.map(tempValue => ({ value: tempValue.reason, code: tempValue.reason_id, applicable: tempValue.applicable_for }))
                    this.setState({
                        managerRejectReason: tempRejectReasonList,
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false,
                        managerRejectReason: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    managerRejectReason: []
                });
            })
    }

    changeRejectionReason = (data) => {
        this.setState({
            reasonCode: data.code,
            reasonValue: data.value,
        })
    }

    rejectRequest = () => {
        this.setState({
            isReject: true
        })
    }

    submitApproval = (type) => {
        const lastWorkingDay = moment(this.props.lwdAsPerPolicy).format("YYYY-MM-DD")
        this.setState({ type: type })
        if (type === 'Approved') {
            if (this.state.managerRecommedLWD === '') {
                toast.error('Please select the date', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (lastWorkingDay > this.state.managerRecommedLWD && this.state.managerJustification1 === '') {
                this.setState({
                    isApproveEarly: false,
                    isReject: false
                })
                toast.error('Please enter the justification', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.props.isBHRApprovalActive ? (lastWorkingDay > this.state.managerRecommedLWD && this.state.managerJustification1.length < 150) : (lastWorkingDay > this.state.managerRecommedLWD && this.state.managerJustification1.length < 300)) {
                this.setState({
                    isApproveEarly: false,
                    isReject: false
                })
                toast.error(this.props.isBHRApprovalActive ? CharLimit150 : CharLimit300, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else {
                this.setState({
                    isApproveEarly: true,
                    isReject: false
                })
            }
        } else {
            this.setState({
                isReject: true,
                isApproveEarly: false
            })
        }
    }

    backClick = () => {
        this.setState({
            isApproveEarly: false,
            isReject: false,
            managerRecommedLWD: '',
            managerJustification1: '',
            noticePeriod: false,
            reasonValue: 'Select',
            reasonCode: '',
            rejectRemarks: '',
            waveOffJustification: '',
            remarks: '',
            listType: ''
        })
    }

    backStageClick = () => {
        this.setState({
            isApproveEarly: false,
            isReject: false,
            waveOff: false
        })
    }

    handleChangeRequestedDate = (e) => {
        this.setState({
            managerRecommedLWD: e,
        }, () => this.isJustifyCall())
    }

    isJustifyCall = () => {
        const lastWorkingDay = moment(this.props.lwdAsPerPolicy).format("YYYY-MM-DD")
        if (lastWorkingDay > this.state.managerRecommedLWD) {
            this.setState({
                noticePeriod: true,
            })
        }
        else if (lastWorkingDay <= this.state.managerRecommedLWD) {
            this.setState({
                noticePeriod: false,
            })
        }
    }

    validateBHUR = (type) => {
        this.setState({ type: type })
        if (type === 'Approved') {
            this.setState({
                isApproveEarly: true,
                isReject: false,
                waveOff: false
            })
        }
    }

    validate = () => {
        console.log('validate');
        if (this.state.managerRecommedLWD === '') {
            toast.error('Please select the date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.managerJustification1 === '' && this.state.managerRecommedLWD !== this.props.lwdAsPerPolicy) {
            toast.error('Please enter the justification', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.props.isBHRApprovalActive ? (this.state.managerJustification1.length < 150 && this.state.managerRecommedLWD !== this.props.lwdAsPerPolicy) : (this.state.managerJustification1.length < 300 && this.state.managerRecommedLWD !== this.props.lwdAsPerPolicy)) {
            toast.error(this.props.isBHRApprovalActive ? CharLimit150 : CharLimit300, {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if ((this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) || this.props.isBHRApprovalActive === true) {
            if ((this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) && this.props.lwdAsPerPolicy > this.state.managerRecommedLWD) {
                if (this.props.lwdAsPerPolicy > this.state.managerRecommedLWD) {
                    this.setState({
                        isApproveEarly: false,
                        isReject: false,
                        waveOff: true
                    })
                }
                else if (this.props.lwdAsPerPolicy <= this.state.managerRecommedLWD) {
                    this.setState({
                        isApproveEarly: false,
                        isReject: false,
                        waveOff: false
                    })
                }
                else {
                    if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
                        this.demApproval();
                    }
                    else if (this.props.isBHRApprovalActive === true) {
                        this.bhrApproval();
                    }
                }
            }
            else if (this.props.isBHRApprovalActive === true) {
                if ((this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy > this.state.managerRecommedLWD && this.state.is_notice_pay_wave_off_dem === true) || (this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy > this.props.lwdRecommendedByDem && this.state.is_notice_pay_wave_off_dem === true)) {
                    this.setState({
                        isApproveEarly: false,
                        isReject: false,
                        waveOff: true
                    })
                }
                else if ((this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy <= this.state.managerRecommedLWD && this.state.is_notice_pay_wave_off_dem === false) || (this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy <= this.props.lwdRecommendedByDem && this.state.is_notice_pay_wave_off_dem === false)) {
                    this.setState({
                        isApproveEarly: true,
                        isReject: false,
                        waveOff: false
                    })
                }
                else if ((this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy == this.state.managerRecommedLWD && this.state.is_notice_pay_wave_off_dem === true) || (this.props.isBHRApprovalActive === true && this.props.lwdAsPerPolicy <= this.props.lwdRecommendedByDem && this.state.is_notice_pay_wave_off_dem === false)) {
                    this.setState({
                        isApproveEarly: true,
                        isReject: false,
                        waveOff: false
                    })
                }
                else {
                    this.bhrApproval();
                }
            }
        }
        else {
            if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
                this.demApproval();
            }
            else if (this.props.isBHRApprovalActive === true) {
                this.bhrApproval();
            }
        }
    }

    approvalAPIFunctionCall = () => {
        if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
            this.demApproval();
        } else if (this.props.isBHRApprovalActive === true) {
            this.bhrApproval();
        } else if (this.props.isBUHRApprovalActive === true) {
            this.buhrApproval();
        }
    }

    rejectRequestCall = () => {
        this.setState({
            isApproveEarly: false,
            isManagerApprovedPopup: false,
            isReject: false
        })
        if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
            this.demApproval();
        } else if (this.props.isBHRApprovalActive === true) {
            this.bhrApproval();
        } else if (this.props.isBUHRApprovalActive === true) {
            this.buhrApproval();
        }
    }

    validateReject = () => {
        if (this.state.reasonValue === 'Select' && this.state.reasonCode === '') {
            toast.error('Please select reason', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.rejectRemarks === '') {
            toast.error('Please enter rejection remark', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.rejectRemarks.length < 100) {
            toast.error('Please enter 100 min characters in remarks', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.rejectRequestCall()
        }
    }

    validateWaveOff = () => {
        if (this.state.listType === true) {
            if (this.state.listType === true && this.state.waveOffJustification === '') {
                toast.error('Please enter the justification', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else if (this.state.waveOffJustification.length < 300) {
                toast.error('Please enter 300 min characters in justification', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            } else {
                if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
                    this.demApproval();
                } else if (this.props.isBHRApprovalActive === true) {
                    this.bhrApproval();
                } else if (this.props.isBUHRApprovalActive === true) {
                    this.buhrApproval();
                }
            }
        } else {
            if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
                this.demApproval();
            } else if (this.props.isBHRApprovalActive === true) {
                this.bhrApproval();
            } else if (this.props.isBUHRApprovalActive === true) {
                this.buhrApproval();
            }
        }
    }

    lengthCalculate = (value) => {
        let length = value ? value.length : 0
        return length
    }

    documentDownload = (attachmentUrl) => {
        commonDownloadFile(attachmentUrl, successDownload)
    }

    render() {
        const { isReject, isApproveEarly, noticePeriod, waveOff, radioFilters } = this.state
        const { onDismiss, noticeSeriodShortfallDays, lwdJustificationDem, lwdJustificationBhr, bhrName, demName, waveOffJustificationBhr, waveOffJustificationDem, lwdRecommendedByBhr, lwdRecommendedByDem, isEsepApprovalActive, isManagerApprovalActive, isNoticePayWaveOffDem, isNoticePayWaveOffBhr, noticePayRecoveryAmount, isBHRApprovalActive, isBUHRApprovalActive, lwdJustificationEmployee, noticePeriodDays, lwdAsPerPolicy, createdBy, employeeSeparationReason, actualResignationDate, employeeName, employeeSeparationRemark, lwdRequestedByEmployee, resignationRequestDate, separationRequestId, attachmentUrl, demApprovalRemark, bhrApprovalRemark } = this.props
        return (
            <div>
                <div className="modal popup-box">
                    <LoaderIndicator loading={this.state.loading} />
                    <div
                        className=
                        {isReject || isApproveEarly || waveOff ?
                            'popupCard-box p-0 w-33'
                            :
                            'popupCard-box p-0 w-70'}>
                        {/* -------------------------Model header Title---------------------------- */}
                        <div className="modal-header">
                            {isReject || isApproveEarly || waveOff ?
                                <>
                                    {waveOff &&
                                        <h4 className="modal_title black">{ApprovalConfirmation}</h4>}
                                    {isApproveEarly &&
                                        <h4 className="modal_title black">{ApprovalConfirmation}</h4>
                                    }
                                    {isReject &&
                                        <h4 className="modal_title black">{RejectionConfirmation}</h4>}
                                </>
                                :
                                <h4 className="modal_title black">{ManagerViewTitle}</h4>}
                            {/* -------------------------Model header Dismiss button---------------------------- */}
                            {isReject || isApproveEarly || waveOff ?
                                <>
                                    {waveOff &&
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.backStageClick()}>&times;</button>}
                                    {isApproveEarly &&
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.backClick()}>&times;</button>}
                                    {isReject &&
                                        <button type="button" className="close" data-dismiss="modal" onClick={() => this.backClick()}>&times;</button>}
                                </>
                                :
                                <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>}
                        </div>
                        {/* -------------------------Model Body---------------------------- */}
                        {isReject || isApproveEarly || waveOff ?
                            <>
                                {waveOff &&
                                    <>
                                        <div className="modal-body">
                                            <div className='row mt-2'>
                                                <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'Notice Pay Waiver User Manual: '}</div>
                                                <a href={NoticePayWaveOffPolicy} target="_blank"><label className='tata-policy-link mr-4 mt-10_2px' type='button'>{'Notice Pay Waiver User Manual'}</label></a>
                                            </div>
                                            <div className='mt-2'>
                                                <div className='p-heading mx-2 mt-2 ml-3'>{this.props.isBHRApprovalActive ? ManagerWaveOffApproval : WaveOffApproval}</div>
                                            </div>
                                            <div className='p-3'>
                                                {radioFilters.length != 0 && radioFilters.map((list, key) => {
                                                    return (
                                                        <div key={key} className='mx-4 mt-1 mb-2 '>
                                                            {this.state.checked === key ?
                                                                <div onClick={() => this.onPresentType1(key, list)}>
                                                                    <img alt="radioOn" className='radio' src={radioOn} />
                                                                    <label className="radioTxt" >{list.type}</label>
                                                                </div>
                                                                :
                                                                <div onClick={() => this.onPresentType1(key, list)}>
                                                                    <img alt="radioOff" className='radio' src={radioOff} />
                                                                    <label className="radioTxt">{list.type}</label>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                }
                                                {this.state.listType === true &&
                                                    <div className='mt-2 mb-10'>
                                                        <div className='p-sub-heading ml-3 required'>{'Notice Pay Waiver Justification?'}</div>
                                                        <textarea
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            maxLength={500}
                                                            onChange={(e) => this.setState({ waveOffJustification: e.target.value })}
                                                            value={this.state.waveOffJustification}
                                                            placeholder={"Enter the justification min 300 characters"}
                                                        >
                                                        </textarea>
                                                        <div className="justify-content-start time-text ml-3 mt-2">
                                                            {this.lengthCalculate(this.state.waveOffJustification)}/500
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <>
                                    {isApproveEarly &&
                                        <>
                                            <div className="modal-body">
                                                <div className='p-3 mb-10'>
                                                    <div className='p-sub-heading ml-3'>{'Approvals Remarks'}</div>
                                                    <textarea
                                                        className="form-control esep-placeholder-editable px-3"
                                                        type="text"
                                                        maxLength={500}
                                                        onChange={(e) => this.setState({ remarks: e.target.value })}
                                                        value={this.state.remarks}
                                                        placeholder={"Enter remarks here.."}
                                                    >
                                                    </textarea>
                                                    <div className="justify-content-start time-text ml-3 mt-2">
                                                        {this.lengthCalculate(this.state.remarks)}/500
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                                {isReject &&
                                    <>
                                        <div className="modal-body">
                                            <div className='mt-2'>
                                                <div className='p-heading mx-2 mt-2 ml-3'>{this.props.isBUHRApprovalActive ? BUHRRejectRequest : RejectRequest}</div>
                                            </div>
                                            <div className='p-3'>
                                                <div className='mt-2'>
                                                    <div className='p-sub-heading ml-3 required'>{'Rejection Reason'}</div>
                                                    <Dropdown
                                                        data={this.state.managerRejectReason}
                                                        selectedValue={(data) => this.changeRejectionReason(data)}
                                                        value={this.state.reasonValue}
                                                        render={'value'}
                                                        height={'NotificationHeight'}
                                                    />
                                                </div>
                                                <div className='mt-2 mb-10'>
                                                    <div className='p-sub-heading ml-3 required'>{'Rejection Remarks'}</div>
                                                    <textarea
                                                        className="form-control esep-placeholder-editable px-3"
                                                        type="text"
                                                        maxLength={500}
                                                        onChange={(e) => this.setState({ rejectRemarks: e.target.value })}
                                                        value={this.state.rejectRemarks}
                                                        placeholder={"Enter remarks min 100 characters"}
                                                    >
                                                    </textarea>
                                                    <div className="justify-content-start time-text ml-3 mt-2">
                                                        {this.lengthCalculate(this.state.rejectRemarks)}/500
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                            </>
                            :
                            <>
                                <div className="modal-body scroll-y h-58vh">
                                    <div className='p-2'>
                                        <>
                                            {/* <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'Employee Details'}</div> */}
                                            <div className='row mb-2 mt-1'>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Esep Request ID'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={separationRequestId ? separationRequestId : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Employee ID'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={createdBy ? createdBy : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Employee Name'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={employeeName ? employeeName : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Esep Request Date'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={moment(resignationRequestDate ? resignationRequestDate : '-').format("DD-MM-YYYY")}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Actual Date of Resignation'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={moment(actualResignationDate ? actualResignationDate : '-').format("DD-MM-YYYY")}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Last Working Date as Per Policy'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text" value={moment(lwdAsPerPolicy ? lwdAsPerPolicy : '-').format("DD-MM-YYYY")}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'LWD Requested by Employee'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={moment(lwdRequestedByEmployee ? lwdRequestedByEmployee : '-').format("DD-MM-YYYY")}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Period Days'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={noticePeriodDays ? noticePeriodDays : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                {(!isEsepApprovalActive || !isManagerApprovalActive) &&
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                        <div className='p-sub-heading ml-3'>{'Employee Resignation Reason'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <input
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={employeeSeparationReason ? employeeSeparationReason : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {noticeSeriodShortfallDays === 0 ?
                                                    null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                        <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Period Shortfall Days'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <input
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={noticeSeriodShortfallDays ? noticeSeriodShortfallDays : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {noticePayRecoveryAmount === 0.0 ? null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                        <div className='row d-flex align-items-end'>
                                                            <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery Amount (INR)'}</div>

                                                            <div data-tooltip2={'Note: Notice Recovery Amount =  ( Gap to notice period / 30 )* Monthly TFP'} className="infoSalary infoIconStyle ml-1" >
                                                                <img alt="info"
                                                                    src={infoicon}
                                                                    className="infosize"
                                                                ></img>
                                                            </div>
                                                        </div>

                                                        <div className='esep-placeholder-editable'>
                                                            <input
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={noticePayRecoveryAmount ? noticePayRecoveryAmount : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {employeeSeparationRemark === null ?
                                                    null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'Employee Resignation Remarks'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={employeeSeparationRemark ? employeeSeparationRemark : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {lwdJustificationEmployee === null ?
                                                    null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'Employee Early Release Justification'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={lwdJustificationEmployee ? lwdJustificationEmployee : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                            </div>
                                        </>
                                        <>
                                            <hr />
                                            <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'Manager Approval Details'}</div>
                                            <div className='row mb-2 mt-1'>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'Manager Name'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={demName ? demName : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                {lwdRecommendedByDem === null ? null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                        <div className='p-sub-heading ml-3'>{'LWD Recommended By Manager'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <input
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={moment(lwdRecommendedByDem ? lwdRecommendedByDem : '-').format("DD-MM-YYYY")}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {lwdJustificationDem === null ? null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'LWD Justification By Manager'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={lwdJustificationDem ? lwdJustificationDem : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {demApprovalRemark === null ? null :
                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'Manager Approval Remarks'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={demApprovalRemark ? demApprovalRemark : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {lwdJustificationDem === null ?
                                                    null :
                                                    <>
                                                        {lwdJustificationDem !== null && isNoticePayWaveOffDem === false ?
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by Manager'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={'No'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by Manager'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={'Yes'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {isBHRApprovalActive === true && isNoticePayWaveOffDem === true || isBUHRApprovalActive === true && isNoticePayWaveOffDem === true ?
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'Notice Pay waive off Justification By Manager'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={waveOffJustificationDem ? waveOffJustificationDem : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </>
                                        <>
                                            <hr />
                                            <div className='document-innercard-content-title mx-2 mt-2 ml-3'>{'BHR Approval Details'}</div>
                                            <div className='row mb-2 mt-1'>
                                                <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                    <div className='p-sub-heading ml-3'>{'BHR Name'}</div>
                                                    <div className='esep-placeholder-editable'>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="text"
                                                            value={bhrName ? bhrName : '-'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                {(isBUHRApprovalActive) && lwdRecommendedByBhr !== null ?
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12'>
                                                        <div className='p-sub-heading ml-3'>{'LWD Recommended By BHR'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <input
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={moment(lwdRecommendedByBhr ? lwdRecommendedByBhr : '-').format("DD-MM-YYYY")}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div> : null}
                                                {lwdJustificationBhr === null ? null :
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'LWD Justification By BHR'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={lwdJustificationBhr ? lwdJustificationBhr : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {bhrApprovalRemark === null ? null :
                                                    <div className='col-md-4 col-sm-6 col-12 mt-2 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'BHR Approval Remarks'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={bhrApprovalRemark ? bhrApprovalRemark : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div>}
                                                {lwdJustificationBhr === null ?
                                                    null :
                                                    <>
                                                        {lwdJustificationBhr !== null && isNoticePayWaveOffBhr === false ?
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by BHR'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={'No'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='col-md-4 col-sm-6 col-12 mt-2'>
                                                                <div className='p-sub-heading ml-3 purple-color font-weight-bold'>{'Notice Pay Recovery waive off by BHR'}</div>
                                                                <div className='esep-placeholder-editable'>
                                                                    <input
                                                                        className="form-control esep-placeholder-editable px-3"
                                                                        type="text"
                                                                        value={'Yes'}
                                                                        readOnly={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                {isBUHRApprovalActive === true && isNoticePayWaveOffBhr === true ?
                                                    <div className='mt-2 col-md-4 col-sm-6 col-12 mb-10'>
                                                        <div className='p-sub-heading ml-3'>{'Notice Pay waive off Justification By BHR'}</div>
                                                        <div className='esep-placeholder-editable'>
                                                            <textarea
                                                                className="form-control esep-placeholder-editable px-3"
                                                                type="text"
                                                                value={waveOffJustificationBhr ? waveOffJustificationBhr : '-'}
                                                                readOnly={true}
                                                            />
                                                        </div>
                                                    </div> : null}
                                            </div>
                                        </>
                                    </div>
                                    {/* --------------------------Manager Early Release Popup Case Handled-------------------------- */}
                                    {this.props.isBUHRApprovalActive === true ?
                                        null :
                                        <>
                                            <hr />
                                            <div className='document-innercard-content-title mx-2 mt-2 ml-4'>{this.props.isBHRApprovalActive ? 'BHR Approval' : 'Manager Approval'}</div>
                                            <div className='viewForm-grid-popups p-2 mb-1'>
                                                <><div className='addFormFirst ml-2'>
                                                    <div className=''>
                                                        <div className='p-sub-heading ml-3 required'>{this.props.isBHRApprovalActive ? 'BHR Recommended LWD' : 'Manager Recommended LWD'}</div>
                                                        <input
                                                            className="form-control esep-placeholder-editable px-3"
                                                            type="date"
                                                            min={moment(this.state.actualLWDDate).format("YYYY-MM-DD")} //From date 
                                                            max={moment(this.props.lwdAsPerPolicy).format("YYYY-MM-DD")} //To date
                                                            value={moment(this.state.managerRecommedLWD).format("YYYY-MM-DD")}
                                                            onChange={(e) => this.handleChangeRequestedDate(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                    {noticePeriod &&
                                                        <div className='addFormSecond ml-2'>
                                                            <div className='mb-10'>
                                                                <div className='p-sub-heading ml-3 required'>{'Reduced Notice Period Justification?'}</div>
                                                                <textarea
                                                                    className="form-control esep-placeholder-editable px-3"
                                                                    type="text"
                                                                    maxLength={500}
                                                                    onChange={(e) => this.setState({ managerJustification1: e.target.value })}
                                                                    value={this.state.managerJustification1}
                                                                    placeholder={this.props.isBHRApprovalActive ? CharLimit150 : CharLimit300}
                                                                >
                                                                </textarea>
                                                                <div className="justify-content-start time-text ml-3 mt-2">
                                                                    {this.lengthCalculate(this.state.managerJustification1)}/500
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            </div>
                                        </>}

                                    <div className='mb-2 doc-view'>
                                        <hr />
                                        <div className='document-innercard-content-title mx-2 mt-2 ml-4'>{'Employee Document'}</div>
                                        <div className="d-flex justify-content-between align-item-center documents_cards MW-30-100 mx-3">
                                            <div className='flex flex-direction-row align-item-center'>
                                                <div className='mx-3'>
                                                    <img alt="pdf" src={pdf} className="w-36px h-36px"></img>
                                                </div>
                                                <div className='flex-direction-column'>
                                                    <div className='document-innercard-content-title common-text-color'>{'e-Separation Document'}</div>
                                                    <div className='document-innercard-content-subtitle light-common-label-color'></div>
                                                </div>
                                            </div>
                                            <div className='align-item-center pointer'
                                                onClick={() => this.documentDownload(attachmentUrl)}>
                                                <img alt="download" src={download} className="w-46px h-46px"></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {/* -------------------------Model Buttons---------------------------- */}
                        <div className='modal-footer btnBox1'>
                            {isReject || isApproveEarly || waveOff ?
                                <>
                                    {waveOff &&
                                        <>
                                            <input type='button' value='BACK' className='cancelBtn' onClick={() => this.backStageClick()} />
                                            <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.validateWaveOff()} />
                                        </>}
                                    {isApproveEarly &&
                                        <>
                                            <input type='button' value='BACK' className='cancelBtn' onClick={() => this.backClick()} />
                                            {((isEsepApprovalActive || isManagerApprovalActive) && this.props.lwdAsPerPolicy <= this.state.managerRecommedLWD) || isBUHRApprovalActive || (isBHRApprovalActive && this.state.is_notice_pay_wave_off_dem === false) || (isBHRApprovalActive && this.props.lwdAsPerPolicy <= lwdRecommendedByDem && this.state.is_notice_pay_wave_off_dem === false) || (isBHRApprovalActive && this.props.lwdAsPerPolicy == this.state.managerRecommedLWD && this.state.is_notice_pay_wave_off_dem === true) ?
                                                <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.approvalAPIFunctionCall()} /> :
                                                <input type='button' value='SUBMIT' className='ml-2 validateBtn' onClick={() => this.validate()} />}
                                        </>}
                                    {isReject &&
                                        <>
                                            <input type='button' value='CANCEL' className='cancelBtn' onClick={() => this.backClick()} />
                                            <input type='button' value='REJECT' className='ml-2 validateBtn' onClick={() => this.validateReject()} />
                                        </>}
                                </>
                                :
                                <>
                                    <input type='button' value='REJECT' className='cancelBtn' onClick={() => this.submitApproval('Rejected')} />
                                    {isBUHRApprovalActive ?
                                        <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => this.validateBHUR('Approved')} /> :
                                        <input type='button' value='APPROVE' className='ml-2 validateBtn' onClick={() => this.submitApproval('Approved')} />}
                                </>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(ApprovalPopups);