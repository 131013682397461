import React, { Component } from 'react';
// import nodata from '../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg';
import enabledisable from '../../assets/drishti_images/nodatafound/enabledisableicon.svg';
import comingSoonIMG from '../../assets/drishti_images/comingSoonIMG.png';
import { configStore } from '../../redux/ConfigStore';
class ServiceUnavailable extends Component {
    render() {
        const companyName = configStore.getState().loginState.company.company_sht_txt
        console.log('companyName service screen---->', companyName);
        return (
            // <div className='flex-direction-column'>
            //     <img
            //         alt="nodata"
            //         src={nodata}
            //         className="mb-2"
            //     ></img>
            //     <h5>{this.props.reason}</h5>
            // </div>
            <div className='row'>
                <div className='col-md-12 d-flex justify-content-center align-items-center flex-column mt-3'>
                    <div>
                        {companyName === 'TMLBSL' ?
                            <img src={comingSoonIMG} alt="Nodata" className='noDataApproval' />
                            :
                            <img src={enabledisable} alt="Nodata" className='noDataApproval light-grey' />
                        }
                    </div>
                    {companyName === 'TMLBSL' ?
                        null :
                        <div className='d-flex justify-content-center'>
                            <label className={this.props.width ? 'card-content-title light-grey w-50 text-align-center' : 'card-content-title light-grey'}>{this.props.reason}</label>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ServiceUnavailable;