import React, { Component } from 'react';
import '../landing/admin.css';
import Engagement from './Engagement';
import ContinuousFeedbackManagement from './ContinuousFeedbackManagement';
import LeaveSummary from './LeaveSummary';
import Notification from './Notification';
import ToDoList from './ToDoList';
import TransferApproval from './TransferApproval';
import WelcomePage from './WelcomePage';
import * as AdminLoginAction from '../../adminScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
class AdminLanding extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='pt-12px px-12px'>
                {this.props.adminLoginData.adminRoles.some(str => str.includes('chro')) ?
                    <div className="admin-grid">
                        <div className="engagement">
                            <Engagement />
                        </div>
                        <div className="leaveSummary">
                            <LeaveSummary />
                        </div>
                        <div className="transferApproval">
                            <TransferApproval />
                        </div>
                        <div className="cfm">
                            <ContinuousFeedbackManagement />
                        </div>
                        <div className="notification">
                            <Notification />
                        </div>
                        <div className="todoList">
                            <ToDoList />
                        </div>
                    </div>
                    :
                    <div>
                        <WelcomePage />
                    </div>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(AdminLanding));