import React, { Component } from 'react'
import overview_button from '../../../../assets/drishti_images/overview_button.svg';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import './announcementNotificationStyle.css';
import cfm from '../../../../assets/drishti_images/notification/cfm.svg';
import todo from '../../../../assets/drishti_images/notification/todo.svg';
import transfer from '../../../../assets/drishti_images/notification/transfer.svg';
import calender_Notify from '../../../../assets/drishti_images/notification/calender-Notify.svg';
import notificationIcon from '../../../../assets/drishti_images/sidebar/admin-notification-inactive.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import DataNotFound from '../../../shared/notFound/DataNotFound';
import { dateFromNow } from '../../../../utils/Utils'
import memo from '../../../../assets/drishti_images/notification/memo.svg'
import moments_icon from "../../../../assets/drishti_images/momentsFeed/MTMHeader.png";

class AnnouncementNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            notificationData: [],
            notificationFilterType: [],
            approval_type: [],
            feedback_type: [],
            other_type: [],
            read_type: [],
            page_number: 1,
            selectitem: 'All',
            isDataPresent: false
        }
    }

    componentDidMount() {
        this.getNotifications(this.props.adminLoginData.emp_id)
    }

    getNotifications(employeeId) {
        this.setState({
            loading: true,
            announcement_type: this.state.selectitem,
            page_number: this.state.page_number,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            announcement_type: this.state.selectitem,
            employee_id: employeeId,
            page_number: this.state.page_number
        }
        fetchApiCall(Config.get_announcement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        notificationData: this.state.notificationData.concat(res.data.data),
                        isDataPresent: true,
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        notificationData: [],
                        isDataPresent: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    changeScreen = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home');
    }

    render() {
        return (
            <div>
                {this.state.loading === true &&
                    <LoaderIndicator loading={this.state.loading} />
                }
                <div>
                    <img alt='' src={overview_button} onClick={this.changeScreen} style={{ cursor: 'pointer' }} />
                </div>
                <div className='row col-md-12'>
                    {/* <div className='common-card col-md-10 mr-3 mt-3 px-12px ' style={{ height: '92vh' }}> */}
                    <div className='common-card col-md-12 mr-3 mt-3 px-12px notificationCardHeight'>
                        <div className='row m-1 p-10'>
                            All Notification
                        </div>
                        <hr />
                        {this.state.notificationData.length === 0 ?
                            <DataNotFound />
                            :
                            <div className='notification-scroller'>
                                {
                                    this.state.notificationData.map((listValue, id) =>
                                        <div key={id}>
                                            <div className='cardnotification col-md mt-3 '>
                                                <div className='flex-direction-row justify-content-between align-item-center m-3'>
                                                    <div className='flex-direction-row justify-content-start'>
                                                        {listValue.announcement_title.includes('Task') ?
                                                            <div className="notification-circle-shape bg-dark-green">
                                                                <img src={todo} alt='' className='notification-emp_circle_text notification-Icons' />
                                                            </div>
                                                            : listValue.announcement_title.includes('Leave') ?
                                                                <div className="notification-circle-shape bg-purple">
                                                                    <img src={calender_Notify} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                    {/* <label className='notification-emp_circle_text'>{this.short_name(this.props.loginData.userData.CompName)}</label> */}
                                                                </div>
                                                                : listValue.announcement_title.includes('feedback') ?
                                                                    <div className="notification-circle-shape bg-orange">
                                                                        <img src={cfm} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                    </div>
                                                                    :
                                                                    <div className="notification-circle-shape bg-puple">
                                                                        <img src={notificationIcon} alt='' className='notification-emp_circle_text notification-Icons' />
                                                                    </div>
                                                        }

                                                        <div className='flex-direction-column'>
                                                            <div className='text19 common-text-color'>
                                                                {listValue.announcement_title}
                                                            </div>
                                                            <div className='text20 black-tag'>
                                                                {listValue.announcement_description}
                                                            </div>
                                                            {/* <div className='align-item-center row'>
                                                            <div><img src={task_calender} /></div>
                                                            <div className='text18 common-text-color '> {moment(listValue.created_datetime).format('DD-MM-YYYY')}</div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className='text18 common-text-color'>
                                                        {dateFromNow(listValue.created_datetime)}
                                                        {/* {moment(listValue.created_datetime).format('DD/MM/YYYY')} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        }
                    </div>
                </div>
            </div>
            // <div>comming soon</div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    }
}
export default connect(mapStatesToProps)(AnnouncementNotification);