import React, { Component } from 'react';
import { GEMSApplicantForm } from '../../../ScreenType/employeeScreens/gems/ApplicantForm';
import { PGApproveForm } from '../../../ScreenType/employeeScreens/gems/PGApproveForm';
import { GEMSProjectForm } from '../../../ScreenType/employeeScreens/gems/ProjectForm';
import GEMSApprovals from '../../../ScreenType/employeeScreens/gems/ApprovalForm'
import './FormPopup.css';
import SuccessButton from '../commonButton/SuccessButton';
import CancelButton from '../commonButton/CancelButton';
import { gemsProjectForm, projectFormConstant, constTypes, warnings } from '../../../ScreenType/employeeScreens/gems/Constant';
import MTRFRPopup from './MTRFRPopup';
import StartProject from '../../../ScreenType/employeeScreens/gems/StartProject';
import { toaster } from '../../../../utils/Utils';
class FormPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectAreaValue: '',
            OUValue: '',
            mngName: "",
            projectDuration: "",
            selectedStartDate: '',
            selectedEndDate: '',
            selectedFRDate: "",
            selectedMTRDate: "",
            projectDescription: '',
            category: '',
            noOfPeople: '',
            pgLocation: '',
            bhrName: '',
            isCVFileDisable: false,
            isfirstCheck: false,
            isSecondCheck: false,
            page: 1,
            targetDeliverable: [],
            isEditable: false,
            actionType: "",
            warning: false,
            pGName: "",
            projectTitle: "",
            openPopup: true,

            location: '',
            projectObjective: '',
            sharingData: this.props.showPendingData ? this.props.showPendingData : '',
            enable: false,
            startProjectKey: false,
            startProjectData: null,
            indSelectedMTRData: null,
            UpdateObjKey: null,
            mtrObjectivesPopup: true,
        }
    }

    // submitAddProjectDetails = (data, state) => {
    //     this.props.submitAddProject(this.state, this.state.targetDeliverable)
    // }

    submitProject = () => {
        this.props.onDismiss()
        this.props.callAPI(true)
    }

    requestValidate = (event) => {
        event.preventDefault();
        // this.submitAddProjectDetails()
    }

    fileUpload = (event) => {
        this.setState({
            isCVFileDisable: true
        })
        this.props.onFileChange(event)
    }

    checkDeclaration = (event) => {
        this.setState({
            isfirstCheck: event
        })
    }

    checkDeclaration2 = (event) => {
        this.setState({
            isSecondCheck: event
        })
    }

    passParamToWarningPopup = (message) => {
        this.props.message(message)
        this.props.closePopUp(true)
        this.props.actionType(this.state.actionType)
    }

    passParamToProject = (value) => {
        this.props.onDismiss()
        if (value === constTypes.startPrj) {
            this.props.statePass(value)
            this.props.startProjectData(this.state.startProjectData, true)
        }
        else if (value === constTypes.startMTR) {
            this.props.startProjectData(this.state.startProjectData, true)
        }
        else if (value === constTypes.indMTR) {
            this.props.selectedIndMTR(this.state.indSelectedMTRData)
        }
        else if (value === constTypes.indFR) {
            this.props.selectedIndFR(this.state.indSelectedMTRData)
        }
        else if (value === constTypes.closeMTR) {
            console.log("CLOSE MTR CALL")
            this.props.reviewWarning(true)
            this.props.closeMTR(this.state.startProjectData)
            this.props.message(warnings.closeMTRMsg)
        }
        else if (value === constTypes.closeFR) {
            console.log("CLOSE FR CALL")
            this.props.reviewWarningForFinalReview(true)
            this.props.closeMTR(this.state.startProjectData)
            this.props.message(warnings.closeFRMsg)
        }
        else if (value !== constTypes.startMTR && value !== constTypes.startFTR) {
            this.props.statePass(value)
        }
        else if (value === constTypes.waitForMTR) {
            this.props.reviewWarning(false)
            this.props.statePass(value)
        }
        else if (value === constTypes.startFTR) {
            this.props.statePass(value)
            this.props.reviewWarning(true)
            this.props.message(warnings.submitMTR)
        }
        else {
            this.props.reviewWarning(false)
            this.props.statePass(value)
        }
    }

    passMTRFR = (data) => {
        this.setState({
            targetDeliverable: data
        }, () => this.props.updateMTRFR(this.state.targetDeliverable))
    }

    validateAddProject = (currStep) => {

        let formFields = {
            "projectTitle": true,
            "pGName": false,
            "mngName": false,
            "pgLocation": true,
            "bhrName": false,
            "projectAreaValue": true,
            "projectDuration": true,
            "noOfPeople": true,
            "selectedStartDate": true,
            "selectedEndDate": true,
            "selectedFRDate": true,
            "selectedMTRDate": true,
            "OUValue": true,
            "projectDescription": true,
            "category": true
        };

        if (currStep == 1) {
            let requiredFieldsAbsent = Object.keys(formFields).filter((field) => {
                console.log(field, this.state[field])
                return formFields[field] && !this.state[field]
            })
            if (requiredFieldsAbsent.length > 0) {
                toaster("warning", "Please fill all required fields");
            } else {
                this.setState({ page: 2 })
            }
        } else if (currStep == 2) {
            let incompleteObjective = this.state.targetDeliverable.find((objective) => {
                return !objective.deliverable || !objective.target
            })
            if (this.state.targetDeliverable?.length > 0 && !incompleteObjective) {
                this.submitProject()
            } else {
                toaster("warning", "Please fill all objective details");
            }
        }
        console.log("this.state: ", this.state)
    }

    render() {
        const approveType = this.props.type == "GEMSApprovePopUp"
        const pendingApproval = this.props.type == "GEMSApproval"
        const applicantType = this.props.type == "GEMSApplicant"
        const projectType = this.props.type == "GEMSProject"
        const mrtFR = this.props.type === "mrtFR"
        const approvalConfirmation = this.props.type === "approvalConfirmation"
        const pgViewDetails = this.props.type === "pgViewDetails"
        const pgMTRFRViewDetails = this.props.type === "pgMTRFRViewDetails"
        const startMTR = this.props.type === constTypes.startMTR
        const indMTR = this.props.type === constTypes.indMTR
        const startFTR = this.props.type === constTypes.startFTR
        const startPRJ = this.props.type === constTypes.startPrj
        const closeMTR = this.props.type === constTypes.closeMTR
        const closeFR = this.props.type === constTypes.closeFR
        const indFR = this.props.type === constTypes.indFR
        const { isEditable } = this.state
        return (
            <form className="modal popup-box" onSubmit={this.requestValidate}>
                <div className={`popupCard-box p-0 ${projectType || pendingApproval ? "w-62-percent" : approveType && this.props.actionValue === "view" ? "gems-approve-popup" : approveType && this.props.actionValue !== "view" ? "w-30" : mrtFR ? "w-62-percent" : startPRJ ? "w-50" : startMTR ? "w-40" : startFTR ? "w-40" : indMTR ? "w-40" : indFR ? "w-40" : "w-33 "}`} >
                    <div className="modal-header">
                        <h4 className="modal_title black">{projectType && this.state.page === 1 ? gemsProjectForm.projectDetails : projectType && this.state.page === 2 ? projectFormConstant.projectObj : approveType && this.props.actionValue !== "view" ? "Approval:" : approveType && this.props.actionValue === "view" ? "Download & Upload :" : approvalConfirmation ? "Approval Confirmation" : pgViewDetails ? "View Details" : pgMTRFRViewDetails ? "View Review Details" : startMTR ? "START MTR REVIEW" : startFTR ? "START FR REVIEW" : startPRJ ? "START PROJECT" : pendingApproval ? "Project Approval" : indMTR ? "Mid Term Review" : indFR ? "Final Review" : closeMTR ? "Close Mid Term Review" : closeFR ? "Close Final Review" : "Confirmation"}</h4>
                        <button type="button" className="close" data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;</button>
                    </div>
                    <div className={`modal-body ${applicantType ? "h-63-percent" : approveType && this.props.actionValue === "view" ? "h-60-percent" : approveType && this.props.actionValue !== "view" ? "h-28vh" : startPRJ ? "scroll-auto h-40vh" : indMTR ? "scroll-auto h-50vh" : indFR ? "scroll-auto h-50vh" : ""}`}>
                        {
                            projectType &&
                            <>
                                <GEMSProjectForm
                                    onsetPrjTltChange={(prjTlt) => this.setState({ projectTitle: prjTlt })}
                                    onPrjGuideNameChange={(prjGdName) => this.setState({ pGName: prjGdName })}
                                    onManagerNameChange={(mngName) => this.setState({ mngName: mngName })}
                                    onPGLocationChange={(lcn) => this.setState({ pgLocation: lcn })}
                                    onBHRNameChange={(bhr) => this.setState({ bhrName: bhr })}
                                    onProjectAreaValueChange={(pa) => this.setState({ projectAreaValue: pa })}
                                    onPrgDurationValueChange={(duration) => this.setState({ projectDuration: duration })}
                                    noOfPeopleChange={(noOfPlp) => this.setState({ noOfPeople: noOfPlp })}
                                    onSelectedStartDateChange={(sDate) => this.setState({ selectedStartDate: sDate })}
                                    onSelectedEndDateChange={(eDate) => this.setState({ selectedEndDate: eDate })}
                                    onSelectedFRDateChange={(eDate) => this.setState({ selectedFRDate: eDate })}
                                    onSelectedMTRDateChange={(eDate) => this.setState({ selectedMTRDate: eDate })}
                                    onOUValue={(oU) => this.setState({ OUValue: oU })}
                                    onProjectDescription={(pd) => this.setState({ projectDescription: pd })}
                                    onCategory={(cat) => this.setState({ category: cat })}
                                    onPageNumber={this.state.page}
                                    onAddTargetDeliverable={(data) => this.setState({ targetDeliverable: data }, this.props.submitAddProject(this.state, this.state.targetDeliverable))}
                                />
                            </>
                        }

                        {pendingApproval &&
                            <>
                                <GEMSApprovals
                                    sendData={this.state.sharingData ? this.state.sharingData : ''}
                                    mtrObjectivesPopup={this.state.mtrObjectivesPopup}
                                />
                            </>
                        }

                        {applicantType &&
                            <GEMSApplicantForm
                                fileUpload={this.fileUpload}
                                checkDeclaration={this.checkDeclaration}
                                checkDeclaration2={this.checkDeclaration2}
                                categoryType={this.props.categoryType}
                            />
                        }

                        {approveType &&
                            <PGApproveForm
                                reviewState={this.props.reviewState}
                                actionValue={this.props.actionValue}
                                fileUpload={this.fileUpload}
                            />
                        }

                        {mrtFR &&
                            <MTRFRPopup
                                id={this.props.id}
                                updateMTRFR={(data, UpdateObjKey) => this.setState({ UpdateObjKey: UpdateObjKey }, this.passMTRFR(data))}
                                isEditable={(data) => this.setState({ isEditable: data })}
                                isEditablePass={this.state.isEditable}
                            // submitAddProjectDetails={() => this.submitAddProjectDetails()}
                            />
                        }

                        {/* -----Approval popup by PG------- */}
                        {
                            (approvalConfirmation || pgViewDetails) && !this.state.warning &&
                            <PGApproveForm
                                projectId={this.props.projectId}
                                data={this.props.data}
                                actionType={this.state.warning}
                            />
                        }

                        {/* -----MTR/FR View Details popup by PG------- */}

                        {pgMTRFRViewDetails &&
                            <StartProject
                                data={this.props.data}
                                type={this.props.type}
                                currentPorjectId={this.props.currentPorjectId}
                            />
                        }

                        {(startPRJ || startMTR || indMTR || closeMTR || indFR || closeFR) && this.state.openPopup &&
                            <StartProject
                                data={this.props.data}
                                type={this.props.type}
                                mtrViewData={this.props.mtrViewData}
                                currentPorjectId={this.props.currentPorjectId}
                                passDetails={(data) => this.setState({ startProjectData: data })}
                                passDetailsInd={(data) => this.setState({ indSelectedMTRData: data })}
                                declaration={(data) => this.setState({ enable: data })}
                                enableStartBtn={(data) => this.setState({ startProjectKey: data })}
                            />
                        }
                    </div>

                    <div className='modal-footer btnBox'>
                        {applicantType ?
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={this.props.onDismiss}
                                />
                                {this.state.isCVFileDisable && this.state.isfirstCheck &&
                                    ((this.props.categoryType !== "Deputation") ||
                                        (this.props.categoryType === "Deputation" && this.state.isSecondCheck)) ?
                                    <SuccessButton
                                        type={'button'}
                                        value={'CONFIRM'}
                                        onClick={() => {
                                            this.props.onDismiss();
                                            this.props.approval("Confirm");
                                        }}
                                    />
                                    :
                                    <SuccessButton
                                        type={'button'}
                                        value={'CONFIRM'}
                                        isDisable={"disable"}
                                    />
                                }
                            </>
                            :
                            this.state.sharingData.status === "Initiated" ?
                                <>
                                    <CancelButton
                                        type={'button'}
                                        value={'REJECT'}
                                        onClick={() => {
                                            this.props.onDismiss();
                                            this.props.approveData("reject")
                                        }}
                                    />
                                    <SuccessButton
                                        type={'button'}
                                        value={'APPROVE'}
                                        onClick={() => {
                                            this.props.onDismiss();
                                            this.props.approveData("approve")
                                        }}
                                    />
                                </>
                                :
                                approveType && this.props.actionValue === "view" &&
                                <>
                                    <CancelButton
                                        type={'button'}
                                        value={'CANCEL'}
                                        onClick={this.props.onDismiss}
                                    />
                                    <SuccessButton
                                        type={'button'}
                                        value={'UPLOAD'}
                                        onClick={this.props.onDismiss}
                                    />
                                </>

                        }
                        {pendingApproval && this.state.sharingData.status !== "Initiated" &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}
                                />
                            </>
                        }
                        {projectType && this.state.page === 1 &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}
                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'NEXT'}
                                    onClick={() => this.validateAddProject(1)}
                                />
                            </>
                        }
                        {projectType && this.state.page === 2 &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'BACK'}
                                    onClick={() => this.setState({ page: 1 })}
                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    onClick={() => this.validateAddProject(2)}
                                />
                            </>
                        }
                        {mrtFR && !isEditable ?
                            <CancelButton
                                type={'button'}
                                value={'CLOSE'}
                                onClick={this.props.onDismiss}
                            />
                            :
                            mrtFR && isEditable &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={() => this.setState({ isEditable: false })}
                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    onClick={() => this.props.submit('submit', this.state.targetDeliverable)}
                                />
                            </>
                        }
                        {/* ------------PG approval---------------- */}
                        {approvalConfirmation &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'REJECT'}
                                    onClick={() => this.setState({
                                        actionType: "Rejected by PG", warning: true
                                    }, () => this.passParamToWarningPopup(warnings.approveRequest))}
                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SELECT'}
                                    onClick={() => this.setState({
                                        actionType: "Shortlisted by PG", warning: true
                                    }, () => this.passParamToWarningPopup(warnings.rejectRequest))}
                                />
                            </>
                        }
                        {(pgViewDetails || pgMTRFRViewDetails) &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}
                                />
                            </>
                        }
                        {
                            startPRJ &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}

                                />
                                {(this.state.enable && this.state.startProjectKey) ?
                                    <>
                                        <SuccessButton
                                            type={'button'}
                                            value={'START'}
                                            onClick={() => this.state.enable == true ? this.passParamToProject(constTypes.startPrj) : toaster("warning", "Please select start date and read declaration")}
                                        />
                                    </>
                                    :
                                    <>
                                        <SuccessButton
                                            type={'button'}
                                            value={'START'}
                                            isDisable={"disable"}
                                        />
                                    </>
                                }

                            </>
                        }
                        {startMTR &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}

                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'CONFIRM MTR'}
                                    onClick={() => this.passParamToProject(constTypes.startMTR)}
                                />
                            </>
                        }
                        {indMTR &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={this.props.onDismiss}

                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    onClick={() => this.passParamToProject(constTypes.indMTR)}
                                />
                            </>
                        }
                        {indFR &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={this.props.onDismiss}

                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    onClick={() => this.passParamToProject(constTypes.indFR)}
                                />
                            </>
                        }
                        {closeMTR &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={this.props.onDismiss}

                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    // need to work for validation check --->   // onClick={() => this.state.startProjectData.mtr_comments !== null ? this.passParamToProject(constTypes.closeMTR) : toaster("warning", "Please fill the input fields")}
                                    onClick={() => this.passParamToProject(constTypes.closeMTR)}
                                />
                            </>
                        }
                        {closeFR &&
                            <>
                                <CancelButton
                                    type={'button'}
                                    value={'CANCEL'}
                                    onClick={this.props.onDismiss}

                                />
                                <SuccessButton
                                    type={'button'}
                                    value={'SUBMIT'}
                                    onClick={() => this.passParamToProject(constTypes.closeFR)}
                                />
                            </>
                        }
                    </div>
                </div>
            </form >
        )
    }
}

export default FormPopup