import React, { Component } from 'react';
import { coi_type } from '../../../../utils/Utils';
import { configStore } from "../../../../redux/ConfigStore";
import * as COIAction from '../conflictOfInterest/COIAction';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
class Stage2ConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirm: false,
            isA1Checked: false,
            isA2Checked: false,
            isA3Checked: false,
            isSubmitProcess: false,
            coiTypeData: coi_type(),
        }
    }

    componentDidMount() {
        configStore.dispatch(COIAction.clearCOITypeData())
    }

    toggleTypeChange = (type) => {
        if (type === 'A1') {
            this.setState({ isA1Checked: !this.state.isA1Checked })
        }
        else if (type === 'A2') {
            this.setState({ isA2Checked: !this.state.isA2Checked })
        }
        else if (type === 'A3') {
            this.setState({ isA3Checked: !this.state.isA3Checked })
        }
        else return null
    }

    nextForm = () => {
        const { isA1Checked, isA2Checked, isA3Checked } = this.state
        if (!isA1Checked && !isA2Checked && !isA3Checked) {
            toast.error('Please select any form option first', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            if (isA1Checked) {
                configStore.dispatch(COIAction.getCOIType('A1'))
            }
            if (isA2Checked) {
                configStore.dispatch(COIAction.getCOIType('A2'))
            }
            if (isA3Checked) {
                configStore.dispatch(COIAction.getCOIType('A3'))
            }
            this.props.openRequestForm()
        }
    }

    render() {
        const { coiTypeData } = this.state
        return (
            <div className="modal popup-box">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content bg-white">
                        <div className="modal-header mx-2">
                            <h4 className="popup-title mt-2">Confirmation with Conflict of Interest Policy</h4>
                            <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            <div className='solid-border'></div>
                        </div>
                        <div className="row">
                            <div>
                                <div className='checkbox-text d-flex align-items-center mt-3'>
                                    <label class="checkBoxData ml-4">
                                        <input type="checkbox" className='hidecheckBox'
                                            checked={this.state.isA1Checked}
                                            onChange={() => this.toggleTypeChange('A1')}
                                        />
                                        <span class="markk"></span>
                                    </label>
                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                        <div className='checkbox-text'>Option {'A1'}</div>
                                    </div>
                                </div>
                                <div className='checkbox-sub-text mt-1 mr-2 ml-60px'>{coiTypeData[0].value + this.props.loginData.company.company_sht_txt + '.'}</div>

                                <div className='checkbox-text d-flex align-items-center mt-3'>
                                    <label class="checkBoxData ml-4">
                                        <input type="checkbox" className='hidecheckBox'
                                            checked={this.state.isA2Checked}
                                            onChange={() => this.toggleTypeChange('A2')}
                                        />
                                        <span class="markk"></span>
                                    </label>
                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                        <div className='checkbox-text'> Option {'A2'}</div>
                                    </div>
                                </div>
                                <div className='checkbox-sub-text mt-1 mr-2 ml-60px'>{coiTypeData[1].value}</div>

                                <div className='checkbox-text d-flex align-items-center mt-3'>
                                    <label class="checkBoxData ml-4">
                                        <input type="checkbox" className='hidecheckBox'
                                            checked={this.state.isA3Checked}
                                            onChange={() => this.toggleTypeChange('A3')}
                                        />
                                        <span class="markk"></span>
                                    </label>
                                    <div className='d-flex' style={{ flexDirection: 'column' }}>
                                        <div className='checkbox-text'>Option {'A3'}</div>
                                    </div>
                                </div>
                                <div className='checkbox-sub-text mt-1 mr-2 ml-60px'>{coiTypeData[2].value}</div>

                                <div className="popup-button-background justify-content-end align-item-center px-0 mt-3">
                                    <div className='justify-content-between mr-4'>
                                        <div
                                            type='button'
                                            className='popup-back-btn popup-back-text'
                                            onClick={() => this.props.back()}
                                        >Back</div>
                                        <div
                                            type='button'
                                            className='popup-confirm-btn'
                                            onClick={() => this.nextForm()}
                                        >Proceed</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(Stage2ConfirmPopup);