import React, { Component } from 'react';
import './HomeScreen.css';
import GEMSProjects from './Projects';
import GEMSApproval from './Approval';
import GEMSLandingScreen from './LandingScreen';
import rightArrow from '../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif';
import gemsLogo from '../../../../assets/drishti_images/header/gemsLogo.png';

const GEMSpolicy = `https://tatamotors.sharepoint.com/:w:/r/sites/c-app/s-MTMDev/_layouts/15/WopiFrame.aspx?sourcedoc={3e3701a5-4d5e-4e31-98d7-dc85d1ffb9de}&action=default`
const tab = [
    { tabName: 'GEMSApplicant', label: 'GEMS for Applicants' },
    { tabName: 'GEMSProjects', label: 'GEMS for Project Guides' },
    { tabName: 'GEMSApproval', label: 'GEMS for Project Approvers' },
]
class GemsHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: 'GEMSApplicant',
            gemsApplied: true,

            isApply: false,
        }
    }

    gotoHome = () => {
        console.log("go to home")
    }

    updateActiveTab = (tabName) => {
        this.setState({
            tabActive: tabName
        });
    }

    render() {
        return (
            <div>
                <div className={`document_card mt-3 p-3 ml-15px mb-24px col-md-12 w-97 ${(this.state.tabActive === "GEMSApplicant" && this.state.gemsApplied == true) ? "" : "h-95-percent"}`}>
                    <div className='overflow-x-auto d-flex flex-direction-row justify-content-between mt-10px mb-0px'>
                        <div className='row'>
                            {tab.map((tabNameMap, id) =>
                                <div className={this.state.tabActive == tabNameMap.tabName ? 'tab-active' : 'tab-inactive'}
                                    onClick={() => this.updateActiveTab(tabNameMap.tabName)} key={id}
                                >
                                    {tabNameMap.label}
                                </div>
                            )}
                        </div>
                        <div className='d-flex'>
                            <div>
                                <img
                                    src={rightArrow}
                                    alt="rightArrow"
                                    className="right-arrow-gif"
                                />
                                <a href={GEMSpolicy} target="_blank"><label className='tata-policy-link mb-1 ml-1' type='button'>{'GEMs Guideline Policy'}</label></a>
                            </div>
                            <div className='ml-3'>
                                <img
                                    src={gemsLogo}
                                    alt="rightArrow"
                                    className="h-36px mt-3px mr-1"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='hz-lines d-flex mx-10px mt-0px mb-0px'></div>
                    <div className='h-71vh'>
                        {this.state.tabActive === "GEMSApplicant" &&
                            <>
                                {this.state.gemsApplied &&
                                    <>
                                        <GEMSLandingScreen
                                            tabActive={this.state.tabActive}
                                            isApply={this.state.isApply}
                                            updateActiveTab={this.updateActiveTab} // Pass the function
                                        />
                                    </>
                                }
                            </>
                        }
                        {this.state.tabActive === "GEMSProjects" &&
                            <GEMSProjects
                                tabActive={this.state.tabActive} />
                        }
                        {this.state.tabActive === "GEMSApproval" &&
                            <GEMSApproval
                                tabActive={this.state.tabActive} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default GemsHomeScreen
