import React, { Component } from "react";
import "./MOMStyles.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { BASE_WEB_URL_DEV } from '../../../../../src/constants/Config';

const RecievedImgs = `Check all the reactions from your colleagues`;
const PopupHeadText1 = `Tata Motors`
const PopupHeadText2 = `wishes you a very`
class WishesCardPopup extends Component {
  constructor(props) {
    super(props);
  }

  goToMomentsThatMatter = (notification_type) => {
    localStorage.removeItem('is_popup_notification');
    const atobNotification_type = btoa(notification_type)
    window.location = BASE_WEB_URL_DEV + `momentsThatMatter?key=${atobNotification_type}`;
  };

  render() {
    let eventToShow = [];
    console.log("notification_type", this.props.notification_type)
    if (this.props.notification_type) {
      this.props.notification_type.forEach(event => {
        eventToShow.push(event)
      });
    }
    console.log('eventToShow', eventToShow)

    return (
      <>
        <div className="modal popup-box d-flex align-item-center justify-content-center" >
          <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable d-flex align-item-center justify-content-center'>
            <div className="modal-content mom_modal">
              <div className="justify-content-between pt-14px">
                <div className='w-100'>
                  <h5 className="text-align-center modal-title welcomepopuptitle pl-20px" id="exampleModalLongTitle">
                    <span className='purple-color mom-common-heading'>{PopupHeadText1}</span> <span className='mom-common-heading'>{PopupHeadText2}</span>
                  </h5>
                </div>
                <div>
                  <button type="button" className="close pr-18px" data-dismiss="modal" aria-label="Close" onClick={this.props.onDismiss}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
              <div className='welcomomepopupdescription'>
                <div>
                  <AliceCarousel autoPlay={eventToShow.length > 1 ? true : false} autoPlayInterval="2000" infinite={true} >
                    {eventToShow && eventToShow.map(el => {
                      return (
                        <>
                          <div className='mom_slider mt-1 mb-30'>
                            <p className="mom-wishText mom-common-wishText mb-1rem">{el.title}</p>
                            <img src={el.image_link} role="presentation" className='mom_slider_image' />
                          </div>
                          <div className='text-align-center mt-17px'>
                            <button
                              type="submit"
                              className='btn btn-primary login-btn'
                              value="View Wishes"
                              disabled={false}
                              onClick={() => this.goToMomentsThatMatter(el.notification_type)}
                            >View Reactions</button>
                          </div>
                        </>)
                    })}
                  </AliceCarousel>
                  <div className='text-align-center'>
                    <label className=" mom-common-heading ">
                      {RecievedImgs}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default WishesCardPopup;
