import React, { Component, useState } from 'react'
import editIcon from '../../../../assets/drishti_images/profile/edit.svg'
import { BASE_WEB_URL_DEV, get_registered_employee_details, headers } from '../../../../constants/Config';
import RegistrationForm from './RegistrationForm';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Nodatafound from '../../../../assets/drishti_images/nodatafound/Nodatafound.svg'
import { noRegisterdata } from '../../../../constants/Messages'
import { connect } from 'react-redux';
import Pagination from '@mui/material/Pagination';
import GoToPage from './goToPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';

class RegisteredEmployeeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatescreenView: false,
            updateData: [],
            count: [],
            page: 1,
            totalPage: 1,
            registeredDetails: [],
            registeredEmployeeDetails: [],
        }
    }

    passValueToEditForm = (data, screenType) => {
        this.setState({
            updatescreenView: true,
            updateData: data,
            registrationType: screenType
        })
    }

    calcPageChange = (event, value) => {
        this.props.parentCallback(value);
    }

    getRowNo = (id) => {
        return (id + 1) + (this.props.currentPage - 1) * 10
    }

    theme = createTheme({
        palette: {
            custom: {
                main: '#623AAA',
                darker: '#623AAA',
                contrastText: '#FFFFFF'
            }
        },
    });

    render() {
        const { updatescreenView } = this.state
        const { message, employeeRegisteredData } = this.props
        return (
            <>
                <div className='secondContainer historyTable-height justify-content-center-noflex'>
                    <LoaderIndicator loading={this.props.loading} />
                    {
                        employeeRegisteredData === undefined || employeeRegisteredData.length == 0 ?
                            <div className='text-align-center m-15px '>
                                <div className=''>
                                    <img src={Nodatafound} alt="no-data" className='' />
                                </div>
                                <div className='text-align-center'>
                                    <label className='card-content-title grey-color'>{message}</label>
                                </div>
                            </div>
                            :

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='w-5 text-align-center'><div className='thead pr-0'>Sr. No</div></th>
                                        <th scope="col" className='w-10 text-align-center'><div className='thead pr-0'>Employee ID</div></th>
                                        <th scope="col" className='w-10 text-align-center'><div className='thead pr-0'>Phone No</div></th>
                                        <th scope="col" className='w-20 text-align-center'><div className='thead pr-0'>Created by</div></th>
                                        <th scope="col" className='w-20 text-align-center'><div className='thead pr-0'>Modified by</div></th>
                                        <th scope="col" className='w-10 text-align-center'><div className='thead pr-0'>Status</div></th>
                                        <th scope="col" className='w-10 text-align-center'><div className='thead pr-0'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeRegisteredData.map((listValue, id) => <tr key={id}>
                                        <td scope="col" className='w-5 text-align-center'><div className='thead pr-0'>{this.getRowNo(id)}</div></td>
                                        <td scope="col" className='w-10 text-align-center'><div className='thead pr-0'>{listValue.employee_id}</div></td>
                                        <td scope="col" className='w-10 text-align-center'><div className='thead pr-0'>{listValue.mobile_number}</div></td>
                                        <td scope="col" className='w-20 text-align-center'><div className='thead pr-0'>{listValue.created_by}</div></td>
                                        <td scope="col" className='w-20 text-align-center'><div className='thead pr-0'>{listValue.modified_by}</div></td>
                                        <td scope="col" className='w-10 text-align-center'><div className='thead pr-0'>{listValue.is_active == true ? "Active" : "Inactive"}</div></td>
                                        <td scope="col" className='w-10 text-align-center'>
                                            <div className='thead pr-0'>
                                                <img src={editIcon} alt="edit" style={{ cursor: 'pointer' }} onClick={() => this.passValueToEditForm(this.props.employeeRegisteredData[id], 'updateDetails')} />
                                            </div>
                                        </td>
                                    </tr>
                                    )}
                                    <tr className='text-align-center'>
                                        <th colspan='7' scope="col" className='w-5 text-align-center btm-pagination'>
                                            <div className='flex-style-for-view-button'>
                                                <ThemeProvider theme={this.theme}>
                                                    <Pagination count={this.props.totalPage} color="custom" page={parseInt(this.props.currentPage)} onChange={this.calcPageChange} showFirstButton showLastButton />
                                                </ThemeProvider>
                                                {/* <GoToPage totalPage={parseInt(this.props.totalPage)} currentPage={this.props.currentPage} onChange={this.props.parentCallback} /> */}
                                            </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                    }
                </div>
                {
                    updatescreenView &&
                    <RegistrationForm
                        onDismiss={() => this.setState({ updatescreenView: false })}
                        registrationType={this.state.registrationType}
                        passUpdateData={this.state.updateData}
                        callgetEmployeeAPI={() => this.props.callgetEmployeeAPI()}
                    // callgetEmployeeAPI={()=> console.log("callgetEmployeeAPI from RegisteredEmployeeTable")}
                    />
                }
            </>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
    }
}

export default connect(mapStatesToProps)(RegisteredEmployeeTable)
