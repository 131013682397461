import React, { useContext, useEffect, useRef, useState } from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import DateFilter from './DateFilter'
import ClearanceTable from './ClearanceTable'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { Pagination } from 'react-bootstrap'
import { RoleContext } from './Contexts'
import { addDays, subtractDays } from '../../managerScreens/approval/commonUtils'
import { traineeRequestsCol } from './constants'
import { exportToExcel, toaster } from '../../../../utils/Utils'
import Axios from 'axios'
import { clearanceBulkTemplates } from '../../../../constants/Messages'
import * as XLS from "xlsx";

function ClearanceForTrainee(props) {
    const filterMaster = useContext(RoleContext)
    const [tableData, setTableData] = useState([])
    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")
    const [loading, setLoading] = useState(false)
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [viewFilter, setViewFilter] = useState(null)
    const [filterVal, setFilterVal] = useState({ id: 0, value: 0, title: "Pending" })
    const maxChunkSize = 10
    const initialRenderRef = useRef(true)

    useEffect(() => {
        if (initialRenderRef.current == true) {
            initialRenderRef.current = false
        } else {
            setCurrentPage(1)
            setTotalPages(1)
            TraineeRequests()
        }
    }, [filterVal, viewFilter, fromDate, toDate])

    useEffect(() => {
        setViewFilter(filterMaster.cleRequestViewFilter[0])
    }, [filterMaster])

    const handleViewFilter = (data) => {
        setViewFilter(data)
    }
    const handlePageCount = (action) => {
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            //   fetchClearanceRequests(filterVal.value)
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            //   fetchClearanceRequests(filterVal.value)
        }
    };
    const TraineeRequests = () => {
        setLoading(true)
        Axios.get('http://localhost:4000/traineeRequests').then(
            (res) => {
                setTableData(res.data)
            }
        ).catch((err) => { console.log("error in Get trainee list api", err); }
        ).finally(() => { setLoading(false) })
    }
    const bulkUploadApi = (bulkList) => {
        let chunks = Math.ceil(bulkList.length / maxChunkSize)
        console.log("chunks", chunks)
        for (let i = 0; i < chunks; i++) {
            let chunkList = i == 0 ? bulkList.slice(0, maxChunkSize) : bulkList.slice(i * maxChunkSize, (i * maxChunkSize) + maxChunkSize)
            console.log("chunkList", chunkList)
        }
    }
    const downloadTemplate = () => {
        if (tableData.length > 0) {
            let colNames = [clearanceBulkTemplates.colNames]
            let formattedData = tableData.map((data, idx) => {
                return {
                    "SR NO": idx + 1,
                    "Employee ID": data.employee.Perno,
                    "Recovery Amount (upto 2 decimals)": "",
                    "Payment Amount (upto 2 decimals)": "",
                    "Remark": ""
                }
            })
            exportToExcel(clearanceBulkTemplates.fileName, clearanceBulkTemplates.ext, formattedData, colNames)
        }
    }
    const bulkUpload = (event) => {
        console.log("event", event.target.files);
        let file = event?.target?.files?.[0];
        if (file) {
            console.log("file:", file.name)
            let fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                let arrayBuffer = fileReader.result;
                var data = new Uint8Array(arrayBuffer);
                // console.log("add file data:",data)
                var arr = new Array();
                for (var i = 0; i != data.length; ++i)
                    arr[i] = String.fromCharCode(data[i]);
                var bstr = arr.join("");
                var workbook = XLS.read(bstr, { type: "binary" });
                var first_sheet_name = workbook.SheetNames[0];
                var worksheet = workbook.Sheets[first_sheet_name];
                // console.log("final XLS:",XLS.utils.sheet_to_json(worksheet, { raw: true }));
                var arraylist = XLS.utils.sheet_to_json(worksheet, { raw: true });
                console.log("arraylist:", arraylist)
                let bulkClearanceList = arraylist.map((data) => {
                    return {
                        emp_id: data?.["Employee ID"] ?? null,
                        pay_amt: data?.["Payment Amount (upto 2 decimals)"] ?? null,
                        recovery_amt: data?.["Recovery Amount (upto 2 decimals)"] ?? null,
                        remark: data?.["Remark"] ?? null
                    }
                })
                console.log("bulk list", bulkClearanceList)
                bulkUploadApi(bulkClearanceList)
            }
        };

    }
    return (
        <div>
            <div className='flex-direction-column'>
                {/* ----------------------------------------------------------- filters ---------------------------------------------------------------------- */}
                <div className='row align-item-center justify-content-end background-highlighter'>
                    <div className='row'>
                        {
                            viewFilter &&
                            <div className='flex-direction-row gap-10px align-item-center'>
                                <div className='p-sub-heading ml-2'>{"Select Agency View"}</div>
                                <div className='d-flex align-item-center'></div>
                                <DynamicDropdown
                                    data={filterMaster.cleRequestViewFilter}
                                    render="title"
                                    value={viewFilter.title}
                                    selectedValue={(data) => handleViewFilter(data)}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                />
                            </div>
                        }
                        <DateFilter title="From Date" action={(val) => setFromDate(val)} value={fromDate} minDate={subtractDays(new Date(), filterMaster.dateRange.minDay)} maxDate={addDays(new Date(), 60)} />
                        <DateFilter title="To Date" action={(val) => setToDate(val)} value={toDate} minDate={subtractDays(new Date(), filterMaster.dateRange.maxDays)} maxDate={addDays(new Date(), 60)} />
                    </div>
                </div>

                {/* ------------------------------------------------------------ Table ----------------------------------------------------------------------- */}
                <div>
                    {
                        tableData && tableData.length > 0 ?
                            <ClearanceTable
                                data={tableData}
                                columns={traineeRequestsCol}
                                hasAction={false}
                                currentPage={currentPage}
                                pageSize={10}
                            />
                            :
                            <div className='mt-5 h-90-percent'>
                                <DataNotFound />
                            </div>
                    }
                    {tableData.length > 0 &&
                        <div className="col-md-12 w-100 d-flex justify-content-center paginator">
                            <Pagination>
                                {currentPage != 1 &&
                                    <>
                                        <Pagination.Prev
                                            onClick={() => { handlePageCount("decrement") }} />
                                        <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                                        <Pagination.Ellipsis disabled />
                                    </>
                                }
                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                {currentPage != totalPages &&
                                    <>
                                        <Pagination.Ellipsis disabled />
                                        <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                                        <Pagination.Next
                                            onClick={() => { handlePageCount("increment") }} />
                                    </>
                                }
                            </Pagination>
                        </div>
                    }
                </div>
            </div>

            {/* --------------------------------------------------------------- Bottom Buttons --------------------------------------------------------------- */}
            <div className="justify-content-end align-item-center pr-3 bottom-right pb-1rem">
                <input type='button' value={'DOWNLOAD TEMPLATE'} className='ml-2 validateBtn' onClick={downloadTemplate} />
                <label className='ml-2 validateBtn' onChange={bulkUpload} >
                    <input
                        id="Attachment"
                        style={{ display: "none" }}
                        type="file"
                        accept=".csv,.xls,.xlsx"
                    />
                    {"BULK UPLOAD"}
                </label>
            </div>
        </div>
    )
}

export default ClearanceForTrainee