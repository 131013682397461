import React, { Component } from 'react';
import '../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import { connect } from 'react-redux';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import NotificationDetailsPopup from './NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from './NotificationConfirmPopup';
import toggleon from '../../../../assets/drishti_images/profile/toggle-on.svg';
import toggleoff from '../../../../assets/drishti_images/profile/toggle-off.svg';
import edit from '../../../../assets/drishti_images/profile/edit.svg';
import ConfirmPopups from './ConfirmPopups';
import ScreenPopUpNotifications from './ScreenPopUpNotification';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
const current_date = new Date();
class ScreenPopupNotifications extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transferRequestData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            actionValue: '',
            isToggle: true,
            isActive: true,
            screenPopUpInactiveDashboard: [],
            show: false,
            requestData: [
                {
                    id: 1,
                    title: 'Active'
                },
                {
                    id: 2,
                    title: 'Inactive'
                }
            ],
            screenPopUpDashboard: [],
            data: [],
            selectedScreenData: {},
        }
    }

    componentDidMount() {
        // this.setState({ listType: 'pending' })
        // this.getAppliedTransferRequest('pending')
        this.getScreenPopupDashBoard()
        this.getDeactivetedPopupDashBoard()
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    activeImage = (data) => {
        // console.log("active images data ", data)
        this.setState({
            showConfirm: true,
            data_delete: data,
            // isToggle: !this.state.isToggle,
            // screenPopUpDashboard: data,
        })
    }

    deletePopupNotification = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            from_date: moment(this.state.data_delete.from_date).format('YYYY-MM-DD'),
            screen_type: this.state.data_delete.screen_type,
            to_date: moment(this.state.data_delete.to_date).format('YYYY-MM-DD'),
        }
        fetchApiCall(Config.deletePopupNotificationBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        showConfirm: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getScreenPopupDashBoard()
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getScreenPopupDashBoard = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            current_date: moment(current_date).format('YYYY-MM-DD'),
            is_admin: true
        }
        fetchApiCall(Config.getActivePopup, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        screenPopUpDashboard: res.data.data ? res.data.data : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        screenPopUpDashboard: [],
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    getDeactivetedPopupDashBoard = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            is_active: false
        }
        fetchApiCall(Config.getDeactivatedPopupNotifications, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        screenPopUpInactiveDashboard: res.data.inactive_notification ? res.data.inactive_notification : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        screenPopUpInactiveDashboard: [],
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    showScreenPopNotificationEdit = (dataValue) => {
        this.setState({
            show: true,
            selectedScreenData: dataValue
        })
    }

    toggle = (isActive) => {
        if (isActive === 'active') {
            this.setState({
                isActive: true
            })
        }
        else {
            this.setState({
                isActive: false
            })
        }
    }

    render() {
        const { showConfirm, showDetail, show } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='d-flex mt-1'>
                    <div className='status-dashboard mt-3 ml-4'>Status :</div>
                    {this.state.isActive === true ?
                        <div className='row align-item-center mt-2 ml-4'>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('active')}
                            >
                                <img alt="radioOn" className='radio ml-4' src={radioOn}
                                />
                                <label className="radioTxt ml-2" >Active</label>
                                <label className="radioTxt ml-1" >{('(' + this.state.screenPopUpDashboard.length + ')')}</label>
                            </div>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('inActive')}
                            >
                                <img alt="radioOff" className='radio ml-4' src={radioOff}
                                />
                                <label className="radioTxt ml-2">Inactive</label>
                                <label className="radioTxt ml-1">{('(' + this.state.screenPopUpInactiveDashboard.length + ')')}</label>
                            </div>
                        </div>
                        :
                        <div className='row align-item-center mt-2 ml-4'>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('active')}>
                                <img alt="radioOn" className='radio ml-4' src={radioOff}
                                />
                                <label className="radioTxt ml-2" >Active</label>
                                <label className="radioTxt ml-1" >{('(' + this.state.screenPopUpDashboard.length + ')')}</label>
                            </div>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('inActive')}>
                                <img alt="radioOff" className='radio ml-4' src={radioOn}
                                />
                                <label className="radioTxt ml-2">Inactive</label>
                                <label className="radioTxt ml-1">{('(' + this.state.screenPopUpInactiveDashboard.length + ')')}</label>
                            </div>
                        </div>
                    }
                </div>
                {this.state.isActive ?
                    <>
                        <div className="pushNotification-container pt-2" >
                            <div className='sm-scroll-table p-2'>
                                {this.state.screenPopUpDashboard.length != 0 ?
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Screen Type</div></th>
                                                <th scope="col"><div className='thead'>Note Title</div></th>
                                                <th scope="col"><div className='thead'>Note Type</div></th>
                                                <th scope="col"><div className='thead'>Note Content</div></th>
                                                <th scope="col"><div className='thead'>From Date</div></th>
                                                <th scope="col"><div className='thead'>To Date</div></th>
                                                <th scope="col"><div className='thead'>Active Status</div></th>
                                                <th scope="col"><div className='thead'>Edit</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.screenPopUpDashboard.map((listValue, index) => {
                                                return (
                                                    <tr key={index} className=''>
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td><div className="td">{listValue.screen_type ? listValue.screen_type : ''}</div></td>
                                                        <td><div className="td" style={{width: "120px"}}>{listValue.note_title ? listValue.note_title : ''}</div></td>
                                                        <td><div className="td">{listValue.note_type ? listValue.note_type : ''}</div></td>
                                                        <td><div className="td" style={{width: "179px"}}>{listValue.note_content ? listValue.note_content : '-'}</div></td>
                                                        <td><div className="td">{listValue.from_date ? moment(listValue.from_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">{listValue.to_date ? moment(listValue.to_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">
                                                            {this.state.isToggle ?
                                                                <img
                                                                    src={toggleon}
                                                                    alt=''
                                                                    onClick={() => this.activeImage(listValue)}
                                                                />
                                                                :
                                                                <img
                                                                    src={toggleoff}
                                                                    alt=''
                                                                    onClick={() => this.activeImage(listValue)}
                                                                />
                                                            }
                                                        </div></td>
                                                        <td>
                                                            <div className="td" >
                                                                <img
                                                                    src={edit}
                                                                    alt=''
                                                                    onClick={() => this.showScreenPopNotificationEdit(listValue)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No screen Pop-up notifications have been added yet!</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="pushNotification-container pt-2" >
                            <div className='sm-scroll-table p-2'>
                                {this.state.screenPopUpInactiveDashboard.length != 0 ?
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Screen Type</div></th>
                                                <th scope="col"><div className='thead'>Note Title</div></th>
                                                <th scope="col"><div className='thead'>Note Type</div></th>
                                                <th scope="col"><div className='thead'>Note Content</div></th>
                                                <th scope="col"><div className='thead'>From Date</div></th>
                                                <th scope="col"><div className='thead'>To Date</div></th>
                                                <th scope="col"><div className='thead'>Active Status</div></th>
                                                {/* <th scope="col"><div className='thead'>Edit</div></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.screenPopUpInactiveDashboard.map((listValue, index) => {
                                                return (
                                                    <tr key={index} className=''>
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td><div className="td">{listValue.screen_type ? listValue.screen_type : ''}</div></td>
                                                        <td><div className="td" style={{width: "120px"}}>{listValue.note_title ? listValue.note_title : ''}</div></td>
                                                        <td><div className="td">{listValue.note_type ? listValue.note_type : ''}</div></td>
                                                        <td><div className="td" style={{width: "179px"}}>{listValue.note_content ? listValue.note_content : '-'}</div></td>
                                                        <td><div className="td">{listValue.from_date ? moment(listValue.from_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">{listValue.to_date ? moment(listValue.to_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">
                                                            {listValue.is_active ?
                                                                <img
                                                                    src={toggleon}
                                                                    alt=''
                                                                />
                                                                :
                                                                <img
                                                                    src={toggleoff}
                                                                    alt=''
                                                                />
                                                            }
                                                        </div></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No screen Pop-up notifications have been added yet!</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {showConfirm &&
                    <NotificationConfirmPopup
                        title={'Deactivate Confirmation '}
                        loader={this.state.loading}
                        action={'enabledisable'}
                        content={'Are you sure you want to deactivate this notification ?'}
                        deactivateData={() => this.deletePopupNotification()}
                        onDismiss={() => this.setState({ showConfirm: false })}

                    />
                }
                {showDetail &&
                    <NotificationDetailsPopup
                        data={this.state.selectedData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
                {/* {show &&
                    <ConfirmPopups
                        popupType={'Screen Pop-Up'}
                        // data={this.state.screenPopUpDashboard}
                        updateData={this.state.selectedScreenData}
                        onDismiss={() => this.setState({ show: false })}
                        type={'editDataPopup'}
                        action={'update'}
                    />
                } */}

                {show &&
                    <ScreenPopUpNotifications
                        popupType={'Screen Pop-Up'}
                        NotificationType={"Screen Pop-Up"}
                        loader={this.state.loading}
                        data={this.state.selectedScreenData}
                        updateData={this.state.selectedScreenData}
                        close={() => this.setState({ show: false })}
                        back={() => this.setState({ show: false })}
                        type={'editDataPopup'}
                        Action={'update'}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(ScreenPopupNotifications);
