import React, { Component } from 'react';
import { gemsApplicant } from './Constant';
import MtrFrObj from '../gems/MtrFrObjective';
import moment from 'moment';
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
class GEMSApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopUp: false,
            objectivID: this.props.sendData,

            mtrObjectivesPopup: false
        };
    }

    componentDidMount() {
        this.setState({
            mtrObjectivesPopup: this.props.mtrObjectivesPopup
        })
    }

    handleClick = (type) => {
        if (type === 'nextToMTR') {
            this.setState({ mtrObjectivesPopup: !this.props.mtrObjectivesPopup });
        }
        else if (type === 'back') {
            this.setState({ mtrObjectivesPopup: true });
        }
    };

    render() {
        const { mtrObjectivesPopup } = this.state
        return (
            <div>
                {mtrObjectivesPopup ?
                    <>
                        <div className='d-flex mt-3 justify-content-end mr-3'>
                            <span
                                onClick={() => this.handleClick('nextToMTR')}
                                style={{ cursor: 'pointer' }}
                                className='btn btn-primary login-btn py-2 w-20'
                            >
                                {"MTR/ FR Objectives"}
                            </span>
                        </div>

                        <div className='flex-direction-column p-3'>

                            <span className='container-project-details px-10px'>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.pgName}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.project_guide?.CompName ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.projectObj}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.title ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.projectStatus}</span><span className='feedback-sm-text ml-5px' style={{ color: this.props.sendData.status === "Initiated" ? '#E3B203' : this.props.sendData.status == "Rejected" ? '#E11111' : '#047857' }}>
                                        {this.props.sendData.status ? this.props.sendData.status : ''}</span>
                                </span>
                            </span>
                            <div className='line-horizontal d-flex hr-line-margin'></div>

                            <span className='container-project-details px-10px mt-1'>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.projectBhrName}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.bhr?.CompName ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.projectLocation}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.location ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.ou}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.org_unit?.org_unit ?? ''}</span>
                                </span>
                            </span>
                            <div className='line-horizontal d-flex hr-line-margin'></div>

                            <span className='container-project-details px-10px mt-1'>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.startDate}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.actual_start_date ?? moment(this.props.sendData?.planned_start_date, 'YYYY-MM-DD').format('DD-MM-YYYY') ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.endDate}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.actual_end_date ?? moment(this.props.sendData?.planned_end_date, 'YYYY-MM-DD').format('DD-MM-YYYY') ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.projectArea}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.area?.area ?? ''}</span>
                                </span>
                            </span>
                            <div className='line-horizontal d-flex hr-line-margin'></div>

                            <span className='container-project-details px-10px mt-1'>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.mtrStartDate}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.actual_mtr_date ? moment(this.props.sendData?.actual_mtr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.props.sendData?.revised_mtr_date ? moment(this.props.sendData?.revised_mtr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.props.sendData?.planned_mtr_date ? moment(this.props.sendData?.planned_mtr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.frStartDate}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.actual_fr_date ? moment(this.props.sendData?.actual_fr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.props.sendData?.revised_fr_date ? moment(this.props.sendData?.revised_fr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : this.props.sendData?.planned_fr_date ? moment(this.props.sendData?.planned_fr_date, 'YYYY-MM-DD').format('DD-MM-YYYY') : '-'}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.no_of_openings ?? ''}</span>
                                </span>
                            </span>
                            <div className='line-horizontal d-flex hr-line-margin'></div>

                            <span className='container-project-details px-10px mt-1'>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.pgLevel}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.project_guide?.Esgtxt ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{gemsApplicant.durationDays}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.duration ?? ''}</span>
                                </span>
                                <span>
                                    <span className='appcenter-app-name'>{"Category:"}</span><span className='feedback-sm-text ml-5px'>{this.props.sendData?.category ?? ''}</span>
                                </span>
                            </span>
                            <div className='line-horizontal d-flex hr-line-margin'></div>

                            <span className='p-10px flex-direction-row mt-1'>
                                <span className='form-popup-value'>{gemsApplicant.description}</span>
                                <span className='feedback-sm-text ml-5px'>{this.props.sendData?.description ?? ''}</span>
                            </span>
                        </div>
                    </>
                    :
                    <>
                        <div className='flex-direction-row noProject-style p-10px mx-10px mt-1rem border_radius_12px' style={{ backgroundColor: '#E2E8F0' }}>
                            <div className='align-item-center mt-1'
                                onClick={() => this.handleClick('back')}>
                                <img alt="Back" className='mr-10 pointer' src={backArrow} />
                            </div>
                            <span className='performance-timeline-text-bold p-10px'>Sr. No</span>
                            <span className='performance-timeline-text-bold p-10px w-42 ml-4'>Deliverable</span>
                            <span className='performance-timeline-text-bold p-10px w-42'>Target</span>
                        </div>

                        <MtrFrObj
                            clickedId={this.state.objectivID ? this.state.objectivID : ''} />
                    </>
                }
            </div>
        )
    }
}

export default GEMSApprovals;