import React, { useEffect, useState } from "react";
import AddIcon from "../../../../../assets/drishti_images/addTableBtn.svg";
import EditIcon from "../../../../../assets/drishti_images/editTableBtn.svg";
import approveAction from "../../../../../assets/drishti_images/approvalTableBtn.svg";
import rejectAction from "../../../../../assets/drishti_images/Rejectcross.svg";
import { DDMMYYYY } from "../../../../../utils/Utils";
import SuggestionOfficeDetailPopup from './SuggestionOfficeDetailPopup';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import { Pagination } from "react-bootstrap";
import "./SuggestionOffice.css";
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import SuggestionOfficeViewDetailPopup from "./SuggestionOfficeViewDetailPopup";
import { statusCodes } from "../../../employeeScreens/suggestionScheme/constants";

function Table(props) {
  const {
    columns,
    data,
    statusCode,
    hasAction = false,
    multiSelect = true,
    currentPage,
    pageSize = 10,
    totalPages,
    setCurrentPage,
    primaryActionImg = AddIcon,
    onSelectSuggestion,
    selectedSuggestion,
    onAction,
    savingDetails = false,
    radioValue,
    className = "",
    getSuggestionOffice= () => {},
    isActionShow=true,
    suggestionOfficeId,
    isSrNoShow=false,
    paginationCall= () => {},
    minHeight=''
  } = props;


  let statusList = [
    { title: 'Pending', value: 'Pending' },
    { title: 'Approved', value: 'Approved' },
    { title: 'Rejected', value  : 'Rejected' },
    { title: 'Expired', value: 'Expired' },
  ]

  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selectedRecord,setSelectedRecord ] = useState([]);
  const [toggleDetailPopup, setToggleDetailPopup] = useState(false);
  const [toggleViewDetailPopup, setToggleViewDetailPopup] = useState(false);
  const [buttonStates, setButtonStates] = useState(
    data?.map(() => false) // Initialize all button states to false
  );
  const keys = [ 'present_status', 'earlier_status', 'proposed_changes', 'changes_implemented', 'benefits', , 'title', 'clarification_to_divc', 'comment', 'applicable_areas']

  const [selectedStatus, setSelectedStatus] = useState(data?.map((val) => {
    return { title: val.saving_type, value: val.saving_type }
  }))
  const [awardAmount, setAwardAmount] = useState(data?.map((val) => {
    console.log('val------->', val);

    return val.award_amount
  }))
  const [selectedNature, setSelectedNature] = useState(data?.map((val) => {
    return { title: val.saving_nature, value: val.saving_nature }
  }))
  const [comment, setComment] = useState(data?.map((val) => {
    return val.comment
  }))

  const handleSelectAll = (checked) => {
    if (!checked) {
      setSelectedIndexes([]);
      onSelectSuggestion([]);
    } else {
      let tempArr = Array.from(Array(data.length), (_, i) => i);
      setSelectedIndexes(tempArr);
      onSelectSuggestion(data);
    }
  };

  const handleToggle = (index, row, value) => {
    const newButtonStates = [...buttonStates];
    newButtonStates[index] = !newButtonStates[index];
    setButtonStates(newButtonStates);
    if (value !== 'edit') {
      updateStatusAtIndex(index, data[index]?.saving_type, data[index]?.saving_type);
      updateAwardAmount(index, data[index].award_amount);
      updateCommentChange(index, data[index].comment);
      updateSavingNature(index, data[index].saving_nature);
    }
  };

  useEffect(() => {
    if (Array.isArray(selectedSuggestion) && selectedSuggestion?.length === 0) {
        setSelectedIndexes([]);
    }
  }, [selectedSuggestion]);

  const handleCheckboxSelection = (index, checked, row) => {
    if (!checked) {
      let arrayIndex = selectedIndexes.findIndex((ind) => {
        return ind == index;
      });
      let tempArr = [
        ...selectedIndexes.slice(0, arrayIndex),
        ...selectedIndexes.slice(arrayIndex + 1),
      ];
      setSelectedIndexes(tempArr);
      let arrayIndexOfSelectedSuggestion = selectedSuggestion.findIndex(
        (item) => item == row
      );
      let tempArrofSelectedSuggestion = [
        ...selectedSuggestion.slice(0, arrayIndexOfSelectedSuggestion),
        ...selectedSuggestion.slice(arrayIndexOfSelectedSuggestion + 1),
      ];
      onSelectSuggestion(tempArrofSelectedSuggestion);
    } else {
      let tempArr = [...selectedIndexes];
      tempArr.push(index);
      setSelectedIndexes(tempArr);
      let tempArrofSelectedSuggestion = [...selectedSuggestion];
      tempArrofSelectedSuggestion.push(row);
      onSelectSuggestion(tempArrofSelectedSuggestion);
    }
  };


  const handletogglePopUp = (row) => {
      setSelectedRecord(row)
      getSuggestionOffice(suggestionOfficeId);
      setToggleDetailPopup(!toggleDetailPopup);
  }
  const handletoggleViewPopUp = (row) => {
    console.log("row id-->",row)
      setSelectedRecord(row)
      setToggleViewDetailPopup(!toggleViewDetailPopup);
  }

  const handleStatusFilter = (value, index) => {
    updateStatusAtIndex(index, value.title, value.value);
  };

  const updateStatusAtIndex = (index, newTitle, newValue) => {
    setSelectedStatus((prevStatus) => {
      // Create a copy of the current state
      const newSelectedStatus = [...prevStatus];

      // Update the object at the specified index
      if (index >= 0 && index < newSelectedStatus.length) {
        newSelectedStatus[index] = {
          ...newSelectedStatus[index],
          title: newTitle,
          value: newValue
        };
      }

      // Return the new array to update the state
      return newSelectedStatus;
    });
  };

  const updateAwardAmount = (index, awardAmount) => {
    setAwardAmount((prevStatus) => {
      // Create a copy of the current state
      const newAwardAmount = [...prevStatus];

      // Update the object at the specified index
      if (index >= 0 && index < newAwardAmount.length) {
        newAwardAmount[index] = awardAmount;
      }

      // Return the new array to update the state
      return newAwardAmount;
    });
  };

  const handleSavingNatureChange = (index, value) => {
    updateSavingNature(index, value)
  }

  const updateSavingNature = (index, savingNature) => {
    setSelectedNature((prevStatus) => {
      // Create a copy of the current state
      const newSavingNAture = [...prevStatus];

      // Update the object at the specified index
      if (index >= 0 && index < newSavingNAture.length) {
        newSavingNAture[index] = {
          ...newSavingNAture[index],
          title: savingNature.title,
          value: savingNature.value
        };
      }

      // Return the new array to update the state
      return newSavingNAture;
    });
  };

  const handleCommentChange = (index, value) => {
    updateCommentChange(index, value)
  }

  const updateCommentChange = (index, comment) => {
    setComment((prevStatus) => {
      // Create a copy of the current state
      const newcomment = [...prevStatus];

      // Update the object at the specified index
      if (index >= 0 && index < newcomment.length) {
        newcomment[index] = comment;
      }

      // Return the new array to update the state
      return newcomment;
    });
  };

  
  const getRowData = (data, cols) => {
    const { type, key } = cols;

    switch(type) {
      case "date":
        return DDMMYYYY(data[key]) ?? "--";
      case "special":
        const accessor = cols?.specialRowFunction(data);
        return data[accessor]
      default: 
        return data[key] ?? "--"
    }
  }

  return (
    <div>
      <div className={`scroll-x max-height-40vh mt-5px mb-15px ${className}`} style={{minHeight: minHeight}}>
        <table className={`table gemsAdmin scroll-y`}>
          <thead className="">
            <tr className="">

              {multiSelect  && statusCode==2 &&
                <th width={"5%"}>
                  <input
                    className="thead"
                    type="checkbox"
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                    }}
                    checked={selectedIndexes.length == data.length}
                  />
                </th>
              }
              {isSrNoShow && <th width={"10%"}><div className="thead">Sr No</div></th>}
              {isActionShow &&  (
                <th width={"10%"}>
                  <div className="thead">Action</div>
                </th>
              )}

              {columns.map((val) => (
                <>
                  <th width={val.width}>
                    <div className="thead white-space-no-wrap">{val.title}</div>
                  </th>
                </>
              ))}

              {savingDetails &&  (
                <th width={"10%"}>
                  <div className="thead">Action</div>
                </th>
              )}

            </tr>

          </thead>

          <tbody>

            {data?.map((row, index) => {
              return (
                <tr className={row?.is_suggestion_office_send_back && statusCode==statusCodes["Pending at Suggestion Office"] ? 'sendBack' : ''}>
                  {multiSelect && statusCode==2 &&
                    <td width={"30"}>
                      <input
                        className="thead"
                        type="checkbox"
                        onChange={(e) => {
                          handleCheckboxSelection(index, e.target.checked, row);
                        }}
                        checked={selectedIndexes.includes(index)}
                      />
                    </td>
                  }

                  {isSrNoShow ? (
                    <td>{index + 1}</td>
                  ) : (
                    <td className="d-flex table-head">
                      {!savingDetails && statusCode !== 2 ? (
                        <>
                          <img
                            alt="view"
                            src={view_action}
                            style={{ width: "25px", cursor: "pointer " }}
                            onClick={() => handletoggleViewPopUp(row)}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            alt="view"
                            src={primaryActionImg}
                            style={{ width: "25px", cursor: "pointer " }}
                            onClick={() => handletogglePopUp(row)}
                          />
                        </>
                      )}
                    </td>
                  )}

                  {columns.map((col) => {
                    const { type, key, width, title } = col;
                      if (key === 'saving_type') {
                      return (
                        <td width={width} id={index}>
                          <DynamicDropdown
                            data={statusList}
                            render="title"
                            value={selectedStatus[index].title}
                            selectedValue={(data) => handleStatusFilter(data, index)}
                            arrow={'true'}
                            height={'NotificationHeight'}
                            width={"w-30rem"}
                            disabled={!buttonStates[index]}
                          />
                        </td>
                      )
                    }
                    else if (key === 'saving_nature') {
                      return (
                        <td width={width}>
                          <DynamicDropdown
                            data={statusList}
                            render="title"
                            value={selectedNature[index].title}
                            selectedValue={(data) => handleSavingNatureChange(index, data)}
                            arrow={'true'}
                            height={'NotificationHeight'}
                            width={"w-30rem"}
                            disabled={!buttonStates[index]}
                          />
                        </td>
                      )
                    }
                    else if (title === 'Comment') {
                      return (
                        <td width={width}>
                          <input className="form-control p-placeholder px-2 p-placeholder-admin search-box"
                            type="text"
                            value={comment[index]}
                            disabled={!buttonStates[index]}
                            onChange={(e) => { handleCommentChange(index, e.target.value) }}>
                          </input>
                        </td>
                      )
                    }
                    else {
                      return (
                        <td width={width}>
                          <pre className={keys.includes(key) ? "unineue-font size_875rem custom-pre" : 'unineue-font size_875rem'}>
                              { getRowData(row, col) }
                          </pre>
                        </td>
                      )
                    };
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {toggleDetailPopup && <SuggestionOfficeDetailPopup title={'Suggestion Details'} backAction={() => handletogglePopUp()} selectedRecordId = {selectedRecord?.id} data={selectedRecord}/>}
      {toggleViewDetailPopup && <SuggestionOfficeViewDetailPopup title={'Suggestion Details'} backAction={() => handletoggleViewPopUp()} selectedRecordId = {selectedRecord?.id} />}  
    </div>
  );
}

export default Table;
