import React, { Component } from 'react'
import "react-circular-progressbar/dist/styles.css";
import './LeaveScreen.css'
import downloadBtn from '../../../../assets/images/applyLeave/downloadBtn.png'
import 'antd/dist/antd.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as KeycloakActions from '../login/KeyCloakAction';
import { bindActionCreators } from 'redux';
// import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { UpcomingHolidayCardSkeleton_New } from '../../../shared/skeleton/Skeletons';
class UpcommingHoliday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            holidayList: [],
            message: ''
        }
    }
    componentDidMount() {
        this.getCurrentYearHolidayList();
    }
    downloadHolidayList = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            action: 'download',
            emp_comp_code: this.props.loginData.company.company_code
        }
        fetchApiCall(Config.getCurrentYearHolidayList,
            body, headers, true)
            .then(res => {
                if (res.data.status_code === '200') {
                    this.generateHolidayList(res.data.template)
                    this.setState({ loading: false })
                }
                else {
                    this.setState({ loading: false })
                    console.log('err')
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err);
            })
    }

    generateHolidayList = async (element) => {
        const html2pdf = (await import("html2pdf.js")).default;
        var html2canvas = { dpi: 192, letterRendering: true, height: 1024 }
        const fileName = 'HolidayList';

        var option = {
            margin: [0.5, 1, 1, 1],
            filename: fileName,
            html2canvas: html2canvas,
            jsPDF: {
                orientation: 'portrait',
                unit: 'cm',
                format: [29.7, 21]
            }
        };
        html2pdf().set(option).from(element).save();
    }
    getCurrentYearHolidayList = () => {
        this.setState({ loading: true })
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id: this.props.loginData.userData.Perno,
            emp_comp_code: this.props.loginData.company.company_code
        }
        fetchApiCall(Config.getCurrentYearHolidayList, body, headers, true)
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        holidayList: res.data.employee_holiday_data ? res.data.employee_holiday_data : [],
                        message: res.data.message,
                        loading: false
                    })
                }
                else {
                    this.setState({ loading: false, holidayList: [] })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }
    render() {
        const { holidayList, loading } = this.state
        return (
            <div className='card-new boxUpcomingLeaves'>
                {/* <LoaderIndicator loading={this.state.loading} /> */}
                <div className='row mt-2 justify-content-between'>
                    <div className='upcomingLeaves card-content-title p-2'>
                        Holidays
                    </div>
                    <div
                        className='leavesBtn holidayBtnColor mr-10'
                        onClick={() => this.downloadHolidayList()}
                    >
                        <img src={downloadBtn} alt='' />&nbsp;
                        Holiday List {new Date().getFullYear()}
                    </div>
                </div>
                {loading ?
                    <div className=''>
                        <UpcomingHolidayCardSkeleton_New />
                    </div>
                    :
                    <>
                        {holidayList.length != 0 ?
                            <div className='mt-2 tble mb-2'>
                                <table className="table table-sm holidayTable">
                                    <thead className=''>
                                        <tr>
                                            <th scope="col" className='holidayThead'>Holiday name</th>
                                            <th scope="col" className='holidayThead'>Day</th>
                                            <th scope="col" className='holidayThead'>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holidayList.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className='td'>{item.holiday_name ? item.holiday_name : '-'}</td>
                                                    <td className='td'>{item.weak_day ? item.weak_day : '-'}</td>
                                                    <td className='td'>{item.holiday_date}</td>
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div> :
                            <DataNotFound reason={this.state.message} />
                        }
                    </>
                }
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        tokenActions: bindActionCreators(KeycloakActions, dispatch)
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(UpcommingHoliday)