import React, { useState } from 'react';
import { gemsApplicantForm, applicantFormNote } from './Constant';
import './HomeScreen.css';

export function GEMSApplicantForm({ fileUpload, checkDeclaration, checkDeclaration2, categoryType }) {
    const [error, setError] = useState(false);
    const [check, setCheck] = useState(false);
    const [check2, setCheck2] = useState(false);

    const handleChange = () => {
        const newCheckValue = !check;
        setCheck(newCheckValue);
        checkDeclaration(newCheckValue);
    };

    const handleChange2 = () => {
        const newCheckValue = !check2;
        setCheck2(newCheckValue);
        checkDeclaration2(newCheckValue);
    };

    return (
        <span className='flex-direction-column px-30px'>
            <span className='mt-10px'>
                <div className='performance-timeline-text-lighter ml-3 mb-1 required'>{gemsApplicantForm.uploadCV}</div>
                <input
                    className={`form-control px-3 paddingTop-11" form-control px-3 paddingTop-11 ${error ? 'p-placeholder-error' : 'p-placeholder'}`}
                    type="file"
                    accept=".pdf, .ppt, .pptx, .doc, .docx"
                    onChange={fileUpload}
                />
            </span>
            <span className='mx-20px mt-5px'>
                <span className='flex-direction-row'>
                    <span className='performance-timeline-text-circle'>{gemsApplicantForm.fileSizeLimit} </span>
                    <span className='performance-timeline-text-lighter ml-5px'>{gemsApplicantForm.fileSize}MB</span>
                </span>
                <span className='flex-direction-row'>
                    <span className='performance-timeline-text-circle'>{gemsApplicantForm.allowFileTypesText} </span>
                    <span className='performance-timeline-text-lighter ml-5px'>{gemsApplicantForm.allowFileTypes}</span>
                </span>
            </span>
            <div>
            <span className='performance-timeline-text-circle ml-2 required'>{'Declaration:'}</span>
                <div className='d-flex flex-direction-row align-items-center mt-5px ml-2'>
                    <label className="checkBoxData pl-3">
                        <input type="checkbox" className='hidecheckBox'
                            checked={check}
                            onChange={handleChange}
                        />
                        <span className="check-mark-gems"></span>
                    </label>
                    <div className='mt-5px'>
                        <span className='performance-timeline-text-lighter ml-5px'>{applicantFormNote.noteInstruction1}</span>
                    </div>
                </div>
                {categoryType === "Deputation" &&
                    <div className='d-flex flex-direction-row align-items-center mt-5px ml-2'>
                        <div>
                            <label className="checkBoxData pl-3">
                                <input type="checkbox" className='hidecheckBox'
                                    checked={check2}
                                    onChange={handleChange2}
                                />
                                <span className="check-mark-gems"></span>
                            </label>
                        </div>
                        <div className='mt-5px'>
                            <span className='performance-timeline-text-lighter ml-5px'>{applicantFormNote.noteCheckBoxInstruction2}</span>
                        </div>
                    </div>
                }
            </div>
        </span>
    )
}

