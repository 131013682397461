import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import {rootReducer} from './reducer/RootReducer';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

let middleWare = [thunk];

const persistConfig = {
    key: "root",
    storage: storage,
    timeout: null, 
    // whitelist: ['loginState','keycloakState','availableServiceState','adminLoginState'], 
    stateReconciler: hardSet,
};

export const persistStoreReducer = persistReducer(persistConfig, rootReducer)

export const configStore = createStore(persistStoreReducer
    ,applyMiddleware(...middleWare));

export let persistor = persistStore(configStore);
