import React, { useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import { tdStyle } from '../../adminScreens/localConveyance/constants'
import { billDetailsColPreview, claimDetailsFormPreview, constantValues, employeeDetailsFieldsPreview, expenseDetailsFieldsPreview, expenseTableColsPreview, initialFields } from './constant'
import moment from 'moment'
import ConfirmPopup from '../../employeeScreens/todo/ConfirmPopup'
function LocalConveyanceSubmitPopUp(props) {
    const { closeAction, selectedClaim, onSubmit, loading } = props
    const [expense, setExpense] = useState("")
    const [bill, setBill] = useState("")
    const [confirmPopup, setConfirmPopUp] = useState(false)
    return (
        <div className="modal popup-box">
            <LoaderIndicator loading={loading} />
            <div className={'popupCard-box p-0 w-60'}>
                {/* ------------------------------------------------------------- Header -------------------------------------------------------------- */}
                <div className="modal-header">
                    {(expense == "" && bill == "") && <h4 className="modal_title black popup-head">{'Claim Submit Preview'}</h4>}
                    {(expense != "" && bill == "") &&
                        <span className='d-flex popup-head'>
                            <div
                                onClick={() => { setExpense(""); setBill("") }}
                            >
                                <img alt="view" src={backArrow} style={{ width: '10px', cursor: 'pointer ' }} />
                            </div>
                            <h4 className="modal_title black popup-title">{expense?.expense_type}</h4>
                        </span>}
                    {bill != "" &&
                        <span className='d-flex popup-head'>
                            <div
                                onClick={() => { setBill("") }}
                            >
                                <img alt="view" src={backArrow} style={{ width: '10px', cursor: 'pointer ' }} />
                            </div>
                            <h4 className="modal_title black popup-title">{bill.name}</h4>
                        </span>
                    }
                    <button type="button" className="close" data-dismiss="modal" onClick={() => {closeAction()}}>&times;</button>
                </div>
                <div className={"modal-body min-h-48vh h-60vh scroll-y"}>

                    {/* ------------------------------------------------------------- Claim Details -------------------------------------------------------------- */}

                    {
                        (expense == "" && bill == "") &&
                        <div className='detail-div mt-12px'>
                            <div className='edit-cardtitle detail-head '>{constantValues.employeeDetails}</div>
                            <div className='details pb-10px'>
                                {
                                    employeeDetailsFieldsPreview.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{selectedClaim?.employeeDetailData?.[field.key] ?? "NA"}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head'>{constantValues.demDetails}</div>
                            <div className='details pb-10px'>
                                {
                                    employeeDetailsFieldsPreview.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{selectedClaim?.singleManagerDetailData?.[0]?.[field.key] ?? "NA"}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head'>{constantValues.claimDetails}</div>
                            <div className='details pb-10px'>
                                {
                                    claimDetailsFormPreview.map((field) => {
                                        return (

                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{field.type == "date" ?
                                                    moment(selectedClaim?.[field.key]).format('DD-MM-YYYY') ?? "NA"
                                                    : (field.key == "fromPlace" || field.key == "toPlace")
                                                        ? selectedClaim?.[field.key]["city"] ?? "NA"
                                                        : selectedClaim?.[field.key] ? selectedClaim[field.key] : "NA"}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/* ----------------------------------------------------------------- expense table view -------------------------------------------------------------------------- */}
                            <div className='edit-cardtitle detail-head '>{constantValues.expenseDetails}</div>
                            <div className='table-container px-10px mt-10px '>
                                {
                                    selectedClaim.expenseDetails.filter((expenseDetails) => expenseDetails != initialFields).length > 0 ?
                                        <div className="main-contain">
                                            <div className="row align-item-center justify-content-start ">
                                                <table className="table gemsAdmin">
                                                    <thead className='w-100'>
                                                        <tr className='w-100'>
                                                            {expenseTableColsPreview.map((col) => {
                                                                return (
                                                                    <th width={col.width}><div className='thead t-head'>{col.title}</div></th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='w-100'>
                                                        {
                                                            selectedClaim.expenseDetails.filter((expenseDetails) => expenseDetails != initialFields).map((row, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.expense_type ? row.expense_type : "NA"}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.sub_expense_type1 ? row.sub_expense_type1 : "NA"}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.sub_expense_type2 ? row.sub_expense_type2 : "NA"}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.total_expense_amount ? parseInt(row.total_expense_amount) : "NA"}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{(row.expense_type === "Accommodation allowance" || row.expense_type === "Daily allowance" || row.expense_type === "Own vehicle") ? parseInt(row.permissible_expense_amount) : "NA"}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.approved_expense_amount ? parseInt(row.approved_expense_amount) : "NA"}</div></td>
                                                                        <td width='5%'>
                                                                            <span className='d-flex'>
                                                                                <div
                                                                                    onClick={() => { setExpense(row) }}
                                                                                >
                                                                                    <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-5 h-90-percent'>
                                            <DataNotFound />
                                        </div>
                                }
                            </div>
                        </div>
                    }

                    {/* ------------------------------------------------------------- Expense Detail -------------------------------------------------------------- */}

                    {
                        (expense != "" && bill == "") && <div className='detail-div mt-12px'>
                            <div className='edit-cardtitle detail-head '>{constantValues.expenseDetails}</div>
                            <div className='details pb-10px'>
                                {
                                    expenseDetailsFieldsPreview.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>
                                                    {field.type == "date" ? moment(expense[field.key]).format('DD-MM-YYYY') ?? "NA"
                                                        : ["approved_expense_amount", "permissible_expense_amount", "total_expense_amount","extra_expense_amount","claim_expense_amount"].includes(field.key) && expense[field.key]? parseInt(expense[field.key])
                                                            : expense[field.key] ? expense[field.key] : "NA"}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head '>{constantValues.Attachments}</div>
                            <div className='table-container px-10px mt-10px '>
                                {
                                    expense?.bill_path?.length > 0 ?
                                        <div className="main-contain">
                                            <div className="row align-item-center justify-content-start ">
                                                <table className="table gemsAdmin">
                                                    <thead className='w-100'>
                                                        <tr className='w-100'>
                                                            {billDetailsColPreview.map((col) => {
                                                                return (
                                                                    <th width={col.width}><div className='thead'>{col.title}</div></th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='w-100'>
                                                        {
                                                            expense.bill_path.map((row, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                                        <td width='5%' ><div className={tdStyle}>{row.attachment_name}</div></td>
                                                                        <td width='5%'>
                                                                            <span className='d-flex'>
                                                                                <div
                                                                                    onClick={() => { setBill({ url: row.attachment, type: row.attachment_type, name: row.attachment_name }) }}
                                                                                >
                                                                                    <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-5 h-90-percent'>
                                            <DataNotFound />
                                        </div>
                                }
                            </div>
                        </div>
                    }

                    {/* ------------------------------------------------------------- Attachments -------------------------------------------------------------- */}

                    {
                        bill != '' && <div className='detail-div mt-12px'>
                            {
                                <>
                                    {(bill.type === 'jpg' || bill.type === 'png' || bill.type === "jpeg") &&
                                        <img src={bill.url} alt="No Preview available" className="upload_bills_modal_body" />
                                    }
                                    {bill.type === 'pdf' &&
                                        <iframe className='upload_bills_modal_body' src={bill.url}></iframe>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
                <div className='modal-footer btnBox1'>
                    <input type='button' onClick={() => {closeAction()}} value='CLOSE' className='cancelBtn' />
                    <input type='button' value="SUBMIT" className='ml-2 validateBtn' onClick={() => setConfirmPopUp(true)} />
                </div>
            </div>
            {
                confirmPopup == true &&
                <ConfirmPopup
                    onDismiss={() => setConfirmPopUp(false)}
                    approve={() => onSubmit()}
                    taskTitle={"Claim Submission Form"}
                    type={'LocalConveyance'}
                    loading={loading}
                />
            }
        </div>
    )
}

export default LocalConveyanceSubmitPopUp