import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import { CalenderMonth, toaster } from '../../../../../utils/Utils';
import { inactiveAppCenterDetails } from '../../../employeeScreens/gems/Gems.api.service';

class AppCenterDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showDelete: false,
            viewPopup: false,
            seletectedID: '',
            tempAppCenterData: []
        }
    }

    inactiveAppCenterConfig = () => {
        this.setState({
            loading: true
        })
        inactiveAppCenterDetails('admin', this.state.seletectedID)
            .then(res => {
                if (res.status == '200' || res.status == '201') {
                    this.setState({
                        loading: false,
                        message: '',
                    }, () => this.props.getAppCenterConfigCall())
                    toaster("success", res.data.message ? res.data.message : "deleted successfully")

                } else {
                    this.setState({
                        loading: false,
                        message: res.data.message
                    });
                    toaster("warning", res.data.message ? res.data.message : "Something went wrong")

                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toaster("", err.data.message ? err.data.message : "Something went wrong")
            })
    }

    onAction = (listData, type) => {
        if (type === 'view') {
            this.setState({
                viewPopup: true,
                tempAppCenterData: listData
            })
        }
        else {
            this.setState({
                showDelete: true,
                seletectedID: listData.event_id,
            })
        }
    }

    render() {
        const { showDelete, viewPopup } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className=" pt-2 pushNotification-container" >
                    {this.props.appCenterData.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" ><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col" ><div className='thead'>Title</div></th>
                                    <th scope="col" className='w-2'><div className='thead'>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.appCenterData.map((listValue, index) => {
                                    return (
                                        <tr key={index} className=''>
                                            <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                            <td><div className="td" >{listValue.event_title ? listValue.event_title : ''}</div></td>
                                            <td className='d-flex'>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'view')}
                                                >
                                                    <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                </div>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'delete')}
                                                >
                                                    <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No app center details have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {viewPopup &&
                    <NotificationDetailsPopup
                        type={"appCenter"}
                        title={"App Center Configuration Details"}
                        data={this.state.tempAppCenterData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteAppCenter'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete app center details?'}
                        deleteNews={() => this.inactiveAppCenterConfig()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(AppCenterDashboard);