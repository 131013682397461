import { combineReducers } from 'redux';
import { login } from '../../components/ScreenType/employeeScreens/login/LoginReducer';
import { adminLogin } from '../../components/ScreenType/adminScreens/login/LoginReducer';
import { cfm } from '../../components/ScreenType/managerScreens/teams/directReportees/CfmReducer';
import { keycloak } from '../../components/ScreenType/employeeScreens/login/KeyCloakReducer';
import { serviceActionToken } from '../../components/ScreenType/employeeScreens/login/ServiceTokenReducer';
import { activePopupData } from '../../components/ScreenType/employeeScreens/landingScreen/ActivePopupReducer';
import { leaveRequestData } from '../../components/ScreenType/employeeScreens/leave/LeaveReducer'
import { AnnouncementRequestData } from '../../components/ScreenType/employeeScreens/Announcement/announcementReducer';
import { coiRequestData } from '../../components/ScreenType/employeeScreens/conflictOfInterest/COIReducer';
import { profile } from '../../components/ScreenType/employeeScreens/profile/ProfileReducer';
import { adminRegistration } from '../../components/ScreenType/adminScreens/blueCollarRegistration/RegistrationReducer';
export const rootReducer = combineReducers({
    keycloakState: keycloak, //changed to new keycloackstate
    serviceTokenState: serviceActionToken,
    loginState: login,     //changeed to new loginstate
    activePopupState: activePopupData,
    leaveState: leaveRequestData,
    coiState: coiRequestData,
    announcementState: AnnouncementRequestData,
    profileState: profile,
    cfmState: cfm,
    adminLoginState: adminLogin,
    adminRegistrationState : adminRegistration
});
