import React, { Component } from 'react';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
const confirmDeleteLastRecord = `Are you sure you want to delete the task '`
let FormSubmissionNote = 'Submission of all the original hard copy invoices and supporting documents to the Local Conveyance Admin is mandatory for processing the claim. The Admin team will validate the hard copies and process claims within specified time.'
class ConfirmPopup extends Component {
    render() {
        const { taskTitle, type, loading } = this.props
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className="declaration-box p-0 popupWidth">
                    <div className="modal-header">
                        {type === 'Approve' || type === 'choicePay' || type === 'LocalConveyance' ?
                            <h4 className="modal_title black">{type === 'Approve' ? 'Confirmation' : type === 'choicePay' ? 'Confirm Choice Pay' : type === 'LocalConveyance' ? 'Confirm Claim Submission' : 'Confirmation'}</h4>
                            :
                            <h4 className="modal_title black">
                                Delete Task?
                            </h4>
                        }
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className='flex-direction-column justify-content-center p-3'>
                            {type === 'Approve' || type === 'choicePay' || type === 'LocalConveyance' ?
                                <>
                                    <div className='align-self-center'>
                                        <img
                                            alt="Success_icon"
                                            src={Success_icon}
                                            className="mb-2"
                                        ></img>
                                    </div>
                                    <div className="popup_text text-center p-3">
                                        {type === 'Approve' ? 'Are you sure you want to complete this task?' : type === 'choicePay' ? 'You are about to submit the Choice Pay selection. Do you want to continue?' : type === 'LocalConveyance' ? 'Are you sure you want to submit the claim request ?' : 'Are you sure you want to complete this task?'}
                                    </div>
                                    {type === 'LocalConveyance' &&
                                        <div className="popup_text text-center p-3">
                                            <span className='purple-color performance-timeline-text-circle'>Note: </span>
                                            {FormSubmissionNote}
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='align-self-center'>
                                        <img
                                            alt="delete_popup"
                                            src={delete_popup}
                                            className="mb-2"
                                        ></img>
                                    </div>
                                    <div className="popup_text text-center p-3">
                                        {confirmDeleteLastRecord + taskTitle + `' from the task list?`}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className='modal-footer btnBox'>
                        {type === 'choicePay' ?
                            <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />
                            :
                            <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        }
                        {type === 'Approve' || type === 'choicePay' || type === 'LocalConveyance' ?
                            <input type='button' value={type === 'Approve' ? 'APPROVE' : type === 'choicePay' ? 'YES' : type === 'LocalConveyance' ? 'SUBMIT' : 'APPROVE'} className='ml-2 validateBtn' onClick={() => this.props.approve()} />
                            :
                            <input type='button' value='DELETE' className='ml-2 validateBtn' onClick={() => this.props.deleteData()} />
                        }

                    </div>
                </div>
            </div>

        );
    }
}
export default ConfirmPopup;