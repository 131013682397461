import React, { Component } from 'react'
import './LoginScreen.css'

class LoginMainHead extends Component {
    constructor(props){
        super(props)

        this.state={
            loginHead: this.props.loginHead,
            loginSubhead: this.props.loginSubhead,
            loginSubPara: this.props.loginSubPara
        }
    }
    render() {

    return (
        <>
            <div className='login-head'>
                {this.state.loginHead}
            </div>
            <div className='login-subhead'>
                {this.state.loginSubhead}
            </div>
            <div className='mt-1'>
                <p className='login-subpara'>
                    {this.state.loginSubPara}
                </p>
            </div>
        </>
    )
  }
}

export default LoginMainHead;