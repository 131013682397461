import React, { Component } from 'react';
import close from '../../../../../assets/images/close.png';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { yesNo_type } from '../../../../../utils/Utils';
import { toast } from 'react-toastify';
import { RiArrowDropDownLine } from 'react-icons/ri';

const agreeMessage = 'Above information given by me is true and correct and I have discussed with my Manager / HR / Ethics regarding this Conflict of Interest.'
const errorMessage = 'Input is missing !'
const chooseItem = 'Choose an item'
const errorFutureDateMessage = 'Future date not allowed'
class RequestFormCardA1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            validate: true,
            agree: false,
            relative_perno: '',
            relative_full_name: '',
            relative_business_unit: '',
            relative_pa: '',
            relative_psa: '',
            relative_esubgroup: '',
            relative_cost_center: '',
            relationship: chooseItem,
            relative_joining_date: '',
            is_reporting_relationship: chooseItem,
            is_disclosed: chooseItem,
            disclosure_description: '',
            relationshipExistData: yesNo_type(),
            disclosedData: yesNo_type(),
            error_relative_full_name: '',
            error_relative_business_unit: '',
            error_relative_perno: '',
            error_relationship: '',
            error_relationship_exist: '',
            error_working_since: '',
            error_disclosed: '',
            error_disclosed_to: '',
            relationshipArray: [],
            employeeType: '',
        }
    }

    componentDidMount() {
        this.setState({
            relationshipData: this.props.relationshipDataArray,
        });
        const relationshipArray = this.props.relationshipDataArray.map((item, idx) => ({
            ...item,
            value: item.relationship
        }))
        this.setState({
            relationshipDataArray: relationshipArray,
        });
    }

    getEmployeeType = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.relative_perno ? this.state.relative_perno : ''
        }
        fetchApiCall(Config.fetchEmployeeType, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        employeeType: res.data.data.employee_type ? res.data.data.employee_type : '',
                    }, () => this.getCOIEmployeeDetails());
                }
                else {
                    this.setState({ loading: false })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                console.log('err---->', err);
                this.setState({ loading: false })
            })
    }

    getCOIEmployeeDetails = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.relative_perno ? this.state.relative_perno : '',
            employee_type: this.state.employeeType ? this.state.employeeType : ''
        }
        fetchApiCall(Config.getCOIEmployeeDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        relative_full_name: res.data.emp_name,
                        relative_business_unit: res.data.business_unit,
                        relative_cost_center: res.data.relative_cost_center,
                        relative_psa: res.data.relative_psa,
                        relative_pa: res.data.relative_pa,
                        relative_esubgroup: res.data.relative_esubgroup,
                    });
                    this.validate()
                    this.onsubmitChange(res.data.emp_name, 'relative_full_name')
                    this.onsubmitChange(res.data.business_unit, 'relative_business_unit')
                    this.onsubmitChange(res.data.relative_cost_center, 'relative_cost_center')
                    this.onsubmitChange(res.data.relative_pa, 'relative_pa')
                    this.onsubmitChange(res.data.relative_psa, 'relative_psa')
                    this.onsubmitChange(res.data.relative_esubgroup, 'relative_esubgroup')
                }
                else {
                    this.setState({ loading: false })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false })
            })
    }

    getEmpDetail = (e) => {
        if (e.key === 'Enter') {
            this.getEmployeeType()
        }
    }

    changeRelationship = (e) => {
        this.setState({ relationship: e.value })
        this.onsubmitChange(e.value, 'relationship')
        this.setState({
            relationship: e.relationship,
        })
    }

    changeRelationshipExist = (e) => {
        this.setState({ is_reporting_relationship: e.value })
        this.onsubmitChange(e.code, 'is_reporting_relationship')
    }

    changeDisclosed = (e) => {
        this.setState({ is_disclosed: e.value })
        this.onsubmitChange(e.code, 'is_disclosed')
    }

    removeCOIRequest = () => {
        this.props.removeCOIData()
    }

    onsubmitChange(text, type) {
        let _type = type.toString()
        let newArray = this.props.COIRequest;
        for (var i = 0; i <= this.props.length; i++) {
            if (i === this.props.index) {
                newArray[this.props.index] = {
                    ...newArray[this.props.index],
                    [_type]: text
                }
            }
        }
        if (type === 'remove_data') {
            newArray.splice(-1)
        }
        this.props.saveCOIA1Request(newArray)
    }

    handleChange = (type, value) => {
        let typeState = type.toString()
        this.setState({ [typeState]: value })
        this.onsubmitChange(value, typeState)
        this.onsubmitChange(this.props.loginData.userData.Perno, 'emp_id')
    }

    agreeCheck = () => {
        this.setState({ agree: !this.state.agree })
    }

    validate = () => {
        if (this.state.relative_perno === '' || !this.state.relative_perno) {
            this.setState({ error_relative_perno: errorMessage })
        }
        else if (this.state.relationship === '' || this.state.relationship === chooseItem) {
            this.setState({ error_relationship: errorMessage })
        }
        else if (this.state.is_reporting_relationship === '' || this.state.is_reporting_relationship === chooseItem) {
            this.setState({ error_relationship_exist: errorMessage })
        }
        else if (this.state.relative_joining_date.length > 0 && new Date(this.state.relative_joining_date).getTime() > new Date().getTime()) {
            this.setState({ error_working_since: errorFutureDateMessage })
        }
        else if (this.state.is_disclosed === '' || this.state.is_disclosed === chooseItem) {
            this.setState({ error_disclosed: errorMessage })
        }
        else if (this.state.is_disclosed === 'Yes' && this.state.disclosure_description === '') {
            this.setState({ error_disclosed_to: errorMessage })
        }
        else if (this.state.relative_full_name === '') {
            this.setState({ error_relative_full_name: errorMessage })
        }
        else if (this.state.relative_business_unit === '') {
            this.setState({ error_relative_business_unit: errorMessage })
        }
        else if (this.state.agree === false) {
            toast.warn('Please check on above declaration', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.setState({ validate: false }, () => this.props.isValidate(this.state.validate))
            if (!this.state.relative_joining_date) {
                this.onsubmitChange(null, 'relative_joining_date')
            }
        }
    }

    render() {
        const { validate, relationshipExistData, disclosedData, relative_full_name, relative_perno, relative_business_unit, relationship, is_reporting_relationship } = this.state
        const { length, index, isVisible, listType, isedit, relationshipDataArray } = this.props;
        return (
            <div className='w-100' style={index !== length - 1 || !isedit ? { pointerEvents: "none", opacity: "0.8", borderRadius: '3%', display: 'grid' } : { display: 'grid' }}>
                <LoaderIndicator loading={this.state.loading} />
                {isVisible ?
                    <div className="row justify-content-end">
                        {index === length - 1 ?
                            <div
                                className='justify-content-end'>
                                <img
                                    alt="close"
                                    className="closeIcon"
                                    src={close}
                                    onClick={() => this.removeCOIRequest()}
                                >
                                </img>
                            </div>
                            : null}
                    </div>
                    : null}
                <div className='form-heading-label font-weight-bold ml-4 mt-2'>
                    Option {this.props.type}
                </div>
                <div className='form-heading-label font-weight-normal mb-3 ml-4'>
                    {this.props.typeDescripton}
                </div>
                <div className='mb-4 ml-2'>
                    <div className='forms-number-text mleft10 mb-1'>{this.props.type}</div>
                    <div className='type-bottom-line ml-3'></div>
                    <div className='solid-border mx-3'></div>
                </div>
                <div className='my-scrol'>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative's personnel no.</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="number"
                                onKeyDown={this.getEmpDetail}
                                name='relative_perno'
                                placeholder='Enter personnel no. and press enter'
                                value={!isedit && listType ? listType.relative_perno : relative_perno}
                                onChange={(e) => this.handleChange('relative_perno', e.target.value)}
                            />
                            {this.state.error_relative_perno != '' && relative_perno === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relative_perno}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Full name of related person</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                defaultValue={index != length - 1 || !isedit ? listType.relative_full_name : relative_full_name}
                                type="text"
                                readOnly={true}
                            />
                            {this.state.error_relative_full_name != '' && relative_full_name === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relative_full_name}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative's buiness unit</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                defaultValue={index != length - 1 || !isedit ? listType.relative_business_unit : relative_business_unit}
                                type="text"
                                readOnly={true}
                            />
                            {this.state.error_relative_business_unit != '' && relative_business_unit === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relative_business_unit}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Relationship</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {relationship}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {relationshipDataArray.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeRelationship(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_relationship != '' && relationship === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relationship}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Does reporting relationship exist with the relative?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {is_reporting_relationship}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {relationshipExistData.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeRelationshipExist(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_relationship_exist != '' && is_reporting_relationship === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_relationship_exist}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4" >
                        <div className="form-label-text ml-3 mb-2">Relative working since?</div>
                        <div className='p-placeholder-text'>
                            <input
                                className="form-control p-placeholder px-3"
                                type="text"
                                name='relative_joining_date'
                                value={!isedit && listType ? listType.relative_joining_date : this.state.relative_joining_date}
                                onChange={(e) => this.handleChange('relative_joining_date', e.target.value)}
                            />
                            {((this.state.error_working_since !== '' && this.state.relative_joining_date.length == 0) || (new Date(this.state.relative_joining_date).getTime() > new Date().getTime())) && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_working_since}</div>}
                        </div>
                    </div>
                    <div className="mb-3 mx-4">
                        <div className="form-label-text ml-3 mb-2">Was this disclosed to anyone in TML?</div>
                        <div className='dropdown'>
                            <button
                                className='btn p-placeholder d-flex justify-content-between'
                                type='button' id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {this.state.is_disclosed}
                                <RiArrowDropDownLine
                                    size={25}
                                    color={'rgb(185 188 197)'}
                                />
                            </button>
                            <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                {disclosedData.map((listValue, i) => {
                                    return (
                                        <label key={i} className='dropdown-item' onClick={() => this.changeDisclosed(listValue)}>
                                            {listValue.value}
                                        </label>
                                    );
                                })}
                            </div>
                            {this.state.error_disclosed != '' && this.state.is_disclosed === chooseItem && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed}</div>}
                        </div>
                    </div>
                    {this.state.is_disclosed === 'Yes' &&
                        <div className="mb-3 mx-4" >
                            <div className="form-label-text ml-3 mb-2">If Yes.to whom and when?</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    name='disclosure_description'
                                    value={!isedit && listType ? listType.disclosure_description : this.state.disclosure_description}
                                    onChange={(e) => this.handleChange('disclosure_description', e.target.value)}
                                />
                                {this.state.error_disclosed_to !== '' && this.state.disclosure_description === '' && <div className='errorMesssage text-danger form-label-text ml-3'>{this.state.error_disclosed_to}</div>}
                            </div>
                        </div>
                    }
                    <div className='mb-3 mx-4'>
                        <div className='form-agree-txt d-flex align-items-start'>
                            <input
                                type="checkbox"
                                className='mx-2 mt-1'
                                checked={this.state.agree}
                                onChange={() => this.agreeCheck()}
                            />
                            {agreeMessage}
                        </div>
                    </div>
                </div>
                <div className="justify-content-end align-item-center mt-3 mb-0">
                    {validate &&
                        <div className='justify-content-between mr-4'>
                            <div
                                type='button'
                                className='popup-back-btn popup-back-text'
                                onClick={() => this.props.onDismiss()}
                            >Back</div>
                            <div
                                type='button'
                                className='popup-confirm-btn'
                                onClick={() => this.validate()}
                            >Validate</div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default RequestFormCardA1;