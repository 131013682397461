import React, { useRef, useState } from "react"
import { toast } from "react-toastify"
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg'
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg'
import CharacterCounter from "../../../shared/CharacterCounter"
import plus from "../../../../assets/images/cfm/plus.svg";
import { errorCodes } from "./constant"
import { updateAttachment } from "../../../../constants/api.service"
import { toaster } from "../../../../utils/Utils"
import LoaderIndicator from "../../../shared/loader/LoaderIndicator"
export const DateField = (props) => {
    const { title, date, onChangeDate, maxDate, minDate, disabled = false, error, field } = props
    return (
        <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
            <div className='p-sub-heading ml-3'>{title}</div>
            <div className='p-placeholder-text'>
                <input
                    className="form-control p-placeholder px-3"
                    type="date"
                    placeholder='Enter date'
                    value={date ?? ""}
                    onChange={(e) => onChangeDate(e)}
                    max={maxDate ?? ""}
                    min={minDate ?? ""}
                    disabled={disabled}
                    onKeyDown={(e) => e.preventDefault()}
                />
            </div>
            {(error && field) ? error?.[field] != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes[field][error[field]]}</span> : <></>}
        </div>
    )
}
export const InputField = (props) => {
    const { value, onChange, readOnly = false, title, type, placeholder, min, max, error, field } = props
    const handleChange = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value == '' || e.target.value.match(re)) {
            onChange(e)
        }
    }
    return (
        <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
            <div className='p-sub-heading ml-3'>{title}</div>
            <div className='p-placeholder-text'>
                <input
                    className="form-control p-placeholder px-3"
                    type={type}
                    placeholder={placeholder}
                    value={value != null ? value : ""}
                    onChange={(e) => handleChange(e)}
                    readOnly={readOnly}
                    min={min}
                    max={max}
                    onWheel={(e) => e.target.blur()}
                />
            </div>
            {(error && field) ? error?.[field] != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes[field][error[field]]}</span> : <></>}
        </div>
    )
}
export const RemarkField = (props) => {
    const { value, onChange, readOnly = false, title, placeholder, error, field } = props
    return (
        <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
            <div className='p-sub-heading ml-3'>{title}</div>
            <div className='p-placeholder-text'>
                <textarea
                    readOnly={readOnly}
                    rows="2"
                    cols="50"
                    style={{ resize: "none" }}
                    className="form-control p-placeholder px-3"
                    placeholder={placeholder}
                    maxLength={200}
                    value={value ?? ""}
                    onChange={(e) => { onChange(e) }}
                />
            </div>
            <CharacterCounter
                totalCount="200"
                currentCount={value?.length ?? "0"}
            />
            {(error && field) ? error?.[field] != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes[field][error[field]]}</span> : <></>}
        </div>
    )
}

export const BillField = (props) => {
    const { onChange, billPath, disabled, error, field, employeeId, isMandatory = true, isAttachement } = props

    let allowedFileTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf"]
    const imageType = ['png', 'jpg', 'jpeg']

    let StaticIdForImage = useRef(1)
    const hiddenFileInput = useRef(null);

    const [selectedUploadBill, setSelectedUploadBill] = useState({})
    const [uploadBillModal, setUploadBillModal] = useState(false)
    const [viewAttachment, setViewAttachment] = useState(true)
    const [loading, setLoading] = useState(false)

    const submitAttachment = (attachmentBase64, attachmentName, attachmentType) => {
        setLoading(true)
        let req_body = {
            emp_id: employeeId,
            attachment: attachmentBase64,
            attachment_name: attachmentName,
            attachment_type: attachmentType
        }
        updateAttachment(req_body, "POST", props, "claim_submit_attachments").then(
            (res) => {
                if (res?.status === 200 || res?.status === 201) {
                    onChange([...billPath, {
                        id: StaticIdForImage.current,
                        attachment: res.data.data.attachment_presigned_url,
                        attachment_name: res.data.data.attachment_name,
                        attachment_type: res.data.data.attachment_type,
                        attachment_path: res.data.data.attachment_path
                    }])
                    toaster("success", res?.data?.message ?? "Claim details are saved successfully")
                }
                else {
                    toaster("error", res?.data?.message ? res?.data?.message : "Some error occurred")
                }
                setLoading(false)
            }
        ).catch(
            (err) => {
                console.log("Attachment post api error -->", err)
                setLoading(false)
                toaster("error", err?.data?.message ? err?.data?.message : "Some error occurred")
            }
        )
    }
    const onImageChange = (event) => {
        let ImagePath = event?.target?.files[0] && event.target.files[0].name
        let typeOfImage = event?.target?.files[0] && event?.target?.files[0].type
        let ImageType = typeOfImage?.split('/')
        if (event.target?.files && event.target?.files[0]) {
            let maxAllowedSize = 5120;           //5MB size
            if (Math.round((event.target.files[0].size / 1024)) <= maxAllowedSize && allowedFileTypes.includes(event.target.files[0].type)) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = async (e) => {
                    await submitAttachment(e.target.result, ImagePath, ImageType[ImageType.length - 1])
                }
                StaticIdForImage.current = StaticIdForImage.current + 1
            }
            else {
                if (!allowedFileTypes.includes(event.target.files[0].type)) {
                    toast.warn('Please Upload Image or PDF only', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
                else {
                    toast.warn('Please upload file with size less than 5MB', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }
        }
        hiddenFileInput.current.value = null;
    }
    const previewAttachment = (data) => {
        setUploadBillModal(true)
        setSelectedUploadBill(data)
    }
    const deleteAttachment = (data) => {
        setLoading(true)
        let req_body = {
            emp_id: employeeId,
            bill_path: data.attachment_path
        }
        updateAttachment(req_body, "POST", props, "delete_claim_attachment/").then(
            (res) => {
                if (res.status === 200 || res.status === 201) {
                    const newArr = billPath?.filter(object => {
                        return object.id != data.id;
                    });
                    onChange(newArr)
                    toaster("success", res?.data?.message ?? "Invoice deleted successfully")
                }
                else {
                    toaster("error", res?.data?.message ? res?.data?.message : "Some error occurred")
                }
                setLoading(false)

            }
        ).catch(
            (err) => {
                console.log("Attachment delete api error -->", err)
                setLoading(false)
                toaster("error", err?.data?.message ? err?.data?.message : "Some error occurred")
            }
        )
    }
    return (
        <div className='col-4 mb-1 mt-1 readOnlyInputBlock'>
            <LoaderIndicator loading={loading} />
            <div className="d-flex justify-content-between align-item-center">
                <span className='p-sub-heading ml-3'>{isAttachement ? "Attachments (if any)" : "Upload Invoices"} {isMandatory ? "*" : ""}</span>
                <span className='pointer card-content-title purple-color mx-10px' onClick={e => { setViewAttachment(!viewAttachment) }}>
                    {viewAttachment == true ? 'Hide' : "Show"} Attachment
                </span>
            </div>
            <div className='p-placeholder-text'>
                <label className="form-control p-placeholder p-placeholder-admin px-2 align-item-center justify-content-between"
                    style={billPath?.length < 3 && !disabled ? {} : {
                        backgroundColor: '#E5E5E5',
                        color: '#1E293B'
                    }}
                    disabled={billPath?.length < 3 && !disabled ? false : true}
                    onChange={(event) => onImageChange(event)}
                >
                    <input
                        id="Attachment"
                        style={{ display: "none" }}
                        type="file"
                        ref={hiddenFileInput}
                        title="No Files Added"
                        disabled={billPath?.length < 3 && !disabled ? false : true}
                    />
                    {isAttachement ? "Upload Document" : billPath.length < 1 ? "Minimum one Invoice is required" : `${billPath.length} ${billPath.length == 1 ? "Invoice" : "Invoices"} added`}
                    {
                        <img alt="plus" src={plus} className="plus-img"></img>
                    }
                </label>
            </div>
            <div className='d-flex justify-content-between align-item-center mb-10px'>
                <label className='noteTextMsg ml-10px d-flex' >
                    <span className='noteTextBold'>{'Note:'}</span>
                    <span className='noteTextMsg ml-5px'>
                        {'You can upload Attachment upto 5MB size only. (pdf/png/jpg/jpeg)'}<br></br>
                        {'Max 3 documents can be uploaded against each expense'}
                    </span>
                </label>
            </div>
            {viewAttachment == true && billPath && billPath.length > 0 ?
                <div className='scroll-y h-8rem w-100'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><div className='thead'>{'Name'}</div></th>
                                <th scope="col"><div className='thead'>{'Preview'}</div></th>
                                <th scope="col"><div className='thead'>{'Delete'}</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {billPath.map((listValue, index) => {
                                return (
                                    <tr key={index}>
                                        <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.attachment_name}</div></td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { previewAttachment(listValue) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { deleteAttachment(listValue, index) }} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : viewAttachment &&
                <span className='d-flex justify-content-center align-item-center'>
                    <label className='card-content-title grey-color' >No data found</label>
                </span>
            }

            {uploadBillModal === true &&
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{selectedUploadBill.attachment_name ? selectedUploadBill.attachment_name : ''}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={e => { setUploadBillModal(false); setSelectedUploadBill({}) }}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    <>
                                        {imageType.includes(selectedUploadBill.attachment_type) &&
                                            <img src={selectedUploadBill?.attachment ? selectedUploadBill.attachment : ""} alt="" className="upload_bills_modal_body" />
                                        }
                                        {selectedUploadBill.attachment_type === 'pdf' &&
                                            <iframe className='upload_bills_modal_body' src={selectedUploadBill?.attachment && selectedUploadBill.attachment}></iframe>
                                        }
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
            {(error && field) ? error?.[field] != 0 && <span className='p-sub-heading ml-3 errorBlock'>{errorCodes[field][error[field]]}</span> : <></>}
        </div>
    )
}