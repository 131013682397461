import React from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'

function ConfirmationPopUp(props) {
    const { loading, title, onDismiss, message, confirmBtnLbl="CONFIRM", cancelBtnLbl="CANCEL", onConfirmAction } = props
    return (
        <div className="modal popup-box">
            <LoaderIndicator loading={loading} />
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-25rem">
                <div className="modal-content bg-white border_radius_12px">
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">{title}</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body">
                        <pre className='flex-direction-column justify-content-center p-3 unineue-font td-choice-pay confirmation-message'>
                            {message}
                        </pre>
                    </div>
                    <div className='modal-footer btnBox1'>
                        <input type='button' onClick={() => { onConfirmAction() }} value={confirmBtnLbl} className='validateBtn' />
                        <input type='button' onClick={() => { onDismiss() }} value={cancelBtnLbl} className='cancelBtn' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationPopUp