import React from 'react'
import { rejectNote } from './constants'

const RejectionPopUp = (props) => {
    const { closeAction, setReason, reason, rejectApproval } = props
    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0" style={{ width: '30%' }}>
                <div className="modal-header">
                    <h4 className="modal_title black">Reject Confirmation</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={() => closeAction()}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className='mt-2rem'>
                        <div className='p-sub-heading ml-3 pb-10px '>Reason : </div>
                        <div className='p-placeholder-text px-10px'>
                            <textarea
                                className="form-control p-placeholder px-3"
                                type='text'
                                placeholder='Please enter reason'
                                maxLength={50}
                                onChange={(data) => { setReason(data) }}
                                rows="3"
                            />
                        </div>
                    </div>
                    <div className="popup_text text-center p-3 m-3">
                        {/* <span className='purple-color performance-timeline-text-circle'>Note: </span> */}
                        {/* {rejectNote} */}
                    </div>
                </div>

                <div className='modal-footer btnBox'>
                    <input type='button' onClick={() => closeAction()} value='CANCEL' className='cancelBtn' />
                    <input type='button' value='CONFIRM'
                        className={reason && reason.length > 0 ? 'validateBtn' : 'disableBtncheck'}
                        disabled={reason && reason.length > 0 ? false : true}
                        onClick={() => rejectApproval()} />
                </div>
            </div>
        </div >
    )
}

export default RejectionPopUp