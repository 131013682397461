import React, { Component } from "react";
import { connect } from "react-redux";
import './MOMStyles.css';
import medal from "../../../../assets/drishti_images/momentsFeed/medal.svg";
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";

const UpcomingMilestonesCardTitle = `Upcoming Milestones`

class UpcomingTataMilestone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      joining_year: '',
      upcoming_long_service: ''
    };
  }

  componentDidMount() {
    this.getTataUpcomingMilestones();
  }

  getTataUpcomingMilestones = () => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      emp_id: this.props.loginData.userData.Perno,
    };
    fetchApiCall(Config.getUpcomingMilesStone, body, headers, true, "")
      .then((res) => {
        if (res.data.status_code === "200") {
          this.setState({
            joining_year: res.data.data.joining_year,
            upcoming_long_service: res.data.data.upcoming_long_service,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,

          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <div className="row">
        <LoaderIndicator loading={this.state.loading} />
        <div className="col-md-12 card-new px-0 upcomming-milestone-card">
          <div className="row">
            <div className="col-md-12 upcoming-medal-logo">
              <div className="col-md-12 edit-cardtitle">{UpcomingMilestonesCardTitle}</div>
              <div className="mt-10px edit-cardtitle">
                <img src={medal} alt="" />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-around mt-2 mb-10"></div>
          <div className="upcoming-mile-inner-text">
            <div className="child-team-card-mtm row justify-content-around align-item-center">
              <p className="upcoming-card-text">Upcoming Long Service : &nbsp;
                {this.state.upcoming_long_service}&nbsp; Years
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    keycloakData: state.keycloakState,
  };
};

export default connect(mapStatesToProps)(UpcomingTataMilestone);
