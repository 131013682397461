import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Dropdown from '../../shared/components/Dropdown';
import DynamicDropdown from '../../shared/components/DynamicDropdown';
import approveIcon from '../../../assets/drishti_images/manager/landing/approve.png';
import rejectIcon from '../../../assets/drishti_images/manager/landing/reject.svg';
import reject_button from '../../../assets/drishti_images/manager/approval/reject_button.svg'
import approve_button from '../../../assets/drishti_images/manager/approval/approve_button.svg'
import book_btn from '../../../assets/drishti_images/supervisor/book/book_btn.svg'
import edit_button from '../../../assets/drishti_images/supervisor/book/edit_button.svg'
import EditPopup from '../../ScreenType/employeeScreens/overTime/Popup/EditPopup';
import OtPopup from '../../ScreenType/employeeScreens/overTime/Popup/OtPopup';
import ValidateBookPopup from '../../ScreenType/employeeScreens/overTime/Popup/ValidateBookPopup';
import './AdminTable.css';
import Nodata from '../../../assets/drishti_images/nodatafound/Nodatafound.svg';
import * as Config from '../../../constants/Config';
import { fetchApiCallWithService, sessionOut } from '../../../constants/Service';
import { commonHeadersAcceptJson, toaster } from '../../../utils/Utils';
import { act } from '@testing-library/react';
import { Pagination } from 'react-bootstrap';
import exportBtn from '../../../assets/images/icons8-excel-50.png';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";


const AdminTable = ({ headers, data, setApiBody, setApiAdminBody, activeTab, onUpdateData, getOTDetails, exportKey, getOTRequest, setActiveStatus, setCurrentPage, currentPage, totalPages }) => {
  const adminLoginState = useSelector(state => state.adminLoginState
  );
  const loginState = useSelector(state => state.loginState);
  const [fromDate, setFromDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]);
  const [emp_id, setemp_id] = useState('')
  const [costCenter, setCostCenter] = useState(['', 'Select Cost Center'])
  const [status, setStatus] = useState(['', 'Select Status'])
  const [otType, setOTType] = useState(['', 'Select OT Type']);
  const [isApprove, setIsApprove] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [supHrs, setSupHrs] = useState('')
  const [isEdit, setIsEdit] = useState(false);
  const [isRegularize, setIsRegularize] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [approveOTBody, setApproveOTBody] = useState({});
  const [isBook, setIsBook] = useState(false);
  const [validatedData, setValidatedData] = useState([])
  const [validateOTData, setValidatedOTData] = useState([])
  const [isValidateData, setIsValidateData] = useState(false);
  const [Hrs, setHrs] = useState('')
  const [Min, setMin] = useState('')

  var validated_emp_id = /^\d{6,8}$/.test(emp_id) ? emp_id : null;

  const props = {
    adminLoginState: adminLoginState,
    loginState: loginState
  }


  useEffect(() => {
    setemp_id('');
    // setCostCenter('Cost Center');
    setActiveStatus(status[0])

  }, [status[0]])

  useEffect(() => {
    setCurrentPage(1)
    setSelectedRows([])

  }, [status[0], fromDate, toDate, otType[0], validated_emp_id, activeTab])


  const selectedStatus = [
    { value: '', code: "1", title: 'All' },
    { value: "Pending", code: "2", title: 'Pending' },
    { value: "Booked & Pending for Approval", code: "3", title: 'Booked & Pending for Approval' },
    { value: "Approved & Pending for Update", code: "4", title: "Approved & Pending for Update" },
    { value: "Rejected", code: "5", title: "Rejected" },
    { value: "Approved & Updated", code: "6", title: "Approved & Updated" },
    { value: "Error in Posting", code: "7", title: "Error in Posting" },
    { value: "Approved & Paid", code: "8", title: "Approved & Paid" },
  ];

  const costCenterSelect = [
    { value: '', code: '1', title: 'All' },
    { value: "Cost Center 1", code: "2", title: 'Cost Center 1' },
    { value: "Cost Center 2", code: "3", title: 'Cost Center 2' },
  ];

  const otTypeSelect = [
    { value: '', code: '1', title: 'All' },
    { value: "Normal", code: "2", title: 'Normal' },
    { value: "Regularize", code: "3", title: 'Regularize' },
    { value: "Booking", code: "4", title: 'Booking' },
  ];

  let tableClass = 'table mt-20';


  if (activeTab === "OverTime") {

  }
  else if (activeTab === 'ApproveOT') {
    tableClass += ' approve';
    // tableClass += ' special-sticky width';
  }

  const handleApprove = (item) => {
    // setIsApprove(true)
    const approveOTBody = [{
      manager_ot_hrs: item.manager_ot_hrs,
      id: item.id,
      emp_id: item.emp_id,
      manager_emp_id: adminLoginState.userData.Perno,
      action: "Approved",
      ot_date: item.ot_date
    }];

    setApproveOTBody(approveOTBody);
    const headers = {
      "Content-Type": "application/json",
    };


    fetchApiCallWithService(Config.validateApproveOt, approveOTBody, headers, false, "admin", "POST", "admin", "")
      .then((res) => {

        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          // approveOT(approveOTBody);
          setIsApprove(true)
          setSelectedRows([]);
        }
        else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
          setValidatedOTData(res.data?.data?.invalid_data)
          setIsValidateData(true)
        }
        else {
          toaster("warning", res.data.message)
        }
      })
      .catch((err) => {
        toaster("error", "Something went wrong")
      });



  };

  const handleReject = (item) => {
    setIsReject(true)
    const approveOTBody = [{
      manager_ot_hrs: item.manager_ot_hrs,
      id: item.id,
      emp_id: item.emp_id,
      manager_emp_id: adminLoginState.userData.Perno,
      action: "Rejected",
      ot_date: item.ot_date
    }];

    setApproveOTBody(approveOTBody);


  };

  const approveOT = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    fetchApiCallWithService(Config.getOTApproveReject, approveOTBody, headers, false, "admin", "POST", "admin", "")
      .then((res) => {
        sessionOut(props, res);
        if (res.status == "200" || res.status == 200) {
          setIsReject(false)
          setIsApprove(false)
          toaster("success", res.data.message)
          setSelectedRows([]);
          getOTRequest();
        }
        else {
          setIsReject(false)
          setIsApprove(false)
          toaster("warning", res.data.message)

        }
      })
      .catch((err) => {
        setIsReject(false)
        setIsApprove(false)
        toaster("error", "Something went wrong")

      });

  }

  const toggleAction = (action) => {
    if (action === "approve") {
      const selectedItems = selectedRows.map(rowIndex => data[rowIndex]);
      const approveOTBody = selectedItems.map(item => ({
        manager_ot_hrs: item.manager_ot_hrs,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: adminLoginState.userData.Perno,
        action: "Approved",
        ot_date: item.ot_date
      }));
      setApproveOTBody(approveOTBody);
      // setIsApprove(true)
      const headers = {
        "Content-Type": "application/json",
      };

      fetchApiCallWithService(Config.validateApproveOt, approveOTBody, headers, false, "admin", "POST", "admin", "")
        .then((res) => {

          sessionOut(props, res);
          if (res.status == "200" || res.status == 200) {
            // approveOT(approveOTBody);
            setIsApprove(true)
            setSelectedRows([]);
          }
          else if ((res.status == "500" || res.status == 500) && res.data?.data?.invalid_data.length > 0) {
            setValidatedOTData(res.data?.data?.invalid_data)
            setIsValidateData(true)
          }
          else {
            toaster("warning", res.data.message)
          }
        })
        .catch((err) => {
          toaster("error", "Something went wrong")
        });


    } else if (action === "reject") {
      const selectedItems = selectedRows.map(rowIndex => data[rowIndex]);
      const approveOTBody = selectedItems.map(item => ({
        manager_ot_hrs: item.manager_ot_hrs,
        id: item.id,
        emp_id: item.emp_id,
        manager_emp_id: adminLoginState.userData.Perno,
        action: "Rejected",
        ot_date: item.ot_date
      }));

      setApproveOTBody(approveOTBody);
      setIsReject(true)
    }
    else if (action === "book") {
      setIsBook(true)
    }
  };


  const handleEditSup = (item) => {
    setSupHrs(item.supervisorHrs)
    setIsEdit(true)
  };
  const handleUpdate = (newHrs, newMin) => {
    onUpdateData(newHrs, newMin, selectedRows)
    setIsEdit(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setIsRegularize(false);
  };

  const handleCheckboxChange = (index) => {
    if (index === 'header') {
      if (selectedRows.length === data.length) {
        setSelectedRows([]);
      } else {
        setSelectedRows([...Array(data.length).keys()]);
      }
    } else {
      const selectedIndex = selectedRows.indexOf(index);
      if (selectedIndex === -1) {
        setSelectedRows([...selectedRows, index]);
      } else {
        setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
      }
    }
  };

  const handleValidateClose = () => {
    setIsValidateData(false)
  }

  const DynamicTooltip = value => (
    <Tooltip {...value}>
      <div className="tool-tip-font">{value}</div>
    </Tooltip>
  );

  const handleBtnClick = () => {
    setApiBody(
      {
        status: status[0],
        from_date: fromDate,
        to_date: toDate,
        ot_type: otType[0],
        emp_id: validated_emp_id,
        email: adminLoginState.adminData.user_email,
        admin_emp_id: adminLoginState.adminData.employeeId,
        export: true
      }
    )
    setFromDate(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0])
    setToDate((new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]))
    setemp_id('')
    setStatus(['', 'Select Status'])
    setOTType(['', 'Select OT Type'])
  }

  const renderActionButtons = (item) => (
    <div className="d-flex">
      <div onClick={() => handleApprove(item)}>
        <img alt="approve" src={approveIcon} style={{ width: '25px' }} />
      </div>
      <div onClick={() => handleReject(item)}>
        <img alt="reject" src={rejectIcon} style={{ width: '25px', marginLeft: '5px' }} />
      </div>
    </div>
  );

  useEffect(() => {
    if (activeTab === 'OverTime') {
      setApiBody(
        {
          status: status[0],
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType[0],
          emp_id: validated_emp_id,
          email: adminLoginState.adminData.user_email,
          admin_emp_id: adminLoginState.adminData.employeeId,
          export: exportKey,
          page_number: currentPage
        }
      )
    } else if (activeTab === 'ApproveOT') {
      setApiAdminBody(
        {
          manager_emp_id: validated_emp_id,
          from_date: fromDate,
          to_date: toDate,
          ot_type: otType[0],
          page_number: currentPage
        }
      )
    }

  }, [status[0], fromDate, toDate, otType[0], validated_emp_id, activeTab, currentPage])

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);

    }
  };
  function parseTime(timeString) {
    if (!timeString) return null;

    const [hours, minutes] = timeString.split(":");
    return new Date(0, 0, 0, hours, minutes);
  }
  const handleEmpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,8}$/.test(value)) {
      setemp_id(value);
    }
  }
  const isNumeric = (value) => {
    return /^\d*$/.test(value); // Regex to check if the value is a number (including empty string)
  };
  const isValidHour = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23));
  const isValidMinute = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59));

  return (
    <div className='h-70vh m-10px'>
      <div className='d-flex row justify-content-between '>
        {activeTab === "OverTime" ? <div className='p-heading mt-2 ml-2'>Over Time Table</div> : <div className='p-heading mt-2 ml-2'>Approve OT Table</div>}
        {activeTab === "OverTime" ? (<div className='d-flex row justify-content-between '>
          <div className='row'>
            <div className='ml-3'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>From Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                    onKeyDown={(e) => e.preventDefault()}
                    min={"2024-07-01"}
                    max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                  />
                </div>
              </div>
            </div>
            <div className='ml-3'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>To Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                    onKeyDown={(e) => e.preventDefault()}
                    max={new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0]}
                    min={new Date(new Date(fromDate).setDate(new Date(fromDate).getDate())).toISOString().split('T')[0]}
                  />
                </div>
              </div>
            </div>

            <div className='ml-3'>
              <div className='column align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>Search Emp Id</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search Emp Id'
                    onChange={handleEmpChange}
                    value={emp_id}
                  />
                </div>
              </div>
            </div>
            <div className='ml-3'>
              <div className='column align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>OT Type</div>
                  <DynamicDropdown
                    data={otTypeSelect}
                    selectedValue={(selectedItem) => { setOTType([selectedItem.value, selectedItem.title]) }}
                    placeholder="OT Type"
                    value={otType[1]}
                    render={'title'}
                    arrow={'true'}
                    height={'NotificationHeight'}
                  // width={"w-35rem"}
                  />
                </div>
              </div>
            </div>
            {/* <div className='ml-3'>
              <div className='column mb-1'>
                <div className='p-sub-heading mb-1'>Cost Center</div>
                <DynamicDropdown
                  data={costCenterSelect}
                  selectedValue={(selectedItem) => { setCostCenter([selectedItem.value, selectedItem.title]) }}
                  placeholder="Cost Center"
                  value={costCenter[1]}
                  render={'title'}
                  arrow={'true'}
                  height={'NotificationHeight'}
                  width={"w-30rem"}
                />
              </div>
            </div> */}
            <div className='ml-3'>
              <div className='column mb-1'>
                <div className='p-sub-heading mb-1 w-15rem'>Status</div>
                <DynamicDropdown
                  data={selectedStatus}
                  selectedValue={(selectedItem) => { setStatus([selectedItem.value, selectedItem.title]) }}
                  placeholder="Status"
                  value={status[1]}
                  render={'title'}
                  arrow={'true'}
                  height={'NotificationHeight'}
                // width={"w-14rem"}
                />
              </div>
            </div>
            {/* <input type='button' value='EXPORT OT DETAILS' className='ml-3 addBtn' onClick={handleBtnClick} style={{ height: "36px" }} /> */}
            <OverlayTrigger placement="top" overlay={DynamicTooltip('Export Details')}>
              <div onClick={handleBtnClick}>
                <img src={exportBtn} style={{ height: '25px', width: '25px', marginLeft: 16, marginTop: 30 }} />
              </div>
            </OverlayTrigger>

          </div>

        </div>) : (
          <div className='row'>
            <div className='ml-3'>
              <div className='row align-item-center'>
              </div>
            </div>
            <div className='ml-3'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>From Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                    onKeyDown={(e) => e.preventDefault()}
                    min={"2024-07-01"}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
            </div>
            <div className='ml-3'>
              <div className='row align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>To Date</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="date"
                    placeholder='Select the date'
                    onChange={(e) => setToDate(e.target.value)}
                    value={toDate}
                    onKeyDown={(e) => e.preventDefault()}
                    max={new Date().toISOString().split('T')[0]}
                    min={fromDate}
                  />
                </div>
              </div>
            </div>
            <div className='ml-3'>
              <div className='column align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>OT Type</div>
                  <DynamicDropdown
                    data={otTypeSelect}
                    selectedValue={(selectedItem) => { setOTType([selectedItem.value, selectedItem.title]) }}
                    placeholder="OT Type"
                    value={otType[1]}
                    render={'title'}
                    arrow={'true'}
                    height={'NotificationHeight'}
                  // width={"w-33rem"}
                  />
                </div>
              </div>
            </div>

            <div className='ml-3'>
              <div className='column align-item-center'>
                <div className='p-placeholder-text mb-1'>
                  <div className='p-sub-heading mb-1'>Search Manager Id</div>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search Emp Id'
                    onChange={handleEmpChange}
                    value={emp_id}
                  />
                </div>
              </div>
            </div>
          </div>
        )
        }
      </div>

      {data.length > 0 ?
        <div className="table-scroll-sticky">
          <>
            <table id="reusable-admin-table" className={tableClass}>
              <thead>
                <tr>
                  {headers.map(({ label }, index) => (
                    <th key={index} className="table-header" style={{ whiteSpace: 'nowrap' }}>
                      <div className="thead">
                        {/* Checkbox rendering */}
                        {label === 'Checkbox' && activeTab === 'ApproveOT' && (
                          <input type="checkbox" checked={selectedRows.length === data.length} onChange={() => handleCheckboxChange('header')} />
                        )}
                      </div>
                      {/* Conditional rendering for label */}
                      {activeTab === "OverTime" && (label === 'Checkbox' || label === 'Action') ? null : label === 'Checkbox' ? null : ((status[0] === 'Booked & Pending for Approval' || status[0] === 'Rejected') && (label === 'Approved OT HRS')) ? null : (status[0] === 'Pending' && (label === 'Booked Hrs (HH:MM)' || label === 'Assigned BC' || label === 'Approved OT HRS')) ? null : label}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {data.map((item, rowIndex) => (
                  <tr key={rowIndex} style={{ whiteSpace: 'nowrap' }}>
                    {headers.map(({ label, valueExtractor }, headerIndex) => (
                      <td key={headerIndex}>
                        <>
                          {label === 'Checkbox' && activeTab === "ApproveOT" && (
                            <input
                              type="checkbox"
                              checked={selectedRows.includes(rowIndex)}
                              onChange={() => handleCheckboxChange(rowIndex)}
                            />
                          )}
                          {label === 'Action' && selectedRows.length > 1 ? (
                            <div className="td table-data-font">--</div>
                          ) : label === 'Action' && activeTab === "ApproveOT" ? (
                            <div className="td table-data-font">{renderActionButtons(item)}</div>
                          ) : activeTab === 'ApproveOT' && label === 'Approved OT Hrs' ? (
                            <div>
                              {/* <input
                                type="time"
                                value={valueExtractor(item)}
                                onChange={({ target: { value } }) => onUpdateData(value, rowIndex)}
                                className={`editBtn ${label === 'Approved OT Hrs' && (parseTime(item.manager_ot_hrs) > parseTime(item.proposed_ot_hrs) || item.manager_ot_hrs === "") ? "input-error" : ""}`}
                              /> */}
                              <div className='d-flex align-items-center'>
                                <div style={{ position: 'relative' }}>
                                  <input
                                    type="text"
                                    value={valueExtractor(item) && valueExtractor(item).split(':')[0]}
                                    onChange={({ target: { value } }) => {
                                      if (isValidHour(value)) {
                                        setHrs(value);
                                        onUpdateData(value, valueExtractor(item).split(':')[1], rowIndex);
                                      }
                                    }}
                                    maxLength="2"
                                    className={'editTime'}
                                    placeholder="HH"
                                  />

                                </div>
                                <span className='mx-1'>:</span>
                                <div style={{ position: 'relative' }}>
                                  <input
                                    type="text"
                                    value={valueExtractor(item) && valueExtractor(item).split(':')[1]}
                                    onChange={({ target: { value } }) => {
                                      if (isValidMinute(value)) {
                                        setMin(value);
                                        onUpdateData(valueExtractor(item).split(':')[0], value, rowIndex);
                                      }
                                    }}
                                    maxLength="2"
                                    className={'editTime'}
                                    placeholder="MM"
                                  />

                                </div>
                              </div>
                              {label === 'Approved OT Hrs' && (parseTime(item.manager_ot_hrs) > parseTime(item.proposed_ot_hrs) || item.manager_ot_hrs === "") ? <span className='error-txt input-error-txt'>Invalid Input</span> : null}
                            </div>

                          ) : status[0] === 'Pending' && (label === 'Booked Hrs (HH:MM)' || label === 'Assigned BC' || label === 'Approved OT HRS') ? (
                            null
                          ) : (status[0] === 'Booked & Pending for Approval' || status[0] === 'Rejected') && (label === 'Approved OT HRS') ? (
                            null
                          ) : activeTab === 'OverTime' && label === 'Action' ? (
                            null
                          ) : typeof valueExtractor === 'function' ? (
                            <div className="td table-data-font">{valueExtractor(item, rowIndex)}</div>
                          ) : null}
                        </>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        </div>
        : <div className='d-flex justify-content-center flex-column' style={{ height: '33vh' }}>
          <div className='align-self-center'>
            <img src={Nodata} alt="Nodata" />
          </div>
          <div className='align-self-center'>
            <label className='card-content-title grey-color'>No Data found!</label>
          </div>
        </div>
      }
      {data.length >= 1 && selectedRows.length <= 1 && totalPages > 1 ?

        (
          <div className="col-md-12 w-100 d-flex justify-content-center">
            <Pagination>
              <Pagination.Prev
                onClick={() => { handlePageCount("decrement") }} />
              {currentPage != 1 &&
                <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
              }
              <Pagination.Ellipsis disabled />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              {currentPage != totalPages &&
                <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
              }
              <Pagination.Next
                onClick={() => { handlePageCount("increment") }} />
            </Pagination>
          </div>
        ) : null}

      {
        selectedRows.length > 1 && (
          <div className='row justify-content-between pt-2' style={{ alignItems: 'center' }}>
            {selectedRows.length > 0 && <span>
              {selectedRows.length} of {data.length} Selected
            </span>
            }

            {selectedRows.length > 1 && totalPages > 1 &&
              <div className="col-md-4 w-100 d-flex justify-content-center" style={{ alignItems: 'center' }}>
                <Pagination>
                  <Pagination.Prev
                    onClick={() => { handlePageCount("decrement") }} />
                  {currentPage != 1 &&
                    <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                  }
                  <Pagination.Ellipsis disabled />
                  <Pagination.Item active>{currentPage}</Pagination.Item>
                  <Pagination.Ellipsis disabled />
                  {currentPage != totalPages &&
                    <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                  }
                  <Pagination.Next
                    onClick={() => { handlePageCount("increment") }} />
                </Pagination>
              </div>
            }


            {selectedRows.length > 1 && <div className='row'>
              <div className='pointer' onClick={handleEditSup}>
                <img alt="edit" src={edit_button} style={{ height: "38px" }} />
              </div>
              <div className='pointer' onClick={() => toggleAction('approve')}>
                <img alt="approve" src={approve_button} />
              </div>
              <div className='mx-2 pointer' onClick={() => toggleAction('reject')}>
                <img alt="reject" src={reject_button} />
              </div>
            </div>
            }
          </div>
        )
      }
      {
        isApprove &&
        <OtPopup
          title={'Approve'}
          action={"approve"}
          content={'Kindly confirm to proceed with the approval'}
          onDismiss={() => setIsApprove(false)}
          updateData={approveOT}
        />
      }
      {
        isReject &&
        <OtPopup
          title={'Reject'}
          action={"reject"}
          content={'Kindly confirm to proceed with the reject'}
          onDismiss={() => setIsReject(false)}
          updateData={approveOT}
        />
      }
      {
        isEdit &&
        <EditPopup onUpdate={handleUpdate} onCancel={handleCancel} supervisorHrs={supHrs} />
      }
      {isValidateData &&
        <ValidateBookPopup
          validatedOTData={validateOTData}
          onDismiss={handleValidateClose}


        />
      }
    </div>
  );
};

export default AdminTable;
