import React, { Component } from 'react'
import GEMSTestimonial from './Testimonial';
import GEMSInstruction from './Instruction';
import GemsCarousel from './GemsCarousel';
import GEMSApplicant from './Applicant';
class GEMSLandingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isApplyProject: false,
        }
    }

    applyProjectFunction = () => {
        this.setState({ isApplyProject: !this.state.isApplyProject })
    }

    gemsProjectFunction = () => {
        this.setState({ isApplyProject: !this.state.isApplyProject });
        this.props.updateActiveTab("GEMSProjects");
    }

    gemsApprovalFunction = () => {
        this.setState({ isApplyProject: !this.state.isApplyProject });
        this.props.updateActiveTab("GEMSApproval");
    }

    render() {
        const { isApplyProject } = this.state;
        return (
            <div >
                {isApplyProject ?
                    <>
                        <GEMSApplicant
                            isApplyProject={isApplyProject}
                        />
                        <span className='back-btn-styles'>
                            <input type="submit" value='BACK' className='cancelBtn' onClick={this.applyProjectFunction} />
                        </span>
                    </>
                    :
                    <div className='container-landing'>
                        <span className='landing-vertical mt-10px'>

                            <span className='card-inner h-31vh p-15px mt-0px mr-10 mb-10'>
                                <GemsCarousel />
                            </span>

                            <span className='card-inner h-28vh p-15px mt-0px mr-10 mb-10'>
                                <GEMSInstruction />
                            </span>

                            <span className='flex-direction-row  mb-10 justify-content-around' >
                                <span className='justify-content-center'>
                                    <input type="submit" value='APPLY GEMS PROJECT' className='ml-2 validateBtn h-40px' onClick={() => this.applyProjectFunction()} />
                                </span>
                                <span className='justify-content-center'>
                                    <input type="submit" value='START GEMS PROJECT' className='ml-2 validateBtn h-40px' onClick={this.gemsProjectFunction} />
                                </span>
                                <span className='justify-content-center'>
                                    <input type="submit" value='APPROVE GEMS PROJECT' className='ml-2 validateBtn h-40px' onClick={this.gemsApprovalFunction} />
                                </span>
                            </span>
                        </span>

                        <span className='card-inner h-70vh p-15px m-10px'>
                            <GEMSTestimonial />
                        </span>
                    </div>
                }

            </div>
        )
    }
}
export default GEMSLandingScreen 
