import React, { Component } from 'react';
import DirectReportees from './directReportees/DirectReportees'
import DepartmentReportees from './DepartmentReportees'
import Approval from './Approval';
import PlannedLeave from './PlannedLeave';
import Leadership from './Leadership';
import ReporteeAndPlannedLeaveMain from './ReporteeAndPlannedLeaveMain';
import './TeamsStyle.css';
import { connect } from 'react-redux';
import * as homeActions from '../../employeeScreens/landingScreen/ActivePopupAction';
import { checkActivePoup, checkPopupCount } from '../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../shared/dynamicCustomPopum/DynamicCustomPopup';
import { bindActionCreators } from 'redux';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';
class TeamsHomeScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',
            is_service_Unavailable: false,
            service_reason: '',

            is_previous_year_leave: false,
            previous_year_leaves_reason: '',
        }
    }

    componentWillMount() {
        this.checkService();
        this.checkActivePoupDetails()
    }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('team');
        this.setState({
            isActive: activeDetails && checkPopupCount('team') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('team')
            }
        })
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "team" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    service_reason: item.reason
                })
            }
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason
                })
            }
        })
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    render() {
        return (
            <div>
                {this.state.is_service_Unavailable ?
                    <div className="col-md-12 h-100vh d-flex justify-content-center align-item-center text-align-center1 px-30px">
                        <ServiceUnavailable reason={this.state.service_reason} width={true} />
                    </div>
                    :
                    <div>
                        <div className="manager-team-grid pt-12px pr-12px pl-12px">
                            <div className="team-direct-reportee">
                                <DirectReportees
                                    loginData={this.props.loginData} />
                            </div>
                            <div className="team-department-reportee">
                                <ReporteeAndPlannedLeaveMain
                                    loginData={this.props.loginData} />
                            </div>
                            <div className="team-leadership">
                                <Leadership
                                    loginData={this.props.loginData} />
                            </div>
                            <div className="team-approval">
                                <Approval
                                    loginData={this.props.loginData}
                                    is_previous_year_leave={this.state.is_previous_year_leave} />
                            </div>
                        </div>
                        {this.state.isActive &&
                            <DynamicCustomPopupModal
                                loading={this.state.isActive}
                                note_title={this.state.note_title}
                                note_content={this.state.note_content}
                                note_type={this.state.note_type}
                                image_url={this.state.image_url}
                                image_hyperlink={this.state.image_hyperlink}
                                message={this.state.message}
                                onDismiss={this.hideModal}
                            />
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch),
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(TeamsHomeScreen);