import React, { useState } from 'react';
import { DDMMYYYY } from '../../../../../utils/Utils';

const ValidateBookPopup = ({ validationTitle, validatedOTData, onDismiss }) => {

    // const [workingHours, setWorkingHours] = useState(supervisorHrs);
    // const [error, setError] = useState(false);

    // Rendered table rows for each validated OT data
    const renderedRows = validatedOTData.map((data, index) => (
        <tr key={index} className='line-horizontal'>
            <td>{data.emp_id}</td>
            <td>{DDMMYYYY(data.ot_date)}</td>
            <td>{data.reason}</td>
        </tr>
    ));

    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0 edit-popup" style={{ width: '50%' }}>
                <div className="modal-header">
                    <h4 className="modal_title black">Issues found while validating OT {validationTitle}</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={onDismiss}>&times;</button>
                </div>
                <div className="modal-body max-height-30vh scroll-auto">
                    <div className='flex-direction-column justify-content-center p-3'>
                        <div className="popup-content">
                            <table className='table mt-20'>
                                <thead>
                                    <tr>
                                        <th className="table-header" style={{ whiteSpace: 'nowrap' }} scope="col">Employee ID</th>
                                        <th className="table-header" style={{ whiteSpace: 'nowrap' }} scope="col">OT Date</th>
                                        <th className="table-header" style={{ whiteSpace: 'nowrap' }} scope="col">Reason</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderedRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='modal-footer btnBox'>
                    <input type='button' value='Close' className='cancelBtn' onClick={onDismiss} />
                </div>
            </div>
        </div>
    );
};

export default ValidateBookPopup;