import React, { Component } from 'react';
import moment from 'moment';
import '../../employeeScreens/transfer/Transfer.css';
class TransferDetailPopup extends Component {
    render() {
        const data = this.props.data
        if (!this.props.data) return null;
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0 scroller-transfer" style={{ width: '40%' }}>
                    <div className="modal-header justify-content-center mt-2 ml-2">
                        <h4 className="modal_title black">{'Transfer Approval Details'}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body p-3">
                        <div className='p-3 mx-3'>
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left font-weight-bold text-dark">
                                    <div className="text14_bold">Employee Name :</div>
                                    <div className="text14_light mt-1">{data.transfer_emp_name}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Employee ID :</div>
                                    <div className="text14_light mt-1">{data.emp_id}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Releasing BHR Name :</div>
                                    <div className="text14_light mt-1">{data.approving_bhr}</div>
                                    <hr />
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Releasing BHR ID</div>
                                    <div className="text14_light mt-1">{data.approving_bhr_id}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Created Date</div>
                                    <div className="text14_light mt-1">{moment(data.created_date_time).format("DD-MM-YYYY")}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Created By</div>
                                    <div className="text14_light mt-1">{data.created_by}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Request Status</div>
                                    <div className="text14_light text-capitalize mt-1">{data.request_status}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Function</div>
                                    <div className="text14_light mt-1">{data.function}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Sub Function 1</div>
                                    <div className="text14_light mt-1">{data.sub_function_1}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Sub Function 2 :</div>
                                    <div className="text14_light mt-1">{data.sub_function_2}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Personnel Area :</div>
                                    <div className="text14_light mt-1">{data.pa}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Personnel Subarea :</div>
                                    <div className="text14_light mt-1">{data.psa}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Time Management Status :</div>
                                    <div className="text14_light mt-1">{data.time_management_status}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Reason For Transfer :</div>
                                    <div className="text14_light mt-1">{data.reason_for_transfer}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                            </div>
                            {data.request_status === 'approved' &&
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                        <div className="text14_bold">Approved By :</div>
                                        <div className="text14_light mt-1">{data.action_taken_by}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                        <div className="text14_bold">Approved Date :</div>
                                        <div className="text14_light mt-1">{moment(data.action_taken_date_time).format("DD-MM-YYYY")}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>}
                            {data.request_status === 'deleted' &&
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                        <div className="text14_bold">Deleted By :</div>
                                        <div className="text14_light mt-1">{data.action_taken_by}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                        <div className="text14_bold">Deleted Date :</div>
                                        <div className="text14_light mt-1">{moment(data.action_taken_date_time).format("DD-MM-YYYY")}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>}
                            {data.request_status === 'rejected' &&
                                <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                    <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                        <div className="text14_bold">Rejected By :</div>
                                        <div className="text14_light mt-1">{data.action_taken_by}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                        <div className="text14_bold">Rejected Date :</div>
                                        <div className="text14_light mt-1">{moment(data.action_taken_date_time).format("DD-MM-YYYY")}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>
                                </div>}
                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Location :</div>
                                    <div className="text14_light mt-1">{data.location}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                {data.swiping_location && <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                    <div className="text14_bold">Swiping Location :</div>
                                    <div className="text14_light mt-1">{data.swiping_location}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>}
                            </div>

                            <div className="d-flex justify-content-between align-item-center w-100 mb-3">
                                <div className="w-50 popup_text text-left  font-weight-bold text-dark">
                                    <div className="text14_bold">Start Date Of Transfer :</div>
                                    <div className="text14_light mt-1">{moment(data.proposed_date_of_transfer).format("DD-MM-YYYY")}</div>
                                    <div className='mt-2'><hr /></div>
                                </div>
                                {data.end_date_of_transfer &&
                                    <div className="font-weight-bold text-dark text-left w-50 popup_text" style={{ paddingLeft: '40px' }}>
                                        <div className="text14_bold">End Date Of Transfer :</div>
                                        <div className="text14_light mt-1">{moment(data.end_date_of_transfer).format("DD-MM-YYYY")}</div>
                                        <div className='mt-2'><hr /></div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default TransferDetailPopup;