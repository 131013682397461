import React, { Component } from 'react';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../conflictOfInterest/COIStyle.css';
import '../../../../assets/styles/popupStyles.css';
import './PaySpaceStyle.css';
import radioOff from '../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../assets/drishti_images/radioOn.png';
import TaxSelectionPopup from './TaxSelectionPopup';
import moment from 'moment';
import { toast } from 'react-toastify';
import edit from '../../../../assets/images/cfm/edit.svg';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import NotePopupPaySpace from './NotePopupPaySpace';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';

const confirming_that = `under Income tax act for computation of TDS on salary. I am also aware that if I opt for Old Tax Regime then I cannot change the same for the`
const warnnNewTaxNote = `Note : You are about to submit New Tax Regime option. If you wish, you can opt for Old Tax Regime later in the current FY, following which further changing of Tax Regime to New Tax Regime will not be possible.`
const warnOldTaxNote = `Note : You are about to submit Old Tax Regime option. Please note that once the employee submits Old Tax Regime option, he/she will not be able to change the tax regime option later in the current financial year.`
const newTaxNote = `Are you sure you want to proceed with New Tax Regime option.`
const oldTaxNote = `Are you sure you want to proceed with Old Tax Regime option.`
class Submission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInstruction: true,
            isRegimeSelection: false,
            radioFilters: [
                {
                    id: 1,
                    title: 'NEW TAX REGIME',
                },
                {
                    id: 2,
                    title: 'OLD TAX REGIME',
                },
            ],
            checked: 0,
            isTextSelectionPopup: false,
            FinancialYear: '',
            EmpAccessForSelection: false,
            empTaxRegimeData: [],
            instructionDetails: [],
            notePopup: false,
            DeclarationConfirm: false,
            isApplicableForTaxRegime: false,
            isEmpRecordPresent: false,
            companyName: '',
            isTaxRegimeSchedule: false,
            employeeReasonData: '',
            isServiceUnavailable: false,  // service unavailble from backend
            serviceUnavailableReason: ''
        }
    }

    componentDidMount() {
        this.checkService()
        this.isApplicableForTaxRegime();
        this.getTaxRegimeInstructions();
        this.fetchTaxRegimeData();
        this.ifDataAlreadyPresent();
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "tax_regime" && item.is_active === false) {
                this.setState({
                    isServiceUnavailable: true,
                    serviceUnavailableReason: item.reason
                })
            }
        })
    }
    isApplicableForTaxRegime = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            Egroup: this.props.loginData.userData.Egroup ? this.props.loginData.userData.Egroup : '',
            Esubgroup: this.props.loginData.userData.Esubgroup ? this.props.loginData.userData.Esubgroup : '',
            employee_type: "White Collar"
        }
        fetchApiCall(Config.isApplicableForTaxRegime, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        isApplicableForTaxRegime: res.data.data.is_applicable_for_tax_regime ? res.data.data.is_applicable_for_tax_regime : false,
                        isEmpRecordPresent: res.data.data.is_emp_data_present ? res.data.data.is_emp_data_present : false,
                        companyName: res.data.data.company_name ? res.data.data.company_name : '',
                        isTaxRegimeSchedule: res.data.data.is_tax_regime_schedule ? res.data.data.is_tax_regime_schedule : false,
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        isApplicableForTaxRegime: false,
                        isEmpRecordPresent: false,
                        companyName: '',
                        isTaxRegimeSchedule: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('isApplicableForTaxRegime error------>', err);
                this.setState({
                    loading: false
                });
            })
    }

    //------------Tax Regima Get Instruction API-----------------------

    getTaxRegimeInstructions = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getTaxRegimeInstructions, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        instructionDetails: res.data.data ? res.data.data : [],
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        instructionDetails: []
                    });
                }
            }).catch(err => {
                console.log('getTaxRegimeInstructions error------>', err);
                this.setState({
                    loading: false
                });
            })
    }

    //------------Tax Regima Get Data API-----------------------

    fetchTaxRegimeData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            employee_type: "White Collar"
        }
        fetchApiCall(Config.fetchTaxRegimeData, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        FinancialYear: res.data.data.fy_year ? res.data.data.fy_year : '',
                        empTaxRegimeData: res.data.data.emp_tax_regime_data ? res.data.data.emp_tax_regime_data : [],
                        EmpAccessForSelection: res.data.data.emp_access_for_selection ? res.data.data.emp_access_for_selection : false,
                    });
                    const recordKeyData = this.state.empTaxRegimeData.map(tempValue => ({ value: tempValue.tax_regime_type }))
                    this.setState({
                        employeeReasonData: recordKeyData[0].value,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        FinancialYear: '',
                        empTaxRegimeData: [],
                        EmpAccessForSelection: false,
                    });
                }
            }).catch(err => {
                console.log('fetchTaxRegimeData error------>', err);
                this.setState({
                    loading: false
                });
            })
    }

    // -----Tax Regima Submit Data API -----

    submitTaxRegimeData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            tax_regime_type: this.state.checked === 0 ? 'new' : 'old',
            comp_name: this.state.companyName ? this.state.companyName : '',
            emp_name: this.props.loginData.userData.CompName ? this.props.loginData.userData.CompName : '',
            financial_year: this.state.FinancialYear ? this.state.FinancialYear : '',
            employee_type: "White Collar"
        }
        fetchApiCall(Config.applyForTaxRegime, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 201 || res.data.status_code === '201') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.fetchTaxRegimeData()
                    this.props.callLanding()
                    this.props.getChoicePayInstructions()
                    this.setState({
                        loading: false,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('submitTaxRegimeData error------>', err);
                this.setState({
                    loading: false
                });
            })
    }

    onPresentType = (key) => {
        this.setState({ checked: key })
    }

    proceed = () => {
        this.setState({ isTextSelectionPopup: true })
    }

    ifDataAlreadyPresent = () => {
        if (this.state.isEmpRecordPresent) {
            this.setState({ isInstruction: false })
        }
        else {
            this.setState({ isInstruction: true })
        }
    }

    close = () => {
        this.setState({
            notePopup: false
        })
    }
    callRefresh = () => {
        console.log("refreshed called ")
        this.isApplicableForTaxRegime();
        this.getTaxRegimeInstructions();
        this.fetchTaxRegimeData();
        this.ifDataAlreadyPresent();
    }
    render() {
        const Firstname = this.props.loginData.userData.Firstname
        const LastName = this.props.loginData.userData.LastName
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                {this.state.isServiceUnavailable == true ?
                    <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                        <ServiceUnavailable reason={this.state.serviceUnavailableReason} width={true} />
                    </div>
                    :
                    <>
                        {this.state.isApplicableForTaxRegime === true ?
                            <>
                                {!this.state.isEmpRecordPresent ?
                                    <>
                                        <div className='justify-content-between mt-3'>
                                            <label className='card-heading mx-4 mt-1'>{"Instructions"}</label>

                                        </div>
                                        {this.state.instructionDetails.map((listValue, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className='row'>
                                                        <h2 className='coi-sub-text mx-4 mr-4 mt-2'>{listValue.line}</h2>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                        <div className='d-flex justify-content-center align-item-center mt-4 mb-4'>
                                            {this.state.isTaxRegimeSchedule &&
                                                <input
                                                    type="button"
                                                    className='btn btn-primary common-btn declaration_button_text px-3'
                                                    value="PROCEED FOR SELECTION"
                                                    onClick={() => this.setState({ isEmpRecordPresent: true, isRegimeSelection: true })}
                                                ></input>
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        {this.state.isEmpRecordPresent && !this.state.isRegimeSelection && !this.state.DeclarationConfirm &&
                                            <div className='mt-3'>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"><div className='thead pr-0 w-35px'>{'Sr No.'}</div></th>
                                                            <th scope="col"><div className='thead'>{'Employee ID'}</div></th>
                                                            <th scope="col"><div className='thead'>{'Name'}</div></th>
                                                            <th scope="col"><div className='thead'>{'Company Name'}</div></th>
                                                            <th scope="col"><div className='thead'>{'Tax Regime Selection'}</div></th>
                                                            <th scope="col"><div className='thead'>{'Date Of Selection'}</div></th>
                                                            <th scope="col"><div className='thead ml-06rem'>{'Action'}</div></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.empTaxRegimeData.map((listValue, index) => {
                                                            return (
                                                                <tr key={index} >
                                                                    <td><div className="td w-20px">{index + 1}</div></td>
                                                                    <td> <div className="td d-flex">{listValue.emp_id ? listValue.emp_id : '-'}</div> </td>
                                                                    <td> <div className="td d-flex">{listValue.emp_name ? listValue.emp_name : '-'}</div> </td>
                                                                    <td> <div className="td d-flex">{listValue.entity ? listValue.entity : '-'}</div> </td>
                                                                    <td><div className="td">{listValue.tax_regime_type ? (listValue.tax_regime_type === 'new' ? "New Tax Regime" : "Old Tax Regime") : '-'}</div></td>
                                                                    <td><div className="td">{listValue.date_of_selection ? moment(listValue.date_of_selection).format("DD-MM-YYYY") : '-'}</div></td>
                                                                    {this.state.EmpAccessForSelection ?
                                                                        <>
                                                                            <td>
                                                                                <div onClick={() => this.setState({ isInstruction: false, isRegimeSelection: true })}>
                                                                                    <img
                                                                                        alt="edit"
                                                                                        src={edit}
                                                                                        className="plus-img"
                                                                                    ></img>
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {listValue.tax_regime_type === 'old' ?
                                                                                <>
                                                                                    <td>
                                                                                        <div>
                                                                                            <img
                                                                                                alt="infoicon"
                                                                                                src={infoicon}
                                                                                                className="info_icon_paySpace"
                                                                                                onClick={() => this.setState({
                                                                                                    notePopup: true
                                                                                                })}
                                                                                            ></img>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>
                                                                                        <div>
                                                                                            {'-'}
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </tr>)
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </>
                                }
                                {this.state.isRegimeSelection &&
                                    <>
                                        <div>
                                            <div className='justify-content-between mt-3 mb-1rem'>
                                                <label className='card-heading mx-4 mt-1'>Tax Regime Selection - {this.state.FinancialYear}</label>
                                            </div>
                                            <div className='d-flex '>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='form-label-text ml-3'>
                                                        {"Employee ID :"}
                                                    </div>
                                                    <div>
                                                        <input className='input-background px-3' type='text' readOnly value={this.props.loginData.userData.Perno}></input>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='form-label-text ml-3'>
                                                        {"Employee Name :"}
                                                    </div>
                                                    <div>
                                                        <input className='input-background px-3' type='text' readOnly value={this.props.loginData.userData.CompName}></input>
                                                    </div>
                                                </div>
                                                <div className='col-md-4 col-sm-6 col-12'>
                                                    <div className='form-label-text ml-3'>
                                                        {"Company Name :"}
                                                    </div>
                                                    <div>
                                                        <input className='input-background px-3' type='text' readOnly value={this.state.companyName}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-1rem'><hr /></div>
                                            <div className='justify-content-between mt-3 mb-1rem'>
                                                <label className='card-heading mx-4 mt-1'>{"Please Select The Regime Option"}</label>
                                            </div>
                                            <div className='d-flex row justify-content-start mt-3 ml-2_5rem'>
                                                {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                                    return (
                                                        <div key={key} className='mx-4 mt-1 mb-2 '>
                                                            {this.state.checked === key ?
                                                                <div onClick={() => this.onPresentType(key)}>
                                                                    <img alt="radioOn" className='radio' src={radioOn} />
                                                                    <label className="radioTxt" >{list.title}</label>
                                                                </div>
                                                                :
                                                                <div onClick={() => this.onPresentType(key)}>
                                                                    <img alt="radioOff" className='radio' src={radioOff} />
                                                                    <label className="radioTxt">{list.title}</label>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                }
                                            </div>
                                            <div className='d-flex justify-content-end align-item-center mt-4 mb-4'>
                                                <input type='button' value='BACK' className='cancelBtn' onClick={() => this.setState({
                                                    DeclarationConfirm: false,
                                                    isTextSelectionPopup: false,
                                                    isRegimeSelection: false,
                                                    isInstruction: false
                                                },
                                                    () => this.callRefresh()
                                                )} />
                                                {this.state.checked === 0 && this.state.employeeReasonData ?
                                                    <>
                                                        <input
                                                            type="button"
                                                            disabled
                                                            className='ml-2 validateBtnDisabled'
                                                            value="SUBMIT"
                                                        ></input>
                                                    </>
                                                    :
                                                    <>
                                                        <input
                                                            type="button"
                                                            className='ml-2 validateBtn'
                                                            value="SUBMIT"
                                                            onClick={() => this.proceed()}
                                                        ></input>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                {this.state.DeclarationConfirm &&
                                    <>
                                        <div className='justify-content-between mt-3'>
                                            <label className='card-heading mx-4 mt-1'>Declaration :</label>
                                        </div>
                                        <div className="row">
                                            <div className=''>
                                                <div className='popup-text mx-4 mt-4 mb-4'>I <span className='mom-common-heading'>{Firstname} {LastName}</span> {'hereby declare that I want to opt for'} <span className='mom-common-heading'>{this.state.checked === 0 ? 'New Tax Regime' : this.state.checked === 1 ? 'Old Tax Regime' : '-'}</span> {confirming_that} <span className='mom-common-heading'>{this.state.FinancialYear}</span>.</div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end align-item-center mt-4 mb-4'>
                                            <input type='button' value='BACK' className='cancelBtn' onClick={() => this.setState({ DeclarationConfirm: false, isTextSelectionPopup: false, isRegimeSelection: true, isInstruction: false })} />
                                            <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.setState({ DeclarationConfirm: false, isTextSelectionPopup: false, isRegimeSelection: false, isInstruction: false }, this.submitTaxRegimeData())} />
                                        </div>
                                    </>
                                }
                                {this.state.isTextSelectionPopup &&
                                    <TaxSelectionPopup
                                        onDismiss={() => this.setState({ isTextSelectionPopup: false })}
                                        warnTaxNote={this.state.checked === 0 ? warnnNewTaxNote : this.state.checked === 1 ? warnOldTaxNote : null}
                                        TaxNote={this.state.checked === 0 ? newTaxNote : this.state.checked === 1 ? oldTaxNote : null}
                                        DeclarationConfirm={() => this.setState({ DeclarationConfirm: true, isTextSelectionPopup: false, isRegimeSelection: false, isInstruction: false })}
                                    />
                                }
                                {this.state.notePopup &&
                                    <NotePopupPaySpace
                                        onDismiss={this.close}
                                        FinancialYear={this.state.FinancialYear}

                                    />
                                }
                            </>
                            :
                            <>
                                <div className='d-flex p-10rem justify-content-center'>
                                    <label className='card-heading mx-4 mt-1'>{'Currently you do not have any access!'}</label>
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(Submission);