import React, { Component } from 'react';
import overviewButton from '../../../../../assets/drishti_images/overview_button.svg';
import transfer from '../../../../../assets/images/transfer/transfer.svg';
import '../../profile/ProfileScreen.css';
import TransferRequestDashboard from './TransferRequestDashboard';
import TransferApprovalScreen from './TransferApprovalScreen';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { toast } from 'react-toastify';

class ApprovalHome extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            appliedRequestData: [],
            receivedRequestdata: [],
            isTransferRequestDashboard: true,
            isTransferApprovalActive: false,
        }
    }
    componentDidMount() {
        this.getDashboardData()
    }
    getDashboardData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno
        }
        fetchApiCall(Config.getTransferDashboardData, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        appliedRequestData: res.data.applied_request_data ? res.data.applied_request_data : [],
                        receivedRequestdata: res.data.received_request_data ? res.data.received_request_data : []
                    });
                } else {
                    toast.warn(res.data.message, { hideProgressBar: true });
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    toggleTransferApprovalDashboard = () => {
        this.setState({ isTransferApprovalActive: true, isTransferRequestDashboard: false })

    }
    toggleTransferRequestDashboard = () => {
        this.setState({ isTransferRequestDashboard: true, isTransferApprovalActive: false },
            // ()=>this.getDashboardData()
        )
    }
    createtransferRequest = () => {
        window.location = (BASE_WEB_URL_DEV + 'transferRequest')
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'home')
    }

    render() {
        return (
            <div className="row p-12px">
                <LoaderIndicator loading={this.state.loading} />
                {this.state.isTransferRequestDashboard || this.state.isTransferApprovalActive ?
                    <div className="col-md-12 px-0 pt-0">
                        <div>
                            <div className='justify-content-between align-item-center'>
                                <div className='row px-0 pt-0' >
                                    <img alt='' src={overviewButton} onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                                </div>
                                <div className='mt-1'>
                                    <a
                                        type="button"
                                        className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-199px'
                                        onClick={() => this.createtransferRequest()}
                                    >
                                        <img alt="icon" src={transfer} className=" mx-1"></img>
                                        INITIATE TRANSFER
                                    </a>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className='card-new col-md-12 mt-2'>
                                    <div className="row">
                                        <div className="d-flex justify-content-between card-title-position">
                                            <div className='mt-1'>
                                                <label className="edit-cardtitle ml-4">Transfer List</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3 px-3 ss col-md-12'>
                                        <div className={this.state.isTransferRequestDashboard ? "tab-active" : "tab-inactive"} onClick={this.toggleTransferRequestDashboard}>
                                            Requests
                                        </div>
                                        <div className={this.state.isTransferApprovalActive ? "tab-active" : "tab-inactive"} onClick={this.toggleTransferApprovalDashboard}>
                                            Approvals
                                        </div>
                                    </div>
                                    <div className='hz-lines mr-4 ml-4'></div>
                                    <div className='profile-inner-card'>
                                        {this.state.isTransferRequestDashboard ?
                                            <TransferRequestDashboard loginData={this.props.loginData} requestData={this.state.appliedRequestData} getDashboardData={this.getDashboardData} /> :
                                            this.state.isTransferApprovalActive ?
                                                <TransferApprovalScreen loginData={this.props.loginData} requestData={this.state.receivedRequestdata} getDashboardData={this.getDashboardData} />
                                                :
                                                <div className="card-new col-md-12 mt-2 align-items-center" >
                                                    <DataNotFound />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default withRouter(connect(mapStatesToProps, {})(ApprovalHome));