import React, { useEffect, useState } from 'react'
import LargeDropdown from '../../../shared/components/LargeDropdown'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { toaster } from '../../../../utils/Utils'
import ClearanceTable from './ClearanceTable'
import { agentConfigCols, selectedOptionStyle } from './constants'
import { checkAgencyAgentMapping } from '../../../../constants/api.service'

function AgentConfig(props) {
    const {
        editMode,
        agentList,
        selectedAgent,
        handleAgentFilter,
        agencyList,
        selectedAgency,
        handleAgencyFilter,
        compCodeList,
        handleCompanyFilter,
        selectedCompany,
        paList,
        selectedPA,
        handlePAFilter,
        psaList,
        selectedPSA,
        handlePSAFilter,
        gradeList,
        selectedGrade,
        handleGradeFilter,
        setIsConfirmPopupOpen,
        agent1,
        agent2,
        handleAgent1,
        handleAgent2,
        option,
        setOption,
        clearAllFields
    } = props
    const [page, setPage] = useState(1)
    const [message, setMessage] = useState('')
    const [Loading, setLoading] = useState(false)
    const [configList, setConfigList] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)
    const back = () => { setPage(1); setIsDisabled(false); clearAllFields() }
    const validateAgentConfig = () => {
        if ((option == "AddNew" && (!selectedAgency.id || !selectedCompany.comp_code || !selectedPSA.value || !selectedPA.value || !selectedAgent.value || !selectedGrade || selectedGrade.length == 0)) || (option == "Duplicate" && (!agent1 || !agent2))) {
            toaster("warning", "Please fill all mandatory fields")
            setIsConfirmPopupOpen(false);
            return false
        }
        else return true
    }
    const handleNext = () => {
        if (page == 3) { setIsConfirmPopupOpen(true) }
        else if (page == 1 && !editMode) { setPage(2) }
        else if (option == "AddNew" || option == "Duplicate") {
            if (validateAgentConfig()) {
                // if (editMode) { setIsConfirmPopupOpen(true) }
                // else {
                //     setPage(3)
                //     getAgentAgencyConfigDetails()
                // }
                setIsConfirmPopupOpen(true)
            }
        }
        else {
            setIsConfirmPopupOpen(true)
        }
    }
    const handleOption = (option) => {
        setOption(option)
        handleNext()
    }
    const getAgentAgencyConfigDetails = () => {
        let params = {
            "comp_code": selectedCompany.comp_code,
            "pers_area": selectedPA.pa,
            "pers_sub_area": selectedPSA.value,
            "agent_id": selectedAgent.value,
            "grade_grp_name": selectedGrade.value,
            "agency_id": selectedAgency.id
        }
        checkAgencyAgentMapping(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        setConfigList(res.data.data)
                        setMessage(res.data.message)
                        res.data?.is_disabled && setIsDisabled(true)
                    }
                    else {
                        setConfigList([])
                        setMessage('')
                        setIsDisabled(true)
                    }
                }
            ).catch((err) => {
                setConfigList([])
                setMessage('')
                setIsDisabled(true)
                console.log("check_agent_agency_config error", err)
            }).finally(() => setLoading(false))
    }
    return (
        <div>
            <LoaderIndicator loading={Loading} />
            <div className="column scroll-y h-42vh">
                {
                    page == 2 || editMode ?
                        option == "Duplicate" ?
                            <>
                                <div className="row mt-10px">
                                    <div className='p-sub-heading ml-3 required'>Select source agent</div>
                                    <div className="col-12 mb-2">
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={agentList.filter((agent) => agent != agent2)}
                                                value={agent1?.label ?? "Select agent"}
                                                selectedValue={(data) => handleAgent1(data)}
                                                noOptionFound={"No Data Found"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-10px">
                                    <div className='p-sub-heading ml-3 required'>Select target agent</div>
                                    <div className="col-12 mb-2">
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={agentList.filter((agent) => agent != agent1)}
                                                value={agent2?.label ?? "Select agent"}
                                                selectedValue={(data) => handleAgent2(data)}
                                                noOptionFound={"No Data Found"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="row mt-10px">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select Agent`}</div>
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={agentList}
                                                value={selectedAgent?.label ?? "Select agent"}
                                                selectedValue={(data) => handleAgentFilter(data)}
                                                noOptionFound={"No Data Found"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select Agency`}</div>
                                        <div className='p-placeholder-text'>
                                            <DynamicDropdown
                                                data={agencyList}
                                                render="name"
                                                value={selectedAgency.name}
                                                selectedValue={(data) => handleAgencyFilter(data)}
                                                arrow={'true'}
                                                height={'NotificationHeight'}
                                                placeholder={"Select Agency"}
                                                disabled={editMode}
                                            />
                                        </div>
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Enter valid domain id to fetch agency list'}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select Company`}</div>
                                        <div className='p-placeholder-text'>
                                            <DynamicDropdown
                                                data={compCodeList}
                                                render={"company_name"}
                                                value={selectedCompany.company_name}
                                                selectedValue={(data) => handleCompanyFilter(data)}
                                                arrow={'true'}
                                                height={'NotificationHeight'}
                                                placeholder={"Select Company"}
                                                disabled={editMode}
                                            />
                                        </div>
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select agency to fetch company list'}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select PA`}</div>
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={paList}
                                                value={selectedPA.value ?? "Select PA"}
                                                selectedValue={(data) => handlePAFilter(data)}
                                                disabled={editMode}
                                                noOptionFound={"No Data Found"}
                                            />
                                        </div>
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select Company to fetch Pers.Area list'}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select PSA`}</div>
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={psaList}
                                                value={selectedPSA.value ?? "Select PSA"}
                                                selectedValue={(data) => handlePSAFilter(data)}
                                                disabled={editMode}
                                                noOptionFound={"No Data Found"}
                                            />
                                        </div>
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select Pers.Area to fetch Pers.SubArea list'}</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        <div className='p-sub-heading ml-3 required'>{`Select Grade Group`}</div>
                                        <div className='p-placeholder-text'>
                                            <LargeDropdown
                                                data={gradeList}
                                                value={!editMode ?
                                                    selectedGrade.length > 0 ? selectedGrade : "Select Grade Group" : selectedGrade?.value ?? "Select Grade Group"}
                                                selectedValue={(data) => handleGradeFilter(data)}
                                                disabled={editMode}
                                                noOptionFound={"No Data Found"}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                customStyles={selectedOptionStyle}
                                            />
                                        </div>
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select Pers.SubArea to fetch Grade-group list'}</span>
                                        </label>
                                    </div>
                                </div>
                            </> :
                        page == 1 ?
                            <div className='flex-direction-column justify-content-center h-100 align-item-center gap-10'>
                                Select Option
                                <input type='button'
                                    value={"Add new Configuration"}
                                    className={`ml-2 validateBtn w-60`}
                                    disabled={isDisabled}
                                    onClick={() => handleOption("AddNew")} />
                                <input type='button'
                                    value={"Duplicate configuration"}
                                    className={`ml-2 validateBtn w-60`}
                                    disabled={isDisabled}
                                    onClick={() => handleOption("Duplicate")} />
                            </div> :
                            <>
                                <div className="row gap-10 mt-10">
                                    <div className="col-12">
                                        <span className='p-sub-heading errorBlock'>{message ?? ""}</span>
                                    </div>
                                    {
                                        configList.length > 0 &&
                                        <div className='column'>
                                            <ClearanceTable
                                                data={configList}
                                                screenType={"Master Maintenance"}
                                                columns={agentConfigCols}
                                                handleAction={() => { }}
                                                hasPrimaryAction={false}
                                                hasSecondaryAction={false}
                                                hasAction={false}
                                                handleSecondaryAction={() => { }}
                                                scrollEnabled={false}
                                            />
                                        </div>
                                    }
                                </div>
                            </>
                }
            </div>
            <div className={'modal-footer btnBox min-h70px' + (editMode ? ' justify-content-end' : ' justify-content-between')}>
                {
                    !editMode &&
                    <div className='justify-content-start'>
                        <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>{page}</span>/2</div>
                    </div>
                }
                <div className='justify-content-around'>
                    {
                        (page != 1 || editMode) &&
                        <>
                            {
                                !editMode &&
                                <div className='justify-content-start'>
                                    <input type='button'
                                        value='BACK'
                                        className='popup-back-btn popup-back-text'
                                        onClick={back} />
                                </div>
                            }
                            <div className='justify-content-start'>
                                <input type='button'
                                    value={(!editMode && (page == 2 || page == 3)) ? "NEXT" : "SUBMIT"}
                                    className={`ml-2 ${isDisabled ? "disableButtonStyle" : "validateBtn"}`}
                                    disabled={isDisabled}
                                    onClick={handleNext} />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default AgentConfig