import React from 'react'
import './Clearance.css'
function DateFilter(props) {
    const { action, title, value, minDate, maxDate } = props
    return (
        <div className='flex-direction-row gap-10px align-item-center'>
            <div className='p-sub-heading ml-2'>{title}</div>
            <div className='row align-item-center'>
                <div className='p-placeholder-text'>
                    <input
                        className="form-control p-placeholder px-2 p-placeholder-admin"
                        type="date"
                        placeholder='Select the date'
                        onChange={(e) => action(e.target.value)}
                        value={value}
                        onKeyDown={(e) => e.preventDefault()}
                        min={minDate}
                        max={maxDate}
                    />
                </div>
            </div>
        </div>
    )
}

export default DateFilter