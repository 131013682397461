import React, { Component } from 'react'
import "react-circular-progressbar/dist/styles.css";
import './LeaveScreen.css'
import prev from '../../../../assets/images/applyLeave/backArrow.png'
import next from '../../../../assets/images/applyLeave/nextArrow.png'
import 'antd/dist/antd.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { Progress } from 'antd';
import DataNotFound from '../../../shared/notFound/DataNotFound'
import QuotaDetailPopup from './QuotaDetailPopup';
class LeaveQuota extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            quotatDetails: [],
            settings: {},
            isPopupOpen: false,
            clickItem: [],
            start: 0,
            end: 3
        }
    }

    componentDidMount() {
        this.getQuotaDetailsData();
    }

    getQuotaDetailsData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            psa: this.props.loginData.userData.PSubarea,
            pa: this.props.loginData.userData.PersArea
        }
        fetchApiCall(Config.getLeaveQuota, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200' || res.data.status_code === '300') {
                    this.setState({
                        loading: false,
                        quotatDetails: res.data.leave_quota_details ? res.data.leave_quota_details : [],
                    });
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            }).catch(err => {
                console.log(err)
                this.setState({
                    loading: false
                })
            })
    }

    clickOnQuata = (item, isPopupOpen) => {
        this.setState({
            clickItem: item,
            isPopupOpen: isPopupOpen
        })
    }

    closePopUp = () => {
        this.setState({
            isPopupOpen: false
        })
    }

    render() {
        return (
            <div className='row card-new width100 boxUpcomingLeaves'>
                <div className='width100'>
                    <div className='row mt-3 card-content-title marginleft'>
                        Leave Quota
                    </div>
                    {this.state.quotatDetails.length !== 0 ?
                        <div className='d-flex justify-content-between align-item-center pointer'>
                            <div className='d-flex justify-content-start align-item-center ml-3 marRight'>
                                <img
                                    src={prev}
                                    className='pre-next-icon'
                                    alt=''
                                    onClick={() => this.setState({
                                        start: this.state.start - 1 < 0 ? this.state.start : this.state.start - 1,
                                        end: this.state.start - 1 < 0 ? this.state.end : this.state.end - 1
                                    })}
                                />
                            </div>
                            <div className='d-flex align-item-center mt-2 leave-quota-circle'>
                                {this.state.quotatDetails.slice(this.state.start, this.state.end).map((item, index) =>
                                    <div key={index} className='flex-direction-column align-item-center leave-quota-space mt-4'
                                        onClick={() => this.clickOnQuata(item, true)}
                                    >
                                        <div>
                                            <Progress
                                                type="circle"
                                                strokeColor={{
                                                    '0%': (item.leave_category_color).split(' ')[1],
                                                    '100%': (item.leave_category_color).split(' ')[3],
                                                }}
                                                trailColor={'#F1F5F9'}
                                                strokeWidth={4}
                                                width={100}
                                                status={'normal'}
                                                percent={((item.available_leaves / item.total_leaves) * 100).toFixed(1)}
                                                format={() => `${(item.available_leaves) + `/` + (item.total_leaves)}`}
                                            />
                                        </div>
                                        <div className='leaveQuotaName leaveQuotaTxt' >
                                            {item.leave_quota_text}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex justify-content-end align-item-center mr-3 marLeft pointer'>
                                <img
                                    src={next}
                                    alt=''
                                    className='pre-next-icon'
                                    onClick={() => this.setState({
                                        start: this.state.quotatDetails.length > this.state.end ? this.state.start + 1 : this.state.start,
                                        end: this.state.quotatDetails.length > this.state.end ? this.state.end + 1 : this.state.end
                                    })}
                                />
                            </div>
                        </div>
                        :
                        <div className='marginLeaveLR'>
                            <DataNotFound />
                        </div>
                    }
                </div>
                {this.state.isPopupOpen &&
                    <QuotaDetailPopup
                        data={this.state.clickItem}
                        onDismiss={this.closePopUp}
                    />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(LeaveQuota)