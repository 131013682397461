import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import Dropdown from '../../../shared/components/Dropdown';
import Papa from "papaparse";
import './DevToolStyles.css';

class SetToDeleteStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            typeData: [
                { code: '01', value: "By Leave ID" },
                { code: '02', value: "By File " }
            ],
            typeValue: 'Select',
            typeCode: '',
            leaveID: '',
            specificLeave: ''
        }
        this.updateData = this.updateData.bind(this);
    }
    componentDidMount() {
    }

    updateData(result, file) {
        if (file.type === "text/csv") {
            console.log('result', result)
            const updateData = result.data; // Here this is available and we can call this.setState (since it's binded in the constructor)

            console.log('specificLeave------------', updateData)
            console.log('result.dataresult.dataresult.dataresult.data', result.data)
            this.setState({ specificLeave: updateData.slice(0, -1) }); // or shorter ES syntax: this.setState({ data });
            // console.log('specificLeave', updateData)
            console.log('specificLeave', updateData.slice(0, -1))
            console.log("this.state.specificLeave", this.state.specificLeave)
            var myJsonString = JSON.stringify(updateData.slice(0, -1));
            console.log("myJsonString", myJsonString)
            console.log("myJsonString spl  ", this.state.specificLeave.length)

            const tempArray = [];

            this.state.specificLeave.forEach(e => {
                tempArray.push(e[0])
            });
            console.log("tempArray", tempArray)
            if (tempArray[0] !== '') {
                const tempArray2 = [];
                tempArray.forEach(e => {
                    console.log("e : ", e)
                    tempArray2.push({ "emp_id": e })
                });
                console.log("temspArray2", tempArray2)

                this.setState({
                    specificLeave: tempArray2
                })
                console.log("this.state.specificLeave", this.state.specificLeave)
            }
            else {
                this.setState({
                    specificLeave: "null"
                })
            }
        }
        else {
            toast.warn("Please upload CSV file only", {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })

            this.setState({
                specificLeave: ''
            })
        }
    }

    startSetToDeleteJob = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        var body = {}
        if (this.state.typeCode === '01') {
            body = {
                emp_id: this.state.leaveID
            }
        }
        else {
            body = this.state.specificLeave
        }
        console.log("setToDeletedStatus body ", body)
        fetchApiCall(Config.setToDeletedStatus, body, headers, false, 'admin')
            .then(res => {
                console.log("setToDeletedStatus res", res)
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        typeValue: 'Select',
                        typeCode: '',
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    changeType = (data) => {
        console.log("data : ", data)
        this.setState({
            typeValue: data.value,
            typeCode: data.code
        })
    }

    handleChange = (e) => {
        console.log("e :   ", e)
        this.setState({
            leaveID: e.target.value
        })
    }

    loadData = () => {
        console.log("this.state.typeCode", this.state.typeCode)
        console.log("this.state.leaveID", this.state.leaveID)

        if (this.state.typeCode === '') {
            toast.warn('Please select type  ', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.typeCode === '01') {
            if (this.state.leaveID === '') {
                toast.warn('Please Enter Leave ID ', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.startSetToDeleteJob()
            }
        }
        else {
            if (this.state.specificLeave === 'null') {
                toast.warn('file is empty/check file format', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else if (this.state.specificLeave === '') {
                toast.warn('Please upload CSV file', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }
            else {
                this.startSetToDeleteJob()
            }
        }
    }

    render() {
        return (
            <div className='h-60vh'>
                <div className="row">
                    <LoaderIndicator loading={this.state.loading} />
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'Set to delete status'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-sub-heading ml-3 mb-1'>{'Select Type'} </div>
                                <div className='p-placeholder-text widthMonth'>
                                    <Dropdown
                                        data={this.state.typeData}
                                        selectedValue={(data) => this.changeType(data)}
                                        value={this.state.typeValue}
                                        render={'value'}
                                        arrow={'true'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.typeCode === '01' &&
                        <div className="col-md-12 px-0" >
                            <div className='row align-item-center'>
                                <div className='mt-3 ml-4'>
                                    <div className='p-sub-heading ml-3 mb-1'>{'Leave ID'}</div>
                                    <div className='p-placeholder-text widthMonth'>
                                        <input
                                            className="form-control p-placeholder px-3"
                                            placeholder="Enter Leave ID"
                                            type="text"
                                            // maxLength={8}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.typeCode === '02' &&
                        <div className="col-md-12 px-0" >
                            <div className='row align-item-center'>
                                <div className='mt-3 ml-4'>
                                    <div className='p-sub-heading ml-3 mb-1'>{'Attach CSV File'}</div>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className='inpt'
                                            type="file"
                                            accept=".csv"
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                console.log("files : ", files);
                                                if (files) {
                                                    console.log("files[0] : ", files[0]);
                                                    Papa.parse(files[0], {
                                                        complete: this.updateData
                                                    })
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 px-0" >
                        <div className='row align-item-center'>
                            <div className='mt-3 ml-4'>
                                <div className='p-placeholder-text widthMonth'>
                                    <div
                                        type='button'
                                        className='save-btn save-txt'
                                        onClick={() => this.loadData()}
                                    >Set to delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default SetToDeleteStatus;