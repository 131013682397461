import React, { Component } from 'react';
import moment from 'moment';

const agreeMessage = 'Kindly confirm that the details for the employee and position code are correctly updated (for any issues reach out to TMLBSL team via RMA for further support). Once verified, please click on the check box and “Save & Proceed” to move further to the Transfer Details form.'
class ApplyPhase2 extends Component {
    constructor() {
        super()
        this.state = {
            checked: false,
            newPositionData: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            newPositionData: nextProps.newPositionData,
        });
    }

    render() {
        return (
            <div className="justify-content-between">
                <div className="col-md-12 px-0" >
                    <div>
                        <div className='row justify-content-between'>
                            <div className='p-heading mx-4 mt-2 mb-2'>Check new position details</div>
                        </div>
                        <div className='row'>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Position Code</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.position1}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Transfer Date</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={moment(this.state.newPositionData.date).format("DD-MM-YYYY")}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Position BHR ID</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.bhr_pernr}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Position BHR Name</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.bhr_name}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Reporting Manager ID</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.dem_pernr}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Reporting Manager Name</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.dem_name}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>FEM ID</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.fem}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>FEM Name</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.fem_name}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Unique Job Role</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.jobtext}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Level</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.jobtext_level}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Job Code</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.job_code}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Job Post</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.postxt}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Immediate Org Unit Text</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.immediate_ou}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>OU1 Text</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.immediate_ou_level_1}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>OU2 Text</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.immediate_ou_level_2}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>OU3 Text</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.immediate_ou_level_3}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Sales Incentive</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.sales_incentive}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className='col-4 mb-3 mt-2'>
                                <div className='p-sub-heading ml-3'>Vacant</div>
                                <div className='p-placeholder-text'>
                                    <input
                                        className="form-control p-placeholder px-3"
                                        type='text'
                                        value={this.state.newPositionData.vacant}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='mb-3 mx-4'>
                                <div className='form-agree-txt d-flex align-items-start'>
                                    <input
                                        type="checkbox"
                                        className='mx-2 mt-1'
                                        value={this.state.checked}
                                        onChange={() => this.setState({ checked: !this.state.checked })}
                                    />
                                    {agreeMessage}
                                </div>
                            </div>
                            {this.state.checked &&
                                <div className='row justify-content-end'>
                                    <div
                                        type='button'
                                        className='save-btn save-txt'
                                        onClick={() => this.props.confirmPositionData()}
                                    // disabled={!this.state.checked}
                                    >PROCEED</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ApplyPhase2