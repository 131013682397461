import React, { Component } from 'react';
import { connect } from 'react-redux';

class WelcomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className="card-new h-100" >
                <div className="welcomePage">
                    Welcome To DRISHTI 2.0 Admin Portal
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(WelcomePage)