import React, { Component } from 'react';
import Dropdown from '../../../shared/components/Dropdown';
import { CalenderMonthWithAll } from '../../../../utils/Utils';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
class LeaveSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfmData: CalenderMonthWithAll(),
            monthValue: 'All',
            monthCode: 'All',
            casual_leave: '',
            privilege_leave: '',
            sick_leave: '',
            pl_state_specific_leave: '',
            sl_cl_leave: '',
            sl_cl_state_specific_leave: '',
        }
    }
    componentDidMount() {
        this.getEmployeeLeaveSummary();
    }

    getEmployeeLeaveSummary = () => {
        console.log("get emp summary called ")
        this.setState({
            loading: true, showConfirm: false
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            month: this.state.monthCode
        }
        fetchApiCall(Config.getEmployeeLeaveSummary, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == '200') {
                    this.setState({
                        loading: false,
                        casual_leave: res.data.data.casual_leave,
                        privilege_leave: res.data.data.privilege_leave,
                        sick_leave: res.data.data.sick_leave,
                        sl_cl_state_specific_leave: res.data.data.sl_cl_state_specific_leave,
                        sl_cl_leave: res.data.data.sl_cl_leave,
                        pl_state_specific_leave: res.data.data.pl_state_specific_leave,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    changeMonth = (data) => {
        console.log("data", data)
        this.setState({
            monthCode: data.code,
            monthValue: data.value,
        },
            () => this.getEmployeeLeaveSummary()
        )
    }

    render() {
        return (
            <div className="card-new h-100 pr-2" >
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Leave Summary</label>
                        </div>
                        <div className='mt-2 justify-content-end'>
                            <div>
                                <Dropdown
                                    data={this.state.cfmData}
                                    selectedValue={(data) => this.changeMonth(data)}
                                    value={this.state.monthValue}
                                    render={'value'}
                                    arrow={'true'}
                                    height={'NotificationHeight'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class='leaveSummaryContainer mx-1'>
                    <div class='leave-pinkColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading'>{this.state.privilege_leave}</div>
                            <div class='detail-lable-head'>Personal Leave</div>
                        </div>
                        <div class='bottom-Border-pink'></div>
                    </div>
                    <div class='leave-blueColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading'>{this.state.casual_leave}</div>
                            <div class='detail-lable-head'>Casual Leave</div>
                        </div>
                        <div class='bottom-Border-green'></div>
                    </div>
                    <div class='leave-redColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading'>{this.state.sick_leave}</div>
                            <div class='detail-lable-head'>Sick Leave</div>
                        </div>
                        <div class='bottom-Border-red'></div>
                    </div>
                    <div class='leave-lightGreenColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading px-2'>{this.state.pl_state_specific_leave}</div>
                            <div class='detail-lable-head'>PL State Specific</div>
                        </div>
                        <div class='bottom-Border-light'></div>
                    </div>
                    <div class='leave-yellowColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading px-2'>{this.state.sl_cl_leave}</div>
                            <div class='detail-lable-head'>SL+CL</div>
                        </div>
                        <div class='bottom-Border-yellow'></div>
                    </div>
                    <div class='leave-darkPinkColor mx-3'>
                        <div class="content-wrapper">
                            <div class='basic-card-heading px-2'>{this.state.sl_cl_state_specific_leave}</div>
                            <div class='detail-lable-head'>SL+PL State Specific</div>
                        </div>
                        <div class='bottom-Border-darkpink -pb-3'></div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}
export default connect(mapStatesToProps)(LeaveSummary);