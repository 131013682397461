import React, { Component } from 'react'
import delete_icon from '../../../../assets/drishti_images/transfer/delete_action.svg';
import { objvalueLimits } from '../../../ScreenType/employeeScreens/gems/Constant';
import edit_icon from '../../../../assets/drishti_images/profile/edit.svg';
import CharacterCounter from '../../../shared/CharacterCounter';
import { getMTRFR, deleteMTRFR, deleteObjectives } from '../../../ScreenType/employeeScreens/gems/Gems.api.service';
import SuccessButton from '../../../shared/components/commonButton/SuccessButton';
import CancelButton from '../../../shared/components/commonButton/CancelButton';
import { toaster } from '../../../../utils/Utils';
class MTRFRObjectivePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditable: false,
            data: []
        }
    }

    componentDidMount() {
        getMTRFR(this.props.id, this.props, 'admin')
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        data: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    this.setState({
                        data: []
                    })
                }
            })
            .catch((err) => {
                console.log("getMTRFR catch error>>", err)
            })
    }

    handleAddRow = (UpdateObjKey) => {
        console.log('UpdateObjKey------->', UpdateObjKey);
        const newRow = { deliverable: "", target: "" };
        console.log('newRow------->', newRow);
        this.setState({
            data: [...this.state.data, newRow]
        }, () => this.props.updateMTRFR(this.state.data, UpdateObjKey))
    };

    handleDeleteRow = (index, dataValue) => {
        const newData = [...this.state.data];
        newData.splice(index, 1);
        this.setState({
            data: newData
        })
        deleteObjectives(this.props.id, dataValue.id, "admin")
            .then((res) => {
                console.log("handleDeleteRow res>>", res)
                if(res.status === 200 || res.status === 201){
                    toaster("success", res.data.message ? res.data.message : "")
                }else{
                    toaster("success", res.data.message ? res.data.message : "Mid-term/Final Review objective deleted successfully.")
                }
            })
            .catch((err) => {
                toaster("", err.data.message ? err.data.message : "")
                console.log("catch handleDeleteRow err>>", err)
            })
    };

    render() {
        const { updateMTRFR, isEditable } = this.state
        // const { isEditable } = this.state
        console.log('{updateMTRFR &&------->', updateMTRFR);
        console.log('{isEditable &&------->', isEditable);
        return (
            <form className="modal popup-box" onSubmit={this.requestValidate}>
                <div className="popupCard-box p-0 w-62-percent">
                    <div className="modal-header">
                        <h4 className="modal_title black">Confirmation</h4>
                        <button type="button" className="close" data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;</button>
                    </div>
                    <div>
                        <div className='justify-content-end'>
                            {isEditable ?
                                <button className='btn btn-primary login-btn py-2 m-10px'
                                    disabled={this.state.data.length > 4 ? true : false}
                                    onClick={() => this.handleAddRow("UpdateObj")}
                                    type='button'
                                >
                                    +&nbsp;ADD NEW OBJECTIVE
                                </button>
                                :
                                <img
                                    src={edit_icon}
                                    className='pointer m-10px'
                                    onClick={() => this.setState({
                                        isEditable: true
                                    })}
                                />
                            }
                        </div>
                        <div className='flex-direction-row noProject-style p-10px mx-10px'>
                            <span className='performance-timeline-text-bold p-10px w-10 '>Sr. No</span>
                            <span className='performance-timeline-text-bold p-10px w-45-percent'>Deliverable</span>
                            <span className='performance-timeline-text-bold p-10px w-45-percent'>Target</span>
                            {this.state.data.length !== 1 && (isEditable) &&
                                <span className='performance-timeline-text-bold p-10px w-10'>Action</span>
                            }
                        </div>
                        <div className='flex-direction-column p-10px mx-10px h-42vh scroll-y'>
                            {this.state.data.map((dataValue, idx) =>
                                <span key={idx} className='flex-direction-row w-100 align-item-center-noflex'>
                                    <span className='w-7 m-10px performance-timeline-text-circle'>{idx + 1}</span>
                                    <span className='w-45-percent m-10px'>
                                        <textarea
                                            className="form-control popup-placeholder px-3"
                                            type="text"
                                            maxLength={objvalueLimits.maxlenght}
                                            disabled={!isEditable}
                                            onChange={(e) => {
                                                const newData = [...this.state.data];
                                                newData[idx].deliverable = e.target.value;
                                                this.setState({ data: newData })
                                                this.props.updateMTRFR(this.state.data)
                                            }}
                                            value={dataValue.deliverable}
                                            placeholder={'Enter Project Deliverable'}
                                        />
                                        <CharacterCounter
                                            totalCount="200"
                                            currentCount={dataValue.deliverable.length}
                                        />
                                    </span>
                                    <span className='w-45-percent m-10px'>
                                        <textarea
                                            className="form-control popup-placeholder px-3"
                                            type="text"
                                            maxLength={objvalueLimits.maxlenght}
                                            disabled={!isEditable}
                                            onChange={(e) => {
                                                const newData = [...this.state.data];
                                                newData[idx].target = e.target.value;
                                                this.setState({ data: newData })
                                                this.props.updateMTRFR(this.state.data)
                                            }}
                                            value={dataValue.target}
                                            placeholder={'Enter Project Target'}
                                        />
                                        <CharacterCounter
                                            totalCount="200"
                                            currentCount={dataValue.target.length}
                                        />
                                    </span>
                                    {this.state.data.length !== 1 && (isEditable) &&
                                        <span className='w-10'>
                                            <img alt="delete"
                                                src={delete_icon}
                                                className='h-20px'
                                                onClick={() => this.handleDeleteRow(idx, dataValue)}
                                            />
                                        </span>
                                    }
                                </span>
                            )}
                        </div>
                        <div className='modal-footer btnBox'>
                            {!isEditable ?
                                <CancelButton
                                    type={'button'}
                                    value={'CLOSE'}
                                    onClick={this.props.onDismiss}
                                />
                                :
                                <>
                                    <CancelButton
                                        type={'button'}
                                        value={'CANCEL'}
                                        onClick={() => this.setState({ isEditable: false })}
                                    />
                                    <SuccessButton
                                        type={'button'}
                                        value={'SUBMIT'}
                                        onClick={() => this.props.submit('submit', this.state.data)}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </form >
        )
    }
}
export default MTRFRObjectivePopup