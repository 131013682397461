import React from 'react'

function AgentMaster(props) {
    const {
        setDomainId,
        domainId,
        editMode,
        setAgentFirstName,
        setAgentLastName,
        agentFirstName,
        agentLastName,
        handleMobileNo,
        mobileNo,
        mobileNoError,
        emailIdError,
        handleEmailId,
        email,
        setIsConfirmPopupOpen
    } = props
    return (
        <div>
            <div className='column mt-3 scroll-y h-42vh'>
                <div className='toggle-card-heading ml-10 mb-10'>{`Basic Details`}</div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Domain id`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setDomainId(e.target.value) }}
                                value={domainId}
                                placeholder={"Enter the domain id"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className='p-sub-heading ml-3 required'>{`Agent First Name`}</div>
                            <div className='p-placeholder-text mb-2'>
                                <input
                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                    type="text"
                                    onChange={(e) => { setAgentFirstName(e.target.value) }}
                                    value={agentFirstName}
                                    placeholder={"Enter the first name"}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className='p-sub-heading ml-3'>{`Agent Last Name`}</div>
                            <div className='p-placeholder-text mb-2'>
                                <input
                                    className="form-control p-placeholder p-placeholder-admin px-2"
                                    type="text"
                                    onChange={(e) => { setAgentLastName(e.target.value) }}
                                    value={agentLastName}
                                    placeholder={"Enter the last name"}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </div>
                </>
                <>
                    <div><hr /></div>
                    <div className='toggle-card-heading ml-10 mb-10'>{`Other Details`}</div>
                </>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='p-sub-heading ml-3 required'>{`Mobile no./ Phone no.`}</div>
                        <div className='p-placeholder-text mb-1'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { handleMobileNo(e) }}
                                value={mobileNo}
                                placeholder={"Enter the mobile / phone number"}
                                maxLength={10}
                            />
                        </div>
                        {!mobileNoError.isValid && <span className='p-sub-heading ml-3 errorBlock'>{mobileNoError.msg}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='p-sub-heading ml-3 required'>{`Email id`}</div>
                        <div className='p-placeholder-text mb-1'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { handleEmailId(e) }}
                                value={email}
                                placeholder={"Enter the email id"}
                                maxLength={50}
                            />
                        </div>
                        {!emailIdError.isValid && <span className='p-sub-heading ml-3 errorBlock'>{emailIdError.msg}</span>}
                    </div>
                </div>
            </div>
            <div className='modal-footer btnBox justify-content-end'>
                <div className='justify-content-start'>
                    <input type='button'
                        value='SUBMIT'
                        className='ml-2 validateBtn'
                        onClick={() => { setIsConfirmPopupOpen(true) }} />
                </div>
            </div>
        </div>
    )
}

export default AgentMaster