import React, { Component } from 'react';
import common_no_data from '../../../assets/drishti_images/common_no_data.svg';
class DataNotFound extends Component {
    render() {
        return (
            <div className='col-md-12 d-flex align-items-center flex-column mb-10px'>
                <div>
                    <img src={common_no_data} alt="Nodata" />
                </div>
                <div>
                    <label className='card-content-title grey-color'>{this.props.reason ? this.props.reason : 'No data found'}</label>
                </div>
            </div>
        )
    }
}

export default DataNotFound
