import React, { useState } from 'react';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';

const EditPopup = ({ onUpdate, onCancel }) => {

    const [workingHours, setWorkingHours] = useState('00:30');
    const [error, setError] = useState(false);
    const [Hrs, setHrs] = useState('')
    const [Min, setMin] = useState('')
    const isNumeric = (value) => {
        return /^\d*$/.test(value); // Regex to check if the value is a number (including empty string)
    };
    const isValidHour = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 23));
    const isValidMinute = (value) => isNumeric(value) && (value === '' || (parseInt(value, 10) >= 0 && parseInt(value, 10) <= 59));

    const isValidInput = (value) => {
        // Handle case where value is undefined (no input provided)
        if (value === undefined) {
            return false;
        }

        // Allow empty input without triggering an error
        if (value.trim() === "") return true;

        // Split the input into parts based on colon
        const parts = value.split(':');
        if (parts.length !== 2) {
            // Must be exactly two parts for HH:MM
            return false;
        }

        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);

        // Validate hours as non-negative integers
        if (isNaN(hours) || hours < 0) {
            return false;
        }

        // Validate minutes between 00 and 59
        if (isNaN(minutes) || minutes < 0 || minutes > 59 || parts[1].length !== 2) {
            return false;
        }

        // Consider only hours greater than 0:30 (30 minutes)
        if (hours === 0 && minutes < 30) {
            return false;
        }

        return true;
    };



    const handleUpdate = () => {

        if (isValidInput(workingHours)) {
            onUpdate(Hrs || workingHours.split(':')[0], Min || workingHours.split(':')[1]);
            setError(false); // Clear error on successful update
        } else {
            setError(true); // Set the error if validation fails
        }
    };

    const handleWorkingHoursChange = (newHour, newMinute) => {
        const newValue = `${newHour}:${newMinute}`;
        setWorkingHours(newValue);
        if (!isValidInput(newValue)) {
            setError(true);
        } else {
            setError(false);
        }
        setWorkingHours(newValue);
    };

    return (
        <div className="modal popup-box">
            <div className="declaration-box p-0 edit-popup" style={{ width: '30%' }}>
                <div className="modal-header">
                    <h4 className="modal_title black">Update OT</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={onCancel}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className='flex-direction-column justify-content-center p-3'>
                        <div className="popup-content">
                            <div className="form-group">
                                <label htmlFor="workingHours">Working Hours (HH:MM)</label>

                                {/* <div className='d-flex align-items-center'>
                                  <DynamicDropdown
                                    data={hours}
                                    selectedValue={(selectedItem) => { setHrs(selectedItem);  handleWorkingHoursChange(selectedItem, Min || workingHours.split(':')[1]); }}
                                    value={workingHours.split(':')[0]}
                                    // arrow={'false'}
                                    height={'NotificationHeight'}
                                    width={"w-30rem"}
                                
                                  />
                                  <span className='mx-1'>:</span>
                                  <DynamicDropdown
                                    data={minutes}
                                    selectedValue={(selectedItem) => { setMin(selectedItem); handleWorkingHoursChange(Hrs ||workingHours.split(':')[0] ,selectedItem)}}
                                    value={workingHours.split(':')[1]}
                                    // arrow={'false'}
                                    height={'NotificationHeight'}
                                    width={"w-30rem"}
                                
                                  />
                                </div> */}
                                <div className='d-flex align-items-center'>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type="text"
                                            value={workingHours.split(':')[0]}
                                            onChange={({ target: { value } }) => {
                                                if (isValidHour(value)) {
                                                    setHrs(value);
                                                    handleWorkingHoursChange(value, Min || workingHours.split(':')[1]);
                                                }

                                            }}
                                            maxLength="2"
                                            className={'editTime'}
                                            placeholder="HH"
                                        />

                                    </div>
                                    <span className='mx-1'>:</span>
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type="text"
                                            value={workingHours.split(':')[1]}
                                            onChange={({ target: { value } }) => {
                                                if (isValidMinute(value)) {
                                                    setMin(value);
                                                    handleWorkingHoursChange(Hrs || workingHours.split(':')[0], value)
                                                }
                                            }}
                                            maxLength="2"
                                            className={'editTime'}
                                            placeholder="MM"
                                        />

                                    </div>
                                </div>
                                {error && (
                                    <div style={{ color: 'red', fontSize: '14px' }}>
                                        Invalid input, please enter a numeric value.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer btnBox'>
                    <input type='button' value='Close' className='cancelBtn' onClick={onCancel} />
                    <input type='button' value='UPDATE' className='ml-2 validateBtn' onClick={handleUpdate} />
                </div>
            </div>
        </div>
    );
};

export default EditPopup;
