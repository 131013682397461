import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import * as serviceActionToken from '../../../employeeScreens/login/ServiceTokenAction';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import Dropdown from '../../../../shared/components/Dropdown';
import * as AdminLoginAction from '../../../adminScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import * as KeycloakActions from '../../../employeeScreens/login/KeyCloakAction';
import * as userActions from '../../../employeeScreens/login/LoginAction';
import { notification_typeWith_All } from '../../../../../utils/Utils';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

class AnnouncementDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            listType: '',
            transferRequestData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            showDelete: false,
            checked: 0,
            actionValue: '',
            page_number: 1,
            notification_type: notification_typeWith_All(),
            announcementType: '',
            announcementData: [],
            tempAnnouncementData: [],
            selectedAnnouncementId: '',
            requestData: [
                {
                    id: 1,
                    title: 'Active'
                },
                {
                    id: 2,
                    title: 'Inactive'
                }
            ]
        }
    }

    componentDidMount() {
        this.setState({
            listType: 'pending',
            announcementType: "All",
        },
            this.props.announcementData)
    }

    deleteAnnouncement = () => {
        this.setState({
            loading: true,
            showDelete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            is_active: false,
            announcement_id: this.state.selectedAnnouncementId
        }
        fetchApiCall(Config.deleteannouncementbyadmin, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.props.getAnnouncementCall()
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    activeConfirmationPopup = (listValue) => {
        this.setState({ selectedData: listValue, showConfirm: !this.state.showConfirm })
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    activeShowData = (data) => {
        this.setState({ selectedData: data, showDetail: true })
    }

    changeNotificationType = (data) => {
        this.setState({
            announcementType: data.value,
        },
            () => this.props.getAnnouncementCall()
        )
    }

    toggle = (isActive) => {
        if (isActive === 'active') {
            this.setState({
                isActive: true
            })
        }
        else {
            this.setState({
                isActive: false
            })
        }
    }

    onAction = (listData, type) => {
        if (type === 'view') {
            this.setState({
                viewPopup: true,
                tempAnnouncementData: listData
            })
        }
        else if (type === 'delete') {
            this.setState({
                showDelete: true,
                selectedAnnouncementId: listData.announcement_id,
            })
        }
        else {
            this.setState({
                doReject: true,
                tempAnnouncementData: listData
            })
        }
    }

    render() {
        const { listType, showConfirm, showDetail, viewPopup, showDelete } = this.state
        const { requestData } = this.props
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='flex-direction-row mt-2 justify-content-end' style={{ 'alignItems': 'center' }}>
                    <div className='ml-2 dropdown-Text'>
                        <Dropdown
                            data={this.state.notification_type}
                            selectedValue={(data) => this.changeNotificationType(data)}
                            value={this.state.announcementType}
                            render={'value'}
                            arrow={'true'}
                            height={'dependOnContent'}
                        />
                    </div>
                </div>
                <div className=" pt-2 pushNotification-container" >
                    {this.props.announcementData1.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" style={{ 'width': '5%' }}><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Title</div></th>
                                    <th scope="col" style={{ 'width': '15%' }}><div className='thead'>Attachment</div></th>
                                    <th scope="col" style={{ 'width': '30%' }}><div className='thead'>Description</div></th>
                                    <th scope="col" style={{ 'width': '20%' }}><div className='thead'>Hyperlink</div></th>
                                    <th scope="col" style={{ 'width': '15%' }}><div className='thead ml-4'>Created Date</div></th>
                                    {/* <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Modified Date</div></th> */}
                                    <th scope="col" style={{ 'width': '5%' }}><div className='thead' style={{ 'marginLeft': '15%' }}>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.announcementData1.map((listValue, index) => {
                                    return (
                                        <tr key={index} className=''>
                                            <td><div className="td">{index + 1}</div></td>
                                            <td><div className="td">{listValue.announcement_title ? listValue.announcement_title : ''}</div></td>
                                            <td><div className="td">{listValue.announcement_image ? listValue.announcement_image : ''}</div></td>
                                            <td><div className="td">{listValue.announcement_description ? listValue.announcement_description : ''}</div></td>
                                            <td><div className="td">{listValue.announcement_url ? listValue.announcement_url : ''}</div></td>
                                            <td><div className="td ml-4" >{moment(listValue.created_datetime).format("DD-MM-YYYY")}</div></td>
                                            {/* <td><div className="td" >{moment(listValue.modified_datetime).format("DD-MM-YYYY")}</div></td> */}
                                            <td className='d-flex'>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'view')}
                                                >
                                                    <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                </div>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'delete')}
                                                >
                                                    <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No annoucements have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {viewPopup &&
                    <NotificationDetailsPopup
                        type={"announcementDetails"}
                        title={"Announcment Details"}
                        data={this.state.tempAnnouncementData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteAnnouncement'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Announcement?'}
                        deleteAnnouncement={() => this.deleteAnnouncement()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        serviceActionToken: bindActionCreators(serviceActionToken, dispatch),
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(AnnouncementDashboard));