import * as type from '../../../../redux/action/ActionType';

export const getActivePopups = (res) => {
    return {
        type: type.GET_ACTIVE_POPUP,
        payload: res
    }
}
export const getActivePopupCount = (res) => {
    return {
        type: type.GET_ACTIVE_POPUP_COUNT,
        payload: res
    }
}

// AVAILABLE_SERVICE_DATA_LOGOUT
export const getServiceAvailabilityData = (res) => {
    return {
        type: type.GET_AVAILABLE_SERVICE_DATA,
        payload: res
    }
}
export const serviceLogout = () => {
    return {
        type: type.AVAILABLE_SERVICE_DATA_LOGOUT
    }
}
