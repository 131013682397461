import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import nodataicon from '../../../../assets/drishti_images/nodatafound/document-nodata.svg';
import DataNotFound from '../../../shared/notFound/DataNotFound';
// import PayslipJobs from './PayslipJobs';
// import ITWorksheetJobs from './ITWorksheetJobs';
// import ErrorInPostingJobs from './ErrorInPostingJobs';
// import ErrorInDeletionJobs from './ErrorInDeletionJobs';
// import SetToDeleteStatus from './SetToDeleteStatus';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
// import DeleteLeaveApproval from './DeleteLeaveApproval';
// import ServiceUnavailable from '../../../shared/ServiceUnavailable';
import toggleon from '../../../../assets/drishti_images/profile/toggle-on.svg';
import toggleoff from '../../../../assets/drishti_images/profile/toggle-off.svg';
// import enabledisable from '../screenEnableDisable/enabledisable.css';
import './enabledisable.css';
import EnableDisablePopup from './EnableDisablePopup';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';


class EnableDisableMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: FontFaceSetLoadEvent,
            isPayslipJobsActive: true,
            serviceData: [],
            action: '',
            show: false,
            servID: '',
            isProfileActive: false,
            isDocumentActive: false,
            // isITWorksheetJobsActive: false,
            // isErrorInPostingActive: false,
            // isErrorInDeletionActive: false,
            // isSetToDeleteStatusActive: false,
        }
    }

    componentWillMount() {
        this.getServiceAvailability()
    }

    getServiceAvailability() {
        const headers = {
            'Content-Type': 'application/json',
        }
        let request = {}
        fetchApiCall(Config.getServiceAvailabilty, request, headers, false, 'admin')
            .then(res => {
                console.log("res.data.services_status2-", res.data)
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    let tempData = res.data.services_status;
                    tempData.sort(function (a, b) {
                        let x = a.service_type.toLowerCase();
                        let y = b.service_type.toLowerCase();
                        if (x < y) { return -1; }
                        if (x > y) { return 1; }
                        return 0;
                    });
                    this.setState({ serviceData: tempData })

                    res.data.services_status.forEach(element => {
                        if (element.service_type === "profileMain") {
                            console.log("prof element ", element.service_type)
                            console.log("prof is_active ", element.is_active)
                            this.setState({
                                isProfileActive: element.is_active
                            })
                        }
                        if (element.service_type === "document_center") {
                            this.setState({
                                isDocumentActive: element.is_active
                            })
                        }

                    })
                }
                console.log("res.data.services_status : ", res.data.services_status)

            }).catch(err => {
                console.log("err", err);
            })
    }
    updateServiceStatus(reason) {
        let request = {}
        if (this.state.screenName === "profileMain") {
            request = {
                action: this.state.action,
                name: "profile",
                reason: reason ? reason : ''
            }
        }
        else if (this.state.screenName === "document_center") {
            request = {
                action: this.state.action,
                name: "document",
                reason: reason ? reason : ''
            }
        }
        else {
            request = {
                service_id: this.state.servID,
                action: this.state.action,
                reason: reason ? reason : ''
            }
        }
        this.setState({
            show: false
        })
        const headers = {
            'Content-Type': 'application/json',
        }

        fetchApiCall(Config.updateServiceStatus, request, headers, false, 'admin')
            .then(res => {
                console.log("res.data.services_status2-", res.data)
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.getServiceAvailability()
                }
            }).catch(err => {
                console.log("err", err);
            })
    }

    toggleDevTools = (type) => {
        console.log('typ', type);
        // if (type === 'payslipJobs') {
        //     this.setState({ isPayslipJobsActive: true, isITWorksheetJobsActive: false, isErrorInPostingActive: false, isErrorInDeletionActive: false, isSetToDeleteStatusActive: false, })
        // }
        // else if (type === 'ITWorksheetJobs') {
        //     this.setState({ isPayslipJobsActive: false, isITWorksheetJobsActive: true, isErrorInPostingActive: false, isErrorInDeletionActive: false, isSetToDeleteStatusActive: false, })
        // }
        // else if (type === 'ErrorInPostingJobs') {
        //     this.setState({ isPayslipJobsActive: false, isITWorksheetJobsActive: false, isErrorInPostingActive: true, isErrorInDeletionActive: false, isSetToDeleteStatusActive: false, })
        // }
        // else if (type === 'ErrorInDeletionJobs') {
        //     this.setState({ isPayslipJobsActive: false, isITWorksheetJobsActive: false, isErrorInPostingActive: false, isErrorInDeletionActive: true, isSetToDeleteStatusActive: false, })
        // }
        // else {
        //     this.setState({ isPayslipJobsActive: false, isITWorksheetJobsActive: false, isErrorInPostingActive: false, isErrorInDeletionActive: false, isSetToDeleteStatusActive: true, })
        // }
    }

    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/home')
    }

    changeData = (screenName, action, serv_id) => {
        if (action == true) {
            this.setState({
                action: "Enable",
                show: true,
                servID: serv_id,
                screenName: screenName
            })
        }
        else {
            this.setState({
                action: "Disable",
                show: true,
                servID: serv_id,
                screenName: screenName
            })
        }
    }
    showError = (screenName) => {
        setTimeout(() => {
            toast.warn('Please enable the ' + screenName + ' screen ', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }, 100)
    }

    render() {
        return (
            <div className="row px-0 px-12px">
                <div className="col-md-12 ">
                    <div className='row px-0 pt-0' >
                        <img src={overviewButton} onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 col-md-12'>
                                <div className={this.state.isPayslipJobsActive ? "tab-active" : "tab-inactive"}
                                // onClick={() => this.toggleDevTools('payslipJobs')}
                                >
                                    Screen Enable disable
                                </div>
                                {/* <div className={this.state.isITWorksheetJobsActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ITWorksheetJobs')}>
                                    IT worksheet jobs
                                </div>
                                <div className={this.state.isErrorInPostingActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ErrorInPostingJobs')}>
                                    Error in posting jobs
                                </div>
                                <div className={this.state.isErrorInDeletionActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('ErrorInDeletionJobs')}>
                                    Error in deletion Jobs
                                </div>
                                <div className={this.state.isSetToDeleteStatusActive ? "tab-active" : "tab-inactive"} onClick={() => this.toggleDevTools('SetToDeleteStatus')}>
                                    Set to delete status
                                </div> */}
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='enable-disable-dashboard'>
                                <div className='row mt-12px mb-10vh'>
                                    {/* <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                                        <div className='text16_medium'>
                                            Profile
                                        </div>
                                        <div className='d-flex row'>
                                        </div>
                                    </div> */}
                                    <div className='row col-md-12'>
                                        {this.state.serviceData.map((list, id) =>
                                            <>
                                                {list.service_type.startsWith("profileMain") &&
                                                    <div key={id} className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                                        <div className='enabledisableIcon justify-content-between align-item-center'>
                                                            <div className='text16_medium row justify-content-between align-item-center'>
                                                                <div className='text16_medium'>{list.service_name}</div>
                                                                {!list.is_active &&
                                                                    <div data-tooltip2={list.reason} className="infoLabel ml-2" >
                                                                        <img alt="info"
                                                                            src={infoicon}
                                                                            className="infosize"
                                                                        >
                                                                        </img>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {list.is_active ?
                                                                <div onClick={() => this.changeData(list.service_type, false, list.service_id)}>
                                                                    <img src={toggleon} />
                                                                </div>
                                                                :
                                                                <div onClick={() => this.changeData(list.service_type, true, list.service_id)}>
                                                                    <img src={toggleoff} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                    <div className='row col-md-12'>
                                        {this.state.serviceData.map((list, id) =>
                                            <>
                                                {list.service_type.startsWith("profile_") &&
                                                    <div key={id} className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                                        <div className='enabledisablebox justify-content-between align-item-center'>
                                                            <div className='text16_medium row justify-content-between align-item-center'>
                                                                <div className='text16_medium'>{list.service_name}</div>
                                                                {this.state.isProfileActive &&
                                                                    <>
                                                                        {!list.is_active &&
                                                                            <div data-tooltip2={list.reason} className="infoLabel ml-2" >
                                                                                <img alt="info"
                                                                                    src={infoicon}
                                                                                    className="infosize"
                                                                                >
                                                                                </img>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            {this.state.isProfileActive ?
                                                                <>
                                                                    {list.is_active ?
                                                                        <div onClick={() => this.changeData(list.service_type, false, list.service_id)}>
                                                                            <img src={toggleon} />
                                                                        </div>
                                                                        :
                                                                        <div onClick={() => this.changeData(list.service_type, true, list.service_id)}>
                                                                            <img src={toggleoff} />
                                                                        </div>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <div onClick={() => this.showError('profile')} >
                                                                        <img src={toggleoff} />
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                    <div className='hz-lines hzWidth ml-4 mb-2'></div>
                                    {/* <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                                        <div className='text16_medium'>
                                            Document Center
                                        </div>
                                        <div className='d-flex row'>
                                        </div>
                                    </div> */}
                                    < div className='row col-md-12' >
                                        {
                                            this.state.serviceData.map((list, id) =>
                                                <>
                                                    {list.service_type.startsWith("document_center") &&
                                                        <div key={id} className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                                            <div className='enabledisableIcon justify-content-between align-item-center'>
                                                                <div className='text16_medium row justify-content-between align-item-center'>
                                                                    <div className='text16_medium'>{list.service_name}</div>
                                                                    {!list.is_active &&
                                                                        <div data-tooltip2={list.reason} className="infoLabel ml-2" >
                                                                            <img alt="info"
                                                                                src={infoicon}
                                                                                className="infosize"
                                                                            >
                                                                            </img>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                {list.is_active ?
                                                                    <div onClick={() => this.changeData(list.service_type, false, list.service_id)}>
                                                                        <img src={toggleon} />
                                                                    </div>
                                                                    :
                                                                    <div onClick={() => this.changeData(list.service_type, true, list.service_id)}>
                                                                        <img src={toggleoff} />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className='row col-md-12'>
                                        {this.state.serviceData.map((list, id) =>
                                            <>
                                                {list.service_type.startsWith("document_") &&
                                                    <>
                                                        {!list.service_type.startsWith("document_center") &&
                                                            <div key={id} className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                                                <div className='enabledisablebox justify-content-between align-item-center'>
                                                                    <div className='text16_medium row justify-content-between align-item-center'>
                                                                        <div className='text16_medium'>{list.service_name}</div>
                                                                        {this.state.isDocumentActive &&
                                                                            <>
                                                                                {!list.is_active &&
                                                                                    <div data-tooltip2={list.reason} className="infoLabel ml-2" >
                                                                                        <img alt="info"
                                                                                            src={infoicon}
                                                                                            className="infosize"
                                                                                        >
                                                                                        </img>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    {this.state.isDocumentActive ?
                                                                        <>
                                                                            {list.is_active ?
                                                                                <div onClick={() => this.changeData(list.service_type, false, list.service_id)}>
                                                                                    <img src={toggleon} />
                                                                                </div>
                                                                                :
                                                                                <div onClick={() => this.changeData(list.service_type, true, list.service_id)}>
                                                                                    <img src={toggleoff} />
                                                                                </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div onClick={() => this.showError('document')}>
                                                                                <img src={toggleoff} />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        )}
                                    </div>
                                    <div className='hz-lines hzWidth ml-4 mb-2'></div>
                                    <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                                        <div className='text16_medium ml-4 mt-3 mb-3'>
                                            Other
                                        </div>
                                        <div className='d-flex row'>
                                        </div>
                                    </div>
                                    <div className='row col-md-12'>
                                        {this.state.serviceData.map((list, id) =>
                                            <>
                                                {!list.service_type.startsWith("document") &&
                                                    <>
                                                        {!list.service_type.startsWith("profile") &&
                                                            <>
                                                                <div key={id} className='col-md-6 col-lg-4 col-sm-6 col-12 mb-3'>
                                                                    <div className='enabledisablebox justify-content-between align-item-center'>
                                                                        <div className='text16_medium row justify-content-between align-item-center'>
                                                                            <div className='text16_medium'>{list.service_name}</div>
                                                                            {!list.is_active &&
                                                                                <div data-tooltip2={list.reason} className="infoLabel  ml-2" >
                                                                                    <img
                                                                                        alt="info"
                                                                                        src={infoicon}
                                                                                        className="infosize"
                                                                                    >
                                                                                    </img>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {list.is_active ?
                                                                            <div onClick={() => this.changeData(list.service_type, false, list.service_id)}>
                                                                                <img src={toggleon} />
                                                                            </div>
                                                                            :
                                                                            <div onClick={() => this.changeData(list.service_type, true, list.service_id)}>
                                                                                <img src={toggleoff} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        )}
                                    </div>
                                    {/* < div className='col-md-12 justify-content-center align-item-center h-60vh' >
                                        <DataNotFound />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.show &&
                    <EnableDisablePopup
                        action={this.state.action}
                        onDismiss={() => this.setState({ show: false })}
                        actionFunction={(reason) => this.updateServiceStatus(reason)}
                    />
                }
            </div >
        );
    }
}


export default EnableDisableMain;
