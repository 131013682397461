import React, { Component } from 'react';
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';

const confirmSubmit = 'The above information given by me is true & correct & I have discussed with my manager/HR/Ethics regarding this "Confilct Of Interest".';
const confirmDelete = 'Are you sure you want to delete this request?'
const confirmDeleteLastRecord = 'Are you sure you want to delete your last COI Record'
class ConfirmFormPopup extends Component {
    render() {
        const action = this.props.action ? this.props.action : ''
        const is_last_record = this.props.is_last_record ? this.props.is_last_record : false
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <div className="modal-header">
                        {action === 'submit' ?
                            <h4 className="popup-title">Self Declaration</h4> :
                            <h4 className="popup-title">Confirmation</h4>}
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body">
                        {action === 'delete' && is_last_record ?
                            <div className="popup-text mx-4 mt-4 mb-4">
                                <div className='d-flex justify-content-center'>
                                    {confirmDeleteLastRecord}
                                </div>
                            </div> :
                            action === 'delete' ?
                                <div className="popup-text mx-4 mt-4 mb-4">
                                    <div className='d-flex justify-content-center'>
                                        <img
                                            alt="delete_popup"
                                            src={delete_popup}
                                            className="mb-2"
                                        ></img>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {confirmDelete}
                                    </div>
                                </div> :
                                action === 'submit' ?
                                    <div className="popup-text mx-4 mt-4 mb-4">
                                        <div className='d-flex justify-content-center'>
                                            {confirmSubmit}
                                        </div>
                                    </div> : null
                        }
                    </div>
                    {action === 'delete' ?
                        <div className="d-flex justify-content-end px-0 mt-3">
                            <div className='d-flex justify-content-end'>
                                <div
                                    type='button'
                                    className='declaration-no-btn'
                                    data-dismiss="modal"
                                    onClick={() => this.props.onDismiss()}
                                >No</div>
                                <div
                                    type='button'
                                    className='declaration-yes-btn'
                                    onClick={() => {
                                        this.props.deleteData()
                                    }}
                                >Yes</div>
                            </div>
                        </div>
                        :
                        <div className="row justify-content-end">
                            <button type="button"
                                className="declaration-yes-btn"
                                style={{ width: '40%' }}
                                onClick={() => {
                                    this.props.saveDetails()
                                }}>Agree
                            </button>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
export default ConfirmFormPopup;