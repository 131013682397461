import React, { Component } from 'react';
import '../../employeeScreens/transfer/Transfer.css';
import EmployeeScreen from './EmployeeScreen';
import GroupScreen from './GroupScreen';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import '../../employeeScreens/profile/ProfileScreen.css';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
class LeaveScreen extends Component {
    constructor() {
        super()
        this.state = {
            loading: false,
            checked: false,
            isEmployeeActive: true,
            isGroupActive: false,
            screenwidth: 0,
            screenheight: 0,
        }
    }

    toggleEmployeeDetails = () => {
        this.setState({ isEmployeeActive: true, isGroupActive: false })
    }
    toggleGroupDetails = () => {
        this.setState({ isEmployeeActive: false, isGroupActive: true })
    }

    render() {
        return (
            <div className="row p-12px">
                <div className="col-md-12 px-0 pt-0">
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.isEmployeeActive ? "tab-active" : "tab-inactive"} onClick={this.toggleEmployeeDetails}>
                                    Employee
                                </div>
                                {this.props.adminLoginData.adminRoles[0] !== 'itsd-lead' &&
                                    this.props.adminLoginData.adminRoles[0] !== 'itsd-member' &&
                                    <div className={this.state.isGroupActive ? "tab-active" : "tab-inactive"} onClick={this.toggleGroupDetails}>
                                        Group
                                    </div>
                                }
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='profile-inner-card'>
                                {this.state.isEmployeeActive ?
                                    <div>
                                        <EmployeeScreen />
                                    </div> : null
                                }
                                {this.state.isGroupActive ?
                                    <div>
                                        <GroupScreen />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(LeaveScreen));
