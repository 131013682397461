import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import './TeamsStyle.css';

class Leadership extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            LeadershipAnnouncementData: []
        }
    }
    componentDidMount() {
        this.getLeadershipAnnouncement()
    }

    getLeadershipAnnouncement = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {}
        fetchApiCall(Config.getLeadershipAnnouncement, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        LeadershipAnnouncementData: res.data.data.announcment_records,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        LeadershipAnnouncementData: [],
                        show: true,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        return (
            <div className={window.innerWidth <= 600 ? "card-new h-100" : "card-new h-100 row"}>
                {this.state.LeadershipAnnouncementData.length !== 0 ?
                    <AliceCarousel autoPlay autoPlayInterval="2000" infinite={true}>
                        {this.state.LeadershipAnnouncementData.map((listValue, index) => {
                            return (
                                <div key={index}>
                                    <div className='leadership-mobile-screen flex-direction-row align-item-center mt-4'>
                                        <img src={listValue.image_link} role="presentation" className='img-leadership test ml-5' />
                                        <label className="text-center edit-cardtitle mx-3 wrap-text-label py-10px">
                                            {listValue.announcement_description}
                                        </label>
                                    </div>
                                </div>
                            )
                        }
                        )
                        }
                    </AliceCarousel>
                    :
                    <>
                        <div className='col-md-12 justify-content-center align-item-center'>
                            <div className='mt-3'>
                                <DataNotFound />
                            </div>
                        </div>
                    </>
                }
            </div >
        );
    }
}

export default Leadership;