import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
class LoginImagePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageName: '',
            imageTitle: '',
            image: '',
            imageHyperlink: '',
        }
    }

    insertDynamicImage = () => {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' }
        let body = {
            image_name: this.state.imageName,
            image_title: this.state.imageTitle,
            image_link: this.state.image_link,
            image_hyperlink: this.state.imageHyperlink,
        }
        // console.log("body:", body);
        fetchApiCall(Config.insertDynamicSliderImageBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false })
                    setTimeout(() => { window.location.reload() })
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    fileSelectHandler = (event) => {
        this.setState({ imageName: event.target.files[0] ? event.target.files[0].name : '' })
        if (event.target.files && event.target.files[0]) {
            let maxAllowedSize = 2097152;           // 2MB size
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => { this.setState({ image_link: e.target.result }) }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ image_link: '' })
            }
        }
        else { this.setState({ image_link: '' }) }
    }

    validate = () => {
        if (this.state.imageTitle === '') {
            toast.warning('Please enter Image title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.imageName === '' || this.state.image_link === '') {
            toast.warning('Please select image', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.imageHyperlink === '') {
            toast.warning('Please enter Hyperlink', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertDynamicImage()
        }
    }

    render() {
        const { NotificatioType, close, back,loading } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{this.props.NotificationType}</h4>
                            {/* <button type="button" className="close" onClick={close}>&times;</button> */}
                        </div>
                        <div className="">
                            <form className='todoModalBody popup_scroller w-100'>
                                {/* <label className='form-label ml-2 mt-2'>Notification Type :
                                    <span className='form-label-notification-bold mx-1'>{NotificatioType}</span>
                                </label> */}
                                <div>
                                    <div>
                                        <div className='p-sub-heading ml-3 mt-2'>Title</div>
                                        <div className='p-placeholder-text mb-2'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the title"}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ imageTitle: e.target.value })}
                                                value={this.state.imageTitle}
                                            />
                                        </div>
                                        <div>
                                            <div className='p-sub-heading ml-3'>File</div>
                                            {!this.props.show &&
                                                <div className='p-placeholder-text mb-2'>
                                                    <input
                                                        className="form-control p-placeholder px-3"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={this.fileSelectHandler}
                                                    />
                                                    <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                                                </div>
                                            }
                                        </div>
                                        <div className='p-sub-heading ml-3'>Description</div>
                                        <div className='p-placeholder-text'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the description"}
                                                maxLength={1000}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ imageHyperlink: e.target.value })}
                                                value={this.state.imageHyperlink}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                                </div>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={back} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='ADD'
                                            className='popup-confirm-btn'
                                            onClick={this.validate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(LoginImagePopup);