import React, { Component } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import './DropdownStyles.css'
import Select from "react-select";
import { components } from 'react-select'

const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "#F2F4FB" }),
    option: (styles, { isDisabled }) => {
        return {
            ...styles,
            backgroundColor: isDisabled ? "red" : "#F2F4FB",
            color: "#64748B",
            cursor: isDisabled ? "not-allowed" : "default",
            border: 'none'
        };
    }
};


const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};
class LargeDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: ''
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ selectedValue: nextProps.value ? nextProps.value : '' })
    }
    setValue = (list) => {
        // console.log("list : ",list)
        this.setState({ selectedValue: list.value }, () =>
            this.props.selectedValue(list))
    }
    render() {
        const { data, value, render, arrow, height, readOnly, disabled, noOptionFound, isMulti = false, closeMenuOnSelect = true, customStyles = {}, hideSelectedOptions = false, isOptionDisabled = false } = this.props
        const { selectedValue } = this.state
        // console.log(',,,,,',selectedValue);
        return (
            <div className=''>
                {/* <button
                    className='btn p-placeholder d-flex justify-content-between align-items-center'
                    type='button' id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    disabled={disabled}
                >
                    {value}
                    <RiArrowDropDownLine
                        size={25}
                        color={'rgb(185 188 197)'}
                    />
                </button>
                
                {data &&
                    <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                    {data.map((listValue, i) => {
                        return (
                            <label key={i} className='dropdown-item'  onClick={() => this.setValue(listValue)}>
                                {listValue.value}
                            </label>
                        );
                    })}
                </div>
                } */}
                <div className=''>
                    <Select
                        styles={{ ...colourStyles, ...customStyles }}
                        onChange={(listValue) => this.setValue(listValue)}
                        placeholder={value}
                        options={data}
                        isDisabled={disabled}
                        value={value}
                        menuPosition="fixed"
                        // menuPlacement="auto"
                        // menuShouldBlockScroll="true"
                        isMulti={isMulti}
                        closeMenuOnSelect={closeMenuOnSelect}
                        maxMenuHeight={150}
                        noOptionsMessage={() => noOptionFound ?? "No Options"}
                        hideSelectedOptions={hideSelectedOptions}
                        components={isMulti ? { Option } : null}
                        isOptionDisabled={(option) => option.isDisabled == true}
                    />
                </div>
            </div>
        );
    }
}

export default LargeDropdown;