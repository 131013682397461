import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import { connect } from 'react-redux';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as cetActions from '../login/LoginAction';
import { currentDateYMD } from '../../../../utils/Utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { bindActionCreators } from 'redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import ImportantDates from './ImportantDates';

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    // componentDidMount() {
    //     this.refreshCETDetails()
    // }

    // refreshCETDetails() {
    //     this.setState({
    //         loading: true
    //     });
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     let body = {
    //         emp_id: this.props.loginData.userData.Perno,
    //         modified_date: currentDateYMD()
    //     }
    //     fetchApiCall(Config.getLatestuserDetailsFromEdpCet, body, headers, true, '')
    //         .then(res => {
    //             sessionOut(this.props, res);
    //             if (res.data.status_code == 200) {
    //                 if (res.data.user_details[0]) {
    //                     // this.props.actions.authUser(res.data.user_details[0]);
    //                 }
    //                 if (res.data.manager_details[0]) {
    //                     this.props.actions.authManager(res.data.manager_details[0]);
    //                 }
    //                 this.setState({
    //                     loading: false
    //                 });
    //                 setTimeout(() => { toast.success(res.data.message, { hideProgressBar: true }) }, 100)
    //             } else {
    //                 this.setState({
    //                     loading: false
    //                 })
    //                 setTimeout(() => { toast.warn(res.data.message, { hideProgressBar: true }) }, 100)
    //             }

    //         }).catch(err => {
    //             this.setState({
    //                 loading: false
    //             });
    //             setTimeout(() => { toast.error(err, { hideProgressBar: true }) }, 100)

    //         })
    // }

    render() {
        let userData = this.props.loginData.userData;
        return (
            <div className="row">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12">
                    <div className='p-heading mx-4 mt-3 mb-2'>Profile Details</div>
                    <div className='row'>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Level</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.Esgtxt ? userData.Esgtxt : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Band</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.Band ? userData.Band : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Employee Category</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.Ptext ? userData.Ptext : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Business HR Employee ID</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.BhrPerno ? userData.BhrPerno : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Business HR Name</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.BhrName ? userData.BhrName : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>DEM Employee ID</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.Reporting ? userData.Reporting : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>DEM Name</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.Nameofreporting ? userData.Nameofreporting : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Function</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.FunctionText ? userData.FunctionText : ''}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className='col-4 mb-3'>
                            <div className='p-sub-heading ml-3'>Org Unit</div>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="text"
                                    value={userData.OuLevel1LongTxt ? userData.OuLevel1LongTxt : ''}
                                    readOnly={true}
                                />
                            </div>

                        </div>
                    </div>
                    <div className='hz-lines mt-4 mb-4 mr-2 ml-2'></div>
                    <ImportantDates />
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(cetActions, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(Profile);