//Created by Aupama Borkar 
//Created On : 9th April 2022,
//Modified by : Anupama Borkar
//Modified On : 28th April 2022
// Description: Calling all API for URL Logic
import axios from 'axios';
import { configStore } from "../redux/ConfigStore";
import { BASE_WEB_URL_DEV, COMMON_BASE_URL_PROD } from '../constants/Config.js'
import * as Config from './Config';
import { emptyLoginState, toggleLoginStatus } from '../components/ScreenType/employeeScreens/login/LoginAction'
import { toaster } from '../utils/Utils'

export function fetchApiCall(endurl, payload = {}, headers = {}, auth, type, method, customHost) {

    const emp_accessToken = configStore.getState().keycloakState.keycloakData.access_token ? configStore.getState().keycloakState.keycloakData.access_token.access_token : '';

    const admin_accessToken = configStore.getState().adminLoginState.keycloakData.access_token ? configStore.getState().adminLoginState.keycloakData.access_token.access_token : '';

    const serviceToken = configStore.getState().serviceTokenState ? configStore.getState().serviceTokenState.serviceToken : ''

    const azureToken = configStore.getState().keycloakState.keycloakData.az_access_token

    const isEmpLogged = configStore.getState().loginState.isLoggedIn

    const token =
        type === 'service' ? serviceToken :
            type === 'azure' ? azureToken :
                type === 'admin' ? admin_accessToken :
                    emp_accessToken

    let emp_comp_path = ''

    let admin_comp_path = ''

    emp_comp_path = configStore.getState().loginState.company ? configStore.getState().loginState.company.kong_path : '/cv'

    admin_comp_path = configStore.getState().adminLoginState.company ? configStore.getState().adminLoginState.company.kong_path : '/cv'

    let comp_path = isEmpLogged ? emp_comp_path : admin_comp_path

    let company_URL_add = auth ? '' : comp_path

    let complete_URL = customHost ? customHost + endurl : Config.COMMON_BASE_URL_PROD + company_URL_add + endurl

    try {
        if (method === "GET") {
            return axios.get(complete_URL, {
                headers: {
                    ...headers,
                    ...(token && {
                        'Authorization': `Bearer ${token}`,
                    })
                },
                params: payload
            });
        } else {
            return axios.post(complete_URL, payload, {
                headers: {
                    ...headers,
                    ...(token && {
                        'Authorization': `Bearer ${token}`,
                    })
                }
            });
        }
    }
    catch (err) {
        console.warn('Boilerplate error', err);
    }
};

export function sessionOut(props, res, admin) {
    if (!res.data.status)
        return;
    if ((res.data.status_code === "401" || res.data.status_code == 401 || res.data.status_code == 450 || res.data.status_code === "450" || res.data.status == "450" || res.data.status == "401")) {
        if (props.adminLoginData?.isAdminLoggedIn == true) {
            window.location = (BASE_WEB_URL_DEV + 'admin');
        }
        else {
            console.log("sessionOut else")
            window.location = (BASE_WEB_URL_DEV + 'login');
        }
    }
    else {
        return
    }
}

export function operativeSessionOut(props, res, admin) {
    if (!res.data.status_code)
        return;

    if (res.data.status_code === "401" || res.data.status_code == 401 || res.data.status_code == 450 || res.data.status_code === "450" || res.status_code == "401") {
        if (admin === "admin") {
            window.location = (BASE_WEB_URL_DEV + 'admin');
        }
        else if (admin === "") {
            window.location = (BASE_WEB_URL_DEV + 'login');
        }
    }
    else {
        return
    }
}

//-------------------------------------------------New function for using GET, POST, DELETE, UPDATE--------------------------------------------------------------------------------------
export async function fetchApiCallWithService(endurl, payload = {}, headers = {}, auth, type, method, comp, propsValue, param = {}, responseType = 'json',) {

    const accessToken = configStore.getState().keycloakState?.keycloakData?.access_token?.access_token;

    const serviceToken = configStore.getState().loginState?.serviceToken

    const admin_accessToken = configStore.getState().adminLoginState?.keycloakData?.access_token ? configStore.getState().adminLoginState?.keycloakData?.access_token.access_token : '';

    const token = type === "service" ? serviceToken : type === "accessToken" ? accessToken : type === 'login' ? '' : type === 'admin' ? admin_accessToken : ""

    let company_path = (comp === 'login') ? configStore.getState().loginState.company.kong_path : comp === 'admin' ? configStore.getState().adminLoginState.company.kong_path : ''

    let company_URL_add = auth ? '' : company_path

    // Axios instance for GET requests
    const instance = axios.create({
        baseURL: COMMON_BASE_URL_PROD,
        timeout: 10000,
        method: method,
        headers: {
            ...headers,
            ...(token ? {
                'Authorization': `Bearer ${token}`,
            } : null)
        },
        responseType: responseType
    });

    instance.interceptors.request.use(
        function (config) {
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    // Function to make a request
    async function makeRequest() {
        try {
            let requestConfig = {};
            requestConfig = {
                url: company_URL_add + endurl,
                data: payload,
                params: param
            };
            const response = await instance(requestConfig);
            if (response?.data?.status === "450" || response?.status == 401 || response?.status == 450 || response?.data?.status == 401 || response?.data?.status == 450
                || response?.data?.status == "401" || response?.status == "401" || response?.status == "450") {
                toaster("error", "Session timeout!")
                window.location = (BASE_WEB_URL_DEV + 'login');
                configStore.dispatch(emptyLoginState());
                window.localStorage.clear();
            }
            else {
                return response;
            }
        } catch (error) {
            console.log("error", error.toJSON(), Object.keys(error), error.response)
            if (error.response.status == 401 || error.response.status == 450) {
                toaster("error", "Session timeout!")
                window.location = (BASE_WEB_URL_DEV + 'login');
                configStore.dispatch(emptyLoginState());
                window.localStorage.clear();
            }
            else {
                console.log('Error occurred:', error.response);
                console.log('Error occurred only:', error);
                return error.response;
            }
        }
    }
    return makeRequest();
};