import React, { Component } from 'react';
import moment from "moment";
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { toast } from 'react-toastify';
import '../../../../assets/styles/popupStyles.css'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'

const bonafide_downloadSuccess = 'Your Bonafide Certificate has been generated succesfully ! '
export default class componentName extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isDateChecked: false,
            concernedAuthorities: '',
            permanentAddressCheckBox: false,
            currentAddressCheckBox: false,
            dateOfJoiningCheckBox: false,
            html: '',
            reason: ''
        }
    }

    getBonafideData() {
        const { concernedAuthorities, reason } = this.state
        if ((!this.state.dateOfJoiningCheckBox && !this.state.currentAddressCheckBox && !this.state.permanentAddressCheckBox)) {
            toast.warn('Please select atleast one detail for bonafide', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
            return;
        } else if (concernedAuthorities == '') {
            toast.warn('Please enter concerned authorities', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
            return;
        } else if (reason == '') {
            toast.warn('Please enter reason', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
            return;
        }
        this.setState({
            loading: true
        });
        let req = {
            emp_id: this.props.loginData.userData.Perno,
            template_code: 'bonafide_certificate',
            concern_authorities: concernedAuthorities ? concernedAuthorities : '',
            reason: reason,
            joining_date: this.state.dateOfJoiningCheckBox,
            current_address: this.state.currentAddressCheckBox,
            permanent_address: this.state.permanentAddressCheckBox
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getBonafideDetails, req, headers, false, '').then(res => {
            sessionOut(this.props, res);

            if (res.data.status_code == 200) {
                this.setState({
                    html: res.data.bonafide_details,
                    loading: false
                })
                this.generateBonafide(this.state.html);
                this.props.onDismiss()
                toast.success(bonafide_downloadSuccess, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastSuccess'
                })
            } else {
                this.setState({
                    loading: false
                });
                this.props.showBonafideMessage(false)
                toast.error(res.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            }
        }).catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    generateBonafide = async (element) => {
        const html2pdf = (await import("html2pdf.js")).default;
        var html2canvas = { dpi: 192, letterRendering: true, height: 1024 }
        let date = new Date();
        const fileName = 'Bonafide' + '_' + ((date.getDate())) + ((date.getMonth() + 1)) + ((date.getFullYear())) + ((date.getHours())) + ((date.getMinutes())) + ((date.getSeconds()));
        var option = {
            margin: [0.5, 1, 1, 1],
            filename: fileName,
            html2canvas: html2canvas,
            jsPDF: {
                orientation: 'portrait',
                unit: 'cm',
                format: [29.7, 21]
            }
        };
        html2pdf().set(option).from(element).save();
    }

    render() {
        const doj = this.props.loginData.userData.Doj
        const { permanentAddressCheckBox, dateOfJoiningCheckBox, currentAddressCheckBox } = this.state
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal_inner_box  align-items-center apply_leave_modal w-480px h-537px">
                    <div className="modal-header">
                        <h4 className="modal-title text_heading">
                            {"Generate Bonafide Certificate"}
                        </h4>
                        <button type="button" className="close" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body w-100">
                        <div className='px-30px pt-24px'>
                            <div className='announcement-pin-content'>Select Details</div>
                            <div className='mt-19px d-flex'>
                                <label className="checkBoxData">
                                    <input type="checkbox" className='hidecheckBox'
                                        checked={dateOfJoiningCheckBox}
                                        onChange={() => this.setState({ dateOfJoiningCheckBox: !this.state.dateOfJoiningCheckBox })}
                                    />
                                    <span className="markk"></span>
                                </label>
                                <div className='d-flex' style={{ flexDirection: 'column' }}>
                                    <div className='announcement-pin-content'>Date Of Joining</div>
                                    <div className='detail-label-light-text'>{moment(doj).format("DD-MM-YYYY")}</div>
                                </div>
                            </div>
                            <div className='mt-19px d-flex'>
                                <label className="checkBoxData">
                                    <input type="checkbox" className='hidecheckBox'
                                        checked={currentAddressCheckBox}
                                        onChange={() => this.setState({ currentAddressCheckBox: !this.state.currentAddressCheckBox })}
                                    />
                                    <span className="markk"></span>
                                </label>
                                <div className='d-flex' style={{ flexDirection: 'column' }}>
                                    <div className='announcement-pin-content'>Current Address</div>
                                    <div className='detail-label-light-text'>{ }</div>
                                </div>
                            </div>
                            <div className='mt-19px d-flex'>
                                <label className="checkBoxData">
                                    <input type="checkbox" className='hidecheckBox'
                                        checked={permanentAddressCheckBox}
                                        onChange={() => this.setState({ permanentAddressCheckBox: !this.state.permanentAddressCheckBox })}
                                    />
                                    <span className="markk"></span>
                                </label>
                                <div className='d-flex' style={{ flexDirection: 'column' }}>
                                    <div className='announcement-pin-content'>Permanent Address</div>
                                    <div className='detail-label-light-text'>{ }</div>
                                </div>
                            </div>
                            <div className='mt-23px'>
                                <div className='announcement-pin-content required ml-2 mb-1'>Concerned Authorities</div>
                                <div className='input-div w-100 checkbox'>
                                    <input 
                                    type="text"
                                        className=' form-control p-placeholder'
                                        value={this.state.concernedAuthorities}
                                        onChange={(e) => this.setState({ concernedAuthorities: e.target.value })}
                                        placeholder="Enter Concerned Authorities"
                                    />
                                </div>
                                <div className='announcement-pin-content pt-20px required ml-2 mb-1'>Reason</div>
                                <textarea
                                    className="form-control announcement-pin-content p-placeholder"
                                    placeholder="Enter Reason"
                                    onChange={(e) => { this.setState({ reason: e.target.value }) }}
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-button'>
                        <input type='button' value='GENERATE' className='ml-2 validateBtn' onClick={() => this.getBonafideData()} />
                        <input type='button' value='CANCEL' className='ml-2 cancelBtn' onClick={this.props.onDismiss} />
                    </div>
                </div>
            </div>
        );
    }
}
