import React, { Component } from 'react';
import { RiArrowDropDownLine } from 'react-icons/ri'
import './DropdownStyles.css'
import down from '../../../assets/images/applyLeave/downArrow.png'

class Dropdown extends Component {
    setValue = (list, item) => {
        this.props.selectedValue(list, item)
    }
    render() {
        const { data, value, render, arrow, height, disabled, width, textBoxWidth, editable, bg } = this.props
        return (
            <div className={[textBoxWidth === 'w-50' ? 'dropdown w-50' : 'dropdown']}>
                <button
                    className={bg === "whiteBG" ? 'btn common-white-dropdown-text d-flex justify-content-between' : bg === 'invalid' ? 'invalidDropdown d-flex justify-content-between btn' : 'btn common-dropdown-text d-flex justify-content-between'}
                    type='button' id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    disabled={disabled}

                >
                    {value}
                    {arrow === 'true' ?
                        <img src={down} alt='' className='mt-2 ml-2 dropdownIcon' />
                        :
                        <RiArrowDropDownLine size={20} color={'rgb(185 188 197)'} />
                    }
                </button>
                <div className={[height === 'height' ?
                    'dropdown-menu scroller menu-height w-100' :
                    height === 'NotificationHeight' ?
                        "Notification-menu-height admin-scroller dropdown-menu " :
                        height === 'dependOnContent' ?
                            "max-dropdown-height contentWise-scroll dropdown-menu " :
                            height === 'LAContent' ?
                                "dropdown-menu dropdown-hnew" :
                                height === 'widthwith' ?
                                    "dropdown-menu dropdown-menu-for-reasoncode" :
                                    height === 'innerCard' ?
                                        "max-dropdown-height contentWise-scroll-min dropdown-menu " :
                                        'dropdown-menu w-25',
                width === 'width' ?
                    'w-100' :
                    ''
                ]} aria-labelledby="dropdownMenuButton">
                    {data.map((listValue, i) => {
                        return (
                            <label key={i} className='dropdown-item' onClick={(e) => this.setValue(listValue, i)}>
                                {render === 'value' ? listValue.value :
                                    render === 'key' ? listValue.key :
                                        render === 'month' ? listValue.month :
                                            render === 'country_text' ? listValue.country_text :
                                                render === "area" ? listValue.area :
                                                    render === "org_unit" ? listValue.org_unit :
                                                        render === "project_duration" ? listValue.project_duration :
                                                            render === "managerName" ? listValue.CompName :
                                                                render === "expenseType" ? listValue.expense_type :
                                                                    render === "subExpenseType1" ? listValue.sub_expense_type1 :
                                                                        render === "subExpenseType2" ? listValue.sub_expense_type2 :
                                                                            render === "traveltType" ? listValue.sub_expense_type1 :
                                                                                render === "ApporvalStatus" ?
                                                                                    <div className='d-flex justify-content-between' >
                                                                                        {listValue.title}
                                                                                    </div>
                                                                                    : listValue}
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Dropdown;