import React, { Component } from 'react';
import '../../../assets/styles/popupStyles.css';
import '../../../assets/styles/styles.css';
import '../confirmationPopup/ConfirmationPopup.css'
class DynamicCustomPopup extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props)
            return null;
        console.log('image_hyperlink', this.props.image_hyperlink);
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <div className="modal-header">
                        <h4 className="edit-cardtitle">{this.props.note_title != null ? this.props.note_title : "Note"}</h4>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body">
                        <div>
                            {this.props.note_content != null ?
                                <div className="Link-text ml-3 mt-2 scroll-auto h-12vh" style={{ whiteSpace: 'pre-line', width: '400px' }} >
                                    {this.props.note_content}
                                </div>
                                : null
                            }

                            {this.props.note_type == 'Image' ?
                                <div className="d-flex justify-content-center p-10" style={{ height: '200px', width: '400px' }}>
                                    <img style={{ objectFit: 'cover' }}
                                        alt=''
                                        src={this.props.image_url}
                                    />
                                </div>
                                : null
                            }

                            {this.props.image_hyperlink !== '' ?
                                <a
                                    className='tata-policy-link ml-3'
                                    href={this.props.image_hyperlink}
                                    target="_blank">
                                    Click here to know more
                                </a> : null
                            }

                        </div><br />
                        <div className='row d-flex justify-content-end'>
                            <input type='button'
                                value='OK'
                                className='ml-2 validateBtn'
                                onClick={this.props.onDismiss} />
                        </div>
                        {/* <div className="row d-flex justify-content-end">
                            <button type="button"
                                className="btn btn-primary pr-3  justify-content-end"
                                onClick={this.props.onDismiss}>Ok
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default DynamicCustomPopup;