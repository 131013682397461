import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { toaster } from '../../../../../utils/Utils';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import {get_agency, get_division, updateEvaluatorAction} from '../../../../../constants/api.service';
class EvaluatorCommonPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            division: '',
            agency: '',
            remarks: null,
            division_id: null,
            agency_id: null,
            divisionData: [],
            agencyList: []
        }
    }
    componentDidMount(){
        this.handleDivisionDropdown();
    }

    handleDivisionDropdown(){
        let param = {
            comp_code: this.props.loginData.userData.CompCode,
            pa: this.props.loginData.userData.PersArea
        };
        get_division(param).then((res)=>{
            if (res.status === 200){
                this.setState({
                    divisionData : res.data.data.division_list
                })
            }else{
                toaster("error", res.data.message)
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    handleAgencyDropdown (){
        let params = {
            division: this.state.division_id
        };
        this.setState({agency:''})
        get_agency(params).then((res)=>{
            if (res.status === 200){
                this.setState({
                    agencyList : res.data.data.agency_list
                })
            }else{
                toaster("error", res.data.message)
            }
        }).catch((err)=>{
            console.log("err ----- " ,err)
        })
    }

    handleBtnAction(){
        let payload ={
            action: this.props.title,
            sug_ids: this.props.data.map((data)=>{return data.id}),
            comment: this.state.remarks,
            agency: this.state.agency_id !== '' ? this.state.agency_id : null,
            from_agency_name: this.props.agency ?? null,
            from_status: 0,
            from_role: "Evaluator",
            to_agency_name: this.state.agency !== '' ? this.state.agency : null,
            to_status: this.props.title === 'Send_to_other_evaluator' ? 0 : this.props.title === 'Reject' ? 7: 6,
            to_role: this.props.title === 'Send_to_other_evaluator' ? 'Evaluator' : this.props.title === 'Reject' ? null: 'Suggestor',
        }
        updateEvaluatorAction(payload).then((res)=>{
            if (res){
                if (res.status === 200){
                    toaster("success", res.data.message)
                    this.props.onDismiss()
                }else{
                    toaster("error", res.data.message)
                }
            }
        }).catch((err)=>{
            console.log("error ----- ", err)
        })
    }
   
    render() {
        const { title, loading } = this.props
        const { division, agency, remarks } = this.state
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{title == "Send_to_other_evaluator" ? "Suggestion to other Evaluator" : title == "Send_back" ? "Clarification Remarks" : title === "Reject" ? "Rejection Remark" : "Re-evaluation Remarks"}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        {/* ------ EVALUATOR Body-------*/}
                        {title === "Send_to_other_evaluator" &&
                            <div className='flex-direction-column justify-content-center pl-3 pr-3'>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Division"}</div>
                                    <DynamicDropdown
                                        data={this.state.divisionData}
                                        render="division_name"
                                        value={division ? division : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) =>{this.setState({
                                            division: data.division_name,
                                            division_id: data.division_code
                                        },()=>{this.handleAgencyDropdown()})
                                    }}
                                        arrow={'true'}
                                        height={'NotificationHeight-L'}
                                        width={"w-16rem"}
                                    />
                                    {this.state.division_id ==='' &&
                                    <div className='text-left p-sub-heading errorBlock'>
                                        Please select division
                                    </div>
                                    }
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Select Agency"}</div>
                                    <DynamicDropdown
                                        data={this.state.agencyList}
                                        render="agency_name"
                                        value={agency ? agency : null}
                                        placeholder={"Select value"}
                                        selectedValue={(data) => this.setState({
                                            agency: data.agency_name,
                                            agency_id : data.agency_code
                                        })}
                                        arrow={'true'}
                                        height={'NotificationHeight-L'}
                                        width={"w-16rem"}
                                    />
                                    {this.state.agency_id ==='' &&
                                    <div className='text-left p-sub-heading errorBlock'>
                                        Please select agency
                                    </div>
                                    }
                                </div>
                                <div className="popup_text text-center p-1">
                                    <div className='popup_text text-left p-1 required'>{"Enter Remarks for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px mb-1'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) =>  {this.setState({ remarks : e.target.value}) }}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                    {this.state.remarks ==='' &&
                                    <div className='text-left p-sub-heading errorBlock'>
                                        Please add remark
                                    </div>
                                    }
                                </div>
                            </div>
                        }
                        {title === "Send_back" &&
                            <div className='flex-direction-column justify-content-center pl-3 pr-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Remarks for Clarification for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                    {this.state.remarks ==='' &&
                                        <div className='text-left p-sub-heading errorBlock'>
                                            Please add remark
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        {title === "Reject" &&
                            <div className='flex-direction-column justify-content-center pl-3 pr-3'>
                                <div className="popup_text text-center p-3">
                                    <div className='popup_text text-left p-1 required'>{"Enter Rejection Reason for Selected Suggestions"}</div>
                                    <div className='row w-100 row-gap-10px'>
                                        <textarea
                                            className="form-control esep-placeholder-editable px-3"
                                            type="textarea"
                                            onChange={(e) => this.setState({ remarks : e.target.value})}
                                            value={remarks ? remarks : ''}
                                            placeholder={'Type your remarks here...'}
                                            maxLength={350}
                                        />
                                    </div>
                                    {this.state.remarks ==='' &&
                                        <div className='text-left p-sub-heading errorBlock'>
                                            Please add remark
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        {title === 'Send_to_other_evaluator' ?
                            <input type='button' value='Send Suggestion to Other Evaluator' className={(this.state.agency_id === null || this.state.division_id === null || this.state.remarks === '' ||this.state.remarks === null ) ? "ml-2 validateBtnDisabled" : "ml-2 validateBtn"} onClick={() => { this.handleBtnAction(); this.props.submitEvaluator(division, agency, remarks) }} disabled={this.state.agency_id === "" || this.state.division_id === "" || this.state.remarks === '' || this.state.remarks === null || this.state.agency_id === null || this.state.division_id === null} /> :
                            title === 'Send_back' ?
                                <input type='button' value='SEND BACK' className={this.state.remarks ===''|| this.state.remarks ===null? "ml-2 validateBtnDisabled":"ml-2 validateBtn"} onClick={() => {this.handleBtnAction();this.props.submitSendBack(remarks)}} disabled={this.state.remarks ==='' || this.state.remarks === null}/> :
                                title === 'Reject' ? <input type='button' value='REJECT' className={this.state.remarks ===''||this.state.remarks ===null ? "ml-2 validateBtnDisabled":"ml-2 validateBtn"} onClick={() => {this.handleBtnAction();this.props.submitRejection(remarks)}} disabled={this.state.remarks ==='' || this.state.remarks === null}/> :
                                    null
                        }
                    </div>
                </div>
            </div>

        );
    }
}
export default EvaluatorCommonPopup;