import React, { Component } from 'react';
import '../continuousFeedbackManagement/CFMHome.css';
import '../../../../assets/styles/flex.css';
import '../../../../assets/styles/styles.css';
import icon from '../../../../assets/images/cfm/icon.svg';
import '../../../../assets/styles/colors.css';
import nodata from '../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { dateFromNow } from '../../../../utils/Utils'
import Dropdown from '../../../shared/components/Dropdown'
import { PerformanceTimelineSkeleton } from '../../../shared/skeleton/Skeletons';
const givenSelfFeedback = 'given a feedback '
const givenDEMFeedback = 'shared a feedback on '

const onTheirGoal = 'the '
const onYourGoal = 'the '
class Performance extends Component {
    constructor() {
        super();
        this.state = {
            performanceData: [],
            filter: '',
            loading: false,
            filterData: [
                { value: 'All', input: 'All' },
                { value: 'Goals', input: 'Goal' },
                { value: 'Leadership Behaviours', input: 'Competency' }
            ],
            showfilter: ''
        }
    }
    componentDidMount() {
        let data = this.props.performanceData ? this.props.performanceData : []
        this.setState({
            performanceData: data,
            filter: 'All',
            showfilter: 'All'
        })

    }
    componentWillReceiveProps = (nextProps) => {
        const { performanceData } = this.state
        if (performanceData != nextProps.performanceData) {
            this.setState({ performanceData: nextProps.performanceData })
        }
    }

    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }

    changeFilter = (filterdata) => {
        this.setState({
            filter: filterdata.input,
            showfilter: filterdata.value
        }
            , this.props.getPerformanceData(filterdata.input)
        )
    }

    render() {
        const { applicationMode, disabledMessage, loading } = this.props
        console.log('write', applicationMode);
        return (
            <div className="row justify-content-between align-item-center">
                <LoaderIndicator loading={this.state.loading} />
                <div className="cfm-card p-3 ml-0 mr-1">
                    <div className='d-flex justify-content-between mb-3'>
                        <div className='cfm_label'>Achievement Updates</div>
                        <div className='d-flex justify-content-end p-0'>
                            <div className='dropdown filter-btn mx-2' >
                                <Dropdown
                                    data={this.state.filterData}
                                    selectedValue={(data) => this.changeFilter(data)}
                                    value={this.state.showfilter}
                                    render={'value'}
                                    height={''}
                                />
                            </div>
                        </div>
                    </div>
                    {applicationMode === 'close' ?
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column mt-25'>
                                <div className='row'>
                                    <img src={nodata} alt="Nodata" />
                                </div>
                                <div className='row'>
                                    <label className='card-content-title grey-color'>{disabledMessage}</label>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            {loading ?
                                <>
                                    <div>
                                        <PerformanceTimelineSkeleton />
                                        <PerformanceTimelineSkeleton />
                                        <PerformanceTimelineSkeleton />
                                        <PerformanceTimelineSkeleton />
                                        <PerformanceTimelineSkeleton />
                                        <PerformanceTimelineSkeleton />
                                    </div>
                                </>
                                :
                                <>
                                    {this.state.performanceData.length != 0 ?
                                        <div className='performance-inside-scrollbar'>
                                            {this.state.performanceData.map((listValue, id) => {
                                                return (
                                                    <div className={id < (this.state.performanceData.length - 1) ? "timeline ptm" : 'timeline1 ptm'}>
                                                        <div className='container1 mt-1'>
                                                            <div className='container2 mt-1'>
                                                                <div
                                                                    className={listValue.role === 'FEM' ? 'fem-bg-circle circle-text' :
                                                                        listValue.role === 'DEM' ? 'dem-bg-circle circle-text' :
                                                                            "employee-bg-circle circle-text"}
                                                                >
                                                                    {this.short_name(listValue.commented_by_name)}
                                                                </div>
                                                            </div>
                                                            <div className='h-liness'></div>
                                                            <div className='content right p-12px'>
                                                                <div className='justify-content-between align-item-center row '>
                                                                    <div className='row'>
                                                                        <div className='sub-card-text-bold pr-1'>
                                                                            {listValue.commented_by_name}
                                                                        </div>
                                                                        <div className='sub-card-text-light pr-1'>
                                                                            {givenDEMFeedback}
                                                                            {/* {(listValue.role === 'DEM' || listValue.role === 'FEM') ? givenDEMFeedback : givenSelfFeedback} */}
                                                                        </div>
                                                                        <div className='sub-card-text-bold'>
                                                                            {onYourGoal}{listValue.type === 'Competency' ? 'Leadership behavior' : listValue.type}
                                                                            {/* {(listValue.role === 'DEM' || listValue.role === 'FEM') ? onYourGoal : onTheirGoal} {listValue.type} */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='justify-content-end align-item-center d-flex'>
                                                                        <div className='mr-2'>
                                                                            <img alt="icon" src={icon} className="icon"></img>
                                                                        </div>
                                                                        <div className='time-text'>
                                                                            {dateFromNow(listValue.created_date_time)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row mt-1'>
                                                                    <div className=''>
                                                                        <div className='justify-content-center mb-3'>
                                                                            <label className='sub-card-text-bold'>
                                                                                {listValue.title}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        :
                                        <div className='row'>
                                            <div className='col-md-12 d-flex align-items-center flex-column mt-25'>
                                                <div className='row'>
                                                    <img src={nodata} alt="Nodata" />
                                                </div>
                                                <div className='row'>
                                                    <label className='card-content-title grey-color'>No feedbacks shared yet!</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }

                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default (connect(mapStatesToProps)(Performance));