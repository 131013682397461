import React, { useEffect, useState } from 'react'
import CommonCard from '../../../employeeScreens/suggestionScheme/commonComponent/CommonCard'
import DataNotFound from '../../../../shared/notFound/DataNotFound'
import leftArrow from '../../../../../assets/drishti_images/left-arrow.svg';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import Table from './Table';
import CommonButton from '../../../../shared/button/GradientButton';
import SuggestionDetailsPopUp from './SuggestionDetailsPopUp';
import EvaluatorDetailsViewPopUp from "./EvaluatorDetailsViewPopUp";
import { suggestionTableFields } from '../../../employeeScreens/suggestionScheme/constants';
import EvaluatorCommonPopup from './EvaluatorCommonPopup';
import { getEvaluatorAgencyDashboard, getEvaluatorSuggestionList, getMasterLists } from '../../../../../constants/api.service';
import { toaster } from '../../../../../utils/Utils';
import { useSelector } from 'react-redux';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import searchIcon from '../../../../../assets/drishti_images/admin/searchIcon.svg';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import { Pagination } from 'react-bootstrap';

function EvaluatorApprovals({ isEvaluatorScreen, isEvaluatorScreenReason }) {
  const [loading, setLoading] = useState(false)
  const [agency, setAgency] = useState('')
  const [filteredAgencyList, setFilteredAgencyList] = useState([])
  const [agencyList, setAgencyList] = useState([])
  const [selectedAgency, setSelectedAgency] = useState('')
  const [selectedAgencyId, setSelectedAgencyId] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState({ id: 1, status_name: 'Pending at Evaluator', status_code: 0 })
  const [selectedSchemeType, setSelectedSchemeType] = useState([])
  const [statusList, setStatusList] = useState([])
  const [schemeTypeList, setschemeTypeList] = useState([])
  const [selectedSuggestion, setSelectedSuggestion] = useState([])
  const [selectedRecordId, setSelectedRecordId] = useState('')
  const [evaluatorDivision, setEvaluatorDivision] = useState('')
  const [evaluatorAgency, setEvaluatorAgency] = useState('')
  const [evaluatorRemark, setEvaluatorRemark] = useState('')
  const [rejectionRemark, setRejectionRemark] = useState('')
  const [sendBackRemark, setSendBackRemark] = useState('')
  const [selectedPopupAction, setSelectedPopupAction] = useState('')
  const [openCommonPopup, setOpenCommonPopup] = useState(false)
  const [evaluatorPaginatedList, setEvaluatorPaginatedList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchSuggText, setSearchSuggText] = useState(null);

  const loginData = useSelector(state => state.loginState);

  useEffect(() => {
    getEvaluatorAgencyDashboardCall()
    getStatusList()
    getSchemeTypeList()
  }, [])
  const getEvaluatorAgencyDashboardCall = () => {
    setLoading(true)
    let params = {
      comp_code: loginData.userData.CompCode,
      pa: loginData.userData.PersArea
    }
    getEvaluatorAgencyDashboard(params)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setFilteredAgencyList(data?.data ? data?.data : [])
          setAgencyList(data?.data ? data.data : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStatusList = () => {
    setLoading(true)

    getMasterLists("status")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setStatusList(data?.data ? data?.data?.status_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const getSchemeTypeList = () => {
    setLoading(true)

    getMasterLists("scheme_type")
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setschemeTypeList(data?.data ? data?.data?.scheme_type_list : [])
        }
        else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
      })
      .catch((error) => { toaster("warning", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }
  const handlePageCount = (action) => {
    setSelectedSuggestion([])
    if (action === "decrement" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
      paginationCall(currentPage - 1)

    } else if (action === "increment" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      paginationCall(currentPage + 1)
    }
  };

  const commonSerachFilters = (data, type) => {
    if (type === "Agency") {
      setSelectedAgency(data.agency_name)
      setSelectedAgencyId(data.agency_id)
      openSelectedAgency(data.agency_id, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])

    } else if (type === "Status") {
      setSelectedStatus(data)
      openSelectedAgency(selectedAgencyId, data?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])
    } else if (type === "Scheme") {
      setSelectedSchemeType(data)
      openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, data?.scheme_type_code, searchSuggText)
      setSelectedSuggestion([])
    } else if (type === "Search") {
      openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, data)
      setSelectedSuggestion([])
    }
  }

  const openSuggListScreen = (value) => {
    setSelectedAgency(value.agency_name);
    setSelectedAgencyId(value.agency_id)
    openSelectedAgency(value.agency_id, selectedStatus?.status_code)
  }

  const closePopup = () => {
    setSelectedRecordId('')
    openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code)
    setSelectedSuggestion([])
  }

  const paginationCall = (currentPageNo) => {
    openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code, searchSuggText, currentPageNo)
  }

  const openSelectedAgency = (data, sugStateCode, SchemeType, searchText, currentPageNo = currentPage) => {
    setLoading(true)

    let params = {
      sug_status_code: sugStateCode !== null ? sugStateCode : null,
      scheme_type_code: SchemeType ?? '',
      search: searchText !== null ? searchText : null,
      page_number: currentPageNo !== null ? currentPageNo : currentPage
    }
    getEvaluatorSuggestionList(params, data)
      .then((res) => {
        const { status, data } = res
        if (status === 200 || status === 201) {
          setEvaluatorPaginatedList(data?.data ? data.data?.paginated_data : [])
          setTotalPages(data?.data ? data.data?.total_pages : null)
        }
        else {
          toaster("warning", res?.data?.message ?? "Something Went Wrong!")
          setEvaluatorPaginatedList([])
        }
      })
      .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
      .finally(() => {
        setLoading(false)
      })
  }

  const searchFilterFunction = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setAgency(e.target.value);
    if (searchValue === '') {
      setFilteredAgencyList(agencyList);
    } else {
      setFilteredAgencyList(
        agencyList.filter((val) =>
          val.agency_name.toLowerCase().includes(searchValue)
        )
      );
    }
  }

  const backAction = () => {
    setSelectedSchemeType([])
    setSelectedStatus({ id: 1, status_name: 'Pending at Evaluator', status_code: 0 })
    setSelectedAgency('');
    setFilteredAgencyList(filteredAgencyList);
    setAgency('')
    setEvaluatorPaginatedList([])
    setSelectedSuggestion([])
    setTotalPages(1)
    setCurrentPage(1)
  }

  const callSelectedPopupAction = (actionType) => {
    setSelectedPopupAction(actionType)
    setOpenCommonPopup(!openCommonPopup)
  }

  const submitEvaluator = (division, agency, remarks) => {
    setEvaluatorRemark(remarks)
    setEvaluatorAgency(agency)
    setEvaluatorDivision(division)
    setOpenCommonPopup(false)
  }

  const setSearchSuggTextCall = (e) => {
    const value = e.target.value;
    if (value === '') {
      setSearchSuggText(null);
    } else {
      setSearchSuggText(value);
    }
  }

  return (
    <div>
      {isEvaluatorScreen ?
        <>
          <div className="col-md-12 h-100vh justify-content-center align-item-center">
            <ServiceUnavailable reason={isEvaluatorScreenReason} />
          </div>
        </>
        :
        <>
          <div className='ml-10 h-90'>
            <LoaderIndicator loading={loading} />
            {/* ------------------------------------------------------------------- Landing Screen -------------------------------------------------------- */}
            {
              selectedAgency === '' ?
                <div className='row justify-content-between mt-10px'>
                  <div className='popup-title'>Select Agency</div>
                  <div className='row'>
                    <div className='row ml-15px'>
                      <div className='p-placeholder-text mr-3'>
                        <input
                          className="form-control p-placeholder px-2 p-placeholder-admin"
                          type="text"
                          placeholder='Search Agency'
                          onChange={(e) => { searchFilterFunction(e) }}
                          value={agency}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className='row justify-content-between mt-10px'>
                  {/* ------------------------------------------------------------------- Listing Screen -------------------------------------------------------- */}
                  <span className='pointer align-item-center add-btn-text' onClick={backAction}>
                    <img
                      alt="left arrow"
                      src={leftArrow}
                      className="left-arrow"
                    />
                    Back
                  </span>
                </div>
            }
            {
              selectedAgency === '' ?
                <div className='row mt-10px gap-20 h-66vh scroll-y'>
                  {
                    filteredAgencyList.length > 0 ?
                      filteredAgencyList.map((value) =>
                        <div className='w-32 h-28vh pointer'>
                          {/* ------------------------------------------------------------------- Landing Screen -------------------------------------------------------- */}
                          <CommonCard onClick={() => openSuggListScreen(value)} >
                            <div className='flex-direction-column h-100 justify-content-center w-97 align-items-center gap-10'>
                              <div className='suggestion-card-content'>{value.count}</div>
                              <div className='document-innercard-content-title'>{value.agency_name}</div>
                            </div>
                          </CommonCard>
                        </div>
                      )
                      : <div className='justify-content-center align-items-center w-100'>
                        <DataNotFound />
                      </div>
                  }
                </div>
                :
                <div className=''>
                  {/* ------------------------------------------------------------------- Listing Screen -------------------------------------------------------- */}
                  <div className='row align-item-center background-highlighter'>
                    <div className='flex-direction-row gap-10px align-item-center mr-4'>
                      <div className='p-sub-heading ml-2'>{"Agency"}</div>
                      <div className='d-flex align-item-center'>
                        <div className='w-12rem'>
                          <DynamicDropdown
                            data={agencyList}
                            render="agency_name"
                            value={selectedAgency}
                            selectedValue={(data) => commonSerachFilters(data, "Agency")}
                            arrow={'true'}
                            height={'dependOnContent'}
                            // height={"NotificationHeight"}
                            width={'w-12rem'}
                            boxWidth="14rem"
                          // bg={'12rem'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex-direction-row gap-10px align-item-center ml-4'>
                      <div className='p-sub-heading ml-2'>{"Suggestion Status"}</div>
                      <div className='d-flex align-item-center'>
                        <div className='w-16rem'>
                          <DynamicDropdown
                            data={statusList}
                            render="status_name"
                            value={selectedStatus.status_name}
                            selectedValue={(data) => commonSerachFilters(data, "Status")}
                            arrow={'true'}
                            // height={'NotificationHeight'}
                            height={'dependOnContent'}
                            width={"w-16rem"}
                            boxWidth="12rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex-direction-row gap-10px align-item-center'>
                      <div className='p-sub-heading ml-2'>{"Type of Suggestion"}</div>
                      <div className='d-flex align-item-center'>
                        <div className='w-13rem'>
                          <DynamicDropdown
                            data={schemeTypeList}
                            render="scheme_type"
                            value={selectedSchemeType.scheme_type}
                            selectedValue={(data) => commonSerachFilters(data, "Scheme")}
                            arrow={'true'}
                            height={'dependOnContent'}
                            width={"w-13rem"}
                            boxWidth="12rem"
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex-direction-row gap-10px align-item-center'>
                      <div className="td-choice-pay p-sub-heading">Search</div>
                      <div className='d-flex align-item-center'>
                        <div className='p-placeholder-text'>
                          <input
                            className="form-control p-placeholder px-2 p-placeholder-admin"
                            type="text"
                            placeholder='Search'
                            onChange={(e) => setSearchSuggTextCall(e)}
                            value={searchSuggText}
                          />
                        </div>
                        <div type='button' className='serchIconSize-evaluator' onClick={() => commonSerachFilters(searchSuggText, "Search")} >
                          <img alt="radioOn" className='filterIcon' src={searchIcon} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    evaluatorPaginatedList?.length > 0 ?
                      <div>
                        <Table
                          isSrNoShow={false}
                          hasAction
                          // className={"h-40vh"}
                          paginationCall={(data) => paginationCall(data)}
                          columns={suggestionTableFields}
                          data={evaluatorPaginatedList}
                          onSelectSuggestion={(data) => { setSelectedSuggestion(data) }}
                          selectedSuggestion={selectedSuggestion}
                          setCurrentPage={setCurrentPage}
                          totalPages={totalPages}
                          currentPage={currentPage}
                          pageSize={6}
                          onAction={(data) => setSelectedRecordId(data.id)}
                          suggestionStatus={selectedStatus.status_name}
                          selectedStatus={selectedStatus}
                          readOnly={true}
                          minHeight={"50vh"}
                        />
                      </div>
                      :
                      <div className='justify-content-center align-items-center w-100 h-54vh'>
                        <DataNotFound />
                      </div>
                  }
                  {openCommonPopup &&
                    <>
                      <EvaluatorCommonPopup
                        title={selectedPopupAction}
                        action={'finalAction'}
                        submitEvaluator={(division, agency, remarks) => { submitEvaluator(division, agency, remarks) }}
                        submitSendBack={(remarks) => { setSendBackRemark(remarks); setOpenCommonPopup(false) }}
                        submitRejection={(remarks) => { setRejectionRemark(remarks); setOpenCommonPopup(false) }}
                        onDismiss={() => { setOpenCommonPopup(false); openSelectedAgency(selectedAgencyId, selectedStatus?.status_code, selectedSchemeType?.scheme_type_code); setSelectedSuggestion([]) }}
                        data={selectedSuggestion}
                        agency={selectedAgency}
                        loginData={loginData}
                      />
                    </>
                  }
                  {
                    evaluatorPaginatedList?.length > 0 &&
                    <div className='row align-item-center justify-content-between Evaluator-background-highlighter gap-10' style={{ bottom: 0 }}>
                      <div className='ptb-0_375 plr-0_75'>{selectedSuggestion.length + '/' + evaluatorPaginatedList.length + ' records selected.'}</div>
                      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Pagination>
                          {currentPage != 1 &&
                            <>
                              <Pagination.Prev
                                onClick={() => { handlePageCount("decrement") }} />
                              <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                              <Pagination.Ellipsis disabled />
                            </>
                          }
                          <Pagination.Item active>{currentPage}</Pagination.Item>
                          {currentPage != totalPages &&
                            <>
                              <Pagination.Ellipsis disabled />
                              <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                              <Pagination.Next
                                onClick={() => { handlePageCount("increment") }} />
                            </>
                          }
                        </Pagination>
                      </div>
                      <div style={{ display: 'flex', gap: '7px', minWidth: '350px', justifyContent: 'flex-end' }}>
                        {
                          selectedSuggestion.length > 0 &&
                          <div className='row gap-7px'>
                            <CommonButton
                              label="Send Suggestion to Other Evaluator"
                              onClick={() => { callSelectedPopupAction("Send_to_other_evaluator") }}
                              isGradientBtn={true}
                              className={'px-20px'}
                            />
                            {!selectedSuggestion.some(ele => ele.is_send_back) &&
                              <CommonButton
                                label="SEND BACK"
                                onClick={() => { callSelectedPopupAction("Send_back") }}
                                isGradientBtn={true}
                                className={'px-20px'}
                              />
                            }
                            <CommonButton
                              label="REJECT"
                              onClick={() => { callSelectedPopupAction("Reject") }}
                              isGradientBtn={true}
                              className={'px-20px'}
                            />
                          </div>
                        }
                      </div>
                    </div>
                  }
                </div>
            }
            {selectedRecordId !== '' && (selectedStatus.status_code === 0 ? <SuggestionDetailsPopUp title={'Suggestion Details'} backAction={closePopup} selectedRecordId={selectedRecordId} data={selectedSuggestion} agency={selectedAgencyId} from_agency={selectedAgency} suggestionStatus={selectedStatus.status_name} selectedStatus={selectedStatus} /> : <EvaluatorDetailsViewPopUp title={'Suggestion Details'} backAction={closePopup} selectedRecordId={selectedRecordId} data={selectedSuggestion} agency={selectedAgencyId} from_agency={selectedAgency} suggestionStatus={selectedStatus.status_name} selectedStatus={selectedStatus} />)}
          </div>
        </>
      }
    </div>
  )
}

export default EvaluatorApprovals