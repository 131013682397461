import React, { Component } from 'react';
import DataNotFound from '../../../shared/notFound/DataNotFound';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    scales
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Legend,
    scales
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'center',
        },
        datalabels: {
            anchor: 'end',
            align: 'top',
        }
    },
    scales: {
        x: {
            gridLines: {
                drawOnChartArea: false
            }
        },
        y: {
            display: false,
        }
    },
};

class DepartmentReportees extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            levelData: [],
            levelNames: []
        }
    }

    componentDidMount() {
        this.getData()
    }
    getData = () => {
        const reporteesData = this.props.loginData.cfmUserDetails.employee_level_report ? this.props.loginData.cfmUserDetails.employee_level_report : []
        let tempValueArr = []
        let tempNameArr = []
        reporteesData.map((list) => {
            tempValueArr.push(`${list.value}`)
            tempNameArr.push(list.key)
        })

        this.setState({
            levelData: tempValueArr,
            levelNames: tempNameArr
        })

    }
    render() {
        const { levelData, levelNames } = this.state
        const labels = levelNames;
        const data = {
            labels,
            datasets: [
                {
                    label: 'Level Chart',
                    data: levelData,
                    backgroundColor: ["#9B72E5", "#8e5ea2", "#6B3EBA", "#3D1D76"],
                    borderColor: "rgba(75,192,192,1)",
                },

            ],
        };
        return (
            <div>
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Employee Level Reportees</label>
                        </div>
                    </div>
                </div>
                <div className={window.innerWidth <= 600 ? 'row' : ''}>
                    {this.state.levelData.length != 0 ?
                        <div className="pt-2 charT height-200">
                            <div className='subheight-child mt-2' style={{ height: '200px' }}>
                                <Bar
                                    height='200px'
                                    width='340px'
                                    options={options}
                                    data={data} />
                                <div className='row'>
                                    {this.state.levelData.map((item, i) =>
                                        <div className={i === 0 ? 'countValuefirst' : 'countValue'}
                                            style={i === 1 ? { border: '1px solid #8e5ea2', backgroundColor: '#8e5ea2' } :
                                                i === 2 ? { border: '1px solid #6B3EBA', backgroundColor: '#6B3EBA' }
                                                    : i === 3 ? { border: '1px solid #3D1D76', backgroundColor: '#3D1D76' } : null}
                                        >{item}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <div className='mt-5'>
                            <DataNotFound />
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default DepartmentReportees;