import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import '../../../../assets/styles/basic.css';
import '../../../../assets/styles/popupStyles.css';
import { bankDetails, dependentNote, bankDetailsNote } from '../../../../constants/Messages';
class NotePopupPaySpace extends Component {
    onClickApplyButton = () => {
        this.props.onDismiss();
    }
    render() {
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <>
                        <div className="modal-header">
                            Note :
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                onClick={this.props.onDismiss}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='p-10 mb-10px'>
                                {/* <div className='performance-timeline-text-lighter'>{'If you have selected for Old Tax Regime, it cannot be changed later in '}{this.props.FinancialYear}</div> */}
                                <div className='performance-timeline-text-lighter'>{'Since you have selected Old Tax Regime, you cannot change the tax regime option again in the current financial year.'}</div>
                                
                            </div>
                        </div>
                    </>
                </div>
            </div>

        );
    }
}

export default NotePopupPaySpace;