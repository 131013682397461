import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import { connect } from 'react-redux';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import Dropdown from '../../../../shared/components/Dropdown';
import { Month } from '../../../../../utils/Utils';
import '../Notification.css';
import * as AdminLoginAction from '../../../adminScreens/login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import Rejectcross from '../../../../../assets/drishti_images/Rejectcross.svg';
import approvewrite from '../../../../../assets/drishti_images/approvewrite.svg';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

class PushNotificationDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            listType: '',
            pushNotificationAdminData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            isActive: true,
            actionValue: '',
            monthData: Month(),
            selectMonth: '',
            selectMonthCode: '',
            doReject: false,
            doActive: false,
            isPushNotifications: false,
            tempPushNotificationData: [],
            approvedNotification: '',
            requestData: [
                {
                    id: 1,
                    title: 'pending'
                },
                {
                    id: 2,
                    title: 'approved'
                },
                {
                    id: 3,
                    title: 'rejected'
                }
            ]
        }
    }

    componentDidMount() {
        this.setState({ listType: 'pending', selectMonth: this.state.monthData[0].value, selectMonthCode: this.state.monthData[0].code })
    }

    // getPushNotification = () => {
    //     this.setState({
    //         loading: true
    //     })
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     let body = {
    //         month: this.state.selectMonthCode === '' ? 'All' : this.state.selectMonthCode
    //     }
    //     fetchApiCall(Config.GetPushNotification, body, headers, false, 'admin')
    //         .then(res => {
    //             sessionOut(this.props, res);
    //             if (res.data.status_code === '200') {
    //                 this.setState({
    //                     loading: false,
    //                     pushNotificationAdminData: res.data ? res.data.data : []
    //                 });
    //             } else {
    //                 this.setState({
    //                     loading: false,
    //                     pushNotificationAdminData: [],
    //                 })
    //             }
    //         }).catch(err => {
    //             this.setState({
    //                 loading: false
    //             })
    //             toast.error(requestFailed, {
    //                 position: "bottom-center",
    //                 hideProgressBar: true,
    //                 className: 'toastError'
    //             })
    //         })
    // }

    bulkUploadPushNotification = (status) => {
        this.setState({
            loading: true,
            showConfirm: false,
            doActive: false,
            doReject: false
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            title: this.state.tempPushNotificationData.notification_title,
            body: this.state.tempPushNotificationData.notification_content,
            image_name: this.state.tempPushNotificationData.notification_image,
            emp_mail: this.props.adminLoginData.adminData.user_email,
            notification_id: this.state.tempPushNotificationData.notification_id,
            is_approved: status,
            notification_type: this.state.approvedNotification
        }
        fetchApiCall(Config.bulkUploadPushNotification, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                    this.props.getPushNotificationCall()
                    )
                    this.setState({
                        loading: false,
                    });
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    activeConfirmationPopup = (listValue) => {
        this.setState({ selectedData: listValue, showConfirm: !this.state.showConfirm })
    }
    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    activeShowData = (data) => {
        this.setState({ selectedData: data, showDetail: true })
    }

    onPresentTypeChange = (key, type) => {
        if (type === "Active") {
            this.setState({
                checked: key,
                listType: "approved"
            })
        }
        else if (type === "Inactive") {
            this.setState({
                checked: key,
                listType: "rejected"
            })
        }
        else {
            this.setState({
                checked: key,
                listType: type
            })
        }
    }

    changeMonth = (data) => {
        this.setState({
            selectMonthCode: data.code,
            selectMonth: data.value,
        },
            this.props.getPushNotificationCall()
        )
        console.warn("change Month code:", this.state.selectMonthCode)
    }

    toggle = (isActive) => {
        if (isActive === 'active') {
            this.setState({
                isActive: true
            })
        }
        else {
            this.setState({
                isActive: false
            })
        }
    }

    onAction = (listData, type) => {
        console.log('listData', listData.notification_type);
        if (type === 'approve') {
            this.setState({
                doActive: true,
                approvedNotification: listData.notification_type,
                tempPushNotificationData: listData
            })
        }
        else {
            this.setState({
                doReject: true,
                approvedNotification: listData.notification_type,
                tempPushNotificationData: listData
            })
        }
    }

    render() {
        const { listType, showConfirm, showDetail, doActive, doReject, requestData } = this.state
        // const { requestData } = this.props
        let i = 0;
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='d-flex row mt-2' style={{ 'alignItems': 'center' }}>
                    <div className='status-dashboard ml-4 mt-2'>Status :</div>
                    <div className='row align-item-center'>
                        {requestData.length != 0 && requestData.map((list, key) => {
                            return (
                                <div key={key} className='mx-4 mt-3 mb-2 '>
                                    {this.state.checked === key ?
                                        <div className='' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                            <img alt="radioOn" className='radio' src={radioOn} />
                                            <label className="radioTxt" >{list.title.charAt(0).toUpperCase() + list.title.slice(1)}</label>
                                        </div>
                                        :
                                        <div className='' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                            <img alt="radioOff" className='radio' src={radioOff} />
                                            <label className="radioTxt">{list.title.charAt(0).toUpperCase() + list.title.slice(1)}</label>
                                        </div>
                                    }
                                </div>
                            )
                        })
                        }
                    </div>
                    <div className='ml-2' style={{ 'position': 'absolute', 'right': 70 }}>
                        <Dropdown
                            data={this.state.monthData}
                            selectedValue={(data) => this.changeMonth(data)}
                            value={this.state.selectMonth}
                            render={'value'}
                            arrow={'true'}
                            height={'height'}
                        />
                    </div>
                </div>
                <div className=" pt-2 pushNotification-container" >
                    {this.props.pushNotificationAdminData1.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" style={{ 'width': '3%' }}><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Title</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Notification Content</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Created Date</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Created By</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Status </div></th>
                                    {listType === 'pending' &&
                                        <th scope="col" style={{ 'width': '2%' }}><div className='thead' style={{ 'marginLeft': '15%' }}>Action</div></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.pushNotificationAdminData1.map((listValue, index) => {
                                    return (
                                        <>
                                            <tr key={index} className=''>
                                                {listValue.is_approved === listType &&
                                                    <>
                                                        <td><div className="td" style={{ width: 20 }}>{i = i + 1}</div></td>
                                                        <td><div className="td" >{listValue.notification_title ? listValue.notification_title : ''}</div></td>
                                                        <td><div className="td">{listValue.notification_content ? listValue.notification_content : ''}</div></td>
                                                        <td><div className="td" >{moment(listValue.created_datetime).format("DD-MM-YYYY")}</div></td>
                                                        <td><div className="td">{listValue.created_by ? listValue.created_by : ''}</div></td>
                                                        <td><div className="td">{listValue.is_approved ? listValue.is_approved.charAt(0).toUpperCase() + listValue.is_approved.slice(1) : ''}</div></td>
                                                        {listType === 'pending' &&
                                                            <td className='d-flex'>
                                                                <>
                                                                    <div onClick={() => this.onAction(listValue, 'approve')}>
                                                                        <img alt="approve" src={approvewrite} style={{ width: '25px' }} className='mx-2' />
                                                                    </div>
                                                                    <div onClick={() => this.onAction(listValue, 'reject')}>
                                                                        <img alt="delete" src={Rejectcross} style={{ width: '25px' }} />
                                                                    </div>
                                                                </>
                                                            </td>
                                                        }
                                                    </>
                                                }
                                            </tr>
                                        </>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No push notifications have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {showConfirm &&
                    <NotificationConfirmPopup
                        loader={this.state.loading}
                        title={'Delete Confirmation'}
                        action={'delete'}
                        content={'Are you sure you want to delete the transfer request?'}
                        deleteData={() => this.deleteTransferRequest()}
                        onDismiss={() => this.setState({ showConfirm: false })}
                    />
                }
                {showDetail &&
                    <NotificationDetailsPopup
                        data={this.state.selectedData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
                {doActive &&
                    <NotificationConfirmPopup
                        title={'Push Notification'}
                        action={'ApprovePushNotification'}
                        content={'Are you sure to send notification ?'}
                        onDismiss={() => this.setState({ doActive: false })}
                        approve={() => this.bulkUploadPushNotification("approved")}
                        loader={this.state.loading}
                    />
                }
                {doReject &&
                    <NotificationConfirmPopup
                        title={'Push Notification'}
                        action={'RejectPushNotification'}
                        loader={this.state.loading}
                        content={'Are you sure to reject this notification ?'}
                        onDismiss={() => this.setState({ doReject: false })}
                        reject={() => this.bulkUploadPushNotification("rejected")}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(PushNotificationDashboard));