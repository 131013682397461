import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import plus from "../../../../assets/images/cfm/plus.svg"
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg'
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg'
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import FileSaver from 'file-saver'
import { docType, imageType } from './constants'

export const AttachmentInput = (props) => {
    const { onChange, attachment, disabled, error, field, employeeId, isMandatory = true, length, maxSize, uploadNote, onDelete } = props

    let allowedFileTypes = ["image/png", "image/jpg", "image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "msg", "message/rfc822","application/msword","application/vnd.ms-excel","text/csv"]
    let StaticIdForImage = useRef(1)
    const hiddenFileInput = useRef(null);

    const [selectedUploadBill, setSelectedUploadBill] = useState({})
    const [uploadBillModal, setUploadBillModal] = useState(false)
    const [viewAttachment, setViewAttachment] = useState(true)
    const [loading, setLoading] = useState(false)

    const submitAttachment = (attachmentBase64, attachmentName, attachmentType) => {
        let typeAttachment = attachmentType
        if (attachmentType == "vnd.openxmlformats-officedocument.wordprocessingml.document") {
            typeAttachment = "docx"
        }
        else if (attachmentType == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            typeAttachment = "xlsx"
        }
        else if (attachmentType == 'rfc822') {
            typeAttachment = 'eml'
        }
        else if (attachmentType == 'msword'){
            typeAttachment = 'doc'
        }
        else if (attachmentType == 'vnd.ms-excel'){
            typeAttachment = 'xls'
        }
        onChange({
            id: StaticIdForImage.current,
            attachment: attachmentBase64,
            attachment_name: attachmentName,
            attachment_type: typeAttachment,
        })
    }

    const onImageChange = (event) => {
        let ImagePath = event?.target?.files[0] && event.target.files[0].name
        let typeOfImage = event?.target?.files[0].type ? event?.target?.files[0].type : 'msg'
        let ImageType = typeOfImage?.split('/')
        if (event.target?.files && event.target?.files[0]) {
            let maxAllowedSize = maxSize * 1024;           //MB size
            if (Math.round((event.target.files[0].size / 1024)) <= maxAllowedSize && allowedFileTypes.includes(typeOfImage)) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                    submitAttachment(e.target.result, ImagePath, ImageType[ImageType.length - 1])
                }
                StaticIdForImage.current = StaticIdForImage.current + 1
            }
            else {
                if (!allowedFileTypes.includes(typeOfImage)) {
                    toast.warn('Please upload PDF,excel,Image,Docx, .eml & .msg format only', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
                else {
                    toast.warn(`Please upload file with size less than ${maxSize} MB`, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }
        }
        hiddenFileInput.current.value = null;
    }
    const previewAttachment = (data) => {
        setUploadBillModal(true)
        setSelectedUploadBill(data)
    }
    const downloadFile = (data) => {
        FileSaver.saveAs(data.attachment, data.attachment_name)
    }
    const deleteAttachment = (data) => {
        onDelete(data)
    }
    return (
        <div className='p-10px mb-1 mt-1 readOnlyInputBlock'>
            <LoaderIndicator loading={loading} />
            <div className="d-flex justify-content-between align-item-center">
                <span className='pointer card-content-title purple-color mx-10px' onClick={e => { setViewAttachment(!viewAttachment) }}>
                    {viewAttachment == true ? 'Hide' : "Show"} Attachment
                </span>
            </div>
            <div className='p-placeholder-text'>
                <label className="form-control p-placeholder p-placeholder-admin px-2 align-item-center justify-content-between"
                    style={attachment?.length < length && !disabled ? {} : {
                        backgroundColor: '#E5E5E5',
                        color: '#1E293B'
                    }}
                    disabled={attachment?.length < length && !disabled ? false : true}
                    onChange={(event) => onImageChange(event)}
                >
                    <input
                        id="Attachment"
                        style={{ display: "none" }}
                        type="file"
                        ref={hiddenFileInput}
                        title="No Files Added"
                        disabled={attachment?.length < length && !disabled ? false : true}
                        accept='.msg,.docx,.xlsx,.png,.jpg,.jpeg,.eml,.csv,.doc,.xls'
                    />
                    {attachment.length < 1 ? "Add Attachments" : `${attachment.length} ${attachment.length == 1 ? "Attachment" : "Attachments"} added`}
                    {
                        <img alt="plus" src={plus} className="plus-img"></img>
                    }
                </label>
            </div>
            <div className='d-flex justify-content-between align-item-center mb-10px'>
                <label className='noteTextMsg ml-10px'>
                    <span className='noteTextBold'>{'Note:'}</span> {uploadNote}
                </label>
            </div>
            {viewAttachment == true && attachment && attachment.length > 0 ?
                <div className='scroll-y h-8rem w-100'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col"><div className='thead'>{'Name'}</div></th>
                                <th scope="col"><div className='thead'>{'Preview'}</div></th>
                                <th scope="col"><div className='thead'>{'Delete'}</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {attachment.map((listValue, index) => {
                                return (
                                    <tr key={index}>
                                        <td> <div className="td-choice-pay d-flex choice-pay-table-rows">{listValue.attachment_name}</div></td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={docType.includes(listValue.attachment_type) ? downloadLatestImg : view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { docType.includes(listValue.attachment_type) ? downloadFile(listValue) : previewAttachment(listValue) }} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="td-choice-pay" >
                                                <img alt="view" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' onClick={(e) => { deleteAttachment(listValue, index) }} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                : viewAttachment &&
                <span className='d-flex justify-content-center align-item-center'>
                    <label className='card-content-title grey-color' >No data found</label>
                </span>
            }

            {uploadBillModal === true &&
                <div className="modal popup-box" >
                    <div className={"popupCard-box p-0 w-50"}>
                        <div className="modal-header">
                            <h4 className="modal_title black">{selectedUploadBill.attachment_name ? selectedUploadBill.attachment_name : ''}</h4>
                            <button type="button" className="close" data-dismiss="modal"
                                onClick={e => { setUploadBillModal(false); setSelectedUploadBill({}) }}
                            >
                                &times;</button>
                        </div>
                        <div className='modal-body m-15px scroll-auto'>
                            <span className='flex-direction-column'>
                                <span className='d-flex flex-direction-column px-10px pb-10px'>
                                    <>
                                        {imageType.includes(selectedUploadBill.attachment_type) &&
                                            <img src={selectedUploadBill?.attachment ? selectedUploadBill.attachment : ""} alt="" className="upload_bills_modal_body" />
                                        }
                                        {(selectedUploadBill.attachment_type === 'pdf') &&
                                            <iframe className='upload_bills_modal_body' src={selectedUploadBill?.attachment && selectedUploadBill.attachment}></iframe>
                                        }
                                    </>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            }
            {/* {<span className='p-sub-heading ml-3 errorBlock'>{error}</span>} */}
        </div>
    )
}