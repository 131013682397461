import React, { Component } from 'react';
import { coi_type } from '../../../../utils/Utils';
import { configStore } from "../../../../redux/ConfigStore";
import * as COIAction from '../conflictOfInterest/COIAction';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { toast } from 'react-toastify';
import moment from 'moment';

const optionA = 'I have an actual or potential conflict of interest situation to declare.'
const optionB = 'I do not have any actual or potential conflict of interest situation to declare.'
const submitConfirm = 'I hereby certify that declaration made by me is accurate and trutful. In case it is identified as false, the Company may take suitable disciplinary action.'
const confirming_that = `I have read the "Conflict of interest" policy and the Tata Code of Conduct and I hereby declare that I shall always act in the interest of the Company, and ensure that any business or personal association which I may have does not involve a conflict of interest with the operations of the Company and my role therein.\n I shall promptly disclose to the Company any personal relationships or associations which involve or are likely to involve conflict of interest and co-operate with the management in complying with any mitigating steps / action suggested.\n`
class Stage1ConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            confirm: false,
            isAChecked: false,
            isBChecked: false,
            submit: false,
            isSubmitProcess: false,
            coiTypeData: coi_type()
        }
    }

    toggleConfirm = () => {
        this.setState({ confirm: !this.state.confirm })
        if (!this.state.confirm) {
            this.setState({ isAChecked: false, isBChecked: false })
        }
        configStore.dispatch(COIAction.clearCOITypeData()) //to cleare previous data of option types from redux store
    }

    backToConfirm = () => {
        this.setState({
            confirm: false,
            isAChecked: false,
            isBChecked: false,
        })
    }

    backFromProceed = () => {
        this.setState({
            isAChecked: false,
            isBChecked: false,
            submit: false,
            confirm: true
        })
    }

    toggleChangeOption = (type) => {
        if (type === 'A') { this.setState({ isAChecked: !this.state.isAChecked, isBChecked: false, isSubmitProcess: false }) }
        else { this.setState({ isBChecked: !this.state.isBChecked, isAChecked: false, isSubmitProcess: true }) }
    }

    submitBForm = () => {
        this.setState({ submit: true })
        configStore.dispatch(COIAction.clearCOITypeData())
    }

    submitCOIRequest = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            a1_type_data: [],
            a2_type_data: [],
            a3_type_data: [],
            emp_pa: this.props.loginData.userData.PersArea,
            emp_psa: this.props.loginData.userData.PSubarea,
            emp_region: this.props.loginData.userData.RegCod,
            company_code: this.props.loginData.userData.CompCode,
            organization_unit: this.props.loginData.userData.Orgtx,
            emp_esubgroup: this.props.loginData.userData.Esubgroup,
            emp_doj: this.props.loginData.userData.Doj,
            emp_name: this.props.loginData.userData.CompName,
            b_type_data: [{
                emp_id: this.props.loginData.userData.Perno,
                declaration_date: new Date()
            }]
        }
        fetchApiCall(Config.submitCOIDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    setTimeout(() => { window.location.reload() }, 1000)
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                // toast.success(err, { hideProgressBar: true })
                this.setState({ loading: false })
            })
    }

    render() {
        const { isAChecked, isBChecked, isSubmitProcess, submit } = this.state
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content bg-white">
                        <div className="modal-header mx-2">
                            <div className='row'>
                                {this.state.submit ?
                                    <h4 className="popup-title mt-2">Declaration Conflict Of Interest</h4> :
                                    <h4 className="popup-title mt-2">Confirmation with Conflict of Interest Policy</h4>
                                }
                            </div>
                            <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            <div className='solid-border'></div>
                        </div>
                        <div className="row">
                            {!this.state.confirm && !isAChecked ?
                                <div className=''>
                                    <div className='popup-text mx-4 mt-4 mb-4'>{confirming_that} </div>
                                    <div className="popup-button-background justify-content-center align-item-center px-0">
                                        <div
                                            type='button'
                                            className='popup-confirm-btn'
                                            onClick={this.toggleConfirm}
                                        >I Confirm</div>
                                    </div>
                                </div> :
                                this.state.confirm && !submit ?
                                    <div className=''>
                                        <div className='popup-text mx-4 mt-2 mb-4'>Please select the appropriate option for the purpose of disclosure. For any doubts, please refer to the Conflict of Interest policy. Or reach out to local HR or Ethics Counsellors</div>
                                        <div className='checkbox-text d-flex align-items-center'>
                                            <label class="checkBoxData ml-4">
                                                <input type="checkbox" className='hidecheckBox'
                                                    checked={isAChecked}
                                                    onChange={() => this.toggleChangeOption('A')}
                                                />
                                                <span class="markk"></span>
                                            </label>
                                            <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                <div className='checkbox-text'>Option A</div>
                                            </div>
                                        </div>
                                        <div className='checkbox-sub-text mt-1 ml-60px'>{optionA}</div>
                                        <div className='checkbox-text d-flex align-items-center mt-3'>
                                            <label class="checkBoxData ml-4">
                                                <input type="checkbox" className='hidecheckBox'
                                                    checked={isBChecked}
                                                    onChange={() => this.toggleChangeOption('B')}
                                                />
                                                <span class="markk"></span>
                                            </label>
                                            <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                <div className='checkbox-text'>Option B</div>
                                            </div>
                                        </div>
                                        <div className='checkbox-sub-text mt-1 ml-60px'>{optionB}</div>
                                        <div className="popup-button-background justify-content-end align-item-center px-0 mt-5">
                                            {!isSubmitProcess && isAChecked ?
                                                <div className='justify-content-between mr-4'>
                                                    <div
                                                        type='button'
                                                        className='popup-back-btn popup-back-text'
                                                        onClick={this.backToConfirm}
                                                    >Back</div>
                                                    <div
                                                        type='button'
                                                        className='popup-confirm-btn'
                                                        onClick={() => this.props.next()}
                                                    >I Confirm</div>
                                                </div>
                                                :
                                                isSubmitProcess && isBChecked ?
                                                    <div className='justify-content-between mr-4'>
                                                        <div
                                                            type='button'
                                                            className='popup-back-btn popup-back-text'
                                                            onClick={this.backToConfirm}
                                                        >Back</div>
                                                        <div
                                                            type='button'
                                                            className='popup-confirm-btn'
                                                            onClick={this.submitBForm}
                                                        >I Confirm</div>
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                    :
                                    this.state.submit && isBChecked ?
                                        <div className=''>
                                            <div className='ml-4'>
                                                <div className='popup-text mt-4 mb-4'>
                                                    {submitConfirm}</div>
                                                <div className="mb-1 mr-4" >
                                                    <div className="form-label-text ml-3">Name</div>
                                                    <input
                                                        className="input-background px-3"
                                                        defaultValue={this.props.loginData.userData.CompName}
                                                        type="text"
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="mb-1 mr-4" >
                                                    <div className="form-label-text ml-3">Personnel No.</div>
                                                    <input
                                                        className="input-background px-3"
                                                        defaultValue={this.props.loginData.userData.Perno}
                                                        type="text"
                                                        readOnly={true}
                                                    />
                                                </div>
                                                <div className="mb-1 mr-4" >
                                                    <div className="form-label-text ml-3">Date of Declaration</div>
                                                    <input
                                                        className="input-background px-3"
                                                        defaultValue={moment(new Date()).format("DD-MM-YYYY")}
                                                        type="text"
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="popup-button-background justify-content-end align-item-center px-0 mt-3">
                                                <div className='justify-content-between mr-4'>
                                                    <div
                                                        type='button'
                                                        className='popup-back-btn popup-back-text'
                                                        onClick={this.backFromProceed}
                                                    >Back</div>
                                                    <div
                                                        type='button'
                                                        className='popup-submit-btn popup-submit-text'
                                                        onClick={() => this.submitCOIRequest()}
                                                    >Submit Declaration</div>
                                                </div>
                                            </div>
                                        </div>
                                        : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Stage1ConfirmPopup;