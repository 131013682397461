import * as type from '../../../../redux/action/ActionType'

export const getLeaveRequestTotalDays = (res) => {
    return{
        type : type.GET_LEAVE_REQUEST_TOTAL_DAYS,
        payload: res
    }
}

export const clearData = () => {
    return{
        type : type.CLEAR_DATA
    }
}
