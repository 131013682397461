import * as type from './ActionType';

const serviceTokenState = {
    serviceToken: ''
}

export const serviceActionToken = (state = serviceTokenState, action) => {
    switch(action.type) {
        case type.GET_SERVICE_TOKEN:
            return {
                ...state,
                serviceToken: action.payload,
            };
        default:
            return state;
    }
}