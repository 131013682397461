import React, { useEffect, useState } from 'react';
import { tdStyle } from '../../../adminScreens/localConveyance/constants';
import AddIcon from '../../../../../assets/drishti_images/addTableBtn.svg';
import { DDMMYYYY } from '../../../../../utils/Utils';
import { Pagination } from 'react-bootstrap';
import '.././../../employeeScreens/suggestionScheme/suggester/suggestion.css';
import viewImg from '../../../../../assets/drishti_images/transfer/view_action.svg';
import { schemeCode, statusCodes } from '../../../employeeScreens/suggestionScheme/constants';

function Table(props) {
    const {
        columns,
        data,
        hasAction = false,
        multiSelect = true,
        currentPage,
        pageSize = 10,
        totalPages,
        setCurrentPage,
        primaryActionImg = AddIcon,
        viewIcon = viewImg,
        onSelectSuggestion,
        selectedSuggestion,
        onAction,
        selectedStatus = {},
        className = '',
        suggestionStatus = "",
        paginationCall,
        isSrNoShow,
        details,
        minHeight='',
    } = props
    const [selectedIndexes, setSelectedIndexes] = useState([])

    useEffect(() => {
        setSelectedIndexes([])
    }, [data])

    const handleSelectAll = (checked) => {
        if (!checked) { setSelectedIndexes([]); onSelectSuggestion([]) }
        else {
            let tempArr = Array.from(Array(data.length), (_, i) => i)
            setSelectedIndexes(tempArr)
            onSelectSuggestion(data)
        }
    }
    const handleCheckboxSelection = (index, checked, row) => {
        if (!checked) {
            let arrayIndex = selectedIndexes.findIndex((ind) => { return ind == index })
            let tempArr = [...selectedIndexes.slice(0, arrayIndex), ...selectedIndexes.slice(arrayIndex + 1)]
            setSelectedIndexes(tempArr)
            let arrayIndexOfSelectedSuggestion = selectedSuggestion.findIndex((item) => item == row)
            let tempArrofSelectedSuggestion = [...selectedSuggestion.slice(0, arrayIndexOfSelectedSuggestion), ...selectedSuggestion.slice(arrayIndexOfSelectedSuggestion + 1)]
            onSelectSuggestion(tempArrofSelectedSuggestion)
        }
        else {
            let tempArr = [...selectedIndexes]
            tempArr.push(index)
            setSelectedIndexes(tempArr)
            let tempArrofSelectedSuggestion = [...selectedSuggestion]
            tempArrofSelectedSuggestion.push(row)
            onSelectSuggestion(tempArrofSelectedSuggestion)
        }
    }
    const handlePageCount = (action) => {
        setSelectedIndexes([]); onSelectSuggestion([])
        if (action === "decrement" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
            paginationCall(currentPage - 1)
        } else if (action === "increment" && currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            paginationCall(currentPage + 1)
        }
    };
    const handleAction = (data) => { onAction(data) }
    const keys = ['present_status', 'earlier_status', 'proposed_changes', 'changes_implemented', 'benefits', , 'title', 'clarification_to_divc', 'comment', 'applicable_areas']
    
    
    const getRowData = (data, cols) => {
    const { type, key } = cols;

    switch(type) {
        case "date":
            return DDMMYYYY(data[key]) ?? "--";
        case "special":
            const accessor = cols?.specialRowFunction(data);
            return data[accessor]
        default: 
            return data[key] ?? "--"
        }
    
    }
    
    return (
        <div>
            <div className={`scroll-x max-height-50vh mt-5px ${className}`} style={{minHeight}}  >
                <table className={`table gemsAdmin scroll-y`}>
                    <thead className='mb-2'>
                        <tr className=''>
                            {multiSelect && selectedStatus.status_code === statusCodes['Pending at Evaluator'] && <th width={"5%"}><input className='thead' type='checkbox' onChange={(e) => { handleSelectAll(e.target.checked) }} checked={selectedIndexes.length == data.length} /></th>}
                            {isSrNoShow ?
                                <th width={"10%"}><div className='thead'>Sr.No</div></th>
                                :
                                <th width={"13%"}><div className='thead white-space-no-wrap'>Suggestion ID</div></th>
                            }
                            {hasAction && <th width={"10%"}><div className='thead'>Action</div></th>}
                            {columns.map((val) => <th width={val.width}><div className='thead white-space-no-wrap'>{val.title}</div></th>)}
                        </tr>
                    </thead>
                    <tbody className='mb-1'>
                        {data.map((row, index) => {
                            return (
                                <tr className={ (selectedStatus.status_code === statusCodes['Pending at Evaluator'] && row.is_send_back) ? 'sendBack' : ''} style={{lineHeight:'18px'}}>
                                    {multiSelect && selectedStatus.status_code ===  statusCodes['Pending at Evaluator'] && 

                                        <td width={"30"}><input className='thead' type='checkbox' onChange={(e) => { handleCheckboxSelection(index, e.target.checked, row) }} checked={selectedIndexes.includes(index)} />
                                        </td>
                                    }
                                    {isSrNoShow ?
                                        <td width='5%'><div className={tdStyle}>{index + 1}</div></td>
                                        :
                                        <td width='5%'><div className={tdStyle}>{row.id}</div></td>
                                    }
                                    {hasAction &&
                                        <th width={"50"}> <img alt="view" src={selectedStatus.status_code ===  statusCodes['Pending at Evaluator'] ? primaryActionImg : viewIcon} style={{ width: '25px', cursor: 'pointer ' }} onClick={() => handleAction(row)} />
                                        </th>
                                    }
                                    {columns.map((col) => {
                                        const { type, key, width } = col;
                                        const isSpecialScheme = (details?.suggestion_details?.scheme_type_code === schemeCode.Normal || details?.suggestion_details?.scheme_type_code === schemeCode.Special) ? true : false;
                                        return (
                                            <>
                                                {data.length && 
                                                    (row?.action?.toLowerCase() === 'submitted' || row?.action?.toLowerCase() === 'clarification_submitted') ? (
                                                    key === 'from_status_name'&& row?.action?.toLowerCase() === 'submitted'  ? (
                                                        <td>
                                                            <div className='unineue-font size_875rem '>{'Suggestion Submitted'}</div>
                                                        </td>
                                                    ) : (key === 'updated_emp_name' || key === 'created_by') && isSpecialScheme ? (
                                                        <td>
                                                            <div className='unineue-font size_875rem '>{'---'}</div>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            <pre className={keys.includes(key) ? "unineue-font size_875rem custom-pre" : 'unineue-font size_875rem'}>
                                                                {type === "date" ? DDMMYYYY(row[key]) ?? '--' : row[key] ?? '--'}
                                                            </pre>
                                                        </td>
                                                    )
                                                ) : (
                                                    <td>
                                                        <pre className={keys.includes(key) ? "unineue-font size_875rem custom-pre" : 'unineue-font size_875rem'}>
                                                            {/* {type === "date" ? DDMMYYYY(row[key]) ?? '--' : row[key] ?? '--'} */}
                                                            { getRowData(row, col) }
                                                        </pre>
                                                    </td>
                                                )}
                                            </>
                                        );
                                    })}


                                </tr>)
                        })}
                    </tbody>
                </table >
            </div>
        </div>
    )
}

export default Table