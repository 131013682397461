import React from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import toggleon from '../../../../assets/drishti_images/profile/toggle-on.svg';
import toggleoff from '../../../../assets/drishti_images/profile/toggle-off.svg';

function AgencyMaster(props) {
    const {
        setAgencyName,
        agencyName,
        editMode,
        setAgencyDesc,
        agencyDesc,
        AgencyTypeList,
        selectedAgencyType,
        handleAgencyTypeFilter,
        setReleaseOrder,
        releaseOrder,
        documentApplicable,
        handleDocumentToggle,
        setIsConfirmPopupOpen
    } = props
    return (
        <div>
            <div className='column mt-3 scroll-y h-42vh'>
                <div className='toggle-card-heading ml-10 mb-10'>{`Basic Details`}</div>
                <div className="row">
                    <div className="col-12 ">
                        <div className='p-sub-heading ml-3 required'>{`Agency Name`}</div>
                        <div className='p-placeholder-text '>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setAgencyName(e.target.value) }}
                                value={agencyName}
                                placeholder={"Enter the agency name"}
                                maxLength={50}
                                disabled={editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3'>{`Description`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setAgencyDesc(e.target.value) }}
                                value={agencyDesc}
                                placeholder={"Enter the agency description"}
                                maxLength={300}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Select Agency Type`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <DynamicDropdown
                                data={AgencyTypeList}
                                render="title"
                                value={selectedAgencyType.title}
                                selectedValue={(data) => handleAgencyTypeFilter(data)}
                                arrow={'true'}
                                height={'NotificationHeight'}
                                placeholder={"Select Agency Type"}
                                disabled={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Release order`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <input
                                className="form-control p-placeholder p-placeholder-admin px-2"
                                type="text"
                                onChange={(e) => { setReleaseOrder(e.target.value) }}
                                value={releaseOrder}
                                placeholder={"Enter the clearance release order"}
                                inputmode="numeric"
                                pattern="\d*"
                                min={1}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className='p-sub-heading ml-3 required'>{`Is Document Applicable`}</div>
                        <div className='p-placeholder-text mb-2'>
                            <img
                                src={documentApplicable ? toggleon : toggleoff}
                                onClick={handleDocumentToggle}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-footer btnBox justify-content-end'>
                <div className='justify-content-start'>
                    <input type='button'
                        value='SUBMIT'
                        className='ml-2 validateBtn'
                        onClick={() => { setIsConfirmPopupOpen(true) }} />
                </div>
            </div>
        </div>
    )
}

export default AgencyMaster