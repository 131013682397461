import React, { useState, useEffect } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { fetchApiCallWithService, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { useSelector } from 'react-redux';
import AdminTable from '../../../shared/adimTable/AdminTable';
import { toast } from 'react-toastify';
import { toaster } from '../../../../utils/Utils';
import moment from 'moment';



function OTAdminDashboard() {

    const adminLoginState = useSelector(state => state.adminLoginState);
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState('OverTime');
    const [exportKey, setExportKey] = useState(false);
    const [apiBody, setApiBody] = useState(
        activeTab === 'OverTime' ? {
            status: '',
            from_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
            to_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
            ot_type: '',
            emp_id: null,
            email: adminLoginState.adminData.user_email,
            admin_emp_id: adminLoginState.adminData.employeeId,
            export: exportKey,
            page_number: 1
        } : {})
    const [apiAdminBody, setApiAdminBody] = useState(activeTab === 'ApproveOT' ?
        {
            manager_emp_id: '',
            from_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
            to_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0],
            ot_type: '',
            page_number: 1
        } : {});
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [activeStatus, setActiveStatus] = useState('');
    const [headers, setHeaders] = useState([
        {
            label: 'Checkbox',
            width: '1%',
        },
        {
            label: 'Sr.No',
            width: '0%',
            valueExtractor: (item, index) => index + 1,
        },
        {
            label: 'Employee Name',
            width: '10%',
            valueExtractor: (item) => item.emp_name || '-',
        },
        {
            label: 'Emp Id',
            width: '6%',
            valueExtractor: (item) => item.emp_id || '-',
        },
        {
            label: 'Date',
            width: '6% !important',
            valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
        },

        {
            label: 'Day Type',
            width: '5%',
            valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
        },
        {
            label: 'Shift Type',
            width: '5%',
            valueExtractor: (item) => item.shift_type || '-',
        },
        {
            label: 'Shift Time',
            width: '5%',
            valueExtractor: (item) => item.shift_time || '-',
        },
        {
            label: 'Shift In',
            width: '5%',
            valueExtractor: (item) => item.sw_in || '-',
        },
        {
            label: 'Shift Out',
            width: '5%',
            valueExtractor: (item) => item.sw_out || '-',
        },
        {
            label: 'OT In',
            width: '5%',
            valueExtractor: (item) => item.ot_in || '-',
        },
        {
            label: 'OT Out',
            width: '5%',
            valueExtractor: (item) => item.ot_out || '-',
        },
        {
            label: 'OT Hrs',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs || '-',
        },
        // {
        //     label: 'OT Min',
        //     width: '5%',
        //     valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
        // },
        {
            label: 'OT Max',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
        },

        {
            label: 'BC',
            width: '5%',
            valueExtractor: (item) => item.booked_cost_center || '-',
        },
        {
            label: 'Org Unit',
            width: '5%',
            valueExtractor: (item) => item.org_unit,
        },
        {
            label: 'Booked Hrs (HH:MM)',
            width: '2%',
            valueExtractor: (item) => item.supervisor_ot_hrs || '-',
        },
        {
            label: 'Approved OT HRS',
            width: '2%',
            valueExtractor: (item) => item.manager_ot_hrs || '-',
        },
        {
            label: 'Authorized HRS',
            width: '2%',
            valueExtractor: (item) => item.authorized_ot_hrs || '-',
        },
        // {
        //     label: 'Assigned BC',
        //     width: '8%',
        //     valueExtractor: (item) => item.cost_center || '-',
        // },

        {
            label: 'Status',
            width: '15%',
            valueExtractor: (item) => item.status || '-',
        },

    ]);
    const [approveHeaders, setApproveHeaders] = useState([
        {
            label: 'Checkbox',
            width: '1%',
        },
        {
            label: 'Sr.No',
            width: '0%',
            valueExtractor: (item, index) => index + 1,
        },
        {
            label: 'Employee Name',
            width: '10%',
            valueExtractor: (item) => item.emp_name || '-',
        },
        {
            label: 'Emp Id',
            width: '6%',
            valueExtractor: (item) => item.emp_id || '-',
        },
        {
            label: 'Date',
            width: '6% !important',
            valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
        },

        {
            label: 'Day Type',
            width: '5%',
            valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
        },
        {
            label: 'Shift Type',
            width: '5%',
            valueExtractor: (item) => item.shift_type || '-',
        },
        {
            label: 'Shift Time',
            width: '5%',
            valueExtractor: (item) => item.shift_time || '-',
        },
        {
            label: 'Shift In',
            width: '5%',
            valueExtractor: (item) => item.sw_in || '-',
        },
        {
            label: 'Shift Out',
            width: '5%',
            valueExtractor: (item) => item.sw_out || '-',
        },
        {
            label: 'OT In',
            width: '5%',
            valueExtractor: (item) => item.ot_in || '-',
        },
        {
            label: 'OT Out',
            width: '5%',
            valueExtractor: (item) => item.ot_out || '-',
        },
        {
            label: 'OT Hrs',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs || '-',
        },
        // {
        //     label: 'OT Min',
        //     width: '5%',
        //     valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
        // },
        {
            label: 'OT Max',
            width: '5%',
            valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
        },

        {
            label: 'BC',
            width: '5%',
            valueExtractor: (item) => item.booked_cost_center || '-',
        },
        {
            label: 'Org Unit',
            width: '5%',
            valueExtractor: (item) => item.org_unit,
        },
        {
            label: 'Booked Hrs (HH:MM)',
            width: '2%',
            valueExtractor: (item) => item.supervisor_ot_hrs || '-',
        },
        {
            label: 'Approved OT Hrs',
            width: '2%',
            valueExtractor: (item) => item.manager_ot_hrs,
        },
        // {
        //     label: 'Assigned BC',
        //     width: '8%',
        //     valueExtractor: (item) => item.cost_center || '-',
        // },
        {
            label: 'Status',
            width: '15%',
            valueExtractor: (item) => item.status || '-',
        },
        {
            label: 'Action',
            width: '8%',
            valueExtractor: () => 'Action',
        },
    ]);



    const props = {
        adminLoginState: adminLoginState
    }


    useEffect(() => {
        if (Object.keys(apiBody).length > 0) {
            getOTDetails();
        }
    }, [apiBody]);

    useEffect(() => {
        if (Object.keys(apiAdminBody).length > 0) {
            getOTRequest();
        }
    }, [apiAdminBody]);

    useEffect(() => {
        const showArray = ['Approved & Pending for Update', 'Approved & Updated', 'Error in Posting', 'Approved & Paid']
        setHeaders([
            {
                label: 'Checkbox',
                width: '1%',
            },
            {
                label: 'Sr.No',
                width: '0%',
                valueExtractor: (item, index) => index + 1,
            },
            {
                label: 'Employee Name',
                width: '10%',
                valueExtractor: (item) => item.emp_name || '-',
            },
            {
                label: 'Emp Id',
                width: '6%',
                valueExtractor: (item) => item.emp_id || '-',
            },

            ...(activeStatus === 'Booked & Pending for Approval' || showArray.includes(activeStatus) ? [{
                label: 'Booked By',
                width: '2%',
                valueExtractor: (item) => item.booked_by || '-'
            }] : []),
            {
                label: 'Date',
                width: '6% !important',
                valueExtractor: (item) => moment(item.ot_date).format('DD-MM-YYYY') || '-',
            },
            {
                label: 'Day Type',
                width: '5%',
                valueExtractor: (item) => item.day_type == 'O' ? "Weekly off" : item.day_type == "H" ? "Holiday" : item.day_type == 'W' ? "Working Day" : "-",
            },
            {
                label: 'Shift Type',
                width: '5%',
                valueExtractor: (item) => item.shift_type || '-',
            },
            {
                label: 'Shift Time',
                width: '5%',
                valueExtractor: (item) => item.shift_time || '-',
            },
            {
                label: 'Shift In',
                width: '5%',
                valueExtractor: (item) => item.sw_in || '-',
            },
            {
                label: 'Shift Out',
                width: '5%',
                valueExtractor: (item) => item.sw_out || '-',
            },
            {
                label: 'OT In',
                width: '5%',
                valueExtractor: (item) => item.ot_in || '-',
            },
            {
                label: 'OT Out',
                width: '5%',
                valueExtractor: (item) => item.ot_out || '-',
            },
            {
                label: 'OT Hrs',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs || '-',
            },
            // {
            //     label: 'OT Min',
            //     width: '5%',
            //     valueExtractor: (item) => item.proposed_ot_hrs_min || '-',
            // },
            {
                label: 'OT Max',
                width: '5%',
                valueExtractor: (item) => item.proposed_ot_hrs_max || '-',
            },

            {
                label: 'BC',
                width: '5%',
                valueExtractor: (item) => item.cost_center,
            },
            {
                label: 'Org Unit',
                width: '5%',
                valueExtractor: (item) => item.org_unit,
            }, {
                label: 'Booked Hrs (HH:MM)',
                width: '2%',
                valueExtractor: (item) => item.supervisor_ot_hrs || '-',
            },
            ...(activeTab === 'OverTime' ? [{
                label: 'Approved OT HRS',
                width: '2%',
                valueExtractor: (item) => item.manager_ot_hrs || '-',
            }] : [{
                label: 'Approved OT HRS',
                width: '2%',
                valueExtractor: (item) => item.manager_ot_hrs,
            }]),
            ...(activeStatus === 'Approved & Paid' ? [{
                label: 'Authorized HRS',
                width: '2%',
                valueExtractor: (item) => item.authorized_ot_hrs,
            }] : []),
            // {
            //     label: 'Assigned BC',
            //     width: '8%',
            //     valueExtractor: (item) => item.booked_cost_center,
            // },
            {
                label: 'Status',
                width: '15%',
                valueExtractor: (item) => item.status || '-',
            },
            {
                label: 'Action',
                width: '8%',
                valueExtractor: () => 'Action',
            },
        ])


    }, [activeStatus]);

    const getOTDetails = () => {
        setLoading(true)

        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCallWithService(Config.getOTDetailsAdmin, apiBody, headers, false, "admin", "POST", "admin", "")
            .then((res) => {
                setLoading(false)
                sessionOut(props, res);
                setTotalPages(res?.data?.data?.total_pages)

                if (apiBody.export == true && res.status == "200") {
                    toaster("success", res.data.message)
                    setTableData(res.data.data.ot_data ? res.data.data.ot_data : [])
                    setCurrentPage(res?.data?.data?.page_number || 1)



                }

                if (apiBody.export == false) {
                    setTableData(res.data.data.ot_data ? res.data.data.ot_data : [])

                }
                if (apiBody.export && res.status != "200") {
                    toaster("warning", res.data.message)

                }


            })
            .catch((err) => {
                setLoading(false)
            });

    }

    const getOTRequest = () => {
        setLoading(true)
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCallWithService(Config.getOTRequestAdmin, apiAdminBody, headers, false, "admin", "POST", "admin", "")
            .then((res) => {
                setLoading(false)
                sessionOut(props, res);
                if (res.data.data.ot_data) {
                    setTableData(res.data.data.ot_data)
                    setCurrentPage(res?.data?.data?.page_number || 1)
                    setTotalPages(res?.data?.data?.total_pages)
                }
                else {
                    setTableData([])
                    setCurrentPage(1)
                    setTotalPages(1)
                }
            })
            .catch((err) => {
                setLoading(false)
            });

    }

    const handleUpdateData = (newHour, newMinute, rowIndex) => {

        const newValue = `${newHour}:${newMinute}`;
        const updateRowManager = (data, index) => {
            return data.map((o, i) => {
                if (i === index) {
                    return {
                        ...o,
                        manager_ot_hrs: newValue
                    };
                }
                return o;
            });
        };
        // if (!isNaN(numericValue)) {
        if (activeTab === "ApproveOT") {
            if (Array.isArray(rowIndex)) {
                const updatedData = tableData.map((row, index) => {
                    if (rowIndex.includes(index)) {
                        return {
                            ...row,
                            manager_ot_hrs: newValue
                        };
                    }
                    return row;
                });
                setTableData(updatedData);
            } else {
                if (rowIndex || rowIndex === 0) {
                    // if (!isNaN(numericValue)) {
                    setTableData(s => updateRowManager(s, rowIndex));
                    // } else {
                    //     toast.error("Manager OT Hours must be less than or equal to the  OT hours.", {
                    //         position: "bottom-center",
                    //         hideProgressBar: true,
                    //         className: 'toastError'
                    //     })
                    // }
                }
            }
        }
        // } else {
        //     toast.error("Invalid input. Please enter a numeric value.", {
        //         position: "bottom-center",
        //         hideProgressBar: true,
        //         className: 'toastError'
        //     });
        // }
    };


    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
    return (
        <div className="row px-0 px-12px">
            <LoaderIndicator loading={loading} />
            <div className="col-md-12">
                <div className='row pt-12px'>
                    <div className='card-new col-md-12'>
                        <div className='d-flex flex-direction-row  mt-10px mb-0px row'>
                            {/* Render tabs here */}
                            <div className={activeTab === 'OverTime' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('OverTime')}>{'Over Time'}</div>
                            {/* <div className={activeTab === 'QuarterMaster' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('QuarterMaster')}>{'QuarterMaster'}</div> */}
                            <div className={activeTab === 'ApproveOT' ? "tab-active" : "tab-inactive"} onClick={() => handleTabClick('ApproveOT')}>{'Approve OT'}</div>


                        </div>
                        <div className='hz-lines d-flex mx-10px mt-0px mb-0px'></div>
                        <div>
                            {activeTab === 'OverTime' &&
                                <div className='mt-10px'>
                                    <AdminTable headers={headers} data={tableData} setApiBody={setApiBody} activeTab={activeTab} onUpdateData={handleUpdateData} getOTDetails={getOTDetails} exportKey={exportKey} setActiveStatus={setActiveStatus} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} />
                                </div>
                            }
                            {/* {activeTab === 'QuarterMaster' &&
                                <div className='mt-10px'>
                                      <AdminTable/>
                                </div>
                            } */}
                            {activeTab === 'ApproveOT' &&
                                <div >
                                    <AdminTable headers={approveHeaders} data={tableData} setApiAdminBody={setApiAdminBody} activeTab={activeTab} onUpdateData={handleUpdateData} getOTRequest={getOTRequest} exportKey={exportKey} setActiveStatus={setActiveStatus} setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OTAdminDashboard