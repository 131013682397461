import React, { useState } from 'react'
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import LargeDropdown from '../../../shared/components/LargeDropdown'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import ClearanceTable from './ClearanceTable'
import { toaster } from '../../../../utils/Utils'
import { agencyConfigCols } from './constants'
import { checkAgencyMapping } from '../../../../constants/api.service'

function AgencyConfig(props) {
    const {
        agencyList,
        selectedAgency,
        handleAgencyFilter,
        editMode,
        compCodeList,
        selectedCompany,
        handleCompanyFilter,
        paList,
        selectedPA,
        handlePAFilter,
        psaList,
        selectedPSA,
        handlePSAFilter,
        setIsConfirmPopupOpen,
        selectedGrade,
        gradeList,
        handleGradeFilter
    } = props
    const [page, setPage] = useState(1)
    const [message, setMessage] = useState('')
    const [Loading, setLoading] = useState(false)
    const [configList, setConfigList] = useState([])
    const back = () => setPage(1)
    const validateAgencyConfig = () => {
        if (!selectedAgency.id || !selectedCompany.comp_code || !selectedPSA.value || !selectedPA.pa || !selectedGrade.value) {
            toaster("warning", "Invalid input fields")
            setIsConfirmPopupOpen(false);
            return false
        }
        else return true
    }
    const handleNext = () => {
        if (page == 2) { setIsConfirmPopupOpen(true) }
        else if (validateAgencyConfig()) {
            setPage(2)
            getAgencyConfigDetails()
        }
    }
    const getAgencyConfigDetails = () => {
        let params = {
            "comp_code": selectedCompany.comp_code,
            "pers_area": selectedPA.pa,
            "pers_sub_area": selectedPSA.value,
            "grade_grp_name": selectedGrade.value,
            "agency_id": selectedAgency.id
        }
        checkAgencyMapping(params, props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        setMessage(res.data.message)
                        setConfigList(res.data.data)
                    }
                    else {
                        setMessage([])
                        setConfigList('')
                    }
                }
            ).catch((err) => {
                setMessage([])
                setConfigList('')
                console.log("check_agent_agency_config error", err)
            }).finally(() => setLoading(false))
    }
    return (
        <div>
            <LoaderIndicator loading={Loading} />
            <div className='column mt-3 scroll-y h-42vh'>
                {
                    page == 1 ?
                        <>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className='p-sub-heading ml-3 required'>{`Select Agency`}</div>
                                    <div className='p-placeholder-text'>
                                        <DynamicDropdown
                                            data={agencyList}
                                            render="name"
                                            value={selectedAgency.name}
                                            selectedValue={(data) => handleAgencyFilter(data)}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                            placeholder={"Select Agency"}
                                            disabled={editMode}
                                        />
                                    </div>
                                    {
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Enter valid domain id to fetch agency list'}</span>
                                        </label>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className='p-sub-heading ml-3 required'>{`Select Company`}</div>
                                    <div className='p-placeholder-text'>
                                        <DynamicDropdown
                                            data={compCodeList}
                                            render={"company_name"}
                                            value={selectedCompany.company_name}
                                            selectedValue={(data) => handleCompanyFilter(data)}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                            placeholder={"Select Company"}
                                            disabled={editMode}
                                        />
                                    </div>
                                    {
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select agency to fetch company list'}</span>
                                        </label>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className='p-sub-heading ml-3 required'>{`Select PA`}</div>
                                    <div className='p-placeholder-text'>
                                        <LargeDropdown
                                            data={paList}
                                            value={selectedPA.pa ?? "Select PA"}
                                            selectedValue={(data) => handlePAFilter(data)}
                                            disabled={editMode}
                                            noOptionFound={"No Data Found"}
                                        />
                                    </div>
                                    {
                                        <label className='noteTextMsg ml-10px d-flex' >
                                            <span className='noteTextBold'>{'Note:'}</span>
                                            <span className='noteTextMsg ml-5px'>{'Select Company to fetch Pers.Area list'}</span>
                                        </label>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className='p-sub-heading ml-3 required'>{`Select PSA`}</div>
                                    <div className='p-placeholder-text'>
                                        <LargeDropdown
                                            data={psaList}
                                            value={selectedPSA.value ?? "Select PSA"}
                                            selectedValue={(data) => handlePSAFilter(data)}
                                            disabled={editMode}
                                            noOptionFound={"No Data Found"}
                                        />
                                    </div>
                                    <label className='noteTextMsg ml-10px d-flex' >
                                        <span className='noteTextBold'>{'Note:'}</span>
                                        <span className='noteTextMsg ml-5px'>{'Select Pers.Area to fetch Pers.SubArea list'}</span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className='p-sub-heading ml-3 required'>{`Select Grade Group`}</div>
                                    <div className='p-placeholder-text'>
                                        <LargeDropdown
                                            data={gradeList}
                                            value={selectedGrade.value ?? "Select Grade Group"}
                                            selectedValue={(data) => handleGradeFilter(data)}
                                            disabled={editMode}
                                            noOptionFound={"No Data Found"}
                                        />
                                    </div>
                                    <label className='noteTextMsg ml-10px d-flex' >
                                        <span className='noteTextBold'>{'Note:'}</span>
                                        <span className='noteTextMsg ml-5px'>{'Select Pers.SubArea to fetch Grade-group list'}</span>
                                    </label>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="row gap-10 mt-10">
                                <div className="col-12">
                                    <span className='p-sub-heading errorBlock'>{message ?? ""}</span>
                                </div>
                                {
                                    configList.length > 0 &&
                                    <div className='column'>
                                        <ClearanceTable
                                            data={configList}
                                            columns={agencyConfigCols}
                                            handleAction={() => { }}
                                            hasPrimaryAction={false}
                                            hasSecondaryAction={false}
                                            hasAction={false}
                                            handleSecondaryAction={() => { }}
                                            scrollEnabled={false}
                                        />
                                    </div>
                                }
                            </div>
                        </>
                }
            </div>
            <div className='modal-footer btnBox justify-content-between'>
                <div className='justify-content-start'>
                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>{page}</span>/2</div>
                </div>
                <div className='justify-content-around'>
                    {
                        page == 2 &&
                        <div className='justify-content-start'>
                            <input type='button'
                                value='BACK'
                                className='popup-back-btn popup-back-text'
                                onClick={back} />
                        </div>
                    }
                    <div className='justify-content-start'>
                        <input type='button'
                            value={page == 1 ? "NEXT" : "SUBMIT"}
                            className='ml-2 validateBtn'
                            onClick={handleNext} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AgencyConfig