import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Resizer from 'react-image-file-resizer';
import { addAppCenterDetails } from '../../employeeScreens/gems/Gems.api.service';

class AppCenterConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: '',
            attachment: '',
            link: '',
            imageName: ''
        }
    }

    validate = () => {
        if (this.state.title === '') {
            toast.warning('Please enter title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.title.length >= 50) {
            toast.warning('Please enter title less than 50 characters', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.attachment === '') {
            toast.warning('Please upload attachment', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.link === '') {
            toast.warning('Please enter link', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.AddAppCenterData()
        }
    }

    AddAppCenterData() {
        let body = {
            title: this.state.title ? this.state.title : '',
            image_path: this.state.imageName ? this.state.imageName : '',
            attachment: this.state.attachment ? this.state.attachment : '',
            link: this.state.link ? this.state.link : ''
        }
        this.setState({ loading: true })
            .then((res) => {
                if (res.status == '200' || res.status == '201') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    }, this.props.allDismiss())
                    this.props.appCenterConfig()
                    this.setState({ loading: false })
                } else {
                    this.setState({ loading: false })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            })
            .catch((err) => {
                console.log("addAppCenterDetails catch error:", err);
            });
    }

    fileSelectHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({
                attachment: file,
                imageName: file.name
            });
            if (file.size <= 2097152) { // 2MB size
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;
                    img.onload = () => {
                        this.setState({
                            heightAndWidth: [img.width, img.height]
                        });
                        this.uploadImg(file, file);
                    }
                }
            } else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
                this.setState({
                    attachment: '',
                    imageName: ''
                });
            }
        } else {
            this.setState({
                attachment: '',
                imageName: ''
            });
        }
    }


    uploadImg = (event, data) => {
        this.setState({
            attachment: event ? event.name : '',
        })
        if (event) {
            let maxAllowedSize = 2097152;  // 2MB size
            if (event.size <= maxAllowedSize) {
                try {
                    Resizer.imageFileResizer(
                        data,
                        1280,
                        720,
                        'PNG',
                        100,
                        0,
                        (uri) => {
                            this.setState({ attachment: uri })
                        },
                        'uri',
                        1280,
                        720
                    );
                } catch (err) {
                    console.log("Resizer err:", err);
                }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ attachment: '' })
            }
        }
        else {
            this.setState({ attachment: '' })
        }
    }

    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div>
                    <form className='todoModalBody popup_scroller w-100'>
                        <div className='p-1 w-100'>
                            <div>
                                <div className='p-sub-heading ml-3'>Title</div>
                                <div className='p-placeholder-text mb-2'>
                                    <input
                                        className="form-control p-placeholder p-placeholder-admin px-2"
                                        type="text"
                                        readOnly={this.state.show}
                                        onChange={(e) => this.setState({ title: e.target.value })}
                                        value={this.state.title}
                                        placeholder={"Enter the title"}
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='p-sub-heading ml-3 mb-1'>Image</div>
                                {!this.props.show &&
                                    <div className='p-placeholder-text mb-2'>
                                        <input
                                            className="form-control p-placeholder  p-placeholder-admin px-2"
                                            type="file"
                                            accept="image/*"
                                            onChange={this.fileSelectHandler}
                                        />
                                        <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                                    </div>
                                }
                            </div>
                            <div>
                                <div className='p-sub-heading ml-3 mb-1'>Hyperlink</div>
                                <div className='p-placeholder-text mb-2'>
                                    <input
                                        className="form-control p-placeholder p-placeholder-admin px-2"
                                        type="text"
                                        value={this.state.link}
                                        onChange={(e) => this.setState({ link: e.target.value })}
                                        placeholder={"Enter the hyperlink here"}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='modal-footer btnBox justify-content-between'>
                        <div className='justify-content-start'>
                            <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                        </div>
                        <div className='justify-content-around'>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='CANCEL'
                                    className='popup-back-btn popup-back-text'
                                    onClick={this.props.back}
                                />
                            </div>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='ADD'
                                    className='popup-confirm-btn'
                                    onClick={this.validate}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(AppCenterConfiguration);