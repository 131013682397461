import React, { Component } from 'react'
import CharacterCounter from '../../../shared/CharacterCounter'
import { mtrFrObjective } from '../../../ScreenType/employeeScreens/gems/Gems.api.service'
import { toast } from 'react-toastify';
import { valueLimits } from '../../../ScreenType/employeeScreens/gems/Constant'
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';

class MtrFrObj extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectives: [],
            id: this.props.clickedId.id
        }
    }

    componentDidMount() {
        this.getObjective(this.state.id)
    }

    getObjective(id) {
        this.setState({ loading: true })
        mtrFrObjective(id, this.props)
            .then((response) => {
                this.setState({
                    loading: false,
                    objectives: response.data.data ? response.data.data : [],
                })
            }).catch((error) => {
                toast.error("Unable to contact server\n\tPlease retry after sometime", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false, objectives: [] })
                console.log("getObjective catch error:", error)
            });
    }

    render() {
        const maxLength = "50"
        const { objectives } = this.state
        return (
            <div>
                {/* <div className='flex-direction-row noProject-style p-10px mx-10px mt-1rem border_radius_12px' style={{ backgroundColor: '#E2E8F0' }}>
                    <span className='performance-timeline-text-bold p-10px w-10 '>Sr. No</span>
                    <span className='performance-timeline-text-bold p-10px w-40'>Deliverable</span>
                    <span className='performance-timeline-text-bold p-10px w-40'>Target</span>
                </div> */}
                {objectives.length != 0 ?
                    <div className='flex-direction-column p-10px mx-10px h-42vh scroll-y'>
                        {objectives.map((dataValue, idx) =>
                            <span key={idx} className='flex-direction-row w-100 align-item-center-noflex ml-4'>
                                <span className='w-7 m-10px performance-timeline-text-circle'>{idx + 1}</span>
                                <span className='w-40 m-10px'>
                                    <textarea
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        maxLength={valueLimits.textAreaMaxLength}
                                        disabled={true}
                                        value={dataValue.deliverable ? dataValue.deliverable : ''}
                                        placeholder={'Enter Project Deliverable'}
                                    />
                                    <CharacterCounter
                                        totalCount="150"
                                        currentCount={dataValue.deliverable.length}
                                    />
                                </span>
                                <span className='w-40 m-10px'>
                                    <textarea
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        maxLength={valueLimits.textAreaMaxLength}
                                        disabled={true}
                                        value={dataValue.target ? dataValue.target : ''}
                                        placeholder={'Enter Project Target'}
                                    />
                                    <CharacterCounter
                                        totalCount="150"
                                        currentCount={dataValue.target.length}
                                    />
                                </span>
                            </span>
                        )
                        }
                    </div>
                    :
                    <div className='row'>
                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                            <div>
                                <img src={noDataTransfer} alt="noDataTransfer" />
                            </div>
                            <div>
                                <label className='card-content-title grey-color'>No MTR/FR Objectives!</label>
                            </div>
                        </div>
                    </div>

                }
            </div>
        )
    }
}

export default MtrFrObj;