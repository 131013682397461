import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader'

export default function CircleSkeleton() {
    return (
        <div className="d-flex justify-content-center align-item-center mx-4 mt-2">
            <ContentLoader
                speed={1}
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
                viewBox="0 0 380 70"
            >
                <circle cx="30" cy="30" r="30" />
                <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
            </ContentLoader>
        </div>
    );
}

export function ApprovalSkeleton() {
    return (
        <div className="pb-5 mb-5">
            <ContentLoader
                speed={1}
                width={900}
                height={320}
                viewBox="0 0 1200 400"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >

                <rect x="26" y="19" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="20" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="21" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="20" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="21" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="19" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="18" rx="10" ry="10" width="85" height="19" />
                <rect x="978" y="18" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="79" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="80" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="81" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="80" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="81" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="79" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="78" rx="10" ry="10" width="85" height="19" />
                <rect x="978" y="78" rx="10" ry="10" width="169" height="19" />

                <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
                <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
                <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
                <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
                <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
                <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
                <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
                <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />


                <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
                <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
                <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />

                {/* <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
                <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />

                <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
                <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
                <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
                <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
                <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
                <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
                <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
                <rect x="977" y="378" rx="10" ry="10" width="169" height="19" /> */}
            </ContentLoader>
        </div>
    )
}


export function AnnouncementsSkeleton() {
    return (
        <div className="m-3">
            <ContentLoader
                height={80}
                speed={1}
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
                viewBox="0 0 380 70"
            >
                {/* Only SVG shapes */}
                <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
                <rect x="80" y="17" rx="4" ry="4" width="100%" height="13" />
                <rect x="80" y="40" rx="3" ry="3" width="70%" height="10" />
            </ContentLoader>
        </div>
    )
}


export function DocumentSkeleton() {
    return (
        <div className="col-md-12 col-sm-6 col-6 mt-2">
            <ContentLoader
                speed={1}
                width={159}
                height={64}
                viewBox="0 0 159 64"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
                <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
                <circle cx="20" cy="20" r="20" />
            </ContentLoader>
        </div>
    )
}

export function TODoListSkeleton() {

    return (
        <div className="m-2">
            <ContentLoader
                speed={1}
                width={300}
                height={70}
                viewBox="0 0 300 70"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <circle cx="20" cy="15" r="11" />
                <rect x="48" y="6" rx="5" ry="5" width="134" height="17" />
                <rect x="62" y="54" rx="5" ry="5" width="1" height="10" />
                <rect x="46" y="40" rx="5" ry="5" width="22" height="18" />
                <rect x="75" y="41" rx="5" ry="5" width="151" height="15" />
                <rect x="249" y="5" rx="5" ry="5" width="20" height="19" />
                <rect x="247" y="35" rx="5" ry="5" width="21" height="21" />
            </ContentLoader>
        </div>
    )
}

// export function LeaveHistoryCardSkeleton() {

//     const rows = 5
//     const rowHeight = 60
//     return (
//         <div className="mx-2 p-2">
//             <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`} >
//                 {new Array(rows).fill(' ').map((el, index) => {
//                     const contentVerticalPosition = contentHeight =>
//                         rows > 1 ? contentHeight + rowHeight * index : contentHeight
//                     return (
//                         <Fragment key={index}>
//                             <rect
//                                 x="20"
//                                 y={`${contentVerticalPosition(20)}`}
//                                 rx="4"
//                                 ry="4"
//                                 width="40"
//                                 height="20"
//                             />
//                             <rect
//                                 x="100"
//                                 y={`${contentVerticalPosition(20)}`}
//                                 rx="10"
//                                 ry="4"
//                                 width="600"
//                                 height="20"
//                             />
//                             <rect
//                                 x="750"
//                                 y={`${contentVerticalPosition(20)}`}
//                                 rx="10"
//                                 ry="4"
//                                 width="600"
//                                 height="20"
//                             />
//                             <rect
//                                 x="1450"
//                                 y={`${contentVerticalPosition(20)}`}
//                                 rx="4"
//                                 ry="4"
//                                 width="20"
//                                 height="20"
//                             />
//                             <rect
//                                 y={`${contentVerticalPosition(59)}`}
//                                 x="10"
//                                 ry="10"
//                                 width="1500"
//                                 height="1"
//                             />
//                         </Fragment>
//                     )
//                 })}
//             </ContentLoader>
//         </div>
//     )
// }

export function LeaveHistoryCardSkeleton_New() {

    const rows = 5
    const rowHeight = 60
    return (
        <div className="mx-2 p-2">
            <ContentLoader viewBox={`0 0 1500 ${rowHeight * rows}`} >
                {new Array(rows).fill(' ').map((el, index) => {
                    const contentVerticalPosition = contentHeight =>
                        rows > 1 ? contentHeight + rowHeight * index : contentHeight
                    return (
                        <Fragment key={index}>
                            <rect
                                x="20"
                                y={`${contentVerticalPosition(20)}`}
                                rx="4"
                                ry="4"
                                width="40"
                                height="20"
                            />
                            <rect
                                x="100"
                                y={`${contentVerticalPosition(20)}`}
                                rx="10"
                                ry="4"
                                width="600"
                                height="20"
                            />
                            <rect
                                x="750"
                                y={`${contentVerticalPosition(20)}`}
                                rx="10"
                                ry="4"
                                width="600"
                                height="20"
                            />
                            <rect
                                x="1450"
                                y={`${contentVerticalPosition(20)}`}
                                rx="4"
                                ry="4"
                                width="20"
                                height="20"
                            />
                            <rect
                                y={`${contentVerticalPosition(59)}`}
                                x="10"
                                ry="10"
                                width="1500"
                                height="1"
                            />
                        </Fragment>
                    )
                })}
            </ContentLoader>
        </div>
    )
}


// export function UpcomingHolidayCardSkeleton() {
//     return (
//         <div className='mx-2'>
//             <ContentLoader
//                 speed={1}
//                 width={590}
//                 height={120}
//                 viewBox="0 0 550 120"
//                 backgroundColor={'#f5f6f7'}
//                 foregroundColor={'#e0e0e0'}
//             >
//                 <rect x="5" y="18" rx="5" ry="5" width="418" height="18" />
//                 <rect x="5" y="58" rx="5" ry="5" width="421" height="18" />
//                 <rect x="3" y="99" rx="5" ry="5" width="422" height="17" />
//             </ContentLoader>
//         </div >
//     )
// }

export function UpcomingHolidayCardSkeleton_New() {
    return (
        <div className='mx-2'>
            <ContentLoader
                speed={1}
                width={590}
                height={120}
                viewBox="0 0 550 120"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <rect x="5" y="18" rx="5" ry="5" width="418" height="18" />
                <rect x="5" y="58" rx="5" ry="5" width="421" height="18" />
                <rect x="3" y="99" rx="5" ry="5" width="422" height="17" />
            </ContentLoader>
        </div >
    )
}

export function LeaveQuotaSkeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={160}
                height={124}
                viewBox="0 0 160 124"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <circle cx="85" cy="62" r="58" />
            </ContentLoader>
        </div >
    )
}

export function LeaveAvailedSkeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={160}
                height={160}
                viewBox="0 0 160 160"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <circle cx="67" cy="36" r="31" />
                <rect x="5" y="72" rx="5" ry="5" width="141" height="17" />
                <rect x="5" y="98" rx="5" ry="5" width="142" height="17" />
                <rect x="5" y="123" rx="5" ry="5" width="141" height="16" />
            </ContentLoader>
        </div >
    )
}

export function LeaveApprovalCardSkeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={380}
                height={40}
                viewBox="0 0 380 40"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <rect x="6" y="18" rx="5" ry="5" width="80" height="18" />
                <rect x="106" y="18" rx="5" ry="5" width="79" height="18" />
                <rect x="205" y="19" rx="5" ry="5" width="81" height="17" />
                <rect x="306" y="18" rx="5" ry="5" width="19" height="18" />
                <rect x="346" y="17" rx="5" ry="5" width="21" height="20" />
            </ContentLoader>
        </div >
    )
}

export function PerformanceTimelineSkeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={560}
                height={80}
                viewBox="0 0 560 80"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <circle cx="32" cy="38" r="27" />
                <rect x="73" y="9" rx="8" ry="8" width="488" height="57" />
            </ContentLoader>
        </div >
    )
}

export function GoalsAndLeadershipSkeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={460}
                height={120}
                viewBox="0 0 460 120"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <rect x="5" y="18" rx="8" ry="8" width="439" height="19" />
                <rect x="3" y="45" rx="8" ry="8" width="147" height="17" />
                <rect x="106" y="78" rx="8" ry="8" width="107" height="19" />
                <circle cx="19" cy="87" r="16" />
                <circle cx="45" cy="87" r="16" />
                <circle cx="67" cy="87" r="16" />
            </ContentLoader>
        </div >
    )
}

export function GoalsAndLeadership_Line_Skeleton() {
    return (
        <div className=''>
            <ContentLoader
                speed={1}
                width={460}
                height={70}
                viewBox="0 0 460 70"
                backgroundColor={'#f5f6f7'}
                foregroundColor={'#e0e0e0'}
            >
                <rect x="5" y="18" rx="8" ry="8" width="439" height="19" />
                <rect x="3" y="45" rx="8" ry="8" width="147" height="17" />
            </ContentLoader>
        </div >
    )
}