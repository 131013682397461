import React, { useEffect, useState } from 'react';
import Dropdown from '../../../shared/components/Dropdown';
import { gemsProjectForm, valueLimits, objvalueLimits, placeholder, projectFormConstant, buttonName } from './Constant';
import { notificationHeight } from '../../../../constants/Messages'
import delete_icon from '../../../../assets/drishti_images/transfer/delete_action.svg';
import CharacterCounter from '../../../shared/CharacterCounter';
import { getArea, getOrgUnit, getDuration, getSupervisors } from './Gems.api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import { configStore } from '../../../../redux/ConfigStore';

const categoryList = [
    {id: 0, value: "Stretch"},
    {id: 1, value: "Deputation"}
]
export function GEMSProjectForm({ onsetPrjTltChange, onPrjGuideNameChange,
    noOfPeopleChange, onPGLocationChange, onProjectAreaValueChange, onPrgDurationValueChange,
    onSelectedStartDateChange, onSelectedEndDateChange, onSelectedFRDateChange, onSelectedMTRDateChange, onOUValue,
    onProjectDescription, onCategory,  onBHRNameChange, onManagerNameChange, onPageNumber, onAddTargetDeliverable
}) {
    const [pgLocation, setPGLocation] = useState('');
    const [prjTlt, setPrjTlt] = useState('');
    const [prjGuideName, setPrjGuideName] = useState('');
    const [bhrName, setBHRName] = useState('');
    const [mgrName, setManagerName] = useState('');
    const [projectAreaValue, setProjectAreaValue] = useState('Select Project Area');
    const [categoryValue, setCategoryValue] = useState('Select Category');
    const [projectDurationValue, setPrgDurationValue] = useState('Select Project Duration');
    const [noOfPeople, setNoOfPeople] = useState('');

    const todayDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss ZZ");
    const futureDate = new Date(todayDate.clone().add(16, 'days'));
    const [selectedStartDate, setSelectedStartDate] = useState(futureDate);
    const [selectedEndDate, setSelectedEndDate] = useState("dd-mm-yyyy");
    const [OUValue, setOUValue] = useState('Select OU');
    const [projectDescription, setProjectDescription] = useState('');
    const [selectedMTRDate, setSelectedMTRdDate] = useState("dd-mm-yyyy");
    const [selectedFRDate, setSelectedFRDate] = useState("dd-mm-yyyy");
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([
        {
            deliverable: "",
            target: ""
        }
    ]);

    const [projectArea, setprojectArea] = useState([]);
    const [ou, setOU] = useState([]);
    const [duration, setDuration] = useState([]);

    useEffect(() => {
        setLoading(true)
        // setBHRName(configStore.getState().loginState?.userData?.BhrName);
        // setPrjGuideName(configStore.getState().loginState?.userData?.CompName);
        getArea()
            .then((res) => {
                setLoading(false)
                if (res) {
                    setprojectArea(res.data.data ? res.data.data : [])
                }
                else {
                    setprojectArea([])
                }
            })
            .catch((err) => {
                console.log("getArea catch error:", err);
            });

        setLoading(true)
        getOrgUnit()
            .then((res) => {
                setLoading(false)
                if (res) {
                    setOU(res.data.data ? res.data.data : [])
                }
                else {
                    setOU([])
                }
            })
            .catch((err) => {
                console.log("getOrgUnit catch error:", err)
            });
        getSupervisors()
            .then((res) => {
                setLoading(false)
                if (res) {
                    setPrjGuideName(res?.data?.data?.project_guide?.CompName || "")
                    setBHRName(res?.data?.data?.bhr?.CompName || "")
                    setManagerName(res?.data?.data?.manager?.CompName || "")
                }
                else {
                    setPrjGuideName("")
                    setBHRName("")
                    setManagerName("")
                }
            })
            .catch((err) => {
                console.log("getOrgUnit catch error:", err)
            });
    }, []);

    const getDurationCall = (cValue) => {
        console.log("getDurationCall Value",cValue.value)
        setCategoryValue(cValue.value);
        onCategory(cValue.value);
        const params = {
            project_category: cValue.value
        }
        getDuration(params)
            .then((res) => {
                setLoading(false)
                if (res) {
                    setDuration(res.data.data ? res.data.data : [])
                    console.log("CHECK getDuration res", res.data.data[0].project_duration)
                    updatePrgDurationChange(res.data.data ? res.data.data[0].project_duration : [])
                }
                else {
                    setDuration([])
                }
            })
            .catch((err) => {
                console.log("getOrgUnit catch error:", err)
            });
    }

    useEffect(() => {
        onAddTargetDeliverable(data);
    }, [data])

    const updatePGLocation = (location) => {
        setPGLocation(location);
        onPGLocationChange(location);
    };
    const updatePrjTlt = (objective) => {
        setPrjTlt(objective);
        onsetPrjTltChange(objective);
    };
    const updatePrjGuideName = (location) => {
        setPrjGuideName(location);
        onPrjGuideNameChange(location);
    };

    const updateBHRName = (bhrName) => {
        setBHRName(bhrName);
        onBHRNameChange(bhrName);
    }

    const updateManagerName = (mgrName) => {
        setManagerName(mgrName);
        onManagerNameChange(mgrName);
    }

    const updatePAChange = (paValue) => {
        setProjectAreaValue(paValue.area);
        onProjectAreaValueChange(paValue.id);
    };

    const updatePrgDurationChange = (durationValue) => {
        let finalProjectDate = new Date(new Date(selectedStartDate).getTime() + durationValue * 24 * 60 * 60 * 1000)
        const firstDate = new Date(selectedStartDate);
        const lastDate = finalProjectDate;
        const timeDiffInMillis = lastDate - firstDate;
        const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
        const mtrDate = new Date(midpointTime);
        onSelectedStartDateChange(selectedStartDate)
        setSelectedEndDate(finalProjectDate)
        setPrgDurationValue(durationValue);
        setSelectedMTRdDate(mtrDate)
        setSelectedFRDate(finalProjectDate)
        onPrgDurationValueChange(durationValue);
        onSelectedEndDateChange(finalProjectDate)
        onSelectedMTRDateChange(mtrDate)
        onSelectedFRDateChange(finalProjectDate)
    };

    const updateNoOfPeople = (enteredNoOfPeople) => {
        setNoOfPeople(enteredNoOfPeople);
        noOfPeopleChange(enteredNoOfPeople);
    };

    const updateStartCalender = (startDate) => {
        let getProjectDurationValue = projectDurationValue === "Select Project Duration" ? 0 : projectDurationValue
        let finalProjectDate = new Date(new Date(startDate).getTime() + getProjectDurationValue * 24 * 60 * 60 * 1000)
        const firstDate = new Date(startDate);
        const lastDate = finalProjectDate;
        const timeDiffInMillis = lastDate - firstDate;
        const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
        const mtrDate = new Date(midpointTime);
        setSelectedStartDate(startDate)
        setSelectedEndDate(finalProjectDate) // End date will be depends on duration
        setSelectedMTRdDate(mtrDate)
        setSelectedFRDate(finalProjectDate)
        onSelectedStartDateChange(startDate)
        onSelectedEndDateChange(finalProjectDate)
        onSelectedMTRDateChange(mtrDate)
        onSelectedFRDateChange(finalProjectDate)
    };

    const changeOU = (projectOU) => {
        setOUValue(projectOU.org_unit)
        onOUValue(projectOU.id)
    };

    const updateProjectDescription = (projectDescription) => {
        setProjectDescription(projectDescription);
        onProjectDescription(projectDescription);
    };

    const handleAddRow = () => {
        const newRow = {
            deliverable: "",
            target: ""
        };
        setData([...data, newRow]);
        onAddTargetDeliverable(data); // pass data to parent
    };

    const handleDeleteRow = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        onAddTargetDeliverable(data); // pass data to parent
    };

    return (
        <div className='scroll-auto pb-20px'>
            <LoaderIndicator loading={loading} />
            {loading === false ?
                <>
                    {
                        onPageNumber === 1 ?
                            <div className='grid-container-form px-20px h-42vh scroll-y' >
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.projectName}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        onChange={(prjTlt) => updatePrjTlt(prjTlt.target.value)}
                                        value={prjTlt}
                                        placeholder={'Enter Project Title'}
                                        maxLength="50"
                                    />
                                    <CharacterCounter
                                        totalCount="50"
                                        currentCount={prjTlt.length}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{gemsProjectForm.PGName}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        onChange={(prjGuideName) => updatePrjGuideName(prjGuideName.target.value)}
                                        value={prjGuideName}
                                        readOnly={true}
                                        placeholder={'-'}
                                        maxLength="50"
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{projectFormConstant.bhrName}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        onChange={(bhrName) => updateBHRName(bhrName.target.value)}
                                        readOnly={true}
                                        value={bhrName}
                                        placeholder={'-'}
                                        maxLength="50"
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{projectFormConstant.managerName}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        onChange={(mgrName) => updateManagerName(mgrName.target.value)}
                                        value={mgrName}
                                        readOnly={true}
                                        placeholder={'-'}
                                        maxLength="50"
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.location}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        onChange={(pgLocation) => updatePGLocation(pgLocation.target.value)}
                                        value={pgLocation}
                                        placeholder={'Enter Location'}
                                        maxLength="50"
                                    />
                                    <CharacterCounter
                                        totalCount="50"
                                        currentCount={pgLocation.length}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.projectArea}</span>
                                    <Dropdown
                                        data={projectArea}
                                        selectedValue={(paValue) => updatePAChange(paValue)}
                                        value={projectAreaValue}
                                        render={'area'}
                                        height={notificationHeight}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{"Category"}</span>
                                    <Dropdown
                                        data={categoryList}
                                        selectedValue={(cValue) => getDurationCall(cValue)}
                                        value={categoryValue}
                                        render={'value'}
                                        height={notificationHeight}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.durations}</span>
                                    <Dropdown
                                        data={duration}
                                        selectedValue={(duration) => updatePrgDurationChange(duration.project_duration)}
                                        value={projectDurationValue}
                                        render={'project_duration'}
                                        height={notificationHeight}
                                        disabled={duration.length !== 0 ? false : true}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.startDate}</span>
                                    <input
                                        className="date-input p-placeholder px-3"
                                        type="date"
                                        onKeyDown={(e) => e.preventDefault()}
                                        name='startDate'
                                        onChange={(startDate) => updateStartCalender(startDate.target.value)}
                                        value={moment(selectedStartDate).format('YYYY-MM-DD')}
                                        min={moment(futureDate).format('YYYY-MM-DD')}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{gemsProjectForm.endDate}</span>
                                    <input
                                        className="date-input p-placeholder px-3"
                                        type="text"
                                        name='endDate'
                                        readOnly={true}
                                        value={selectedEndDate !== "dd-mm-yyyy" ? moment(selectedEndDate).format('DD-MM-YYYY') : selectedEndDate}

                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.projectOU}</span>
                                    <Dropdown
                                        data={ou}
                                        selectedValue={(projectOU) => changeOU(projectOU)}
                                        value={OUValue}
                                        render={'org_unit'}
                                        height={notificationHeight}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.noOfPeople}</span>
                                    <input
                                        className="form-control popup-placeholder px-3"
                                        type="number"
                                        onChange={(people) => updateNoOfPeople(people.target.value)}
                                        value={noOfPeople}
                                        placeholder={'Enter Number of People'}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3 required'>{gemsProjectForm.projectDescription}</span>
                                    <textarea
                                        className="form-control popup-placeholder px-3"
                                        type="text"
                                        maxLength="300"
                                        onChange={(projectDescription) => updateProjectDescription(projectDescription.target.value)}
                                        value={projectDescription}
                                        placeholder={placeholder.projectDescription}
                                    />
                                    <CharacterCounter
                                        totalCount="300"
                                        currentCount={projectDescription.length}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{gemsProjectForm.mtrDate}</span>
                                    <input
                                        className="date-input p-placeholder px-3"
                                        type="text"
                                        name='endDate'
                                        readOnly={true}
                                        value={selectedMTRDate !== "dd-mm-yyyy" ? moment(selectedMTRDate).format('DD-MM-YYYY') : selectedMTRDate}
                                    />
                                </span>
                                <span className='mx-10px mt-10px'>
                                    <span className='p-sub-heading ml-3'>{gemsProjectForm.frDate}</span>
                                    <input
                                        className="date-input p-placeholder px-3"
                                        type="text"
                                        name='endDate'
                                        readOnly={true}
                                        value={selectedFRDate !== "dd-mm-yyyy" ? moment(selectedFRDate).format('DD-MM-YYYY') : selectedFRDate}
                                    />
                                </span>
                            </div>
                            :
                            onPageNumber === 2 &&
                            <div>
                                <div className='justify-content-end'>
                                    <button
                                        type="submit"
                                        className='btn btn-primary login-btn py-2 m-10px'
                                        disabled={data.length > 4 ? true : false}
                                        onClick={() => handleAddRow()}
                                    >
                                        <span className='px-20px'>+&nbsp;{buttonName.addNewOBJ}</span>
                                    </button>
                                </div>
                                <div className='flex-direction-row noProject-style p-10px mx-10px'>
                                    <span className='performance-timeline-text-bold p-10px w-10 '>Sr. No</span>
                                    <span className='performance-timeline-text-bold p-10px w-45-percent'>Deliverable</span>
                                    <span className='performance-timeline-text-bold p-10px w-45-percent'>Target</span>
                                    {
                                        data.length !== 1 &&
                                        <span className='performance-timeline-text-bold p-10px w-10'>Action</span>
                                    }
                                </div>
                                <div className='flex-direction-column p-10px mx-10px h-42vh scroll-y'>
                                    {
                                        data.map((dataValue, idx) =>
                                            <span key={idx} className='flex-direction-row w-100 align-item-center-noflex'>
                                                <span className='w-7 mx-10px mt-10px performance-timeline-text-circle'>{idx + 1}</span>
                                                <span className='w-45-percent mx-10px mt-10px'>
                                                    <textarea
                                                        className="form-control popup-placeholder px-3"
                                                        type="text"
                                                        maxLength={objvalueLimits.maxlenght}
                                                        onChange={(e) => {
                                                            const newData = [...data];
                                                            newData[idx].deliverable = e.target.value;
                                                            setData(newData);
                                                            onAddTargetDeliverable(data); // pass data to parent
                                                        }}
                                                        value={dataValue.deliverable}
                                                        placeholder={'Enter Project Deliverable'}
                                                    />
                                                    <CharacterCounter
                                                        totalCount="200"
                                                        currentCount={dataValue.deliverable.length}
                                                    />
                                                </span>
                                                <span className='w-45-percent mx-10px mt-10px'>
                                                    <textarea
                                                        className="form-control popup-placeholder px-3"
                                                        type="text"
                                                        maxLength={objvalueLimits.maxlenght}
                                                        onChange={(e) => {
                                                            const newData = [...data];
                                                            newData[idx].target = e.target.value;
                                                            setData(newData);
                                                            onAddTargetDeliverable(data); // pass data to parent
                                                        }}
                                                        value={dataValue.target}
                                                        placeholder={'Enter Project Target'}
                                                    />
                                                    <CharacterCounter
                                                        totalCount="200"
                                                        currentCount={dataValue.target.length}
                                                    />
                                                </span>
                                                {
                                                    data.length !== 1 &&
                                                    <span className='w-10 text-align-center-noflex'>
                                                        <img alt="delete"
                                                            src={delete_icon}
                                                            className='h-20px'
                                                            onClick={() => handleDeleteRow(idx)}
                                                        />
                                                    </span>
                                                }
                                            </span>
                                        )
                                    }
                                </div>
                            </div>
                    }
                </>
                :
                <span></span>
            }
        </div>
    )
}