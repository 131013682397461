import React, { Component } from 'react'
import NewNotdata from '../../../shared/notFound/NewNoData';
import { applicants, currentProject, formType, gemsApplicant, viewDetailsFromApplicant, isCurrentAlreadyAppliedNote, isOthersAlreadyAppliedNote } from './Constant';
import { configStore } from '../../../../redux/ConfigStore';
import moment from 'moment';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { applicantApplyProject } from './Gems.api.service';
import { toast } from 'react-toastify';
import FormPopup from '../../../shared/components/gemsFormPopUp/FormPopup';
class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedID: '',
            FileName: '',
            FileContent: '',
            isFormClosed: true,
            categoryType: ''
        }
    }

    sendingId(id) {
        this.setState({
            selectedID: id
        })
    }

    submitAddProject() {
        let body = {
            "filename": this.state.FileName,
            "attachment": this.state.FileContent,
            'emp_id': configStore.getState().loginState?.userData?.Perno
        }
        this.setState({ loading: true })
        applicantApplyProject(this.state.selectedID, body)
            .then((res) => {
                this.setState({ loading: false })
                this.props.getDiffProject()
                this.props.onChangedStatus()
                toast.success(res.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastSuccess'
                })
            })
            .catch((err) => {
                console.log("submitAddProject catch error:", err);
            });
    }

    upload = (event) => {
        if (event.target.files && event.target.files[0]) {
            const fileType = event.target.files[0].type;
            const fileName = event.target.files[0].name;
            const fileExtension = fileName.split('.').pop().toLowerCase();

            const allowedExtensions = ['pdf', 'doc', 'docx', 'docm', 'dot', 'dotx', 'dotm', 'ppt', 'pptm', 'pptx', 'pot', 'potx', 'potm'];
            const maxFileSize = 1048576 * 2; //2MB

            if (allowedExtensions.includes(fileExtension)) {
                if (event.target.files[0].size <= maxFileSize) {
                    let reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        this.setState({ FileName: fileName, FileContent: e.target.result }, () => {
                            // File is valid, do something with it
                        });
                    };
                } else {
                    toast.error('Please upload a file less than 2MB', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    });
                    this.setState({ FileContent: '', FileName: '' });
                }
            } else {
                toast.error('Please upload a PDF, PPT, or Word file only', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
                this.setState({ FileContent: '', FileName: '' });
            }
        } else {
            this.setState({ FileContent: '', FileName: '' });
        }
    }


    decided = (type) => {
        this.setState({
            finalUpload: type
        })
        this.submitAddProject(type)
    }

    render() {
        const { tempProjectDetails, type, isSelected, loading } = this.props;
        const dataValue = type === 'click' ? tempProjectDetails[isSelected] : tempProjectDetails[0]
        return (
            <div className='card-inner m-5px p-5px mt-3 mr_6px'>
                <LoaderIndicator loading={this.state.loading} />
                <>
                    {type === 'click' ?
                        <div className='w-100'>

                            <div className='flex justify-content-between status-background-styles'>
                                <div className='ml-2 mt-1'>
                                    <span><span className='text1_25rem-bold text-align-center'>{dataValue?.title ? dataValue?.title : '-'}</span></span>
                                </div>

                                {dataValue.is_active_apply_project ?
                                    <span onClick={() => this.setState({ isFormClosed: false, categoryType: dataValue.category },
                                        () => this.sendingId(dataValue.id))}
                                        className='btn btn-primary login-btn w-10 pointer'>
                                        {applicants.apply}
                                    </span>
                                    :
                                    dataValue.is_active_current_already_applied_note ?
                                        <div>
                                            <h1 className='paySpace-text green-tag mt-1'>{(isCurrentAlreadyAppliedNote) + (dataValue.date_of_application ? moment(dataValue.date_of_application).format("DD-MM-YYYY") : '-')}</h1>
                                        </div>
                                        :
                                        dataValue.is_active_others_already_applied_note &&
                                        <div>
                                            <h1 className='paySpace-text green-tag mt-1'>{(isOthersAlreadyAppliedNote)}</h1>
                                        </div>
                                }
                            </div>

                            <div className='scroll-y h-42vh mt-2 p-2'>
                                <span className='flex-direction-column mt-3'>
                                    <span className='container-project-details px-10px'>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.pgName}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.CompName ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.projectLocation}</span><span className='feedback-sm-text ml-5px'>{dataValue?.location ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.projectArea}</span><span className='feedback-sm-text ml-5px'>{dataValue?.area?.area ?? ''}</span>
                                        </span>
                                    </span>
                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                    <span className='container-project-details px-10px mt-1'>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.pgLevel}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.Esgtxt ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.ou}</span><span className='feedback-sm-text ml-5px'>{dataValue?.org_unit?.org_unit ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text ml-5px'>{dataValue?.no_of_openings ?? ''}</span>
                                        </span>
                                    </span>
                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                    <span className='container-project-details px-10px mt-1'>
                                        <span>
                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationsReceived}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_received ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistBySPOC}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_spoc ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistByPG}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_pg ?? ''}</span>
                                        </span>
                                    </span>
                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                    <span className='container-project-details px-10px mt-1'>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.startDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_start_date ? moment(dataValue.actual_start_date).format("DD-MM-YYYY") : dataValue.planned_start_date ? moment(dataValue.planned_start_date).format("DD-MM-YYYY") : '-'}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{gemsApplicant.endDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_end_date ? moment(dataValue.actual_end_date).format("DD-MM-YYYY") : dataValue.planned_end_date ? moment(dataValue.planned_end_date).format("DD-MM-YYYY") : '-'}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{currentProject.planned_mtr_date}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_mtr_date ? moment(dataValue.actual_mtr_date).format("DD-MM-YYYY") : dataValue.revised_mtr_date ? moment(dataValue.revised_mtr_date).format("DD-MM-YYYY") : dataValue.planned_mtr_date ? moment(dataValue.planned_mtr_date).format("DD-MM-YYYY") : '-'}</span>
                                        </span>
                                    </span>
                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                    <span className='container-project-details px-10px mt-1'>
                                        <span>
                                            <span className='appcenter-app-name'>{currentProject.planned_fr_date}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_fr_date ? moment(dataValue.actual_fr_date).format("DD-MM-YYYY") : dataValue.revised_fr_date ? moment(dataValue.revised_fr_date).format("DD-MM-YYYY") : dataValue.planned_fr_date ? moment(dataValue.planned_fr_date).format("DD-MM-YYYY") : '-'}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{"Category:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.category ?? ''}</span>
                                        </span>
                                        <span>
                                            <span className='appcenter-app-name'>{"Duration:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.duration ?? ''}</span>
                                        </span>
                                    </span>
                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                    <span className='p-10px flex-direction-row mt-1'>
                                        <span className='form-popup-value'>{gemsApplicant.description}</span>
                                        <span className='feedback-sm-text ml-5px'>{dataValue?.description ?? ''}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        :
                        <>
                            {!loading && dataValue &&
                                <div className='w-100'>

                                    <div className='flex justify-content-between status-background-styles'>
                                        <div className='ml-2 mt-1'>
                                            <span><span className='text1_25rem-bold text-align-center'>{dataValue?.title ? dataValue?.title : '-'}</span></span>
                                        </div>
                                        {dataValue.is_active_apply_project ?
                                            <span onClick={() => this.setState({ isFormClosed: false, categoryType: dataValue.category },
                                                () => this.sendingId(dataValue.id))}
                                                className='btn btn-primary login-btn w-10 pointer'>
                                                {applicants.apply}
                                            </span>
                                            :
                                            dataValue.is_active_current_already_applied_note ?
                                                <div>
                                                    <h1 className='paySpace-text green-tag mt-1'>{(isCurrentAlreadyAppliedNote) + (dataValue.date_of_application ? moment(dataValue.date_of_application).format("DD-MM-YYYY") : '-')}</h1>
                                                </div>
                                                :
                                                dataValue.is_active_others_already_applied_note &&
                                                <div>
                                                    <h1 className='paySpace-text green-tag mt-1'>{isOthersAlreadyAppliedNote}</h1>
                                                </div>
                                        }
                                    </div>

                                    <div className='scroll-y h-42vh mt-2 p-2'>
                                        <span className='flex-direction-column mt-3'>
                                            <span className='container-project-details px-10px'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.pgName}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.CompName ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.projectLocation}</span><span className='feedback-sm-text ml-5px'>{dataValue?.location ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.projectArea}</span><span className='feedback-sm-text ml-5px'>{dataValue?.area?.area ?? ''}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.pgLevel}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.Esgtxt ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.ou}</span><span className='feedback-sm-text ml-5px'>{dataValue?.org_unit?.org_unit ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text ml-5px'>{dataValue?.no_of_openings ?? ''}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationsReceived}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_received ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistBySPOC}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_spoc ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistByPG}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_pg ?? ''}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.startDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_start_date ? moment(dataValue.actual_start_date).format("DD-MM-YYYY") : dataValue.planned_start_date ? moment(dataValue.planned_start_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.endDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_end_date ? moment(dataValue.actual_end_date).format("DD-MM-YYYY") : dataValue.planned_end_date ? moment(dataValue.planned_end_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{currentProject.planned_mtr_date}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_mtr_date ? moment(dataValue.actual_mtr_date).format("DD-MM-YYYY") : dataValue.revised_mtr_date ? moment(dataValue.revised_mtr_date).format("DD-MM-YYYY") : dataValue.planned_mtr_date ? moment(dataValue.planned_mtr_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{currentProject.planned_fr_date}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_fr_date ? moment(dataValue.actual_fr_date).format("DD-MM-YYYY") : dataValue.revised_fr_date ? moment(dataValue.revised_fr_date).format("DD-MM-YYYY") : dataValue.planned_fr_date ? moment(dataValue.planned_fr_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Category:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.category ?? ''}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Duration:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.duration ?? ''}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='p-10px flex-direction-row mt-1'>
                                                <span className='form-popup-value'>{gemsApplicant.description}</span>
                                                <span className='feedback-sm-text ml-5px'>{dataValue?.description ?? ''}</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </>
                {!this.state.isFormClosed &&
                    <FormPopup
                        type={formType.gemsApplicant}
                        categoryType={this.state.categoryType}
                        onDismiss={() => this.setState({ isFormClosed: true })}
                        onFileChange={this.upload}
                        approval={this.decided}
                    />
                }
            </div>
        )
    }
}

export default ProjectDetails