import React, { Component } from 'react';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import nodata from '../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg';
class FeedbackRequestPopupNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            nextPopUpOpen: false,
            tempGoal: [],
            count: 1,
            ShowlastPop: false,
            firstnextbtn: false,
            data: [],
            leadershipData: [],
            goalIdCollector: [],
            leadersheepGoalsData: [],
            competancy_item_ids: [],
            goal_item_ids: [],
            note: '',
            leadershipApplicationMode: '',
            leadershipMessageStatus: '',
            countStatus: 1,
        }
    }

    componentDidMount() {
        const { goalApplicationMode, leadershipApplicationMode } = this.props
        if ((goalApplicationMode === 'close' || goalApplicationMode === 'view') && leadershipApplicationMode === 'write') {
            this.setState({
                nextPopUpOpen: true,
                firstnextbtn: false,
                data: this.props.goalsdata
            },
                this.getLeadersheepGoals())
        }
        else {
            this.setState({
                firstnextbtn: true,
                data: this.props.goalsdata
            },
                this.getLeadersheepGoals())
        }
    }

    getLeadersheepGoals = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            user_id: this.props.loginData.cfmUserDetails.length != 0 ? this.props.loginData.cfmUserDetails.user_details.user_id : '',
            is_employee: true
        }
        fetchApiCall(Config.getEmployeeCompetencies, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: res.data.sf_competency_details ? res.data.sf_competency_details : [],
                        leadershipApplicationMode: res.data.application_modes,
                        leadershipMessageStatus: res.data.message
                    },
                    );
                    const tempGoalData = this.state.leadersheepGoalsData.map(tempValue => ({ ...tempValue, commentDescription: '', isCommentShow: false, isSelected: false }))
                    this.setState({ leadersheepGoalsData: tempGoalData })
                } else {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    leadersheepGoalsData: []
                });
            })
    }

    success_factor_push_feedack = () => {
        this.setState({
            loading: true,
        });
        let body = {
            goal_item_ids: this.state.goal_item_ids,
            competancy_item_ids: this.state.competancy_item_ids,
            item_ids: this.state.goal_item_ids.concat(this.state.competancy_item_ids),
            emp_id: this.props.loginData.userData.Perno,
            emp_name: this.props.loginData.userData.CompName,
            reporting_manager: this.props.loginData.userData.Reporting,
            note: this.state.note
        };
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.nudgeForFeedbackRequest, body, headers, true, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: "toastSuccess",
                    });
                    setTimeout(() => { window.location.reload() }, 1000)
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                toast.error("error", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: "toastError",
                });
            });
    };

    next = (type) => {
        const { goalApplicationMode, leadershipApplicationMode } = this.props;
        this.setState({ countStatus: this.state.countStatus + 1 })
        if (type === "nextforLeadership") {
            if (goalApplicationMode === 'write' && (leadershipApplicationMode === 'close'|| leadershipApplicationMode === 'view')) {
                this.setState({
                    ShowlastPop: true,
                    count: 1,
                    firstnextbtn: false,
                    nextPopUpOpen: false,
                });
            }
            else {
                this.setState({
                    nextPopUpOpen: true,
                    count: 2,
                    firstnextbtn: false,
                });
            }
        } else if (type === 'submit') {
            this.success_factor_push_feedack();
        } else {
            this.setState({
                ShowlastPop: true,
                count: 3,
                firstnextbtn: false,
                nextPopUpOpen: false,
            });
        }
    }

    SelectCheck = (dataValue, id, type) => {
        const select = !dataValue.isSelected
        const { goal_item_ids, competancy_item_ids } = this.state
        if (type === 'goal') {
            this.setState(prevState => ({
                data: prevState.data.map(
                    date => date.sf_goal_details_id === dataValue.sf_goal_details_id ? { ...date, isSelected: select } : date
                )
            }))

            if (select) {
                goal_item_ids.push(dataValue.item_id)
            }
            else {
                const isLargeNumber = (element) => element === dataValue.item_id;
                goal_item_ids.splice(goal_item_ids.findIndex(isLargeNumber), 1);
            }
        }
        else {
            this.setState(prevState => ({
                leadersheepGoalsData: prevState.leadersheepGoalsData.map(
                    date => date.sf_competency_details_id === dataValue.sf_competency_details_id ? { ...date, isSelected: select } : date
                )
            }))

            if (select) {
                competancy_item_ids.push(dataValue.item_id)
            }
            else {
                const isLargeNumber = (element) => element === dataValue.item_id;
                competancy_item_ids.splice(competancy_item_ids.findIndex(isLargeNumber), 1);
            }
        }
    }

    back = () => {
        this.setState({
            nextPopUpOpen: false,
            count: 1,
            firstnextbtn: true,
        })
    }

    render() {
        const { leadershipApplicationMode, goalApplicationMode, type } = this.props
        const totalSteps = (goalApplicationMode == 'write' && leadershipApplicationMode == 'write') ? 3 : 2;
        if (this.state.ShowlastPop) {
            this.state.countStatus = (goalApplicationMode == 'write' && leadershipApplicationMode == 'write') ? 3 : 2;
        }
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.props.loading} />
                <div className="modal_inner_box  align-items-center apply_leave_modal w-480px h-601px px-0">
                    <div className="modal-header">
                        <h4 className="modal-title text_heading ml-3">
                            {"Request Manager’s Feedback"}
                        </h4>
                        <button type="button" className="close" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body w-100">
                        <div className='px-30px pt-24px'>
                            <div className='announcement-pin-content'>Select the <span className='sub-card-text-bold pr-1'>goals</span>and <span className='sub-card-text-bold pr-1'>leadership behaviours</span>you want to request feedback on :</div>
                            {goalApplicationMode === 'write' && !this.state.nextPopUpOpen && !this.state.ShowlastPop &&
                                <div className='popUpHeight'>
                                    <div className='mt-3'>
                                        <span className='sub-card-text-bold'>Goals</span>
                                    </div>
                                    {this.state.data.length > 0 ? (
                                        this.state.data.map((listValue, id) => {
                                            return (
                                                <div key={id}>
                                                    <div className='mt-19px d-flex'>
                                                        <label className="checkBoxData">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                checked={listValue.isSelected}
                                                                onChange={() => this.SelectCheck(listValue, id, 'goal')}
                                                            />
                                                            <span className="markk"></span>
                                                        </label>
                                                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                            <div className='announcement-pin-content'>
                                                                {listValue.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }))
                                        :
                                        <>
                                            <div className='d-flex h-60vh align-item-center flex-column w-100 justify-content-center'>
                                                <img className='align-self-center' src={nodata} alt="Nodata" />
                                                <div className='row'>
                                                    <label className='card-content-title grey-color'>Data Not Found</label>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {leadershipApplicationMode === 'write' && this.state.nextPopUpOpen &&
                                <div className='popUpHeight'>
                                    <div className='mt-3'>
                                        <span className='sub-card-text-bold'>Leadership Behaviours</span>
                                    </div>
                                    {this.state.leadersheepGoalsData.length > 0 ?
                                        (this.state.leadersheepGoalsData.map((listValue, id) => {
                                            return (
                                                <div key={id}>
                                                    <div className='mt-19px d-flex'>
                                                        <label className="checkBoxData">
                                                            <input type="checkbox" className='hidecheckBox'
                                                                checked={listValue.isSelected}
                                                                onChange={() => this.SelectCheck(listValue, id, 'leadership')}
                                                            />
                                                            <span className="markk"></span>
                                                        </label>
                                                        <div className='d-flex' style={{ flexDirection: 'column' }}>
                                                            <div className='announcement-pin-content'>
                                                                {listValue.name}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        )
                                        :
                                        <>
                                            <div className='d-flex h-60vh align-item-center flex-column w-100 justify-content-center'>
                                                <img className='align-self-center' src={nodata} alt="Nodata" />
                                                <div className='row'>
                                                    <label className='card-content-title grey-color'>Data Not Found</label>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            }
                            {
                                this.state.ShowlastPop &&
                                <div className='popUpHeight'>
                                    <div className='mt-3'>
                                        <span className='sub-card-text-bold'>Request Manager's Feedback</span>
                                    </div>
                                    <div className='mt-19px d-flex' style={{ flexDirection: 'column' }}>
                                        <label className='announcement-pin-content'>Select the <span className='announcement-pin-content-bold'>goals</span> and <span className='announcement-pin-content-bold'>leadership behaviours</span> you want to request feedback on:</label>
                                        <label className='announcement-pin-content mt-2'>Personal Note</label>
                                    </div>
                                    <hr />
                                    <div>
                                        <label className='announcement-pin-content'>Add Note</label>
                                        <textarea
                                            className=" note-comment-textarea note-comment-inner note-comment"
                                            // style={{ 'borderBottom': 'none' }}
                                            placeholder="Enter Note..."
                                            rows="3"
                                            maxLength={300}
                                            onChange={(e) => this.setState({ note: e.target.value })}
                                        ></textarea>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={this.props.loading ? 'popup-button-background justify-content-between align-item-center bottomfix mb-3' : 'popup-button-background justify-content-between align-item-center mt-3 bottomfix'}>
                    <div className='popup-category-text ml-3'>Category <span className='popup-category-text-bold'>{this.state.countStatus}</span>/{totalSteps}</div>
                        <div>
                            {
                                this.state.firstnextbtn &&
                                <input type='button'
                                    value='NEXT'
                                    className='validateBtn mr-3'
                                    onClick={() => this.next("nextforLeadership")}
                                />
                            }
                            {
                                this.state.nextPopUpOpen &&
                                <input type='button'
                                    value='NEXT'
                                    className='validateBtn mr-3'
                                    onClick={() => this.next()}
                                />
                            }
                            {
                                this.state.ShowlastPop &&
                                <input type='button'
                                    value='SUBMIT'
                                    className='validateBtn mr-3'
                                    onClick={() => this.next('submit')}
                                />
                            }
                            {
                                this.state.nextPopUpOpen && !(goalApplicationMode === 'close'|| goalApplicationMode==='view' && leadershipApplicationMode === 'write') &&
                                <input type='button'
                                    value='BACK'
                                    className='cancelBtn mr-3'
                                    onClick={() => this.back()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default (connect(mapStatesToProps)(FeedbackRequestPopupNew));