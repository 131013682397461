import React, { Component } from 'react';
import viewIcon from '../../../../assets/drishti_images/view.svg';
import ViewError from './ViewError';
import { get_bulk_upload_details, headers } from '../../../../constants/Config';
import { fetchApiCall } from "../../../../constants/Service";
import { connect } from 'react-redux';
import * as registrationAction from './RegistrationAction';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { noBulkDetails, someThingWentWrong } from '../../../../constants/Messages'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import Nodatafound from '../../../../assets/drishti_images/nodatafound/Nodatafound.svg'

class HistoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passErrorData: [],
            viewErrorScreen: false,
            registeredData: []
        }
    }

    componentDidMount() {
        this.getBulkRegistrationHostory()
    }

    getBulkRegistrationHostory = () => {
        this.setState({
            loading: true
        })
        let body = {
            logged_in_emp_id: this.props.adminLoginData.emp_id,
        }
        fetchApiCall(get_bulk_upload_details, body, headers, false, 'admin')
            .then(res => {
                if (res.status == 200) {
                    this.setState({
                        loading: false,
                    })
                    if (res.data.status_code === "200") {
                        this.setState({
                            loading: false,
                            registeredData: res.data.data[0] ? res.data.data[0] : []
                        })
                    }
                    else {
                        this.setState({
                            loading: false,
                            registeredData: []
                        })
                        setTimeout(() => {
                            toast.warn(res.data.message, {
                                position: "bottom-center",
                                hideProgressBar: true,
                                className: 'toastWarn'
                            })
                        }, 100)
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        registeredData: []
                    })
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    registeredData: []
                })
                console.log("error :", err)
                setTimeout(() => {
                    toast.warn(someThingWentWrong, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }, 100)

            })
    }
    ViewAllError = (errorData) => {
        console.log("errorData:", errorData)
        this.setState({
            viewErrorScreen: true,
            passErrorData: errorData
        })
    }
    render() {
        console.log("registeredData.lenght:", this.state.registeredData.length);
        const { viewErrorScreen } = this.state
        return (
            <>
                <div className='secondContainer historyTable-height justify-content-center-noflex'>
                    <LoaderIndicator loading={this.state.loading} />
                    {
                            this.state.registeredData.length === 0 ?
                            <div className='text-align-center m-15px '>
                                <div className=''>
                                    <img src={Nodatafound} alt="no-data" className='' />
                                </div>
                                <div className='text-align-center'>
                                    <label className='card-content-title grey-color'>{noBulkDetails}</label>
                                </div>
                            </div>
                            :
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col" className='w-15 text-align-center'><div className='thead pr-0'>Bulk ID</div></th>
                                        <th scope="col" className='w-20 text-align-center'><div className='thead pr-0'>Data & Time</div></th>
                                        <th scope="col" className='w-5 text-align-center'><div className='thead pr-0'>Success</div></th>
                                        <th scope="col" className='w-15 text-align-center'><div className='thead pr-0'>Failure</div></th>
                                        <th scope="col"><div className='thead pr-0'>Status</div></th>
                                    </tr>
                                </thead>
                                <tbody className=''>
                                    {this.state.registeredData.map((listValue, id) =>
                                        <tr key={id}>
                                            <td scope="col" className='w-15 text-align-center'><div className='thead pr-0 '>{listValue.bulk_upload_id}</div></td>
                                            <td scope="col" className='w-15 text-align-center'><div className='thead pr-0 '>{moment(listValue.created_date_time).format('MMMM Do YYYY, h:mm:ss a')}</div></td>
                                            <td scope="col" className='w-5 text-align-center'><div className='thead pr-0 '><div>{listValue.success_count}</div></div></td>
                                            <td scope="col" className='w-5 text-align-center'><div className='thead pr-0 flex-style-for-view-button align-item-center-noflex'><div className='m-5px thead pr-0'>{listValue.failure}</div>
                                                {listValue.failure == 0 ?
                                                    ""
                                                    :
                                                    <div className=''>
                                                        <img src={viewIcon} className="pointer viewButton"
                                                            onClick={() => this.ViewAllError(listValue.failure_data)}
                                                        />
                                                    </div>
                                                }
                                            </div></td>
                                            <td scope="col"><div className='thead pr-0'>{listValue.bulk_upload_status}</div></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                    }
                </div>
                {viewErrorScreen &&
                    <ViewError
                        passErrorDataToErrorScreen={this.state.passErrorData}
                        onDismiss={() => this.setState({ viewErrorScreen: false })}
                    />
                }
            </>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        adminRegistrationDate: state.adminRegistrationState,
        keycloakData: state.keycloakState,
        adminLoginData: state.adminLoginState,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(registrationAction, dispatch),
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(HistoryDetails);