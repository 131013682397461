import React, { Component } from 'react';
import '../profile/ProfileScreen.css';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import EducationCards from '../profile/EducationCards';
import { toast } from 'react-toastify';
import DataNotFound from '../../../shared/notFound/DataNotFound';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'

toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime'

class EducationalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            countryData: [],
            examinationPassedData: [],
            educationEstimateData: [],
            instituteData: [],
            weightageData: [],
            branchData: [],
            courseModeData: [],
            finalGradeData: [],
            loading: false,
            showData: true,
            newEducationData: [],
            isActive: false,
        };
    }

    componentDidMount() {
        this.getEducationalDetails(this.props.loginData.userData.Perno);
        this.getEducationMasterData();
    }

    getEducationMasterData = () => {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getEducationMasterDropdown, {}, headers, false, '')
            .then(result => {
                sessionOut(this.props, result);
                if (result.data.status_code == 200) {
                    this.setState({
                        countryData: result.data.data.country ? result.data.data.country : [],
                        examinationPassedData: result.data.data.examination ? result.data.data.examination : [],
                        educationEstimateData: result.data.data.education_estimate ? result.data.data.education_estimate : [],
                        weightageData: result.data.data.weightage ? result.data.data.weightage : [],
                        courseModeData: result.data.data.course_mode ? result.data.data.course_mode : [],
                        finalGradeData: result.data.data.final_grade ? result.data.data.final_grade : [],
                        branchData: result.data.data.branch ? result.data.data.branch : []
                    });
                }

            }).catch(err => {
                // toast.warn('Education Master err', err, {hideProgressBar: true});
            })
    }

    getEducationalDetails(employeeId) {
        this.setState({
            loading: true, isEdit: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getEducationalDetails, { 'empId': employeeId }, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.statusCode == 200 || res.data.statusCode == 300) {
                    if (res.data.statusCode == 300) {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }
                    this.setState({
                        newEducationData: res.data.educationDetails ? res.data.educationDetails : [],
                        showData: true,
                        loading: false
                    });
                }
                else {
                    this.setState({
                        loading: false,
                        newEducationData: []
                    });
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    saveEducationalDetails = (savingData) => {
        this.setState({
            loading: true
        });
        const req = {
            data: [savingData]
        }

        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updateEducationalDetails, req, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.statusCode == 200) {
                    this.setState({
                        loading: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getEducationalDetails(this.props.loginData.userData.Perno)

                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    deleteEducationalDetails = (deleteData) => {
        this.setState({
            loading: true
        });
        const req = {
            data: [deleteData]
        }

        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.deleteEmployeeEducationalDetails, req, headers, false, '')
            .then(res => {
                if (res.data.statusCode == 200) {
                    this.setState({
                        loading: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.getEducationalDetails(this.props.loginData.userData.Perno);
                    if (this.state.newEducationData.length == 1) {
                        this.setState({
                            loading: false,
                            isEdit: false,
                            showData: true
                        });
                    }
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    unSetEdit = () => {
        this.setState({ isEdit: false }, () => this.getEducationalDetails(this.props.loginData.userData.Perno))
    }

    toggleOpenDetail = () => {
        this.setState({
            isEdit: !this.state.isEdit,
        })
    }

    onClickApplyButton = () => {
        this.setState({
            show: !this.state.show
        })
    }
    render() {
        return (
            <div className="row">
                <LoaderIndicator loading={this.state.loading} />
                <div className='row col-md-12 d-flex justify-content-end'>
                    {!this.state.isEdit && <label
                        type='button'
                        className='bank-addoption-text mx-4 mt-3 mb-0'
                        onClick={() => this.toggleOpenDetail()}
                    > + Add New Educational Detail
                    </label>}
                </div>
                <div className="col-md-12">
                    {this.state.showData && !this.state.isEdit ?
                        <div>
                            {!this.state.newEducationData.length == 0 ?
                                <div>
                                    {this.state.newEducationData.map((item, i) => {
                                        return (
                                            <div key={i}>
                                                <EducationCards
                                                    item={item}
                                                    isEdit={false}
                                                    deleteEducationData={(data) => this.deleteEducationalDetails(data)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                :
                                <div className='mt-2'>
                                    <DataNotFound />
                                </div>
                            }
                        </div> : null
                    }
                    {this.state.isEdit ?
                        <EducationCards
                            isEdit={this.state.isEdit}
                            countryData={this.state.countryData}
                            branchData={this.state.branchData}
                            examinationPassedData={this.state.examinationPassedData}
                            educationEstimateData={this.state.educationEstimateData}
                            weightageData={this.state.weightageData}
                            courseModeData={this.state.courseModeData}
                            finalGradeData={this.state.finalGradeData}
                            show={this.state.show}
                            saveEducationData={(data) => this.saveEducationalDetails(data)}
                            cancel={this.unSetEdit} />
                        : null
                    }
                </div>

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(EducationalDetails);