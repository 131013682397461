import React, { Component } from 'react';
import LeaveHistory from '../leave/LeaveHistory';
import PreviousLeaveHistory from '../previousYearLeave/PreviousLeaveHistory';
import * as homeActions from '../landingScreen/ActivePopupAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
export class LeaveHistoryMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_previous_year_leave: false,
            previous_year_leaves_reason: '',
            loading: true
        }
    }

    componentDidMount() {
        this.checkService();
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason,
                    loading: false
                })
            }
        })
    }

    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='card-new leaveHistoryCard'>
                    {!this.state.is_previous_year_leave ?
                        <>
                            <div className='d-flex justify-content-start align-item-center'>
                                <div className={this.props.isCurrentLeaveActive ? "tab-actives" : "tab-inactive"}
                                    onClick={() => this.props.toggleCurrentDetails(this.props.currentLeave)}>{'Current Leave History'}
                                </div>
                                <div className={this.props.isPreviousLeaveActive ? "tab-actives" : "tab-inactive"}
                                    onClick={() => this.props.togglePreviousDetails(this.props.previousLeave)}>{'Previous Year Attd. Reg. History'}
                                </div>
                            </div>
                            <div className='mt-3px'><hr /></div>
                        </>
                        :
                        !this.state.loading &&
                        <div>
                            <LeaveHistory
                                is_previous_year_leave={this.state.is_previous_year_leave}
                                getHistoryAPICall={this.props.getHistoryAPICall} />
                        </div>
                    }

                    {!this.state.is_previous_year_leave && !this.state.loading &&
                        <>
                            {this.props.isCurrentLeaveActive &&
                                <div>
                                    <LeaveHistory
                                        is_previous_year_leave={this.state.is_previous_year_leave}
                                    />
                                </div>
                            }
                            {this.props.isPreviousLeaveActive &&
                                <div>
                                    <PreviousLeaveHistory
                                        is_previous_year_leave={this.state.is_previous_year_leave}
                                        togglePreviousDetails={this.props.togglePreviousDetails}
                                    />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(LeaveHistoryMain);