import React, { Component } from 'react'
import './AppInfo.css'
// import androidIcon from '../../../assets/drishti_images/appInfo/android.png'
import androidIcon from '../../../assets/drishti_images/appInfo/google-blue.svg'
// import appleIcon from '../../../assets/drishti_images/appInfo/apple.png'
import appleIcon from '../../../assets/drishti_images/appInfo/apple-blue.svg'
import LoginMainHead from '../../../components/ScreenType/employeeScreens/login/LoginMainHead'

import { BASE_WEB_URL_DEV, getSliderImages, headers } from '../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../constants/Service';
import { Carousel } from 'react-bootstrap';
import slider_image_default from '../../../assets/drishti_images/login/drishti_login_default_img.png'
import LoaderIndicator from '../../shared/loader/LoaderIndicator'
import loginHead from '../../../assets/drishti_images/login/tml_logo.png'
import { Link } from 'react-router-dom';
import { default_text } from '../../../constants/Messages'

class AppInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderImgData: [],
            loading: false
        }
    }
    componentDidMount() {
        this.getSliderImage()
    }
    getSliderImage = () => {
        this.setState({ loading: true })

        const comp_path = '/cv'       //slider images common for all companies
        fetchApiCall(comp_path + getSliderImages, {}, headers, true, 'service')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        sliderImgData: res.data.data,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        sliderImgData: []
                    })

                }
            })
            .catch(err => {
                console.log('getSliderImage catch err:', err)
                this.setState({ loading: false, sliderImgData: [] })
            })
    }


    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='sec-col d-flex row rowRevert'>
                    <div className='column-1 col-lg-6 col-xl-6 col-md-6' id="down">
                        <div className='round-border d-flex align-items-center justify-content-center'>
                            <div className='main-box round-border mt-5'>
                                <div>
                                    <div>
                                        <LoginMainHead
                                            loginHead={'DRISHTI 2.0'}
                                            loginSubhead={'is also available on '}
                                        />
                                        <div className='mt-15percent '>
                                            <div className='card-new mt-20px mb-20px dflex justify-content-center-noflex w-75'>
                                                <a href="https://apps.apple.com/us/app/tata-motors-drishti/id1523269041" target="_blank"
                                                    className='flex-direction-row align-item-center-noflex'>
                                                    <img
                                                        alt="appleIcon"
                                                        src={appleIcon}
                                                        className="m-8px icon-size"
                                                    ></img>
                                                    <div className='text21 mr-5px getAppText'>Apple Store</div>
                                                </a>
                                            </div>
                                            <div className='card-new mt-20px mb-20px dflex justify-content-center-noflex w-75'>
                                                <a href="https://play.google.com/store/apps/details?id=com.tatamotors.edp_impower" target="_blank"
                                                    className=' flex-direction-row align-item-center-noflex '>
                                                    <img
                                                        alt="androidIcon"
                                                        src={androidIcon}
                                                        className="m-8px icon-size"
                                                    ></img>
                                                    <div className='text21 mr-5px getAppText'>Google Store</div>
                                                </a>
                                            </div>
                                            {/* <div className='flex-direction-row'>
                                                <Link to={'login'} className='w-75'>
                                                    <div className='login-btn text-white px-5px pb-5px pt-5px  m-10px justify-content-center'> 
                                                        BACK TO LOGIN
                                                    </div>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='column-2 col-lg-6 col-xl-6 col-md-6'>
                        <div className='row mt-4 ml-5'>
                            <img src={loginHead} alt='' className='login-img-head' />
                        </div>
                        {this.state.sliderImgData.length != 0 ?
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    {this.state.sliderImgData.map((list, index) =>
                                        <Carousel.Item
                                            key={index}
                                            activeindex={index - 1}
                                            interval={3000}
                                        >
                                            <div className='row mt-1 mr-4 align-item-center-noflex justify-content-center-noflex '>
                                                <img src={list.image_link} alt='' className='login-3d-img' />
                                            </div>
                                            <div>
                                                <div className='row ml-4'>
                                                    <div className='login-stay-touch'>{list.image_title}</div>
                                                </div>
                                                <div className='login-stay-touch-sub mt-2 ml-1'>
                                                    {list.image_hyperlink}
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                                <div className='scroll-down-login'>
                                    <a className='scroll-down-key' href="#down">LOGIN</a>
                                </div>
                            </div>
                            :
                            <div className='my__carousel'>
                                <Carousel
                                    nextIcon={false}
                                    prevIcon={false}
                                    indicators={true}
                                    controls={false}
                                    pause={false}
                                >
                                    <Carousel.Item
                                        activeindex={1}
                                        interval={3000}
                                    >
                                        <div className='row mt-4 ml-5 pl-5'>
                                            <img src={slider_image_default} alt='' className='login-3d-img' />
                                        </div>
                                        <div className='row mt-1 ml-4'>
                                            <span className='login-stay-touch'>New experience,</span>
                                            <span className='login-stay-touch'>new beginnings!</span>
                                        </div>
                                        <div className='login-stay-touch-sub mt-2 ml-1'>
                                            {default_text}
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                                <div className='scroll-down-login'>
                                    <a className='scroll-down-key' href="#down">LOGIN</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default AppInfo