import React, { useEffect, useState } from 'react';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import edit_action from '../../../../../assets/drishti_images/profile/edit.svg';
import close_icon from '../../../../../assets/drishti_images/profile/close_button.png';
import save_icon from '../../../../../assets/drishti_images/profile/save_Icon.png';


const ViewTable = ({
    data,
    PreviewDocument,
    documentKey,
    columns,
    hasAction,
    handleSecondaryAction,
    hasSecondaryAction,
    handleDeleteDocument,
    showCheck,
    showEdit,
    handleContactUpdate
}) => {
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [editedContacts, setEditedContacts] = useState({});
    const [editingRows, setEditingRows] = useState(new Set());

    useEffect(() => {
        if (selectAll) {
            setSelectedRows(data.map((_, index) => index));
        } else {
            setSelectedRows([]);
        }
    }, [data, selectAll]);

    const handleSelectAll = () => {
        setSelectAll(prev => !prev);
    };

    const handleSelectRow = (index) => {
        setSelectedRows(prev => {
            const newSelectedRows = prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index];
            setSelectAll(newSelectedRows.length === data.length);
            return newSelectedRows;
        });
    };

    const handleContactChange = (index, value) => {
        setEditedContacts(prev => ({
            ...prev,
            [index]: value
        }));
    };

    const handleEditClick = (index) => {
        setEditingRows(prev => {
            const newEditingRows = new Set(prev);
            if (newEditingRows.has(index)) {
                newEditingRows.delete(index);
            } else {
                newEditingRows.add(index);
                setEditedContacts(prev => ({
                    ...prev,
                    [index]: data[index]?.contact_no || ''
                }));
            }
            return newEditingRows;
        });
    };

    const handleSaveClick = (index) => {
        const editedContactValue = editedContacts[index];
        if (editedContactValue && editedContactValue.length === 10) {
            handleContactUpdate([{ index, value: editedContactValue }]);
            resetEditingState();
        }
    };

    const handleSaveMultiple = () => {
        const validContacts = selectedRows.filter(index => editedContacts[index] && editedContacts[index].length === 10);
        const updatedContacts = validContacts.map(index => ({
            index,
            value: editedContacts[index]
        }));

        handleContactUpdate(updatedContacts);
        resetEditingState();
    };

    const handleDeleteSelectedRows = () => {
        handleDeleteDocument(selectedRows);
        resetEditingState();
    };

    const resetEditingState = () => {
        setEditingRows(new Set());
        setSelectedRows([]);
        setSelectAll(false);
        setEditedContacts({});
    };

    const handleCloseClick = (index) => {
        setEditingRows(prev => {
            const newEditingRows = new Set(prev);
            newEditingRows.delete(index);
            return newEditingRows;
        });
        setEditedContacts(prev => {
            const newEditedContacts = { ...prev };
            delete newEditedContacts[index];
            return newEditedContacts;
        });
    };

    const canSaveMultiple = selectedRows.every(index => editedContacts[index] && editedContacts[index].length === 10);

    const handleEditMultiple = () => {
        setEditingRows(prev => {
            const newEditingRows = new Set(prev);
            selectedRows.forEach(index => newEditingRows.add(index));
            return newEditingRows;
        });
        setEditedContacts(prev => {
            const newEditedContacts = { ...prev };
            selectedRows.forEach(index => {
                newEditedContacts[index] = data[index]?.contact_no || '';
            });
            return newEditedContacts;
        });
    };

    return (
        <>
            {showCheck && selectedRows.length > 0 && (
                <div className='w-100' style={{ display: 'flex', justifyContent: 'end', position: 'relative', marginBottom: '10px' }}>
                    <div style={{ top: '10px', display: 'flex', gap: '6px' }}>
                        <img
                            src={delete_action}
                            alt='Delete'
                            style={{ width: '30px' }}
                            className='pointer mx-1'
                            onClick={handleDeleteSelectedRows}
                        />
                    </div>
                </div>
            )}

            <div style={{ position: 'relative', overflow: 'auto', minHeight: '140px' ,maxHeight:'140px' }}>
                <table className='table table-no-border' style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead className='thred-Fixed-suggestion'>
                        <tr>
                            {showCheck && (
                                <th className='thead' style={{ width: "0%", padding: '8px' }}>
                                    <input
                                        type='checkbox'
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                </th>
                            )}
                            <th className='thead' style={{ width: "1%", padding: '8px' }}>
                                Sr No
                            </th>
                            {columns.map((column) => (
                                <th className='thead' key={column.key} style={{ width: column.width, padding: '8px' }}>
                                    {column.title}
                                </th>
                            ))}
                            {hasAction && !selectAll && selectedRows.length === 0 && (
                                <th className='thead' style={{ width: "2%" }}>
                                    Action
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((row, index) => (
                            <tr key={index}>
                                {showCheck && (
                                    <td className='tdLeaveHistory' style={{ padding: '8px' }}>
                                        <input
                                            type='checkbox'
                                            checked={selectedRows.includes(index)}
                                            onChange={() => handleSelectRow(index)}
                                        />
                                    </td>
                                )}
                                <td className='tdLeaveHistory' style={{ padding: '8px' }}>{index + 1}</td>
                                {columns.map((column) => (
                                    <td className='tdLeaveHistory' key={column.key} style={{ padding: '8px' }}>
                                        {column.key === 'contact_no' && editingRows.has(index) ? (
                                            <input
                                                type='text'
                                                className='form-control p-placeholder'
                                                style={{
                                                    height: '25px',
                                                    borderColor: (editedContacts[index] && editedContacts[index].length < 10) ? 'red' : 'initial',
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                }}
                                                value={editedContacts[index] || ''}
                                                onChange={(e) => handleContactChange(index, e.target.value)}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key) || (e.target.value.length >= 10)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                            />
                                        ) : (
                                            row[column.key]
                                        )}
                                    </td>
                                ))}
                                {hasAction && !selectAll && selectedRows.length === 0 && (
                                    <td>
                                        <div>
                                            {hasSecondaryAction ? (
                                                <>
                                                </>
                                            ) : (
                                                <>
                                                    {documentKey ? (
                                                        <>
                                                            <img
                                                                src={view_action}
                                                                alt='view'
                                                                style={{ width: '30px' }}
                                                                className='pointer mx-1'
                                                                onClick={() => PreviewDocument(index)}
                                                            />
                                                            <img
                                                                src={delete_action}
                                                                alt='Delete'
                                                                style={{ width: '30px' }}
                                                                className='pointer mx-1'
                                                                onClick={() => handleDeleteDocument(index, 'prev')}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {editingRows.has(index) ? (
                                                                editedContacts[index] && editedContacts[index].length === 10 && (
                                                                    <>
                                                                        <img
                                                                            src={save_icon}
                                                                            alt='Save'
                                                                            style={{ width: '30px' }}
                                                                            className='pointer mx-1'
                                                                            onClick={() => handleSaveClick(index)}
                                                                        />
                                                                    </>
                                                                )
                                                            ) : (
                                                                showEdit && (
                                                                    <>
                                                                        <img
                                                                            src={edit_action}
                                                                            alt='Edit'
                                                                            style={{ width: '30px' }}
                                                                            className='pointer mx-1'
                                                                            onClick={() => handleEditClick(index)}
                                                                        />
                                                                    </>
                                                                )
                                                            )}
                                                            {editingRows.has(index) && (
                                                                <img
                                                                    src={close_icon}
                                                                    alt='Close'
                                                                    style={{ width: '30px' }}
                                                                    className='pointer mx-1'
                                                                    onClick={() => handleCloseClick(index)}
                                                                />
                                                            )}
                                                            <img
                                                                src={delete_action}
                                                                alt='Delete'
                                                                style={{ width: '30px' }}
                                                                className='pointer mx-1'
                                                                onClick={() => handleDeleteDocument(index)}
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ViewTable;