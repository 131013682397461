import React, { Component } from 'react';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import '../../conflictOfInterest/COIStyle.css';
import '../../../../../assets/styles/popupStyles.css';
import '../PaySpaceStyle.css'
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import { toast } from 'react-toastify';
import moment from 'moment';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
// import tataCars from '../../../../../assets/drishti_images/tata-cars.png';
// import accommodationHome from '../../../../../assets/drishti_images/accommodation-home.png';
import radioOff from '../../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../../assets/drishti_images/radioOn.png';
import infoicon from '../../../../../assets/images/cfm/infoicon.svg';

const Note = `Note: Details from 01st Jan 2022 onwards are made available here. For previous details or any other query, please connect with TMLBSL team by raising RMS.`
class CompensationStructure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isServiceUnavailable: false,  // service unavailble from backend
            conveyanceReason: '',
            checked: 0,
            radioFilters: [
                {
                    id: 1,
                    title: 'Accommodation Details',
                    value: 'Accommodation'
                },
                {
                    id: 2,
                    title: 'Conveyance Details',
                    value: 'Conveyance'
                },
            ],
            category: 'Accommodation',
            responseData: [],
        }
    }

    componentDidMount() {
        this.checkService();
        this.getHraAndConveyanceDetails();
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "conveyance_housing" && item.is_active === false) {
                this.setState({
                    isServiceUnavailable: true,
                    conveyanceReason: item.reason
                })
            }
        })
    }

    onPresentType = (key, value) => {
        this.setState({ checked: key, category: value }, () => this.getHraAndConveyanceDetails())
    }

    //------------Get HRA and Conveyance API-----------------------
    getHraAndConveyanceDetails = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            selection_type: this.state.category ? this.state.category : '',
        }
        fetchApiCall(Config.getHraAndConveyanceDetails, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        responseData: res.data.data ? res.data.data : [],
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        responseData: [],
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log("getChoicePayComponents error ", err)
                this.setState({
                    loading: false,
                    responseData: [],
                });
            })
    }

    render() {
        const { loading, conveyanceReason, isServiceUnavailable, responseData, category } = this.state;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                {isServiceUnavailable == true ?
                    <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                        <ServiceUnavailable reason={conveyanceReason} width={true} />
                    </div>
                    :
                    <>
                        <div className='d-flex justify-content-between mt-3'>
                            <div className='d-flex'>
                                <label className='text14_bold ml-4 mt-2'>{"Selection Type:"}</label>
                                <div className='d-flex row justify-content-start ml-2_5rem'>
                                    {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                        return (
                                            <div key={key} className='mx-4 mt-1 mb-2 '>
                                                {this.state.checked === key ?
                                                    <div onClick={() => this.onPresentType(key, list.value)}>
                                                        <img alt="radioOn" className='radio' src={radioOn} />
                                                        <label className="radioTxt" >{list.title}</label>
                                                    </div>
                                                    :
                                                    <div onClick={() => this.onPresentType(key, list.value)}>
                                                        <img alt="radioOff" className='radio' src={radioOff} />
                                                        <label className="radioTxt">{list.title}</label>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='document_card p-3 mb-1rem'>
                            {/* ----------------------Accommondation Details-------------------------- */}
                            {category == 'Accommodation' &&
                                <div>
                                    <div>
                                        <label className='card-heading mb-1'>{"Accommodation Details"}</label>
                                    </div>
                                    <div>
                                        {/* <span className='w-40'>
                                            <img alt="radioOn" className='h-90 border_radius_12px' src={accommodationHome} />
                                        </span> */}
                                        <div className='mt-2'>
                                            {responseData.length != 0 ?
                                                <div className="main-contain h-40vh scroll-y">
                                                    <div className="row align-item-center">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"><div className='thead'>{'Accommodation Type'}</div></th>
                                                                    <th scope="col"><div className='thead'>{'Rent Amount (₹)'}</div></th>
                                                                    <th scope="col"><div className='thead'>{'Start Date'}</div></th>
                                                                    <th scope="col"><div className='thead'>{'End Date'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {responseData.map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay">{listValue.HRATXT ? listValue.HRATXT : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay">{listValue.RTAMT ? listValue.RTAMT : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay">{moment(listValue.BEGDA).format("DD-MM-YYYY") ? moment(listValue.BEGDA).format("DD-MM-YYYY") : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay">{moment(listValue.ENDDA).format("DD-MM-YYYY") ? moment(listValue.ENDDA).format("DD-MM-YYYY") : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* ----------------------Conveyance Details-------------------------- */}
                            {category == 'Conveyance' &&
                                <div>
                                    <label className='card-heading mb-1'>{"Conveyance Details"}</label>
                                    <div>
                                        {/* <span className='w-40'>
                                            <img alt="radioOn" className='h-90 border_radius_12px' src={tataCars} />
                                        </span> */}
                                        <div className='mt-2'>
                                            {responseData.length != 0 ?
                                                <div className="main-contain h-40vh scroll-y">
                                                    <div className="row align-item-center justify-content-start">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col"><div className='thead'>{'Conveyance Type'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Valid From'}</div></th>
                                                                    <th scope="col"><div className='thead '>{'Valid Upto'}</div></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {responseData.map((listValue, index) => {
                                                                    return (
                                                                        <tr key={index} >
                                                                            <td> <div className="td-choice-pay ">{listValue.CONTXT ? listValue.CONTXT : '-'}</div></td>
                                                                            <td> <div className="td-choice-pay">{moment(listValue.BEGDA).format("DD-MM-YYYY") ? moment(listValue.BEGDA).format("DD-MM-YYYY") : '-'}</div> </td>
                                                                            <td> <div className="td-choice-pay">{moment(listValue.ENDDA).format("DD-MM-YYYY") ? moment(listValue.ENDDA).format("DD-MM-YYYY") : '-'}</div> </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <div className='mt-5'>
                                                    <DataNotFound />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row align-content-center'>
                                <img
                                    src={infoicon}
                                    className='mt-2px'
                                ></img>
                                <div className='card-content-title mt-1 ml-1'>
                                    {Note}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(CompensationStructure);