import React, { Component, PureComponent } from 'react';
import Dropdown from '../../../shared/components/Dropdown';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";

const barData = [
    {
        name: "Mon",
        emp_count: 10,
        manager_count: 20
    },
    {
        name: "Tue",
        emp_count: 25,
        manager_count: 33
    },
    {
        name: "Wed",
        emp_count: 8,
        manager_count: 15
    },
    {
        name: "Thu",
        emp_count: 18,
        manager_count: 22
    },
    {
        name: "Fri",
        emp_count: 30,
        manager_count: 12
    },
    {
        name: "Sat",
        emp_count: 23,
        manager_count: 20
    },
    {
        name: "Sun",
        emp_count: 31,
        manager_count: 48
    }
    // {
    //     name: "Mon",
    //     emp_count: 4000,
    //     manager_count: 2400,
    //     amt: 2400
    // },
    // {
    //     name: "Tue",
    //     emp_count: 3000,
    //     manager_count: 1398,
    //     amt: 2210
    // },
    // {
    //     name: "Wed",
    //     emp_count: 2000,
    //     manager_count: 9800,
    //     amt: 2290
    // },
    // {
    //     name: "Thu",
    //     emp_count: 2780,
    //     manager_count: 3908,
    //     amt: 2000
    // },
    // {
    //     name: "Fri",
    //     emp_count: 1890,
    //     manager_count: 4800,
    //     amt: 2181
    // },
    // {
    //     name: "Sat",
    //     emp_count: 2390,
    //     manager_count: 3800,
    //     amt: 2500
    // },
    // {
    //     name: "Sun",
    //     emp_count: 3490,
    //     manager_count: 4300,
    //     amt: 2100
    // }
];
class cfm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cfmData: ['Weekly', 'Monthly'],
            cfmValue: '',
            cfmKey: 'Weekly',
            cfmDataList: [],
        }
    }
    componentDidMount() {
        this.setState({
            cfmKey: this.state.cfmData[0]
        })
        // this.getCFMData(this.props.adminLoginData.emp_id)
    }

    getCFMData(employeeId) {
        this.setState({
            loading: true,
            announcement_type: this.state.selectitem,
            page_number: this.state.page_number,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            announcement_type: this.state.selectitem,
            employee_id: employeeId,
            page_number: this.state.page_number
        }
        console.log("get_announcement body", body);
        fetchApiCall(Config.get_announcement, body, headers, false, 'admin')
            .then(res => {
                console.log("get_announcement res", res);
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        cfmDataList: this.state.cfmDataList.concat(res.data.data),
                        isDataPresent: true,
                        loading: false,
                    })
                } else {
                    this.setState({
                        loading: false,
                        cfmDataList: [],
                        isDataPresent: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    render() {
        return (
            <div className="card-new h-100" >
                <LoaderIndicator loading={this.state.loading} />
                <div className="row d-flex justify-content-center align-item-center">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Continous Feedback Management</label>
                        </div>
                        <div className='mt-2 justify-content-end'>
                            <div>
                                {/* <Dropdown
                                    data={this.state.cfmData}
                                    selectedValue={(data) => this.setState({ cfmValue: data.value, cfmKey: data.key })}
                                    value={this.state.cfmKey}
                                    // render={'key'}
                                    arrow={'true'}
                                /> */}
                            </div>
                        </div>
                    </div>
                    {false ?
                        <BarChart
                            width={500}
                            height={250}
                            data={barData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 1" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="manager_count" fill="#8884d8" name="Manager Count" background={{ fill: "#eee" }} />
                            <Bar dataKey="emp_count" fill="#82ca9d" name="Employee Count" />
                        </BarChart>

                        :
                        <label className='card-content-title grey-color mt-8rem'>Coming Soon</label>
                    }
                </div>
            </div>
        );
    }
}

export default cfm;
