import React, { Component } from 'react'
const title = ' What is Learning Landscape?'
const description = "Learning Landscape has been designed to help you explore and navigate learning opportunities for your Current and Aspirational role. It will help provide rich inputs to IDP conversations."
const whatsnewtext = "Access program details, on LMS by clicking the links, where applicable."
class Welcomepopup extends Component {
    render() {
        return (
            <div className="modal popup-box" >
                <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className="modal-content apply_leave_modal">
                        <div className="modal-header">
                            <h5 className="modal-title welcomepopuptitle" id="exampleModalLongTitle"> {title}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onDismiss}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='welcomomepopupdescription'>
                            {description}
                        </div>
                        <div className='purple-color performance-timeline-text-circle ml-3'>What's new -</div>
                        <div className='welcomomepopupdescription'>
                            {whatsnewtext}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
export default Welcomepopup;
