import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import { connect } from 'react-redux';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import toggleon from '../../../../../assets/drishti_images/profile/toggle-on.svg';
import toggleoff from '../../../../../assets/drishti_images/profile/toggle-off.svg';
import edit from '../../../../../assets/drishti_images/profile/edit.svg';
import ScreenPopUpNotifications from '../ScreenPopupNotification';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import EditScreenPopup from '../EditScreenPopup';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
const current_date = new Date();
class ScreenPopupDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transferRequestData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            actionValue: '',
            isToggle: true,
            isActive: true,
            show: false,
            requestData: [
                {
                    id: 1,
                    title: 'Active'
                },
                {
                    id: 2,
                    title: 'Inactive'
                }
            ],
            data: [],
            selectedScreenData: {},

            fromDate: '',
            imageHyperlink: '',
            imageUrl: '',
            isActiveData: '',
            noteContent: '',
            noteTitle: '',
            noteType: '',
            notificationId: '',
            screenType: '',
            toDate: ''
        }
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    popupDismiss = ()=> {
        this.setState({
            show: !this.state.show
        })
    }

    activeImage = (data) => {
        this.setState({
            showConfirm: true,
            data_delete: data,

            screenType: data.screen_type,
            fromDate: data.from_date,
            toDate: data.to_date
        })
    }

    deletePopupNotification = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            from_date: moment(this.state.fromDate).format('YYYY-MM-DD'),
            screen_type: this.state.screenType,
            to_date: moment(this.state.toDate).format('YYYY-MM-DD'),
        }
        fetchApiCall(Config.deletePopupNotificationBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        showConfirm: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                        this.props.getScreenPopupDashBoardCall(),
                        this.props.getDeactivetedPopupDashBoardCall()
                    )
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    showScreenPopNotificationEdit = (listValue) => {
        console.log('edit----->', listValue);
        this.setState({
            show: true,
            fromDate: listValue.from_date,
            imageHyperlink: listValue.image_hyperlink,
            imageUrl: listValue.image_url,
            isActiveData: listValue.is_active,
            noteContent: listValue.note_content,
            noteTitle: listValue.note_title,
            noteType: listValue.note_type,
            notificationId: listValue.notification_id,
            screenType: listValue.screen_type,
            toDate: listValue.to_date,
        })
    }

    toggle = (isActive) => {
        if (isActive === 'active') {
            this.setState({
                isActive: true
            })
        }
        else {
            this.setState({
                isActive: false
            })
        }
    }

    render() {
        const { showConfirm, showDetail, show } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='d-flex mt-1'>
                    <div className='status-dashboard mt-3 ml-4'>Status :</div>
                    {this.state.isActive === true ?
                        <div className='row align-item-center mt-2 ml-4'>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('active')}
                            >
                                <img alt="radioOn" className='radio ml-4' src={radioOn}
                                />
                                <label className="radioTxt ml-2" >Active</label>
                                <label className="radioTxt ml-1" >{('(' + this.props.screenPopUpDashboard1.length + ')')}</label>
                            </div>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('inActive')}
                            >
                                <img alt="radioOff" className='radio ml-4' src={radioOff}
                                />
                                <label className="radioTxt ml-2">Inactive</label>
                                <label className="radioTxt ml-1">{('(' + this.props.screenPopUpInactiveDashboard1.length + ')')}</label>
                            </div>
                        </div>
                        :
                        <div className='row align-item-center mt-2 ml-4'>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('active')}>
                                <img alt="radioOn" className='radio ml-4' src={radioOff}
                                />
                                <label className="radioTxt ml-2" >Active</label>
                                <label className="radioTxt ml-1" >{('(' + this.props.screenPopUpDashboard1.length + ')')}</label>
                            </div>
                            <div style={{ 'padding': '5px' }}
                                onClick={() => this.toggle('inActive')}>
                                <img alt="radioOff" className='radio ml-4' src={radioOn}
                                />
                                <label className="radioTxt ml-2">Inactive</label>
                                <label className="radioTxt ml-1">{('(' + this.props.screenPopUpInactiveDashboard1.length + ')')}</label>
                            </div>
                        </div>
                    }
                </div>
                {this.state.isActive ?
                    <>
                        <div className="pushNotification-container pt-2" >
                            <div className='sm-scroll-table p-2'>
                                {this.props.screenPopUpDashboard1.length != 0 ?
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Screen Type</div></th>
                                                <th scope="col"><div className='thead'>Note Title</div></th>
                                                <th scope="col"><div className='thead'>Note Type</div></th>
                                                <th scope="col"><div className='thead'>Note Content</div></th>
                                                <th scope="col"><div className='thead'>From Date</div></th>
                                                <th scope="col"><div className='thead'>To Date</div></th>
                                                <th scope="col"><div className='thead'>Active Status</div></th>
                                                <th scope="col"><div className='thead'>Edit</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.screenPopUpDashboard1.map((listValue, index) => {
                                                return (
                                                    <tr key={index} className=''>
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td><div className="td">{listValue.screen_type ? listValue.screen_type : ''}</div></td>
                                                        <td><div className="td" style={{ 'max-width': '150px' }}>{listValue.note_title ? listValue.note_title : ''}</div></td>
                                                        <td><div className="td">{listValue.note_type ? listValue.note_type : ''}</div></td>
                                                        <td><div className="td" style={{ width: "179px" }}>{listValue.note_content ? listValue.note_content : '-'}</div></td>
                                                        <td><div className="td">{listValue.from_date ? moment(listValue.from_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">{listValue.to_date ? moment(listValue.to_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">
                                                            {this.state.isToggle ?
                                                                <img
                                                                    src={toggleon}
                                                                    onClick={() => this.activeImage(listValue)}
                                                                />
                                                                :
                                                                <img
                                                                    src={toggleoff}
                                                                    onClick={() => this.activeImage(listValue)}
                                                                />
                                                            }
                                                        </div></td>
                                                        <td>
                                                            <div className="td" >
                                                                <img
                                                                    src={edit}
                                                                    onClick={() => this.showScreenPopNotificationEdit(listValue)}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No screen Pop-up notifications have been added yet!</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="pushNotification-container pt-2" >
                            <div className='sm-scroll-table p-2'>
                                {this.props.screenPopUpInactiveDashboard1.length != 0 ?
                                    <table className="table">
                                        <thead className=''>
                                            <tr>
                                                <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                                <th scope="col"><div className='thead'>Screen Type</div></th>
                                                <th scope="col"><div className='thead'>Note Title</div></th>
                                                <th scope="col"><div className='thead'>Note Type</div></th>
                                                <th scope="col"><div className='thead'>Note Content</div></th>
                                                <th scope="col"><div className='thead'>From Date</div></th>
                                                <th scope="col"><div className='thead'>To Date</div></th>
                                                <th scope="col"><div className='thead'>Active Status</div></th>
                                                {/* <th scope="col"><div className='thead'>Edit</div></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.screenPopUpInactiveDashboard1.map((listValue, index) => {
                                                return (
                                                    <tr key={index} className=''>
                                                        <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                        <td><div className="td">{listValue.screen_type ? listValue.screen_type : ''}</div></td>
                                                        <td><div className="td" style={{ width: "120px" }}>{listValue.note_title ? listValue.note_title : ''}</div></td>
                                                        <td><div className="td">{listValue.note_type ? listValue.note_type : ''}</div></td>
                                                        <td><div className="td" style={{ width: "179px" }}>{listValue.note_content ? listValue.note_content : '-'}</div></td>
                                                        <td><div className="td">{listValue.from_date ? moment(listValue.from_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">{listValue.to_date ? moment(listValue.to_date).format("DD-MM-YYYY") : ''}</div></td>
                                                        <td><div className="td">
                                                            {listValue.is_active ?
                                                                <img
                                                                    src={toggleon}
                                                                />
                                                                :
                                                                <img
                                                                    src={toggleoff}
                                                                />
                                                            }
                                                        </div></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={noDataTransfer} alt="noDataTransfer" />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>No screen Pop-up notifications have been added yet!</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </>
                }
                {showConfirm &&
                    <NotificationConfirmPopup
                        title={'Deactivate Confirmation '}
                        loader={this.state.loading}
                        action={'enabledisable'}
                        content={'Are you sure you want to deactivate this notification ?'}
                        deactivateData={() => this.deletePopupNotification()}
                        onDismiss={() => this.setState({ showConfirm: false })}

                    />
                }
                {showDetail &&
                    <NotificationDetailsPopup
                        data={this.state.selectedData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
                {/* {show &&
                    <ConfirmPopups
                        popupType={'Screen Pop-Up'}
                        // data={this.state.screenPopUpDashboard}
                        updateData={this.state.selectedScreenData}
                        onDismiss={() => this.setState({ show: false })}
                        type={'editDataPopup'}
                        action={'update'}
                    />
                } */}

                {show &&
                    <div className="modal popup-box" id="addTodoModal">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content toDoModal bg-light">
                                <div className="modal-header edit-cardtitle">
                                    <h4 className="modal_title">{'Screen Pop-Up'}</h4>
                                    <button type="button" className="close" onClick={() => this.setState({ show: false })}>&times;</button>
                                </div>
                                <EditScreenPopup
                                    Action={'update'}
                                    fromDate={this.state.fromDate}
                                    imageHyperlink={this.state.imageHyperlink}
                                    imageUrl={this.state.imageUrl}
                                    isActiveData={this.state.isActiveData}
                                    noteContent={this.state.noteContent}
                                    noteTitle={this.state.noteTitle}
                                    noteType={this.state.noteType}
                                    notificationId={this.state.notificationId}
                                    screenType={this.state.screenType.charAt(0).toUpperCase() + this.state.screenType.slice(1)}
                                    toDate={this.state.toDate}
                                    popupDismiss={this.popupDismiss}
                                    screenPopupDashboardCall={this.props.getScreenPopupDashBoardCall}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(ScreenPopupDashboard);
