import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
class ErrorInPostingJobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    startErrorInPostingJob = () => {
        console.log("job started")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.exportToExcelErrorInPosting, {}, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                this.props.adminAction.setScheduleJobs("errorInPosting")
                if (res.data.status_code === '200') {
                    this.props.adminAction.setScheduleJobs("errorInPosting")
                    this.setState({
                        loading: false,
                    });
                    toast.success("Job started", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row mt-12px '>
                    <div className='row justify-content-between align-item-center px-3 col-md-12 mb-1'>
                        <div className='text16_medium'>
                            {'Error In Posting Jobs'}
                        </div>
                        <div className='d-flex row'>

                        </div>
                    </div>
                    {this.props.adminLoginData.scheduleJobData.errorInPosting === true ?
                        <div className='col-md-12 justify-content-center align-item-center h-60vh'>
                            <button
                                type="submit"
                                className='btn btn-primary w-35 mt-3 login-btn py-2'
                                value="start"
                                onClick={this.startErrorInPostingJob}
                            >{'Start Error in posting Job'} </button>
                        </div>
                        :
                        <div className='col-md-12 justify-content-center align-item-center h-60vh flex-column-job'>
                            <button
                                type="submit"
                                className='btn btn-primary w-35 mt-3 login-btn py-2'
                                value="Started"
                            >{'Error in posting Job Started'}  </button>
                            <div className='row'>
                                <label className='noteTextMsg'>
                                    <span className='noteTextBold'>{'Note:'}</span> {'Please hold on as job has started.'}
                                </label>
                            </div>
                        </div>
                    }
                </div>

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch)
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(ErrorInPostingJobs);