export const GEMSMaintenanceConstant = {
    GEMSMaintenance: 'GEMS Maintenance',
    AddForm: 'Add Form'
}

export const AddPopUp = {
    FormType: 'Form Type',
    PleaseSelectMaintenanceType: 'Please select maintenance type',
    SelectNotificationType: 'Select Form Type',
    Step: 'Step'
}

export const MaintenanceDashboardConstant = {
    Sr: 'Sr',
    Area: 'Area',
    ColorCode: 'Color',
    OrgUnit: 'Organization Unit',
    EmpId: 'Employee Id',
    File: 'File',
    EmpId: 'Employee Id',
    EmpName: 'Employee Name',
    Function: 'Function',
    Testimonials: 'Testimonials',
    ProfileImage: 'Profile Image',
    Image: 'Image',
    ProjectCategory: 'Project Category',
    ProjectDuration: 'Project Duration',
    mtrDuration: 'Mid-Term Review Duration',
    frDuration: 'Final Review Duration',
    ApplicationId: 'Application Id',
    ApplicationPerNo: 'Application Perno',
    ApplicationNo: 'Applicant Employee Id',
    StartDate: 'Start Date',
    EndDate: 'End Date',
    SPOCId: 'SPOC Id',
    Upin: 'Upin',
    SPOCPerNo: 'SPOC Perno',
    SPOCEmail: 'SPOC Email',
    Reason: 'Reason',

    // keys
    Area: 'Area',
    Entity: 'Entity',
    Testimonials: 'Testimonials',
    Duration: 'Duration',
    GemsSliderScreen: 'GemsSliderScreen',
    PIPApplicant: 'PIPApplicant',
    DebarApplicant: 'DebarApplicant',
    OtherProjectApplicant: 'OtherProjectApplicant',
    SPOCList: 'SPOCList',
    isApplicationRejectScreen: 'isApplicationRejectScreen'
}

export const ConfirmedFormPopupConstant = {
    PleaseUpload: 'Please upload image less than 2MB'
}