import React, { Component, Fragment } from 'react'
import FormPopup from '../../../shared/components/gemsFormPopUp/FormPopup';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import right_button_arrow from '../../../../assets/drishti_images/right-button-arrow.svg'
import { appliedApplicant, currentProject, constTypes, buttonName, projectFormConstant, warnings, applicants, statusConst } from './Constant'
import WarningPopup from '../../../shared/components/gemsFormPopUp/WarningPopup';
import CompleteProject from './CompleteProject';
import GemsConfirmPopup from '../../../shared/components/gemsFormPopUp/GemsConfirmPopup';
import SuccessButton from '../../../shared/components/commonButton/SuccessButton';
import CancelButton from '../../../shared/components/commonButton/CancelButton';
import CheckGreenIcon from '../../../../assets/drishti_images/cfm/CheckGreenIcon.svg'
import { raiseProject, getProject, getProjects, updateMTRFR, deleteProject, startProject, getApplicants, startMTR, startFR, closeMTR, closeFR, updateStatus, getPGInstructions, selectIndMTR, selectIndFR, fetchMTRSummary, fetchFRSummary } from './Gems.api.service'
import { toaster } from '../../../../utils/Utils'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import Dropdown from '../../../shared/components/Dropdown';
import { configStore } from "../../../../../src/redux/ConfigStore"
import NewNotdata from '../../../shared/notFound/NewNoData';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import ClosedProject from './ClosedProject';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';

const tableTitles = ["employeeId", "employeeName", "level", "employeeLocation", "projectOU"]
const projectStaus = [
    { id: 0, title: 'Current Projects' },
    { id: 1, title: 'Completed Projects' },
    { id: 2, title: 'Closed Projects' }
]
const applicantStatus = [
    { id: "Shortlisted by SPOC", value: 'Pending' },
    { id: "Shortlisted by PG", value: 'Shortlisted by PG' },
    { id: "Rejected by PG", value: 'Rejected by PG' },
]
const listOfProjectDetails = ["planned_start_date", "planned_end_date", "no_of_openings", "location"]
const listOfProjectDtlKeys = {
    planned_start_date: "Start Date :", planned_end_date: "End Date :", no_of_openings: "No. of Opening :", location: "Project Guide Location :"
}

var TodayDate = new Date()
let body = {}
class GEMSProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            instruction: [],
            isInstructionVisible: true,
            isFormClosed: true,
            canPGAddPrj: false,
            showApplicantList: false,
            hideStatusDrop: true,
            paginated_data: [],
            completedProject: [],
            closedProject: [],
            checked: 0,
            isActionOpened: false,
            actionValue: '',
            reviewState: constTypes.startPrj,
            projectPublished: true,
            projectStarted: false,
            showDetails: false,
            showCurrentProject: true,
            mtrFRShow: false,
            mtrFRShowData: [],
            isApprovalConfirmOpen: false,
            applicantData: [],
            applicantPopupData: [],
            closePopUp: false,
            isActionPopupOpen: false,
            isDeletePopupOPen: false,
            selectedProject: [],
            projectState: constTypes.startPrj,
            projectData: [],
            fieldToCheck: 'mtr_status',
            fieldToCheck2: 'fr_status',
            dropFieldToCheck: 'mtr_status',
            openPopup: false,
            MTRDone: false,
            reviewWarningForFinalReview: false,
            completeProjectPopup: false,
            approveRejectPopup: false,
            loading: false,
            page_number: 1,
            total_pages: "",
            total_records: "",
            canBeDeleteable: true,
            projectStatus: "",
            isMTRUpdate: "",
            futureStartDate: "",
            startProjectDate: [],
            selectedProjectDtl: [],
            showProjects: true,
            statusValue: "Pending",
            applicantDataTemp: [],
            MTRFRKeyhandle: "",
            pgAction: "",
            keyHandleByViewBtn: "",
            applicantDataId: '',
            indMTRData: null,
            closeMTRData: null,
            indFRData: null,
            mtrViewData: null,
            titleText: '',
            isSearchActive: true,
            isEarlyStartMTR: false,
            isEarlyStartFR: false,
            dateData: ''
        }
    }

    componentDidMount() {
        this.getProjects();
        this.getPGInstructions()
    }

    getPGInstructions = () => {
        this.setState({
            loading: true
        })
        getPGInstructions().then((res) => {
            this.setState({
                instruction: res?.data?.data || [],
                loading: false
            })
        }).catch((err) => {
            console.log("get pg instructions error", err, err.response);
            toaster("toastWarn", err?.response?.message || "Error fetching PG Instructions")
        })
    }

    getProjects = (pageNo, customStatus) => {
        this.setState({
            loading: true
        })
        const param = {
            pg_perno: configStore.getState().loginState?.userData?.Perno,
            page_number: this.state.page_number,
            title: this.state.titleText
        }
        if (customStatus) {
            param.status = customStatus
        }
        getProjects(param, this.props)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    this.setState({
                        page_number: res.data.data.page_number ? res.data.data.page_number : "",
                        total_records: res.data.data.total_records ? res.data.data.total_records : "",
                        total_pages: res.data.data.total_pages ? res.data.data.total_pages : ""
                    })
                    if (!customStatus) {
                        this.setState({
                            paginated_data: [
                                ...(this.state.page_number == 1 ? [] : this.state.paginated_data),
                                ...(res.data.data.paginated_data ? res.data.data.paginated_data : [])
                            ].filter((val) => val.status !== "Completed")
                        })
                    } else if (customStatus == "Completed") {
                        this.setState({
                            completedProject: res.data.data.paginated_data
                        })
                    }
                    else if (customStatus == "Closed") {
                        this.setState({
                            closedProject: res.data.data.paginated_data
                        })
                    }
                }
                else {
                    toaster("warning", res.data.message ? res.data.message : "")
                    this.setState({
                        paginated_data: [], page_number: "", total_records: 0, total_pages: 0
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false, paginated_data: [], page_number: "", total_records: 0, total_pages: 0
                })
                toaster("", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    paginationApi = () => {
        this.setState({
            page_number: this.state.page_number + 1
        },
            () => this.getProjects(this.state.page_number))
    }

    submitAddProject = (data, obj) => {
        let UpdatedBody = {
            title: data.projectTitle,
            description: data.projectDescription,
            area: data.projectAreaValue,
            org_unit: data.OUValue,
            location: data.pgLocation,
            no_of_openings: data.noOfPeople,
            duration: data.projectDuration,
            category: data.category,
            planned_start_date: moment(new Date(data.selectedStartDate)).format("YYYY-MM-DD"),
            planned_end_date: moment(new Date(data.selectedEndDate)).format("YYYY-MM-DD"),
            planned_mtr_date: moment(new Date(data.selectedMTRDate)).format("YYYY-MM-DD"),
            planned_fr_date: moment(new Date(data.selectedFRDate)).format("YYYY-MM-DD"),
            gems_review_objective: data.targetDeliverable,
        }
        body = UpdatedBody
    }

    addProjectApi = () => {
        // console.log("addProjectApi body", body)
        this.setState({
            loading: true
        })
        raiseProject(body, this.props)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({ page_number: 1 }, () => this.getProjects());
                }
                else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                toaster("toastWarn", "Something went wrong")
                console.log("raiseProject catch error:", err)
            })
    }

    reviewCall = (data, type) => {
        //start MTR
        if (type === constTypes.startMTR) {
            this.setState({
                reviewWarning: true,
                type: type,
                projectData: data,
                message: warnings.strtMTR
            })
        }
        else if (type === "Early Start MTR") {
            this.setState({
                reviewWarning: true,
                dateData: data.revised_mtr_date,
                isEarlyStartMTR: true,
                type: type,
                projectData: data,
                message: warnings.strtMTR
            })
        }
        //Start FR
        else if (type === constTypes.startFTR) {
            this.setState({
                reviewWarningForFinalReview: true,
                type: type,
                projectData: data,
                message: warnings.strtFR
            })
        }
        else if (type === "Early Start FR") {
            this.setState({
                reviewWarningForFinalReview: true,
                dateData: data.revised_fr_date,
                isEarlyStartFR: true,
                type: type,
                projectData: data,
                message: warnings.strtFR
            })
        }
        //Complete Project
        else if (type === constTypes.completePro) {
            this.setState({
                completeProjectPopup: true,
                type: type,
                projectData: data,
                message: warnings.completeProject
            })
        }
    }

    deleteProject = () => {
        let projectData = this.state.selectedProjectDtl[0];
        this.setState({
            loading: true
        })
        deleteProject(projectData.id, this.props)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status != 400 && res.status != 404) {
                    console.log("deleteProject res>>", res)
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        showCurrentProject: false,
                        showDetails: false,
                        isDeletePopupOPen: false,
                        showProjects: true,
                        page_number: 1
                    }, () => this.getProjects())
                }
                else {
                    toaster("toastWarn", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                toaster("toastWarn", err.response?.message)
                console.log("get project catch error:", err)
            })
    }

    onChangedProjectStatus = (key, title) => {
        const refreshAction = title === "Current Projects" ? '' : title === "Completed Projects" ? 'Completed' : title === "Closed Projects" ? 'Closed' : ''
        this.setState({
            isSearchActive: true,
            titleText: '',
            checked: key,
            page_number: 1,
            showCurrentProject: title === "Current Projects" ? true : false,
            showProjects: title === "Current Projects" ? true : false,
            showDetails: false,
            showApplicantList: false,
        }, () => this.getProjects(this.state.page_number, refreshAction))
    }

    goToDetailScreen = (dataValue, idx) => {
        this.setState({
            showProjects: false,
            projectStatus: dataValue.status,
            isSearchActive: false
        })
        this.setState({ loading: true })
        getProject(dataValue.id, this.props)
            .then((res) => {
                this.setState({ loading: false })
                if (res) {
                    this.setState({
                        selectedProjectDtl: res.data.data ? res.data.data : [],
                        showDetails: true,
                        showProjects: false,
                        showCurrentProject: false
                    })
                }
                else {
                    this.setState({
                        selectedProjectDtl: []
                    })
                }
            })
            .catch((err) => {
                this.setState({ loading: false, selectedProjectDtl: [] })
                console.log("getProject err---->", err)
            })
    }

    showObjective = (dataValue) => {
        this.setState({
            mtrFRShow: true,
            mtrFRShowData: dataValue.id
        })
    }

    updateMTRFR = (data, obj) => {
        // console.log('updateMTRFR data NEWWWWWW---->', data);
        // console.log('updateMTRFR obj NEWWWWW---->', obj);
        this.updateProjectObj(data)
    }

    updateProjectObj = (action, data) => {
        // console.log("updateProjectObj DATA---->", data)
        // console.log("updateProjectObj action---->", action)
        let body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "gems_review_objective": action === 'submit' ? data : action.deliverable
        }
        if (action === "submit") {
            updateMTRFR(this.state.mtrFRShowData, body, this.props)
                .then((res) => {
                    this.setState({
                        loading: false,
                        mtrFRShow: false
                    })
                    if (res.status === 200 || res.status === 201) {
                        this.setState({
                            loading: false,
                            mtrFRShow: false
                        })
                        toaster("success", res.data.message ? res.data.message : "")
                    }
                    else {
                        this.setState({
                            loading: false,
                            mtrFRShow: false
                        })
                        toaster("warning", res.data.message ? res.data.message : "")
                    }
                })
                .catch((err) => {
                    this.setState({
                        loading: false,
                        mtrFRShow: false
                    })
                    console.log("raiseProject catch error:", err)
                })
        }
        else {
            console.log("updateProjectObj else")
        }
    }

    // ------- Approval action by PG ------------
    approvalConfirm = (data, status) => {
        this.setState({
            isApprovalConfirmOpen: true,
            applicantPopupData: [data],
            applicantDataId: data.id,
            pgAction: status
        })
    }

    doCloseMTR = () => {
        this.setState({
            openPopup: true,
            projectState: constTypes.closeMTR,
            type: constTypes.closeMTR
        })
    }

    doCloseFR = () => {
        this.setState({
            mtrViewData: this.state.applicantData,
            openPopup: true,
            projectState: constTypes.closeFR,
            type: constTypes.closeFR
        })
    }

    doIndividualMTR = (data, action) => {
        this.setState({
            openPopup: true,
            type: action,
            projectData: data,
            projectState: constTypes.indMTR
        })
    }

    doIndividualFR = (data, action) => {
        this.setState({
            openPopup: true,
            type: action,
            projectData: data,
            projectState: constTypes.indFR
        })
    }

    startPrj = (data, action) => {
        const todayDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss ZZ");
        const futureStartDate = new Date(todayDate.clone().add(15, 'days'));
        // Open declare popup and then start the project
        this.setState({
            openPopup: true,
            type: action,
            projectData: data,
            futureStartDate: futureStartDate,
        })
    }

    completeProjectApi = (pjId) => {
        let body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": "Completed"
        }
        this.setState({ loading: true })
        startProject(pjId, body)
            .then((res) => {
                this.setState({ loading: false })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                    this.setState({
                        showCurrentProject: false,
                        showDetails: false,
                        checked: 1
                    },
                        () => this.getProjects(this.state.page_number, "Completed")
                    )
                }
                else {
                    toaster("warning", res?.data?.message ? res?.data?.message : "")
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("startProjectApi catch err>>", err)
            })
    }

    startProjectApi = (data, value) => {
        let body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate.status,
            "duration": this.state.startProjectDate.duration,
            "category": this.state.startProjectDate.category,
            "planned_start_date": this.state.startProjectDate.planned_start_date,
            "actual_start_date": this.state.startProjectDate.actual_start_date,
            "actual_end_date": this.state.startProjectDate.actual_end_date,
            "revised_mtr_date": this.state.startProjectDate.actual_mtr_date,
            "revised_fr_date": this.state.startProjectDate.actual_fr_date
        }
        this.setState({ loading: true })
        value === true ?
            startProject(this.state.projectData.id, body)
                .then((res) => {
                    this.setState({ loading: false })
                    if (res.status === 200 || res.status === 201) {
                        toaster("success", res?.data?.message ? res?.data?.message : "")
                        this.setState({
                            showCurrentProject: true,
                            showDetails: true,
                        }, () => this.goToDetailScreen(this.state.selectedProjectDtl[0]))
                    }
                    else {
                        toaster("warning", res?.data?.message ? res?.data?.message : "")
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false })
                    console.log("startProjectApi catch err>>", err)
                })
            :
            toaster("warning", "Please select start date and read declaration")
    }
    // First call the start MTR api then call getApplicants
    callStartMTR = (id) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate.status,
        }
        this.setState({ loading: true })
        startMTR(id, body)
            .then((res) => {
                this.setState({ loading: false })
                console.log("callStartMTR res>>", res)
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        showCurrentProject: true,
                        showDetails: true,
                    }, () => this.goToDetailScreen(this.state.selectedProjectDtl[0]))
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callStartMTR catch err>>", err)
            })
    }

    // First call the start FR api then call getApplicants
    callStartFR = (id) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate.status,
        }
        this.setState({ loading: true })
        startFR(id, body)
            .then((res) => {
                this.setState({ loading: false })
                console.log("callStartFR res>>", res)
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.setState({
                        showCurrentProject: true,
                        showDetails: true,
                    }, () => this.goToDetailScreen(this.state.selectedProjectDtl[0]))
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callStartFR catch err>>", err)
            })
    }

    getApplicantsFunction = (id, status) => {
        this.setState({ loading: true })
        const params = {
            status: status
        }
        getApplicants(id, params, this.props)
            .then((res) => {
                this.setState({ loading: false })
                if (res) {
                    this.setState({ applicantData: res?.data?.data ? res.data.data : [] })
                    console.log("getApplicants res>>", res)
                    this.setState({
                        keyHandleByViewBtn: status
                    })
                }
                else {
                    this.setState({ applicantData: [] })
                }
            })
            .catch((err) => {
                this.setState({ loading: false, applicantData: [] })
                console.log("getApplicants catch err>>", err)
            })
    }

    // First call the select MTR api then call getApplicants
    callSelectMTR = (selectedProjectId, applicantId) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate?.status,
            "mtr_status": this.state.indMTRData?.mtr_status ? this.state.indMTRData?.mtr_status : "On Track",
            "mtr_comments": this.state.indMTRData?.mtr_comments,
        }
        this.setState({ loading: true })
        selectIndMTR(selectedProjectId, applicantId, body)
            .then((res) => {
                this.setState({ loading: false })
                console.log("callSelectMTR res>>", res)
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG")
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callSelectMTR catch err>>", err)
            })
    }

    // First call the select FR api then call getApplicants
    callSelectFR = (selectedProjectId, applicantId) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate?.status,
            "fr_status": this.state.indFRData?.fr_status ? this.state.indFRData?.fr_status : "Completed",
            "fr_comments": this.state.indFRData?.fr_comments,
        }
        this.setState({ loading: true })
        selectIndFR(selectedProjectId, applicantId, body)
            .then((res) => {
                this.setState({ loading: false })
                console.log("callSelectFR res>>", res)
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res.data.message ? res.data.message : "")
                    this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG")
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callSelectFR catch err>>", err)
            })
    }

    onChangeStatus = (status) => {
        this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, status)
        this.setState({
            keyHandleByViewBtn: status
        })
    }

    callCloseMTR = (id) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate.status,
            "mtr_status": this.state.closeMTRData?.mtr_status,
            "mtr_comments": this.state.closeMTRData?.mtr_comments,
            "filename": this.state.closeMTRData?.filename,
            "attachment": this.state.closeMTRData?.attachment,
        }
        console.log("CLOSE MTR body", body)
        this.setState({ loading: true })
        closeMTR(id, body)
            .then((res) => {
                this.setState({ loading: false })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                    this.setState({ showApplicantList: false, showDetails: true }, () => this.goToDetailScreen(this.state.selectedProjectDtl[0]))
                    console.log("callCloseMTR res>>", res)
                } else {
                    toaster("warning", res?.data?.message ? res?.data?.message : "")
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callCloseMTR catch err>>", err)
            })
    }

    callCloseFR = (id) => {
        const body = {
            "emp_id": configStore.getState().loginState?.userData?.Perno,
            "status": this.state.startProjectDate?.status,
            "fr_status": this.state.closeMTRData?.fr_status,
            "fr_comments": this.state.closeMTRData?.fr_comments,
            "filename": this.state.closeMTRData?.filename,
            "attachment": this.state.closeMTRData?.attachment,
            "is_project_completed": this.state.closeMTRData?.is_project_completed,
        }
        console.log("CLOSE FR body", body)
        this.setState({ loading: true })
        closeFR(id, body)
            .then((res) => {
                this.setState({ loading: false })
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                    if (this.state.closeMTRData?.is_project_completed === true) {
                        this.setState({ showApplicantList: false, checked: 1, showCurrentProject: false, showDetails: false }, () => this.completeProjectApi(this.state.selectedProjectDtl[0].id))
                    } else {
                        this.setState({ showApplicantList: false, showDetails: true }, () => this.goToDetailScreen(this.state.selectedProjectDtl[0]))
                    }
                    console.log("callCloseFR res>>", res)
                } else {
                    toaster("warning", res?.data?.message ? res?.data?.message : "")
                }
            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log("callCloseFR catch err>>", err)
            })
    }

    approvalByPG = (value) => {
        const status = value === "approval" ? "Shortlisted by PG" : value === "reject" ? "Rejected by PG" : ""
        const body = {
            "status": status,
            "emp_id": configStore.getState().loginState?.userData?.Perno,
        }
        this.setState({ loading: true })
        updateStatus(this.state.selectedProjectDtl[0].id, this.state.applicantDataId, body)
            .then((res) => {
                this.setState({ loading: false })
                console.log("updateStatus res>>", res)
                if (res.status === 200 || res.status === 201) {
                    toaster("success", res?.data?.message ? res?.data?.message : "")
                    this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by SPOC")
                    this.setState({
                        MTRFRKeyhandle: this.state.selectedProjectDtl[0].status,
                        keyHandleByViewBtn: status
                    })
                }
                else {
                    toaster("warning", res?.data?.message ? res?.data?.message : "")
                }
            })
            .catch((err) => {
                console.log("updateStatus catch err>>", err)
            })
    }

    renderSFTooltip = props => (
        <div className='flex-direction-row tool-tip-font-gems-applicant'>
            <div className='flex-direction-column justify-content-around'>
                <span>{'Applicant already selected in another project.'}</span>
            </div>
        </div>
    );

    downloadMTR = (projectID) => {
        this.setState({
            loading: true
        })
        fetchMTRSummary(projectID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchMTRSummary catch err>>", err)
            })
    }

    downloadFR = (projectID) => {
        this.setState({
            loading: true
        })
        fetchFRSummary(projectID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res.status === 200 || res.status === 201) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                } else {
                    toaster("warning", res.data.message ? res.data.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchFRSummary catch err>>", err)
            })
    }

    searchProjectFilter = (status) => {
        this.setState({
            titleText: this.state.titleText,
            pageNumber: 1,
        }, () => this.getProjects("", status))
    }

    render() {
        // Use the filter method to get an array of objects where the specified field is null
        const nullValues = this.state.applicantData.filter(item => item[this.state.fieldToCheck] === null);
        // Get the count of null values using the length property of the filtered array
        const nullCount = nullValues.length;
        const nullValues2 = this.state.applicantData.filter(item => item[this.state.fieldToCheck2] === null);
        const nullCount2 = nullValues2.length;
        const dropValues = this.state.applicantData.filter(item => item[this.state.dropFieldToCheck] === "Dropped");
        const dropCount = dropValues.length;
        const finalFRCount = nullCount2 - dropCount
        const { paginated_data } = this.state;
        let instructionFlag = false;
                return (
            <div className='m-10px'>
                <LoaderIndicator loading={this.state.loading} />
                {/* -------Radio button for current/complete project---------- */}
                <div className='flex-direction-row align-item-center-noflex justify-content-between mx-15px mt-10px mb-15px'>
                    <div className='flex-direction-row align-item-center-noflex'>
                        <span className='performance-timeline-text-circle'>Project Status:</span>
                        {projectStaus.map((list, key) => {
                            return (
                                <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                    {this.state.checked === key ?
                                        <span className=''
                                            onClick={() => this.onChangedProjectStatus(key, list.title)}
                                        >
                                            <img alt="radioOn" className='radio' src={radioOn} />
                                            <label className="radioTxt ml-15px" >{list.title}</label>
                                        </span>
                                        :
                                        <span className=''
                                            onClick={() => this.onChangedProjectStatus(key, list.title)}
                                        >
                                            <img alt="radioOff" className='radio' src={radioOff} />
                                            <label className="radioTxt ml-15px">{list.title}</label>
                                        </span>
                                    }
                                </span>
                            )
                        })}
                        {this.state.isSearchActive &&
                            <div className='flex-direction-row f-gap-1rem'>
                                <div className='row ml-10'>
                                    <div className='p-placeholder-text'>
                                        <input
                                            className="form-control p-placeholder px-2 p-placeholder-admin"
                                            type="text"
                                            placeholder='Search Project'
                                            onChange={(e) => this.setState({ titleText: e.target.value })}
                                            value={this.state.titleText}
                                        />
                                    </div>
                                    <div type='button' className='serchIconSize-gems' onClick={() => this.searchProjectFilter(this.state.checked == 1 ? "Completed" : this.state.checked == 2 ? "Closed" : '')}>
                                        <img alt="radioOn" className='filterIcon' src={searchIcon} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <span className={`${!this.state.showDetails && !this.state.showApplicantList ? "w-15" : ""} `}>
                        <span>
                            {!this.state.showDetails && !this.state.showApplicantList && this.state.projectState !== constTypes.waitForMTR &&
                                <button
                                    type="submit"
                                    className='btn btn-primary login-btn py-2 w-100'
                                    disabled={false}
                                    onClick={() => this.setState({ isFormClosed: false, checked: 0 })}
                                >
                                    +&nbsp;{constTypes.addNewProject}
                                </button>
                            }
                        </span>
                        {this.state.checked == 0 &&
                            <>
                                { //This status dropdown will be shown if project not started and mtr status not be started
                                    this.state.showApplicantList && !this.state.hideStatusDrop && !this.state.selectedProjectDtl[0].is_active_start_mtr &&
                                    <div className='flex-direction-row align-item-center-noflex'>
                                        <span className='radioTxt'>{"Applicant Status:"}</span>
                                        <span>
                                            <Dropdown
                                                data={applicantStatus}
                                                selectedValue={(applicantStatus) => this.setState({ statusValue: applicantStatus.value }, () => this.onChangeStatus(applicantStatus.id))}
                                                value={this.state.statusValue}
                                                render={'value'}
                                                height={"notificationHeight"}
                                            />
                                        </span>
                                    </div>
                                }
                            </>}
                    </span>
                </div>

                {this.state.checked === 0 ?
                    <>
                        {this.state.paginated_data == 0 && !this.state.showDetails ?
                            <>
                                <div className='noProject-style performance-timeline-text-bold p-10px mx-10px'>
                                    {constTypes.plsAddProject}
                                </div>
                                <div className='justify-content-between flex-direction-row mx-30px mt-10px mb-15px'>
                                    <span className='edit-cardtitle'>{constTypes.Instruction}</span>
                                </div>
                                <div className='h-48vh scroll-auto'>
                                    {this.state.instruction.map((listValue, index) => {
                                        return (
                                            <div className='m-10px px-30px' key={index}>
                                                <div className='row mx-4 mr-4 mt-2'>
                                                    {listValue?.line?.split(" ").map((text) =>
                                                        <span>{text !== "<b>" && text !== "</b>" && !instructionFlag &&
                                                            <h2 className='coi-sub-text mr-4px'>
                                                                {text}
                                                            </h2>}
                                                            {text === "<b>" && (instructionFlag = true)}
                                                            {text !== "<b>" && text !== "</b>" && instructionFlag &&
                                                                <span className='highlited-text mr-4px'>
                                                                    {text}
                                                                </span>}
                                                            {text === "</b>" && (instructionFlag = false)}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </>
                            :
                            // -------------show all project(PG's project list)--------------------------
                            this.state.showProjects &&
                            <div className='h-60vh scroll-auto'>
                                {paginated_data.map((dataValue, idx) =>
                                    <div key={idx} className='document_card p-10px m-10px pointer flex-direction-row justify-content-between align-item-center-noflex'
                                        onClick={() => this.goToDetailScreen(dataValue, idx)}
                                    >
                                        <span>
                                            <span className='text16_bold project-list-style mx-10px '>
                                                {dataValue.title}
                                            </span>
                                            <div className='flex-direction-row'>
                                                {
                                                    listOfProjectDetails.map((key, idx) =>
                                                        <span key={idx} className='mx-10px'>
                                                            <span className='appcenter-app-name'>{listOfProjectDtlKeys[key]}&nbsp;&nbsp;</span>
                                                            <span className='feedback-sm-text'>{key == 'planned_start_date' || key == 'planned_end_date' ? moment(dataValue[key]).format('DD-MM-YYYY') : dataValue[key]}</span>
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        </span>
                                        <span className='mr-10'>
                                            <img alt="go to details" className='' src={right_button_arrow} />
                                        </span>
                                    </div>
                                )
                                }
                                {this.state.page_number < this.state.total_pages && this.state.total_pages > 1 &&
                                    <span className='blue-button pointer dflex align-item-center-noflex justify-content-center-noflex m-10px' onClick={() => this.paginationApi()}>
                                        <span className='card-content-title more-style m-10px'>{constTypes.more}</span>
                                    </span>
                                }
                            </div>
                        }
                        {/*------PG's perticular project, after the select one project (Detail view)----------------*/}
                        {this.state.showDetails &&
                            <div className='card-new'>
                                {this.state.selectedProjectDtl.map((dataValue, id) =>
                                    <span key={id} className=''>
                                        <div className='justify-content-between flex-direction-row mx-20px mt-10px mb-15px'>
                                            <span className='align-item-center'>
                                                <span onClick={() => this.setState({ showDetails: false, showCurrentProject: true, showProjects: true, isSearchActive: true }, () => this.getProjects(this.state.page_number, ""))}>
                                                    <img alt="Back" className='mr-10 pointer' src={backArrow} />
                                                </span>
                                                <span className='text1_25rem-bold'>{dataValue.title}</span>
                                            </span>
                                            {/* ---------------- Start button ---------- */}
                                            {dataValue.is_active_start_project &&
                                                <>{
                                                    dataValue.is_enable_start_project ?
                                                        <>
                                                            <button
                                                                type="startProject"
                                                                className='btn btn-primary login-btn py-2 w-15'
                                                                disabled={false}
                                                                onClick={() => this.startPrj(dataValue, constTypes.startPrj)}
                                                            >
                                                                {constTypes.startPrj}
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                            <button
                                                                type="startProject"
                                                                className='btn btn-primary validateBtnDisabled py-2 w-15'
                                                                disabled={true}

                                                            >
                                                                {constTypes.startPrj}
                                                            </button>

                                                        </>}
                                                </>

                                            }
                                            {/* ---------withdraw (Delete) Project button ------- */}
                                            {dataValue.is_active_withdraw_project &&
                                                <button
                                                    type="WithdrowProject"
                                                    className='btn btn-primary login-btn py-2 w-20'
                                                    disabled={false}
                                                    onClick={() => this.setState({ isDeletePopupOPen: true })}
                                                >
                                                    {buttonName.withdrawProject}
                                                </button>
                                            }
                                            {(dataValue.is_active_start_mtr && dataValue.revised_mtr_date > moment(TodayDate).format('YYYY-MM-DD')) ?
                                                <>
                                                    <button
                                                        type="startMTR"
                                                        className='btn btn-primary login-btn py-2 w-15'
                                                        disabled={false}
                                                        onClick={() => this.reviewCall(dataValue, "Early Start MTR")}
                                                    >
                                                        {"START MTR REVIEW"}
                                                    </button>
                                                </>
                                                :
                                                <>
                                                {dataValue.is_active_start_mtr &&
                                                    <button
                                                        type="startMTR"
                                                        className='btn btn-primary login-btn py-2 w-15'
                                                        disabled={false}
                                                        onClick={() => this.reviewCall(dataValue, constTypes.startMTR)}
                                                    >
                                                        {constTypes.startMTR}
                                                    </button>
                                                }
                                                </>
                                            }
                                            {this.state.selectedProjectDtl[0]?.status === "Ongoing - MTR Initiated" &&
                                                <button
                                                    type="ProccedMtr"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {constTypes.proceedToMTR}
                                                </button>
                                            }
                                            {(dataValue.is_active_start_fr && dataValue.revised_fr_date > moment(TodayDate).format('YYYY-MM-DD')) ?
                                                <>
                                                    <button
                                                        type="startMTR"
                                                        className='btn btn-primary login-btn py-2 w-15'
                                                        disabled={false}
                                                        onClick={() => this.reviewCall(dataValue, "Early Start FR")}
                                                    >
                                                        {"START FR REVIEW"}
                                                    </button>
                                                </>
                                                :
                                                <>
                                                {dataValue.is_active_start_fr &&

                                                    <button
                                                        type="startMTR"
                                                        className='btn btn-primary login-btn py-2 w-15'
                                                        disabled={false}
                                                        onClick={() => this.reviewCall(dataValue, constTypes.startFTR)}
                                                    >
                                                        {constTypes.startFTR}
                                                    </button>
                                                }
                                                </>
                                            }
                                            {this.state.selectedProjectDtl[0]?.status === "Ongoing - FR Initiated" &&
                                                <button
                                                    type="ProccedMtr"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {constTypes.proceedToFR}
                                                </button>
                                            }
                                            {dataValue.is_active_complete_project &&
                                                <button
                                                    type="completeThePro"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    disabled={false}
                                                    onClick={() => this.reviewCall(dataValue, constTypes.completePro)}
                                                >
                                                    {constTypes.completePro}
                                                </button>
                                            }
                                        </div>

                                        {/* ---- MTR/FR button */}
                                        <div className='justify-content-end mx-25px p-10px' >
                                            <span className='card-content-title project-list-style pointer'
                                                onClick={() => this.showObjective(dataValue)}
                                            >
                                                {buttonName.mtrFRObj}
                                            </span>
                                        </div>
                                        <>
                                            <div className="mx-20px scroll-auto h-31vh">
                                                <div className='pg-project-grid'>
                                                    <div className='pgProjectFirst flex-direction-column'>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Guide Name : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.CompName ? dataValue.project_guide.CompName : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Manager Name : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.manager?.CompName ? dataValue.manager.CompName : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Start Date : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.actual_start_date ? moment(dataValue.actual_start_date).format("DD-MM-YYYY") : dataValue.planned_start_date ? moment(dataValue.planned_start_date).format("DD-MM-YYYY") : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Category : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.category ? dataValue.category : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Duration (days) : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.duration ? dataValue.duration : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Application Shortlisted by SPOC : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue.appl_shrtlted_by_spoc ? dataValue.appl_shrtlted_by_spoc : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Mid Term Review Attachment : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.mtr_attachment ? dataValue.mtr_attachment : "--"}</span>
                                                            {dataValue?.mtr_attachment &&
                                                                <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadMTR(dataValue?.id)} />
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className='pgProjectSecond flex-direction-column'>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Guide Location : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.location ? dataValue.location : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Area : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.area?.area ? dataValue.area.area : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project End Date : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.actual_end_date ? moment(dataValue.actual_end_date).format("DD-MM-YYYY") : dataValue.planned_end_date ? moment(dataValue.planned_end_date).format("DD-MM-YYYY") : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Guide Level : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.Esgtxt ? dataValue.project_guide.Esgtxt : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Final Review Status : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.fr_status ? dataValue.fr_status : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Final Review Attachment : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.fr_attachment ? dataValue.fr_attachment : "--"}</span>
                                                            {dataValue?.fr_attachment &&
                                                                <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadFR(dataValue?.id)} />
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className='pgProjectThird flex-direction-column'>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"BHR Name : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.bhr?.CompName ? dataValue.bhr.CompName : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Entity : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.org_unit?.org_unit ? dataValue.org_unit.org_unit : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Mid Term Review Date : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.actual_mtr_date ? moment(dataValue.actual_mtr_date).format("DD-MM-YYYY") : dataValue.revised_mtr_date ? moment(dataValue.revised_mtr_date).format("DD-MM-YYYY") : dataValue.planned_mtr_date ? moment(dataValue.planned_mtr_date).format("DD-MM-YYYY") : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{" Mid Term Review Status : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.mtr_status ? dataValue.mtr_status : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Application Received : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue.appl_received ? dataValue.appl_received : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Mid Term Review Comments : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.mtr_comments ? dataValue.mtr_comments : "--"}</span>
                                                        </span>
                                                    </div>
                                                    <div className='pgProjectFourth flex-direction-column'>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Project Status : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.status ? dataValue.status : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"No. of Opening : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.no_of_openings ? dataValue.no_of_openings : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Final Review Date : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.actual_fr_date ? moment(dataValue.actual_fr_date).format("DD-MM-YYYY") : dataValue.revised_fr_date ? moment(dataValue.revised_fr_date).format("DD-MM-YYYY") : dataValue.planned_fr_date ? moment(dataValue.planned_fr_date).format("DD-MM-YYYY") : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Application Shortlisted by PG : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue.appl_shrtlted_by_pg ? dataValue.appl_shrtlted_by_pg : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Selected In Other Projects : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue.selected_in_other_projects ? dataValue.selected_in_other_projects : "--"}</span>
                                                        </span>
                                                        <span className="line-horizontal mt-1">
                                                            <span className='appcenter-app-name'>{"Final Review Comments : "} </span>
                                                            <span className='feedback-sm-text ml-5px'>{dataValue?.fr_comments ? dataValue.fr_comments : "--"}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='pgProjectFifth mt-1 mb-2'>
                                                    <span className='appcenter-app-name'>{"Description: "}</span>
                                                    <span className='feedback-sm-text ml-5px'>{dataValue.description}</span>
                                                    <div className='line-horizontal mt-1'></div>
                                                </div>
                                            </div>
                                        </>
                                    </span>
                                )
                                }
                                {/* -------------------Applicants button-------------------*/}
                                {(this.state.selectedProjectDtl[0]?.status === "Published") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: false, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by SPOC"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                                {(this.state.selectedProjectDtl[0]?.status === "Ongoing") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: false, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                                {(this.state.selectedProjectDtl[0]?.status === "Ongoing - MTR Initiated") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                                {(this.state.selectedProjectDtl[0]?.status === "Ongoing - MTR Closed") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                                {(this.state.selectedProjectDtl[0]?.status === "Ongoing - FR Initiated") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                                {(this.state.selectedProjectDtl[0]?.status === "Ongoing - FR Closed") &&
                                    <>
                                        {(this.state.selectedProjectDtl[0]?.is_active_applicants) &&
                                            <div className='justify-content-center mt-30px pb-10px'>
                                                <button
                                                    type="applicantView"
                                                    className='btn btn-primary login-btn py-2 w-15'
                                                    value="APPLICANTS"
                                                    disabled={false}
                                                    onClick={() => this.setState({ showApplicantList: true, showDetails: false, showProjects: false, hideStatusDrop: true, MTRFRKeyhandle: this.state.selectedProjectDtl[0]?.status }, () => this.getApplicantsFunction(this.state.selectedProjectDtl[0].id, "Shortlisted by PG"))}
                                                >
                                                    {buttonName.applicant}
                                                </button>
                                            </div>}
                                    </>
                                }
                            </div>
                        }
                        {/*----------------- show all applicant in table --------------------*/}
                        {this.state.showApplicantList &&
                            <>
                                <div className='overflow-x-auto h-49vh'>
                                    {this.state.applicantData?.length !== 0 ?
                                        <table>
                                            <thead>
                                                <tr>
                                                    {
                                                        Object.values(appliedApplicant).map((value, idx) =>
                                                            <th scope="col" className={`p-10px  ${idx === 0 ? "w-5" : idx === 7 ? null : "w-10"}  ${idx === 8 ? "text-align-center-noflex" : ""}`}
                                                                key={idx}
                                                            >
                                                                <div className='thead pr-0'>{value}</div>
                                                            </th>
                                                        )
                                                    }
                                                    {/* ------- Dynamic lable and value for MTR status ----------- */}
                                                    {this.state.MTRFRKeyhandle === "Ongoing - MTR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - MTR Closed" || this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - FR Closed" || this.state.MTRFRKeyhandle === "Completed" ?
                                                        <th scope="col" className='p-10px w-5'>
                                                            <div className='thead pr-0'>{projectFormConstant.mtrStatus}</div>
                                                        </th>
                                                        :
                                                        null
                                                    }
                                                    {/* ------- Dynamic lable and value for FR status ----------- */}
                                                    {this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - FR Closed" || this.state.MTRFRKeyhandle === "Completed" ?
                                                        <th scope="col" className='p-10px w-5'>
                                                            <div className='thead pr-0'>{projectFormConstant.frStatus}</div>
                                                        </th>
                                                        :
                                                        null
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.applicantData?.length !== 0 &&
                                                    this.state.applicantData.map((projectData, id) =>
                                                        <tr className='line-horizontal' key={id}>
                                                            <td className="td w-5 pt-10px padding-left-10">
                                                                {id + 1}
                                                            </td>
                                                            <td className="td w-5 pt-10px padding-left-10">
                                                                {this.state.selectedProjectDtl[0].title}
                                                            </td>
                                                            {tableTitles.map((value, idx) =>
                                                                <Fragment key={idx}>
                                                                    {
                                                                        idx === 0 ?
                                                                            <td key={idx} className={`td pt-10px w-10 padding-left-10`}>
                                                                                {projectData.applicant.Perno ?? '-'}</td>
                                                                            :
                                                                            idx === 1 ?
                                                                                <td key={idx} className={`td pt-10px w-10 padding-left-10`}>
                                                                                    {projectData.applicant.CompName ?? '-'}</td>
                                                                                :
                                                                                idx === 2 ?
                                                                                    <td key={idx} className={`td pt-10px w-10 padding-left-10`}>
                                                                                        {projectData.applicant.Esgtxt ?? '-'}</td>
                                                                                    :
                                                                                    idx === 3 ?
                                                                                        <td key={idx} className={`td pt-10px w-10 padding-left-10`}>
                                                                                            {projectData.applicant.LocDesc ?? '-'}</td>
                                                                                        :
                                                                                        idx === 4 ?
                                                                                            <td key={idx} className={`td pt-10px w-10 padding-left-10`}>
                                                                                                {projectData.applicant.Orgtx ?? '-'}</td>
                                                                                            :
                                                                                            <td key={idx} className={`td pt-10px w-10  ${idx === 8 ? "text-align-center-noflex " : "padding-left-10"} `}>
                                                                                                {projectData[value]}</td>
                                                                    }
                                                                </Fragment>
                                                            )}
                                                            <td className='td w-5 pt-10px padding-left-20'>
                                                                {/* appraval poup open action button */}
                                                                {this.state.MTRFRKeyhandle === "Published" &&
                                                                    <>
                                                                        {this.state.keyHandleByViewBtn === "Shortlisted by SPOC" ?
                                                                            <>
                                                                                {projectData.is_approval_active ?
                                                                                    <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                        onClick={() => this.approvalConfirm(projectData, "approvalConfirmation")}
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        <OverlayTrigger placement="left" overlay={this.renderSFTooltip(this.props)}>
                                                                                            <img
                                                                                                alt="infoicon"
                                                                                                src={infoicon}
                                                                                                className="admin-gems-info-icon"
                                                                                            ></img>
                                                                                        </OverlayTrigger>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                onClick={() => this.approvalConfirm(projectData, "pgViewDetails")}
                                                                            />
                                                                        }
                                                                    </>
                                                                }
                                                                {this.state.MTRFRKeyhandle === "Ongoing" &&
                                                                    <>
                                                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                            onClick={() => this.approvalConfirm(projectData, "pgViewDetails")}
                                                                        />
                                                                    </>
                                                                }
                                                                {/* //-----For MTR/FR View action, dont remove this code-----/// */}
                                                                {/* Individual MTR */}
                                                                {this.state.MTRFRKeyhandle === "Ongoing - MTR Initiated" &&
                                                                    <>
                                                                        {projectData.mtr_status === null ?
                                                                            < img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                onClick={() => this.doIndividualMTR(projectData, constTypes.indMTR)}
                                                                            />
                                                                            :
                                                                            <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                onClick={() => this.doIndividualMTR(projectData, "pgMTRFRViewDetails")}
                                                                            />
                                                                        }
                                                                    </>
                                                                }
                                                                {this.state.MTRFRKeyhandle === "Ongoing - MTR Closed" &&
                                                                    <>
                                                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                            onClick={() => this.doIndividualMTR(projectData, "pgMTRFRViewDetails")}
                                                                        />
                                                                    </>
                                                                }
                                                                {/* Individual FR */}
                                                                {this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" &&
                                                                    <>
                                                                        {projectData.fr_status === null ?
                                                                            <>
                                                                                {projectData.mtr_status !== "Dropped" ?
                                                                                    <>
                                                                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                            onClick={() => this.doIndividualFR(projectData, constTypes.indFR)}
                                                                                        />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                            onClick={() => this.doIndividualFR(projectData, "pgMTRFRViewDetails")}
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                                onClick={() => this.doIndividualFR(projectData, "pgMTRFRViewDetails")}
                                                                            />
                                                                        }
                                                                    </>
                                                                }
                                                                {this.state.MTRFRKeyhandle === "Ongoing - FR Closed" &&
                                                                    <>
                                                                        <img alt="view" src={view_action} className='m-5px table-action-button '
                                                                            onClick={() => this.doIndividualFR(projectData, "pgMTRFRViewDetails")}
                                                                        />
                                                                    </>
                                                                }
                                                            </td>
                                                            {/* //-----For MTR/FR Check Tick-----/// */}
                                                            {(this.state.MTRFRKeyhandle === "Ongoing - MTR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - MTR Closed" || this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - FR Closed" || this.state.MTRFRKeyhandle === "Completed") && projectData.mtr_status !== null ?
                                                                <td className='text-align-center-noflex pt-10px w-5'>
                                                                    <img alt="view" src={CheckGreenIcon} className='m-5px w-15px' />
                                                                </td> : null
                                                            }
                                                            {(this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - FR Closed" || this.state.MTRFRKeyhandle === "Completed") && projectData.fr_status !== null ?
                                                                <td className='text-align-center-noflex pt-10px w-5'>
                                                                    <img alt="view" src={CheckGreenIcon} className='m-5px w-15px' />
                                                                </td>
                                                                :
                                                                <>
                                                                    {(this.state.MTRFRKeyhandle === "Ongoing - FR Initiated" || this.state.MTRFRKeyhandle === "Ongoing - FR Closed") && projectData.mtr_status === "Dropped" ?
                                                                        <td className='text-align-center-noflex pt-10px w-5'>
                                                                            {projectData.mtr_status !== "Dropped" ?
                                                                                null
                                                                                :
                                                                                <>
                                                                                    {"-"}
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                            }
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                        :
                                        <>
                                            <span className='d-flex justify-content-center align-item-center mt-10p'>
                                                <NewNotdata />
                                            </span>
                                        </>
                                    }
                                    {/* -------Back button----------- */}
                                </div>
                                <div className='flex-direction-row justify-content-end p'>
                                    <CancelButton
                                        type={'button'}
                                        value={'BACK'}
                                        onClick={() => this.setState({ showApplicantList: false, showDetails: true, statusValue: "Pending" })}
                                    />
                                    {/* ------ Close MTR ------- */}
                                    {this.state.selectedProjectDtl[0].is_active_close_mtr &&
                                        <>
                                            {nullCount === 0 ?
                                                <SuccessButton
                                                    type={'button'}
                                                    value={constTypes.closeMTR}
                                                    onClick={() => this.doCloseMTR()}
                                                />
                                                :
                                                <SuccessButton
                                                    type={'button'}
                                                    value={constTypes.closeMTR}
                                                    isDisable={'disable'}
                                                />
                                            }
                                        </>
                                    }
                                    {/* ------ Close FR ------- */}
                                    {this.state.selectedProjectDtl[0].is_active_close_fr &&
                                        <>{finalFRCount === 0 ?
                                            <SuccessButton
                                                type={'button'}
                                                value={constTypes.closeFR}
                                                onClick={() => this.doCloseFR()}
                                            />
                                            :
                                            <SuccessButton
                                                type={'button'}
                                                value={constTypes.closeFR}
                                                isDisable={'disable'}
                                            />
                                        }
                                        </>
                                    }
                                </div>
                            </>
                        }
                        {/* ---------add new project popup ------------------*/}
                        {
                            !this.state.isFormClosed &&
                            <FormPopup
                                type={constTypes.gemsProject}
                                onDismiss={() => this.setState({ isFormClosed: true })}
                                submitAddProject={(data, obj) => this.submitAddProject(data, obj)}
                                callAPI={(call) => this.addProjectApi(call)}
                            />
                        }
                        {
                            this.state.isActionOpened &&
                            <FormPopup
                                type={constTypes.gemsApprovePopUp}
                                reviewState={this.state.reviewState}
                                actionValue={this.state.actionValue}
                                onDismiss={() => this.setState({ isActionOpened: false })}
                            />
                        }
                        {/* ------------------MTR/FR read and editable form ------------------ */}
                        {
                            this.state.mtrFRShow &&
                            <FormPopup
                                type="mrtFR"
                                id={this.state.mtrFRShowData}
                                updateMTRFR={(data, obj) => this.updateMTRFR(data, obj)}
                                onDismiss={() => this.setState({ mtrFRShow: false })}
                                submit={(actionType, data) => this.updateProjectObj(actionType, data)}
                            />
                        }
                        {/* ------------------PG approve/reject form------------------ */}
                        {
                            this.state.isApprovalConfirmOpen && !this.state.closePopUp &&
                            <FormPopup
                                onDismiss={() => this.setState({ isApprovalConfirmOpen: false })}
                                data={this.state.applicantPopupData}
                                type={this.state.pgAction}
                                projectId={this.state.selectedProjectDtl}
                                message={(value) => this.setState({ message: value })}
                                closePopUp={(value) => this.setState({ closePopUp: value })}
                                actionType={(value) => this.setState({ actionType: value })}
                            />
                        }
                        {/* ----------------PG approve/reject warning page-------------- */}
                        {
                            this.state.closePopUp &&
                            <WarningPopup
                                onDismiss={() => this.setState({ closePopUp: false, isApprovalConfirmOpen: false })}
                                type={this.state.actionType}
                                message={this.state.message}
                                // isApprovalConfirmOpen={(value)=> console.log("isApprovalConfirmOpen value>>", value)}
                                isApprovalConfirmOpen={(value) => this.approvalByPG(value)}
                            />
                        }
                    </>
                    :
                    this.state.checked === 1 ?
                        <CompleteProject
                            data={this.state.completedProject}
                            view={(value) => this.setState({ isActionPopupOpen: value })}
                            selectedProject={(data) => this.setState({ selectedProject: data })}
                            detailsViewTitle={(data) => this.setState({ detailsViewTitle: data })}
                        />
                        :
                        this.state.checked === 2 &&
                        <ClosedProject
                            data={this.state.closedProject}
                            view={(value) => this.setState({ isActionPopupOpen: value })}
                            selectedProject={(data) => this.setState({ selectedProject: data })}
                            detailsViewTitle={(data) => this.setState({ detailsViewTitle: data })}
                        />
                }
                {/*----------Open Popup from Complete project through action button-----------*/}
                {this.state.isActionPopupOpen &&
                    <GemsConfirmPopup
                        type="project_detail_view"
                        appliedProjects={[this.state.selectedProject]}
                        detailsViewTitle={this.state.detailsViewTitle}
                        header="Project Details"
                        width="large"
                        onDismiss={() => this.setState({ isActionPopupOpen: false })}
                    />
                }
                {/* --------------for start project/mtr/fr-------------- */}
                {this.state.openPopup &&
                    <FormPopup
                        onDismiss={() => this.setState({ openPopup: false })}
                        data={[this.state.projectData]}
                        currentPorjectId={this.state.selectedProjectDtl[0].id}
                        type={this.state.type}
                        mtrViewData={this.state.mtrViewData}
                        statePass={(value) => this.setState({ projectState: value })}
                        message={(value) => this.setState({ message: value })}
                        reviewWarning={(value) => this.setState({ reviewWarning: value })}
                        reviewWarningForFinalReview={(value) => this.setState({ reviewWarningForFinalReview: value })}
                        startProjectData={(data, value) => this.setState({ startProjectDate: data }, () => this.startProjectApi(data, value))}
                        closeMTR={(data) => this.setState({ closeMTRData: data })}
                        selectedIndMTR={(data) => this.setState({ indMTRData: data }, () => this.callSelectMTR(this.state.selectedProjectDtl[0]?.id, this.state.projectData.id))}
                        selectedIndFR={(data) => this.setState({ indFRData: data }, () => this.callSelectFR(this.state.selectedProjectDtl[0]?.id, this.state.projectData.id))}
                    />
                }
                {/*--------------Confirmation Pop-Up for withdraw project -------------------*/}
                {this.state.isDeletePopupOPen &&
                    <GemsConfirmPopup
                        type="delete"
                        appliedProjects={[this.state.selectedProject]}
                        detailsViewTitle={this.state.detailsViewTitle}
                        header="Confirmation"
                        message="Note:  Are you sure you want to withdraw the project request."
                        onDismiss={() => this.setState({ isDeletePopupOPen: false })}
                        deleteAppication={() => this.deleteProject()}
                    />
                }
                {/*--------------WarningPopup For Start MTR review -------------------*/}
                {this.state.reviewWarning &&
                    <WarningPopup
                        onDismiss={() => this.setState({ reviewWarning: false })}
                        isEarlyStartMTR={this.state.isEarlyStartMTR}
                        dateData={this.state.dateData}
                        type={this.state.type}
                        message={this.state.message}
                        data={this.state.selectedProjectDtl}
                        projectState={this.state.projectState}
                        sumbit={() =>
                            (this.state.type === constTypes.startMTR || this.state.isEarlyStartMTR)  ?
                                this.callStartMTR(this.state.selectedProjectDtl[0]?.id)
                                :
                                this.state.type === constTypes.closeMTR ?
                                    this.callCloseMTR(this.state.selectedProjectDtl[0]?.id)
                                    :
                                    ""
                        }
                    />
                }
                {this.state.reviewWarningForFinalReview &&
                    <WarningPopup
                        onDismiss={() => this.setState({ reviewWarningForFinalReview: false })}
                        isEarlyStartFR={this.state.isEarlyStartFR}
                        dateData={this.state.dateData}
                        type={this.state.type}
                        message={this.state.message}
                        data={this.state.selectedProjectDtl}
                        projectState={this.state.projectState}
                        sumbit={() =>
                            (this.state.type === constTypes.startFTR || this.state.isEarlyStartFR) ?
                                this.callStartFR(this.state.selectedProjectDtl[0]?.id)
                                :
                                this.state.type === constTypes.closeFR ?
                                    this.callCloseFR(this.state.selectedProjectDtl[0]?.id)
                                    :
                                    ""
                        }
                    />
                }
                {this.state.completeProjectPopup &&
                    <WarningPopup
                        onDismiss={() => this.setState({ completeProjectPopup: false })}
                        type={this.state.type}
                        message={this.state.message}
                        data={this.state.selectedProjectDtl}
                        sumbit={() => this.completeProjectApi(this.state.selectedProjectDtl[0]?.id)}
                    />
                }
            </div>
        )
    }
}

export default GEMSProjects