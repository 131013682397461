import React, { Component } from 'react';
import '../../../employeeScreens/continuousFeedbackManagement/CFMHome.css';
import GoalScreen from '../directReportees/GoalScreen';
import LeadershipScreen from '../directReportees/LeadershipScreen';
import * as Config from '../../../../../constants/Config';
import { bindActionCreators } from 'redux';
import * as userActions from '../../../employeeScreens/login/LoginAction';
import sendicon from '../../../../../assets/images/cfm/sendicon.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import NudgePopup from './NudgePopUp';

toast.configure();
class SummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            todoSelect: 'Goal',
            loading: false,
            show: false,
            isGoalPopup: true,
            isLeadershipPopup: false,
            isSubmitPopup: false,
            leadersheepGoalsData: [],
            goalsData: [],
            noGoalSet: "",
            noLeadershipSet: "",
            applicationMode: '',
            disabledMessage: '',
            empIdOfReportee: this.props.cfm?.directReporteeDetails?.empId,

            directReporteeEmailID: '',
            managerEmailID: '',
            matrixManagerEmailID: ''
        }
    }

    componentDidMount() {
        this.getUserDetail()
        const empIdOfReportee = this.props.cfm.directReporteeDetails ? this.props.cfm.directReporteeDetails.empId : ''
        this.getGoalsDetails();
        // this.getLeadersheepGoals();
        // this.getCETData(empIdOfReportee)
    }

    // getCETData = (emp_id) => {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //     }
    //     fetchApiCall(Config.getUserAndManagerDetail, { "empId": emp_id }, headers, true)
    //         .then(res => {
    //             console.log('personalDetail', res)
    //             if (res.data.status_code === '200') {
    //                 // console.log('bbbb', res.data.user_details[0]);
    //             } else {
    //                 console.log('invalid credentials')
    //             }
    //         }).catch(err => {
    //             this.setState({
    //                 loading: false
    //             });
    //             console.log('error login')
    //         })
    // }

    getGoalsDetails = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.cfm.directReporteeDetails.empId,
            user_id: this.props.cfm.directReporteeDetails.userId,
            is_employee: false
        }
        fetchApiCall(Config.getEmployeeGoals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        goalsData: res.data.sf_goal_details ? res.data.sf_goal_details : [],
                        applicationMode: res.data.application_modes,
                        disabledMessage: res.data.message,
                    });
                    const tempGoalData = this.state.goalsData.map(tempValue => ({ ...tempValue, commentDescription: '', isCommentShow: false }))
                    this.setState({ goalsData: tempGoalData })
                }
                else if (res.data.status_code === '201') {
                    this.setState({
                        loading: false,
                        noGoalSet: res.data.message
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        goalsData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    goalsData: []
                });
            })
    }

    getLeadersheepGoals = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.cfm.directReporteeDetails.empId,
            user_id: this.props.cfm.directReporteeDetails.userId,
            is_employee: false
        }
        fetchApiCall(Config.getEmployeeCompetencies, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: res.data.sf_competency_details ? res.data.sf_competency_details : [],
                        applicationMode: res.data.application_modes,
                        disabledMessage: res.data.message,
                    });
                    const tempGoalData = this.state.leadersheepGoalsData.map(tempValue => ({ ...tempValue, commentDescription: '', isCommentShow: false }))
                    this.setState({ leadersheepGoalsData: tempGoalData })
                }
                else if (res.data.status_code === '201') {
                    this.setState({
                        loading: false,
                        noLeadershipSet: res.data.message
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        leadersheepGoalsData: []
                    })
                }
            }).catch(err => {
                console.log('err--->', err)
                this.setState({
                    loading: false,
                    leadersheepGoalsData: []
                });
            })
    }

    getUserDetail = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.state.empIdOfReportee
        }
        fetchApiCall(Config.getSuccessFactorUserDetails, body, headers, true, '')
            .then(res => {
                if (res.data.status_code === '200') {
                    this.setState({
                        directReporteeEmailID: res.data.user_details.user_name ? res.data.user_details.user_name : '',
                        managerEmailID: res.data.user_details.manager.username ? res.data.user_details.manager.username : '',
                        matrixManagerEmailID: res.data.user_details.matrix_manager.username ? res.data.user_details.matrix_manager.username : '',
                        loading: false
                    })
                } else {
                    this.setState({
                        directReporteeEmailID: '',
                        managerEmailID: '',
                        matrixManagerEmailID: '',
                        loading: false
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    directReporteeEmailID: '',
                    managerEmailID: '',
                    matrixManagerEmailID: '',
                });
                console.log('err--->', err)
            })
    }

    showPop = (applicationMode, disabledMessage) => {
        if (applicationMode === 'write') {
            this.setState({ show: !this.state.show })
        }
        else {
            toast.error(disabledMessage, {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastError",
            });
        }
    }

    openPopUp = () => {
        this.setState({
            show: false
        })
    }

    render() {
        const { applicationMode, disabledMessage, empIdOfReportee, directReporteeEmailID, managerEmailID, matrixManagerEmailID } = this.state
        return (
            <div className="row justify-content-between align-item-center">
                <LoaderIndicator loading={this.state.loading} />
                <div className="cfm-card p-3 ml-3 mr-0">
                    <div className='d-flex justify-content-between'>
                        <div className='cfm_label'>Continuous Feedback</div>
                        {applicationMode === 'write' &&
                            <div
                                type='button'
                                className='d-flex justify-content-end p-0'
                            >
                                <img
                                    alt="sendicon"
                                    src={sendicon}
                                    className="send-img1 mt-2"
                                ></img>
                                <div
                                    type="button"
                                    className='send-text'
                                    onClick={() => this.showPop(applicationMode, disabledMessage)}
                                >
                                    Nudge For Feedback
                                </div>
                            </div>
                        }
                    </div>
                    <div className='row d-flex justify-content-center mt-3 mb-4'>
                        {this.state.todoSelect === 'Competency' ?
                            <>
                                <div className="row deactivate-button-left d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Goal' }, () => this.getGoalsDetails())}>Goals</div>
                                <div className="row activate-button-right d-flex justify-content-center" >Leadership Behaviours</div>
                            </>
                            :
                            <>
                                <div className="row activate-button-left d-flex justify-content-center">Goals</div>
                                <div className="row deactivate-button-right d-flex justify-content-center" onClick={() => this.setState({ todoSelect: 'Competency' }, () => this.getLeadersheepGoals())}>Leadership Behaviours</div>
                            </>
                        }
                    </div>
                    <div className=''>
                        {this.state.todoSelect === 'Goal' ?
                            <div>
                                {applicationMode === 'close' ?
                                    <div className='justify-content-center align-item-center' style={{ marginTop: '35%' }}>
                                        {disabledMessage}
                                    </div> :
                                    <GoalScreen
                                        getGoalsDetail={this.getGoalsDetails}
                                        data={this.state.goalsData}
                                        role={this.props.cfm.directReporteeDetails.internal_role === 'direct_reportees' ? 'DEM' : 'FEM'}
                                        noGoalSet={this.state.noGoalSet}
                                        applicationMode={applicationMode}
                                        empIdOfReportee={empIdOfReportee}
                                        directReporteeEmailID={directReporteeEmailID}
                                        managerEmailID={managerEmailID}
                                        matrixManagerEmailID={matrixManagerEmailID}
                                    />
                                }
                            </div>
                            :
                            <div>
                                {applicationMode === 'close' ?
                                    <div className='justify-content-center align-item-center' style={{ marginTop: '35%' }}>
                                        {disabledMessage}
                                    </div> :
                                    <LeadershipScreen
                                        getLeadersheepGoals={this.getLeadersheepGoals}
                                        data={this.state.leadersheepGoalsData}
                                        clickid={this.props.cfm.directReporteeDetails ? this.props.cfm.directReporteeDetails.empId : ''}
                                        role={this.props.cfm.directReporteeDetails.internal_role === 'direct_reportees' ? 'DEM' : 'FEM'}
                                        noLeadershipSet={this.state.noLeadershipSet}
                                        applicationMode={applicationMode}
                                        empIdOfReportee={empIdOfReportee}
                                        directReporteeEmailID={directReporteeEmailID}
                                        managerEmailID={managerEmailID}
                                        matrixManagerEmailID={matrixManagerEmailID}
                                    />
                                }
                            </div>
                        }
                    </div>
                </div>
                {this.state.show &&
                    <NudgePopup
                        Perno={this.props.loginData.userData.Perno}
                        CompName={this.props.loginData.userData.CompName}
                        clickid={this.props.clickid}
                        openPopUp={this.openPopUp}
                        type={"Nudge for Feedback"}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfm: state.cfmState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default (connect(mapStatesToProps, mapDispatchToProps)(SummaryCard));
