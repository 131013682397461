import React, { Component } from 'react';
import '../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as serviceActionToken from '../../employeeScreens/login/ServiceTokenAction';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from './NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from './NotificationConfirmPopup';
import Dropdown from '../../../shared/components/Dropdown';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import * as KeycloakActions from '../../employeeScreens/login/KeyCloakAction';
import * as userActions from '../../employeeScreens/login/LoginAction';
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const d = new Date();

class UpcomingOccasionsDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            listType: '',
            selectedData: {},
            showConfirm: false,
            showDelete: false,
            checked: 0,
            announcementType: '',
            upcomingOccasionsData: [],
            tempAnnouncementData: [],
            selectedAnnouncementId: '',
            requestData: [
                {
                    id: 1,
                    title: 'Active'
                },
                {
                    id: 2,
                    title: 'Inactive'
                }
            ],
            monthCode: d.getMonth() + 1,
            eventYear: new Date().getUTCFullYear()
        }
    }

    componentDidMount() {
        this.setState({
            listType: 'pending',
            announcementType: "All",
        },
            () => this.getAnnouncement()
        )
    }

    getAnnouncement = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_month: this.state.monthCode,
            event_year: this.state.eventYear,
        }
        fetchApiCall(Config.getUpcomingEvents, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        upcomingOccasionsData: res.data.data.upcoming_occasions,
                        loading: false,
                        message: '',
                    })
                } else {
                    this.setState({
                        loading: false,
                        upcomingOccasionsData: [],
                        show: true,
                        datapresent: false,
                        message: res.data.message
                    });
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
            })
    }

    deleteAnnouncement = () => {
        this.setState({
            loading: true,
            showDelete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_id: this.state.selectedAnnouncementId
        }
        fetchApiCall(Config.deleteUpcomingEvents, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.getAnnouncement()
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    onAction = (listData, type) => {
        if (type === 'view') {
            this.setState({
                viewPopup: true,
                tempAnnouncementData: listData
            })
        }
        else if (type === 'delete') {
            this.setState({
                showDelete: true,
                selectedAnnouncementId: listData.event_id,
            })
        }
        else {
            this.setState({
                doReject: true,
                tempAnnouncementData: listData
            })
        }
    }

    render() {
        const { showConfirm, viewPopup, showDelete } = this.state
        return (
            <div>
                <div className=" pt-2 pushNotification-container" >
                    {this.state.upcomingOccasionsData.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col"><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col"><div className='thead'>Title</div></th>
                                    <th scope="col"><div className='thead'>Date</div></th>
                                    <th scope="col"><div className='thead'>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.upcomingOccasionsData.map((listValue, index) => {
                                    return (
                                        <tr key={index} className=''>
                                            <td><div className="td">{index + 1}</div></td>
                                            <td><div className="td" >{listValue.event_title ? listValue.event_title : '-'}</div></td>
                                            <td><div className="td" >{listValue.created_datetime ? moment(listValue.created_datetime).format("DD-MM-YYYY") : '-'}</div></td>
                                            <td className='d-flex'>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'view')}
                                                >
                                                    <img alt="view" src={view_action} className='mx-2 table-action-button' />
                                                </div>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'delete')}
                                                >
                                                    <img alt="delete" src={delete_action} className='table-action-button' />
                                                </div>

                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column mt-10px'>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No leadership annoucements have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {showConfirm &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'delete'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete the transfer request?'}
                        deleteData={() => this.deleteTransferRequest()}
                        onDismiss={() => this.setState({ showConfirm: false })}
                    />
                }
                {viewPopup &&
                    <NotificationDetailsPopup
                        type={"upcomingOccasionsDetails"}
                        title={"Upcoming occasions Details"}
                        data={this.state.tempAnnouncementData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteAnnouncement'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Announcement?'}
                        deleteAnnouncement={() => this.deleteAnnouncement()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        serviceActionToken: bindActionCreators(serviceActionToken, dispatch),
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        actions: bindActionCreators(userActions, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(UpcomingOccasionsDashboard));