import React, { Component } from 'react';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import moment from 'moment';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import ConfirmPopup from '../../../managerScreens/teams/directReportees/ConfirmPopup';
import EsepTrackerDashboard from './EsepTrackerDashboard';
import ApprovalPopups from '../ApprovalPopups';
import Pagination from 'react-bootstrap/Pagination';
import { AddEsepRequest } from '../EseparationContants';
import radioOff from '../../../../../assets/drishti_images/radioOff.png';
import radioOn from '../../../../../assets/drishti_images/radioOn.png';
export class EseparationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            masterRequestData: [],
            separationRequestId: '',
            resignationRequestDate: '',
            lwdRequestedByEmployee: '',
            employeeSeparationRemark: '',
            employeeName: '',
            actualResignationDate: '',
            employeeSeparationReason: '',
            createdBy: '',
            noticePeriodDays: '',
            lwdJustificationEmployee: '',
            separationStatus: '',
            noticePayRecoveryAmount: '',
            isNoticePayWaveOffDem: false,
            isNoticePayWaveOffBhr: false,
            lwdJustificationDem: '',
            lwdJustificationBhr: '',
            waveOffJustificationBhr: '',
            waveOffJustificationDem: '',
            lwdRecommendedByBhr: '',
            lwdRecommendedByDem: '',
            demApprovalRemark: '',
            bhrApprovalRemark: '',
            buhrApprovalRemark: '',
            bhrRejectionRemark: '',
            bhrRejectionReason: '',
            demRejectionRemark: '',
            demRejectionReason: '',
            attachmentUrl: '',
            buhrName: '',
            bhrName: '',
            demName: '',
            isDelete: false,
            isManagerApprovedPopup: false,
            isView: false,
            lwdAsPerPolicy: '',
            actualLWDDate: '',
            radioFilters: [{ id: 1, title: 'Pending', }, { id: 2, title: 'Approved', }, { id: 3, title: 'Rejected', }],
            listType: '',
            checked: 0,
        }
    }

    componentDidMount() {
        if (this.props.isRequestActive === true) {
            this.props.toggleRequestsDetails()
        } else if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
            if (this.props.isEsepApprovalActive === true) {
                this.props.toggleApprove()
            } else if (this.props.isManagerApprovalActive === true) {
                this.props.toggleManagerApprovalsDetails()
            }
        } else if (this.props.isBHRApprovalActive === true) {
            this.props.toggleBHRApprovalsDetails()
        } else if (this.props.isBUHRApprovalActive === true) {
            this.props.toggleBUHRApprovalsDetails()
        } else if (this.props.isClearanceActive === true) {
            this.props.toggleClearanceDetails()
        }
        this.setState({ listType: 'Pending' })
    }

    componentWillReceiveProps(props) {
        if (this.props.isRequestActive) {
            this.setState({
                masterRequestData: props.requestsData
            })
        } else if (this.props.isEsepApprovalActive || this.props.isManagerApprovalActive) {
            this.setState({
                masterRequestData: props.approvalsRequestData
            })
        } else if (this.props.isBHRApprovalActive) {
            this.setState({
                masterRequestData: props.bhrEsepApprovalsData
            })
        } else if (this.props.isBUHRApprovalActive) {
            this.setState({
                masterRequestData: props.buHREsepApprovalsData
            })
        } else if (this.props.isClearanceActive) {
            this.setState({
                masterRequestData: props.clearanceData
            })
        }
    }

    onPresentType = (key, type) => {
        if (this.props.isRequestActive === true || (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) || this.props.isBHRApprovalActive === true || this.props.isBUHRApprovalActive == true) {
            if (this.props.isRequestActive === true) {
                this.setState({
                    checked: key,
                    listType: type
                })
                this.props.getEsepRequestCall(type)
                this.props.searchEmpIdFunction(type)
            }
            else if (this.props.isEsepApprovalActive === true || this.props.isManagerApprovalActive === true) {
                this.setState({
                    checked: key,
                    listType: type
                })
                this.props.getDemEsepApprovals(type)
                this.props.searchEmpIdFunction(type)
            }
            else if (this.props.isBHRApprovalActive === true) {
                this.setState({
                    checked: key,
                    listType: type
                })
                this.props.getBhrEsepApprovals(type)
                this.props.searchEmpIdFunction(type)
            }
            else if (this.props.isBUHRApprovalActive === true) {
                this.setState({
                    checked: key,
                    listType: type
                })
                this.props.getBuhrEsepApprovals(type)
                this.props.searchEmpIdFunction(type)
            }
        }
    }

    onActionDelete = (listValue) => {
        this.setState({
            separationRequestId: listValue.separation_request_id,
            isDelete: true
        })
    }

    onActionView = (listValue) => {
        if (this.props.isEsepApprovalActive && listValue.separation_status === 'Pending' || this.props.isManagerApprovalActive && listValue.separation_status === 'Pending') {
            this.setState({
                //---------Aprovals and tracking dashboard popup--------------
                isManagerApprovedPopup: true,
                isView: false,
                //---------Respective request all data retrived here------------
                separationRequestId: listValue.separation_request_id,
                lwdAsPerPolicy: listValue.lwd_as_per_policy,
                actualLWDDate: listValue.actual_resignation_date,
                resignationRequestDate: listValue.resignation_request_date,
                lwdRequestedByEmployee: listValue.lwd_requested_by_employee,
                employeeSeparationRemark: listValue.employee_separation_remark,
                employeeName: listValue.employee_name,
                actualResignationDate: listValue.actual_resignation_date,
                employeeSeparationReason: listValue.employee_separation_reason,
                createdBy: listValue.created_by,
                noticePeriodDays: listValue.notice_period_days,
                lwdJustificationEmployee: listValue.lwd_justification_employee,
                separationStatus: listValue.separation_status,
                isNoticePayWaveOffBhr: listValue.is_notice_pay_wave_off_bhr,
                isNoticePayWaveOffDem: listValue.is_notice_pay_wave_off_dem,
                attachmentUrl: listValue.attachment_url,
                noticeSeriodShortfallDays: listValue.notice_period_shortfall_days,
                noticePayRecoveryAmount: listValue.notice_pay_recovery_amount,
                lwdJustificationDem: listValue.lwd_justification_dem,
                lwdJustificationBhr: listValue.lwd_justification_bhr,
                waveOffJustificationBhr: listValue.wave_off_justification_bhr,
                waveOffJustificationDem: listValue.wave_off_justification_dem,
                lwdRecommendedByBhr: listValue.lwd_recommended_by_bhr,
                lwdRecommendedByDem: listValue.lwd_recommended_by_dem,
                demApprovalRemark: listValue.dem_approval_remark,
                bhrApprovalRemark: listValue.bhr_approval_remark,
                buhrApprovalRemark: listValue.buhr_approval_remark,
                bhrRejectionRemark: listValue.bhr_rejection_remark,
                bhrRejectionReason: listValue.bhr_rejection_reason,
                demRejectionRemark: listValue.dem_rejection_remark,
                demRejectionReason: listValue.dem_rejection_reason,
                demName: listValue.dem_name,
                bhrName: listValue.bhr_name,
                buhrName: listValue.buhr_name,
            })
        } else if (this.props.isBHRApprovalActive && listValue.separation_status === 'Approved by DEM') {
            this.setState({
                //---------Aprovals and tracking dashboard popup--------------
                isManagerApprovedPopup: true,
                isView: false,
                //---------Respective request all data retrived here------------
                separationRequestId: listValue.separation_request_id,
                lwdAsPerPolicy: listValue.lwd_as_per_policy,
                actualLWDDate: listValue.actual_resignation_date,
                resignationRequestDate: listValue.resignation_request_date,
                lwdRequestedByEmployee: listValue.lwd_requested_by_employee,
                employeeSeparationRemark: listValue.employee_separation_remark,
                employeeName: listValue.employee_name,
                actualResignationDate: listValue.actual_resignation_date,
                employeeSeparationReason: listValue.employee_separation_reason,
                createdBy: listValue.created_by,
                noticePeriodDays: listValue.notice_period_days,
                lwdJustificationEmployee: listValue.lwd_justification_employee,
                separationStatus: listValue.separation_status,
                isNoticePayWaveOffBhr: listValue.is_notice_pay_wave_off_bhr,
                isNoticePayWaveOffDem: listValue.is_notice_pay_wave_off_dem,
                attachmentUrl: listValue.attachment_url,
                noticeSeriodShortfallDays: listValue.notice_period_shortfall_days,
                noticePayRecoveryAmount: listValue.notice_pay_recovery_amount,
                lwdJustificationDem: listValue.lwd_justification_dem,
                lwdJustificationBhr: listValue.lwd_justification_bhr,
                waveOffJustificationBhr: listValue.wave_off_justification_bhr,
                waveOffJustificationDem: listValue.wave_off_justification_dem,
                lwdRecommendedByBhr: listValue.lwd_recommended_by_bhr,
                lwdRecommendedByDem: listValue.lwd_recommended_by_dem,
                demApprovalRemark: listValue.dem_approval_remark,
                bhrApprovalRemark: listValue.bhr_approval_remark,
                buhrApprovalRemark: listValue.buhr_approval_remark,
                bhrRejectionRemark: listValue.bhr_rejection_remark,
                bhrRejectionReason: listValue.bhr_rejection_reason,
                demRejectionRemark: listValue.dem_rejection_remark,
                demRejectionReason: listValue.dem_rejection_reason,
                demName: listValue.dem_name,
                bhrName: listValue.bhr_name,
                buhrName: listValue.buhr_name,
            })
        } else if (this.props.isBUHRApprovalActive && listValue.separation_status === 'Approved by BHR and Updated') {
            this.setState({
                //---------Aprovals and tracking dashboard popup--------------
                isManagerApprovedPopup: true,
                isView: false,
                //---------Respective request all data retrived here------------
                separationRequestId: listValue.separation_request_id,
                lwdAsPerPolicy: listValue.lwd_as_per_policy,
                actualLWDDate: listValue.actual_resignation_date,
                resignationRequestDate: listValue.resignation_request_date,
                lwdRequestedByEmployee: listValue.lwd_requested_by_employee,
                employeeSeparationRemark: listValue.employee_separation_remark,
                employeeName: listValue.employee_name,
                actualResignationDate: listValue.actual_resignation_date,
                employeeSeparationReason: listValue.employee_separation_reason,
                createdBy: listValue.created_by,
                noticePeriodDays: listValue.notice_period_days,
                lwdJustificationEmployee: listValue.lwd_justification_employee,
                separationStatus: listValue.separation_status,
                isNoticePayWaveOffBhr: listValue.is_notice_pay_wave_off_bhr,
                isNoticePayWaveOffDem: listValue.is_notice_pay_wave_off_dem,
                attachmentUrl: listValue.attachment_url,
                noticeSeriodShortfallDays: listValue.notice_period_shortfall_days,
                noticePayRecoveryAmount: listValue.notice_pay_recovery_amount,
                lwdJustificationDem: listValue.lwd_justification_dem,
                lwdJustificationBhr: listValue.lwd_justification_bhr,
                waveOffJustificationBhr: listValue.wave_off_justification_bhr,
                waveOffJustificationDem: listValue.wave_off_justification_dem,
                lwdRecommendedByBhr: listValue.lwd_recommended_by_bhr,
                lwdRecommendedByDem: listValue.lwd_recommended_by_dem,
                demApprovalRemark: listValue.dem_approval_remark,
                bhrApprovalRemark: listValue.bhr_approval_remark,
                buhrApprovalRemark: listValue.buhr_approval_remark,
                bhrRejectionRemark: listValue.bhr_rejection_remark,
                bhrRejectionReason: listValue.bhr_rejection_reason,
                demRejectionRemark: listValue.dem_rejection_remark,
                demRejectionReason: listValue.dem_rejection_reason,
                demName: listValue.dem_name,
                bhrName: listValue.bhr_name,
                buhrName: listValue.buhr_name,
            })
        } else {
            this.setState({
                //---------Aprovals and tracking dashboard popup--------------
                isManagerApprovedPopup: false,
                isView: true,
                //---------Respective request all data retrived here------------
                separationRequestId: listValue.separation_request_id,
                lwdAsPerPolicy: listValue.lwd_as_per_policy,
                actualLWDDate: listValue.actual_resignation_date,
                resignationRequestDate: listValue.resignation_request_date,
                lwdRequestedByEmployee: listValue.lwd_requested_by_employee,
                employeeSeparationRemark: listValue.employee_separation_remark,
                employeeName: listValue.employee_name,
                actualResignationDate: listValue.actual_resignation_date,
                employeeSeparationReason: listValue.employee_separation_reason,
                createdBy: listValue.created_by,
                noticePeriodDays: listValue.notice_period_days,
                lwdJustificationEmployee: listValue.lwd_justification_employee,
                separationStatus: listValue.separation_status,
                isNoticePayWaveOffBhr: listValue.is_notice_pay_wave_off_bhr,
                isNoticePayWaveOffDem: listValue.is_notice_pay_wave_off_dem,
                attachmentUrl: listValue.attachment_url,
                noticeSeriodShortfallDays: listValue.notice_period_shortfall_days,
                noticePayRecoveryAmount: listValue.notice_pay_recovery_amount,
                lwdJustificationDem: listValue.lwd_justification_dem,
                lwdJustificationBhr: listValue.lwd_justification_bhr,
                waveOffJustificationBhr: listValue.wave_off_justification_bhr,
                waveOffJustificationDem: listValue.wave_off_justification_dem,
                lwdRecommendedByBhr: listValue.lwd_recommended_by_bhr,
                lwdRecommendedByDem: listValue.lwd_recommended_by_dem,
                demName: listValue.dem_name,
                bhrName: listValue.bhr_name,
                buhrName: listValue.buhr_name,
                demApprovalRemark: listValue.dem_approval_remark,
                bhrApprovalRemark: listValue.bhr_approval_remark,
                buhrApprovalRemark: listValue.buhr_approval_remark,
                bhrRejectionRemark: listValue.bhr_rejection_remark,
                bhrRejectionReason: listValue.bhr_rejection_reason,
                demRejectionRemark: listValue.dem_rejection_remark,
                demRejectionReason: listValue.dem_rejection_reason,

            })
        }
    }

    //-------------------------Delete e-Separation Requests APIs------------------------------
    deleteEsepRequest = () => {
        this.setState({
            loading: true,
            isDelete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            separation_request_id: this.state.separationRequestId ? this.state.separationRequestId : '',
        }
        fetchApiCall(Config.deleteEsepRequest, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.getEsepRequestCall('Pending')
                } else {
                    this.setState({
                        loading: false,
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                });
            })
    }

    render() {
        const { masterRequestData, isDelete, isView, buhrName, bhrName, demName, lwdRecommendedByDem, lwdRecommendedByBhr, buhrApprovalRemark, bhrApprovalRemark, demApprovalRemark, demRejectionReason, demRejectionRemark, bhrRejectionReason, bhrRejectionRemark, waveOffJustificationDem, waveOffJustificationBhr, lwdJustificationBhr, lwdJustificationDem, noticePayRecoveryAmount, noticeSeriodShortfallDays, isManagerApprovedPopup, lwdJustificationEmployee, separationStatus, noticePeriodDays, lwdAsPerPolicy, createdBy, employeeSeparationReason, actualResignationDate, employeeName, employeeSeparationRemark, lwdRequestedByEmployee, resignationRequestDate, separationRequestId, isNoticePayWaveOffBhr, isNoticePayWaveOffDem, attachmentUrl } = this.state
        const { isRequestActive, isEsepApprovalActive, isManagerApprovalActive, isBHRApprovalActive, isBUHRApprovalActive, relationshipData, isClearanceActive } = this.props
        const list = this.state.listType;
        return (
            <div>
                <LoaderIndicator loading={this.props.loading} />
                {isRequestActive || (isEsepApprovalActive || isManagerApprovalActive) || isBHRApprovalActive || isBUHRApprovalActive ?
                    <div>
                        <>
                            {!isClearanceActive &&
                                <div className='d-flex row justify-content-between'>
                                    <div className='d-flex row justify-content-start mt-3'>
                                        <div className='status-dashboard ml-4 mt-2'>{'Status :'}</div>
                                        {this.state.radioFilters.length != 0 && this.state.radioFilters.map((list, key) => {
                                            return (
                                                <div key={key} className='mx-4 mt-1 mb-2 '>
                                                    {this.state.checked === key ?
                                                        <div onClick={() => this.onPresentType(key, list.title)}>
                                                            <img alt="radioOn" className='radio' src={radioOn} />
                                                            <label className="radioTxt" >{list.title}</label>
                                                        </div>
                                                        :
                                                        <div onClick={() => this.onPresentType(key, list.title)}>
                                                            <img alt="radioOff" className='radio' src={radioOff} />
                                                            <label className="radioTxt">{list.title}</label>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <>
                                        {this.props.isApplicable ?
                                            <div div className='pointer row add-btn-text flex-row-reverse p-11px'
                                                onClick={() => this.props.initiateEsepRequest()}>
                                                {AddEsepRequest}
                                            </div> : null
                                        }
                                    </>
                                    {(isEsepApprovalActive || isManagerApprovalActive) || isBHRApprovalActive || isBUHRApprovalActive ?
                                        <div className='d-flex row justify-content-end align-item-center mleft-15'>
                                            <div className='p-placeholder-text widthMonth'>
                                                <input
                                                    className="form-control p-placeholder px-3"
                                                    placeholder="Enter Employee ID"
                                                    type="number"
                                                    onChange={(e) => this.props.handleChange(e.target.value)}
                                                />
                                            </div>
                                            <div type='button' className='common-save-btn save-txt'
                                                onClick={() => this.props.searchEmpIdFunction(this.state.listType)}
                                            >{'SEARCH'}
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            }
                        </>
                        {masterRequestData.length != 0 ?
                            <>
                                <div className='col-md-12 flex-direction-column mt-1'>
                                    <div className='scroll-y h-42vh'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><div className='thead pr-0 w-35px'>{'Sr No.'}</div></th>
                                                    <th scope="col"><div className='thead'>{'Employee ID'}</div></th>
                                                    {!isRequestActive &&
                                                        <th scope="col"><div className='thead'>{'Employee Name'}</div></th>}
                                                    <th scope="col"><div className='thead'>{'Resignation Request Date'}</div></th>
                                                    <th scope="col"><div className='thead'>{'Actual Date of Resignation'}</div></th>
                                                    <th scope="col"><div className='thead'>{'Notice Period Days'}</div></th>
                                                    {(!isEsepApprovalActive || !isManagerApprovalActive) &&
                                                        <th scope="col"><div className='thead'>{'Reason'}</div></th>
                                                    }
                                                    {isRequestActive &&
                                                        <th scope="col"><div className='thead'>{'Status'}</div></th>
                                                    }
                                                    {isRequestActive &&
                                                        <th scope="col"><div className='thead'>{'Pending With'}</div></th>
                                                    }
                                                    <th scope="col"><div className='thead ml-06rem'>{'Action'}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {masterRequestData.map((listValue, index) => {
                                                    return (
                                                        <tr key={index} >
                                                            <td><div className="td w-20px">{index + 1}</div></td>
                                                            <td> <div className="td d-flex">{listValue.employee_id ? listValue.employee_id : '-'}</div> </td>
                                                            {!isRequestActive &&
                                                                <td> <div className="td d-flex">{listValue.employee_name ? listValue.employee_name : '-'}</div> </td>}
                                                            <td> <div className="td d-flex">{listValue.resignation_request_date ? moment(listValue.resignation_request_date).format("DD-MM-YYYY") : '-'}</div> </td>
                                                            <td> <div className="td d-flex">{listValue.actual_resignation_date ? moment(listValue.actual_resignation_date).format("DD-MM-YYYY") : '-'}</div> </td>
                                                            <td><div className="td">{listValue.notice_period_days ? listValue.notice_period_days : '-'}</div></td>
                                                            {(!isEsepApprovalActive || !isManagerApprovalActive) &&
                                                                <td> <div className="td d-flex">{listValue.employee_separation_reason ? listValue.employee_separation_reason : '-'}</div> </td>
                                                            }
                                                            {isRequestActive &&
                                                                <td><div className="td">{listValue.separation_status ? listValue.separation_status : '-'}</div></td>}
                                                            {isRequestActive &&
                                                                <td><div className="td">
                                                                    {listValue.separation_status === 'Pending' ?
                                                                        listValue.dem_name + ' (Manager)' :
                                                                        listValue.separation_status === 'Approved by DEM' ?
                                                                            listValue.bhr_name + ' (BHR)' : 'NA'
                                                                    }
                                                                </div></td>}
                                                            <td className='d-flex'>
                                                                <div onClick={() => this.onActionView(listValue)}>
                                                                    <img alt="view" src={view_action} className='pointer mx-2' />
                                                                </div>
                                                                {listValue.separation_status === 'Pending' && isRequestActive && !isEsepApprovalActive &&
                                                                    <div onClick={() => this.onActionDelete(listValue)}>
                                                                        <img alt="delete" src={delete_action} className='pointer' />
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </>
                            :
                            <div className='col-md-12 justify-content-center align-item-center mb-5 mt-5'>
                                {'No Records Found'}
                            </div>
                        }
                        {/* ----------------------------Pagination model-------------------------------- */}
                        {!isRequestActive &&
                            <>
                                {masterRequestData.length != 0 ?
                                    <div className="col-md-12 w-100 d-flex justify-content-center">
                                        <Pagination>
                                            <Pagination.Prev
                                                onClick={() => this.props.handlePageCount("decrement", list)} />
                                            {this.props.count.length > 1 &&
                                                <Pagination.Item onClick={() => this.props.selectedPage(1, list)}>{1}</Pagination.Item>}<Pagination.Ellipsis />
                                            <Pagination.Item active>{this.props.page}</Pagination.Item><Pagination.Ellipsis />
                                            {this.props.count.length > 1 &&
                                                <Pagination.Item onClick={() => this.props.selectedPage(this.props.count[this.props.count.length - 1], list)}>{this.props.count[this.props.count.length - 1]}</Pagination.Item>}
                                            <Pagination.Next
                                                onClick={() => this.props.handlePageCount("increment", list)} />
                                        </Pagination>
                                    </div> : null}
                            </>}
                    </div> : null
                }
                {/* ----------------------------Popups model called-------------------------------- */}
                {isDelete &&
                    <ConfirmPopup
                        loading={this.props.loading}
                        title={'Delete Confirmation'}
                        action={'deleteEsepRequest'}
                        content={'Are you sure you want to delete the e-separation request?'}
                        deleteEsepData={() => this.deleteEsepRequest()}
                        onDismiss={() => this.setState({ isDelete: false })}
                    />
                }
                {isView &&
                    <EsepTrackerDashboard
                        title={'e-Separation Requests Dashboard'}
                        action={'viewEsepRequest'}
                        approvalRequestData={masterRequestData}
                        onDismiss={() => this.setState({ isView: false })}
                        separationRequestId={separationRequestId}
                        resignationRequestDate={resignationRequestDate}
                        lwdRequestedByEmployee={lwdRequestedByEmployee}
                        employeeSeparationRemark={employeeSeparationRemark}
                        employeeName={employeeName}
                        actualResignationDate={actualResignationDate}
                        employeeSeparationReason={employeeSeparationReason}
                        createdBy={createdBy}
                        lwdAsPerPolicy={lwdAsPerPolicy}
                        noticePeriodDays={noticePeriodDays}
                        lwdJustificationEmployee={lwdJustificationEmployee}
                        separationStatus={separationStatus}
                        isNoticePayWaveOffBhr={isNoticePayWaveOffBhr}
                        isNoticePayWaveOffDem={isNoticePayWaveOffDem}
                        relationshipData={relationshipData}
                        isRequestActive={isRequestActive}
                        isEsepApprovalActive={isEsepApprovalActive}
                        isManagerApprovalActive={isManagerApprovalActive}
                        isBUHRApprovalActive={isBUHRApprovalActive}
                        isBHRApprovalActive={isBHRApprovalActive}
                        lwdRecommendedByDem={lwdRecommendedByDem}
                        lwdRecommendedByBhr={lwdRecommendedByBhr}
                        waveOffJustificationDem={waveOffJustificationDem}
                        waveOffJustificationBhr={waveOffJustificationBhr}
                        lwdJustificationBhr={lwdJustificationBhr}
                        lwdJustificationDem={lwdJustificationDem}
                        getBuhrEsepApprovals={this.props.getBuhrEsepApprovals}
                        getBhrEsepApprovals={this.props.getBhrEsepApprovals}
                        getDemEsepApprovals={this.props.getDemEsepApprovals}
                        payRecoveryPopup={this.props.payRecoveryPopup}
                        toatalDaysCount={this.props.toatalDaysCount}
                        noticeSeriodShortfallDays={noticeSeriodShortfallDays}
                        noticePayRecoveryAmount={noticePayRecoveryAmount}
                        demName={demName}
                        bhrName={bhrName}
                        buhrName={buhrName}
                        demApprovalRemark={demApprovalRemark}
                        buhrApprovalRemark={buhrApprovalRemark}
                        bhrApprovalRemark={bhrApprovalRemark}
                        demRejectionReason={demRejectionReason}
                        demRejectionRemark={demRejectionRemark}
                        bhrRejectionReason={bhrRejectionReason}
                        bhrRejectionRemark={bhrRejectionRemark}
                    />
                }
                {isManagerApprovedPopup &&
                    <ApprovalPopups
                        onDismiss={() => this.setState({ isManagerApprovedPopup: false })}
                        approvalRequestData={masterRequestData}
                        isManagerApprovedPopup={isManagerApprovedPopup}
                        isEsepApprovalActive={isEsepApprovalActive}
                        isManagerApprovalActive={isManagerApprovalActive}
                        isBUHRApprovalActive={isBUHRApprovalActive}
                        isBHRApprovalActive={isBHRApprovalActive}
                        lwdAsPerPolicy={this.state.lwdAsPerPolicy}
                        actualLWDDate={this.state.actualLWDDate}
                        separationRequestId={this.state.separationRequestId}
                        resignationRequestDate={resignationRequestDate}
                        lwdRequestedByEmployee={lwdRequestedByEmployee}
                        employeeSeparationRemark={employeeSeparationRemark}
                        employeeName={employeeName}
                        actualResignationDate={actualResignationDate}
                        employeeSeparationReason={employeeSeparationReason}
                        createdBy={createdBy}
                        noticePeriodDays={noticePeriodDays}
                        lwdJustificationEmployee={lwdJustificationEmployee}
                        separationStatus={separationStatus}
                        attachmentUrl={attachmentUrl}
                        noticeSeriodShortfallDays={noticeSeriodShortfallDays}
                        noticePayRecoveryAmount={noticePayRecoveryAmount}
                        isNoticePayWaveOffBhr={isNoticePayWaveOffBhr}
                        isNoticePayWaveOffDem={isNoticePayWaveOffDem}
                        demName={demName}
                        bhrName={bhrName}
                        buhrName={buhrName}
                        lwdRecommendedByDem={lwdRecommendedByDem}
                        lwdRecommendedByBhr={lwdRecommendedByBhr}
                        waveOffJustificationDem={waveOffJustificationDem}
                        waveOffJustificationBhr={waveOffJustificationBhr}
                        lwdJustificationBhr={lwdJustificationBhr}
                        lwdJustificationDem={lwdJustificationDem}
                        demApprovalRemark={demApprovalRemark}
                        buhrApprovalRemark={buhrApprovalRemark}
                        bhrApprovalRemark={bhrApprovalRemark}
                        demRejectionReason={demRejectionReason}
                        demRejectionRemark={demRejectionRemark}
                        bhrRejectionReason={bhrRejectionReason}
                        bhrRejectionRemark={bhrRejectionRemark}
                        payRecoveryPopup={this.props.payRecoveryPopup}
                        toatalDaysCount={this.props.toatalDaysCount}
                        getBuhrEsepApprovals={this.props.getBuhrEsepApprovals}
                        getBhrEsepApprovals={this.props.getBhrEsepApprovals}
                        getDemEsepApprovals={this.props.getDemEsepApprovals}
                        toggleApprove={this.props.toggleApprove}
                        toggleManagerApprovalsDetails={this.props.toggleManagerApprovalsDetails}
                        toggleBHRApprovalsDetails={this.props.toggleBHRApprovalsDetails}
                        toggleBUHRApprovalsDetails={this.props.toggleBUHRApprovalsDetails}
                    />
                }
            </div >
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(EseparationDashboard);