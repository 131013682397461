import React, { Component } from 'react';
import '../continuousFeedbackManagement/CFMHome.css';
import Performance from '../continuousFeedbackManagement/Performance';
import Feedback from '../continuousFeedbackManagement/Feedback';
import { connect } from 'react-redux';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import { checkActivePoup, checkPopupCount } from '../../../../utils/CheckActivePopup';
import DynamicCustomPopupModal from '../../../shared/dynamicCustomPopum/DynamicCustomPopup'
import * as homeActions from '../landingScreen/ActivePopupAction';
import { bindActionCreators } from 'redux';
class CFMHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            screenwidth: 0,
            screenheight: 0,
            filter: 'All',
            performanceData: [],
            applicationMode: '',
            disabledMessage: '',

            // is_service_Unavailable: false,  // service unavailble from backend
            // service_reason: '',

            isActive: false,
            message: '',
            note_content: '',
            note_title: '',
            note_type: '',
            image_url: '',
            image_hyperlink: '',
        }
    }

    componentDidMount() {
        this.getPerformaceTimeline(this.state.filter)
        this.checkActivePoupDetails()
        // this.checkService()
    }

    checkActivePoupDetails() {
        var activeDetails = checkActivePoup('cfm');
        this.setState({
            isActive: activeDetails && checkPopupCount('cfm') ? true : false,
            message: activeDetails ? activeDetails : '',
            note_content: activeDetails.note_content ? activeDetails.note_content : '',
            note_title: activeDetails.note_title ? activeDetails.note_title : '',
            note_type: activeDetails.note_type ? activeDetails.note_type : '',
            image_url: activeDetails.image_url ? activeDetails.image_url : '',
            image_hyperlink: activeDetails.image_hyperlink ? activeDetails.image_hyperlink : '',
        }, () => {
            if (this.state.isActive) {
                this.props.homeActions.getActivePopupCount('cfm')
            }
        })
    }

    hideModal = () => { this.setState({ isActive: !this.state.isActive }) }

    // checkService = () => {
    //     this.props.serviceData.availableServiceData.forEach((item, i) => {
    //         if (item.service_type == "cfm" && item.is_active == false) {
    //             this.setState({
    //                 is_service_Unavailable: true,
    //                 service_reason: item.reason
    //             })
    //         }
    //     })
    // }

    getPerformaceTimeline = (filter) => {
        // this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            user_id: this.props.loginData.cfmUserDetails.length != 0 ?
                this.props.loginData.cfmUserDetails.user_details.user_id : '',
            filter: filter
        }
        fetchApiCall(Config.getPerformaceTimeline, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        performanceData: res.data.timeline_data ? res.data.timeline_data : [],
                        applicationMode: res.data.application_modes,
                        disabledMessage: res.data.message
                    });
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                console.log("getPerformaceTimeline err", err)
                this.setState({
                    loading: false,
                });
            })
    }

    render() {
        return (
            <div>
                <div>
                    <div className="justify-content-between">
                        <LoaderIndicator loading={this.state.loading} />
                        <div className="col-md-12" >
                            <div className="center-body-color">

                                <div className="row pt-12px">
                                    <div className="col-md-6 px-0">
                                        <Performance
                                            performanceData={this.state.performanceData}
                                            getPerformanceData={(filter) => this.getPerformaceTimeline(filter)}
                                            applicationMode={this.state.applicationMode}
                                            disabledMessage={this.state.disabledMessage}
                                        />
                                    </div>
                                    <div className="col-md-6 px-0 feedback">
                                        <Feedback
                                            getPerformanceData={(filter) => this.getPerformaceTimeline(filter)}
                                            applicationMode={this.state.applicationMode}
                                            disabledMessage={this.state.disabledMessage}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isActive &&
                    <DynamicCustomPopupModal
                        loading={this.state.isActive}
                        note_title={this.state.note_title}
                        note_content={this.state.note_content}
                        note_type={this.state.note_type}
                        image_url={this.state.image_url}
                        image_hyperlink={this.state.image_hyperlink}
                        message={this.state.message}
                        onDismiss={this.hideModal} />
                }
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        homeActions: bindActionCreators(homeActions, dispatch)
    };
}

export default (connect(mapStatesToProps, mapDispatchToProps)(CFMHome));
