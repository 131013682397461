import React, { Component } from 'react';
import plus from '../../../../../assets/drishti_images/back.svg';
import document from '../../../../../assets/drishti_images/documents/document.svg';
import incometax from '../../../../../assets/drishti_images/documents/incometax.svg';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import nodataicon from '../../../../../assets/drishti_images/nodatafound/document-nodata.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { DocumentSkeleton } from '../../../../shared/skeleton/Skeletons';
import * as Constant from '../../../../../constants/Messages';
import { connect } from 'react-redux';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import { commonDownloadFile } from '../../../../shared/CommonDownloadFunction';

class ManagerDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            isDataPresent: true,
            latestMonthPayslipData: [],
            latestITSheetData: [],

            is_service_Unavailable: false,
            leave_Service_reason: ''
        }
    }

    componentDidMount() {
        this.getMonthDocuments()
        this.checkService();
    }

    async checkService() {
        // console.log("checkService called :", this.props.serviceData.availableServiceData)
        const result = await this.props.serviceData.availableServiceData;
        // console.log("checkservice called result", result)
        // console.log("checkservice this.props.loginData.serviceEnableDisableData", this.props.loginData.serviceEnableDisableData)

        this.props.loginData.serviceEnableDisableData.forEach((item, i) => {
            // console.log("serviceData item ", item)
            if (item.service_type == "document_center" && item.is_active == false) {
                console.log("checkService leave inside :")
                this.setState({
                    is_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
        })
    }

    getDownloadDocuments(path) {
        this.setState({ loading: true })
        let body = {
            path: path
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getMonthlyPayslipData, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res)
                if (res.data.status_code == 200) {
                    this.setState({
                        url: res.data.data.url ? res.data.data.url : '',
                        loading: false,
                    })
                    this.actualDownload(this.state.url);
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    });
                }

            }).catch(err => {
                console.log("getDownloadDocuments error : ", err)
                this.setState({
                    loading: false
                });
                toast.error('something went wrong', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }
    actualDownload(url) {
        commonDownloadFile(url, 'Your document has been downloaded. Use your PAN number to open the file.')
    }

    goToDocument = () => {
        window.location = (BASE_WEB_URL_DEV + 'document');
    }

    getMonthDocuments = () => {
        this.setState({
            loading: true
        });
        const body = { emp_id: this.props.loginData.userData.Perno }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getAvailableLatestDocument, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        latestMonthPayslipData: res.data.data.payslip_month_list ? res.data.data.payslip_month_list : [],
                        latestITSheetData: res.data.data.it_sheet_month_list ? res.data.data.it_sheet_month_list : [],
                        loading: false
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                console.log("getMonthDocuments error : ", err)
                this.setState({
                    loading: false
                });
                toast.error(Constant.requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    render() {
        const { loading } = this.state
        return (
            <div className="card-new new-height" >
                <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Document</label>
                        </div>
                        <div onClick={this.goToDocument}>
                            <img alt="Back" src={plus} className="righticon"></img>
                        </div>
                    </div>
                </div>
                {this.state.is_service_Unavailable ?
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                            <ServiceUnavailable reason={this.state.leave_Service_reason} />
                        </div>
                    </div>
                    :
                    <div>
                        {loading ?
                            <>
                                <div className='p-1'>
                                    <DocumentSkeleton />
                                    <DocumentSkeleton />
                                </div>
                            </>
                            :
                            <>
                                {
                                    this.state.latestITSheetData.length != 0 && this.state.latestMonthPayslipData.length != 0 ?
                                        <div
                                            className='row mb-2'
                                        // className={window.innerWidth < 600 ? 'row justify-content-center mb-10px' : ''}
                                        >
                                            <div className="col-md-12 col-5 col-sm-5"
                                                onClick={() => this.getDownloadDocuments(this.state.latestMonthPayslipData[0].path)}
                                            >
                                                <div className="d-flex justify-content-center mt-2 mb-3">
                                                    <img alt="document" src={document} className="document-logo mr-2"></img>
                                                    <div className="align-self-center document-text mr-4">
                                                        Payslip
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row d-flex justify-content-center col-md-12 col-sm-1 col-1">
                                                <div className="mid-line w-75 h-20% mx-3"></div>
                                            </div>

                                            <div className="col-md-12 col-5 col-sm-5 row" onClick={() => this.getDownloadDocuments(this.state.latestITSheetData[0].path)}>
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-center mt-3 mb-10px">
                                                        <img alt="document" src={incometax} className="incometax-logo ml-1"></img>
                                                        <div className=" align-self-center incometax-text ml-2">
                                                            Income tax worksheet
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        this.state.latestMonthPayslipData.length != 0 ?
                                            <div className="row col-md-12 d-flex justify-content-center landing-document-height"
                                                onClick={() => this.getDownloadDocuments(this.state.latestMonthPayslipData[0].path)}
                                            >
                                                <img alt="document" src={document} className="document-single-logo mr-2 align-self-center"></img>
                                                <div className="align-self-center document-text mr-4">
                                                    Payslip
                                                </div>
                                            </div>
                                            :
                                            this.state.latestITSheetData.length != 0 ?
                                                <div className="row col-md-12 d-flex justify-content-center landing-document-height"
                                                    onClick={() => this.getDownloadDocuments(this.state.latestITSheetData[0].path)}
                                                >
                                                    <img alt="document" src={incometax} className="align-self-center incometax-single-logo ml-1"></img>
                                                    <div className=" align-self-center incometax-text ml-2">
                                                        Income tax worksheet
                                                    </div>
                                                </div>
                                                :
                                                <div className='row'>
                                                    <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                                        <div>
                                                            <img src={nodataicon} alt="Nodata" />
                                                        </div>
                                                        <div>
                                                            <label className='card-content-title grey-color'>No files found</label>
                                                        </div>
                                                    </div>
                                                </div>
                                }
                            </>
                        }
                    </div>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(ManagerDocuments);
