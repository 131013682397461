import React, { Component } from 'react';
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import * as Config from "../../../../constants/Config";
import { toast } from "react-toastify";
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import NoDescription from '../../../../assets/drishti_images/nodatafound/NoDescriptionWithCircle.svg'
class LACopyRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            leadership_behaviours_description: []
        }
    }
    // componentDidMount() {
    //     this.getleadershipbehaviourdescription((this.props.title).trim().split(/ +/).join(' '))
    // }
    // getleadershipbehaviourdescription = (title) => {
    //     this.setState({
    //         loading: true,
    //     });
    //     let body = {
    //         emp_id: this.props.empID,
    //         leadership_behaviours_title: title.trim()    //   title 'AGILITY'  title
    //     };
    //     const headers = {
    //         "Content-Type": "application/json",
    //     };
    //     fetchApiCall(Config.getleadershipbehaviourdescription, body, headers, true, "")
    //         .then((res) => {
    //             sessionOut(this.props, res);
    //             if (res.data.status_code === "200") {
    //                 this.setState({
    //                     loading: false,
    //                     data: res.data.result ? res.data.result : [],
    //                     leadership_behaviours_description: res.data.result.leadership_behaviours_description
    //                 });
    //             } else {
    //                 this.setState({
    //                     loading: false,
    //                     goalCommentsData: [],
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             this.setState({
    //                 loading: false,
    //             });
    //             toast.error("error", {
    //                 position: "bottom-center",
    //                 hideProgressBar: true,
    //                 className: "toastError",
    //             });
    //         });
    // };
    render() {
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="declaration-box p-0 " style={{ width: '40%' }}>
                    <div className="modal-header justify-content-center mt-2 ml-2">
                        <h4 className="modal_title black">Title</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>

                    {/* {this.state.leadership_behaviours_description.length !== 0 ?
                        <div className="modal-body p-3">
                            <div className='p-3 mx-3'>
                                <div className='announcement-pin-content-bold'>
                                    {data.leadership_behaviours_title}
                                </div>
                                <div className='form-label mb-10 mt-5px'>
                                    {data.leadership_behaviours_heading}
                                </div>
                                <div className='flex-direction-row'>
                                    <div className='document-innercard-content-title'
                                        style={{ 'marginLeft': '5px', 'marginRight': '5px' }}>
                                        Level
                                    </div>
                                    <div className='document-innercard-content-title'
                                        style={{ 'marginLeft': '25px', 'marginRight': '5px' }}>
                                        Descriptor
                                    </div>
                                </div>
                                <div style={{ 'maxHeight': '52vh', 'overflowY': 'auto' }}>
                                    {this.state.leadership_behaviours_description.map((dataValue, id) =>
                                        <div key={id} className='flex-direction-row ml-5px leadershipPopupInnerContainer'>
                                            <div className="leadershipPopupContent-text leadershipPopupLevel" >{(dataValue.leadership_level).match(/\d+/g)}</div>
                                            <div className="leadershipPopupContent-text leadershipPopupDescription">{dataValue.leadership_behaviours_description}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="modal-body p-3 text-align-center mb-2">
                            <div className='flex-col align-items-center pb-20px pt-20px'>
                                <div>
                                    <img src={NoDescription} alt="Nodata" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color' >No discription available </label>
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div >
        );
    }
}
export default LACopyRight;