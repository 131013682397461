import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { dynamic_screen_type } from '../../../../utils/Utils';
import { requestFailed } from '../../../../constants/Messages';
class ScreenPopUpNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeScreenData: dynamic_screen_type(),
            noteTypeData: [{ value: 'Image' }, { value: 'Text' }],
            screenPopupImageName: '',
            screenPopupNotificationImage: '',
            loading: false,
            dynamicPopupImageName: '',
            noteTitle: '',
            noteContent: '',
            screenTypeCode: '',
            screenTypeValue: 'Select',
            noteType: 'Select',
            dynamicPopuphyperlink: '',
            fromDate: '',
            toDate: '',
            isActive: true,
            notificationID: '',
        }
    }

    componentDidMount() {
        if (this.props.data) {
            this.setState({
                screenTypeValue: this.props.data.screen_type,
                noteTitle: this.props.data.note_title,
                noteType: this.props.data.note_type,
                fromDate: this.props.data.from_date,
                toDate: this.props.data.to_date,
                noteContent: this.props.data.note_content,
                dynamicPopuphyperlink: this.props.data.image_hyperlink,
                screenTypeCode: this.props.data.screen_type,
                notificationID: this.props.data.notification_id,
            })
        }
    }

    validate = () => {
        let end_date = new Date(this.state.toDate);
        let start_date = new Date(this.state.fromDate);
        if (this.state.screenTypeCode === 'Select') {
            toast.warning('Please select screen type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        } else if (this.state.noteTitle === '') {
            toast.warning('Please select Note title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        } else if (this.state.noteType === 'Select note type' || this.state.noteType == '') {
            toast.warning('Please select Note type', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        } else if (this.state.fromDate === '') {
            toast.warning('Please Enter start date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        } else if (this.state.toDate === '') {
            toast.warning('Please Enter end date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        } else if (start_date > end_date) {
            toast.warning('Start date cannot exceed to date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            // console.log("this.props.Action", this.props.Action)
            this.insertPopupNotification(this.props.Action)
        }
    }

    insertPopupNotification = (action) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            image_name: this.state.dynamicPopupImageName,
            screen_type: this.state.screenTypeCode,
            note_title: this.state.noteTitle,
            note_content: this.state.noteContent,
            note_type: this.state.noteType,
            image_url: this.state.screenPopupNotificationImage,
            image_hyperlink: this.state.dynamicPopuphyperlink,
            from_date: this.state.fromDate,
            to_date: this.state.toDate,
            is_active: this.state.isActive,
            action: action,
            notification_id: this.state.notificationID
        }
        // console.log("insertPopupNotification body:", body)
        fetchApiCall(Config.insertPopUpNotificationBychro, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false })
                    // this.props.updateData()
                    setTimeout(() => { window.location.reload() }, 1000)
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    // fileSelectHandler = (event) => {
    //     this.setState({ screenPopupImageName: event.target.files[0] ? event.target.files[0].name : '' })
    //     if (event.target.files && event.target.files[0]) {
    //         let maxAllowedSize = 2097152;           // 2MB size
    //         if (event.target.files[0].size <= maxAllowedSize) {
    //             let reader = new FileReader();
    //             reader.readAsDataURL(event.target.files[0]);
    //             reader.onload = (e) => { this.setState({ screenPopupNotificationImage: e.target.result }) }
    //         }
    //         else {
    //             toast.warn('Please upload image less than 2MB', {
    //                 position: "bottom-center",
    //                 hideProgressBar: true,
    //                 className: 'toastWarn'
    //             })
    //             this.setState({ screenPopupNotificationImage: '' })
    //         }
    //     }
    //     else { this.setState({ screenPopupNotificationImage: '' }) }
    // }

    fileSelectHandler = (event) => {
        this.setState({ dynamicPopupImageName: event.target.files[0] ? event.target.files[0].name : '' })
        let re = /(?:\.([^.]+))?$/;
        // var ext = re.exec("file.name.with.dots.txt")[1];
        console.log("ext:", re.exec(event.target.files[0])[1])
        if (event.target.files && event.target.files[0]) {
            let maxAllowedSize = 2097152;           // 2MB size
            if (event.target.files[0].size <= maxAllowedSize) {
                let reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (e) => {
                    this.setState({
                        screenPopupNotificationImage: e.target.result,
                    })
                }
            }
            else {
                toast.warn('Please upload image less than 2MB', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
                this.setState({ screenPopupNotificationImage: '' })
            }
        }
        else { this.setState({ screenPopupNotificationImage: '' }) }
    }

    changeNoteType = (data) => {
        this.setState({ noteType: data.value })
    }

    changeNotificationType = (data) => {
        this.setState({
            screenTypeCode: data.code,
            screenTypeValue: data.value,
        })
    }

    render() {
        const { close, back, data } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{this.props.NotificationType}</h4>
                            {/* <button type="button" className="close" onClick={close}>&times;</button> */}
                        </div>
                        <div className="">
                            <form className='todoModalBody popup_scroller w-100'>
                                {/* <label className='form-label ml-2 mt-2'>Notification Type :
                                    <span className='form-label-notification-bold mx-1'>{NotificationType}</span>
                                </label> */}
                                <div className='p-1 w-100'>
                                    <div>
                                        <div>
                                            <div className='p-sub-heading ml-3 mb-1'>Screen Type</div>
                                            <div className='p-placeholder-text mb-2'>
                                                <Dropdown
                                                    data={this.state.typeScreenData}
                                                    selectedValue={(data) => this.changeNotificationType(data)}
                                                    value={this.state.screenTypeValue}
                                                    render={'value'}
                                                    height={'NotificationHeight'}
                                                />
                                            </div>
                                        </div>
                                        <div className='p-sub-heading ml-3 mb-1'>Title</div>
                                        <div className='p-placeholder-text mb-2'>
                                            <input
                                                className="form-control p-placeholder px-2 p-placeholder-admin"
                                                type="text"
                                                placeholder='Enter the title'
                                                onChange={(e) => this.setState({ noteTitle: e.target.value })}
                                                value={this.state.noteTitle}
                                            />
                                        </div>
                                        <div>
                                            <div className='p-sub-heading ml-3 mb-1'>Note Type</div>
                                            <div className='p-placeholder-text mb-2'>
                                                <Dropdown
                                                    data={this.state.noteTypeData}
                                                    selectedValue={(data) => this.changeNoteType(data)}
                                                    value={this.state.noteType}
                                                    render={'value'}
                                                    height={'dependOnContent'}
                                                />
                                            </div>
                                        </div>
                                        {this.state.noteType === 'Image' &&
                                            <div>
                                                <div>
                                                    <div className='p-sub-heading ml-3 mb-1'>Image</div>
                                                    {!this.props.show &&
                                                        <div className='p-placeholder-text mb-2'>
                                                            <input
                                                                className="form-control p-placeholder  p-placeholder-admin px-2"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={this.fileSelectHandler}
                                                            />
                                                            <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                                                        </div>
                                                    }
                                                </div>
                                                {this.state.screenPopupImageName != '' && <div className="d-flex w-100 mb-2"><i className="nav-icon cui-speedometer"></i>
                                                    <img className="d-flex w-100" src={this.state.screenPopupNotificationImage} alt={"image"} />
                                                </div>}
                                            </div>
                                        }
                                        <div className='row justify-content-between'>
                                            <div className='mb-3 mt-2'>
                                                <div className='p-sub-heading ml-3'>Start Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder p-placeholder-admin px-2"
                                                        type="date"
                                                        value={this.state.fromDate}
                                                        onChange={(e) => this.setState({ fromDate: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3 mt-2'>
                                                <div className='p-sub-heading ml-3'>End Date</div>
                                                <div className='p-placeholder-text'>
                                                    <input
                                                        className="form-control p-placeholder p-placeholder-admin px-2"
                                                        type="date"
                                                        value={this.state.toDate}
                                                        onChange={(e) => this.setState({ toDate: e.target.value })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='p-sub-heading ml-3 mb-1'>Hyperlink</div>
                                        <div className='p-placeholder-text mb-2'>
                                            <input
                                                className="form-control p-placeholder p-placeholder-admin px-2"
                                                type="text"
                                                value={this.state.dynamicPopuphyperlink}
                                                onChange={(e) => this.setState({ dynamicPopuphyperlink: e.target.value })}
                                                placeholder={"Hyperlink"}
                                            />
                                        </div>
                                        <div className='p-sub-heading ml-3'>Note Content</div>
                                        <div className='p-placeholder-text'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                maxLength={1000}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ noteContent: e.target.value })}
                                                value={this.state.noteContent}
                                                placeholder={"Enter the note content"}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                                </div>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={back} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='ADD'
                                            className='popup-confirm-btn'
                                            onClick={this.validate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScreenPopUpNotifications;