import React, { Component } from 'react';
import '../../profile/ProfileScreen.css';
import Adhar from './Adhar'
import Pan from './Pan';
import Passport from './Passport';
import UAN from './UAN';

class DocumentDetails extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <Adhar />
                    <Pan />
                    <Passport />
                    <UAN />
                </div>
            </div>
        );
    }
}

export default DocumentDetails;