import React, { Component } from 'react';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/react-circular-progressbar/dist/styles.css';
import { connect } from 'react-redux';
import { BASE_WEB_URL_DEV } from './constants/Config.js';
import IdleTimer from 'react-idle-timer';
import * as Config from './constants/Config';
import { configStore } from "./redux/ConfigStore";
import { fetchApiCall, sessionOut } from './constants/Service';
import { emptyCache } from './utils/Catche'
import { RenderRoutes } from "./navigation/NavigationScreens";
class App extends Component {
	constructor(props) {
		super(props)
		this.idleTimer = null
		this.handleOnIdle = this.handleOnIdle.bind(this)
	}

	componentDidMount() {
		emptyCache()
		if (this.props.adminLoginData.isAdminLoggedIn == true) {
			if(window.location.pathname.split('/')[1] !== "admin"){
				window.location = (BASE_WEB_URL_DEV + 'admin');
			}
		}
		else if(this.props.loginData.isLoggedIn == true){
			if(window.location.pathname.split('/')[1] === "admin"){
				window.location = (BASE_WEB_URL_DEV + 'login');
			}
		}
	}
	handleOnIdle(event) {
		this.logoutApi(this.props.loginData.userData.Perno)
	}
	logoutApi = () => {
		const refreshToken = configStore.getState().keycloakState.keycloakData.access_token ?
			configStore.getState().keycloakState.keycloakData.access_token.refresh_token : '';
		const headers = {
			'Content-Type': 'application/json',
		}
		const body = {
			empId: configStore.getState().loginState.userData.Perno,
			refresh_token: refreshToken
		}
		fetchApiCall(Config.logout, body, headers, true, '')
			.then(res => {
				sessionOut(this.props, res);
				window.localStorage.clear();
				window.location = (BASE_WEB_URL_DEV + 'login');
			}).catch(err => {
				console.log("logout err");
				window.localStorage.clear();
				window.location = (BASE_WEB_URL_DEV + 'login');
			})
	}

	render() {
		// console.log("process.env.REACT_APP_COMMON_BASE_URL:", process.env)
		// console.log("configStore.getState() app:", configStore.getState())
		// console.log("window:",window.location.pathname.split('/')[1])
		return (
			<div className="">
				{/* outer-container */}
				<IdleTimer
					ref={ref => { this.idleTimer = ref }}
					timeout={1000 * 600}
					onIdle={this.handleOnIdle}
					debounce={250}
				/>
				<RenderRoutes />
			</div>
		);
	}
}

const mapStatesToProps = (state) => {
	return {
		loginData: state.loginState,
		keycloakData: state.keycloakState,
		adminLoginData: state.adminLoginState
	}
}

export default connect(mapStatesToProps, {})(App);