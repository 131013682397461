export const NoEsepRequest = `You have not submitted any e-Separation request.`;
export const EsepEligibilityNote = `Please note that E-sep functionality is currently not mapped to your level. Kindly connect with the BHR.`;
export const Title = `e-Separation Form`;
export const AddEsepRequest = `+ Initiate e-Separation Request`;
export const AddFormTitle = `Employee e-Separation Request`;
export const ManagerViewTitle = `e-Separation Request Details`;
export const ApprovalConfirmation = `Approval Confirmation`;
export const RejectionConfirmation = `Rejection Confirmation`;
export const PayRecoveryTitle = `Notice Pay recovery`;
export const FileUploadSizeNote = `You can upload png, jpg, pdf, and msg file upto 2MB only`;
export const ActualDateWarning = `Discliamer : Please ensure you put actual date of resignation in discussion with manager`;
export const PayRecoveryNote = `Notice pay recovery details`;
export const RejectRequest = `Do you want to reject the e-separation requests`;
export const BUHRRejectRequest = `Do you want to reject the notice pay waiver request?`;
export const PopupConfirmationNote = `Do you want to proceed and submit`;
export const WaveOffApproval = `Do you want to recommend the waive off?`;
export const CharLimit150 = `Enter the justification min 150 characters`;
export const CharLimit300 = `Enter the justification min 300 characters`;
export const ManagerWaveOffApproval = `Do you want to approve the notice pay recovery waive off recommended by manager?`;

export const GuidelineTitle = `Guidelines on e-Separation`;
export const GuidelineHeader = `Welcome to e-Separation portal on Drishti 2.0.`;
export const Point1 = `1. You are encouraged to have discussion with your manager before you initiate e-separation request and document the same on mail.`;
export const Point2 = `2. While writing the mail it is recommended that the below stakeholders are addressed:`;
export const Point3 = `3. You will need attach this email while initiating e-separation request as pdf or image.`;
export const SubPoint3 = `Notice period is to ensure continuity of business processes via transfer of knowledge and smooth handover of responsibilities.`;
export const Point4 = `4. It is recommended to complete notice period post your request as per notice period policy.`;
export const Point5 = `5. You can check indicative notice period recovery purely based on Total Fixed Pay on the portal.`;
export const Point6 = `6. Leave quota overview is also available in employee detail section`;
export const Point7 = `7. You can connect with IT SERVICE DESK in case of any issues related to portal.`;
export const Point8 = `8. For any process related issue connect with your manager or BHR.`;

export const errMessage = 'Unable to contact server\nPlease try again later';
export const successDownload = 'Your document has been downloaded.';


