import React, { Component } from 'react';
import back from '../../../../assets/drishti_images/back.svg';
import ConfirmPopup from '../landing/ConfirmPopup';
import nodataicon from '../../../../assets/drishti_images/nodatafound/document-nodata.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from 'moment';
import approve from '../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../assets/drishti_images/manager/landing/reject.svg';
import * as Constant from '../../../../constants/Messages';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
class TransferApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isApprove: false,
            isReject: false,
            actionCode: '',
            actionValue: '',

            admin: true,
            loading: false,
            emp_id: '',
            isTransferRequestDashboard: true,
            adminRole: props.adminLoginData ? props.adminLoginData.adminData.user_role : [],
            rpoBhr: false,

            showConfirm: false,
            delete: false,
            selectedData: [],
            transferRequestStatus: '',
            transferTypeCode: '',
            transferRequestData: [],
            transferRequestDetails: {},
        }
    }

    componentDidMount() {
        this.getDashboardData()
        this.getbhrRole()
        this.getAppliedTransferRequestDAta('pending')
        this.setState({ transferTypeCode: 'pending' })
    }

    getbhrRole = () => {
        if (this.state.adminRole.length != 0) {
            let roleSet = this.state.adminRole
            if (roleSet.includes('rpo-bhr')) {
                this.setState({ rpoBhr: true })
            }
            else {
                this.setState({ rpoBhr: false })
            }
        }
    }

    getAppliedTransferRequestDAta = (type) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            request_status: type.toLowerCase()
        }
        fetchApiCall(Config.getEmployeeTransferRequest, body, headers, false, 'admin')
            .then(res => {
                console.log('getEmployeeTransferRequest--->', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        transferRequestData: res.data.transfer_request ? res.data.transfer_request : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        transferRequestData: [],
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(Constant.requestFailed, { hideProgressBar: true })
            })
    }

    approveRejectTransferRequest = () => {
        this.setState({
            loading: true, showConfirm: false
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            req_status: this.state.transferRequestStatus,
            transfer_request_unique_id: this.state.selectedData.transfer_request_unique_id
        }
        fetchApiCall(Config.approveRejectTransferRequest, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    this.getAppliedTransferRequestDAta('pending')
                    this.props.getDashboardData()
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    transferData = (data) => {
        this.setState({ transferRequestDetails: data })
    }

    getDashboardData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_mail: this.props.adminLoginData.adminData.user_email
        }
        fetchApiCall(Config.getTransferRequestCount, body, headers, false, 'admin')
            .then(res => {
                console.log('getTransferRequestCount', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        transferRequestCount: res.data.transfer_request_count ? res.data.transfer_request_count : [],
                    });
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({
                        loading: false,
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(Constant.requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    showModal = (data, type) => {
        this.setState({ showConfirm: true, selectedData: data, transferRequestStatus: type })
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    goTransfer = () => {
        window.location = (BASE_WEB_URL_DEV + 'admin/transfer')
    }

    render() {
        const { transferRequestStatus, showConfirm } = this.state
        // console.log('this.props.Trasfer------->>>>>>>', this.props.adminLoginData.emp_id);
        return (
            <div className="card-new h-100" >
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">Transfer Approvals</label>
                        </div>
                        <div
                            type='button'
                            onClick={this.goTransfer}>
                            <img alt="gotoAnnouncement" src={back} className="righticon"></img>
                        </div>
                    </div>
                </div>
                <div className="main-contain main-contain-child pt-2" >
                    <div className='sm-scroll-table px-4'>
                        {this.state.transferRequestData.length != 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                        <th scope="col"><div className='thead'>Employee Name</div></th>
                                        <th scope="col"><div className='thead'>Transfer Date</div></th>
                                        <th scope="col"><div className='thead'>Reason</div></th>
                                        <th scope="col"><div className='thead'>Location</div></th>
                                        <th scope="col"><div className='thead'>Approve/Reject</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transferRequestData.map((listValue, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td>
                                                    <div className="td" style={{ width: 20 }}>{index + 1}</div>
                                                </td>
                                                <td>
                                                    <div className="td" >{listValue.transfer_emp_name ? listValue.transfer_emp_name : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td" >{moment(listValue.proposed_date_of_transfer).format("DD-MM-YYYY")}</div>
                                                </td>
                                                <td>
                                                    <div className="td">{listValue.reason_for_transfer}</div></td>
                                                <td>
                                                    <div className="td">{listValue.location}</div></td>
                                                <td className='d-flex'>
                                                    <div onClick={() => this.showModal(listValue, 'approved')}>
                                                        <img alt="approve" src={approve} style={{ width: '25px' }} className='mx-2' />
                                                    </div>
                                                    <div onClick={() => this.showModal(listValue, 'rejected')}>
                                                        <img alt="reject" src={reject} style={{ width: '25px' }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                    <div>
                                        <img src={nodataicon} alt="Nodata" />
                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>No record found</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {(transferRequestStatus === 'rejected') && showConfirm &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={transferRequestStatus}
                        rejectData={this.approveRejectTransferRequest}
                        selectedData={this.state.selectedData}
                        onDismiss={() => this.setState({ transferRequestStatus: '', showConfirm: false })}
                    />
                }
                {(transferRequestStatus === 'approved') && showConfirm &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={transferRequestStatus}
                        approveData={this.approveRejectTransferRequest}
                        selectedData={this.state.selectedData}
                        onDismiss={() => this.setState({ transferRequestStatus: '', showConfirm: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(TransferApproval));
