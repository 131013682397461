import moment from "moment"

export const calcTotalDays = (todate, fromdate) => {
    let to_date = new Date(todate)
    let from_date = new Date(fromdate)
    let days = Math.round((to_date.getTime() - from_date.getTime()) / (1000 * 3600 * 24))
    return days
}
export const calcTotalTime = (totime, fromtime) => {
    const startTimeMoment = moment(fromtime, 'HH:mm');
    const endTimeMoment = moment(totime, 'HH:mm');
    const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    let formattedDifference;
    if (totime && fromtime) {
        formattedDifference = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
    }
    return [formattedDifference, hours]
}
export const addDays = (date, days) => {
    var NewDate = moment(date, "YYYY-MM-DD").add(days, 'days').format("YYYY-MM-DD")
    return NewDate
}
export const subtractDays = (date, days) => {
    var NewDate = moment(date, "YYYY-MM-DD").subtract(days, 'days').format("YYYY-MM-DD")
    return NewDate
}
export const handleValidation = (expenseDetails, selectedTravelType, setError, index) => {
    if (expenseDetails.expense_type == null) { setError("expense_type", 1, index); return false }
    else if ((expenseDetails.expense_type == "Transportation")) {
        if (expenseDetails.sub_expense_type1 == null || expenseDetails.sub_expense_type1 == "") { setError("sub_expense_type1", 1, index); return false }
        else if (expenseDetails.sub_expense_type1 == "Own vehicle") {
            if (expenseDetails.sub_expense_type2 == "" || expenseDetails.sub_expense_type2 == null) { setError("sub_expense_type2", 1, index); return false }
            else if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
            else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
            else if (expenseDetails.start_reading == "" || expenseDetails.start_reading == null) { setError("start_reading", 1, index); return false }
            else if (expenseDetails.end_reading == "" || expenseDetails.end_reading == null) { setError("end_reading", 1, index); return false }
            else if (parseInt(expenseDetails.end_reading) <= parseInt(expenseDetails.start_reading)) { setError("end_reading", 2, index); return false }
            else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
            else if ((expenseDetails.extra_expense_amount != null && expenseDetails.extra_expense_amount != "") && expenseDetails.bill_path == "" || expenseDetails.bill_path == null) { setError("bill_path", 1, index); return false }
            else { return true }
        }
        else if (expenseDetails.sub_expense_type1 != "Own vehicle") {
            if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
            else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
            else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
            else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
            else if (expenseDetails.bill_path == "" || expenseDetails.bill_path == null) { setError("bill_path", 1, index); return false }
            else { return true }
        }
    }
    else if (expenseDetails.expense_type == "Unbilled Expenses") {
        if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
        else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
        else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
        else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
        // else if (expenseDetails.bill_path == "" || expenseDetails.bill_path == null) { setError("bill_path", 1, index); return false }
        else { return true }
    }
    else if ((expenseDetails.expense_type == "Accommodation allowance")) {
        if (expenseDetails.sub_expense_type1 == null || expenseDetails.sub_expense_type1 == "") { setError("sub_expense_type1", 1, index); return false }
        else if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
        else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
        else if (expenseDetails.total_expense_days == "" || expenseDetails.total_expense_days == null) { setError("total_expense_days", 1, index); return false }
        else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
        else if (expenseDetails.permissible_expense_amount == "" || expenseDetails.permissible_expense_amount == null) { setError("permissible_expense_amount", 1, index); return false }
        else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
        else if (expenseDetails.bill_path == "" || expenseDetails.bill_path == null) { setError("bill_path", 1, index); return false }
        else { return true }
    }
    else if ((expenseDetails.expense_type == "Consolidated allowance")) {
        if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
        else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
        else if (expenseDetails.total_expense_days == "" || expenseDetails.total_expense_days == null) { setError("total_expense_days", 1, index); return false }
        else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
        else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
        else { return true }
    }
    else if ((expenseDetails.expense_type == "Daily allowance")) {
        if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
        else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
        else if (expenseDetails.total_expense_days == "" || expenseDetails.total_expense_days == null) { setError("total_expense_days", 1, index); return false }
        else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
        else if (expenseDetails.permissible_expense_amount == "" || expenseDetails.permissible_expense_amount == null) { setError("permissible_expense_amount", 1, index); return false }
        else if (expenseDetails.remark == "" || expenseDetails.remark == null) { setError("remark", 1, index); return false }
        else if (expenseDetails.bill_path == "" || expenseDetails.bill_path == null) { setError("bill_path", 1, index); return false }
        else { return true }
    }
    else if ((expenseDetails.expense_type == "Incidental allowance")) {
        if (expenseDetails.from_expense_date == "" || expenseDetails.from_expense_date == null) { setError("from_expense_date", 1, index); return false }
        else if (expenseDetails.to_expense_date == "" || expenseDetails.to_expense_date == null) { setError("to_expense_date", 1, index); return false }
        else if (expenseDetails.total_expense_days == "" || expenseDetails.total_expense_days == null) { setError("total_expense_days", 1, index); return false }
        else if (expenseDetails.claim_expense_amount == "" || expenseDetails.claim_expense_amount == null) { setError("claim_expense_amount", 1, index); return false }
        else if (selectedTravelType == "Intracity" && (expenseDetails.total_expense_time == "" || expenseDetails.total_expense_time == null)) { setError("total_expense_time", 1, index); return false }
        else { return true }
    }
    else { return true }
}