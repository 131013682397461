import React, { Component } from 'react'
import { instructions } from './Constant';
import { applicantInstructions } from './Gems.api.service';
import { toaster } from '../../../../utils/Utils';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import NewNotdata from '../../../shared/notFound/NewNoData';
class GEMSInstruction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            instructionData: [],
        }
    }

    componentDidMount() {
        this.getInstructions()
    }

    getInstructions = () => {
        this.setState({ loading: true })
        const param = {}
        applicantInstructions(param, this.props)
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        instructionData: res?.data?.data ? res?.data?.data : []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        instructionData: []
                    })
                    toaster("", res.message ? res.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    instructionData: []
                })
                toaster("", "Something went wrong")
                console.log("getInstructions", err)
            })
    }

    render() {
        const { instructionData, loading } = this.state;
        let instructionFlag = false;
        return (
            <div>
                <LoaderIndicator loading={loading} />
                <>
                    <div className='coi-text'>{instructions.whatIsGems}</div>
                    {instructionData.length !== 0 ?
                        <div className='scroll-y h-21vh ml-2 mt-2'>
                            {instructionData.map((listValue, index) => {
                                return (
                                    <div key={index}>
                                        <div className='row mr-4 mt-2'>
                                            {listValue.line.split(" ").map((text) =>
                                                <span>{text !== "<b>" && text !== "</b>" && !instructionFlag &&
                                                    <h2 className='noteTextMsg mr-4px'>
                                                        {text}
                                                    </h2>}
                                                    {text === "<b>" && (instructionFlag = true)}
                                                    {text !== "<b>" && text !== "</b>" && instructionFlag &&
                                                        <span className='highlight-sm-Text mr-4px mt-3px'>
                                                            {text}
                                                        </span>}
                                                    {text === "</b>" && (instructionFlag = false)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        <span className='justify-content-center align-item-center-noflex'>
                            <NewNotdata />
                        </span>
                    }
                </>
            </div>
        )
    }
}
export default GEMSInstruction