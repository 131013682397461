import React, { Component } from "react";
import { connect } from "react-redux";
import "./MOMStyles.css";
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import Today from "../../../../assets/drishti_images/momentsFeed/Today.png";
import StartFlag from "../../../../assets/drishti_images/momentsFeed/StartFlag.png";
import NoDataFound from "../../../../assets/drishti_images/nodatafound/announcement-nodata.svg";


const preProfile = "data:image/png;base64,";
const NoDataMsg = `No Data Found`
class TataMilestones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      joining_year: '',
      experience: '',
      current_year: '',
      image: '',
      milestoneData: ''
    };
  }

  componentDidMount() {
    this.getTataMileStones();
  }

  getTataMileStones = () => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      emp_id: this.props.loginData.userData.Perno,
    };
    fetchApiCall(Config.getMyMilesStone, body, headers, true, "")
      .then((res) => {
        if (res.data.status_code === "200") {
          this.setState({
            milestoneData: res.data.data,
            joining_year: res.data.data.joining_year,
            experience: res.data.data.experience,
            current_year: res.data.data.current_year,
            image: res.data.data.image,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,

          });
        }
      })
      .catch((err) => {
        console.warn("err", err);
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    return (
      <><LoaderIndicator loading={this.state.loading} />
        <div className='row'>
          <div className="col-md-12 card-new milestoneCard">
            <div className="row"> 
              <div className="col-md-12 edit-cardtitle">
                Tata Motors Milestone
              </div>
            </div>
            {this.state.milestoneData !== '' ?
              <div className="d-flex tata-milestones-timeline">
                {/* <div className=" align-item-center coming-soon-milestones">Comming Soon</div> */}
                <div className="row justify-content-between mt-1rem">
                  <div className=".fem-bg-circle-timeline timeline-circle circle-text-timeline timeline-goal-timeline"><img src={StartFlag} alt="icon" className="tata-milestone-img1" /></div>
                  <div className="row pl-10px mt-20px">
                    <div className="sub-card-text-bold">{this.state.joining_year} <span className="purple-color">Joined</span></div>
                  </div>
                </div>
                <div className="row justify-content-between mt-2_5rem center-circle">
                  <div className=".fem-bg-circle-timeline timeline-circle circle-text-timeline-center timeline-goal-timeline">{this.state.image !== '' ? <img alt="" src={this.state.image} className="tata-milestone-img2" /> : <img alt="" src={StartFlag} className="tata-milestone-img2" />}</div>
                  <div className="row pl-10px mt-20px">
                    <div className="sub-card-text-bold">{this.state.experience}</div>
                  </div>
                </div>
                <div className="row justify-content-between mt-2_5rem">
                  <div className=".fem-bg-circle-timeline timeline-circle circle-text-timeline ml-35px"><img src={Today} alt="icon" className="tata-milestone-img3" /></div>
                  <div className="row pl-10px mt-14px">
                    <div className="sub-card-text-bold">{this.state.current_year} <span className="purple-color">Today</span></div>
                  </div>
                </div>
              </div>
              :
              <div className='d-flex justify-content-center flex-column' style={{ height: '16.688rem' }}>
                <div className='align-self-center'>
                  <img src={NoDataFound} alt="Nodata" />
                </div>
                <div className='align-self-center'>
                  <label className='card-content-title grey-color'>{NoDataMsg}</label>
                </div>
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    keycloakData: state.keycloakState,
  }
}

export default connect(mapStatesToProps)(TataMilestones);