import React, { Component } from 'react'
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { dynamic_screen_type } from '../../../../utils/Utils';
import { requestFailed } from '../../../../constants/Messages';
import Resizer from 'react-image-file-resizer';

class ScreenPopupNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      typeScreenData: dynamic_screen_type(),
      noteTypeData: [{ value: 'Image' }, { value: 'Text' }],
      screenPopupImageName: '',
      loading: false,
      dynamicPopupImageName: '',
      noteTitle: '',
      noteContent: '',
      screenTypeCode: '',
      screenTypeValue: 'Select',
      screenPopupNotificationImage: '',
      noteType: 'Select',
      dynamicPopuphyperlink: '',
      fromDate: '',
      toDate: '',
      isActive: true,
      notificationID: '',
    }
  }

  validate = () => {
    let current_date = new Date()
    let end_date = new Date(this.state.toDate);
    let start_date = new Date(this.state.fromDate);
    if (this.state.screenTypeCode === 'Select') {
      toast.warning('Please select screen type', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.noteTitle === '') {
      toast.warning('Please select Note title', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.noteType === 'Select note type' || this.state.noteType == '') {
      toast.warning('Please select Note type', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.fromDate === '') {
      toast.warning('Please Enter start date', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.toDate === '') {
      toast.warning('Please Enter end date', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (start_date > end_date) {
      toast.warning('Start date cannot exceed to date', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else {
      this.insertPopupNotification(this.props.Action)
    }
  }

  insertPopupNotification = (action) => {
    this.setState({
      loading: true
    })
    const headers = {
      'Content-Type': 'application/json',
    }
    let body = {
      image_name: this.state.dynamicPopupImageName,
      screen_type: this.state.screenTypeCode,
      note_title: this.state.noteTitle,
      note_content: this.state.noteContent,
      note_type: this.state.noteType,
      image_url: this.state.screenPopupNotificationImage,
      image_hyperlink: this.state.dynamicPopuphyperlink,
      from_date: this.state.fromDate,
      to_date: this.state.toDate,
      is_active: this.state.isActive,
      action: action,
      notification_id: this.state.notificationID
    }
    fetchApiCall(Config.insertPopUpNotificationBychro, body, headers, false, 'admin')
      .then(res => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code === '200') {
          toast.success(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          },
            this.props.allDismiss(),
            this.props.getScreenPopupDashBoardCall(),
            this.props.screenPopupNotificationsActive()
          )
          this.setState({ loading: false })
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
          this.setState({ loading: false })
        }
      }).catch(err => {
        this.setState({ loading: false })
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastError'
        })
      })
  }

  // fileSelectHandler = (event) => {
  //   this.setState({ dynamicPopupImageName: event.target.files[0] ? event.target.files[0].name : '' })
  //   let re = /(?:\.([^.]+))?$/;
  //   // var ext = re.exec("file.name.with.dots.txt")[1];
  //   console.log("ext:", re.exec(event.target.files[0])[1])
  //   if (event.target.files && event.target.files[0]) {
  //     let maxAllowedSize = 2097152;           // 2MB size
  //     if (event.target.files[0].size <= maxAllowedSize) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]);
  //       reader.onload = (e) => {
  //         this.setState({
  //           screenPopupNotificationImage: e.target.result,
  //         })
  //       }
  //     }
  //     else {
  //       toast.warn('Please upload image less than 2MB', {
  //         position: "bottom-center",
  //         hideProgressBar: true,
  //         className: 'toastWarn'
  //       })
  //       this.setState({ screenPopupNotificationImage: '' })
  //     }
  //   }
  //   else { 
  //     this.setState({ screenPopupNotificationImage: '' }) }
  // }


  fileSelectHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ dynamicPopupImageName: event.target.files[0] ? event.target.files[0] : '' })
    // let re = /(?:\.([^.]+))?$/;
    // var ext = re.exec("file.name.with.dots.txt")[1];
    // console.log("ext:", re.exec(event.target.files[0])[1])
    if (event.target.files && event.target.files[0]) {
      let maxAllowedSize = 2097152;           // 2MB size
      if (event.target.files[0].size <= maxAllowedSize) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.setState({
              heightAndWidth: [img.width, img.height]
            })
            this.uploadImg(this.state.dynamicPopupImageName, file)
            console.log('Image width:', img.width);
            console.log('Image height:', img.height);
          }
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({ dynamicPopupImageName: '' })
      }
    }
    else { 
      this.setState({ dynamicPopupImageName: '' }) }
  }

  uploadImg = (event, data) => {
    console.log("uploadImg event:", event);
    this.setState({
      dynamicPopupImageName: event ? event.name : '',
    })
    console.log('event--------->', event)
    if (event) {
      console.log('event.size--------->', event.size)
      let maxAllowedSize = 2097152;  // 2MB size
      if (event.size <= maxAllowedSize) {
        // console.log("inside :", event.size, maxAllowedSize, data.file);
        // ******************************for Resize the image*****************************************
        try {
          Resizer.imageFileResizer(
            data,
            1280,
            720,
            'PNG',
            100,
            0,
            (uri) => {
              console.log('Resized image URI:', uri);
              this.setState({ screenPopupNotificationImage: uri })
              // Image.getSize(uri, (width, height) => {
              //   console.log('Resized image size:', width, height);
              // });
            },
            'uri',
            1280,
            720
          );
        } catch (err) {
          console.log("Resizer err:", err);
        }
        // **************************************************************************

        // let reader = new FileReader();
        // reader.readAsDataURL(event);
        // reader.onload = (e) => {
        //   this.setState({
        //     sparshImgUrl: e.target.result
        //   },
        //     console.log("e.target.result:", e.target.result)
        //   )
        // }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          screenPopupNotificationImage: '',
          dynamicPopupImageName: ''
        })
      }
    }
    else {
      this.setState({
        screenPopupNotificationImage: '',
        dynamicPopupImageName: ''
      })
    }
  }

  changeNoteType = (data) => {
    this.setState({ noteType: data.value })
  }

  changeNotificationType = (data) => {
    this.setState({
      screenTypeCode: data.code,
      screenTypeValue: data.value,
    })
  }

  render() {
    console.log('ScreenPopupNotification');
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        <div>
          <form className='todoModalBody popup_scroller w-100'>
            <div className='p-1 w-100'>
              <div>
                <div>
                  <div className='p-sub-heading ml-3 mb-1'>Screen Type</div>
                  <div className='p-placeholder-text mb-2'>
                    <Dropdown
                      data={this.state.typeScreenData}
                      selectedValue={(data) => this.changeNotificationType(data)}
                      value={this.state.screenTypeValue}
                      render={'value'}
                      height={'NotificationHeight'}
                    />
                  </div>
                </div>
                <div className='p-sub-heading ml-3 mb-1'>Title</div>
                <div className='p-placeholder-text mb-2'>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Enter the title'
                    onChange={(e) => this.setState({ noteTitle: e.target.value })}
                    value={this.state.noteTitle}
                  />
                </div>
                <div>
                  <div className='p-sub-heading ml-3 mb-1'>Note Type</div>
                  <div className='p-placeholder-text mb-2'>
                    <Dropdown
                      data={this.state.noteTypeData}
                      selectedValue={(data) => this.changeNoteType(data)}
                      value={this.state.noteType}
                      render={'value'}
                      height={'dependOnContent'}
                    />
                  </div>
                </div>
                {this.state.noteType === 'Image' &&
                  <div>
                    <div>
                      <div className='p-sub-heading ml-3 mb-1'>Image</div>
                      {!this.props.show &&
                        <div className='p-placeholder-text mb-2'>
                          <input
                            className="form-control p-placeholder  p-placeholder-admin px-2"
                            type="file"
                            accept="image/*"
                            onChange={this.fileSelectHandler}
                          />
                          <div className="p-sub-note ml-10">You can upload Image upto 2MB size only</div>
                        </div>
                      }
                    </div>
                    {this.state.screenPopupImageName != '' && <div className="d-flex w-100 mb-2"><i className="nav-icon cui-speedometer"></i>
                      <img className="d-flex w-100" src={this.state.screenPopupNotificationImage} alt={"image"} />
                    </div>}
                  </div>
                }
                <div className='row justify-content-between'>
                  <div className='mb-3 mt-2'>
                    <div className='p-sub-heading ml-3'>Start Date</div>
                    <div className='p-placeholder-text'>
                      <input
                        className="form-control p-placeholder p-placeholder-admin px-2"
                        type="date"
                        value={this.state.fromDate}
                        onChange={(e) => this.setState({ fromDate: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className='mb-3 mt-2'>
                    <div className='p-sub-heading ml-3'>End Date</div>
                    <div className='p-placeholder-text'>
                      <input
                        className="form-control p-placeholder p-placeholder-admin px-2"
                        type="date"
                        value={this.state.toDate}
                        onChange={(e) => this.setState({ toDate: e.target.value })}
                      />
                    </div>
                  </div>
                </div>
                <div className='p-sub-heading ml-3 mb-1'>Hyperlink</div>
                <div className='p-placeholder-text mb-2'>
                  <input
                    className="form-control p-placeholder p-placeholder-admin px-2"
                    type="text"
                    value={this.state.dynamicPopuphyperlink}
                    onChange={(e) => this.setState({ dynamicPopuphyperlink: e.target.value })}
                    placeholder={"Hyperlink"}
                  />
                </div>
                <div className='p-sub-heading ml-3'>Note Content</div>
                <div className='p-placeholder-text'>
                  <textarea
                    className="form-control p-placeholder px-3"
                    type="text"
                    maxLength={1000}
                    readOnly={this.state.show}
                    onChange={(e) => this.setState({ noteContent: e.target.value })}
                    value={this.state.noteContent}
                    placeholder={"Enter the note content"}
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </form>
          <div className='modal-footer btnBox justify-content-between'>
            <div className='justify-content-start'>
              <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
            </div>
            <div className='justify-content-around'>
              <div className='justify-content-start'>
                <input type='button'
                  value='CANCEL'
                  className='popup-back-btn popup-back-text'
                  onClick={this.props.Action === 'update' ? this.props.popupDismiss : this.props.back} />
              </div>
              <div className='justify-content-start'>
                <input type='button'
                  value='ADD'
                  className='popup-confirm-btn'
                  onClick={this.validate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ScreenPopupNotification;

