import * as Config from '../constants/Config';

export function emptyCache() {
	// try {
		let version = localStorage.getItem('version');
		console.log("Config.version:",Config.version)
		console.log("version:",version);
		if (version != Config.version) {
			// if ('caches' in window) {
				console.log("inside")
				caches.keys().then((names) => {
					// Delete all the cache files
					names.forEach(name => {
						caches.delete(name);
					})
				});
				// Makes sure the page reloads. Changes are only visible after you refresh.
				window.location.reload(true);
			// }
			localStorage.clear();
			localStorage.setItem('version', Config.version);
		}
	// }
	// catch (error) {
	// 	console.log("error erer", error)
	// }
}