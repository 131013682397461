import React, { Component } from 'react';
// import sendIcon from '../../../../../assets/drishti_images/cfm/sendicon.svg'
import * as Config from "../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import activeRadio from '../../../../assets/drishti_images/learningArchitecture/active-radio.svg'
import inactiveRadio from '../../../../assets/drishti_images/learningArchitecture/inactive-radio.svg'
// import Dropdown from '../../../shared/components/Dropdown'
import LargeDropdown from '../../../shared/components/LargeDropdown'

const firstTitle = "Select type"
class LASearchPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            popupClose: false,
            statusRadio: 'active',
            firstSearch: true,
            guidedSearch: false,
            ujrSearch: false,
            statusRadioCode: 'GuidedSearch',
            companyValue: 'Company',
            levelValue: 'Level',
            jf1Value: 'Sub job family 1',
            jf2Value: 'Sub job family 2',
            functionValue: 'Function',
            ujr: '',
            ujrValue: 'Unique Job Role',
            ujrTextValue: 'Unique Job Role Text',
            ujrCodeValue: ''
        }
    }

    componentDidMount() {
        // this.get_company()
    }

    get_company() {
        console.log("get_company running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        console.log("get_company body:", body)
        fetchApiCall(Config.get_company, body, headers, true, '')
            .then(res => {
                console.log("get_company res:", res.data.company_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        company_list: res.data.company_list,
                        loading: false
                    })
                    const tempcompany_list = this.state.company_list.map(tempValue => ({ label: tempValue }))
                    console.log("tempcompany_list:", tempcompany_list);
                    this.setState({
                        company_list: tempcompany_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        company_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }

    get_function(companyparam) {
        console.log("get_function running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: companyparam
        }
        console.log("get_function body:", body)
        fetchApiCall(Config.get_function, body, headers, true, '')
            .then(res => {
                console.log("get_function res:", res.data.function_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        function_list: res.data.function_list,
                        loading: false
                    })
                    const tempfunction_list = this.state.function_list.map(tempValue => ({ label: tempValue }))
                    console.log("tempfunction_list:", tempfunction_list);
                    this.setState({
                        function_list: tempfunction_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        function_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_sub_job_family_1(functionparam) {
        console.log("get_function running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
            function: functionparam
        }
        console.log("get_sub_job_family_1 body:", body)
        fetchApiCall(Config.get_sub_job_family_1, body, headers, true, '')
            .then(res => {
                console.log("get_sub_job_family_1 res:", res.data.sub_job_family_1_list)
                console.log("res.data.status_code:", res.data.status_code)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        sub_job_family_1_list: res.data.sub_job_family_1_list,
                        loading: false
                    })
                    const tempsub_job_family_1_list = this.state.sub_job_family_1_list.map(tempValue => ({ label: tempValue }))
                    console.log("tempsub_job_family_1_list:", tempsub_job_family_1_list);
                    this.setState({
                        sub_job_family_1_list: tempsub_job_family_1_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        sub_job_family_1_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }

    get_sub_job_family_2(subjob1param) {
        console.log("get_sub_job_family_2 running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
            function: this.state.functionValue,
            sub_job_family_1: subjob1param
        }
        console.log("get_sub_job_family_2 body:", body)
        fetchApiCall(Config.get_sub_job_family_2, body, headers, true, '')
            .then(res => {
                console.log("get_sub_job_family_2 res:", res.data.sub_job_family_2_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        sub_job_family_2_list: res.data.sub_job_family_2_list,
                        loading: false
                    })
                    const tempsub_job_family_2_list = this.state.sub_job_family_2_list.map(tempValue => ({ label: tempValue }))
                    console.log("tempsub_job_family_2_list:", tempsub_job_family_2_list);
                    this.setState({
                        sub_job_family_2_list: tempsub_job_family_2_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        sub_job_family_2_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_level(subjob2param) {
        console.log("get_level running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
            function: this.state.functionValue,
            sub_job_family_1: this.state.jf1Value,
            sub_job_family_2: subjob2param
        }
        console.log("get_level body:", body)
        fetchApiCall(Config.get_level, body, headers, true, '')
            .then(res => {
                console.log("get_level res:", res.data.sub_job_family_2_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        level_list: res.data.level_list,
                        loading: false
                    })
                    const templevel_list = this.state.level_list.map(tempValue => ({ label: tempValue }))
                    console.log("level_list:", templevel_list);
                    this.setState({
                        level_list: templevel_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        level_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_level_ujr(subjob2param) {
        console.log("get_level_ujr running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
        }
        console.log("get_level body:", body)
        fetchApiCall(Config.get_level_ujr, body, headers, true, '')
            .then(res => {
                console.log("get_level res:", res.data.level_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        level_list: res.data.level_list,
                        loading: false
                    })
                    const templevel_list = this.state.level_list.map(tempValue => ({ label: tempValue }))
                    console.log("level_list:", templevel_list);
                    this.setState({
                        level_list: templevel_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        level_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_ujr_text(levelparam) {
        console.log("get_ujr_text running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
            function: this.state.functionValue,
            sub_job_family_1: this.state.jf1Value,
            sub_job_family_2: this.state.jf2Value,
            level: levelparam
        }
        console.log("get_ujr_text body:", body)
        fetchApiCall(Config.get_ujr_text, body, headers, true, '')
            .then(res => {
                console.log("get_ujr_text res:", res.data.ujr_text_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        ujrText_list: res.data.ujr_text_list,
                        loading: false
                    })
                    const tempujrText_list = this.state.ujrText_list.map(tempValue => ({ label: tempValue }))
                    console.log("level_list:", tempujrText_list);
                    this.setState({
                        ujrText_list: tempujrText_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        level_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_ujr_search_text(levelparam) {
        console.log("get_ujr_search_text running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
            company: this.state.companyValue,
            // function: this.state.functionValue,
            // sub_job_family_1: this.state.jf1Value,
            // sub_job_family_2: this.state.jf2Value,
            level: levelparam
        }
        console.log("get_ujr_search_text body:", body)
        fetchApiCall(Config.get_ujr_search_text, body, headers, true, '')
            .then(res => {
                console.log("get_ujr_search_text res:", res.data.ujr_text_list)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        ujrText_list: res.data.ujr_text_list,
                        loading: false
                    })
                    const tempujrText_list = this.state.ujrText_list.map(tempValue => ({ label: tempValue }))
                    console.log("level_list:", tempujrText_list);
                    this.setState({
                        ujrText_list: tempujrText_list,
                        loading: false
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        level_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    get_learning_arch_master_data() {
        console.log("get_learning_arch_master_data running..")
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        console.log("get_learning_arch_master_data body:", body)
        fetchApiCall(Config.get_learning_arch_master_data, body, headers, true, '')
            .then(res => {
                console.log("get_learning_arch_master_data res:", res.data)
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        company_list: res.data.company_list,
                        level_list: res.data.level_list,
                        ujrText_list: res.data.ujr_text_list,
                        loading: false
                    })
                    const tempcompany_list = this.state.company_list.map(tempValue => ({ label: tempValue }))
                    const templevel_list = this.state.level_list.map(tempValue => ({ label: tempValue }))
                    const tempujrText_list = this.state.ujrText_list.map(tempValue => ({ label: tempValue }))
                    console.log("tempujrText_list in masterdata:", tempujrText_list);
                    // console.log("tempfunction_list:",tempfunction_list);
                    this.setState({
                        // function_list: tempfunction_list,
                        loading: false,
                        company_list: tempcompany_list,
                        level_list: templevel_list,
                        ujrText_list: tempujrText_list
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        company_list: [],
                        level_list: [],
                        // function_list: [],
                    })
                    setTimeout(() => {
                        toast.error(res.data.message, {
                            position: "bottom-center",
                            hideProgressBar: true,
                            className: 'toastError'
                        })
                    }, 100)
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
                setTimeout(() => {
                    toast.error("Something went wrong", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            })
    }
    radioStateChange = (radioState) => {
        if (radioState === 'active') {
            this.setState({
                statusRadio: "active",
                statusRadioCode: "GuidedSearch"
            })
        }
        else {
            this.setState({
                statusRadio: "inactive",
                statusRadioCode: "UJRSearch"
            })
        }
    }
    proceedNext = (radioOption) => {
        console.log("proceedNext radioOption:", radioOption)
        if (radioOption === 'GuidedSearch') {
            this.setState({
                firstSearch: false,
                guidedSearch: true,
                ujrSearch: false,
                companyValue: 'Company',
                functionValue: 'Function',
                jf1Value: 'Sub job family 1',
                jf2Value: 'Sub job family 2',
                levelValue: 'Level',
                ujrTextValue: 'Unique Job Role Text'
            },
                () => this.get_company())
        }
        else {
            this.setState({
                firstSearch: false,
                guidedSearch: false,
                ujrSearch: true,
                companyValue: 'Company',
                functionValue: 'Function',
                jf1Value: 'Sub job family 1',
                jf2Value: 'Sub job family 2',
                levelValue: 'Level',
                ujrTextValue: 'Unique Job Role Text'
            },
                () => this.get_company())

            // () => this.get_learning_arch_master_data())
        }

    }
    backToFirst = () => {
        this.setState({
            firstSearch: true,
            guidedSearch: false,
            ujrSearch: false
        })
    }
    backToSecond = () => {
        this.setState({
            firstSearch: true,
            guidedSearch: false,
            ujrSearch: false
        })
    }
    filterByCompany = (listValue, type) => {
        console.log("type:", type)
        if (this.state.guidedSearch) {
            this.setState({
                companyValue: listValue.label,
                functionValue:'Function',
                jf1Value:'Sub job family 1',
                jf2Value:'Sub job family 2',
                levelValue: 'Level',
                ujrTextValue:'Unique Job Role Text',
            },
                () => this.get_function(listValue.label))
        }
        else {
            this.setState({
                companyValue: listValue.label,
                levelValue: 'Level',
                ujrTextValue:'Unique Job Role Text',
            },
                () => this.get_level_ujr(listValue.label)
            )
        }

    }
    filterByLevel = (listValue) => {
        if (this.state.guidedSearch) {
            this.setState({
                levelValue: listValue.label,
                ujrTextValue:'Unique Job Role Text',
            },
                () => this.get_ujr_text(listValue.label))
        }
        else {
            this.setState({
                levelValue: listValue.label,
                ujrTextValue:'Unique Job Role Text',
            },
                () => this.get_ujr_search_text(listValue.label)
                // () => this.get_ujr_text(listValue.label)
            )
        }
    }
    filterByFunction = (listValue) => {
        this.setState({
            functionValue: listValue.label,
            jf1Value:'Sub job family 1',
            jf2Value:'Sub job family 2',
            levelValue: 'Level',
            ujrTextValue:'Unique Job Role Text',
        },
            () => this.get_sub_job_family_1(listValue.label))
    }
    filterByjf1 = (listValue) => {
        this.setState({
            jf1Value: listValue.label,
            jf2Value:'Sub job family 2',
            levelValue: 'Level',
            ujrTextValue:'Unique Job Role Text',
        },
            () => this.get_sub_job_family_2(listValue.label))
    }
    filterByjf2 = (listValue) => {
        this.setState({
            jf2Value: listValue.label,
            levelValue: 'Level',
            ujrTextValue:'Unique Job Role Text',
        },
            () => this.get_level(listValue.label))
    }
    filterByujr = (listValue) => {
        this.setState({
            ujrTextValue: listValue.label
        })
    }

    // handleChangeUjr = (label) => {
    //     this.setState({
    //         ujr: label
    //     });
    // }

    Submit = (submitType) => {
        console.log("Submit Type:", submitType)
        this.props.passMasterDataValues(submitType, this.state.companyValue, this.state.functionValue,
            this.state.jf1Value, this.state.jf2Value, this.state.levelValue, this.state.ujrTextValue
        );
        this.props.onDismiss()
    }

    validate = (SearchType) => {
        if (SearchType === 'guidedSearch') {
            if (this.state.companyValue === 'Company' || this.state.companyValue === '') {
                setTimeout(() => {
                    toast.error('Please select company', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.functionValue === 'Function' || this.state.functionValue === '') {
                setTimeout(() => {
                    toast.error('Please select function', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.jf1Value === 'Sub job family 1' || this.state.jf1Value === '') {
                setTimeout(() => {
                    toast.error('Please select sub job family 1', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.jf2Value === 'Sub job family 2' || this.state.jf2Value === '') {
                setTimeout(() => {
                    toast.error('Please select sub job family 2', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.levelValue === 'Level' || this.state.levelValue === '') {
                setTimeout(() => {
                    toast.error('Please select level', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.ujrTextValue === 'Unique Job Role Text' || this.state.ujrTextValue === '') {
                setTimeout(() => {
                    toast.error('Please select ujr text', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else {
                this.Submit(SearchType)
            }
        }
        else {
            if (this.state.companyValue === 'Company' || this.state.companyValue === '') {
                setTimeout(() => {
                    toast.error('Please select company', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.levelValue === 'Level' || this.state.levelValue === '') {
                setTimeout(() => {
                    toast.error('Please select level', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else if (this.state.ujrTextValue === 'Unique Job Role Text' || this.state.ujrTextValue === '') {
                setTimeout(() => {
                    toast.error('Please select ujr text', {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }, 100)
            }
            else {
                this.Submit(SearchType)
            }
        }

    }
    render() {
        const { firstSearch, statusRadioCode, guidedSearch, ujrSearch } = this.state
        // console.log("functionValue :", this.state.functionValue )
        // console.log("this.state.jf1Value :", this.state.jf1Value)
        // console.log("this.state.jf2Value :", this.state.jf2Value)
        // console.log("this.state.levelValue  :", this.state.levelValue)
        // console.log("this.state.ujrTextValue   :", this.state.ujrTextValue )

        // console.log("ujrText_list in render:", this.state.ujrText_list);
        // console.log("ujrTextValue in render:", this.state.ujrTextValue);
        // console.log("companyValue in render:", this.state.companyValue);
        return (
            <div className="modal popup-box" >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">

                    <div className="modal-content apply_leave_modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle"> {firstSearch && firstTitle}{guidedSearch && "Guided Search"}{ujrSearch && "Unique Job Role Search"}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onDismiss}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        {firstSearch &&
                            <>
                                <div className="row modal-body mdl-body justify-content-around h-15vh">
                                    <div className='pointer'
                                        onClick={() => this.radioStateChange('active')}
                                    >
                                        <img alt='' src={this.state.statusRadio === 'active' ? activeRadio : inactiveRadio}
                                            className='radio-wh m-10px'
                                            onClick={() => this.radioStateChange('active')}
                                        />
                                        <label className='pointer'>Guided Search</label>
                                    </div>
                                    <div className='pointer'
                                        onClick={() => this.radioStateChange('inactive')}
                                    >
                                        <img alt='' src={this.state.statusRadio !== 'inactive' ? inactiveRadio : activeRadio}
                                            className='radio-wh m-10px'
                                            onClick={() => this.radioStateChange('inactive')}
                                        />
                                        <label className='pointer'>Unique Job Role Search</label>
                                    </div>
                                </div>

                                <div className='modal-footer btnBox'>
                                    <input type='button'
                                        onClick={this.props.onDismiss}
                                        data-dismiss="modal"
                                        value='Cancel' className='cancelBtn'
                                    />
                                    <input type='button'
                                        value='Proceed'
                                        className='ml-2 validateBtn'
                                        onClick={() => this.proceedNext(statusRadioCode)}
                                    />
                                </div>
                            </>
                        }
                        {guidedSearch &&
                            <>
                                <div className="modal-body mdl-body">
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.company_list}
                                            selectedValue={(data) => this.filterByCompany(data, "guidedSearch")}
                                            value={this.state.companyValue}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.function_list}
                                            selectedValue={(data) => this.filterByFunction(data)}
                                            value={this.state.functionValue}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.sub_job_family_1_list}
                                            selectedValue={(data) => this.filterByjf1(data)}
                                            value={this.state.jf1Value}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.sub_job_family_2_list}
                                            selectedValue={(data) => this.filterByjf2(data)}
                                            value={this.state.jf2Value}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.level_list}
                                            selectedValue={(data) => this.filterByLevel(data)}
                                            value={this.state.levelValue}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.ujrText_list}
                                            selectedValue={(data) => this.filterByujr(data)}
                                            value={this.state.ujrTextValue}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                                <div className='modal-footer btnBox'>
                                    <input type='button'
                                        onClick={() => this.backToFirst()}
                                        data-dismiss="modal"
                                        value='Back' className='cancelBtn'
                                    />
                                    <input type='button'
                                        value='Submit'
                                        className='ml-2 validateBtn'
                                        onClick={() => this.validate(
                                            "guidedSearch", this.state.companyValue, this.state.functionValue,
                                            this.state.jf1Value, this.state.jf2Value,
                                            this.state.levelValue, this.state.ujrTextValue)}
                                    />
                                </div>
                            </>
                        }
                        {
                            ujrSearch &&
                            <>
                                <div className="modal-body mdl-body h-40vh">
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.company_list}
                                            selectedValue={(data) => this.filterByCompany(data, "ujrSearch")}
                                            value={this.state.companyValue}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.level_list}
                                            selectedValue={(data) => this.filterByLevel(data)}
                                            value={this.state.levelValue}
                                            disabled={false}
                                        />
                                    </div>
                                    <div className='dropdown-style'>
                                        <LargeDropdown
                                            data={this.state.ujrText_list}
                                            selectedValue={(data) => this.filterByujr(data)}
                                            value={this.state.ujrTextValue}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                                <div className='modal-footer btnBox'>
                                    <input type='button'
                                        onClick={() => this.backToSecond()}
                                        data-dismiss="modal"
                                        value='Back' className='cancelBtn'
                                    />
                                    <input type='button'
                                        value='Submit'
                                        className='ml-2 validateBtn'
                                        onClick={() => this.validate(
                                            "ujrSearch", this.state.companyValue, this.state.levelValue, this.state.ujrTextValue)}
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        cfmData: state.cfmState
    }
}
export default (connect(mapStatesToProps)(LASearchPopup));
