import React, { Component } from 'react';

const confirm = 'After click on submit a mail containing your separation form will be sent to you, BHR and ESEP team. Are you sure you want to submit the form?'

class ExitConfirmPopup extends Component {
   
    render() {
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">Confirmation</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="popup_text text-center p-3">
                        {confirm}
                        </div>
                    </div>

                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='NO' className='cancelBtn' />

                        <input type='button' value='YES' className='ml-2 validateBtn' onClick={() => this.props.submit()} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ExitConfirmPopup;