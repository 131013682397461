import React, { Component } from 'react';
import swapicon from '../../../../assets/drishti_images/transfer/swapicon.svg';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';

const confirm = 'Please confirm if you want to proceed with swap?'
const swapconfirm = 'Since this is a swap scenario, position vacancy validation will not be checked.'
class TransferRequestPopups extends Component {
    oncancel = ()=>{
        this.props.onDismiss();
        this.props.oncancel();
    }
    render() {
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.props.loader} />
                <div className="declaration-box p-0" style={{ width: '30%' }}>
                    <div className="modal-header">
                        <h4 className="modal_title black">Swap Confirmation</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className="row justify-content-center mt-4">
                            <img alt="swap" src={swapicon} />
                        </div>
                        <div className="row popup_text justify-content-center mx-3 mt-4">
                            {confirm}
                        </div>
                        <div className="popup_text-light justify-content-center align-item-center mx-4 mt-1 mb-4">
                            {swapconfirm}
                        </div>
                    </div>

                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.oncancel()} value='CANCEL' className='cancelBtn' />

                        <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.props.submit()} />
                    </div>
                </div>
            </div>
        );
    }
}

export default TransferRequestPopups;