import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';

class TataBuzzNews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            title: '',
            description: '',
        }
    }

    insertTataBuzzNews = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            action: "POST",
            news_title: this.state.title,
            news_description: this.state.description,
            emp_mail: this.props.adminLoginData?.adminData?.user_email
        }
        fetchApiCall(Config.insertBuzzNews, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200' || res.data.status_code === '201') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    },
                        this.props.AllClose(),
                    )
                    this.props.addTataBuzzNewsActive()
                    this.setState({ loading: false })
                } else {
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    },
                        this.props.AllClose(),
                    )
                    this.props.upcomingOccasionActive()
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(err, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }


    validate = () => {
        if (this.state.title === '') {
            toast.warning('Please enter title', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else if (this.state.description === '') {
            toast.warning('Please enter description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            })
        }
        else {
            this.insertTataBuzzNews()
        }
    }

    render() {
        const { back, loading } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{this.props.NotificationType}</h4>
                        </div>
                        <div className="">
                            <form className='todoModalBody popup_scroller w-100'>
                                <div>
                                    <div>
                                        <div className='p-sub-heading ml-3 mt-2'>Title</div>
                                        <div className='p-placeholder-text mb-2'>
                                            <input
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the title"}
                                                onChange={(e) => this.setState({ title: e.target.value })}
                                                value={this.state.title}
                                            />
                                        </div>
                                        <div className='p-sub-heading ml-3'>Description</div>
                                        <div className='p-placeholder-text'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                placeholder={"Enter the description"}
                                                maxLength={1000}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ description: e.target.value })}
                                                value={this.state.description}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className='modal-footer btnBox justify-content-between'>
                                <div className='justify-content-start'>
                                    <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                                </div>
                                <div className='justify-content-around'>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='CANCEL'
                                            className='popup-back-btn popup-back-text'
                                            onClick={back} />
                                    </div>
                                    <div className='justify-content-start'>
                                        <input type='button'
                                            value='ADD'
                                            className='popup-confirm-btn'
                                            onClick={this.validate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
        adminLoginData: state.adminLoginState,
    }
}

export default connect(mapStatesToProps)(TataBuzzNews);