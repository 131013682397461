import React, { Component } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import './CalendarStyle.css'
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config'
import './TeamsStyle.css'
import Loader from '../../../shared/loader/LoaderIndicator'
import CircleSkeleton from '../../../shared/skeleton/Skeletons';
import { Month } from '../../../../utils/Utils';
import Dropdown from '../../../shared/components/Dropdown';
import default_profile from '../../../../assets/drishti_images/profile/default_profile.svg';
import PlannedNoData from '../../../../assets/drishti_images/manager/teams/PlannedNoDataNew.png';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import '../../../shared/sidebar/Sidebar.css';
import { connect } from 'react-redux';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { planeLeaveNote } from '../../../../constants/Messages';
import ServiceUnavailable from '../../../shared/ServiceUnavailable';

const currentMonth = new Date()
const temp = { code: 'All', value: 'All months' }
class PlannedLeave extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isCalender: true,
            loading: false,
            directReporteesData: [],
            monthData: Month(),
            current: currentMonth.getMonth() + 1,
            selectMonthCode: currentMonth.getMonth() + 1,
            selectMonthValue: '',
            newData: [],
            new: [],
            new1: [],
            is_service_Unavailable: false,
            leave_Service_reason: ''
        }
    }

    componentDidMount() {
        this.checkService();
        this.getDirectReporteeData();
        let currentMonthvalue = this.state.monthData.filter((list) => list.code == this.state.selectMonthCode)
        this.setState({
            selectMonthValue: currentMonthvalue[0].value,
        }, () => this.latestMonthFilter())
    }

    async checkService() {
        const result = await this.props.serviceData.availableServiceData;
        this.props.loginData.serviceEnableDisableData.forEach((item, i) => {
            if (item.service_type == "planned_leave" && item.is_active == false) {
                console.log("checkService leave inside :")
                this.setState({
                    is_service_Unavailable: true,
                    leave_Service_reason: item.reason
                })
            }
        })
    }

    latestMonthFilter = () => {
        let latestMonth = this.state.monthData.filter((list) => list.code >= this.state.selectMonthCode)
        latestMonth.unshift(temp)
        this.setState({ monthData: latestMonth })
    }

    toggleScreen = () => {
        this.setState({
            isCalender: !this.state.isCalender
        })
    }

    getDirectReporteeData = () => {
        this.setState({ loading: true })
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            manager_emp_id: this.props.loginData.cfmUserDetails.length != 0 ? this.props.loginData.cfmUserDetails.user_details.emp_id : '',
            month: this.state.selectMonthCode
        }
        fetchApiCall(Config.getDirectReporteeData, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        directReporteesData: res.data.planned_leaves_data !== '' ? res.data.planned_leaves_data : [],
                        loading: false
                    })
                }
                else {
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                console.log(err)
            })
    }

    changeMonth = (data) => {
        this.setState({
            selectMonthCode: data.code,
            selectMonthValue: data.value,
        },() => this.getDirectReporteeData())
    }

    renderTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{planeLeaveNote}</div>
        </Tooltip>
    );

    render() {
        const { isCalender } = this.state
        return (
            <div>
                <Loader loading={this.state.loading} />
                {this.state.is_service_Unavailable ?
                    <div className='row'>
                        <div className='flex-column plannedLeaveImg'>
                            <ServiceUnavailable reason={this.state.leave_Service_reason} />
                        </div>
                    </div>
                    :
                    <>
                        {this.state.loading ?
                            <>
                                <div className='mt-4 p-2'>
                                    <CircleSkeleton />
                                    <CircleSkeleton />
                                    <CircleSkeleton />
                                    <CircleSkeleton />
                                </div>
                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="d-flex justify-content-between card-title-position">
                                        <div className='w-10rem'>
                                            <label className="edit-cardtitle">Planned Leaves</label>
                                            <OverlayTrigger placement="right" overlay={this.renderTooltip}>
                                                <img src={infoicon} alt='' style={{ 'marginLeft': '5px' }} />
                                            </OverlayTrigger>
                                        </div>
                                        <div className='mt-2'>
                                            <Dropdown
                                                data={this.state.monthData}
                                                selectedValue={(data) => this.changeMonth(data)}
                                                value={this.state.selectMonthValue}
                                                render={'value'}
                                                height={'NotificationHeight'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="" >
                                    {!isCalender ?
                                        <div className="scroll-calender">
                                            <Calendar
                                                className="m-calendar"
                                                tileClassName="m-calendar-tile"
                                                onChange={this.onChange}
                                                value={this.state.date}
                                                onActiveStartDateChange={this.onChangeMonth}
                                                onClickMonth={this.onSelectMonth}
                                                prev2Label={null}
                                                next2Label={null}
                                            />
                                        </div> :
                                        <div className="p-3 reporteedata height-185">
                                            {this.state.directReporteesData.length !== 0 ?
                                                <div>{this.state.directReporteesData.map((list, i) =>
                                                    <div className='child-team-card row justify-content-between align-item-center px-2 mb-2' key={i}>
                                                        <div className="row align-item-center py-2">
                                                            {list.employee_profile_picture === '' ?
                                                                <img alt="avatar" src={default_profile} className="profile-circle-shape emp_circle_text"></img>
                                                                :
                                                                <img alt="avatar" src={list.employee_profile_picture} className="profile-circle-shape emp_circle_text"></img>
                                                            }

                                                            <div className="flex-direction-column ml-2">
                                                                <div className='card-content-title common-text-color'>{list.requestor_name}</div>
                                                                <div className='row'>
                                                                    <div className='profile-list-item-text'>{moment(list.from_date).format("DD-MM-YYYY")}</div>
                                                                    <div className='profile-list-item-text font-weight-bold'>&nbsp; to &nbsp;</div>
                                                                    <div className='profile-list-item-text'>{moment(list.to_date).format("DD-MM-YYYY")}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                </div>
                                                :
                                                <div className='col-md-12 d-flex align-items-center flex-column '>
                                                    <div >
                                                        <img className='Nodata-img' src={PlannedNoData} alt="PlannedNoData" />
                                                    </div>
                                                    <div>
                                                        <label className='card-content-title grey-color'>No planned leaves yet!</label>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(PlannedLeave);