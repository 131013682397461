import React, { Component } from 'react';
import './announcementScreen.css';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import AnnouncmentScreen from './announcments';
import UpcommingOccasion from './upcomingOccasion';
import TataBuzzNews from './tataBuzzNews';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';

class AnnouncmentHomescreen extends Component {
    constructor(props) {
        super(props);
    }

    gotoLanding = () => {
        window.location = (BASE_WEB_URL_DEV + 'home');
    }

    render() {
        return (
            <div className='row'>
                <div className="col-md-12 px-0">
                    <div
                        type='button'
                        className='row'
                        onClick={this.gotoLanding}>
                        <img src={overviewButton} alt='' />
                    </div>
                    <div className='row'>
                        <div className='col-md-9 mt-2'>
                            <AnnouncmentScreen />
                        </div>
                        <div className='col-md mt-2'>
                            <UpcommingOccasion />
                            <TataBuzzNews />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AnnouncmentHomescreen;