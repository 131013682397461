import React, { Component } from 'react';
import './Loader.css';
// import Lottie from "lottie-react";
// import loadImg from "./TMLLoader.json";
import AnimatedLoader from './AnimatedLoader'
class LoaderIndicator extends Component {
    render() {
        const {loading} = this.props;
        if(!loading) return null;

        return (
            <div className="loader-container ">
                <div className="loader">
                 <AnimatedLoader /> 
                </div>
            </div>
        )
    }   
}

export default LoaderIndicator;

