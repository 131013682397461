import React, { Component } from 'react';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { connect } from 'react-redux';
import moment from 'moment';
import '../adminNotificationHome/Notification.css';
import '../adminNotifications/Notification.css';
import { Month } from '../../../../utils/Utils';
import LargeDropdown from '../../../shared/components/LargeDropdown';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { RiArrowDropDownLine } from 'react-icons/ri';
import ReactExport from "react-data-export";
import Pagination from 'react-bootstrap/Pagination';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const chooseselect = 'Enter PA'
const selectDefault = 'Select'
toast.configure();
const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class GroupScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: true,
            isEdit: false,
            loading: false,
            leaveRequestDetails: [],
            paData: [],
            psaData: [],
            paCode: '',
            paValue: chooseselect,
            leaveStatusData: [{ value: 'All', code: '' }, { value: 'Error in posting', code: '8' }, { value: 'Error in deletion', code: '9' }],
            psaCode: 'Select PSA',
            leaveStatus: 'Select leave status',
            leaveCode: '',
            leaveRequestsMonth: Month(),
            leaveMonthCode: moment(new Date()).format("MM"),
            leaveMonthValue: moment(new Date()).format("MMMM"),
            count: [],
            page: 1,
            excelData: [],
            label: '',

            pa: selectDefault,
            psa: selectDefault
        }
    }

    componentDidMount() {
        this.getPersonalAreaMasterData()
    }

    getPersonalAreaMasterData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getPersonalAreaMaster, {}, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin)
                if (res.data.status_code == 200) {
                    if (res.data.pa_data) {
                        const paArray = res.data.pa_data.map((item, idx) => ({
                            ...item,
                            label: item.pa_code
                        }))
                        this.setState({
                            loading: false,
                            paData: paArray
                        },
                            () => console.log('parrayyyy', this.state.paData)
                        )
                    }
                } else {
                    this.setState({
                        loading: false,
                        paData: []
                    })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
            })
    }

    getPersonalSubAreaMasterData = (PA) => {
        const headers = {
            'Content-Type': 'application/json'
        }
        let body = {
            pa_code: PA
        }
        fetchApiCall(Config.getPersonalSubAreaMaster, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin)
                if (res.data.status_code === '200') {
                    this.setState({
                        psaData: res.data.psa_data ? res.data.psa_data : []
                    });
                    const psaArray = this.state.psaData.map((item, idx) => ({
                        ...item,
                        label: item.psa_code
                    }))
                    this.setState({ psaData: psaArray })
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                    this.setState({
                        loading: false,
                        psaData: []
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                });
            })
    }

    getLeaveListingData = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            month: this.state.leaveMonthCode,
            pa_code: this.state.pa,
            psa_code: this.state.psa,
            leave_status: this.state.leaveCode,
            page_number: this.state.page
        }
        fetchApiCall(Config.leaveRequestByTimeOffice, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin)
                if (res.data.status_code == 200) {
                    //CountArray to collect all number of screens for pagination 
                    let CountArray = Array.from({ length: res.data.total_page }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        leaveRequestDetails: res.data.user_leave_request ? res.data.user_leave_request : [],
                        count: CountArray,
                    });
                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    });
                    this.setState({
                        loading: false,
                        leaveRequestDetails: [],
                        page: 1
                    })
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                    page: 1
                })
                // toast.error(res.data.message, {
                //     position: "bottom-center",
                //     hideProgressBar: true,
                //     className: 'toastError'
                // });
            })
    }

    // this function is called to get all data of employee to download in excelsheet without pagination from backend
    downloadLeaveRequestByTimeOffice = () => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            month:this.state.leaveMonthCode,
            pa_code: this.state.pa,
            psa_code: this.state.psa,
            leave_status:this.state.leaveCode
        }
        fetchApiCall(Config.downloadLeaveRequestByTimeOffice, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin)
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        excelData: res.data.user_leave_request ? res.data.user_leave_request : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        excelData: []
                    })
                }
            }).catch(err => { this.setState({ loading: false }) })
    }

    handlePageCount = (type) => {
        let pageCountInc = this.state.page + 1
        let pageCountDec = this.state.page - 1
        if (type === "increment") {
            if (pageCountInc <= this.state.count[this.state.count.length - 1]) {
                this.setState({ page: pageCountInc }, () => this.getLeaveListingData())
            }
        }
        else if (type === "decrement") {
            if (this.state.page > 1) { this.setState({ page: pageCountDec }, () => this.getLeaveListingData()) }
        }
    }

    selectedPage = (pageCount) => {
        this.setState({ page: pageCount }, () => this.getLeaveListingData())
    }

    handleChangePA = (data) => {
        this.setState({
            pa: data.pa_code,
        })
        this.getPersonalSubAreaMasterData(data.pa_code)
    }

    handleChangePSA = (data) => {
        this.setState({
            psa: data.psa_code,
        })
    }

    filterLeaveByleaveStatus = (e, listValue) => {
        this.setState({
            leaveStatus: listValue.value,
            leaveCode: listValue.code
        })
    }

    filterLeaveByMonth = (e, listValue) => {
        this.setState({
            leaveMonthCode: listValue.code,
            leaveMonthValue: listValue.value
        }, () => this.loadData())
    }

    loadData = () => {
        if (this.state.pa === '') {
            toast.warn('Please Enter PA value', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.psa === 'Select PSA') {
            toast.warn('Please select PSA value', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else if (this.state.leaveStatus === 'Select leave status') {
            toast.warn('Please select leave status', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastWarn'
            });
        }
        else {
            this.getLeaveListingData()
            this.downloadLeaveRequestByTimeOffice()
        }
    }

    render() {
        return (
            <div className="justify-content-between">
                <LoaderIndicator loading={this.state.loading} />
                <div className="col-md-12 px-0">
                    <div className='row'>
                        <div className='group-width ml-4 mx-2 mb-1 mt-3'>
                            <div className='p-sub-heading ml-3 mb-1'>PA</div>
                            <LargeDropdown
                                data={this.state.paData}
                                value={this.state.pa}
                                selectedValue={(data) => this.handleChangePA(data)}
                            />
                        </div>
                        <div className='group-width mx-2 mb-1 mt-3'>
                            <div className='p-sub-heading ml-3 mb-1'>PSA</div>
                            <LargeDropdown
                                data={this.state.psaData}
                                value={this.state.psa}
                                selectedValue={(data) => this.handleChangePSA(data)}
                            />
                        </div>
                        <div className='group-width mx-2 mb-1 mt-3'>
                            <div className='p-sub-heading ml-3 mb-1'>Status</div>
                            <div className='dropdown'>
                                <button
                                    className='btn p-11px p-placeholder h-42px d-flex justify-content-between'
                                    type='button' id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {this.state.leaveStatus}
                                    <RiArrowDropDownLine
                                        size={25}
                                        color={'rgb(185 188 197)'}
                                    />
                                </button>
                                <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                    {this.state.leaveStatusData.map((listValue, index) => {
                                        return (
                                            <label
                                                key={index}
                                                onClick={(e) => this.filterLeaveByleaveStatus(e, listValue)}
                                                className='dropdown-item'>{listValue.value}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='group-width mx-2 mb-1 mt-3'>
                            <div className='p-sub-heading ml-3 mb-1'>Month</div>
                            <div className='dropdown'>
                                <button
                                    className='btn p-11px p-placeholder h-42px d-flex justify-content-between'
                                    type='button' id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {this.state.leaveMonthValue}
                                    <RiArrowDropDownLine
                                        size={25}
                                        color={'rgb(185 188 197)'}
                                    />
                                </button>
                                <div className='dropdown-menu scroller w-100' aria-labelledby="dropdownMenuButton">
                                    {this.state.leaveRequestsMonth.map((listValue, index) => {
                                        return (
                                            <label
                                                key={index}
                                                onClick={(e) => this.filterLeaveByMonth(e, listValue)}
                                                className='dropdown-item'>{listValue.value}
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            type='button'
                            className='save-btn save-txt mt-38px'
                            onClick={() => this.loadData()}
                        >SEARCH
                        </div>
                        {this.state.leaveRequestDetails.length > 0 ?
                            <ExcelFile filename="Employees Leave Report"
                                element={
                                    <div
                                        type='button'
                                        className="save-btn save-txt mt-38px"
                                    >Export
                                    </div>
                                }>
                                <ExcelSheet
                                    data={this.state.excelData}
                                    name="Employees"
                                >
                                    <ExcelColumn label="EMPLOYEE ID" value="requestor_emp_id" />
                                    <ExcelColumn label="EMPLOYEE EMAIL ID" value="requestor_email" />
                                    <ExcelColumn label="LEAVE CATEGORY" value="leave_category" />
                                    <ExcelColumn label="LEAVE STATUS" value="leave_status" />
                                    <ExcelColumn label="LEAVE TYPE" value="leave_type" />
                                    <ExcelColumn label="APPROVER EMAIL" value="approver_email" />
                                    <ExcelColumn label="FROM DATE" value="from_date" />
                                    <ExcelColumn label="TO DATE" value="to_date" />
                                    <ExcelColumn label="APPROVER EMPLOYEE ID" value="approver_emp_id" />
                                    <ExcelColumn label="LEAVE APPLIED DATE" value="applied_date" />
                                    <ExcelColumn label="LEAVE POSTED DATE TIME" value="created_date_time" />
                                    <ExcelColumn label="LAST MODIFIED DATE TIME" value="modified_date_time" />
                                </ExcelSheet>
                            </ExcelFile> : null
                        }
                    </div>
                    <div className="pt-2 pushNotification-container">
                        <div>
                            {this.state.leaveRequestDetails.length != 0 ?
                                <table className="table">
                                    <thead className=''>
                                        <tr>
                                            <th scope="col" style={{ 'width': '3%' }}><div className='thead pr-0'>Sr. No</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Employee ID</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Category</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Status</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Leave Type</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>From Date</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>To Date</div></th>
                                            <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Approver ID</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.leaveRequestDetails.map((listValue, index) => {
                                            return (
                                                <tr key={index} className=''>
                                                    <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                    <td><div className="td" >{listValue.requestor_emp_id ? listValue.requestor_emp_id : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_status ? listValue.leave_status : ''}</div></td>
                                                    <td><div className="td">{listValue.leave_type ? listValue.leave_type : ''}</div></td>
                                                    <td><div className="td" >{moment(listValue.from_date).format("DD-MM-YYYY")}</div></td>
                                                    <td><div className="td" >{moment(listValue.to_date).format("DD-MM-YYYY")}</div></td>
                                                    <td><div className="td">{listValue.approver_emp_id ? listValue.approver_emp_id : ''}</div></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                :
                                <div className='row'>
                                    <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                        <div>
                                            <img src={noDataTransfer} alt="noDataTransfer" />
                                        </div>
                                        <div>
                                            <label className='card-content-title grey-color'>No leave record found!</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.leaveRequestDetails.length > 0 &&
                                <div className="col-md-12 w-100 p-3 d-flex justify-content-center ">
                                    <Pagination>
                                        <Pagination.Prev
                                            onClick={() => this.handlePageCount("decrement")}
                                        />
                                        {this.state.count.length > 1 &&
                                            <Pagination.Item
                                                onClick={() => this.selectedPage(1)}>{1}
                                            </Pagination.Item>}
                                        <Pagination.Ellipsis />
                                        <Pagination.Item active>
                                            {this.state.page}
                                        </Pagination.Item>
                                        <Pagination.Ellipsis />
                                        {this.state.count.length > 1 &&
                                            <Pagination.Item
                                                onClick={() => this.selectedPage(this.state.count[this.state.count.length - 1])} >
                                                {this.state.count[this.state.count.length - 1]}</Pagination.Item>}
                                        <Pagination.Next
                                            onClick={() => this.handlePageCount("increment")} />
                                    </Pagination>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(GroupScreen);