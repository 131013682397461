import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import radioOn from '../../../../../assets/images/applyLeave/radioOn.png';
import { connect } from 'react-redux';
import radioOff from '../../../../../assets/images/applyLeave/radioOff.png';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
// import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import TransferDetailPopup from '../TransferDetailPopup';
import moment from 'moment';
import * as AdminLoginAction from '../../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import ConfirmPopup from '../ConfirmPopup';
import approve from '../../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../../assets/drishti_images/manager/landing/reject.svg';

class TransferApprovalScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            listType: '',
            transferRequestData: [],
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            actionValue: '',
            transferRequestStatus: ''
        }
    }

    componentDidMount() {
        this.setState({ listType: 'pending' })
        this.getAppliedTransferRequestDAta('pending')
    }

    getAppliedTransferRequestDAta = (type) => {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            req_status: type.toLowerCase()
        }
        fetchApiCall(Config.getreceivedTransferRequest, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                        transferRequestData: res.data.transfer_request_data ? res.data.transfer_request_data : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        transferRequestData: [],
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    approveRejectTransferRequest = () => {
        this.setState({
            loading: true, showConfirm: false
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.adminLoginData.emp_id ? this.props.adminLoginData.emp_id : '',
            req_status: this.state.transferRequestStatus,
            transfer_request_unique_id: this.state.selectedData.transfer_request_unique_id
        }
        fetchApiCall(Config.approveRejectTransferRequest, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    this.getAppliedTransferRequestDAta('pending')
                    this.props.getDashboardData()
                } else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        loading: false,
                    })
                }

            }).catch(err => {
                this.setState({
                    loading: false,
                })
                toast.error(err, { hideProgressBar: true })
            })
    }

    activeConfirmationPopup = (listValue) => {
        this.setState({ selectedData: listValue, showConfirm: !this.state.showConfirm })
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    activeShowData = (data) => {
        this.setState({ selectedData: data, showDetail: true })
    }

    showModal = (data, type) => {
        this.setState({ showConfirm: true, selectedData: data, transferRequestStatus: type })
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    onPresentTypeChange = (key, type) => {
        this.setState({
            checked: key,
            listType: type
        },
            () => this.getAppliedTransferRequestDAta(type)
        )
    }

    render() {
        const { listType, transferRequestStatus, showDetail, showConfirm } = this.state
        const { requestData } = this.props
        return (
            <div>
                <div className='d-flex'>
                    <div className='status-dashboard mt-3 ml-4'>Status :</div>
                    {requestData.length != 0 && requestData.map((list, key) => {
                        return (
                            <div key={key} className='mx-4 mt-3 mb-2'>
                                {this.state.checked === key ?
                                    <div className='row align-item-center' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                        <img alt="radioOn" className='radio' src={radioOn} />
                                        <label className="radioTxt" >{list.title.charAt(0).toUpperCase() + list.title.slice(1)}</label>
                                        <div className="radioTxt">{('(' + list.value + ')')}</div>
                                    </div>
                                    :
                                    <div className='row align-item-center' onClick={() => this.onPresentTypeChange(key, list.title)}>
                                        <img alt="radioOff" className='radio' src={radioOff} />
                                        <label className="radioTxt">{list.title.charAt(0).toUpperCase() + list.title.slice(1)}</label>
                                        <div className="radioTxt">{('(' + list.value + ')')}</div>
                                    </div>
                                }
                            </div>
                        )
                    })
                    }
                </div>
                <div className="main-contain main-contain-transfer pt-2" >
                    <div className='sm-scroll-table p-2'>
                        {this.state.transferRequestData.length != 0 ?
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col"><div className='thead pr-0' style={{ width: '35px' }}>Sr. No</div></th>
                                        <th scope="col"><div className='thead'>Request No</div></th>
                                        <th scope="col"><div className='thead'>Employee Name</div></th>
                                        <th scope="col"><div className='thead'>Releasing BHR</div></th>
                                        <th scope="col"><div className='thead'>Transfer Date</div></th>
                                        <th scope="col"><div className='thead'>Reason</div></th>
                                        <th scope="col"><div className='thead'>Position Code</div></th>
                                        <th scope="col"><div className='thead'>Status</div></th>
                                        <th scope="col"><div className='thead'>Action</div></th>
                                        {listType === 'pending' &&
                                            <th scope="col"><div className='thead'>Approve/Rejected</div></th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transferRequestData.map((listValue, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                                <td><div className="td" >{listValue.transfer_request_no ? listValue.transfer_request_no : ''}</div></td>
                                                <td><div className="td">{listValue.transfer_emp_name ? listValue.transfer_emp_name : ''}</div></td>
                                                <td> <div className="td d-flex">{listValue.approving_bhr ? listValue.approving_bhr : ''}</div> </td>
                                                <td><div className="td" >{moment(listValue.proposed_date_of_transfer).format("DD-MM-YYYY")}</div></td>
                                                <td><div className="td" style={{ whiteSpace: 'pre-line' }} >{listValue.reason_for_transfer ? listValue.reason_for_transfer : ''}</div></td>
                                                <td><div className="td" >{listValue.position_code ? listValue.position_code : ''}</div></td>
                                                <td><div className="td">{listValue.request_status.charAt().toUpperCase() + listValue.request_status.slice(1)}</div></td>
                                                <td>
                                                    <div onClick={() => this.activeShowData(listValue)}>
                                                        <img src={view_action} style={{ width: '25px' }} className='mx-2' />
                                                    </div>
                                                </td>
                                                {listType === 'pending' &&
                                                    <td className='d-flex ml-2'>
                                                        <div className='row' style={{ width: '70px' }}>
                                                            <div onClick={() => this.showModal(listValue, 'rejected')}>
                                                                <img alt="" src={reject} style={{ width: '25px' }} className='mx-2' />
                                                            </div>

                                                            <div onClick={() => this.showModal(listValue, 'approved')}>
                                                                <img alt="" src={approve} style={{ width: '25px' }} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                    <div>
                                        <img src={noDataTransfer} alt="noDataTransfer" />
                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>No record found!</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {showDetail &&
                    <TransferDetailPopup
                        data={this.state.selectedData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
                {(transferRequestStatus === 'approved') && showConfirm &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={transferRequestStatus}
                        approveData={this.approveRejectTransferRequest}
                        selectedData={this.state.selectedData}
                        onDismiss={() => this.setState({ transferRequestStatus: '', showConfirm: false })}
                    />
                }
                {(transferRequestStatus === 'rejected') && showConfirm &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={transferRequestStatus}
                        rejectData={this.approveRejectTransferRequest}
                        selectedData={this.state.selectedData}
                        onDismiss={() => this.setState({ transferRequestStatus: '', showConfirm: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
    };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(TransferApprovalScreen));