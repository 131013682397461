import * as type from '../../../../redux/action/ActionType';

export const getCOIType = (res) => {
    return{
        type : type.GET_COI_TYPE,
        payload: res
    }
}
export const getCOITypeA1Data = (res) => {
    return{
        type : type.GET_COI_TYPE_A1_DATA,
        payload: res
    }
}
export const getCOITypeA2Data = (res) => {
    return{
        type : type.GET_COI_TYPE_A2_DATA,
        payload: res
    }
}
export const getCOITypeA3Data = (res) => {
    return{
        type : type.GET_COI_TYPE_A3_DATA,
        payload: res
    }
}
export const clearCOITypeData = () => {
    return{
        type : type.CLEAR_COI_TYPE_DATA
    }
}
