import React, { Component } from 'react';
import moment from 'moment';
import '../transfer/Transfer.css';
class QuotaDetailPopup extends Component {
    render() {
        const { data, action } = this.props
        if (!this.props.data) return null;
        return (
            <div className="modal popup-box">
                <div className="modal_inner_box  align-items-center apply_leave_modal">
                    <div className="modal-header">
                        Quota Details
                        <button
                            type="button"
                            className="close pt-4"
                            data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;
                        </button>
                        <div className='solid-border'></div>
                    </div>
                    <div className="modal-body">
                        <div className="row mr-0 p-3">
                            <div className="col-md">
                                <div className='text14_bold '> Quota Type :</div>
                                <div className="text14_light mt-1 ">{action === 'Teams' ? data.leave_category_text : data.leave_quota_text}</div>
                                <hr />
                            </div>

                            <div className="col-md">
                                <div className='text14_bold '> Consumed: </div>
                                <div className="text14_light mt-1 ">{data.total_leaves - data.available_leaves}</div>
                                <hr />
                            </div>

                        </div>

                        {action !== 'Teams' &&
                            <div className="row mr-0 p-3">
                                <div className="col-md">
                                    <div className='text14_bold '> Valid From :</div>
                                    <div className="text14_light mt-1 ">{moment(data.quota_valid_from).format("DD-MM-YYYY")}</div>
                                    <hr />
                                </div>
                                <div className="col-md">
                                    <div className='text14_bold '> Valid To :</div>
                                    <div className="text14_light mt-1 ">{moment(data.quota_valid_to).format("DD-MM-YYYY")}</div>
                                    <hr />
                                </div>
                            </div>
                        }

                        <div className="row mr-0 p-3">
                            <div className="col-md">
                                <div className='text14_bold '>Remaining: </div>
                                <div className="text14_light mt-1 ">{data.available_leaves}</div>
                                <hr />
                            </div>

                            <div className="col-md">
                                <div className='text14_bold '> Entitlement:</div>
                                <div className="text14_light mt-1 ">{data.total_leaves}</div>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default QuotaDetailPopup;