import * as type from '../../../../redux/action/ActionType';

const keycloakState = {
    keycloakData: {},
}

export const keycloak = (state = keycloakState, action) => {
    switch(action.type) {
        case type.GET_TOKEN:
            return {
                ...state,
                keycloakData: action.payload,
            };
        default:
            return state;
    }
}