import React, { Component } from 'react';
import RequestFormCardA1 from '../coiRequestForms/RequestFormCardA1';
import RequestFormCardA2 from '../coiRequestForms/RequestFormCardA2';
import RequestFormCardA3 from '../coiRequestForms/RequestFormCardA3';
import * as Config from '../../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import { toast } from 'react-toastify';
import { coi_type } from '../../../../../utils/Utils';
import { configStore } from "../../../../../redux/ConfigStore";
import * as COIAction from '../COIAction';
import ConfirmFormPopup from '../ConfirmFormPopup';
import { connect } from 'react-redux';
import * as commonMessage from '../../../../../constants/Messages';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
class RequestFormPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            formA1Data: [],
            formA2Data: [],
            formA3Data: [],
            newCOIRequest: [],
            newCOIRequestA1: [],
            newCOIRequestA2: [],
            newCOIRequestA3: [],
            showSubmit: false,
            isSubmitShow: true,
            nextForm: false,
            isVisible: false,
            editA1Form: true,
            editA2Form: true,
            editA3Form: true,
            showConfirmPopup: false,
            typeData: coi_type(),
            typeSelectedData: [],
            activeType: ''
        }
    }
    componentDidMount() {
        let selectType = configStore.getState().coiState.COITypeData
        // console.log('selectType compoundDIdMount------->',selectType);
        if (selectType.length != 0) {
            this.setState({
                activeType: selectType[0],
                typeSelectedData: selectType
            })
            if (selectType.includes('A1')) {
                this.addAnotherFormAdata('A1')
            }
            if (selectType.includes('A2')) {
                this.addAnotherFormAdata('A2')
            }
            if (selectType.includes('A3')) {
                this.addAnotherFormAdata('A3')
            }
            else return null
        }
    }

    addAnotherFormAdata = (type) => {
        // console.log('addAnotherFormAdata doted------>',...this.state.formA1Data);
        // console.log('addAnotherFormAdata------>',this.state.formA1Data);
        if (type === 'A1') {
            this.setState({
                formA1Data: [...this.state.formA1Data, ''],
                editA1Form: true,
                isVisible: true,
                showSubmit: false
            })
            if (this.state.formA1Data.length === 0) {
                this.setState({
                    isVisible: false
                });
            }
        }
        else if (type === 'A2') {
            this.setState({
                formA2Data: [...this.state.formA2Data, ''],
                editA2Form: true,
                isVisible: true,
                showSubmit: false
            })
            if (this.state.formA2Data.length === 0) {
                this.setState({
                    isVisible: false
                });
            }
        }
        else if (type === 'A3') {
            this.setState({
                formA3Data: [...this.state.formA3Data, ''],
                editA3Form: true,
                isVisible: true,
                showSubmit: false
            })
            if (this.state.formA3Data.length === 0) {
                this.setState({
                    isVisible: false
                });
            }
        }
        else return null
    }
    removeFormData = (type) => {
        let actualData
        if (type === 'A1') {
            actualData = this.state.formA1Data.splice(1)
            this.setState({
                formA1Data: actualData,
                editA1Form: false,
            });
        }
        else if (type === 'A2') {
            actualData = this.state.formA2Data.splice(1)
            this.setState({
                formA2Data: actualData,
                editA2Form: false,
            });
        }
        else if (type === 'A3') {
            actualData = this.state.formA3Data.splice(1)
            this.setState({
                formA3Data: actualData,
                editA3Form: false,
            });
        }
        else return null
    }
    validateData = (value, type) => {
        if (!value) {
            this.isLastForm(type)
            if (type === 'A1') {
                this.setState({ editA1Form: false })
                configStore.dispatch(COIAction.getCOITypeA1Data(this.state.formA1Data))
            }
            else if (type === 'A2') {
                this.setState({ editA2Form: false })
                configStore.dispatch(COIAction.getCOITypeA2Data(this.state.formA2Data))
            }
            else if (type === 'A3') {
                this.setState({ editA3Form: false })
                configStore.dispatch(COIAction.getCOITypeA3Data(this.state.formA3Data))
            }
        }
    }
    isLastForm = (type) => {
        const { typeSelectedData } = this.state
        if (typeSelectedData[typeSelectedData.length - 1] === type) {
            this.setState({ showSubmit: true })
        } else {
            this.setState({ showSubmit: false })
        }
    }

    submitCOIRequest = () => {
        console.log('login state--------------->', this.props.loginData.userData)
        const a1Typelist = configStore.getState().coiState.COITypeA1Data
        const a2Typelist = configStore.getState().coiState.COITypeA2Data
        const a3Typelist = configStore.getState().coiState.COITypeA3Data
        let body = {
            a1_type_data: a1Typelist,
            a2_type_data: a2Typelist,
            a3_type_data: a3Typelist,
            emp_pa: this.props.loginData.userData.PersArea,
            emp_psa: this.props.loginData.userData.PSubarea,
            emp_region: this.props.loginData.userData.RegCod,
            company_code: this.props.loginData.userData.CompCode,
            organization_unit: this.props.loginData.userData.Orgtx,
            emp_esubgroup: this.props.loginData.userData.Esubgroup,
            emp_doj: this.props.loginData.userData.Doj,
            emp_name: this.props.loginData.userData.CompName
        }
        
        this.setState({ loading: true, showConfirmPopup: false, isSubmitShow: false })
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.submitCOIDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    configStore.dispatch(COIAction.clearCOITypeData())
                    window.location.reload()
                }
                else {
                    this.setState({ loading: false })
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                }
            }).catch(err => {
                toast.error(commonMessage.requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                this.setState({ loading: false })
            })
    }
    backFromRequest = (type) => {
        if (type === 'A3' && this.state.typeSelectedData.includes('A2')) {
            this.setState({ activeType: 'A2' })
        }
        else if (type === 'A3' && this.state.typeSelectedData.includes('A1')) {
            this.setState({ activeType: 'A1' })
        }
        else if (type === 'A2' && this.state.typeSelectedData.includes('A1')) {
            this.setState({ activeType: 'A1' })
        }
        else {
            this.props.backFromRequest()
        }
    }
    next = (type) => {
        if (type === 'A1' && this.state.typeSelectedData.includes('A2')) {
            this.setState({ activeType: 'A2' })
        }
        else if (type === 'A1' && this.state.typeSelectedData.includes('A3')) {
            this.setState({ activeType: 'A3' })
        }
        else if (type === 'A2' && this.state.typeSelectedData.includes('A3')) {
            this.setState({ activeType: 'A3' })
        }
        else {
            this.setState({ showSubmit: true })
        }
    }
    onDismiss = () => {
        this.setState({ showConfirmPopup: false })
    }

    render() {
        const { showSubmit, isSubmitShow, activeType, editA1Form, editA2Form, editA3Form } = this.state
        const { relationshipData } = this.props
        const A1Typelist = configStore.getState().coiState.COITypeA1Data
        const A2Typelist = configStore.getState().coiState.COITypeA2Data
        const A3Typelist = configStore.getState().coiState.COITypeA3Data
        // console.log('this.state.formA1Data------>',this.state.formA1Data);
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content bg-white">
                        <div className="modal-header mx-2">
                            <div className='flex-direction-column'>
                                <div className='row justify-content-start'>
                                    <h4 className="popup-title mt-2">COI Request Form</h4>
                                </div>
                            </div>
                            <div className=''>
                                <button type="button" className="close pt-4" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            </div>
                        </div>
                        <div className="modal-body h-700px">
                            {activeType === 'A1' &&
                                <div>
                                    {this.state.formA1Data.map(({ }, index) => {
                                        return (
                                            <RequestFormCardA1
                                                key={index}
                                                loginData={this.props.loginData}
                                                listType={A1Typelist[index]}
                                                relationshipDataArray={relationshipData}
                                                type={activeType}
                                                typeDescripton={this.state.typeData[0].value + this.props.loginData.company.company_sht_txt + '.'}
                                                COIRequest={this.state.newCOIRequestA1}
                                                length={this.state.formA1Data.length}
                                                index={index}
                                                validateForm={this.state.validateA1}
                                                isVisible={this.state.isVisible}
                                                next={() => this.next('A1')}
                                                isedit={editA1Form}
                                                isValidate={(data) => this.validateData(data, 'A1')}
                                                removeCOIData={() => this.removeFormData('A1')}
                                                saveCOIA1Request={(data) => this.setState({ formA1Data: data })}
                                                onDismiss={this.props.onDismiss}
                                            />
                                        )
                                    })}
                                </div>
                            }
                            {activeType === 'A2' &&
                                <div>
                                    {this.state.formA2Data.map(({ }, index) => {
                                        return (
                                            <RequestFormCardA2
                                                key={index}
                                                loginData={this.props.loginData}
                                                listType={A2Typelist[index]}
                                                relationshipDataArray={relationshipData}
                                                type={activeType}
                                                typeDescripton={this.state.typeData[1].value}
                                                COIRequest={this.state.newCOIRequestA2}
                                                length={this.state.formA2Data.length}
                                                index={index}
                                                isVisible={this.state.isVisible}
                                                isedit={editA2Form}
                                                next={() => this.next('A2')}
                                                isValidate={(data) => this.validateData(data, 'A2')}
                                                removeCOIData={() => this.removeFormData('A2')}
                                                saveCOIA2Request={(data) => this.setState({ formA2Data: data })}
                                                onDismiss={this.props.onDismiss}
                                            />
                                        )
                                    })}
                                </div>
                            }
                            {activeType === 'A3' &&
                                <div>
                                    {this.state.formA3Data.map(({ }, index) => {
                                        return (
                                            <RequestFormCardA3
                                                key={index}
                                                loginData={this.props.loginData}
                                                listType={A3Typelist[index]}
                                                relationshipDataArray={relationshipData}
                                                type={activeType}
                                                typeDescripton={this.state.typeData[2].value}
                                                COIRequest={this.state.newCOIRequestA3}
                                                length={this.state.formA3Data.length}
                                                index={index}
                                                isVisible={this.state.isVisible}
                                                isedit={editA3Form}
                                                next={() => this.next('A3')}
                                                isValidate={(data) => this.validateData(data, 'A3')}
                                                removeCOIData={() => this.removeFormData('A3')}
                                                saveCOIA3Request={(data) => this.setState({ formA3Data: data })}
                                                onDismiss={this.props.onDismiss}
                                            />
                                        )
                                    })}
                                </div>
                            }
                            <div className="justify-content-end align-item-center mb-2">
                                {(activeType === 'A1' && !editA1Form) || (activeType === 'A2' && !editA2Form) || (activeType === 'A3' && !editA3Form) ?
                                    <div className='mr-4'>
                                        {showSubmit ?
                                            <>
                                                {isSubmitShow &&
                                                    <div
                                                        type='button'
                                                        className='popup-confirm-btn'
                                                        onClick={() => this.submitCOIRequest()}
                                                    >
                                                        Submit
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div
                                                type='button'
                                                className='popup-confirm-btn'
                                                onClick={() => this.next(activeType)}
                                            >
                                                Proceed
                                            </div>
                                        }
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showConfirmPopup &&
                    <ConfirmFormPopup
                        onDismiss={this.onDismiss}
                        action={'submit'}
                        saveDetails={this.submitCOIRequest} />}
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}

export default connect(mapStatesToProps)(RequestFormPopup);