import React, { useEffect, useState } from 'react'
import { applicantDetailsForm, applicantStatus, approveNote, claimDetailsForm, claimPrimaryKeys, expenseTableCols, rejectNote, tdStyle } from './constants'
import { constantValues, employeeDetailsForm } from '../../managerScreens/approval/constant'
import Dropdown from '../../../shared/components/Dropdown'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import right_button_arrow from '../../../../assets/drishti_images/right-button-arrow.svg'
import { admincheckapprovalstatus, checkapprovalstatus, getClaimAttachmentsAdmin, getComponentDetailsAdmin, getConveyanceListAdmin, updateClaimAdmin } from '../../../../constants/api.service'
import { toaster } from '../../../../utils/Utils'
import NewNotdata from '../../../shared/notFound/NewNoData'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import ExpenseDetailsPopUp from './ExpenseDetailsPopUp'
import ConfirmPopup from '../../managerScreens/ConfirmPopup'
import RejectionPopUp from './RejectionPopUp'
import moment from 'moment'
import * as Config from '../../../../constants/Config';
import { fetchApiCall } from '../../../../constants/Service';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';
import DynamicDropdown from '../../../shared/components/DynamicDropdown'
import { Pagination } from 'react-bootstrap';

function AdminApproval(props) {
  const [approvedChecked, setApprovedChecked] = useState({ id: "PENDING_BY_ADMIN", title: 'Pending for Admin approval' })
  const [companyData, setCompanyData] = useState([])
  const [companyValue, setCompanyValue] = useState(['Select company', ''])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])
  const [showListingPage, setShowListingPage] = useState(true)
  const [showDetailsPage, setShowDetailsPage] = useState(false)
  const [selectedClaim, setSelectedClaim] = useState("")
  const [expenseDetails, setExpenseDetails] = useState([])
  const [expense, setExpense] = useState("")
  const [billDetails, setBillDetails] = useState([])
  const [isExpensePopUpOpen, setIsExpensePopUpOpen] = useState(false)
  const [openConfirmPopUp, setOpenConfirmPopUp] = useState(false)
  const [action, setAction] = useState("")
  const [rejectionReason, setRejectionReason] = useState(null)
  const [isRejectionPopUpOpen, setIsRejectionPopUpOpen] = useState(false)
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const [empID, setEmpID] = useState('')
  const [approvedNote, setApprovedNote] = useState('')

  const statusList = [
    "Pending for Admin approval",
    "Approved by Admin",
    "Claim Approved & sent to Accountant for settlement",
    "Claim Settled"
  ];

  const isStatusIncluded = statusList.includes(selectedClaim['claim_status']);

  const filteredCols = expenseTableCols.filter(col => {
    if (col.title === 'Approved Amount' && !isStatusIncluded) {
      return false;
    }
    return true;
  });

  useEffect(() => {
    getCompanyNames()
    fetchClaimDetails(approvedChecked.title, currentPage)
  }, [])

  const onChangedApprovalStatus = (data) => {
    setApprovedChecked(data)
    setCurrentPage(1)
    fetchClaimDetails(data.title, 1)
  }

  const goToDetailsPage = (dataValue) => {
    setSelectedClaim(dataValue)
    fetchClaimComponents(dataValue.claim_id, dataValue.emp.Perno)
  }

  const morePage = () => {
    let updatedPage = currentPage + 1
    setCurrentPage(updatedPage)
    fetchClaimDetails(approvedChecked.title, updatedPage)
  }

  const backAction = () => {
    setShowListingPage(true)
    setShowDetailsPage(false)
  }

  const rejectionCloseAction = () => {
    setIsRejectionPopUpOpen(false)
    setRejectionReason(null)
  }

  const handleRejection = () => {
    setIsRejectionPopUpOpen(false)
    setOpenConfirmPopUp(true)
  }

  //--------Get Company Names API-----------------------------
  const getCompanyNames = () => {
    setLoading(true)
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    fetchApiCall(Config.getCompanyNames, {}, headers, true, 'service')
      .then(res => {
        if (res?.data?.status_code == "200") {
          let comp_codes = []
          res.data?.company_list && res.data.company_list.map((comp) => {
            comp_codes.push({ code: comp.company_code, value: comp.company_sht_txt })
          })
          setCompanyData(comp_codes)
        }
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log("company code error", err);
      })
  }

  const fetchClaimDetails = (status, pageNo) => {
    setLoading(true)
    let params = {
      page_number: pageNo,
      from_claim_date: fromDate ? fromDate : '',
      to_claim_date: toDate ? toDate : '',
      claimant_comp_code: companyValue[1] ? companyValue[1] : [],
      search_text: empID ? empID : ''
    }
    getConveyanceListAdmin(params, props, status).then((res) => {
      if (res.status === 200 || res.status === 201) {
        if (res.data.status == "401") {
          setTotalPages(1)
          setCurrentPage(1)
          setPaginatedData([])
          setLoading(false)
        }
        else {
          // Filter data based on date range
          let filteredData = res?.data?.data?.paginated_data
          setTotalPages(res?.data?.data?.total_pages);
          setCurrentPage(res?.data?.data?.page_number);
          setPaginatedData(filteredData);
          setLoading(false);
        }
      }
      else {
        setTotalPages(1)
        setCurrentPage(1)
        setPaginatedData([])
        setLoading(false)
        toaster("warning", res?.data?.message ? res?.data?.message : "")
      }
    }).catch((err) => {
      setTotalPages(1)
      setCurrentPage(1)
      setPaginatedData([])
      setLoading(false)
      toaster("warning", err?.data?.message ? err?.data?.message : "")
    })
  }

  const fetchClaimComponents = (claimId, applicantId) => {
    setLoading(true)
    let params = {
      claim_id: claimId,
      emp_id: applicantId
    }
    getComponentDetailsAdmin(params, props).then(
      (res) => {
        if (res.status == 200) {
          setExpenseDetails(res?.data?.data)
          setShowDetailsPage(true)
          setShowListingPage(false)
        }
        else if (res.status == 404) {
          setExpenseDetails([])
        }
        setLoading(false)
      }
    ).catch((err) => {
      setLoading(false)
      console.log("claim_components error ", err)
    })
  }

  const fetchAttachments = (id) => {
    setLoading(true)
    let params = {
      component_id: id,
      emp_id: selectedClaim.emp.Perno
    }
    getClaimAttachmentsAdmin(params, props).then(
      (res) => {
        if (res.status == 200) {
          setBillDetails(res?.data?.data)
        }
        else if (res.status == 404) {
          setBillDetails([])
        }
        setLoading(false)
      }
    ).
      catch((err) => {
        setLoading(false)
        console.log("claim_component_attachments error", err)
      })
  }

  const updateClaim = (claimId, action, remark) => {
    setLoading(true)
    let reqBody = {
      // claim_id: claimId,
      claim_action: action,
      admin_rejection_remark: remark ?? null
    }
    updateClaimAdmin(reqBody, props, claimId).then((res) => {
      if (res.status === 200) {
        if (res.data.status == "401") {
          toaster("error", `Claim Updation failed`)
        }
        else {
          { action == "Approve" ? toaster("success", `Claim Approved Successfully`) : toaster("success", "Claim Rejected Successfully") }
          setOpenConfirmPopUp(false)
          setShowDetailsPage(false)
          setPaginatedData([])
          setSelectedClaim("")
          setExpenseDetails([])
          setExpense("")
          setBillDetails([])
          setShowListingPage(true)
          setApprovedChecked({ id: "PENDING_BY_ADMIN", title: 'Pending for Admin approval' })
          fetchClaimDetails('Pending for Admin approval', 1)
        }
      }
      else {
        toaster("error", `Claim Updation failed`)
      }
      setLoading(false)
    }).catch((err) => {
      console.log("claim patch err", err)
      setLoading(false)
    })
  }

  const checkApproval = () => {
    setLoading(true)
    const param = {
      employee_id: selectedClaim?.emp.Perno,
      screen_type: 'Admin',
      from_date: selectedClaim?.from_date,
      to_date: selectedClaim?.to_date,
      travel_type: selectedClaim?.travel_type,
    }
    admincheckapprovalstatus(param)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setApprovedNote(res?.data?.message)
        }
        setAction("Approve");
        setOpenConfirmPopUp(true);
        setLoading(false)
      })
      .catch((error) => {
        setAction("Approve");
        setOpenConfirmPopUp(true);
        setLoading(false)
      });
  }

  const handlePageCount = (action) => {
    if (action === "decrement" && currentPage > 1) {
      let tempCurrentPage = currentPage - 1
      setCurrentPage(tempCurrentPage);
      fetchClaimDetails(approvedChecked.title, tempCurrentPage)
    } else if (action === "increment" && currentPage < totalPages) {
      let tempCurrentPage = currentPage + 1
      setCurrentPage(tempCurrentPage);
      fetchClaimDetails(approvedChecked.title, tempCurrentPage)
    }
  };

  return (
    <div className='h-71vh'>
      <div className='m-10px'>
        <LoaderIndicator loading={loading} />

        {openConfirmPopUp == true &&
          <ConfirmPopup
            title={'Confirmation'}
            content={action == "Approve" ? approveNote : rejectNote}
            approveNoteContent={approvedNote}
            action={'confirm'}
            LocalConveyanceConfirmApporval={() => { updateClaim(selectedClaim.claim_id, action, rejectionReason); setApprovedNote('') }}
            onDismiss={() => { setAction(""); setRejectionReason(null); setOpenConfirmPopUp(false); setApprovedNote('') }}
          />
        }

        {isRejectionPopUpOpen == true &&
          <RejectionPopUp
            closeAction={rejectionCloseAction}
            setReason={(e) => { setRejectionReason(e.target.value) }}
            reason={rejectionReason}
            rejectApproval={handleRejection}
          />
        }

        <ExpenseDetailsPopUp
          visible={isExpensePopUpOpen}
          expense={expense}
          billDetails={billDetails}
          closeAction={() => { setIsExpensePopUpOpen(false); setBillDetails([]) }}
        />

        {showListingPage == true &&
          <>
            <div className='d-flex row justify-content-between background-highlighter'>
              <div className='row'>
                <div className='ml-3'>
                  <div className='p-sub-heading ml-2 mb-1'>{'From Date'}</div>
                  <div className='row align-item-center'>
                    <div className='p-placeholder-text mb-1'>
                      <input
                        className="form-control p-placeholder px-2 p-placeholder-admin"
                        type="date"
                        placeholder='Select the date'
                        onChange={(e) => setFromDate(e.target.value)}
                        value={fromDate}
                        onKeyDown={(e) => e.preventDefault()}
                      // max={toDate}
                      />
                    </div>
                  </div>
                </div>

                <div className='ml-3'>
                  <div className='p-sub-heading mb-1 ml-2'>{'To Date'}</div>
                  <div className='row align-item-center'>
                    <div className='p-placeholder-text mb-1'>
                      <input
                        className="form-control p-placeholder px-2 p-placeholder-admin"
                        type="date"
                        placeholder='Select the date'
                        onChange={(e) => setToDate(e.target.value)}
                        value={toDate}
                        onKeyDown={(e) => e.preventDefault()}
                      // min={fromDate}
                      />
                    </div>
                  </div>
                </div>

                <div className='ml-3'>
                  <div className='p-sub-heading mb-1 ml-2'>{'Select Company'}</div>
                  <div className='column align-item-center'>
                    <div className='p-placeholder-text mb-1'>
                      <Dropdown
                        data={companyData}
                        selectedValue={(selectedItem) => { setCompanyValue([selectedItem.value, selectedItem.code]) }}
                        value={companyValue[0]}
                        render={'value'}
                        arrow={true}
                        height={'innerCard'}
                      />
                    </div>
                  </div>
                </div>

                <div className='ml-3'>
                  <div className='p-sub-heading mb-1 ml-2'>{constantValues.Status}</div>
                  <div className='d-flex align-item-center'>
                    <DynamicDropdown
                      data={applicantStatus}
                      selectedValue={(data) => onChangedApprovalStatus(data)}
                      value={approvedChecked.title ? approvedChecked.title : 'Select status'}
                      render={'title'}
                      arrow={'true'}
                      height={'NotificationHeight'}
                      width={"w-35rem"}
                    />
                  </div>
                </div>

                <div className='row align-item-center mt-4'>
                  <input
                    type="button"
                    value='SEARCH'
                    className='btn validateBtn mb-1 ml-3'
                    onClick={() => { fetchClaimDetails(approvedChecked.title, 1) }} />
                </div>
              </div>

              <div className='row mt-4'>
                <div className='p-placeholder-text mb-1 mr-3'>
                  <input
                    className="form-control p-placeholder px-2 p-placeholder-admin"
                    type="text"
                    placeholder='Search'
                    onChange={(e) => setEmpID(e.target.value)}
                    value={empID}
                  />
                </div>
                <div type='button' className='serchIconSize' onClick={() => fetchClaimDetails(approvedChecked.title, 1)}>
                  <div>
                    <img alt="radioOn" className='filterIcon' src={searchIcon} />
                  </div>
                </div>
              </div>

            </div>

            <div className='table-container ml-3 mt-10px summary-table'>
              {paginatedData?.length > 0 ?
                paginatedData.map((dataValue, idx) => {
                  return (
                    <div key={idx} className='document_card p-10px m-10px pointer flex-direction-row justify-content-between align-item-center-noflex'
                      onClick={() => { goToDetailsPage(dataValue) }}>
                      <span>
                        <span className='text16_bold project-list-style mx-10px '>
                          {dataValue?.claim_id}
                        </span>
                        <div className='flex-direction-row'>
                          {claimPrimaryKeys.map((data, idx) => {
                            return (
                              <span key={idx} className='mx-10px'>
                                <span className='appcenter-app-name'>{data.value}&nbsp;&nbsp;</span>
                                <span className='feedback-sm-text'>{
                                  data.key == "emp" ? (dataValue[data.key]["CompName"] ?? "NA") : data.type == "date" ? (moment(dataValue[data.key]).format("DD-MM-YYYY") ?? "NA") : (dataValue[data.key] ?? "NA")
                                }</span>
                              </span>
                            )
                          })}
                        </div>
                      </span>
                      <span className='mr-10'>
                        <img alt="go to details" className='' src={right_button_arrow} />
                      </span>
                    </div>
                  )
                })
                :
                <span className='justify-content-center align-item-center-noflex flex_1 h-100'>
                  <NewNotdata />
                </span>
              }
              <div className="col-md-12 w-100 d-flex justify-content-center">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => { handlePageCount("decrement") }} />
                  {currentPage != 1 &&
                    <Pagination.Item disabled onClick={() => setCurrentPage(1)}>{1}</Pagination.Item>
                  }
                  <Pagination.Ellipsis disabled />
                  <Pagination.Item active>{currentPage}</Pagination.Item>
                  <Pagination.Ellipsis disabled />
                  {currentPage != totalPages &&
                    <Pagination.Item disabled onClick={() => setCurrentPage(totalPages)} >{totalPages}</Pagination.Item>
                  }
                  <Pagination.Next
                    onClick={() => { handlePageCount("increment") }} />
                </Pagination>
              </div>
            </div>
          </>
        }

        {showDetailsPage == true &&
          <>
            <div className='justify-content-between flex-direction-row ml-10px mt-10px mb-15px mr-20px'>
              <span className='align-item-center'>
                <span onClick={() => { backAction() }}>
                  <img alt="Back" className='mr-10 pointer' src={backArrow} />
                </span>
                <span class="text1_25rem-bold">{selectedClaim != "" ? selectedClaim.claim_id : 'NA'}</span>
              </span>
            </div>

            <div className='toggleStyle mt-1rem max-h-53vh scroll-auto'>
              <>
                <div className='flex-direction-column mb-1 w-97'>
                  <div class="m-2 card-heading w-97">{employeeDetailsForm.EmployeeDetails}</div>
                  <div className='details pb-10px ml-2'>
                    {applicantDetailsForm.map((field) => {
                      return (
                        <div className='detail-field-approve'>
                          <div className='details-lbl'>{field.label} :</div>
                          <div className='field-value'>{selectedClaim.emp[field.key]}</div>
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
                <div className='flex-direction-column mb-1 w-97'>
                  <div class="m-2 card-heading w-97">DEM Details</div>
                  <div className='details pb-10px ml-2'>
                    {applicantDetailsForm.map((field) => {
                      return (
                        <div className='detail-field-approve'>
                          <div className='details-lbl'>{field.label} :</div>
                          <div className='field-value'>{selectedClaim.dem[field.key]}</div>
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
                {selectedClaim?.is_miscellaneous == true && selectedClaim?.misc_approver != null &&
                  <div className='flex-direction-column mb-1 w-97'>
                    <div class="m-2 card-heading w-97">L3 & Above Details</div>
                    <div className='details pb-10px ml-2'>
                      {
                        applicantDetailsForm.map((field) => {
                          return (
                            <div className='detail-field-approve'>
                              <div className='details-lbl'>{field.label} :</div>
                              <div className='field-value'>{selectedClaim?.misc_approver[field.key]}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
                <div className='flex-direction-column mb-1 w-97'>
                  <div class="m-2 card-heading w-97">{employeeDetailsForm.claimDetails}</div>
                  <div className='details pb-10px ml-2'>
                    {claimDetailsForm.map((field) => {
                      const tempValue = selectedClaim[field.key];
                      if ((field.key === "dem_rejection_remark" || field.key === "misc_rejection_remark" || field.key === "admin_rejection_remark") && tempValue === null) {
                        return null;
                      }
                      if (field.key === 'total_approved_claim_amount') {
                        const claimStatus = selectedClaim['claim_status'];
                        if (!(claimStatus === "Pending for Admin approval" || claimStatus === "Approved by Admin" || claimStatus === "Claim Approved & sent to Accountant for settlement" || claimStatus === "Claim Settled")) {
                          return null;
                        }
                      }
                      return (
                        <div className='detail-field-approve'>
                          <div className='details-lbl'>{field.label} :</div>
                          <div className='field-value'>{field.type == "date" ? moment(selectedClaim[field.key]).format('DD-MM-YYYY') ?? "NA"
                            : selectedClaim[field.key] !== null ? selectedClaim[field.key] : "NA"}</div>
                        </div>
                      )
                    })
                    }
                  </div>
                </div>

                <div className='flex-direction-column mb-1 w-97'>
                  <div class="m-2 card-heading w-97">Expense Details</div>
                  <div className='table-container px-10px mt-10px '>
                    {expenseDetails?.length > 0 ?
                      <div className="main-contain">
                        <div className="row align-item-center justify-content-start ">
                          <table className="table gemsAdmin">
                            <thead className='w-100'>
                              <tr className='w-100'>
                                {filteredCols.map((col) => { return (<th width={col.width}><div className='thead t-head'>{col.title}</div></th>) })}
                              </tr>
                            </thead>
                            <tbody className='w-100'>
                              {
                                expenseDetails.map((row, index) => {
                                  return (
                                    <tr>
                                      <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                      <td width='10%'><div className={tdStyle}>{row.expense_type ? row.expense_type : 'NA'}</div></td>
                                      <td width='10%'><div className={tdStyle}>{row.sub_expense_type1 ? row.sub_expense_type1 : 'NA'}</div></td>
                                      <td width='10%'><div className={tdStyle}>{row.sub_expense_type2 ? row.sub_expense_type2 : 'NA'}</div></td>
                                      <td width='10%'><div className={tdStyle}>{row.total_expense_amount ? row.total_expense_amount : 'NA'}</div></td>
                                      <td width='10%'><div className={tdStyle}>{(row?.expense_type == 'Accommodation allowance' || row?.expense_type == 'Daily allowance' || row?.sub_expense_type1 == 'Own vehicle') ? row?.permissible_expense_amount != null ? row.permissible_expense_amount : 'NA' : "NA"}</div></td>
                                      <td width='10%'><div className={tdStyle}>{row.approvable_expense_amount !== null ? row.approvable_expense_amount : 'NA'}</div></td>
                                      {statusList.includes(selectedClaim['claim_status']) &&
                                        <td> <div className="td d-flex">{row?.approved_expense_amount !== null ? row.approved_expense_amount : "NA"}</div> </td>}
                                      <td width='10%'><div className={tdStyle}>{row.is_claim_expense_approved ? "Approved" : 'Rejected'}</div></td>
                                      <td width='5%'>
                                        <span className='d-flex'>
                                          <div
                                            onClick={() => { fetchAttachments(row.id); setExpense(row); setIsExpensePopUpOpen(true) }}
                                          >
                                            <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                          </div>
                                        </span>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      :
                      <div className='mt-5 h-90-percent'>
                        <DataNotFound />
                      </div>
                    }
                  </div>
                </div>
              </>
            </div>

            {
              approvedChecked?.id == "PENDING_BY_ADMIN" &&
              <div className='d-flex justify-content-end align-item-center mt-4 p-10px '>
                <button
                  type="submit"
                  className={(selectedClaim && selectedClaim.emp) ? 'enable_cancel_btn mx-10px' : 'disable_cancel_btn mx-10px'}
                  value="REJECT"
                  disabled={(selectedClaim && selectedClaim.emp) ? false : true}
                  onClick={() => { setAction("Reject"); setIsRejectionPopUpOpen(true) }}>
                  {constantValues.REJECT}
                </button>

                <button
                  type="submit"
                  className={(selectedClaim && selectedClaim.emp) ? 'enableBtncheck mx-10px' : 'disableBtncheck mx-10px'}
                  value="APPROVE"
                  disabled={(selectedClaim && selectedClaim.emp) ? false : true}
                  onClick={() => checkApproval()}>
                  {constantValues.APPROVE}
                </button>
              </div>
            }
          </>
        }
      </div>
    </div >
  )
}

export default AdminApproval