import React, { Component, Fragment } from 'react'
import delete_popup from '../../../../assets/images/cfm/delete_popup.svg';
import SuccessButton from '../commonButton/SuccessButton';
import CancelButton from '../commonButton/CancelButton';
import { viewDetailsFromApplicant, applicantDetail, applicants, valueLimits, buttonName, gemsApplicant } from '../../../ScreenType/employeeScreens/gems/Constant'
import '../../../ScreenType/employeeScreens/gems/HomeScreen.css'
import CharacterCounter from '../../CharacterCounter';
import { getMTRFR, downloadApplicantCV, fetchMTRSummary, fetchFRSummary } from '../../../ScreenType/employeeScreens/gems/Gems.api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { project } from '../../../../constants/Config';
import { warnings } from '../../../../../src/components/ScreenType/employeeScreens/gems/Constant';
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction';
import moment from 'moment';

const pgDetails = "Project Details"
const applicantDetails = "Applicant Details"
class GemsConfirmPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mtrFRView: false,
            mtrFRViewData: [],
            loading: false,
            selectedView: this.props.appliedProjectsDetail ? this.props.appliedProjectsDetail : ''
        }
    }
    SendResponse = (type) => {
        this.props.respocType(type)
    }
    showObjective = (id) => {
        this.setState({
            mtrFRView: true,
            loading: true
        })
        getMTRFR(id, this.props)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        mtrFRViewData: res?.data?.data ? res.data.data : [],
                    })
                }
                else {
                    this.setState({ mtrFRViewData: [] })
                }
            })
            .catch((err) => {
                console.log("getMTRFR catch error>>", err)
            })

    }

    downloadCV = (projectID, applicantID) => {
        console.log("downloadCVdownloadCVdownloadCV")
        this.setState({
            loading: true
        })
        downloadApplicantCV(projectID, applicantID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("downloadApplicantCV catch err>>", err)
            })
    }

    downloadMTRdoc = (projectID) => {
        console.log("downloadPGMTRdocCall")
        this.setState({
            loading: true
        })
        fetchMTRSummary(projectID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchMTRSummary catch err>>", err)
            })
    }

    downloadFRdoc = (projectID) => {
        console.log("downloadPGFRdocCall")
        this.setState({
            loading: true
        })
        fetchFRSummary(projectID)
            .then((res) => {
                this.setState({
                    loading: false
                })
                if (res) {
                    commonDownloadFile(res.data.data.attachment, res.data.message)
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
                console.log("fetchFRSummary catch err>>", err)
            })
    }
    render() {
        // console.log("this.props.appliedProjects:", this.props.appliedProjects)
        // console.log("selectedView", this.state.selectedView)
        const { selectedView } = this.state

        return (
            <div className="modal popup-box" >
                <LoaderIndicator loading={this.state.loading} />
                <div className={`popupCard-box ${this.props.width === "small" ? "w-30" : this.props.width === "large" ? "w-70" : ""}`}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{this.props.header}</h4>
                        <button type="button" className="close" data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;</button>
                    </div>
                    <div className={`modal-body m-15px ${this.props.type === "detail_view" || this.props.type === "project_detail_view" ? "h-52vh scroll-auto" : ""}`}>
                        {this.props.type === "delete" &&
                            <div className='flex-direction-column justify-content-center align-item-center-noflex h-100'>
                                <img
                                    alt="delete_popup"
                                    src={delete_popup}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text'>
                                    {this.props.message}
                                </div>
                            </div>
                        }
                        {this.props.type === "detail_view" &&
                            <>
                                <div>
                                    <div className='coi-text status-background-styles'>
                                        <div className='coi-text ml-2'>{pgDetails}</div>
                                    </div>
                                    <div className='ml-3 mt-3'>
                                        <span className='flex-direction-column'>

                                            <span className='container-project-details px-10px'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.pgName}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.project_guide?.CompName ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.projectObj}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.title ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.projectLocation}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.location ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.pgLevel}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.project_guide?.Esgtxt ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.ou}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.org_unit?.org_unit ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.projectArea}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.area?.area ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.no_of_openings ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationsReceived}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.appl_received ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistBySPOC}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.appl_shrtlted_by_spoc ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistByPG}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.appl_shrtlted_by_pg ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.startDate}</span><span className='feedback-sm-text ml-5px'>{selectedView.project.actual_start_date ? moment(selectedView.project.actual_start_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{gemsApplicant.endDate}</span><span className='feedback-sm-text ml-5px'>{selectedView.project.actual_end_date ? moment(selectedView.project.actual_end_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Project Status:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.status ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Mid Term Review Date:"}</span><span className='feedback-sm-text ml-5px'>{selectedView.project.actual_mtr_date ? moment(selectedView.project.actual_mtr_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Final Review Date:"}</span><span className='feedback-sm-text ml-5px'>{selectedView.project.actual_fr_date ? moment(selectedView.project.actual_fr_date).format("DD-MM-YYYY") : '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Mid Term Status:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.mtr_status ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Mid Term Review Comments:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.mtr_comments ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Mid Term Review Attachment:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.mtr_attachment ?? '-'}</span>
                                                    {selectedView?.project?.mtr_attachment &&
                                                        <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadMTRdoc(selectedView.project.id)} />
                                                    }
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Final Status:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.fr_status ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Final Review Comments:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.fr_comments ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Final Review Attachment:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.fr_attachment ?? '-'}</span>
                                                    {selectedView?.project?.fr_attachment &&
                                                        <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadFRdoc(selectedView.project.id)} />
                                                    }
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Duration:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.duration ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Category:"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.project?.category ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='p-10px flex-direction-row'>
                                                <span className='appcenter-app-name'>{gemsApplicant.description}</span>
                                                <span className='feedback-sm-text ml-5px mt-10px'>{selectedView?.project?.description ?? '-'}</span>
                                            </span>

                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <div className='status-background-styles'>
                                        <div className='coi-text ml-2'>{applicantDetails}</div>
                                    </div>
                                    <div className='ml-3 mt-3'>
                                        <span className='flex-direction-column'>

                                            <span className='container-project-details px-10px'>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicationID}</span><span className='feedback-sm-text ml-5px'>{selectedView?.id ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantID}</span><span className='feedback-sm-text ml-5px'>{selectedView?.applicant?.Perno ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantlocation}</span><span className='feedback-sm-text ml-5px'>{selectedView?.applicant?.LocDesc ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantName}</span><span className='feedback-sm-text ml-5px'>{selectedView?.applicant?.CompName ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantLevel}</span><span className='feedback-sm-text ml-5px'>{selectedView?.applicant?.Esgtxt ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantOU}</span><span className='feedback-sm-text ml-5px'>{selectedView?.applicant?.Orgtx ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"MTR Status :"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.mtr_status ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"MTR Comments :"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.mtr_comments ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"FR Status :"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.fr_status ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='container-project-details px-10px mt-1'>
                                                <span>
                                                    <span className='appcenter-app-name'>{"FR Comments :"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.fr_comments ?? '-'}</span>
                                                </span>
                                                <span>
                                                    <span className='appcenter-app-name'>{"Rejection Reason :"}</span><span className='feedback-sm-text ml-5px'>{selectedView?.rejection_reason ?? '-'}</span>
                                                </span>
                                            </span>
                                            <div className='line-horizontal d-flex hr-line-margin'></div>

                                            <span className='d-flex row container-project-details px-10px mt-1'>
                                                <div>
                                                    <span className='appcenter-app-name'>{applicantDetail.applicantCV}</span>
                                                    <span className='feedback-sm-text ml-5px'>{selectedView?.attachment ?? '-'}</span>
                                                    <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadCV(selectedView.project.id, selectedView.id)} />
                                                </div>
                                            </span>

                                        </span>
                                    </div>
                                </div>
                            </>
                        }
                        {/*----------Popup from Complete project through action button-----------*/}
                        {this.props.type === "project_detail_view" &&
                            <>
                                {!this.state.mtrFRView ?
                                    this.props.appliedProjects.map((dataValue, idx) =>
                                        <Fragment key={idx}>
                                            <div className='justify-content-end' >
                                                <span className='btn btn-primary login-btn pointer'
                                                    onClick={() => this.showObjective(dataValue.id)}
                                                >
                                                    {buttonName.mtrFRObj}
                                                </span>
                                            </div>
                                            <div className='ml-3 mt-3'>
                                                <span className='flex-direction-column'>

                                                    <span className='container-project-details px-10px'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.pgName}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.CompName ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.projectObj}</span><span className='feedback-sm-text ml-5px'>{dataValue?.title ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.projectLocation}</span><span className='feedback-sm-text ml-5px'>{dataValue?.location ?? '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.pgLevel}</span><span className='feedback-sm-text ml-5px'>{dataValue?.project_guide?.Esgtxt ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.ou}</span><span className='feedback-sm-text ml-5px'>{dataValue?.org_unit?.org_unit ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.projectArea}</span><span className='feedback-sm-text ml-5px'>{dataValue?.area?.area ?? '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text ml-5px'>{dataValue?.no_of_openings ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationsReceived}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_received ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistBySPOC}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_spoc ?? '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{viewDetailsFromApplicant.applicationShortlistByPG}</span><span className='feedback-sm-text ml-5px'>{dataValue?.appl_shrtlted_by_pg ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.startDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_start_date? moment(dataValue.actual_start_date).format("DD-MM-YYYY") : dataValue.planned_start_date ? moment(dataValue.planned_start_date).format("DD-MM-YYYY") : '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{gemsApplicant.endDate}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_end_date ? moment(dataValue.actual_end_date).format("DD-MM-YYYY") : dataValue.planned_end_date ? moment(dataValue.planned_end_date).format("DD-MM-YYYY") : '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Project Status:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.status ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Mid Term Review Date:"}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_mtr_date ? moment(dataValue.actual_mtr_date).format("DD-MM-YYYY") : dataValue.revised_mtr_date ? moment(dataValue.revised_mtr_date).format("DD-MM-YYYY") : dataValue.planned_mtr_date ? moment(dataValue.planned_mtr_date).format("DD-MM-YYYY") : '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Final Review Date:"}</span><span className='feedback-sm-text ml-5px'>{dataValue.actual_fr_date ? moment(dataValue.actual_fr_date).format("DD-MM-YYYY") : dataValue.revised_fr_date ? moment(dataValue.revised_fr_date).format("DD-MM-YYYY") : dataValue.planned_fr_date ? moment(dataValue.planned_fr_date).format("DD-MM-YYYY") : '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Mid Term Status:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.mtr_status ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Mid Term Review Comments:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.mtr_comments ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Mid Term Review Attachment:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.mtr_attachment ?? '-'}</span>
                                                            {dataValue?.mtr_attachment &&
                                                                <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadMTRdoc(dataValue.id)} />
                                                            }
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Final Status:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.fr_status ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Final Review Comments:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.fr_comments ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Final Review Attachment:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.fr_attachment ?? '-'}</span>
                                                            {dataValue?.fr_attachment &&
                                                                <img src={downloadLatestImg} alt='download attachment' className='w-20px pointer ml-2' onClick={() => this.downloadFRdoc(dataValue.id)} />
                                                            }
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='container-project-details px-10px mt-1'>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Duration:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.duration ?? '-'}</span>
                                                        </span>
                                                        <span>
                                                            <span className='appcenter-app-name'>{"Category:"}</span><span className='feedback-sm-text ml-5px'>{dataValue?.category ?? '-'}</span>
                                                        </span>
                                                    </span>
                                                    <div className='line-horizontal d-flex hr-line-margin'></div>

                                                    <span className='p-10px flex-direction-row'>
                                                        <span className='appcenter-app-name'>{gemsApplicant.description}</span>
                                                        <span className='feedback-sm-text ml-5px mt-10px'>{dataValue?.description ?? '-'}</span>
                                                    </span>

                                                </span>
                                            </div>
                                        </Fragment>
                                    )
                                    :
                                    <>
                                        <div className='flex-direction-row noProject-style p-10px mx-10px'>
                                            <span className='performance-timeline-text-bold p-10px w-10 '>{'Sr.No'}</span>
                                            <span className='performance-timeline-text-bold p-10px w-45-percent'>{'Deliverable'}</span>
                                            <span className='performance-timeline-text-bold p-10px w-45-percent'>{'Target'}</span>
                                        </div>
                                        <div className='flex-direction-column p-10px mx-10px'>
                                            {
                                                this.state.mtrFRViewData.map((dataValue, idx) =>
                                                    <span key={idx} className='flex-direction-row w-100 align-item-center-noflex'>
                                                        <span className='w-7 m-10px performance-timeline-text-circle'>{idx + 1}</span>
                                                        <span className='w-45-percent m-10px'>
                                                            <textarea
                                                                className="form-control popup-placeholder px-3"
                                                                type="text"
                                                                maxLength={valueLimits.textAreaMaxLength}
                                                                value={dataValue.deliverable}
                                                                placeholder={'Enter Project Deliverable'}
                                                                readOnly={true}
                                                            />
                                                            <CharacterCounter
                                                                totalCount="150"
                                                                currentCount={dataValue?.deliverable?.length}
                                                            />
                                                        </span>
                                                        <span className='w-45-percent m-10px'>
                                                            <textarea
                                                                className="form-control popup-placeholder px-3"
                                                                type="text"
                                                                maxLength={valueLimits.textAreaMaxLength}
                                                                value={dataValue.target}
                                                                placeholder={'Enter Project Target'}
                                                                readOnly={true}
                                                            />
                                                            <CharacterCounter
                                                                totalCount="150"
                                                                currentCount={dataValue?.target?.length}
                                                            />
                                                        </span>
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        {
                            this.props.appliedProjects?.status === "Under TA Screening" || this.props.type === "delete" ?
                                <>
                                    <CancelButton
                                        type={'button'}
                                        value={'CANCEL'}
                                        onClick={this.props.onDismiss}
                                    />
                                    <SuccessButton
                                        type={'button'}
                                        value={'DELETE'}
                                        onClick={() => this.props.deleteAppication("Confirmed")}
                                    />
                                </>
                                :
                                this.props.type === "project_detail_view" ?
                                    this.state.mtrFRView ?
                                        <CancelButton
                                            type={'button'}
                                            value={'CLOSE'}
                                            onClick={() => this.setState({ mtrFRView: false })}
                                        />
                                        :
                                        <CancelButton
                                            type={'button'}
                                            value={'CLOSE'}
                                            onClick={this.props.onDismiss}
                                        />
                                    :
                                    <>
                                        <CancelButton
                                            type={'button'}
                                            value={'CLOSE'}
                                            onClick={this.props.onDismiss}
                                        />
                                    </>
                        }
                    </div>
                </div>
            </div >
        )
    }
}
export default GemsConfirmPopup
