import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import * as Config from "../../../../constants/Config";
import LoaderIndicator from "../../../shared/loader/LoaderIndicator";
import DataNotFound from "../../../shared/notFound/DataNotFound";
import Dropdown from "../../../shared/components/Dropdown";
import { Month, yesNo_type } from "../../../../utils/Utils";
import ReactExport from "react-data-export";
import LargeDropdown from "../../../shared/components/LargeDropdown";
import radioOn from "../../../../assets/images/applyLeave/radioOn.png";
import radioOff from "../../../../assets/images/applyLeave/radioOff.png";
import moment from "moment";
import { RiArrowDropDownLine } from "react-icons/ri";
import { configStore } from "../../../../redux/ConfigStore";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const chooseselect = "Enter PA";
const selectDefault = "Select";

const requestFailed = "Unable to contact server\n\tPlease retry after sometime";
class SpecificPeriodReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthData: Month(),
      monthCode: "",
      monthValue: "Select",
      fromDate: "",
      toDate: "",
      paData: [],
      psaData: [],
      paCode: "",
      paValue: chooseselect,
      psaCode: "Select PSA",
      coiData: [],
      regionData: [],
      companyList: [],
      region_updated: selectDefault,
      pa_updated: selectDefault,
      psa_updated: selectDefault,
      company_updated: selectDefault,
      requestData: [],
      checked: 0,
      checkedType: "",
      showSpecificPeriodReport: false,
      deleted_data: yesNo_type(),
      deleted_coi_type: "",
      deleted_coi: 0,
      checkedCoi: 0,
      isDeleted: true,
    };
  }

  componentDidMount() {
    if (
      configStore.getState().adminLoginState.company.company_code == Config.TMCV
    ) {
      this.getPersonalAreaMasterData(Config.TMCV_PATH);
      this.getRegion(Config.TMCV_PATH);
    } else if (
      configStore.getState().adminLoginState.company.company_code ==
      Config.TMPVL
    ) {
      this.getPersonalAreaMasterData(Config.TMPVL_PATH);
      this.getRegion(Config.TMPVL_PATH);
    } else if (
      configStore.getState().adminLoginState.company.company_code ==
      Config.TPEML
    ) {
      this.getPersonalAreaMasterData(Config.TPEML_PATH);
      this.getRegion(Config.TPEML_PATH);
    } else if (
      configStore.getState().adminLoginState.company.company_code ==
      Config.TMLBSL
    ) {
      this.getPersonalAreaMasterData(Config.TMLBSL_PATH);
      this.getRegion(Config.TMLBSL_PATH);
    }
    this.getCompanyNames();
    this.setCompanyInitially();
  }

  getPersonalAreaMasterData = (service) => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    fetchApiCall(
      Config.getPersonalAreaMaster,
      {},
      headers,
      false,
      "admin",
      "",
      Config.COMMON_BASE_URL_PROD + service
    )
      .then((res) => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code == 200) {
          if (res.data.pa_data) {
            const paArray = res.data.pa_data.map((item, idx) => ({
              ...item,
              label: item.pa_code,
            }));
            this.setState(
              {
                loading: false,
                paData: paArray,
              });
          }
        } else {
          this.setState({
            loading: false,
            paData: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastError",
        });
      });
  };

  getRegion = (service) => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    fetchApiCall(
      Config.getRegion,
      {},
      headers,
      false,
      "admin",
      "",
      Config.COMMON_BASE_URL_PROD + service
    )
      .then((res) => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code == 200) {
          if (res.data.region_data) {
            const paArray = res.data.region_data.map((item, idx) => ({
              ...item,
              label: item.RegCod,
            }));
            this.setState(
              {
                loading: false,
                regionData: paArray,
              });
          }
        } else {
          this.setState({
            loading: false,
            regionData: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastError",
        });
      });
  };

  getCompanyNames = () => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    fetchApiCall(Config.getCompanyNames, {}, headers, true, "service")
      .then((res) => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code == 200) {
          if (res.data.company_list) {
            const paArray = res.data.company_list.map((item, idx) => ({
              ...item,
              label: item.company_sht_txt,
            }));
            this.setState(
              {
                loading: false,
                companyList: paArray,
              },
              () => console.log("parrayyyy", this.state.companyList)
            );
          }
        } else {
          this.setState({
            loading: false,
            companyList: [],
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastError",
        });
      });
  };

  getCOIData = (service) => {
    this.setState({
      loading: true,
    });
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      start_date: this.state.fromDate,
      end_date: this.state.toDate,
      emp_pa: this.state.pa_updated,
      emp_psa: this.state.psa_updated,
      emp_region: this.state.region_updated,
      emp_company: this.state.company_updated.company_code || "Select",
      is_deleted: this.state.isDeleted,
    };

    fetchApiCall(Config.getCOIPeriodReport, body, headers, false, "admin", "POST", Config.COMMON_BASE_URL_PROD + service)
      .then((res) => {
        sessionOut(this.props, res, this.state.admin);
        console.log("getCOIEmpReport response", res);
        if (res.data.status_code === "200") {
          this.setState({
            loading: false,
            allData: res.data,
            coiData: res.data.a1_data,
            requestData: res.data.count_data,
            showSpecificPeriodReport: true,
            checkedType: "A1",
          });
          console.log("allData", this.state.allData);
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastWarn",
          });
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastError",
        });
      });
  };

  changeMonth = (data) => {
    this.setState(
      {
        monthCode: data.code,
        monthValue: data.value,
      }
    );
  };

  getPersonalSubAreaMasterData = (PA, service) => {
    const headers = {
      "Content-Type": "application/json",
    };
    let body = {
      pa_code: PA,
    };
    fetchApiCall(Config.getPersonalSubAreaMaster, body, headers, false, "admin", "", Config.COMMON_BASE_URL_PROD + service)
      .then((res) => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code === "200") {
          this.setState({
            psaData: res.data.psa_data ? res.data.psa_data : [],
          });
          const psaArray = this.state.psaData.map((item, idx) => ({
            ...item,
            label: item.psa_code,
          }));
          this.setState({ psaData: psaArray });
        } else {
          toast.error(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: "toastError",
          });
          this.setState({
            loading: false,
            psaData: [],
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastError",
        });
      });
  };

  handleChangePA = (data) => {
    this.setState({
      pa: data.pa_code,
      pa_updated: data.pa_code,
    });
    if (this.state.company_updated.company_code == Config.TMCV) {
      this.getPersonalSubAreaMasterData(data.pa_code, Config.TMCV_PATH);
    } else if (this.state.company_updated.company_code == Config.TMPVL) {
      this.getPersonalSubAreaMasterData(data.pa_code, Config.TMPVL_PATH);
    } else if (this.state.company_updated.company_code == Config.TPEML) {
      this.getPersonalSubAreaMasterData(data.pa_code, Config.TPEML_PATH);
    } else if (this.state.company_updated.company_code == Config.TMLBSL) {
      this.getPersonalSubAreaMasterData(data.pa_code, Config.TMLBSL_PATH);
    }
  };

  handleChangePSA = (data) => {
    this.setState({
      psa: data.psa_code,
      psa_updated: data.psa_code,
    });
  };

  handleChangeRegion = (data) => {
    this.setState({
      region_updated: data.RegCod,
    });
  };

  handleChangeCompany = (data) => {
    this.setState({
      company_updated: data,
      pa_updated: selectDefault,
      psa_updated: selectDefault,
      region_updated: selectDefault,
    });
    if (data.company_code == Config.TMCV) {
      this.getPersonalAreaMasterData(Config.TMCV_PATH);
      this.getRegion(Config.TMCV_PATH);
    } else if (data.company_code == Config.TMPVL) {
      this.getPersonalAreaMasterData(Config.TMPVL_PATH);
      this.getRegion(Config.TMPVL_PATH);
    } else if (data.company_code == Config.TPEML) {
      this.getPersonalAreaMasterData(Config.TPEML_PATH);
      this.getRegion(Config.TPEML_PATH);
    } else if (data.company_code == Config.TMLBSL) {
      this.getPersonalAreaMasterData(Config.TMLBSL_PATH);
      this.getRegion(Config.TMLBSL_PATH);
    }
  };

  setCompanyInitially = () => {
    this.setState({
      company_updated: configStore.getState().adminLoginState.company,
    });
  };

  handleDeletedCOI = (key, type) => {
    this.setState({
      isDeleted: type.code,
      checkedCoi: key,
      checkedCoiType: type,
    });
  };

  validateData = () => {
    if (this.state.fromDate === "") {
      toast.warning("Please select from date", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else if (this.state.toDate === "") {
      toast.warning("Please select to date", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else if (this.state.pa_updated === "") {
      toast.warning("Please select PA", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else if (this.state.psa_updated === "") {
      toast.warning("Please select PSA", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else if (this.state.region_updated === "") {
      toast.warning("Please select region", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else if (this.state.company_updated.length === 0) {
      toast.warning("Please select company", {
        position: "bottom-center",
        hideProgressBar: true,
        className: "toastWarn",
      });
    } else {
      if (this.state.company_updated.company_code == Config.TMCV) {
        this.getCOIData(Config.TMCV_PATH);
      } else if (this.state.company_updated.company_code == Config.TMPVL) {
        this.getCOIData(Config.TMPVL_PATH);
      } else if (this.state.company_updated.company_code == Config.TPEML) {
        this.getCOIData(Config.TPEML_PATH);
      } else if (this.state.company_updated.company_code == Config.TMLBSL) {
        this.getCOIData(Config.TMLBSL_PATH);
      }
    }
  };

  onPresentTypeChange = (key, type) => {
    console.log("key, type", key, type);
    if (type === "A1") {
      this.setState({
        coiData: this.state.allData.a1_data,
      });
      if (this.state.allData.a1_data.length === 0) {
        toast.warning("Data Not Available", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    } else if (type === "A2") {
      this.setState({
        coiData: this.state.allData.a2_data,
      });
      if (this.state.allData.a2_data.length === 0) {
        toast.warning("Data Not Available", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    } else if (type === "A3") {
      this.setState({
        coiData: this.state.allData.a3_data,
      });
      if (this.state.allData.a3_data.length === 0) {
        toast.warning("Data Not Available", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    } else {
      this.setState({
        coiData: this.state.allData.b_data,
      });
      if (this.state.allData.b_data.length === 0) {
        toast.warning("Data Not Available", {
          position: "bottom-center",
          hideProgressBar: true,
          className: "toastWarn",
        });
      }
    }
    this.setState({
      checked: key,
      checkedType: type,
    });
  };

  render() {
    const toDay = new Date().toISOString().substring(0, 10);
    return (
      <div>
        <div className="row justify-content-between align-item-center pt-3 px-3">
          <div>
            <label className="edit-cardtitle ml-2">
              Enter the period details:
            </label>
          </div>
        </div>
        <div className="justify-content-between">
          <LoaderIndicator loading={this.state.loading} />
          <div className="col-md-12 px-0">
            <div className="row">
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3">Company</div>
                <div className="p-placeholder-text">
                  <LargeDropdown
                    data={this.state.companyList}
                    value={this.state.company_updated.company_sht_txt}
                    selectedValue={(data) => this.handleChangeCompany(data)}
                  />
                </div>
              </div>
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3 required">From Date</div>
                <div className="p-placeholder-text">
                  <input
                    className="form-control p-placeholder p-placeholder-admin px-2"
                    type="date"
                    max={toDay} //min props using for hiding the date current to future date
                    value={this.state.fromDate}
                    onChange={(e) =>
                      this.setState({ fromDate: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3 required">To Date</div>
                <div className="p-placeholder-text">
                  <input
                    className="form-control p-placeholder p-placeholder-admin px-2"
                    type="date"
                    max={toDay} //min props using for hiding the date current to future date
                    value={this.state.toDate}
                    onChange={(e) => this.setState({ toDate: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3">PA Type</div>
                <div>
                  <LargeDropdown
                    data={this.state.paData}
                    value={this.state.pa_updated}
                    selectedValue={(data) => this.handleChangePA(data)}
                  />
                </div>
              </div>
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3">PSA Type</div>
                <div className="p-placeholder-text">
                  <LargeDropdown
                    data={this.state.psaData}
                    value={this.state.psa_updated}
                    selectedValue={(data) => this.handleChangePSA(data)}
                  />
                </div>
              </div>
              <div className="col-4 mb-3 mt-3 group-width">
                <div className="p-sub-heading ml-3">Region Type</div>
                <div className="p-placeholder-text">
                  <LargeDropdown
                    data={this.state.regionData}
                    value={this.state.region_updated}
                    selectedValue={(data) => this.handleChangeRegion(data)}
                  />
                </div>
              </div>

              <div className="col-2 mb-3 mt-3 group-width">
                <div className="status-dashboard ml-3">Deleted COI</div>
                <div className="d-flex justify-content-start">
                  {this.state.deleted_data.map((list, i) => {
                    return (
                      <div key={i} className="mx-4 mt-2 mb-1">
                        {this.state.checkedCoi === i ? (
                          <div
                            className="row align-item-center"
                            onClick={() => this.handleDeletedCOI(i, list)}
                          >
                            <img
                              alt="radioOn"
                              className="radio"
                              src={radioOn}
                            />
                            <div className="radioTxt">{list.value}</div>
                          </div>
                        ) : (
                          <div
                            className="row align-item-center"
                            onClick={() => this.handleDeletedCOI(i, list)}
                          >
                            <img
                              alt="radioOff"
                              className="radio"
                              src={radioOff}
                            />
                            <div className="radioTxt">{list.value}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                type="button"
                className="save-btn save-txt mt-38px"
                onClick={() => this.validateData()}
              >
                SEARCH
              </div>
            </div>
          </div>
        </div>
        {this.state.showSpecificPeriodReport === true && (
          <>
            {this.state.checkedType !== "" && (
              <>
                <div className="hz-lines mr-4 ml-4"></div>
                <div className="row justify-content-between align-item-center px-3 mt-2">
                  <div>
                    <label className="edit-cardtitle ml-2">
                      Employee-wise Summary: {this.state.checkedType} Report(TML
                      Relatives)
                    </label>
                  </div>
                  <div className=" justify-content-end">
                    {this.state.checkedType === "A1" ? (
                      <div>
                        {this.state.coiData.length > 0 ? (
                          <ExcelFile
                            filename="SpecificPeriodReport"
                            element={
                              <div type="button" className="save-btn save-txt">
                                Export
                              </div>
                            }
                          >
                            <ExcelSheet
                              data={this.state.coiData}
                              name="Employees"
                            >
                              <ExcelColumn
                                label="Form Type"
                                value="form_type"
                              />
                              <ExcelColumn label="Employee ID" value="emp_id" />
                              <ExcelColumn
                                label="Employee Name"
                                value="emp_name"
                              />
                              <ExcelColumn label="PA" value="emp_pa" />
                              <ExcelColumn label="PSA" value="emp_psa" />
                              <ExcelColumn label="Region" value="emp_region" />
                              <ExcelColumn
                                label="Employee Subgroup"
                                value="emp_esubgroup"
                              />
                              <ExcelColumn
                                label="Employee Joining Date"
                                value="emp_doj"
                              />
                              <ExcelColumn
                                label="Company Code"
                                value="company_code"
                              />
                              <ExcelColumn
                                label="Organization Unit"
                                value="organization_unit"
                              />
                              <ExcelColumn
                                label="Relative ID"
                                value="relative_perno"
                              />
                              <ExcelColumn
                                label="Relative Name"
                                value="relative_full_name"
                              />
                              <ExcelColumn
                                label="Relative Subgroup"
                                value="relative_esubgroup"
                              />
                              <ExcelColumn
                                label="Relative Business Unit"
                                value="relative_business_unit"
                              />
                              <ExcelColumn
                                label="Relationship"
                                value="relationship"
                              />
                              <ExcelColumn
                                label="Is Reporting Relationship"
                                value="is_reporting_relationship"
                              />
                              <ExcelColumn
                                label="Relative Joining Date"
                                value="relative_joining_date"
                              />
                              <ExcelColumn
                                label="Is Disclosed"
                                value="is_disclosed"
                              />
                              <ExcelColumn
                                label="Disclosure Description"
                                value="disclosure_description"
                              />
                              <ExcelColumn
                                label="Financial Year"
                                value="financial_year"
                              />
                              <ExcelColumn
                                label="Relative Cost Center"
                                value="relative_cost_center"
                              />
                              <ExcelColumn
                                label="Relative PA"
                                value="relative_pa"
                              />
                              <ExcelColumn
                                label="Relative PSA"
                                value="relative_psa"
                              />
                              <ExcelColumn
                                label="Relative Esubgroup"
                                value="relative_esubgroup"
                              />
                              <ExcelColumn label="Deleted" value="is_deleted" />
                              <ExcelColumn
                                label="Created Date Time"
                                value="created_date_time"
                              />
                              <ExcelColumn
                                label="Modified Date Time"
                                value="modified_date_time"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : null}
                      </div>
                    ) : this.state.checkedType === "A2" ? (
                      <div>
                        {this.state.coiData.length > 0 ? (
                          <ExcelFile
                            filename="SpecificPeriodReport"
                            element={
                              <div type="button" className="save-btn save-txt">
                                Export
                              </div>
                            }
                          >
                            <ExcelSheet
                              data={this.state.coiData}
                              name="Employees"
                            >
                              <ExcelColumn
                                label="Form Type"
                                value="form_type"
                              />
                              <ExcelColumn label="Employee ID" value="emp_id" />
                              <ExcelColumn
                                label="Employee Name"
                                value="emp_name"
                              />
                              <ExcelColumn label="PA" value="emp_pa" />
                              <ExcelColumn label="PSA" value="emp_psa" />
                              <ExcelColumn label="Region" value="emp_region" />
                              <ExcelColumn
                                label="Employee Subgroup"
                                value="emp_esubgroup"
                              />
                              <ExcelColumn
                                label="Employee Joining Date"
                                value="emp_doj"
                              />
                              <ExcelColumn
                                label="Company Code"
                                value="company_code"
                              />
                              <ExcelColumn
                                label="Organization Unit"
                                value="organization_unit"
                              />
                              <ExcelColumn
                                label="Relative Name"
                                value="relative_full_name"
                              />
                              <ExcelColumn
                                label="Relationship"
                                value="relationship"
                              />

                              <ExcelColumn
                                label="Modified Date Time"
                                value="modified_date_time"
                              />

                              <ExcelColumn
                                label="Relative Comp Name"
                                value="relative_comp_name"
                              />

                              <ExcelColumn
                                label="Relative Comp Address"
                                value="relative_comp_add"
                              />

                              <ExcelColumn
                                label="Nature of Interest"
                                value="nature_of_interest"
                              />

                              <ExcelColumn
                                label="Relationship Exist"
                                value="is_working_relationship_exist"
                              />

                              <ExcelColumn
                                label="Relative Joining Date"
                                value="relative_joining_date"
                              />

                              <ExcelColumn
                                label="Is Disclosed"
                                value="is_disclosed"
                              />

                              <ExcelColumn
                                label="Disclosure Description"
                                value="disclosure_description"
                              />

                              <ExcelColumn
                                label="Financial Year"
                                value="financial_year"
                              />

                              <ExcelColumn label="Deleted" value="is_deleted" />
                              <ExcelColumn
                                label="Created Date Time"
                                value="created_date_time"
                              />
                              <ExcelColumn
                                label="Modified Date Time"
                                value="modified_date_time"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : null}
                      </div>
                    ) : this.state.checkedType === "A3" ? (
                      <div>
                        {this.state.coiData.length > 0 ? (
                          <ExcelFile
                            filename="SpecificPeriodReport"
                            element={
                              <div type="button" className="save-btn save-txt">
                                Export
                              </div>
                            }
                          >
                            <ExcelSheet
                              data={this.state.coiData}
                              name="Employees"
                            >
                              <ExcelColumn
                                label="Form Type"
                                value="form_type"
                              />
                              <ExcelColumn label="Employee ID" value="emp_id" />
                              <ExcelColumn
                                label="Employee Name"
                                value="emp_name"
                              />
                              <ExcelColumn label="PA" value="emp_pa" />
                              <ExcelColumn label="PSA" value="emp_psa" />
                              <ExcelColumn label="Region" value="emp_region" />
                              <ExcelColumn
                                label="Employee Subgroup"
                                value="emp_esubgroup"
                              />
                              <ExcelColumn
                                label="Employee Joining Date"
                                value="emp_doj"
                              />
                              <ExcelColumn
                                label="Company Code"
                                value="company_code"
                              />
                              <ExcelColumn
                                label="Organization Unit"
                                value="organization_unit"
                              />
                              <ExcelColumn
                                label="Relative Name"
                                value="relative_full_name"
                              />
                              <ExcelColumn label="Name" value="name" />
                              <ExcelColumn label="Location" value="location" />
                              <ExcelColumn
                                label="Association Type"
                                value="association_type"
                              />
                              <ExcelColumn
                                label="Associated Since"
                                value="associated_since"
                              />
                              <ExcelColumn
                                label="Is Interacting"
                                value="is_interacting"
                              />
                              <ExcelColumn
                                label="Is Benefitted"
                                value="is_benefitted"
                              />
                              <ExcelColumn
                                label="Is Disclosed"
                                value="is_disclosed"
                              />
                              <ExcelColumn
                                label="Disclosure Description"
                                value="disclosure_description"
                              />
                              <ExcelColumn
                                label="Relative Name"
                                value="relative_full_name"
                              />
                              <ExcelColumn
                                label="Financial Year"
                                value="financial_year"
                              />
                              <ExcelColumn label="Deleted" value="is_deleted" />
                              <ExcelColumn
                                label="Created Date Time"
                                value="created_date_time"
                              />
                              <ExcelColumn
                                label="Modified Date Time"
                                value="modified_date_time"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        {this.state.coiData.length > 0 ? (
                          <ExcelFile
                            filename="SpecificPeriodReport"
                            element={
                              <div type="button" className="save-btn save-txt">
                                Export
                              </div>
                            }
                          >
                            <ExcelSheet
                              data={this.state.coiData}
                              name="Employees"
                            >
                              <ExcelColumn
                                label="Form Type"
                                value="form_type"
                              />
                              <ExcelColumn label="Employee ID" value="emp_id" />
                              <ExcelColumn
                                label="Employee Name"
                                value="emp_name"
                              />
                              <ExcelColumn label="PA" value="emp_pa" />
                              <ExcelColumn label="PSA" value="emp_psa" />
                              <ExcelColumn label="Region" value="emp_region" />
                              <ExcelColumn
                                label="Employee Subgroup"
                                value="emp_esubgroup"
                              />
                              <ExcelColumn
                                label="Employee Joining Date"
                                value="emp_doj"
                              />
                              <ExcelColumn
                                label="Company Code"
                                value="company_code"
                              />
                              <ExcelColumn
                                label="Organization Unit"
                                value="organization_unit"
                              />
                              <ExcelColumn label="Deleted" value="is_deleted" />
                              <ExcelColumn
                                label="Created Date Time"
                                value="created_date_time"
                              />
                              <ExcelColumn
                                label="Modified Date Time"
                                value="modified_date_time"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="status-dashboard mt-2 ml-4">Status :</div>
                  {this.state.requestData.length != 0 &&
                    this.state.requestData.map((list, key) => {
                      return (
                        <div key={key} className="mx-4 mt-2 mb-1">
                          {this.state.checked === key ? (
                            <div
                              className="row align-item-center"
                              onClick={() =>
                                this.onPresentTypeChange(key, list.title)
                              }
                            >
                              <img
                                alt="radioOn"
                                className="radio"
                                src={radioOn}
                              />
                              <label className="radioTxt">
                                {list.title.charAt(0).toUpperCase() +
                                  list.title.slice(1)}
                              </label>
                              <div className="radioTxt">
                                {"(" + list.value + ")"}
                              </div>
                            </div>
                          ) : (
                            <div
                              className="row align-item-center"
                              onClick={() =>
                                this.onPresentTypeChange(key, list.title)
                              }
                            >
                              <img
                                alt="radioOff"
                                className="radio"
                                src={radioOff}
                              />
                              <label className="radioTxt">
                                {list.title.charAt(0).toUpperCase() +
                                  list.title.slice(1)}
                              </label>
                              <div className="radioTxt">
                                {"(" + list.value + ")"}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    keycloakData: state.keycloakState,
  };
};

export default connect(mapStatesToProps)(SpecificPeriodReport);
