import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import { connect } from 'react-redux';
import { notification_type } from '../../../../utils/Utils';
import { requestFailed } from '../../../../constants/Messages';
import * as AdminLoginAction from '../login/LoginAction';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import * as Config from '../../../../constants/Config';
import '../adminNotificationHome/Notification.css';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Dropdown from '../../../shared/components/Dropdown';
import Resizer from 'react-image-file-resizer';

class AnnouncementNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      announcementType: notification_type(),
      announcementTypeValue: 'Select',
      title: '',
      announcementImageName: '',
      announcementImage: '',
      announcementDescription: '',
      dynamicPopuphyperlink: '',
    }
  }

  changeAnnouncentType = (data) => {
    this.setState({
      announcementTypeValue: data.value,
    })
  }

  validate = () => {
    // if (this.state.announcementTypeValue === 'Select') {
    //     toast.warning('Please select announcement type', {
    //         position: "bottom-center",
    //         hideProgressBar: true,
    //         className: 'toastWarn'
    //     })
    // } 
    if (this.state.title === '') {
      toast.warning('Please enter announcement title', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.announcementImageName === '') {
      toast.warning('Please select image', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    } else if (this.state.announcementDescription === '') {
      toast.warning('Please enter announcement description ', {
        position: "bottom-center",
        hideProgressBar: true,
        className: 'toastWarn'
      })
    }
    else {
      this.insert_announcement_by_admin()
    }
  }

  insert_announcement_by_admin = () => {
    this.setState({
      loading: true
    })
    const headers = {
      'Content-Type': 'application/json',
    }
    let body = {
      emp_mail: this.props.adminLoginData.adminData.user_email,
      announcement_title: this.state.title,
      announcement_description: this.state.announcementDescription,
      image_name: this.state.announcementImageName,
      image_link: this.state.announcementImage,
      // announcement_type: this.state.announcementTypeValue
      announcement_type: 'organization',
      announcement_url: this.state.dynamicPopuphyperlink
    }
    fetchApiCall(Config.insert_announcement_by_admin, body, headers, false, 'admin')
      .then(res => {
        sessionOut(this.props, res, this.state.admin);
        if (res.data.status_code === '200') {
          toast.success(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastSuccess'
          },
            this.props.allDismiss(),
            this.props.getAnnouncementCall(),
            this.props.announcementsActive()
          )
          this.setState({ loading: false })
        } else {
          toast.warning(res.data.message, {
            position: "bottom-center",
            hideProgressBar: true,
            className: 'toastWarn'
          })
          this.setState({ loading: false })
        }
      }).catch(err => {
        this.setState({ loading: false })
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastError'
        })
      })
  }

  // fileSelectHandler = (event) => {
  //   this.setState({ announcementImageName: event.target.files[0] ? event.target.files[0].name : '' })
  //   let re = /(?:\.([^.]+))?$/;
  //   // var ext = re.exec("file.name.with.dots.txt")[1];
  //   console.log("ext:", re.exec(event.target.files[0])[1])
  //   if (event.target.files && event.target.files[0]) {
  //     let maxAllowedSize = 2097152;           // 2MB size
  //     if (event.target.files[0].size <= maxAllowedSize) {
  //       let reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]);
  //       reader.onload = (e) => {
  //         this.setState({
  //           announcementImage: e.target.result,
  //         })
  //       }
  //     }
  //     else {
  //       toast.warn('Please upload image less than 2MB', {
  //         position: "bottom-center",
  //         hideProgressBar: true,
  //         className: 'toastWarn'
  //       })
  //       this.setState({ announcementImage: '' })
  //     }
  //   }
  //   else {
  //     this.setState({ announcementImage: '' })
  //   }
  // }


  fileSelectHandler = (event) => {
    const file = event.target.files[0];
    this.setState({ announcementImageName: event.target.files[0] ? event.target.files[0] : '' })
    // let re = /(?:\.([^.]+))?$/;
    // // var ext = re.exec("file.name.with.dots.txt")[1];
    // console.log("ext:", re.exec(event.target.files[0])[1])
    if (event.target.files && event.target.files[0]) {
      let maxAllowedSize = 2097152;           // 2MB size
      if (event.target.files[0].size <= maxAllowedSize) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            this.setState({
              heightAndWidth: [img.width, img.height]
            })
            this.uploadImg(this.state.announcementImageName, file)
            console.log('Image width:', img.width);
            console.log('Image height:', img.height);
          }
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({ announcementImageName: '' })
      }
    }
    else {
      this.setState({ announcementImageName: '' })
    }
  }

  uploadImg = (event, data) => {
    console.log("uploadImg event:", event);
    this.setState({
      announcementImageName: event ? event.name : '',
    })
    console.log('event--------->', event)
    if (event) {
      console.log('event.size--------->', event.size)
      let maxAllowedSize = 2097152;  // 2MB size
      if (event.size <= maxAllowedSize) {
        // console.log("inside :", event.size, maxAllowedSize, data.file);
        // ******************************for Resize the image*****************************************
        try {
          Resizer.imageFileResizer(
            data,
            780,
            650,
            'PNG',
            100,
            0,
            (uri) => {
              console.log('Resized image URI:', uri);
              this.setState({ announcementImage: uri })
              // Image.getSize(uri, (width, height) => {
              //   console.log('Resized image size:', width, height);
              // });
            },
            'uri',
            780,
            650
          );
        } catch (err) {
          console.log("Resizer err:", err);
        }
      }
      else {
        toast.warn('Please upload image less than 2MB', {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
        this.setState({
          announcementImage: '',
          announcementImageName: ''
        })
      }
    }
    else {
      this.setState({
        announcementImage: '',
        announcementImageName: ''
      })
    }
  }


  render() {
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        <div>
          <form className='todoModalBody popup_scroller w-100'>
            <div className='p-1 w-100'>
              <div>
                <div>
                  {/* <div>
                  <div className='p-sub-heading ml-3 mb-1'>Announcent Type</div>
                  <div className='p-placeholder-text mb-2'>
                    <Dropdown
                      data={this.state.announcementType}
                      selectedValue={(data) => this.changeAnnouncentType(data)}
                      value={this.state.announcementTypeValue}
                      render={'value'}
                      height={'dependOnContent'}
                    />
                  </div>
                </div> */}
                  <div className='p-sub-heading ml-3'>Title</div>
                  <div className='p-placeholder-text mb-2'>
                    <input
                      className="form-control p-placeholder p-placeholder-admin px-2"
                      type="text"
                      readOnly={this.state.show}
                      onChange={(e) => this.setState({ title: e.target.value })}
                      value={this.state.title}
                      placeholder={"Enter the title"}
                      maxLength={256}
                    />
                  </div>
                  <div>
                    <div className='p-sub-heading ml-3'>File</div>
                    {!this.props.show &&
                      <div className='p-placeholder-text mb-2 inputHeight'>
                        <input
                          className="form-control p-placeholder p-placeholder-admin px-2"
                          type="file"
                          accept="image/*"
                          onChange={this.fileSelectHandler}
                        />
                      </div>
                    }
                  </div>
                  <div className='p-sub-heading ml-3 mb-1'>Hyperlink</div>
                  <div className='p-placeholder-text mb-2'>
                    <input
                      className="form-control p-placeholder p-placeholder-admin px-2"
                      type="text"
                      value={this.state.dynamicPopuphyperlink}
                      onChange={(e) => this.setState({ dynamicPopuphyperlink: e.target.value })}
                      placeholder={"Hyperlink"}
                    />
                  </div>
                  <div className='p-sub-heading ml-3'>Description</div>
                  <div className='p-placeholder-text'>
                    <textarea
                      className="form-control p-placeholder px-3"
                      type="text"
                      maxLength={1000}
                      readOnly={this.state.show}
                      onChange={(e) => this.setState({ announcementDescription: e.target.value })}
                      value={this.state.announcementDescription}
                      placeholder={"Enter the description"}
                    >
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='modal-footer btnBox justify-content-between'>
            <div className='justify-content-start'>
              <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
            </div>
            <div className='justify-content-around'>
              <div className='justify-content-start'>
                <input type='button'
                  value='CANCEL'
                  className='popup-back-btn popup-back-text'
                  onClick={this.props.back}
                />
              </div>
              <div className='justify-content-start'>
                <input type='button'
                  value='ADD'
                  className='popup-confirm-btn'
                  onClick={this.validate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  return {
    loginData: state.loginState,
    adminLoginData: state.adminLoginState,
    keycloakData: state.keycloakState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminAction: bindActionCreators(AdminLoginAction, dispatch),
  };
}

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(AnnouncementNotification));