import React, { useEffect, useRef, useState } from 'react'
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { awardType, awardTypeImplemented, evaluatorActions, exclusiveSchemeTypes, historyDataFields, memberDataFields, suggestionDataFields, savingDetails, suggestionDataFieldsConditinal, finalAwardType, schemeCode, statusCodes } from '../../../employeeScreens/suggestionScheme/constants';
import { DDMMMYY, DDMMYYYY, toaster } from '../../../../../utils/Utils';
import Table from './Table';
import DynamicDropdown from '../../../../shared/components/DynamicDropdown';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import plus from "../../../../../assets/images/cfm/plus.svg";
import { toast } from "react-toastify"
import { getMasterAward, getMasterAwardCategory, getMasterNatureOfSaving, getMasterSavingCategory, suggestionById, suggestionDocAttachment, updateSuggestionByEvaluator, deleteS3Documents, get_division, get_agency, updateEvaluatorAction, deleteSavingDetails, deleteDocuments } from '../../../../../constants/api.service';
import CommonPreviewComponent from '../../../employeeScreens/suggestionScheme/commonComponent/CommonPreviewComponent';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import { EvaluatorAttachmentsInputs } from './EvaluatorAttachmentsInputs';


function EvaluatorDetailsViewPopUp(props) {
    const { title, backAction, selectedRecordId, agency, from_agency , suggestionStatus, selectedStatus} = props
    const [loading, setLoading] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null)
    const [selectedAwardType, setSelectedAwardType] = useState(null)
    const [actionError, setActionError] = useState(null)
    const [rejectionReason, setRejectionReason] = useState(null)
    const [clarificationRemark, setClarificationRemark] = useState(null)
    const [evaluatorComment, setEvaluatorComment] = useState(null)
    const [divisionalCoordinatorComment, setDivisionalCoordinatorComment] = useState(null)
    const [isSuggestionImplementable, setIsSuggestionImplementable] = useState(null)
    const [isMonetary, setIsMonetary] = useState(null)
    const [awardAmt, setAwardAmt] = useState(null)
    const [ideaAwardAmt, setIdeaAwardAmt] = useState(null)
    const [ideaAcceptedInBrief, setIdeaAcceptedInBrief] = useState('')
    const [benefitsInBrief, setBenefitsInBrief] = useState(null)
    const [implementationInBrief, setImplementationInBrief] = useState(null)
    const [implementationDate, setImplementationDate] = useState(null)
    const [attachments, setAttachments] = useState([])
    const [attachmentsSubmit, setAttachmentsSubmit] = useState([])
    const [attachmentsSubmitNew, setAttachmentsSubmitNew] = useState([])
    const [suggestionSelectedData, setSuggestionSelectedData] = useState(null)
    const [addSavingDetails, setAddSavingDetails] = useState(false)
    const [awardList, setAwardList] = useState([])
    const [awardCategoryList, setAwardCategoryList] = useState([])
    const [savingCategoryList, setSavingCategoryList] = useState([]);
    const [natureOfSavingList, setNatureOfSavingList] = useState([]);
    const [selectedSavingCategory, setSelectedSavingCategory] = useState(null);
    const [selectedSavingType, setSelectedSavingType] = useState(null);
    const [enteredSavingAmount, setEnteredSavingAmount] = useState(null);
    const [expenseDetails, setExpenseDetails] = useState([]);
    const [expenseDetailsId, setExpenseDetailsId] = useState([]);
    const [detedNewList, setDetedNewList] = useState([]);
    const [isDetedNewList, setIsDetedNewList] = useState(false);
    const [expenseDetailsIdReq, setExpenseDetailsIdReq] = useState([]);
    const [error, setError] = useState(null);
    const [mergedDetails, setMergedDetails] = useState([]);
    const [mergedDetailsById, setMergedDetailsById] = useState([]);

    const [divisionDetails, setDivisionDetails] = useState({
        division: null,
        division_id: null
    })
    const [agencyDetails, setAgencyDetails] = useState({
        agency: null,
        agency_id: null
    })
    const [divisionData, setDivisionData] = useState([])
    const [agencyData, setAgencyData] = useState([])
    const [checkBoxSelect, setcheckBoxSelect] = useState(false)
    const [ideaImplementation, setIdeaImplementation] = useState(null)
    const [filteredActions,setFilteredActions] = useState([])
    const [isImple,setIsImple] = useState(false)
    const loginData = useSelector(state => state.loginState);
    const actionRef = useRef()
    const awardRef = useRef()
    const finalAwardRef = useRef()
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        getSuggestionByIdData(selectedRecordId)
        setSelectedAction("Approve")
    }, [])

    useEffect(() => {
      if(isDetedNewList){
          const merged = [
            ...(detedNewList || []),
            ...mergedDetails,
          ];
          setMergedDetails(merged);
          const mergedId = [
            ...(detedNewList || []),
            ...mergedDetailsById,
          ];
          setMergedDetailsById(mergedId);
        }else{
          const merged = [
            ...(suggestionSelectedData?.saving_details || []),
            ...mergedDetails,
          ];
          setMergedDetails(merged);
          const mergedId = [
            ...(suggestionSelectedData?.saving_details || []),
            ...mergedDetailsById,
          ];
          setMergedDetailsById(mergedId);
        }
  
    }, [suggestionSelectedData])

    useEffect(() => {
      if (selectedSavingCategory && selectedSavingType && enteredSavingAmount) {
        setIsButtonEnabled(true);
      } else {setIsButtonEnabled(false);}
    }, [selectedSavingCategory, selectedSavingType, enteredSavingAmount]);

    const handleFilterDropdown = (sendBack) =>{
      const value = evaluatorActions.filter((ele) => sendBack? ele.value !== 'send back' : ele)
      setFilteredActions(value)
    } 
    const handleAwardAmtChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        value? setAwardAmt(value):setAwardAmt(null)
        setError(
            value === '' ? '' : value < 100 || value > 25000 ? 'Awrad amount must be between 100 and 25,000.' : '');
    };
    const getSuggestionByIdData = (id) => {
        setLoading(true)
        suggestionById(id)
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {

                    const newAttachmentSubmit = data?.data

                    setSuggestionSelectedData(newAttachmentSubmit ? newAttachmentSubmit : null);
                    handleFilterDropdown(data?.data?.suggestion_details?.is_send_back);
                    setAttachmentsSubmit(newAttachmentSubmit?.document_details);
                    // setSelectedAwardType( newAttachmentSubmit?.eval_details.length > 0 ? newAttachmentSubmit?.eval_details[0].award_type_name : null )

                    if(data?.data?.eval_details?.length > 0) {
                      setSelectedAwardType({award_name: data?.data?.eval_details[0].award_type_name})
                      setIsMonetary({id: data?.data?.eval_details[0]?.award_category, award_category: data?.data?.eval_details[0].award_category_name})
                    }

                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => { setLoading(false) })
    }

    const handleActionFilter = (data) => {
      if (suggestionSelectedData?.eval_details?.length > 0 && suggestionSelectedData.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward") {
        setIsImple(true)
      }else{
        setIsImple(false)
      }
      if(suggestionSelectedData?.eval_details[0]?.implementation_brief !== null && suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward"){
        setIdeaImplementation(suggestionSelectedData?.eval_details[0]?.implementation_brief)
      }
      if(suggestionSelectedData?.eval_details[0]?.amount !== null){
        setIdeaAwardAmt(suggestionSelectedData?.eval_details[0]?.amount)
      }
      if(suggestionSelectedData?.eval_details[0]?.amount !== null){
        setAwardAmt(suggestionSelectedData?.eval_details[0]?.amount)
      }
      if(suggestionSelectedData?.eval_details[0]?.implementation_brief !== null && suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "finalaward"){
        setImplementationInBrief(suggestionSelectedData?.eval_details[0]?.implementation_brief)
      }else{
        setImplementationInBrief(null)
      }
      if(suggestionSelectedData?.eval_details[0]?.benefits !== null){
        setBenefitsInBrief(suggestionSelectedData?.eval_details[0]?.benefits)
      }else{
        setBenefitsInBrief(null);
      }
      if(suggestionSelectedData?.eval_details[0]?.implemented_date !== null){
        setImplementationDate(suggestionSelectedData?.eval_details[0]?.implemented_date)
      }
      if(suggestionSelectedData?.suggestion_details.is_suggestion_implementable === true){
        setIsSuggestionImplementable(suggestionSelectedData?.suggestion_details.is_suggestion_implementable)
      }else if(suggestionSelectedData?.suggestion_details.is_suggestion_implementable === false){
        setIsSuggestionImplementable(suggestionSelectedData?.suggestion_details.is_suggestion_implementable)
      }else{
        setIsSuggestionImplementable(null)
      }
      if(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward"){
        setSelectedAwardType({award_name: suggestionSelectedData.eval_details[0].award_type_name})
      }else if(suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "finalaward"){
        setSelectedAwardType({award_name: suggestionSelectedData.eval_details[0].award_type_name})
      }else{
        setSelectedAwardType(null)
      }
        setActionError(null)
        setSelectedAction(data.name)
        getMasterAwardCall()
        getMasterAwardCategoryCall()
        getMasterSavingCategoryCall()
        getMasterNatureOfSavingCall()
        // setSelectedAwardType(null)
        // setIsMonetary(null)
        // setImplementationInBrief('');
        setRejectionReason(null);
        setClarificationRemark(null)
        setEvaluatorComment(null);
        setAgencyDetails({
            agency: null,
            agency_id: null
        })
        setDivisionDetails({
          division: null,
          division_id: null
      })
        setcheckBoxSelect(true ? false : false)
        if (data.name === 'Send Suggestion to Other Evaluator' && divisionData.length < 1) {
            handleDivisionDropdown()
            setRejectionReason(null);
            setDivisionalCoordinatorComment(null);
            setClarificationRemark(null);
            setEvaluatorComment(null);
            setDivisionDetails({
              division: null,
              division_id: null
          })
        } else if (data.name === 'Reject') {
            setDivisionalCoordinatorComment(null);
            setClarificationRemark(null);
            setEvaluatorComment(null);
            setDivisionDetails({
                division: null,
                division_id: null
            })
        } else if (data.name === 'Send Back') {
            setDivisionalCoordinatorComment(null);
            setEvaluatorComment(null);
            setEvaluatorComment(null);
            setDivisionDetails({
                division: null,
                division_id: null
            })
        }
    }
    const handleAwardType = (data) => {
        if (data?.award_name?.toLowerCase().replace(/\s/g, '') === 'ideaaward') {
          setIsImple(true)
          setIsSuggestionImplementable(null)
          setImplementationInBrief(null)
          setAwardAmt(null)
          setImplementationDate(null)
          setSelectedSavingCategory(null);
          setEnteredSavingAmount(null);
          setSelectedSavingType(null);
          setExpenseDetails([])
          // setAttachments([])
          setAttachmentsSubmit([])
          setAddSavingDetails(false)
          setBenefitsInBrief(null);
        } else if(data?.award_name?.toLowerCase().replace(/\s/g, '') === 'finalaward') {
          setIsImple(false)
          setIdeaImplementation(null)
          setIdeaAwardAmt(null)
        }
        setActionError(null)
        setSelectedAwardType(data)
    }
    const handleMonetaryOption = (data) => {
        setActionError(null)
        setAwardAmt(null)
        setIdeaAwardAmt(null)
        setIsMonetary(data)
    }
    // const handleOption = (e) => { setIsSuggestionImplementable(e.target.value) }
    const handleOption = (value) => {
      setIsSuggestionImplementable(value);
    };
    const addAttachment = (data) => {
        setLoading(true)
        let req_body = {
            emp_id: suggestionSelectedData?.suggestion_details?.entered_by ?? '',
            attachment: data.attachment_presigned_url,
            attachment_name: data.name,
            attachment_type: data.extension
        }
        suggestionDocAttachment(req_body, "POST", props, "suggestion_document")
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                      const newAttachmentSubmit = {
                        id: data.id,
                        attachment_presigned_url: res.data.data.attachment_presigned_url,
                        name: res.data.data.attachment_name,
                        type: null,
                        document_category: "saving_details",
                        extension: res.data.data.attachment_extension,
                        path: res.data.data.attachment_path,
                        role: "Evaluator"
                    };

                    setAttachmentsSubmit((prevAttachmentsSubmit) => [
                        ...prevAttachmentsSubmit,
                        newAttachmentSubmit
                    ]);

                    // Update attachmentsSubmitNew state without the id key
                    setAttachmentsSubmitNew((prevAttachmentsSubmitNew) => [
                        ...prevAttachmentsSubmitNew,
                        {
                            attachment_presigned_url: res.data.data.attachment_presigned_url,
                            name: res.data.data.attachment_name,
                            type: null,
                            document_category: "saving_details",
                            extension: res.data.data.attachment_extension,
                            path: res.data.data.attachment_path,
                            role: "Evaluator"
                        }
                    ]);
                    toaster("success", res.data.message)
                }
                else { toaster("warning", res?.data?.message ?? "something went wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "SomeThing Went Wrong") })
            .finally(() => { setLoading(false) })
    }

    const commonAttachmentDelete = (index, data) => {
      const updatedDocuments = [...attachmentsSubmit];
      const deletedDocument = updatedDocuments[index];
      if (deletedDocument.id) {
          deleteDocumentPRE(deletedDocument.id, updatedDocuments, index)
      } else {
          deleteAttachment(deletedDocument, updatedDocuments, index) 
      }
    }

    const commonAttachmentDeleteNew = (index, data) => {
      const updatedDocuments = [...attachmentsSubmitNew];
      const deletedDocument = updatedDocuments[index];
      deleteAttachment(deletedDocument, updatedDocuments, index) 
    }

    const deleteAttachment = (selectedDocuments, documents, index) => {
        setLoading(true)
        deleteS3Documents(selectedDocuments).then((res) => {
            if (res.status === 200 || res.status === 201) {
                documents.splice(index, 1);
                setAttachmentsSubmit(documents);
                setAttachmentsSubmitNew(documents);
                toaster("success", res.data.message ? res.data.message : "")
            } else {
                res?.status !== 404 && toaster("warning", res.message ? res.message : "")
            }
        }).catch((error) => {
            error?.status !== 404 && toaster("error", error?.data?.message ? error.data.message : "")
        }).finally(() => { setLoading(false) })
    }

    const deleteDocumentPRE = (id, documents, index) => {
      setLoading(true)
      deleteDocuments(id).then((res) => {
          if (res.status === 200 || res.status === 201) {
              documents.splice(index, 1);
              setAttachmentsSubmit(documents);
              toaster("success", res.data.message ? res.data.message : "")
          } else {
              res?.status !== 404 && toaster("warning", res.message ? res.message : "")
          }
      }).catch((error) => {
          error?.status !== 404 && toaster("error", error?.data?.message ? error.data.message : "")
      }).finally(() => { setLoading(false) })
    }

    const handleDivisionDropdown = () => {
        setLoading(true)
        let param = {
            comp_code: loginData.userData.CompCode,
            pa: loginData.userData.PersArea
        };
        get_division(param).then((res) => {
            if (res.status === 200) {
                setDivisionData(res?.data?.data?.division_list)
            } else {
                toaster("warning", res.data.message)
            }
        }).catch((err) => {
            console.log("error", err?.data?.message ?? "Something Went Wrong")
        }).finally(() => { setLoading(false) })
    }

    const handleDropdownValue = (data) => {
            setAgencyDetails({
            agency: null,
            agency_id: null
        })
        if (data.division_code) {
            handleAgencyDropdown(data.division_code)
        }
    }

    const handleAgencyDropdown = (id) => {
        setLoading(true)
        let params = {
            division: id
        };
        get_agency(params).then((res) => {
            if (res.status === 200) {
                setAgencyData(res.data.data.agency_list)
            } else {
                toaster("warning", res.data.message)
            }
        }).catch((err) => {
            toaster("error", err?.data?.message ?? "Something Went Wrong")
        }).finally(() => { setLoading(false) })
    }

    const handleBtnAction = () => {
        setLoading(true)
        let payload = {
            action: selectedAction === 'Send Suggestion to Other Evaluator' ? 'Send_to_other_evaluator' : selectedAction === 'Reject' ? 'Reject' : 'Send_back',
            sug_ids: [selectedRecordId],
            comment: selectedAction === 'Send Suggestion to Other Evaluator' ? evaluatorComment : selectedAction === 'Reject' ? rejectionReason : clarificationRemark,
            agency: agencyDetails.agency_id !== '' ? agencyDetails.agency_id : null,
            from_agency_name: from_agency ?? null,
            from_status: 0,
            from_role: "Evaluator",
            to_agency_name: agencyDetails.agency !== '' ? agencyDetails.agency : null,
            to_status: selectedAction === 'Send Suggestion to Other Evaluator' ? 0 : selectedAction === 'Reject' ? 7 : 6,
            to_role: selectedAction === 'Send Suggestion to Other Evaluator' ? 'Evaluator' : selectedAction === 'Reject' ? null : 'Suggestor',
        }
        // updateEvaluatorAction(payload).then((res) => {
        //     if (res) {
        //         if (res.status === 200) {
        //             toaster("success", res?.data?.message);
        //             backAction();
        //         } else {
        //             toaster("warning", res?.data?.message)
        //         }
        //     }
        // }).catch((err) => {
        //     toaster("error", err?.data?.message ?? "Something Went Wrong")
        // }).finally(() => { setLoading(false) })
    }

    const getMasterAwardCall = () => {
        setLoading(true)
        let params = {
            suggestion_scheme_type: suggestionSelectedData?.suggestion_details.scheme_type ? suggestionSelectedData?.suggestion_details.scheme_type : null
        }
        getMasterAward(params)
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setAwardList(data?.data ? data?.data?.award_list : [])
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMasterAwardCategoryCall = () => {
        setLoading(true)
        getMasterAwardCategory()
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setAwardCategoryList(data?.data ? data?.data?.award_category_list : [])
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMasterSavingCategoryCall = () => {
        setLoading(true)
        getMasterSavingCategory()
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setSavingCategoryList(data?.data ? data?.data?.saving_category_list : [])
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }

    const getMasterNatureOfSavingCall = () => {
        setLoading(true)
        getMasterNatureOfSaving()
            .then((res) => {
                const { status, data } = res
                if (status === 200 || status === 201) {
                    setNatureOfSavingList(data?.data ? data?.data?.nature_of_saving_list : [])
                }
                else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
            })
            .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
            .finally(() => {
                setLoading(false)
            })
    }

    const addSuggestion = async () => {
        // setLoading(true)
        // let body = {
        //     current_role: "Evaluator",
        //     from_role: "Evaluator",
        //     to_role: "Division_Coordinator",
        //     action: "Approved",
        //     to_status: 1,
        //     from_status: 0,
        //     from_agency_name: suggestionSelectedData?.suggestion_details.agency_name,
        //     to_agency_name: suggestionSelectedData?.suggestion_details.division_name,
        //     role: "Evaluator",
        //     comment: divisionalCoordinatorComment ? divisionalCoordinatorComment : null,
        //     existing: {
        //         suggestion_details: {
        //           is_suggestion_implementable: isSuggestionImplementable === true ? true : isSuggestionImplementable === false ? false : null ,
        //         },
        //         eval_details: [
        //             {
        //                 award_type_name: selectedAwardType?.award_name,
        //                 award_category_name: isMonetary?.award_category,
        //                 amount: awardAmt?awardAmt:ideaAwardAmt?ideaAwardAmt:null,
        //                 implemented_date:(suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special) && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') == 'finalaward'? moment(implementationDate).format('YYYY-MM-DD'): 
        //                 (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode['Special Implemented'] || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Implemented) && 
        //                 selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') == 'finalaward'? suggestionSelectedData?.suggestion_details?.implemented_date:null ,
        //                 implementation_brief: selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" ? implementationInBrief : ideaImplementation,
        //                 benefits: benefitsInBrief,
        //                 role: "Evaluator",
        //                 award_type: selectedAwardType?.id,
        //                 award_category: isMonetary?.id,
        //             }
        //         ],
        //         saving_details: expenseDetailsId,
        //         document_details: attachmentsSubmitNew
        //     }
        // }
        // await updateSuggestionByEvaluator(body, suggestionSelectedData?.suggestion_details.id)
        //     .then((res) => {
        //         if (res.status === 200 || res.status === 201) {
        //             toaster("success", res?.data?.message ?? "Update Details Successfully");
        //             backAction();
        //         }
        //         else {
        //             res?.status != 404 && toaster("warning", res?.data?.message ? res.data.message : "")
        //         }
        //     })
        //     .catch((error) => {
        //         console.log("error in get Edit request API", error)
        //         error?.status != 404 && toaster("error", error?.data?.message ? error.data.message : "")
        //     })
        //     .finally(() => {
        //         setLoading(false)
        //     })
    }
    const updateSuggestion = async () => {
      // let implementatable

      // if((suggestionSelectedData?.suggestion_details.is_suggestion_implementable === true || selectedAwardType?.award_name === "ideaaward") && isSuggestionImplementable === true ){
      //  implementatable = true
      // console.log("TRUE--------->")
      // }
      // else if((suggestionSelectedData?.suggestion_details.is_suggestion_implementable === false || selectedAwardType?.award_name === "ideaaward") && isSuggestionImplementable === false ){
      //  implementatable = false
      //  console.log("FALSE--------->")

      // } else{
      //   implementatable = null
      // }
      // console.log("BODYYYYYYYYYYYYYYY FINAL--->", awardAmt)
      // console.log("BODYYYYYYYYYYYYYYY IDEA--->", ideaAwardAmt)
      // console.log("BODYYYYYYYYYYYYYYY VALUE--->", selectedAwardType?.award_name !== "ideaaward" || suggestionSelectedData?.eval_details[0]?.award_type_name !== "ideaaward" ? "FINAL" : "IDEA")
      // setLoading(true)
      // let body = {
      //     current_role: "Evaluator",
      //     from_role: "Evaluator",
      //     to_role: "Division_Coordinator",
      //     action: "Approved",
      //     to_status: 1,
      //     from_status: 0,
      //     from_agency_name: suggestionSelectedData?.suggestion_details.agency_name,
      //     to_agency_name: suggestionSelectedData?.suggestion_details.division_name,
      //     role: "Evaluator",
      //     comment: divisionalCoordinatorComment ? divisionalCoordinatorComment : null,
      //     existing: {
      //         suggestion_details: {
      //             is_suggestion_implementable: isSuggestionImplementable === true ? true : isSuggestionImplementable === false ? false : null ,
      //         },
      //         eval_details: [
      //             {
      //                 award_type_name: selectedAwardType?.award_name?  selectedAwardType?.award_name : suggestionSelectedData?.eval_details[0]?.award_type_name,
      //                 award_category_name: isMonetary?.award_category ? isMonetary?.award_category : suggestionSelectedData?.eval_details[0]?.award_category_name,
      //                 amount: selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" || suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" ? (awardAmt ? awardAmt : null) : (ideaAwardAmt ? ideaAwardAmt : null),
      //                 implemented_date:(suggestionSelectedData?.suggestion_details?.scheme_type_name?.toLowerCase() === "normal" || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special) && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') == 'finalaward'? moment(implementationDate ? implementationDate: null).format('YYYY-MM-DD'): 
      //                 (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode['Special Implemented'] || suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Implemented) && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') == 'finalaward'? suggestionSelectedData?.suggestion_details?.implemented_date:null ,
      //                 implementation_brief: (selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" || suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward") ? (implementationInBrief ? implementationInBrief : null) : (ideaImplementation ? ideaImplementation : null),
      //                 benefits: selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "ideaaward" ? null : benefitsInBrief ? benefitsInBrief : null,
      //                 role: "Evaluator",
      //                 award_type: selectedAwardType?.id ? selectedAwardType?.id: suggestionSelectedData?.eval_details[0]?.award_type,
      //                 award_category: isMonetary?.id ? isMonetary?.id : suggestionSelectedData?.eval_details[0]?.award_category,
      //             }
      //         ],
      //         saving_details: expenseDetailsIdReq,
      //         document_details: attachmentsSubmitNew
      //     }
      // }
      // // console.log("updateSuggestion EDIT BODYYYYYYYY", body)
      // await updateSuggestionByEvaluator(body, suggestionSelectedData?.suggestion_details.id)
      //     .then((res) => {
      //         if (res.status === 200 || res.status === 201) {
      //             toaster("success", res?.data?.message ?? "Update Details Successfully");
      //             backAction();
      //         }
      //         else {
      //             res?.status != 404 && toaster("warning", res?.data?.message ? res.data.message : "")
      //         }
      //     })
      //     .catch((error) => {
      //         console.log("error in get Edit request API", error)
      //         error?.status != 404 && toaster("error", error?.data?.message ? error.data.message : "")
      //     })
      //     .finally(() => {
      //         setLoading(false)
      //     })
    }

    const deleteSavingDetailsCall = (id) => {
      setLoading(true)
      const param ={
        sug_id: suggestionSelectedData?.suggestion_details?.id
      }
      deleteSavingDetails(id, param)
          .then((res) => {
              const { status, data } = res
              if (status === 200 || status === 201) {
                setDetedNewList(data?.data ? data?.data: [])
                setIsDetedNewList(true)
                toaster("success", res?.data?.message ?? "Deleted Record Successfully")
              }
              else { toaster("warning", res?.data?.message ?? "Something Went Wrong!") }
          })
          .catch((error) => { toaster("error", error?.data?.message ?? "Something Went Wrong") })
          .finally(() => {
              setLoading(false)
          })
  }

    const onAddSavingDetails = () => {
        const newRecord = {
            saving_category_name: selectedSavingCategory.saving_category_name,
            nature_of_saving_name: selectedSavingType.nature_of_saving_name,
            eval_amount: enteredSavingAmount,
            role: "Evaluator"
        };
        const newRecordId = {
            saving_category: selectedSavingCategory.id,
            nature_of_saving: selectedSavingType.id,
            eval_amount: enteredSavingAmount,
            role: "Evaluator"
        };
        setExpenseDetails([...expenseDetails, newRecord]);
        setExpenseDetailsId([...expenseDetailsId, newRecordId]);
        setSelectedSavingCategory(null);
        setSelectedSavingType(null);
        setEnteredSavingAmount('');
    };

    const onAddSavingDetailsEdit = () => {
      const newRecord = {
          saving_category_name: selectedSavingCategory.saving_category_name,
          nature_of_saving_name: selectedSavingType.nature_of_saving_name,
          eval_amount: enteredSavingAmount,
          role: "Evaluator"
      };
      const newRecordId = {
          saving_category: selectedSavingCategory.id,
          nature_of_saving: selectedSavingType.id,
          eval_amount: enteredSavingAmount,
          role: "Evaluator"
      };

      setMergedDetails([...mergedDetails, newRecord])
      setExpenseDetailsIdReq([...expenseDetailsIdReq, newRecordId]);
      setSelectedSavingCategory(null);
      setSelectedSavingType(null);
      setEnteredSavingAmount('');
  };

    const handleDeleteInitial = (index) => {
        const updatedExpenseDetails = expenseDetails.filter((_, i) => i !== index);
        setExpenseDetails(updatedExpenseDetails);

        const updatedExpenseDetailsid = expenseDetailsId.filter((_, i) => i !== index);
        setExpenseDetailsId(updatedExpenseDetailsid)
    };

    const handleDelete = (indexToDelete) => {
      // Get the ID of the item to delete from mergedDetailsById
      const idToDelete = mergedDetailsById[indexToDelete]?.id;
  
      if (idToDelete !== undefined) {
          // Update mergedDetails by filtering out the item at indexToDelete
          setMergedDetails((prevDetails) =>
              prevDetails.filter((_, index) => index !== indexToDelete)
          );
  
          // Update mergedDetailsById by filtering out the item at indexToDelete
          setMergedDetailsById((prevDetails) =>
              prevDetails.filter((_, index) => index !== indexToDelete)
          );
  
          // Call the API to delete the item with the extracted ID
          deleteSavingDetailsCall(idToDelete);
      } else {
        toaster("success", "Saving details deleted successfully")
        setMergedDetails((prevDetails) =>
          prevDetails.filter((_, index) => index !== indexToDelete)
      );
          console.error('No ID found for the item to delete');
      }
  };
  

    const keys = ['title','present_status', 'earlier_status', 'proposed_changes', 'changes_implemented', 'benefits','comment','clarification_to_divc','applicable_areas', 'scheme_name']
    const renderFields = (fields) => (
        fields.length > 0 ? (
            fields.map((field) => {
              const { name, key, type } = field;
              
              if(key === 'scheme_name' ) {
                return (suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode.Special || suggestionSelectedData?.suggestion_details['scheme_type_code'] === schemeCode['Special Implemented'] )  &&  ( <div className="col-4" key={key}>
                      <div className="text14_bold">{name}</div>
                      <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                          {type === 'date' ? DDMMYYYY(selectedStatus.status_code === statusCodes['Pending at Divisional Co-ordinator'] ? suggestionSelectedData?.eval_details[0][key]: suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
                      </div>
                      <div className='mt-10px mb-10px'><hr /></div>
                  </div>);
              }else if (key === 'title'){
                return ( <div className="col-4" key={key}>
                  <div className="text14_bold">{name}</div>
                  <div className={keys.includes(key) ? " text14_light mt-1 custom-pre-suggestor " : ' text14_light mt-1 '}>
                      {type === 'date' ? DDMMYYYY(selectedStatus.status_code === statusCodes['Pending at Divisional Co-ordinator'] ? suggestionSelectedData?.eval_details[0][key]: suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
                  </div>
                  <div className='mt-10px mb-10px'><hr /></div>
                 </div>)
              }              
              else {
                return ( <div className="col-4" key={key}>
                  <div className="text14_bold">{name}</div>
                  <div className={keys.includes(key) ? " text14_light mt-1 popup-overflow " : ' text14_light mt-1'}>
                      {type === 'date' ? DDMMYYYY(selectedStatus.status_code === statusCodes['Pending at Divisional Co-ordinator'] ? suggestionSelectedData?.eval_details[0][key]: suggestionSelectedData?.suggestion_details[key]) ?? '--' : suggestionSelectedData?.suggestion_details[key] ?? '--'}
                  </div>
                  <div className='mt-10px mb-10px'><hr /></div>
                 </div>)
              }
            })
        ) : (
            <DataNotFound />
        )
    );

    const handleChange = (e) => {
        const value = e.target.value;
        setIdeaImplementation(null);
        if (value === '') {
            setImplementationInBrief(null);
        } else {
            setImplementationInBrief(value);
        }
    };

    const handleIdeaChange = (e) => {
        const value = e.target.value;
        setBenefitsInBrief(null);
        if (value === '') {
            setIdeaImplementation(null);
        } else {
            setIdeaImplementation(value);
        }
    };

    const handleChangeBenifit = (e) => {
        const value = e.target.value;
        if (value === '') {
            setBenefitsInBrief(null);
        } else {
            setBenefitsInBrief(value);
        }
    };

    return (
      <div className={"modal popup-box "}>
        <LoaderIndicator loading={loading} />
        <div
          className={
            "modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem"
          }
        >
          <div
            className={
              `modal-content bg-white border_radius_12px max-height-100 ` +
              `w-85vw`
            }
          >
            <div className="modal-header mx-2">
              <div className="row">
                <h4 className="popup-title mt-2">{title}</h4>
              </div>
              <button
                type="button"
                className="close pt-4"
                data-dismiss="modal"
                onClick={backAction}
              >
                &times;
              </button>
              <div className="solid-border"></div>
            </div>
            <div className="column mt-3 scroll-y pb-20px m-2">
              <div className="d-flex flex-column">
                <div className="row w-100 mb-3 row-gap-10px">
                  {suggestionSelectedData?.suggestion_details
                    .scheme_type_code === schemeCode.Special ||
                  suggestionSelectedData?.suggestion_details
                    .scheme_type_code === schemeCode.Normal
                    ? renderFields(suggestionDataFields)
                    : renderFields(suggestionDataFieldsConditinal)}
                </div>
              </div>
              <div className="mb-10px">
                <hr />
              </div>

              <div className="toggle-card-heading ml-10 mb-10">
                {"History Details"}
              </div>
              {suggestionSelectedData?.history_details.length > 0 ? (
                <>
                  <div className="d-flex flex-column">
                    <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                      <Table
                        isSrNoShow={true}
                        details={suggestionSelectedData}
                        data={suggestionSelectedData.history_details}
                        columns={historyDataFields}
                        multiSelect={false}
                        className={"w-80vw max-height-33vh"}
                      />
                    </div>
                  </div>
                  <div className="mt-10px mb-10px">
                    <hr />
                  </div>
                </>
              ) : (
                <DataNotFound />
              )}
              {/* {(suggestionSelectedData?.suggestion_details.scheme_type_name ===
                "SPECIAL IMPLEMENTED" ||
                suggestionSelectedData?.suggestion_details.scheme_type_name ===
                  "IMPLEMENTED") && (
                <>
                  <div className="toggle-card-heading ml-10 mb-10">
                    {"Member Details"}
                  </div>
                  {suggestionSelectedData?.member_details.length > 0 ? (
                    <>
                      <div className="d-flex flex-column">
                        <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                          <Table
                            isSrNoShow={true}
                            data={suggestionSelectedData.member_details}
                            columns={memberDataFields}
                            multiSelect={false}
                            className={"w-75vw max-height-33vh"}
                          />
                        </div>
                      </div>
                      <div className="mt-10px mb-10px">
                        <hr />
                      </div>
                    </>
                  ) : (
                    <DataNotFound />
                  )}
                </>
              )} */}
              {suggestionSelectedData?.document_details.filter((e) => e.document_category === 'suggestion_details').length > 0 && (
                <>
                  <div className="toggle-card-heading ml-10 mb-10">
                    {"Suggestor Documents"}
                  </div>
                    <CommonPreviewComponent
                      data={suggestionSelectedData?.document_details?.filter(listValue => listValue.role === "Suggestor")}
                      download={true}
                    />
                  <div className="mt-10px mb-10px">
                    <hr />
                  </div>
                </>)
              }
                  <>

                  <>
                  { selectedAwardType && <>

                  <div className="toggle-card-heading ml-10 mb-10 required">
                    {"Award Type"}
                  </div>
                  <div className="d-flex flex-row ml-10">
                    <div className="row w-20 row-gap-10px">
                      <DynamicDropdown
                        placeholder={"Select Award type"}
                        data={awardList}
                        render="award_name"
                        value={selectedAwardType?.award_name != null ? selectedAwardType?.award_name : suggestionSelectedData?.eval_details[0]?.award_type_name }
                        selectedValue={(data) => handleAwardType(data)}
                        arrow={"true"}
                        disabled={true}
                        height={"NotificationHeight--L"}
                        width={"w-16rem"}
                        refs={awardRef}
                      />
                    </div>
                    <div className="row w-20 row-gap-10px">
                      <DynamicDropdown
                        placeholder={"Select Award Category"}
                        data={awardCategoryList}
                        render="award_category"
                        value={isMonetary?.award_category != null ? isMonetary?.award_category :  suggestionSelectedData?.eval_details[0]?.award_category_name }
                        selectedValue={(data) => handleMonetaryOption(data)}
                        arrow={"true"}
                        disabled={true}
                        height={"NotificationHeight-L"}
                        width={"w-16rem"}
                        refs={finalAwardRef}
                      />
                    </div>
                    {(selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "ideaaward" || isImple ) &&
                      !(
                        suggestionSelectedData?.suggestion_details
                          .scheme_type_code === schemeCode['Special Implemented'] ||
                        suggestionSelectedData?.suggestion_details
                          .scheme_type_code === schemeCode.Implemented
                      ) && (
                        <div className="row align-item-center ml-2 pt-10px pb-10px pl-10px">
                          <span className="bold-font mr-10px">
                            Is Suggestion Implementable:  { `${ suggestionSelectedData?.suggestion_details?.is_suggestion_implementable ? suggestionSelectedData?.suggestion_details?.is_suggestion_implementable === true ? "yes" : "no" : "null" }` }
                          </span>
                          
                        </div>
                      )}
                    {actionError?.id == "2" && (
                      <span className="p-sub-heading mt-2 errorBlock">
                        {actionError.message}
                      </span>
                    )}
                  </div>


                  {(selectedAwardType || suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') ==="finalaward" || suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward") && (
                    <>
                      <div className="mt-10px mb-10px">
                        <hr />
                      </div>

                      {((selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "finalaward" && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward") || (selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "ideaaward" && suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "finalaward")) &&
                        <div className="row gap-10">
                          <div className="d-flex flex-column ml-10 w-47">
                            <div className="text14_bold required">
                              Implementation details in brief
                            </div>
                            <div className="row w-100 row-gap-10px">
                              <textarea
                                className="form-control esep-placeholder-editable px-3"
                                type="textarea"
                                onChange={handleChange}
                                disabled={true}
                                value={ suggestionSelectedData?.eval_details[0]?.implementation_brief ?? null }
                                placeholder={"Type here..."}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column ml-10 w-47">
                            <div className="text14_bold required">
                              Benefits in brief
                            </div>
                            <div className="row w-100 row-gap-10px">
                              <textarea
                                className="form-control esep-placeholder-editable px-3"
                                type="textarea"
                                disabled={true}
                                onChange={handleChangeBenifit}
                                value={suggestionSelectedData?.eval_details[0]?.benefits ?? null}
                                placeholder={"Type here..."}
                              />
                            </div>
                          </div>
                          {(suggestionSelectedData?.eval_details[0]?.award_category_name === "Monetary") && (
                            <div className="d-flex flex-column ml-10 w-47">
                              <div className="text14_bold required">
                                {"Award Amount in ₹"}
                              </div>
                              <div className="esep-placeholder-editable">
                                <input
                                  className="form-control esep-placeholder-editable px-3"
                                  type="number"
                                  onChange={(e)=>handleAwardAmtChange(e)}
                                  value={suggestionSelectedData?.eval_details[0]?.amount ?? null}
                                  disabled={true}
                                  placeholder={"Enter Award Amount... "}
                                  // onWheel={(e) => e.target.blur()}
                                  // onKeyPress={(e) => {
                                  //   if (!/[0-9]/.test(e.key)) {
                                  //       e.preventDefault();
                                  //   }
                                  // }}
                                  min={1}
                                  inputmode="decimal"
                                  pattern="\d*"
                                />
                              </div>
                              
                            </div>
                          )}
                          {
                            (suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Special ||
                            suggestionSelectedData?.suggestion_details?.scheme_type_code === schemeCode.Normal) && (
                              <div className='col-12 col-md-3 mb-3'>
                                <div className='text14_bold required'>Implementation Date</div>
                                <div className='p-placeholder-text'>
                                  <input
                                    className="form-control p-placeholder"
                                    type="date"
                                    style={{ height: '38px' }}
                                    max={today}
                                    disabled={true}
                                    value={suggestionSelectedData?.eval_details[0]?.implemented_date ?? null}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setImplementationDate(value ? value : null);
                                    }}/>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      }
                       
                      {((selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') === "ideaaward" && selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "finalaward" ) || (selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') !== "finalaward" && suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "ideaaward")) &&
                        <div className="row gap-10">
                          <div className="d-flex flex-column ml-10 w-47">
                            <div className="text14_bold required">
                              Idea Accepted in brief
                            </div>
                            <div className="row w-100 row-gap-10px">
                              <textarea
                                className="form-control esep-placeholder-editable px-3"
                                type="textarea"
                                disabled={true}
                                // onChange={handleIdeaChange}
                                value={suggestionSelectedData?.eval_details[0]?.implementation_brief ?? null}
                                placeholder={"Type here..."}
                              />
                            </div>
                          </div>
                          {(isMonetary?.award_category === "Monetary" || (isMonetary?.award_category !== "Non-Monetary" && suggestionSelectedData?.eval_details[0]?.award_category_name === "Monetary")) && (
                            <div className="d-flex flex-column ml-10 w-47">
                              <div className="text14_bold required">
                                {"Award Amount in ₹"}
                              </div>
                              <div className="esep-placeholder-editable">
                              <input
                                      className="form-control esep-placeholder-editable px-3"
                                      type="text"
                                      // onChange={(e) => {
                                      //   const value = e.target.value.replace(/\D/g, '');
                                      //   value?setIdeaAwardAmt(e.target.value):setIdeaAwardAmt(null);
                                      // }}
                                      disabled={true}
                                      value={suggestionSelectedData?.eval_details[0]?.amount ?? null}
                                      // onKeyPress={(e) => {
                                      //   if (!/[0-9]/.test(e.key)) {
                                      //       e.preventDefault();
                                      //   }
                                      // }}
                                      placeholder={"Enter Award Amount... "}
                                      onWheel={(e) => e.target.blur()}
                                      min={1}
                                      inputMode="decimal"
                                      pattern="\d*"
                                    />
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </>
                  )}
                  {(selectedAwardType?.award_name?.toLowerCase().replace(/\s/g, '') == "finalaward" || suggestionSelectedData?.eval_details[0]?.award_type_name?.toLowerCase().replace(/\s/g, '') === "finalaward") && (
                    <>
                      <div className="mt-10px mb-10px">
                        <hr />
                      </div>
                      {(suggestionSelectedData?.suggestion_details.scheme_type_code ===
                          schemeCode['Special Implemented'] ||
                          suggestionSelectedData?.suggestion_details.scheme_type_code ===
                            schemeCode.Implemented) && (
                          <>
                            <div className="toggle-card-heading ml-10 mb-10">
                              {"Member Details"}
                            </div>
                            {suggestionSelectedData?.member_details.length > 0 ? (
                              <>
                                <div className="d-flex flex-column">
                                  <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                                    <Table
                                      isSrNoShow={true}
                                      data={suggestionSelectedData.member_details}
                                      columns={memberDataFields}
                                      multiSelect={false}
                                      className={"w-75vw max-height-33vh"}
                                    />
                                  </div>
                                </div>
                                <div className="mt-10px mb-10px">
                                  <hr />
                                </div>
                              </>
                            ) : (
                              <DataNotFound />
                            )}
                          </>
                        )}
                      <div className="row justify-content-between w-100">
                        <div className="d-flex row justify-content-between w-100">
                          <div className="toggle-card-heading ml-10 mb-10">
                            {"Saving Details"}  
                          </div>
                        </div>
                            <div className="d-flex flex-column p-20px w-100">
                              <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                                <table className="table gemsAdmin">
                                  <thead
                                    className="w-100"
                                    style={{ zIndex: "1" }}
                                  >
                                    <tr className="w-100">
                                      <th scope="col">
                                        <div className="thead pr-0 w-35px">
                                          {"Sr No"}
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="thead">
                                          {"Saving Category"}
                                        </div>
                                      </th>
                                      <th scope="col">
                                        <div className="thead">
                                          {"Saving Type"}
                                        </div>
                                      </th>
                                      <th scope="col">
                                          <div className="thead">
                                            {"Entered By"}
                                          </div>
                                        </th>
                                        <th scope="col">
                                          <div className="thead">
                                            {"Entered Date"}
                                          </div>
                                        </th>
                                      <th scope="col">
                                        <div className="thead">
                                          {"Saving Amount"}
                                        </div>
                                      </th>
                                      {/* <th scope="col">
                                        <div className="thead ml-06rem">
                                          {"Actions"}
                                        </div>
                                      </th> */}
                                    </tr>
                                  </thead>
                                  <tbody className="adminScreentbody w-100">
                                  {mergedDetails?.length > 0 ? (
                                      mergedDetails.map((listValue, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="td w-20px">
                                                {index + 1}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="td d-flex">
                                                {listValue.saving_category_name}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="td d-flex">
                                                {listValue.nature_of_saving_name}
                                              </div>
                                            </td>
                                            <td>
                                                <div className="td d-flex">
                                                  {listValue.role ?? '--'}
                                                </div>
                                              </td>
                                              <td>
                                                <div className="td d-flex">
                                                  {DDMMYYYY(listValue.created_datetime) ?? '--'}
                                                </div>
                                              </td>
                                              <td>
                                              <div className="td d-flex">
                                                {listValue.eval_amount}
                                              </div>
                                            </td>
                                            {/* <td className="d-flex">
                                              <div
                                                onClick={() =>
                                                  handleDelete(index)
                                                }
                                              >
                                                <img
                                                  alt="view"
                                                  src={delete_action}
                                                  className="pointer mx-2"
                                                />
                                              </div>
                                            </td> */}
                                          </tr>
                                        );
                                      })
                                    )  : (
                                      <tr>
                                        <td colSpan="5">
                                          <DataNotFound />
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="d-flex flex-column w-100">
                              <div className="mt-10px mb-10px">
                                <hr />
                              </div>
                              {/* <div className="toggle-card-heading ml-3 mt-10px mb-10">
                                {"Add Saving Documents"}
                              </div> */}
                              <EvaluatorAttachmentsInputs
                                attachment={attachmentsSubmit}
                                onChange={(attachment) => {
                                  addAttachment(attachment);
                                }}
                                isMandatory={
                                  selectedAction == "Approve" ? false : true
                                }
                                length={5}
                                maxSize={2}
                                uploadNote={
                                  "You can upload attachment up to 2MB size only (pdf/jpeg/png)"
                                }
                                onDelete={(index, data) => {
                                  commonAttachmentDelete(index, data);
                                }}
                                readOnly={true}
                              />
                            </div>
                      </div>
                    </>
                  )}
                  
                  <div className="mt-10px mb-10px">
                    <hr />
                  </div>

                  </>}

                  <div className="toggle-card-heading required ml-10 mb-10">
                    {"Comment"}
                  </div>
                  <div className="d-flex flex-row ml-10 w-70 gap-100px">
                    <div className="row w-100 flex-direction-column row-gap-10px">
                      <textarea
                        className="form-control esep-placeholder-editable px-3"
                        type="textarea"
                        // onChange={(e) => {
                        //   setDivisionalCoordinatorComment(e.target.value);
                        // }}
                        disabled={true}
                        value={suggestionSelectedData?.suggestion_details?.comment}
                        placeholder={"Enter Comment to Divisional Coordinator"}
                      />
                      {/* {((divisionalCoordinatorComment !== null &&
                        divisionalCoordinatorComment === "") ||
                        !/[a-zA-Z0-9]/.test(divisionalCoordinatorComment) ||
                        /\s{2,}/.test(divisionalCoordinatorComment)) && (
                        <div className="text-left p-sub-heading errorBlock ml-2">
                          Comments cannot be blank, can only include alphanumeric
                          characters and cannot includes more than 1 spaces.
                        </div>
                      )} */}
                    </div>
                    {/* <div className='d-flex flex-row'>
                    <div className="text14_bold">{"Comment for Clarification :"}</div>
                      <div className={'text14_light mt-1'}>
                        {suggestionSelectedData?.suggestion_details?.comment}
                      </div>
                    </div> */}
                  </div>
                </>
</>

<>
{/* {selectedAction == "Approve" && (
  <>
    <div className="toggle-card-heading ml-10 mb-10 required">
      {"Award Type Selection"}
    </div>
    <div className="d-flex flex-row ml-10">
      <div className="row w-20 row-gap-10px">
        <DynamicDropdown
          placeholder={"Select Award type"}
          data={awardList}
          render="award_name"
          value={selectedAwardType?.award_name}
          selectedValue={(data) => handleAwardType(data)}
          arrow={"true"}
          height={"NotificationHeight"}
          width={"w-16rem"}
          refs={awardRef}
        />
      </div>
      <div className="row w-20 row-gap-10px">
        <DynamicDropdown
          placeholder={"Select Award Category"}
          data={awardCategoryList}
          render="award_category"
          value={isMonetary?.award_category}
          selectedValue={(data) => handleMonetaryOption(data)}
          arrow={"true"}
          height={"NotificationHeight"}
          width={"w-16rem"}
          refs={finalAwardRef}
        />
      </div>
      {selectedAwardType?.award_name == "ideaaward" &&
        !(
          suggestionSelectedData?.suggestion_details
            .scheme_type_name === "SPECIAL IMPLEMENTED" ||
          suggestionSelectedData?.suggestion_details
            .scheme_type_name === "IMPLEMENTED"
        ) && (
          <div className="row align-item-center ml-2 pt-10px pb-10px pl-10px">
            <span className="bold-font mr-10px">
              Is Suggestion Implementable:{" "}
            </span>
            <input
              type="radio"
              id="yes"
              value={true}
              checked={isSuggestionImplementable === true }
              onChange={() => handleOption(true)}
              className="purple-radio mr-10px"
            />
            <label
              className="td-choice-pay mr-15px"
              htmlFor="yes"
            >
              Yes
            </label>
            <input
              type="radio"
              id="no"
              value={false}
              checked={isSuggestionImplementable === false }
              onChange={() => handleOption(false)}
              className="purple-radio mr-10px"
            />
            <label className="td-choice-pay mr-15px" htmlFor="no">
              No
            </label>
          </div>
        )}
      {actionError?.id == "2" && (
        <span className="p-sub-heading mt-2 errorBlock">
          {actionError.message}
        </span>
      )}
    </div>
    {selectedAwardType && (
      <>
        <div className="mt-10px mb-10px">
          <hr />
        </div>
        {selectedAwardType?.award_name == "finalaward" ? (
          <div className="row gap-10">
            <div className="d-flex flex-column ml-10 w-47">
              <div className="text14_bold required">
                Implementation details in brief
              </div>
              <div className="row w-100 row-gap-10px">
                <textarea
                  className="form-control esep-placeholder-editable px-3"
                  type="textarea"
                  onChange={handleChange}
                  value={implementationInBrief && implementationInBrief }
                  placeholder={"Type here..."}
                />
              </div>
            </div>
            <div className="d-flex flex-column ml-10 w-47">
              <div className="text14_bold required">
                Benefits in brief
              </div>
              <div className="row w-100 row-gap-10px">
                <textarea
                  className="form-control esep-placeholder-editable px-3"
                  type="textarea"
                  onChange={handleChangeBenifit}
                  value={benefitsInBrief}
                  placeholder={"Type here..."}
                />
              </div>
            </div>
            {isMonetary?.award_category === "Monetary" && (
              <div className="d-flex flex-column ml-10 w-47">
                <div className="text14_bold">
                  {"Award Amount in ₹"}
                </div>
                <div className="esep-placeholder-editable">
                  <input
                    className="form-control esep-placeholder-editable px-3"
                    type="number"
                    onChange={(e)=>handleAwardAmtChange(e)}
                    value={awardAmt}
                    placeholder={"Enter Award Amount... "}
                    onWheel={(e) => e.target.blur()}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                      }
                  }}
                    min={1}
                    inputmode="decimal"
                    pattern="\d*"
                  />
                </div>
                {awardAmt<100 && awardAmt !== null && awardAmt !=='' && (
                  <div className="p-sub-heading ml-3 errorBlock">
                    Minimum Award value is Rs. 100
                  </div>
                )}
                {awardAmt > 25000 && awardAmt !== null && awardAmt !=='' && (
                  <div className="p-sub-heading ml-3 errorBlock">
                    Maximum Award value can be Rs. 25000
                  </div>
                )}
              </div>
            )}
            {
              (suggestionSelectedData?.suggestion_details?.scheme_type_name === "SPECIAL" ||
              suggestionSelectedData?.suggestion_details?.scheme_type_name === "NORMAL") && (
                <div className='col-12 col-md-3 mb-3'>
                  <div className='text14_bold required'>Implementation Date</div>
                  <div className='p-placeholder-text'>
                    <input
                      className="form-control p-placeholder"
                      type="date"
                      style={{ height: '38px' }}
                      max={today}
                      onChange={(e) => {
                        const value = e.target.value;
                        setImplementationDate(value ? value : null);
                      }}/>
                  </div>
                </div>
              )
            }
          </div>
        ) : (
          <div className="row gap-10">
            <div className="d-flex flex-column ml-10 w-47">
              <div className="text14_bold required">
                Idea Accepted in brief
              </div>
              <div className="row w-100 row-gap-10px">
                <textarea
                  className="form-control esep-placeholder-editable px-3"
                  type="textarea"
                  onChange={handleIdeaChange}
                  value={ideaImplementation && ideaImplementation}
                  placeholder={"Type here..."}
                />
              </div>
            </div>
            {isMonetary?.award_category === "Monetary" && (
              <div className="d-flex flex-column ml-10 w-47">
                <div className="text14_bold">
                  {"Award Amount in ₹"}
                </div>
                <div className="esep-placeholder-editable">
                <input
                        className="form-control esep-placeholder-editable px-3"
                        type="text"
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          value?setIdeaAwardAmt(e.target.value):setIdeaAwardAmt('');
                        }}
                        value={ideaAwardAmt}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                              e.preventDefault();
                          }
                      }}
                        placeholder={"Enter Award Amount... "}
                        onWheel={(e) => e.target.blur()}
                        min={1}
                        inputMode="decimal"
                        pattern="\d*"
                      />
                  {ideaAwardAmt < 100  &&
                    ideaAwardAmt !== "" &&
                    ideaAwardAmt !== null && (
                      <div className="p-sub-heading ml-3 errorBlock">
                        Minimum Award value is Rs. 100
                      </div>
                    )}
                  {ideaAwardAmt > 25000 &&
                    ideaAwardAmt !== "" &&
                    ideaAwardAmt !== null && (
                      <div className="p-sub-heading ml-3 errorBlock">
                        Maximum Award value can be Rs. 25000
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )}
    {selectedAwardType?.award_name == "finalaward" && (
      <>
        <div className="mt-10px mb-10px">
          <hr />
        </div>
        {(suggestionSelectedData?.suggestion_details.scheme_type_name ===
            "SPECIAL IMPLEMENTED" ||
            suggestionSelectedData?.suggestion_details.scheme_type_name ===
              "IMPLEMENTED") && (
            <>
              <div className="toggle-card-heading ml-10 mb-10">
                {"Member Details"}
              </div>
              {suggestionSelectedData?.member_details.length > 0 ? (
                <>
                  <div className="d-flex flex-column">
                    <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                      <Table
                        isSrNoShow={true}
                        data={suggestionSelectedData.member_details}
                        columns={memberDataFields}
                        multiSelect={false}
                        className={"w-75vw max-height-33vh"}
                      />
                    </div>
                  </div>
                  <div className="mt-10px mb-10px">
                    <hr />
                  </div>
                </>
              ) : (
                <DataNotFound />
              )}
            </>
          )}
        <div className="row justify-content-between w-100">
          <div className="d-flex row justify-content-between w-100">
            <div className="toggle-card-heading ml-10 mb-10">
              {"Saving Details (Optional)"}
            </div>
            <div
              div
              className="pointer row add-btn-text flex-row-reverse p-11px"
              onClick={() => {
                setAddSavingDetails(!addSavingDetails);
              }}
            >
              {addSavingDetails
                ? "- Remove Saving Details"
                : "+ Add Saving Details"}
            </div>
          </div>
          {addSavingDetails ? (
            <>
              <div className="d-flex flex-column w-100">
                <div className="toggle-card-heading ml-3 mb-10">
                  {"Add Saving Details"}
                </div>
                <div className="row w-100 gap-10 gap-7px">
                  <div className="ml-3">
                    <DynamicDropdown
                      data={savingCategoryList}
                      render="saving_category_name"
                      placeholder={"Select Saving Category"}
                      value={
                        selectedSavingCategory?.saving_category_name
                      }
                      selectedValue={(data) => {
                        setSelectedSavingCategory(data);
                      }}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-16rem"}
                      // refs={awardRef}
                    />
                  </div>
                  <div className="ml-3">
                    <DynamicDropdown
                      data={natureOfSavingList}
                      render="nature_of_saving_name"
                      placeholder={"Select Saving Type"}
                      value={
                        selectedSavingType?.nature_of_saving_name
                      }
                      selectedValue={(data) => {
                        setSelectedSavingType(data);
                      }}
                      arrow={"true"}
                      height={"NotificationHeight"}
                      width={"w-16rem"}
                      // refs={awardRef}
                    />
                  </div>
                  <div className="w-17rem">
                    <input
                      className="form-control p-placeholder-evaluator"
                      type="text"
                      name="PG_Name"
                      maxLength={50}
                      readOnly={false}
                      placeholder={"Enter Saving Amount in ₹..."}
                      onChange={(e) => {
                        setEnteredSavingAmount(e.target.value);
                      }}
                      value={enteredSavingAmount}
                      onKeyPress={(e) => {
                        if (!/[0-9.]$/.test(e.key)) {
                            e.preventDefault();
                        }
                    }}
                    />
                  </div>
                  <div className="">
                    <input
                      type="button"
                      value="ADD"
                      className={`${isButtonEnabled ? 'validateBtn' : 'validateBtnDisabled'}`}
                      onClick={onAddSavingDetails}
                      disabled={!isButtonEnabled} 
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column p-20px w-100">
                <div className="row w-100 mb-3 row-gap-10px justify-content-center">
                  <table className="table gemsAdmin">
                    <thead
                      className="w-100"
                      style={{ zIndex: "1" }}
                    >
                      <tr className="w-100">
                        <th scope="col">
                          <div className="thead pr-0 w-35px">
                            {"Sr No"}
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead">
                            {"Saving Category"}
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead">
                            {"Saving Type"}
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead">
                            {"Entered By"}
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead">
                            {"Evaluation Amount"}
                          </div>
                        </th>
                        <th scope="col">
                          <div className="thead ml-06rem">
                            {"Actions"}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="adminScreentbody w-100">
                      {expenseDetails.length > 0 ? (
                        expenseDetails.map((listValue, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="td w-20px">
                                  {index + 1}
                                </div>
                              </td>
                              <td>
                                <div className="td d-flex">
                                  {listValue.saving_category_name}
                                </div>
                              </td>
                              <td>
                                <div className="td d-flex">
                                  {listValue.nature_of_saving_name}
                                </div>
                              </td>
                              <td>
                                <div className="td d-flex">
                                  {listValue.role}
                                </div>
                              </td>
                              <td>
                                <div className="td d-flex">
                                  {listValue.eval_amount}
                                </div>
                              </td>
                              <td className="d-flex">
                                <div
                                  onClick={() =>
                                    handleDeleteInitial(index)
                                  }
                                >
                                  <img
                                    alt="view"
                                    src={delete_action}
                                    className="pointer mx-2"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <DataNotFound />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="d-flex flex-column w-100">
                <div className="mt-10px mb-10px">
                  <hr />
                </div>
                <div className="toggle-card-heading ml-3 mt-10px mb-10">
                  {"Add Saving Documents"}
                </div>
                <EvaluatorAttachmentsInputs
                  attachment={attachmentsSubmit}
                  onChange={(attachment) => {
                    addAttachment(attachment);
                  }}
                  isMandatory={
                    selectedAction == "Approve" ? false : true
                  }
                  length={5}
                  maxSize={2}
                  uploadNote={
                    "You can upload attachment up to 2MB size only (pdf/jpeg/png)"
                  }
                  onDelete={(index, data) => {
                    commonAttachmentDeleteNew(index, data);
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </>
    )}

    <div className="mt-10px mb-10px">
      <hr />
    </div>
    <div className="toggle-card-heading required ml-10 mb-10">
      {"Comment to Divisional Coordinator"}
    </div>
    <div className="d-flex flex-column ml-10 w-50">
      <div className="row w-100 flex-direction-column row-gap-10px">
        <textarea
          className="form-control esep-placeholder-editable px-3"
          type="textarea"
          onChange={(e) => {
            setDivisionalCoordinatorComment(e.target.value);
          }}
          value={divisionalCoordinatorComment}
          placeholder={"Enter Comment to Divisional Coordinator"}
        />
        {((divisionalCoordinatorComment !== null &&
          divisionalCoordinatorComment === "") ||
          !/[a-zA-Z0-9]/.test(divisionalCoordinatorComment) ||
          /\s{2,}/.test(divisionalCoordinatorComment)) && (
          <div className="text-left p-sub-heading errorBlock ml-2">
            Comments cannot be blank, can only include alphanumeric
            characters and cannot includes more than 1 spaces.
          </div>
        )}
      </div>
    </div>
  </>
)} */}
</> 

            </div>
            <div className="popup-button-background justify-content-end align-item-center pr-3 p-10px">
              {/* {
                 selectedAction === "Approve" ? (
                  <>
                  {suggestionSelectedData?.suggestion_details?.is_divisional_coordinator_send_back ?
                    <input
                    type="button"
                    value="APPROVE"
                    className={
                      "ml-2 validateBtn"
                    }
                    disabled={false}
                    onClick={() => updateSuggestion()}
                  />
                  :
                  <input
                    type="button"
                    value="APPROVE"
                    className={
                      (selectedAwardType?.award_name != null &&
                        isMonetary?.award_category != null &&
                        benefitsInBrief != null &&
                        implementationInBrief != null &&
                        ((suggestionSelectedData?.suggestion_details?.scheme_type_name?.toLowerCase() === "normal" || 
                        suggestionSelectedData?.suggestion_details?.scheme_type_name?.toLowerCase() === "special") && 
                        selectedAwardType?.award_name === 'finalaward' ? implementationDate !== null : true) &&
                        divisionalCoordinatorComment !== '' &&
                        divisionalCoordinatorComment !== null &&
                        (error === "" || error === null)) ||
                      (ideaImplementation !== null && isMonetary?.award_category != null &&
                        ideaImplementation !== "" &&
                        !(
                          divisionalCoordinatorComment === "" ||
                          !/[a-zA-Z0-9]/.test(divisionalCoordinatorComment) ||
                          divisionalCoordinatorComment === null ||
                          /\s{2,}/.test(divisionalCoordinatorComment)
                        ) &&
                        !(
                          (ideaAwardAmt < 100 || ideaAwardAmt > 25000) &&
                          ideaAwardAmt !== "" &&
                          ideaAwardAmt !== null
                        ))
                        ? "ml-2 validateBtn"
                        : "ml-2 validateBtnDisabled"
                    }
                    disabled={
                      (selectedAwardType?.award_name != null &&
                        isMonetary?.award_category != null &&
                        benefitsInBrief != null &&
                        implementationInBrief != null &&
                        // implementationDate!=null &&
                        ((suggestionSelectedData?.suggestion_details?.scheme_type_name?.toLowerCase() === "normal" || 
                        suggestionSelectedData?.suggestion_details?.scheme_type_name?.toLowerCase() === "special") && 
                        selectedAwardType?.award_name === 'finalaward' ? implementationDate !== null : true) &&
                        benefitsInBrief !== "" &&
                        implementationInBrief !== "" &&
                        (divisionalCoordinatorComment !== '' ||
                          evaluatorComment !== null ||
                          rejectionReason !== null ||
                          clarificationRemark !== null) &&
                        (error === "" || error === null)) ||
                      (ideaImplementation !== null && isMonetary?.award_category != null &&
                        ideaImplementation !== "" &&
                        !(
                          divisionalCoordinatorComment === "" ||
                          !/[a-zA-Z0-9]/.test(divisionalCoordinatorComment) ||
                          divisionalCoordinatorComment === null ||
                          /\s{2,}/.test(divisionalCoordinatorComment)
                        ) &&
                        !(
                          (ideaAwardAmt < 100 || ideaAwardAmt > 25000) &&
                          ideaAwardAmt !== "" &&
                          ideaAwardAmt !== null
                        ))
                        ? false
                        : true
                    }
                    onClick={() => addSuggestion()}
                  />
                  }
                  
                </>) 
              :( */}
                <input
                  type="button"
                  value={"CLOSE"}
                  className={
                    'ml-2 validateBtn'
                  }
                  // disabled={
                  //     selectedAction=== null ? false : ((!checkBoxSelect ||
                  //     evaluatorComment === "" ||
                  //     evaluatorComment === null ||
                  //     !/[a-zA-Z0-9]/.test(evaluatorComment) ||
                  //     divisionDetails.division === null ||
                  //     divisionDetails.division_id === null ||
                  //     /\s{2,}/.test(evaluatorComment)) &&
                  //   suggestionStatus === "Pending at Evaluator" &&
                  //   (!checkBoxSelect ||
                  //     rejectionReason === "" ||
                  //     !/[a-zA-Z0-9]/.test(rejectionReason) ||
                  //     rejectionReason === null ||
                  //     /\s{2,}/.test(rejectionReason)) &&
                  //   (!checkBoxSelect ||
                  //     clarificationRemark === "" ||
                  //     !/[a-zA-Z0-9]/.test(clarificationRemark) ||
                  //     clarificationRemark === null ||
                  //     /\s{2,}/.test(clarificationRemark)) &&
                  //   (!checkBoxSelect ||
                  //     divisionalCoordinatorComment === "" ||
                  //     !/[a-zA-Z0-9]/.test(divisionalCoordinatorComment) ||
                  //     divisionalCoordinatorComment === null ||
                  //     /\s{2,}/.test(divisionalCoordinatorComment)))
                  // }
                  onClick={() => {
                    // suggestionStatus !== "Pending at Evaluator" || selectedAction === null
                      backAction() 
                      // : handleBtnAction();
                  }}
                />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    );
}

export default EvaluatorDetailsViewPopUp