import React, { Component } from 'react';
import '../ProfileScreen.css';
import { connect } from 'react-redux';
import edit from '../../../../../assets/drishti_images/profile/edit.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import * as profileActions from '../ProfileAction';
import { bindActionCreators } from 'redux';
import LargeDropdown from '../../../../shared/components/LargeDropdown';
import moment from 'moment';
import DeclarationPopup from '../../profile/DeclarationPopup';
import { currentDateYMD } from '../../../../../utils/Utils';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'

class MaritalStatus extends Component {
    constructor() {
        super();
        this.state = {
            isMeritalEdit: false,
            maritialStatusDropdown: [],
            loading: false,
            showDate: false,   //show date if maried   
            tempmaritialStatusText: '',
            tempmaritialStatusCode: '',
            tempdateOfMarriage: '',
            maritialStatusText: '',
            maritialStatusCode: '',
            marital_status_code: '',
            dateOfMarriage: '',
            index: '',
            popupVisibility: false,

        }
    }
    componentDidMount() {
        this.getMaritalStatusDropdown();
        this.getPersonalDetails(this.props.loginData.userData.Perno);
    }

    validateData = () => {
        if (this.state.maritialStatusCode.toString() == '') {
            toast.error('Please select marital status', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.maritialStatusCode == '1' && this.state.dateOfMarriage == '') {
            toast.error('Please select marriage date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else if (this.state.maritialStatusCode == '1' && this.state.dateOfMarriage > currentDateYMD()) {
            toast.error('Marriage date cannot exceed current date', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        } else {
            this.setState({
                popupVisibility: true
            })
        }
    }

    cancelDetails = () => {
        this.setState({ isMeritalEdit: false },
            this.getPersonalDetails(this.props.loginData.userData.Perno)
        )
    }

    getMaritalStatusDropdown() {
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.getMaritalStatus, {}, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    this.setState({
                        maritialStatusDropdown: res.data.marital_status
                    });
                    const maritalArray = this.state.maritialStatusDropdown.map((item, idx) => ({
                        ...item,
                        id: idx,
                        label: item.value
                    }))
                    this.setState({ maritialStatusDropdown: maritalArray })

                } else {
                    this.setState({
                        loading: false
                    });
                }

            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    getPersonalDetails(employeeId) {
        this.setState({
            loading: true
        })
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = { 'emp_id': employeeId }
        fetchApiCall(Config.getPersonalDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200 || res.data.status_code == 300) {
                    if (res.data.status_code == 300) {
                        // setTimeout(() => { Toast.show(res.data.message, Toast.SHORT) }, 100);
                    }
                    this.setState({
                        showDate: res.data.personal_details.marital_status == 'Married' ? true : false,
                        tempmaritialStatusText: res.data.personal_details.marital_status ? res.data.personal_details.marital_status : '',
                        tempmaritialStatusCode: res.data.personal_details.marital_status_code ? res.data.personal_details.marital_status_code : '',
                        tempdateOfMarriage: res.data.personal_details.marriage_date ? res.data.personal_details.marriage_date : '',

                        maritialStatusText: res.data.personal_details.marital_status ? res.data.personal_details.marital_status : '',
                        maritialStatusCode: res.data.personal_details.marital_status_code ? res.data.personal_details.marital_status_code : '',
                        dateOfMarriage: res.data.personal_details.marriage_date ? res.data.personal_details.marriage_date : '',
                        loading: false
                    });

                } else {
                    this.setState({
                        loading: false
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }

    savePersonalDetails = () => {
        this.setState({
            loading: true,
            popupVisibility: false
        })

        const body = {
            emp_id: this.props.loginData.userData.Perno,
            marital_status_code: this.state.maritialStatusCode.toString(),
            marriage_date: this.state.maritialStatusCode == '1' ? this.state.dateOfMarriage : '0000-00-00'
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(Config.updatePersonalDetails, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        isMeritalEdit: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.getPersonalDetails(this.props.loginData.userData.Perno);
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                },
                    toast.error(err, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    }));
            })
    }

    setMarriageDate = (fulldate) => {
        this.setState({
            dateOfMarriage: moment(fulldate).format("YYYY-MM-DD")
        });
    }
    ondropdownChanged = (data) => {
        this.setState({
            maritialStatusText: data.value,
            maritialStatusCode: data.id,
            showDate: data.value == 'Married' ? true : false,
            dateOfMarriage: data.value == 'Married' ? '' : this.state.dateOfMarriage
        })
    }

    render() {
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='row justify-content-between'>
                    <div className='p-heading mx-4 mt-3 mb-2'>Marital Status</div>
                    {!this.state.isMeritalEdit ?
                        <div className='mt-2 mr-3'>
                            <img
                                alt="edit"
                                src={edit}
                                className="plus-img"
                                onClick={() => this.setState({ isMeritalEdit: true })}
                            ></img>
                        </div> : null
                    }
                </div>
                <div>
                    <div className='row'>
                        <div className='col-2 mb-3'>
                            <div className='p-sub-heading ml-3'>{''}</div>
                            <div style={{ width: '8.688rem', marginTop: '18px', marginBottom: '5px' }}>
                                <LargeDropdown
                                    data={this.state.maritialStatusDropdown}
                                    render={'value'}
                                    selectedValue={(value) => this.ondropdownChanged(value)}
                                    value={this.state.maritialStatusText}
                                    disabled={!this.state.isMeritalEdit}
                                />
                            </div>
                        </div>
                        {this.state.showDate || this.state.maritialStatusText === 'Married' ?
                            <div className='col-3 mb-3'>
                                <div className='p-sub-heading ml-3'>Date of Marriage</div>
                                <input
                                    className="form-control p-placeholder px-3"
                                    type="date"
                                    name='DateofMarriage'
                                    value={this.state.dateOfMarriage}
                                    onChange={(e) => this.setMarriageDate(e.target.value)}
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                    {this.state.isMeritalEdit === true ?
                        <div className='row justify-content-end'>
                            <div className='justify-content-between'>
                                <div
                                    type='button'
                                    className='cancl-btn cancel-txt'
                                    onClick={() => this.cancelDetails()}
                                >Cancel</div>
                                <div
                                    type='button'
                                    className='save-btn save-txt'
                                    onClick={() => this.validateData()}
                                >Save</div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <div className='hz-lines mt-4 mb-4 mr-2 ml-2'></div>
                </div>
                {this.state.popupVisibility &&
                    <DeclarationPopup
                        onDismiss={() => this.setState({ popupVisibility: false })}
                        popupVisibility={this.state.popupVisibility}
                        saveDetails={this.savePersonalDetails}
                    />
                }
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        maritalData: state.profileState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        maritalStatusActions: bindActionCreators(profileActions, dispatch),

    };
}

export default connect(mapStatesToProps, mapDispatchToProps)(MaritalStatus);