import * as type from '../../../../redux/action/ActionType';

const initialState = {
    totalLeavedays: [],
}

export const leaveRequestData = (state= initialState, action) => {
    switch(action.type) {
        case type.GET_LEAVE_REQUEST_TOTAL_DAYS:
            let tempArray = state.totalLeavedays;
            tempArray.push(action.payload)
            return {
                ...state,
                totalLeavedays: tempArray,
            }
        case type.CLEAR_DATA:
            return {
                ...state,
                totalLeavedays: [],
            }    
        default:
            return state;
    }
}
