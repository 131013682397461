import React, { Component } from 'react'
import '../../employeeScreens/gems/HomeScreen.css'
import Dropdown from '../../../shared/components/Dropdown'
import '../../employeeScreens/leave/LeaveScreen.css';
import '../../../../assets/styles/popupStyles.css';
import { getUpdateStatusData } from '../../employeeScreens/gems/Gems.api.service';
import { toaster } from '../../../../utils/Utils';
import moment from 'moment';

const Note = 'Note: Are you sure you want to update the status of project'
const declarationInfo = "I am aware that once I start the project with the required number of applications, the project will not be republished to add any additional members."

class UpdateProjectStatusPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            deleteLeaveData: this.props.deleteLeaveData,
            updateResponse: [],
            reasonValue: 'Select',
            isUpdateStatus: true,
            check: false,
            isDateSelected: false,
            selectedStartDate: '',
            newMidTermDate: '',
            newFinalTermDate: '',
            StartProjectData: null
        }
    }

    componentDidMount() {
        this.getUpdateStatusCall(this.props.projectId, this.props.projectStatus)
    }

    getUpdateStatusCall = (id, status) => {
        console.log("getUpdateStatusCall----------------> CALL")
        this.setState({ loading: true })
        const params = {
            status: status
        }
        getUpdateStatusData(id, params)
            .then((res) => {
                // console.log("getUpdateStatusData res", res)
                this.setState({
                    loading: false
                })
                if (res) {
                    this.setState({
                        updateResponse: res.data.data ? res.data.data.map(tempValue => ({ status: tempValue.status, value: tempValue.status })) : []
                    })
                }
                else {
                    toaster("", res.message ? res.message : "")
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    updateResponse: []
                })
                toaster("warning", "Something went wrong")
                console.log("get project catch error:", err)
            })
    }

    changeResonsType = (data) => {
        if (data.status === "Ongoing") {
            this.setState({
                isUpdateStatus: false,
                reasonValue: data.status,
            })
        } else {
            this.setState({
                isUpdateStatus: true,
                reasonCode: data.status,
                reasonValue: data.status,
            })
        }
    }

    callCancelButtion() {
        this.setState({
            updateResponse: []
        },
            () => this.props.onDismiss()
        )
    }

    cancelButtion() {
        this.setState({
            isUpdateStatus: true,
            reasonValue: 'Select',
            StartProjectData: null,
            check: false,
            isDateSelected: false,
            selectedStartDate: '',
            newMidTermDate: '',
            newFinalTermDate: '',
        })
    }

    callSubmitButtion() {
        if (this.state.reasonValue == 'Select') {
            toaster("warning", "Please select project update status")
        }
        else {
            this.setState({
                // updateResponse: []
            },
                () => this.props.statusUpdate(this.state.reasonValue, this.state.StartProjectData)
            )
        }
    }

    declaration() {
        this.setState({
            check: this.state.check
        })
    }

    updateStartDate = (selectedDate) => {
        this.setState({
            isDateSelected: true
        })
        const duration = this.props.selectedProData.duration
        let finalProjectDate = new Date(new Date(selectedDate).getTime() + duration * 24 * 60 * 60 * 1000)
        const firstDate = new Date(selectedDate);
        const lastDate = finalProjectDate;
        const timeDiffInMillis = lastDate - firstDate;
        const midpointTime = firstDate.getTime() + timeDiffInMillis / 2;
        const mtrDate = new Date(midpointTime);
        this.setState({
            selectedStartDate: selectedDate,
            newMidTermDate: mtrDate,
            newFinalTermDate: finalProjectDate,
            StartProjectData: {
                "actual_start_date": moment(selectedDate).format('YYYY-MM-DD'),
                "actual_end_date": moment(finalProjectDate).format('YYYY-MM-DD'),
                "actual_mtr_date": moment(mtrDate).format('YYYY-MM-DD'),
                "actual_fr_date": moment(finalProjectDate).format('YYYY-MM-DD'),
            }
        })
    }

    render() {
        const projectData = this.props.selectedProData
        return (
            < div className="modal popup-box" >
                <div className="popupCard-box p-0 w-33">
                    {this.state.isUpdateStatus ?
                        <>
                            <div className="modal-header">
                                <h4 className="modal_title black">{"Update Project Status"}</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            </div>
                            <div className="modal-body h-37vh scroll-y">
                                <div className='justify-content-between p-3 h-80'>
                                    <span className='dropDown-title'>{"Project status:"} </span>
                                    <div className='ml-2 dropdown-text-gems'>
                                        <Dropdown
                                            data={this.state.updateResponse}
                                            selectedValue={(data) => this.changeResonsType(data)}
                                            value={this.state.reasonValue}
                                            render={'value'}
                                            arrow={'true'}
                                            height={'NotificationHeight'}
                                        />
                                    </div>
                                </div>
                                <span className='modal-note justify-content-evenly row p-2'>{Note}</span>
                            </div>
                            <div className='modal-footer btnBox1'>
                                <input type='button' onClick={() => this.callCancelButtion()} value='CANCEL' className='cancelBtn' />
                                <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.callSubmitButtion()} />
                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header">
                                <h4 className="modal_title black">{"Start Project"}</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                            </div>

                            <div className='p-10px margin-left-20'>
                                
                                    <div className='d-flex gap50px'>
                                        <div className='flex-direction-column'>
                                            <div className='d-flex flex-direction-row mt-1'>
                                                <span className='performance-timeline-text-date'>{"Project Start Date :"}</span>
                                                <span className='feedback-sm-text'>{moment(projectData?.planned_start_date).format('DD-MM-YYYY')}</span>
                                            </div>
                                            <div className='pb-10px'>
                                                <span className='performance-timeline-text-date'>{"Duration :"}</span>
                                                <span className='feedback-sm-text'>{projectData.duration}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex flex-direction-column mt-1'>
                                            <div className='performance-timeline-text-date required'>{"New Actual Start Date :"}</div>
                                            <div>
                                                <input
                                                    className="form-control popup-placeholder px-3"
                                                    type="date"
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    name='actual start date'
                                                    placeholder={'Enter'}
                                                    onChange={(planned_start_date) => this.updateStartDate(planned_start_date.target.value)}
                                                    value={(this.state.selectedStartDate)}
                                                    min={moment(projectData?.planned_start_date).format('YYYY-MM-DD')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex gap30px'>
                                        <div className='d-flex flex-direction-row mt-1'>
                                            <span className='performance-timeline-text-date'>{"Mid Term Review Date :"}</span>
                                            <span className='feedback-sm-text'>{moment(projectData?.planned_mtr_date).format('DD-MM-YYYY')}</span>
                                        </div>
                                        <div className='d-flex flex-direction-row mt-1'>
                                            <span className='performance-timeline-text-date'>{"New Mid Term Review Date :"}</span>
                                            <span className='feedback-sm-text'>{this.state.newMidTermDate !== "" ? moment(this.state.newMidTermDate).format('DD-MM-YYYY') : "  -"}</span>
                                        </div>
                                    </div>
                                    <div className='d-flex gap50px'>
                                        <div className='d-flex flex-direction-row mt-1'>
                                            <span className='performance-timeline-text-date'>{"Final Review Date :"}</span>
                                            <span className='feedback-sm-text'>{moment(projectData?.planned_fr_date).format('DD-MM-YYYY')}</span>
                                        </div>
                                        <div className='d-flex flex-direction-row mt-1'>
                                            <span className='performance-timeline-text-date'>{"New Final Review Date :"}</span>
                                            <span className='feedback-sm-text'>{this.state.newFinalTermDate !== "" ? moment(this.state.newFinalTermDate).format('DD-MM-YYYY') : "  -"}</span>
                                        </div>
                                    </div>
                                    <div className='line-horizontal mt-1rem'></div>
                            </div>
                            <div className='d-flex flex-direction-row align-items-center ml-2'>
                                <label className="checkBoxData pl-3">
                                    <input type="checkbox" className='hidecheckBox'
                                        checked={this.state.check}
                                        onChange={e => this.setState({ check: !this.state.check }, () => this.declaration(this.state.check))}
                                    />
                                    <span className="markk"></span>
                                </label>
                                <div className='mt-3'>
                                    <span className='performance-timeline-text-date'>{'Declaration:'}</span>
                                    <span className='feedback-sm-text'>{declarationInfo}</span>
                                </div>
                            </div>
                            <div className='modal-footer btnBox1'>
                                <input type='button' onClick={() => this.cancelButtion()} value='CANCEL' className='cancelBtn' />
                                {(this.state.isDateSelected && this.state.check) ?
                                    <>
                                        <input type='button' value='CONFIRM' className='ml-2 validateBtn' onClick={() => this.callSubmitButtion()} />
                                    </>
                                    :
                                    <>
                                        <input type='button' value='CONFIRM' className='ml-2 validateBtnDisabled' />
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div >
        )
    }
}

export default UpdateProjectStatusPopup