import React, { useState } from 'react'
import { billDetailsCol, expenseDetailsFields, tdStyle } from './constants'
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import moment from 'moment/moment'

const ExpenseDetailsPopUp = (props) => {
    const { visible, expense, billDetails, closeAction, action, setBDCPopup, paginatedData } = props
    const [bill, setBill] = useState('')
    const [isPreviewErr, setIsPreviewErr] = useState(false)
    const downloadBill = (url) => {
        commonDownloadFile(url)
    }
    return (
        <div>
            {action == 'bdcPopupDetails' ?
                <div>
                    <div className="modal popup-box">
                        <div className={'popupCard-box p-0 w-60'}>
                            <div className="modal-header">
                                <h4 className="modal_title black popup-head">{'BDC Details'}</h4>

                                <button type="button" className="close" data-dismiss="modal" onClick={() => setBDCPopup(false)}>&times;</button>

                            </div>
                            <div className={"modal-body min-h-48vh h-60vh scroll-y"}>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '> Claim No</div>
                                        <div className="text14_light mt-1 ">{paginatedData.claim_no ? paginatedData.claim_no : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Employee ID </div>
                                        <div className="text14_light mt-1 ">{paginatedData.emp_id ? paginatedData.emp_id : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '> GL/Employee ID </div>
                                        <div className="text14_light mt-1 ">{paginatedData.gl_emp_id ? paginatedData.gl_emp_id : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '>Claimant Employee Name</div>
                                        <div className="text14_light mt-1 ">{paginatedData.claimant_emp_name ? paginatedData.claimant_emp_name : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Travel Type </div>
                                        <div className="text14_light mt-1 ">{paginatedData.travel_type ? paginatedData.travel_type : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '>Start Date</div>
                                        <div className="text14_light mt-1 ">{paginatedData.claim_start_date ? moment(paginatedData.claim_start_date).format('DD-MM-YYYY') : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '> Claim To Date</div>
                                        <div className="text14_light mt-1 ">{paginatedData.claim_to_date ? moment(paginatedData.claim_to_date).format('DD-MM-YYYY') : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Claim From place </div>
                                        <div className="text14_light mt-1 ">{paginatedData.claim_from_place ? paginatedData.claim_from_place : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '> Claim To Place </div>
                                        <div className="text14_light mt-1 ">{paginatedData.claim_to_place ? paginatedData.claim_to_place : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '> Purpose</div>
                                        <div className="text14_light mt-1 ">{paginatedData.purpose ? paginatedData.purpose : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Cost Center </div>
                                        <div className="text14_light mt-1 ">{paginatedData.costcenter ? paginatedData.costcenter : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '> Amount </div>
                                        <div className="text14_light mt-1 ">{paginatedData.amount ? paginatedData.amount : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '> Order No </div>
                                        <div className="text14_light mt-1 ">{paginatedData.order_no ? paginatedData.order_no : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Unique No </div>
                                        <div className="text14_light mt-1 ">{paginatedData.unique_no ? paginatedData.unique_no : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '> Text (LOC/CLAIM ID/Emp ID/Destination City)  </div>
                                        <div className="text14_light mt-1 ">{paginatedData.text_1 ? paginatedData.text_1 : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="row mr-0 p-3">
                                    <div className="col-md">
                                        <div className='text14_bold '> Payment Term</div>
                                        <div className="text14_light mt-1 ">{paginatedData.payment_term ? paginatedData.payment_term : '-'}</div>
                                        <hr />
                                    </div>

                                    <div className="col-md">
                                        <div className='text14_bold '> Report Download Date </div>
                                        <div className="text14_light mt-1 ">{paginatedData.report_download_date ? paginatedData.report_download_date : '-'}</div>
                                        <hr />
                                    </div>
                                    <div className="col-md">
                                        <div className='text14_bold '> Posting Code </div>
                                        <div className="text14_light mt-1 ">{paginatedData.posting_code ? paginatedData.posting_code : '-'}</div>
                                        <hr />
                                    </div>
                                </div>
                            </div>

                            <div className='modal-footer btnBox1'>
                                <input type='button'
                                    onClick={() => setBDCPopup(false)}
                                    value='CLOSE' className='cancelBtn' />
                            </div>

                        </div>
                    </div>
                </div>
                :
                <div>
                    {visible && (
                        <div className="modal popup-box">
                            <div className={'popupCard-box p-0 w-60'}>
                                {/* ------------------------------------------------------------- Header -------------------------------------------------------------- */}
                                <div className="modal-header">
                                    {expense != '' && bill == '' && <h4 className="modal_title black popup-head">{'Claim Expense Data'}</h4>}
                                    {bill != "" &&
                                        <span className='d-flex popup-head'>
                                            <div
                                                onClick={() => { setBill("") }}
                                            >
                                                <img alt="view" src={backArrow} style={{ width: '10px', cursor: 'pointer ' }} />
                                            </div>
                                            <h4 className="modal_title black popup-title">{bill.name}</h4>
                                        </span>
                                    }
                                    <button type="button" className="close" data-dismiss="modal" onClick={() => {
                                        setIsPreviewErr(false)
                                        setBill('')
                                        closeAction()
                                    }}>&times;</button>
                                </div>
                                <div className={"modal-body min-h-48vh h-60vh scroll-y"}>

                                    {/* ------------------------------------------------------------- Expense Detail -------------------------------------------------------------- */}

                                    {
                                        expense != '' && bill == '' &&
                                        <div className='detail-div mt-12px'>
                                            <div className='edit-cardtitle detail-head '>Expense Details</div>
                                            <div className='details pb-10px'>
                                                {
                                                    expenseDetailsFields.map((field) => {
                                                        return (
                                                            <div className='detail-field'>
                                                                <div className='details-lbl'>{field.label} :</div>
                                                                <div className='field-value'>{
                                                                    field.type == 'date' ? moment(expense[field.key]).format('DD-MM-YYYY')
                                                                        : (expense['expense_type'] == 'Accommodation allowance' || expense['expense_type'] == 'Daily allowance' || expense['sub_expense_type1'] == 'Own vehicle') && field.key == 'permissible_expense_amount' ? expense['permissible_expense_amount'] != null ? expense['permissible_expense_amount'] : 'NA'
                                                                            : expense[field.key] && field.key != 'permissible_expense_amount' ? expense[field.key] : 'NA'
                                                                }</div>

                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className='edit-cardtitle detail-head '>Attachments</div>
                                            <div className='table-container px-10px mt-10px '>
                                                {
                                                    billDetails.length > 0 ?
                                                        <div className="main-contain">
                                                            <div className="row align-item-center justify-content-start ">
                                                                <table className="table gemsAdmin">
                                                                    <thead className='w-100'>
                                                                        <tr className='w-100'>
                                                                            {billDetailsCol.map((col) => {
                                                                                return (
                                                                                    <th width={col.width}><div className='thead'>{col.title}</div></th>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='w-100'>
                                                                        {
                                                                            billDetails.map((row, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                                                        <td width='5%' ><div className={tdStyle}>{row.attachment_name}</div></td>
                                                                                        <td width='5%'>
                                                                                            <span className='d-flex'>
                                                                                                <div
                                                                                                    onClick={() => { downloadBill(row.attachment_url) }}
                                                                                                >
                                                                                                    <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                                </div>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td width='5%'>
                                                                                            <span className='d-flex'>
                                                                                                <div
                                                                                                    onClick={() => { setBill({ url: row.attachment_url, type: row.attachment_type, name: row.attachment_name }) }}
                                                                                                >
                                                                                                    <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                                </div>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='mt-5 h-90-percent'>
                                                            <DataNotFound />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {/* ------------------------------------------------------------- Attachments -------------------------------------------------------------- */}

                                    {
                                        bill != '' && <div className='detail-div mt-12px'>
                                            {
                                                <>
                                                    {(bill.type === 'jpg' || bill.type === 'png' || bill.type === "jpeg") &&
                                                        <img src={bill.url} alt="No Preview available" className="upload_bills_modal_body" />
                                                    }
                                                    {bill.type === 'pdf' &&
                                                        <iframe className='upload_bills_modal_body' src={bill.url}></iframe>
                                                    }
                                                </>
                                            }
                                        </div>
                                    }
                                </div>
                                <div className='modal-footer btnBox1'>
                                    <input type='button' onClick={() => {
                                        setIsPreviewErr(false)
                                        setBill('')
                                        closeAction()
                                    }} value='CLOSE' className='cancelBtn' />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}
export default ExpenseDetailsPopUp