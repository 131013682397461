import React, { Component } from 'react'
import rightArrow from '../../../../assets/drishti_images/right-arrow-bg.svg';
import { gemsApplicant, constTypes } from './Constant'
import NewNotdata from '../../../shared/notFound/NewNoData';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import radioOn from '../../../../assets/images/applyLeave/radioOn.png';
import radioOff from '../../../../assets/images/applyLeave/radioOff.png';
import MyProject from './MyProject'
import { toast } from 'react-toastify';
import { configStore } from "../../../../../src/redux/ConfigStore"
import { getArea, getProjectStatus } from '../../../ScreenType/employeeScreens/gems/Gems.api.service';
import ProjectDetails from './ProjectDetails';
import moment from 'moment';
import searchIcon from '../../../../assets/drishti_images/admin/searchIcon.svg';

const status = [
    { id: 1, title: 'All Projects' },
    { id: 2, title: 'My Projects' }
]
class GEMSApplicant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            projectDetails: [],
            tempProjectDetails: [],
            isSelected: 0,
            selectedProjects: [],
            isFormClosed: true,
            appliedProjects: [],
            statusColor: [],
            selectedStatusText: "All",
            projectStatus: 1,
            checked: 0,
            FileName: '',
            FileContent: '',
            selectedID: '',
            totalPages: '',
            pageNumber: 1,
            type: '',
            selectedStatus: null,
            titleText: ''
        }
    }

    componentDidMount() {
        this.getDiffArea()
        this.getDiffProject()
        this.setState({
            tempProjectDetails: this.state.tempProjectDetails ? this.state.tempProjectDetails : []
        })
    }

    getDiffArea() {
        this.setState({ loading: true })
        getArea()
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        statusColor: res.data.data ? res.data.data : []
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        statusColor: []
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                    statusColor: []
                })
                console.log("getDiffArea catch error:", err);
            });
    }

    getDiffProject(id) {
        const param = {
            area: id ? id : '',
            status: 'Published',
            page_number: this.state.pageNumber,
            title: this.state.titleText,
        }
        this.setState({
            loading: true,
            tempProjectDetails: this.state.pageNumber == 1 ? [] : this.state.tempProjectDetails,
        })
        getProjectStatus(param, this.props)
            .then((res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        totalPages: res.data.data.total_pages,
                        pageNumber: res?.data?.data?.page_number,
                        tempProjectDetails: this.state.tempProjectDetails.concat(res?.data?.data?.paginated_data),
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        tempProjectDetails: [],
                        pageNumber: 1,
                    })
                }
            })
            .catch((error) => {
                this.setState({
                    loading: false,
                    tempProjectDetails: [],
                    pageNumber: 1,
                })
                console.log("getDiffProject catch error:", error)
            });
    }

    handlePagination = () => {
        const { pageNumber } = this.state;
        this.setState({
            pageNumber: pageNumber + 1
        }, () => this.getDiffProject())
    }

    selectProjectTab = (id, data, type) => {
        this.setState({
            isSelected: id,
            selectedProjects: [data],
            type: type
        })
    }

    onChangedStatus = (key) => {
        this.setState({
            checked: key,
            titleText: '',
        })
        this.getDiffProject()
    }

    updateData() {
        const param = {
            applicant_perno: configStore.getState().loginState?.userData?.Perno,
            title: this.state.titleText,
        }
        this.setState({ loading: true })
        getProjectStatus(param, this.props)
            .then((res) => {
                if (res.status == 200 || res.status == 201) {
                    this.setState({
                        loading: false,
                        appliedProjects: res.data.data ? res.data.data : []
                    })
                } else {
                    this.setState({
                        loading: true,
                        appliedProjects: res.data.data ? res.data.data : []
                    });
                }
            }).catch((error) => {
                this.setState({ loading: false, appliedProjects: [] })
                toast.error("Unable to contact server\n\tPlease retry after sometime", {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
                console.log("updateData catch error:", error)
            });
    }

    projectFilter = (statusId) => {
        const selectedProjects = [this.state.projectDetails.filter(list => list.projectType == this.state.selectedStatusText || this.state.selectedStatusText === "All")[0]]
        const tempData = this.state.projectDetails.filter(list => list.projectType == this.state.selectedStatusText || this.state.selectedStatusText === "All")
        this.setState({
            selectedProjects: this.state.projectDetails.length !== 0 && selectedProjects[0] !== undefined ? selectedProjects : [],
            tempProjectDetails: tempData,
            isSelected: 0
        }, () => this.getDiffProject(statusId))
    }

    isSelectedStatus(status) {
        return this.state.selectedStatus === status.id;
    }

    searchProjectFilter = () => {
        this.setState({
            titleText: this.state.titleText,
            tempProjectDetails: [],
            pageNumber: 1,
        }, () => this.getDiffProject())
        if (this.state.checked == 1) {
            this.setState({
                titleText: this.state.titleText,
            }, () => this.updateData())
        }
    }

    render() {
        const { tempProjectDetails, pageNumber, totalPages } = this.state;
        return (
            <div className='h-90-percent'>
                <LoaderIndicator loading={this.state.loading} />
                <div className='flex-direction-row align-item-center-noflex justify-content-between mx-30px mt-10px mb-15px'>
                    <div className='flex-direction-row align-item-center-noflex justify-content-between'>
                        <span className='performance-timeline-text-circle'>{constTypes.status}</span>
                        {status.length != 0 && status.map((list, key) => {
                            return (
                                <span key={key} className='grid-status align-item-center-noflex pl-15 ml-10 px-5px'>
                                    {this.state.checked === key ?
                                        <span onClick={() => this.onChangedStatus(key)}>
                                            <img alt="radioOn" className='radio' src={radioOn} />
                                            <label className="radioTxt ml-15px" >{list.title}</label>
                                        </span>
                                        :
                                        <span onClick={() => this.onChangedStatus(key)}>
                                            <img alt="radioOff" className='radio' src={radioOff} />
                                            <label className="radioTxt ml-15px">{list.title}</label>
                                        </span>
                                    }
                                </span>
                            )
                        })
                        }
                    </div>
                    <div className='flex-direction-row f-gap-1rem'>
                        <div className='row ml-10'>
                            <div className='p-placeholder-text'>
                                <input
                                    className="form-control p-placeholder px-2 p-placeholder-admin"
                                    type="text"
                                    placeholder='Search Project'
                                    onChange={(e) => this.setState({ titleText: e.target.value })}
                                    value={this.state.titleText}
                                />
                            </div>
                            <div type='button' className='serchIconSize-gems' onClick={() => this.searchProjectFilter()}>
                                <img alt="radioOn" className='filterIcon' src={searchIcon} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* -----------------All project first filter----------------------*/}
                {this.state.checked === 0 ?
                    <>
                        {/* -----------------All status colours code------------------ */}
                        <div className='flex-direction-row status-background-styles justify-content-around overflow-x-auto active-status'>
                            <span className='flex-direction-row' >
                                <span className='flex-direction-row align-item-center-noflex pl-15 pointer'
                                    style={{
                                        borderBottom: `red`,
                                    }}
                                    onClick={() => this.setState({ selectedStatusText: 'All', tempProjectDetails: [], selectedStatus: [], colorStatusData: [], pageNumber: 1 },
                                        () => this.getDiffProject())}>
                                    <span className="status-color-circle dflex m-5px"
                                        style={{ backgroundColor: "#E3B203" }}></span>
                                    <span className='sm-text'>{"All"}</span>
                                    <span className='mt-0px mb-0px ml-10px'></span>
                                </span>
                                <span className='seperator'></span>
                            </span>

                            {this.state.statusColor.map((colorStatusData, id) =>
                                <span className='flex-direction-row' key={id}>
                                    <span className={`flex-direction-row align-item-center-noflex pl-15 pointer ${this.isSelectedStatus(colorStatusData) ? 'active-status mr-10px' : ""} `}
                                        style={{
                                            borderBottom: `${this.isSelectedStatus(colorStatusData) ? `2px solid ${colorStatusData.color_code}` : ""} `,
                                            backgroundColor: `${this.isSelectedStatus(colorStatusData) ? `${colorStatusData}` : ""}`
                                        }}
                                        onClick={() => this.setState({ selectedStatus: colorStatusData.id, selectedStatusText: colorStatusData.statustext, pageNumber: 1 },
                                            () => this.projectFilter(colorStatusData.id))}
                                    >
                                        <span className="status-color-circle dflex m-5px" style={{ backgroundColor: `${colorStatusData.color_code}` }}></span>
                                        <span className='sm-text'>{colorStatusData.area}</span>
                                        <span className='mt-0px mb-0px ml-10px'></span>
                                    </span>
                                    <span className='seperator'></span>
                                </span>
                            )}
                        </div>

                        <div className='flex-direction-row applicant-main px-5px'>
                            {/* -----------------Left side all project list panel ------------------------------*/}
                            <div className='scroll-auto m-5px p-5px mt-3 h-57vh card-inner'>
                                {tempProjectDetails.length !== 0 ?
                                    <>
                                        {tempProjectDetails.map((dataValue, id) =>
                                            <div className={`card-inner flex-direction-row pointer card-style mb-10px justify-content-between ${this.state.isSelected == id ? "selected-tab" : ""}`}
                                                onClick={() => this.selectProjectTab(id, dataValue, 'click')}
                                                key={id}
                                            >
                                                <span className='align-item-center'>
                                                    <span className='side-vertical-status-bar'
                                                        style={{ borderLeft: `6px solid ${dataValue.area.color_code}` }}
                                                    ></span>
                                                    <div className='p-lr'>
                                                        <span>
                                                            <span className='flex-direction-row p-5px document-innercard-content-title'>{dataValue.title}</span>
                                                            <span className='project-short-view px-5px'>
                                                                <span>
                                                                    <span className='appcenter-app-name'>{gemsApplicant.startD}</span><span className='feedback-sm-text'> {moment(dataValue?.actual_start_date).format("DD-MM-YYYY") ? moment(dataValue?.planned_start_date).format("DD-MM-YYYY") : '-'}</span>
                                                                </span>
                                                                <span>
                                                                    <span className='appcenter-app-name'>{gemsApplicant.endD}</span><span className='feedback-sm-text'> {moment(dataValue?.actual_end_date).format("DD-MM-YYYY") ? moment(dataValue?.planned_end_date).format("DD-MM-YYYY") : '-'}</span>
                                                                </span>
                                                                <span>
                                                                    <span className='appcenter-app-name'>{gemsApplicant.noOfOpening}</span><span className='feedback-sm-text'> {dataValue?.no_of_openings ?? ''}</span>
                                                                </span>
                                                                <span>
                                                                    <span className='appcenter-app-name'>{gemsApplicant.projectLoc}</span><span className='feedback-sm-text'> {dataValue?.location ?? ''}</span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </div>
                                                </span>
                                                <div className='arrow-hide align-item-center mr-1'>
                                                    <img
                                                        alt="right arrow"
                                                        src={rightArrow}
                                                        className="right-arrow"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/* ----------Pagination condition----------------- */}
                                        {pageNumber < totalPages && totalPages > 1 &&
                                            <span className='blue-button pointer dflex align-item-center-noflex justify-content-center-noflex m-10px'>
                                                <span className='card-content-title more-style m-10px' onClick={() => this.handlePagination()}>{constTypes.more}</span>
                                            </span>
                                        }
                                    </>
                                    :
                                    <span className='justify-content-center align-item-center-noflex h-93-percent'>
                                        <NewNotdata />
                                    </span>
                                }
                            </div>
                            {/* ----------------- Right specific project details panel ------------------------------*/}
                            {tempProjectDetails.length !== 0 ?
                                <>
                                    <ProjectDetails
                                        loading={this.state.loading}
                                        isSelected={this.state.isSelected}
                                        type={this.state.type}
                                        tempProjectDetails={this.state.tempProjectDetails}
                                        selectedProjects={this.state.selectedProjects}
                                        getDiffProject={() => this.getDiffProject()}
                                        onChangedStatus={() => this.setState({ checked: 1 }, () => this.updateData())}
                                    />
                                </>
                                :
                                <span className='justify-content-center align-item-center-noflex h-93-percent'>
                                    <NewNotdata />
                                </span>
                            }
                        </div>
                    </>
                    :
                    <>
                        {/* ----------------- My project second filter----------------------*/}
                        <MyProject
                            loading={this.state.loading}
                            appliedProjects={this.state.appliedProjects}
                            updateData={() => this.updateData()}
                        />
                    </>
                }
            </div >
        )
    }
}

export default (GEMSApplicant)