import React, { useState, useEffect } from 'react';
import { assetTableCols, clearancePopupDetails, commentField, constantVals, contactField } from '../../../adminScreens/clearance/constants';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { DDMMYYYY,DDMMMYY, toaster } from '../../../../../utils/Utils';
import { getAgencyWiseClearanceEmployee, postClearanceReminderEmployee, getEmployeeAssetsDetail } from '../../../../../constants/api.service';
import moment from 'moment';
import ConfirmationPopUp from '../../../adminScreens/clearance/ConfirmationPopUp';
import DataNotFound from '../../../../shared/notFound/DataNotFound';
import ClearanceTable from '../../../adminScreens/clearance/ClearanceTable';
import ClearancePreviewComponent from './ClearancePreviewComponent';

function CleEmpDetailsPopup(props) {
    //-------------------------All States------------------------------------
    const { onDismiss, title, selectedEmployee } = props;
    const [loading, setLoading] = useState(false)
    const [clearanceDetails, setClearanceDetails] = useState(null)
    const [openConfirmPopUp, setOpenConfirmPopUp] = useState(false)
    const [employeeAssetsDetails, setEmployeeAssetsdetails] = useState([]);
    const { clearance_id, agency_config } = selectedEmployee
    //---------------------------All Api's and functions---------------------------

    const getClearanceDetails = () => {
        setLoading(true)
        let params = { "agency_config_id": agency_config }
        getAgencyWiseClearanceEmployee(props, clearance_id, params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    setClearanceDetails(res.data.data)
                    title == "IT Asset Clearance" && getEmployeeDetails(res.data.data);
                }
                else {
                    toaster("warning", res.data.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log("error in Get Clearance Agency Details", error);
                toaster("warning", error?.data?.message)
                setLoading(false)
            })
            .finally(() => { title != "IT Asset Clearance" && setLoading(false) })
    }
    //API for get Assests of Employee 
    const getEmployeeDetails = (data) => {
        setLoading(true)
        let params = {
            // "emp_id": '00509862',
            "emp_id": data.employee_details.Perno

        }
        getEmployeeAssetsDetail(props, params)
            .then((res) => {
                if ((res.status === 200 || res.status === 201) && res?.data?.data != "No Record Found !!") {
                    setEmployeeAssetsdetails(res.data.data)
                }
                else {
                    setEmployeeAssetsdetails([])
                    // toaster("warning", res?.data?.message ?? someThingWentWrong)
                }
            })
            .catch((error) => {
                console.log("error in Get Clearance Agency Details", error);
                toaster("warning", error?.data?.message)
            })
            .finally(() => setLoading(false))
    }
    const nudgeForClearance = () => {
        setLoading(true)
        let params = { "agency_config_id": agency_config, "clearance_id": clearance_id }
        postClearanceReminderEmployee(props, params)
            .then((res) => {
                if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                else { toaster("warning", res.data.message) }
            })
            .catch((error) => {
                console.log("error in Get Clearance Agency Details", error);
                toaster("warning", error?.data?.message)
            })
            .finally(() => { setLoading(false); setOpenConfirmPopUp(false); onDismiss() })
    }
    useEffect(() => {
        getClearanceDetails()
    }, [])

    return (
        <div className="modal popup-box">
            <LoaderIndicator loading={loading} />
            {openConfirmPopUp &&
                <ConfirmationPopUp
                    loading={loading}
                    title={"Nudge For Clearance"}
                    message={"Are you sure that you want to nudge for clearance? \nYou can use this feature once per day."}
                    confirmBtnLbl={"SEND"}
                    onConfirmAction={() => { nudgeForClearance() }}
                    onDismiss={() => { setOpenConfirmPopUp(false) }}
                />
            }
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable w-60rem">
                <div className="modal-content bg-white border_radius_12px max-height-80 w-60vw">
                    <div className="modal-header mx-2">
                        <div className='row'>
                            <h4 className="popup-title mt-2">{title}</h4>
                        </div>
                        <button type="button" className="close pt-4" data-dismiss="modal" onClick={() => { onDismiss() }}>&times;</button>
                        <div className='solid-border'></div>
                    </div>

                    <div className='column mt-3 h-50vh scroll-y'>
                        <div>
                            <div className='toggle-card-heading ml-10 mb-10'>{constantVals.EmployeeDetails}</div>
                            <div className='d-flex flex-wrap'>
                                {clearancePopupDetails.map((listValue, index) => (
                                    <div key={index} className="col-4">
                                        <div className="text14_bold">{listValue.label}</div>
                                        <div className="text14_light mt-1">{clearanceDetails?.["employee_details"]?.[listValue.key] ? clearanceDetails["employee_details"][listValue.key] : '--'}</div>
                                        <div className='mt-1 mb-3'><hr /></div>
                                    </div>
                                ))}
                                <div className="col-4">
                                    <div className="text14_bold">{"Agency Clearance Completion Date"}</div>
                                    <div className="text14_light mt-1">{DDMMMYY(clearanceDetails?.["request"]?.[0]?.["release_date"]) ?? '--'}</div>
                                    <div className='mt-1 mb-3'><hr /></div>
                                </div>
                                <div className="col-4">
                                    <div className="text14_bold">{"Updated By"}</div>
                                    <div className="text14_light mt-1">
                                        {clearanceDetails?.["request"]?.[0]?.["released_by_name"] ? (clearanceDetails?.["request"]?.[0]?.["released_by_name"] + ` (${clearanceDetails?.["request"]?.[0]?.["released_by"] ?? "--"})`) : '--'}
                                    </div>
                                    <div className='mt-1 mb-3'><hr /></div>
                                </div>
                            </div>

                            <div><hr /></div>
                            <div className='toggle-card-heading mt-10px ml-10 mb-10'>{constantVals.AgencyContactDetails}</div>
                            <div className='d-flex flex-column'>
                                {clearanceDetails?.["request"]?.[0]?.["contact"].length > 0 ? clearanceDetails["request"][0]["contact"].map((contact, index) => {
                                    return (
                                        <div className='row justify-content-between mb-3' key={index}>
                                            <div className="col-4">
                                                <div className="text14_bold">{"SPOC " + (index + 1) + " Name"} :</div>
                                                <div className="text14_light mt-1"> {contact["agent"]?.["first_name"] + " " + contact["agent"]?.["last_name"] ?? "--"}</div>
                                                <div className='mt-1'><hr /></div>
                                            </div>
                                            {contactField.map((field, fieldIndex) => (
                                                <div className="col-4" key={fieldIndex}>
                                                    <div className="text14_bold">{field.label.slice(0, 5) + ` ${index + 1} ` + field.label.slice(5)} :</div>
                                                    <div className="text14_light mt-1"> {contact["agent"]?.[field.key] ?? "--"}</div>
                                                    <div className='mt-1'><hr /></div>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                }) : (
                                    <div className='h-90-percent w-100'>
                                        <DataNotFound />
                                    </div>
                                )}
                            </div>

                            <div><hr /></div>
                            <div className='toggle-card-heading mt-10px ml-10 mb-10'>{constantVals.timeline}</div>
                            <div className='d-flex row'>
                                <div className="col flex-basis-50">
                                    <div className="row">
                                        <div className="">
                                            <div className="text14_bold">{constantVals.PayAmount}:</div>
                                            <div className="text14_light mt-1"> {clearanceDetails?.request?.[0]?.pay_amount ?? "--"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col flex-basis-50">
                                    <div className="row">
                                        <div className="">
                                            <div className="text14_bold">{constantVals.RecAmount}:</div>
                                            <div className="text14_light mt-1"> {clearanceDetails?.request?.[0]?.recovery_amount ?? "--"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex row'>
                                <div className="col flex-basis-50">
                                    <div className="row">
                                        <div className="">
                                            <div className="text14_bold">Remarks:</div>
                                            <div className="text14_light mt-1"> {clearanceDetails?.request?.[0]?.remark ?? "--"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div><hr /></div>
                            {clearanceDetails?.request?.[0]?.comment.length > 0 &&
                                <>
                                    <div className='mb-10'>
                                        <div className='toggle-card-heading mt-10px ml-10 mb-10'>{constantVals.Comments}</div>
                                        {clearanceDetails?.request?.[0]?.comment.map((item, index) => {
                                            return (
                                                <div className='d-flex row'>
                                                    <div className='mt-2 col flex-basis-50'>
                                                        <div className="row">
                                                            <div className="">
                                                                <div className="text14_bold">Additional Remarks {index + 1}</div>
                                                                <div className="text14_light mt-1">{item.remark ?? "--"}</div>
                                                            </div></div>
                                                    </div>
                                                    <div className='mt-2 col flex-basis-50'>
                                                        <div className="row">
                                                            <div className="">
                                                                <div className="text14_bold">Created by </div>
                                                                <div className="text14_light mt-1">{item.created_by_name + ` ( ${item.created_by} )` ?? "--"}</div>
                                                            </div></div>
                                                    </div>
                                                    <div className='mt-2 col flex-basis-50'>
                                                        <div className="row">
                                                            <div className="">
                                                                <div className="text14_bold">Created on</div>
                                                                <div className="text14_light mt-1">{moment(item.created_datetime).format("DD MMM YY HH:mm") ?? "--"}</div>
                                                            </div></div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                    <div><hr /></div>
                                </>
                            }
                            {
                                title == "IT Asset Clearance" &&
                                <>
                                    {employeeAssetsDetails.length > 0 ?
                                        <div>
                                            <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.AssetsDetails}</div>
                                            <ClearanceTable
                                                data={employeeAssetsDetails}
                                                columns={assetTableCols}
                                                hasAction={false}
                                            />
                                            <div><hr /></div>
                                        </div>
                                        :
                                        <div>
                                            <div className='toggle-card-heading ml-3 mt-10px mb-10'>{constantVals.AssetsDetails}</div>
                                            <DataNotFound />
                                            <div><hr /></div>
                                        </div>
                                    }
                                </>
                            }
                            {
                                clearanceDetails?.presigned_urls && clearanceDetails?.presigned_urls.length > 0 &&
                                <>
                                    <ClearancePreviewComponent data={clearanceDetails.presigned_urls} />
                                    <div><hr /></div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="popup-button-background justify-content-end align-item-center pr-3">
                        <input type='button' value={'CLOSE'} className='cancelBtn' onClick={onDismiss} />
                        {clearanceDetails?.["request"]?.[0]?.is_nudge_visible ? <input type='button' value={'NUDGE FOR CLEARANCE'} className={`ml-2 ${!clearanceDetails?.request?.[0]?.is_nudge_applicable ? "disableButtonStyle" : "validateBtn"}`} onClick={() => { setOpenConfirmPopUp(true) }} disabled={!clearanceDetails?.request?.[0]?.is_nudge_applicable} />
                            : <></>} </div>
                </div>
            </div>
        </div>
    )
}

export default CleEmpDetailsPopup