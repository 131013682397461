import React, { Component } from 'react'
import rightArrowWithBG from '../../../../assets/drishti_images/rightArrowWithBG.svg'

class GoToPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enteredPage: this.props.currentPage
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentPage !== prevProps.currentPage) {
            this.setState({ enteredPage: this.props.currentPage })
        }
    }

    customInputChange = (e) => {
        if (((this.state.enteredPage != 0 || this.state.enteredPage <= this.props.totalPage) && this.state.enteredPage != this.props.currentPage)) {
            this.callOnchangeProp(e)
        }
        else {
            return false
        }
    }

    callOnchangeProp = (e) => {
        let val = parseInt(this.state.enteredPage)
        if ((e.key === 'Enter' && val <= this.props.totalPage && val > 0) || (val <= this.props.totalPage && val > 0)) {
            this.props.onChange(this.state.enteredPage)
        }
        else {
            return false
        }
    }

    render() {
        const { enteredPage } = this.state
        return (
            <div className='goToPage'>
                <span>Go to Page</span>
                <input
                    type="number"
                    readOnly={false}
                    onChange={(e) => this.setState({ enteredPage: e.target.value ? e.target.value : '' })}
                    value={enteredPage}
                // onKeyDown={this.customInputChange}
                ></input>
                <div type='button'
                    onClick={(e) => this.customInputChange(e)}
                >
                    <img alt="Back" src={rightArrowWithBG} className="go-to-Button"></img>
                </div>
            </div>
        )
    }
}
export default GoToPage