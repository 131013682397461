import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import moment from 'moment';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import { notification_typeWith_All } from '../../../../../utils/Utils';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

class LeadershipAnnouncementDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showConfirm: false,
            showDelete: false,
            notification_type: notification_typeWith_All(),
            tempAnnouncementData: [],
            selectedAnnouncementId: '',
        }
    }

    deleteAnnouncement = () => {
        this.setState({
            loading: true,
            showDelete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            is_active: false,
            announcement_id: this.state.selectedAnnouncementId
        }
        fetchApiCall(Config.deleteLeadershipAnnouncement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.props.getLeadershipAnnouncementCall()
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    onAction = (listData, type) => {
        if (type === 'view') {
            this.setState({
                viewPopup: true,
                tempAnnouncementData: listData
            })
        }
        else {
            this.setState({
                showDelete: true,
                selectedAnnouncementId: listData.announcement_id,
            })
        }
    }

    render() {
        const { viewPopup, showDelete } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className=" pt-2 pushNotification-container" >
                    {this.props.announcementData1.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" style={{ 'width': '5%' }}><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col" style={{ 'width': '13%' }}><div className='thead'>Attachment</div></th>
                                    <th scope="col" style={{ 'width': '30%' }}><div className='thead'>Description</div></th>
                                    <th scope="col" style={{ 'width': '10%' }}><div className='thead ml-4'>Created Date</div></th>
                                    <th scope="col" style={{ 'width': '2%' }}><div className='thead' style={{ 'marginLeft': '15%' }}>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.announcementData1.map((listValue, index) => {
                                    return (
                                        <tr key={index} className=''>
                                            <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                            <td><div className="td">{listValue.announcement_image ? listValue.announcement_image : ''}</div></td>
                                            <td><div className="td">{listValue.announcement_description ? listValue.announcement_description : ''}</div></td>
                                            <td><div className="td ml-4" >{moment(listValue.created_at).format("DD-MM-YYYY")}</div></td>
                                            <td className='d-flex'>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'view')}
                                                >
                                                    <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                </div>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'delete')}
                                                >
                                                    <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No annoucements have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {viewPopup &&
                    <NotificationDetailsPopup
                        type={"leadershipAnnouncementDetails"}
                        title={"Leadership Announcment Details"}
                        data={this.state.tempAnnouncementData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteAnnouncement'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Announcement?'}
                        deleteAnnouncement={() => this.deleteAnnouncement()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(LeadershipAnnouncementDashboard);