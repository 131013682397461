import React, { useEffect, useState } from 'react'
import './LocalConveyanceAdmin.css'
import ViewIcon from '../../../../assets/drishti_images/view.svg'
import backArrow from '../../../../assets/drishti_images/backArrow.svg'
import downloadLatestImg from '../../../../assets/drishti_images/downloadLatestImg.svg'
import DataNotFound from '../../../shared/notFound/DataNotFound'
import { adminDetailsFields, billDetailsCol, claimDetailsFormClaim, employeeDetailsFields, expenseDetailsFields, expenseTableColsSummary, tdStyle } from './constants'
import { getClaimAttachmentsAdmin, getComponentDetailsAdmin } from '../../../../constants/api.service'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { commonDownloadFile } from '../../../shared/CommonDownloadFunction'
import moment from 'moment'

const SummaryPopUp = (props) => {
    const { closeAction, selectedClaim } = props
    const [expense, setExpense] = useState("")
    const [bill, setBill] = useState("")
    const [loading, setLoading] = useState(false)
    const [expenseDetails, setExpenseDetails] = useState([])
    const [billDetails, setBillDetails] = useState([])
    const [isPreviewErr, setIsPreviewErr] = useState(false)

    useEffect(() => {
        fetchClaimComponents()
    }, [])

    const downloadBill = (url) => {
        commonDownloadFile(url)
    }

    const fetchClaimComponents = () => {
        setLoading(true)
        let params = {
            claim_id: selectedClaim.claim_id,
            emp_id: selectedClaim.emp.Perno
        }
        getComponentDetailsAdmin(params, props).then(
            (res) => {
                console.log("%c Component details", "color: green; font-weight: bold; font-size:16px", res)
                if (res.status == 200) {
                    setExpenseDetails(res?.data?.data)
                }
                else if (res.status == 404) {
                    setExpenseDetails([])
                }
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
            console.log("%c Error in api call", "color: yellow; font-weight: bold; font-size:16px", err)
        })
    }
    const fetchAttachments = (id) => {
        setLoading(true)
        let params = {
            component_id: id,
            emp_id: selectedClaim.emp.Perno
        }
        getClaimAttachmentsAdmin(params, props).then(
            (res) => {
                console.log("%c Attachments details", "color: green; font-weight: bold; font-size:16px", res)
                if (res.status == 200) {
                    setBillDetails(res?.data?.data)
                }
                else if (res.status == 404) {
                    setBillDetails([])
                }
                setLoading(false)
            }
        ).
            catch((err) => {
                setLoading(false)
                console.log("%c Error in api call", "color: yellow; font-weight: bold; font-size:16px", err)
            })
    }
    return (
        <div className="modal popup-box">
            <LoaderIndicator loading={loading} />
            <div className={'popupCard-box p-0 w-60'}>
                {/* ------------------------------------------------------------- Header -------------------------------------------------------------- */}
                <div className="modal-header">
                    {(expense == "" && bill == "") && <h4 className="modal_title black popup-head">{'Claim Summary Data'}</h4>}
                    {(expense != "" && bill == "") &&
                        <span className='d-flex popup-head'>
                            <div
                                onClick={() => { setExpense(""); setBill("") }}
                            >
                                <img alt="view" src={backArrow} style={{ width: '10px', cursor: 'pointer ' }} />
                            </div>
                            <h4 className="modal_title black popup-title">{expense?.expense_type}</h4>
                        </span>}
                    {bill != "" &&
                        <span className='d-flex popup-head'>
                            <div
                                onClick={() => { setBill("") }}
                            >
                                <img alt="view" src={backArrow} style={{ width: '10px', cursor: 'pointer ' }} />
                            </div>
                            <h4 className="modal_title black popup-title">{bill.name}</h4>
                        </span>
                    }
                    <button type="button" className="close" data-dismiss="modal" onClick={() => {
                        setIsPreviewErr(false)
                        closeAction()
                    }}>&times;</button>
                </div>
                <div className={"modal-body min-h-48vh h-60vh scroll-y"}>

                    {/* ------------------------------------------------------------- Claim Details -------------------------------------------------------------- */}

                    {
                        (expense == "" && bill == "") &&
                        <div className='detail-div mt-12px'>
                            <div className='edit-cardtitle detail-head '>Employee Details</div>
                            <div className='details pb-10px'>
                                {
                                    employeeDetailsFields.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{selectedClaim.emp[field.key]}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head'>DEM Details</div>
                            <div className='details pb-10px'>
                                {
                                    employeeDetailsFields.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{selectedClaim.dem[field.key]}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head'>L3 Details</div>
                            {
                                selectedClaim.misc_approver ?
                                    <div className='details pb-10px'>
                                        {
                                            employeeDetailsFields.map((field) => {
                                                return (
                                                    <div className='detail-field'>
                                                        <div className='details-lbl'>{field.label} :</div>
                                                        <div className='field-value'>{selectedClaim.misc_approver[field.key]}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className='mt-5 h-90-percent'>
                                        <DataNotFound />
                                    </div>
                            }
                            <div className='edit-cardtitle detail-head'>Admin Details</div>
                            {
                                selectedClaim.admin ?
                                    <div className='details pb-10px'>
                                        {
                                            adminDetailsFields.map((field) => {
                                                return (
                                                    <div className='detail-field'>
                                                        <div className='details-lbl'>{field.label} :</div>
                                                        <div className='field-value'>{selectedClaim.admin[field.key]}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <div className='mt-5 h-90-percent details pb-10px'>
                                        <DataNotFound />
                                    </div>
                            }
                            <div className='edit-cardtitle detail-head'>Claim Details</div>
                            {
                                <div className='details pb-10px'>
                                    {
                                        claimDetailsFormClaim.map((field) => {
                                            const tempValue = selectedClaim[field.key];
                                            if ((field.key === "dem_rejection_remark" || field.key === "misc_rejection_remark" || field.key === "admin_rejection_remark") && tempValue === null) {
                                                return null;
                                            }
                                            return (
                                                <div className='detail-field'>
                                                    <div className='details-lbl'>{field.label} :</div>
                                                    <div className='field-value'>{field.type == "date" ? moment(selectedClaim[field.key]).format('DD-MM-YYYY') ?? "NA"
                                                        : selectedClaim[field.key] ?? "NA"}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            }


                            {/* ----------------------------------------------------------------- expense table view -------------------------------------------------------------------------- */}
                            <div className='edit-cardtitle detail-head '>Expense Details</div>
                            <div className='table-container px-10px mt-10px '>
                                {
                                    expenseDetails.length > 0 ?
                                        <div className="main-contain">
                                            <div className="row align-item-center justify-content-start ">
                                                <table className="table gemsAdmin">
                                                    <thead className='w-100'>
                                                        <tr className='w-100'>
                                                            {expenseTableColsSummary.map((col) => {
                                                                return (
                                                                    <th width={col.width}><div className='thead t-head'>{col.title}</div></th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='w-100'>
                                                        {
                                                            expenseDetails.map((row, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.expense_type ? row.expense_type : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.sub_expense_type1 ? row.sub_expense_type1 : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.sub_expense_type2 ? row.sub_expense_type2 : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.total_expense_amount ? row.total_expense_amount : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{(row?.expense_type == 'Accommodation allowance' || row?.expense_type == 'Daily allowance' || row?.sub_expense_type1 == 'Own vehicle') && row.permissible_expense_amount != null ? row.permissible_expense_amount : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.approvable_expense_amount !== null ? row.approvable_expense_amount : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.approved_expense_amount !== null ? row.approved_expense_amount : 'NA'}</div></td>
                                                                        <td width='10%'><div className={tdStyle}>{row.remark ? row.remark : 'NA'}</div></td>
                                                                        <td width='5%'>
                                                                            <span className='d-flex'>
                                                                                <div
                                                                                    onClick={() => { fetchAttachments(row.id); setExpense(row) }}
                                                                                >
                                                                                    <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-5 h-90-percent'>
                                            <DataNotFound />
                                        </div>
                                }
                            </div>
                        </div>
                    }

                    {/* ------------------------------------------------------------- Expense Detail -------------------------------------------------------------- */}

                    {
                        (expense != "" && bill == "") && <div className='detail-div mt-12px'>
                            <div className='edit-cardtitle detail-head '>Expense Details</div>
                            <div className='details pb-10px'>
                                {
                                    expenseDetailsFields.map((field) => {
                                        return (
                                            <div className='detail-field'>
                                                <div className='details-lbl'>{field.label} :</div>
                                                <div className='field-value'>{
                                                    field.type == "date" ? moment(selectedClaim[field.key]).format('DD-MM-YYYY') ?? "NA" :
                                                        (expense['expense_type'] == 'Accommodation allowance' || expense['expense_type'] == 'Daily allowance' || expense['sub_expense_type1'] == 'Own vehicle') && field.key == 'permissible_expense_amount' ? expense[field.key] != null ? expense[field.key] : 'NA' :
                                                            field.key != 'permissible_expense_amount' && expense[field.key] != null ? expense[field.key] : 'NA'}</div>

                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='edit-cardtitle detail-head '>Attachments</div>
                            <div className='table-container px-10px mt-10px '>
                                {
                                    billDetails.length > 0 ?
                                        <div className="main-contain">
                                            <div className="row align-item-center justify-content-start ">
                                                <table className="table gemsAdmin">
                                                    <thead className='w-100'>
                                                        <tr className='w-100'>
                                                            {billDetailsCol.map((col) => {
                                                                return (
                                                                    <th width={col.width}><div className='thead'>{col.title}</div></th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='w-100'>
                                                        {
                                                            billDetails.map((row, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td width='5%' ><div className={tdStyle}>{index + 1}</div></td>
                                                                        <td width='5%' ><div className={tdStyle}>{row.attachment_name}</div></td>
                                                                        <td width='5%'>
                                                                            <span className='d-flex'>
                                                                                <div
                                                                                    onClick={() => { downloadBill(row.attachment_url) }}
                                                                                >
                                                                                    <img alt="view" src={downloadLatestImg} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                        <td width='5%'>
                                                                            <span className='d-flex'>
                                                                                <div
                                                                                    onClick={() => { setBill({ url: row.attachment_url, type: row.attachment_type, name: row.attachment_name }) }}
                                                                                >
                                                                                    <img alt="view" src={ViewIcon} style={{ width: '25px', cursor: 'pointer ' }} />
                                                                                </div>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-5 h-90-percent'>
                                            <DataNotFound />
                                        </div>
                                }
                            </div>
                        </div>
                    }

                    {/* ------------------------------------------------------------- Attachments -------------------------------------------------------------- */}

                    {
                        bill != '' && <div className='detail-div mt-12px'>
                            {
                                <>
                                    {(bill.type === 'jpg' || bill.type === 'png' || bill.type === 'jpeg') &&
                                        <img src={bill.url} alt="No Preview available" className="upload_bills_modal_body" />
                                    }
                                    {bill.type === 'pdf' &&
                                        <iframe className='upload_bills_modal_body' src={bill.url}></iframe>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
                <div className='modal-footer btnBox1'>
                    <input type='button' onClick={() => {
                        setIsPreviewErr(false)
                        closeAction()
                    }} value='CLOSE' className='cancelBtn' />
                </div>
            </div>
        </div>
    )
}
export default SummaryPopUp