import React, { Component } from 'react';
import CommonButton from '../../../shared/button/GradientButton';
import moment from 'moment';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { attendanceLeaveType, exportToExcel } from '../../../../utils/Utils';
import { attendanceDetails } from '../../../../constants/Messages';
import NodataApproval from '../../../../assets/drishti_images/nodatafound/todolist-nodata.svg';
import Dropdown from '../../../shared/components/Dropdown';

const confirmation = 'Confirmantion'
const TodaysDate = new Date();
const TodaysDates = TodaysDate.toISOString().slice(0, 10);
const validationNotes = `Only latest 3 months data can be generated in the report.`
class DateFilterPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            toDate: '',
            fromDate: '',
            reporteeAttendanceData: [],
            filterData: attendanceLeaveType(),
            statusCode: '',
            statusType: 'All'
        }
    }

    handleFromDateFunction = (e) => {
        this.setState({
            fromDate: e.target.value,
        })
    }

    handleToDateFunction = (e) => {
        this.setState({
            toDate: e.target.value,
        })
    }

    async getAttendanceEnquiryDetails(type) {
        const { action, reporteeEmpId, reporteeDoj } = this.props;
        console.log('reporteeDoj', reporteeDoj);
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        const body = {
            emp_id: action === 'Teams' ? reporteeEmpId : this.props.loginData?.userData?.Perno,
            from_date: this.state.fromDate ? moment(this.state.fromDate).format("YYYY-MM-DD") : '',
            to_date: this.state.toDate ? moment(this.state.toDate).format("YYYY-MM-DD") : '',
            date_of_joining: action === 'Teams' ? (reporteeDoj ? moment(reporteeDoj).format("YYYY-MM-DD") : '') : this.props.loginData?.userData?.Doj ? moment(this.props.loginData?.userData?.Doj).format("YYYY-MM-DD") : '',
            filter: this.state.statusType ? this.state.statusType : ''
        }
        fetchApiCall(Config.generateAttendanceReports, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    if (type === 'search') {
                        this.setState({
                            loading: false,
                            reporteeAttendanceData: res.data.data ? res.data.data : [],
                            message: res.data.message
                        })
                    } else if (type === 'export') {
                        this.setState({ loading: false });
                        this.exportAllData(res.data?.data)
                        return
                    }
                } else {
                    this.setState({
                        loading: false,
                        reporteeAttendanceData: []
                    });
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    reporteeAttendanceData: []
                });
                toast.error('Something went wrong', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    exportAllData = (exportData) => {
        const { action, reporteeEmpId } = this.props;
        if (exportData) {
            let colNames = [attendanceDetails.colNames]
            let attendanceRecords = exportData.map((data, idx) => {
                return {
                    SR_NO: idx + 1,
                    DATE: moment(data.LDATE).format("DD-MM-YYYY"),
                    DAY: moment(data.LDATE).format("dddd"),
                    SWIPE_IN: data.SWIPE_IN,
                    SWIPE_OUT: data.SWIPE_OUT,
                    FIRST_HALF: data.FH,
                    SECOND_HALF: data.SH,
                    REASON: data.reason,
                    STATUS: data.title
                }
            })
            exportToExcel(action === 'Teams' ? reporteeEmpId + '_' + 'Attendance_Report' : this.props.loginData?.userData?.Perno + '_' + "Attendance Report", attendanceDetails.ext, attendanceRecords, colNames)
            toast.success('Attendance report downloaded successfully', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastSuccess'
            })
        }
    }

    changeStatusDropdown = (data) => {
        this.setState({
            statusCode: data.code,
            statusType: data.value,
        })
    }

    render() {
        const { title, action } = this.props;
        const { reporteeAttendanceData, fromDate, toDate, loading } = this.state;
        const today = new Date();
        const priorDate = new Date(new Date().setMonth(today.getMonth() - 3));
        const toDay = priorDate.toISOString().substring(0, 10); // for hiding dates
        return (
            <div className="modal popup-box">
                <LoaderIndicator loading={loading} />
                <div className={'declaration-box p-0 w-50'}>
                    <div className="modal-header">
                        <h4 className="modal_title black">{action === 'Report' || action === 'Teams' ? title : confirmation}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className="modal-body">
                        <div className={'pl-4 pr-4'}>
                            <div className='d-flex flex-direction-row'>
                                <div className='p-2'>
                                    <div className='p-sub-heading ml-3'>{'From Date'}</div>
                                    <div className='esep-placeholder-editable'>
                                        <input
                                            className="form-control esep-placeholder-editable px-3"
                                            type="date"
                                            max={TodaysDates}
                                            min={toDay}
                                            value={fromDate}
                                            onChange={(e) => this.handleFromDateFunction(e)}
                                        />
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <div className='p-sub-heading ml-3'>{'To Date'}</div>
                                    <div className='esep-placeholder-editable'>
                                        <input
                                            className="form-control esep-placeholder-editable px-3"
                                            type="date"
                                            disabled={fromDate === '' ? true : false}
                                            min={fromDate}
                                            max={TodaysDates}
                                            value={toDate}
                                            onChange={(e) => this.handleToDateFunction(e)}
                                        />
                                    </div>
                                </div>
                                <div className='p-2 mt-1_5rem'>
                                    <Dropdown
                                        data={this.state.filterData}
                                        selectedValue={(data) => this.changeStatusDropdown(data)}
                                        value={this.state.statusType}
                                        render={'value'}
                                        arrow={'true'}
                                        height={'NotificationHeight'}
                                    />
                                </div>
                                <div className='mt-33px ml-2'>
                                    {fromDate === '' || toDate === '' ?
                                        <div>
                                            <CommonButton
                                                label="SEARCH"
                                                isDisableBtn={true}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <CommonButton
                                                label="SEARCH"
                                                isGradientBtn={true}
                                                onClick={() => this.getAttendanceEnquiryDetails('search')}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='d-flex flex-direction-row'>
                                <div className='purple-color performance-timeline-text-circle ml-3 mt-3'>{'Note:'}</div>
                                <div className='welcomomepopupdescription ml-1'>
                                    {validationNotes}
                                </div>
                            </div>
                            <div className='mt-2'> <hr /></div>
                            <div className='scroll-y height-41vh'>
                                {reporteeAttendanceData.length != 0 ?
                                    <table className="table table choice-pay-thead">
                                        <thead className=''>
                                            <tr>
                                                <th className='table-header' scope="col"><div className='thead pr-0'>{'Sr.No'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Date'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Day'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'In Time'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead' >{'Out Time'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead' >{'First Half'}</div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Second Half'} </div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Reason'} </div></th>
                                                <th className='table-header' scope="col"><div className='thead'>{'Status'}</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reporteeAttendanceData.map((listValue, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td><div className="td table-data-font">{index + 1}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.LDATE ? moment(listValue.LDATE).format("DD-MM-YYYY") : '-')}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.LDATE ? moment(listValue.LDATE).format("dddd") : '-')}</div></td>
                                                        <td><div className="td table-data-font">{(listValue.SWIPE_IN ? listValue.SWIPE_IN : '-')}</div></td>
                                                        <td> <div className="td table-data-font">{(listValue.SWIPE_OUT ? listValue.SWIPE_OUT : '-')}</div> </td>
                                                        <td><div className="td table-data-font" >{(listValue.FH ? listValue.FH : '-')}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.SH ? listValue.SH : '-')}</div></td>
                                                        <td><div className="td table-data-font" >{(listValue.reason ? listValue.reason : '-')}</div></td>
                                                        <td><div className="td table-data-font">{(listValue.title ? listValue.title : '-')}</div></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    :
                                    <div className='row'>
                                        <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                            <div>
                                                <img src={NodataApproval} alt="Nodata" style={{ "height": "6.25rem" }} />
                                            </div>
                                            <div>
                                                <label className='card-content-title grey-color'>{'No Data Found'}</label>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer btnBox'>
                        {(action === 'Report' || action === 'Teams') &&
                            <>
                                {fromDate === '' || toDate === '' ?
                                    <div className='d-flex justify-content-end p-2'>
                                        <CommonButton
                                            label="DOWNLOAD REPORT"
                                            isDisableBtn={true}
                                        />
                                    </div>
                                    :
                                    <>
                                        <CommonButton
                                            label="DOWNLOAD REPORT"
                                            isGradientBtn={true}
                                            onClick={() => this.getAttendanceEnquiryDetails('export')}
                                        />
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState
    }
}

export default connect(mapStatesToProps)(DateFilterPopup);