import React, { Component } from 'react';
import '../../employeeScreens/todo/ToDo.css';
import Dropdown from '../../../shared/components/Dropdown';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AdminLoginAction from '../login/LoginAction';
import * as KeycloakActions from '../../employeeScreens/login/KeyCloakAction';
import * as userActions from '../../employeeScreens/login/LoginAction';
import Papa from "papaparse";
const chooseSelect = 'Select'
class AddNewTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: true,
            show: false,
            title: '',
            description: '',
            url: '',
            priorityData: [],
            statusData: [],
            priorityValue: '',
            priorityKey: chooseSelect,
            statusValue: 'notStarted',
            statusKey: chooseSelect,
            taskDueDate: '',
            todoDisplayName: 'Organisation',
            selectionKey: 'All',
            loading: false,
            allEmployees: true,
            specificEmployee: false,
            scvFilBase64: '',
            scvFileName: '',
            files: '',
            specificEmp: [],
            isSubmit: true,
            preview: false,
            submit: true
        }
        this.updateData = this.updateData.bind(this);
    }

    componentDidMount() {
        this.getMSTodoMasterData()
    }

    updateActionTake = () => {
        const { data, action } = this.props
        if (action === 'update') {
            this.setState({
                title: data.title,
                taskDueDate: data.dueDateTime ? data.dueDateTime.dateTime : '',
            })
            let statusList = this.state.statusData.filter((list) => list.value === data.status)
            let priorityList = this.state.priorityData.filter((list) => list.value === data.importance)
            this.setState({
                priorityValue: priorityList[0].value ? priorityList[0].value : '',
                priorityKey: priorityList[0].key ? priorityList[0].key : '',
                statusValue: statusList[0].value ? statusList[0].value : '',
                statusKey: statusList[0].key ? statusList[0].key : ''
            })
        }
    }

    getMSTodoMasterData = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno,
        }
        fetchApiCall(Config.getMSTodoMasterData, body, headers, true, 'admin')
            .then(res => {
                console.log('todo data', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        statusData: res.data.data.status ? res.data.data.status : [],
                        priorityData: res.data.data.importance ? res.data.data.importance : []
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            }).then(() =>
                this.updateActionTake()
            )
    }

    storeAdminTask = () => {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {} //Initialize body
        if (this.state.selectionKey === 'All') {
            body = {
                emp_id: this.props.loginData.userData.Perno,
                todo_display_name: this.state.todoDisplayName,
                title: this.state.title,
                task_due_date: this.state.taskDueDate,
                priority: this.state.priorityValue,
                status: this.state.statusValue,
                selection_key: this.state.selectionKey,
            }
        }
        else {
            body = {
                todo_display_name: this.state.todoDisplayName,
                title: this.state.title,
                task_due_date: this.state.taskDueDate,
                priority: this.state.priorityValue,
                status: this.state.statusValue,
                selection_key: this.state.selectionKey,
                emp_id_list: this.state.specificEmp
            }
        }
        fetchApiCall(Config.storeAdminTask, body, headers, true, 'admin')
            .then(res => {
                // console.log('storeAdminTask', res);
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.props.getAdminTask()
                    this.props.onDismiss()
                } else {
                    this.setState({
                        loading: false,
                        todoData: []
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false,
                });
            })
    }

    validateTasks = () => {
        var taskDueDate = new Date(this.state.taskDueDate).setHours(0, 0, 0, 0);
        var currentDate = new Date().setHours(0, 0, 0, 0);
        if (this.state.title === '') {
            toast.error('Please enter description', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else if (this.state.taskDueDate === '') {
            toast.error("Please select due date",
                {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                });
        }
        else if (taskDueDate < currentDate) {
        }
        else if (this.state.priorityKey === chooseSelect) {
            toast.error('Please select priority', {
                position: "bottom-center",
                hideProgressBar: true,
                className: 'toastError'
            })
        }
        else {
            this.setState({
                preview: true,
                submit: false
            })
            // if (this.props.action === 'add') {
            //     this.storeAdminTask()
            // }
        }
    }

    updateData(result) {
        console.log('result', result)
        const updateData = result.data; // Here this is available and we can call this.setState (since it's binded in the constructor)

        console.log('specificEmp------------', updateData)
        console.log('result.dataresult.dataresult.dataresult.data', result.data)
        this.setState({ specificEmp: updateData.slice(0, -1) }); // or shorter ES syntax: this.setState({ data });
        // console.log('specificEmp', updateData)
        console.log('specificEmp', updateData.slice(0, -1))
    }

    render() {
        const toDay= new Date().toISOString().substring(0, 10);
        const { action, data } = this.props
        return (
            <div className="modal popup-box" id="addTodoModal">
                <LoaderIndicator loading={this.state.loading} />
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <h4 className="modal_title">{action === 'add' ? 'New Task Details' : 'Update Task Details'}</h4>
                            <button type="button" className="close" onClick={this.props.onDismiss}>&times;</button>
                        </div>
                        <div className="modal-body">
                            {this.state.isSubmit &&
                                <>
                                    {
                                        this.state.preview &&
                                        <form className='todoModalBody'>
                                            <label className='form-label ml-2'>Task Description</label>
                                            <textarea
                                                className='inpt'
                                                value={this.state.title}
                                                readOnly={true}
                                            />
                                            <label className='form-label ml-2 mt-2'>Due Date</label>
                                            <input
                                                className='inpt'
                                                value={this.state.taskDueDate}
                                                readOnly={true}
                                            />
                                            <label className='form-label ml-2 mt-2'>Set Priority</label>
                                            <input
                                                className='inpt'
                                                value={this.state.priorityKey}
                                                readOnly={true}
                                            />
                                            <div className='row mt-2'>
                                                <div className='d-flex form-label ml-1 mt-2 w-50'>
                                                    <label className="checkBoxData pl-3">
                                                        <input
                                                            type="checkbox"
                                                            className='hidecheckBox'
                                                            checked={this.state.allEmployees}
                                                            readOnly={true}
                                                        />
                                                        <span className="markk"></span>
                                                    </label>
                                                    <div className='form-label'>All Employees  </div>
                                                </div>
                                                <div className='d-flex form-label ml-1 mt-2'>
                                                    <label className="checkBoxData pl-3">
                                                        <input
                                                            type="checkbox"
                                                            className='hidecheckBox'
                                                            checked={this.state.specificEmployee}
                                                            readOnly={true}
                                                        />
                                                        <span className="markk"></span>
                                                    </label>
                                                    <div className='form-label'>Specific Employees  </div>
                                                </div>
                                            </div>
                                            {this.state.specificEmployee &&
                                                <div>
                                                    <label className='form-label ml-2 mt-2'>Attach CSV File</label>
                                                    <input
                                                        className='inpt'
                                                        readOnly={true}
                                                        value={this.state.specificEmp}
                                                    />
                                                </div>
                                            }
                                        </form>
                                    }
                                    {this.state.submit &&
                                        <form className='todoModalBody'>
                                            <label className='form-label ml-2'>Task Description</label>
                                            <textarea
                                                className='inpt'
                                                type='text'
                                                placeholder='Enter Task Description'
                                                value={this.state.title}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ title: e.target.value })}
                                            />
                                            <label className='form-label ml-2 mt-2'>Due Date</label>
                                            <input
                                                className='inpt'
                                                type='date'
                                                min={toDay}  //min props using for hiding the date current to prev date 
                                                placeholder='Select Due Date'
                                                value={this.state.taskDueDate}
                                                readOnly={this.state.show}
                                                onChange={(e) => this.setState({ taskDueDate: e.target.value })}
                                            />
                                            <label className='form-label ml-2 mt-2'>Set Priority</label>
                                            <Dropdown
                                                data={this.state.priorityData}
                                                selectedValue={(data) => this.setState({ priorityValue: data.value, priorityKey: data.key })}
                                                value={this.state.priorityKey}
                                                readOnly={this.state.show}
                                                render={'key'}
                                            />
                                            {/* <label className='form-label ml-2 mt-2'>Status</label>
                                <Dropdown
                                    data={this.state.statusData}
                                    selectedValue={(data) => this.setState({ statusValue: data.value, statusKey: data.key })}
                                    value={this.state.statusKey}
                                    readOnly={this.state.show}
                                    render={'key'}
                                /> */}
                                            {/* <label className='form-label ml-2'>Select bulk upload type</label> */}
                                            {/* <input
                                    className='inpt'
                                    type='text'
                                    placeholder='Enter Task Title'
                                    value={this.state.title}
                                    readOnly={this.state.show}
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                /> */}
                                            <div className='row mt-2'>
                                                <div className='d-flex form-label ml-1 mt-2 w-50'>
                                                    <label className="checkBoxData pl-3">
                                                        <input
                                                            type="checkbox"
                                                            className='hidecheckBox'
                                                            checked={this.state.allEmployees}
                                                            onChange={e => this.setState({ allEmployees: !this.state.allEmployees, specificEmployee: false })}
                                                        />
                                                        <span className="markk"></span>
                                                    </label>
                                                    <div className='form-label'>All Employees  </div>
                                                </div>
                                                <div className='d-flex form-label ml-1 mt-2'>
                                                    <label className="checkBoxData pl-3">
                                                        <input
                                                            type="checkbox"
                                                            className='hidecheckBox'
                                                            checked={this.state.specificEmployee}
                                                            onChange={e => this.setState({ specificEmployee: !this.state.specificEmployee, allEmployees: false, selectionKey: 'specific' })}
                                                        />
                                                        <span className="markk"></span>
                                                    </label>
                                                    <div className='form-label'>Specific Employees  </div>
                                                </div>
                                            </div>
                                            {this.state.specificEmployee &&
                                                <div>
                                                    <label className='form-label ml-2 mt-2'>Attach CSV File</label>
                                                    <input
                                                        className='inpt'
                                                        type="file"
                                                        accept=".csv,.xlsx,.xls"
                                                        onChange={(e) => {
                                                            const files = e.target.files;
                                                            console.log(files);
                                                            if (files) {
                                                                console.log(files[0]);
                                                                Papa.parse(files[0], {
                                                                    complete: this.updateData
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </form>
                                    }
                                </>
                            }
                        </div>
                        {this.state.preview &&
                            <div className='modal-footer btnBox'>
                                <input type='button'
                                    onClick={() => this.setState({ submit: true, preview: false })}
                                    value='BACK'
                                    className='cancelBtn'
                                />
                                <input type='button'
                                    value='SUBMIT'
                                    className='ml-2 validateBtn'
                                    onClick={() => this.storeAdminTask()}
                                />
                            </div>
                        }
                        {this.state.submit &&
                            <div className='modal-footer btnBox'>
                                <input type='button'
                                    onClick={() => this.props.onDismiss()}
                                    value='CANCEL'
                                    className='cancelBtn'
                                />
                                <input type='button'
                                    value='PREVIEW'
                                    className='ml-2 validateBtn'
                                    onClick={() => this.validateTasks()}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState,
        keycloakData: state.keycloakState,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adminAction: bindActionCreators(AdminLoginAction, dispatch),
        tokenActions: bindActionCreators(KeycloakActions, dispatch),
        actions: bindActionCreators(userActions, dispatch),
    };
}
export default connect(mapStatesToProps, mapDispatchToProps)(AddNewTask);

