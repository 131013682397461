import React, { Component } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import Dropdown from '../../../shared/components/Dropdown';
import { getPlantUpdateTypes, postPlantUpdate, getAdminPlants } from '../../../../constants/api.service'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import OutsideClickHandler from 'react-outside-click-handler';
import calander from '../../../../assets/images/applyLeave/calander.png'
import moment from 'moment';
import { resizes, toaster, byteToMB } from '../../../../utils/Utils'
import { plantUpdateMsg, SparshandTvMsg, fileSize } from '../../../../constants/Messages'
import CharacterCounter from '../../../shared/CharacterCounter';
import { configStore } from "../../../../redux/ConfigStore";
import './Notification.css'
export default class AddPlantUpdatePopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plantUpdateTitle: '',
            plantUpdateDescription: '',
            plantUpdateImageContent: "",
            plantUpdateImageName: '',
            plantUpdateType: undefined,
            plantUpdateDocumentName: '',
            plantUpdateDocumentContent: '',
            plantUpdateTypeValue: 'Select',
            plantUpdateTypeValueSet: '',
            showCalender: false,
            selectedDate: new Date(),
            error: false,
            plants: [],
            selectedPlant: null
        }
    }

    componentDidMount() {
        this.setState({
            loading: true
        },
            () => {
                this.callPUTypes();
                this.getPlants();
            })
    }

    componentWillUnmount() {
        this.callPUTypes()
    }

    getPlants = () => {
        this.setState({ loading: true })
        let param = {
            emp_id: configStore.getState()?.adminLoginState?.userData?.Perno,
            comp: configStore.getState()?.adminLoginState?.company?.company_code
        }
        getAdminPlants(param, 'admin')
            .then((response) => {
                response.data.plants = response.data.plants.map((plant) => ({ viewValue: plant, value: plant }))
                this.setState({ plants: response.data.plants, selectedPlant: (response.data.plants[0]), loading: false });
            }).catch((error) => {
                this.setState({ loading: false })
                console.log("getAdminPlants catch error:", error)
            });
    }

    callPUTypes = () => {
        this.setState({ loading: true })
        getPlantUpdateTypes({}, this.props)
            .then((response) => {
                this.setState({ plantUpdateType: response.data.data.filter(item => item.value !== 'All'), loading: false })
            })
            .catch((error) => {
                this.setState({ plantUpdateType: [], loading: false })
                console.log("getPlantUpdateTypes catch error:", error)
            });
    }

    pUSelectedType = (value) => {
        console.log("value:", value)
        this.setState({
            plantUpdateTypeValue: value.value,
            plantUpdateTypeValueSet: value.viewValue
        })
    }

    pUDocUpload = (event) => {
        console.log("event.target.files[0]:", event.target.files[0])
        if (event.target.files[0] && event.target.files[0].type.split('/')[1] === "pdf" && event.target.files[0] !== undefined) {
            if (event.target.files[0].size < fileSize.pdfSize) { // file size less than 2MB
                this.setState({
                    plantUpdateDocumentName: event.target.files[0] ? event.target.files[0].name : '',
                    plantUpdateDocumentContent: event.target.files[0],
                    documentError: false
                })
            }
            else {   // file size greater thant 2MB
                this.setState({
                    documentError: true,
                    plantUpdateDocumentContent: "",
                    plantUpdateDocumentName: ''
                },
                    toaster("warning", SparshandTvMsg.pdfSizeNote + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + "MB."))
            }
        }
        else if (event.target.files[0] == undefined) { // document select but cancle.
            this.setState({
                documentError: false,
                plantUpdateDocumentName: "",
                plantUpdateDocumentContent: ''
            })
        }
        else {
            this.setState({
                documentError: true
            },
                toaster("warning", SparshandTvMsg.unsupportedMediaforthumbnail)
            )
        }
    }

    pUImgUpload = (event) => {
        if (event.target.files[0] && (event.target.files[0].type.split('/')[1] === "png" || event.target.files[0].type.split('/')[1] === "jpeg") && event.target.files[0] !== undefined) {
            if (event.target.files[0].size < fileSize.imageSize) { // file size less than 2MB
                resizes(event.target.files[0], 1280, 720, 'PNG', 100, 0, 'file', 128, 72)
                    .then((uri) => {
                        this.setState({ plantUpdateImageContent: uri, plantUpdateImageName: uri.name, imageError: false })
                    })
                    .catch((error) => {
                        this.setState({ plantUpdateImageContent: '', plantUpdateImageName: "", imageError: true })
                    });
            }
            else {   // file size greater thant 2MB
                this.setState({
                    imageError: true,
                    plantUpdateImageContent: "",
                    plantUpdateImageName: ''
                },
                    toaster("warning", SparshandTvMsg.imgSizeNote + ". " + SparshandTvMsg.ourSize + " " + byteToMB(event.target.files[0].size) + "MB."))
            }
        }
        else if (event.target.files[0] == undefined) { // document select but cancel.
            this.setState({
                imageError: false,
                plantUpdateImageContent: "",
                plantUpdateImageName: ''
            })
        }
        else {
            this.setState({
                imageError: true
            },
                toaster("warning", SparshandTvMsg.unsupportedMediaforthumbnail)
            )

        }
    }

    onStartCalender = () => {
        this.setState({
            showCalender: !this.state.showCalender
        })
    }

    handleCalender = (date) => {
        this.setState({
            showCalender: !this.state.showCalender,
            selectedDate: date
        })
    }

    uploadValidation = (event) => {
        event.preventDefault();

        if (!this.state.selectedPlant) {
            toaster("warning", plantUpdateMsg.unselectedPlant)
        }
        else if (this.state.plantUpdateTypeValue === 'Select') {
            toaster("warning", plantUpdateMsg.selectPlantUpdate)
        }
        else if (this.state.plantUpdateTitle === "") {
            toaster("warning", SparshandTvMsg.tittleWarning)
        }
        else if (this.state.plantUpdateDescription == '') {
            toaster("warning", SparshandTvMsg.descriptionWarning)
        }
        else if (this.state.documentError == true || this.state.imageError) {
            toaster("warning", SparshandTvMsg.finaluploadError)
        }
        else if (this.state.plantUpdateDocumentName === "") {
            toaster("warning", plantUpdateMsg.uploadDocument)
        }
        else if (this.state.plantUpdateImageName === "") {
            toaster("warning", plantUpdateMsg.uploadImage)
        }
        else {
            this.setState({
                loading: true
            }, () => this.uploadPlantUpdates()
            )
        }
    }

    uploadPlantUpdates = () => {
        const formData = new FormData();
        formData.append("title", this.state.plantUpdateTitle);
        formData.append("description", this.state.plantUpdateDescription);
        formData.append("pdf_file", this.state.plantUpdateDocumentContent);
        formData.append("pdf_name", this.state.plantUpdateDocumentName);
        formData.append("img_name", this.state.plantUpdateImageName);
        formData.append("img_file", this.state.plantUpdateImageContent);
        formData.append("date", this.state.selectedDate.toISOString());
        const param = {
            update_type: this.state.plantUpdateTypeValueSet,
            plant: this.state.selectedPlant.value
        }
        //do not remove the below code
        // for (var pair of formData.entries()) {
        //     console.log("uploadPlantUpdates: ", pair[0] + ', ' + pair[1]);
        // }
        postPlantUpdate(param, this.props, formData)
            .then((response) => {
                this.setState({ loading: false })
                this.props.allDismiss();
                this.props.callPlantUpdate();
                toaster("success", response.data.message)

            })
            .catch((error) => {
                this.setState({ loading: false })
                this.props.allDismiss();
                toaster("error", error?.data?.message ? error?.data?.message : "Some error occurred")
                console.log("postPlantUpdate catch error:", error.response)
            });
    }

    render() {
        const { documentError, imageError } = this.state
        return (
            <div>
                {
                    this.state.loading &&
                    <LoaderIndicator loading={this.state.loading} />
                }
                <div>
                    <form className='todoModalBody w-100 max-height-55vh'
                    // onSubmit={this.uploadValidation}
                    >
                        {this.state.plantUpdateType != undefined ?
                            <div className='p-1 w-100'>
                                <div>
                                    <div className='popup_TVscroller'>
                                        <div className='p-placeholder-text mt-1 mb-2 pl-10px pr-10px'>
                                            <label className='form-label ml-2'>Plant:</label>
                                            <Dropdown
                                                data={this.state.plants}
                                                selectedValue={(data) => this.setState({ selectedPlant: data })}
                                                value={this.state?.selectedPlant ? this.state?.selectedPlant?.value : "No Plant Assigned"}
                                                render={'value'}
                                                height={'NotificationHeight'}
                                                bg={"whiteBG"}
                                                disabled={this.state?.plants?.length <= 1}
                                            />
                                        </div>
                                        <div className='p-placeholder-text mb-2 pl-10px pr-10px'>
                                            <label className='form-label ml-2'>Plant Update Type:</label>
                                            <Dropdown
                                                data={this.state.plantUpdateType}
                                                selectedValue={(data) => this.pUSelectedType(data)}
                                                value={this.state.plantUpdateTypeValue}
                                                render={'value'}
                                                height={'NotificationHeight'}
                                                bg={"whiteBG"}
                                            />
                                        </div>
                                        <label className='form-label ml-3'>Title:</label>
                                        <div className='p-placeholder-text mb-2 pl-10px pr-10px'>
                                            <input
                                                className="form-control p-placeholder p-placeholder-admin px-2 pl-10px pr-10px"
                                                type="text"
                                                readOnly={false}
                                                onChange={(e) => this.setState({ plantUpdateTitle: e.target.value })}
                                                value={this.state.plantUpdateTitle}
                                                placeholder={"Enter the title"}
                                                maxLength={120}
                                            />
                                        </div>
                                        <label className='form-label ml-3'>Description:</label>
                                        <div className='p-placeholder-text mb-2 pl-10px pr-10px'>
                                            <textarea
                                                className="form-control p-placeholder px-3"
                                                type="text"
                                                maxLength={fileSize.characterCount}
                                                readOnly={false}
                                                onChange={(e) => this.setState({ plantUpdateDescription: e.target.value })}
                                                value={this.state.plantUpdateDescription}
                                                placeholder={"Enter the description"}
                                            >
                                            </textarea>
                                            <CharacterCounter
                                                currentCount={this.state.plantUpdateDescription.length}
                                                totalCount={fileSize.characterCount}
                                            />
                                        </div>
                                        <div>
                                            <label className='form-label ml-3'>Document:</label>
                                            <div className='secondContainer align-item-center-noflex mx-10px'>
                                                <input
                                                    className={documentError ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control  p-placeholder px-3 paddingTop-11"}//"form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                                                    type="file"
                                                    name='videoUpload'
                                                    id="files"
                                                    accept=".pdf"
                                                    placeholder={"Upload document"}
                                                    readOnly={false}
                                                    onChange={this.pUDocUpload}
                                                />
                                            </div>
                                            <div className='flex-direction-row'>
                                                <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                                                <div className='mb-2'>
                                                    {plantUpdateMsg.documentInstructions.map((instruction, idx) =>
                                                        <div className='p-sub-heading ml-3 mt-2px'>{idx + 1}. {instruction}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <label className='form-label ml-3'>Upload image:</label>
                                            <div className='secondContainer align-item-center-noflex mx-10px'>
                                                <input
                                                    className={imageError ? "form-control p-placeholder-error px-3 paddingTop-11" : "form-control  p-placeholder px-3 paddingTop-11"}//"form-control p-placeholder-error px-3 paddingTop-11" : "form-control p-placeholder px-3 paddingTop-11"}
                                                    type="file"
                                                    name='videoUpload'
                                                    id="files"
                                                    accept="image/jpeg, image/jpg, image/png"
                                                    placeholder={"Upload image"}
                                                    readOnly={false}
                                                    onChange={this.pUImgUpload}
                                                />
                                            </div>
                                            <div className='flex-direction-row'>
                                                <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.instruction}</div>
                                                <div>
                                                    <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction1}</div>
                                                    <div className='p-sub-heading ml-3 mt-2px'>{SparshandTvMsg.thumbnailInstruction2}</div>
                                                    <div className='p-sub-heading ml-3 mt-2px mb-2'>{SparshandTvMsg.thumbnailInstruction3}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <label className='form-label ml-3'>Plant Update Date:</label>
                                        <div className='px-10px'>
                                            <div className='date-input flex-direction-row align-item-center-noflex p-15px '>
                                                <span>{moment(this.state.selectedDate).format("DD-MM-YYYY")}</span>
                                                {/* <img src={calander} alt='calender-ico' className='dateImg mt-1' onClick={() => this.onStartCalender()} /> */}
                                            </div>
                                        </div>
                                        {
                                            this.state.showCalender &&
                                            <div
                                                style={{ zIndex: 2 }}
                                                className='flex-direction-row align-item-center-noflex justify-content-center-noflex popup-calender'
                                            >
                                                <div className="calanderCard w-20 ">
                                                    <OutsideClickHandler
                                                        onOutsideClick={() => this.onStartCalender()}
                                                    >
                                                        <Calendar
                                                            className="calendar"
                                                            onChange={(date) => this.handleCalender(date)}
                                                            value={this.state.selectedDate}
                                                            selectRange={false}
                                                            maxDate={new Date()}
                                                            prev2Label={null}
                                                            next2Label={null}
                                                        />
                                                    </OutsideClickHandler>
                                                </div>
                                            </div>
                                        }
                                        <div className='flex-direction-row'>
                                            <div className='p-sub-heading ml-3'>{SparshandTvMsg.instruction}</div>
                                            <div className='mb-2'>
                                                {plantUpdateMsg.calendarInstructions.map((instruction, idx) =>
                                                    <div className='p-sub-heading ml-3 mt-2px'>{idx + 1}. {instruction}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='min-height-45vh'>
                            </div>
                        }
                    </form>
                    <div className='modal-footer btnBox justify-content-between'>
                        <div className='justify-content-start'>
                            <div className='popup-next-screen-txt-light'>Step <span className='popup-next-screen-txt-bold'>2</span>/2</div>
                        </div>
                        <div className='justify-content-around'>
                            <div className='justify-content-start'>
                                <input type='button'
                                    value='CANCEL'
                                    className='popup-back-btn popup-back-text'
                                    onClick={this.props.back}
                                />
                            </div>
                            {/* <div className='justify-content-start'>
                                <input
                                    type='submit'
                                    value='ADD'
                                    className='popup-confirm-btn'
                                    onSubmit={this.uploadValidation}
                                />
                            </div> */}
                            <div className='justify-content-start'>
                                <a
                                    type="button"
                                    className='btn btn-primary common-btn common-button-text d-flex justify-content-center w-13rem align-item-center-noflex w-10rem'
                                    onClick={this.uploadValidation}
                                >
                                    ADD
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}