import * as type from '../../../../redux/action/ActionType';
import PayslipJobs from '../developerTools/PayslipJobs';

const initialState = {
    isAdminLoggedIn: false,
    adminData: [],
    userData: {},
    adminRoles: [],
    operativeRole:[],
    keycloakData: {},
    emp_id: '',
    profileImage: '',
    company: { company_sht_txt: 'TML', company_code: '0100', kong_path: '/cv' },
    scheduleJobData: {},
}

export const adminLogin = (state = initialState, action) => {
    switch (action.type) {
        case type.TOGGLE_ADMIN:
            return {
                ...state,
                isAdminLoggedIn: action.payload
            }
        case type.ADMIN_DATA:
            return {
                ...state,
                adminData: action.payload
            }
        //------for admin EMP ID----
        case type.ADMIN_EMP_ID:
            return {
                ...state,
                emp_id: action.payload
            }
        case type.ADMIN_USER_LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
            }
        case type.ADMIN_GET_EMPLOYEE_PROFILE_DETAILS:
            return {
                ...state,
                profileImage: action.payload
            }
        case type.ADMIN_ROLE:
            return {
                ...state,
                adminRoles: action.payload
            }
        case type.SET_ADMIN_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case type.SET_SCHEDULE_JOBS:
            if (typeof action.payload === 'string') {
                let tempCOIArray = state.scheduleJobData;
                let temp = action.payload;
                tempCOIArray[temp] = false
                return {
                    ...state,
                }
            }
            return {
                ...state,
                scheduleJobData: action.payload
            }
        case type.GET_ADMIN_TOKEN:
            return {
                ...state,
                keycloakData: action.payload,
            };
        case type.ADMIN_LOGOUT:
            return {
                isAdminLoggedIn: false,
                adminData: [],
                adminRoles: [],
                operativeRole:[],
                emp_id: '',
                keycloakData: {},
                profileImage: '',
                scheduleJobData: {}
            };
        case type.ADMIN_OPERATIVE_ROLE:
            return {
                ...state,
                operativeRole: action.payload
            }
        default:
            return state;
    }
}