import React, { Component } from "react";
import overviewButton from "../../../../assets/drishti_images/overview_button.svg";
import TataMotorsMilestones from './TataMilestones';
import UpcomingMilestone from './UpcomingTataMilestone';
import { BASE_WEB_URL_DEV } from "../../../../constants/Config";
import "./MOMStyles.css";
import MOMFeeds from "./MOMFeeds";

class MOMHomeScreen extends Component {
  constructor(props) {
    super(props);
  }

  gotoLanding = () => {
    window.location = BASE_WEB_URL_DEV + "home";
  };

  render() {
    return (
      <div className="row">
        <div type="button" className="row mom-backscreen-key" onClick={this.gotoLanding}>
          <img src={overviewButton} alt="" />
        </div>
        <div className="mtm-wraper-grid">
          <div className="mom-feeds">
            <MOMFeeds />
          </div>
          <div className="col-md-12">
            <div className="upcoming-milestone">
              <UpcomingMilestone />
            </div>
            <div className="tataMotors-milestones">
              <TataMotorsMilestones />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MOMHomeScreen;
