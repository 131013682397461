import React, { Component } from 'react'
import "../continuousFeedbackManagement/CFMHome.css";
import "../../../../assets/styles/flex.css";
import "../../../../assets/styles/styles.css";
import plus from "../../../../assets/images/cfm/plus.svg";
import done from "../../../../assets/images/cfm/done.svg";
import cancel from "../../../../assets/images/cfm/cancel.svg";
import basline from "../../../../assets/images/cfm/basline.svg";
import { connect } from "react-redux";
import * as Config from "../../../../constants/Config";
import CheckGreenIcon from '../../../../assets/drishti_images/cfm/CheckGreenIcon.svg';
import { fetchApiCall, sessionOut } from "../../../../constants/Service";
import nodata from "../../../../assets/drishti_images/nodatafound/performance-timeline-nodata.svg";
import { toast } from "react-toastify";
import { dateFromNow } from '../../../../utils/Utils'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import ConfirmPopup from '../continuousFeedbackManagement/ConfirmPopup';
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
import LeadershipPopUp from './LeadershipPopUp';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import doubleTick from '../../../../assets/drishti_images/manager/teams/doubleTick.svg';

toast.configure();
const requestFailed = "Unable to contact server\n\tPlease retry after sometime";
class LeadershipScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popupVisibility: false,
            loading: false,
            addComment: false,
            isToggleOn: '',
            role: "Employee",
            comment: '',
            itemId: '',
            name: '',
            user_id: '',
            formDataID: '',
            formTemplateId: '',
            goalData: [],
            goalCommentsData: [],
            isSubmit: false,
            actionCode: '',
            actionValue: '',
            showPopupLeadership: false,
            tempTitle: ''
        };
        this.handleClick = this.handleClick.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            goalData: nextProps.data,
        });
    }
    addCommentDetails = (item_id, name, user_id) => {
        this.setState({
            loading: true
        });
        const req = {
            emp_id: this.props.empId,
            user_id: user_id,
            item_id: item_id,
            comment: this.state.comment,
            commented_by: this.props.loginData.userData.Perno,
            commented_by_name: this.props.loginData.userData.CompName,
            competency_name: name,
            role: this.state.role,
            form_data_id: this.state.formDataID,
            form_template_id: this.state.formTemplateId,
            prev_sf_competency_comments_id: '',
            fem_email: this.props.matrixManagerEmailID,
            dem_email: this.props.managerEmailID,
            emp_email: this.props.directReporteeEmailID
        };
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.addCompetenciesComment, req, headers, true, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                        isSubmit: false
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    this.props.getLeadersheepGoals()
                    this.props.getPerformanceData()
                    this.cancelComment()
                } else {
                    this.setState({ loading: false });
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: "toastError",
                    });
                }
            })
            .catch((err) => {
                console.log('add leadership', err);
                this.setState({
                    loading: false,
                });
            });
    };
    popupAction = (action) => {
        if (action === "next") {
            this.setState({
                isGoalPopup: true,
                isLeadershipPopup: false,
            });
        } else {
            this.setState({
                isGoalPopup: false,
                isLeadershipPopup: true,
            });
        }
    };
    handleClick = (id, action, user_id) => {
        const tempGoal = this.state.goalData.map((item, idx) => ({
            ...item,
            isCommentShow: false
        }))
        this.setState({ goalData: tempGoal, addComment: false })
        if (action === 'view') {
            this.setState({
                itemId: id,
            }, () => this.getCommentDetails(id, user_id));
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === id
                        ? { ...date, isCommentShow: true }
                        : date
                ),
            }));
        }
        else {
            this.setState({ itemId: '' })
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === id
                        ? { ...date, isCommentShow: false }
                        : date
                ),
            }));
        }
    }
    handleChangeComment = (value, listValue) => {
        this.setState((prevState) => ({
            goalData: prevState.goalData.map((date) =>
                date.item_id === listValue.item_id
                    ? { ...date, commentDescription: value }
                    : date
            ),
        }));
        this.setState({
            comment: value,
            itemId: listValue.item_id,
            formDataID: listValue.form_data_id,
            formTemplateId: listValue.form_template_id,
        });
    };
    cancelComment = (listValue) => {
        if (listValue) {
            this.setState((prevState) => ({
                goalData: prevState.goalData.map((date) =>
                    date.item_id === listValue.item_id
                        ? { ...date, commentDescription: '' }
                        : date
                ),
            }));
        }
        this.setState({
            comment: '',
            itemId: '',
            formDataID: '',
            formTemplateId: '',
            addComment: false
        });
    }
    onClickPlusButton = (id) => {
        this.setState({
            itemId: id,
            addComment: true
        });
        const tempGoal = this.state.goalData.map((item, idx) => ({
            ...item,
            isCommentShow: false
        }))
        this.setState({ goalData: tempGoal })
    };
    short_name = (str) => {
        if (str.split(' ').length < 3) {
            let regular_ex = /\b(\w)/g;
            let match_name = str.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        } else {
            let string = str.substring(str.lastIndexOf(" ") + 1);
            let full_string = str.split(' ')[0] + " " + string
            let regular_ex = /\b(\w)/g;
            let match_name = full_string.match(regular_ex);
            let shortName = match_name.join('');
            return shortName;
        }
    }
    validateData = (item_id, name, user_id) => {
        if (this.state.comment == "") {
            toast.error("Please enter comment", {
                position: "bottom-center",
                hideProgressBar: true,
                className: "toastError",
            });
        } else {
            this.setState({ isSubmit: true, itemID: item_id, name: name, user_id: user_id })
        }
    };
    getCommentDetails = (id, user_id) => {
        this.setState({
            loading: true,
        });
        let body = {
            item_id: id,
            user_id: user_id,
            is_employee: true
        };
        const headers = {
            "Content-Type": "application/json",
        };
        fetchApiCall(Config.getCompetenciesComments, body, headers, true, "")
            .then((res) => {
                sessionOut(this.props, res);
                if (res.data.status_code === "200") {
                    this.setState({
                        loading: false,
                        goalCommentsData: res.data.competency_comments ? res.data.competency_comments : [],
                    });
                } else {
                    this.setState({
                        loading: false,
                        goalCommentsData: [],
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: "toastError",
                });
            });
    };
    showModal = () => {
        this.setState({ popupVisibility: true });
    };
    hideModal = () => this.setState({ popupVisibility: false });
    lengthCalculate = (value) => {
        let length = value.length
        return length
    }
    toggleAction = (type, data) => {
        this.setState({ itemID: data, actionValue: type })
        if (type === 'approve') {
            this.setState({ isSubmit: true })
        }
        else {
            return null
        }
    }
    showPopupLeadership = (title) => {
        this.setState({
            showPopupLeadership: true,
            tempTitle: title
        })
    }
    renderMTRTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{'Sent to MTR'}</div>
        </Tooltip>
    );
    renderSFTooltip = props => (
        <Tooltip {...props}>
            <div className="tool-tip-font">{'Sent to Final Appraisal'}</div>
        </Tooltip>
    );

    render() {
        const { applicationMode } = this.props
        if (!this.props.data) return null;
        const { isSubmit, showPopupLeadership } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <>
                        {this.state.goalData.length != 0 ?
                            <div className="feedback-inside-scrollbar mr-0">
                                {this.state.goalData.map((listValue, id) => {
                                    return (
                                        <div className="col-12 row justify-content-between" key={id}>
                                            <p className="col-11 feedback-text justify-content-start px-0">
                                                {listValue.name}
                                                <img src={infoicon} alt='' className='infoIconleadership' style={{ 'whiteSpace': 'nowrap' }}
                                                    onClick={() => this.showPopupLeadership((listValue.name.split("-")[0]))}
                                                />
                                            </p>
                                            {applicationMode === 'write' ?
                                                <div
                                                    type="button"
                                                    className="justify-content-end mr-0"
                                                    onClick={() => this.onClickPlusButton(listValue.item_id)}
                                                >
                                                    <img alt="plus" src={plus} className="plus-img"></img>
                                                </div>
                                                : null
                                            }
                                            <div>
                                                {this.state.itemId === listValue.item_id && this.state.addComment ?
                                                    <div className="">
                                                        <div className="flex-direction-column">
                                                            <textarea
                                                                className="form-control sub-textarea cfm-subcard"
                                                                placeholder="Enter your feedback..."
                                                                rows="3"
                                                                maxLength={300}
                                                                value={listValue.commentDescription}
                                                                onChange={(e) => {
                                                                    this.handleChangeComment(
                                                                        e.target.value,
                                                                        listValue
                                                                    );
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                        <div className="justify-content-between row">
                                                            <div className="justify-content-start time-text ml-3 mt-2">
                                                                {this.lengthCalculate(listValue.commentDescription)}/300
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <div
                                                                    type="button"
                                                                    className="btn done-text grey-color mt-1"
                                                                    onClick={() => this.cancelComment(listValue)}
                                                                >
                                                                    <img
                                                                        alt="cancel"
                                                                        src={cancel}
                                                                        className="done-icon mr-1 mb-0"
                                                                    ></img>
                                                                    Cancel
                                                                </div>
                                                                <div
                                                                    type="button"
                                                                    className="btn done-text purple-color mt-1"
                                                                    onClick={() => this.validateData(listValue.item_id, listValue.name, listValue.user_id)}
                                                                >
                                                                    <img
                                                                        alt="done"
                                                                        src={done}
                                                                        className="done-icon mr-1 mb-0"
                                                                    ></img>
                                                                    Submit
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                                {listValue.comments.length != 0 &&
                                                    <div>
                                                        {!listValue.isCommentShow &&
                                                            <div className="mb-3">
                                                                <div className="d-flex flex-wrap align-item-center">
                                                                    {listValue.comments.map(
                                                                        (commentValue, id) => {
                                                                            return (
                                                                                <div key={id}
                                                                                    className={commentValue.role === 'FEM' ? 'fem-bg-circle cfm-hide-goals-view' :
                                                                                        commentValue.role === 'DEM' ? 'dem-bg-circle cfm-hide-goals-view' :
                                                                                            "employee-bg-circle cfm-hide-goals-view"}
                                                                                >
                                                                                    {this.short_name(
                                                                                        commentValue.commented_by_name
                                                                                    )}
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <label
                                                                        type="button"
                                                                        className="view-feedback-btn mx-3"
                                                                        onClick={() => this.handleClick(listValue.item_id, 'view', listValue.user_id)}
                                                                    >
                                                                        View feedbacks
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.goalCommentsData.length != 0 && listValue.isCommentShow &&
                                                            <div>
                                                                <div className="feedback-subcard">
                                                                    {this.state.goalCommentsData.map((goallistValue, id) => {
                                                                        return (
                                                                            <div className="d-flex align-item-center" key={id}>
                                                                                <div className={id < (this.state.goalCommentsData.length - 1) ? "timeline-goal ml-3" : 'timeline-goal1 ml-3'}>
                                                                                    <div
                                                                                        className={goallistValue.role === 'FEM' ? 'fem-bg-circle circle-text' :
                                                                                            goallistValue.role === 'DEM' ? 'dem-bg-circle circle-text' :
                                                                                                "employee-bg-circle circle-text"}
                                                                                    >
                                                                                        {this.short_name(goallistValue.commented_by_name)}
                                                                                    </div>
                                                                                    <div className="ml-55px">
                                                                                        <div className="row justify-content-between mt-2">
                                                                                            <div className="row">
                                                                                                <div className="sub-card-text-bold">
                                                                                                    {goallistValue.commented_by_name}
                                                                                                </div>
                                                                                                <div className='time-text mx-3'>{dateFromNow(goallistValue.created_date_time)}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="mr-4">
                                                                                                {goallistValue.status === '4' ?
                                                                                                    <OverlayTrigger placement="left" overlay={this.renderMTRTooltip}>
                                                                                                        <img
                                                                                                            alt="basline"
                                                                                                            src={CheckGreenIcon}
                                                                                                            className="inside-basedline"
                                                                                                        >
                                                                                                        </img>
                                                                                                    </OverlayTrigger>
                                                                                                    :
                                                                                                    goallistValue.status === '5' ?
                                                                                                        <OverlayTrigger placement="left" overlay={this.renderSFTooltip}>
                                                                                                            <img
                                                                                                                alt="basline"
                                                                                                                src={doubleTick}
                                                                                                                className="inside-basedline"
                                                                                                            ></img>
                                                                                                        </OverlayTrigger>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row feedback-comment-text mt-1">
                                                                                            {goallistValue.comment}
                                                                                        </div>
                                                                                        <div className="row mt-2 mb-2">
                                                                                            <img
                                                                                                alt="basline"
                                                                                                src={basline}
                                                                                                className="inside-basedline row"
                                                                                            ></img>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                                <div className="justify-content-start align-item-start mt-2">
                                                                    <label
                                                                        type="button"
                                                                        className="view-feedback-btn mx-3"
                                                                        onClick={() => this.handleClick(listValue.item_id, 'hide')}
                                                                    >
                                                                        Hide feedbacks
                                                                    </label>
                                                                </div>
                                                            </div>}
                                                    </div>
                                                }
                                                <div className="mt-2">
                                                    <img
                                                        alt="basline"
                                                        src={basline}
                                                        className="basedline"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            :
                            <div className="col-12 column mt-25">
                                <div className="justify-content-center ml-7rem">
                                    <img src={nodata} alt="Nodata" />
                                </div>
                                <div className="justify-content-center ml-7rem">
                                    <label className="card-content-title grey-color">
                                        Your leadership behaviours forms is not yet completed !
                                    </label>
                                </div>
                            </div>
                        }
                    </>
                </div >
                {isSubmit &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={'submit'}
                        content={'Please note, once feedback is submitted it cannot be modified or deleted. Kindly confirm if you want to proceed?'}
                        submitData={() => this.addCommentDetails(this.state.itemId, this.state.name, this.state.user_id)}
                        onDismiss={() => this.setState({ isSubmit: false })}
                    />
                }
                {showPopupLeadership &&
                    <LeadershipPopUp
                        title={this.state.tempTitle}
                        loginData={this.props.loginData}
                        empID={this.props.loginData.userData.Perno}
                        onDismiss={() => this.setState({ showPopupLeadership: false })}
                    />
                }
            </div>
        );
    }
}
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
    }
}
export default (connect(mapStatesToProps)(LeadershipScreen));