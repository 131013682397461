import React, { useEffect, useState } from 'react'
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import ConfirmationPopUp from './ConfirmationPopUp'
import { toaster } from '../../../../utils/Utils'
import AgentConfig from './AgentConfig'
import AgentMaster from './AgentMaster'
import AgencyMaster from './AgencyMaster'
import AgencyConfig from './AgencyConfig'
import GradeGroupMapping from './GradeGroupMapping'
import { addAgencyConfig, addAgencyMaster, addEsgGroup, addSPOCConfig, addSPOCMaster, duplicateSPOCConfigApi, getAgencyMasterList, getAgencySPOCConfigList, getSPOCMasterList, updateAgencyMaster, updateSPOCConfigApi, updateSPOCMaster } from '../../../../constants/api.service'
import { AgencyTypeList, confirmPopupMessage } from './constants'
import { exportTOExcel } from "../localConveyance/constants";
import { OverlayTrigger } from 'react-bootstrap'
import infoicon from '../../../../assets/drishti_images/infoicon.svg';
const mobileNoRegex = /^[6-9]\d{9}$/
const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
function MasterPopup(props) {
    const { closePopUp, masterOption, loading, title = "Add Form", editMode = false, selectedRecord } = props
    const [agentFirstName, setAgentFirstName] = useState(null)
    const [agentLastName, setAgentLastName] = useState(null)
    const [agentName, setAgentName] = useState(null)
    const [domainId, setDomainId] = useState(null)
    const [mobileNo, setMobileNo] = useState(null)
    const [email, setEmail] = useState(null)
    const [groupName, setGroupName] = useState(null)
    const [grade, setGrade] = useState(null)
    const [eGroup, setEgroup] = useState(null)
    const [gradeDesc, setGradeDesc] = useState(null)
    const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false)
    const [selectedAgency, setSelectedAgency] = useState({ title: null, value: "1" })
    const [selectedCompany, setSelectedCompany] = useState({ title: null, value: "1" })
    const [selectedPA, setSelectedPA] = useState({ label: null, value: null })
    const [selectedPSA, setSelectedPSA] = useState({ label: null, value: null })
    const [selectedAgencyType, setSelectedAgencyType] = useState({ title: null, value: "1" })
    const [documentApplicable, setDocumentApplicable] = useState(false)
    const [releaseOrder, setReleaseOrder] = useState(1)
    const [agencyName, setAgencyName] = useState(null)
    const [agencyDesc, setAgencyDesc] = useState(null)
    const [agencyList, setAgencyList] = useState([])
    const [agentList, setAgentList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const [selectedGrade, setSelectedGrade] = useState({ label: null, value: null })
    const [selectedAgent, setSelectedAgent] = useState(null)
    const [compCodeList, setCompCodeList] = useState([])
    const [paList, setPaList] = useState([])
    const [psaList, setPsaList] = useState([])
    const [Loading, setLoading] = useState(false)
    const [mobileNoError, setMobileNoError] = useState({ isValid: true, msg: "" })
    const [emailIdError, setEmailIdError] = useState({ isValid: true, msg: "" })
    const [agent1, setAgent1] = useState(null)
    const [agent2, setAgent2] = useState(null)
    const [option, setOption] = useState("")

    const validateSPOCMaster = () => {
        if (!domainId || !agentFirstName || !mobileNo || !email || mobileNo == "" || !email.match(emailRegex)) {
            toaster("warning", "Invalid input fields")
            setIsConfirmPopupOpen(false);
            return false
        }
        else return true
    }
    const validateAgencyMaster = () => {
        if (!agencyName || !agencyDesc || !documentApplicable || !selectedAgencyType.title || !releaseOrder) {
            toaster("warning", "Invalid input fields")
            setIsConfirmPopupOpen(false);
            return false
        }
        else return true
    }

    const validateGradeGroup = () => {
        if (!selectedCompany.comp_code || !groupName || !grade || !selectedPA.pa || !eGroup) {
            toaster("warning", "Invalid input fields")
            setIsConfirmPopupOpen(false);
            return false
        }
        else return true
    }
    const clearAllFields = () => {
        setAgentFirstName(null); setAgentLastName(null); setAgencyName(null); setDomainId(null); setMobileNo(null); setEmail(null)
        setGroupName(null); setIsConfirmPopupOpen(false); setSelectedAgency({ title: null, value: null }); setSelectedCompany({ label: null, value: null });
        setSelectedPA({ label: null, value: null }); setSelectedPSA({ label: null, value: null }); setSelectedAgencyType({ title: null, value: null });
        setDocumentApplicable(false); setReleaseOrder(1); setAgencyName(null); setAgencyDesc(null);  setLoading(false); setMobileNo({ isValid: true, msg: "" }); setEmailIdError({ isValid: true, msg: "" });
        setGrade(null); setEgroup(null);  setSelectedGrade({ label: null, value: null }); setSelectedAgent(null);setAgent1(null);setAgent2(null)
    }
    const closeConfirmPopup = () => {clearAllFields();setAgencyList([]); setCompCodeList([]);
        setPaList([]); setPsaList([]);setAgentList([]); setGradeList([]);closePopUp()}

    //  ---------------------------------------------------------------------- API functions ------------------------------------------------------------------ //
    const addNewAgent = () => {
        if (validateSPOCMaster()) {
            let req_body = {
                domain: domainId,
                first_name: agentFirstName,
                last_name: agentLastName ?? "",
                mobile_no: mobileNo,
                email: email,
                phone: null
            }
            addSPOCMaster(req_body, props)
                .then((res) => {
                    closeConfirmPopup()
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                }).
                catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
        }
    }
    const updateAgent = () => {
        if (validateSPOCMaster()) {
            let req_body = {
                first_name: agentFirstName,
                last_name: agentLastName ?? "",
                mobile_no: mobileNo,
                phone: null,
                email: email,
                domain: domainId
            }
            updateSPOCMaster(req_body, selectedRecord.id, props)
                .then((res) => {
                    closeConfirmPopup()
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                }).catch((err) => {
                    console.log("error", err.data)
                    toaster("error", err?.response?.data?.message)
                    closeConfirmPopup()
                })
        }
    }
    const addNewSPOCConfig = () => {
        let req_body = {
            agent: selectedAgent.value,
            pa: selectedPA?.value,
            psa: selectedPSA?.value,
            comp_code: selectedCompany.comp_code,
            agency: selectedAgency.id,
            grade_grp_list: selectedGrade.map((data) => data.label)
        }
        addSPOCConfig(req_body, props)
            .then((res) => {
                closeConfirmPopup()
                let { status, data } = res
                if (status === 200 || status === 201) { toaster("success", data.message) }
                else if (status == 400) {
                    if (data?.data) { exportTOExcel("Failed Records", data.data, ".csv") }
                    toaster("warning", data.message)
                }
                else { res?.status != 404 && toaster("warning", data.message ? data.message : "") }
            }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
    }
    const duplicateSPOCConfig = () => {
        let req_body = {
            agent_to_duplicate: agent1,
            agent_to_target: agent2
        }
        duplicateSPOCConfigApi(req_body, props)
            .then((res) => {
                closeConfirmPopup()
                let { status, data } = res
                if (status === 200 || status === 201) { toaster("success", data.message) }
                else if (status == 400) {
                    if (data?.data) { exportTOExcel("Failed Records", data.data, ".csv") }
                    toaster("warning", data.message)
                }
                else { res?.status != 404 && toaster("warning", data.message ? data.message : "") }
            }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
    }
    const updateSPOCConfig = () => {
        let req_body = {
            agent_id: selectedAgent.value
        }
        updateSPOCConfigApi(req_body, selectedRecord.id, props)
            .then((res) => {
                closeConfirmPopup()
                if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
            }).catch((err) => {
                console.log("error", err.data)
                toaster("error", err?.response?.data?.message)
                closeConfirmPopup()
            })
    }
    const addNewAgency = () => {
        if (validateAgencyMaster()) {
            let req_body = {
                name: agencyName,
                description: agencyDesc,
                is_document_applicable: documentApplicable,
                managed_at: selectedAgencyType.title,
                release_order: releaseOrder
            }
            addAgencyMaster(req_body, props)
                .then((res) => {
                    closeConfirmPopup()
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
        }
    }
    const updateAgency = () => {
        if (validateAgencyMaster()) {
            let req_body = {
                description: agencyDesc,
                is_document_applicable: documentApplicable,
                managed_at: selectedAgencyType.title,
                release_order: releaseOrder
            }
            updateAgencyMaster(req_body, selectedRecord.id, props)
                .then((res) => {
                    closeConfirmPopup()
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
        }
    }
    const addNewAgencyConfig = () => {
        let req_body = {
            agency: selectedAgency.id,
            pa: selectedPA.value,
            psa: selectedPSA.value,
            comp_code: selectedCompany.comp_code,
            grade_grp: selectedGrade.value
        }
        addAgencyConfig(req_body, props)
            .then((res) => {
                closeConfirmPopup()
                if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
            }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
    }
    const addGradeGroup = () => {
        if (validateGradeGroup()) {
            let req_body = {
                pa: selectedPA.value,
                comp_code: selectedCompany.comp_code,
                grp_name: groupName,
                esubgroup: grade,
                egroup: eGroup,
                grp_desc: gradeDesc
            }
            addEsgGroup(req_body, props)
                .then((res) => {
                    closeConfirmPopup()
                    if (res.status === 200 || res.status === 201) { toaster("success", res.data.message) }
                    else { res?.status != 404 && toaster("warning", res.data.message ? res.data.message : "") }
                }).catch((err) => { console.log("error", err); toaster("error", err?.response?.data?.message); closeConfirmPopup() })
        }
    }
    const updateMaster = () => {
        if (masterOption == "0") { updateAgent() }
        else if (masterOption == "1") { updateSPOCConfig() }
        else if (masterOption == "2") { updateAgency() }
    }
    const addMaster = () => {
        if (masterOption == "0") { addNewAgent() }
        else if (masterOption == "1") { option == "Duplicate" ? duplicateSPOCConfig() : addNewSPOCConfig() }
        else if (masterOption == "2") { addNewAgency() }
        else if (masterOption == "3") { addNewAgencyConfig() }
        else if (masterOption == "4") { addGradeGroup() }
    }
    const getAgencyMaster = () => {
        setLoading(true)
        getAgencyMasterList('', props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) { setAgencyList(res.data.data.paginated_data) }
                    else { setAgencyList([]) }
                }
            ).catch((err) => { setAgencyList([]) }).finally(() => setLoading(false))
    }
    const getCompanyList = () => {
        setLoading(true)
        getAgencySPOCConfigList('', props)
            .then(
                (res) => {
                    if (res.status === 200 || res.status === 201) {
                        setCompCodeList(res.data.data)
                        // setSelectedCompany({ label: null, value: null })
                        // setSelectedPA({ label: null, value: null })
                        // setSelectedPSA({ label: null, value: null })
                        // setPaList([])
                        // setPsaList([])
                    }
                    // setAgencyList(res.data.data)
                }
            ).catch((err) => { setCompCodeList([]) }).finally(() => setLoading(false))
    }
    const getPAList = (data) => {
        setLoading(true)
        let params = { "domain_id": domainId, "comp_code": data.comp_code }
        getAgencySPOCConfigList(params, props).then(
            (res) => {
                if (res.status === 200 || res.status === 201) {
                    let tempPaList = []
                    res.data.data.map((data) => { tempPaList.push({ ...data, label: data.pa, value: data.pa }) })
                    setPaList(tempPaList)
                    // setSelectedPA({ label: null, value: null })
                    // setSelectedPSA({ label: null, value: null })
                    // setPsaList([])
                }
            }
        ).catch((err) => { setPaList([]) }).finally(() => setLoading(false))
    }
    const getPSAList = (data) => {
        setLoading(true)
        let params = { "comp_code": data.comp_code, "pers_area": data.pa }
        getAgencySPOCConfigList(params, props)
            .then(
                (res) => {
                    let tempPSAlist = []
                    if (masterOption == "3" || masterOption == "1") {
                        tempPSAlist.push({ label: "ALL", value: "ALL" })
                    }
                    res.data.data.map((data) => {
                        tempPSAlist.push({ label: data.psa, value: data.psa, isDisabled: false })
                    })
                    setPsaList(tempPSAlist)
                    // setSelectedPSA({ label: null, value: null })
                    // setSelectedGrade({ label: null, value: null })
                    // setGradeList([])
                }
            ).catch((err) => { setPsaList([]) }).finally(() => setLoading(false))
    }
    const getAgentList = () => {
        getSPOCMasterList('', props)
            .then(
                (res) => {
                    let tempAgentList = []
                    if (res.status === 200 || res.status === 201) {
                        res?.data?.data?.paginated_data && res.data.data.paginated_data.map((data) => { tempAgentList.push({ ...data, label: data.domain_id, value: data.id }) })
                    }
                    setAgentList(tempAgentList)
                }
            ).catch((err) => { console.log("err", err.data) }).finally(() => setLoading(false))
    }
    const getGradeGrpList = (data) => {
        let params = { "comp_code": selectedCompany.comp_code, "pers_area": selectedPA.pa, "pers_sub_area": data.value }
        getAgencySPOCConfigList(params, props)
            .then(
                (res) => {
                    let tempPSAlist = []
                    res.data.data.map((data) => {
                        tempPSAlist.push({ label: data.grp_name, value: data.grp_name })
                    })
                    setGradeList(tempPSAlist)
                }
            ).catch((err) => { setGradeList([]) }).finally(() => setLoading(false))
    }

    // ------------------------------------------------------ filter functions ------------------------------------------------------------------ // 
    const handleAgencyFilter = (data) => {
        if (masterOption == "1") { getCompanyList(data) }
        setSelectedAgency(data)
    }
    const handleAgencyTypeFilter = (data) => { setSelectedAgencyType(data) }
    const handleCompanyFilter = (data) => {
        if (masterOption == "1" || masterOption == "3" || masterOption == "4") { getPAList(data) }
        setSelectedCompany(data)
    }
    const handlePAFilter = (data) => {
        if (masterOption != "4") { getPSAList(data) }
        setSelectedPA(data)
    }
    const handlePSAFilter = (data) => {
        if (masterOption == "1" || masterOption == "3") { getGradeGrpList(data) }
        // console.log("some", data, "\n", data.some((option) => option.value == 'ALL'))
        // if (data.some((option) => option.value == 'ALL')) {
        //     console.log("psaList", psaList)
        //     psaList.map((option) => { return { ...option, ...{ "isDisabled": (option.value != 'ALL' ? true : false) } } })
        //     console.log("psalist", psaList)
        //     setPsaList(psaList)
        //     setSelectedPSA(psaList)
        // }
        // else {
        //     psaList.map((option) => { return { ...option, isDisabled: false } })
        //     console.log("psalist", psaList)
        //     setPsaList(psaList)
        //     setSelectedPSA(data)
        // }
        setSelectedPSA(data)
    }
    const handleDocumentToggle = () => { setDocumentApplicable(!documentApplicable) }
    const handleMobileNo = (e) => {
        // if (e.target.value.match(mobileNoRegex)) { setMobileNoError({ isValid: true, msg: "" }) }
        if (e.target.value && e.target.value !== "") { setMobileNoError({ isValid: true, msg: "" }) }
        else { setMobileNoError({ isValid: false, msg: "Invalid Mobile / Phone No." }) }
        setMobileNo(e.target.value)
    }
    const handleEmailId = (e) => {
        if (e.target.value.match(emailRegex)) { setEmailIdError({ isValid: true, msg: "" }) }
        else { setEmailIdError({ isValid: false, msg: "Invalid Email Id." }) }
        setEmail(e.target.value)
    }
    const handleAgentFilter = (data) => {
        setSelectedAgent(data)
    }
    const handleGradeFilter = (data) => {
        setSelectedGrade(data)
    }
    const handleAgent1 = (data) => { setAgent1(data) }
    const handleAgent2 = (data) => { setAgent2(data) }
    const agentMasterTooltip = () => (
        <>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Note: Please ensure agent details you wish to add are present on keycloak. Agent will be able to access clearance portal post the master creation.`}</div>
            <div className='mt-3px'><hr /></div>
        </>
    )
    const agentConfigTooltip = () => (
        <>
            <div className='align-item-center-noflex p-3'> {`Add New Agent Configuration:`}</div>
            <div className='justify-content-center align-item-center-noflex p-3'> {`This feature enables you to create new configuration for agents across clearance agencies.`}</div>
            <div className='mt-3px'><hr /></div>
            <div className='align-item-center-noflex p-3'> {`Duplicate Configuration:`}</div>
            <div className='justify-content-center align-item-center-noflex p-3'> {`This feature enables you to copy existing configuration from one agent to another agent. e.g If agent A (source) is colleague of agent B (target),\n
            then use duplicate configuration to copy configurations of agent A to create configurations of agent B.`}
            </div>
            <div className='mt-3px'><hr /></div>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Note: While adding new configuration, if PSA-All configuration is created then all other PSA wise configuration for a combination of Agent, Agency, Company, PA & Grade group will be deleted. And vice-versa is also true. `}</div>
        <div className='mt-3px'><hr /></div>
        </>
    )
    const updateAgentMasterTooltip = () => (
        <>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Note: You can edit First Name, Last Name, email id & mobile no fields only.`}</div>
            <div className='mt-3px'><hr /></div>
        </>
    )
    const updateAgentConfigTooltip = () => (
        <>
            <div className='justify-content-center align-item-center-noflex p-3'> {`Note: You can edit domain id field only.\n
            This feature can be used for transferring specific configuration from one agent to another agent`}</div>
            <div className='mt-3px'><hr /></div>
        </>
    )
    const renderSFTooltip = () => (
        <div className='flex-direction-column tool-tip-font-choicePay' style={{ zIndex: 99999, position: 'relative', left: 760, top: 120 }}>
            {
                !editMode ?
                    masterOption == "0" ? agentMasterTooltip() : agentConfigTooltip()
                    :
                    masterOption == "0" ? updateAgentMasterTooltip() : updateAgentConfigTooltip()
            }
        </div>
    );
    useEffect(() => {
        if (masterOption == "3" || masterOption == "4") {
            getAgencyMaster()
            getCompanyList()
        }
        if (masterOption == "1") {
            getAgentList()
            getAgencyMaster()
            getCompanyList()
        }
        if (editMode && selectedRecord) {
            selectedRecord.domain_id && setDomainId(selectedRecord.domain_id)
            selectedRecord.mobile_no && setMobileNo(selectedRecord.mobile_no)
            selectedRecord.email && setEmail(selectedRecord.email)
            selectedRecord.first_name && setAgentFirstName(selectedRecord.first_name)
            selectedRecord.last_name && setAgentLastName(selectedRecord.last_name)
            if (masterOption == "0") {
                selectedRecord.name && setAgentName(selectedRecord.name)
                selectedRecord.agency && setSelectedAgency({ id: 0, title: selectedRecord.agency, value: "1" })
                selectedRecord.company && selectedRecord.comp_code && setSelectedCompany({ id: 0, title: selectedRecord.company, value: selectedRecord.comp_code })
            }
            else if (masterOption == "1") {
                selectedRecord.agent_name && setSelectedAgent({ value: selectedRecord.agent, label: selectedRecord.domain_id })
                selectedRecord.agency_name && selectedRecord.agency && setSelectedAgency({ id: selectedRecord.id, name: selectedRecord.agency_name, value: selectedRecord.agency })
                selectedRecord.company_name && selectedRecord.comp_code && setSelectedCompany({ company_name: selectedRecord.company_name, comp_code: selectedRecord.comp_code })
            }
            else if (masterOption == "2") {
                selectedRecord.name && setAgencyName(selectedRecord.name)
                selectedRecord.agency && setSelectedAgency({ id: 0, title: selectedRecord.agency, value: "1" })
                selectedRecord.company && selectedRecord.comp_code && setSelectedCompany({ id: 0, title: selectedRecord.company, value: selectedRecord.comp_code })
            }
            selectedRecord.is_document_applicable && setDocumentApplicable(selectedRecord.is_document_applicable)
            selectedRecord.managed_at && setSelectedAgencyType({ id: 0, title: selectedRecord.managed_at, value: "1" })
            selectedRecord.release_order && setReleaseOrder(selectedRecord.release_order)
            selectedRecord.description && setAgencyDesc(selectedRecord.description)
            selectedRecord.pa && setSelectedPA({ id: 0, label: selectedRecord.pa, value: selectedRecord.pa })
            selectedRecord.psa && setSelectedPSA({ id: 0, label: selectedRecord.psa, value: selectedRecord.psa })
            selectedRecord.grade_grp && setSelectedGrade({ id: 0, label: selectedRecord.grade_grp, value: selectedRecord.grade_grp })
        }
    }, [])
    return (
        <>
            <LoaderIndicator loading={Loading || loading} />
            {
                isConfirmPopupOpen &&
                <div style={{ zIndex: 999 }}>
                    <ConfirmationPopUp onDismiss={() => {
                        closeConfirmPopup()
                    }} title={"Confirmation"} loading={false} onConfirmAction={() => {
                        editMode ? updateMaster() : addMaster();
                    }} message={confirmPopupMessage[masterOption][editMode ? "1" : "0"]} />
                </div>
            }
            <div className="modal popup-box" id="addTodoModal" style={{ zIndex: 7 }}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content toDoModal bg-light">
                        <div className="modal-header edit-cardtitle">
                            <div className='gap-10px row align-items-center'>
                                <h4 className="modal_title">{title}</h4>
                                <div className="d-flex">
                                    <OverlayTrigger placement="right" overlay={renderSFTooltip}>
                                        <img
                                            alt="infoicon"
                                            src={infoicon}
                                            className="info_icon_flexi_pay"
                                        ></img>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <button type="button" className="close" onClick={closeConfirmPopup}>&times;</button>
                        </div>
                        {
                            masterOption == "0" &&
                            <AgentMaster
                                setDomainId={setDomainId}
                                domainId={domainId}
                                editMode={editMode}
                                setAgentFirstName={setAgentFirstName}
                                agentFirstName={agentFirstName}
                                handleMobileNo={handleMobileNo}
                                mobileNo={mobileNo}
                                mobileNoError={mobileNoError}
                                emailIdError={emailIdError}
                                handleEmailId={handleEmailId}
                                email={email}
                                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                                agentLastName={agentLastName}
                                setAgentLastName={setAgentLastName}
                            />
                        }
                        {
                            masterOption == "1" &&
                            <AgentConfig
                                agentList={agentList}
                                editMode={editMode}
                                selectedAgent={selectedAgent}
                                handleAgentFilter={handleAgentFilter}
                                agencyList={agencyList}
                                selectedAgency={selectedAgency}
                                handleAgencyFilter={handleAgencyFilter}
                                compCodeList={compCodeList}
                                handleCompanyFilter={handleCompanyFilter}
                                selectedCompany={selectedCompany}
                                paList={paList}
                                selectedPA={selectedPA}
                                handlePAFilter={handlePAFilter}
                                psaList={psaList}
                                selectedPSA={selectedPSA}
                                handlePSAFilter={handlePSAFilter}
                                gradeList={gradeList}
                                selectedGrade={selectedGrade}
                                handleGradeFilter={handleGradeFilter}
                                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                                agent1={agent1}
                                agent2={agent2}
                                handleAgent1={handleAgent1}
                                handleAgent2={handleAgent2}
                                option={option}
                                setOption={setOption}
                                clearAllFields={clearAllFields}
                            />
                        }
                        {
                            masterOption == "2" &&
                            <AgencyMaster
                                setAgencyName={setAgencyName}
                                agencyName={agencyName}
                                editMode={editMode}
                                setAgencyDesc={setAgencyDesc}
                                agencyDesc={agencyDesc}
                                AgencyTypeList={AgencyTypeList}
                                selectedAgencyType={selectedAgencyType}
                                handleAgencyTypeFilter={handleAgencyTypeFilter}
                                setReleaseOrder={setReleaseOrder}
                                releaseOrder={releaseOrder}
                                documentApplicable={documentApplicable}
                                handleDocumentToggle={handleDocumentToggle}
                                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                            />
                        }
                        {
                            masterOption == "3" &&
                            <AgencyConfig
                                agencyList={agencyList}
                                selectedAgency={selectedAgency}
                                handleAgencyFilter={handleAgencyFilter}
                                editMode={editMode}
                                compCodeList={compCodeList}
                                selectedCompany={selectedCompany}
                                handleCompanyFilter={handleCompanyFilter}
                                paList={paList}
                                selectedPA={selectedPA}
                                handlePAFilter={handlePAFilter}
                                psaList={psaList}
                                selectedPSA={selectedPSA}
                                handlePSAFilter={handlePSAFilter}
                                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                                selectedGrade={selectedGrade}
                                gradeList={gradeList}
                                handleGradeFilter={handleGradeFilter}
                            />
                        }
                        {
                            masterOption == "4" &&
                            <GradeGroupMapping
                                setGroupName={setGroupName}
                                groupName={groupName}
                                editMode={editMode}
                                setGrade={setGrade}
                                setEgroup={setEgroup}
                                setGradeDesc={setGradeDesc}
                                compCodeList={compCodeList}
                                selectedCompany={selectedCompany}
                                handleCompanyFilter={handleCompanyFilter}
                                paList={paList}
                                selectedPA={selectedPA}
                                handlePAFilter={handlePAFilter}
                                setIsConfirmPopupOpen={setIsConfirmPopupOpen}
                                grade={grade}
                                eGroup={eGroup}
                                gradeDesc={gradeDesc}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MasterPopup