import React, { Component } from 'react';
import '../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import * as Config from '../../../../constants/Config';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import view_action from '../../../../assets/drishti_images/transfer/view_action.svg';
import delete_action from '../../../../assets/drishti_images/transfer/delete_action.svg';
import NotificationDetailsPopup from './NotificationDetailsPopup';
import moment from 'moment';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator';
import NotificationConfirmPopup from './NotificationConfirmPopup';
import { currentDate } from '../../../../utils/Utils';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
class LeadershipAnnouncementDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admin: true,
            loading: false,
            listType: '',
            selectedData: {},
            showConfirm: false,
            showDetail: false,
            checked: 0,
            data: [],
            tempData: [],
            announcmentRecords: [],
            isAdmin: true,
            requestData: [
                { id: 1, title: 'Active' },
                { id: 2, title: 'Inactive' }
            ]
        }
    }

    componentDidMount() {
        this.getSliderImagesData()
    }

    onDismiss = () => {
        this.setState({ showConfirm: false })
    }

    inActiveImage = () => {
        this.deleteSliderImageDetails()
    }

    getSliderImagesData() {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'application/json', }
        let request = { 'current_date': currentDate() }
        fetchApiCall(Config.getLeadershipAnnouncement, request, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.isAdmin);
                if (res.data.status_code === '200') {
                    if (res.data.data.length !== 0) {
                        this.setState({ announcmentRecords: res.data.data.announcment_records, loading: false })
                    }
                }
                else { this.setState({ loading: false, announcmentRecords: [] }) }
            }).catch(err => {
                this.setState({ loading: false })
            })
    }

    deleteSliderImageDetails = () => {
        this.setState({ loading: true })
        const headers = { 'Content-Type': 'application/json' }
        let body = {
            announcement_id: this.state.tempData.announcement_id
        }
        fetchApiCall(Config.deleteLeadershipAnnouncement, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res, this.state.admin);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({ loading: false })
                    setTimeout(() => { window.location.reload() }, 1000)

                } else {
                    toast.warn(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                    this.setState({ loading: false })
                }
            }).catch(err => {
                this.setState({ loading: false })
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    warningPopUp = (data) => {
        this.setState({
            showConfirm: true,
            tempData: data
        })
    }

    showDetails = (data) => {
        this.setState({ showDetail: true, tempData: data })
    }

    render() {
        const { showConfirm, showDetail } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className="pushNotification-container pt-2" >
                    <div className='sm-scroll-table p-2'>
                        {this.state.announcmentRecords.length != 0 ?
                            <table className="table">
                                <thead className=''>
                                    <tr>
                                        <th scope="col"><div className='thead pr-0'>Sr. No</div></th>
                                        <th scope="col"><div className='thead'>File</div></th>
                                        <th scope="col"><div className='thead'>Description</div></th>
                                        <th scope="col"><div className='thead'>Created Date</div></th>
                                        <th scope="col"><div className='thead'>Modified Date</div></th>
                                        {/* <th scope="col"><div className='thead ml-2'>Status</div></th> */}
                                        <th scope="col"><div className='thead ml-3'>Action</div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.announcmentRecords.map((listValue, index) => {
                                        return (
                                            <tr key={index} className=''>
                                                <td><div className="td" >{index + 1}</div></td>
                                                <td><div className="td" >{listValue.image_link ? listValue.image_link : '-'}</div></td>
                                                <td><div className="td" >{listValue.announcement_description ? listValue.announcement_description : '-'}</div></td>
                                                <td><div className="td" >{listValue.created_at ? moment(listValue.created_at).format("DD-MM-YYYY") : '-'}</div></td>
                                                <td><div className="td" >{listValue.updated_at ? moment(listValue.updated_at).format("DD-MM-YYYY") : '-'}</div></td>

                                                <td className='d-flex'>
                                                    <div
                                                        onClick={() => this.showDetails(listValue)}>
                                                        <img
                                                            src={view_action}
                                                            alt='view'
                                                            className='mx-2 table-action-button'
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => this.warningPopUp(listValue)}
                                                    >
                                                        <img alt="delete"
                                                            src={delete_action}
                                                            className='table-action-button' />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            :
                            <div className='row'>
                                <div className='col-md-12 d-flex align-items-center flex-column mt-10px'>
                                    <div>
                                        <img src={noDataTransfer} alt="noDataTransfer" />
                                    </div>
                                    <div>
                                        <label className='card-content-title grey-color'>No any image data!</label>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    showConfirm &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'inactive'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete slider images?'}
                        inActiveImage={this.inActiveImage}
                        onDismiss={() => this.setState({ showConfirm: false })}
                    />
                }
                {
                    showDetail &&
                    <NotificationDetailsPopup
                        type={"LeadershipAnnouncement"}
                        title={"Leadership announcement details"}
                        data={this.state.tempData}
                        onDismiss={() => this.setState({ showDetail: false })}
                    />
                }
            </div >
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(LeadershipAnnouncementDashboard);