import React, { Component } from 'react';
import '../../../employeeScreens/leave/LeaveScreen.css';
import noDataTransfer from '../../../../../assets/images/transfer/noDataTransfer.svg';
import { fetchApiCall, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator';
import { connect } from 'react-redux';
import delete_action from '../../../../../assets/drishti_images/transfer/delete_action.svg';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import moment from 'moment';
import NotificationConfirmPopup from '../NotificationConfirmPopup';
import NotificationDetailsPopup from '../NotificationDetailsPopup';
import Dropdown from '../../../../shared/components/Dropdown';
import { CalenderMonth } from '../../../../../utils/Utils';


const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const d = new Date();

class UpcomingOccasionsDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            showDelete: false,
            monthData: CalenderMonth(),
            monthName: month[d.getMonth()],
            monthCode: d.getMonth() + 1,
            selectedEventtId: '',
            viewPopup: false
        }
    }

    deleteUpcomingOccasion = () => {
        this.setState({
            loading: true,
            showDelete: false
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            event_id: this.state.selectedEventtId
        }
        fetchApiCall(Config.deleteUpcomingEvents, body, headers, false, 'admin')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    this.setState({
                        loading: false,
                    },
                        () => this.props.getUpcomingEventsCall(this.state.monthCode)
                    )
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                } else {
                    this.setState({
                        loading: false
                    });
                    toast.warning(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastWarn'
                    })
                }
            }).catch(err => {
                console.warn('err', err)
                this.setState({
                    loading: false
                });
                toast.error(requestFailed, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastError'
                })
            })
    }

    changeNotificationType = (data) => {
        this.setState({
            monthName: data.value,
            monthCode: data.code,
        },
            () => this.props.getUpcomingEventsCall(this.state.monthCode)
        )
    }

    onAction = (listData, type) => {
        if (type === 'view') {
            this.setState({
                viewPopup: true,
                tempAnnouncementData: listData
            })
        }
        else {
            this.setState({
                showDelete: true,
                selectedEventtId: listData.event_id,
            })
        }
    }

    render() {
        const { showDelete, viewPopup } = this.state
        return (
            <div>
                <LoaderIndicator loading={this.state.loading} />
                <div className='flex-direction-row mt-2 justify-content-end' style={{ 'alignItems': 'center' }}>
                    <div className='ml-2 dropdown-Text'>
                        <Dropdown
                            data={this.state.monthData}
                            selectedValue={(data) => this.changeNotificationType(data)}
                            value={this.state.monthName}
                            render={'value'}
                            arrow={'true'}
                            height={'dependOnContent'}
                        />
                    </div>
                </div>
                <div className=" pt-2 pushNotification-container" >
                    {this.props.upcomingEventsData.length != 0 ?
                        <table className="table">
                            <thead className=''>
                                <tr>
                                    <th scope="col" ><div className='thead pr-0'>Sr. No</div></th>
                                    <th scope="col" ><div className='thead'>Title</div></th>
                                    <th scope="col" ><div className='thead ml-4'>Event Date</div></th>
                                    <th scope="col" className='w-2'><div className='thead'>Action</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.upcomingEventsData.map((listValue, index) => {
                                    return (
                                        <tr key={index} className=''>
                                            <td><div className="td" style={{ width: 20 }}>{index + 1}</div></td>
                                            <td><div className="td" >{listValue.event_title ? listValue.event_title : ''}</div></td>
                                            <td><div className="td ml-4" >{moment(listValue.event_date).format("DD-MM-YYYY")}</div></td>
                                            <td className='d-flex'>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'view')}
                                                >
                                                    <img alt="view" src={view_action} style={{ width: '25px', cursor: 'pointer' }} className='mx-2' />
                                                </div>
                                                <div
                                                    onClick={() => this.onAction(listValue, 'delete')}
                                                >
                                                    <img alt="delete" src={delete_action} style={{ width: '25px', cursor: 'pointer' }} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        :
                        <div className='row'>
                            <div className='col-md-12 d-flex align-items-center flex-column' style={{ top: '10px' }}>
                                <div>
                                    <img src={noDataTransfer} alt="noDataTransfer" />
                                </div>
                                <div>
                                    <label className='card-content-title grey-color'>No upcoming occasions have been added yet!</label>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {viewPopup &&
                    <NotificationDetailsPopup
                        type={"upcomingOccasionDetails"}
                        title={"Upcoming Occasions Details"}
                        data={this.state.tempAnnouncementData}
                        onDismiss={() => this.setState({ viewPopup: false })}
                    />
                }
                {showDelete &&
                    <NotificationConfirmPopup
                        title={'Delete Confirmation'}
                        action={'deleteTataBuzzNews'}
                        loader={this.state.loading}
                        content={'Are you sure you want to delete Event?'}
                        deleteNews={() => this.deleteUpcomingOccasion()}
                        onDismiss={() => this.setState({ showDelete: false })}
                    />
                }
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        adminLoginData: state.adminLoginState
    }
}

export default connect(mapStatesToProps)(UpcomingOccasionsDashboard);