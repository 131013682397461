import React, { Component } from 'react';
import back from '../../../../../assets/drishti_images/back.svg';
import approve from '../../../../../assets/drishti_images/manager/landing/approve.png';
import reject from '../../../../../assets/drishti_images/manager/landing/reject.svg';
import LoaderIndicator from '../../../../shared/loader/LoaderIndicator'
import { fetchApiCall, operativeSessionOut, sessionOut } from '../../../../../constants/Service';
import * as Config from '../../../../../constants/Config';
import { toast } from 'react-toastify';
import '../Landing.css';
import moment from 'moment';
import { BASE_WEB_URL_DEV } from '../../../../../constants/Config';
import ConfirmPopup from '../../ConfirmPopup'
import { LeaveApprovalCardSkeleton } from '../../../../shared/skeleton/Skeletons';
import NodataApproval from '../../../../../assets/drishti_images/nodatafound/Nodata-Approval.svg'
import ServiceUnavailable from '../../../../shared/ServiceUnavailable';
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import esepActive from '../../../../../assets/drishti_images/manager/teams/esepActive.svg';
import esepInactive from '../../../../../assets/drishti_images/manager/teams/esepInactive.svg';
import leaveActive from '../../../../../assets/drishti_images/manager/teams/leaveActive.svg';
import leaveInactive from '../../../../../assets/drishti_images/manager/teams/leaveInactive.svg';
import prevLeaveActive from '../../../../../assets/drishti_images/manager/teams/leaveActive.svg';
import prevLeaveInactive from '../../../../../assets/drishti_images/manager/teams/leaveInactive.svg';
import animeArrow from "../../../../../assets/drishti_images/header/right-arrow-gif-unscreen.gif";
import { configStore } from '../../../../../redux/ConfigStore';

const NoApprovalsData = 'You have taken care of all the approvals!';
class Approval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSupervisor : configStore?.getState()?.loginState?.OTData?.is_supervisor,
            isManager :  configStore.getState()?.keycloakState?.keycloakData?.is_employee_reporting,
            loading: false,
            leaveApproval: [],
            leaveApprovalList: [],
            selectedData: [],
            actionValue: '',
            actionCode: '',
            isApprove: false,
            isReject: false,
            is_service_Unavailable: false,  // service unavailble from backend
            manager_approval_Service_reason: '',

            isLeaveActive: 'leave',
            status: 'Pending',
            isDEMApprovalPresent: false,
            isEsepManager: false,
            approvalsRequestData: [],
            page: 1,
            count: [],
            empID: '',

            is_previous_year_leave: false,
            previous_year_leaves_reason: '',

            isEsepActive: false,
            isPreveLeaveActive: false,
            isTraineLeave: ''
        }
    }

    componentDidMount() {
        this.checkService();
        if (this.state.isManager){
            this.setState({isLeaveActive:'leave'},()=>{
                this.getApprovalLeaveListingData(this.props.loginData?.userData?.Perno ? this.props.loginData?.userData?.Perno : '')            })
        }
        else if (this.state.isSupervisor) {
            this.setState({isTraineLeave:'traineeLeave'},()=>{
                this.getTraineeApprovalLeaveListingData(this.props.loginData?.userData?.Perno ? this.props.loginData?.userData?.Perno : '')
        })
        }
    }

    getTraineeApprovalLeaveListingData(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
            approval_type: 'Leave Request',
            leave_category_code: 'All',
            leave_status_code: '0',
            emp_id_value: 'All',
            filter_type: 'emp_id',
            approver_id: this.props.loginData.managerData.Perno ? this.props.loginData.managerData.Perno : '',
            pa: this.props.loginData.userData.PersArea ? this.props.loginData.userData.PersArea : '',
            psa: this.props.loginData.userData.PSubarea ? this.props.loginData.userData.PSubarea : ''
            
        }
        fetchApiCall(Config.trl_getLeaveApproval, body, headers, false, '')
            .then(res => {
                // sessionOut(this.props, res);
                operativeSessionOut(this.props, res,"");
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApproval: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false
                    });
                    const tempApprovalList = this.state.leaveApproval.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                    });

                } else {
                    this.setState({
                        loading: false,
                        leaveApproval: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    leaveApproval: []
                })
            })
    }

    getApprovalLeaveListingData(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId,
            approval_type: 'Leave Request',
            leave_category_code: 'All',
            leave_status_code: '0',
            emp_id_value: 'All',
            filter_type: 'emp_id',
            approver_id: this.props.loginData.managerData.Perno ? this.props.loginData.managerData.Perno : '',
            pa: this.props.loginData.userData.PersArea ? this.props.loginData.userData.PersArea : '',
            psa: this.props.loginData.userData.PSubarea ? this.props.loginData.userData.PSubarea : ''
        }
        fetchApiCall(Config.getLeaveApproval, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApproval: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false
                    });
                    const tempApprovalList = this.state.leaveApproval.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                    });

                } else {
                    this.setState({
                        loading: false,
                        leaveApproval: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    leaveApproval: []
                })
            })
    }

    //--------Get previous year leave approval------------
    getApprovalLeaveData(employeeId) {
        this.setState({
            loading: true
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: employeeId ? employeeId : '',
            approval_type: 'Leave Request',
            leave_category_code: 'All',
            leave_status_code: '0',
            emp_id_value: 'All',
            filter_type: 'emp_id',
            approver_id: this.props.loginData.managerData.Perno ? this.props.loginData.managerData.Perno : '',
            pa: this.props.loginData.userData.PersArea ? this.props.loginData.userData.PersArea : '',
            psa: this.props.loginData.userData.PSubarea ? this.props.loginData.userData.PSubarea : '',
            approver_emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : ''
        }
        fetchApiCall(Config.getPreviousYearLeaveRequest, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        leaveApproval: res.data.data.leave_requests_approval ? res.data.data.leave_requests_approval : '',
                        loading: false
                    });
                    const tempApprovalList = this.state.leaveApproval.map(tempValue => ({ ...tempValue, isSelected: false, tempSelect: false }))
                    this.setState({
                        leaveApprovalList: tempApprovalList,
                    });
                } else {
                    this.setState({
                        loading: false,
                        leaveApproval: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    leaveApproval: []
                })
            })
    }

    goToMain = () => {
        window.location = (BASE_WEB_URL_DEV + 'approval');
    }

    approveRequest = (action, list) => {
        this.setState({
            loading: true,
            isApprove: false,
            isReject: false
        });
        let approvingData = []
        let temp = {
            leave_request_id: list.leave_request_id,
            requestor_emp_id: list.requestor_emp_id
        }
        approvingData.push(temp)
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            data: approvingData,
            action: action,
            approval_type: 'Leave Request'
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        // let apiType = Config.approveLeaveRequest;
        let apiType =  this.state.isTraineLeave ? Config.trl_approveLeaveRequest : Config.approveLeaveRequest;
        fetchApiCall(apiType, body, headers, false, '')
            .then(res => {
                // sessionOut(this.props, res);
                this.state.isTraineLeave ? operativeSessionOut(this.props, res,"") : sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    // this.getApprovalLeaveListingData(this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '')
                    this.state.isTraineLeave ? this.getTraineeApprovalLeaveListingData(this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '') : this.getApprovalLeaveListingData(this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '')
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                        isApprove: false,
                        isReject: false
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    isApprove: false,
                    isReject: false
                });
            })
    }

    //--------Approve previous year leave approval------------
    approvePreviousRequest = (action, list) => {
        this.setState({
            loading: true,
            isApprove: false,
            isReject: false
        });
        let approvingData = []
        let temp = {
            leave_request_id: list.leave_request_id,
            requestor_emp_id: list.requestor_emp_id
        }
        approvingData.push(temp)
        const body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            data: approvingData,
            action: action,
            approval_type: 'Leave Request',
            pa: this.props.loginData.userData.PersArea ? this.props.loginData.userData.PersArea : '',
            psa: this.props.loginData.userData.PSubarea ? this.props.loginData.userData.PSubarea : '',
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        let apiType = Config.prevYearLeaveApprovalRequest;
        fetchApiCall(apiType, body, headers, false, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code === '200') {
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    })
                    this.setState({
                        loading: false,
                    });
                    this.getApprovalLeaveData(this.props.loginData.userData.Perno)
                }
                else {
                    toast.error(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastError'
                    })
                    this.setState({
                        popupVisibility: false,
                        loading: false,
                        isApprove: false,
                        isReject: false
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    isApprove: false,
                    isReject: false
                });
            })
    }

    toggleAction = (type, data) => {
        this.setState({ selectedData: data, actionValue: type })
        if (type === 'approve') {
            this.setState({ actionCode: '0', isApprove: true })
        }
        else {
            this.setState({ actionCode: '1', isReject: true })
        }
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "manager_leave_approval" && item.is_active == false) {
                this.setState({
                    is_service_Unavailable: true,
                    manager_approval_Service_reason: item.reason
                })
            }
            if (item.service_type == "previous_year_leaves" && item.is_active == false) {
                this.setState({
                    is_previous_year_leave: true,
                    previous_year_leaves_reason: item.reason
                })
            }
        })
    }

    //-----------------Get Dem Approvals Data API-----------------------
    getDemEsepApprovals = () => {
        this.setState({
            loading: false,
        });
        const headers = {
            'Content-Type': 'application/json',
        }
        let body = {
            emp_id: this.props.loginData.userData.Perno ? this.props.loginData.userData.Perno : '',
            comp_code: this.props.loginData.userData.CompCode ? this.props.loginData.userData.CompCode : '',
            search_text: this.state.empID ? this.state.empID : '',
            status: this.state.status ? this.state.status : '',
            page_number: this.state.page ? this.state.page : 1,
        }
        fetchApiCall(Config.getDemEsepApprovals, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    //demData to collect total number for pagination records
                    let demData = Array.from({ length: res.data.data.total_pages }, (_, i) => i + 1)
                    this.setState({
                        loading: false,
                        approvalsRequestData: res.data.data.paginated_data ? res.data.data.paginated_data : [],
                        isDEMApprovalPresent: res.data.data.is_approval_present ? res.data.data.is_approval_present : false,
                        count: demData ? demData : []
                    });
                } else {
                    this.setState({
                        loading: false,
                        approvalsRequestData: [],
                        isDEMApprovalPresent: false,
                        count: []
                    });
                }
            }).catch(err => {
                console.log('err-->', err);
                this.setState({
                    loading: false,
                    approvalsRequestData: [],
                    isDEMApprovalPresent: false,
                    count: []
                });
            })
    }

    toggleIcon = (type) => {
        this.setState((prevState) => ({
            isLeaveActive: type === 'leave',
            isEsepActive: type === 'esep',
            isPreveLeaveActive: type === 'prevLeave',
            isTraineLeave : type === 'traineeLeave'
        }), () => {
            if (type === 'esep') {
                this.getDemEsepApprovals();
            }
            else if (type === 'prevLeave') {
                this.getApprovalLeaveData(this.props.loginData?.userData?.Perno);
            }
            else if (type === 'leave') {
                this.getApprovalLeaveListingData(this.props.loginData?.userData?.Perno);
            }
            else if (type === 'traineeLeave') {
                this.getTraineeApprovalLeaveListingData(this.props.loginData?.userData?.Perno);
            }
        });
    }

    render() {
        const { isApprove, leaveApprovalList, isReject, loading, approvalsRequestData, isLeaveActive, isPreveLeaveActive, isEsepActive, isSupervisor,isTraineLeave, isManager } = this.state
        return (
            <div className="card-new h-100" >
                <LoaderIndicator loading={this.state.loading} />
                <div className="row">
                    <div className="d-flex justify-content-between card-title-position">
                        <div>
                            <label className="edit-cardtitle">{isPreveLeaveActive && isManager ? 'Previous Year Attd. Approvals' : isLeaveActive && isManager ? 'Approvals' :isTraineLeave && isSupervisor ?"Trainee Leave Approvals": 'e-Separation Approvals'}</label>
                        </div>
                        <Link
                            to={{ pathname: '/approval' }}>
                            <img alt="gotoAnnouncement" src={back} className="righticon"></img>
                        </Link>
                    </div>
                </div>
                {this.state.is_service_Unavailable ?
                    <div className='row'>
                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                            <ServiceUnavailable reason={this.state.manager_approval_Service_reason} />
                        </div>
                    </div>
                    :
                    <>
                        {loading ?
                            <>
                                <div className='m-1 p-1'>
                                    <LeaveApprovalCardSkeleton />
                                    <LeaveApprovalCardSkeleton />
                                    <LeaveApprovalCardSkeleton />
                                </div>
                            </>
                            :
                            <>
                                <div className="main-contain main-contain-child pt-2" >
                                    <div className='sm-scroll-table p-2'>
                                        {(isLeaveActive || isPreveLeaveActive) ?
                                            /* -------------------Current/Previous leave approvals table-------------------- */
                                            <div>
                                                {leaveApprovalList.length != 0 ?
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className='table-header thead table-landing-heading w-28' scope="col">{'Employee Name'}</th>
                                                                <th className='table-header thead table-landing-heading w-21' scope="col">{'Leave Type'}</th>
                                                                <th className='table-header thead table-landing-heading w-29' scope="col text_ellipsis w-150px">{'Reason'}</th>
                                                                <th className='table-header thead table-landing-heading pl-23px w-20' scope="col">{'Action'}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {leaveApprovalList.map((listValue, index) => {
                                                                return (
                                                                    <tr key={index} className=''>
                                                                        <td> <div className="td d-flex">{listValue.requestor_name ? listValue.requestor_name.slice(0, 20) + (listValue.requestor_name.length > 20 ? "..." : "") : ''}</div> </td>
                                                                        <td><div className="td " >{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                                        <td><div className="td ">{listValue.reason ? listValue.reason.slice(0, 20) + (listValue.reason.length > 20 ? "..." : "") : ""}</div></td>
                                                                        <td className='d-flex'>
                                                                            <div onClick={() => this.toggleAction('approve', listValue)}>
                                                                                <img alt='approve' src={approve} className='w-25px mx-2' />
                                                                            </div>
                                                                            <div onClick={() => this.toggleAction('reject', listValue)}>
                                                                                <img alt="reject" src={reject} className='w-25px' />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div className='row'>
                                                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                                                            <div>
                                                                <img src={NodataApproval} alt="Nodata" className='noDataApproval' />
                                                            </div>
                                                            <div className='mt-15'>
                                                                <label className='performance-timeline-text-date grey-color'>{NoApprovalsData}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            /* -------------------Eseparation approvals table-------------------- */
                                            isEsepActive ?
                                            <div>
                                                {approvalsRequestData.length != 0 ?
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className='table-header thead table-landing-heading' scope="col" >{'Employee Name'}</th>
                                                                <th className='table-header thead table-landing-heading' scope="col" >{'Resignation Request Date'}</th>
                                                                <th className='table-header thead table-landing-heading' scope="col" >{'Reason'}</th>
                                                                <th className='table-header thead table-landing-heading' scope="col">{'More Details'}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {approvalsRequestData.map((listValue, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td> <div className="td d-flex">{listValue.employee_name ? listValue.employee_name.slice(0, 20) + (listValue.employee_name.length > 20 ? "..." : "") : ''}</div> </td>
                                                                        <td><div className="td " >{listValue.resignation_request_date ? moment(listValue.resignation_request_date).format("DD-MM-YYYY") : '-'}</div></td>
                                                                        <td><div className="td ">{listValue.employee_separation_reason ? listValue.employee_separation_reason.slice(0, 20) + (listValue.employee_separation_reason.length > 20 ? "..." : "") : ""}</div></td>
                                                                        <td className='d-flex'>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: '/approval',
                                                                                    state: { isEsepManager: true },
                                                                                }}>
                                                                                <img alt='approve' src={view_action} className='mx-2 w-25px' />
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div className='row'>
                                                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                                                            <div>
                                                                <img src={NodataApproval} alt="Nodata" className='noDataApproval' />
                                                            </div>
                                                            <div className='mt-15'>
                                                                <label className='performance-timeline-text-date grey-color'>{NoApprovalsData}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            isSupervisor &&
                                            <div>
                                                {/* Blue Collar  */}
                                                {leaveApprovalList.length != 0 ?
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className='table-header thead table-landing-heading w-28' scope="col">{'Employee Name'}</th>
                                                                <th className='table-header thead table-landing-heading w-21' scope="col">{'Leave Type'}</th>
                                                                <th className='table-header thead table-landing-heading w-29' scope="col text_ellipsis w-150px">{'Reason'}</th>
                                                                <th className='table-header thead table-landing-heading pl-23px w-20' scope="col">{'Action'}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {leaveApprovalList.map((listValue, index) => {
                                                                return (
                                                                    <tr key={index} className=''>
                                                                        <td> <div className="td d-flex">{listValue.requestor_name ? listValue.requestor_name.slice(0, 20) + (listValue.requestor_name.length > 20 ? "..." : "") : ''}</div> </td>
                                                                        <td><div className="td " >{listValue.leave_category ? listValue.leave_category : ''}</div></td>
                                                                        <td><div className="td ">{listValue.reason ? listValue.reason.slice(0, 20) + (listValue.reason.length > 20 ? "..." : "") : ""}</div></td>
                                                                        <td className='d-flex'>
                                                                            <div onClick={() => this.toggleAction('approve', listValue)}>
                                                                                <img alt='approve' src={approve} className='w-25px mx-2' />
                                                                            </div>
                                                                            <div onClick={() => this.toggleAction('reject', listValue)}>
                                                                                <img alt="reject" src={reject} className='w-25px' />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    :
                                                    <div className='row'>
                                                        <div className='col-md-12 d-flex justify-content-center align-items-center flex-column'>
                                                            <div>
                                                                <img src={NodataApproval} alt="Nodata" className='noDataApproval' />
                                                            </div>
                                                            <div className='mt-15'>
                                                                <label className='performance-timeline-text-date grey-color'>{NoApprovalsData}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                    {/* -------------------Bottom touchable icons-------------------- */}
                                    <div className='d-flex row justify-content-center'>
                                       { isManager &&
                                        <div className="toggleStyle pointer" data-tooltip="Leave">
                                            <img src={this.state.isLeaveActive ? leaveActive : leaveInactive} className='mx-1' onClick={() => this.toggleIcon('leave')} />
                                        </div>
                                        }
                                        {!this.state.is_previous_year_leave && isManager &&
                                            <div className='mt_25px'>
                                                <img
                                                    src={animeArrow}
                                                    alt="Birthday"
                                                    className="rightGIF ml-2"
                                                />
                                                <div className="toggleStyle pointer" data-tooltip="Previous Year Attd. Reg.">
                                                    <img src={this.state.isPreveLeaveActive ? prevLeaveActive : prevLeaveInactive} onClick={() => this.toggleIcon('prevLeave')} />
                                                </div>
                                            </div>
                                        }
                                       { isManager &&
                                        <div className="toggleStyle pointer ml-1" data-tooltip="e-Separation">
                                            <img src={this.state.isEsepActive ? esepActive : esepInactive} onClick={() => this.toggleIcon('esep')} />
                                        </div>
                                        }
                                        {isSupervisor &&
                                            <div className="toggleStyle pointer ml-1" data-tooltip="Trainee Leave">
                                                <img src={this.state.isTraineLeave ? esepActive : esepInactive} onClick={() => this.toggleIcon('traineeLeave')} />
                                            </div>
                                        }
                                    </div>

                                </div>
                            </>
                        }
                    </>
                }
                {isReject &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Kindly confirm to proceed with the leave rejection'}
                        rejectData={this.state.isPreveLeaveActive ? () => this.approvePreviousRequest(this.state.actionCode, this.state.selectedData) : () => this.approveRequest(this.state.actionCode, this.state.selectedData)}
                        onDismiss={() => this.setState({ isReject: false })}
                    />
                }
                {isApprove &&
                    <ConfirmPopup
                        title={'Confirmation'}
                        action={this.state.actionValue}
                        content={'Kindly confirm to proceed with the leave approval'}
                        approveData={this.state.isPreveLeaveActive ? () => this.approvePreviousRequest(this.state.actionCode, this.state.selectedData) : () => this.approveRequest(this.state.actionCode, this.state.selectedData)}
                        onDismiss={() => this.setState({ isApprove: false })}
                    />
                }

            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState
    }
}

export default connect(mapStatesToProps)(Approval);
