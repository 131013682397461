import React, { useEffect, useState } from "react";
import AddIcon from "../../../../../assets/drishti_images/addTableBtn.svg";
import { DDMMYYYY } from "../../../../../utils/Utils";
import { tdStyle } from '../../../adminScreens/localConveyance/constants'
import view_action from '../../../../../assets/drishti_images/transfer/view_action.svg'
import send_back from '../../../../../assets/drishti_images/send-back.svg'

function Table(props) {
  const {
    columns,
    data,
    hasAction = false,
    multiSelect = true,
    currentPage,
    agency_name,
    pageSize = 10,
    totalPages,
    setCurrentPage,
    primaryActionImg = AddIcon,
    onSelectSuggestion,
    selectedSuggestion,
    onAction,
    radioValue,
    className = "",
    readOnly = true,
    onSendBackAction = () => {},
    isSrNoShow=false,
    firstColumn="Suggestion ID",
    minHeight=''
  } = props;
  

  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [buttonStates, setButtonStates] = useState(
    data.map(() => false) // Initialize all button states to false
  );

  const keys = ['present_status', 'earlier_status', 'proposed_changes', 'changes_implemented', 'benefits', , 'title', 'clarification_to_divc', 'comment', 'applicable_areas', 'implemented_date']

  const handleSelectAll = (checked) => {
    if (!checked) {
      setSelectedIndexes([]);
      onSelectSuggestion([]);
    } else {
      let tempArr = Array.from(Array(data.length), (_, i) => i);
      setSelectedIndexes(tempArr);
      onSelectSuggestion(data);
    }
  };
  
  const handleCheckboxSelection = (index, checked, row) => {
    if (!checked) {
      let arrayIndex = selectedIndexes.findIndex((ind) => {
        return ind == index;
      });
      let tempArr = [
        ...selectedIndexes.slice(0, arrayIndex),
        ...selectedIndexes.slice(arrayIndex + 1),
      ];
      setSelectedIndexes(tempArr);
      let arrayIndexOfSelectedSuggestion = selectedSuggestion.findIndex(
        (item) => item == row
      );
      let tempArrofSelectedSuggestion = [
        ...selectedSuggestion.slice(0, arrayIndexOfSelectedSuggestion),
        ...selectedSuggestion.slice(arrayIndexOfSelectedSuggestion + 1),
      ];
      onSelectSuggestion(tempArrofSelectedSuggestion);
    } else {
      let tempArr = [...selectedIndexes];
      tempArr.push(index);
      setSelectedIndexes(tempArr);
      let tempArrofSelectedSuggestion = [...selectedSuggestion];
      tempArrofSelectedSuggestion.push(row);
      onSelectSuggestion(tempArrofSelectedSuggestion);
    }
  };

  useEffect( () => {
    if(selectedSuggestion?.length === 0 ) setSelectedIndexes([])
  }, [selectedSuggestion] )


  const handletogglePopUp = (row) => {
    // setToggleDetailPopup(!toggleDetailPopup);
    setSelectedIndexes([])
    onAction(row);
  }
  

  const getRowData = (data, cols) => {
    const { type, key } = cols;

    switch(type) {
      case "date":
        return DDMMYYYY(data[key]) ?? "--";
      case "special":
        const accessor = cols?.specialRowFunction(data);
        return data[accessor]
      default: 
        return data[key] ?? "--"
    }
  
  }
  
  return (
    <div>
      <div className={`scroll-x max-height-45vh mt-5px ${className}`} style={{minHeight: minHeight}}>
        <table className={`table gemsAdmin scroll-y`}>
          <thead className="mb-2">
            <tr className="">
              {!readOnly && multiSelect && radioValue !== 'enquiry' &&(
                <th width={"5%"}>
                  <input
                    className="thead"
                    type="checkbox"
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                    }}
                    checked={selectedIndexes.length == data.length}
                  />
                </th>
              )}
              {radioValue === 'enquiry' && <th width={"10%"}><div className='thead'>Sr No.</div></th>}
                <th width={"10%"}>
                  <div className="thead white-space-no-wrap">{firstColumn}</div>
                </th>
              
                {hasAction && <th width={"10%"}>
                  <div className="thead">Action</div>
                </th>}
              {columns.map((val) => (
                <th width={val.width}>
                  <div className="thead white-space-no-wrap">{val.title}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="mb-1">
            {data.map((row, index) => {
              return (
                <tr className={ (!readOnly && row?.is_super_admin_send_back) ? 'sendBack' : ''}>
                  { !readOnly && multiSelect && (
                    radioValue !== 'enquiry' && (
                    <>
                      <td width={"30"}>
                        <input
                          className="thead"
                          type="checkbox"
                          onChange={(e) => {
                            handleCheckboxSelection(index, e.target.checked, row);
                          }}
                          checked={selectedIndexes.includes(index)}
                        />
                      </td>
                    </>
                  )
                  )}
                  {
                    radioValue === 'enquiry' && <td width='5%'><div className={tdStyle}>{(currentPage ? (currentPage - 1) * pageSize : 0) + (index + 1)}</div></td>
                  }
                  <td width='5%'><div className={tdStyle}>{isSrNoShow ? index+1 : row.id}</div></td>
                  { hasAction && (
                    (readOnly ? (
                    <th className="d-flex table-head">
                      <img
                      alt="view"
                      src={view_action}
                      style={{ width: "25px", cursor: "pointer " }}
                      onClick={() => handletogglePopUp(row)}
                      />
                    </th>
                  ) : (
                    <th className="d-flex table-head">
                      <img
                        alt="view"
                        src={primaryActionImg}
                        style={{ width: "25px", cursor: "pointer " }}
                        onClick={() => handletogglePopUp(row)}
                      />{" "}
                        {!buttonStates[index] && <img
                        alt="view"
                        src={send_back}
                        style={{ width: "25px", cursor: !row?.is_super_admin_send_back ? "pointer " : ''   }}
                        onClick={() =>{ if(!row?.is_super_admin_send_back) { onSendBackAction(row) } }}
                        />}
                    </th>)
                  )
                  )}
              
                  {columns.map((col) => {
                    const { type, key, width } = col;
                      return (
                        <td width={width}>
                          <pre className={keys.includes(key) ? "unineue-font size_875rem custom-pre" : 'unineue-font size_875rem'}>
                            {
                              getRowData(row, col)
                            }
                          </pre>
                        </td>
                      )
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Table;
