import React, { Component } from 'react';
import overviewButton from '../../../../assets/drishti_images/overview_button.svg';
import '../profile/ProfileScreen.css';
import Profile from '../profile/Profile';
import BasicDetails from '../profile/BasicDetails/BasicDetails';
import ContactDetail from '../profile/ContactDetails/ContactDetails';
import BankDetails from '../profile/BankDetails/BankDetails';
import DocumentDetails from './document/DocumentDetails';
import EducationalDetails from '../profile/EducationalDetails';
import EmploymentDetails from '../profile/EmploymentDetails';
import DependentDetails from '../profile/DependentDetails';
import { BASE_WEB_URL_DEV } from '../../../../constants/Config';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ServiceUnavailable from '../../../shared/ServiceUnavailable';

class ProfileScreen extends Component {
    constructor() {
        super();
        this.state = {
            isProfileActive: true,
            isBasicDetailActive: false,
            isContactDetailActive: false,
            isBankDetailActive: false,
            isDocumentActive: false,
            isEducationDetailActive: false,
            isEmploymentDetailActive: false,
            isDependentDetailActive: false,


            // service unavailble from backend
            is_Service_Unavailable: false,
            Profile_Service_reason: '',
            is_ProfileSummary_Service_Unavailable: false,
            ProfileSummary_Service_reason: '',
            is_BasicDetails_Service_Unavailable: false,
            BasicDetails_Service_reason: '',
            is_ContactDetail_Service_Unavailable: false,
            ContactDetail_Service_reason: '',
            is_BankDetails_Service_Unavailable: false,
            BankDetails_Service_reason: '',
            is_DocumentDetails_Service_Unavailable: false,
            DocumentDetails_Service_reason: '',
            is_EducationalDetails_Service_Unavailable: false,
            EducationalDetails_Service_reason: '',
            is_EmploymentDetails_Service_Unavailable: false,
            EmploymentDetails_Service_reason: '',
            is_DependentDetails_Service_Unavailable: false,
            DependentDetails_Service_reason: '',
        }
    }


    componentDidMount() {
        this.checkService();
    }

    toggleprofile = () => {
        this.setState({ isProfileActive: true, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    togglebasicdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: true, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    togglecontactdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: true, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    togglebankdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: true, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    toggledocument = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: true, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    toggleeducationdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: true, isEmploymentDetailActive: false, isDependentDetailActive: false })
    }
    toggleemploymentdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: true, isDependentDetailActive: false })
    }
    toggledependentdetail = () => {
        this.setState({ isProfileActive: false, isBasicDetailActive: false, isContactDetailActive: false, isBankDetailActive: false, isDocumentActive: false, isEducationDetailActive: false, isEmploymentDetailActive: false, isDependentDetailActive: true })
    }
    gotoHome = () => {
        window.location = (BASE_WEB_URL_DEV + 'home')
    }

    checkService = () => {
        this.props.serviceData.availableServiceData.forEach((item, i) => {
            if (item.service_type == "profileMain" && item.is_active === false) {
                this.setState({
                    is_Service_Unavailable: true,
                    Profile_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_summary" && item.is_active === false) {
                this.setState({
                    is_ProfileSummary_Service_Unavailable: true,
                    ProfileSummary_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_basic_details" && item.is_active === false) {
                this.setState({
                    is_BasicDetails_Service_Unavailable: true,
                    BasicDetails_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_contact_details" && item.is_active === false) {
                this.setState({
                    is_ContactDetail_Service_Unavailable: true,
                    ContactDetail_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_bank_details" && item.is_active === false) {
                this.setState({
                    is_BankDetails_Service_Unavailable: true,
                    BankDetails_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_document_details" && item.is_active === false) {
                this.setState({
                    is_DocumentDetails_Service_Unavailable: true,
                    DocumentDetails_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_education_details" && item.is_active === false) {
                this.setState({
                    is_EducationalDetails_Service_Unavailable: true,
                    EducationalDetails_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_employment_details" && item.is_active === false) {
                this.setState({
                    is_EmploymentDetails_Service_Unavailable: true,
                    EmploymentDetails_Service_reason: item.reason,
                })
            }
            if (item.service_type == "profile_dependent_details" && item.is_active === false) {
                this.setState({
                    is_DependentDetails_Service_Unavailable: true,
                    DependentDetails_Service_reason: item.reason,
                })
            }
        })
    }

    render() {
        return (
            <div className="row p-12px">
                <div className="col-md-12 px-0 pt-0">
                    <div className='row px-0 pt-0' >
                        <img alt='' src={overviewButton} onClick={this.gotoHome} style={{ cursor: 'pointer' }} />
                    </div>
                    {this.state.is_Service_Unavailable ?
                        <div className="col-md-12 mt-5 h-60vh  justify-content-center align-item-center">
                            <ServiceUnavailable reason={this.state.Profile_Service_reason} width={true} />
                        </div>
                        :
                        <div className='row mt-1'>
                            <div className='card-new col-md-12'>
                                <div className='row mt-3 px-3 ss col-md-12'>
                                    <div className={this.state.isProfileActive ? "tab-active" : "tab-inactive"} onClick={this.toggleprofile}>
                                        Profile Summary
                                    </div>
                                    <div className={this.state.isBasicDetailActive ? "tab-active" : "tab-inactive"} onClick={this.togglebasicdetail}>
                                        Basic Details
                                    </div>
                                    <div className={this.state.isContactDetailActive ? "tab-active" : "tab-inactive"} onClick={this.togglecontactdetail}>
                                        Contact Details
                                    </div>
                                    <div className={this.state.isBankDetailActive ? "tab-active" : "tab-inactive"} onClick={this.togglebankdetail}>
                                        Bank Details
                                    </div>
                                    <div className={this.state.isDocumentActive ? "tab-active" : "tab-inactive"} onClick={this.toggledocument}>
                                        Documents
                                    </div>
                                    <div className={this.state.isEducationDetailActive ? "tab-active" : "tab-inactive"} onClick={this.toggleeducationdetail}>
                                        Educational Details
                                    </div>
                                    <div className={this.state.isEmploymentDetailActive ? " tab-active" : "tab-inactive"} onClick={this.toggleemploymentdetail}>
                                        Employment Details
                                    </div>
                                    <div className={this.state.isDependentDetailActive ? " tab-active" : "tab-inactive"} onClick={this.toggledependentdetail}>
                                        Dependent Details
                                    </div>
                                </div>
                                <div className='hz-lines mr-4 ml-4'></div>
                                <div className='profile-inner-card scroller-profile' >
                                    {this.state.isProfileActive ?
                                        <div>
                                            {this.state.is_ProfileSummary_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.ProfileSummary_Service_reason} width={true} />
                                                </div>
                                                :
                                                <Profile />
                                            }
                                        </div>
                                        : null
                                    }
                                    {this.state.isBasicDetailActive ?
                                        <div>
                                            {this.state.is_BasicDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.BasicDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <BasicDetails />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isContactDetailActive ?
                                        <div>
                                            {this.state.is_ContactDetail_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.ContactDetail_Service_reason} width={true} />
                                                </div>
                                                :
                                                <ContactDetail />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isBankDetailActive ?
                                        <div>
                                            {this.state.is_BankDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.BankDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <BankDetails />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isDocumentActive ?
                                        <div>
                                            {this.state.is_DocumentDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.DocumentDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <DocumentDetails
                                                    loginData={this.props.loginData} />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isEducationDetailActive ?
                                        <div>
                                            {this.state.is_EducationalDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.EducationalDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <EducationalDetails />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isEmploymentDetailActive ?
                                        <div>
                                            {this.state.is_EmploymentDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.EmploymentDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <EmploymentDetails />
                                            }
                                        </div> : null
                                    }
                                    {this.state.isDependentDetailActive ?
                                        <div>
                                            {this.state.is_DependentDetails_Service_Unavailable ?
                                                <div className="col-md-12 h-60vh  justify-content-center align-item-center">
                                                    <ServiceUnavailable reason={this.state.DependentDetails_Service_reason} width={true} />
                                                </div>
                                                :
                                                <DependentDetails />
                                            }
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        serviceData: state.activePopupState,
    }
}

export default withRouter(connect(mapStatesToProps, {})(ProfileScreen));