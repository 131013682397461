import React, { Component } from 'react';
import Certificate from './Certificate';
import ExitForm from './ExitForm';
import { connect } from 'react-redux';
import './ExitScreensStyle.css';

class ExitScreen extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isExitFormActive: true,
            isCertificateActive: false,
        }
    }

    toggleEmployeeDetails = () => {
        this.setState({ isExitFormActive: true, isCertificateActive: false })
    }
    toggleGroupDetails = () => {
        this.setState({ isExitFormActive: false, isCertificateActive: true })
    } 

    render() {
        return (
            <div className="row p-12px">
                <div className="col-md-12 px-0 pt-0">
                    <div className='row mt-1'>
                        <div className='card-new col-md-12'>
                            <div className='row mt-3 px-3 ss col-md-12'>
                                <div className={this.state.isExitFormActive ? "tab-active" : "tab-inactive"} onClick={this.toggleEmployeeDetails}>
                                    Exit Form
                                </div>
                                <div className={this.state.isCertificateActive ? "tab-active" : "tab-inactive"} onClick={this.toggleGroupDetails}>
                                    Certificate
                                </div>
                            </div>
                            <div className='hz-lines mr-4 ml-4'></div>
                            <div className='profile-inner-card'>
                                {this.state.isExitFormActive ?
                                    <div>
                                        <ExitForm />
                                    </div> : null
                                }
                                {this.state.isCertificateActive ?
                                    <div>
                                        <Certificate />
                                    </div> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// export default ExitScreen;
const mapStatesToProps = (state) => {
    return {
        loginData: state.loginState,
        keycloakData: state.keycloakState,
    }
}

export default connect(mapStatesToProps)(ExitScreen);