import React, { Component } from 'react'
import approval_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import delete_icon from '../../../../assets/images/cfm/delete_popup.svg'
import SuccessButton from '../commonButton/SuccessButton';
import CancelButton from '../commonButton/CancelButton';
import { constTypes } from '../../../ScreenType/employeeScreens/gems/Constant'
import moment from 'moment';
class WarningPopup extends Component {
    constructor(props) {
        super(props);
    }

    startMTRConfirmation = () => {
        console.log("startMTRConfirmation call")
        this.props.onDismiss()
        this.props.sumbit()
    }

    closeMTRConfirmation = () => {
        console.log("closeMTRConfirmation call")
        this.props.onDismiss()
        this.props.sumbit()
    }

    startFRConfirmation = () => {
        console.log("startFRConfirmation call")
        this.props.onDismiss()
        this.props.sumbit()
    }

    closeFRConfirmation = () => {
        console.log("closeFRConfirmation call")
        this.props.onDismiss()
        this.props.sumbit()
    }

    completeConfirmation = () => {
        console.log("completeConfirmation call")
        this.props.onDismiss()
        this.props.sumbit()
    }

    shortlistedByPG = () => {
        this.props.isApprovalConfirmOpen("approval")
        this.props.onDismiss()
    }

    dismissPopup = () => {
        this.props.onDismiss()
    }

    rejectedByPG = () => {
        this.props.isApprovalConfirmOpen("reject")
        this.props.onDismiss()
    }

    render() {
        return (
            <div className="modal popup-box" >
                <div className='popupCard-box w-30'>
                    <div className="modal-header">
                        <h4 className="modal_title black">Confirmation</h4>
                        <button type="button" className="close" data-dismiss="modal"
                            onClick={this.props.onDismiss}
                        >
                            &times;</button>
                    </div>
                    <div className='modal-body m-15px'>
                        {
                            this.props.type === "REJECT" &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="delete_popup"
                                    src={delete_icon}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text mb-2'>
                                    {this.props.message}
                                </div>
                            </div>
                        }
                        {
                            this.props.isEarlyStartMTR &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="accept_popup"
                                    src={approval_icon}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text mb-2'>
                                    {`Note: Mid Term Review is scheduled on ${moment(this.props.dateData).format("DD-MM-YYYY")}.  Are you sure you want to start the Mid Term Review now?`}
                                </div>
                            </div>
                        }
                        {
                            this.props.isEarlyStartFR &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="accept_popup"
                                    src={approval_icon}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text mb-2'>
                                    {`Note: Final Review is scheduled on ${moment(this.props.dateData).format("DD-MM-YYYY")}.  Are you sure you want to start the Final Review now ?`}
                                </div>
                            </div>
                        }
                        {
                            (this.props.type === "ACCEPT" || this.props.type === "APPROVE" || this.props.type === "START MTR REVIEW" || this.props.type === "CLOSE MTR REVIEW" || this.props.type === "START FINAL REVIEW" || this.props.type === "CLOSE FINAL REVIEW" || this.props.type === "COMPLETE PROJECT") &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="accept_popup"
                                    src={approval_icon}
                                    className="mb-2"
                                ></img>
                                {this.props.type === "CLOSE FINAL REVIEW" ?

                                    <div className='mb-2'>
                                        <span className='popup_text'>{`Note: Closing of Final Review will also be considered as Completion of the Project.`}</span> <span className='popup_text-light'>{this.props.message}</span>
                                    </div>
                                    :
                                    <div className='popup_text mb-2'>
                                        {this.props.message}
                                    </div>
                                }
                            </div>
                        }
                        {
                            this.props.type === "Rejected by PG" &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="accept_popup"
                                    src={approval_icon}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text mb-2'>
                                    {this.props.message}
                                </div>
                            </div>
                        }
                        {
                            this.props.type === "Shortlisted by PG" &&
                            <div className='flex-direction-column align-item-center-noflex justify-content-center-noflex'>
                                <img
                                    alt="accept_popup"
                                    src={approval_icon}
                                    className="mb-2"
                                ></img>
                                <div className='popup_text mb-2'>
                                    {this.props.message}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        {
                            this.props.type === "APPROVE" || this.props.type === "REJECT" ?
                                <>
                                    <CancelButton
                                        type={'button'}
                                        value={'CANCEL'}
                                        onClick={this.props.onDismiss}
                                    />
                                    <SuccessButton
                                        type={'button'}
                                        value={'CONFIRM'}
                                    // onClick={() => this.props.isApprove(this.props.type === "REJECT" ? "reject" : "approve")}
                                    />
                                </>
                                :
                                this.props.type === "START MTR REVIEW" ?
                                    <>
                                        <CancelButton
                                            type={'button'}
                                            value={'CANCEL'}
                                            onClick={this.props.onDismiss}
                                        />
                                        <SuccessButton
                                            type={'button'}
                                            value={'CONFIRM'}
                                            onClick={() => this.startMTRConfirmation()}
                                        />
                                    </>
                                    :
                                    this.props.type === "CLOSE MTR REVIEW" ?
                                        <>
                                            <CancelButton
                                                type={'button'}
                                                value={'CANCEL'}
                                                onClick={this.props.onDismiss}
                                            />
                                            <SuccessButton
                                                type={'button'}
                                                value={'CONFIRM'}
                                                onClick={() => this.closeMTRConfirmation()}
                                            />
                                        </>
                                        :
                                        this.props.type === "START FINAL REVIEW" ?
                                            <>
                                                <CancelButton
                                                    type={'button'}
                                                    value={'CANCEL'}
                                                    onClick={this.props.onDismiss}
                                                />
                                                <SuccessButton
                                                    type={'button'}
                                                    value={'CONFIRM'}
                                                    onClick={() => this.startFRConfirmation()}
                                                />
                                            </>
                                            :
                                            this.props.type === "CLOSE FINAL REVIEW" ?
                                                <>
                                                    <CancelButton
                                                        type={'button'}
                                                        value={'CANCEL'}
                                                        onClick={this.props.onDismiss}
                                                    />
                                                    <SuccessButton
                                                        type={'button'}
                                                        value={'CONFIRM'}
                                                        onClick={() => this.closeFRConfirmation()}
                                                    />
                                                </>
                                                :
                                                this.props.type === "Shortlisted by PG" ?
                                                    <>
                                                        <CancelButton
                                                            type={'button'}
                                                            value={'CANCEL'}
                                                            // onClick={this.props.onDismiss}
                                                            onClick={() => this.dismissPopup()}
                                                        />
                                                        <SuccessButton
                                                            type={'button'}
                                                            value={'CONFIRM'}
                                                            onClick={() => this.shortlistedByPG()}
                                                        />
                                                    </>
                                                    :
                                                    this.props.type === "Rejected by PG" ?
                                                        <>
                                                            <CancelButton
                                                                type={'button'}
                                                                value={'CANCEL'}
                                                                // onClick={this.props.onDismiss}
                                                                onClick={() => this.dismissPopup()}
                                                            />
                                                            <SuccessButton
                                                                type={'button'}
                                                                value={'CONFIRM'}
                                                                onClick={() => this.rejectedByPG()}
                                                            />
                                                        </>
                                                        :
                                                        this.props.type === "COMPLETE PROJECT" ?
                                                            <>
                                                                <CancelButton
                                                                    type={'button'}
                                                                    value={'CANCEL'}
                                                                    onClick={this.props.onDismiss}
                                                                />
                                                                <SuccessButton
                                                                    type={'button'}
                                                                    value={'CONFIRM'}
                                                                    onClick={() => this.completeConfirmation()}
                                                                />
                                                            </>
                                                            :
                                                            this.props.isEarlyStartMTR ?
                                                                <>
                                                                    <CancelButton
                                                                        type={'button'}
                                                                        value={'CANCEL'}
                                                                        onClick={this.props.onDismiss}
                                                                    />
                                                                    <SuccessButton
                                                                        type={'button'}
                                                                        value={'CONFIRM'}
                                                                        onClick={() => this.startMTRConfirmation()}
                                                                    />
                                                                </>
                                                                :
                                                                this.props.isEarlyStartFR ?
                                                                    <>
                                                                        <CancelButton
                                                                            type={'button'}
                                                                            value={'CANCEL'}
                                                                            onClick={this.props.onDismiss}
                                                                        />
                                                                        <SuccessButton
                                                                            type={'button'}
                                                                            value={'CONFIRM'}
                                                                            onClick={() => this.startFRConfirmation()}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <CancelButton
                                                                            type={'button'}
                                                                            value={'CANCEL'}
                                                                            onClick={() => this.props.isApprove("back")}
                                                                        />
                                                                        <SuccessButton
                                                                            type={'button'}
                                                                            value={'CONFIRM'}
                                                                            onClick={() => this.props.isApprove(this.props.type === "REJECT" ? "reject" : "approve")}
                                                                        />
                                                                    </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default WarningPopup