import React, { Component } from 'react';
import '../../employeeScreens/transfer/Transfer.css';
import Success_icon from '../../../../assets/drishti_images/manager/landing/Success_icon.svg';
import { toast } from 'react-toastify';

const enable = `Are you sure want to enable this screen?`
const disable = `Are you sure want to disable this screen?`
class EnableDisablePopup extends Component {
    constructor() {
        super()
        this.state = {
            reason: ''
        }
    }

    validate = () => {
        if (this.state.reason === '' && this.props.action === 'Disable') {
            setTimeout(() => {
                toast.warn('Please enter reason', {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastWarn'
                })
            }, 100)
        }
        else {
            this.props.actionFunction(this.state.reason)
        }
    }

    render() {
        const { action } = this.props
        return (
            <div className="modal popup-box">
                <div className="declaration-box p-0">
                    <div className="modal-headers mt-2 mx-3">
                        <h4 className="modal_title black">{action + ' confirmation'}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.props.onDismiss}>&times;</button>
                    </div>
                    <div className='hr'>
                        <hr />
                    </div>
                    <div className="modal-body">
                        {action === 'Enable' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='align-self-center'>
                                    <img
                                        alt="Enable"
                                        src={Success_icon}
                                        className="mb-2"
                                    ></img>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {enable}
                                </div>
                            </div>
                        }
                        {action === 'Disable' &&
                            <div className='flex-direction-column justify-content-center p-3'>
                                <div className='col-12'>
                                    <div className='p-sub-heading ml-3'>Enter Reason</div>
                                    <div className='p-placeholder-text'>
                                        <textarea
                                            className="form-control p-placeholder"
                                            type='text'
                                            placeholder='Enter reason'
                                            value={this.state.reason}
                                            onChange={(e) => this.setState({ reason: e.target.value })}
                                            readOnly={false}
                                            maxLength={300}
                                        />
                                    </div>
                                </div>
                                <div className="popup_text text-center p-3 m-3">
                                    {disable}
                                </div>
                            </div>
                        }
                    </div>
                    <div className='modal-footer btnBox'>
                        <input type='button' onClick={() => this.props.onDismiss()} value='CANCEL' className='cancelBtn' />
                        <input type='button' value={action === 'Enable' ? "ENABLE" : "DISABLE"} className='ml-2 validateBtn' onClick={() => this.validate()} />
                    </div>
                </div>
            </div>

        );
    }
}
export default EnableDisablePopup;