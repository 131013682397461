import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Select, { components } from "react-select";
import searchIcon from "../../../assets/drishti_images/plain-search.svg";
import { logEmpSearchActivity } from "../../../constants/Config";
import { fetchApiCall } from "../../../constants/Service";
import { configStore } from "../../../redux/ConfigStore";

const searchPlaceholder = "Search";
const headers = {
  "Content-Type": "application/json",
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span {...innerProps} />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img className="search-icon" src={searchIcon} />
    </components.DropdownIndicator>
  );
};

const Option = (props) => {
  return <components.Option {...props}>{props.children}</components.Option>;
};

const Search = (props) => {
  const [userModules, setUserModules] = useState([]);
  const [allowSearch, setAllowSearch] = useState(
    !configStore.getState()?.adminLoginState?.isAdminLoggedIn
  );
  const [searchText, setSearchText] = useState("");
  const history = useHistory();

  useEffect(() => {
    setUserModules(
      configStore.getState()?.loginState?.empModules.map((val) => {
        return { ...val, label: val.screen_name, value: val.screen_name };
      })
    );
  }, [configStore.getState()?.loginState?.empModules]);

  const filterOptions = (module, input) => {
    if (input) {
      return module.data.keywords.some((keyword) => {
        return keyword.toLowerCase().includes(input.toLowerCase());
      });
    }
    return true;
  };

  const recordSearchActivity = (selectedInput) => {
    let body = {
      employee_id: configStore.getState()?.loginState?.userData?.Perno,
      comp_code: configStore.getState()?.loginState?.userData?.CompCode,
      searched_text: searchText,
      clicked_link: selectedInput.path,
    };

    fetchApiCall(logEmpSearchActivity, body, headers, true, "")
      .then((res) => {
        console.log("Logged search activity");
      })
      .catch((err) => {
        console.error("Log search activity error", err.response);
      });
  };

  const redirect = (selectedInput, actionMeta) => {
    recordSearchActivity(selectedInput);
    if (selectedInput.type === "external") {
      // opening in new tab in case of external link
      window.open(selectedInput.path);
    } else {
      history.push(selectedInput.path);
    }
  };

  const handleInputChange = (newValue, meta) => {
    console.log("newValue", newValue, "meta", meta)
    if (meta.action === "input-change") {
      setSearchText(newValue);
    }
  };

  return (
    allowSearch && (
      <Select
        placeholder={searchText || searchPlaceholder}
        options={userModules}
        isDisabled={false}
        value={searchText}
        onChange={redirect}
        onInputChange={handleInputChange}
        menuPosition="fixed"
        filterOption={filterOptions}
        maxMenuHeight={150}
        classNamePrefix="search"
        components={{ DropdownIndicator, IndicatorSeparator, Option }}
      />
    )
  );
};

export default Search;
