import * as type from '../../../../redux/action/ActionType'

const ProfileState = {
    panData: {},
    aadharData: {},
    permanentAddressData: {},
    currentAddressData: {},
    contactData: {},
    bankData: {}
}

export const profile = (state = ProfileState, action) => {
    switch(action.type) {
        case type.GET_PAN_DETAILS:
            return {
                ...state,
                panData: action.payload
            }

        case type.GET_AADHAR_DETAILS:
            return {
                ...state,
                aadharData: action.payload
            }

        case type.GET_PERMANENT_ADDRESS_DETAILS:
            return {
                ...state,
                permanentAddressData: action.payload
            }

        case type.GET_CURRENT_ADDRESS_DETAILS:
            return {
                ...state,
                currentAddressData: action.payload
            }

        case type.GET_CONTACT_DETAILS:
            return {
                ...state,
                contactData: action.payload
            }
        case type.GET_BANK_DETAILS:
            return {
                ...state,
                bankData: action.payload
            }          
        case type.APP_LOGOUT:
            return{
                ...state,
                panData: {},
                aadharData: {},
                permanentAddressData: {},
                currentAddressData: {},
                contactData: {},
                bankData: {},
                isLoggedIn: false
            }
        default:
            return state;
    }
}