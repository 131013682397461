import React, { Component } from 'react';
import sendIcon from '../../../../../assets/drishti_images/cfm/sendicon.svg'
import * as Config from "../../../../../constants/Config";
import { fetchApiCall, sessionOut } from "../../../../../constants/Service";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
class NudgePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            popupClose: false,
        }
    }

    nudgeForLeave = () => {
        this.setState({
            loading: true
        });
        const body = {
            emp_id: this.props.ReporteeEmpId,
            emp_name: this.props.ReporteeName,
            emp_mail: this.props.ReporteeEmailId,
            reporting_manager: this.props.Perno,
            manager_name: this.props.CompName,
            reporting_manager_mail: this.props.EmailID
        }
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(
            Config.NudgeForLeave, body, headers, false, ''
        ).then(res => {
            sessionOut(this.props, res);

            if (res.data.status_code == 200) {
                this.setState({
                    loading: false,
                    popupClose: true,
                });
                toast.success(res.data.message, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    className: 'toastSuccess'
                });
                setTimeout(() => { window.location.reload() }, 1000)
            } else {
                this.setState({
                    loading: false,
                    popupClose: false,
                });
            }
        }).catch(err => {
            this.setState({
                loading: false
            });
        })
    }

    nudgeForFeedback = () => {
        this.setState({
            loading: true
        });
        const body = {
            emp_id: this.props.Perno,
            emp_name: this.props.CompName,
            reporting_manager: this.props.cfmData.directReporteeDetails.empId,
        }
        console.log("nudgeForFeedback body:", body)
        const headers = {
            'Content-Type': 'application/json',
        }
        fetchApiCall(
            Config.success_factor_push_feedack, body, headers, true, '')
            .then(res => {
                sessionOut(this.props, res);
                if (res.data.status_code == 200) {
                    this.setState({
                        loading: false,
                        popupClose: true,
                    });
                    toast.success(res.data.message, {
                        position: "bottom-center",
                        hideProgressBar: true,
                        className: 'toastSuccess'
                    });
                    setTimeout(() => { window.location.reload() }, 1000)
                } else {
                    this.setState({
                        loading: false,
                        popupClose: false,
                    });
                }
            }).catch(err => {
                this.setState({
                    loading: false
                });
            })
    }
    runningFornudgeForLeave() {
        this.props.openPopUp();
        this.nudgeForLeave();
    }
    render() {
        const { type } = this.props
        return (
            <div className="modal popup-box" >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    {type === "Nudge for Feedback" &&
                        <div className="modal-content apply_leave_modal">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Nudge for Feedback</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopUp}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row modal-body mdl-body justify-content-center ">
                                <img src={sendIcon} alt='' className='wh m-4' />
                                <div className='row'>
                                    <label className='text14_bold  m-3'>By clicking on this option, you will be sending a nudge to the employee to take their feedback more regularly.</label>
                                </div>
                            </div>
                            <div className='modal-footer btnBox'>
                                <input type='button'
                                    // onClick={() => window.location.reload()} 
                                    onClick={this.props.openPopUp}
                                    data-dismiss="modal"
                                    value='NOT NOW' className='cancelBtn'
                                />
                                <input type='button' value='SEND' className='ml-2 validateBtn'
                                    onClick={() => this.nudgeForFeedback()}
                                />
                            </div>
                        </div>
                    }

                    {type === "Nudge for leave" &&
                        <div className="modal-content apply_leave_modal">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Nudge for Leave</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.openPopUp}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row modal-body mdl-body justify-content-center ">
                                <img src={sendIcon} alt='' className='wh m-4' />
                                <div className='row'>
                                    <label className='text14_bold  m-3'>By clicking on this option, you will be sending a nudge to the employee to take their leaves more regularly.</label>
                                </div>
                            </div>
                            <div className='modal-footer btnBox'>
                                <input type='button'
                                    // onClick={() => window.location.reload()} 
                                    onClick={this.props.openPopUp}
                                    data-dismiss="modal"
                                    value='NOT NOW'
                                    className='cancelBtn'
                                />
                                <input type='button' value='SEND' className='ml-2 validateBtn'
                                    onClick={() => this.runningFornudgeForLeave()}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}
const mapStatesToProps = (state) => {
    return {
        cfmData: state.cfmState
    }
}
export default (connect(mapStatesToProps)(NudgePopup));
