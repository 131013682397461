export const gemsHomeScreen = {
    gemsTabApplicant: "GEMs for Applicant",
    gemsTabProjectGuide: "GEMs for Project Guide",
    gemsTabApproval: "GEMs Approval",
    gemstabPastProject: "GEMs Past Project"
}

export const gemsApplicant = {
    durationDays: 'Duration (Days):',
    pgName: "Project Guide Name:",
    BHRName: "BHR Name:",
    projectLocation: "Project Guide Location:",
    projectLoc: "Project Location: ",
    projectArea: "Project Area:",
    description: "Description:",
    noOfOpening: "No of Opening:",
    applicationsReceived: "Applications Received:",
    applicationsShortlisted: "Applications Shortlisted:",
    projectOU: "Entity:",
    startDate: "Project Start Date:",
    startD: "Start Date: ",
    endD: "End Date: ",
    endDate: "Project End Date:",
    projectCategory: "Project Category:",
    pgLevel: "Project Guide Level:",
    noOfPeople: "No of People:",
    ou: "Entity:",
    projectObj: "Project Title: ",
    projectStatus: "Project Status :",
    projectBhrName: "Project BHR Name :",
    mtrStartDate : "Mid Term Review Date :",
    mtrEndDate : "Mid Term Review End Date :",
    frStartDate : 'Final Review Date :',
    frEndDate :  "Final Review End Date :"
}

export const applicants = {
    requiredApplicant: "Required Applicant:",
    projectLocation: "Project Location:",
    apply: "APPLY",
    description: "Description:",
    applicant: "APPLICANT",
    pgLevel: "Project Guide Level:",
    MTRFRobjective: "MTR/FR Objective"
}

export const applicantFormNote = {
    noteInstruction1: "I am applying for the project to develop my professional skills. Also, I am aware that post selection I will not be able to withdraw my application till the completion of the project.",
    noteCheckBoxInstruction2: "I have completed prior discussion with my immediate manager regarding the deputation project/s that I wish to apply.",
}

export const gemsApplicantForm = {
    note: "Note:",
    noteInstruction1: "Please make sure the following details must present in your CV",
    noteInstruction2: "Provision to attach CV/ system should fetch employee details such as ID, Name, BU and Level (details provided in form )",
    uploadCV: "Kindly Upload Your CV",
    fileSizeLimit: "File Size Limit:",
    fileSize: "2",
    allowFileTypesText: "Allowed File Types:",
    allowFileTypes: "Word, PPT or PDF only"
}

export const gemsProjectForm = {
    projectName: "Project Title",
    PGName: "Project Guide Name",
    projectDetails: "Project Details",
    projectObjective: "Project Titles",
    location: "Project Guide Location",
    projectArea: "Project Area",
    durations: "Duration (Days)",
    noOfPeople: "Number of People",
    startDate: "Project Start Date",
    endDate: "Project End Date",
    projectOU: "Entity",
    mtrDate: "Mid Term Review Date",
    frDate: "Final Review Date",
    projectCategory: "Project Category",
    projectDescription: "Project Description",
}

export const gemsTableContaint = {
    srNo: "Sr No",
    pgName: "PG Name",
    projectTitle: "Project Title",
    status: "Status",
    startDate: "Start Date",
    endDate: "End Date",
    action: "Action"
}

export const formType = {
    gemsApplicant: "GEMSApplicant"
}

export const GEMSPGApproveForm = {
    approveNote: "Note: Are you sure you want to Approve the request?",
    rejectNote: "Note: Are you sure you want to Reject the request?",
    note: "Note: Click on the download icon to download the MTR Excel file and upload the file.",
    uploadNote: "Kindly Upload Your Form *",
    midTermReview: "Mid Term Review Form",
    finalTermReview: "Final Term Review Form",
    downloadFile: "Download File:"
}

export const instructions = {
    whatIsGems: "What is GEMs?"
}

export const appliedApplicant = { // do not add anything to this object
    srNo: 'Sr. No',
    projectTitle: 'Project Title',
    employeeId: "Applicant Id",
    employeeName: "Applicant Name",
    level: "Applicant Level",
    employeeLocation: "Applicant Location",
    projectOU: "Applicant OU",
    action: "Action"
}

export const applicantDetail = {
    applicantID: "Applicant Id: ",
    applicationID: "Application Id: ",
    applicantlocation: "Applicant Location: ",
    applicantName: "Applicant Name: ",
    applicantLevel: "Applicant Level: ",
    applicantOU: "Applicant OU: ",
    applicantCV: "Applicant CV:",
}
export const currentProject = {
    pg_perno: "Project Guide Name",
    location: "Project Guide Location",
    manager_perno: "Pending With",
    status: "Project Status",
    bhr_perno: "Project BHR Name",
    manager_perno: "Manager Name",
    projectArea: "Project Area",
    projectOU: "Entity",
    no_of_openings: "No. of Openings",
    planned_start_date: "Project Start Date",
    planned_end_date: "Project End Date",
    pgLevel: "Project Guide Level",
    planned_mtr_date: "Mid Term Review  Date",
    planned_fr_date: "Final Review Date",
    appl_received: "Applications Received:",
    appl_shrtlted_by_spoc: 'Applications Shortlisted by SPOC:',
    appl_shrtlted_by_pg: 'Application Selected by PG:'
}

export const valueLimits = {
    textAreaMaxLength: 500
}

export const objvalueLimits = {
    maxlenght: 200
}
export const constTypes = {
    completePro: "COMPLETE PROJECT",
    gemsProject: "GEMSProject",
    gemsApprovePopUp: "GEMSApprovePopUp",
    startPrj: "START PROJECT",
    startMTR: "START MTR REVIEW",
    waitForMTR: "MTR STATUS",
    waitForFR: "FR STATUS",
    closeMTR: "CLOSE MTR REVIEW",
    startFTR: "START FINAL REVIEW",
    endFTR: "END FINAL REVIEW",
    Instruction: "Instructions",
    addNewProject: "Add New Project",
    plsAddProject: "You don’t have any current project, Please add new project.",
    indMTR: "Individual MTR",
    indFR: "Individual FR",
    pdNote: "Note : 2 MB files upload here, file type pdf only.",
    declarationInfo: "I am aware that once I start the project with the required number of applications, the project will not be republished to add any additional members.",
    overallComment: "Overall comments for the Project",
    atchPrjReport: "Attached Project Summary Report",
    more:"MORE",
    status:"Status:",
    back:"BACK",
    closeFR:"CLOSE FINAL REVIEW",
    proceedToMTR: "PROCEED TO MTR",
    proceedToFR: "PROCEED TO FR"
}

export const placeholder = {
    projectDescription: "Enter the project description"
}

export const adminScreenAction = {
    updateStatus: "UPDATE STATUS",
    publish: "PUBLISH",
    cancel: "CANCEL",
    submit: "SUBMIT"
}

export function admin_radio_fiters() {
    let type = []
    type = [
        { id: 1, title: 'Review', status: 'Approved' },
        { id: 2, title: 'Shortlisting', status: 'Published' },
        { id: 3, title: 'All', status: '' },
        { id: 4, title: 'Search Application', status: 'Search Application' }
    ]
    return type
}

export function ta_shortlisting_radio_fiters() {
    let type = []
    type = [
        { id: 1, title: 'TA Screening' },
        { id: 2, title: 'PG Screening' },
        { id: 3, title: 'Rejected' }
    ]
    return type
}

export const adminGemsAllFilter = [
    {
        "code": "",
        "value": "All"
    },
    {
        "code": "1",
        "value": "Completed"
    },
    {
        "code": "2",
        "value": "Initiated"
    },
    {
        "code": "3",
        "value": "Ongoing"
    },
    {
        "code": "4",
        "value": "Ongoing - MTR Initiated"
    },
    {
        "code": "5",
        "value": "Ongoing - FR Initiated"
    },
    {
        "code": "6",
        "value": "Ongoing - MTR Closed"
    },
    {
        "code": "7",
        "value": "Ongoing - FR Closed"
    },
    {
        "code": "8",
        "value": "Approved"
    },
    {
        "code": "9",
        "value": "Published"
    },
    {
        "code": "10",
        "value": "Unpublished"
    },
    {
        "code": "11",
        "value": "Closed w/o Selection"
    },
    {
        "code": "12",
        "value": "Closed w/o Completion"
    },
]

export const gemsApplicantAdmin = {
    pgName: "PG Name:",
    location: "Project Location:",
    bhr_perno: "BHR Name",
    no_of_openings: "No of Opening:",
    pgLevel: "Project Guide Level:",
    manager_name: "Manager Name :",
    projectArea: "Project Area:",
    ou: "Entity:",
    applicationsReceived: "Applications Received:",
    applicationsShortlisted: "Applications Shortlisted:",
    startDate: "Start Date:",
    planned_start_date: "Start Date:",
    endDate: "End Date:",
    projectCategory: "Project Category:",
    noOfPeople: "No of People:",
}

export const gemsApplicantAdminDetail = {
    pgName: "PG Name:",
    location: "Project Location:",
    bhr_perno: "BHR Name",
    no_of_openings: "No of Opening:",
    pgLevel: "Project Guide Level:",
    manager_name: "Manager Name :",
    projectArea: "Project Area:",
    ou: "Entity:",
    startDate: "Start Date:",
    endDate: "End Date:",
    applicationsReceived: "Mid Term Review  Date :",
    applicationsShortlisted: "Final Review End Date :"
}
export const viewDetailsFromApplicant = {
    pgName: "Project Guide Name:",
    projectObj: "Project Title:",
    projectLocation: "Project Location:",
    noOfOpening: "No. of Openings:",
    applicationsReceived: "Applications Received:",
    applicationShortlistBySPOC: "Applications Shortlisted by SPOC:",
    applicationShortlistByPG: "Application Selected by PG:",
    startDate: "Start Date:",
    endDate: "End Date:",
    pgLevel: "Project Guide Level:",
    projectOU: "Entity:",
    projectArea: "Project Area:"
}

export const warnings = {
    noProject: "You have not applied to any projects yet",
    deleteProject: "Are you sure you want to delete request.",
    submitMTR: "Note:  Are you sure you want to submit the Mid term review.",
    approveRequest: "Note:  Are you sure you want to reject the request.",
    rejectRequest: "Note:  Are you sure you want to approve request.",
    strtMTR: "Note:  Are you sure you want to start the Mid Term Review now ?",
    strtFR: "Note:  Are you sure you want to start the Final Review now ?",
    closeMTRMsg: "Note:  Are you sure you want to close the Mid Term Review.",
    closeFRMsg: "Are you sure you want to close the Final Review now ?",
    cvDownload:"Your CV has been download succesfully.",
    completeProject: "Note:  Are you sure you want to complete the project.",
}

export const projectFormConstant = {
    bhrName: "Project BHR Name",
    managerName: "Project Approver Name ",
    projectObj: "Project Objective Details",
    mtrDate: "Mid Term Review  Date",
    frDate: "Final Review Date",
    prjMtrEvaluation: "Project Mid Term Evaluation",
    prjFREvaluation: "Project Final Evaluation",
    prjCompletion: "Project Completion",
    mtrStatus: "MTR Status",
    frStatus: "FR Status"
}

export const buttonName = {
    mtrFRObj: "MTR/FR Objective",
    applicant: "APPLICANTS",
    addNewOBJ: "ADD NEW OBJECTIVE",
    withdrawProject:"WITHDRAW PROJECT"
}

export const statusConst = {
    published:"Published",
    initiated: "Initiated",
    ongoing:"Ongoing",
    ongoingMTRInitiated:"Ongoing - MTR Initiated",
    ongoingMTRClosed:"Ongoing - MTR Closed",
    ongoingFRInitiated:"Ongoing - FR Initiated"
}

export const isCurrentAlreadyAppliedNote = `You have already applied for this project on `
export const isOthersAlreadyAppliedNote = `You have already selected in other projects`