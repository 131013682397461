import React, { Component } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import './Calendar.css'
import Dropdown from '../../../shared/components/Dropdown'
import { CalenderMonth, leaveColorData } from '../../../../utils/Utils'
import '../../../../assets/styles/basic.css';
import calender_on from '../../../../assets/drishti_images/attendance/calendar_toggle_on.svg';
import calender_off from '../../../../assets/drishti_images/attendance/calendar_toggle_off.svg';
import * as Config from '../../../../constants/Config';
import { fetchApiCall, sessionOut } from '../../../../constants/Service';
import LoaderIndicator from '../../../shared/loader/LoaderIndicator'
import { toast } from 'react-toastify';
import AttendanceDetailsPopup from './AttendanceDetailsPopup'
import { LeaveHistoryCardSkeleton_New } from '../../../shared/skeleton/Skeletons';
import NewNotdata from '../../../shared/notFound/NewNoData';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';

const requestFailed = 'Unable to contact server\n\tPlease retry after sometime';
const weeklyffs = 'Weekly Offs'
const publiHolidays = 'Public Holidays'
const localizer = momentLocalizer(moment);
class BigCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthData: CalenderMonth(),
      leaveDataColor: leaveColorData(),
      monthValue: moment().format("MMMM"),
      monthCode: moment().format("MM"),
      yearCode: moment().format("YYYY"),
      attendanceEnquiryDetails: [],
      weekOff: [],
      publicHolidays: [],
      loading: false,
      isCalender: true,
      attendanceDetailShow: false,
      selectedDate: '',
      selectedData: {}
    }
  }

  async componentDidMount() {
    this.getAttendanceEnquiryDetails(this.state.monthCode, this.state.yearCode)
  }

  async getAttendanceEnquiryDetails(month, year) {
    this.setState({
      loading: true
    });
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = {
      emp_id: this.props.loginData.userData.Perno,
      month: month,
      year: year.toString(),
      date_of_joining: moment(this.props.loginData.userData.Doj).format("YYYY-MM-DD")
    }

    fetchApiCall(Config.getAttendanceLeaveDetail, body, headers, false, '')
      .then(res => {
        sessionOut(this.props, res);
        if (res.data.status_code === '200') {
          this.setState({
            attendanceEnquiryDetails: res.data.attendance_enquiry_details,
            weekOff: res.data.week_offs,
            publicHolidays: res.data.public_holidays,
            loading: false,
          });
          const tempAttendanceArray = res.data.attendance_enquiry_details.map((item, idx) => ({
            ...item,
            title: item.status_text === publiHolidays ? publiHolidays : item.status_text === weeklyffs ? weeklyffs : this.nullRemove(item.FH, item.SH, item.title),
            start: item.LDATE + ' 00:00',
            end: item.LDATE + ' 23:59',
            hexColor: item.hexColor != '' ? item.hexColor : '#ffffff',
            title_status: item.title
          }))
          this.setState({ attendanceEnquiryDetails: tempAttendanceArray })
        } else {
          this.setState({
            loading: false
          });
        }

      }).catch(err => {
        this.setState({
          loading: false
        });
        toast.error(requestFailed, {
          position: "bottom-center",
          hideProgressBar: true,
          className: 'toastWarn'
        })
      })
  }

  nullRemove = (FH, SH, title) => {
    // if ((FH != null && SH != null && title != null)) {
    //   return 'FH: ' + FH + '\n' + 'SH: ' + SH + '\n' + title + '\n'
    // }
    if (FH != null) {
      return FH
    }
    else if (SH != null) {
      return SH
    }
    else if (title != null) {
      return title
    }
  }

  // nullRemove = (FH, SH, title) => {
  //   if ((FH != null && SH != null && title != null)) {
  //     return 'FH: ' + FH + '\n' + 'SH: ' + SH + '\n' + title + '\n'
  //   }
  //   else if (FH == null) {
  //     if (SH != null) {
  //       return 'FH:' + SH + '\n' + title + '\n'
  //     }
  //     else {
  //       return '' + title + '\n'
  //     }
  //   }
  //   else if (SH == null) {
  //     if (FH != null) {
  //       return 'SH:' + FH + '\n' + title + '\n'
  //     }
  //     else {
  //       return '' + title + '\n'
  //     }
  //   }
  //   else if (title != null) {
  //     return '' + title
  //   }
  // }

  eventSelected = (data) => {
    this.setState({ attendanceDetailShow: true, selectedData: data })
  }

  slotSelected = (data) => {
    this.setState({ attendanceDetailShow: true, selectedDate: {} })
  }

  changeMonth = (data) => {
    this.setState({
      monthCode: data.code,
      monthValue: data.value,
    }, () => this.getAttendanceEnquiryDetails(data.code, this.state.yearCode)
    )
  }

  toggleScreen = () => {
    this.setState({ isCalender: !this.state.isCalender })
  }

  getDate = (date) => {
    let tempMonth = moment(date).format("MM")
    let tempMonthVAL = moment(date).format("MMMM")
    let tempYear = moment(date).format("YYYY")
    this.setState({
      monthCode: tempMonth,
      monthValue: tempMonthVAL,
    }, () => this.getAttendanceEnquiryDetails(tempMonth, tempYear)
    )
  }

  renderEvent = (event) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id={`tooltip-${event.id}`} className="custom-tooltip">
            <div className='row'><span className='noteTextBold mr-1'>{'First half:'}</span><span className='highlight-sm-Text'>{event.event.FH ? event.event.FH : '-'}</span></div>
            <div className='row'><span className='noteTextBold mr-1'>{'Second half:'} </span><span className='highlight-sm-Text'>{event.event.SH ? event.event.SH : '-'} </span></div>
            <div className='row'><span className='noteTextBold mr-1'>{'Status:'} </span><span className='highlight-sm-Text'>{event.event.title_status ? event.event.title_status : '-'} </span></div>
          </Tooltip>
        }
      >
        <div className='text-bold'>
          {event.event.title_status ? <div><span className={`noteTextBold`} style={{color: event.event.hexColor}}>{event.event.title_status ? event.event.title_status : '-'} </span></div> : null}
          <div><span>{'Swipe In: '} </span><span>{event.event.SWIPE_IN == '00:00:00' ? '-' : event.event.SWIPE_IN} </span></div>
          <div><span>{'Swipe Out: '} </span><span>{event.event.SWIPE_OUT == '00:00:00' ? '-' : event.event.SWIPE_OUT} </span></div>
        </div>
      </OverlayTrigger>
    );
  };

  render() {
    const { monthCode, monthData, isCalender, attendanceEnquiryDetails, loading } = this.state
    const currentYear = moment().format("YYYY")
    return (
      <div>
        <LoaderIndicator loading={this.state.loading} />
        <div className='row justify-content-between align-item-center pt-12px'>
          <h5 className='announcement-inner-content common-text-color'>{isCalender ? 'Calendar View : ' + this.state.monthValue : 'Table View'}</h5>
          <div className='row align-item-center justify-content-between'>
            <div className=''>
              {isCalender ? <img alt="calendar" style={{ width: '80px' }} src={calender_on} onClick={() => this.toggleScreen()}></img> :
                <img alt="list" style={{ width: '80px' }} src={calender_off} onClick={() => this.toggleScreen()}></img>}
            </div>
            <div className={isCalender ? 'visibility-hidden calender-button-width ml-3' : 'calender-button-width ml-3'}>
              <Dropdown
                data={monthData}
                selectedValue={(data) => this.changeMonth(data)}
                value={this.state.monthValue}
                render={'value'}
                disabled={isCalender}
              />
            </div>
          </div>
        </div>
        {isCalender ?
          <div className='mt-12px scroller-cal-bg'>
            <Calendar
              localizer={localizer}
              defaultDate={new Date(monthCode + '-01-' + currentYear)}
              events={attendanceEnquiryDetails}
              wrapTitle
              startAccessor="start"
              endAccessor="end"
              style={{ height: 550 }}
              defaultView="month"
              views={["month"]}
              popup={true}
              onSelectEvent={(e) => this.eventSelected(e)}
              selectable={true}
              onSelectSlot={(slot) => {
                this.slotSelected(slot)
              }}
              longPressThreshold={20}
              onNavigate={(date) => this.getDate(date)}
              eventPropGetter={(event, start, end, isSelected) => ({
                event,
                start,
                end,
                isSelected,
                style: { backgroundColor: 'transparent', color: 'black', borderLeft: '5px solid' + event.hexColor, height: '60px' }
              })}
              tooltipAccessor={null}
              components={{
                event: this.renderEvent
              }}
            />
          </div>
          :
          <div className='row mt-12px '>
            {loading ?
              <div style={{ width: '100%' }}>
                <LeaveHistoryCardSkeleton_New />

              </div>
              :
              <>
                {attendanceEnquiryDetails.length != 0 ?
                  <div className='big_calender_table_scroll mb-2 col-md-12'>
                    <table className="table">
                      <thead className=''>
                        <tr>
                          <th scope="col" style={{ 'width': '8%' }}><div className='thead pr-0'>Sr No</div></th>
                          <th scope="col" style={{ 'width': '10%' }}><div className='thead'>Date</div></th>
                          <th scope="col" style={{ 'width': '5%' }}><div className='thead'>Day</div></th>
                          <th scope="col" style={{ 'width': '15%' }}><div className='thead'>In Time</div></th>
                          <th scope="col" style={{ 'width': '15%' }}><div className='thead'>Out Time</div></th>
                          <th scope="col" style={{ 'width': '15%' }}><div className='thead'>First Half</div></th>
                          <th scope="col" style={{ 'width': '15%' }}><div className='thead'>Second Half</div></th>
                          <th scope="col" style={{ 'textAlign': 'center', 'width': '15%' }}><div className='thead'>Status</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {attendanceEnquiryDetails.map((listValue, index) => {
                          return (
                            <tr key={index} >
                              <td style={{ 'width': '8%' }}><div className="tdLeaveHistory" style={{ 'marginLeft': '10px' }}>{index + 1}</div></td>
                              <td><div className="tdLeaveHistory">{moment(listValue.LDATE).format("DD-MM-YYYY")}</div></td>
                              <td><div className="tdLeaveHistory">{moment(listValue.LDATE).format("dddd")}</div></td>
                              <td><div className="tdLeaveHistory">{listValue.SWIPE_IN}</div></td>
                              <td><div className="tdLeaveHistory">{listValue.SWIPE_OUT}</div></td>
                              <td><div className="tdLeaveHistory">{listValue.FH}</div></td>
                              <td><div className="tdLeaveHistory">{listValue.SH}</div></td>
                              <td><div className="tdLeaveHistory" style={{ color: listValue.hexColor, textAlign: 'center' }} >{listValue.title_status ? listValue.title_status : '-'}</div></td>
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                  </div> :
                  <div style={{ height: '40vh' }} className='col-md-12 d-flex align-items-center justify-content-center  '>
                    <NewNotdata />
                  </div>
                }
              </>
            }
          </div>
        }
        {this.state.attendanceDetailShow &&
          <AttendanceDetailsPopup
            leaveColorData={this.state.leaveDataColor}
            date={this.state.selectedDate}
            data={this.state.selectedData}
            onDismiss={() => this.setState({ attendanceDetailShow: false })} />}
      </div>
    );
  }
}

export default BigCalendar;
